import React, { Component } from "react";
import classes from "./App.module.css";

import SystemText from "./Initialize/SystemText";
import GetUserLocation from "./Initialize/GetUserLocation";
import MainPage from "./Containers/MainPage/MainPage";
import DetectInputLanguage from "./Components/Functions/DetectInputLanguage"
import LoginForm from "./Components/Complex/LoginForm/LoginForm"
import TransparentBackdrop from "./Components/UI/Backdrop/TransparentBackdrop";

/* import firebase from "firebase"; */
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/analytics';
import firebaseConfig from "./Firebase";

import { isMobile } from "react-device-detect";


let loadingTop;
let loadingBottom
if (isMobile){
    loadingTop = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FloadingTopMobile.png?alt=media&token=190342fb-58f6-4cef-a096-661cda83043a"
    loadingBottom = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FloadingBottomMobile.png?alt=media&token=cb032012-4053-4110-8892-6d7a8bc725a0"
}
else{
    loadingTop = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FloadingTopDesktop.png?alt=media&token=6cb4f326-f37a-477d-80cf-5d04fdbe9e41"
    loadingBottom = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FloadingBottomDesktop.png?alt=media&token=1d5a01e5-29e1-442e-af4d-e924ea80efd7"
}


const environment = "/prod";

const today = new Date();

let start = today.getTime();
//initialize using firebase api for the project
if (!firebase.apps.length) { 
  firebase.initializeApp(firebaseConfig);
 }else {
  firebase.app(); // if already initialized, use that one
}

const analytics = firebase.analytics();

//for firebase authentication with Google
 const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account"
});

//for firebase authentication with Facebook
const facebookProvider = new firebase.auth.FacebookAuthProvider();  

var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);

const todayDate = moment(today).format('DD-MM-YYYY').toString();

export default class App extends Component {

  state={
    userCountry:"",
    loadingStatus:"initialize",
    loginStatus:"checking",
    userCountryIsInCountriesToExploreList:false,
    inputLanguage:"English",
    showLoginForm:false,
    userSessionID:"",
    showWelcomeWizard:false,
  }

  componentDidMount = () =>{
    this.checkIfShowWelcomeWizard();
    this.loadSystemInitializeData();
    this.checkLoginStatus();
    this.checkUserLocation();
  }

  checkIfShowWelcomeWizard = () =>{
    if (localStorage.getItem('showWelcomeWizard')!==null){
      let showWelcomeWizard =JSON.parse(localStorage.getItem('showWelcomeWizard'))
      this.setState({showWelcomeWizard:showWelcomeWizard})
    }
    else{
      this.setState({showWelcomeWizard:true})
    }
  }
  
  shouldComponentUpdate = nexState =>{
    if (this.state.loginStatus!==nexState.loginStatus||
      this.state.loadingStatus!==nexState.loadingStatus||
      this.state.userCountry!==nexState.userCountry||
      this.state.userCountryIsInCountriesToExploreList!==nexState.userCountryIsInCountriesToExploreList||
      this.state.inputLanguage!==nexState.inputLanguage||
      this.state.userLanguage!==nexState.userLanguage){
          return true
      }
      return false
  }

  reloadHandler = () =>{
    window.location.reload();
  }

  transparentBackdropHandler = () =>{
    this.setState({
      showLoginForm:false,
      showTransparentBackdrop:false
    })
  }

  checkUserLocation = async () =>{
    await this.refs.getUserLocation.getUserLocation();
  }

  
  addToDatabase = (path, object, key) => {
    firebase.database()
      .ref(path + "/" + key)
      .set(object);
  };



  updateStatistics = async (path, type, criteria) => {
    let stats;
     await firebase.database()
      .ref(
       path+"/"+type
      )
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          stats=snapshot.val();
        }
      });
      if (stats === undefined) {
        stats = { count: 1, list: [criteria] };
      } 
      else if (stats.count===0){
        stats = { count: 1, list: [criteria] };
      }
      else {
        let include = false;
        for (let i = 0; i < stats.list.length; i++) {
          if (JSON.stringify(stats.list[i].userID) === JSON.stringify(criteria.userID)) {
            include = true;
          }
        }
        if (!include) {
          stats.count += 1;
          stats.list.push(criteria);
        }
      }   
      this.addToDatabase(
        path,
        stats,
        type
      );
  }


  UpdateInDatabase = (path, object) => {
    firebase
      .database()
      .ref(path)
      .set(object);
  };

  updateInitializationState = () =>{
    // check if all 3 first steps are completed
    if (this.state.loginStatus!=="checking"&&
      this.state.systemInitializeData!==undefined&&
      this.state.userCountry!=="")
    {
      
      if (this.state.systemInitializeData.countriesToExplore!==undefined){
        if (this.state.systemInitializeData.countriesToExplore.includes(this.state.userCountry)){
          this.setState({userCountryIsInCountriesToExploreList:true})
        }
        else{
          //default for now
          this.setState({userCountry:"Israel"})
        }
      }
      this.setState({loadingStatus:"completed"})
    }
    
  }

  logoutHandler = async () =>{
    await firebase.auth().signOut().then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
    localStorage.clear();
    window.location.reload();
    this.setState({loginStatus: "tryOut",user: {}})
  }

checkLoginStatus =async()=> {
  /* if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    console.log("with")
    console.log(window.location.href)
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
    }
    // The client SDK will parse the code from the link for you.
    console.log(email)
    firebase.auth().signInWithEmailLink(email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
    }
   else{ */
    await firebase.auth().onAuthStateChanged(user => {
      if (user) {
        let  loggedUser ={
         displayName: user.displayName,
         userID: user.uid,
         userEmail: user.email,
         photoUrl: user.photoURL
         };
       
        
        //write login activity
        let userSessionID = user.uid+"_"+start
        let activityKey="USER_LOGIN"
        if (user.email===null){
         activityKey="USER_LOGIN_ANONYMOUS"
        }
        //activityKey.eventLevel=1
      /*   analytics.logEvent(activityKey, { email: user.email}); */
        this.setState({userSessionID:userSessionID,activityKey:activityKey
          })
        this.handleLogin(loggedUser);
     }
     else {
          firebase.auth().signInAnonymously()
       .then((user) => {
         // Signed in..
         //write login activity
         let anonymousUser = {
           displayName: user.displayName,
           userID: user.uid,
           userEmail: user.email,
           photoUrl: user.photoURL
         }
        let userSessionID = user.uid+"_"+start
        this.setState({userSessionID:userSessionID,activityKey:"USER_SIGNIN_ANONYMOUS",showWelcomeWizard:true,showWelcomeWizardDefined:true})
        localStorage.setItem('showWelcomeWizard',true);
        let activity={
          userID:user.uid,
          userSessionID:userSessionID,
          timestamp:start,
          activityKey:"USER_SIGNIN_ANONYMOUS",
          eventLevel:1
        }
       /*  analytics.logEvent('USER_SIGNIN_ANONYMOUS'); */
        this.addToDatabase(
         environment+"/systemMetrics/activitiesLog/"+todayDate,
         activity,
         activity.timestamp+"_"+activity.userID
       );
       this.handleLogin(anonymousUser);
       })
       .catch((error) => {
         var errorCode = error.code;
         var errorMessage = error.message;
         // ...
       });
     }
   });
  /*  } 
   */
}


getUserInfo = async (user) =>{
  //retrieve user info
  let userID = user.userID
  let userDataPath = "/usersData/"
  if (user.userEmail === null){
    userDataPath = "/tempUsersData/"
  }
  let userInfo;
  if (localStorage.getItem('userInfo')!==null){
    userInfo =JSON.parse(localStorage.getItem('userInfo'))
    this.setState({userInfo:userInfo})
  }
  await firebase.database()
      .ref(
        environment + userDataPath + userID + "/userInfo"
      )
      .once("value")
      .then(snapshot => {
        if (snapshot!==null){
          userInfo=snapshot.val()
          localStorage.setItem('userInfo',JSON.stringify(userInfo));
          this.setState({userInfo:userInfo})
        }
        else{
          //for newly created user
          let userSettings={profileImage:{name:"profileImage", url:user.photoUrl}}
          let userInfo={userSettings:userSettings}
          localStorage.setItem('userInfo',JSON.stringify(userInfo));
          this.setState({userInfo:userInfo})
        }
      });
   
}
 
  loadSystemInitializeData = async () =>{
    //get the system most basic information from the db or cached if exist
    let systemInitializeData;
    //use initializedData cached
    if (localStorage.getItem('systemInitializeData')!==null){
      systemInitializeData =JSON.parse(localStorage.getItem('systemInitializeData'));

      let systemText = this.refs.systemText.returnText();
      await this.setState({systemInitializeData:systemInitializeData,systemText:systemText})
      this.updateInitializationState()
      
    }
    await firebase.database()
      .ref(
        environment + "/systemInitializeData"
      )
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          systemInitializeData=snapshot.val();
          
        }
       
      });
      localStorage.setItem('systemInitializeData', JSON.stringify(systemInitializeData));
      //====Need to replace string ref with "useImperativeHandle"===
      let systemText = this.refs.systemText.returnText();
      await this.setState({systemInitializeData:systemInitializeData,systemText:systemText})
      this.updateInitializationState()
  }

  handleFacebookLogin = async () =>{
    this.setState({showLoginForm:false,showTransparentBackdrop:false})
    let user;
    await firebase
  .auth()
  .signInWithPopup(facebookProvider)
  .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    var credential = result.credential;

    // The signed-in user info.
    
    console.log(result.user)
    user ={
      displayName: result.user.displayName,
      userID: result.user.uid,
      userEmail: result.user.email,
      photoUrl: result.user.photoURL
      };
      let userSessionID = user.uid+"_"+start
      this.setState({userSessionID:userSessionID})
      let activity={
        userID:user.uid,
        userSessionID:userSessionID,
        timestamp:start,
        activityKey:"SIGNIN_FACEBOOK",
        eventLevel:1
      }
      this.addToDatabase(
       environment+"/systemMetrics/activitiesLog/"+todayDate,
       activity,
       activity.timestamp+"_"+activity.userID
     );
      analytics.logEvent("SIGNIN_FACEBOOK");

    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    var accessToken = credential.accessToken;

    // ...
  })
  .catch((error) => {
    // Handle Errors here.
    var errorCode = error.code;
    alert(error.message)
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;

    // ...
  });
  if (user!==undefined){
    window.location.reload();
    //this.handleLogin(user);
  }
  }

  handleGoogleLogin = async ()=>{
    this.setState({showLoginForm:false,showTransparentBackdrop:false})
    let user;
    let loginError;
    await firebase.auth().signInWithPopup(googleProvider).then(function(result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken;
      // The signed-in user info.
       user ={
        displayName: result.user.displayName,
        userID: result.user.uid,
        userEmail: result.user.email,
        photoUrl: result.user.photoURL
        };
        let userSessionID = user.uid+"_"+start
        this.setState({userSessionID:userSessionID})
        let activity={
          userID:user.uid,
          userSessionID:userSessionID,
          timestamp:start,
          activityKey:"SIGNIN_GOOGLE",
          eventLevel:1
        }
        this.addToDatabase(
         environment+"/systemMetrics/activitiesLog/"+todayDate,
         activity,
         activity.timestamp+"_"+activity.userID
       );
        analytics.logEvent("SIGNIN_GOOGLE");
      // ...
    }).catch(function(error) {
      loginError=error;
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
    if (user!==undefined){
      window.location.reload();
      //this.handleLogin(user);
    }
    }

    handleResetPassword = email =>{
      firebase.auth().sendPasswordResetEmail(email).then(function() {
        // Email sent.
      }).catch(function(error) {
        // An error happened.
      });
  }

  handleEmailLink = async (email,displayName)=>{
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this URL
      // must be whitelisted in the Firebase Console.
      'url': window.location.href, // Here we redirect back to this same page.
      'handleCodeInApp': true // This must be true.
     };

    firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      // ...
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  handlerEmailPasswordRegister = async (email,password,firstName, lastName)=>{
    this.setState({showLoginForm:false,showTransparentBackdrop:false})
    let displayName = firstName + " "+lastName
    let registerSuccess;
    let registerResult
    await firebase.auth().createUserWithEmailAndPassword(email.trim(), password)
        .then(function(result) {
          // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
         // this.refs.landingPage.registerSuccess();
         analytics.logEvent("SIGNIN_EMAIL");
         registerResult=result
          registerSuccess=true;
          result.user.updateProfile({
            displayName: displayName
          }).then(function() {
            // Update successful.
          }).catch(function(error) {
            // An error happened.
          });
         
        }).catch(function(error) {
          // Handle error.
          var errorCode = error.code;
          registerSuccess=false;
         /*  alert(error); */
          var errorMessage = error.message;
        });
     if (registerSuccess&&registerResult!==undefined){
        this.handlerEmailPasswordLogin(email,password,displayName)
      }
  }


  handlerEmailPasswordLogin = async (email, password)=>{
    this.setState({showLoginForm:false,showTransparentBackdrop:false})
    let user;
    let emailLogin = email.toString().trim();
    await firebase.auth().signInWithEmailAndPassword(emailLogin, password).then(function(result){
      user ={displayName: result.user.displayName,
        userID: result.user.uid,
        userEmail: result.user.email,
        photoUrl: result.user.photoURL
        };
        let userSessionID = user.uid+"_"+start
        this.setState({userSessionID:userSessionID})
        let activity={
          userID:user.uid,
          userSessionID:userSessionID,
          timestamp:start,
          activityKey:"EMAIL_LOGIN",
          eventLevel:1
        }
        this.addToDatabase(
         environment+"/systemMetrics/activitiesLog/"+todayDate,
         activity,
         activity.timestamp+"_"+activity.userID
       );
    }).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
    /*   alert(error); */
      var errorMessage = error.message;
      // ...
    });
    if (user!==undefined){
     setTimeout(e => {
        window.location.reload();
        //this.handleLogin(user);
      }, 500); 
    }
      //User just registered
  }

  handleLogin = async user =>{
    if (user.userEmail === null){
      //handling anonymous user
      await this.setState({
        user:user,
        loginStatus: "tryOut"}) 
      this.getUserInfo(user)
      this.updateInitializationState()
      this.updateUsersActivityMetrics(user.userID);  
      
      return;
    }
    else{
      //handling logged user
      await this.setState({
        loginStatus: "logged",
        user: user
      });
      this.getUserInfo(user)
      this.updateInitializationState()
      let userExist;
      await firebase
      .database()
      .ref(environment + "/users/"+user.userID)
      .once("value")
      .then(snapshot => {
        if (snapshot!==null){
          userExist=snapshot.val()
        }
      });  
      if (userExist!==undefined&&userExist!==null){
        let dbUser={...user}
        dbUser.registerDate=userExist.registerDate;
        dbUser.lastActivity= today.toLocaleString();
        if (userExist.showWelcomeWizard!==undefined){
          dbUser.showWelcomeWizard = userExist.showWelcomeWizard
          localStorage.setItem('showWelcomeWizard',userExist.showWelcomeWizard);
        }
        this.setState({showWelcomeWizard:userExist.showWelcomeWizard, showWelcomeWizardDefined:true})
        if (userExist.userCountry!==undefined){
          dbUser.userCountry = userExist.userCountry
          await this.setState({userResidenceCountry:userExist.userCountry})
          this.handleSelectedCountry()
        }
        else if (this.state.locationCountry!==undefined){
          dbUser.userCountry = this.state.locationCountry
          await this.setState({userResidenceCountry:this.state.locationCountry})
        }
        this.UpdateInDatabase(
          
          environment + "/users/" + user.userID ,
          dbUser
        );
        
        //update metrics
        this.updateUsersActivityMetrics(user.userID);
        
      }
      else{
        // creating entirely new user - user just signed in for the first time
        let dbUser={displayName: user.displayName,
          userID: user.userID,
          userEmail: user.userEmail,
          photoUrl: user.photoUrl,
          registerDate:today.toLocaleString(),
          lastActivity: today.toLocaleString()
          };
        this.UpdateInDatabase(
          environment + "/users/" + dbUser.userID,
          dbUser
        );
        let userSettings={profileImage:{name:"profileImage", url:user.photoUrl}}
        this.UpdateInDatabase(
          environment + "/usersData/" + dbUser.userID+"/userInfo/userSettings",
          userSettings
        );
  
         //update metrics
          this.setState({firstLogin:true,showWelcomeWizard:false,showWelcomeWizardDefined:true})
         this.updateUsersActivityMetrics(user.userID);
         this.updateNewUsersMetrics(user.userID)
      }  
    }
   
  }

  updateUsersActivityMetrics = async (userID) =>{
    //get date
    var localeData = moment.localeData();
    var format = localeData.longDateFormat('L');
    const dateMetric = moment(today);
    let date = dateMetric.format('YYYY-MM-DD');
    let statsPath = environment +  "/systemMetrics/usersActivity/activeUsers";
    this.updateStatistics(
      statsPath,
      date.toString(),
      {userID:userID,logged:this.state.loginStatus==="logged"});
  }

  updateNewUsersMetrics = async (userID) =>{
    //get date
    var localeData = moment.localeData();
    var format = localeData.longDateFormat('L');
    const dateMetric = moment(today);
    let date = dateMetric.format('YYYY-MM-DD');
    let statsPath = environment +  "/systemMetrics/usersActivity/newUsers";
    this.updateStatistics(
      statsPath,
      date.toString(),
      {userID:userID,logged:this.state.loginStatus==="logged"});
  }


  detectInputLanguageHandler = async input =>{
    await this.refs.detectInputLanguage.detectLanguage(input).then(result => {
      this.setState({
        inputLanguage:result
      })
    }); 
  }

  updateSystemLanguage = language =>{
    localStorage.setItem('language',language);
    let RTL=false
    switch(language){
      case "Hebrew":{
        RTL=true;
        break;
      }
      default: RTL=false
    } 
    this.setState({userLanguage:language,RTL:RTL})
  }

  updateUserLanguage = async language => {
    let userLanguage=""
    let RTL=false
    if (localStorage.getItem('language')!==null){
      userLanguage = localStorage.getItem('language').toString()
      switch(userLanguage){
        case "Hebrew":{
          RTL=true;
          break;
        }
        default: RTL=false
      } 
    }
    else{
      userLanguage=language    
      switch(userLanguage){
        case "Hebrew":{
          RTL=true;
          break;
        }
        default: RTL=false
      }
    }
    await this.setState({userLanguage:userLanguage,RTL:RTL})
  }

  updateUserCountry = async countryName =>{
    //UPDATING FROM WHERE THE USER CONNECTED TO WEBAPP
    await this.setState({locationCountry:countryName})
    this.handleSelectedCountry()
  }

  handleSelectedCountry = async () =>{
    let locationCountry = this.state.locationCountry
    let userResidenceCountry = this.state.userResidenceCountry;
    if (locationCountry!==undefined&&userResidenceCountry!==undefined){
      //NEED TO ADD USER CONFLICT OF DIFFERENT COUNTRIES
        await this.setState({userCountry:userResidenceCountry})
        this.updateInitializationState()

    }
    else if (userResidenceCountry!==undefined){
      await this.setState({userCountry:userResidenceCountry})
      this.updateInitializationState()
    }
    else if (locationCountry!==undefined && this.state.loginStatus!=="logged"){
      await this.setState({userCountry:locationCountry})
      this.updateInitializationState()
    }
  }


  //getting all the hard coded text based on user language
  systemTextByLanguage = () =>{
    return(
      <SystemText
        ref="systemText"
        userLanguage={this.state.userLanguage}
      />
    )
  }

  detectInputLanguage = () =>{
    return (<DetectInputLanguage
        ref="detectInputLanguage"
      />)
  }

  getUserLocation = () =>{
    return (<GetUserLocation
          ref="getUserLocation"
          updateUserCountry={this.updateUserCountry}
          updateLanguage={this.updateUserLanguage}
          loggedInStatus={this.state.loggedInStatus}
          tryAgain={this.getUserLocation}
      />)
  }

  renderApp = () =>{
    let loggedUser = this.state.loginStatus==="logged"
    let isAdminUser = false;
    if (this.state.loginStatus==="tryOut"){
      loggedUser=false;
    }
    else{
      if (this.state.systemInitializeData.admin.sysAdmins.includes(this.state.user.userID)){
        isAdminUser = true
      }
    }
    let tempUser = {...this.state.user}
    tempUser.userID = "1GzoLhyeFnPIWnBJENybdgmNyi22"
    return(
      <MainPage
        ref="mainPage"
        updateSystemLanguage = {this.updateSystemLanguage}
        systemInitializeData = {this.state.systemInitializeData}
        systemText = {this.state.systemText}
        userLanguage = {this.state.userLanguage}
        inputLanguage = {this.state.inputLanguage}
        detectInputLanguage = {this.detectInputLanguageHandler}
        userCountry = {this.state.userCountry}
        locationCountry = {this.state.locationCountry}
        userResidenceCountry = {this.state.userResidenceCountry}
        RTL={this.state.RTL}
        loggedUser = {loggedUser}
        showWelcomeWizard = {this.state.showWelcomeWizard}
        environment = {environment}
        userCountryIsInCountriesToExploreList = {this.state.userCountryIsInCountriesToExploreList}
        loginHandler = {()=>{this.setState({showLoginForm:true,showTransparentBackdrop:true})}}
        user = {this.state.user}
        isAdminUser = {isAdminUser}
        logout = {this.logoutHandler}
        userInfo = {this.state.userInfo}
        reloadApp = {this.reloadHandler}
        userSessionID={this.state.userSessionID}
        loginActivity = {this.state.activityKey}
      />
    )
  }

  renderMainApp = () =>{
    switch (this.state.loadingStatus){
      case "initialize":{
         return(
          <div className={classes.Wrapper}>
            {/* {isMobile?(
               <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FwelcomeImageMobile.jpg?alt=media&token=07c72158-dc78-4bec-8be5-8fee11b88f43"} className={classes.WelcomeImage} alt="" />
            ):(
              <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FwelcomeImageDesktop.jpg?alt=media&token=760ae078-3d17-4cd3-9102-03d40919da32"} className={classes.WelcomeImage} alt="" />
            )} */}
              <div className={classes.LoadingSection}>  
                <img className={classes.LoadingTop} alt="" src={loadingTop} />
                <img className={classes.LoadingBottom} alt="" src={loadingBottom} />
            </div>
           
          </div>
        ); 
        break;
      }
      case "completed":{
        if (this.state.loginStatus==="tryOut"||
        this.state.user!==undefined){
          return ( 
            <div className={classes.Wrapper}>{this.renderApp()}</div>
          ); 
        }
        
        break;
      }
      default: return null;
    } 
  }

  renderLoginForm = () =>{
    if (this.state.showLoginForm){
      return(
        <LoginForm
            systemText = {this.state.systemText}
            RTL={this.state.RTL}
            userLanguage = {this.state.userLanguage}
            discard = {()=>{this.setState({showLoginForm:false})}}

            googleLogin = {this.handleGoogleLogin}
            facebookLogin = {this.handleFacebookLogin}
            emailRegister={this.handlerEmailPasswordRegister} 
            emailLogin={this.handlerEmailPasswordLogin}
            resetPassword={this.handleResetPassword}
            handleEmailLink={this.handleEmailLink}
        />
      )
    }
  }

   render() {
    return (
      <div className={classes.App}>
        <TransparentBackdrop show={this.state.showTransparentBackdrop} clicked={this.transparentBackdropHandler}/>
          {this.renderLoginForm()}
          {this.getUserLocation()}
          {this.detectInputLanguage()}
          {this.systemTextByLanguage()}
          {this.renderMainApp()}
      </div>)
    }
}

