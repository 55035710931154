import React, { Component } from "react";
import classes from "./ExperienceEditor.module.css";

import ModalTopSection from "../../../Components/UI/Misc/ModalTopSection"
import ConfirmModal from "../../../Components/UI/Modals/ConfirmationModal";
import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop"
import Backdrop from "../../../Components/UI/Backdrop/Backdrop"
import PlanPlaceItem from "../../../Components/Complex/Plan/PlanPlaceItem"
import PlaceSelection from "../../../Components/Complex/PlaceSelection/PlaceSelection"
import ImageUploader from "../../../Components/Complex/Images/ImageUploader"
import PlansWall from "../TripPlans/PlansWall"
import TaggingSection from "../../../Components/Complex/Tagging/TaggingSection"
import OptionSelection from "../../../Components/Complex/OptionSelection/OptionSelection"
import DetectInputLanguage from "../../../Components/Functions/DetectInputLanguage"
import PlanEditors from "../../../Components/Complex/Plan/PlanEditors"

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker,isInclusivelyAfterDay } from "react-dates";

import { Editor, EditorState , convertFromRaw, convertToRaw} from 'draft-js';

import {isMobile} from 'react-device-detect';

import ReactPlayer from 'react-player'

let uploadIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FUploadIconWhite.png?alt=media&token=12848c43-cd1b-4cd3-914c-e3bfbc33e811"
let likedIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLikeIcon.png?alt=media&token=2c9382fc-0df7-4e31-8cbe-a06caded454a"
let likedIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLikeIconGrey.png?alt=media&token=5cf27b7a-9a20-4957-b6c0-7b485c4d8d4f"
let dislikedIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDislikeIcon.png?alt=media&token=7c1b777c-384c-4276-b47a-f3d4f7883aa7"
let dislikedIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDislikeIconGrey.png?alt=media&token=e1646dac-e19b-406b-8edb-78abab769c32"
let videoIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FVideoIcon.png?alt=media&token=2e6959a6-b00f-40ee-aec4-4780fd7c0ad1"
let videoIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FVideoIconGrey.png?alt=media&token=ac01b16c-0e8b-4ae6-a61d-b4e62fc0b374"
let privacyIcon =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPrivacyIcon.png?alt=media&token=b0c80a2b-fd2b-4d94-864c-7ad74ed6e22f";
let placeIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"
let friendsIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditorsIcon.png?alt=media&token=7259f4db-7b92-4c8c-893b-b5d9a0259c3d"
let friendsIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditorsIconGrey.png?alt=media&token=87917115-6039-484b-9a63-1a50baaa25ae"

let  dropdownIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDetail.png?alt=media&token=2945590b-6232-4f58-afbe-358781245f57";
let collapseIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCollapse.png?alt=media&token=0a19f67c-7fad-4e02-8449-20d7e13e615c";


var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);

class ExperienceEditor extends Component {

    state={
        changesMade:false,
        experienceRank:0,
        imageGallery:[],
        imagePreview:[],
        deletedImages:[],
        experienceText:EditorState.createEmpty(),
        taggedUsers:[],
        experiencePrivacy: "friendsFollowers",
        privacyOptions: ["private","friends","friendsFollowers","public"],
        durationValue:0,
        filteredPlans:[],
        planName:"",
        experienceTags:[],
        videoUrl:"",
        videoUrlNotValid:false,
        connectToPlan:false,
        showTaggedUsers: false,
        showVideoSection:false,
    }

    componentWillMount = () =>{
        document.body.style.overflow = "hidden"   
        if (this.props.experiencePlace!==null){
            this.setState({experiencePlace:this.props.experiencePlace}) 
            this.setState({placeSelected:true})
        } 
        if (this.props.editMode==="Create"){
            if (this.props.experiencePlan!==null){
                this.setState({planIsSelected: true,selectedPlan:this.props.experiencePlan, showPlanSelection:true})
                let startDate = new Date(this.props.experiencePlan.planDates.startDate);
                let endDate = new Date(this.props.experiencePlan.planDates.endDate);
                let planStartDate =  moment( startDate);
                let planEndDate =  moment(endDate);
                this.setState({planStartDate:planStartDate,planEndDate:planEndDate,experienceDate:planStartDate})
            }
            if (this.props.experienceDate!==null){
                this.setState({experienceDate:this.props.experienceDate})
            } 
            else{
                let experienceDate = moment(new Date())
                this.setState({experienceDate:experienceDate})
            }
            if (this.props.locationCountry!==undefined){
                if (this.props.locationsTranslator[this.props.locationCountry]!==undefined){
                    this.setState({placeCountrySelected:this.props.locationsTranslator[this.props.locationCountry]})
                }
            }
        }
        else if (this.props.experiencePlanID!==undefined){
            //if editing experience
            const index = this.props.userPlans.findIndex(plan => {
                return plan.planID === this.props.experiencePlanID;
              });
            if (index!==-1){
                let plan = this.props.userPlans[index]
                this.setState({planIsSelected: true,selectedPlan:plan, showPlanSelection:true})
                let startDate = new Date(plan.planDates.startDate);
                let endDate = new Date(plan.planDates.endDate);
                let planStartDate =  moment( startDate);
                let planEndDate =  moment(endDate);
                this.setState({planStartDate:planStartDate,planEndDate:planEndDate})
            }
                
        }
    }

    componentDidMount = () =>{
        if (this.props.editMode === "Edit"){
            this.setState({
                experienceRank:this.props.experienceRank,
                taggedUsers:this.props.taggedUsers,
                experiencePrivacy: this.props.experiencePrivacy,
                experienceDuration: this.props.experienceDuration,
                experienceTags:[...this.props.experienceTags],
            })
            let experienceDate=new Date(this.props.experienceDate)
            experienceDate = moment(experienceDate)
            this.setState({experienceDate:experienceDate})
            if (this.props.videoUrl!==undefined&&this.props.videoUrl!==""){
                this.setState({
                    videoUrl:this.props.videoUrl,
                    videoUrlNotValid:true,
                })
            }
            try {
                JSON.parse(this.props.experienceText);
                this.setState({experienceText: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.experienceText))),
                  jsonString: true
                  })
            } catch (e) {
              this.setState({experienceText:EditorState.createEmpty(), jsonString: true})
            }
            let imagePreview = [...this.props.imagePreview]
            let imageGallery = [...this.props.imageGallery]
            let imagesList = [];
            this.setState({imagePreview:imagePreview,imageGallery:imageGallery})
            if (this.props.imagePreview.length>0){
                imagesList= this.props.imagePreview;
                if (Object.keys(this.props.imagePreview[0]).includes("smallImage")){
                    imagesList = this.props.imagePreview.map(img=>{return img.smallImage});
                }
            }
            else{
                this.setState({imagePreview:[],imageGallery:[]})
            }
            this.setState({imagePreview:imagesList})
        }
    }

    componentDidUpdate = prevProps =>{
        if (prevProps.existingPlace!==this.props.existingPlace){
            this.setState({experiencePlace:this.props.existingPlace})
            if (this.props.existingPlace!==undefined){
                this.setState({placeSelected:true})
            }
        }
    }

    selectPlace = place =>{
        if (place.placeLocation!==undefined){
            this.props.getExistingPlaceData(this.state.placeCountrySelected.treeID, place.placeID)
            this.setState({placeSelected:true})
        }
        else{
            this.setState({showNewPlaceButton:true})
        }
       
    }


    backdropHandler = () =>{
        this.setState({showTransparentBackdrop:false,
            filteredPlans:[],
            planName:""
        })
    }
    
    onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }
     videoUrlHandler=event=>{
        let videoUrlNotValid;
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        videoUrlNotValid= !pattern.test(event.target.value);
        this.setState({ videoUrl:event.target.value,videoUrlNotValid:videoUrlNotValid,
          changesMade: true});

        let screen = "NEW_EXPERIENCE"
        if (this.props.editMode==="Edit"){
            screen = "UPDATE_EXPERIENCE"
        }
        this.props.writeAcitivityLog({
            activityKey:"SHARE_EXPERIENCE_UPDATE_VIDEO",
            userScreen:screen,
            validUrl:!videoUrlNotValid
        })
        
        
    }


    updateTaggedUsers = taggedUsers =>{
        this.setState({taggedUsers:taggedUsers, changesMade: true})
    }

    updateText = text =>{
        this.setState({experienceText:text, changesMade: true})

        let screen = "NEW_EXPERIENCE"
        if (this.props.editMode==="Edit"){
            screen = "UPDATE_EXPERIENCE"
        }
        this.props.writeAcitivityLog({
            activityKey:"SHARE_EXPERIENCE_UPDATE_TEXT",
            userScreen:screen
        })
    }

    updateImages = (imagePreview, imageGallery,deletedImages) => {
        this.setState({ imagePreview: imagePreview, imageGallery: imageGallery,deletedImages:deletedImages, imagesUploading:false, changesMade: true });
      let screen = "NEW_EXPERIENCE"
        if (this.props.editMode==="Edit"){
            screen = "UPDATE_EXPERIENCE"
        }
        this.props.writeAcitivityLog({
            activityKey:"SHARE_EXPERIENCE_UPDATE_IMAGES",
            userScreen:screen,
            experienceImagesNumber:imagePreview.length
        })
    };
      
      imageUploadHandler = (event) =>{
          this.refs.imageChild.uploadImages(event);
          this.setState({imagesUploading: true})
      }

    updateDurationNumber = e =>{
        let durationValue=e.currentTarget.value;
       this.setState({durationValue:durationValue, changesMade: true})

       let screen = "NEW_EXPERIENCE"
       if (this.props.editMode==="Edit"){
           screen = "UPDATE_EXPERIENCE"
       }
       this.props.writeAcitivityLog({
           activityKey:"SHARE_EXPERIENCE_UPDATE_DURATION",
           userScreen:screen
       })
    }

    updateExperienceTags = (tags, tagsInputField) => {
        
        this.setState({ experienceTags: tags, tagsInputField: tagsInputField, changesMade: true, categoryInfoFilled:true });

        let screen = "NEW_EXPERIENCE"
        if (this.props.editMode==="Edit"){
            screen = "UPDATE_EXPERIENCE"
        }
        this.props.writeAcitivityLog({
            activityKey:"SHARE_EXPERIENCE_UPDATE_TAGS",
            userScreen:screen,
            experienceTags:tags
        })
      };

      likedDislikedHandler = (value,scenario) =>{
        this.setState({experienceRank:value, changesMade:true})

        let screen = "NEW_EXPERIENCE"
        if (this.props.editMode==="Edit"){
            screen = "UPDATE_EXPERIENCE"
        }
        this.props.writeAcitivityLog({
            activityKey:scenario,
            userScreen:screen
        })
    }

    selectPlanHandler = (plan) =>{
        this.setState({planIsSelected: true,selectedPlan:plan,showTransparentBackdrop:false})
        let startDate = new Date(plan.planDates.startDate);
        let endDate = new Date(plan.planDates.endDate);
        let planStartDate =  moment( startDate);
        let planEndDate =  moment(endDate);
        this.setState({planStartDate:planStartDate,planEndDate:planEndDate,experienceDate:planStartDate,
            showSuggestions:false,planName:"",filteredPlans:[], changesMade: true})

        this.props.writeAcitivityLog({
            activityKey:"SHARE_EXPERIENCE_SELECT_PLAN",
            userScreen:"NEW_EXPERIENCE"
        })

        //add trip friends to the experience

    }

    unselectPlanHandler = () =>{
        this.setState({
            planIsSelected:false,
            selectedPlan:undefined, changesMade: true
        })

        this.props.writeAcitivityLog({
            activityKey:"SHARE_EXPERIENCE_UNSELECT_PLAN",
            userScreen:"NEW_EXPERIENCE"
        })
    }

      discardTransparent = () =>{
          this.setState({
            showTransparentBackdrop:false,
            showPrivacyOptions:false
          })
      }

      planInputHandler = async event => {
        let inputValue = event.target.value;
        //In case user pressed 'esc' key, clean the input field
        if (event.keyCode === 27) {
          this.setState({
            planName: "",
            showSuggestions: false,
            showTransparentBackdrop: false,
            filteredPlans: []
          });
          return;
        }
        //In case user pressed 'enter' key, add the tag to experience tags array
        //Use the selectTagSuggestion function for that
        if (event.key === "Enter") {
          //In case user pressed 'enter' with blank input, do nothing
          if (inputValue === "") return;
          if (this.state.filteredPlans.length > 0) {
            this.selectSearchSuggestion(this.state.filteredPlans[0]);
          }
          return;
        } else {
          //Get suggestions for user existing tags based in sub-input
          let filteredPlans = this.getPlanSuggestions(inputValue);
          this.setState({ planName: inputValue });
          //In case user entered some characters, the suggestions should be shown
          if (inputValue != "" && filteredPlans.length != 0) {
            this.setState({
              showSuggestions: true,
              showTransparentBackdrop: true,
              filteredPlans: filteredPlans
            });
    
            //If no suggetions exist based on entered value, don't show the suggestions
          } else if (filteredPlans.length === 0) {
            this.setState({
              showSuggestions: false,
              showTransparentBackdrop: false,
              filteredPlans: []
            });
          } else {
            //Input is blank
            this.setState({
              showSuggestions: false,
              showTransparentBackdrop: false,
              filteredPlans: []
            });
          }
        }
      };

      getPlanSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        
        return inputLength === 0
          ? []
          : this.props.userPlans.filter(
            plan =>
            plan.planName.toLowerCase().includes(inputValue)
            );
      };

     
      updateSelectedCountry = country =>{
        this.setState({placeCountrySelected:country})
        this.props.loadCountryPlaces(country.treeID)
      }

    discardCreation = () => {
        if (!this.state.changesMade){
            this.props.discard();

            
          }else{ 
            this.setState({
              confirmMessage: this.props.systemText.extraModals.confirmationModal.message[this.props.userLanguage],
              primaryBtn: this.props.systemText.extraModals.confirmationModal.stay[this.props.userLanguage],
              secondaryBtn: this.props.systemText.extraModals.confirmationModal.leave[this.props.userLanguage],
              confirmMode: true,
              showBackdrop: true
            });
        } 
      };

      discardDiscardHandler = () => {
        this.setState({
            confirmMode: false,
            showBackdrop: false
        });
    };
        
    discardChangesHandler = () => {
        this.props.discard();
    };

        

          saveHandler = async () =>{
            let date = new Date();
            let timestamp = date.getTime();
            let experienceID
            let experiencePlaceID
            let experiencePlaceName
            let experienceCategory = []
            let experienceLocation = [];
            let experienceStats = {
                viewed: { count: 0, users: [] },
                inspired: { count: 0, users: [] }
            }
            let owner = {
                ownerID: this.props.user.userID,
                ownerName: this.props.user.displayName,
                ownerPhoto: this.props.user.photoUrl,
                ownerEmail: this.props.user.userEmail
              };
            let experienceCreationDate = timestamp
            if (this.props.editMode==="Create"){
                experienceID = this.props.user.userID+"_"+timestamp;
                experiencePlaceID = this.state.experiencePlace.placeID;
                experiencePlaceName=this.state.experiencePlace.placeName;
                experienceCategory = this.state.experiencePlace.placeCategory;
                experienceLocation = this.state.experiencePlace.placeLocation.locationTree.locationTreeIDs;
            }
            else{
                experienceID = this.props.experienceID
                experiencePlaceID = this.props.experiencePlaceID
                experiencePlaceName = this.props.experiencePlaceName
                experienceCategory = this.props.experienceCategory
                experienceLocation = this.props.experienceLocation
                experienceStats = this.props.experienceStats
                owner = this.props.owner
                experienceCreationDate = this.props.experienceCreationDate
            } 
           
            let experienceDate=new Date(this.state.experienceDate.toDate().getTime()).getTime(); 
            let experienceText = JSON.stringify(convertToRaw(this.state.experienceText.getCurrentContent()));
            let experienceRank = this.state.experienceRank;
            let imagePreview = [...this.state.imagePreview]
            let imageGallery = [...this.state.imageGallery]
            let taggedUsers = [...this.state.taggedUsers]
            
            
            let experienceTags = [];
            for (let i=0 ; i<this.state.experienceTags.length ; i++){
                await this.refs.detectInputLanguage.detectLanguage(this.state.experienceTags[i]).then(result => {
                let newTag={};
                newTag[result]=this.state.experienceTags[i]
                experienceTags.push(newTag)
                 });
            }
            let experiencePrivacy = this.state.experiencePrivacy;
            let experienceDuration=this.state.durationValue;
            
           
            let experience={
                experienceCreationDate:experienceCreationDate,
                experienceLastModified:timestamp,
                experienceID:experienceID,
                experiencePlaceID:experiencePlaceID,
                experiencePlaceName:experiencePlaceName,
                experienceLocation:experienceLocation,
                experienceCategory:experienceCategory,
                experienceDate:experienceDate,
                experienceText:experienceText,
                experienceRank:experienceRank,
                experienceStats:experienceStats,
               
                imagePreview:imagePreview,
                imageGallery:imageGallery,
                experienceTags:experienceTags,
                taggedUsers:taggedUsers,
                experienceDuration:experienceDuration,
                owner:owner,
                experiencePrivacy:experiencePrivacy

            }
            if (this.props.editMode==="Create"){
                if (this.state.planIsSelected){
            
                    experience.experiencePlanID=this.state.selectedPlan.planID;
                    experience.experiencePlanName=this.state.selectedPlan.planName
                }
            }
            
            else if (this.props.experiencePlanID!==undefined){
                experience.experiencePlanID=this.props.experiencePlanID;
                experience.experiencePlanName=this.props.experiencePlanName
            }
            if (this.state.videoUrl!==""&&!this.state.videoUrlNotValid){
                experience.videoUrl = this.state.videoUrl
            }
            if (this.props.editMode === "Edit"){
                experience.oldRank=this.props.experienceRank;
                experience.deletedImages = [...this.state.deletedImages]

                let removedTaggedUsers = [];
                let taggedUsersIDs = taggedUsers.map(user=>{return user.userID})
                for (let i=0 ; i<this.props.taggedUsers.length ; i++){
                    if (!taggedUsersIDs.includes(this.props.taggedUsers[i].userID)){
                        removedTaggedUsers.push(this.props.taggedUsers[i].userID)
                    }
                }
                experience.removedTaggedUsers = removedTaggedUsers;
            }
           
            
            this.props.save(experience);
            this.props.discard();
            //console.log(experience)
        }

        privacyHandler = (privacy) => {
            this.setState({experiencePrivacy:privacy,showPrivacyOptions:false,showTransparentBackdrop:false,changesMade:true})

            let screen = "NEW_EXPERIENCE"
            if (this.props.editMode==="Edit"){
                screen = "UPDATE_EXPERIENCE"
            }
            this.props.writeAcitivityLog({
                activityKey:"SHARE_EXPERIENCE_CHANGE_PRIVACY",
                userScreen:screen,
                selectedPrivacy:privacy
            })
        }

        renderPrivacy = () =>{
            let privacyIconClass = "PrivacyIcon"
            if (this.props.RTL){
                privacyIconClass = "PrivacyIconRTL"
            }
            return (<div className={classes.ExperiencePrivacy}>
                      <div className={classes.SelectedPrivacy} onClick={()=>{this.setState({showPrivacyOptions:true,showTransparentBackdrop:true})}}>
                        <img src={privacyIcon} alt="" className={classes[privacyIconClass]}/>
                        {this.props.systemText.experience.createExperience.privacy[this.state.experiencePrivacy][this.props.userLanguage]}</div>
                      {this.state.showPrivacyOptions?(
                      <div className={classes.PrivacyOptions}>
                        <div className={classes.PrivacyOption} 
                        onClick={()=>{
                            this.privacyHandler("public")
                            }}>
                            {this.props.systemText.experience.createExperience.privacy.public[this.props.userLanguage]}
                          </div>
                        <div className={classes.PrivacyOption} 
                        onClick={()=>{
                            this.privacyHandler("friendsFollowers")
                            }}>
                              {this.props.systemText.experience.createExperience.privacy.friendsFollowers[this.props.userLanguage]}
                          </div>
                        <div className={classes.PrivacyOption} 
                        onClick={()=>{
                            this.privacyHandler("friends")
                            }}>
                              {this.props.systemText.experience.createExperience.privacy.friends[this.props.userLanguage]}
                          </div>
                        <div className={classes.PrivacyOption} 
                        onClick={()=>{
                            this.privacyHandler("private")
                            }}>
                              {this.props.systemText.experience.createExperience.privacy.private[this.props.userLanguage]}
                          </div>
                      </div>):null}
            </div>)
          }

    renderTopSection = () => {
        let title = ""
        let buttonName = "";
        switch(this.props.editMode){
            case "Create":{
                title = this.props.systemText.experience.createExperience.title[this.props.userLanguage];
                buttonName = this.props.systemText.misc.buttons.createButton[this.props.userLanguage];
                break;
            }   
            case "Edit":{
                title = this.props.systemText.experience.createExperience.editTitle[this.props.userLanguage];
                buttonName = this.props.systemText.misc.buttons.updateButton[this.props.userLanguage];
                break;
            }
            default: return null;
        }
        let saveReady = false
        if (
            this.state.experienceDate!==undefined&&
            (
                this.state.experienceText.getCurrentContent().hasText()||this.state.imageGallery.length>0
            )){
                if (this.props.editMode === "Create"){
                    if (this.state.experiencePlace!==undefined) saveReady=true
                }
                else if (this.state.changesMade){
                    saveReady=true
                }  
            }
        return (
          <div>
            <ModalTopSection
              title={title}
              discard={this.discardCreation}
              saveReady={saveReady}
              showButton={true}
              buttonText={buttonName}
              clicked={this.saveHandler}
              saveButton={true}
              RTL = {this.props.RTL}
              modalView={true}
            />

          </div>
        );
      };

      renderDatesSection = () =>{
          return(<div className={classes.DatesSection}>
             
               {this.renderDate()}
          </div>)
      }

      renderBottomSection = () =>{
        return (<div className={classes.BottomSection}>
            {this.state.showTaggedUsers?(
                 <img
                 src={friendsIcon}
                 alt=""
                 onClick={()=>{this.setState({showTaggedUsers:false})}}
                 className={classes.BottomIcon}
                 />
            )
            :(
                <img
                src={friendsIconGrey}
                alt=""
                onClick={()=>{this.setState({showFriends:true,showTaggedUsers:true,showBackdrop:true})}}
                className={classes.BottomIcon}
                /> 
            )}
           
               {this.state.showVideoSection?(
                  <img src={videoIcon} alt="" className={classes.VideoIcon} onClick={()=>{this.setState({showVideoSection: false})}}/>
              ):
              (
                  <img src={videoIconGrey} alt="" className={classes.VideoIcon} onClick={()=>{this.setState({showVideoSection: true})}}/>
              )}
      </div>)
      }
    
    renderBody = () =>{
        return (<div className={classes.Body}>
            <div className={classes.SelectionsSection}>
                {this.renderPlaceSelection()}
                {this.renderPlanSelection()} 
            </div>
           
           
            <div className={classes.InfoSection}>
               
                 {this.renderTopDetails()}
                 {this.renderRank()}
                 {this.renderDuration()}
                 {this.renderTaggedUsers()}
            </div>

            {this.renderImagesSection()}
            {this.renderExperienceVideo()}
            <div className={classes.TextInputSection}>
           
            {this.renderTextSection()}
            {this.renderTagsSection()}
            </div>
            
        </div>)
    }

    renderTaggedUsers = () =>{
        if (this.state.showTaggedUsers){
            let toolsIconClass = "UsersIcon"
            let tagMessageClass = "TagMessage"
            if (this.props.RTL){
                toolsIconClass = "UsersIconRTL"
                tagMessageClass ="TagMessageRTL"
            }
            return(
                <div className={classes.TaggedUsers}>
                    <div className={classes[tagMessageClass]}>
                    {this.props.systemText.experience.createExperience.tagFriends[this.props.userLanguage]}
                    </div>
                    <img alt="" src={friendsIcon} 
                className={classes[toolsIconClass]} 
                  onClick={()=>{this.setState({showFriends:true,showBackdrop:true})
                  let screen = "NEW_EXPERIENCE"
                  if (this.props.editMode==="Edit"){
                      screen = "UPDATE_EXPERIENCE"
                  }
                 this.props.writeAcitivityLog({
                      activityKey:"EXPERIENCE_UPDATE_FRIENDS",
                      userScreen:screen
                    }) 
                  }}/>
                  {this.state.taggedUsers.map((user,index)=>(
                      <div className={classes.User}>
                           <img key={index} className={classes.UserImage} src = {user.photoUrl}/>
                      </div>
                  ))}
                </div>
            )
        }
       
    }

    renderPlanSelectionDropdownIcon = () =>{
        if (this.props.userPlans.length>0){
            let dropdownButtonClass = "DropdownButton"
            if (this.props.RTL){
              dropdownButtonClass = "DropdownButtonRTL"
            }
            if (this.state.filteredPlans.length === 0){
                return(
                    <img alt="" className={classes[dropdownButtonClass]} 
                        onClick={()=>{this.setState({filteredPlans:this.props.userPlans,showTransparentBackdrop:true})}}
                        src={dropdownIcon}/>
                )
            }
            else if(this.state.planName===""){
                return(
                    <img alt="" className={classes[dropdownButtonClass]} 
                        onClick={this.backdropHandler}
                        src={collapseIcon}/>
                )
            }
        }
        
    }

    renderPlanSelection = () =>{
        if (this.props.editMode==="Create"){
            if (this.state.planIsSelected&&this.state.selectedPlan!==undefined && this.state.selectedPlan!==null){
                return(<div className={classes.PlanTop}>
                    {this.props.experiencePlan!==undefined && this.props.experiencePlan!==null?(
                        null
                    ):(
                        <div className={classes.ResetPlace} onClick={this.unselectPlanHandler}>
                            {this.props.systemText.extraModals.addToPlan.resetPlan[this.props.userLanguage]}
                        </div>
                    )}
                    
                    <div className={classes.SelectedPlan}>
                    <PlansWall
                            plansList={[this.state.selectedPlan]}
                            viewHandler={()=>{return null}}
                            selectedContentWall={"AddToPlan"}
                            hideTools={true}
                            viewOnly={true}

                            RTL = {this.props.RTL}
                            userLanguage={this.props.userLanguage}
                            systemText={this.props.systemText}
                        />
                        </div>
                
                
                </div>)
            }
            else{
                if (this.state.connectToPlan){
                    return(
                        <div className={classes.PlanSelectionSection}>
                              <TransparentBackdrop show={this.state.showTransparentBackdrop} clicked={this.backdropHandler}/>
                            <div className={classes.PlanSelectSection}>
                                <input
                                    value={this.state.planName}
                                    onChange={this.planInputHandler}
                                    onKeyDown={this.planInputHandler}
                                    onKeyPress={this.planInputHandler}
                                    placeholder= {this.props.systemText.extraModals.addToPlan.selectPlanPlaceholder[this.props.userLanguage]}
                                    className={classes.InputText}
                                    onBlur={this.blurHandler}
                                />
                                 {this.renderPlanSelectionDropdownIcon()}
                            </div>
                            {this.state.filteredPlans.length > 0 ? (
                                <div className={classes.Suggestions}>
                                <PlansWall
                                    plansList={this.state.filteredPlans}
                                    viewHandler={this.selectPlanHandler}
                                    selectedContentWall={"SelectPlan"}
                                    hideTools={true}
                                    viewOnly={true}
        
                                    RTL = {this.props.RTL}
                                    userLanguage={this.props.userLanguage}
                                    systemText={this.props.systemText}
                                />
                                </div>
                            ) : null}
                            <div className={classes.ConnectToTripButton} onClick={()=>{this.setState({connectToPlan:false})}}>
                            {this.props.systemText.experience.createExperience.hideConnectToTrip[this.props.userLanguage]}
                            </div>
                        </div>)
                }
                else{
                    return(
                        <div className={classes.PlanSelectionSection}>
                            <div className={classes.ConnectToTripButton} onClick={()=>{this.setState({connectToPlan:true})}}>
                            {this.props.systemText.experience.createExperience.addTrip[this.props.userLanguage]}
                            </div>
                        </div>
                    )
                }
                
            }
        }       
    } 

    renderPlaceSelection = () =>{
        if (this.props.editMode==="Edit"){
            let iconClass = "Icon"
            if (this.props.RTL){
                iconClass = "IconRTL"
            }
            let placeName="";
            //get the relevant language or at least any language name
            if (this.props.experiencePlaceName[this.props.userLanguage]!==undefined){
            placeName=this.props.experiencePlaceName[this.props.userLanguage]
            }
            else if(this.props.experiencePlaceName["English"]!==undefined){
            placeName=this.props.experiencePlaceName["English"]
            }
            else{
            let placesNames =  Object.keys(this.props.experiencePlaceName);
            placeName=this.props.experiencePlaceName[placesNames[0]]
            }
            return(
                <div className={classes.ExperiencePlace} >
                    <img src = {placeIcon} alt="" className={classes[iconClass]}/>
                    <div className={classes.PlaceName} onClick={()=>{this.props.viewExperiencePlace(this.props.experiencePlaceID,this.props.experienceLocation[0])}}>
                        {placeName}
                    </div>
                </div>
            )
        }
        else{
            if (this.state.experiencePlace!==undefined&&this.state.placeSelected){
                let imagePreview ={url:""}
                if (Array.isArray(this.state.experiencePlace.imagePreview)){
                    imagePreview = this.state.experiencePlace.imagePreview[0]
                }
                else{
                    imagePreview = this.state.experiencePlace.imagePreview
                }
                return (
                <div className={classes.PlaceTop}>
                    
                     <div className={classes.PlaceSelection}>
                     {this.props.experiencePlace===null?(
                        <div className={classes.ResetPlace} onClick={()=>{this.setState({experiencePlace:undefined,placeSelected:false})}}>
                            {this.props.systemText.experience.createExperience.resetPlace[this.props.userLanguage]}
                        </div>
                    ):null}
                    <div style={{width:"100%"}} 
                    onClick={()=>{this.props.viewExperiencePlace(this.state.experiencePlace.placeID,this.state.experiencePlace.placeLocation.locationTree.placeCountry)}}>
                        <PlanPlaceItem
                        placeID={this.state.experiencePlace.placeID}
                        placeName={this.state.experiencePlace.placeName}
                        placeCategory={this.state.experiencePlace.placeCategory}
                        imagePreview={imagePreview}
                        placeLocation = {this.state.experiencePlace.placeLocation}
                        placeStatus = {this.state.experiencePlace.placeStatus}
                        addToPlan= {true}
    
                        categoriesTranslator = {this.props.categoriesTranslator}
                        locationsTranslator = {this.props.locationsTranslator}
                        RTL = {this.props.RTL}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                    />
                    </div>
                   
                    </div>
                   
                </div>)
            }
            else{
                let placeName=""
                if (this.state.experiencePlace!==undefined){
                    placeName=this.state.experiencePlace.placeName[this.props.userLanguage]
                }
                return (
                    <div className={classes.PlaceTop}>
                         {this.renderPlaceCountrySelection()}
                         {this.state.placeCountrySelected?(
                            <div className={classes.PlaceSelection}>
                                <PlaceSelection
                                    
                                    placeholderMessage= {this.props.systemText.experience.createExperience.selectPlacePlaceholder[this.props.userLanguage]}
                                    places={this.props.slimSystemPlaces[this.state.placeCountrySelected.treeID]}
                                    placeName={placeName}
                                    slimPlaces = {true}
                                    userLanguage={this.props.userLanguage}
                                    required = {true}
    
                                    detectInputLanguage = {this.props.detectInputLanguage}
                                    inputLanguage = {this.props.inputLanguage}
                                    RTL = {this.props.RTL}
                                    systemText={this.props.systemText}
                                    updatePlace={this.selectPlace}
                                />
                            </div>
                         ):null} 
                         <div className={classes.PlaceNotExist} onClick={()=>{this.props.createQuickPlaceHandler()}}>
                             {this.props.systemText.experience.createExperience.placeNotExistMessage[this.props.userLanguage]}
                         </div>
                    </div>)  
            }
        }
    }

    renderPlaceCountrySelection = () =>{
        let countriesList = [];
        for (let i=0;i<this.props.countriesToExplore.length ; i++){
            let country = JSON.parse(JSON.stringify(this.props.locationsTree[this.props.countriesToExplore[i]]))
            delete country.regions;
            delete country.cities; 
            countriesList.push(country)
        }
        let currentSelection = ""
        if (this.state.placeCountrySelected){
            currentSelection = this.state.placeCountrySelected[this.props.userLanguage]
        }
        return(
            <div className={classes.CountrySelection}>
                <div className={classes.Message}>
                {this.props.systemText.experience.createExperience.selectPlaceCountry[this.props.userLanguage]}
                </div>
              
                    <OptionSelection
                        currentSelection={currentSelection}
                        optionsList={countriesList}
                        updateSelectedOption={this.updateSelectedCountry}
                        placeholder={""}
                        valueRequired={currentSelection===""}
                        onlyFromList={true}
                        userLanguage={this.props.userLanguage}
                        RTL = {this.props.RTL}
                        inputLanguage = {this.props.inputLanguage}
                        detectInputLanguage = {this.props.detectInputLanguage}
                 /> 
            </div>
        )
    }

    renderTopDetails = () =>{
        return (<div className={classes.TopDetails}>
           
            {this.renderDatesSection()}
            {this.renderPrivacy()}
        </div>)
    }

    renderDuration = () =>{
        return(<div className={classes.Duration}>
            <div className={classes.DurationTitle}>
                {this.props.systemText.experience.createExperience.durationTitlePrefix[this.props.userLanguage]}</div>
                <input
                    type="number"
                    value={this.state.durationValue}
                    onChange={this.updateDurationNumber}
                    className={classes.NumberValue}
                />
        </div>)
    }

    

    renderRank= () =>{
        return(
            <div className={classes.Rank}>
                 {this.props.systemText.experience.createExperience.likedIt[this.props.userLanguage]}
                {this.state.experienceRank===1?(
                    <img src={likedIcon} alt="" className={classes.SelectedRankIcon} onClick={()=>{
                        this.likedDislikedHandler(0,"SHARE_EXPERIENCE_LIKED_BUTTON_UNSELECT")
                    }}/>
                ):(
                    <img src={likedIconGrey} alt="" className={classes.RankIcon} onClick={()=>{
                        this.likedDislikedHandler(1,"SHARE_EXPERIENCE_LIKED_BUTTON_SELECT")
                    }}/>
                )}
                 {this.state.experienceRank===-1?(
                    <img src={dislikedIcon} alt="" className={classes.SelectedRankIcon} onClick={()=>{
                        this.likedDislikedHandler(0,"SHARE_EXPERIENCE_DISLIKE_BUTTON_UNSELECT")
                    }}/>
                ):(
                    <img src={dislikedIconGrey} alt="" className={classes.RankIcon} onClick={()=>{
                        this.likedDislikedHandler(-1,"SHARE_EXPERIENCE_DISLIKE_BUTTON_SELECT")
                      }}/>
                )}
            </div>
        )
    }

    renderDate=()=>{
        let orientation="horizontal"
        /* if (isMobile){
          orientation="vertical"
        } */
        let isRTL=true
        let direction="ltr"
        let anchorDirection ="left"
        if (this.props.userLanguage==="Hebrew"){
            direction="rtl"
            isRTL=false;
            anchorDirection="right"
        }
        let dateClass = "Date"
        if (this.state.experienceDate===undefined){
            dateClass = "EmptyDate"
        }
        return(
            <div className={classes[dateClass]}>
            <SingleDatePicker
            placeholder={this.props.systemText.experience.createExperience.setDate[this.props.userLanguage]}
            date={this.state.experienceDate} // momentPropTypes.momentObj or null
            onDateChange={date => this.setState({ experienceDate:date,changesMade: true })} // PropTypes.func.isRequired
            focused={this.state.focused} // PropTypes.bool
            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
            id="experience" // PropTypes.string.isRequired,
            orientation={orientation} 
            verticalHeight={568}
              horizontalMargin={0}
              noBorder={true}
              small={true}
              numberOfMonths={1}
              anchorDirection={anchorDirection}
              isOutsideRange={(day)=>{if (!this.state.planIsSelected){
                return  isInclusivelyAfterDay(day, moment().add(1,'days'))
                }
                else{
            
                if(this.state.planEndDate.diff(day)>=0&&
                day.diff(this.state.planStartDate)>=0){
                    return false
                }
                else return true
                }}}
                isRTL
            /></div>
        )
    }
    
    

    renderExperienceVideo = () =>{
        if (this.state.showVideoSection){
            let videoIconClass = "VideoIcon"
           if (this.props.RTL){
            videoIconClass = "VideoIconRTL"
           }
            return (
                <div className={classes.VideoSection}>
                    <div className={classes.VideoInputSection}>
                        {this.state.videoUrl!==""&&!this.state.videoUrlNotValid?(
                            <img src={videoIcon} alt="" className={classes[videoIconClass]}/>
                        ):(
                            <img src={videoIconGrey} alt="" className={classes[videoIconClass]}/>
                        )}
                        <input 
                         ref={(input) => { this.videoInput = input }} 
                        className={classes.VideoUrlInput}
                        value={this.state.videoUrl}
                        placeholder={this.props.systemText.experience.createExperience.enterVideoUrl[this.props.userLanguage]}
                        onChange={this.videoUrlHandler}/>
                    </div>
                    {this.state.videoUrlNotValid?(
                        <div className={classes.UrlNotValid}>
                            {this.props.systemText.experience.createExperience.videoUrlNotValid[this.props.userLanguage]}</div>
                    ):null}
                    {this.state.videoUrl!==""&&!this.state.videoUrlNotValid?(
   
                        <ReactPlayer 
                        config={{
                            youtube: {
                                playerVars: {rel: 0, showinfo: 0, ecver: 2}
                            },
                            facebook: {
                              appId: '12345'
                            }
                          }}
                        url={this.state.videoUrl} className={classes.VideoBox} controls={true} /> 

                   ):null}
                </div>
            )
        }
           
    }
    renderImagesSection = () =>{
        return(
            <div className={classes.ImageSection}>
            <div className={classes.ImageContent}>
                <div className={classes.ImageButton} >
                    <div className={classes.ImageUploadWrapper}>
                        <img src={uploadIcon} className={classes.ImageIcon} alt="" />
                        <input
                        type="file"
                        onChange={this.imageUploadHandler}
                        multiple={true}
                        accept="image/*"
                        className={classes.FileInput}
                    />
                    <div className={classes.ImageText}>{this.props.systemText.place.createPlace.uplodaImageButton[this.props.userLanguage]}</div>
                </div>
                </div>               
                </div>
                <div className={classes.Images}>
                <ImageUploader
                    ref="imageChild"
                    discard={this.discardPhotoHandler}
                    update={this.updateImages}
                    imagePreview={this.state.imagePreview}
                    imageGallery={this.state.imageGallery}
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}

                    handleSaving = {value=>{this.setState({imageProcess:value})}}
                    />
                </div>
            </div>
        )
    }

    

    styleMap = (contentBlock) => {
        return classes['public-DraftStyleDefault-block'];
    }
    focus(){
        this.refs.editor.focus()
    }

    renderTagsSection = () =>{
        return(
            <TaggingSection
             selectedTags={this.state.experienceTags}
             userTags={[]}
             updateTags={this.updateExperienceTags}
             placeholder= {this.props.systemText.experience.createExperience.addTagsPlaceholder[this.props.userLanguage]}

             RTL = {this.props.RTL}
             userLanguage={this.props.userLanguage}
             systemText={this.props.systemText}
            />
         )
    }

    renderTextSection = () =>{
        return (
            <div className={classes.TextSection} onClick={this.focus.bind(this)}>
            <Editor
                placeholder= {this.props.systemText.experience.createExperience.textPlaceholder[this.props.userLanguage]}
                blockStyleFn={this.styleMap}
                className={classes.TextInput}
                editorState={this.state.experienceText}
                onChange={this.updateText}
                resize="none"
                ref="editor"
                />
            </div>
        );
    }

    renderConfirmModal = () => {
        if (this.state.confirmMode) {
          return (
            <ConfirmModal
            header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
            primaryAction={this.discardDiscardHandler}
            secondaryAction={this.discardChangesHandler}
            message={this.state.confirmMessage}
            primaryBtn={this.state.primaryBtn}
            secondaryBtn={this.state.secondaryBtn}
            primaryStyle = {"PositiveButton"}
            secondaryStyle = {"DiscardButton"}
            RTL = {this.props.RTL}

          />
          );
        }
      };

      renderFriendsModal = () =>{
          if (this.state.showFriends){
              let isOwner = false;
              if (this.props.editMode==="Create"){
                isOwner = true
              }
              else if (this.props.owner!==undefined){
                if (this.props.user.userID===this.props.owner.ownerID){
                    isOwner = true
                }
              }
            return(
                <PlanEditors 
                title={this.props.systemText.users.experienceFriends[this.props.userLanguage]}
                ownerID={this.props.user.userID}
                isOwner={isOwner}
                updatePlanEditors={this.updateTaggedUsers}
                discard={()=>{this.setState({showFriends:false,showBackdrop:false})
                /* this.props.writeAcitivityLog({
                    activityKey:"TRIP_EDITORS_CLOSE",
                    userScreen:"VIEW_TRIP"
                }) */
                }}
                friends ={this.state.taggedUsers}
                myFriends={this.props.myFriends}
                experienceTagging = {true}

                writeAcitivityLog = {this.props.writeAcitivityLog}

                RTL = {this.props.RTL}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}/>
            )
        }
      }

      detectInputLanguage = () =>{
        return (<DetectInputLanguage
            ref="detectInputLanguage"
          />)
      }
   
    render() {
        let RTL = ""
        if (this.props.RTL){
            RTL = "RTL"
        }
        return (
            <div className={[classes.Modal,classes[RTL]].join(" ")}>
            <TransparentBackdrop show={this.state.showTransparentBackdrop} clicked={this.discardTransparent}/>
            <Backdrop show={this.state.showBackdrop} />
            {this.detectInputLanguage()}
            {this.renderConfirmModal()}
            {this.renderFriendsModal()}
            {this.renderTopSection()}
            {this.renderBody()}
            {this.renderBottomSection()}
        </div>)
    }
}
export default ExperienceEditor;