import React from "react";
import classes from "./IconTab.module.css";

const iconTab = props => {

  const renderBody = () =>{
    let bodyClass = "Body"
    let iconClass = "Icon"
    let selectedClass = ""
    let iconStyle = "Icon"+props.style
    if (props.RTL){
      bodyClass = "BodyRTL"
      iconClass = "IconRTL"
    }
    if (props.selected) {
        selectedClass = "Selected"
    }
    return(
      <div className={[classes[bodyClass],classes[props.style],classes[selectedClass]].join(" ")}
      onClick={props.clicked}
      >
          <img src={props.icon} alt="" className={[classes[iconClass],classes[iconStyle]].join(" ")}/>
            {props.text!==undefined?(
                <div>{props.text}</div>):
                (null)
            }
      </div>
    )
  }
  return (
    <div>
        {renderBody()}
    </div>
  );
};

export default iconTab;
