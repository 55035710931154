import React , { useState}  from "react";

import classes from "./CreateMenu.module.css";
import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"

let experienceIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FExperienceIconGrey.png?alt=media&token=e54dfc0d-7cef-4432-81e6-fd4643a8a75b";
let planIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTripIconGrey.png?alt=media&token=eb61b497-5906-4b11-bd33-413e77cc4e45";
let placeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIconGrey.png?alt=media&token=14eefd33-8dd4-4473-aa17-babc75342117";

let experienceIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FExperienceIconFade.png?alt=media&token=6a5e32d0-7e5b-421d-99e9-2ffb12c25909"
let placeIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIconFade.png?alt=media&token=87402b19-e7ff-4fdb-9432-e2666857f780"

const CreateMenu = props => {
  const [showTripSelection, showSelection] = useState(false);

const renderNewOptions = () => {
  let newOptTextClass = "NewOptText"

  if (props.RTL){
    newOptTextClass = "NewOptTextRTL"
  }
    return (
        <div className={classes.NewOptions}>
           <TransparentBackdrop show={showTripSelection} clicked={()=>{showSelection(false)}} />
            {renderTripCreationSelections()}
            {props.loggedUser?(
                <div
                 className={classes.NewOpt}
                 onClick={()=>{props.createNewHandler("Experience")}}
               >
                 <div className={classes.NewOptImage}>
                   <img src={experienceIcon} alt="" className={classes.Icon} />
                 </div>
                 <div className={classes[newOptTextClass]}>
                   <div className={classes.NewOptTitle}> 
                     {props.systemText.extraModals.createMenu.experience.mainMessage[props.userLanguage]}
                   </div>
     
                   <div className={classes.NewOptSubtitle}>
                   {props.systemText.extraModals.createMenu.experience.subMessage[props.userLanguage]}
                   </div>
                 </div>
               </div>   
            ):(
                <div
                className={classes.NewOptGrey}
                onClick={props.loginHandler}
              >
                <div className={classes.NewOptImage}>
                  <img src={experienceIconFade} alt="" className={classes.Icon} />
                </div>
                <div className={classes[newOptTextClass]}>
                  <div className={classes.NewOptTitle}> 
                    {props.systemText.extraModals.createMenu.experience.mainMessage[props.userLanguage]}
                  </div>
    
                  <div className={classes.NewOptSubtitle}>
                  {props.systemText.extraModals.createMenu.experience.subMessage[props.userLanguage]}
                  </div>
                </div>
              </div>   
            )}
         
         
          
           <div className={classes.NewOpt} onClick={()=>{showSelection(true)}}>
              <div className={classes.NewOptImage}>
                <img src={planIcon} alt="" className={classes.Icon} />
              </div>
              <div className={classes[newOptTextClass]}>
                <div className={classes.NewOptTitle}>
                {props.systemText.extraModals.createMenu.plan.mainMessage[props.userLanguage]}
                </div>

                <div className={classes.NewOptSubtitle}>
                {props.systemText.extraModals.createMenu.plan.subMessage[props.userLanguage]}
                </div>
              </div>
            </div>  
            {props.loggedUser?(
              <div className={classes.NewOpt} onClick={()=>{props.createNewHandler("Place")}}>
                <div className={classes.NewOptImage}>
                  <img src={placeIcon} alt="" className={classes.Icon} />
                </div>
                <div className={classes[newOptTextClass]}>
                  <div className={classes.NewOptTitle}> 
                  {props.systemText.extraModals.createMenu.place.mainMessage[props.userLanguage]}
                  </div>
    
                  <div className={classes.NewOptSubtitle}>
                  {props.systemText.extraModals.createMenu.place.subMessage[props.userLanguage]}
    
                  </div>
                </div>
              </div>
            ):(
              <div className={classes.NewOptGrey} onClick={props.loginHandler}>
                <div className={classes.NewOptImage}>
                  <img src={placeIconFade} alt="" className={classes.Icon} />
                </div>
                <div className={classes[newOptTextClass]}>
                  <div className={classes.NewOptTitle}> 
                  {props.systemText.extraModals.createMenu.place.mainMessage[props.userLanguage]}
                  </div>

                  <div className={classes.NewOptSubtitle}>
                  {props.systemText.extraModals.createMenu.place.subMessage[props.userLanguage]}

                  </div>
                </div>
              </div>
            )}
            
        </div>
      );     
  };

  const renderTripCreationSelections = () =>{
    if (showTripSelection){
      return(
        <div className={classes.CreateTripSelection}>
          <div className={classes.SelectionTitle}> 
               {props.systemText.extraModals.createMenu.plan.selectionTitle[props.userLanguage]}
              </div>
              <div className={classes.SelectionOptions}>
                  <div className={classes.CreationOption} onClick={()=>{props.createNewHandler("Trip")}}>
                    {props.systemText.extraModals.createMenu.plan.createFromScratch[props.userLanguage]}
                </div>
                <div className={classes.CreationOption}  onClick={props.createTemplateTrips}>
                    {props.systemText.extraModals.createMenu.plan.generateSuggestions[props.userLanguage]}
                </div>
              </div>
           
        </div>
      )
    }
  }


return(
    <div className={[classes.CreateMenuBody, classes[props.RTLBody]].join(" ")}>
    
        {renderNewOptions()}
    </div>
)
}
export default CreateMenu;