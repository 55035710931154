import React, { Component } from "react";
import classes from "./TripAlbumImage.module.css";

class TripAlbumImage extends Component {
    state={
        imageRef:React.createRef(),
    }


    componentDidMount = () =>{
        if (this.props.imageViewerIndex===this.props.index){
            setTimeout(() => {
                this.state.imageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest'});
            }, 300);
          
          }
    }

    componentDidUpdate = (prevProps) =>{
        if (prevProps.imageViewerIndex!==this.props.imageViewerIndex){
            if (this.props.imageViewerIndex===this.props.index){
                setTimeout(() => {
                    this.state.imageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest'});
                }, 300);
              }
        }
      }

    render(){
        let imageClass = "Image"
        if (this.props.scrollImage){
            imageClass = "ScrollImage"
        }
        return(
        <img className={classes[imageClass]} alt="" src={this.props.url}
        onClick={()=>{this.props.imageClicked(this.props.index)}}
        ref={this.state.imageRef}/>)
    }
}

export default TripAlbumImage;