

import React, { Component } from "react";
import classes from "./PlansWall.module.css"
import Plan from "./Plan";

import {isMobile} from 'react-device-detect';

class PlansWall extends Component {
state={gettingData:false,currentList:[],count:0,
  plansList:[...this.props.plansList]}
componentWillMount=()=>{
  if (isMobile){
    this.setState({maxAddedPlans:5});
  }
  else{
    this.setState({maxAddedPlans:9});
  }
  this.getMoreData()
  

}


getMoreData = async () => {
  return new Promise(async (resolve, reject) => {
  if (this.state.currentList.length === this.state.plansList.length) {
    this.setState({gettingData:false})
    return
  }
  let count=this.state.count;

  let addedObject=0;
  let maxAddedPlans=0;
  if (isMobile){
    maxAddedPlans=5;
  }
  else{
    maxAddedPlans=12;
  }
  let currentList = this.state.currentList;
  while (addedObject<maxAddedPlans&&count<this.state.plansList.length){
    currentList.push(this.state.plansList[count])
    count=count+1;

  
  }
  await this.setState({ count:count,currentList:currentList,gettingData:false})
    });
}

checkGetMoreData = async () =>{
  let scrollLimit=45;
  if (this.props.selectedContentWall==="Discover"){
    scrollLimit=60;
  }
  if ((this.props.top/this.state.count)>scrollLimit&&!this.state.gettingData){
    await this.setState({gettingData:true})
    await setTimeout(() => {
     
      this.getMoreData();
   }, 1000)
 
  }
}

filtersChangedHandler = async () =>{
  await this.setState({count:0,currentList:[]})
  if (!this.state.gettingData){
    await this.setState({gettingData:true})
    await setTimeout(() => {
     this.setState({plansList:[...this.props.plansList]})
      this.getMoreData();
   }, 200)
  }
}

componentDidUpdate= async prevProps=>{
  if (JSON.stringify(this.props.plansList)!==JSON.stringify(prevProps.plansList)){
    this.filtersChangedHandler()
  }

  if (this.props.top!==prevProps.top){
    this.checkGetMoreData()
  }
}
viewHandler= index =>{
  let plan  = this.state.currentList[index];
  this.props.viewHandler(plan);
}

render(){
  return(<div className={classes.Wall} >
    {this.state.currentList && this.state.currentList.map((plan, index) =>{
       let planID=plan.planID;
       let planName=plan.planName;
       let owner=plan.owner;
       let planDates= plan.planDates;
       let planEditors = plan.planEditors
       let imagePreview=[];
       if (plan.imagePreview!==undefined){
         imagePreview=plan.imagePreview;
       }
       let imageGallery=[];
       if (plan.imageGallery!==undefined){
         imageGallery=plan.imageGallery;
       }
       let tripCountries=[];
       if (plan.tripCountries!==undefined){
        tripCountries=plan.tripCountries;
       }
       let tripLocationTags=[];
       if (plan.tripLocationTags!==undefined){
        tripLocationTags=plan.tripLocationTags;
       }
       let participants=plan.participants;
       let planCategories = [];
       if (plan.planCategories!==undefined){
         planCategories=plan.planCategories;
       }
       let tripStyle = {} 
       if (plan.tripStyle!==undefined){
        tripStyle=plan.tripStyle;
      }
       return(
       <Plan 
       planID={planID}
       planName={planName}
       planDates={planDates}
       planDuration={plan.planDuration}
       noDatesSelected = {plan.noDatesSelected}
       imagePreview={imagePreview}
       imageGallery={imageGallery}
       owner={owner}
       planEditors={planEditors}
       tripCountries={tripCountries}
       tripLocationTags = {tripLocationTags}
       participants={participants}
       tripStyle = {tripStyle}
       planCategories={planCategories}
       favoriteTripsIDs = {this.props.favoriteTripsIDs}
       addPlanToFavorite={this.props.addPlanToFavorite}
       updateFavoriteTags={this.props.updateFavoriteTags}
       removePlanFromFavorite={this.props.removePlanFromFavorite}
       clonePlan={this.props.clonePlan}
       hideTools={this.props.hideTools}
       viewHandler={this.viewHandler}
       deleteHandler={this.props.deleteHandler}
       leavePlan={this.props.leavePlan}
       reportDislikedSuggestedTrip = {this.props.reportDislikedSuggestedTrip}

       loginHandler = {this.props.loginHandler}
       userLanguage={this.props.userLanguage}
       loggedUser = {this.props.loggedUser}
       systemText={this.props.systemText}
       RTL = {this.props.RTL}
       selectedContentWall={this.props.selectedContentWall}
       locationsTranslator = {this.props.locationsTranslator}
       user={this.props.user}
       index={index}
       key={plan.planID}

       />)
      })}
       </div>)
     }
   }
   export default PlansWall;
