import React, { Component } from "react";
import classes from "./PlanDayItem.module.css";

import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop";
import PlanPlaceItem from "./PlanPlaceItem";
import PlanTransport from "./Transport/PlanTransport"

import Note from "../Notes/Note"
import Toolkit from "../../UI/Toolbar/Toolkit"

import TimeInput from 'material-ui-time-picker'


//let moveIcon =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMoveDayIcon.png?alt=media&token=797c5811-ae9a-41cd-a457-871c587c99c9"
let moveIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDayMoveIcon.png?alt=media&token=dea06c76-5e0f-4f37-9a2c-b201a1f606ac"
let removeIcon  =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"
let priceIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPriceIcon.png?alt=media&token=32bdec31-f379-45a8-bf61-1095c849948c"
let redMarker ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FRedMarker.png?alt=media&token=119b65f8-542e-4f0c-b90c-907d5c8b8a88";
let yellowMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FYellowMarker.png?alt=media&token=04f5ab71-cf8c-441b-9d04-fd3d81fcd429";
let greenMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FGreenMarker.png?alt=media&token=2da8f89d-0b73-4e5b-be47-b83a68cee98d";
let blueMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FBlueMarker.png?alt=media&token=dc67767f-a9e5-4a81-b6bb-40c0a538a3cd";
let purpleMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FPurpleMarker.png?alt=media&token=4eea73a8-8366-429a-bd3c-1257ce12aacc";
let greyMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FGreyMarker.png?alt=media&token=9ece10b5-dcc9-4a1b-b494-aec21bbd1d26";

let likeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLikeIcon.png?alt=media&token=2c9382fc-0df7-4e31-8cbe-a06caded454a"
let likeIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLikeIconGrey.png?alt=media&token=5cf27b7a-9a20-4957-b6c0-7b485c4d8d4f"
let dislikeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDislikeIcon.png?alt=media&token=7c1b777c-384c-4276-b47a-f3d4f7883aa7"
let dislikeIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDislikeIconGrey.png?alt=media&token=e1646dac-e19b-406b-8edb-78abab769c32"

let accommodationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIcon.png?alt=media&token=3f3fbed6-2d65-4896-a2d0-6036f7e1ac5b";

class PlanDayItem extends Component {
    state={
        index: this.props.index,
        type: this.props.object.type,
        object: this.props.object,
        showBackdrop:false,
        confirmMode:false,
    }

    componentWillMount = () =>{  
        if (this.props.object.type==="place"){
          if (this.props.object.item.placeColorNumber!==undefined){
            this.setState({placeColorNumber:this.props.object.item.placeColorNumber})
          }
          if (this.props.object.item.placeVote!==undefined){
            this.setState({placeVote:this.props.object.item.placeVote})
          }
          if (this.props.object.item.placeTime!==undefined){
            this.setState({time:this.props.object.item.placeTime})
          }
        } 
    }

    componentDidUpdate = prevProps =>{
      if (this.props.object.type==="place"){
        if (this.props.object.item.placeColorNumber!==undefined){
          if (this.props.object.item.placeColorNumber!==prevProps.object.item.placeColorNumber){
            this.setState({placeColorNumber:this.props.object.item.placeColorNumber})
          }
        }
        if (this.props.object.item.placeVote!==undefined){
          if (this.props.object.item.placeVote!==prevProps.object.item.placeVote){
            this.setState({placeVote:this.props.object.item.placeVote})
          }
        }
        if (this.props.object.item.placeTime!==undefined){
          if (this.props.object.item.placeTime!==prevProps.object.item.placeTime){
            this.setState({time:this.props.object.item.placeTime})
          }
        }
      }
    }

    discardBackdrop = () =>{
      this.setState({showBackdrop:false,showColorsPalete:false,showDaysOptions:false,showPriceInput: false, showVotes:false})
    }

    removeFromPlanHandler = () =>{
      this.props.removeFromPlan(this.props.index)
    }

    


    renderItem = () =>{
        if (this.props.object.type==="place"||this.props.object.type==="accommodation"){
            let place = this.props.object.item;
            return(
              <div 
              onClick={this.props.viewHandler}
              style={{width:"100%",height:"100%",position:"relative"}}>
                <PlanPlaceItem
                  index={this.props.index}
                addedPlace={place.addedPlace}
                addToPlan={this.props.addToPlan}
                placeName={place.placeName}
                placeID={place.placeID}
                placeCategory={place.placeCategory}
                placeLocation = {place.placeLocation}
                placeCharacteristics={place.placeCharacteristics}
                imagePreview={place.imagePreview}
                placeStatus = {place.placeStatus}
                clickedMarkerPlaceID={this.props.clickedMarkerPlaceID}
                removeFromPlan={this.props.removePlaceFromPlan}
                hoverHandler={this.props.hoverHandler}
                dayIndex={this.props.dayIndex}
                hoverDayIndex = {this.props.hoverDayIndex}

                locationsTranslator = {this.props.locationsTranslator}
                categoriesTranslator = {this.props.categoriesTranslator}

                userLanguage={this.props.userLanguage}
                discoverSearchLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
            />
            </div>
            )
        }
       else if (this.props.object.type==="note"){
          let note = this.props.object.item;
          if (note.imagePreview===undefined){
            note.imagePreview=[]
          }
          if (note.imageGallery===undefined){
            note.imageGallery=[]
          }
          if (!this.props.externalAdd){
            return(
              <div 
              onClick={this.props.viewHandler}
              style={{width:"100%",height:"100%",position:"relative",  zIndex: this.props.openModeNoteId===note.noteID ? 1 : 0}}>
                <Note  
                  openMode={this.props.openModeNoteId===note.noteID}
                  newNoteID={this.props.newNoteID}
                  editNoteID = {this.props.editNoteID}
                  note={note}
                  index={this.props.index}
                  updateNote={this.props.updateNote}
                  noteOpenHandler={this.props.noteOpenHandler}

                  writeAcitivityLog = {this.props.writeAcitivityLog}
                  
                  RTL = {this.props.RTL}
                  userLanguage={this.props.userLanguage}
                  systemText={this.props.systemText}
                  user={this.props.user}/>
              </div>
            )}
        }
          else if (this.props.object.type==="transport"){
          let transport = this.props.object.item;
            return(
              <div 
              onClick={this.props.viewHandler}
              style={{width:"100%",height:"100%",position:"relative"}}>
                <PlanTransport  
                    transportID={transport.transportID}
                    transportLastModified = {transport.transportLastModified}
                    transportType={transport.transportType}
                    otherType={transport.otherType}
                    origin={transport.origin}
                    destination={transport.destination}
                    transportNote={transport.transportNote}
                    departure={transport.departure}
                    arrival={transport.arrival}
                    attachments={transport.attachments}
                    owner={transport.owner}

                    RTL = {this.props.RTL}
                    userLanguage={this.props.userLanguage}
                    planBoard={true}
                    editNote={this.props.editNoteHandler}
                    user={this.props.user}/>
              </div>
            )
        } 
    }


    renderMoveOptions = () =>{
        if (this.state.showDaysOptions){  
          let daysOptionsRTL ="DaysOptionsLTR"
          if (this.props.RTL){
            daysOptionsRTL ="DaysOptionsRTL"
          }
          return(
            <div className={[classes.DaysOptions,classes[daysOptionsRTL]].join(" ")}>
              {this.props.dayNames.map((day,index)=>{
                if (index!==this.props.dayIndex){
                  let text = day
                  if (index===0&&this.props.tripGenerator){
                    text = this.props.systemText.plan.viewPlan.planning.anyDay[this.props.userLanguage]
                  }
                  else if (index===0){
                    text = this.props.systemText.plan.viewPlan.planning.interestigMobile[this.props.userLanguage]
                  }
                  else{
                    text = this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage]+day.substring(day.indexOf(" "))
                  }
                  let dayOptionClass = "DayOption"
                  if (this.props.dayName===index){
                    dayOptionClass = "DayOptionSelected"
                  }
                  if (this.props.object.type!=="accommodation"||(index!==0&&index!==this.props.dayNames.length-1))
                  return(
                    <div key={index} className={classes[dayOptionClass]}
                    onClick={()=>{
                      this.props.moveDayHandler(index); this.setState({showBackdrop: false, showDaysOptions: false})}}
                    >
                      {text}
                    </div>
                  )
                }
               
              })}

            </div>
          )
        }
    }
   
    renderColorsPalete = () =>{
      if (this.state.showColorsPalete){
        let colorsPalletesRTL ="ColorsPalleteLTR"
        if (this.props.RTL){
          colorsPalletesRTL ="ColorsPalleteRTL"
        }
        return(<div className={[classes.ColorsPallete,classes[colorsPalletesRTL]].join(" ")}>
          <img alt="" className={classes.MarkerIcon} src={redMarker} onClick={()=>{this.props.updateColor(0);this.setState({showColorsPalete:false,showBackdrop:false})}}/>
          <img alt="" className={classes.MarkerIcon} src={blueMarker} onClick={()=>{this.props.updateColor(1);this.setState({showColorsPalete:false,showBackdrop:false})}}/>
          <img alt="" className={classes.MarkerIcon} src={greenMarker} onClick={()=>{this.props.updateColor(2);this.setState({showColorsPalete:false,showBackdrop:false})}}/>
          <img alt="" className={classes.MarkerIcon} src={yellowMarker} onClick={()=>{this.props.updateColor(3);this.setState({showColorsPalete:false,showBackdrop:false})}}/>
          <img alt="" className={classes.MarkerIcon} src={purpleMarker} onClick={()=>{this.props.updateColor(4);this.setState({showColorsPalete:false,showBackdrop:false})}}/>
          <img alt="" className={classes.MarkerIcon} src={greyMarker} onClick={()=>{this.props.updateColor(5);this.setState({showColorsPalete:false,showBackdrop:false})}}/>
        </div>)
      }
    }

    renderVotes = () =>{
      if (this.state.showVotes){
        let imageClass = "UserVoteImage"
        if (this.props.RTL){
          imageClass = "UserVoteImageRTL"
        }
        return(<div className={classes.VotesList}>
            {this.state.placeVote.map((vote,index)=>(
              <div className={classes.UserVote} key={index}>
                <div>
                <img alt= "" src={vote.photoUrl} className={classes[imageClass]}/>
                {vote.displayName}
                </div>
                {vote.vote>0?(
                    <img className = {classes.VoteIcon} alt ="" src={likeIcon}/>
                ):(  <img className = {classes.VoteIcon} alt ="" src={dislikeIcon}/>)}
              </div>
            ))}
          </div>)
      }
    }

    renderColorIcon = () =>{
      if (this.state.placeColorNumber!==undefined){
           let icon;
          switch(this.props.object.item.placeColorNumber){
            case 0:{
              icon = redMarker
              break;
            }
            case 1:{
              icon = blueMarker
              break;
            }
            case 2:{
              icon = greenMarker
              break;
            }
            case 3:{
              icon = yellowMarker
              break;
            }
            case 4:{
              icon = purpleMarker
              break;
            }
            case 5:{
              icon = greyMarker
              break;
            }
            default:return null;
          }
          return(
            <img className={classes.MarkerIcon} alt="" src={icon} onClick={()=>{this.setState({showColorsPalete:true,showBackdrop:true})}}/>)
        }
    }

    renderRemoveFromPlanIcon = () =>{
      let iconClass = "CloseIcon"
      if (this.props.RTL){
        iconClass = "CloseIconRTL"
      }
      return (<img className={classes[iconClass]} alt="" onClick={(e)=>{e.stopPropagation(); this.removeFromPlanHandler()}} src={removeIcon}/>)
    }
  

      renderVoteIcon = () =>{
        if (this.state.type==="place"&&this.props.isEditor&&this.props.editorsNumber>1){
         
          let renderVoteResults = false
          let userVote = 0;
          let usersLiked =0;
          if (this.state.placeVote!==undefined){
            
            
            for (let i=0; i<this.state.placeVote.length ; i++){
                if (this.state.placeVote[i].vote!==0){
                  renderVoteResults = true
                }
                if (this.state.placeVote[i].vote>0){
                  usersLiked=usersLiked+1;
                }
                if (this.state.placeVote[i].userID===this.props.user.userID){
                  userVote = this.state.placeVote[i].vote
                }
            }
          }
          return(
            <div className={classes.Vote}>
                {this.renderVoteOptions(userVote)}
              {renderVoteResults?(
                <div className={classes.VoteResults} onClick={()=>{this.setState({showVotes:true,showBackdrop:true})}}>{usersLiked+"/"+this.props.editorsNumber}</div>
              ):null}
            </div>
          )
        }
      }

      renderVoteOptions = (userVote) =>{
        let voteOptionClass = "VoteOptions"
        if (this.props.RTL){
          voteOptionClass = "VoteOptionsRTL"
        }
        return(
          <div className={classes[voteOptionClass]}>
            {userVote===1?(
              <img className = {classes.VoteIcon} alt ="" src={likeIcon}
              onClick={()=>{this.props.voteHandler(this.props.index,0)}}/>
            ):(
              <img className = {classes.VoteIcon} alt ="" src={likeIconGrey}
              onClick={()=>{this.props.voteHandler(this.props.index,1)}}/>
            )}
            {userVote===-1?(
              <img className = {classes.VoteIcon} alt ="" src={dislikeIcon}
              onClick={()=>{this.props.voteHandler(this.props.index,0)}}/>
            ):(
              <img className = {classes.VoteIcon} alt ="" src={dislikeIconGrey}
              onClick={()=>{this.props.voteHandler(this.props.index,-1)}}/>
            )}
          </div>
        )
      }

      renderTools = () =>{
        let toolkitClass = "ItemTools"
        let toolsClass = "Tools"
        let iconClass = "ToolsIcon"
        if (this.props.RTL){
          toolkitClass = "ItemToolsRTL"
          toolsClass = "ToolsRTL"
          iconClass = "ToolsIconRTL"
        }
        let defaultTime = new Date();
        defaultTime.setHours(0);
        defaultTime.setMinutes(0);
        let time = this.state.time;
        if (time=== undefined){
          time = defaultTime
        }
        if (this.props.isEditor){
          return(
            <div className={classes.ToolsSection}>
              {this.state.type==="place"&&!this.props.tripGenerator?(
                <TimeInput
                mode='24h'
                value={time}
                defaultValue={defaultTime}
                onChange={(time) => {this.props.updatePlaceTime(time); this.setState({time:time})}}
                />
              ):null}
             
              <div className={classes[toolkitClass]}>
            
               {this.renderRemoveFromPlanIcon()}
                {this.renderMoveIcon()}
                <div className={classes[toolsClass]}>
                     {this.renderMoveOptions()}
              </div>
              {this.renderAccommodationIcon()}
                {this.renderColorIcon()}
                <div className={classes[toolsClass]}>
                     {this.renderColorsPalete()}
              </div>
                {/* {this.renderPriceIcon()} */}
                {this.renderVoteIcon()}
              <div className={classes[toolsClass]}>
                     {this.renderVotes()}
              </div>
          </div>
          </div>)
        }
       else if (this.props.addToPlan && this.state.type==="place"){
        return(
          <div className={classes.ToolsSection}>
                <div className={classes[toolkitClass]}>
                  {this.renderRemoveFromPlanIcon()}
                  </div>
          </div>)
       }
       else if (this.props.object.type==="accommodation"){
        return(
        <div className={classes.ToolsSection}>
          <div className={classes[toolkitClass]}>
            {this.renderAccommodationIcon()}
          </div>
        </div>)
       }
    }

    renderAccommodationIcon = () =>{
      if (this.props.object.type==="accommodation"){
        let iconClass = "ToolsIcon"
        if (this.props.RTL){
          iconClass = "ToolsIconRTL"
        }
        return(
          <img src={accommodationIcon} alt="" className={classes[iconClass]}/>
        )
      }
    }

    renderMoveIcon = () =>{
      let iconClass = "ToolsIcon"
      if (this.props.RTL){
        iconClass = "ToolsIconRTL"
      }
      if (this.state.object.type==="accommodation"){
        if (this.props.dayNames.length>3){
          return(
            <img src={moveIcon} alt="" className={classes[iconClass]} 
            onClick={()=>{this.props.moveDayHandler()}}/>
          )
        }
      }
      else if (this.props.dayNames.length>1){
        return(
          <img src={moveIcon} alt="" className={classes[iconClass]} onClick={()=>{this.props.moveDayHandler()/* this.setState({showDaysOptions:true, showBackdrop:true}) */}}/>
        )
      }

    }

    renderPriceIcon = () =>{
      return (<img className={classes.CloseIcon} alt="" 
      onClick={(e)=>{e.stopPropagation(); this.setState({showPriceInput: true, showBackdrop: true})}} 
      src={priceIcon}/>)
     
    }

    renderPriceInput = () =>{
      if (this.state.showPriceInput){

      }
    }

    render(){
        let noBorderClass = ""
        if (this.props.object.type==="transport"||this.props.addToPlan){
          noBorderClass="NoBorder"
        }
        return(<div className={[classes.Body,classes[noBorderClass]].join(" ")} >
            <TransparentBackdrop show={this.state.showBackdrop} clicked={this.discardBackdrop}/>
          
            {this.renderTools()}
            <div className={classes.Content}>
              {this.renderItem()}
            </div>    
            
        </div>)
    }
}

export default PlanDayItem;