import React, { Component } from "react";
import classes from "./TripPlanOverviewEditor.module.css";

import ModalTopSection from "../../../Components/UI/Misc/ModalTopSection"
import ImageUploader from "../../../Components/Complex/Images/ImageUploader"
import TextButton from "../../../Components/UI/Buttons/TextButton"

import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
 

import {isMobile} from 'react-device-detect';
import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop";

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"
let uploadIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FUploadIconGrey.png?alt=media&token=ceb0a76e-b032-4cfe-a467-590711dd42e6"
let pasteIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPasteIconGrey.png?alt=media&token=686af38e-52ad-4ea7-81bc-bb3b029d0700"

let radioButtonChecked = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FRadioButtonChecked.png?alt=media&token=c962a776-42f5-4063-822f-f1f4a0a6fecc"
let radioButtonClear = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FRadioButtonClear.png?alt=media&token=76d5e393-ae07-478b-85d8-fe4ee149831f"

var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);



class TripPlanOverviewEditor extends Component {

    state={
        planName:"",
        imagePreview:[],
        imageGallery:[],
        datesRangeSelected:false,
        selectionRange:{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
        startDate:new Date(),
        planDays:[{name:"Interesting",dayItems:[], selected:true}],
        participants:{adults:1,kids:0,toddler:0},
        travelWithKids:false,
        planDuration:1,
        noDatesSelected: false,
        oneDayTrip:false
    }

    componentWillMount = () =>{
      document.body.style.overflow = "hidden"  
      if (this.props.editMode === "Create"){
        if (this.props.clonedPlan!==undefined){
          if (this.props.clonedPlan.planDuration===1){
            this.setState({oneDayTrip:true})
          }
          this.setState({planDuration:this.props.clonedPlan.planDuration, clonedPlanStartDateSelected:false})
          if (this.props.clonedPlan.noDatesSelected){
            this.setState({noDatesSelected:true})
          }
        }
        let startDate = new Date();
        let planStartDate =  moment(startDate);
        let planDates={startDate:startDate,endDate:startDate}
        this.setState({planStartDate:planStartDate,planEndDate:planStartDate,planDates:planDates})
      } 
      else if (this.props.editMode === "Edit"){
        let travelWithKids = false
        if (this.props.travelWithKids!==undefined){
          travelWithKids = this.props.travelWithKids
        }
        let planDates = this.props.planDates;
        planDates.startDate=new Date(planDates.startDate);
        planDates.endDate=new Date(planDates.endDate);
        let planStartDate =  moment( planDates.startDate);
        let planEndDate =  moment(planDates.endDate);
        this.setState({planDates:planDates,planStartDate:planStartDate,planEndDate:planEndDate,datesRangeSelected:true})
        this.setState({
            planName:this.props.planName,
            travelWithKids:travelWithKids,
            deletedImages:[],
            participants:this.props.participants,
            noDatesSelected:this.props.noDatesSelected,
            planDuration:this.props.planDuration
        })
        if (this.props.planDuration==1){
          this.setState({oneDayTrip:true})
        }
        else{
          this.setState({oneDayTrip:false})
        }
        let imagePreview = [...this.props.imagePreview]
        let imageGallery = [...this.props.imageGallery]
        let imagesList = [];
        this.setState({imagePreview:imagePreview,imageGallery:imageGallery})
        if (this.props.imagePreview.length>0){
            imagesList= this.props.imagePreview;
            if (Object.keys(this.props.imagePreview[0]).includes("smallImage")){
                imagesList = this.props.imagePreview.map(img=>{return img.smallImage});
            }
        }
        else{
            this.setState({imagePreview:[],imageGallery:[]})
        }
        this.setState({imagePreview:imagesList})
      }
       
}

componentWillUnmount=()=>{
  document.body.style.overflow = "auto"
}

handleNoDatesSelection = async value =>{
  this.setState({noDatesSelected:value})
  let newPlanDays=[{name:"Interesting",dayItems:[], selected:true}];
  await this.setState({planDays:newPlanDays})
  if (value===false){
    let endDate = this.state.planStartDate.clone();
    endDate=endDate.add('days', this.state.planDuration);
    this.datePickerHandler({startDate:this.state.planStartDate,endDate:endDate})
    if (this.state.planDuration==1){
      this.setState({oneDayTrip:true})
    }
    else{
      this.setState({oneDayTrip:false})
    }
  }
  else{
    this.planDuratoinHandler(this.state.planDuration)
  }
}
planDurationInputHandler = async e =>{
  if (e.target.value<1)return
  let duration = parseInt(e.target.value, 10)
  this.planDuratoinHandler(duration)
}

planDuratoinHandler = async duration =>{
    let planDuration=duration
    let newPlanDays=[{name:"Interesting",dayItems:[], selected:true}];
    for (let i = 0; i < planDuration; i++) {
        let dayName="Day " +(i+1);
        newPlanDays.push({name:dayName,dayItems:[], selected:false}); 
    }   
    await this.setState({planDays:newPlanDays,planDuration:planDuration})
    if (planDuration==1){
      this.setState({oneDayTrip:true})
    }
    else{
      this.setState({oneDayTrip:false})
    }
    let screen = "NEW_TRIP"
    if (this.props.editMode==="Edit"){
        screen = "EDIT_TRIP"
    }
    this.props.writeAcitivityLog({
      activityKey:"TRIP_EDIT_OVERVIEW_DURATION_UPDATE",
      userScreen:screen,
    })
}

datePickerHandler = async (range) =>{
  let startDate=range.startDate;
  let endDate=range.endDate
  if (range.startDate===null||range.endDate===null){
    this.setState({planStartDate:startDate,planEndDate:endDate});
    return;
  }
    this.setState({planStartDate:startDate,planEndDate:endDate,datesRangeSelected:true,});
    let planDuration= 0
    if (endDate!==null){
      planDuration= endDate.diff(startDate, 'days') +1; 
    }
    let newPlanDays=[{name:"Interesting",dayItems:[], selected:true}];
    let planStart = startDate.toDate();
    for (let i = 0; i < planDuration; i++) {
        var localeData = moment.localeData();
        var format = localeData.longDateFormat('L');
        const runningDate = moment(planStart);
        let date = runningDate.format(format);
        let dayName="Day " +(i+1)+" - "+date;
        newPlanDays.push({name:dayName,dayItems:[], selected:false}); 
        planStart.setDate(planStart.getDate() + 1);
    }
    let planDates
    if (endDate===null){
      planDates ={startDate:startDate.toDate(),endDate:startDate.toDate()}
    }
    else{
      planDates ={startDate:startDate.toDate(),endDate:endDate.toDate()}
    }
    await this.setState({planDays:newPlanDays,planDates:planDates,planDuration:planDuration})

    let screen = "NEW_TRIP"
    if (this.props.editMode==="Edit"){
        screen = "EDIT_TRIP"
    }
    this.props.writeAcitivityLog({
      activityKey:"TRIP_EDIT_OVERVIEW_DATES_UPDATE",
      userScreen:screen,
    })
}

singleDateHandler = date =>{
   if (this.props.clonedPlan!==undefined){
    this.setStartDate({startDate:date})
  }
  else{
    this.datePickerHandler({startDate:date,endDate:date})
  } 
}

setStartDate = async range =>{

  let startDate=range.startDate;
  let endDate = startDate.clone();
  endDate=endDate.add('days', this.state.planDuration);
  this.setState({planStartDate:startDate,planEndDate:endDate,datesRangeSelected:true,clonedPlanStartDateSelected:true});
  let planDuration = this.state.planDuration + 1; 
  let newPlanDays=[{name:"Interesting",dayItems:[], selected:true}];
    let planStart = startDate.toDate();
    for (let i = 0; i < planDuration; i++) {
        var localeData = moment.localeData();
        var format = localeData.longDateFormat('L');
        const runningDate = moment(planStart);
        let date = runningDate.format(format);
        let dayName="Day " +(i+1)+" - "+date;
        newPlanDays.push({name:dayName,dayItems:[], selected:false}); 
        planStart.setDate(planStart.getDate() + 1);
    }
    let planDates
    if (endDate===null){
      planDates ={startDate:startDate.toDate(),endDate:startDate.toDate()}
    }
    else{
      planDates ={startDate:startDate.toDate(),endDate:endDate.toDate()}
    }
   
    await this.setState({planDays:newPlanDays,planDates:planDates})
}


updateImages = (imagePreview, imageGallery, deletedImages) => {
  this.setState({
    imagePreview: imagePreview,
    imageGallery: imageGallery,
    deletedImages: deletedImages
  });

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_IMAGE_UPDATE",
    userScreen:screen,
  })
}

removeImageHandler = () =>{
  if (this.props.imagePreview!==undefined){
    if (this.props.imagePreview.length>0){
      if (this.state.imagePreview[0].smallImage!==undefined){
        if (this.state.imagePreview[0].smallImage.url===this.props.imagePreview[0].smallImage.url){
          this.setState({deletedImages:[this.props.imagePreview[0].smallImage.name]})
        }
      }
      
    }
  }
  this.setState({imagePreview:[], imageGallery:[]})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_IMAGE_DELETE",
    userScreen:screen,
  })
}

imageUploadHandler = (event) =>{
  this.refs.imageChild.uploadImages(event);
  this.setState({imagesUploading: true})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_IMAGE_UPLOAD",
    userScreen:screen,
  })
}

imagePasteHandler = () =>{
this.refs.imageChild.handlePaste();
this.setState({imagesUploading: true})

let screen = "NEW_TRIP"
if (this.props.editMode==="Edit"){
    screen = "EDIT_TRIP"
}
this.props.writeAcitivityLog({
  activityKey:"TRIP_EDIT_OVERVIEW_IMAGE_COPY",
  userScreen:screen,
})
}

mobileImagePasteHandler = (pasteEvent,btnTrigger)=>{
this.refs.imageChild.copyImage(pasteEvent,btnTrigger);
this.setState({imagesUploading: true})

let screen = "NEW_TRIP"
if (this.props.editMode==="Edit"){
    screen = "EDIT_TRIP"
}
this.props.writeAcitivityLog({
  activityKey:"TRIP_EDIT_OVERVIEW_IMAGE_COPY",
  userScreen:screen,
})
}

adultsDecreasHandler = () =>{
  if (this.state.participants.adults===1) return;
  let participants=this.state.participants;
  participants.adults =   parseInt(participants.adults, 10)-1
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_ADULTS_DECREASE",
    userScreen:screen,
  })
}

adultsIncreaseHandler = () =>{
  let participants=this.state.participants;
  participants.adults =  parseInt(participants.adults, 10)+1
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_ADULTS_INCREASE",
    userScreen:screen,
  })
}

updateAdultsNumber = e =>{
  if (e.currentTarget.value<1) return
  let participants=this.state.participants;
  participants.adults=e.currentTarget.value;
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_ADULTS_INPUT",
    userScreen:screen,
  })
}


kidsDecreasHandler = () =>{
  if (this.state.participants.kids===0) return;
  let participants=this.state.participants;
  participants.kids =  parseInt(participants.kids, 10)-1
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_KIDS_DECREASE",
    userScreen:screen,
  })
}

kidsIncreaseHandler = () =>{
  let participants=this.state.participants;
  participants.kids =  parseInt(participants.kids, 10)+1
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_KIDS_INCREASE",
    userScreen:screen,
  })
}

updateKidsNumber = e =>{
  if (e.currentTarget.value<0) return
  let participants=this.state.participants;
  participants.kids=e.currentTarget.value;
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_KIDS_INPUT",
    userScreen:screen,
  })
}

toddlerDecreasHandler = () =>{
  if (this.state.participants.toddler===0) return;
  let participants=this.state.participants;
  participants.toddler = parseInt(participants.toddler, 10)-1
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_TODDLERS_DECREASE",
    userScreen:screen,
  })
}

toddlerIncreaseHandler = () =>{
  let participants=this.state.participants;
  participants.toddler =  parseInt(participants.toddler, 10)+1
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_TODDLERS_INCREASE",
    userScreen:screen,
  })
}

updateToddlerNumber = e =>{
  if (e.currentTarget.value<0) return
  let participants=this.state.participants;
  participants.toddler=e.currentTarget.value;
  this.setState({participants:participants})

  let screen = "NEW_TRIP"
  if (this.props.editMode==="Edit"){
      screen = "EDIT_TRIP"
  }
  this.props.writeAcitivityLog({
    activityKey:"TRIP_EDIT_OVERVIEW_PARTICIPANTS_TODDLERS_INPUT",
    userScreen:screen,
  })
}

    planTitleHandler = event => {
        let planName=event.target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        if(this.state.planName!==planName)
        {
          this.setState({changesMade:true}) 
        }
        this.setState({planName: planName})

       
    };

    planTItleBlur = () =>{
      let screen = "NEW_TRIP"
      if (this.props.editMode==="Edit"){
          screen = "EDIT_TRIP"
      }
      this.props.writeAcitivityLog({
        activityKey:"TRIP_EDIT_OVERVIEW_NAME_UPDATE",
        userScreen:screen,
      })
    }

    saveHandler = () =>{
        if (this.props.editMode === "Create"){
          this.props.writeAcitivityLog({
            activityKey:"TRIP_EDIT_OVERVIEW_SAVE_BUTTON",
            userScreen:"NEW_TRIP",
          })

          this.createHandler()
        }
        else if (this.props.editMode === "Edit"){
          this.props.writeAcitivityLog({
            activityKey:"TRIP_EDIT_OVERVIEW_UPDATE_BUTTON",
            userScreen:"EDIT_TRIP",
          })

          this.updateHandler()
        }
    }

    updateHandler = () =>{
        let planName = this.state.planName;
        let planDates = this.state.planDates;
       
        let imagePreview = [...this.state.imagePreview]
        let imageGallery = [...this.state.imageGallery]
        let deletedImages = [...this.state.deletedImages]
        let participants = this.state.participants;
        let plan={
            planName:planName,
            planDates:planDates,
            imagePreview:imagePreview,
            imageGallery:imageGallery,
            deletedImages:deletedImages,
            participants:participants,
            travelWithKids:this.state.travelWithKids,
            noDatesSelected:this.state.noDatesSelected,
            planDuration:this.state.planDuration
        }
       /*  console.log(plan) */
        
       this.props.update(plan)
       this.props.discard(); 
      
       
    }
    
    createHandler = () =>{
        let date = new Date();
        let timestamp = date.getTime();
        let planID = timestamp.toString()+"_"+this.props.user.userID;
        let planName = this.state.planName;
        let planDates = this.state.planDates;
        planDates.startDate=new Date(planDates.startDate.getTime()).getTime();
        planDates.endDate=new Date(planDates.endDate.getTime()).getTime();
        let participants = this.state.participants;
        let imagePreview = [...this.state.imagePreview]
        let imageGallery = [...this.state.imageGallery]
        let owner = {
            ownerID: this.props.user.userID,
            ownerName: this.props.user.displayName,
            ownerPhoto: this.props.user.photoUrl,
            ownerEmail: this.props.user.userEmail
          };
        let newPlanDays =[...this.state.planDays];
        if (this.props.clonedPlan!==undefined){
          let planDays=this.props.clonedPlan.planDays;
          if (this.state.noDatesSelected){
            newPlanDays=[{name:"Interesting",dayItems:[], selected:true}];
            for (let i = 0; i < this.state.planDuration; i++) {
           
              let dayName="Day " +(i+1)
              newPlanDays.push({name:dayName,dayItems:[], selected:false}); 
            }
          }
          for (let i=0; i<planDays.length ; i++){
            newPlanDays[i].selected=true;
            newPlanDays[i].dayItems=[];
            if (planDays[i].dayItems!==undefined){
                for(let j=0; j<planDays[i].dayItems.length ; j++){
                //now handling only places
                if (planDays[i].dayItems[j].type==="place"){
                  let dayItem={};
                  dayItem.type="place";
                  dayItem.item={
                    placeID:planDays[i].dayItems[j].item.placeID,
                    placeCountry:planDays[i].dayItems[j].item.placeCountry,
                    placeColorNumber:planDays[i].dayItems[j].item.placeColorNumber,
                    placeExperiences:[]
                  }
                 /*  if (planDays[i].dayItems[j].item.placeVote!==undefined){
                    dayItem.item.placeVote=planDays[i].dayItems[j].item.placeVote
                  } */
                  if (planDays[i].dayItems[j].item.placeTime!==undefined){
                    if (planDays[i].dayItems[j].item.placeTime instanceof Date){
                      dayItem.item.placeTime = new Date(planDays[i].dayItems[j].item.placeTime.getTime()).getTime();
                    }
                  }
                  newPlanDays[i].dayItems.push(dayItem)
                }
              }
            }
          }         
        }
        let editorUser = {...this.props.user};
        editorUser.planLastModified=timestamp;
        let planEditors =[editorUser];

        let tripStats = {
          viewed: { count: 0, users: [] },
          inspired: { count: 0, users: [] },
          cloned: {count: 0, users:[]}
        };
        let plan={
            planID: planID,
            isPublic:false,
            planLastModified: timestamp,
            planCreationDate: timestamp,
            planName:planName,
            planDates:planDates,
            planDuration:this.state.planDuration,
            participants:participants,
            travelWithKids:this.state.travelWithKids,
            planDays:newPlanDays,
            planPlacesIDs:[],
            imagePreview:imagePreview,
            imageGallery:imageGallery,
            planTasks:[],
            owner:owner,
            planEditors:planEditors,
            noDatesSelected: this.state.noDatesSelected,
            planSecurity:"private",
            albumSecurity:{[this.props.user.userID]:"private"},
            tripStats:tripStats
        }
        if (this.props.adminUser){
          plan.systemCreated = true
        }
       /*   console.log("plan")
        console.log(plan)  */
        

        if (!this.props.loggedUser&&this.props.clonedPlan===undefined){
          this.setState({showLoggedUserTripBenefitsMessage: true,planToCreate:plan})
        }
        else{
          this.props.create(plan)
        }
        //this.props.discard(); 
    }

    renderTopSection = () => {
        let title = "";
        let buttonName = ""
        if (this.props.editMode === "Create"){
            title = this.props.systemText.plan.createPlan.title[this.props.userLanguage];
            buttonName = this.props.systemText.misc.buttons.createButton[this.props.userLanguage];
        }
        else{
            buttonName = this.props.systemText.misc.buttons.updateButton[this.props.userLanguage];
        }
        
        let saveReady = false;
        if (this.state.planName.length>2&&
            (this.state.datesRangeSelected||this.state.noDatesSelected)
        ){ 
            saveReady=true;
         }
        return (
          <div>
            <ModalTopSection
              title={title}
              discard={this.props.discard}
              saveReady={saveReady}
              showButton={true}
              modalView={true}
              buttonText={buttonName}
              clicked={this.saveHandler}
              saveButton={true}
              RTL = {this.props.RTL}
            />           
          </div>
        );
      };

      renderTitleSection = () =>{
        let inputClass = "TitleInput"
        if (this.state.planName.length<3){
            inputClass = "TitleInputRed"
        }
        return (
        <div className={classes.TitleSection}>
            <input
                className = {classes[inputClass]}
                value = {this.state.planName}
                placeholder = {this.props.systemText.plan.createPlan.titlePlaceholder[this.props.userLanguage]}
                onChange={this.planTitleHandler}
                onKeyDown={this.planTitleHandler}
                onKeyPress={this.planTitleHandler}
                onBlur = {this.planTItleBlur}
                />
        </div>
        );
      }

      renderImageSection = () => {
        let imageIconClass = "ImageIcon"
        if (this.props.RTL){
            imageIconClass = "ImageIconRTL"
        }
        if (this.props.loggedUser){
          return(
            <div className={classes.ImageSection}>
              <div className={classes.SectionHeader}>
               {this.props.systemText.plan.createPlan.imageSectionHeader[this.props.userLanguage]}
              </div>
              {this.state.imagePreview.length>0?(
                <div className={classes.ImageContent}>
                  <TextButton 
                      clicked={() => {
                        this.removeImageHandler();
                      }}
                      text={this.props.systemText.place.createPlace.removeImage[this.props.userLanguage]}
                      style = {"DeleteButton"}
                  />
                </div>
              ):(
                <div className={classes.ImageContent}>
                <div className={classes.ImageButton} >
                    <div className={classes.ImageUploadWrapper}>
                        <img src={uploadIconGrey} className={classes[imageIconClass]} alt="" />
                      <input
                      type="file"
                      onChange={this.imageUploadHandler}
                      multiple={false}
                      accept="image/*"
                      className={classes.FileInput}
                  />
                <div className={classes.ImageText}>{this.props.systemText.place.createPlace.uplodaImageButton[this.props.userLanguage]}</div>
            </div>
            </div>
            {isMobile?(
               /*  <div className={classes.ImageButton}>
                    <img src={pasteIconGrey} className={classes[imageIconClass]} alt="" />
                    <input className={classes.ImageText}value=  {this.props.systemText.place.createPlace.pasteImageButton[this.props.userLanguage]} onPaste={this.mobileImagePasteHandler}/>
            </div> */null
            ):(
                <div className={classes.ImageButton}  onClick={this.imagePasteHandler} >
                    <img src={pasteIconGrey} className={classes[imageIconClass]} alt="" />
                    <div className={classes.ImageText}>{this.props.systemText.place.createPlace.pasteImageButton[this.props.userLanguage]}</div>
                
            </div>
            )}
           
            </div>
              )}
           
            <div className={classes.Images}>
            <ImageUploader
                ref="imageChild"
                discard={this.discardPhotoHandler}
                update={this.updateImages}
                imagePreview={this.state.imagePreview}
                imageGallery={this.state.imageGallery}
                narrowMode={false}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
                singleImage = {true}

                handleSaving = {value=>{this.setState({imageProcess:value})}}
                />
            </div>
        </div>
        ) 
        }
       
      }


      renderNoDatesButton = () =>{
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
        if (this.state.noDatesSelected){
          return(
            <div className={classes.NoDatesSection} >
              <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.handleNoDatesSelection(false)}}/>
              <div className={classes.NoDatesButtonSelected}>{this.props.systemText.plan.createPlan.noDatesMessage[this.props.userLanguage]}</div>
            </div>
          )
        }
        else{
          return(
            <div className={classes.NoDatesSection}>
               <div className={classes[uncheckBoxClass]} onClick={()=>{this.handleNoDatesSelection(true)}}/>
              <div className={classes.NoDatesButton}>{this.props.systemText.plan.createPlan.noDatesMessage[this.props.userLanguage]}</div>
            </div>
          )
        }
      }

      renderDatesSelection = () =>{
        let orientation="horizontal"
        let numberOfMonths = 2
        if (isMobile){
          numberOfMonths = 1
        } 
        let isRTL=false
        let direction="ltr"
        let anchorDirection ="left"
        if (this.props.RTL){
            direction="rtl"
            isRTL=true;
            anchorDirection="right"
        }
        if (this.state.oneDayTrip){
          return(
            <SingleDatePicker
            placeholder={this.props.systemText.experience.createExperience.setDate[this.props.userLanguage]}
            date={this.state.planStartDate} // momentPropTypes.momentObj or null
            onDateChange={this.singleDateHandler} // PropTypes.func.isRequired
            focused={this.state.focused} // PropTypes.bool
            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
            id="experience" // PropTypes.string.isRequired,
            orientation={orientation} 
            verticalHeight={568}
              horizontalMargin={0}
              noBorder={true}
              small={false}
              numberOfMonths={1}
              anchorDirection={anchorDirection}
              isOutsideRange={(day)=>{ return false
              }}
              isRTL = {isRTL}
            />
          )
        }
        else{
          if (this.props.clonedPlan!==undefined){
            return(
              <DateRangePicker
              startDate={this.state.planStartDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.planEndDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={this.setStartDate} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              anchorDirection={anchorDirection}
              numberOfMonths = {numberOfMonths}
           /*    showDefaultInputIcon */
             /*  showClearDates */
             isRTL = {isRTL}
              startDatePlaceholderText={this.props.systemText.extraModals.reactDates.startDatePlaceholder[this.props.userLanguage]}
              endDatePlaceholderText={this.props.systemText.extraModals.reactDates.endDatePlaceholder[this.props.userLanguage]}
            />
            )
          }
          else{
            return(
              <DateRangePicker
                startDate={this.state.planStartDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.planEndDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={this.datePickerHandler} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                orientation={orientation} verticalHeight={568}
                horizontalMargin={0}
                numberOfMonths = {numberOfMonths}
                noBorder={true}
                anchorDirection={anchorDirection}
                isOutsideRange={(day)=>{ return false
                  }}
                  isRTL = {isRTL}
                startDatePlaceholderText={this.props.systemText.extraModals.reactDates.startDatePlaceholder[this.props.userLanguage]}
                endDatePlaceholderText={this.props.systemText.extraModals.reactDates.endDatePlaceholder[this.props.userLanguage]}
              />
            )
          }
        }
      }

      renderDurationSelection = () =>{
      /*   if (!this.state.noDatesSelected){ */
          let radioButtonIcon = "RadioButton"
          let durationMessageClass = "DurationMessage"
          if (this.props.RTL){
            radioButtonIcon = "RadioButtonRTL"
            durationMessageClass = "DurationMessageRTL"
          }
         
          return(
            <div className={classes.DurationSection}>
             {/*  <div className={classes[durationMessageClass]}>
                {this.props.systemText.plan.createPlan.durationMessage[this.props.userLanguage]}</div> */}
              <div className={classes.DuraitonRadioButtons}>
                <div className={classes.DurationButton}>
                {this.state.oneDayTrip?(
                  <img src={radioButtonChecked} alt="" className={classes[radioButtonIcon]}/>
                ):(
                  <img src={radioButtonClear} alt="" className={classes[radioButtonIcon]}
                  onClick={()=>{this.setState({oneDayTrip:true})
                  let screen = "NEW_TRIP"
                  if (this.props.editMode==="Edit"){
                      screen = "EDIT_TRIP"
                  }
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_EDIT_OVERVIEW_DURATION_SINGLEDAY_BUTTON",
                    userScreen:screen,
                  })}}/>
                )}
                {this.props.systemText.plan.createPlan.singleDay[this.props.userLanguage]}
              </div>
              <div className={classes.DurationButton}>
                {this.state.oneDayTrip?(
                  <img src={radioButtonClear} alt="" className={classes[radioButtonIcon]}
                  onClick={()=>{this.setState({oneDayTrip:false})
                  let screen = "NEW_TRIP"
                  if (this.props.editMode==="Edit"){
                      screen = "EDIT_TRIP"
                  }
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_EDIT_OVERVIEW_DURATION_MULTIPLEDAYS_BUTTON",
                    userScreen:screen,
                  })}}/>
                ):(
                  <img src={radioButtonChecked} alt="" className={classes[radioButtonIcon]}/>
                )}
                {this.props.systemText.plan.createPlan.multipleDays[this.props.userLanguage]}
              </div>
              </div>
            </div>
          )
        /* } */
      }

      renderDatesPlanDuration = () =>{
        
        if (this.props.clonedPlan!==undefined){
          let startDateMessageColor = ""
          if (!this.state.clonedPlanStartDateSelected){
            startDateMessageColor = "RedMessage"
          }
          let subHeaderClass = "SubHeader"
          if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
          }
          return(<div className={classes.DatesSection}>
            <div className={classes.SectionHeader}>
              {this.props.systemText.plan.createPlan.datesHeader[this.props.userLanguage]}
            </div>
            {this.renderDurationSelection()}
            {this.renderNoDatesButton()}
            
            {this.state.noDatesSelected?(
                null
            ):<div className={[classes.StartDateMessage,classes[startDateMessageColor]].join(" ")}>
            {this.props.systemText.plan.createPlan.startMessagePrefix[this.props.userLanguage]+this.state.planDuration+this.props.systemText.plan.createPlan.startMessagePostfix[this.props.userLanguage]}
            </div>}
                {this.state.noDatesSelected?(
                <div className={classes.PlanDuration}>
                <div className={classes[subHeaderClass]}>
                  {this.props.systemText.plan.createPlan.planDurationHeader[this.props.userLanguage]}
                  </div>
                  <div className={classes.PlanDurationInput}>{this.state.planDuration}</div>
              </div>
              ):(
               <div>{this.renderDatesSelection()}</div>
              )}
            </div>)
        }
        else{
          return(<div className={classes.DatesSection}>
             <div className={classes.SectionHeader}>
             {this.props.systemText.plan.createPlan.datesHeader[this.props.userLanguage]}
            </div>
            {this.renderDurationSelection()}
            {this.renderNoDatesButton()}
            
             {this.state.noDatesSelected?(
                 <div className={classes.PlanDuration}>
                   <div className={classes.SubHeader}>
                     {this.props.systemText.plan.createPlan.planDurationHeader[this.props.userLanguage]}
                     </div>
                     <input type="number" 
                     className={classes.PlanDurationInput}
                     value={this.state.planDuration} onChange={this.planDurationInputHandler}/>
                 </div>
              ):(
                <div>{this.renderDatesSelection()}</div>
              )}
            </div>)
        }
        
      }

      renderTravelWithKidsButton = () =>{
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
        if (this.state.travelWithKids){
          return(
            <div className={classes.NoDatesSection} >
              <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.setState({travelWithKids:false})}}/>
              <div className={classes.NoDatesButtonSelected}>{this.props.systemText.plan.createPlan.travelWithKids[this.props.userLanguage]}</div>
            </div>
          )
        }
        else{
          return(
            <div className={classes.NoDatesSection}>
               <div className={classes[uncheckBoxClass]}  onClick={()=>{this.setState({travelWithKids:true})}}/>
              <div className={classes.NoDatesButton}>{this.props.systemText.plan.createPlan.travelWithKids[this.props.userLanguage]}</div>
            </div>
          )
        }
      }

      renderParticipants = () =>{
        return(
          <div className={classes.ParticipantsSection}>
            <div className={classes.SectionHeader}>
                {this.props.systemText.plan.createPlan.participantsHeader[this.props.userLanguage]}
            </div>
            {this.renderTravelWithKidsButton()}
            <div className={classes.Participants}>
            <div className={classes.Participant}>
            <div className={classes.ParticipantVisual}>
              <div className={classes.ParticipantText}>
                {this.props.systemText.plan.createPlan.adults[this.props.userLanguage]}
              </div> 
            </div>
            <div className={classes.NumberInput}>
              <div className={classes.NumberInputButton} onClick={this.adultsDecreasHandler}>
                  {"-"}
              </div>
              <input
                  type="number"
                  value={this.state.participants.adults}
                  onInput={this.updateAdultsNumber}
                  className={classes.NumberValue}
              />
              <div className={classes.NumberInputButton}  onClick={this.adultsIncreaseHandler}>
                  {"+"}
              </div>
            </div>
            </div>
          {this.state.travelWithKids?(
            <div>
                <div className={classes.Participant}>
                <div className={classes.ParticipantVisual}>
                    <div className={classes.ParticipantText}>
                      {this.props.systemText.plan.createPlan.kids[this.props.userLanguage]}
                    </div>
                  </div>
                  <div className={classes.NumberInput}>
                    <div className={classes.NumberInputButton} onClick={this.kidsDecreasHandler}>
                        {"-"}
                    </div>
                    <input
                        type="number"
                        value={this.state.participants.kids}
                        onInput={this.updateKidsNumber}
                        className={classes.NumberValue}
                    />
                    <div className={classes.NumberInputButton}  onClick={this.kidsIncreaseHandler}>
                        {"+"}
                    </div>
                  </div>
                  
                </div>
                <div className={classes.Participant}>
                <div className={classes.ParticipantVisual}>
                    <div className={classes.ParticipantText}>
                      {this.props.systemText.plan.createPlan.toddlers[this.props.userLanguage]}
                    </div>
                  </div>
                  <div className={classes.NumberInput}>
                    <div className={classes.NumberInputButton} onClick={this.toddlerDecreasHandler}>
                        {"-"}
                    </div>
                    <input
                        type="number"
                        value={this.state.participants.toddler}
                        onInput={this.updateToddlerNumber}
                        className={classes.NumberValue}
                    />
                    <div className={classes.NumberInputButton}  onClick={this.toddlerIncreaseHandler}>
                        {"+"}
                    </div>
                  </div>
                  
                </div>
            </div>
          ):null}
          </div>
          
        
      </div>)
    }

    renderAnonymousUserMessage = () =>{
      if (this.state.showLoggedUserTripBenefitsMessage){
        return(
          <div className={classes.AnonymousUserMessage}>
              <div className={classes.MessageContent}>
                <div className={classes.MessageTitle}>
                {this.props.systemText.plan.createPlan.anonymousUser.messageTitle[this.props.userLanguage]}
                </div>
                <div className={classes.SubMessage} >
                  {this.props.systemText.plan.createPlan.anonymousUser.subMessage[this.props.userLanguage].split('\n').map((str,index) => <p key={index}>{str}</p>)}
                </div>
              </div>
              
              <div className={classes.MessageButtonsSection}>
                <div className={classes.SignUpButton}  onClick={()=>{this.props.tripCreateLogin(this.state.planToCreate)}}>
                  {this.props.systemText.plan.createPlan.anonymousUser.signUpButton[this.props.userLanguage]}
                </div>
                <div className={classes.ContinueButton} onClick={()=>{this.props.create(this.state.planToCreate)}}>
                {this.props.systemText.plan.createPlan.anonymousUser.continueButton[this.props.userLanguage]}
                </div>
                
              </div>
          </div>
      )
      }
    }

      renderCreationForm = () =>{
        return(
            <div className={classes.CreationForm}>              
                {this.renderTitleSection()}
                {this.renderDatesPlanDuration()}
                {this.renderParticipants()}
                {this.renderImageSection()}
            </div>)
      }

    render() {
        let RTL = ""
        if (this.props.RTL){
            RTL = "RTL"
        }
        return(
            <div  className={[classes.Modal,classes[RTL]].join(" ")}>
              <TransparentBackdrop show={this.state.showLoggedUserTripBenefitsMessage}/>
              {this.renderAnonymousUserMessage()}
                {this.renderTopSection()}
                {this.renderCreationForm()}
            </div>
        )
    }

}

export default TripPlanOverviewEditor;