import React, { Component } from "react";
import axios from 'axios'
 
class GetUserLocation extends Component {
    
    getUserLocation = async () =>{
          this.getByIPAddress();
    }

    userLanguageBasedOnCountry = country =>{
        switch(country){
            case "Israel":{
                this.props.updateLanguage("Hebrew")
                break;
            }
            default: this.props.updateLanguage("English")
        }
    }

    getByIPAddress = async () =>{
       await axios.get('https://ipapi.co/json/',{timeout:5000}).then((response) => {
        let data = response.data;
        this.props.updateUserCountry(data.country_name);
        this.userLanguageBasedOnCountry(data.country_name)
    }).catch((error) => {
        console.log(error);
        this.props.updateLanguage("English")
        this.props.tryAgain();
    });
    }


    render(){
    return (
        <div
        ></div>
    );}
};
export default GetUserLocation;