import React, { Component } from "react";
import classes from "./PlanCalendar.module.css";


import PlanDayObject from "./PlanDayObject";

let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowLeft.png?alt=media&token=8020b73f-93be-478c-a1c3-c3cee641fbe8"
let arrowIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowRight.png?alt=media&token=e0fca8aa-a34f-47cf-80d3-79b36b305e88"


let scrollConstant=0.5;


class PlanCalendar extends Component {
    state={ calendarRef:React.createRef(),}

    componentDidMount = () =>{
        var scrollComponent = this;
        var frame = this.state.calendarRef.current;
        frame.addEventListener("scroll", function(e) {
          scrollComponent.calendarScrollToggle();
        });      
        frame.scrollTo({
            left:0,
            behavior: "smooth"
          });  
    }

    calendarScrollToggle = () =>{
        var frame = this.state.calendarRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        if (scrollLeft>=(frame.scrollWidth-frame.offsetWidth-10)){
            this.setState({showForwardArrow:false})
        }
        else{
            this.setState({showForwardArrow:true})
        }
        this.setState({imageScrollLeft:scrollLeft})
       
        if (scrollLeft>5){
            this.setState({showBackArrow:true})
        }
        else{
            this.setState({showBackArrow:false})
        }
    }
    scrollLeftHandler = () =>{
        var frame = this.state.calendarRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let leftValue =0;
        let val =1
        if (scrollLeft===-1*frame.scrollLeft&&scrollLeft!==0){
            val=-1
        }
        if ((scrollLeft-(window.innerWidth)*scrollConstant)>0){
            leftValue =(scrollLeft-(window.innerWidth)*scrollConstant)
        }
        frame.scrollTo({
            left:val*leftValue,
            behavior: "smooth",
            duration:500
          });

         /*  this.props.writeAcitivityLog({
            activityKey:"EXPERIENCE_IMAGES_ARROW",
            userScreen:this.props.selectedContentWall.toUpperCase()
        }) */
    }

    scrollRightHandler = () =>{
        var frame = this.state.calendarRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let val =1
        if (scrollLeft===-1*frame.scrollLeft&&scrollLeft!==0){
            val=-1
        }
        let leftValue =scrollLeft+(window.innerWidth)*scrollConstant;
        frame.scrollTo({
            left:val*leftValue,
            behavior: "smooth",
            duration:500
          });

         /*  this.props.writeAcitivityLog({
            activityKey:"EXPERIENCE_IMAGES_ARROW",
            userScreen:this.props.selectedContentWall.toUpperCase() 
        }) */
    }

renderBody = () =>{
    let leftArrowClass = "ArrowLeft"
    let rightArrowClass = "ArrowRight"
    let rotateArrow = ""
    if (this.props.RTL){
        rotateArrow = "RotateArrow"
        leftArrowClass = "ArrowLeftRTL"
        rightArrowClass = "ArrowRightRTL"
    }
    return(
        <div  className={classes.PlanWall} >
            {this.state.showBackArrow ?(
            <img className={[classes.Arrow,classes[leftArrowClass],classes[rotateArrow]].join(" ")} alt = "" src={arrowIconLeft} onClick={this.scrollLeftHandler}/>):null}
         <div className={classes.CalendarDays} ref={this.state.calendarRef}>
        {this.props.planDays.map((day, index) => {
            if (index!==0 || this.props.isEditor)
           
            return(
                <div className={classes.CalendarDay}  key={index}>
                   
                <PlanDayObject
                  numberOfPlanDays = {this.props.planDays.length}
                    planLastModified = {this.props.planLastModified}
                    isEditor={this.props.isEditor}
                    calendarDay = {true}
                    ongoingDay={this.props.ongoingDay}
                    editorsNumber={this.props.editorsNumber}
                    key={index}
                    index={index}
                    dayName={day.name}
                    dayItems={day.dayItems}
                    selected={day.selected}
                    showDaySelection={this.props.showDaySelection}
                    viewHandler={itemIndex=>{this.props.viewHandler(index,itemIndex)}}
                  
                    updateColor={(colorNumber,itemIndex)=>{this.props.updateColor(colorNumber,index,itemIndex)}}
                    updatePlaceTime={(time,itemIndex)=>{this.props.updatePlaceTime(time,index,itemIndex)}}
                    dayNames = {this.props.planDays.map(day=>{return day.name})}
                    moveDayHandler={(itemIndex,newDayIndex,type)=>{this.props.moveDayHandler(index,itemIndex,newDayIndex,type)}}
                    hoverHandler={this.props.hoverHandler}
                    clickedMarkerPlaceID={this.props.clickedMarkerPlaceID}
                    updateDayOrder={this.props.updateDayOrder}
                    removeFromPlan={(itemIndex)=>{this.props.removeFromPlan(index,itemIndex)}}
                    daySelectHandler={()=>{this.props.daySelectHandler(index)}}

                    newNoteHandler={()=>{this.props.newNoteHandler(index)}}
                    openModeNoteId = {this.props.openModeNoteId}
                    newNoteID = {this.props.newNoteID}
                    editNoteID = {this.props.editNoteID}
                    noteOpenHandler={(itemIndex,value)=>{this.props.noteOpenHandler(index,itemIndex,value)}}
                    updateNote={(note,itemIndex)=>{this.props.updateNote(note,index,itemIndex)}}


                    newTransportHandler={()=>{this.props.newTransportHandler(index)}}
                    voteHandler={(itemIndex,value)=>{this.props.voteHandler(value,index,itemIndex)}}

                    addAccommodationHandler = {this.props.addAccommodationHandler}

                    locationsTranslator = {this.props.locationsTranslator}
                    categoriesTranslator = {this.props.categoriesTranslator}

                    writeAcitivityLog = {this.props.writeAcitivityLog}

                    showSuggestedTrips = {this.props.showSuggestedTrips}
                    
                    RTL = {this.props.RTL}
                    user={this.props.user}
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                />
                
            </div>
            )
            
            } )}
            </div>
               {this.state.showForwardArrow ?(
                 <img src={arrowIconRight} className={[classes.Arrow,classes[rightArrowClass],classes[rotateArrow]].join(" ")} alt="" onClick={this.scrollRightHandler}/>):null}  
        </div>
    )
    }


    render(){
        let RTL = ""
        if (this.props.RTL){
            RTL = "RTL"
        }
        return(
        <div className={[classes.Body,classes[RTL]].join(" ")}>
            {this.renderBody()}
        </div>)
    }
}

export default PlanCalendar;