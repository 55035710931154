import React, { Component } from "react";

class ShouldPlaceRender extends Component {
  
    shouldRender = (args) =>{
        return new Promise(async (resolve, reject) => {
          let place = args.place;
          let discoverPlacesNameFilterTag = args.discoverPlacesNameFilterTag
          let discoverPlacesAdditionalTagFilterTag = args.discoverPlacesAdditionalTagFilterTag
          let placeCharacteristicsFilters = args.placeCharacteristicsFilters
          let discoverPlacesDaysFilter = args.discoverPlacesDaysFilter
          let showPlace = false
          if (discoverPlacesNameFilterTag.length >0){
            for (let j=0 ; j<discoverPlacesNameFilterTag.length ; j++){
              if (place.placeName[discoverPlacesNameFilterTag[j].language]===discoverPlacesNameFilterTag[j].tag[discoverPlacesNameFilterTag[j].language]){
                showPlace=true;
              }
            }
            resolve(showPlace)
            
          }
          
          else if (placeCharacteristicsFilters!==undefined || discoverPlacesAdditionalTagFilterTag.length>0){
            let additionalTagMatch = true
            let characteristicsMatch = true;
            let selectedDaysMatch = true;
            if (discoverPlacesAdditionalTagFilterTag.length>0){
              for (let j=0 ; j<discoverPlacesAdditionalTagFilterTag.length ; j++){
                let additionalTagLanguage = discoverPlacesAdditionalTagFilterTag[j].language
                //must have all the search tags
              
                let placeTags = place.placeTags;
                if (placeTags!==undefined){
                  let placeTagsIDs = placeTags.map(tag=>{if (tag[additionalTagLanguage]!==undefined) return tag[additionalTagLanguage]})
                  if (!placeTagsIDs.includes(discoverPlacesAdditionalTagFilterTag[j].tag[additionalTagLanguage])){
                    additionalTagMatch = false
                  }
                }
                else{
                  additionalTagMatch = false
                }
              }
            } 
            if (Object.keys(placeCharacteristicsFilters).length>0){
              //have some characteristics filters             
              characteristicsMatch= this.handlePlaceCharacteristicsFilters(place,placeCharacteristicsFilters);       
            }
            if (JSON.stringify(discoverPlacesDaysFilter)!==JSON.stringify([0,0,0,0,0,0,0])){
              selectedDaysMatch = this.handleDiscoverPlacesDaysFilter(place,discoverPlacesDaysFilter);   
            }
            resolve(characteristicsMatch&&selectedDaysMatch&&additionalTagMatch)
          }
          else  resolve(true)
        });
    }

    handlePlaceCharacteristicsFilters = (place,placeCharacteristicsFilters) =>{
      //return only places that have exactly the filtered characteristics and not return these that are not defined for it
        let charFiltersPass=true;
        //general tags
        if (placeCharacteristicsFilters.generalTags!==undefined){
          if (placeCharacteristicsFilters.generalTags.length>0){
            if (place.placeCharacteristics!==undefined){
              if (place.placeCharacteristics.generalTags!==undefined){
                for (let i=0 ; i<placeCharacteristicsFilters.generalTags.length ; i++){
                  if (!place.placeCharacteristics.generalTags.includes(placeCharacteristicsFilters.generalTags[i])){
                    charFiltersPass = false;
                  }
                }
              }
              else{
                charFiltersPass = false;
              }
            }
            else{
              charFiltersPass = false;
            }
          }
        }
        //audience tags
        if (placeCharacteristicsFilters.audienceTags!==undefined){
          if (placeCharacteristicsFilters.audienceTags.length>0){
            if (place.placeCharacteristics!==undefined){
              if (place.placeCharacteristics.audienceTags!==undefined){
                for (let i=0 ; i<placeCharacteristicsFilters.audienceTags.length ; i++){
                  if (!place.placeCharacteristics.audienceTags.includes(placeCharacteristicsFilters.audienceTags[i])){
                    charFiltersPass = false;
                  }
                }
              }
              else{
                charFiltersPass = false;
              }
            }
            else{
              charFiltersPass = false;
            }
          }
        }
        //main tags
        if (placeCharacteristicsFilters.mainTags!==undefined){
          if (placeCharacteristicsFilters.mainTags.length>0){
            if (place.placeCharacteristics!==undefined){
              if (place.placeCharacteristics.mainTags!==undefined){
                for (let i=0 ; i<placeCharacteristicsFilters.mainTags.length ; i++){
                  if (!place.placeCharacteristics.mainTags.includes(placeCharacteristicsFilters.mainTags[i])){
                    charFiltersPass = false;
                  }
                }
              }
              else{
                charFiltersPass = false;
              }
            }
            else{
              charFiltersPass = false;
            }
          }
        }

        //sub tags
        if (placeCharacteristicsFilters.subTags!==undefined){
          if (placeCharacteristicsFilters.subTags.length>0){
            if (place.placeCharacteristics!==undefined){
              if (place.placeCharacteristics.subTags!==undefined){
                for (let i=0 ; i<placeCharacteristicsFilters.subTags.length ; i++){
                  if (!place.placeCharacteristics.subTags.includes(placeCharacteristicsFilters.subTags[i])){
                    charFiltersPass = false;
                  }
                }
              }
              else{
                charFiltersPass = false;
              }
            }
            else{
              charFiltersPass = false;
            }
          }
        }

        return charFiltersPass;
    }
      
    handleDiscoverPlacesDaysFilter  = (place,discoverPlacesDaysFilter) =>{
      //return both open places and these that not defined
        let dayFilterPass=true;
        for (let i = 0 ; i<discoverPlacesDaysFilter.length ; i++){
          if (discoverPlacesDaysFilter[i]){
            if (place.openDays[i]===0){
              dayFilterPass = false;
            }
          }
        }
        return dayFilterPass;
    }

    sortByOpenDay = args =>{
      return new Promise(async (resolve, reject) => {
        let sortedList = []
       
        let openedPlaces = []
        let undefinedOpen = []
        let openedPlacesIDs = []
        let undefinedPlacesIDs = []

        let currentList = args.currentList
        let discoverPlacesDaysFilter = args.discoverPlacesDaysFilter

        for (let i=0 ; i<currentList.length ; i++){
          for (let j = 0 ; j<discoverPlacesDaysFilter.length ; j++){
            if (discoverPlacesDaysFilter[j]){
              if (currentList[i].openDays[j]===1){
                if (!openedPlacesIDs.includes(currentList[i].placeID)){
                  openedPlaces.push(currentList[i])
                  openedPlacesIDs.push(currentList[i].placeID)
                }
               
              }
              else{
                if (!undefinedPlacesIDs.includes(currentList[i].placeID)){
                  undefinedOpen.push(currentList[i])
                  undefinedPlacesIDs.push(currentList[i].placeID)
                }
               
              }
            }
          }
        }
        sortedList = openedPlaces
        sortedList = sortedList.concat(undefinedOpen)

        resolve(sortedList)

      });
    }

  render(){
    return (
        <div></div>
      );
  }
    
  }
  export default ShouldPlaceRender;
