import React, {useState} from "react";
import classes from "./Tag.module.css";

import { isMobile } from "react-device-detect";
let closeIcon = ""

const Tag = props => {
  const [hover, setHover] = useState(false);

  const renderBody = () =>{
    let closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"
    if (props.style==="CharacteristicSelected" || props.style==="DislikeCategory"){
      closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FRemoveIconWhite.png?alt=media&token=ad7d419f-e9ae-4ebc-9f6f-f460cb4cfa9f"
    }
    let bodyClass = "Body"
    let iconClass = "Icon"
    let clickableClass = ""
    if (props.RTL){
      bodyClass = "BodyRTL"
      iconClass = "IconRTL"
    }
    if (props.clickable){
      clickableClass = "ClickableBody"
    }
    let resizeOnHover=""
    if (props.removable){
      resizeOnHover="ResizeOnHover"
    }
    let renderIcon = false;
    if (props.removable){
      if (isMobile){
        renderIcon = true
      }
      else if(hover){
        renderIcon = true
      }
    }
    return(
      <div className={[classes[bodyClass],classes[props.style],classes[clickableClass],classes[resizeOnHover]].join(" ")}
      onClick={props.clicked}
      onMouseEnter={()=>{setHover(true)}}
      onMouseLeave={()=>{setHover(false)}}
      >
          <div>{props.text}</div>
            {renderIcon?(
              <img src={closeIcon} 
            onClick = {props.remove}
            alt="" className={classes[iconClass]}/>
            ):null}
            
      </div>
    )
  }
  return (
    <div>
        {renderBody()}
    </div>
  );
};

export default Tag;
