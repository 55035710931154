import React, { useState, useEffect } from "react";
import classes from "./UserItem.module.css";



const UserItem = props => {
  const [tagState, updateTagState] = useState({
    tagValue: props.tag,
    isPaused: false
  });

  const deleteTag = () => {
    props.delete();
  };


  const viewHandler = () =>{
      if (props.viewHandler!==undefined){
          props.viewHandler(props.user)
      }
  }


  const renderSearchFriendsUser = () =>{
    let status = "Ask Request";
    for (let i=0; i<props.existingList.length;i++){
        if (props.existingList[i].userID===props.user.userID){
            if (props.existingList[i].status==="Pending"){
                status="Pending"
            }
            if (props.existingList[i].status==="Friend"){
                status="Friend"
            }
        }
    }
    for (let i=0; i<props.usersListFollow.length;i++){
        if (props.usersListFollow[i].userID===props.user.userID){
            status="Following"
        }
    }
    return (
        <div className={classes.SearchFriends}>
          <div className={classes.UserSection}>
              <img src={props.user.photoUrl} alt="" className={classes.UserImage}/>
              <div className={classes.UserName}>{props.user.displayName}</div>
              </div>
           {renderTools(status)}
          
        </div>
      );
    }

    const renderTools = (status) =>{
        let removeMessage = props.systemText.users.userItem.removePrefix[props.userLanguage]+props.user.displayName+props.systemText.users.userItem.removePostfix[props.userLanguage]
        switch(status){
            case "Friend":{
                return(
                    <div className={classes.ToolsSection}>
                  {/*   <div className={classes.Friend}>
                    {props.systemText.users.userItem.friend[props.userLanguage]}
                    </div> */}
                    <div className={classes.Remove}  onClick={(e)=>{ e.stopPropagation();props.removeFriend(props.user,removeMessage)}}>
                    {props.systemText.users.userItem.remove[props.userLanguage]}
                    </div>
                 </div>
                );
                break;
            }
            case "Pending":{
             return(
                 <div className={classes.ToolsSection}>
                    <div className={classes.RequestSent}>
                    {props.systemText.users.userItem.requestSent[props.userLanguage]}
                    </div>
                   <div className={classes.Remove}  onClick={(e)=>{ e.stopPropagation();props.removeFriend(props.user,removeMessage)}}>
                   {props.systemText.users.userItem.remove[props.userLanguage]}
                   </div>
                </div>
                );
                break;
            }
            case "Ask Request":{
                return(
                    <div className={classes.ToolsSection}>
                        <div className={classes.AskRequest}  onClick={(e)=>{ e.stopPropagation();props.requestFriendship(props.user)}}>
                        {props.systemText.users.userItem.requestFriendship[props.userLanguage]}
                        </div>
                        <div className={classes.AskFollow}  onClick={(e)=>{ e.stopPropagation();props.followUser(props.user)}}>
                        {props.systemText.users.userItem.requestFollow[props.userLanguage]}
                        </div>
                    </div>
                    );
                break;
            }
            case "New Request":{
                return(
                    <div className={classes.ToolsSection}>
                        <div className={classes.Accept}  onClick={(e)=>{ e.stopPropagation();props.acceptFriendship(props.user)}}>
                        {props.systemText.users.userItem.accept[props.userLanguage]}
                        </div>
                        <div className={classes.Decline}  onClick={(e)=>{ e.stopPropagation();props.removeFriend(props.user, props.systemText.users.userItem.declineFriendship[props.userLanguage])}}>
                        {props.systemText.users.userItem.decline[props.userLanguage]}
                        </div>
                    </div>
                );
                break;
            }
            case "Following":{
                return(
                    <div className={classes.ToolsSection}>
                       {/*  <div className={classes.RequestSent}>
                         {props.systemText.users.userItem.followed[props.userLanguage]}
                        </div> */}
                        <div className={classes.Remove}  onClick={(e)=>{ e.stopPropagation();props.stopFollow(props.user,props.systemText.users.unfollowMessage[props.userLanguage])}}>
                            {props.systemText.users.userItem.stopFollow[props.userLanguage]}
                        </div>
                    </div>
                );
                break;
            }
            default: return null;
        }
    }

    const renderFollowTools = () =>{
        return(
            <div className={classes.ToolsSection}>
                {/*  <div className={classes.RequestSent}>
                    {props.systemText.users.userItem.followed[props.userLanguage]}
                </div> */}
                <div className={classes.Remove}  onClick={(e)=>{ e.stopPropagation();props.stopFollow(props.user,props.systemText.users.unfollowMessage[props.userLanguage])}}>
                   {props.systemText.users.userItem.stopFollow[props.userLanguage]}
                </div>
            </div>
        );
    }

    const renderMyFriendsUser =()=>{
               return(
                <div className={classes.SearchFriends}>
                <div className={classes.UserSection}>
                    <img src={props.user.photoUrl} alt="" className={classes.UserImage}/>
                    <div className={classes.UserName}>{props.user.displayName}</div>
                    </div>
                  {renderTools(props.user.status)}
                
              </div> 
               );
    }

    const renderExistingFriendTools = () =>{
        let removeMessage = props.systemText.users.userItem.removeMessage[props.userLanguage]
        let inExistingList=false;
        for (let i=0; i<props.existingList.length;i++){
            if (props.existingList[i].userID===props.user.userID){
                inExistingList=true;
            }
        }
        if (inExistingList){
            if (props.isOwner){
                if (props.ownerID!==props.user.userID){
                    return( <div className={classes.ToolsSection}>
                    <div className={classes.Remove}  onClick={(e)=>{ e.stopPropagation();props.removeFriend(props.user,removeMessage)}}>
                        {props.systemText.users.userItem.remove[props.userLanguage]}
                    </div>
                    </div>)
                }
            }
        }
        else{
            return( <div className={classes.ToolsSection}>
                <div className={classes.Accept}  onClick={(e)=>{ e.stopPropagation();props.addToList(props.user)}}>
                    {props.systemText.users.userItem.add[props.userLanguage]}
                </div>
             </div>)
        }
    }

    const renderSearchExistingFriendItem = () =>{
        return(
            <div className={classes.SearchFriends}>
            <div className={classes.UserSection}>
                <img src={props.user.photoUrl} alt="" className={classes.UserImage}/>
                <div className={classes.UserName}>{props.user.displayName}</div>
                </div>
              {renderExistingFriendTools()}
            
          </div> 
           );
    }

    const renderFollowingSearchItem = () =>{
        return(
            <div className={classes.SearchFriends}>
            <div className={classes.UserSection}>
                <img src={props.user.photoUrl} alt="" className={classes.UserImage}/>
                <div className={classes.UserName}>{props.user.displayName}</div>
                </div>
              {renderFollowTools()}
            
          </div> 
           );
    }

  const renderBody = () => {
    switch (props.type) {
      case "searchFriends": {
        return renderSearchFriendsUser();
        break;
      }
      case "myFriends":{
          return renderMyFriendsUser();
          break;
      }
      case "searchExistingFriend":{
        return renderSearchExistingFriendItem();
        break;
      }
      case "followingList":{
        return renderFollowingSearchItem();
        break;
      }
      default:
        return null;
    }
  };

  return <div className={classes.Body} onClick={viewHandler}>{renderBody()}</div>;
};

export default UserItem;
