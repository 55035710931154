import React, { Component } from "react";
import classes from "./MyContent.module.css";

import PlacesWall from "../Content/Places/PlacesWall"
import PlansWall from "../Content/TripPlans/PlansWall"
import ExperiencesWall from "../Content/Experiences/ExperiencesWall"
import SimpleSearchBar from "../../Components/Complex/SearchBars/SimpleSearchBar";
import SearchBarContentType from "../../Components/UI/ContentTypeSelector/SearchBarContentType"
import ScrollToTop from "../../Components/UI/Buttons/ScrollToTop"

import Tag from "../../Components/UI/Tag/Tag";

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"


export default class MyContent extends Component {

    state={
      showDreamStageTrips: true,
      showCurrentTrips: true,
      showFutureTrips: true,
      showPastTrips: true
    }

    componentDidMount = () =>{
      if (this.props.experiences!==undefined && this.props.selectedContentType==="Experiences"){
        var scrollComponent = this;
        var frame = document.getElementById("UserExperiences");
        if (frame===null) return
        frame.addEventListener("scroll", function(e) {
          scrollComponent.experiencesScrollToggle();
        });      
     }
    }

    componentDidUpdate = prevProps =>{
      if (this.props.experiences!==prevProps.experiences){
        if (this.props.experiences!==undefined && this.props.selectedContentType==="Experiences"){
            var scrollComponent = this;
            var frame = document.getElementById("UserExperiences");
            frame.addEventListener("scroll", function(e) {
              scrollComponent.experiencesScrollToggle();
            });      
        }
      }
      if (this.props.selectedContentType!==prevProps.selectedContentType){
        this.setState({showScrollUpButton:false})
        if (this.props.selectedContentType==="Experiences"){
          var scrollComponent = this;
            var frame = document.getElementById("UserExperiences");
            if (frame!==null){
              frame.addEventListener("scroll", function(e) {
                scrollComponent.experiencesScrollToggle();
              });   
            }
              
        }
      }
    }

    scrollExperiencesToTop = () =>{
      var frame = document.getElementById("UserExperiences");
      frame.scrollTo({
          top: 0,
          behavior: "smooth"
      });
    }
  
    experiencesScrollToggle = () =>{
      var frame = document.getElementById("UserExperiences");
      if (frame===null) return
      let scrollTop = frame.scrollTop
      if ((scrollTop>=(frame.scrollHeight-frame.offsetHeight-1000))&&
      this.props.loadedMoreExperiencesToFeed){
          this.props.loadMoreExperiencesFeedHandler()
      }
      if (frame.scrollTop>window.innerHeight){
        this.setState({showScrollUpButton:true})
      }
      else{
          this.setState({showScrollUpButton:false})
      }
    }

    deletePlanHandler= index =>{
      let plan = this.props.plans[index];
      this.props.deletePlanHandler(plan);
  }

  leavePlanHandler = (sharedPlan,planEditors) =>{
    this.props.updatePlanEditors(sharedPlan,this.props.user,false,planEditors);
    this.props.updateList("DeleteUserTripPlan",sharedPlan.planID,);
  }

  clonePlan = index =>{
    let plan = this.props.plans[index];
    this.props.clonePlanHandler(plan);
  }

  editExperienceHandler= index =>{
    let experience  = this.props.experiences[index];
    this.props.editExperienceHandler(experience);
  }

  removeTaggingHandler= index =>{
    let experience  = this.props.experiences[index];
    this.props.removeTaggingHandler(experience);
  }

  deleteExperienceHandler= index =>{
    let experience = this.props.experiences[index];
    this.props.deleteExperienceHandler(experience);
  }

  addExperienceToPlanHandler= index =>{
    let experience  = this.props.experiences[index];
    this.props.addToPlanHandler(experience,"Experience");
  }

  removeExperienceFromPlanHandler= index =>{
    let experience  = this.props.experiences[index];
    this.props.removeExperienceFromPlanHandler(experience);
  }


    renderSearchBarSection = () =>{
        let searchBarSectionClass = "SearchBarSection"
        if (this.props.RTL){
            searchBarSectionClass = "SearchBarSectionRTL"
        }
      //for places
      let searchTags = []
      let placeHolderMessage = ""
      if (this.props.selectedContentType==="Places"){
        searchTags = this.props.userCreatedPlacesSearchTags;
        placeHolderMessage = this.props.systemText.myContent.searchSection.searchBarPlacesPlaceholder[this.props.userLanguage];
      }  
      else if (this.props.selectedContentType==="Experiences"){
        searchTags = this.props.userExperiencesSearchTags;
        placeHolderMessage = this.props.systemText.myContent.searchSection.searchBarExperiencesPlaceholder[this.props.userLanguage];
      }
      else if (this.props.selectedContentType==="Trips"){
        searchTags = this.props.userCreatedTripPlansSearchTags
        placeHolderMessage = this.props.systemText.myContent.searchSection.searchBarTripsPlaceholder[this.props.userLanguage];
      }
       
      return(
          <div className={classes.Toolbar}>
            <div className={classes[searchBarSectionClass]}>
              <SearchBarContentType
                    selectedContentType = {this.props.selectedContentType}
                    updateSelectedContentType = {this.props.updateSelectedContentType}

                    showExperiences = {true}
                    showTrips = {true}
                    RTL = {this.props.RTL}
                    systemText = {this.props.systemText}
                    userLanguage = {this.props.userLanguage}
                />
                <SimpleSearchBar
                    searchTags = {searchTags}
                    placeholderMessage = {placeHolderMessage}
                    detectLanguage = {this.props.detectInputLanguage}
                    inputLanguage = {this.props.inputLanguage}
                    RTL = {this.props.RTL}
                    systemText = {this.props.systemText}
                    userLanguage = {this.props.userLanguage}
                    updateFilterTags = {this.props.userContentSearchHandler}
                    locationsTranslator = {this.props.locationsTranslator}
                />
               
                 </div>
                 {this.renderFilterTags()}
        </div>
      )
    }

    renderFilterTags = () =>{
     
      if (this.props.selectedContentType==="Places"){
        return(
          <div className={classes.TagsList}>
          {this.props.userCreatedPlacesFilterTags.map((tag, index) => (
                <Tag
                key={index}
                text={tag.tag[tag.language]}
                removable={true}
                style={"FavoritePreviewTag"}
                remove={() => {this.props.userContentRemoveTagHandler(index)}}
                RTL = {this.props.RTL}
              />
        ))} 
       
        </div>
        )
      }
      else if (this.props.selectedContentType==="Experiences"){
        return(
          <div className={classes.TagsList}>
          {this.props.userExperiencesFilterTags.map((tag, index) => (
                <Tag
                key={index}
                text={tag.tag[tag.language]}
                removable={true}
                style={"FavoritePreviewTag"}
                remove={() => {this.props.userContentRemoveTagHandler(index)}}
                RTL = {this.props.RTL}
              />
        ))} 
       
        </div>
        )
       
      }
      else if (this.props.selectedContentType==="Trips"){
        return(
          <div className={classes.TagsList}>
          {this.props.userCreatedTripPlansFilterTags.map((tag, index) => (
                <Tag
                key={index}
                text={tag.tag[tag.language]}
                removable={true}
                style={"FavoritePreviewTag"}
                remove={() => {this.props.userContentRemoveTagHandler(index)}}
                RTL = {this.props.RTL}
              />
        ))} 
       
        </div>
        )
       
      }
    }

    renderPlaces = () =>{
      if (this.props.places.length===0){
        if (this.props.userCreatedPlacesFilterTags.length>0){
          return( <div  className={classes.EmptyWall}>
            <div className={classes.NoContentMessage}>
              {this.props.systemText.myContent.noContentMessage.noPlacesResults[this.props.userLanguage]}
            </div>
          </div>)
        }
        else{
          return( <div  className={classes.EmptyWall}>
            <div className={classes.NoContentMessage}>
              {this.props.systemText.myContent.noContentMessage.noPlaces[this.props.userLanguage]}
            </div>
          </div>)
        }
       
      }
      else{
        return(       
            <div  className={classes.PlacesWall}>
                <PlacesWall
                 loggedUser = {this.props.loggedUser}
                 userLanguage={this.props.userLanguage}
                 systemText = {this.props.systemText}
                 RTL = {this.props.RTL}
                 inputLanguage = {this.props.inputLanguage}
                 user={this.props.user}

                 categoriesTranslator = {this.props.categoriesTranslator}
                 locationsTranslator = {this.props.locationsTranslator}

                 placesList={this.props.places}
                 favoritePlacesIDs={this.props.favoritePlacesIDs}

                 viewType={"List"}
                 selectedContentWall={this.props.selectedContentWall}
                 deletePlaceHandler={this.props.deletePlaceHandler}
                 viewHandler={this.props.viewPlaceHandler}
                 editPlaceHandler={this.props.editPlaceHandler}
                 addToPlan={this.props.addToPlanHandler}
                 addPlaceToFavorite={this.props.addToFavoriteHandler}
                 removePlaceFromFavorite={this.props.removeFromFavoriteHandler}
                 updateFavoriteTags={this.props.updateFavoriteTagsHandler}
                 shareExperience={this.props.shareExperience}
                  />
            </div>
        )
      }
    }

    renderTripsPlans = () =>{
      if (this.props.plans.length===0){
        if (this.props.userCreatedTripPlansFilterTags.length>0){
          return( <div  className={classes.EmptyWall}>
            <div className={classes.NoContentMessage}>
              {this.props.systemText.myContent.noContentMessage.noTripsResults[this.props.userLanguage]}
            </div>
          </div>)
        }
        else{
          return( 
          <div  className={classes.EmptyWall}>
            <div className={classes.NoContentMessage}>
              {this.props.systemText.myContent.noContentMessage.noTrips[this.props.userLanguage]}
            </div>
            <div className={classes.CallToAction} onClick={()=>{this.props.createNew("Trip")}}>
            {this.props.systemText.myContent.noContentMessage.createTrip[this.props.userLanguage]}
            </div>
          </div>)
        }
      }
      else{
      return (
        <div  className={classes.PlansWall}>
        <PlansWall
            plansList={this.props.plans}
            user={this.props.user}
            viewHandler={this.props.viewPlanHandler}
            leavePlan={this.leavePlanHandler}
            deleteHandler={this.deletePlanHandler}
            selectedContentWall={this.props.selectedContentWall}
            clonePlan={this.clonePlan}

            locationsTranslator = {this.props.locationsTranslator}

            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
        />
        </div>
       );
      }
    }

    renderExperiences = () =>{
      if (this.props.experiences.length===0){
        if (this.props.userExperiencesFilterTags.length>0){
          return( <div  className={classes.EmptyWall} id="UserExperiences">
            <div className={classes.NoContentMessage}>
              {this.props.systemText.myContent.noContentMessage.noExperiencesResults[this.props.userLanguage]}
            </div>
          </div>)
        }
        else{
          return( <div  className={classes.EmptyWall} id="UserExperiences">
          <div className={classes.NoContentMessage}>
            {this.props.systemText.myContent.noContentMessage.noExperiences[this.props.userLanguage]}
          </div>
          <div className={classes.CallToAction} onClick={()=>{this.props.createNew("Experience")}}>
            {this.props.systemText.welcomePage.experiences.shareExperience[this.props.userLanguage]}
            </div>
        </div>)
        }
       
      }
      else{
      return (
        <div  className={classes.ExperiencesWall} id="UserExperiences">
        <ExperiencesWall
            experiencesList={this.props.experiences}
            user={this.props.user}
            viewStyle={"wall"}
            deleteHandler={this.deleteExperienceHandler}
            editHandler={this.editExperienceHandler}
            removeTaggingHandler = {this.removeTaggingHandler}
            viewExperiencePlace={this.props.viewExperiencePlace}
            viewExperiencePlan={this.props.viewExperiencePlan}
            selectedContentWall={this.props.selectedContentWall}
            addToPlan={this.addExperienceToPlanHandler}
            removeFromPlan={this.removeExperienceFromPlanHandler}
            viewImagesFullscreen = {this.props.viewImagesFullscreen}

            writeAcitivityLog = {this.props.writeAcitivityLog}
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
        />
        </div>
      );
      }
    }

    renderTripStagesSelection = () =>{
      let checkIconClass = "CheckIcon"
      let uncheckBoxClass = "UncheckBox"
      if (this.props.RTL){
        checkIconClass = "CheckIconRTL"
        uncheckBoxClass = "UncheckBoxRTL"
      }
      return(
        <div className={classes.TripStagesSelection}>
          <div className={classes.TripStage}>
            {this.state.showDreamStageTrips?(
               <img src={checkedBox} alt="" className={classes[checkIconClass]} 
               onClick={()=>{this.setState({showDreamStageTrips:false})}}/>
            ):(
              <div className={classes[uncheckBoxClass]}
              onClick={()=>{this.setState({showDreamStageTrips:true})}} />
            )}
            {this.props.systemText.myContent.tripsStages.dreamStageTrips[this.props.userLanguage]}
          </div>
          <div className={classes.TripStage}>
            {this.state.showDreamStageTrips?(
               <img src={checkedBox} alt="" className={classes[checkIconClass]} 
               onClick={()=>{this.setState({currentTrips:false})}}/>
            ):(
              <div className={classes[uncheckBoxClass]}
              onClick={()=>{this.setState({currentTrips:true})}} />
            )}
            {this.props.systemText.myContent.tripsStages.currentTrips[this.props.userLanguage]}
          </div>
          <div className={classes.TripStage}>
            {this.state.showDreamStageTrips?(
               <img src={checkedBox} alt="" className={classes[checkIconClass]} 
               onClick={()=>{this.setState({showFutureTrips:false})}}/>
            ):(
              <div className={classes[uncheckBoxClass]}
              onClick={()=>{this.setState({showFutureTrips:true})}} />
            )}
            {this.props.systemText.myContent.tripsStages.futureTrips[this.props.userLanguage]}
          </div>
          <div className={classes.TripStage}>
            {this.state.showDreamStageTrips?(
               <img src={checkedBox} alt="" className={classes[checkIconClass]} 
               onClick={()=>{this.setState({showPastTrips:false})}}/>
            ):(
              <div className={classes[uncheckBoxClass]}
              onClick={()=>{this.setState({showPastTrips:true})}} />
            )}
            {this.props.systemText.myContent.tripsStages.pastTrips[this.props.userLanguage]}
          </div>
        </div>
      )
    }

    renderWall = () =>{
        switch (this.props.selectedContentType) {
          
            case "Places": {
              return  <div className={classes.Wall} style={{minWidth:"100%"}} id="MyContentWall" >{this.renderPlaces()}</div>;
              break;
            }
            case "Trips":{
              return  <div className={classes.Wall} style={{minWidth:"100%"}} id="MyContentWall">
               {/*  {this.renderTripStagesSelection()} */}
                {this.renderTripsPlans()}
                </div>;
              break;
            }
            case "Experiences":{
              return  <div className={classes.Wall} style={{minWidth:"100%"}} >{this.renderExperiences()}</div>;
              break;
           }
            default:
              return null;
          }
    }
    render(){
        let bodyClass = "Body"
        if (this.props.RTL){
          bodyClass = "BodyRTL"
        }
          return(
              <div className={classes[bodyClass]}>
                 {this.state.showScrollUpButton?(<ScrollToTop scrollToTop={this.scrollExperiencesToTop}/>):null}
                  {this.renderSearchBarSection()}
                  {this.renderWall()}
              </div>
          )
      }
  }