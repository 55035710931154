import React, { Component } from "react";
import classes from "./Note.module.css";

import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"
import Links from "../Links/Links"
import TextButton from "../../UI/Buttons/TextButton"

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState , convertFromRaw, convertToRaw} from 'draft-js';

import imageCompression from "browser-image-compression";

import {isMobile} from 'react-device-detect'; 

let attachIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAttachment.png?alt=media&token=c49f6954-8969-4f28-bf62-39bf5b0e78ee"
let removeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"
let editIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIcon.png?alt=media&token=c964d68a-44ba-45bf-afd7-b4b8eaeb6fba"

class Note extends Component {
    state={
        noteTopic:this.props.note.noteTopic,
        noteText:this.props.note.noteText,
        imageGallery: [],
        imagePreview: [],
        deletedImages:[],
        attachedFiles: [],
        deletedFiles:[],
        noteLinks:[],
        editMode: false
    }

    componentDidUpdate=(prevProps)=>{
        if (this.props.note.noteLastModified!==prevProps.note.noteLastModified){
            this.initializeNoteData()
        }
        if (this.props.openMode!==prevProps.openMode){
            if (!this.props.openMode){
                this.setState({editMode:false})
            }
            else{
                this.setState({editMode:true})
            }
        }
        if (this.props.editNoteID!==prevProps.editNoteID){
            if (this.props.editNoteID===this.props.note.noteID){
                this.setState({editMode:true})
            }
        }
      }

      componentDidMount=()=>{
        this.initializeNoteData()
        if (this.props.editNoteID===this.props.note.noteID){
            this.setState({editMode:true})
        }
      }

    initializeNoteData = () =>{
        this.setState({
            noteTopic:this.props.note.noteTopic, editMode: false
        })
        if (this.props.note.attachedFiles===undefined){
            this.setState({attachedFiles:[]})
        }
        else{
            this.setState({attachedFiles:[...this.props.note.attachedFiles]})
        }
        if (this.props.note.noteLinks===undefined){
            this.setState({noteLinks:[]})
        }
        else{
            this.setState({noteLinks:this.props.note.noteLinks})
        }
        if (this.props.note.imagePreview===undefined){
            this.setState({imagePreview:[]})
        }
        else{
            this.setState({imagePreview:this.props.note.imagePreview})
        }
        if (this.props.note.imageGallery===undefined){
            this.setState({imageGallery:[]})
        }
        else{
            this.setState({imageGallery:this.props.note.imageGallery})
        }
        try {
          JSON.parse(this.props.note.noteText);
          this.setState({noteText: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.note.noteText))),
            jsonString: true
            })
      } catch (e) {
     
      }
    }

    discard = () =>{
        this.setState({
            showBackdrop: false
        })
    }

    topSectionClickedHandler = () =>{
        if (!this.props.openMode){
            this.props.noteOpenHandler(this.props.index)
        }
    }

    updateNoteTitle = async (e) =>{
        await this.setState({noteTopic:e.target.value});
    }

    updateNoteInfo = async (noteText) =>{
        this.setState({ noteText: noteText });
    }

    linksHandler = () => {
        this.setState({ showManageLinks: true,showBackdrop:true});
      };

    discardLinksnHandler = () => {
    this.setState({ showManageLinks: false, showBackdrop: false });
    };
    

    updateLinks = async noteLinks => {
        await this.setState({
            noteLinks: noteLinks,
        });
        this.props.writeAcitivityLog({
            activityKey:"TRIP_NOTE_EDIT_UPDATE_LINKS",
            userScreen:"VIEW_TRIP"
            })  
        this.updateNote() 
    };

    converImage = (url)=>{
        return new Promise(async (resolve, reject) => {
        var xhr = new XMLHttpRequest();
    
        // Use JSFiddle logo as a sample image to avoid complicating
        // this example with cross-domain issues.
        xhr.open( "GET", url, true );
        
        // Ask for the result as an ArrayBuffer.
        xhr.responseType = "arraybuffer";
        let myBlob
      
         xhr.onload =  function( e ) { 
            var arrayBufferView = new Uint8Array( this.response );
             myBlob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
            resolve(myBlob);
         }; 
        xhr.send();
      });
      }
  
      compressUploadedImage = async image => {
        let imageFile = image;
        let optionsLarge = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 2048,
          useWebWorker: true,
          maxIteration:20
        };
       
        try {
          const compressedFileLarge = await imageCompression(imageFile, optionsLarge);
          let attachedFiles = this.state.attachedFiles;
          attachedFiles.push(compressedFileLarge);
          this.setState({ attachedFiles: attachedFiles,
              changesMade: true });
        } catch (error) {
          console.log(error);
        }
      };
  
  
      attachmentUpploader = async event => {
          if (event.target.files[0]!==undefined){
  
            if (event.target.files[0].type.includes('image')){
              let objectURL = URL.createObjectURL(event.target.files[0])
              let myBlob;
              await this.converImage(objectURL).then(result => {
                  myBlob = result;
                  myBlob.name=event.target.files[0].name
                });
                this.compressUploadedImage(myBlob);
              //if uploading image compress image before attachment save
            }
            else{
              let attachedFiles = this.state.attachedFiles;
              attachedFiles.push(event.target.files[0]);
              await this.setState({ attachedFiles: attachedFiles,
                  changesMade: true });
            }
             
            this.props.writeAcitivityLog({
                activityKey:"TRIP_NOTE_EDIT_ADD_ATTACHMENT",
                userScreen:"VIEW_TRIP",
                attachmentType:event.target.files[0].type
            })   
          }
          this.updateNote()
      };
  
      deleteFileHandler = async index =>{
        let attachedFiles = this.state.attachedFiles;
        let deletedFiles = this.state.deletedFiles
        let deletedFile = attachedFiles[index]
        deletedFiles.push(deletedFile)
        attachedFiles.splice(index,1);
        await this.setState({attachedFiles:attachedFiles, deletedFiles:deletedFiles})

        this.props.writeAcitivityLog({
            activityKey:"TRIP_NOTE_EDIT_REMOVE_ATTACHMENT",
            userScreen:"VIEW_TRIP"
            })   
        this.updateNote()
      }


    updateNote = () =>{
        let date = new Date();
        let timestamp = date.getTime();
        let noteText=JSON.stringify(convertToRaw(this.state.noteText.getCurrentContent()));
        let note={
            noteCreationDate:this.props.note.noteCreationDate,
            noteLastModified:timestamp,
            noteTopic:this.state.noteTopic,
            noteID:this.props.note.noteID,
            imageGallery: this.state.imageGallery,
            imagePreview: this.state.imagePreview,
            deletedImages: this.state.deletedImages,
            noteText:noteText,
            attachedFiles:this.state.attachedFiles,
            deletedFiles: this.state.deletedFiles,
            owner:this.props.note.owner,
            noteLinks:this.state.noteLinks,
        }
        //console.log(note)
        this.setState({editMode:false})
        this.props.updateNote(note,this.props.index)
        this.props.noteOpenHandler(-1)
    }



    renderNoteTopSection = () =>{
        let topSectionClass = "TopSection"
        if (this.props.openMode){
            topSectionClass = "TopSectionOpen"
        }
        return(
            <div className = {classes[topSectionClass]} onClick={this.topSectionClickedHandler} >
                {this.renderTitle()}
              {/*   <div className={classes.Tools}>
                    {this.renderTools()}
                </div> */}
            </div>
        )
    }

    renderTools = () =>{
        if (this.state.editMode){
            return( <TextButton
                clicked={() => {
                  this.updateNote();
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_NOTE_UPDATE",
                    userScreen:"VIEW_TRIP"
                    })   
                }}
                text={this.props.systemText.plan.viewPlan.note.updateButton[this.props.userLanguage]}
                style = {"PositiveButton2"}
              />)
        }
        else{
            return (<img className={classes.EditIcon} alt="" 
            onClick={(e)=>{e.stopPropagation(); this.setState({editMode:true});  this.props.noteOpenHandler(this.props.index);
            this.props.writeAcitivityLog({
                activityKey:"TRIP_NOTE_EDIT",
                userScreen:"VIEW_TRIP"
                })            
            }} src={editIcon}/>)
            
        }
    }

    renderTitle = () =>{
        if (this.props.openMode&& this.state.editMode){
            return(
                <div className={classes.TitleSection}>
                    <input value={this.state.noteTopic} 
                    className={classes.TitleInput} 
                    placeholder={
                        this.props.systemText.plan.viewPlan.planning.tasks.titlePlaceholder[this.props.userLanguage]
                    }
                    onChange={this.updateNoteTitle}
                    />
                </div>
            )
        }
        else{
            let title = this.state.noteTopic
            if (title===""){
                title= this.props.systemText.plan.viewPlan.planning.tasks.titlePlaceholder[this.props.userLanguage]
            }
            return(
                <div className={classes.TitleSection}>
                    {title}
                </div>
            )
            
        }
    }

    
renderInfoEdit = () =>{
    let infoInputClass = "InfoInput"
    let toolbarClassName = "ToolbarClassname"
    let textSectionClass = "TextSection"
    if (this.props.RTL){
        infoInputClass = "InfoInputRTL"
        toolbarClassName = "ToolbarClassnameRTL"
        textSectionClass = "TextSectionRTL"
    }
    if (this.state.jsonString){
        if (this.state.editMode){
            return (
            <div className={classes.TextSection}>
                <Editor
                toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                
                }}
                placeholder={this.props.systemText.plan.viewPlan.planning.tasks.notesPlaceholder[this.props.userLanguage]}
                editorClassName={classes[infoInputClass]}
                toolbarClassName={classes[toolbarClassName]}
                    editorState={this.state.noteText}
                    onEditorStateChange={this.updateNoteInfo}
                    
                />
            </div>
            );
            }
        else{
            if (this.state.noteText.getCurrentContent().hasText()){
                return(
                  <div className={classes.TextSection}>
                  <Editor
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    
                    }}
                  readOnly={true}
                  toolbarHidden={true}
                  editorClassName={classes[infoInputClass]}
                  editorState={this.state.noteText}
                  wrapperClassName={classes.wrapperClassName}
                  />
                  </div>
                )
             }
        }
        }
  }

  renderLinks = () =>{
      return(
        <div className = {classes.Links}>
             {this.state.editMode?(
                <div className={classes.LinkButton}  onClick={this.linksHandler}>
                    {this.props.systemText.place.createPlace.linksSectionHeader[this.props.userLanguage]}
                </div>):null}
            {this.state.noteLinks.length>0?(
                <div className={classes.LinksList}>
                    {this.state.noteLinks.map((link, index) => (
                    <a href={link.url} target="_blank" key={index}>
                    <div className={classes.Link} key={index}>
                        {link.name}
                        </div>
                        </a> 
                    ))}
                </div>
            ):null}
        </div>
      )
  }

  renderAttachmentSection = () => {
    if (this.state.editMode||this.state.attachedFiles.length > 0){
        return (
        <div className={classes.AttachmentSection}>
            {this.state.editMode?(
                <div className={classes.UploadSection}>
                    <div className={classes.ImageUploadWrapper}>
                        <input
                            type="file"
                            name="upload"
                            accept="application/pdf,image/*"
                            onChange={this.attachmentUpploader}
                            className={classes.FileInput}
                        />
                        <div className={classes.UploadButton}>
                            <img src={attachIcon} alt="" className={classes.AttachIcon} />
                        </div>
                    </div>
                    <div className={classes.AttachmentMessage}>
                        {this.props.systemText.plan.viewPlan.attachments.attachmentsMessage[this.props.userLanguage]}
                    </div>
                </div>):null}
            
            {this.renderAttachedFiles()}
        </div>);
  }
}

renderAttachedFiles = () => {
    if (this.state.attachedFiles.length > 0) {
      return (
        <div className={classes.AttachedFiles}>
          <div className={classes.SectionTitle}>
            {this.props.systemText.plan.viewPlan.attachments.attachedFiles[this.props.userLanguage]}
          </div>
          <div className={classes.Files}>
            {this.state.attachedFiles.map((file, index) => (
              <div className={classes.FileItem}>
                  {file.url!==undefined?(
                        <a href={file.url} target="_blank" key={index}>
                        {file.name}
                        </a>
                  ):(
                    <div> {file.name}</div>
                  )}
                 {this.state.editMode?(
                    <img
                    src={removeIcon}
                    alt=""
                    className={classes.RemoveIcon}
                    onClick={() => {
                        this.deleteFileHandler(index);
                    }}
                    />
                 ):null}
              </div>
            ))}
          </div>
        </div>
      );
    }
  }; 

    renderContent = () =>{
        return(
            <div className={classes.NoteContent}>
                {this.renderInfoEdit()}
                {this.renderLinks()}
                {this.renderAttachmentSection()}
            </div>
        )
    }

    renderBody = () =>{
        if (this.props.openMode){
            return(
                <div className={classes.OpenBody}>
                    {this.renderNoteTopSection()}
                    {this.renderContent()}
                </div>
            )
        }
        else{
            return(
                <div className={classes.ClosedBody}>
                    {this.renderNoteTopSection()}
                </div>
            )
        }
    }

    renderLinksModal = () => {
        if (this.state.showManageLinks) {
          return (
            <Links
              discard={this.discardLinksnHandler}
              update={this.updateLinks}
              links={this.state.noteLinks}

              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
              RTL = {this.props.RTL}
            />
          );
        }
      };

    render () {
        return(
            <div className={classes.Wrapper}>
                 {this.renderLinksModal()}
                <TransparentBackdrop show={this.state.showBackdrop} clicked={this.discard} />
                <TransparentBackdrop show={this.props.openMode} clicked={()=>{/* this.props.noteOpenHandler(-1) */; this.updateNote();/* this.initializeNoteData() */}} />
                {this.renderBody()}
            </div>
        )
       
    }

}

export default Note;
