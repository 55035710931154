import React from "react";
import classes from "./TextButton.module.css";

const textButton = props => {


  const renderBody = () =>{
      let buttonClass = "Button"
      if (props.RTL){
          buttonClass = "ButtonRTL"
      }
      return(
        <div className={[classes[buttonClass],classes[props.style]].join(" ")} onClick={props.clicked}>
            {props.text}
        </div>
      )
  }

  return (
    <div>
        {renderBody()}
    </div>
  );
};

export default textButton;
