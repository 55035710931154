import React, { Component } from "react";
import classes from "./TaskOwner.module.css";

import ModalTopSection from "../../UI/Misc/ModalTopSection"

class TaskOwner extends Component {
    state={
      
     }


  renderTopSection = () => {
    let title = this.props.systemText.plan.viewPlan.planning.tasks.taskOwnerTitle[this.props.userLanguage];
    return (
      <ModalTopSection
        title={title}
        discard={this.props.discard}
        saveButton={false}

        RTL = {this.props.RTL}
      />
    );
  };

  selectUser = user => {
    this.props.updateOwner(user)
    this.props.discard();
  }

    renderBody = () =>{
      return(<div className={classes.Body}>
        {this.props.planEditors.map((user,index)=>(
          <div className={classes.User} onClick={()=>{this.selectUser(user)}}>
            <img src={user.photoUrl} alt="" className={classes.UserPhoto} />
            <div className={classes.UserName}>{user.displayName}</div>
          </div>
        ))}
      </div>)
    }

    render () {
        return(
            <div className={classes.Modal}>
                {this.renderTopSection()}
                {this.renderBody()}
            </div>
        )
       
    }
}

export default TaskOwner;
