

import React, { Component } from "react";

import classes from "./PlaceFeed.module.css";

import ExperiencesWall from "../Experiences/ExperiencesWall"
import ScrollToTop from "../../../Components/UI/Buttons/ScrollToTop"

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"
let checkedBoxFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquareFade.png?alt=media&token=ea283160-9198-4c2d-810c-cb1db603c999"

class PlaceFeed extends Component {
    state={
        selectedFeed: "Experiences",
        showFriendsExperiences:false,
        showMyExperiences:false,
    }

    componentDidMount = () =>{
        var scrollComponent = this;
        var frame = document.getElementById("PlaceFeedWall");
        frame.addEventListener("scroll", function(e) {
            scrollComponent.PlaceFeedWallScrollToggle();
        });   
    }

    scrollToTop = () =>{
        var frame = document.getElementById("PlaceFeedWall");
        frame.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    PlaceFeedWallScrollToggle = () =>{
        var frame = document.getElementById("PlaceFeedWall");
        let scrollTop = frame.scrollTop
        if (frame.scrollTop>window.innerHeight){
            this.setState({showScrollUpButton:true})
        }
        else{
            this.setState({showScrollUpButton:false})
        }
        if (this.state.selectedFeed==="Experiences"&&(!this.state.showFriendsExperiences&&!this.state.showMyExperiences)){
            if (scrollTop>=(frame.scrollHeight-frame.offsetHeight-1000)){
                this.props.loadMoreExperiencesFeedHandler()
                this.props.writeAcitivityLog({
                    activityKey:"PLACE_FEED_LOAD_MORE_EXPERIENCES",
                    userScreen:"VIEW_PLACE",
                  })
            }

          /*   if ((scrollTop/window.innerHeight)*2.25>(this.props.placeExperiences.length-4)&&
            this.props.loadedMoreExperiencesToFeed){
                this.props.loadMoreExperiencesFeedHandler()
            } */
        }
       
    }

    renderContentWall = () =>{
        switch(this.state.selectedFeed){
            case "Experiences":{
                return(<div className={classes.ContentWall} id = "PlaceFeedWall">
                    <div className={classes.Header}>
                        {this.props.systemText.misc.placeFeedExperiences[this.props.userLanguage]}
                    </div>
                    {this.experiencesWallFilters()}
                    {this.renderExperiencesWall()}
                </div>)
                break;
            }
            default: return null;
        }
    }
    

    experiencesWallFilters = () =>{
        if (this.props.loggedUser){
            let checkIconClass = "CheckIcon"
            let uncheckBoxClass = "UncheckBox"
            let filtersClass = "Filters"
            if (this.props.RTL){
                checkIconClass = "CheckIconRTL"
                uncheckBoxClass = "UncheckBoxRTL"
                filtersClass = "FiltersRTL"
            }
            return(
                <div className={classes[filtersClass]}>
                    {this.state.showFriendsExperiences?(
                        <div className={classes.FriendsExperiences}>
                        <img src={checkedBox} alt="" className={classes[checkIconClass]} 
                        onClick={()=>{ this.setState({showFriendsExperiences:false})
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_FEED_UNFILTER_FRIENDS_EXPERIENCES",
                            userScreen:"VIEW_PLACE",
                          })}}/>
                            {this.props.systemText.place.viewPlace.placeFeed.friendsExperiencesFilter[this.props.userLanguage]}
                           
                        </div>
                    ):(
                        <div className={classes.FriendsExperiences}>
                        <div className={classes[uncheckBoxClass]} 
                        onClick={()=>{ this.props.loadFriendsExperiences(); this.setState({showFriendsExperiences:true})
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_FEED_FILTER_FRIENDS_EXPERIENCES",
                            userScreen:"VIEW_PLACE",
                          })}}/>
                           {this.props.systemText.place.viewPlace.placeFeed.friendsExperiencesFilter[this.props.userLanguage]}
                         
                       </div>
                    )}
                     {this.state.showMyExperiences?(
                        <div className={classes.FriendsExperiences}>
                        <img src={checkedBox} alt="" className={classes[checkIconClass]} onClick={()=>{ this.setState({showMyExperiences:false})
                            this.props.writeAcitivityLog({
                                activityKey:"PLACE_FEED_UNFILTER_MY_EXPERIENCES",
                                userScreen:"VIEW_PLACE",
                              })
                        }}/>
                            {this.props.systemText.place.viewPlace.placeFeed.myExperiencesFilter[this.props.userLanguage]}
                           
                        </div>
                    ):(
                        <div className={classes.FriendsExperiences}>
                        <div className={classes[uncheckBoxClass]} onClick={()=>{ this.props.loadMyExperiences(); this.setState({showMyExperiences:true})
                            this.props.writeAcitivityLog({
                                activityKey:"PLACE_FEED_FILTER_MY_EXPERIENCES",
                                userScreen:"VIEW_PLACE",
                              })
                        }}/>
                           {this.props.systemText.place.viewPlace.placeFeed.myExperiencesFilter[this.props.userLanguage]}
                         
                       </div>
                    )}                    
                </div>
               
            )  
        }   
        else{
            //show option fade
            let checkIconClass = "CheckIcon"
            let uncheckBoxClass = "UncheckBoxFade"
            let filtersClass = "FiltersFade"
            if (this.props.RTL){
                checkIconClass = "CheckIconRTL"
                uncheckBoxClass = "UncheckBoxRTLFade"
                filtersClass = "FiltersRTLFade"
            }
            return(
                <div className={classes[filtersClass]}>
                    {this.state.showFriendsExperiences?(
                        <div className={classes.FriendsExperiences}>
                        <img src={checkedBoxFade} alt="" className={classes[checkIconClass]}/>
                            {this.props.systemText.place.viewPlace.placeFeed.friendsExperiencesFilter[this.props.userLanguage]}
                           
                        </div>
                    ):(
                        <div className={classes.FriendsExperiences}>
                        <div className={classes[uncheckBoxClass]} />
                           {this.props.systemText.place.viewPlace.placeFeed.friendsExperiencesFilter[this.props.userLanguage]}
                         
                       </div>
                    )}
                     {this.state.showMyExperiences?(
                        <div className={classes.FriendsExperiences}>
                        <img src={checkedBoxFade} alt="" className={classes[checkIconClass]} />
                            {this.props.systemText.place.viewPlace.placeFeed.myExperiencesFilter[this.props.userLanguage]}
                           
                        </div>
                    ):(
                        <div className={classes.FriendsExperiences}>
                        <div className={classes[uncheckBoxClass]}/>
                           {this.props.systemText.place.viewPlace.placeFeed.myExperiencesFilter[this.props.userLanguage]}
                         
                       </div>
                    )}                    
                </div>
               
            )  
        }     
    }

    renderExperiencesWall = () =>{
        let experiencesList = this.props.placeExperiences
        if (this.state.showFriendsExperiences||this.state.showMyExperiences){
            experiencesList = [];
            if (this.state.showFriendsExperiences&&this.props.placeFriendsExperiences!==undefined){
                let experienceListIds = experiencesList.map(exp=>{return exp.experienceID})
                for (let i=0 ; i<this.props.placeFriendsExperiences.length ; i++){
                    if (!experienceListIds.includes(this.props.placeFriendsExperiences[i].experienceID)){
                        experienceListIds.push(this.props.placeFriendsExperiences[i].experienceID)
                        experiencesList.push(this.props.placeFriendsExperiences[i])
                    }
                }
               // experiencesList = experiencesList.concat(this.props.placeFriendsExperiences)
            }
            if (this.state.showMyExperiences&&this.props.placeMyExperiences!==undefined){
                let experienceListIds = experiencesList.map(exp=>{return exp.experienceID})
                for (let i=0 ; i<this.props.placeMyExperiences.length ; i++){
                    if (!experienceListIds.includes(this.props.placeMyExperiences[i].experienceID)){
                        experiencesList.push(this.props.placeMyExperiences[i])
                    }
                }
            }
        }
       
        return(
            <ExperiencesWall
            experiencesList={experiencesList}
            user={this.props.user}
            viewStyle={"wall"}
            deleteHandler={this.deleteExperienceHandler}
            editHandler={this.editExperienceHandler}
            viewExperiencePlace={this.props.viewExperiencePlace}
            viewExperiencePlan={this.props.viewExperiencePlan}
            selectedContentWall={"Place"}
            addToPlan={this.addExperienceToPlanHandler}
            removeFromPlan={this.removeExperienceFromPlanHandler}
            experienceFullScreenHandler={this.props.experienceFullScreenHandler}
            viewUserProfileHandler = {this.props.viewUserProfileHandler}
           
            followingUsersIDs = {this.props.followingUsersIDs}
            myFriendsIDs = {this.props.myFriends.map(friend=>{return friend.userID})}
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
        />
        )
    }

 render(){
    let RTLBody = ""
    if (this.props.RTL){
        RTLBody = "RTL"
    }
    return(
        <div className={[classes.Body,classes[RTLBody]].join(" ")} >
             {this.state.showScrollUpButton?(<ScrollToTop scrollToTop={this.scrollToTop}/>):null}
            {this.renderContentWall()}
        </div>)
}

}
export default PlaceFeed;