import React, { Component } from "react";
import classes from "./PlaceEditor.module.css";

import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop"
import Backdrop from "../../../Components/UI/Backdrop/Backdrop"
import ModalTopSection from "../../../Components/UI/Misc/ModalTopSection";
import ConfirmModal from "../../../Components/UI/Modals/ConfirmationModal";
/* import InfoModal from "../../../Components/UI/Modals/InfoModal" */
import SetLocation from "../../../Components/Complex/Map/SetLocation"
import ContactInfo from "../../../Components/Complex/ContactInfo/ContactInfo"
import Links from "../../../Components/Complex/Links/Links";
import DetectInputLanguage from "../../../Components/Functions/DetectInputLanguage"
import PlacesList from "./PlacesList"
import TextButton from "../../../Components/UI/Buttons/TextButton"
import OpenHoursPreview from "../../../Components/Complex/OpenHours/OpenHoursPreview"
import OpenHours from "../../../Components/Complex/OpenHours/OpenHours"
import MainCategory from "../../../Components/UI/Misc/MainCategory"
import TaggingSection from "../../../Components/Complex/Tagging/TaggingSection"
import OptionSelection from "../../../Components/Complex/OptionSelection/OptionSelection"
import Tag from "../../../Components/UI/Tag/Tag"
import ImageUploader from "../../../Components/Complex/Images/ImageUploader"
import PlaceCharacteristics from "../../../Components/Complex/PlaceCharacteristics/PlaceCharacteristics"

import {isMobile} from 'react-device-detect';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState , convertToRaw, convertFromRaw} from 'draft-js';

let detailIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDetail.png?alt=media&token=2945590b-6232-4f58-afbe-358781245f57";
let collapseIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCollapse.png?alt=media&token=0a19f67c-7fad-4e02-8449-20d7e13e615c";
let pinIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMapClick.png?alt=media&token=9e2fd48b-8144-48c2-8598-b4fb15dfd722";
let resetIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FResetIcon.png?alt=media&token=c3be2f66-2cd2-42d7-b97c-b4da958a51f1";
let locationIconRed = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLocationIconRed.png?alt=media&token=31efbc56-cef0-4c72-bdf0-386ffe06f78e";
let locationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLocationIcon.png?alt=media&token=7ab0f38c-21ab-4b86-abe3-97f41437e77f";
let contactIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FContactInfoIcon.png?alt=media&token=80764062-a512-44a3-8170-675167c5edb9"
let contactIconGrey  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FContactInfoIconGrey.png?alt=media&token=4c6d4451-8a6b-485c-a1aa-d6b8820af7fe"
let categoryIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategoryIcon.png?alt=media&token=b4da017b-58ec-4cf0-bf1a-e6635504e1aa"
let categoryIconRed = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategoryIconRed.png?alt=media&token=2b72054b-05a2-46a1-82ae-4c119eda52de"
let imageIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FImagesIconSelected.png?alt=media&token=163347ba-66d2-4030-90ef-bdc1241511fa"
let imageIconRed = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FImagesIconRed.png?alt=media&token=7190f237-a721-4882-8877-0db1c1fa427f"
let uploadIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FUploadIconGrey.png?alt=media&token=ceb0a76e-b032-4cfe-a467-590711dd42e6"
let pasteIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPasteIconGrey.png?alt=media&token=686af38e-52ad-4ea7-81bc-bb3b029d0700"
let generalInfoIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FGeneralInfoIcon.png?alt=media&token=77d9c992-a24a-4a7f-a784-cb7224dbf7f2"
let generalInfoIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FGeneralInfoIconGrey.png?alt=media&token=e7b95029-dc59-447a-b38e-0eafd68ecd60"

let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowLeft.png?alt=media&token=8020b73f-93be-478c-a1c3-c3cee641fbe8"
let arrowIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowRight.png?alt=media&token=e0fca8aa-a34f-47cf-80d3-79b36b305e88"

let webLinkIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FWebLink%20(1).png?alt=media&token=2d5ada00-f077-4889-8c8d-045eab1f0037"
let webLinkGreyIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FWebLinkGrey%20(1).png?alt=media&token=c46e7672-7b68-4d3a-80b5-0f4bb74021ad"

class PlaceEditor extends Component {

    state={
        showTransparentBackdrop:false, 
        showBackdrop: false,
        inFavorite: true,
        placeName:{[this.props.initializeLanguage]:""},
        placeLocation: { placeAddress: "" }, 
        locationWasUpdated: false,
        placeLinks: [],
        hoursAreSet: false,
        hoursRows:[{hoursArray:[{open:"Not specified",close:"Not specified"}],selectedDays:[0,0,0,0,0,0,0],
                     isClosed:false,open24:false}],
        categorySelected:false,
        placeMainCategory:[0,0,0,0,0,0],
        placeCategory:[],
        placeNewCategory:{inputText:""},
        placeNewCategories:[],
        placeTags:[],
        placeDescription:{[this.props.initializeLanguage]:EditorState.createEmpty()},
        imagePreview:[], 
        imageGallery:[],
        deletedImages: [],
        placeCharacteristics: {},
        placeNameInputLanguage: this.props.initializeLanguage,
        placeStatus:"potential",

        locationSectionOpen:true,
        categorySectionOpen:true,
        imageSectionOpen:true,

        phoneNumber:"",
        facebookLink:"",
        instagramLink:"",
        twitterLink:"",
        officialWebsiteLink:"",
        googleMapsLink:"",
        wazeLink:"",

        averageVisitDuration: 0,

        activityScreen:"",

        extraInfoRef:React.createRef()
    }

    componentDidMount = () =>{
        this.initializeEditState()
    }

    initializeEditState = async () =>{
        if (this.props.quickCreation&&this.props.quickPlaceCreationMainCategory!==undefined){
            this.setState({placeMainCategory:[...this.props.quickPlaceCreationMainCategory]})
            if (JSON.stringify(this.props.quickPlaceCreationMainCategory)===JSON.stringify([0,0,0,0,0,1])){
                this.setState({creatingAccommodation:true})
            }
        }
        if (this.props.quickCreation&&this.props.quickPlaceCreationCategories!==undefined){
           this.setState({placeCategory:[...this.props.quickPlaceCreationCategories]})
        }
        if (this.props.editMode !== 'Create'){
            /* const request = {
                placeId: this.props.selectedPlace.placeID,
                fields: ["rating"],
              };
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            await service.getDetails(request, (place, status) => {
              if (place.rating!==undefined){
                console.log(place.rating)
                place.placeGoogleRating = place.rating
              }
              
            }) */
            this.setState({placeStatus:this.props.selectedPlace.placeStatus})
            if (this.props.editMode==="Edit"){
                this.setState({activityScreen:"EDIT_PLACE"})
            }
            else{
                this.setState({activityScreen:"SUGGEST_EDIT_PLACE"})
            }
            let placeLocation = this.props.selectedPlace.placeLocation
            let placeMainCategory = [...this.props.selectedPlace.placeMainCategory]
            let placeNewCategories = this.props.selectedPlace.placeNewCategories 
            for (let i=0 ; i<placeNewCategories.length ; i++){
                placeNewCategories[i].inputText = placeNewCategories[i].id
            }
        
            let placeTags = []
            for (let j=0 ; j<this.props.selectedPlace.placeTags.length; j++){
                let tagLanguages =  Object.keys(this.props.selectedPlace.placeTags[j]);
                let tag = this.props.selectedPlace.placeTags[j][tagLanguages[0]];
                placeTags.push(tag);
              }
           /*  let placeDescription = this.props.selectedPlace.placeDescription[this.props.placeLanguage]; */
            let placeLinks = [...this.props.selectedPlace.placeLinks];
            let placeTemporaryClosed = this.props.selectedPlace.placeTemporaryClosed;
            let temporaryClosedNote = this.props.selectedPlace.temporaryClosedNote;
            let placePermanentlyClosed = this.props.selectedPlace.placePermanentlyClosed;
            let permanentlyClosedDate = this.props.selectedPlace.permanentlyClosedDate;
            let placeCharacteristics = this.props.selectedPlace.placeCharacteristics;
              
            await this.setState({
                placeLocation: placeLocation,
                placeMainCategory: placeMainCategory,
                placeNewCategories: placeNewCategories,
                placeTags: placeTags,
               /*  placeDescription :placeDescription, */
                placeLinks: placeLinks,
                placeTemporaryClosed: placeTemporaryClosed,
                temporaryClosedNote: temporaryClosedNote,
                placePermanentlyClosed: placePermanentlyClosed,
                permanentlyClosedDate: permanentlyClosedDate,
                placeCharacteristics: placeCharacteristics,
                locationIsSet: true,
                categorySelected:true,
                inFavorite:false,
                editPlaceInitialized:true
            })
            if (this.props.selectedPlace.averageVisitDuration!==undefined){
                this.setState({averageVisitDuration: this.props.selectedPlace.averageVisitDuration})
            }
           
            if (this.props.selectedPlace.contactInfo.phoneNumber!==undefined){
                this.setState({phoneNumber:this.props.selectedPlace.contactInfo.phoneNumber,})
            }
            if (this.props.selectedPlace.contactInfo.facebookLink!==undefined){
                this.setState({facebookLink:this.props.selectedPlace.contactInfo.facebookLink,})
            }
            if (this.props.selectedPlace.contactInfo.instagramLink!==undefined){
                this.setState({instagramLink:this.props.selectedPlace.contactInfo.instagramLink,})
            }
            if (this.props.selectedPlace.contactInfo.twitterLink!==undefined){
                this.setState({twitterLink:this.props.selectedPlace.contactInfo.twitterLink,})
            }
            if (this.props.selectedPlace.contactInfo.officialWebsiteLink!==undefined){
                this.setState({officialWebsiteLink:this.props.selectedPlace.contactInfo.officialWebsiteLink,})
            }
            if (this.props.selectedPlace.navigation.wazeLink!==undefined){
                this.setState({wazeLink:this.props.selectedPlace.navigation.wazeLink,})
            }
            if (this.props.selectedPlace.navigation.googleMapsLink!==undefined){
                this.setState({googleMapsLink:this.props.selectedPlace.navigation.googleMapsLink,})
            }

          

            let placeCategoryIDs=[...this.props.selectedPlace.placeCategory]
            let placeCategory = []
            for (let i = 0 ; i<placeCategoryIDs.length ; i++){
                if (this.props.categoriesTranslator[placeCategoryIDs[i]]!==undefined){
                    placeCategory.push(this.props.categoriesTranslator[placeCategoryIDs[i]])
                }
                
            }
            for (let i=0; i<placeCategory.length ; i++){
                let placeCategoryLanguage="";
                if(placeCategory[i][this.props.userLanguage]!==undefined){
                    placeCategoryLanguage=this.props.userLanguage
                }
                else {
                    placeCategoryLanguage="English"
                }
                placeCategory[i].inputText=placeCategory[i][placeCategoryLanguage]
            }
            this.setState({placeCategory:placeCategory})

            if (this.props.selectedPlace.contactInfo.phoneNumber!==undefined){
                this.setState({phoneIsSet:true})
            }

            let hoursRows=[...this.props.selectedPlace.hoursRows];
            for (let i=0 ; i<hoursRows.length ; i++){
                if (hoursRows[i].specialDates!==undefined){
                    let specialDates =  hoursRows[i].specialDates
                    specialDates.startDate=new Date(specialDates.startDate);
                    specialDates.endDate=new Date(specialDates.endDate);
                    hoursRows[i].specialDates=specialDates
                }
                if (hoursRows[i].hoursArray!==undefined){
                    for (let j=0 ; j<hoursRows[i].hoursArray.length ; j++){
                        if (hoursRows[i].hoursArray[j].open !=="Not specified"){
                            hoursRows[i].hoursArray[j].open = new Date(hoursRows[i].hoursArray[j].open);
                        }
                        if (hoursRows[i].hoursArray[j].close !=="Not specified"){
                            hoursRows[i].hoursArray[j].close = new Date(hoursRows[i].hoursArray[j].close);
                        }
                        
                    }
                }
                else{
                    hoursRows[i].hoursArray=[];
                }
            }
            this.setState({hoursRows:hoursRows,hoursAreSet:true})

            if (this.props.selectedPlace.permanentlyClosedDate!==undefined){
                this.setState({permanentlyClosedDate:new Date(this.props.selectedPlace.permanentlyClosedDate)})
            }
            document.body.style.overflow = "hidden"  

            let placeNameInputLanguage=this.props.placeLanguage;
            let placeName=this.props.selectedPlace.placeName;
            /* placeName.inputText=placeName[placeNameInputLanguage] */
            this.setState({placeNameInputLanguage:placeNameInputLanguage,placeName:placeName})
            let descriptionLanguages = []
            let placeDescription={}
             if (this.props.selectedPlace.placeDescription!==undefined){
                descriptionLanguages = Object.keys(this.props.selectedPlace.placeDescription)
                for (let i=0 ; i<descriptionLanguages.length ; i++){
                    try {
                        JSON.parse(this.props.selectedPlace.placeDescription[descriptionLanguages[i]]);
                        placeDescription[descriptionLanguages[i]] = EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.selectedPlace.placeDescription[descriptionLanguages[i]])))
                    } catch (e) {
                        placeDescription[descriptionLanguages[i]] = EditorState.createEmpty()
                    }
                }
             }
             else{
                placeDescription[placeNameInputLanguage] = EditorState.createEmpty()
             }
            await this.setState({ placeDescription:placeDescription, jsonString: true})
            if (this.props.editMode === 'Edit'){
                let imagePreview = [...this.props.selectedPlace.imagePreview]
                let imageGallery = [...this.props.selectedPlace.imageGallery]
                let imagesList = [];
                await this.setState({/* imagePreview:imagePreview, */imageGallery:imageGallery})
                if (this.props.selectedPlace.imagePreview.length>0){
                    imagesList= this.props.selectedPlace.imagePreview;
                    if (Object.keys(this.props.selectedPlace.imagePreview[0]).includes("smallImage")){
                        imagesList = this.props.selectedPlace.imagePreview.map(img=>{return img.smallImage});
                    }
                }
                else{
                    this.setState({imagePreview:[],imageGallery:[]})
                }
                this.setState({imagePreview:imagesList})
             }
        }
        else{
            this.setState({activityScreen:"NEW_PLACE"})
        }
    }

    componentDidUpdate = prevProps =>{
        if (prevProps.existingPlace!==this.props.existingPlace){
            this.setState({existingPlace:this.props.existingPlace})
        }
    }

    placeNameHandler = (event) =>{
        let name = event.target.value
        this.updatePlaceName(name)
    }

    updatePlaceName=async (name)=>{
        let placeNameText=name.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        if(this.state.placeName[this.state.placeNameInputLanguage]!==placeNameText)
        {
          this.setState({changesMade:true}) 
        }
        let placeName = this.state.placeName
        placeName[this.state.placeNameInputLanguage]=placeNameText;
       await this.setState({placeName: placeName}) 
       if (this.state.locationIsSet){
        this.checkExistingPlaceLocation();
       }
       
      }

      updateLocation = async (location,locationWasEdited) => {
        let saveLocation = location;
        this.setState({locationLandingPage:false, changesMade: true})
        if (this.state.showLocationPath){
            //updated only the location tree path
            let placeLocation = this.state.placeLocation
            let oldLocationTreeID = [...placeLocation.locationTree.locationTreeIDs]
            //handle the using/not using treeID value 
            placeLocation.locationTree = location.locationTree
            await this.setState({
                locationWasEdited:locationWasEdited, 
                placeLocation:placeLocation,
                showLocationPath:false,placeStatus:"potential"
            })
            if (JSON.stringify(oldLocationTreeID)!==JSON.stringify(placeLocation.locationTree.locationTreeIDs)){
                this.setState({locationWasUpdated:true})
            }
        }
        else{
            
              if (location.inputMode!=="Search"){
                  let googleMapsLink = "http://www.google.com/maps/place/"+location.coordinates.lat+","+location.coordinates.lng
                  this.setState({googleMapsLink:googleMapsLink})
                  saveLocation.placeID=""
              }
              await this.setState({ placeLocation: saveLocation,placeStatus:"potential" });
              this.setState({ showLocationModal: false, displayScreen: true ,locationWasEdited:locationWasEdited, locationWasUpdated:true});
              if (location.inputMode==="Search"/* &&this.state.placeName.inputText==="" */){
                 await this.updatePlaceName(location.address.split(',', 1)[0]);
              }
              if (location.inputMode==="Search"){
                  let googleMapsLink = "https://www.google.com/maps/search/?api=1&query=<address>&query_place_id="+location.placeID
                  this.setState({googleMapsLink:googleMapsLink})
                  if (location.placeGoogleRating!==undefined){
                      this.setState({placeGoogleRating:location.placeGoogleRating})
                  }
                  if (location.placePhoneNumber!==undefined &&this.state.phoneNumber===""){
                      this.setState({phoneNumber:location.placePhoneNumber})
                  }
                  if (location.placeWebsite!==undefined&&this.state.officialWebsiteLink===""){
                      if (location.placeWebsite!=="http://www.google.com")
                      this.setState({officialWebsiteLink:location.placeWebsite})
                  }
              }
      
              
              // update waze link
              let wazeLink = "https://www.waze.com/ul?ll="+location.coordinates.lat+","+location.coordinates.lng+"&navigate=yes&zoom=17"
              this.setState({wazeLink:wazeLink})
              this.checkExistingPlaceLocation();
      
              this.props.writeAcitivityLog({
                  activityKey:"PLACE_EDIT_LOCATION_UPDATE",
                  userScreen:this.state.activityScreen
              })
              if (location.coordinates !== undefined) {
                this.setState({ locationIsSet: true });
              } else {
                this.setState({ locationIsSet: false });
              }
        }
        
      }

      checkExistingPlaceLocation = async () =>{
        let placeLocation = this.state.placeLocation;
        let existingPlace;
        let locationCountry = placeLocation.locationTree.placeCountry.treeID;
        //Checking based on google place ID 
        if (placeLocation.placeID!==""){
            if (this.props.slimPlacesByCountry[locationCountry]!==undefined){
                for (let i =0 ; i<this.props.slimPlacesByCountry[locationCountry].length ; i++){
                    if (this.props.slimPlacesByCountry[locationCountry][i].placeLocation.placeID===placeLocation.placeID){
                        existingPlace=this.props.slimPlacesByCountry[locationCountry][i]
                    }
                }
            }
            
        }
        
        else{
            //handle places that have a close location with the same name? - it will probably be handled in name check
           this.checkExistingLocationWithCoordinates();
        }
        if (existingPlace!==undefined){
            if (this.props.editMode!=="Create"&&this.props.selectedPlace.placeLocation.placeID===placeLocation.placeID){
                return
            }
            //get the place from DB
            this.props.getExistingPlaceData(locationCountry,existingPlace.placeID)
            await this.setState({
                showExistingPlaces: true,
                showTransparentBackdrop:true,
            })
            this.props.writeAcitivityLog({
                activityKey:"PLACE_EDIT_EXISTING_PLACE_LOCATION",
                userScreen:this.state.activityScreen
            })
        }
        else{
            this.checkExistingLocationWithCoordinates();
        }
      }

      checkExistingLocationWithCoordinates = async () =>{
        let placeLocation = this.state.placeLocation;
        let existingPlace;
        let locationCountry = placeLocation.locationTree.placeCountry.treeID;
        if (placeLocation.coordinates!==undefined){
            let currentCoordinates = placeLocation.coordinates;
            if (this.props.slimPlacesByCountry[locationCountry]!==undefined){
                for (let i =0 ; i<this.props.slimPlacesByCountry[locationCountry].length ; i++){
                    let existingCoordinates = this.props.slimPlacesByCountry[locationCountry][i].placeLocation.coordinates;
                    if (Math.abs(existingCoordinates.lat-currentCoordinates.lat)<0.005&&
                        Math.abs(existingCoordinates.lng-currentCoordinates.lng)<0.005&&
                        this.state.placeName[this.state.placeNameInputLanguage]===this.props.slimPlacesByCountry[locationCountry][i].placeName[this.state.placeNameInputLanguage]
                    ){
                        existingPlace=this.props.slimPlacesByCountry[locationCountry][i]
                    }
                }
            }
            if (existingPlace!==undefined){
                this.props.getExistingPlaceData(locationCountry,existingPlace.placeID)
                await this.setState({
                    showExistingPlaces: true,
                    showTransparentBackdrop:true,
                })
                this.props.writeAcitivityLog({
                    activityKey:"PLACE_EDIT_EXISTING_PLACE_LOCATION",
                    userScreen:this.state.activityScreen
                })
            }
        }   
      }

      favoriteIconHandler = value =>{
        if (value){
          this.setState({inFavorite: true})
          this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_FAVORITE_BUTTON_SELECT",
            userScreen:this.state.activityScreen
            })
        }
        else{
          this.setState({inFavorite: false})
          this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_FAVORITE_BUTTON_UNSELECT",
            userScreen:this.state.activityScreen
            })
        }
    }

      updatePlaceCategory = async category => {
        
        if (category.id.split('_')[1]==="other"){
          this.setState({
         /*   showInfo: true,  */
           infoHeader: this.props.systemText.place.createPlace.informationModal.infoHeader[this.props.userLanguage], 
           informationMessage:this.props.systemText.place.createPlace.informationModal.informationMessage[this.props.userLanguage],
         /*   showTransparentBackdrop:true, */
           showNewCategory:true})
           this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_NEWCATEGORY_SELECT",
            userScreen:this.state.activityScreen
        })
        }
        else{
           
            let placeCategory = this.state.placeCategory;
            if (!placeCategory.map(cat=>cat.id).includes(category.id)){
                placeCategory.push(category)
            }
        
            await this.setState({ placeCategory: placeCategory,changesMade:true });
            this.setState({showNewCategory: false,categoryExtraInfoOpen:true,categorySelected:true})

            this.props.writeAcitivityLog({
                activityKey:"PLACE_EDIT_CATEGORY_ADD",
                userScreen:this.state.activityScreen
            })
        }
       
       };
 
       removeCategory = async index =>{
         let placeCategory = this.state.placeCategory;
         placeCategory.splice(index,1)
         let categorySelected=(placeCategory.length>0)
         await this.setState({ placeCategory: placeCategory,categorySelected:categorySelected, changesMade:true});

         this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_CATEGORY_REMOVE",
            userScreen:this.state.activityScreen
        })
       }

       removeNewCategory = async index =>{
        let placeNewCategories = this.state.placeNewCategories;
        placeNewCategories.splice(index,1)
        let categorySelected=(this.state.placeCategory.length>0||this.state.placeNewCategories.length>0)
        await this.setState({ placeNewCategories: placeNewCategories,categorySelected:categorySelected, changesMade:true});

        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_NEWCATEGORY_REMOVE",
            userScreen:this.state.activityScreen
        })
      }
 
       mainCategoryHandler = async index =>{
        let placeMainCategory = this.state.placeMainCategory;
        let activityKey=""
        if (placeMainCategory[index]){
            activityKey = "PLACE_EDIT_MAINCATEGORY_UNSELECT"
        }
        else{
            activityKey = "PLACE_EDIT_MAINCATEGORY_SELECT"
        }
        
         placeMainCategory[index]=1-placeMainCategory[index]
         this.setState({placeMainCategory: placeMainCategory, changesMade:true})

         this.props.writeAcitivityLog({
            activityKey:activityKey,
            userScreen:this.state.activityScreen
        })
     }

     newCategoryBlur = () =>{
        let placeNewCategory=this.state.placeNewCategory;
        if (placeNewCategory.length<2) return;
        placeNewCategory.id=placeNewCategory.inputText;;
        placeNewCategory[this.state.newCategoryLanguage]=placeNewCategory.inputText;
        let placeNewCategories = this.state.placeNewCategories;
        placeNewCategories.push(placeNewCategory)
        this.setState({placeNewCategory:{inputText:""},placeNewCategories:placeNewCategories,showNewCategory:false,newCategoryCreated:true})
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_NEWCATEGORY_ADD",
            userScreen:this.state.activityScreen
        })
    }
    newCategoryInputHandler = async event =>{
        let inputValue = event.target.value;
        if(inputValue.length===1){
          await this.refs.detectInputLanguage.detectLanguage(inputValue).then(result => {
              this.setState({newCategoryLanguage:result})
           });
        }
        if (event.key === "Enter") {
          this.newCategoryBlur()
          return;
        }
        let placeNewCategory = {inputText: inputValue.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})}
        this.setState({
          placeNewCategory:placeNewCategory,
          categoryExtraInfoOpen:true, categorySelected:true})
    }
 

      handleLanguageSelection = async language =>{
        this.setState({showCreateNewLanguage:true,showBackdrop:true,newLanguage:language})
    
      }
    

      locationHandler = () => {
        this.setState({
          showLocationModal: true,
        });
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_SET_LOCATION_BUTTON",
            userScreen:this.state.activityScreen
        })
      };
      
      locationPathHandler = (locationPath) =>{
        this.setState({
            showLocationModal: true,
            showLocationPath: true,
            locationPath:locationPath
          });
      }

      discardLocationHandler = () => {
        this.setState({showLocationModal:false})
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_LOCATION_DISCARD",
            userScreen:this.state.activityScreen
        })
      };
      
      removeLocationHandler = async () => {
        await this.setState({
          locationIsSet: false,
          placeLocation: { placeAddress: "" },
          changesMade:true,
          googleMapsLink: undefined
        });
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_RESET_LOCATION_BUTTON",
            userScreen:this.state.activityScreen
        })
      };

    
      hoursHandler = () =>{
        this.setState({ showManageHours: true});
      }

      updateOpenHours = (rows) =>{
        this.setState({hoursAreSet: true,hoursRows:rows, changesMade:true});
      }

      discardHoursHandler = () => {
        this.setState({ showManageHours: false, showBackdrop: false});
      };

      linksHandler = () => {
        this.setState({ showManageLinks: true,showBackdrop:true});
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_LINKS_BUTTON",
            userScreen:this.state.activityScreen
        })
      };

      discardLinksnHandler = () => {
        this.setState({ showManageLinks: false, showBackdrop: false });
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_LINKS_DISCARD",
            userScreen:this.state.activityScreen
        })
      };
      

      updateLinks = placeLinks => {
        this.setState({
          placeLinks: placeLinks,
          changesMade: true
        });
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_LINKS_SAVE",
            userScreen:this.state.activityScreen
        })
      };

    

      updateImages = (imagePreview, imageGallery,deletedImages) => {
        this.setState({ imagePreview: imagePreview, imageGallery: imageGallery, deletedImages:deletedImages, imagesUploading:false, changesMade:true });
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_IMAGE_UPDATE",
            userScreen:this.state.activityScreen
        })
    };
      
      imageUploadHandler = (event) =>{
          this.refs.imageChild.uploadImages(event);
          this.setState({imagesUploading: true})
          this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_IMAGE_UPLOAD",
            userScreen:this.state.activityScreen
        })
      }

      imagePasteHandler = () =>{
        this.refs.imageChild.handlePaste();
        this.setState({imagesUploading: true})
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_IMAGE_PASTE",
            userScreen:this.state.activityScreen
        })
    }

   /*  mobileImagePasteHandler = (e)=>{
        this.refs.imageChild.copyImage(e);
         this.refs.imageChild.handlePaste();
        this.setState({imagesUploading: true})
    } */
      
    
updatePlaceTags = (tags, tagsInputField) => {
        
    this.setState({ placeTags: tags, tagsInputField: tagsInputField, changesMade:true, categoryInfoFilled:true });
    this.props.writeAcitivityLog({
        activityKey:"PLACE_EDIT_TAG_UPDATE",
        userScreen:this.state.activityScreen
    })
  };
  
  updateDescription = description => {
    let placeDescription = this.state.placeDescription
    placeDescription[this.state.placeNameInputLanguage] = description
    this.setState({ placeDescription: placeDescription, changesMade:true });
  };

  updateCharacteristicsHandler = placeCharacteristics =>{
    this.setState({ placeCharacteristics: placeCharacteristics, changesMade:true });

    this.props.writeAcitivityLog({
        activityKey:"PLACE_EDIT_CHAR_UPDATE",
        userScreen:this.state.activityScreen
    })
  }

    discardCreation = () => {
         if (!this.state.changesMade||this.props.quickCreation){
          this.props.discard();
        }else{ 
          this.setState({
            confirmMessage: this.props.systemText.extraModals.confirmationModal.message[this.props.userLanguage],
            primaryBtn: this.props.systemText.extraModals.confirmationModal.stay[this.props.userLanguage],
            secondaryBtn: this.props.systemText.extraModals.confirmationModal.leave[this.props.userLanguage],
            confirmMode: true,
            showBackdrop: true
          });
      } 
      };


    discardDiscardHandler = () => {
        this.setState({
            confirmMode: false,
            showBackdrop: false
        });
    };
        
    discardChangesHandler = () => {
        this.props.discard();
    };

  
    saveHandler = async () =>{
        await this.setState({savingPlace:true})
        let date = new Date();
        let timestamp = date.getTime();
        let inFavorite = this.state.inFavorite;
        let placeLastModified = timestamp
        let placeCreationDate;
        let placeID;
        let placeName = this.state.placeName;
        let placeLanguages=[]
        let placeNameLanguages = Object.keys(placeName)
        for (let i=0 ; i<placeNameLanguages.length ; i++){
            if (placeName[placeNameLanguages[i]]!==undefined){
                if (placeName[placeNameLanguages[i]]!==""){
                    placeLanguages.push(placeNameLanguages[i])
                }
            }
        }
        let placeDescription = {}
        let descriptionKeys = Object.keys(this.state.placeDescription)
        for (let i=0 ; i<descriptionKeys.length ; i++){
            placeDescription[descriptionKeys[i]] = JSON.stringify(convertToRaw(this.state.placeDescription[descriptionKeys[i]].getCurrentContent()));
        }
        let placeLinks = [...this.state.placeLinks];
        let placeMainCategory = [...this.state.placeMainCategory];
        let placeCategory = [...this.state.placeCategory].map(cat=>{return cat.id});
       /*  for (let i=0 ; i<placeCategory.length ; i++){
            delete placeCategory[i].inputText;
        }    */
        let placeNewCategories = [...this.state.placeNewCategories];
        for (let i=0 ; i<placeNewCategories.length ; i++){
            delete placeNewCategories[i].inputText;
        }  
        let placeTags =[];
        for (let i =0 ; i<this.state.placeTags.length ; i++){
            await this.refs.detectInputLanguage.detectLanguage(this.state.placeTags[i]).then(result => {
                let tag = {}
                tag[result] = this.state.placeTags[i]
                placeTags.push(tag)
              }); 
        }
        let placeCharacteristics=this.state.placeCharacteristics;
        let imagePreview= [...this.state.imagePreview]
        let imageGallery=[...this.state.imageGallery]   
        let deletedImages=[...this.state.deletedImages]
        let placeLocation = this.state.placeLocation;
        let oldPlaceLocation; 
        let hoursRows=[...this.state.hoursRows];
        for (let i=0 ; i<hoursRows.length ; i++){
            if (hoursRows[i].specialDates!==undefined){
                let specialDates = hoursRows[i].specialDates
                specialDates.startDate=new Date(specialDates.startDate.getTime()).getTime();
                specialDates.endDate=new Date(specialDates.endDate.getTime()).getTime();
                hoursRows[i].specialDates=specialDates
            }
            for (let j=0 ; j<hoursRows[i].hoursArray.length ; j++){
                if (hoursRows[i].hoursArray[j].open  instanceof Date){
                    hoursRows[i].hoursArray[j].open = new Date(hoursRows[i].hoursArray[j].open.getTime()).getTime();
                }
                if (hoursRows[i].hoursArray[j].close  instanceof Date){
                    hoursRows[i].hoursArray[j].close = new Date(hoursRows[i].hoursArray[j].close.getTime()).getTime();
                }
                
            }
           
        }
        let contactInfo={};
        if (this.state.phoneNumber!==""){
            contactInfo.phoneNumber=this.state.phoneNumber
        }
        if (this.state.facebookLink!==""){
            contactInfo.facebookLink=this.state.facebookLink
        }
        if (this.state.instagramLink!==""){
            contactInfo.instagramLink=this.state.instagramLink
        }
        if (this.state.twitterLink!==""){
            contactInfo.twitterLink=this.state.twitterLink
        }
        if (this.state.officialWebsiteLink!==""){
            contactInfo.officialWebsiteLink=this.state.officialWebsiteLink
        }
        let navigation = {};
        if (this.state.googleMapsLink!==""){
            navigation.googleMapsLink=this.state.googleMapsLink
        }
        if (this.state.wazeLink!==""){
            navigation.wazeLink=this.state.wazeLink
        }

        let placeStats = { // not relevant in DB update of existing place
            totalExperiences: 0,
            usersExperienced: 0,
            usersLiked: 0,
            usersDisliked: 0,
            viewed: { count: 0, users: [] },
            inspired: { count: 0, users: [] }
        };
        let placeStatus = this.state.placeStatus; // not relevant in DB update of existing place
        let placeExperiences = [] // not relevant in DB update of existing place
        let owner = {
            ownerID: this.props.user.userID,
            ownerName: this.props.user.displayName,
            ownerPhoto: this.props.user.photoUrl,
            ownerEmail: this.props.user.userEmail
          };
        let contributors = [];
        contributors.push(owner);
       
        
        if (this.props.editMode==="Create"){
            placeID = timestamp
            placeCreationDate = timestamp
            if (this.state.placeLocation.placeID!==undefined){
                placeID = this.state.placeLocation.placeID+"_"+timestamp;
            }
          /*   placeName= this.state.placeName; 
            placeDescription[this.state.placeNameInputLanguage]=JSON.stringify(convertToRaw(this.state.placeDescription.getCurrentContent()));*/
            deletedImages = [];
           /*  placeLanguages=[this.state.placeNameInputLanguage] */
           
        }
        else{
            placeID = this.props.selectedPlace.placeID
            placeCreationDate = this.props.selectedPlace.placeCreationDate
            //name and description are language version related
           /*  placeName= this.props.selectedPlace.placeName;
            placeName[this.state.placeNameInputLanguage]=this.state.placeName.inputText; 
            placeDescription=this.props.selectedPlace.placeDescription
            placeDescription[this.state.placeNameInputLanguage]=JSON.stringify(convertToRaw(this.state.placeDescription.getCurrentContent()));*/
            oldPlaceLocation = this.props.selectedPlace.placeLocation; 
           
          /*   placeLanguages = this.props.selectedPlace.placeLanguages
            if (!placeLanguages.includes(this.state.placeNameInputLanguage)){
                placeLanguages.push(this.state.placeNameInputLanguage)   
            } */
        }
        /* delete placeName.inputText; */


        //remove unessesary content from placelocation location tree (place country, etc...)
        //if place edit and already approved save only treeIDs for country, region, etc...
        let placeCountry=placeLocation.locationTree.placeCountry
        let placeRegion=placeLocation.locationTree.placeRegion
        let placeCity=placeLocation.locationTree.placeCity
        if (this.props.editMode==="Edit"&&!this.state.locationWasUpdated){
            placeLocation = this.props.selectedPlace.placeLocation
        }
        else{
            let useOnlyTreeIDs = false
            if (this.props.editMode==="Edit"){
                if (this.state.placeStatus==="approved"&&!this.state.locationWasUpdated){
                    useOnlyTreeIDs = true;
                   
                }
            }
            if (useOnlyTreeIDs){
                placeLocation.locationTree.placeCountry=placeCountry.treeID;
            }
            else{
                if (placeCountry.inputText!==undefined) delete placeCountry.inputText;
                if (placeCountry.type!==undefined) delete placeCountry.type;
                placeLocation.locationTree.placeCountry=placeCountry;
            }
            

            let removeRegion = false;
            if (placeRegion===undefined){
                removeRegion = true;
            }
            else if (placeRegion.inputText!==undefined){
                if (placeRegion.inputText===""){
                    removeRegion = true;
                }
            }
            if (removeRegion){
            delete placeLocation.locationTree.placeRegion;
            }
            else{
                if (useOnlyTreeIDs){
                    placeLocation.locationTree.placeRegion=placeRegion.treeID;
                }
                else{
                    if (placeRegion.inputText!==undefined) delete placeRegion.inputText;
                    if (placeRegion.type!==undefined) delete placeRegion.type;
                    placeLocation.locationTree.placeRegion=placeRegion;
                }
               
            }

            let removeCity = false;
            if (placeCity===undefined){
                removeCity = true;
            }
            else if (placeCity.inputText!==undefined){
                if (placeCity.inputText===""){
                    removeCity = true;
                }
            }
            if (removeCity){
            delete placeLocation.locationTree.placeCity;
            }
            else{
                if (useOnlyTreeIDs){
                    placeLocation.locationTree.placeCity=placeCity.treeID;
                }
                else{
                    if (placeCity.inputText!==undefined) delete placeCity.inputText;
                    if (placeCity.type!==undefined) delete placeCity.type;
                    placeLocation.locationTree.placeCity=placeCity;
                }
                
            }
        }
        
        let place = {
            placeLanguages: placeLanguages,
            inFavorite: inFavorite,
            placeID: placeID,
            placeLastModified: placeLastModified,
            placeCreationDate: placeCreationDate,
            placeName: placeName,
            placeDescription: placeDescription,
            placeLinks: placeLinks,
            placeMainCategory: placeMainCategory,
            placeCategory: placeCategory,
            placeNewCategories: placeNewCategories,
            placeTags: placeTags,
            placeCharacteristics: placeCharacteristics,
            imagePreview: imagePreview,
            imageGallery: imageGallery,
            deletedImages: deletedImages,
            placeLocation: placeLocation,
            hoursRows: hoursRows,
            contactInfo: contactInfo,
            navigation: navigation,
            placeStats: placeStats,
            placeStatus: placeStatus,
            placeExperiences: placeExperiences,
            owner: owner,
            contributors: contributors,
        }

        //updatevisitDuration
        if (this.state.averageVisitDuration!==0){
            place.averageVisitDuration=this.state.averageVisitDuration
        }
        if (this.state.placeGoogleRating!==undefined){
            place.placeGoogleRating = this.state.placeGoogleRating
        }

        if (this.props.editMode!=="Create"){
            place.oldPlaceLocation = oldPlaceLocation;
            if (this.props.editMode==="Edit"){
                place.locationWasUpdated = this.state.locationWasUpdated;
                let placeTemporaryClosed=this.state.placeTemporaryClosed;
                let temporaryClosedNote=this.state.temporaryClosedNote;
                let placePermanentlyClosed=this.state.placePermanentlyClosed;
                let permanentlyClosedDate;
                if (this.state.permanentlyClosedDate!==undefined){
                    permanentlyClosedDate=new Date(this.state.permanentlyClosedDate.getTime()).getTime();
                }
                place.placeTemporaryClosed = placeTemporaryClosed
                place.temporaryClosedNote = temporaryClosedNote
                place.placePermanentlyClosed = placePermanentlyClosed
                place.permanentlyClosedDate = permanentlyClosedDate

                place.placeLanguage = this.props.placeLanguage;
            }
        }

      /*    console.log("place")
        console.log(place)  */
        if (this.props.quickCreation){
            this.props.quickPlaceWasCreated()
        }
        if (this.props.editMode==="Create"){
            this.props.create(place)
        }
        if (this.props.editMode==="Edit"){
            this.props.update(place)
        }
        if (this.props.editMode==="SuggestEdit"){
            this.props.submitSuggestion(place)
        }
        this.props.discard();
    }

    

    renderTopSection = () => {
        let title = ""
        let buttonName = "";
        switch(this.props.editMode){
            case "Create":{
                title = this.props.systemText.place.createPlace.title[this.props.userLanguage];
                buttonName = this.props.systemText.misc.buttons.createButton[this.props.userLanguage];
                break;
            }   
            case "Edit":{
                title = this.props.systemText.place.createPlace.editTitle[this.props.userLanguage];
                buttonName = this.props.systemText.misc.buttons.updateButton[this.props.userLanguage];
                break;
            }
            case "SuggestEdit":{
                title = this.props.systemText.place.createPlace.placeEditSuggestion.title[this.props.userLanguage];
                buttonName = this.props.systemText.place.createPlace.placeEditSuggestion.button[this.props.userLanguage];
                break;
            }
            default: return null;
        }
        if (this.props.quickCreation&&this.props.quickPlaceCreationMainCategory!==undefined){
           
            if (JSON.stringify(this.props.quickPlaceCreationMainCategory)===JSON.stringify([0,0,0,0,0,1])){
                title = this.props.systemText.place.createPlace.createAccommodationTitle[this.props.userLanguage];
              }
        }
        
       
        let saveReady = false;
        if (this.props.quickCreation){
            if (this.state.placeName.inputText!==""&&
            this.state.placeName[this.state.placeNameInputLanguage].length>3&&
            this.state.locationIsSet &&
            this.state.categorySelected &&
            JSON.stringify(this.state.placeMainCategory)!==JSON.stringify([0,0,0,0,0,0]) ){
                saveReady=true;
            }
        }
        else{
            if (this.state.placeName.inputText!==""&&
            this.state.placeName[this.state.placeNameInputLanguage].length>3&&
            this.state.locationIsSet &&
            this.state.categorySelected &&
            !this.state.imageProcess &&
            JSON.stringify(this.state.placeMainCategory)!==JSON.stringify([0,0,0,0,0,0])             
        ){ 
            if (this.props.editMode === "Edit" || this.props.editMode === "SuggestEdit"){
                
                if (this.state.changesMade){
                    if (this.props.editMode === "Edit"){
                        if ( this.state.imagePreview.length>0){
                            saveReady=true;
                           }
                    }
                    else{
                        saveReady=true;
                    }
                    
                }
            }
           else{
               if ( this.state.imagePreview.length>0){
                saveReady=true;
               }
           
           }
         }
        }
       
        return (
          <div>
            <ModalTopSection
              title={title}
              discard={this.discardCreation}
              saveReady={saveReady}
              showButton={true}
              buttonText={buttonName}
              clicked={this.saveHandler}
              saveButton={true}
              modalView={true}
              RTL = {this.props.RTL}
            />
           
          </div>
        );
      };

    renderCreationMessage = () =>{
        let renderMessage = true;
        if (this.state.placeName.inputText!==""&&
        this.state.locationIsSet &&
        this.state.categorySelected
        ){
            if (this.props.editMode === "SuggestEdit"){
                renderMessage = false;
            }
            else{
                if (this.state.imagePreview.length>0 ){
                    renderMessage = false;
                }
            }
        }
        if (renderMessage){
            return(
                <div className={classes.CreationMessage}>
                   {this.props.systemText.place.createPlace.creationMessage[this.props.userLanguage]}
                </div>
            )
        }
    }

    renderPlaceName = () => {
        let inputClass = "TitleInput"
        if (this.state.placeName[this.state.placeNameInputLanguage].length<3){
            inputClass = "TitleInputRed"
        }
        return (
        <div className={classes.Section}>
          <div className={classes.PlaceTitle}>
            <input
                className = {classes[inputClass]}
                value = {this.state.placeName[this.state.placeNameInputLanguage]}
                placeholder = {this.props.systemText.place.createPlace.placeNamePlaceHolder[this.props.userLanguage]}
                onChange={this.placeNameHandler}
                onKeyDown={this.placeNameHandler}
                onKeyPress={this.placeNameHandler}
                />
            {this.renderFavoriteIcon()} 
            </div>
        </div>
        );
      };

     

      renderFavoriteIcon = () =>{
          let favoriteIcon = ""
          if (this.state.inFavorite){
            favoriteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FInFavorites.png?alt=media&token=a5d129b8-23a0-4051-9993-731ba1325bdf"
          }
          else{
            favoriteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FInFavoritesGrey.png?alt=media&token=7063ef15-2b81-43be-ae29-151b614c880a"
          }
          return(
            <div className={classes.AddToFavorites} onClick={()=>{this.favoriteIconHandler(!this.state.inFavorite) }}>
                <img src={favoriteIcon} alt="" className={classes.FavoriteIcon}/>
            </div>
          )
      } 

      renderLocationHeaderSelected = () =>{
          let iconClass = "Icon"
          if (this.props.RTL){
              iconClass = "IconRTL"
          }
        return(
            <div className={classes.SectionHeaderTitle}>
            <img src={locationIcon} alt="" className={classes[iconClass]}/>
            <div className={classes.HeaderSelected}>
                {this.props.systemText.place.createPlace.locationSectionHeader[this.props.userLanguage]}
            </div>
        </div>
        )
    }

    renderLocationHeaderGrey=()=>{
        let iconClass = "Icon"
        if (this.props.RTL){
            iconClass = "IconRTL"
        }
        return(
            <div className={classes.SectionHeaderTitle}>
                <img src={locationIconRed} alt="" className={classes[iconClass]}/>
                <div className={classes.HeaderGrey}>
                    {this.props.systemText.place.createPlace.locationSectionHeader[this.props.userLanguage]}
                </div>
            </div>
        )
    }
    
    renderLocationContentSet = () =>{
        let inputModeText =""
        if (this.state.placeLocation.inputMode==="Search"){
            inputModeText=this.state.placeLocation.address;
        }
        else{
            inputModeText=this.props.systemText.place.createPlace.locationInputMode[this.props.userLanguage];
        }
        let imageIconClass = "ImageIcon"
        if (this.props.RTL){
            imageIconClass = "ImageIconRTL"
        }
        return(
            <div className={classes.LocationContent}>
                <div className={classes.PlaceAddress}>
                        {inputModeText}
                    </div>
                <div className={classes.ButtonsSection}>
                    <div className={classes.LocationButtonRed} onClick={this.removeLocationHandler}>
                        <img src={resetIcon} className={classes[imageIconClass]} alt="" />
                        <div className={classes.ImageText}>
                            {this.props.systemText.place.createPlace.resetLocationButton[this.props.userLanguage]}
                            </div>
                    </div>
                </div>
            </div>
        )
    }

    renderLocationContentUnset = () =>{
        let imageIconClass = "ImageIcon"
        if (this.props.RTL){
            imageIconClass = "ImageIconRTL"
        }
        return(
            <div className={classes.LocationContent}>
                <div className={classes.ButtonsSection}>
                    <div className={classes.LocationButton} onClick={this.locationHandler}>
                        <img src={pinIcon} className={classes[imageIconClass]} alt="" />
                        <div className={classes.ImageText}>{this.props.systemText.place.createPlace.setLocationButton[this.props.userLanguage]}</div>
                    </div>
                </div>
            </div>
        )
    }

    renderMobileLocation = () =>{
        if (this.props.editMode!=="SuggestEdit"){
            let detailIconClass = "DetailIcon"
            if (this.props.RTL){
                detailIconClass = "DetailIconRTL"
            }
            if (this.state.locationSectionOpen){
                if (this.state.locationIsSet){
                    return(
                        <div className={classes.Section}>
                            <div className={classes.SectionHeader} onClick={()=>{this.setState({locationSectionOpen:false})}}>
                                {this.renderLocationHeaderSelected()}
                                <img className={classes[detailIconClass]} alt="" src={collapseIcon} />
                                
                            </div>
                            {this.renderLocationContentSet()}
                        </div>
                    )
                }
                else{
                    return(
                        <div className={classes.Section}>
                            <div className={classes.SectionHeader} onClick={()=>{this.setState({locationSectionOpen:false})}}>
                                {this.renderLocationHeaderGrey()}
                                <img className={classes[detailIconClass]} alt="" src={collapseIcon} />
                            
                            </div>
                            {this.renderLocationContentUnset()}
                        </div>
                    )
                }
            }
            else{
                if (this.state.locationIsSet){
                    return(
                        <div className={classes.Section}>
                            <div className={classes.SectionHeader} onClick={()=>{this.setState({locationSectionOpen:true})}}>
                                {this.renderLocationHeaderSelected()}
                                <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                        
                            </div>
                        </div>
                    )
                }
                else{
                    return(
                        <div className={classes.Section}>
                            <div className={classes.SectionHeader} onClick={()=>{this.setState({locationSectionOpen:true})}}>
                                {this.renderLocationHeaderGrey()}
                                <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                        
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    renderLocation = () =>{
        if (this.props.editMode!=="SuggestEdit"){
            if (this.state.locationIsSet){
                return(
                    <div className={classes.Section}>
                        <div className={classes.SectionHeader} onClick={()=>{this.setState({locationSectionOpen:false})}}>
                            {this.renderLocationHeaderSelected()}
                        </div>
                        <div className={classes.LocationRow}>
                            {this.renderLocationContentSet()}
                            {this.renderLocationPath()}
                        </div>
                      
                    </div>
                )
            }
            else{
                return(
                    <div className={classes.Section}>
                        <div className={classes.SectionHeader} onClick={()=>{this.setState({locationSectionOpen:false})}}>
                            {this.renderLocationHeaderGrey()}
                        </div>
                        {this.renderLocationContentUnset()}
                       
                    </div>
                )
            }
        }
    }

    renderLocationPath = () =>{
        let placeCountryName = "";
        let placeRegionName = "";
        let placeCityName = "";
        let placeCountryTreeID;
        let locationPath={
            placeCountry:"",
            placeRegion:"",
            placeCity:""
        }
        if (this.state.locationIsSet){
            let placeCountry = this.state.placeLocation.locationTree.placeCountry
            let placeRegion = this.state.placeLocation.locationTree.placeRegion
            let placeCity = this.state.placeLocation.locationTree.placeCity
            if (this.state.placeStatus==="potential"){
                if (placeCountry!==undefined){
                    if (placeCountry[this.props.userLanguage]!==undefined){
                        placeCountryName=placeCountry[this.props.userLanguage]
                    }
                    else if(placeCountry["English"]!==undefined){
                    placeCountryName=placeCountry["English"]
                    }
                    else{
                        let names =  Object.keys(placeCountry);
                        placeCountryName=placeCountry[names[0]]
                    }
                    locationPath.placeCountry = placeCountry.treeID
                }
                placeCountryTreeID = placeCountry.treeID

                if (placeRegion!==undefined){
                    if (placeRegion[this.props.userLanguage]!==undefined){
                        placeRegionName=placeRegion[this.props.userLanguage]
                    }
                    else if(placeRegion["English"]!==undefined){
                        placeRegionName=placeRegion["English"]
                    }
                    else{
                        let names =  Object.keys(placeRegion);
                        placeRegionName=placeRegion[names[0]]
                    }
                    locationPath.placeRegion = placeRegion.treeID.split('_')[1]
                }
                
                if (placeCity!==undefined){
                    if (placeCity[this.props.userLanguage]!==undefined){
                        placeCityName=placeCity[this.props.userLanguage]
                    }
                    else if(placeCity["English"]!==undefined){
                        placeCityName=placeCity["English"]
                    }
                    else{
                        let names =  Object.keys(placeCity);
                        placeCityName=placeCity[names[0]]
                    }
                    locationPath.placeCity = placeCity.treeID.split('_')[1]
                }

                
            }
            else{
                //place approved - use the location translator
                if (placeCountry!==undefined){
                    if (this.props.locationsTranslator[placeCountry]!==undefined){
                        if (this.props.locationsTranslator[placeCountry][this.props.userLanguage]!==undefined){
                            placeCountryName=this.props.locationsTranslator[placeCountry][this.props.userLanguage]
                        }
                        else if(this.props.locationsTranslator[placeCountry]["English"]!==undefined){
                            placeCountryName=this.props.locationsTranslator[placeCountry]["English"]
                        }
                        else{
                            let names =  Object.keys(this.props.locationsTranslator[placeCountry]);
                            placeCountryName=this.props.locationsTranslator[placeCountry][names[0]]
                        }
                        locationPath.placeCountry =placeCountry
                    }
                   
                }
                placeCountryTreeID = placeCountry

                if (placeRegion!==undefined){
                    if (this.props.locationsTranslator[placeRegion]!==undefined){
                        if (this.props.locationsTranslator[placeRegion][this.props.userLanguage]!==undefined){
                            placeRegionName=this.props.locationsTranslator[placeRegion][this.props.userLanguage]
                        }
                        else if(this.props.locationsTranslator[placeRegion]["English"]!==undefined){
                            placeRegionName=this.props.locationsTranslator[placeRegion]["English"]
                        }
                        else{
                            let names =  Object.keys(this.props.locationsTranslator[placeRegion]);
                            placeRegionName=this.props.locationsTranslator[placeRegion][names[0]]
                        }
                        locationPath.placeRegion = placeRegion.split('_')[1]
                    }
                    
                }
                
                if (placeCity!==undefined){
                    if (this.props.locationsTranslator[placeCity]!==undefined){
                        if (this.props.locationsTranslator[placeCity][this.props.userLanguage]!==undefined){
                            placeCityName=this.props.locationsTranslator[placeCity][this.props.userLanguage]
                        }
                        else if(this.props.locationsTranslator[placeCity]["English"]!==undefined){
                            placeCityName=this.props.locationsTranslator[placeCity]["English"]
                        }
                        else{
                            let names =  Object.keys(this.props.locationsTranslator[placeCity]);
                            placeCityName=this.props.locationsTranslator[placeCity][names[0]]
                        }
                        locationPath.placeCity = placeCity.split('_')[1]
                    }
                }
            }
            
            
            if (placeRegionName!==""&&placeCityName!==""){
                return(<div className={classes.LocationPath} onClick={()=>{this.locationPathHandler(locationPath)}}>
                    <div className={classes.LocationString}>{placeCityName+", "+placeRegionName+", "+placeCountryName}</div>
                    </div>)
            }
            else if(placeRegionName!==""){
                return(<div className={classes.LocationPath} onClick={()=>{this.locationPathHandler(locationPath)}}>
                    <div className={classes.LocationString}>{placeRegionName+", "+placeCountryName}</div>
                    </div>)
            }
            else{
                return(<div className={classes.LocationPath} onClick={()=>{this.locationPathHandler(locationPath)}}>
                <div className={classes.LocationString}> {placeCityName+" ,"+placeCountryName}</div>
                    </div>)
            }
        }
    }

    renderContactHeaderSelected = () =>{
        let iconClass = "Icon"
        if(this.props.RTL){
            iconClass = "IconRTL"
        }
        return(
            <div className={classes.SectionHeaderTitle}>
                <img src={contactIcon} alt="" className={classes[iconClass]}/>
                <div className={classes.HeaderSelected}>
                {this.props.systemText.place.createPlace.contactSectionHeader[this.props.userLanguage]}
                </div>
            </div>
        )
    }

    
    renderContactHeaderGrey = () =>{
        let iconClass = "Icon"
        if(this.props.RTL){
            iconClass = "IconRTL"
        }
        return(
            <div className={classes.SectionHeaderTitle}>
                <img src={contactIconGrey} alt="" className={classes[iconClass]}/>
                <div className={classes.HeaderGrey}>
                {this.props.systemText.place.createPlace.contactSectionHeader[this.props.userLanguage]}
                </div>
            </div>
        )
    }

    updatePhoneNumber=(phoneNumber)=>{
       /*  console.log("update phone number")
        console.log(phoneNumber) */
        var pattern = new RegExp(/^[0-9\b]+$/);

        if (pattern.test(phoneNumber)&&phoneNumber.length>8) {

           this.setState({contactIsSet:true})

        }
        else{
            this.setState({contactIsSet:false})
        }
        this.setState({phoneNumber:phoneNumber, changesMade:true})
        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_PHOTN_UPDATE",
            userScreen:this.state.activityScreen
        })
    }


    renderContactInfo = () =>{
         return(
            <ContactInfo
            phoneNumber={this.state.phoneNumber}
            updatePhoneNumber={(phone)=>{this.updatePhoneNumber(phone)}}
            facebookLink={this.state.facebookLink}
            updateFacebookLink={(e)=>{this.setState({facebookLink:e.target.value, changesMade:true})}}
            instagramLink={this.state.instagramLink}
            updateInstagramLink={(e)=>{this.setState({instagramLink:e.target.value, changesMade:true})}}
            twitterLink={this.state.twitterLink}
            updateTwitterLink={(e)=>{this.setState({twitterLink:e.target.value, changesMade:true})}}
            officialWebsiteLink={this.state.officialWebsiteLink}
            updateWebsiteLink={(e)=>{this.setState({officialWebsiteLink:e.target.value, changesMade:true})}}
            systemText={this.props.systemText}
            userLanguage={this.props.userLanguage}
            RTL = {this.props.RTL}
            />
        ) 
    }

      renderMobileContact = () =>{
        let iconClass = "ImageIcon"
        let detailIconClass = "DetailIcon"
        if (this.props.RTL){
            detailIconClass = "DetailIconRTL"
            iconClass = "ImageIconRTL"
        }
        if(this.state.contactSectionOpen){
                return(
                    <div className={classes.Section}>
                        {this.state.contactIsSet?(
                            <div className={classes.SectionHeader} onClick={()=>{this.setState({contactSectionOpen:false})}}>
                                {this.renderContactHeaderSelected()}
                            <img className={classes[detailIconClass]} alt="" src={collapseIcon} />
                        </div>
                        ):(
                            <div className={classes.SectionHeader} onClick={()=>{this.setState({contactSectionOpen:false})}}>
                                {this.renderContactHeaderGrey()}
                                <img className={classes[detailIconClass]} alt="" src={collapseIcon} />                          
                            </div>
                        )}
                        
                    {this.renderContactInfo()}
                    <div className = {classes.Links}>
                        <div className={classes.LinkButton}  onClick={this.linksHandler}>
                            {this.state.placeLinks.length>0?(
                                <img src={webLinkIcon} alt="" className={classes[iconClass]}/>
                            ):(
                                <img src={webLinkGreyIcon} alt="" className={classes[iconClass]}/>
                            )}
                            {this.props.systemText.place.createPlace.addAdditionalLinks[this.props.userLanguage]}
                        </div>
                        {this.state.placeLinks.length>0?(
                            <div className={classes.LinksList}>
                                {this.state.placeLinks.map((link, index) => (
                                <a href={link.url} target="_blank" key={index}>
                                <div className={classes.Link} key={index}>
                                    {link.name}
                                    </div>
                                    </a> 
                                ))}
                            </div>
                        ):null}
                    </div>
                    </div>
                ) 
         }
         else{
             if (this.state.contactIsSet){ 
                 return(
                     <div className={classes.Section}>
                         <div className={classes.SectionHeader} onClick={()=>{this.setState({contactSectionOpen:true})}}>
                            {this.renderContactHeaderSelected()}
                            <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                            
                         </div>
                     </div>
                 )
              }
             else{
                 return(
                     <div className={classes.Section}>
                         <div className={classes.SectionHeader} onClick={()=>{this.setState({contactSectionOpen:true})}}>
                            {this.renderContactHeaderGrey()}
                            <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                            
                         </div>
                     </div>
                 )
             } 
         }
      }

    renderContact = () =>{
        let iconClass = "ImageIcon"
        if (this.props.RTL){
            iconClass = "ImageIconRTL"
        }
        return(
            <div className={classes.Section}>
                <div className={classes.SectionHeader} onClick={()=>{this.setState({contactSectionOpen:false})}}>
                   {this.renderContactHeaderGrey()}                        
                </div>
                {this.renderContactInfo()}
                <div className = {classes.Links}>
                        <div className={classes.LinkButton}  onClick={this.linksHandler}>
                            {this.state.placeLinks.length>0?(
                                <img src={webLinkIcon} alt="" className={classes[iconClass]}/>
                            ):(
                                <img src={webLinkGreyIcon} alt="" className={classes[iconClass]}/>
                            )}
                            {this.props.systemText.place.createPlace.addAdditionalLinks[this.props.userLanguage]}
                        </div>
                    {this.state.placeLinks.length>0?(
                       <div className={classes.LinksList}>
                            {this.state.placeLinks.map((link, index) => (
                            <a href={link.url} target="_blank" key={index}>
                            <div className={classes.Link} key={index}>
                                {link.name}
                                </div>
                                </a> 
                             ))}
                         </div>
                    ):null}
                </div>
            </div>
        )
    }
 
    renderOpenHours = () =>{
        if (!this.state.savingPlace){
            return(
                <div className={classes.Section}>
                    <OpenHoursPreview
                    creationEditMode={true}
                    hoursHandler={this.hoursHandler}
                    openHours={[...this.state.hoursRows]}                    
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}
                />
                </div>
                
            )
        }
        
  } 

  renderCategoryHeaderSelected = () =>{
    let iconClass = "Icon"
    if(this.props.RTL){
        iconClass = "IconRTL"
    }
    return(
        <div className={classes.SectionHeaderTitle}>
                    <img src={categoryIcon} alt="" className={classes[iconClass]}/>
                    <div className={classes.HeaderSelected}>
                        {this.props.systemText.place.createPlace.categorySectionHeader[this.props.userLanguage]}
                    </div>
                </div>
    )
}

renderCategoryHeaderGrey = () =>{
    let iconClass = "Icon"
    if(this.props.RTL){
        iconClass = "IconRTL"
    }
    return(
        <div className={classes.SectionHeaderTitle}>
        <img src={categoryIconRed} alt="" className={classes[iconClass]}/>
        <div className={classes.HeaderGrey}>
            {this.props.systemText.place.createPlace.categorySectionHeader[this.props.userLanguage]}
        </div>
    </div>
    )
}



renderCategoriesList=()=>{
    return(
        <div className={classes.Categories}>
            {[0,0,0,0,0,0].map((i,index)=>{
                let disabled=false
                if (this.state.creatingAccommodation){
                    if (i!==5){
                        disabled=true
                    }
                }
                return(<div className={classes.Category}  key = {index}>
                    <MainCategory
                       index = {index}
                       selected={this.state.placeMainCategory[index]===1}
                       disabled={disabled}
                       clicked={()=>{if(!disabled){this.mainCategoryHandler(index)}}}
                       systemText = {this.props.systemText}
                       userLanguage = {this.props.userLanguage}
                       RTL = {this.props.RTL}
                   />
                   </div>)               
                    })}
        </div>
    )
}

renderCharacteristicsHeader = () =>{
    let iconClass = "Icon"
    if(this.props.RTL){
        iconClass = "IconRTL"
    }
    return(
        <div className={classes.SectionHeaderTitle}>
       {/*  <img src={characteristicsIcon} alt="" className={classes[iconClass]}/> */}
        <div className={classes.HeaderGrey}>
        {this.props.systemText.placeCharacteristics.placeCharacteristicsTitle[this.props.userLanguage]}
        </div>
    </div>
    )
}

renderMobileCharacteristics = () =>{
    let detailIconClass = "DetailIcon"
    if (this.props.RTL){
        detailIconClass = "DetailIconRTL"
    }
    if(this.state.characteristicsOpen){
            return(
                <div className={classes.Section}>
                   <div className={classes.SectionHeader} onClick={()=>{this.setState({characteristicsOpen:false})}}>
                            {this.renderCharacteristicsHeader()}
                            <img className={classes[detailIconClass]} alt="" src={collapseIcon} />                          
                        </div>
                    
                {this.renderPlaceCharacteristics()}
                </div>
            ) 
     }
     else{
        return(
            <div className={classes.Section}>
               <div className={classes.SectionHeader} onClick={()=>{this.setState({characteristicsOpen:true})}}>
                       {this.renderCharacteristicsHeader()}
                       <img className={classes[detailIconClass]} alt="" src={detailIcon} />                          
                   </div>
            </div>
        )
     }
  }

 renderPlaceCharacteristics = () =>{
     if (!this.props.quickCreation  && (this.props.editMode === 'Create'||this.state.editPlaceInitialized)){
        return(
            <PlaceCharacteristics
            placeMainCategory={[...this.state.placeMainCategory]}
            placeCategory = {this.state.placeCategory}
            systemText={this.props.systemText}
            userLanguage={this.props.userLanguage}
            selectedLanguage={this.state.placeNameInputLanguage}
            placesCharacteristicsData={this.props.placesCharacteristicsData}
            placeCharacteristics={this.state.placeCharacteristics}
            RTL = {this.props.RTL}
            inputLanguage = {this.props.inputLanguage}
            detectInputLanguage = {this.props.detectInputLanguage}

            updateCharacteristics = {this.updateCharacteristicsHandler}
        />
        )
     }
} 

renderMobileCategory=()=>{
    let detailIconClass = "DetailIcon"
    if (this.props.RTL){
        detailIconClass = "DetailIconRTL"
    }
    if (this.state.categorySectionOpen){
        let placeCategoryID=[];
        for (let i=0 ; i<this.state.placeCategory.length ; i++){
            placeCategoryID.push(this.state.placeCategory[i].id)
        }
        return(
            <div className={classes.Section}>
                {this.state.categorySelected&&JSON.stringify(this.state.placeMainCategory)!==JSON.stringify([0,0,0,0,0,0])?(
                    <div className={classes.SectionHeader}  onClick={()=>{this.setState({categorySectionOpen:false})}}>
                        {this.renderCategoryHeaderSelected()}
                        <img className={classes[detailIconClass]} alt="" src={collapseIcon}/>
                    
                    </div>
                ):(
                    <div className={classes.SectionHeader}  onClick={()=>{this.setState({categorySectionOpen:false})}}>
                        {this.renderCategoryHeaderGrey()}
                        <img className={classes[detailIconClass]} alt="" src={collapseIcon}/>
                    
                    </div>
                )}
                
                {this.renderCategoriesList()}
                {this.renderSubCategory()}
                
            </div>
        )
    }
    else{
        if (this.state.categorySelected){
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({categorySectionOpen:true})}}>
                       {this.renderCategoryHeaderSelected()}
                       <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                       
                    </div>
                </div>
            )
        }
        else{
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({categorySectionOpen:true})}}>
                       {this.renderCategoryHeaderGrey()}
                       <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                       
                    </div>
                </div>
            )
        }
    }
   
}

renderSlimCategory = () =>{

}


renderCategory = () =>{
    let placeCategoryID=[];
    for (let i=0 ; i<this.state.placeCategory.length ; i++){
        placeCategoryID.push(this.state.placeCategory[i].id)
    }
    return(
        <div className={classes.Section}>
            {this.state.categorySelected&&JSON.stringify(this.state.placeMainCategory)!==JSON.stringify([0,0,0,0,0,0])?(
                <div className={classes.SectionHeader}>
                    {this.renderCategoryHeaderSelected()}
                </div>
            ):(
                <div className={classes.SectionHeader}>
                    {this.renderCategoryHeaderGrey()}
                </div>
            )}
            
            {this.renderCategoriesList()}
            {this.renderSubCategory()}
         
        </div>
    )
}

renderSubCategory = () =>{
    if (JSON.stringify(this.state.placeMainCategory)!==JSON.stringify([0,0,0,0,0,0])){
        let optionsList = [];
        for (let i =0 ; i<this.state.placeMainCategory.length ; i++){
            if (this.state.placeMainCategory[i]===1){
                optionsList=optionsList.concat( Object.values(this.props.systemCategories[i]))
            }
        }
        return(
            <div className={classes.CategorySection}>
                <div className={classes.SelectedCategories}>
                    {this.state.placeCategory.map((tag, index) => (
                        <Tag
                        key={index}
                        text={tag[this.props.userLanguage]}
                        removable={true}
                        style={"CategoryFilter"}
                        remove={() => {this.removeCategory(index)}}
                        RTL = {this.props.RTL}
                      />
                    ))}
                </div>          
                <div className={classes.SelectedCategories}>
                    {this.state.placeNewCategories.map((tag, index) => (
                        <Tag
                        key={index}
                        text={tag.inputText}
                        removable={true}
                        style={"OtherCategoryFilter"}
                        remove={() => {this.removeNewCategory(index)}}
                        RTL = {this.props.RTL}
                      />
                    ))}
                </div>                                                                            
                <div className={classes.CategoriesSelectionList}>
                 <OptionSelection
                    multiple={true}
                    currentSelection={""}
                    optionsList={optionsList}
                    updateSelectedOption={this.updatePlaceCategory}
                    placeholder={this.props.systemText.place.createPlace.selectSubCategoryPlaceHolder[this.props.userLanguage]}
                    valueRequired={this.state.placeCategory.length===0&&this.state.placeNewCategories.length===0}
                    onlyFromList={true}
                    userLanguage={this.props.userLanguage}
                    errorMesage={this.props.systemText.place.createPlace.subCategoryErrorMessage[this.props.userLanguage]}
                    systemText={this.props.systemText}
                    noResultPlaceholder = {{
                        id:"other_other",
                        English:"Other",
                        Hebrew:"אחר"
                    }}
                    RTL = {this.props.RTL}
                    inputLanguage = {this.props.inputLanguage}
                    detectInputLanguage = {this.props.detectInputLanguage}
                /> 
                </div>
            
                {this.state.showNewCategory?(
                    <input 
                    className={classes.NewCategotry}
                    value={this.state.placeNewCategory.inputText}
                    onChange={this.newCategoryInputHandler}
                    onKeyDown={this.newCategoryInputHandler}
                    onKeyPress={this.newCategoryInputHandler}
                    onBlur={this.newCategoryBlur}
                    placeholder={this.props.systemText.place.createPlace.newCategoryPlaceHolder[this.props.userLanguage]}
                    />
                ):(null)}
            </div>
        )   
    }
}

renderTagsSection=()=>{
    if (!this.props.quickCreation&& (this.props.editMode === 'Create'||this.state.editPlaceInitialized)){
        return(
            <TaggingSection
             selectedTags={this.state.placeTags}
             userTags={[]}
             updateTags={this.updatePlaceTags}
             placeholder= {this.props.systemText.place.createPlace.addTagsPlaceHolder[this.props.userLanguage]}
             userLanguage={this.props.userLanguage}
             systemText={this.props.systemText}
             RTL = {this.props.RTL}
            />
         )
    }
}


renderImageHeaderSelected = () =>{
    let iconClass = "Icon"
    if(this.props.RTL){
        iconClass = "IconRTL"
    }
    return(
        <div className={classes.SectionHeaderTitle}>
                    <img src={imageIcon} alt="" className={classes[iconClass]}/>
                    <div className={classes.HeaderSelected}>
                        {this.props.systemText.place.createPlace.imagesSectionHeader[this.props.userLanguage]}
                    </div>
                </div>
    )
}

renderImageHeaderGrey = () =>{
    let iconClass = "Icon"
    if(this.props.RTL){
        iconClass = "IconRTL"
    }
    return(
        <div className={classes.SectionHeaderTitle}>
        <img src={imageIconRed} alt="" className={classes[iconClass]}/>
        <div className={classes.HeaderGrey}>
        {this.props.systemText.place.createPlace.imagesSectionHeader[this.props.userLanguage]}
        </div>
    </div>
    )
}

renderImageSectionContent=()=>{
    let imageIconClass = "ImageIcon"
    if (this.props.RTL){
        imageIconClass = "ImageIconRTL"
    }
    return(
        <div className={classes.ImageSection}>
        <div className={classes.ImageContent}>
            <div className={classes.ImageButton} >
                <div className={classes.ImageUploadWrapper}>
                    <img src={uploadIconGrey} className={classes[imageIconClass]} alt="" />
                    <input
                    type="file"
                    onChange={this.imageUploadHandler}
                    multiple={true}
                    accept="image/*"
                    className={classes.FileInput}
                />
                <div className={classes.ImageText}>{this.props.systemText.place.createPlace.uplodaImageButton[this.props.userLanguage]}</div>
            </div>
            </div>
            {isMobile?(
               /*  <div className={classes.ImageButton}>
                    <img src={pasteIconGrey} className={classes[imageIconClass]} alt="" />
                    <input className={classes.ImageText}value=  {this.props.systemText.place.createPlace.pasteImageButton[this.props.userLanguage]} onPaste={this.mobileImagePasteHandler}/>
            </div> */null
            ):(
                <div className={classes.ImageButton}  onClick={this.imagePasteHandler} >
                    <img src={pasteIconGrey} className={classes[imageIconClass]} alt="" />
                    <div className={classes.ImageText}>{this.props.systemText.place.createPlace.pasteImageButton[this.props.userLanguage]}</div>
                
            </div>
            )}
           
            </div>
            <div className={classes.Images}>
            <ImageUploader
                ref="imageChild"
                discard={this.discardPhotoHandler}
                update={this.updateImages}
                imagePreview={this.state.imagePreview}
                imageGallery={this.state.imageGallery}
                narrowMode={false}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}

                handleSaving = {value=>{this.setState({imageProcess:value})}}
                />
            </div>
        </div>
    )
}


renderMobileImagesSection = () =>{
    let detailIconClass = "DetailIcon"
    if (this.props.RTL){
        detailIconClass = "DetailIconRTL"
    }
    if(this.state.imageSectionOpen){
        if (this.state.imagePreview.length>0){
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({imageSectionOpen:false})}}>
                       {this.renderImageHeaderSelected()}
                       <img className={classes[detailIconClass]} alt="" src={collapseIcon} />
                    </div>
                    {this.renderImageSectionContent()}
                </div>
            )
        }
        else{
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({imageSectionOpen:false})}}>
                       {this.renderImageHeaderGrey()}
                       <img className={classes[detailIconClass]} alt="" src={collapseIcon} />                          
                    </div>
                    {this.renderImageSectionContent()}
                </div>
            )
        }
    }
    else{
        if (this.state.imagePreview.length>0){
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({imageSectionOpen:true})}}>
                       {this.renderImageHeaderSelected()}
                       <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                       
                    </div>
                </div>
            )
        }
        else{
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({imageSectionOpen:true})}}>
                       {this.renderImageHeaderGrey()}
                       <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                       
                    </div>
                </div>
            )
        }
    }
}

renderImagesSection = () =>{
    return(
        <div className={classes.Section}>
            {this.state.imagePreview.length>0?(
                 <div className={classes.SectionHeader}>
                    {this.renderImageHeaderSelected()}                       
                 </div>
            ):(
                <div className={classes.SectionHeader}>
                    {this.renderImageHeaderGrey()}                       
                </div>
            )}
           
            {this.renderImageSectionContent()}
        </div>
    )
}

renderGeneralInfoHeaderSelected = () =>{
    let iconClass = "Icon"
    if(this.props.RTL){
        iconClass = "IconRTL"
    }
    return(
        <div className={classes.SectionHeaderTitle}>
                    <img src={generalInfoIcon} alt="" className={classes[iconClass]}/>
                    <div className={classes.HeaderSelected}>
                        {this.props.systemText.place.createPlace.generalInfoSectionHeader[this.props.userLanguage]}
                    </div>
                </div>
    )
}

renderGeneralInfoHeaderGrey = () =>{
    let iconClass = "Icon"
    if(this.props.RTL){
        iconClass = "IconRTL"
    }
    return(
        <div className={classes.SectionHeaderTitle}>
        <img src={generalInfoIconGrey} alt="" className={classes[iconClass]}/>
        <div className={classes.HeaderGrey}>
            {this.props.systemText.place.createPlace.generalInfoSectionHeader[this.props.userLanguage]}
        </div>
    </div>
    )
}

renderPlaceDescription = () => {
    let descriptionInputFieldClass = "DescriptionInputField"
    if (this.props.RTL){
        descriptionInputFieldClass = "DescriptionInputFieldRTL"
    }
    return (
      <div className={classes.DescriptionSection}>
        <Editor
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
         
          }}
          stripPastedStyles={true}
          placeholder={
            this.props.systemText.place.createPlace.generalInformationPlaceHolder[this.props.userLanguage]
          }
          editorClassName={classes[descriptionInputFieldClass]}
          editorState={this.state.placeDescription[this.state.placeNameInputLanguage]}
          onEditorStateChange={this.updateDescription}
        />
      
      </div>
    );
  };

renderMobileInformationSection = () =>{
    let detailIconClass = "DetailIcon"
    if (this.props.RTL){
        detailIconClass = "DetailIconRTL"
    }
    if(this.state.informationSectionOpen){
      
       if (this.state.placeDescription[this.state.placeNameInputLanguage].getCurrentContent().hasText()){
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({informationSectionOpen:false})}}>
                       {this.renderGeneralInfoHeaderSelected()}
                       <img className={classes[detailIconClass]} alt="" src={collapseIcon} />
                    </div>
                    {this.renderPlaceDescription()}
                </div>
            )
        }
        else{
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({informationSectionOpen:false})}}>
                       {this.renderGeneralInfoHeaderGrey()}
                       <img className={classes[detailIconClass]} alt="" src={collapseIcon} />                          
                    </div>
                    {this.renderPlaceDescription()}
                </div>
            )
        } 
    }
    else{
        if (this.state.placeDescription[this.state.placeNameInputLanguage].getCurrentContent().hasText()){ 
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({informationSectionOpen:true})}}>
                       {this.renderGeneralInfoHeaderSelected()}
                       <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                       
                    </div>
                </div>
            )
         }
        else{
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionHeader} onClick={()=>{this.setState({informationSectionOpen:true})}}>
                       {this.renderGeneralInfoHeaderGrey()}
                       <img className={classes[detailIconClass]} alt="" src={detailIcon} />
                       
                    </div>
                </div>
            )
        } 
    }
}

renderInformationSection = () =>{
    return(
        <div className={classes.Section}>
            {this.state.placeDescription[this.state.placeNameInputLanguage].getCurrentContent().hasText()?(
                <div className={classes.SectionHeader} onClick={()=>{this.setState({informationSectionOpen:true})}}>
                    {this.renderGeneralInfoHeaderSelected()}
                </div>
            ):(
                <div className={classes.SectionHeader} onClick={()=>{this.setState({informationSectionOpen:true})}}>
                {this.renderGeneralInfoHeaderGrey()}
                </div>
            )}
            
            {this.renderPlaceDescription()}
        </div>
    )
}

renderVisitDuration = () =>{
    return(
        <div className={classes.Section}>
           {/*  {this.state.averageVisitDuration!==0?(
                <div className={classes.SectionHeader} onClick={()=>{this.setState({informationSectionOpen:true})}}>
                    {this.renderVisitDurationHeaderSelected()}
                </div>
            ):(
                <div className={classes.SectionHeader} onClick={()=>{this.setState({informationSectionOpen:true})}}>
                {this.renderVisitDurationHeaderGrey()}
                </div>
            )}
            
            {this.renderPlaceDescription()} */}
        </div>
    )
}

renderSlimBody = () =>{
    return(
        <div className={classes.SlimBody}> 
          <div className={classes.SlimContent}>
         
          {this.renderLocation()}
          {this.renderPlaceName()}
          {this.renderCategory()}
          </div>
        </div> 
      )
}
    
    renderBody = () =>{
        if (isMobile){
            return(
                <div className={classes.Body}> 
                 {this.renderCreationMessage()}
                  <div className={classes.Content}>
                  {this.renderLanguageOptions()}
                  {this.renderMobileLocation()}
                  {this.renderPlaceName()}
                  
                
                  {this.renderMobileCategory()}
                  {this.renderMobileImagesSection()}
                  {this.renderOpenHours()}
                  {this.renderMobileContact()}
                  {this.renderMobileInformationSection()}
                  {this.renderTagsSection()}
                  {this.renderVisitDuration()}
                  {this.renderMobileCharacteristics()}
                    
                  </div>
                </div> 
              )
        }
        else{
            return(
                <div className={classes.Body}> 
                    {this.renderCreationMessage()}
                    <div className={classes.Content}>
                     
                        <div className={classes.MandatoryContent}>
                            {this.renderLanguageOptions()}
                             {this.renderLocation()}
                             {this.renderPlaceName()}
                             {this.renderCategory()}
                             {this.renderImagesSection()}
                        </div>
                        {this.renderShowAdditionalInfo()}
                       
                            <div className={classes.AdditionalInfo} ref={this.state.extraInfoRef}>
                            {this.state.showAdditionalInfo?(
                                <div>
                               
                                {this.renderOpenHours()}
                                {this.renderInformationSection()}
                                {this.renderContact()}
                                {this.renderTagsSection()}
                                {this.renderVisitDuration()}
                                {this.renderPlaceCharacteristics()} 

                                </div>
                                 ):null}
                                
                            </div> 
                      
                         
                    </div>
                </div> 
              )
        }
         
      }

      renderShowAdditionalInfo = () =>{
          let showExtraInfo = "ShowExtraInfo"
          if (this.props.RTL){
            showExtraInfo = "ShowExtraInfoRTL"
          }
          if (this.state.showAdditionalInfo){
            return(
                <div className={classes[showExtraInfo]} onClick={()=>{this.setState({showAdditionalInfo:false})}}>
                    <img src = {arrowIconLeft} className={classes.UpArrow} />
                </div>
            )
          }
          else{
              return(
                  <div className={[classes[showExtraInfo],classes.WhiteBackground].join(" ")} onClick={this.showAdditionalInfoHandler}>
                      <div className={classes.ExtraInfoText}>
                          { this.props.systemText.place.createPlace.updateMoreInfo[this.props.userLanguage]}
                      </div>
                      <img src = {arrowIconLeft} className={classes.Arrow} />
                  </div>
              )
          }
      }

      showAdditionalInfoHandler = async () =>{
          await this.setState({showAdditionalInfo: true})
          setTimeout(() => {
            this.state.extraInfoRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                delay:300,
              });
           }, 100);
      }


      renderConfirmModal = () => {
        if (this.state.confirmMode) {
          return (
            <ConfirmModal
              header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
              primaryAction={this.discardDiscardHandler}
              secondaryAction={this.discardChangesHandler}
              message={this.state.confirmMessage}
              primaryBtn={this.state.primaryBtn}
              secondaryBtn={this.state.secondaryBtn}
              primaryStyle = {"PositiveButton"}
              secondaryStyle = {"DiscardButton"}
              RTL = {this.props.RTL}

            />
          );
        }
      };

      renderLocationModal = () => {
        if (this.state.showLocationModal) {
         return (
            <SetLocation
              discard={this.discardLocationHandler}
              update={this.updateLocation}
              location={this.state.placeLocation}
              title={this.props.systemText.extraModals.setLocation.title[this.props.userLanguage]}
              userCountry={this.props.userCountry}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
              RTL = {this.props.RTL}
              locationsTree={this.props.locationsTree}
              detectInputLanguage = {this.props.detectInputLanguage}
              inputLanguage = {this.props.inputLanguage}
              modalView={this.props.quickCreation}
              mapHeight = {"60vh"}
              showLocationPath = {this.state.showLocationPath}
              locationPath = {this.state.locationPath}
              disableShowLocationPath = {()=>{this.setState({showLocationPath:false})}}

              writeAcitivityLog = {this.props.writeAcitivityLog}
              activityScreen = {this.state.activityScreen}

              getPlacesListForPlaceCreationValidation = {this.props.getPlacesListForPlaceCreationValidation}
            />
          );
        }
      };

      renderLinksModal = () => {
        if (this.state.showManageLinks) {
          return (
            <Links
              discard={this.discardLinksnHandler}
              update={this.updateLinks}
              links={this.state.placeLinks}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
              RTL = {this.props.RTL}
            />
          );
        }
      };

      renderHoursModal = () =>{  
        if (this.state.showManageHours) {
            let hoursRowsCopy = [];
            for (let i=0 ; i<this.state.hoursRows.length ; i++){
              hoursRowsCopy.push(JSON.parse(JSON.stringify(this.state.hoursRows[i])))
            }
          return (
            <OpenHours
              discard={this.discardHoursHandler}
              update={this.updateOpenHours}
              hoursRows={[...hoursRowsCopy]}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
              RTL = {this.props.RTL}

              editPlace={this.props.editMode==="Edit"}

              placeTemporaryClosed={this.state.placeTemporaryClosed}
              temporaryClosedNote={this.state.temporaryClosedNote}
              placePermanentlyClosed={this.state.placePermanentlyClosed}
              permanentlyClosedDate={this.state.permanentlyClosedDate}
    
              updatePlaceTemporaryClosedState={(value)=>{this.setState({placeTemporaryClosed:value})}}
              updatePlaceTemporaryClosedNote = {e=>{this.setState({temporaryClosedNote:e.target.value})}}
              updatePlacePermanentlyClosedState={(value)=>{this.setState({placePermanentlyClosed:value})}}
              updatePlacePermanentlyClosedDate={(value)=>{this.setState({permanentlyClosedDate:value})}}
            />
          );
        }
      }
      

      renderExistingPlaces = () =>{
        if (this.state.showExistingPlaces && this.state.existingPlace!==undefined){
          
            /* let differentLanguage = true;
            if (this.props.existingPlace.placeLanguages.includes(this.state.placeNameInputLanguage)){
                differentLanguage=false;
            } */
            return(
              <div className={classes.ExistingPlaces}>
                  <div className={classes.Message}>{this.props.systemText.place.createPlace.placeExist.message[this.props.userLanguage]}</div>
                   <div className={classes.PlaceView}>
                   { <PlacesList
                      placesList={[this.state.existingPlace]}
                      viewType={"Preview"}
                      user={this.props.user}
                      viewHandler={()=>{this.props.viewPlaceHandler(this.state.existingPlace)}}
                      discoverPlacesNameFilterTag={[]}
                      userLanguage={this.state.placeNameInputLanguage}
                      systemText={this.props.systemText}
                      RTL = {this.props.RTL}
                    />}
                  </div> 
                 {/*  {differentLanguage?(
                       <TextButton
                       clicked={()=>{this.handleLanguageSelection(this.state.placeNameInputLanguage)}}
                       text= {this.props.systemText.place.createPlace.placeExist.createLanguagePrefix[this.props.userLanguage]+this.props.systemText.languages.longLanguage[this.state.placeNameInputLanguage]+this.props.systemText.place.createPlace.placeExist.createLanguagePostfix[this.props.userLanguage]}
                       style={"SaveButton"}/>
                  ):null} */}
                  {this.props.quickCreation?(
                       <TextButton
                       clicked={()=>{this.props.updateQuickCreationPlaceSelection(this.state.existingPlace);this.props.discard()}}
                       text= {this.props.systemText.place.createPlace.placeExist.useThisPlace[this.props.userLanguage]}
                       style={"SaveButton"}/>
                  ):null}
                  <div className={classes.MessageOptions}>
                      <TextButton
                          clicked={()=>{this.setState({showExistingPlaces:false, showTransparentBackdrop:false, locationIsSet:false,placeLocation: { placeAddress: "" }, placeName:{[this.state.placeNameInputLanguage]:""} })}}
                          text={this.props.systemText.place.createPlace.placeExist.resetLocation[this.props.userLanguage]}
                          style={"DiscardButton"}/>
                       <TextButton
                          clicked={this.discardCreation}
                          text={this.props.systemText.place.createPlace.placeExist.discardCreation[this.props.userLanguage]}
                          style={"DeleteButton"}/>
                  </div>
              </div>);
                
        }
    }

   /*  handleLanguageVersion = (language) =>{
        this.setState({placeNameInputLanguage: language.id})

        let placeNameInputLanguage=language.id;
        let placeName=this.props.selectedPlace.placeName;
        if (placeName[placeNameInputLanguage]!==undefined){
            placeName.inputText=placeName[placeNameInputLanguage]
        }
        else{
            placeName.inputText=""
        }
        
        this.setState({placeNameInputLanguage:placeNameInputLanguage,placeName:placeName})
        
        if (this.props.selectedPlace.placeDescription[placeNameInputLanguage]!==undefined){
            try {
                JSON.parse(this.props.selectedPlace.placeDescription[placeNameInputLanguage]);
                this.setState({placeDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.selectedPlace.placeDescription[placeNameInputLanguage]))),
                  jsonString: true
                  })
            } catch (e) {
              this.setState({placeDescription:EditorState.createEmpty(), jsonString: true})
            }
        }
        else{
            this.setState({placeDescription:EditorState.createEmpty(), jsonString: true})
        }
       
    } */

    updateLanguageVersion = async language =>{
        if (this.state.placeName[language.id]===undefined){
            let placeName = this.state.placeName
            placeName[language.id] = ""
            await this.setState({placeName:placeName})
        }
       let placeDescription = this.state.placeDescription
        if (placeDescription[language.id]===undefined){
            placeDescription[language.id] =EditorState.createEmpty()
            await this.setState({placeDescription:placeDescription, jsonString: true})
        }
       

        this.setState({placeNameInputLanguage: language.id})

        this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_LANGUAGE_CHANGED",
            userScreen:this.state.activityScreen
        })
    }

    renderLanguageOptions = ()=>{
        let languageHeaderClass = "LanguageHeader"
        if (this.props.RTL){
            languageHeaderClass = "LanguageHeaderRTL"
        }
        let optionsList = Object.values(this.props.systemLanguages)
        let currentSelection = this.props.systemLanguages[this.state.placeNameInputLanguage][this.props.userLanguage]
        if (this.props.editMode === "Create"){
            //render only selection of possible language place
            return(
                <div className={classes.LanguageSection}>
                    <div className={classes[languageHeaderClass]}>
                        {this.props.systemText.place.createPlace.placeLanguageSection.creationHeader[this.props.userLanguage]}
                    </div>
                    <OptionSelection
                        currentSelection={currentSelection}
                        optionsList={optionsList}
                        updateSelectedOption={this.updateLanguageVersion}
                        required={false}
                        onlyFromList = {true}
                        showCurrentSelection = {true}
                        systemText={this.props.systemText}
                        userLanguage={this.props.userLanguage}
                        RTL = {this.props.RTL}
                        inputLanguage = {this.props.inputLanguage}
                        detectInputLanguage = {this.props.detectInputLanguage}
                        />
                </div>
            )
        }
        else if (this.props.editMode==="Edit"){
            //select which language version to edit
            return(
                <div className={classes.LanguageSection}>
                    <div className={classes[languageHeaderClass]}>
                        {this.props.systemText.place.createPlace.placeLanguageSection.creationHeader[this.props.userLanguage]}
                    </div>
                    <OptionSelection
                        currentSelection={currentSelection}
                        optionsList={optionsList}
                        updateSelectedOption={this.updateLanguageVersion}
                        required={false}
                        onlyFromList = {true}
                        showCurrentSelection = {true}
                        systemText={this.props.systemText}
                        userLanguage={this.props.userLanguage}
                        RTL = {this.props.RTL}
                        inputLanguage = {this.props.inputLanguage}
                        detectInputLanguage = {this.props.detectInputLanguage}
                        />
                </div>
            )
        }
    }

      detectInputLanguage = () =>{
        return (<DetectInputLanguage
            ref="detectInputLanguage"
          />)
      }

    render() {
        let RTL = ""
        if (this.props.RTL){
            RTL = "RTL"
        }
        if (this.props.quickCreation){
            return(
                <div className={[classes.SlimModal,classes[RTL]].join(" ")}>
                 {this.detectInputLanguage()}
                {this.renderLocationModal()}
                {this.renderExistingPlaces()}
               <TransparentBackdrop show={this.state.showTransparentBackdrop}/>
               <Backdrop show={this.state.showBackdrop} />
               {!this.state.showLocationModal?(
                   <div>{this.renderTopSection()}
                   {this.renderSlimBody()}</div>
               ):null}
                   
           </div>
            )
        }
        else{
            return (
                <div className={[classes.SlimModal,classes[RTL]].join(" ")}>
                     {this.renderConfirmModal()}
                     {this.renderLocationModal()}
                     {this.renderLinksModal()}
                     {this.detectInputLanguage()}
                     {this.renderExistingPlaces()}
                     {this.renderHoursModal()} 
                    <TransparentBackdrop show={this.state.showTransparentBackdrop}/>
                    <Backdrop show={this.state.showBackdrop} />
                        {this.renderTopSection()}
                        {this.renderBody()}
                </div>
                )
        }
      }
    }
    
    export default PlaceEditor;