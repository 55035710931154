import React, { Component } from "react";
import classes from "./TripAlbumDay.module.css";

import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import ModalTopSection from "../../../UI/Misc/ModalTopSection"

import {isMobile, isMobileOnly} from 'react-device-detect';
import { Editor, EditorState , convertFromRaw, convertToRaw} from 'draft-js';
import PlanJourneyDay from "../PlanJourneyDay";
import TransparentBackdrop from "../../../UI/Backdrop/TransparentBackdrop";


let placeIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"

const SortableItem = sortableElement(({index, value}) =>
{ 
  return(<img className={classes.MovingImage} alt="" src={value}/>)});

const SortableContainer = sortableContainer(({children}) => {
  return <div  className={classes.ImagesSection}>{children}</div>;
});


class TripAlbumDay extends Component {
    state={
        dayRef:React.createRef(),
    }

    

    componentDidUpdate=(prevProps)=>{
        if (this.props.selectedDayIndex!==prevProps.selectedDayIndex){
            if (this.props.selectedDayIndex===this.props.dayIndex){
                this.scrollToViewHandler()
            }
        }
        if (this.props.albumScroll!==prevProps.albumScroll){
            this.isInViewport()
        }
    }

    scrollToViewHandler = async () =>{
        await this.props.preventScroll(true)
        await setTimeout(() => {
            this.state.dayRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
              });
             
           }, 100);
        await setTimeout(() => {
            this.props.preventScroll(false)
            
            }, 1500);
          
    }

    isInViewport() {
        if (!this.state.dayRef.current) return false;
        let left = this.state.dayRef.current.getBoundingClientRect().left
        let right = this.state.dayRef.current.getBoundingClientRect().right
        if (this.props.albumScroll>0){
            //LRT
            if (left<=0&&right>100){
                this.props.updateSelectedDay(this.props.dayIndex)
            }
        }
        else{
            //RTL
            if (left<= (window.innerWidth || document.documentElement.clientWidth)-100&&right>=(window.innerWidth || document.documentElement.clientWidth)-100){
                this.props.updateSelectedDay(this.props.dayIndex)
            }
        }
      }


    renderPlaceContent = (place,index) =>{
        if ( Object.keys(place.placeExperiences).length === 0){
          if (this.props.loggedUser){
            
            return(
                <div className={classes.PlaceContent}>
                    {this.props.isEditor?(
                        <div className={classes.ShareNewExperience} onClick={()=>{this.props.shareExperience(this.props.dayIndex+1,index)}}>
                         {this.props.systemText.plan.viewPlan.experiencesAlbum.shareExperience[this.props.userLanguage]}
                      </div>
                    ):null}
                    
                </div>
            )
          }
          else{
             
            //if anonymous user try to share experience for his created trip - but he cannot share experiences without login first
            return(
                <div className={classes.PlaceContent}>
                    {this.props.isEditor?(
                        <div className={classes.ShareNewExperience} onClick={()=>{this.setState({showExperienceMessage:true})}}>
                         {this.props.systemText.plan.viewPlan.experiencesAlbum.shareExperience[this.props.userLanguage]}
                      </div>
                    ):null}
                    
                </div>
            )
          }
          
        }
        else{
            let userJourneyImages = []
            let userID = this.props.user.userID
            if (!this.props.isEditor){
                userID = this.props.viewUserJourneyID
            }
            if (this.props.planDays[this.props.dayIndex].dayItems[index].item.placeExperiences[userID]!==undefined){
                if (this.props.planDays[this.props.dayIndex].dayItems[index].item.placeExperiences[userID].userJourneyImages!==undefined){
                    userJourneyImages = this.props.planDays[this.props.dayIndex].dayItems[index].item.placeExperiences[userID].userJourneyImages
                }
            }
            let images = []
            if (isMobileOnly){
                images = userJourneyImages.map(img => {return img.smallImage.url})
            }
            else{
                images = userJourneyImages.map(img => {return img.mediumImage.url})
            }
             //check if other user shared any experiences with images for this place
             if (images.length===0){
                 let usersIDs = Object.keys(this.props.planDays[this.props.dayIndex].dayItems[index].item.placeExperiences)
                 let usersHaveImages = false
                 for (let i=0 ; i<usersIDs.length ; i++){
                     if (this.props.planDays[this.props.dayIndex].dayItems[index].item.placeExperiences[usersIDs[i]].userJourneyImages!==undefined){
                        usersHaveImages = true
                     }
                 }
                 if (usersHaveImages){
                    return(
                        <div className={classes.PlaceContent} onClick={()=>{
                            this.props.showPlaceAlbum(this.props.dayIndex,index,place)}}>
                            <div className={classes.ShareNewExperience}>
                            {this.props.systemText.plan.viewPlan.experiencesAlbum.friendsSharedPhotos[this.props.userLanguage]}
                            </div>
                            
                        </div>
                    )
                }
                else{
                    return(
                       <div className={classes.PlaceContent}>
                       {this.props.isEditor?(
                           <div className={classes.ShareNewExperience} onClick={()=>{this.props.shareExperience(this.props.dayIndex+1,index)}}>
                            {this.props.systemText.plan.viewPlan.experiencesAlbum.shareExperience[this.props.userLanguage]}
                         </div>
                       ):null}
                       
                   </div>
                    )
                }
             }
             else{
                return(
                    <div className={classes.PlaceContent} onClick={()=>{
                        this.props.showPlaceAlbum(this.props.dayIndex,index,place)}}>
                        {images.length>0?(
                            <img src = {images[0]} alt= "" className={classes.ExperiencesFirstImage}/>
                        ):null}
                        
                    </div>
                 )
            }
             
        }
    }


    renderDayPlace = (item,index) =>{
        if (item.type==="place"){
            let place = item.item
            let placeName="";
            //get the relevant language or at least any language name
            if (place.placeName[this.props.userLanguage]!==undefined){
            placeName=place.placeName[this.props.userLanguage]
            }
            else if(place.placeName["English"]!==undefined){
            placeName=place.placeName["English"]
            }
            else{
            let placesNames =  Object.keys(place.placeName);
            placeName=place.placeName[placesNames[0]]
            }
            //console.log(place)
            return(
                <div className={classes.DayPlace}>
                    {this.renderShareExperienceIcon(place,index)}
                    <div className={classes.PlaceTitleSection} >
                        <div className={classes.PlaceName} onClick={()=>{this.props.viewPlaceHandler(item.item)}}>
                            <img src={placeIcon} alt="" className={classes.PlaceIcon}/>
                            {placeName}
                        </div>
                        <div className={classes.DateName}>
                            {this.props.dateName}
                        </div>
                    </div>
                    {this.renderPlaceContent(place,index)}
                </div>
            )
        }
        
    }

    renderShareExperienceIcon = (place,index) =>{
        let experienceIconClass = "ExperienceIcon"
        if (this.props.RTL){
            experienceIconClass = "ExperienceIconRTL"
        }
        return(
            <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FExperienceIcon.png?alt=media&token=b0a60690-11d3-4422-87f6-cfff83ad6ddb"} 
            alt="" className={classes[experienceIconClass]} onClick={()=>{this.props.shareExperience(this.props.dayIndex+1,index)}}/>
        )
    }

    renderExperienceMessage = () =>{
        if (this.state.showExperienceMessage){
            return(
                <div className={classes.ExperienceMessage}>
                    <div className={classes.MessageContent}>
                    {this.props.systemText.plan.viewPlan.experiencesAlbum.messageContent[this.props.userLanguage]}
                    </div>
                    <div className={classes.MessageButton}
                    onClick={()=>{this.setState({showExperienceMessage:false})}}>
                       {this.props.systemText.plan.viewPlan.experiencesAlbum.messageButton[this.props.userLanguage]}
                    </div>
                </div>
            )
        }
    }
  

    render(){
        if (isMobile){
            return(
                <div className={classes.AlbumDay} ref={this.state.dayRef}>
                {this.renderExperienceMessage()}
                <TransparentBackdrop show={this.state.showExperienceMessage}/>
                {this.props.planDays[this.props.dayIndex].dayItems.map((dayItem,index)=>(
                    <div style={{width:"100%",height:"50%"}} key={index}>
                        {this.renderDayPlace(dayItem,index)}
                    </div>
                ))}
            </div>
            )
        }
        else{
            return(
                <div className={classes.AlbumDay} ref={this.state.dayRef}>
                {this.renderExperienceMessage()}
                <TransparentBackdrop show={this.state.showExperienceMessage}/>
                {this.props.planDays[this.props.dayIndex].dayItems.map((dayItem,index)=>(
                    <div style={{height:"100%"}} key={index}>
                        {this.renderDayPlace(dayItem,index)}
                    </div>
                ))}
            </div>
            )
        }
        
    }
}

export default TripAlbumDay;