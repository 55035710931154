import React, { Component } from "react";
import classes from "./Favorite.module.css";

import PlacesWall from "../Content/Places/PlacesWall"
import PlansWall from "../Content/TripPlans/PlansWall"
import SimpleSearchBar from "../../Components/Complex/SearchBars/SimpleSearchBar";
import SearchBarContentType from "../../Components/UI/ContentTypeSelector/SearchBarContentType"

import Tag from "../../Components/UI/Tag/Tag";

export default class Favorite extends Component {

    state={
    }

    clonePlan = index =>{
      let plan = this.props.plans[index];
      this.props.clonePlanHandler(plan);
    }

    updatePlanFavoriteTags = (index) =>{  
      let plan = this.props.plans[index];
      this.props.updateFavoriteTagsHandler(plan,"Trip");
    }
  
    
    removePlanFromFavorite= (index) =>{  
      let plan = this.props.plans[index];
      this.props.removeFromFavoriteHandler(plan,"Trip");
    }
  

    renderSearchBarSection = () =>{
        let searchBarSectionClass = "SearchBarSection"
        if (this.props.RTL){
            searchBarSectionClass = "SearchBarSectionRTL"
        }
      //for places
      let searchTags = []
      let placeHolderMessage = ""
      if (this.props.selectedContentType==="Places"){
        searchTags = this.props.favoritePlacesSearchTags;
        placeHolderMessage = this.props.systemText.favorite.searchSection.searchBarPlacesPlaceholder[this.props.userLanguage];
      }
      else if (this.props.selectedContentType==="Trips"){
        searchTags = this.props.favoriteTripPlansSearchTags;
        placeHolderMessage = this.props.systemText.favorite.searchSection.searchBarTripsPlaceholder[this.props.userLanguage];
      }
      
      return(
          <div className={classes.Toolbar}>
            
            <div className={classes[searchBarSectionClass]}>
              <SearchBarContentType
                  selectedContentType = {this.props.selectedContentType}
                  updateSelectedContentType = {this.props.updateSelectedContentType}

                  showTrips = {true}

                  RTL = {this.props.RTL}
                  systemText = {this.props.systemText}
                  userLanguage = {this.props.userLanguage}
               />
                <SimpleSearchBar
                    searchTags = {searchTags}
                    placeholderMessage = {placeHolderMessage}
                    detectLanguage = {this.props.detectInputLanguage}
                    inputLanguage = {this.props.inputLanguage}
                    RTL = {this.props.RTL}
                    updateFilterTags = {this.props.favoriteSearchHandler}
                    
                    systemText = {this.props.systemText}
                    userLanguage = {this.props.userLanguage}
                    locationsTranslator = {this.props.locationsTranslator}
                />
                  </div>
                {this.renderFilterTags()}
        </div>
      )
    }

    renderFilterTags = () =>{
      if (this.props.selectedContentType==="Places"&&this.props.favoritePlacesFilterTags.length>0){
        return(<div className={classes.TagsList}>
              {this.props.favoritePlacesFilterTags.map((tag, index) => (
                    <Tag
                    key={index}
                    text={tag.tag[tag.language]}
                    removable={true}
                    style={"FavoritePreviewTag"}
                    remove={() => {this.props.favoriteRemoveTagHandler(index)}}
                    RTL = {this.props.RTL}
                  />
            ))} 
          
        </div>)
      }
      else if (this.props.selectedContentType==="Trips"&&this.props.favoriteTripPlansFilterTags.length>0){
       return(<div className={classes.TagsList}>
              {this.props.favoriteTripPlansFilterTags.map((tag, index) => (
                    <Tag
                    key={index}
                    text={tag.tag[tag.language]}
                    removable={true}
                    style={"FavoritePreviewTag"}
                    remove={() => {this.props.favoriteRemoveTagHandler(index)}}
                    RTL = {this.props.RTL}
                  />
            ))} 
          
          </div>)
      }
    }

    renderPlaces = () =>{
      if (this.props.noResultsFavoritePlaces){
        return( <div  className={classes.PlacesWall}>
          <div className={classes.NoContentMessage}>
            {this.props.systemText.favorite.noContentMessage.noPlacesResults[this.props.userLanguage]}
          </div>
        </div>)
      }
      else if (this.props.places.length===0){
        return( <div  className={classes.PlacesWall}>
          <div className={classes.NoContentMessage}>
            {this.props.systemText.favorite.noContentMessage.noPlaces[this.props.userLanguage]}
          </div>
        </div>)
      }
      else{
        return(       
          <div  className={classes.PlacesWall}>
              <PlacesWall
               loggedUser = {this.props.loggedUser}
               userLanguage={this.props.userLanguage}
               systemText = {this.props.systemText}
               RTL = {this.props.RTL}
               inputLanguage = {this.props.inputLanguage}
               user={this.props.user}

               categoriesTranslator = {this.props.categoriesTranslator}
               locationsTranslator = {this.props.locationsTranslator}

               placesList={this.props.places}

               viewType={"List"}
               selectedContentWall={this.props.selectedContentWall}
               deletePlaceHandler={this.props.deletePlaceHandler}
               viewHandler={this.props.viewPlaceHandler}
               editPlaceHandler={this.props.editPlaceHandler}
               addToPlan={this.props.addToPlanHandler}
               addPlaceToFavorite={this.props.addToFavoriteHandler}
               removePlaceFromFavorite={this.props.removeFromFavoriteHandler}
               updateFavoriteTags={this.props.updateFavoriteTagsHandler}
               shareExperience={this.props.shareExperience}
                />
          </div>
        )
      }
        
    }

    renderTripsPlans = () =>{
      if (this.props.noResultsFavoriteTrips){
        return( <div  className={classes.PlacesWall}>
          <div className={classes.NoContentMessage}>
            {this.props.systemText.favorite.noContentMessage.noTripsResults[this.props.userLanguage]}
          </div>
        </div>)
      }
      else if (this.props.plans.length===0){
        return( <div  className={classes.PlansWall}>
          <div className={classes.NoContentMessage}>
            {this.props.systemText.favorite.noContentMessage.noTrips[this.props.userLanguage]}
          </div>
        </div>)
      }
      else{
      return (
        <div  className={classes.PlansWall}>
        <PlansWall
            plansList={this.props.plans}
            user={this.props.user}
            viewHandler={this.props.viewPlanHandler}
            selectedContentWall={this.props.selectedContentWall}
            clonePlan={this.clonePlan}
            removePlanFromFavorite={this.removePlanFromFavorite}
            updateFavoriteTags={this.updatePlanFavoriteTags}

            locationsTranslator = {this.props.locationsTranslator}

            RTL = {this.props.RTL}
            loggedUser={this.props.loggedUser}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
        />
        </div>
       );
      }
    }

    renderWall = () =>{
        switch (this.props.selectedContentType) {
          
            case "Places": {
              return  <div className={classes.Wall} style={{minWidth:"100%"}} id="FavoriteWall" >{this.renderPlaces()}</div>;
              break;
            }
            case "Trips":{
              return  <div className={classes.Wall} style={{minWidth:"100%"}} id="FavoriteWall">{this.renderTripsPlans()}</div>;
              break;
            }
/*            case "Experiences":{
            return  <div className={classes.Wall} style={{minWidth:"100%"}} id="FavoriteWall">{this.renderExperiences()}</div>;
            break;
           } */
            default:
              return null;
          }
    }
    render(){
        let bodyClass = "Body"
        if (this.props.RTL){
          bodyClass = "BodyRTL"
        }
          return(
              <div className={classes[bodyClass]}>
                  {this.renderSearchBarSection()}
                  {this.renderWall()}
              </div>
          )
      }
  }