import React, { Component } from "react";

import classes from "./WelcomeWizard.module.css";

import Backdrop from "../../UI/Backdrop/Backdrop"

import { isMobile, isMobileOnly } from "react-device-detect"; 


let discoverScreenshot_1 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FDiscoverScreenshot_1.jpg?alt=media&token=bb7642a5-44e2-4d8b-8d19-a89600e4471d"
let discoverScreenshot_2 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FDiscoverScreenshot_2.jpg?alt=media&token=747379c8-605d-4d24-a9a9-754543169e90"
let discoverScreenshot_3 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FDiscoverScreenshot_3.jpg?alt=media&token=f938b829-828b-4116-82bf-8d22968814b4"
let mobileDiscoverScreenshot_1 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobileDiscoverScreenshot_1.jpg?alt=media&token=0752387f-d910-4e8a-9554-cf57a7b50398"
let mobileDiscoverScreenshot_2 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobileDiscoverScreenshot_2.jpg?alt=media&token=48e37640-1328-4ce9-8994-250789794195"
let mobileDiscoverScreenshot_3 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobileDiscoverScreenshot_3.jpg?alt=media&token=08d99955-5d5a-42fe-b585-dd3773aac240"
let planScreenshot_1 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FPlanScreenshot_1.jpg?alt=media&token=62759db1-b68b-4cab-b186-3977cf93b883"
let planScreenshot_2 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FPlanScreenshot_2.jpg?alt=media&token=3fbf2bc6-f42e-4242-adfb-27845333acae"
let mobilePlanScreenshot_1 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobilePlanScreenshot_1.jpg?alt=media&token=458198fd-6308-4f2e-a592-d67f4449f6ec"
let mobilePlanScreenshot_2 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobilePlanScreenshot_2.jpg?alt=media&token=3fff28d9-eda3-4a37-87a4-04d8f75f8f6e"
let experienceScreenshot_1 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FExperienceScreenshot_1.jpg?alt=media&token=5ca06798-9392-4f06-b38a-4cd68cfbd55b"
let experienceScreenshot_2 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FExperienceScreenshot_2.jpg?alt=media&token=43074a70-72a6-403b-bc48-00005223d3d5"
let mobileExperienceScreenshot_1 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobileExperienceScreenshot_1.jpg?alt=media&token=3f64e739-efcb-4364-a851-63b53d6aa788"
let mobileExperienceScreenshot_2 = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobileExperienceScreenshot_2.jpg?alt=media&token=7159bd50-8c23-4025-a92a-d661d9405f74"

let experienceIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FExperienceIcon.png?alt=media&token=b82ab90d-a10c-4d08-92a4-23fb14d15cf1";
let tripIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FTripIconGrey%20-%20Copy.png?alt=media&token=8ce60420-f364-4d3b-931d-5cf12179ce60";
let dreamIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FExploreIconSelected.png?alt=media&token=ace2753d-e8af-4474-a156-94e7800fd850";

let closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"

let dream = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FDiscoverWelcome-min.png?alt=media&token=b1475285-1cda-4408-af5a-6a7ebef7cc34"
let plan = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FPlanWelcome-min.png?alt=media&token=32cabd7b-e3d2-4d76-9b6a-704486d14201"
let experience = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FExperienceWelcome-min.png?alt=media&token=95e0e1e7-4457-4380-83ac-ab60fd142271"
let desktopLogo = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FSavingGif.png?alt=media&token=34dfb2f2-8c58-4ffc-8d8a-b92919286d13"
let mobileLogo = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FLogo.png?alt=media&token=9c05c21e-ea61-4000-ba7f-adf9e69edac0"

let mobileDream ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FDiscoverImage-Mobile.jpg?alt=media&token=e33f06df-75d7-4769-bb01-0aefa33ed0ed"
let mobilePlan = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FPlanImage-Mobile.jpg?alt=media&token=81a7adb8-5b1f-4ca2-95a2-1993980c0be3"
let mobileExperience = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FExperiencesImage-Mobile.jpg?alt=media&token=c3b354c2-0f9d-4ad3-80cd-012934018ed2"

class WelcomeWizard extends Component {
  state = {
    step:1,
    hoverDream: false,
    hoverPlan: false,
    hoverExperience: false,
    logoRotate:true
  };


  dreamHandler = () =>{
    if (isMobile){
        if (this.state.step!==4){
            this.setState({showDreamModal:true, showBackdrop: true})
        }
       
    }
    else{
        this.setState({showDreamModal:true, showBackdrop: true})
    }
   
  }

  planHandler = () =>{
    if (this.state.step>1){
        if (isMobile){
            if (this.state.step!==4){
                this.setState({showPlanModal:true, showBackdrop: true})
            }
        }
        else{
            this.setState({showPlanModal:true, showBackdrop: true})
        }
        
    }
  }

  experienceHandler = () =>{
    if (this.state.step>2){
        if (isMobile){
            if (this.state.step!==4){
                this.setState({showExperienceModal:true, showBackdrop: true})
            }
        }
        else{
            this.setState({showExperienceModal:true, showBackdrop: true})
        }
        
    }
  }

  nextStepsHandler = () =>{
    this.setState({showNextStep: true, showBackdrop:true})
  }

  renderBody = () =>{
    let dreamImageClass = "DreamImage"
    let planImageClass = "PlanImage"
    let expImageClass = "ExperienceImage"
    let highlightDream =""
    let highlightPlan=""
    let highlightExperience = ""
    if (this.state.step===1&&!this.state.hoverDream){
        highlightDream ="HighlightDream"
    }
    if (this.state.step===2&&!this.state.hoverPlan){
        highlightPlan="HighlightPlan"
    }
    if (this.state.step===3&&!this.state.hoverExperience){
        highlightExperience = "HighlightExperience"
    }
    let dreamImageOpacity = ""
    if (this.state.hoverDream){
        dreamImageOpacity = "DreamImageOpacity"
    }
    let planImageOpacity = ""
    if (this.state.hoverPlan&&this.state.step>1){
        planImageOpacity = "PlanImageOpacity"
    }
    let experienceImageOpacity = ""
    if (this.state.hoverExperience&&this.state.step>2){
        experienceImageOpacity = "ExperienceImageOpacity"
    }
    let dreamText = "DreamTextLTR"
    let planText = "PlanTextLTR"
    let experienceText = "ExperienceTextLTR"
    if (this.props.RTL){
        dreamImageClass = "DreamImageRTL"
        planImageClass = "PlanImageRTL"
        expImageClass = "ExperienceImageRTL"
        dreamText = "DreamTextRTL"
        planText = "PlanTextRTL"
        experienceText = "ExperienceTextRTL"
    }
    /* dreamImageClass = "DreamImageRTL"
    planImageClass = "PlanImageRTL"
    expImageClass = "ExperienceImageRTL" */
    let dreamGrab = "DreamImageGrab"
    let planGrab = ""
    if (this.state.step>1){
        planGrab = "PlanImageGrab"
    }
    let experienceGrab = ""
    if (this.state.step>2){
        experienceGrab = "ExperienceImageGrab"
    }
    let logoRotate=""

    if (this.state.logoRotate){
        logoRotate="LogoRotate"
       setTimeout(() => {
        this.setState({logoRotate:false,enableLogoAnimation:true})
       }, 2000);
    }
    let logoAnimation=""
    if (this.state.enableLogoAnimation){
        logoAnimation = "LogoAnimation"
    }
    if (isMobile){
        return(
            <div className={classes.Body}>
                <div className={classes.TopLogoSection}>
                    <img src = {mobileLogo} alt="" className={classes.TopLogo}/>
                </div>
                <div className={classes.Section}>
                    <div className={classes.RightRectangle} onClick={this.dreamHandler}>
                       
                    </div>
                    <img src={mobileDream} alt="" className={[classes[dreamImageClass],classes[dreamImageOpacity],classes[highlightDream]].join(" ")}
                         onClick={this.dreamHandler}/>
                    <div className={[classes.DreamText,classes[dreamText]].join(" ")}
                        onClick={this.dreamHandler}>
                        <img src={dreamIcon} alt="" className={classes.Icon}/>
                        {this.props.systemText.welcomeWizard.discover[this.props.userLanguage]}
                    </div>
                </div>
                <div className={classes.Section}>
                    
                    <img src={mobilePlan} alt="" className={[classes[planImageClass],classes[planImageOpacity],classes[highlightPlan]].join(" ")}
                    onClick={this.planHandler}/>
                    <div className={classes.LeftRectangle} onClick={this.planHandler}>

                    </div>
                    {this.state.step>1?(
                        <div className={[classes.PlanText,classes[planText]].join(" ")}
                        onClick={this.planHandler}>
                               <img src={tripIcon} alt="" className={classes.Icon}/>
                               {this.props.systemText.welcomeWizard.plan[this.props.userLanguage]}
                        </div>
                    ):null}
                    
                        
                </div>
                <div className={classes.Section}>
                <div className={classes.RightRectangle} onClick={this.experienceHandler}>
                   
                  </div>
                    <img src={mobileExperience} alt="" className={[classes[expImageClass],classes[experienceImageOpacity],classes[highlightExperience]].join(" ")}
                    onClick={this.experienceHandler}/>
                    {this.state.step>2?(
                        <div className={[classes.ExperienceText,classes[experienceText]].join(" ")}
                        onClick={this.experienceHandler}>
                             <img src={experienceIcon} alt="" className={classes.Icon}/>
                             {this.props.userLanguage==="English"?(
                                <div style={{display:"flex"}}>
                                    <div style={{color:"rgb(255,119,0"}}>
                                        {this.props.systemText.welcomeWizard.experience[this.props.userLanguage].slice(0, 3)}
                                    </div>
                                    <div style={{color:"rgb(34,121,190"}}>
                                    {this.props.systemText.welcomeWizard.experience[this.props.userLanguage].slice(4)}
                                    </div>
                                </div>
                             ):(
                                    <div style={{color:"rgb(34,121,190"}}>
                                        {this.props.systemText.welcomeWizard.experience[this.props.userLanguage]}
                                    </div>
                             )}
                            
                      </div> 
                    ):null}
                    
                </div>
               
                    

            </div>
        )
    }
    else{
        return(
            <div className={classes.Body}>
                 <img src={dream} alt="" className={[classes[dreamImageClass],classes[dreamImageOpacity],classes[dreamGrab],classes[highlightDream]].join(" ")}
                  onMouseEnter={()=>{this.setState({hoverDream:true,hoverPlan:false,hoverExperience:false})}}
                  onMouseLeave={()=>{this.setState({hoverDream:false})}}
                  onClick={this.dreamHandler}/>
                  <img src={plan} alt="" className={[classes[planImageClass],classes[planImageOpacity],classes[planGrab],classes[highlightPlan]].join(" ")}
                   onMouseEnter={()=>{this.setState({hoverPlan:true,hoverDream:false,hoverExperience:false})}}
                   onMouseLeave={()=>{this.setState({hoverPlan:false})}}
                   onClick={this.planHandler}/>
                   <img src={experience} alt="" className={[classes[expImageClass],classes[experienceImageOpacity],classes[experienceGrab],classes[highlightExperience]].join(" ")}
                   onMouseEnter={()=>{this.setState({hoverExperience:true,hoverDream:false,hoverPlan:false})}}
                   onMouseLeave={()=>{this.setState({hoverExperience:false})}}
                   onClick={this.experienceHandler}/>
                  <div className={[classes.DreamText,classes[dreamText],classes[dreamGrab]].join(" ")}
                   onMouseEnter={()=>{this.setState({hoverDream:true})}}
                   onClick={this.dreamHandler}>
                       <img src={dreamIcon} alt="" className={classes.Icon}/>
                        {this.props.systemText.welcomeWizard.discover[this.props.userLanguage]}
                        </div>
                    {this.state.step>1?(
                        <div className={[classes.PlanText,classes[planText],classes[planGrab]].join(" ")}
                        onMouseEnter={()=>{this.setState({hoverPlan:true})}}
                        onClick={this.planHandler}>
                            <img src={tripIcon} alt="" className={classes.Icon}/>
                             {this.props.systemText.welcomeWizard.plan[this.props.userLanguage]}</div>
                    ):null}
                    {this.state.step>2?(
                        <div className={[classes.ExperienceText,classes[experienceText]].join(" ")}
                        onClick={this.experienceHandler}>
                             <img src={experienceIcon} alt="" className={classes.Icon}/>
                             {this.props.userLanguage==="English"?(
                                <div style={{display:"flex"}}>
                                    <div style={{color:"rgb(255,119,0"}}>
                                        {this.props.systemText.welcomeWizard.experience[this.props.userLanguage].slice(0, 3)}
                                    </div>
                                    <div style={{color:"rgb(34,121,190"}}>
                                    {this.props.systemText.welcomeWizard.experience[this.props.userLanguage].slice(4)}
                                    </div>
                                </div>
                             ):(
                                    <div style={{color:"rgb(34,121,190"}}>
                                        {this.props.systemText.welcomeWizard.experience[this.props.userLanguage]}
                                    </div>
                             )}
                            
                      </div> 
                    ):null}
                  {this.state.step===4?(
                      <div className={[classes.LogoSection,classes.HighlightLogoSection].join(" ")} onClick={this.nextStepsHandler}>
                          <img src = {desktopLogo} alt="" className={[classes.LogoImage,classes[logoRotate],classes.HighlightLogo,classes[logoAnimation]].join(" ")}/>
                       </div>
                  ):(
                      <div className={classes.LogoSection}>
                          <img src = {desktopLogo} alt="" className={[classes.LogoImage,classes[logoRotate]].join(" ")}/>
                      </div>
                  )}
                  
            </div>
        )
    }
      
  }
 
  discardDreamModal = () =>{
      this.setState({
          showDreamModal:false,
          showBackdrop:false
      })
      if (this.state.step===1){
          this.setState({step:2})
      }
  }

  discardPlanModal = () =>{
    this.setState({
        showPlanModal:false,
        showBackdrop:false
    })
    if (this.state.step===2){
        this.setState({step:3})
    }
  }

  discardExperienceModal = () =>{
    this.setState({
        showExperienceModal:false,
        showBackdrop:false
    })
    if (this.state.step===3){
        this.setState({step:4,logoRotate:true})
        
    }
  }

  /* renderDreamModalOld = () =>{
    if (this.state.showDreamModal){
        let closeIconClass = "CloseIcon"
        if (this.props.RTL){
            closeIconClass = "CloseIconRTL"
        }
        let image = desktopDiscoverImage
        if (isMobile){
            image = mobileDiscoverImage
        }
        return(<div className={classes.InnerModal}>
            <img src={closeIcon} alt ="" className={classes[closeIconClass]}
            onClick={this.discardDreamModal}/>
             <div className={classes.ImagineScreen}>
                    <div className={classes.ImagineMessage}>
                        {this.props.systemText.welcomeWizard.imagineFirstMessage[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={placeholderImage}
                        src={image}
                        alt=""
                        className={classes.ImagineBackground}
                    />
                    <div className={classes.ImagineMessageBottom}>
                        {this.props.systemText.welcomeWizard.imagineSecondMessage[this.props.userLanguage]}
                    </div>
              </div>
        </div>)
    }
  } */

  renderDreamModal = () =>{
    if (this.state.showDreamModal){
        let discoverScreen_1 = discoverScreenshot_1
        let discoverScreen_2 = discoverScreenshot_2
        let discoverScreen_3 = discoverScreenshot_3
        if (isMobile){
            discoverScreen_1 = mobileDiscoverScreenshot_1
            discoverScreen_2 = mobileDiscoverScreenshot_2
            discoverScreen_3 = mobileDiscoverScreenshot_3
        }
        return(
            <div className={classes.InnerModal}>
                <div className={classes.ScreenshotScreen}>
                     <div className={classes.ScreenshotText}>
                        {this.props.systemText.welcomeWizard.discoverScreenshot_1[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={""}
                        src={discoverScreen_1}
                        alt=""
                        className={classes.ScreenshotImage}
                    />
                </div>
                <div className={classes.ScreenshotScreen}>
                     <div className={classes.ScreenshotText}>
                        {this.props.systemText.welcomeWizard.discoverScreenshot_2[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={""}
                        src={discoverScreen_2}
                        alt=""
                        className={classes.ScreenshotImage}
                    />
                </div>
                <div className={classes.ScreenshotScreen}>
                     <div className={classes.ScreenshotText}>
                        {this.props.systemText.welcomeWizard.discoverScreenshot_3[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={""}
                        src={discoverScreen_3}
                        alt=""
                        className={classes.ScreenshotImage}
                    />
                </div>
                <div className={classes.NextButton}  onClick={this.discardDreamModal}>
                {this.props.systemText.welcomeWizard.nextButton[this.props.userLanguage]}
                </div>
            </div>)
    }
     
  }

  renderPlanModal = () =>{
    if (this.state.showPlanModal){
        let planScreen_1 = planScreenshot_1
        let planScreen_2 = planScreenshot_2
        if (isMobile){
            planScreen_1 = mobilePlanScreenshot_1
            planScreen_2 = mobilePlanScreenshot_2
        }
        return(
            <div className={classes.InnerModal}>
                <div className={classes.ScreenshotScreen}>
                     <div className={classes.ScreenshotText}>
                        {this.props.systemText.welcomeWizard.planScreenshot_1[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={""}
                        src={planScreen_1}
                        alt=""
                        className={classes.ScreenshotImage}
                    />
                </div>
                <div className={classes.ScreenshotScreen}>
                     <div className={classes.ScreenshotText}>
                        {this.props.systemText.welcomeWizard.planScreenshot_2[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={""}
                        src={planScreen_2}
                        alt=""
                        className={classes.ScreenshotImage}
                    />
                </div>
                <div className={classes.NextButton}  onClick={this.discardPlanModal}>
                {this.props.systemText.welcomeWizard.nextButton[this.props.userLanguage]}
                </div>
            </div>)
    }
  }

  /* renderPlanModalOld = () =>{
    if (this.state.showPlanModal){
        let closeIconClass = "CloseIcon"
        if (this.props.RTL){
            closeIconClass = "CloseIconRTL"
        }
        let image = desktopPlanImage
        if (isMobile){
            image = mobilePlanImage
        }
        return(<div className={classes.InnerModal}>
              <img src={closeIcon} alt ="" className={classes[closeIconClass]}
            onClick={this.discardPlanModal}/>
             <div className={classes.ImagineScreen}>
                    <div className={classes.ImagineMessage}>
                    {this.props.systemText.welcomeWizard.planFirstMessage[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={placeholderImage}
                        src={image}
                        alt=""
                        className={classes.ImagineBackground}
                    />
                    <div className={classes.ImagineMessageBottom}>
                    {this.props.systemText.welcomeWizard.planSecondMessage[this.props.userLanguage]}
                    </div>
              </div>
        </div>)
    }
  } */

  renderExperienceModal = () =>{
    if (this.state.showExperienceModal){
        let experienceScreen_1 = experienceScreenshot_1
        let experienceScreen_2 = experienceScreenshot_2
        if (isMobile){
            experienceScreen_1 = mobileExperienceScreenshot_1
            experienceScreen_2 = mobileExperienceScreenshot_2
        }
        return(
            <div className={classes.InnerModal}>
                <div className={classes.ScreenshotScreen}>
                     <div className={classes.ScreenshotText}>
                        {this.props.systemText.welcomeWizard.experienceScreenshot_1[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={""}
                        src={experienceScreen_1}
                        alt=""
                        className={classes.ScreenshotImage}
                    />
                </div>
                <div className={classes.ScreenshotScreen}>
                     <div className={classes.ScreenshotText}>
                        {this.props.systemText.welcomeWizard.experienceScreenshot_2[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={""}
                        src={experienceScreen_2}
                        alt=""
                        className={classes.ScreenshotImage}
                    />
                </div>
                <div className={classes.NextButton}  onClick={this.discardExperienceModal}>
                {this.props.systemText.welcomeWizard.nextButton[this.props.userLanguage]}
                </div>
            </div>)
    }
  }

  /* renderExperienceModalOld = () =>{
    if (this.state.showExperienceModal){
        let closeIconClass = "CloseIcon"
        if (this.props.RTL){
            closeIconClass = "CloseIconRTL"
        }
        let image = desktopExperienceImage
        if (isMobile){
            image = mobileExperienceImage
        }
        return(<div className={classes.InnerModal}>
              <img src={closeIcon} alt ="" className={classes[closeIconClass]}
            onClick={this.discardExperienceModal}/>
             <div className={classes.ImagineScreen}>
                    <div className={classes.ImagineMessage}>
                    {this.props.systemText.welcomeWizard.experiencesFirstMessage[this.props.userLanguage]}
                    </div>
                    <img
                        placeholderSrc={placeholderImage}
                        src={image}
                        alt=""
                        className={classes.ImagineBackground}
                    />
                    <div className={classes.ImagineMessageBottom}>
                    {this.props.systemText.welcomeWizard.experiencesSecondMessage[this.props.userLanguage]}
                    </div>
              </div>
        </div>)
    }
  } */

  renderNextStepModal = () =>{
      if (this.state.showNextStep){
          return(
              <div className={classes.NextStepsModal}>
                  <div className={classes.Options}>
                    <div className={classes.NextStep1}>
                        <div className={classes.GetPersonalizedTrips} 
                        onClick={()=>{this.props.getPersonalizedTrips()
                            this.props.writeAcitivityLog({
                                activityKey:"WELCOME_WIZARD_GET_SUGGESTIONS",
                                userScreen:"WELCOME_WIZARD",
                                eventLevel:1
                            });
                            this.props.discrad()}}>
                            {this.props.systemText.welcomeWizard.getPersonalizedTrips[this.props.userLanguage]}
                        </div>
                    </div>
                    <div className={classes.NextStep2}>
                        <div className={classes.DiscoverNewPlaces}
                        onClick={()=>{this.props.getUserCountryPlaces();
                            this.props.writeAcitivityLog({
                                activityKey:"WELCOME_WIZARD_DISCOVER",
                                userScreen:"WELCOME_WIZARD",
                                eventLevel:1
                            });
                            this.props.discrad()}}>
                            {this.props.systemText.welcomeWizard.discoverPlaces[this.props.userLanguage]}
                        </div>
                    </div>
                  </div>
                  
                    <div>
                    <div className={classes.DirectMeToHomePage} 
                    onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"WELCOME_WIZARD_HOMEPAGE",
                            userScreen:"WELCOME_WIZARD",
                            eventLevel:1
                        });
                        this.props.discrad()}}>
                        {this.props.systemText.welcomeWizard.homePage[this.props.userLanguage]}
                    </div>
                </div>
              </div>
          )
      }
  }

  renderMobileLogo = () =>{
    if (this.state.step===4&&isMobile){
        let logoRotate=""

        if (this.state.logoRotate){
            logoRotate="LogoRotate"
        setTimeout(() => {
            this.setState({logoRotate:false,enableLogoAnimation:true})
        }, 2000);
        }
        let logoAnimation=""
        if (this.state.enableLogoAnimation){
            logoAnimation = "LogoAnimation"
        }
        return(
            <div className={[classes.LogoSection,classes.HighlightLogoSection].join(" ")} onClick={this.nextStepsHandler}>
                <img src = {desktopLogo} alt="" className={[classes.LogoImage,classes[logoRotate],classes.HighlightLogo,classes[logoAnimation]].join(" ")}/>
            </div>
        )
    }
  }

  renderSkipButton = () =>{
      let buttonClass = "SkipButton"
      if (this.props.RTL){
        buttonClass = "SkipButtonRTL"
      }
      return(
          <div className={classes[buttonClass]}
          onClick={()=>{
            this.props.writeAcitivityLog({
                activityKey:"WELCOME_WIZARD_SKIP",
                userScreen:"WELCOME_WIZARD",
                eventLevel:1
            });
            this.props.discrad()}}>
              {this.props.systemText.welcomeWizard.skipButton[this.props.userLanguage]}
          </div>
      )
  }

  render() {
      let RTL = "LTR"
      if (this.props.RTL){
          RTL = "RTL"
      }
      
    return (
       
        <div className={[classes.Modal,classes[RTL]].join(" ")}>
            <Backdrop show={this.state.showBackdrop}/>
            {this.renderSkipButton()}
            {this.renderDreamModal()}
            {this.renderPlanModal()}
            {this.renderExperienceModal()}
            {this.renderNextStepModal()}
            {this.renderMobileLogo()}
           {this.renderBody()}
      </div>
    );
  }
}
export default WelcomeWizard;
