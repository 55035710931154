import React, { Component } from "react";
import classes from "./Experience.module.css";

import Toolkit from "../../../Components/UI/Toolbar/Toolkit"
import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop"
import ImageFullscreenViewer from "../../../Components/Complex/Images/ImageFullscreenViewer"
import ConfirmationModal from "../../../Components/UI/Modals/ConfirmationModal"


import { Editor,EditorState , convertFromRaw} from 'draft-js';

 
import ReactPlayer from 'react-player'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isMobile, isMobileOnly } from "react-device-detect"; 

let editIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIcon.png?alt=media&token=c964d68a-44ba-45bf-afd7-b4b8eaeb6fba"
let deleteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2Fdelete.png?alt=media&token=1d88eb4a-6c67-43d8-81ed-0bb919c7d5fa"
let addToPlanIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAddItemToPlan.png?alt=media&token=b06a2f46-33c2-4ddf-87b0-aab1b369b044"
let removeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"

let likedIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLikeIcon.png?alt=media&token=2c9382fc-0df7-4e31-8cbe-a06caded454a"
let dislikedIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDislikeIcon.png?alt=media&token=7c1b777c-384c-4276-b47a-f3d4f7883aa7"
let placeIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"
let tripIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTripIconGrey.png?alt=media&token=eb61b497-5906-4b11-bd33-413e77cc4e45"
let expandIconUp = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FExpandIconUp.png?alt=media&token=3373c0dd-5a5b-4dd8-941f-4a5999134d48"

let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowLeft.png?alt=media&token=8020b73f-93be-478c-a1c3-c3cee641fbe8"
let arrowIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowRight.png?alt=media&token=e0fca8aa-a34f-47cf-80d3-79b36b305e88"

let scrollConstant=0.5;
if (isMobile){
    scrollConstant = 1
}

var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);


class Experience extends Component {
    state={
        mouseOnExperience: false,
        expandedView:false,
        images:[],
        experienceText:EditorState.createEmpty(),
        imagesRef:React.createRef(),
    }

    componentDidMount = () =>{
        this.initializeData()
       
        this.showExperiencePlanHandler()
    }

    showExperiencePlanHandler = () =>{
        if (this.props.experiencePlanID!==undefined){
           let showPlanLink = false;
           if (this.props.owner.ownerID===this.props.user.userID){
               showPlanLink = true;
           }
           if (this.props.experiencePlanSecurity==="public"||this.props.experienceAlbumSecurity==="public"){
            showPlanLink = true;
           }
           else if (this.props.experiencePlanSecurity==="friendsFollowers"||this.props.experienceAlbumSecurity==="friendsFollowers"){
               if (this.props.followingUsersIDs.includes(this.props.owner.ownerID)){
                    showPlanLink = true;
               }
           }
           else if(this.props.experiencePlanSecurity==="friends"||this.props.experienceAlbumSecurity==="friends"){
               if (this.props.myFriendsIDs.includes(this.props.owner.ownerID)){
                showPlanLink = true;
               }
           }
           this.setState({showPlanLink:showPlanLink})
        }
    }

    imageGalleryScrollToggle = () =>{
        var frame = this.state.imagesRef.current;
        if (frame===null) return
        let scrollLeft = Math.abs(frame.scrollLeft)
        if (scrollLeft>=(frame.scrollWidth-frame.offsetWidth-10)){
            this.setState({showForwardImageIcon:false})
        }
        else if(frame.scrollWidth>frame.offsetWidth){
            this.setState({showForwardImageIcon:true})
        }
        this.setState({imageScrollLeft:scrollLeft})
       
        if (scrollLeft>5){
            this.setState({showBackImageIcon:true})
        }
        else{
            this.setState({showBackImageIcon:false})
        }
    }

    imageGalleryLeftHandler = () =>{
        var frame = this.state.imagesRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let leftValue =0;
        let val =1
        if (this.props.RTL){
            val = -1
        }
        if ((scrollLeft-(window.innerWidth)*scrollConstant)>0){
            leftValue =(scrollLeft-(window.innerWidth)*scrollConstant)
        }
        frame.scrollTo({
            left:val*leftValue,
            behavior: "smooth",
            duration:500
          });

         /*  this.props.writeAcitivityLog({
            activityKey:"EXPERIENCE_IMAGES_ARROW",
            userScreen:this.props.selectedContentWall.toUpperCase()
        }) */
    }

    imageGalleryRightHandler = () =>{
        var frame = this.state.imagesRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let val =1
        if (this.props.RTL){
            val = -1
        }
        let leftValue =scrollLeft+(window.innerWidth)*scrollConstant;
        frame.scrollTo({
            left:val*leftValue,
            behavior: "smooth",
            duration:500
          });

        /*   this.props.writeAcitivityLog({
            activityKey:"EXPERIENCE_IMAGES_ARROW",
            userScreen:this.props.selectedContentWall.toUpperCase()
        }) */
    }

    componentDidUpdate = prevProps =>{
        if (this.props.experienceLastModified!==prevProps.experienceLastModified){
            this.initializeData()
        }
        if (this.props.experiencePlanID!==prevProps.experiencePlanID){
            this.showExperiencePlanHandler()
        }
        if (this.props.followingUsersIDs!==prevProps.followingUsersIDs){
            this.showExperiencePlanHandler()
        }
        if (this.props.myFriendsIDs!==prevProps.myFriendsIDs){
            this.showExperiencePlanHandler()
        }
    }

    initializeData = async () =>{
        let images = [];
        if (this.props.imagePreview!==undefined){
          if (isMobileOnly){
            for(let i=0;i<this.props.imagePreview.length; i++){
                let image={ source:this.props.imagePreview[i].thumbnailImage.url}
                images.push(image)
            }
          }
          else{
            for(let i=0;i<this.props.imagePreview.length; i++){
                let image={ source:this.props.imagePreview[i].smallImage.url}
                images.push(image)
            }
          }
        }
       
        await this.setState({images:images})
        if (images.length>0){
            if (this.props.board!=="SelectPlan"){
                var scrollComponent = this;
                var frame = this.state.imagesRef.current;
                frame.addEventListener("scroll", function(e) {
                  scrollComponent.imageGalleryScrollToggle();
                });      
                frame.scrollTo({
                    left:0,
                    behavior: "smooth"
                  });  
                if (frame.scrollWidth>frame.offsetWidth){
                    this.setState({showForwardImageIcon:true})
                }
                else {
                    this.setState({showForwardImageIcon:false})
                }
            }
        }
        try {
            JSON.parse(this.props.experienceText);
            this.setState({experienceText: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.experienceText))),
              jsonString: true
              })
        } catch (e) {
       
        }
        setTimeout(() => {
            this.imageGalleryScrollToggle()
        }, 200);
        
        
    }

    viewExperienceImagesHandler = (index) =>{
        let imagesFullScreenList=[];
        if (this.state.images.length>0){
            if (isMobileOnly){
                if (Object.keys(this.props.imagePreview[0]).includes("mediumImage")){
                    imagesFullScreenList=this.props.imagePreview.map(img=>{return img.mediumImage.url})
                }
                    
            }
            else{
                if (Object.keys(this.props.imagePreview[0]).includes("largeImage")){
                    imagesFullScreenList=this.props.imagePreview.map(img=>{return img.largeImage.url})
                }
            }
        }
        this.props.viewImagesFullscreen(imagesFullScreenList,index)
        /* this.props.writeAcitivityLog({
            activityKey:"EXPERIENCE_IMAGES_FULLSCREEN_ENTER",
            userScreen:this.props.selectedContentWall.toUpperCase()
        }) */
    }

    viewUserProfileHandler = (clickedUser) =>{
        if (this.props.owner.ownerID===this.props.user.userID){
            return
        }
        else if (clickedUser!==undefined){
            this.props.viewUserProfileHandler(clickedUser)
        }
        else{
            let user = {
                userID: this.props.owner.ownerID,
                displayName: this.props.owner.ownerName,
                photoUrl: this.props.owner.ownerPhoto,
                userEmail: this.props.owner.ownerEmail
            }
            this.props.viewUserProfileHandler(user)
        }
       
    }

    discard = () =>{
        this.setState({ optionsTools:false,
            showTransparentBackdrop: false}) 
      }

      removeFromPlan = () =>{
        this.setState({
            confirmMessage: this.props.systemText.extraModals.confirmationModal.removeExpFromPlan[this.props.userLanguage],
            primaryBtn: this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage],
            secondaryBtn: this.props.systemText.extraModals.confirmationModal.disconnect[this.props.userLanguage],
            confirmRemoveFromPlan: true,
            showBackdrop: true,
            optionsTools: false
          });
      }

      confirmRemove = () => {
        this.props.removeFromPlan(this.props.index);
        this.setState({
            confirmRemoveFromPlan: false,
          showBackdrop: false
        });
      };
      
      discardRemove = () => {
        this.setState({
            confirmRemoveFromPlan: false,
          showBackdrop: false
        });
      };

      deleteHandler = () =>{
        this.setState({
          confirmMessage: this.props.systemText.extraModals.confirmationModal.deleteMessage[this.props.userLanguage],
          primaryBtn: this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage],
          secondaryBtn: this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage],
          confirmMode: true,
          showBackdrop: true,
          optionsTools: false
        });
      }
  
  
      confirmDelete = () => {
        this.props.deleteHandler(this.props.index);
        this.setState({
          confirmMode: false,
          showBackdrop: false
        });
      };
      
      discardDelete = () => {
        this.setState({
          confirmMode: false,
          showBackdrop: false
        });
      };

      removeTaggingHandler = () =>{
        this.setState({
          confirmMessage: this.props.systemText.extraModals.confirmationModal.removeUserTag[this.props.userLanguage],
          primaryBtn: this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage],
          secondaryBtn: this.props.systemText.extraModals.confirmationModal.remove[this.props.userLanguage],
          confirmRemoveTagging: true,
          showBackdrop: true,
          optionsTools: false
        });
      }

      confirmRemoveTagging = () => {
        this.props.removeTaggingHandler(this.props.index);
        this.setState({
        confirmRemoveTagging: false,
          showBackdrop: false
        });
      };
      
      discardRemoveTagging = () => {
        this.setState({
        confirmRemoveTagging: false,
          showBackdrop: false
        });
      };
  
  

    renderTools = () =>{
        let toolsClass = "Tools"
        let toolsIconClass = "ToolsIcon"
        if (this.props.RTL){
            toolsClass = "ToolsRTL"
            toolsIconClass = "ToolsIconRTL"
        }
        if (this.props.owner.ownerID===this.props.user.userID || this.props.taggedUsers.map(user=>{return user.userID}).includes(this.props.user.userID)){
            if (this.props.selectedContentWall!=="Place"){
                return(
                    <div className={classes[toolsClass]}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditMode.png?alt=media&token=53018dfb-bdc0-4dcb-92b1-600bcfac76a7"} 
                            alt="" className={classes[toolsIconClass]} onClick={(e)=>{ e.stopPropagation();this.setState({optionsTools: true, showTransparentBackdrop: true})}}/>
                        <div className={classes.Toolkit}>
                         {this.renderOptionsTools()}
                        </div>
                    </div>
                )
            }
            
        }
    }

    renderRank = () =>{
        let iconClass = "RankIcon"
        if (this.props.RTL){
            iconClass = "RankIconRTL"
        }
        if (this.props.experienceRank===1){
            return(
                <img src={likedIcon} alt="" className={classes[iconClass]}/>
            )

        }
        if (this.props.experienceRank===-1){
            return(
                <img src={dislikedIcon} alt="" className={classes[iconClass]}/>
            )
        }
    }

    renderOptionsTools = () =>{
        if (this.state.optionsTools){
            let toolKitStyle="Style2"
            let tools = [];
            if (this.props.taggedUsers.map(user=>{return user.userID}).includes(this.props.user.userID)){
                tools.push({
                    action:  ()=>{this.removeTaggingHandler(this.props.index);
                        this.setState({showTransparentBackdrop:false, optionsTools:false})},
                    text: this.props.systemText.experience.tools.removeUserTag[this.props.userLanguage],
                    icon: removeIcon
                    }); 
            }
            else{
                tools.push({
                    action:  ()=>{this.props.editHandler(this.props.index);
                        this.setState({showTransparentBackdrop:false, optionsTools:false})},
                    text: this.props.systemText.experience.tools.editExperience[this.props.userLanguage],
                    icon: editIcon
                    }); 
                    if (this.props.experiencePlanID!==undefined){
                        tools.push({
                            action:  ()=>{this.removeFromPlan(this.props.index);
                                this.setState({showTransparentBackdrop:false, optionsTools:false})},
                            text:  this.props.systemText.experience.tools.disconnectFromPlan[this.props.userLanguage],
                            icon: addToPlanIcon
                            });
                    }
                    else{
                        tools.push({
                            action:  ()=>{this.props.addToPlan(this.props.index);
                                this.setState({showTransparentBackdrop:false, optionsTools:false})},
                            text:  this.props.systemText.experience.tools.connectToPlan[this.props.userLanguage],
                            icon: addToPlanIcon
                            });
                    }
                    
                    tools.push({
                    action: ()=>{this.deleteHandler();
                        this.setState({showTransparentBackdrop:false, optionsTools:false})},
                    text: this.props.systemText.experience.tools.deleteExperience[this.props.userLanguage],
                    icon: deleteIcon
                    });
            }
            
            return(
                <Toolkit
                style={toolKitStyle}
                tools={tools}
                RTL = {this.props.RTL}
                bodyRTL={this.props.RTL}
                />)
        }
      }

    renderExperiencePlace = () =>{
        if (this.props.selectedContentWall!=="Place"){
            let iconClass = "Icon"
            if (this.props.RTL){
                iconClass = "IconRTL"
            }
            let placeName="";
            //get the relevant language or at least any language name
            if (this.props.experiencePlaceName[this.props.userLanguage]!==undefined){
            placeName=this.props.experiencePlaceName[this.props.userLanguage]
            }
            else if(this.props.experiencePlaceName["English"]!==undefined){
            placeName=this.props.experiencePlaceName["English"]
            }
            else{
            let placesNames =  Object.keys(this.props.experiencePlaceName);
            placeName=this.props.experiencePlaceName[placesNames[0]]
            }
            return(
                <div className={classes.ExperiencePlace} >
                    <img src = {placeIcon} alt="" className={classes[iconClass]}/>
                    <div className={classes.PlaceName} onClick={()=>{this.props.viewExperiencePlace(this.props.experiencePlaceID,this.props.experienceLocation[0])}}>
                        {placeName}
                    </div>
                </div>
            )
        }
    }
    renderExperiencePlan = () =>{
        if (this.props.board!=="plan" && this.props.experiencePlanID!==undefined && this.props.selectedContentWall!=="Place"){
            if (this.props.owner.ownerID===this.props.user.userID||
                this.state.showPlanLink
                ){ 
                let iconClass = "Icon"
                if (this.props.RTL){
                    iconClass = "IconRTL"
                }
                return(
                    <div className={classes.ExperiencePlan} >
                    <img src = {tripIcon} alt="" className={classes[iconClass]}/>
                    <div className={classes.PlanName} onClick={()=>{this.props.viewExperiencePlan(this.props.experiencePlanID,this.props.owner.ownerID)}}>
                        {this.props.experiencePlanName}
                    </div>
                </div>
                )
            }
        }
    }

    renderExperienceOwner = () =>{
        let imageClass = "OwnerImage"
        if (this.props.RTL){
            imageClass = "OwnerImageRTL"
        }
        let ownerClickable = ""
        if (this.props.owner.ownerID!==this.props.user.userID){
            ownerClickable="Clickable"
        }
        return(
            <div className= {[classes.Owner,classes[ownerClickable]].join(" ")} onClick={e=>{this.viewUserProfileHandler()}}>
                <img className={classes[imageClass]} alt="" src={this.props.owner.ownerPhoto}/>
                <div className={classes.ExperienceInfo}>
                    <div className={classes.OwnerName}>
                        {this.props.owner.ownerName}
                    </div>
                    <div className={classes.ExperienceDate}>
                        {moment(this.props.experienceDate).format("DD/MM/YYYY")}
                    </div>
                </div>
            </div>
        )
    }

    renderTaggedUsers = () =>{
        if (this.props.taggedUsers.length>0){
            return(
                <div className={classes.TaggedUsers}>
                    {this.props.systemText.experience.withUsers[this.props.userLanguage]}
                   {this.props.taggedUsers.map((user,index)=>{
                       let displayName = user.displayName
                       if (index<this.props.taggedUsers.length-1){
                        displayName = displayName+", "
                       }
                       return(
                        <div key={index} className={classes.User} onClick={()=>{this.viewUserProfileHandler(user)}}>
                            {displayName}
                        </div>
                       )
                    }
                    )}
                </div>
            )
        }
    }

    blockStyleFn = (contentBlock) => {
        return classes['public-DraftStyleDefault-block'];
    }
    renderText = () =>{
        if (this.state.experienceText.getCurrentContent().hasText()){
            let experienceTextClass = "ExperienceText"
            if (this.props.board==="SelectPlan"){
                experienceTextClass = "SelectPlanText"
            }
            return(
                <div className={classes[experienceTextClass]}>
                    <Editor
                        blockStyleFn={this.blockStyleFn}
                        customStyleMap={this.styleMap}
                        className={classes.TextInput}
                        editorState={this.state.experienceText}
                        readOnly={true}
                        resize="none"
                        ref="editor"
                        />
                    
                </div>
            )
        }
    }

    renderExpandedText = () =>{
        if (this.state.expandedView){
            let expandIconClass = "ExpandIcon"
            let textWidthClass = "ExpandedExperienceTextWidth"
            if (this.props.RTL){
                expandIconClass = "ExpandIconRTL"
            }
            if (this.state.images.length===0){
                textWidthClass = "ExpandedExperienceTextWidthNoImage"
            }
            return(
                <div className={[classes.ExpandedExperienceText,classes[textWidthClass]].join(" ")}>
                      <img src={expandIconUp} alt="" className={classes[expandIconClass]}
                        onClick={()=>{this.setState({expandedView:false})
                        this.props.writeAcitivityLog({
                            activityKey:"EXPERIENCE_READMORE_CLOSE",
                            userScreen:this.props.selectedContentWall.toUpperCase()
                        })}}/> 
                    <Editor
                        blockStyleFn={this.blockStyleFn}
                        customStyleMap={this.styleMap}
                        className={classes.TextInput}
                        editorState={this.state.experienceText}
                        onChange={this.updateText}
                        readOnly={true}
                        resize="none"
                        ref="editor"
                        />
                    
                </div>
            )
        }
    }

    renderReadMoreButton = () =>{
        var count = (this.props.experienceText.match(/key/g) || []).length;
        let countLimit = 4;
        if (isMobile){
            countLimit=4
        }
        if (count>countLimit){
            return(
                <div className={classes.SeeMoreButton} 
                onClick = {()=>{this.setState({expandedView:true})
                this.props.writeAcitivityLog({
                    activityKey:"EXPERIENCE_READMORE_OPEN",
                    userScreen:this.props.selectedContentWall.toUpperCase()
                })
                }}>
                    {this.props.systemText.experience.readMoreButton[this.props.userLanguage]}
                </div>
            )
        }
    }

    renderContent = () =>{
        if (this.props.board==="SelectPlan"){
            return(<div className={classes.SelectPlanContent}>
                    {this.renderExperiencePlace()}
                    {this.renderText()}
                </div>)
        }
        else{
            let contentRTL=""
            if (this.props.RTL){
                contentRTL="ContentRTL"
            }
            if (this.state.expandedView){
               
                return(
                    <div className={[classes.Content,classes[contentRTL]].join(" ")}>
                        {this.renderTools()}
                        {this.renderRank()}
                        <div className={classes.InfoSection}>
                            {this.renderExperiencePlace()}
                            {this.renderExperiencePlan()}
                            {this.renderExperienceOwner()}
                            {this.renderTaggedUsers()}
                        </div>
                        {isMobile?(
                            <div>{this.renderExpandedText()}</div>
                        ):null}
                    </div>
                )
            }
            else{
                return(
                    <div className={[classes.Content,classes.CollapsedContent,classes[contentRTL]].join(" ")}>
                        {this.renderTools()}
                        {this.renderRank()}
                        <div className={classes.InfoSection}>
                            {this.renderExperiencePlace()}
                            {this.renderExperiencePlan()}
                            {this.renderExperienceOwner()}
                            {this.renderTaggedUsers()}
                        </div>
                        {this.renderText()}
                        {this.renderReadMoreButton()}
                    </div>
                )
            }
            
        }
        
    }

    renderImagesGallery = () =>{
        if (this.props.board==="SelectPlan"){
            if (this.state.images.length>0){
                return(
                    <div className={classes.SelectPlanImageGallery}>
                       <img src = {this.state.images[0].source} alt="" className={classes.SelectPlanImage}/>
                    </div>
                )
            }
           
        }
        else if(this.state.images.length>0){
            let leftArrowClass = "ArrowLeft"
            let rightArrowClass = "ArrowRight"
            let rotateArrow = ""
            if (this.props.RTL){
                rotateArrow = "RotateArrow"
                leftArrowClass = "ArrowLeftRTL"
                rightArrowClass = "ArrowRightRTL"
            }
            return(
                <div className={classes.ImagesWrapper}>
                {this.state.showBackImageIcon ?(
                        <img className={[classes.Arrow,classes[leftArrowClass],classes[rotateArrow]].join(" ")} alt = "" src={arrowIconLeft} onClick={this.imageGalleryLeftHandler}/>):null}
                <div className={classes.ImagesGallery}  ref={this.state.imagesRef}>
                    
                    {this.state.images.map((img,index)=>(
                         <img key={index} src = {img.source} alt="" className={classes.Image}
                            onClick={()=>{this.viewExperienceImagesHandler(index)}}
                        />
                    ))}
                </div>
                {this.state.showForwardImageIcon ?(
                <img src={arrowIconRight} className={[classes.Arrow,classes[rightArrowClass],classes[rotateArrow]].join(" ")} alt="" onClick={this.imageGalleryRightHandler}/>):null}
            </div>
            )
        }
        
    }



    renderConfirmModal = () => {
        if (this.state.confirmMode) {
            return (
                <ConfirmationModal
                  header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
                  primaryAction={this.discardDelete}
                  secondaryAction={this.confirmDelete}
                  message={this.state.confirmMessage}
                  primaryBtn={this.state.primaryBtn}
                  secondaryBtn={this.state.secondaryBtn}
                  primaryStyle = {"DiscardButton"}
                  secondaryStyle = {"DeleteButton"}
                  RTL = {this.props.RTL}
      
                />
              );
        }
        else if (this.state.confirmRemoveFromPlan){
            return (
                <ConfirmationModal
                  header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
                  primaryAction={this.discardRemove}
                  secondaryAction={this.confirmRemove}
                  message={this.state.confirmMessage}
                  primaryBtn={this.state.primaryBtn}
                  secondaryBtn={this.state.secondaryBtn}
                  primaryStyle = {"DiscardButton"}
                  secondaryStyle = {"DeleteButton"}
                  RTL = {this.props.RTL}
      
                />
              ); 
        }
        else if (this.state.confirmRemoveTagging){
            return (
                <ConfirmationModal
                  header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
                  primaryAction={this.discardRemoveTagging}
                  secondaryAction={this.confirmRemoveTagging}
                  message={this.state.confirmMessage}
                  primaryBtn={this.state.primaryBtn}
                  secondaryBtn={this.state.secondaryBtn}
                  primaryStyle = {"DiscardButton"}
                  secondaryStyle = {"DeleteButton"}
                  RTL = {this.props.RTL}
      
                />
              ); 
        }
      };

  render(){
    let RTLClass = ""
    if (this.props.RTL){
      RTLClass = "RTL"
    }
    let bodyClass = "Body"
    if (this.props.board==="SelectPlan"){
        bodyClass = "SelectPlanBody"
    }
    let collapsedBodyClass = "CollapsedBody"
    if (isMobile &&this.props.board==="SelectPlan"){
        collapsedBodyClass = "CollapsedPlanBody"
    }
    return(
        <div className={[classes[bodyClass],classes[RTLClass]].join(" ")}>
            <TransparentBackdrop show={this.state.showTransparentBackdrop}  clicked={this.discard} />
            {this.renderConfirmModal()}
          {/*   {this.renderFullscreenViewver()} */}
            <div className={classes[collapsedBodyClass]}>
                {this.renderContent()}
                {this.renderImagesGallery()}
            </div>
            {!isMobile?(
                <div> {this.renderExpandedText()}</div>
            ):null}
           
        </div>
    )
}

}
export default Experience;