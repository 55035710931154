import React, { Component } from "react";

import classes from "./PlanAccommodationSelector.module.css";

import PlaceSelection from "../PlaceSelection/PlaceSelection"
import OptionSelection from "../OptionSelection/OptionSelection"
import ModalTopSection from "../../UI/Misc/ModalTopSection"
import Tag from "../../UI/Tag/Tag"
import PlanPlaceItem from "./PlanPlaceItem"

class PlanAccommodationSelector extends Component {

    state={
        selectedAccommodation:null,
        accommodationCategories:[],
    }
    componentDidMount = () =>{
        if (this.props.selectedCountry!==undefined){
            this.setState({selectedCountry:this.props.selectedCountry})
        }
    }

    removeAccommodationCategory = index =>{
        let accommodationCategories = this.state.accommodationCategories;
        accommodationCategories.splice(index,1)
        this.setState({ accommodationCategories: accommodationCategories});
    }

    updateAccommodationCategory = category =>{
        let accommodationCategories = this.state.accommodationCategories;
        if (!accommodationCategories.map(cat=>cat.id).includes(category.id)){
            accommodationCategories.push(category)
        }
        this.setState({ accommodationCategories: accommodationCategories});
    }

    selectAccommodationPlace = place =>{
        this.setState({selectedAccommodation:place})
    }
    
    renderTopSection = () =>{
        let title = this.props.systemText.tripGenerator.prefernecesForm.accommodationHeader[this.props.userLanguage];
        let buttonName = this.props.systemText.plan.createPlan.accommodation.addButton[this.props.userLanguage];
        let saveReady = false;
        if (this.state.selectedCountry &&
            this.state.selectedAccommodation!==null){
            saveReady=true
          }
        return (
          <div className={classes.TopSection}>
            <ModalTopSection
             title={title}
             discard={this.props.discard}
             saveReady={saveReady}
             showButton={true}
             buttonText={buttonName}
             clicked={this.finishHandler}
             saveButton={true}
             RTL = {this.props.RTL}
             modalView = {true}
            />
           
          </div>
        );
    }

    finishHandler = () =>{
        this.props.addAccommodationHandler(this.state.selectedAccommodation)
        this.props.discard();
    }

    renderBody = () =>{
        let potentialPlaces = []
        if (this.props.slimPlacesByCountry!==undefined && this.props.selectedCountry!==undefined){
            if (this.props.slimPlacesByCountry[this.props.selectedCountry.treeID]!==undefined)
            potentialPlaces = this.props.slimPlacesByCountry[this.props.selectedCountry.treeID]
        }
        let potentialAccommodations = potentialPlaces.filter(place=>place.placeMainCategory[5]===1)
        let filteredAccommodations = []
        if (this.state.accommodationCategories.length>0){
            for (let i=0 ; i<potentialAccommodations.length ; i++){
                let placeCategory = potentialAccommodations[i].placeCategory
                let include = false
                for (let j=0 ; j<this.state.accommodationCategories.length ; j++){
                    if (placeCategory.includes(this.state.accommodationCategories[j].id)){
                        include = true
                    }
                }
                if (include){
                    filteredAccommodations.push(potentialAccommodations[i])
                }
            }
            
        }
        else{
            filteredAccommodations = potentialAccommodations
        }
        if (this.state.selectedAccommodation!==null){
            let imagePreview ={url:""}
            if (Array.isArray(this.state.selectedAccommodation.imagePreview)){
                imagePreview = this.state.selectedAccommodation.imagePreview[0]
            }
            else{
                imagePreview = this.state.selectedAccommodation.imagePreview
            }
            return (
                <div className={classes.Body}>
                     <div className={classes.SectionHeader}>
                     </div>
                     <div className={classes.ResetPlace} onClick={()=>{this.setState({selectedAccommodation:null})}}>
                            {this.props.systemText.experience.createExperience.resetPlace[this.props.userLanguage]}
                    </div>
                     <PlanPlaceItem
                        placeID={this.state.selectedAccommodation.placeID}
                        placeName={this.state.selectedAccommodation.placeName}
                        placeCategory={this.state.selectedAccommodation.placeCategory}
                        imagePreview={imagePreview}
                        placeLocation = {this.state.selectedAccommodation.placeLocation}
                        placeStatus = {this.state.selectedAccommodation.placeStatus}
                        addToPlan= {true}
    
                        categoriesTranslator = {this.props.categoriesTranslator}
                        locationsTranslator = {this.props.locationsTranslator}
                        RTL = {this.props.RTL}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                    />
                 
                </div>)
        }
        else{
            return (
                <div className={classes.Body}>
                     <div className={classes.SectionHeader}>
                         
                    </div>
                     <div className={classes.AccommodationSelection}>
                         {this.renderPlaceCountrySelection()}
                            {this.state.selectedCountry?(
                                 <div>
                                {this.renderAccommodationCategories()}
                               {this.state.accommodationCategories.length>0?(
                                <PlaceSelection
                                    
                                    placeholderMessage= {this.props.systemText.tripGenerator.prefernecesForm.accommodationPlaceholder[this.props.userLanguage]}
                                    places={filteredAccommodations}
                                    placeName={""}
                                    slimPlaces = {true}
                                    userLanguage={this.props.userLanguage}
                                    required = {false}
    
                                    detectInputLanguage = {this.props.detectInputLanguage}
                                    inputLanguage = {this.props.inputLanguage}
                                    RTL = {this.props.RTL}
                                    systemText={this.props.systemText}
                                    updatePlace={this.selectAccommodationPlace}
                                />):null}
                               
                                </div>):null}
                        </div>
                        {this.state.accommodationCategories.length>0?(
                     <div className={classes.PlaceNotExist} onClick={()=>{this.props.createNewAccommodation([0,0,0,0,0,1],this.state.accommodationCategories)}}>
                         {this.props.systemText.experience.createExperience.placeNotExistMessage[this.props.userLanguage]}
                     </div>):null}
                </div>)  
        }
       
    }

    renderAccommodationCategories = () =>{
        return(
            <div className={classes.AccommodationCategories}>
                <div className={classes.SelectedCategories}>
                    {this.state.accommodationCategories.map((tag, index) => (
                        <Tag
                        key={index}
                        text={tag[this.props.userLanguage]}
                        removable={true}
                        style={"CategoryFilter"}
                        remove={() => {this.removeAccommodationCategory(index)}}
                        RTL = {this.props.RTL}
                      />
                    ))}
                </div>                                                                                    
                <div className={classes.CategoriesSelectionList}>
                 <OptionSelection
                    multiple={true}
                    currentSelection={""}
                    optionsList={ Object.values(this.props.systemCategories[5])}
                    updateSelectedOption={this.updateAccommodationCategory}
                    placeholder={this.props.systemText.plan.createPlan.accommodation.categoryPlaceholder[this.props.userLanguage]}
                    valueRequired={this.state.accommodationCategories.length===0}
                    onlyFromList={true}
                    userLanguage={this.props.userLanguage}
                    errorMesage={""}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}
                    inputLanguage = {this.props.inputLanguage}
                    detectInputLanguage = {this.props.detectInputLanguage}
                /> 
                </div>
            </div>
        )  
    }

    renderPlaceCountrySelection = () =>{
        let countriesList = [];
        for (let i=0;i<this.props.countriesToExplore.length ; i++){
            let country = JSON.parse(JSON.stringify(this.props.locationsTree[this.props.countriesToExplore[i]]))
            delete country.regions;
            delete country.cities; 
            countriesList.push(country)
        }
        let currentSelection = ""
        if (this.props.selectedCountry){
            currentSelection = this.props.selectedCountry[this.props.userLanguage]
        }
        return(
            <div className={classes.CountrySelection}>
                <div className={classes.Message}>
                {this.props.systemText.plan.createPlan.accommodation.accommodationCountry[this.props.userLanguage]}
                </div>
              
                    <OptionSelection
                        currentSelection={currentSelection}
                        optionsList={countriesList}
                        updateSelectedOption={this.props.updateSelectedCountry}
                        placeholder={""}
                        valueRequired={currentSelection===""}
                        onlyFromList={true}
                        userLanguage={this.props.userLanguage}
                        RTL = {this.props.RTL}
                        inputLanguage = {this.props.inputLanguage}
                        detectInputLanguage = {this.props.detectInputLanguage}
                 /> 
            </div>
        )
    }

render() {
    return (
        <div className={classes.Modal}>
        {this.renderTopSection()}
        {this.renderBody()}
        </div>
    );
    }
}
export default PlanAccommodationSelector;