import React, { Component } from "react";
import classes from "./PlanMap.module.css";

import PlacesList from "../Places/PlacesList"
import GoogleMap from "../../../Components/Complex/Map/GoogleMap"


class PlanMap extends Component {
    state={
        calendarSelected:true,
        mapSelected:false,
        discoverSelected:true,
        boundariesFilteredDiscoverPlacesForMap:this.props.planMapPlaces,
        lockBounds:false,
        hoverIndex:-1,
        clickedMarkerIndex:-1,
        discoverTop:0,
        mobilePreviewScrollLeft:0,
    }

    componentWillMount = () =>{  
        this.updateFilteredPlaces();
       
    }

    componentDidMount = () =>{
        this.setState({ screenWidth: window.innerWidth, screenHeight: window.innerHeight });
        var scrollComponent = this;
        var frame = document.getElementById("MobilePlanMapPlacesPreview");
        frame.addEventListener("scroll", function(e) {
          scrollComponent.mobileMapPreviewScrollToggle();
        });        
    }

    
  mobileMapPreviewScrollToggle = async () =>{
    if (!this.state.scrollStopped){ 
      this.setState({lastScroll: new Date()})
      setTimeout(e => {
        if (this.state.lastScroll.getTime() < (new Date()).getTime() - 200) {
          
          this.mobileMapPreviewScollHandler()
        }        
      }, 200); 
    }
  }

    mobileMapPreviewScollHandler= async () =>{
        var frame = document.getElementById("MobilePlanMapPlacesPreview");
      
        let screenWidth = this.state.screenWidth*0.9;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let val =1
        if (scrollLeft===-1*frame.scrollLeft){
          val=-1
        }
        if (scrollLeft>this.state.mobilePreviewScrollLeft){
    
          //moving to the right
          if (Math.floor(scrollLeft/screenWidth) === Math.round(scrollLeft/screenWidth)){
            //to the right part
         
            frame.scrollTo({
              left:val*screenWidth*Math.floor(scrollLeft/screenWidth),
              behavior: "smooth",
              duration:500
            });
           // this.setState({hoverIndex:Math.floor(scrollLeft/screenWidth)})
           this.hoverHandler(Math.round(scrollLeft/screenWidth))
          }
          else{
            
            if ( Math.round(scrollLeft/screenWidth)<=19){
              frame.scrollTo({
                left:val*screenWidth* Math.round(scrollLeft/screenWidth),
                behavior: "smooth",
                duration:500
              });
              //this.setState({hoverIndex: Math.round(scrollLeft/screenWidth)})
              this.hoverHandler(Math.round(scrollLeft/screenWidth))
            }
          }
        }
        else{
          //moving to the left
    
          if (Math.floor(scrollLeft/screenWidth) === Math.round(scrollLeft/screenWidth)){
            //to the right part
              frame.scrollTo({
                left:val*screenWidth*Math.floor(scrollLeft/screenWidth),
                behavior: "smooth",
                duration:500
              });
              //this.setState({hoverIndex:Math.floor(scrollLeft/screenWidth)})
              this.hoverHandler(Math.round(scrollLeft/screenWidth))
          }
          else{
            frame.scrollTo({
              left:val*screenWidth* Math.round(scrollLeft/screenWidth),
              behavior: "smooth",
              duration:500
            });
           // this.setState({hoverIndex:Math.round(scrollLeft/screenWidth)})
            this.hoverHandler(Math.round(scrollLeft/screenWidth))
          }
        }
        await this.setState({scrollStopped:false,mobilePreviewScrollLeft:scrollLeft}) 
      }

    shouldUpdateFilteredPlaces = () =>{
        this.updateFilteredPlaces(this.state.mapBounds)
    }

    hoverHandler = index =>{
      if (this.state.boundariesFilteredDiscoverPlacesForMap[index]!==undefined){
        if (this.state.hoverIndex!==-1){
            if (this.state.hoverIndex===this.state.boundariesFilteredDiscoverPlacesForMap[index].placeID){
                this.setState({hoverIndex:this.state.boundariesFilteredDiscoverPlacesForMap[index].placeID})
            }
            else{
                this.setState({hoverIndex:-1})
            }
         }
          else{
            this.setState({hoverIndex:this.state.boundariesFilteredDiscoverPlacesForMap[index].placeID})
          }
      } 
    }


    updateFilteredPlaces = async (mapBounds,zoom,mapCenter)=>{
        let boundariesFilteredDiscoverPlacesForMap=[];
        for (let i=0 ; i< this.props.planDays[this.props.mobileDaySelectedIndex].dayItems.length ; i++ ){
            if (this.props.planDays[this.props.mobileDaySelectedIndex].dayItems[i].type==="place"){
                boundariesFilteredDiscoverPlacesForMap.push(this.props.planDays[this.props.mobileDaySelectedIndex].dayItems[i].item)
            }
        }
        let focusCoordinates;
       if (mapCenter!==undefined){
        focusCoordinates=mapCenter;
       }
       else if (boundariesFilteredDiscoverPlacesForMap.length>0){
        focusCoordinates = boundariesFilteredDiscoverPlacesForMap[0].placeLocation.coordinates
       } 
       else{
        
        focusCoordinates = this.state.focusCoordinates
        if (focusCoordinates===undefined){
          if (this.props.planMapPlaces.length>0) {
            focusCoordinates = this.props.planMapPlaces[0].placeLocation.coordinates
            boundariesFilteredDiscoverPlacesForMap=this.props.planMapPlaces}
          else {
            focusCoordinates = this.props.planPlacesList[0].placeLocation.coordinates
            boundariesFilteredDiscoverPlacesForMap=this.props.planPlacesList
          }
        }
       }
        await this.setState({mapBounds:mapBounds,lockBounds:true,defaultZoon:zoom,focusCoordinates:focusCoordinates})
        await this.setState({mapBounds:mapBounds,lockBounds:true,showMap:true,
            boundariesFilteredDiscoverPlacesForMap:boundariesFilteredDiscoverPlacesForMap,})  
    }



    

    markerClicked = (placeID,index) =>{
        this.setState({clickedMarkerIndex:placeID,hoverIndex:placeID})
        var frame = document.getElementById("MobilePlanMapPlacesPreview");
        let screenWidth = this.state.screenWidth*0.85
        let scrollLeft = Math.abs(frame.scrollLeft)
        let val =1
        if (scrollLeft===-1*frame.scrollLeft&&scrollLeft!==0){
          val=-1
        }
        frame.scrollTo({
          left:val*screenWidth*index,
          behavior: "smooth",
          duration:500
        });
      }
  
      mapClickedHandler = () =>{
        this.setState({clickedMarkerIndex:-1})
      }

    mapBoundsHandler = async (mapBounds,zoom,mapCenter) =>{
        if (mapBounds!==undefined&&mapBounds!==this.state.mapBounds){
            if (this.state.mapBounds!==undefined){
              this.updateFilteredPlaces(mapBounds,zoom,mapCenter)
            }
            else{
              this.setState({mapBounds:mapBounds})
            }
           
        }  
        // this.props.mapBoundsHandler(mapBounds)
    }

    viewPlaceHandler = index =>{
        let place = this.state.boundariesFilteredDiscoverPlacesForMap[index];
        let planDaysIndex=-1;
        for (let i =0 ; i<this.props.planDays[this.props.mobileDaySelectedIndex].dayItems.length ; i++){
            if (this.props.planDays[this.props.mobileDaySelectedIndex].dayItems[i].type==="place"){
                if (this.props.planDays[this.props.mobileDaySelectedIndex].dayItems[i].item.placeID===place.placeID){
                    planDaysIndex=i;
                }
            }
           
        }
        this.props.viewHandler(this.props.mobileDaySelectedIndex,planDaysIndex)
    }



    renderDays = () =>{
      let daysClass = "Days"
      if (this.props.RTL){
        daysClass = "DaysRTL"
      }
        return(
            <div className={classes[daysClass]}>
                {this.props.planDays.map((day,index)=>{
                    let dayClass = "Day"
                    if (index===this.props.mobileDaySelectedIndex){
                        dayClass = "DaySelected"
                    }
                    let name = day.name;
                    if (name==="Interesting"){
                        name=this.props.systemText.plan.viewPlan.planning.interestigMobile[this.props.userLanguage];
                    }
                    else{
                        name= this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage] +" "+ day.name.substr(day.name.indexOf(' ')+1);
                    }
                        
                    return(
                        <div key={index} className={classes[dayClass]} onClick={()=>{this.props.mobileDaySelectHandler(index)}}>
                        {name}
                    </div>  )
                   
                })}
            </div>
        )
    }

    renderMap = () =>{
        let height="95%"
      return(
          <div className={classes.MapSection} onMouseUp={()=>{if (this.refs.map!==undefined) {this.refs.map.handleCenterChanged()}}} onTouchEnd={()=>{if (this.refs.map!==undefined) {this.refs.map.handleCenterChanged()}}}>
           {this.state.showMap&&this.state.focusCoordinates!==undefined?(
          <GoogleMap
          ref="map"
          focusCoordinates={this.state.focusCoordinates}
          boundsPlaces={this.state.boundariesFilteredDiscoverPlacesForMap}
          markers={this.state.boundariesFilteredDiscoverPlacesForMap}
          board={"Plan"}
          showOrder={true}
          lockBounds={this.state.lockBounds}
          ideasOnMapCount ={this.props.planMapIdeasCount}
          isClickable={true}
          markerClicked={this.markerClicked}
          mapClicked={this.mapClickedHandler}
          mapBoundsHandler={this.mapBoundsHandler}
          height={height}
          defaultZoom={this.state.zoom}
          mapBounds={this.state.mapBounds}
          hoverIndex={this.state.hoverIndex}
          currentLocation={this.state.currentLocation}
          showCurrentLocation={this.state.showCurrentLocation}
          discoverPlacesNameFilterTag={[]}
      />):null} 
      <div className={classes.PlacesWall}  id="MobilePlanMapPlacesPreview"
        onScroll={()=>{return null}}
        onTouchEnd={this.mobileMapPreviewScollHandler}
        onTouchStart={()=>{return null}}>
            <PlacesList
              userLanguage={this.props.userLanguage}
              systemText = {this.props.systemText}
              RTL = {this.props.RTL}
              user={this.props.user}
  
              categoriesTranslator = {this.props.categoriesTranslator}
              locationsTranslator = {this.props.locationsTranslator}
  
                placesList={this.state.boundariesFilteredDiscoverPlacesForMap}
                favoritePlacesIDs={[]}
                viewType={"Map"}
                selectedContentWall={this.props.selectedContentWall}
                viewHandler={this.viewPlaceHandler}
              
  
                hoverHandler={(index,placeID)=>{this.setState({hoverIndex:placeID,clickedMarkerIndex:placeID,})}}
                clickedMarkerIndex={this.state.clickedMarkerIndex}
              /> 
        </div>
       </div>
        )
    }



    render(){
        return(<div className={classes.Body}>
            <div className={classes.TopSection}>
              {this.renderDays()}
            </div>
            {this.renderMap()}
        </div>)
    }
}

export default PlanMap;