
import React, { Component } from "react";
import classes from "./PlanTasksWall.module.css";

import PlanTask from "./PlanTask"
import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"

import { ReactSortable } from "react-sortablejs";
import { isMobile } from "react-device-detect";

import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

let plusIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPlusIconSquare.png?alt=media&token=65fcb735-831b-435c-8fbf-06538e6118ca"


class PlanTasksWall extends Component {
    state={
        planTasks:this.props.planTasks
    }

    componentDidUpdate = prevProps =>{
        if (JSON.stringify( prevProps.planTasks)!==JSON.stringify(this.props.planTasks)){
            this.setState({planTasks:this.props.planTasks})  
        }
    }

    updateTasksOrder = async (planTasks)=>{
        let changed = false;
        for (let i=0 ; i<planTasks.length ; i++){
            if (planTasks[i].taskID!==this.state.planTasks[i].taskID){
                changed=true;
            }
        }
        if (changed){
            await this.setState({planTasks: planTasks})
            this.props.updateTasksOrder(planTasks) 
        }
      
    }

        
performTaskSwipeAction =() =>{
    if (this.state.taskSwipeAction==="Delete"){
        this.props.deleteTask(this.state.taskSwipeIndex)
    }
  }

renderTasks = () =>{
    if (this.props.planTasks.length===0){
        return(
            <div className={classes.Tasks}>
                <div className={classes.NoTasksMessage}>
                {this.props.systemText.plan.viewPlan.planning.noTasksMessage[this.props.userLanguage]}
                </div>
            </div>
        )
    }
    else{
        if (isMobile){
            return(
            <SwipeableList>
            {this.props.planTasks.map((task,index) => {
             return(
                 <SwipeableListItem
                 style={{height:"100%"}}
                 blockSwipe={task.taskID===this.props.openModeTaskID}
                 swipeLeft={{
                 content: <div className={classes.DeleteSwipe}>{this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage]}</div>,
                 action: () =>this.setState({taskSwipeAction:"Delete",taskSwipeIndex:index})
                 }}
                 onSwipeEnd={this.performTaskSwipeAction}
             >
                       <div style={{width:'100%'}} key = {task.taskID}>
                        <PlanTask
                             openMode={task.taskID===this.props.openModeTaskID}
                             planEditors={this.props.planEditors}
                             newTaskID={this.props.newTaskID}
                             index={index}
                             task={task}
                             taskOpenHandler={this.props.taskOpenHandler}
                             deleteTask={this.props.deleteTask}
                             updateTask={this.props.updateTask}

                             writeAcitivityLog={this.props.writeAcitivityLog}
                             RTL = {this.props.RTL}
                             user={this.props.user}
                             userLanguage={this.props.userLanguage}
                             systemText={this.props.systemText}
                     />    
                     </div>
                   </SwipeableListItem>)
            })}

            </SwipeableList>)
        }
        else{
            return(
                <div className={classes.Tasks}>
                      <ReactSortable
                            list={this.props.planTasks}
                            animation={200}
                            delayOnTouchStart={true}
                            delay={500}
                            setList={(newState) => this.updateTasksOrder(newState)}
                        >
                            {this.props.planTasks.map((task,index) => (
                            <div style={{width:'100%'}} key = {task.taskID}>
                            <PlanTask
                                    openMode={task.taskID===this.props.openModeTaskID}
                                    planEditors={this.props.planEditors}
                                    newTaskID={this.props.newTaskID}
                                    index={index}
                                    task={task}
                                    taskOpenHandler={this.props.taskOpenHandler}
                                    deleteTask={this.props.deleteTask}
                                    updateTask={this.props.updateTask}
                                    writeAcitivityLog = {this.props.writeAcitivityLog}
                                    
                                    RTL = {this.props.RTL}
                                    user={this.props.user}
                                    userLanguage={this.props.userLanguage}
                                    systemText={this.props.systemText}
                            />    
                            </div>
                           
                            ))}
                        </ReactSortable>
                </div>
            )
        }
        
    }
} 

renderAddButton = () => {
    if (this.props.isEditor){
        return(
            <div className={classes.AddButton}>
                <img src={plusIcon} alt="" className={classes.PlusIcon} onClick={this.props.newTaskHandler}/>
            </div>
        )
    }
    
}

    render () {
        let zIndex = "120"
        if (this.props.confirmDeleteTask){
            zIndex = "15"
        }
        return(
            <div style={{width:"100%"}}>
                <TransparentBackdrop show={this.props.openModeTaskID!==-1} clicked={()=>{this.props.taskOpenHandler(-1)}} />
                <div  className={classes.TasksWall} style={{zIndex:zIndex}}>
                    {this.renderAddButton()}
                    {this.renderTasks()} 
                </div>
            </div>
        )
    }
}

export default PlanTasksWall;
