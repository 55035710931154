import React, { Component } from "react";
import classes from "./Discover.module.css";

import PlacesWall from "../Content/Places/PlacesWall"
import PlacesList from "../Content/Places/PlacesList"
import ExperiencesWall from "../Content/Experiences/ExperiencesWall"
import PlansWall from "../Content/TripPlans/PlansWall"

import DiscoverSearchBar from "../../Components/Complex/SearchBars/DiscoverSearchBar"
import SearchBarContentType from "../../Components/UI/ContentTypeSelector/SearchBarContentType"
import DiscoverFilter from "../../Components/Complex/DiscoverFilters/DiscoverFilter"
import GoogleMap from "../../Components/Complex/Map/GoogleMap"
import ScrollToTop from "../../Components/UI/Buttons/ScrollToTop"
import Tag from "../../Components/UI/Tag/Tag"
import Tab from "../../Components/UI/Tabs/Tab"

import { isMobile, isMobileOnly } from "react-device-detect";

let filterIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FFilterIconGrey.png?alt=media&token=d5e6c598-6ce3-4f95-b2dd-a611f8b0b442"
let filterIconApplied = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FFilterAppliedIcon.png?alt=media&token=81f47745-75ed-4919-9a84-4dfaefd24c29"
let mapIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLocationIcon.png?alt=media&token=7ab0f38c-21ab-4b86-abe3-97f41437e77f"
let mapIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLocationIconGrey.png?alt=media&token=603bd54f-2130-4f63-9b6b-17de74cc164d"
let currentLocationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCurrentLocation.png?alt=media&token=54ea82af-3b95-42bb-b624-d600768bc1fe"
let mobileFilterIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMobileFilterIconGrey.png?alt=media&token=cc51e549-c739-484b-b429-f75321ecfdb5"
let mobileFilterIconApplied = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMobileFilterIcon.png?alt=media&token=c037918a-a1ad-40eb-a870-5a091751bf85"
let arrowIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FArrowIcon.png?alt=media&token=b664f71d-dc5e-4e14-914c-505ed1794fa1"
let arrowIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FArrowIconFade.png?alt=media&token=4693bc24-2b8d-4a4f-97e9-d3b3019dadab"
let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FArrowIconLeft.png?alt=media&token=57bea1aa-0b1f-4553-80c0-aae0cf0cd2cb"
let arrowIconLeftFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FArrowIconLeftFade.png?alt=media&token=7d1e1a31-1ed3-4071-8014-1273e2472982"


export default class Discover extends Component {

    state={
        showFilters:false,
        showMap:false,
        top:0,
        hoverIndex:-1,
        mapViewPlacesList:[...this.props.places],
        mobilePreviewScrollLeft:0,
        pageNumber:0,
    }

  componentWillMount = () =>{
    if (isMobile){
      if (this.props.selectedContentType==="Places"){
        this.setState({showMap:true,mapEnabled:true})  
      }
      //in mobile map is openned on mount hence some parameters need to be initialized
      this.setState({hoverIndex:0})
      this.setState({boundariesFilteredDiscoverPlacesForMap:this.props.places.slice(0,20)})
      if (this.props.places[0]!==undefined){
        this.setState({ focusCoordinates:this.props.places[0].placeLocation.coordinates})
      }
    }
  }

  componentDidUpdate=(prevProps)=>{
    if (this.props.selectedContentType!==prevProps.selectedContentType){
     // this.wallScrollHandler()
     this.setState({showScrollUpButton:false})
      if (this.props.userExperiencesFeed!==undefined && this.props.selectedContentType==="Experiences"){
        var scrollComponent = this;
        var frame = document.getElementById("ExperiencesFeed");
        frame.addEventListener("scroll", function(e) {
          scrollComponent.experiencesFeedScrollToggle();
        });      
      }
      if (this.props.plans!==undefined && this.props.selectedContentType==="Trips" &&!this.props.showSuggestedTrips){
        var scrollComponent = this;
        var frame = document.getElementById("TripsWall");
        frame.addEventListener("scroll", function(e) {
          scrollComponent.tripsWallScrollToggle();
        });      
      }
    }
    if (this.props.userExperiencesFeed!==prevProps.userExperiencesFeed){
      if (this.props.plans!==undefined && this.props.selectedContentType==="Trips" &&!this.props.showSuggestedTrips){
        var scrollComponent = this;
        var frame = document.getElementById("TripsWall");
        frame.addEventListener("scroll", function(e) {
          scrollComponent.tripsWallScrollToggle();
        });      
      }
    }
    if (JSON.stringify(this.props.discoverCountryPlaces)!==JSON.stringify(prevProps.discoverCountryPlaces)&&isMobile&&
    this.props.selectedContentType==="Places"){
      this.setState({showMap:true}) 
      if (isMobile){
       if (this.props.places[0]!==undefined){
         this.setState({ focusCoordinates:this.props.places[0].placeLocation.coordinates})
       }
       //helps to zoom in with map boundaries on initialization
       setTimeout(e => {
         this.useLocationFilterPlaces(this.props.places,true)
       }, 500); 
      
       var scrollComponent = this;
       var frame = document.getElementById("MobileMapPlacesPreview");
       if (frame!==null){ frame.addEventListener("scroll", function(e) {
         scrollComponent.mobileMapPreviewScrollToggle();
       });    }
          
     }
    }
  }

  componentDidMount = () =>{
    this.setState({ screenWidth: window.innerWidth, screenHeight: window.innerHeight });
    if (this.props.selectedContentType==="Places"){
      if (isMobile){
        
        setTimeout(e => {
          if(this.props.discoverPlacesNameFilterTag.length===0){
            this.openFiltersHandler()
          }
          
        }, 2000); 
      }
      else{
        setTimeout(e => {
          if(this.props.discoverPlacesNameFilterTag.length===0){
            this.setState({showFilters:true})
          }
         
        }, 2000); 
      }     
    }
    if (isMobile&&this.props.selectedContentType==="Places"){
      this.setState({showMap:true})  
       if (isMobile){
        if (this.props.places[0]!==undefined){
          this.setState({ focusCoordinates:this.props.places[0].placeLocation.coordinates})
        }
        //helps to zoom in with map boundaries on initialization
        setTimeout(e => {
          this.useLocationFilterPlaces(this.props.places,true)
        }, 500); 
       
        var scrollComponent = this;
        var frame = document.getElementById("MobileMapPlacesPreview");
        if (frame!==null){ frame.addEventListener("scroll", function(e) {
          scrollComponent.mobileMapPreviewScrollToggle();
        });    }
           
      }
    }
    if (this.props.userExperiencesFeed!==undefined && this.props.selectedContentType==="Experiences"){
      var scrollComponent = this;
      var frame = document.getElementById("ExperiencesFeed");
      frame.addEventListener("scroll", function(e) {
        scrollComponent.experiencesFeedScrollToggle();
      });      
    }
    if (this.props.plans!==undefined && this.props.selectedContentType==="Trips" &&!this.props.showSuggestedTrips){
      var scrollComponent = this;
      var frame = document.getElementById("TripsWall");
      frame.addEventListener("scroll", function(e) {
        scrollComponent.tripsWallScrollToggle();
      });      
    }   
    
  }

  //--------VIEWPORT FOCUS FUNCTIONS-----------

  scrollFeedToTop = () =>{
    if (this.props.selectedContentType==="Experiences"){
      var experiencesFrame = document.getElementById("ExperiencesFeed");
      experiencesFrame.scrollTo({
          top: 0,
          behavior: "smooth"
      });
  
      this.props.writeAcitivityLog({
        activityKey:"EXPERIENCE_FEED_SCROLL_TO_TOP",
        userScreen:"DISCOVER"
      })
    }
    else if (this.props.selectedContentType==="Trips"){
      var experiencesFrame = document.getElementById("TripsWall");
      experiencesFrame.scrollTo({
          top: 0,
          behavior: "smooth"
      });
  
      this.props.writeAcitivityLog({
        activityKey:"DISCOVER_TRIPS_SCROLL_TO_TOP",
        userScreen:"DISCOVER"
      })
    }
    
}

tripsWallScrollToggle = () =>{
  var frame = document.getElementById("TripsWall");
  if (frame===null) return
  let scrollTop = frame.scrollTop
  if ((scrollTop>=(frame.scrollHeight-frame.offsetHeight-1000))){
      this.props.loadMoreTrips()
  }
  if (frame.scrollTop>window.innerHeight){
    this.setState({showScrollUpButton:true})
  }
  else{
      this.setState({showScrollUpButton:false})
  }
}

  experiencesFeedScrollToggle = () =>{
    var frame = document.getElementById("ExperiencesFeed");
    if (frame===null) return
    let scrollTop = frame.scrollTop
    if ((scrollTop>=(frame.scrollHeight-frame.offsetHeight-1000))&&
      this.props.loadedMoreExperiencesToFeed){
        this.props.loadMoreExperiencesFeedHandler()
    }
    if (frame.scrollTop>window.innerHeight){
      this.setState({showScrollUpButton:true})
    }
    else{
        this.setState({showScrollUpButton:false})
    }
  }

 /*  scrollMobilePlacesToTop() {
    var frame = document.getElementById("MobilePlacesScroll");
    frame.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    frame.scrollTop=0;
  } */
  scrollMobilePlacesToTop() {
    var frame = document.getElementById("DiscoverPlacesBody");
    frame.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    //scroll to first element in lsit once page changed
    this.mobileMarkerClicked(0)
    this.setState({clickedMarkerIndex:0,hoverIndex:0})
  }

  mobileMapPreviewScrollToggle = async () =>{
    if (!this.state.scrollStopped){ 
      this.setState({lastScroll: new Date()})
      setTimeout(e => {
        if (this.state.lastScroll.getTime() < (new Date()).getTime() - 200) {
          
          this.mobileMapPreviewScollHandler()
        }        
      }, 200); 
    }
  }

  mobileMapPreviewScrollToggleOld = async () =>{
    if (!this.state.scrollStopped){ 
      var frame = document.getElementById("MobileMapPlacesPreview");
      let scrollLeft = Math.abs(frame.scrollLeft)
      //console.log(Math.abs(scrollLeft-this.state.mobilePreviewScrollLeft))
      if (Math.abs(scrollLeft-this.state.mobilePreviewScrollLeft)<2){
        await this.setState({scrollStopped:true})
        setTimeout(e => {
          if (this.state.scrollStopped){
            this.mobileMapPreviewScollHandler()
          }
        
        }, 500); 
        
      }
      else{
        await this.setState({mobilePreviewScrollLeft:scrollLeft}) 
      }  
    }
  }

  mobileMapScrollStartHandler = async () =>{
    await this.setState({scrollStopped:true})
  }

  mobileMapPreviewScollHandler= async () =>{
    await this.setState({scrollStopped:true})
    var frame = document.getElementById("MobileMapPlacesPreview");
  
    let screenWidth = this.state.screenWidth*0.85;
    let scrollLeft = Math.abs(frame.scrollLeft)
    let val =1
    if (scrollLeft===-1*frame.scrollLeft){
      val=-1
    }
    if (scrollLeft>this.state.mobilePreviewScrollLeft){

      //moving to the right
      if (Math.floor(scrollLeft/screenWidth) === Math.round(scrollLeft/screenWidth)){
        //to the right part
     
        frame.scrollTo({
          left:val*screenWidth*Math.floor(scrollLeft/screenWidth),
          behavior: "smooth",
          duration:500
        });
        this.setState({hoverIndex:Math.floor(scrollLeft/screenWidth)})
      }
      else{
        
        if ( Math.round(scrollLeft/screenWidth)<=19){
          frame.scrollTo({
            left:val*screenWidth* Math.round(scrollLeft/screenWidth),
            behavior: "smooth",
            duration:500
          });
          this.setState({hoverIndex: Math.round(scrollLeft/screenWidth)})
        }
      }
    }
    else{
      //moving to the left

      if (Math.floor(scrollLeft/screenWidth) === Math.round(scrollLeft/screenWidth)){
        //to the right part
          frame.scrollTo({
            left:val*screenWidth*Math.floor(scrollLeft/screenWidth),
            behavior: "smooth",
            duration:500
          });
          this.setState({hoverIndex:Math.floor(scrollLeft/screenWidth)})
      }
      else{
        frame.scrollTo({
          left:val*screenWidth* Math.round(scrollLeft/screenWidth),
          behavior: "smooth",
          duration:500
        });
        this.setState({hoverIndex:Math.round(scrollLeft/screenWidth)})
      }
    }
    await this.setState({scrollStopped:false,mobilePreviewScrollLeft:scrollLeft})
  }

  placesNextPageHandler = (placesList,pageNumber) =>{
    if (isMobile){
      this.scrollMobilePlacesToTop()
    }
    else{
      this.refs.discoverPlacesWall.scrollToTop()
    }
    
    this.setState({boundariesFilteredDiscoverPlacesForMap:placesList,pageNumber:pageNumber})
  }

  placesPreviousPageHandler = (placesList,pageNumber) =>{
    if (isMobile){
      this.scrollMobilePlacesToTop()
    }
    else{
      this.refs.discoverPlacesWall.scrollToTop()
    }
    this.setState({boundariesFilteredDiscoverPlacesForMap:placesList,pageNumber:pageNumber})
  }

  //-----------MAP RELATED FUNCTIONS-----------

  enableMap = async (placeData) =>{
   await  this.setState({showMap:true,mapEnabled:true})
    if (placeData){
      setTimeout(e => {
        this.showNearByHandler(placeData.coordinates,placeData.placeID)
        }, 300); 
    }
  }

  mapBoundsHandler = async (mapBounds,zoom,mapCenter) =>{
      if (this.state.mapBounds!==undefined){
        if (this.state.useLocationFilterPlaces){
          this.setState({useLocationFilterPlaces:false,mapSearchButtonVisible:false})
        }
        else{
          this.setState({mapSearchButtonVisible:true})
        }
        
      }
      this.setState({mapBounds:mapBounds,mapCenter:mapCenter})
    }    

    useLocationFilterPlaces = async (filteredDiscoverPlaces,locationChanged) =>{
      if (this.state.mapEnabled){
        if (locationChanged){
          await this.setState({useLocationFilterPlaces:true})
        
      
          const bounds = new window.google.maps.LatLngBounds();
          filteredDiscoverPlaces.forEach((child) => {
                  bounds.extend(new window.google.maps.LatLng(child.placeLocation.coordinates.lat, child.placeLocation.coordinates.lng));
          }) 
          //this.updateFilteredPlaces(bounds.toJSON())
          if ( this.refs.map!==undefined){
            setTimeout(e => {
              this.refs.map.updateBounds(bounds.toJSON())
              }, 500);
           
          }
          else{
            setTimeout(e => {
              this.useLocationFilterPlaces(filteredDiscoverPlaces,locationChanged)
              }, 500);
          
          }
        }
      }
    }

  mobileMarkerClicked = index =>{
    var frame = document.getElementById("MobileMapPlacesPreview");
    let screenWidth = this.state.screenWidth*0.85
    let scrollLeft = Math.abs(frame.scrollLeft)
    let val =1
    if (scrollLeft===-1*frame.scrollLeft&&scrollLeft!==0){
      val=-1
    }
    frame.scrollTo({
      left:val*screenWidth*index,
      behavior: "smooth",
      duration:500
    });
  }

  markerClicked = async (placeID,index) =>{
    await this.setState({clickedMarkerIndex:index,hoverIndex:index})
    this.props.writeAcitivityLog({
      activityKey:"DISCOVER_PLACES_MAP_MARKER_CLICKED",
      userScreen:this.props.selectedContentWall.toUpperCase()
    })
    if (isMobile){
      this.mobileMarkerClicked(index)
    }
  }

  mapClickedHandler = () =>{
    this.setState({clickedMarkerIndex:-1,hoverIndex:-1})
    this.props.writeAcitivityLog({
      activityKey:"DISCOVER_PLACES_MAP_CLICKED",
      userScreen:this.props.selectedContentWall.toUpperCase()
    })
  }

  showNearByHandler = async (coordinates,placeID) =>{
    this.setState({
      currentLocation: 
        { lat: coordinates.lat, lng: coordinates.lng},
    });
     let mapBounds = {
      south:coordinates.lat-0.02,
      north:coordinates.lat+0.02,
      west:coordinates.lng-0.02,
      east:coordinates.lng+0.02,
      } 
      if (this.refs.map!==undefined){
        this.refs.map.updateBounds(mapBounds)
        setTimeout(e => {
          this.mapBoundariesPlacesHandler(mapBounds,this.state.mapCenter)
          if (isMobile){
            this.scrollMobilePlacesToTop()
          }
          else{
            this.refs.discoverPlacesWall.scrollToTop()
          }
          
        }, 0);  
        if (placeID!==undefined){
         
          this.highlightPlaceForNearby(placeID)
        }     
      }
      else{
        setTimeout(e => {
        
          this.showNearByHandler(coordinates)
          }, 500);   
      }
      
  }

  highlightPlaceForNearby = placeID =>{
    if (this.state.boundariesFilteredDiscoverPlacesForMap!==undefined){
      const index = this.state.boundariesFilteredDiscoverPlacesForMap.findIndex(place => {
        return place.placeID === placeID
      });
      this.markerClicked(placeID,index)
    }
    else{
      setTimeout(e => {
        
        this.showNearByHandler(placeID)
        }, 500);   
    }
  }

  searchAroundHandler = async () =>{
    await navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        currentLocation: 
          { lat: position.coords.latitude, lng: position.coords.longitude},
      });
       let mapBounds = {
        south:position.coords.latitude-0.02,
        north:position.coords.latitude+0.02,
        west:position.coords.longitude-0.02,
        east:position.coords.longitude+0.02,
        } 
        this.refs.map.updateBounds(mapBounds)
        setTimeout(e => {
          this.setState({showCurrentLocation:true })  
          this.mapBoundariesPlacesHandler(mapBounds,this.state.mapCenter)
          }, 0);      
          this.props.writeAcitivityLog({
            activityKey:"DISCOVER_PLACES_SHOWNEARBY_BUTTON",
            success:true,
            userScreen:this.props.selectedContentWall.toUpperCase()
          })
    }, error=>{
      if (error.code===1){
        this.props.writeAcitivityLog({
          activityKey:"DISCOVER_PLACES_SHOWNEARBY_BUTTON",
          success:false,
          userScreen:this.props.selectedContentWall.toUpperCase()
        })
        alert("Geolocation permission has been blocked as the user has dismissed the permission prompt several times. This can be reset in Page Info which can be accessed by clicking the info icon left to the URL")
      }
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    });
   
  }


  updateFilteredList = (placesList,limit) =>{
    this.setState({boundariesFilteredDiscoverPlacesForMap:placesList.slice(0,limit)})
    if (placesList[0]!==undefined){
     this.setState({ focusCoordinates:placesList[0].placeLocation.coordinates})
     }
   }

   mapBoundariesPlacesHandler = async (mapBounds)=>{
     await this.props.mapBoundariesPlacesHandler(mapBounds).then(result => {
      
    }); 
   }


   //------ADDITIONAL FUNCTIONS-------------

   viewPlacePreviewHandler = (index)=>{
    let placesList=this.state.boundariesFilteredDiscoverPlacesForMap
    let place = placesList[index]
    this.props.viewPlaceHandler(place);
  }

  
  clonePlan = index =>{
    let plan = this.props.plans[index];
    this.props.clonePlanHandler(plan);
  }

  addPlanToFavorite = (index) =>{
    let plan = this.props.plans[index];
    this.props.addToFavoriteHandler(plan,"Trip");
  }


  updatePlanFavoriteTags = (index) =>{  
    let plan = this.props.plans[index];
    this.props.updateFavoriteTagsHandler(plan,"Trip");
  }

  
  removePlanFromFavorite= (index) =>{  
    let plan = this.props.plans[index];
    this.props.removeFromFavoriteHandler(plan,"Trip");
  }

  cloneSuggestedPlan = index =>{
    let plan = this.props.generatedSuggestedTrips[index]
    this.props.clonePlanHandler(plan,true);
  }

  addSuggestedPlanToFavorite = (index) =>{
    let plan = this.props.generatedSuggestedTrips[index];
    this.props.addToFavoriteHandler(plan,"Trip");
  }


  updateSuggestedPlanFavoriteTags = (index) =>{  
    let plan = this.props.generatedSuggestedTrips[index];
    this.props.updateFavoriteTagsHandler(plan,"Trip");
  }

  
  removeSuggestedPlanFromFavorite= (index) =>{  
    let plan = this.props.generatedSuggestedTrips[index];
    this.props.removeFromFavoriteHandler(plan,"Trip");
  }

  reportDislikedSuggestedTrip = (index,userInput) =>{
    let plan = this.props.generatedSuggestedTrips[index];
    this.props.reportDislikedSuggestedTrip(plan,userInput);
  }

  //----------RENDERING---------------
    renderDiscoverToolbar = (regularToolbar) =>{
      let searchBarSectionClass = "SearchBarSection"
        if (this.props.RTL){
            searchBarSectionClass = "SearchBarSectionRTL"
        }
      //for places
      let specialPlaceHolderMessage = ""
      let placeHolderMessage = ""
      let searchTags = [];
      if (this.props.selectedContentType==="Places"){
        if (this.props.discoverPlacesLocationFilterTags.length>0){
          searchTags = this.props.discoverPlacesLocationsSearchTags;
          searchTags = searchTags.concat(this.props.discoverPlacesAvailableCategories.filter(tag=>!this.props.discoverPlacesCategoriesFilterTags.map(tag=>{return tag.tag.id}).includes(tag)).map(tag=>{return this.props.categoriesTranslator[tag]})).filter(tag=>tag!==undefined)
          searchTags = searchTags.concat(this.props.discoverPlacesNameSearchTags)
          searchTags = searchTags.concat(this.props.discoverPlacesAdditionalTagsSearchTags)
          
        }
        else{
          searchTags = this.props.placesLocationSearchTags
        }
        specialPlaceHolderMessage = this.props.systemText.discover.searchSection.searchBarPlaceholder[this.props.userLanguage];
        if (this.props.discoverPlacesLocationFilterTags.length>0){
          placeHolderMessage = this.props.discoverPlacesLocationFilterTags[this.props.discoverPlacesLocationFilterTags.length-1].tag[this.props.userLanguage]
        }
        if (this.props.discoverPlacesLocationFilterTags.length===0){
          placeHolderMessage = this.props.systemText.welcomePage.searchSection.searchBarPlaceholder[this.props.userLanguage]
          specialPlaceHolderMessage = this.props.systemText.welcomePage.searchSection.searchBarPlaceholder[this.props.userLanguage]
        }
      }      
      else if (this.props.selectedContentType==="Trips"){
        searchTags = []
        if (this.props.discoverTripsLocationSearchTags!==undefined){
          searchTags = this.props.discoverTripsLocationSearchTags
        }
        if (this.props.discoverTripsAdditionalSearchTags!==undefined){
          searchTags = searchTags.concat(this.props.discoverTripsAdditionalSearchTags)
        }
        placeHolderMessage = this.props.systemText.discover.searchSection.searchBarTripsPlaceholder[this.props.userLanguage]
        /* if (this.props.discoverTripsLocationFilters.length>0){
          placeHolderMessage = this.props.locationsTranslator[this.props.discoverTripsLocationFilters[0]][this.props.userLanguage]
          
        } */
      }
      let DiscoverToolbar = "DiscoverToolbar"
      if (regularToolbar){
        DiscoverToolbar = ""
      }
      return(
          <div className={classes[DiscoverToolbar]}>
            {this.renderFiltersButton()}
              <div className={classes[searchBarSectionClass]}>
               <SearchBarContentType
                  selectedContentType = {this.props.selectedContentType}
                  updateSelectedContentType = {this.props.updateSelectedContentType}

                  showExperiences = {this.props.loggedUser}
                  showTrips = {true}
                  constantlyOpenned = {this.props.selectedContentType==="Experiences"}
                  RTL = {this.props.RTL}
                  systemText = {this.props.systemText}
                  userLanguage = {this.props.userLanguage}
               />
               
              <DiscoverSearchBar
                  searchTags = {searchTags}
                  placeholderMessage = {placeHolderMessage}
                  specialPlaceHolderMessage = {specialPlaceHolderMessage}
                  detectLanguage = {this.props.detectInputLanguage}
                  inputLanguage = {this.props.inputLanguage}
                  RTL = {this.props.RTL}
                  updateFilterTags = {this.props.searchDiscoverLocationHandler}
                  noSearchResultsHandler={this.props.noSearchResultsHandler}
                  locationsTranslator = {this.props.locationsTranslator}

              />
          </div>
          {this.renderMapButton()}
          
      </div>
      )
  }

  renderFiltersButton = () =>{
    if (this.props.selectedContentType!=="Experiences" && !(
      this.props.discoverPlacesLocationFilterTags.length ===0 && this.props.selectedContentType==="Places"
    )){
      let iconClass = "ToolbarIcon"
      let mobileFilterButtonClass = "MobileFiltersButton"
          if (this.props.RTL){
              iconClass = "ToolbarIconRTL"
              mobileFilterButtonClass = "MobileFiltersButtonRTL"
          }
      let filterApplied = false
      if (this.props.selectedContentType==="Places"){
        if (this.props.discoverPlacesLocationFilterTags.length>1||
          this.props.discoverPlacesCategoriesFilterTags.length>0||
          this.props.discoverPlacesSelectedMainCategory[0]!==1||
          this.props.discoverPlacesNameFilterTag.length>0||
          this.props.discoverPlacesAdditionalTagFilterTag.length>0||
          Object.keys(this.props.discoverPlacesCharacteristicsFilter).length>0||
          JSON.stringify(this.props.discoverPlacesDaysFilter)!==JSON.stringify([0,0,0,0,0,0,0])){
            filterApplied = true
          }
      }
     if (this.props.selectedContentType==="Trips"){
       if(this.props.discoverTripsCountriesFilters.length > 0||
        this.props.discoverTripsLocationFilters.length > 0 ||
        this.props.discoverTripsPlanAdditionalTagsFilters.length > 0 ||
        this.props.discoverTripsCategoriesFilters.length > 0 ||
        this.props.discoverTripsStyleFocus.length > 0 ||
        JSON.stringify(this.props.discoverPlansDurationFilter)!==JSON.stringify([1,20])||
        JSON.stringify(this.props.discoverPlansParticipants)!==JSON.stringify([1,0,0])
        ){
          filterApplied = true
        }
     }
      if (isMobile){
        return(
          <div className={classes[mobileFilterButtonClass]}>
            {filterApplied?          
            (
              <img src={mobileFilterIconApplied} alt=""
              className={classes[iconClass]} onClick={this.openFiltersHandler}/>
              )
              :
              (
                <img src={mobileFilterIcon} alt=""
                className={classes[iconClass]} onClick={this.openFiltersHandler}/>
              )}
          </div>
        )
      }
      else{
        return(
          <div className={classes.ToolbarButton} onClick={()=>{
            this.props.writeAcitivityLog({
              activityKey:"DISCOVER_PLACES_FILTERS_OPEN",
              userScreen:this.props.selectedContentWall.toUpperCase()
            })
            this.setState({showFilters:true})}}>
            {filterApplied?(
              <img src={mobileFilterIconApplied} alt="" className={classes[iconClass]}/>
            ):(
              <img src={mobileFilterIcon} alt="" className={classes[iconClass]}/>
            )}
            {this.props.systemText.discover.searchSection.openFilter[this.props.userLanguage]}
        </div>
        )
      }
    }
    
  }

  renderMapButton = () =>{
    if (this.props.selectedContentType==="Places" && this.props.discoverPlacesLocationFilterTags.length > 0){
      let iconClass = "ToolbarIcon"
          if (this.props.RTL){
              iconClass = "ToolbarIconRTL"
          }
      if (!isMobile){
        if (this.state.showMap){
          return(
            <div className={classes.ToolbarButton} onClick = {()=>{
              this.props.writeAcitivityLog({
                activityKey:"DISCOVER_PLACES_MAP_HIDE",
                userScreen:this.props.selectedContentWall.toUpperCase()
              })
              this.setState({showMap:false});
              this.props.discoverPlacesHideMapHandler()}}>
                <img src={mapIcon} alt="" className={classes[iconClass]}/>
              {this.props.systemText.discover.searchSection.hideMap[this.props.userLanguage]}
            </div>
          )
        }
        else{
          return(
            <div className={classes.ToolbarButton} onClick = {()=>{
              this.props.writeAcitivityLog({
                activityKey:"DISCOVER_PLACES_MAP_SHOW",
                userScreen:this.props.selectedContentWall.toUpperCase()
              })
              this.setState({showMap:true,mapEnabled:true})}}>
                <img src={mapIconGrey} alt="" className={classes[iconClass]}/>
              {this.props.systemText.discover.searchSection.showMap[this.props.userLanguage]}
            </div>
          )
        }
      }
    }
  }

  renderMapSearchButton = () =>{
    if (this.state.mapSearchButtonVisible){
      return(
        <div className={classes.MapSearchButton} 
          onClick={()=>{this.mapBoundariesPlacesHandler(this.state.mapBounds,this.state.mapCenter);
          this.setState({mapSearchButtonVisible:false}); this.props.removePlaceNameFilterTag(); this.props.removePlaceAdditionalTagFilterTag()
          this.props.writeAcitivityLog({
            activityKey:"DISCOVER_PLACES_MAPBOUNDARIES_BUTTON",
            userScreen:this.props.selectedContentWall.toUpperCase()
          })
          }}>
           {this.props.systemText.discover.map.showInMapArea[this.props.userLanguage]}
        </div>)
    }
  }

  renderPlacesMap = ()=>{
    let placesList=this.props.places;
    if (isMobile){
      placesList = this.state.boundariesFilteredDiscoverPlacesForMap
    }
    let placesMapClass = "PlacesMap"
    if (this.props.RTL){
      placesMapClass = "PlacesMapRTL"
    }
    //for desktop show map only if user selected to show map, for mobile map is always shown
    if (this.state.mapEnabled||isMobile){
      let height="83vh"
      if (isMobile){height="100%"}

      //show category icons
      let showSpecialIcon=true;
      if (this.props.discoverPlacesSelectedMainCategory[0]===0||
        this.props.discoverPlacesCategoriesFilterTags.length>0){
        showSpecialIcon=false;
      };

      //to avoid mounting map each time user open and closes map, map is always rendered once openned, but is hidden
      let showMapDesktopClass =""
      if (!isMobile&&!this.state.showMap){
        showMapDesktopClass="HideDesktopMap"
      }
      return(
        <div className={[classes[placesMapClass],classes[showMapDesktopClass]].join(" ")} 
        onMouseUp={()=>{if (this.refs.map!==undefined) {this.refs.map.handleCenterChanged()}}} onTouchEnd={()=>{if (this.refs.map!==undefined){this.refs.map.handleCenterChanged()}}}
        >
           <img src={currentLocationIcon}  onClick={this.searchAroundHandler} alt="" className={classes.CurrentLocationIcon} />
              {this.renderMapSearchButton()}
              {isMobile?(
                <div>
                  {this.renderDiscoverToolbar()}
                  </div>
              ):null}
              {(isMobile&&this.state.focusCoordinates!==undefined)||!isMobile?(
                  <GoogleMap
                  ref="map"
                  mapCenter={this.state.mapCenter}
                  focusCoordinates={this.state.focusCoordinates}
                  boundsPlaces={this.state.boundariesFilteredDiscoverPlacesForMap}
                  markers={this.state.boundariesFilteredDiscoverPlacesForMap.map(place => {return {placeID:place.placeID,placeLocation:{coordinates:place.placeLocation.coordinates},mainCategory:place.placeMainCategory}})}
                  board={"Discover"}
                  isClickable={true}
                  markerClicked={this.markerClicked}
                  mapClicked={this.mapClickedHandler}
                  mapBoundsHandler={this.mapBoundsHandler}
                  height={height}
                  defaultZoom={this.state.zoom}
                  mapBounds={this.state.mapBounds}
                  hoverIndex={this.state.hoverIndex}
                  showSpecialIcon={showSpecialIcon}
                  currentLocation={this.state.currentLocation}
                  showCurrentLocation={this.state.showCurrentLocation}
                  discoverPlacesNameFilterTag={this.props.discoverPlacesNameFilterTag}
              /> 
              ):null}
              {isMobile?(
              <div className={classes.PlacesPreview} id="MobileMapPlacesPreview"
              onScroll={()=>{return null}}
              onTouchEnd={this.mobileMapPreviewScollHandler}
              onTouchStart={this.mobileMapScrollStartHandler}
              >
            <PlacesList
             loggedUser = {this.props.loggedUser}
             userLanguage={this.props.userLanguage}
             systemText = {this.props.systemText}
             RTL = {this.props.RTL}
             user={this.props.user}
             loginHandler = {this.props.loginHandler}

             categoriesTranslator = {this.props.categoriesTranslator}
             locationsTranslator = {this.props.locationsTranslator}

              placesList={placesList}
              favoritePlacesIDs={this.props.favoritePlacesIDs}
              viewType={"Map"}
              discoverSearchLanguage={this.props.discoverSearchLanguage}
              selectedContentWall={this.props.selectedContentWall}
              viewHandler={this.viewPlacePreviewHandler}

              hoverHandler={(index)=>{this.setState({hoverIndex:index,clickedMarkerIndex:index,})}}
              clickedMarkerIndex={this.state.clickedMarkerIndex}
              discoverPlacesNameFilterTag={this.props.discoverPlacesNameFilterTag}
              discoverPlacesAdditionalTagFilterTag = {this.props.discoverPlacesAdditionalTagFilterTag}
              /> 

          </div>):null}
        </div>
      )
    }
   
  }

    renderPlaces = () =>{
      let placesWallClass = "PlacesWall"
      if (this.state.showMap&&!isMobile){
        placesWallClass = "PlacesWallNarrow"
      }
      let openCloseButtonClassRTL = ""
      let prevArrow = arrowIconLeft
      let nextArrow = arrowIcon
      let prevArrowFade = arrowIconLeftFade
      let nextArrowFade = arrowIconFade
      if (this.props.RTL){
        openCloseButtonClassRTL = "OpenCloseButtonRTL"
      }
      if (this.props.places.length===0){
        return( 
          <div  className={classes.EmptyWall}>
            <div className={classes.NoContentMessage}>
              {this.props.systemText.discover.noContentMessage.noPlacesMessage[this.props.userLanguage]}
            </div>
            <div className={classes.CallToAction} onClick={()=>{if (this.props.loggedUser){this.props.createNew("Place")}
          else{this.props.loginHandler()}}}>
            {this.props.systemText.discover.noContentMessage.createPlaceButton[this.props.userLanguage]}
            </div>
          </div>)
      }
      else{
        return(       
          <div  className={classes[placesWallClass]}>
            {isMobile&&this.props.discoverPlacesNameFilterTag.length===0?(
              <div className={[classes.OpenCloseButton,classes[openCloseButtonClassRTL]].join(" ")}>
              {this.state.pageNumber>0?(
                  <img src={prevArrow} className={classes.MobilePrevArrow} alt=""
                  onClick={()=>{this.refs.discoverPlacesWall.previousPageHandler()}}
                  />):(
                    <img src={prevArrowFade} className={classes.MobilePrevArrow} alt=""/>
                  )}
                  <div className={classes.MobilePreviewPlacesNum}>
                    {20*this.state.pageNumber+"-"+Math.min(20*(this.state.pageNumber+1)-1,this.props.places.length) +" ("+this.props.places.length+")"}</div>
                  {((this.state.pageNumber+1)*20)<this.props.places.length?(
                    <img src={nextArrow} className={classes.MobileNextArrow} alt=""
                    onClick={()=>{this.refs.discoverPlacesWall.nextPageHandler()}}
                   />
                  ):(
                    <img src={nextArrowFade} className={classes.MobileNextArrow} alt=""/>
                  )}
                  
              </div>
            ):null}
            <PlacesWall
              ref="discoverPlacesWall"
              loggedUser = {this.props.loggedUser}
              userLanguage={this.props.userLanguage}
              systemText = {this.props.systemText}
              RTL = {this.props.RTL}
              inputLanguage = {this.props.inputLanguage}
              user={this.props.user}
              loginHandler = {this.props.loginHandler}
  
              adminUser={this.props.adminUser}
  
              categoriesTranslator = {this.props.categoriesTranslator}
              locationsTranslator = {this.props.locationsTranslator}
  
              discoverPlacesCharacteristicsFilter={JSON.parse(JSON.stringify(this.props.discoverPlacesCharacteristicsFilter))}
              placesList={this.props.places}
              favoritePlacesIDs={this.props.favoritePlacesIDs}
              discoverPlacesNameFilterTag = {this.props.discoverPlacesNameFilterTag}
              discoverPlacesAdditionalTagFilterTag = {this.props.discoverPlacesAdditionalTagFilterTag}
              discoverPlacesDaysFilter = {[...this.props.discoverPlacesDaysFilter]}
  
              viewType={"List"}
              selectedContentWall={this.props.selectedContentWall}
              deletePlaceHandler={this.props.deletePlaceHandler}
              viewHandler={this.props.viewPlaceHandler}
              editPlaceHandler={this.props.editPlaceHandler}
              addToPlan={this.props.addToPlanHandler}
              addPlaceToFavorite={this.props.addToFavoriteHandler}
              removePlaceFromFavorite={this.props.removeFromFavoriteHandler}
              updateFavoriteTags={this.props.updateFavoriteTagsHandler}
              shareExperience={this.props.createNew}
  
              hoverHandler={(index)=>{if(this.state.showMap){this.setState({hoverIndex:index,clickedMarkerIndex:index,})}}}
              clickedMarkerIndex={this.state.clickedMarkerIndex}
  
              nextPageHandler={this.placesNextPageHandler}
              previousPageHandler={this.placesPreviousPageHandler}
              updateFilteredList={this.updateFilteredList}
  
              />
        </div>)
      }
    }

    renderExperiences = () =>{
      return(
        <div className={classes.ExperiencesWall}  id="ExperiencesFeed">
             <ExperiencesWall
              experiencesList={this.props.userExperiencesFeed}
              user={this.props.user}
              viewStyle={"wall"}
              deleteHandler={(index)=>{this.props.deleteExperienceHandler(this.props.userExperiencesFeed[index])}}
              editHandler={(index)=>{this.props.editExperienceHandler(this.props.userExperiencesFeed[index])}}
              removeTaggingHandler={(index)=>{this.props.removeTaggingHandler(this.props.userExperiencesFeed[index])}}
              viewExperiencePlace={this.props.viewExperiencePlace}
              viewExperiencePlan={this.props.viewExperiencePlan}
              selectedContentWall={this.props.selectedContentWall}
              addToPlan={this.addExperienceToPlanHandler}
              removeFromPlan={(index)=>{this.props.removeExperienceFromPlanHandler(this.props.userExperiencesFeed[index])}}
              
              viewImagesFullscreen = {this.props.viewImagesFullscreen}
              viewUserProfileHandler = {this.props.viewUserProfileHandler}
              followingUsersIDs = {this.props.followingUsersIDs}
              myFriendsIDs = {this.props.myFriends.map(friend=>{return friend.userID})}
              RTL = {this.props.RTL}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
              writeAcitivityLog = {this.props.writeAcitivityLog}
          />
        </div>
      )
    }


    renderPublicPlans = () =>{
      if (this.props.plans.length===0){
        return( <div  className={classes.PlansWall} id="TripsWall">
          <div className={classes.NoContentMessage}>
            {this.props.systemText.discover.noContentMessage.noTripsResults[this.props.userLanguage]}
          </div>
        </div>)
      }
      else{
        return (
          <div  className={classes.PlansWall} id="TripsWall">
          <PlansWall
              plansList={this.props.plans}
              user={this.props.user}
              viewHandler={this.props.viewPlanHandler}
              clonePlan={this.clonePlan}
              selectedContentWall={this.props.selectedContentWall}

              addPlanToFavorite={this.addPlanToFavorite}
              removePlanFromFavorite={this.removePlanFromFavorite}
              updateFavoriteTags={this.updatePlanFavoriteTags}

              favoriteTripsIDs = {this.props.favoriteTripsIDs}

              locationsTranslator = {this.props.locationsTranslator}

              loginHandler = {this.props.loginHandler}

              loggedUser = {this.props.loggedUser}
              RTL = {this.props.RTL}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
          />
          </div>
        );
      }
    }

    renderGeneratedSuggestedTrips = () =>{
      if (this.props.generatedSuggestedTrips.length===0){
        return(
            <div  className={classes.EmptyWall}>
            <div className={classes.CallToAction} onClick={this.props.createTemplateTrips}>
            {this.props.systemText.welcomePage.generateTrips[this.props.userLanguage]}
            </div>
          </div>
        )
      }
      else{
        return(
          <div  className={classes.PlansWall}>
            <PlansWall
            plansList={this.props.generatedSuggestedTrips}
            user={this.props.user}
            viewHandler={this.props.viewSuggestedPlanHandler}
            selectedContentWall={"SuggestedTrips"}
            favoriteTripsIDs={[]}

            addPlanToFavorite={this.addSuggestedPlanToFavorite}
            removePlanFromFavorite={this.removeSuggestedPlanFromFavorite}
            updateFavoriteTags={this.updateSuggestedPlanFavoriteTags}
            clonePlan={this.cloneSuggestedPlan}
            reportDislikedSuggestedTrip = {this.reportDislikedSuggestedTrip}
  
            locationsTranslator = {this.props.locationsTranslator}
  
            loggedUser = {this.props.loggedUser}
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
            />
          </div>
        )
      }
      
    }

    renderTipsDiscoverOrSuggestedTrips = () =>{
      let suggestedTripsSelection = "SelectedMobileWide"
      let searchTripsSelection = "RegularMobileWide"
      if (!this.props.showSuggestedTrips){
         suggestedTripsSelection = "RegularMobileWide"
         searchTripsSelection = "SelectedMobileWide"
      }
      return(<div className={classes.TripsSelectionToolbar}>
               <div className={classes.TripsViewSelection}>
             {/*   <div className={classes[searchTripsSelection]}  onClick={()=>{this.props.showSuggestedTripsHandler(false)}}>
                  {this.props.systemText.discover.tripsToolbar.exploreTripsTab[this.props.userLanguage]}
                </div>
                <div className={classes.MobileSeperator}/>
                <div className={classes[suggestedTripsSelection]} onClick={()=>{this.props.showSuggestedTripsHandler(true)}}>
                  {this.props.systemText.discover.tripsToolbar.suggestedTripsTab[this.props.userLanguage]}
                </div> */}
                  <Tab
                    tabType={searchTripsSelection}
                    text= {this.props.systemText.discover.tripsToolbar.exploreTripsTab[this.props.userLanguage]}
                    clicked={()=>{this.props.showSuggestedTripsHandler(false)}}
                  />
                      <div className={classes.MobileSeperator}/>
                  <Tab
                    tabType={suggestedTripsSelection}
                    text= {this.props.systemText.discover.tripsToolbar.suggestedTripsTab[this.props.userLanguage]}
                    clicked={()=>{this.props.showSuggestedTripsHandler(true)}}
                  />  
               
               
              </div>
              {this.props.showSuggestedTrips&&this.props.generatedSuggestedTrips.length>0?(
                   <div className={classes.SuggestedTripsToolbar}>
                      <div className={classes.SuggestedTripAction} onClick={this.props.runAgainTripsGenerator}>
                      {this.props.systemText.discover.tripsToolbar.generateNewTrips[this.props.userLanguage]}
                      </div>
                      <div className={classes.SuggestedTripAction} onClick={this.props.createTemplateTrips}>
                      {this.props.systemText.discover.tripsToolbar.updatePreferneces[this.props.userLanguage]}
                      </div>
                   </div>
              ):null}
           
          </div>
      )
    }

    renderSelectedContent = () => {
        switch (this.props.selectedContentType) {
          
          case "Places": {
            if (isMobile){
              if (this.props.discoverCountryPlaces.length>0){
                return ( <div className={classes.PlacesDiscoverWall} style={{minWidth:"100%"}} >
                 
                {this.renderPlacesMap()}
                {this.renderPlaces()}
             
              </div>)
              }
              else{
                return ( <div className={classes.PlacesDiscoverWall} style={{minWidth:"100%"}} >
                  <div className={classes.RegularToolBar}>
                     {this.renderDiscoverToolbar(true)}
                  </div>
                 
                 </div>)
              }
            }
            else{
              if (this.props.discoverPlacesLocationFilterTags.length===0){
                return ( <div className={classes.PlacesDiscoverWall} style={{minWidth:"100%"}} >
                 {this.renderFilterTags()}
                 <div className={classes.NoContentMessage}>
                  {this.props.systemText.discover.noContentMessage.noPlacesNoLocation[this.props.userLanguage]}
                </div>
             
              </div>)
              }
              else{
                return ( <div className={classes.Wall} style={{minWidth:"100%"}}>
               
                {this.renderFilterTags()}
                {this.renderPlaces()}
                {this.renderPlacesMap()}
                </div>)
              }
             
           
            }
          }
         case "Trips":{
          if (this.props.showSuggestedTrips){
            return  <div className={classes.TripsDiscoverWall} id="Wall" style={{minWidth:"100%"}}>
                {this.renderTipsDiscoverOrSuggestedTrips()}
                {this.renderGeneratedSuggestedTrips()}
              </div>;
          }
          else{
            return  <div className={classes.TripsDiscoverWall} id="Wall" style={{minWidth:"100%"}}>
                <div className={classes.RegularToolBar}>
                     {this.renderDiscoverToolbar()}
                  </div>
                {this.renderTipsDiscoverOrSuggestedTrips()}
                {this.renderPublicPlans()}
              </div>;
          }
           
            break;
         }
         case "Experiences":{
          return  <div className={classes.PlacesDiscoverWall}  style={{minWidth:"100%"}}>
            <div className={classes.RegularToolBar}>
                     {this.renderDiscoverToolbar()}
                  </div>
            {this.renderExperiences()}</div>;
          break;
         } 
        
          default:
            return null;
        }
      };

      closeFiltersHandler = () =>{
        let activityKey = ""
        if (this.props.selectedContentType==="Places"){
          activityKey="DISCOVER_PLACES_FILTERS_CLOSE"
        }

        else if (this.props.selectedContentType==="Trips"){

        }

        this.props.writeAcitivityLog({
          activityKey:activityKey,
          userScreen:this.props.selectedContentWall.toUpperCase(),
          eventLevel:1
        })
        this.setState({showFilters:false});
        this.props.hideNavbar(false)
      }

      openFiltersHandler = () =>{

        let activityKey = ""
        if (this.props.selectedContentType==="Places"){
          activityKey="DISCOVER_PLACES_FILTERS_OPEN"
        }

        else if (this.props.selectedContentType==="Trips"){
          activityKey="DISCOVER_TRIPS_FILTERS_OPEN"
        }

        this.props.writeAcitivityLog({
          activityKey:activityKey,
          userScreen:this.props.selectedContentWall.toUpperCase(),
          eventLevel:1
        })
        this.setState({showFilters:true});
        this.props.hideNavbar(true)
      }

      renderFilterTags = () =>{
        return(
            <div className={classes.FilterTags}>
               {this.props.discoverPlacesLocationFilterTags.length>1?(
              <div className={classes.Filters}>
              {this.props.discoverPlacesLocationFilterTags.map((tag, index) => {
                if (index!==0){
                  return(
                    <Tag
                      key={index}
                      text={tag.tag[this.props.userLanguage]}
                      removable={true}
                      style={tag.type}
                      remove={() => {this.props.removePlaceLocationFilterTag(index)}}
                      RTL = {this.props.RTL}
                    />
                  )
                }
                
               })}
              </div>):null}
             {this.props.discoverPlacesCategoriesFilterTags.length>0?(
              <div className={classes.Filters}>
              {this.props.discoverPlacesCategoriesFilterTags.map((tag, index) => (
                <Tag
                  key={index}
                  text={tag.tag[this.props.userLanguage]}
                  removable={true}
                  style={tag.type}
                  remove={() => {this.props.removePlaceCategoryFilterTag(index)}}
                  RTL = {this.props.RTL}
                />
              ))}
              {/* {this.renderCharTags()} */}
              </div>):null}
              {this.props.discoverPlacesNameFilterTag.length>0?(
              <div className={classes.Filters}>
              {this.props.discoverPlacesNameFilterTag.map((tag, index) => (
                <Tag
                  key={index}
                  text={tag.tag[tag.language]}
                  removable={true}
                  style={tag.type}
                  remove={() => {this.props.removePlaceNameFilterTag(index)}}
                  RTL = {this.props.RTL}
                />
              ))}
              </div>):null}
              {this.props.discoverPlacesAdditionalTagFilterTag.length>0?(
              <div className={classes.Filters}>
              {this.props.discoverPlacesAdditionalTagFilterTag.map((tag, index) => (
                <Tag
                  key={index}
                  text={tag.tag[tag.language]}
                  removable={true}
                  style={tag.type}
                  remove={() => {this.props.removePlaceAdditionalTagFilterTag(index)}}
                  RTL = {this.props.RTL}
                />
              ))}
              </div>):null}
            </div>
        )
    }
      
      renderFilters = () =>{
        let modalClass = ""
        if (this.state.showFilters){
          modalClass = "ModalLTR"
          if (this.props.RTL&&!isMobile){
            modalClass = "ModalRTL"
          }
        }
        else{
          modalClass = "HideModalLTR"
          if (this.props.RTL&&!isMobile){
            modalClass = "HideModalRTL"
          }
        }
        return(
          <DiscoverFilter
            modalClass = {modalClass}
            systemText = {this.props.systemText}
            userLanguage = {this.props.userLanguage}
            RTL = {this.props.RTL}
            detectInputLanguage = {this.props.detectInputLanguage}
            inputLanguage = {this.props.inputLanguage}
            selectedContentType = {this.props.selectedContentType}
            writeAcitivityLog = {this.props.writeAcitivityLog}

            categoriesTranslator = {this.props.categoriesTranslator}
            locationsTranslator = {this.props.locationsTranslator}
            placesCharacteristicsData = {this.props.placesCharacteristicsData}

            discoverPlacesDaysFilter = {this.props.discoverPlacesDaysFilter}
            discoverPlacesCharacteristicsFilter = {this.props.discoverPlacesCharacteristicsFilter}
            locationFilterTags = {this.props.discoverPlacesLocationFilterTags}
            categoriesFilterTags = {this.props.discoverPlacesCategoriesFilterTags}
            namesFilterTag = {this.props.discoverPlacesNameFilterTag}
            additionalTagsFilterTag = {this.props.discoverPlacesAdditionalTagFilterTag}

            suggestedLocationsFilters = {this.props.suggestedLocationsFilters}
            discoverPlacesAvailableCategories = {this.props.discoverPlacesAvailableCategories}
            discoverPlacesAvailableMainCategories = {this.props.discoverPlacesAvailableMainCategories}
            discoverPlacesSelectedMainCategory = {this.props.discoverPlacesSelectedMainCategory}

            closeFilters = {this.closeFiltersHandler}                  

            removePlaceLocationFilterTag = {this.props.removePlaceLocationFilterTag}
            addPlaceLocationTagHandler = {this.props.addPlaceLocationTagHandler}
            discoverPlacesMainCategoryHandler = {this.props.discoverPlacesMainCategoryHandler}
            addPlaceCategoryTagHandler = {this.props.addPlaceCategoryTagHandler}
            removePlaceCategoryFilterTag = {this.props.removePlaceCategoryFilterTag}
            removePlaceNameFilterTag = {this.props.removePlaceNameFilterTag}
            removePlaceAdditionalTagFilterTag = {this.props.removePlaceAdditionalTagFilterTag}
            updatePlaceCharacteristicsFilters = {this.props.updatePlaceCharacteristicsFilters}
            updateSelectedDay = {this.props.updateDiscoverPlacesSelectedDay}

            countriesToExplore = {this.props.countriesToExplore}
            locationsTree = {this.props.locationsTree}

            discoverTripsCountriesFilters = {this.props.discoverTripsCountriesFilters}
            discoverTripsLocationSearchTags = {this.props.discoverTripsLocationSearchTags}
            discoverTripsLocationFilters={this.props.discoverTripsLocationFilters}
            discoverTripsPlanAdditionalTagsFilters = {this.props.discoverTripsPlanAdditionalTagsFilters}
            discoverTripsCategoriesFilters = {this.props.discoverTripsCategoriesFilters}
            discoverTripsStyleFocus = {this.props.discoverTripsStyleFocus}
            discoverPlansDurationLimits = {this.props.discoverPlansDurationLimits}
            discoverPlansDurationFilter = {this.props.discoverPlansDurationFilter}
            discoverPlansParticipants = {this.props.discoverPlansParticipants}
            addDiscoverTripsLocationFilterTag = {this.props.addDiscoverTripsLocationFilterTag}

            discoverTripsCombineLocationsHandler = {this.props.discoverTripsCombineLocationsHandler}
            discoverTripsCombineLocations = {this.props.discoverTripsCombineLocations}

            addTripsCountry = {this.props.addDiscoverTripsCountry}
            removeTripsCountry = {this.props.removeDiscoverTripsCountry}
            removeTripsLocationsFilters = {this.props.removeDiscoverTripsLocationsFilters}
            updateTripsCategoriesFilters = {this.props.updateDiscoverTripsCategoriesFilters}
            updateTripsStyle = {this.props.updateDiscoverTripsStyleFocus}
            discoverPlansDurationHandler={this.props.discoverPlansDurationHandler}
            discoverPlansParticipantsHandler = {this.props.discoverPlansParticipantsHandler}
            removeTripAdditionalTag = {this.props.removeDiscoverTripsAdditionalTag}
          />
        )
      }

    render(){
      let bodyClass = "Body"
      if (this.props.RTL){
        bodyClass = "BodyRTL"
      }
      let showDiscoverToolbar=false;
      if (!isMobile&&this.props.selectedContentType==="Places") showDiscoverToolbar=true
        return(
            <div className={classes[bodyClass]}   id ="DiscoverPlacesBody">
               {this.state.showScrollUpButton?(<ScrollToTop scrollToTop={this.scrollFeedToTop}/>):null}
                {this.renderFilters()}
                
               {showDiscoverToolbar?(
                 <div className={classes.RegularToolBar}>
                 {this.renderDiscoverToolbar()}
              </div>
               ):null}
                {this.renderSelectedContent()}
            </div>
        )
    }
}