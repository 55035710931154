import React, { Component } from "react";
import classes from "./DiscoverFilter.module.css";

import Tag from "../../UI/Tag/Tag";
import MainCategory from "../../UI/Misc/MainCategory";
import PlaceCharacteristics from "../PlaceCharacteristics/PlaceCharacteristics"
import OptionSelection from "../OptionSelection/OptionSelection"
import SimpleSearchBar from "../SearchBars/SimpleSearchBar"

import { isMobile } from "react-device-detect";

import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"


export default class DiscoverFilter extends Component {

    state={
        showAlllocationsFilters:false,

    }

    renderShowResults = () =>{
      if (isMobile){
        let showResultsClass = "ShowResults"
        if (this.props.RTL){
            showResultsClass = "ShowResultsRTL"
        }
        return(
          <div className={classes.BottomSection} >
          <div className={classes.ShowResults}  onClick={this.props.closeFilters}>
              {this.props.systemText.discover.filters.mobileClose[this.props.userLanguage]}
            </div>
          
      </div>
      )
      }
    }
    

    renderTopSection = () =>{
        let closeIconClass = "CloseIcon"
        if (this.props.RTL){
            closeIconClass = "CloseIconRTL"
        }
        return(
          <div className={classes.TopSection} >
            <div className={classes.Title}>{this.props.systemText.discover.filters.title[this.props.userLanguage]}</div>
            {!isMobile?(
              <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"} 
              alt="" className={classes[closeIconClass]} onClick={this.props.closeFilters}/>
            ):null}
            
  
        </div>
        )
     }

     renderCharTags = () =>{

     }

    renderFilterTags = () =>{
  

        return(
            <div className={classes.FilterTags}>
               {this.props.locationFilterTags.length>0?(
              <div className={classes.Filters}>
              {this.props.locationFilterTags.map((tag, index) => {
                 if (tag.tag[this.props.userLanguage]!==undefined){
                  return(
                    <Tag 
                      key={index}
                      removable={true}
                      style={tag.type}
                      remove={() => {this.props.removePlaceLocationFilterTag(index)}}
                      text={tag.tag[this.props.userLanguage]}
                      RTL={this.props.RTL}/>
                  )
                }
              })}
              </div>):null}
             {this.props.categoriesFilterTags.length>0?(
              <div className={classes.Filters}>
              {this.props.categoriesFilterTags.map((tag, index) => (
                <Tag
                  key={index}
                  text={tag.tag[this.props.userLanguage]}
                  removable={true}
                  style={tag.type}
                  remove={() => {this.props.removePlaceCategoryFilterTag(index)}}
                  RTL = {this.props.RTL}
                />
              ))}
              {this.renderCharTags()}
              </div>):null}
              {this.props.namesFilterTag.length>0?(
              <div className={classes.Filters}>
              {this.props.namesFilterTag.map((tag, index) => (
                <Tag
                  key={index}
                  text={tag.tag[tag.language]}
                  removable={true}
                  style={tag.type}
                  remove={() => {this.props.removePlaceNameFilterTag(index)}}
                  RTL = {this.props.RTL}
                />
              ))}
              </div>):null}
              {this.props.additionalTagsFilterTag.length>0?(
              <div className={classes.Filters}>
              {this.props.additionalTagsFilterTag.map((tag, index) => (
                <Tag
                  key={index}
                  text={tag.tag[tag.language]}
                  removable={true}
                  style={tag.type}
                  remove={() => {this.props.removePlaceAdditionalTagFilterTag(index)}}
                  RTL = {this.props.RTL}
                />
              ))}
              </div>):null}
            </div>
        )
    }

    renderLocationFilters = () =>{
      if (this.props.suggestedLocationsFilters!==undefined){
        let locationTags=this.props.suggestedLocationsFilters.filter(tag=>tag!==undefined).filter(suggestedTag=>!this.props.locationFilterTags.map(tag=>{return tag.tag.treeID}).includes(suggestedTag.treeID))
        if(locationTags.length>0 && !this.props.hideLocationSuggestions){ 
            return(
                <div className={classes.FilterSection}>
                    <div className={classes.SectionHeader}>
                        {this.props.systemText.discover.filters.locationFilters.title[this.props.userLanguage]}
                    </div>
                    {this.renderLocations()}
                </div>
            )
        }
      }
       
    }

    renderLocations = () =>{
        //show only location filters which are not already selected
        let locationTags=this.props.suggestedLocationsFilters.filter(tag=>tag!==undefined).filter(tag=>!this.props.locationFilterTags.map(tag=>{return tag.tag.treeID}).includes(tag.treeID))
        if (this.state.showAlllocationsFilters){
          //sort all results by ABC
          let sortedFilters = locationTags.sort((a, b) => {
            var keyA = a[this.props.userLanguage];
            var keyB = b[this.props.userLanguage];
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          return(
            <div className={classes.FilterContent}>
                <div className={classes.Filters}>
                {sortedFilters.map((tag, index) => {
                  if (tag[this.props.userLanguage]!==undefined){
                    return(
                      <Tag 
                        key={index}
                        clickable={true}
                        style={"LocationFilter"}
                        clicked={()=>{ this.props.addPlaceLocationTagHandler(tag);this.setState({showAlllocationsFilters:false})}}
                        text={tag[this.props.userLanguage]}
                        RTL={this.props.RTL}/>
                    )
                  }
                })}
                </div>
                <div className={classes.ShowLessFilters}
                  onClick={()=>{this.setState({showAlllocationsFilters:false})}}>  
                   {this.props.systemText.discover.filters.locationFilters.closeLocationsFilters[this.props.userLanguage]}
                  </div>
            </div>
          )
        }
        else{
          return(
            <div className={classes.FilterContent}>
              <div className={classes.Filters}>
                {locationTags.slice(0,7).map((tag, index) => (
                    <Tag 
                    key={index}
                    clickable={true}
                    style={"LocationFilter"}
                    clicked={()=>{ this.props.addPlaceLocationTagHandler(tag)}}
                    text={tag[this.props.userLanguage]}
                    RTL={this.props.RTL}/>
                ))}
              </div>
              {locationTags.length>7?(
                  <div className={classes.ShowMoreFilters}
                  onClick={()=>{this.setState({showAlllocationsFilters:true})}}>
                   {this.props.systemText.discover.filters.locationFilters.showAllLocations[this.props.userLanguage]}
                    </div>
              ):null}
              
          </div>
          )
        }
             
      }

    renderCategoryFilters = () =>{
        return(
            <div className={classes.FilterSection}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.discover.filters.categoryFilters.title[this.props.userLanguage]}
                </div>
                {this.renderMainCategories()}
                {this.renderCategories()}
            </div>)
    }

    renderMainCategories = () =>{
        return(
            <div className={classes.MainCategories}>
                {[0,0,0,0,0,0].map((i,index)=>(
                    <div className={classes.Category}  key = {index}>
                     <MainCategory
                        index = {index}
                        selected={this.props.discoverPlacesSelectedMainCategory[index+1]}
                        disabled={!this.props.discoverPlacesAvailableMainCategories[index]}
                        clicked={()=>{this.props.discoverPlacesMainCategoryHandler(index+1)}}
                        systemText = {this.props.systemText}
                        userLanguage = {this.props.userLanguage}
                        RTL = {this.props.RTL}
                    />
                    </div>
                   
                ))}
            </div>
        )
    }

    renderCategories = () =>{
      if (this.props.discoverPlacesAvailableCategories!==undefined){
        let discoverPlacesAvailableCategories = this.props.discoverPlacesAvailableCategories.filter(tag=>!this.props.categoriesFilterTags.map(tag=>{return tag.tag.id}).includes(tag)).filter(tag=>tag.split('_')[1]!=="other")
        let allowedCategories = [];

        const index = this.props.discoverPlacesSelectedMainCategory.findIndex(cat => {
            return cat === 1;
          });
        if (index===0){
            allowedCategories = discoverPlacesAvailableCategories
        }
        else{
            switch(index){
                case 1:{
                    allowedCategories=discoverPlacesAvailableCategories.filter(cat=>cat.split('_')[0]==="culturalSightseeing") 
                    break;
                }
                case 2:{
                    allowedCategories=discoverPlacesAvailableCategories.filter(cat=>cat.split('_')[0]==="nature") 
                    break;
                }
                case 3:{
                    allowedCategories=discoverPlacesAvailableCategories.filter(cat=>cat.split('_')[0]==="activities") 
                    break;
                }
                case 4:{
                    allowedCategories=discoverPlacesAvailableCategories.filter(cat=>cat.split('_')[0]==="sport") 
                    break;
                }
                case 5:{
                    allowedCategories=discoverPlacesAvailableCategories.filter(cat=>cat.split('_')[0]==="foodDrink") 
                    break;
                }
                case 6:{
                    allowedCategories=discoverPlacesAvailableCategories.filter(cat=>cat.split('_')[0]==="accommodation") 
                    break;
                }
                default: return null;
            }
        }
        allowedCategories = allowedCategories.filter(cat=>this.props.categoriesTranslator[cat]!==undefined)
        if (this.state.showAllCategoriesFilters){
          let sortedCategories = allowedCategories.sort((a, b) => {
            if (this.props.categoriesTranslator[a]===undefined){
            }
            var keyA = this.props.categoriesTranslator[a][this.props.userLanguage];
            var keyB = this.props.categoriesTranslator[b][this.props.userLanguage];
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          return(
            <div className={classes.FilterContent}>
               <div className={classes.Filters}>
                {sortedCategories.map((tag, index) => (
                     <Tag 
                     key={index}
                     clickable={true}
                     style={"CategoryFilter"}
                     clicked={()=>{ this.props.addPlaceCategoryTagHandler(tag)}}
                     text={this.props.categoriesTranslator[tag][this.props.userLanguage]}
                     RTL={this.props.RTL}/>
                ))}
                </div>
                <div className={classes.ShowLessFilters}
                 onClick={()=>{this.setState({showAllCategoriesFilters:false})}}>  
                   {this.props.systemText.discover.filters.categoryFilters.closeCategoriesFilters[this.props.userLanguage]}
                 </div>
          </div>
          )
        }
        else{
       
          return(
            <div className={classes.FilterContent}>
              <div className={classes.Filters}>
                {allowedCategories.slice(0,7).map((tag, index) => (
                    <Tag 
                    key={index}
                    clickable={true}
                    style={"CategoryFilter"}
                    clicked={()=>{ this.props.addPlaceCategoryTagHandler(tag)}}
                    text={this.props.categoriesTranslator[tag][this.props.userLanguage]}
                    RTL={this.props.RTL}/>
                ))}
              </div>
              {allowedCategories.length>7?(
                 <div className={classes.ShowMoreFilters}
                 onClick={()=>{this.setState({showAllCategoriesFilters:true})}}>
                   {this.props.systemText.discover.filters.categoryFilters.showAllCategories[this.props.userLanguage]}
                   </div>
              ):null}
             
          </div>
          )
        } 
      }
    }

    renderDaysFilters = () =>{
      return(
        <div className={classes.FilterSection}>
            <div className={classes.SectionHeader}>
                {this.props.systemText.discover.filters.dayFilters.title[this.props.userLanguage]}
            </div>
            {this.renderDaysList()}
        </div>)
    }

    renderDaysList = () =>{
      return(
        <div className={classes.DaysList}>
      
          {this.props.discoverPlacesDaysFilter[0]?
          (  <div className={classes.DaySelected}  onClick={()=>{this.props.updateSelectedDay(0,0)}}>
             {this.props.systemText.place.openHours.weekDaysShort.sunday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day}  onClick={()=>{this.props.updateSelectedDay(0,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.sunday[this.props.userLanguage]}
             </div>)}
           {this.props.discoverPlacesDaysFilter[1]?
          (
            <div className={classes.DaySelected} onClick={()=>{this.props.updateSelectedDay(1,0)}}>
               {this.props.systemText.place.openHours.weekDaysShort.monday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day}  onClick={()=>{this.props.updateSelectedDay(1,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.monday[this.props.userLanguage]}
             </div>)}
           {this.props.discoverPlacesDaysFilter[2]?
          ( 
            <div className={classes.DaySelected} onClick={()=>{this.props.updateSelectedDay(2,0)}}>
              {this.props.systemText.place.openHours.weekDaysShort.tuesday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day} onClick={()=>{this.props.updateSelectedDay(2,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.tuesday[this.props.userLanguage]}
             </div>)}
           {this.props.discoverPlacesDaysFilter[3]?
          (   <div className={classes.DaySelected} onClick={()=>{this.props.updateSelectedDay(3,0)}}>
            {this.props.systemText.place.openHours.weekDaysShort.wednesday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day} onClick={()=>{this.props.updateSelectedDay(3,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.wednesday[this.props.userLanguage]}
             </div>)}
           {this.props.discoverPlacesDaysFilter[4]?
          ( <div className={classes.DaySelected} onClick={()=>{this.props.updateSelectedDay(4,0)}}>
             {this.props.systemText.place.openHours.weekDaysShort.thursday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day} onClick={()=>{this.props.updateSelectedDay(4,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.thursday[this.props.userLanguage]}
             </div>
              )}
           {this.props.discoverPlacesDaysFilter[5]?
          (   <div className={classes.DaySelected} onClick={()=>{this.props.updateSelectedDay(5,0)}}>
            {this.props.systemText.place.openHours.weekDaysShort.friday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day} onClick={()=>{this.props.updateSelectedDay(5,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.friday[this.props.userLanguage]}
             </div>
              )}
           {this.props.discoverPlacesDaysFilter[6]?
          (  <div className={classes.DaySelected} onClick={()=>{this.props.updateSelectedDay(6,0)}}>
             {this.props.systemText.place.openHours.weekDaysShort.saturday[this.props.userLanguage]}
             </div>
          ):
          ( <div className={classes.Day} onClick={()=>{this.props.updateSelectedDay(6,1)}}>            
            {this.props.systemText.place.openHours.weekDaysShort.saturday[this.props.userLanguage]}
             </div>)}
       
      </div>
      )
    }

    renderCharacteristicsFilters = () =>{
      let placeMainCategory=[0,0,0,0,0,0];
      for (let i=0 ; i<this.props.discoverPlacesSelectedMainCategory.length ; i++){
          if (this.props.discoverPlacesSelectedMainCategory[i]===1){
            placeMainCategory[i-1]=1;
          }
      }
      
        return(
            <PlaceCharacteristics
            placeMainCategory={placeMainCategory}
            placeCategory = {this.props.categoriesFilterTags.map(cat=>{return cat.tag})}
            systemText={this.props.systemText}
            userLanguage={this.props.userLanguage}
            selectedLanguage={this.state.placeNameInputLanguage}
            placesCharacteristicsData={this.props.placesCharacteristicsData}
            placeCharacteristics={this.props.discoverPlacesCharacteristicsFilter}
            RTL = {this.props.RTL}
            filterMode = {true}
            inputLanguage = {this.props.inputLanguage}
            detectInputLanguage = {this.props.detectInputLanguage}

            updateCharacteristics = {this.props.updatePlaceCharacteristicsFilters}
        />
        )
    }

    renderTripsCountries = () =>{
      let countriesList = [];
      for (let i=0;i<this.props.countriesToExplore.length ; i++){
          let country = JSON.parse(JSON.stringify(this.props.locationsTree[this.props.countriesToExplore[i]]))
          delete country.regions;
          delete country.cities; 
          countriesList.push(country)
      }
      return(
        <div className={classes.FilterTags}>
            <div className={classes.SectionHeader}>
                {this.props.systemText.discover.filters.planFilters.countrySelection.title[this.props.userLanguage]}
            </div>
            <div className={classes.Filters}>
              {this.props.discoverTripsCountriesFilters.map((tag,index)=>(
                  <Tag 
                  key={index}
                  removable={true}
                  style={"LocationFilter"}
                  clicked={()=>{ this.props.removeTripsCountry(index)}}
                  text={this.props.locationsTranslator[tag][this.props.userLanguage]}
                  RTL={this.props.RTL}/>
              ))}
            </div>
            <div className={classes.SelectionSection}>
                <OptionSelection
                    currentSelection={""}
                    optionsList={countriesList}
                    updateSelectedOption={this.props.addTripsCountry}
                    placeholder={""}
                    valueRequired={false}
                    onlyFromList={true}
                    userLanguage={this.props.userLanguage}
                    RTL = {this.props.RTL}
                    inputLanguage = {this.props.inputLanguage}
                    detectInputLanguage = {this.props.detectInputLanguage}
             /> 
             </div>
        </div>
    )
    }

    renderLocationsOptions = () =>{
      let checkIconClass = "CheckIcon"
      let uncheckBoxClass = "UncheckBox"
      if (this.props.RTL){
        checkIconClass = "CheckIconRTL"
        uncheckBoxClass = "UncheckBoxRTL"
      }
      if (this.props.discoverTripsCombineLocations){
        return(
          <div className={classes.NoDatesSection} >
            <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.props.discoverTripsCombineLocationsHandler(false)}}/>
            <div className={classes.NoDatesButtonSelected}>
            {this.props.systemText.discover.filters.planFilters.combineLocationTags[this.props.userLanguage]}</div>
           
          </div>
        )
      }
      else{
        return(
          <div className={classes.NoDatesSection}>
             <div className={classes[uncheckBoxClass]} onClick={()=>{this.props.discoverTripsCombineLocationsHandler(true)}}/>
            <div className={classes.NoDatesButton}>
            {this.props.systemText.discover.filters.planFilters.combineLocationTags[this.props.userLanguage]}</div>
            
          </div>
        )
      }
    }
    renderTripsLocations = () =>{
      return(
        <div className={classes.FilterSection}>
            <div className={classes.SectionHeader}>
                {this.props.systemText.discover.filters.planFilters.filteredLocationTags[this.props.userLanguage]}
            </div>
            {this.renderLocationsOptions()}
            {this.props.discoverTripsCombineLocations?(
              <div className={classes.LocationsMessage}> {this.props.systemText.discover.filters.planFilters.combineLocationsMessage[this.props.userLanguage]}</div>
            ):(
              <div className={classes.LocationsMessage}>{this.props.systemText.discover.filters.planFilters.notCombineLocationsMessage[this.props.userLanguage]}</div>
            )}
            <div className={classes.SearchSection}>
                <SimpleSearchBar
                    searchTags = {this.props.discoverTripsLocationSearchTags}
                    placeholderMessage = {this.props.systemText.welcomePage.searchSection.searchBarTripsPlaceholder[this.props.userLanguage]}
                    detectLanguage = {this.props.detectInputLanguage}
                    inputLanguage = {this.props.inputLanguage}
                    RTL = {this.props.RTL}
                    updateFilterTags = {this.props.addDiscoverTripsLocationFilterTag}
                    
                    systemText = {this.props.systemText}
                    userLanguage = {this.props.userLanguage}
                    locationsTranslator = {this.props.locationsTranslator}
                />
              </div>
            {this.props.discoverTripsLocationFilters.length>0?(
            <div className={classes.Filters}>
              {this.props.discoverTripsLocationFilters.map((tag,index)=>(
                  <Tag 
                  key={index}
                  removable={true}
                  style={"LocationFilter"}
                  clicked={()=>{ this.props.removeTripsLocationsFilters(index)}}
                  text={this.props.locationsTranslator[tag][this.props.userLanguage]}
                  RTL={this.props.RTL}/>
              ))}
            </div>):null}
    
        </div>
      )
    }

    renderTripsAdditionalTags = () =>{
      if (this.props.discoverTripsPlanAdditionalTagsFilters.length>0){
        return(
          <div className={classes.FilterSection}>
              <div className={classes.SectionHeader}>
                  {this.props.systemText.discover.filters.planFilters.filteredAdditionalTags[this.props.userLanguage]}
              </div>
              <div className={classes.Filters}>
              {this.props.discoverTripsPlanAdditionalTagsFilters.map((tag,index)=>(
                  <Tag 
                  key={index}
                  removable={true}
                  style={"AdditionalTagFilter"}
                  clicked={()=>{ this.props.removeTripAdditionalTag(index)}}
                  text={tag}
                  RTL={this.props.RTL}/>
              ))}
            </div>
    
        </div>)
      }
    }

    renderTripsDuration = () =>{
      return(
        <div className={classes.FilterSection}>
         <div className={classes.SectionHeader}>
                  {this.props.systemText.discover.filters.planFilters.durationFilter[this.props.userLanguage]}
                  </div>
                  <div className={classes.DurationNumbers}>
                       <input type="number" value={this.props.discoverPlansDurationFilter[0]} 
                          onChange={e=>{
                              let discoverPlansDurationFilter=this.props.discoverPlansDurationFilter; discoverPlansDurationFilter[0]=e.target.value;
                              this.props.discoverPlansDurationHandler(discoverPlansDurationFilter)
                          }}
                          className={classes.DurationNumber}/>
                      {" : "}
                      <input type="number" value={this.props.discoverPlansDurationFilter[1]} 
                          onChange={e=>{
                              let discoverPlansDurationFilter=this.props.discoverPlansDurationFilter; discoverPlansDurationFilter[1]=e.target.value;
                              this.props.discoverPlansDurationHandler(discoverPlansDurationFilter)
                          }}
                          className={classes.DurationNumber}/>
                  </div>
             
              <div className={classes.RangeSection}>
               <Range
                  min={this.props.discoverPlansDurationLimits.min}
                  max={this.props.discoverPlansDurationLimits.max}
                  value={this.props.discoverPlansDurationFilter}
                  disabled={this.props.discoverPlansDurationLimits.max===0}
                  trackStyle={[{ backgroundColor: 'rgb(34,121,190,0.7)', height:3 ,width:"80%"}]}
          
                  railStyle={{ backgroundColor: 'rgb(223,223,223)', height: 1 }}
              
                  handleStyle={[{
                      transform: 'translate(-50%, -50%)',
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      border: "solid 0.7px rgb(34,52,70)",
                      backgroundColor: " radial-gradient(rgb(34,52,70), black)",
                      
                      boxShadow: "none",
                      position:"relative",
                      top:"-5px"
                      },
                      {
                      transform: 'translate(-50%, -50%)',
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      border: "solid 0.7px rgb(34,52,70)",
                      backgroundColor: "rgb(255,255,255)",
                      boxShadow: "none",
                      position:"relative",
                      top:"-19px"
                      }]}
                   onChange={value=>{this.props.discoverPlansDurationHandler(value)}}
               />
               </div>
          </div>
      )
    }

    renderTripsCategories = () =>{

    }

    renderTripsParticipants = () =>{
      let kidsStyle = "Characteristic"
      if (this.props.discoverPlansParticipants[1]){
          kidsStyle = "CharacteristicSelected"
      }
      let toddlersStyle = "Characteristic"
      if (this.props.discoverPlansParticipants[2]){
        toddlersStyle = "CharacteristicSelected"
      }
      return(
        <div className={classes.FilterSection}>
          <div className={classes.SectionHeader}>
            {this.props.systemText.discover.filters.planFilters.participants[this.props.userLanguage]}
          </div>
          <div className={classes.Participants}>
            <Tag 
              clickable={true}
              style={kidsStyle}
              clicked={()=>{ this.props.discoverPlansParticipantsHandler(1)}}
              text= {this.props.systemText.plan.createPlan.kids[this.props.userLanguage]}
              RTL={this.props.RTL}/>
            <Tag 
              clickable={true}
              style={toddlersStyle}
              clicked={()=>{ this.props.discoverPlansParticipantsHandler(2)}}
              text= {this.props.systemText.plan.createPlan.toddlers[this.props.userLanguage]}
              RTL={this.props.RTL}/>
          </div>
        </div>)
    }

    renderTripsStyleFocus = () =>{

    }


    renderBody = () =>{
      if (this.props.selectedContentType==="Places"){
        if (this.props.namesFilterTag.length>0){
          return(
            <div className={classes.Body}>
              {this.renderFilterTags()}
            </div>
          )
        }
        else{
          return(
            <div className={classes.Body}>
                {this.renderFilterTags()}
                {this.renderLocationFilters()}
                {this.renderCategoryFilters()}
                {this.renderDaysFilters()}
                {this.renderCharacteristicsFilters()}
            </div>
        )
        }
       
      }
      else if (this.props.selectedContentType==="Trips"){
        return(
          <div className={classes.Body}>
           {/*  {this.renderTripsCountries()} */}
            {this.renderTripsLocations()}
            {this.renderTripsDuration()}
            {this.renderTripsAdditionalTags()}
           {/*  {this.renderTripsCategories()}
            {this.renderTripsParticipants()}
            {this.renderTripsStyleFocus()} */}
          </div>
        )
        
      }
        
    }

   render(){
     let RTLClass = "" 
     if (this.props.RTL){
       RTLClass = "RTL"
     }
    return(
        <div className={[classes[this.props.modalClass],classes[RTLClass]].join(" ")}>
            {this.renderTopSection()}
            {this.renderBody()}       
            {this.renderShowResults()}   
      </div>
    )
}
}