import React, { Component } from "react";
import classes from "./PlanSecurity.module.css";

import ModalTopSection from "../../UI/Misc/ModalTopSection";
import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState , convertToRaw, convertFromRaw} from 'draft-js';

import TaggingSection from "../../../Components/Complex/Tagging/TaggingSection"

let privacyIcon =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPrivacyIcon.png?alt=media&token=b0c80a2b-fd2b-4d94-864c-7ad74ed6e22f";


class PlanSecurity extends Component {
    state={
        planSecurity: this.props.planSecurity,
        albumSecurity: this.props.albumSecurity,
        currentUserAlbumSecurity: this.props.albumSecurity[this.props.user.userID],
        publicPlanTags:this.props.publicPlanTags,
        planDescription: EditorState.createEmpty(),
    }

    componentDidMount = () =>{

        if (this.props.planDescription!==undefined){
            try {
                JSON.parse(this.props.planDescription);
                this.setState({planDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.planDescription))),
                  jsonString: true
                  })
            } catch (e) {
              this.setState({planDescription:EditorState.createEmpty(), jsonString: true})
            }
        }
    }

    discardTransparent = () =>{
        this.setState({
            showPlanPrivacyOptions: false,
            showAlbumPrivacyOptions: false,
            showTransparentBackdrop: false
        })
    }

    updateHandler = () =>{
        let planSecurity = this.state.planSecurity;
        let albumSecurity = this.state.albumSecurity;
        albumSecurity[this.props.user.userID] = this.state.currentUserAlbumSecurity
        let planDescription = JSON.stringify(convertToRaw(this.state.planDescription.getCurrentContent()));
        this.props.writeAcitivityLog({
            activityKey:"TRIP_PRIVACY_SAVE",
            userScreen:"VIEW_TRIP"
        })
        let data = {
            planSecurity:planSecurity,
            albumSecurity:albumSecurity,
            planDescription: planDescription,
            publicPlanTags: this.state.publicPlanTags
        }
        this.props.update(data)
        this.props.discard();
    }

    updateTags = (tags) =>{
        this.setState({ publicPlanTags: tags});
        this.props.writeAcitivityLog({
            activityKey:"TRIP_PRIVACY_UPDATE_PUBLIC_TAGS",
            userScreen:"VIEW_TRIP"
        })
    }
 
    renderTopSection = () =>{
        let title = this.props.systemText.plan.viewPlan.privacy.title[this.props.userLanguage];
        let buttonName = this.props.systemText.misc.buttons.updateButton[this.props.userLanguage];
        return (
          <div className={classes.TopSection}>
             <ModalTopSection
                title={title}
                discard={this.props.discard}
                saveReady={true}
                showButton={true}
                buttonText={buttonName}
                clicked={this.updateHandler}
                saveButton={true}
                RTL = {this.props.RTL}
                modalView = {true}
                />
           
          </div>
        );
    }

    planPrivacyHandler = (privacy) =>{
        if (privacy==="public"){
            if (this.props.planIncludePlaces)
            {
                this.setState({showPlanHasNoPlacesMessage:false,planSecurity:"public",showPlanPrivacyOptions:false,showTransparentBackdrop:false})
            }
            else{
                this.setState({showPlanHasNoPlacesMessage:true,showPlanPrivacyOptions:false,showTransparentBackdrop:false})
            }
        }
        else{
            this.setState({showPlanHasNoPlacesMessage:false,planSecurity:privacy,showPlanPrivacyOptions:false,showTransparentBackdrop:false})
        }
        this.props.writeAcitivityLog({
            activityKey:"TRIP_PRIVACY_PLAN_PRIVACY_UPDATE",
            userScreen:"VIEW_TRIP",
            selectedPrivacy:privacy
          })
    }

    renderPlanPrivacy = () =>{
        let privacyIconClass = "PrivacyIcon"
        if (this.props.RTL){
            privacyIconClass = "PrivacyIconRTL"
        }
        return(
            <div className={classes.PlanPrivacy}>
                <div className={classes.Title}>
                    {this.props.systemText.plan.viewPlan.privacy.planSecurityTitle[this.props.userLanguage]}
                </div>
                <div className={classes.PrivacyHeader}>
           
                    {this.props.systemText.plan.viewPlan.privacy.planSecurityMessage[this.props.userLanguage].split('\n').map(str => <p>{str}</p>)}
                </div>
                <div className={classes.PrivacySelection}>
                <div className={classes.SelectedPrivacy} onClick={()=>{this.setState({showPlanPrivacyOptions:true,showTransparentBackdrop:true})}}>
                        <img src={privacyIcon} alt="" className={classes[privacyIconClass]}/>
                        {this.props.systemText.experience.createExperience.privacy[this.state.planSecurity][this.props.userLanguage]}</div>
                      {this.state.showPlanPrivacyOptions?(
                      <div className={classes.PrivacyOptions}>
                          {this.props.loggedUser?(
                               <div className={classes.PrivacyOption} 
                               onClick={()=>{this.planPrivacyHandler("public")}}>
                                   {this.props.systemText.experience.createExperience.privacy.public[this.props.userLanguage]}
                                 </div>
                          ):(
                            <div className={classes.PrivacyOptionDisabled}>
                                {this.props.systemText.experience.createExperience.privacy.public[this.props.userLanguage]}
                              </div>
                          )}
                       {this.props.loggedUser?(
                           <div className={classes.PrivacyOption} 
                           onClick={()=>{this.planPrivacyHandler("friendsFollowers")}}>
                                 {this.props.systemText.experience.createExperience.privacy.friendsFollowers[this.props.userLanguage]}
                           </div>
                       ):(
                            <div className={classes.PrivacyOptionDisabled}>
                                {this.props.systemText.experience.createExperience.privacy.friendsFollowers[this.props.userLanguage]}
                            </div>
                       )}
                        {this.props.loggedUser?(
                            <div className={classes.PrivacyOption} 
                            onClick={()=>{this.planPrivacyHandler("friends")}}>
                                  {this.props.systemText.experience.createExperience.privacy.friends[this.props.userLanguage]}
                              </div>
                        ):(
                            <div className={classes.PrivacyOptionDisabled}>
                              {this.props.systemText.experience.createExperience.privacy.friends[this.props.userLanguage]}
                          </div>
                        )}
                        
                          
                        <div className={classes.PrivacyOption} 
                        onClick={()=>{this.planPrivacyHandler("private")}}>
                              {this.props.systemText.experience.createExperience.privacy.private[this.props.userLanguage]}
                          </div>
                      </div>):null}
                </div>
                {this.state.showPlanHasNoPlacesMessage?(
                    <div className={classes.PlanHasNoPlacesMessage}>
                        {this.props.systemText.experience.createExperience.privacy.planHasNoPlacesMessage[this.props.userLanguage]}
                    </div>
                ):null}
            </div>
        )
    }

    albumPrivacyHandler = privacy =>{
        this.setState({currentUserAlbumSecurity:privacy,showAlbumPrivacyOptions:false,showTransparentBackdrop:false})
        
        this.props.writeAcitivityLog({
            activityKey:"TRIP_PRIVACY_ALBUM_PRIVACY_UPDATE",
            userScreen:"VIEW_TRIP",
            selectedPrivacy:privacy
        })
    }

   renderAlbumPrivacy = () =>{
    let privacyIconClass = "PrivacyIcon"
    if (this.props.RTL){
        privacyIconClass = "PrivacyIconRTL"
    }
    return(
        <div className={classes.PlanPrivacy}>
            <div className={classes.Title}>
                {this.props.systemText.plan.viewPlan.privacy.albumSecurityTitle[this.props.userLanguage]}
            </div>
            <div className={classes.PrivacyHeader}>
                {this.props.systemText.plan.viewPlan.privacy.albumSecurityMessage[this.props.userLanguage].split('\n').map(str => <p>{str}</p>)}
        
            </div>
            <div className={classes.PrivacySelection}>
            <div className={classes.SelectedPrivacy} onClick={()=>{this.setState({showAlbumPrivacyOptions:true,showTransparentBackdrop:true})}}>
                    <img src={privacyIcon} alt="" className={classes[privacyIconClass]}/>
                    {this.props.systemText.experience.createExperience.privacy[this.state.currentUserAlbumSecurity][this.props.userLanguage]}</div>
                  {this.state.showAlbumPrivacyOptions?(
                  <div className={classes.PrivacyOptions}>
                    <div className={classes.PrivacyOption} 
                    onClick={()=>{this.albumPrivacyHandler("public")}}>
                        {this.props.systemText.experience.createExperience.privacy.public[this.props.userLanguage]}
                      </div>
                    <div className={classes.PrivacyOption} 
                    onClick={()=>{this.albumPrivacyHandler("friendsFollowers")}}>
                            {this.props.systemText.experience.createExperience.privacy.friendsFollowers[this.props.userLanguage]}
                    </div>
                    <div className={classes.PrivacyOption} 
                    onClick={()=>{this.albumPrivacyHandler("friends")}}>
                          {this.props.systemText.experience.createExperience.privacy.friends[this.props.userLanguage]}
                      </div>
                    <div className={classes.PrivacyOption} 
                    onClick={()=>{this.albumPrivacyHandler("private")}}>
                          {this.props.systemText.experience.createExperience.privacy.private[this.props.userLanguage]}
                      </div>
                  </div>):null}
            </div>
        </div>
    )
   }

   renderPublicPlanInfo = () =>{
       if (this.state.planSecurity==="public"){
        let descriptionInputFieldClass = "DescriptionInputField"
        if (this.props.RTL){
            descriptionInputFieldClass = "DescriptionInputFieldRTL"
        }
        return(
            <div className={classes.PublicPlanInfo}>
                
                 <div className={classes.DescriptionSection}>
                    <Editor
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', ],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                    
                    }}
                    stripPastedStyles={true}
                    placeholder={
                        this.props.systemText.plan.createPlan.publicPlan.descriptionPlaceholder[this.props.userLanguage]
                    }
                    editorClassName={classes[descriptionInputFieldClass]}
                    editorState={this.state.planDescription}
                    onEditorStateChange={(value)=>{this.setState({planDescription:value})}}
                    />
                
                </div>
                
                <TaggingSection
                    selectedTags={this.state.publicPlanTags}
                    userTags={[]}
                    updateTags={this.updateTags}
                    placeholder= {this.props.systemText.plan.createPlan.publicPlan.addTagsPlaceHolder[this.props.userLanguage]}
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}
                    />
            </div>
        )
       }
   }

    renderBody = () =>{
        return(<div className={classes.Body}>
            {this.renderPlanPrivacy()}
            {this.renderPublicPlanInfo()}
            <div className={classes.Sperator}/>
            {this.renderAlbumPrivacy()}
        </div>)
    }

    render(){
      let RTL = ""
      if (this.props.RTL){
          RTL = "RTL"
      }
      return(
          <div className={[classes.Modal,classes[RTL]].join(" ")}>
                <TransparentBackdrop show={this.state.showTransparentBackdrop} clicked={this.discardTransparent}/>
            {this.renderTopSection()}
            {this.renderBody()}
        </div>)
    }
}

export default PlanSecurity;