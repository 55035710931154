import React, { Component } from "react";

import classes from "./ImageCarusel.module.css";
import ImageFullscreenViewer from "./ImageFullscreenViewer"; 

import { isMobileOnly } from "react-device-detect";

let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowLeft.png?alt=media&token=8020b73f-93be-478c-a1c3-c3cee641fbe8"
let arrowIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowRight.png?alt=media&token=e0fca8aa-a34f-47cf-80d3-79b36b305e88"

let scrollConstant = 1

class ImageCarusel extends Component {
  state = { zoomUrl: "" ,
            selectedImage:this.props.imagesUrls[0],
            imageIndex:0,
            imagesRef:React.createRef(),
};

componentDidMount = () =>{
    var scrollComponent = this;
    var frame = this.state.imagesRef.current;
    frame.addEventListener("scroll", function(e) {
        scrollComponent.imageGalleryScrollToggle();
    });      
    frame.scrollTo({
        left:0,
        behavior: "smooth"
        });  
}

componentDidUpdate = prevProps =>{
    if (JSON.stringify(this.props.imagesUrls)!==JSON.stringify(prevProps.imagesUrls)){
        this.setState({ zoomUrl: "" ,
        selectedImage:this.props.imagesUrls[0],
        imageIndex:0})
    }
}

imageGalleryScrollToggle = () =>{
    var frame = this.state.imagesRef.current;
    let scrollLeft = Math.abs(frame.scrollLeft)
    if (!this.state.scrollStopped){ 
        this.setState({lastScroll: new Date()})
        setTimeout(e => {
          if (this.state.lastScroll.getTime() < (new Date()).getTime() - 200) {
            
            this.mobileMapPreviewScollHandler()
          }        
        }, 200); 
      }
}
mobileMapPreviewScollHandler= async () =>{
    await this.setState({scrollStopped:true})
    var frame = this.state.imagesRef.current;
  
    let screenWidth = window.innerWidth;
    let scrollLeft = Math.abs(frame.scrollLeft)
    let val =1
    if (scrollLeft===-1*frame.scrollLeft){
      val=-1
    }
    if (scrollLeft>this.state.mobilePreviewScrollLeft){
      //moving to the right
      if (Math.floor(scrollLeft/screenWidth) === Math.round(scrollLeft/screenWidth)){
        //to the right part
     
        frame.scrollTo({
          left:val*screenWidth*Math.floor(scrollLeft/screenWidth),
          behavior: "smooth",
          duration:500
        });
        this.setState({imageIndex:Math.floor(scrollLeft/screenWidth)})
      }
      else{
        
        frame.scrollTo({
            left:val*screenWidth* Math.round(scrollLeft/screenWidth),
            behavior: "smooth",
            duration:500
          });
          this.setState({imageIndex: Math.round(scrollLeft/screenWidth)})
      }
    }
    else{
      //moving to the left
      if (Math.floor(scrollLeft/screenWidth) === Math.round(scrollLeft/screenWidth)){
        //to the right part
          frame.scrollTo({
            left:val*screenWidth*Math.floor(scrollLeft/screenWidth),
            behavior: "smooth",
            duration:500
          });
          this.setState({imageIndex:Math.floor(scrollLeft/screenWidth)})
      }
      else{
        frame.scrollTo({
          left:val*screenWidth* Math.round(scrollLeft/screenWidth),
          behavior: "smooth",
          duration:500
        });
        this.setState({imageIndex:Math.round(scrollLeft/screenWidth)})
      }
    }
    await this.setState({scrollStopped:false,mobilePreviewScrollLeft:scrollLeft})
  }


leftHandler = () =>{
    let imageIndex = this.state.imageIndex;
    if (imageIndex===0) {
        imageIndex = this.props.imagesUrls.length -1;
        let selectedImage = this.props.imagesUrls[imageIndex];
        this.setState({imageIndex:imageIndex, selectedImage:selectedImage})
    }
    else{
        imageIndex = imageIndex -1;
        let selectedImage = this.props.imagesUrls[imageIndex];
        this.setState({imageIndex:imageIndex, selectedImage:selectedImage})
    }   
       
    if (this.props.placeImage){
        this.props.writeAcitivityLog({
            activityKey:"PLACE_IMAGES_ARROWS",
            userScreen:"VIEW_PLACE"
          })
    }
}

selectBulletHandler = index =>{
    let imageIndex =index;
    let selectedImage = this.props.imagesUrls[imageIndex];
    this.setState({imageIndex:imageIndex, selectedImage:selectedImage})

    if (this.props.placeImage){
        this.props.writeAcitivityLog({
            activityKey:"PLACE_IMAGES_BULLETS",
            userScreen:"VIEW_PLACE"
          })
    }
}

rightHandler = () =>{
    let imageIndex = this.state.imageIndex;
    if (imageIndex===this.props.imagesUrls.length-1) {
        imageIndex = 0;
        let selectedImage = this.props.imagesUrls[imageIndex];
        this.setState({imageIndex:imageIndex, selectedImage:selectedImage})
    }
    else{
        imageIndex = imageIndex+1;
        let selectedImage = this.props.imagesUrls[imageIndex];
        this.setState({imageIndex:imageIndex, selectedImage:selectedImage})
    }
    
    if (this.props.placeImage){
        this.props.writeAcitivityLog({
            activityKey:"PLACE_IMAGES_ARROWS",
            userScreen:"VIEW_PLACE"
          })
    }
   
}

renderBullet = (index) =>{
    if (this.state.imageIndex===index){
        return(<div className={classes.SelectedBullet}>

        </div>)
    }
    else{
        return (<div className={classes.UnselectedBullet}></div>)
    }
}

renderImageBullets = () =>{
    return (<div className={classes.ImageBullets}>
        {this.props.imagesUrls.map((bullet,index) => (
            <div key={index} className={classes.Bullet} onClick={()=>{this.selectBulletHandler(index)}}>
                {this.renderBullet(index)}
            </div>
        ))}
    </div>)
}

renderImageCarusel = () =>{
    let rotateArrow = ""
    if (this.props.RTL){
        rotateArrow = "RotateArrow"
    }
    return(
        <div className={classes.Wrapper}>
            {!isMobileOnly?(
            <div className={classes.ArrowsSection}>
                {this.props.imagesUrls.length > 1 ?(
                    <img className={[classes.Arrow,classes[rotateArrow]].join(" ")} alt = "" src={arrowIconLeft} onClick={this.leftHandler}/>):null}
                    {this.props.imagesUrls.length > 1 ?(
                <img src={arrowIconRight} className={[classes.Arrow,classes[rotateArrow]].join(" ")} alt="" onClick={this.rightHandler}/>):null}
            </div>):null}
            {isMobileOnly?(
                <div className={classes.ImagesSection} ref={this.state.imagesRef}>
                {this.props.imagesUrls.map(image=>(
                    <img src={image} className={classes.Image} alt=""
                    onClick={()=>{
                        if (this.props.viewImagesFullscreen!==undefined){
                           this.props.viewImagesFullscreen(this.state.imageIndex);
                        } }} />
                ))}
            </div>
            ):(
                <div className={classes.ImagesSection} ref={this.state.imagesRef}>
                <img src={this.state.selectedImage} className={classes.Image} alt=""
                 onClick={()=>{
                     if (this.props.viewImagesFullscreen!==undefined){
                        this.props.viewImagesFullscreen(this.state.imageIndex);
                    } }} />
                  </div> 
            )}
            
            {this.props.showCopyrightMessage?(
            <div className={classes.CopyrightMessage} >
                <div>{this.props.systemText.place.viewPlace.imageCopyrightMessage[this.props.userLanguage]}</div>
                <div className={classes.CopyrightLink} onClick={this.props.copyrightHandler}> {this.props.systemText.place.viewPlace.imageCopyrightClick[this.props.userLanguage]}</div>
            </div>
            ):null}
          
            {this.renderImageBullets()}
        </div>
    )
}

renderFullscreenViewver = () =>{
    if (this.state.showFullscreen){
        return (
            <ImageFullscreenViewer
            imageIndex={this.state.imageIndex}
            imagesUrls={this.props.imagesFullScreenUrls}
            discard={()=>{this.setState({showFullscreen:false});
                if (this.props.placeImage){
                    this.props.writeAcitivityLog({
                        activityKey:"PLACE_IMAGES_FULLSCREEN_EXIST",
                        userScreen:"VIEW_PLACE"
                    })
                }
                }}
            RTL={this.props.RTL}
            />
        )
    }
} 

  render() {
    return (
            <div className={classes.Body}>
                {this.renderFullscreenViewver()}
                {this.renderImageCarusel()}
            </div>    
    );
  }
}

export default ImageCarusel;