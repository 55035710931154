import React, { Component } from "react";
import classes from "./databaseProcessing.module.css"

import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';


let saving = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FSavingGif.png?alt=media&token=34dfb2f2-8c58-4ffc-8d8a-b92919286d13"

const today = new Date();
let start = today.getTime();

class LoadDatabase extends Component {
  state={firestorePrefix:this.props.environment}

  componentDidMount = () =>{
   this.loadSystemPreloadData();
   this.loadSystemCategories();
   if (this.props.loggedUser){
    this.loadMyFriends();
    this.loadUsersIFollow();
    this.loadUserPlans();
    this.loadFollowingUsers();
   }
   
   this.loadUserCountryPlacesNames();
   this.loadDestinationsRecommendations()
  }

  //Preload functions

  loadDestinationsRecommendations = async () =>{
    
    let recommendedDestinations={};
      await firebase.database()
      .ref(this.props.environment + "/systemData/recommendedDestinationsByCountry")
      .once("value")
      .then(snapshot => {
      if (snapshot.val()!==null){
          //covering every country
          recommendedDestinations=snapshot.val();
      }
      });
      this.props.updateLoadData(recommendedDestinations,"RecommendedDestinationsByCountry")
  }

  loadUserCountryPlacesNames = async () =>{
    if (this.props.userCountry!==undefined){
      let slimPlaces={};
      await firebase.database()
      .ref(this.props.environment + "/systemData/placeCreationData/placesListForPlaceCreation/"+this.props.userCountry)
      .once("value")
      .then(snapshot => {
      if (snapshot.val()!==null){
          //covering every country
          slimPlaces[this.props.userCountry]=Object.values(snapshot.val());
      }
      });
      this.props.updateLoadData(slimPlaces,"PlacesListForPlaceCreation")
    }

  }

  loadFollowingUsers = async () =>{
    let usersIDs = [];
    await firebase.database()
     .ref(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs")
     .once("value")
     .then(snapshot => {
       if (snapshot.val()!==null){
         //covering every country
         usersIDs=snapshot.val();
       }
     });
    this.props.updateLoadData([...usersIDs],"FollowingUsersIDs")
  }

  loadFollowExperiences = async () =>{
    //load experience feed including experiences of friends and users i follow
    let userExperiencesFeed = [];
    let userExperiencesFeedIDs=[];
    let usersIDs = [];
    await firebase.database()
     .ref(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs")
     .once("value")
     .then(snapshot => {
       if (snapshot.val()!==null){
         //covering every country
         usersIDs=snapshot.val();
       }
     });
    this.props.updateLoadData([...usersIDs],"FollowingUsersIDs")
    let firestore = firebase.firestore()
    const usersExperiences = firestore.collection(this.state.firestorePrefix+'usersExperiences');
    for (let i =0 ; i< usersIDs.length ; i++){
      const latestExperiences = await usersExperiences.doc(usersIDs[i]).collection('experiences').where('experiencePrivacy', 'in', ['friends', 'public','friendsFollowers']).orderBy('experienceLastModified', 'desc').limit(5).get();
      latestExperiences.forEach(doc => {
        if (!userExperiencesFeedIDs.includes(doc.data().experienceID)){
          userExperiencesFeedIDs.push(doc.data().experienceID)
          userExperiencesFeed.push(doc.data());
        }
        
      });
     
    }
    let currentUserExperiences = [];
    let experiencesIDs = userExperiencesFeed.map(exp=>{return exp.experienceID})
    const userLatestExperiences = await usersExperiences.doc(this.props.userID).collection('experiences').orderBy('experienceLastModified', 'desc').limit(5).get();
    userLatestExperiences.forEach(doc => {
      if (!experiencesIDs.includes(doc.data().experienceID)){
        currentUserExperiences.push(doc.data());
        experiencesIDs.push(doc.data().experienceID)
      }
     
    });
    userExperiencesFeed = userExperiencesFeed.concat(currentUserExperiences)
    let sortedUserExperiencesFeed=await userExperiencesFeed.sort((a, b) => {
      var keyA = a.experienceLastModified;
      var keyB = b.experienceLastModified;
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    let result = {
      list:sortedUserExperiencesFeed ,
      limit:5
    }
    this.props.updateLoadData(result,"UserExperiencesFeed")
   
  }

  loadRecommendedPlaces = async (userCategoriesScore,userDislikedCategories) =>{
    let topCategories = [];
    let systemTopCategories = [];
    let userTopCategories = [];
    let topCategoriesUpdated;
    let loadedPlacesIDs=[]
    let recommendedPlacesByCategory = [];
    let cacheRecommendationsDate;
    let recommendedPlacesByCategoryCache;
    //try to use the cache
    if (localStorage.getItem('recommendedPlacesByCategory')!==null){
      if (JSON.parse(localStorage.getItem('recommendedPlacesByCategory')).length>0){
        recommendedPlacesByCategoryCache =JSON.parse(localStorage.getItem('recommendedPlacesByCategory'));
        cacheRecommendationsDate=recommendedPlacesByCategoryCache.updated
        this.props.updateLoadData(Object.values(recommendedPlacesByCategoryCache),"RecommendedPlacesByCategory")
      }
     
    }
    if (userCategoriesScore!==undefined){
      let sortedCategories = await userCategoriesScore.sort((a, b) => {
        var keyA = a.score;
        var keyB = b.score;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      userTopCategories = sortedCategories.map(category=>{return category.id})
    }
    await firebase.database().ref(this.props.environment + "/systemData/topSystemCategories/"+this.props.userCountry)
      .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            systemTopCategories = snapshot.val().list;
            topCategoriesUpdated = snapshot.val().updated;
          }
        });
    if (topCategoriesUpdated!==undefined){
      if (topCategoriesUpdated>cacheRecommendationsDate||cacheRecommendationsDate===undefined){
        topCategories = [...new Set(userTopCategories.concat(systemTopCategories))];
        if (userDislikedCategories!==undefined){
          topCategories=topCategories.filter(id=>!userDislikedCategories.includes(id))
        }
        let loopLength = Math.min(5,topCategories.length)
        let i =0;
        let added = 0;
        while (added<loopLength&&i<topCategories.length){
          let category = {id:topCategories[i],places:[]}
          let categoryPlacesList = [];
          const categoryPlacesRef = firebase.firestore().collection(this.state.firestorePrefix+'systemPlaces').doc(this.props.userCountry).collection('countryPlaces').where('placeCategory', 'array-contains', topCategories[i]);
          let snapshot =  await categoryPlacesRef.orderBy('placeScore').limit(20).get();
          snapshot.forEach(doc => {
            if (!loadedPlacesIDs.includes(doc.data().placeID)){
              loadedPlacesIDs.push(doc.data().placeID)
              categoryPlacesList.push(doc.data());
            }
           
          });
          if (categoryPlacesList.length>5){
            category.places=categoryPlacesList.slice(0,10)
            recommendedPlacesByCategory.push(category)
            added++;
          }
          i++;
          
        }
        localStorage.setItem('recommendedPlacesByCategory',JSON.stringify(recommendedPlacesByCategory));
        this.props.updateLoadData(recommendedPlacesByCategory,"RecommendedPlacesByCategory")
      }
    }
  }

 
  loadRecommendedPlacesOld = async () =>{
    let recommendedPlacesByCategoryCache
    let recommendedPlacesByCategory = [];
    let cacheRecommendationsDate
    let topCategories = [];
    let topCategoriesUpdated;
    let loadedPlacesIDs=[]
    if (localStorage.getItem('recommendedPlacesByCategory')!==null){
      recommendedPlacesByCategoryCache =JSON.parse(localStorage.getItem('recommendedPlacesByCategory'));
      cacheRecommendationsDate=recommendedPlacesByCategoryCache.updated
      this.props.updateLoadData(Object.values(recommendedPlacesByCategoryCache),"RecommendedPlacesByCategory")
    }
    await firebase.database().ref(this.props.environment + "/systemData/topSystemCategories/"+this.props.userCountry)
    .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          topCategories = snapshot.val().list;
          topCategoriesUpdated = snapshot.val().updated;
        }
      });
      if (topCategoriesUpdated!==undefined){
        if (topCategoriesUpdated>cacheRecommendationsDate||cacheRecommendationsDate===undefined){
          let loopLength = Math.min(5,topCategories.length)
          for (let i = 0 ; i<loopLength ; i++){
            let category = {id:topCategories[i],places:[]}
            let categoryPlacesList = [];
            /* await firebase.database().ref(this.props.environment + "/systemData/placesSortedByCategory/"+this.props.userCountry+"/"+topCategories[i])
              .once("value")
                .then(snapshot => {
                  if (snapshot.val()!==null){
                  
                    //covering every country
                    recommendedPlacesByCategory.push(snapshot.val());
                  }
                }); */
              const categoryPlacesRef = firebase.firestore().collection(this.state.firestorePrefix+'systemPlaces').doc(this.props.userCountry).collection('countryPlaces').where('placeCategory', 'array-contains', topCategories[i]);
              let snapshot =  await categoryPlacesRef.orderBy('placeScore').limit(20).get();
              snapshot.forEach(doc => {
                if (!loadedPlacesIDs.includes(doc.data().placeID))
                loadedPlacesIDs.push(doc.data().placeID)
                categoryPlacesList.push(doc.data());
              });
              category.places=categoryPlacesList.slice(0,10)
              recommendedPlacesByCategory.push(category)
          }
          localStorage.setItem('recommendedPlacesByCategory',JSON.stringify(recommendedPlacesByCategory));
          this.props.updateLoadData(recommendedPlacesByCategory,"RecommendedPlacesByCategory")
        }
      }
     
    
  }



  loadUserRecommendedPlaces = async (userCategoriesScore) =>{
    //at this point use user categories interests and behavior
    let userRecommendedPlaces = []
    let loadedPlacesIDs = [];
    let sortedCategories  =await userCategoriesScore.sort((a, b) => {
      var keyA = a.score;
      var keyB = b.score;
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    
    let loopLength = Math.min(5,sortedCategories.length)
    for (let i = 0 ; i<loopLength ; i++){
      let category = {id:sortedCategories[i].id,places:[]}
      let categoryPlacesList = [];
      const categoryPlacesRef = firebase.firestore().collection(this.state.firestorePrefix+'systemPlaces').doc(this.props.userCountry).collection('countryPlaces').where('placeCategory', 'array-contains', sortedCategories[i].id);
      let snapshot =  await categoryPlacesRef.orderBy('placeScore').limit(20).get();
      snapshot.forEach(doc => {
        if (!loadedPlacesIDs.includes(doc.data().placeID))
        loadedPlacesIDs.push(doc.data().placeID)
        categoryPlacesList.push(doc.data());
      });
      category.places=categoryPlacesList.slice(0,10)
      userRecommendedPlaces.push(category)
    }
    localStorage.setItem('userRecommendedPlacesByCategory',JSON.stringify(userRecommendedPlaces));
    this.props.updateLoadData(userRecommendedPlaces,"UserRecommendedPlacesByCategory")
  }

  handleLocationsTreeTags = async locationsTree =>{
    let countriesToExplore = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesToExplore=snapshot.val();
      }
    });
    let countriesList=[];
    let discoverPlacesLocationsSearchTags =[];
    let tempLocationsTree =JSON.parse(JSON.stringify(locationsTree))
    countriesList= Object.keys(locationsTree);
    for (let i=0;i<countriesList.length ; i++){
        let country = tempLocationsTree[countriesList[i]];
        let countryObject=JSON.parse(JSON.stringify(country));
         delete countryObject.regions;
        delete countryObject.cities; 
        if (countriesToExplore.includes(countryObject.English)){
          discoverPlacesLocationsSearchTags.push(countryObject);
          let regionsList=[];
          if (country.regions!==undefined){
            regionsList = Object.keys(country.regions);
          }
          for (let j=0 ; j<regionsList.length ; j++){
            let region = country.regions[regionsList[j]];
            let regionObject=JSON.parse(JSON.stringify(region));
            //delete regionObject.type;
            delete regionObject.cities;
            discoverPlacesLocationsSearchTags.push(regionObject);
            let citiesList=[];
            if (region.cities!==undefined){
              citiesList = Object.keys(region.cities);
            }
            for (let k=0 ; k<citiesList.length ; k++){
              let city = region.cities[citiesList[k]];
              let cityObject=JSON.parse(JSON.stringify(city));
              //delete cityObject.type;
              discoverPlacesLocationsSearchTags.push(cityObject);
            }
          }
          let citiesList=[];
          if (country.cities!==undefined){
            citiesList = Object.keys(country.cities);
          }
          for (let j=0 ; j<citiesList.length ; j++){
            let city = country.cities[citiesList[j]];
            let cityObject=JSON.parse(JSON.stringify(city));
            //delete cityObject.type;
            discoverPlacesLocationsSearchTags.push(cityObject);
          }
        }
        
      } 

      discoverPlacesLocationsSearchTags=discoverPlacesLocationsSearchTags.map(tag => {let newTag = Object.assign({},tag); newTag.tagType="LocationFilter"; return newTag});

      this.props.updateLoadData(discoverPlacesLocationsSearchTags,"DiscoverPlacesLocationsSearchTags")
      this.props.updateLoadData(locationsTree, "SystemLocationsTree");
  }


  loadSystemCategories = async () =>{
    //these are used for creation of new places
     let placesCategories = [[],[],[],[],[],[]];
     let categoriesList = []
     await firebase.database()
     .ref(this.props.environment + "/systemData/systemCategories")
     .once("value")
     .then(snapshot => {
     
       snapshot.forEach(child => {
         if (child.key === "CulturalSightseeing") {
          placesCategories[0]=child.val();
          categoriesList=categoriesList.concat(Object.values(child.val()))
         }
         if (child.key === "Nature") {
           placesCategories[1]=child.val();
           categoriesList=categoriesList.concat(Object.values(child.val()))
         }
         if (child.key === "Activities") {
           placesCategories[2]=child.val();
           categoriesList=categoriesList.concat(Object.values(child.val()))
         }
         if (child.key === "Sport") {
           placesCategories[3]=child.val();
           categoriesList=categoriesList.concat(Object.values(child.val()))
         }   
         if (child.key === "FoodDrink") {
           placesCategories[4]=child.val();
           categoriesList=categoriesList.concat(Object.values(child.val()))
         }
         if (child.key === "Accommodation") {
           placesCategories[5]=child.val();
           categoriesList=categoriesList.concat(Object.values(child.val()))
         }      
       });
     });
     this.props.updateLoadData(placesCategories, "SystemCategories");
     this.props.updateLoadData(categoriesList, "AllCategoriesList");
   }

  loadSystemPreloadData = async () =>{
      let preloadData;
      await firebase.database()
      .ref(this.props.environment + "/systemPreloadData")
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          preloadData=snapshot.val();
        }
      }); 
      this.handleLocationsTreeTags(preloadData.locationsTreeTags)
     
      
      this.props.updateLoadData(preloadData.placesCharacteristicsData, "PlacesCharacteristicsData");

    

      localStorage.setItem('categoriesTranslator',JSON.stringify(preloadData.categoriesTranslator));
      await this.props.updateLoadData(preloadData.categoriesTranslator, "CategoriesTranslator");

      localStorage.setItem('locationsTranslator',JSON.stringify(preloadData.locationsTranslator));
      await this.props.updateLoadData(preloadData.locationsTranslator, "LocationsTranslator");

      let discoverTripsLocationSearchTags = [];
      let generateTripsDestinations = [];
      let publicTripsLocationSearchTags =[];
      if (preloadData.publicTripsLocationSearchTags!==undefined){
        publicTripsLocationSearchTags = preloadData.publicTripsLocationSearchTags
      }
      localStorage.setItem('publicTripsLocationSearchTags',JSON.stringify(publicTripsLocationSearchTags));

      let generateTripsDestinationApprovedTags =[];
      if (preloadData.generateTripsDestinationApprovedTags!==undefined){
        generateTripsDestinationApprovedTags = preloadData.generateTripsDestinationApprovedTags
      }
      localStorage.setItem('generateTripsDestinationApprovedTags',JSON.stringify(generateTripsDestinationApprovedTags));
      
      discoverTripsLocationSearchTags = publicTripsLocationSearchTags.map(treeID => {return preloadData.locationsTranslator[treeID]})
      generateTripsDestinations = generateTripsDestinationApprovedTags.map(treeID => {return preloadData.locationsTranslator[treeID]})

      await this.props.updateLoadData(discoverTripsLocationSearchTags, "DiscoverTripsLocationSearchTags");
      await this.props.updateLoadData(generateTripsDestinations, "GenerateTripsDestinations");
      if (preloadData.publicTripsAdditionalTagsSearchTags!==undefined){
        await this.props.updateLoadData(preloadData.publicTripsAdditionalTagsSearchTags, "DiscoverTripsAdditionalSearchTags");
      }

      await this.setState({preloadData:preloadData})
      await this.setState({categoriesTranslator:preloadData.categoriesTranslator})
      await this.setState({locationsTranslator:preloadData.locationsTranslator})
      this.finishLoading()
  }

  loadMoreExperiencesFeed = async (friendsIDs,limit,feed) =>{
    return new Promise(async (resolve, reject) => {
      let newExperiences = [];
      let currentFeed = feed
      let usersIDs = friendsIDs
      let currentFeedIDs = feed.map(exp=>{return exp.experienceID})
      let firestore = firebase.firestore()
      const usersExperiences = firestore.collection(this.state.firestorePrefix+'usersExperiences');
      for (let i =0 ; i< usersIDs.length ; i++){
        const latestExperiences = await usersExperiences.doc(usersIDs[i]).collection('experiences').where('experiencePrivacy', 'in', ['friends', 'public','friendsFollowers']).orderBy('experienceLastModified', 'desc').limit(limit).get();
        latestExperiences.forEach(doc => {
          if (!currentFeedIDs.includes(doc.data().experienceID)){
            currentFeedIDs.push(doc.data().experienceID)
            newExperiences.push(doc.data());
          }
         
        });
        
      }

      let currentUserNewExperiences = [];
      const userLatestExperiences = await usersExperiences.doc(this.props.userID).collection('experiences').orderBy('experienceLastModified', 'desc').limit(limit).get();
      userLatestExperiences.forEach(doc => {
        if (!currentFeedIDs.includes(doc.data().experienceID)){
          currentFeedIDs.push(doc.data().experienceID)
          currentUserNewExperiences.push(doc.data());
        }
        
      });
      newExperiences = newExperiences.concat(currentUserNewExperiences)
      let sortedNewExperiences=await newExperiences.sort((a, b) => {
        var keyA = a.experienceLastModified;
        var keyB = b.experienceLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      let loadedMoreExperiencesToFeed = newExperiences.length >0
      currentFeed = currentFeed.concat(sortedNewExperiences)
      let result = {
        list:currentFeed ,
        limit:limit,
        loadedMoreExperiencesToFeed:loadedMoreExperiencesToFeed
      }
      resolve(result);
    });
  }

  loadMoreExperiencesFeedForPlace = async (limit,feed,placeID) =>{
    return new Promise(async (resolve, reject) => {
      let newExperiences = [];
      let currentFeed = feed
      let currentFeedIDs = feed.map(exp=>{return exp.experienceID})
      let firestore = firebase.firestore()
      const latestExperiences = await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(placeID).collection('experiences').where('experiencePrivacy', '==', 'public').orderBy('experienceDate', 'desc').limit(limit).get();
      latestExperiences.forEach(doc => {
        if (!currentFeedIDs.includes(doc.data().experienceID))
        newExperiences.push(doc.data());
      });

      let sortedNewExperiences=await newExperiences.sort((a, b) => {
        var keyA = a.experienceLastModified;
        var keyB = b.experienceLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      let loadedMoreExperiencesToFeed = newExperiences.length >0
      currentFeed = currentFeed.concat(sortedNewExperiences)
      let result = {
        list:currentFeed ,
        limit:limit,
        loadedMoreExperiencesToFeed:loadedMoreExperiencesToFeed
      }
      resolve(result);
    });
  }

  loadMoreUserExperiences = async (limit,feed) =>{
    return new Promise(async (resolve, reject) => {
      let newExperiences = [];
      let currentFeed = feed
      let currentFeedIDs = feed.map(exp=>{return exp.experienceID})
      let firestore = firebase.firestore()
      const experiencesRef = firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences');
      let latestExperiences =  await experiencesRef.orderBy('experienceLastModified', 'desc').limit(limit).get();
      latestExperiences.forEach(doc => {
        if (!currentFeedIDs.includes(doc.data().experienceID))
        newExperiences.push(doc.data());
      });
      let sortedNewExperiences=await newExperiences.sort((a, b) => {
        var keyA = a.experienceLastModified;
        var keyB = b.experienceLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      let loadedMoreUserExperiences = newExperiences.length >0
      currentFeed = currentFeed.concat(sortedNewExperiences)
      let result = {
        list:currentFeed ,
        limit:limit,
        loadedMoreUserExperiences:loadedMoreUserExperiences
      }
      resolve(result);
    });
  }

  loadFriendsExperiencesForPlace = async (placeID,usersIDs) =>{
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
      let friendsExperiences = [];
      let firestore = firebase.firestore()
      const usersExperiences = firestore.collection(this.state.firestorePrefix+'usersExperiences');
      for (let i =0 ; i< usersIDs.length ; i++){
        const experiences = await usersExperiences.doc(usersIDs[i]).collection('experiences').where('experiencePlaceID', '==', placeID).where('experiencePrivacy', 'in', ['friends','friendsFollowers', 'public']).orderBy('experienceLastModified', 'desc').get();
        experiences.forEach(doc => {
          friendsExperiences.push(doc.data());
        });
        
      }
      let sortedFriendsExperiences=await friendsExperiences.sort((a, b) => {
        var keyA = a.experienceLastModified;
        var keyB = b.experienceLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      await this.setState({processing:false})
      resolve(sortedFriendsExperiences);
    });
  }

  loadMyExperiencesForPlace = async (placeID) =>{
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
      let myExperiences = [];
      let firestore = firebase.firestore()
      const experiences = await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences').where('experiencePlaceID', '==', placeID).orderBy('experienceLastModified', 'desc').get();
      experiences.forEach(doc => {
        myExperiences.push(doc.data());
      });
      let sortedMyExperiences=await myExperiences.sort((a, b) => {
        var keyA = a.experienceLastModified;
        var keyB = b.experienceLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      await this.setState({processing:false})
      resolve(sortedMyExperiences);
    });
  }

    //loading wall of clicked user
  loadSelectedUserContent = async (selectedUserID,status) =>{
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
      let userExperiences = [];
      let userPlans=[];
      let firestore = firebase.firestore()
      const usersExperiencesRef = firestore.collection(this.state.firestorePrefix+'usersExperiences');
      if (status==="Friend"){
        const experiences = await usersExperiencesRef.doc(selectedUserID).collection('experiences').where('experiencePrivacy', 'in', ['friends','friendsFollowers', 'public']).orderBy('experienceLastModified', 'desc').get();
        experiences.forEach(doc => {
          userExperiences.push(doc.data());
        });
      }
     else if (status==="Following"){
      const experiences = await usersExperiencesRef.doc(selectedUserID).collection('experiences').where('experiencePrivacy', 'in', ['friendsFollowers', 'public']).orderBy('experienceLastModified', 'desc').get();
      experiences.forEach(doc => {
        userExperiences.push(doc.data());
      });
     }
     else{
      const experiences = await usersExperiencesRef.doc(selectedUserID).collection('experiences').where('experiencePrivacy', 'in', [ 'public']).orderBy('experienceLastModified', 'desc').get();
      experiences.forEach(doc => {
        userExperiences.push(doc.data());
      });
     }
      let sortedUserExperiences=await userExperiences.sort((a, b) => {
        var keyA = a.experienceLastModified;
        var keyB = b.experienceLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      
      await firebase.database()
      .ref(
       this.props.environment+"/usersData/"+selectedUserID+"/userSlimPlans"
      )
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          snapshot.forEach(child => {
            let albumSecurity = ""
            if (child.val().albumSecurity!==undefined){
              if (child.val().albumSecurity[selectedUserID]!==undefined){
                albumSecurity = child.val().albumSecurity[selectedUserID]
              }
            }
            if (child.val().planSecurity==="public"||albumSecurity==="public"){
              userPlans.push(child.val())
            }
            else if((child.val().planSecurity==="friends"||albumSecurity==="friends")&&
            status==="Friend"){
              userPlans.push(child.val())
            }
            else if((child.val().planSecurity==="friendsFollowers"||albumSecurity==="friendsFollowers")&&
            (status==="Following"||status==="Friend")){
              userPlans.push(child.val())
            }
          })
        }
      });
  
      let sharedPlanDetails=[];
      let sharedPlans = [];
      await firebase.database()
        .ref(
          this.props.environment +
            "/usersData/" +
            selectedUserID +
            "/sharedPlans"
        )
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          sharedPlanDetails = Object.values(snapshot.val())
        }
      });
     
      for (let i=0 ; i<sharedPlanDetails.length ; i++){
        let userID = sharedPlanDetails[i].planOwnerID
        let planID=  sharedPlanDetails[i].planID
        await firebase.database()
        .ref(
          this.props.environment + "/usersData/" +userID + "/userSlimPlans/"+planID
        )
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            snapshot.forEach(child => {
              let albumSecurity = ""
              if (child.val().albumSecurity!==undefined){
                if (child.val().albumSecurity[selectedUserID]!==undefined){
                  albumSecurity = child.val().albumSecurity[selectedUserID]
                }
              }
              if (child.val().planSecurity==="public"||albumSecurity==="public"){
                sharedPlans.push(child.val())
              }
              else if((child.val().planSecurity==="friends"||albumSecurity==="friends")&&
              status==="Friend"){
                sharedPlans.push(child.val())
              }
              else if((child.val().planSecurity==="friendsFollowers"||albumSecurity==="friendsFollowers")&&
              status==="Following"){
                sharedPlans.push(child.val())
              }
            })
          }
        });
      }
      let allUserPlans = [...userPlans]
      allUserPlans=allUserPlans.concat(sharedPlans)
  
     let sortedAllUserPlans = await allUserPlans.sort((a, b) => {
        var keyA = a.planLastModified;
        var keyB = b.planLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      await this.setState({processing:false})
      resolve({experiences:sortedUserExperiences,trips:sortedAllUserPlans});
 });
  }
 
  loadCountryPlaces = async (countryName) =>{
    return new Promise(async (resolve, reject) => {
        await this.setState({processing:true})
        let slimPlaces;
        await firebase.database()
        .ref(this.props.environment + "/systemData/slimPlacesData/"+countryName)
        .once("value")
        .then(snapshot => {
        if (snapshot.val()!==null){
            //covering every country LOADING ONLY APPROVED PLACES
            slimPlaces=Object.values(snapshot.val()).filter(place=>place.placeStatus==="approved"||(place.owner==this.props.userID&&place.placeStatus==="potential"));
        }
        });
        resolve(slimPlaces);
        await this.setState({processing:false})
   });
  }

  loadPlacesForDestination = async (tag,loadEntireCountry) =>{
    return new Promise(async (resolve, reject) => {
      let countryName = tag.treeID.split('_')[0]
      let placesList = [];
      let cityRegionPlacesList = [];
      let geoPlacesIDs = []
      let cityPlacesIDs = []
      let regionPlacesIDs = []
      let region = ""
      let currentCountryLocationsTree;
      let cityUnderRegion = false
      if (tag.type==="region"||tag.type==="city"){
        let path = this.props.environment+"/systemData/systemLocationsTree/"+countryName
       
        await firebase.database()
        .ref(path)
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            currentCountryLocationsTree=snapshot.val();
        }
        });
        
      }
     
      if (tag.type==="region"){
        region = tag.treeID.split('_')[1]
        regionPlacesIDs = currentCountryLocationsTree.regions[region].placesIDs
      }

      if (tag.type==="city"){
        let countryRegions = Object.keys(currentCountryLocationsTree.regions)
        let regionIndex = -1
        for (let i=0 ; i <countryRegions.length ; i++){
          
          let regionCities = Object.keys(currentCountryLocationsTree.regions[countryRegions[i]].cities)
          if (regionCities.includes(tag.treeID.split('_')[1])){
            regionIndex = i;
            cityUnderRegion = true
          }
        }
        if (regionIndex!==-1){
          cityPlacesIDs = currentCountryLocationsTree.regions[countryRegions[regionIndex]].cities[tag.treeID.split('_')[1]].placesIDs
          regionPlacesIDs = currentCountryLocationsTree.regions[countryRegions[regionIndex]].placesIDs
        }
        if (currentCountryLocationsTree.cities!==undefined){
          let countryCities = Object.keys(currentCountryLocationsTree.cities)
          let cityIncluded = false
          if (countryCities.includes(tag.treeID.split('_')[1])){
            cityIncluded = true;
          }
          if (cityIncluded){
            cityPlacesIDs = cityPlacesIDs.concat(currentCountryLocationsTree.cities[tag.treeID.split('_')[1]].placesIDs)
          }
        }
        
      }

      if (tag.type==="region"){
        geoPlacesIDs = regionPlacesIDs
      }
      if (tag.type==="city"){
        if (cityUnderRegion&&cityPlacesIDs.length>0){
          let verySlimPlaces = []
          await firebase.database()
          .ref(this.props.environment + "/systemData/placeCreationData/placesListForPlaceCreation/"+countryName)
          .once("value")
          .then(snapshot => {
          if (snapshot.val()!==null){
              //covering every country LOADING ONLY APPROVED PLACES
              verySlimPlaces=Object.values(snapshot.val())
          }
          });
          let cityPlaces = []
          let regionPlaces = []
          let cityBoundsNorth
          let cityBoundsSouth
          let cityBoundsWest
          let cityBoundsEast
          for (let i=0 ; i<verySlimPlaces.length ; i++){
            if (cityPlacesIDs.includes(verySlimPlaces[i].placeID)){
              cityPlaces.push(verySlimPlaces[i])
              if (cityBoundsNorth===undefined){
                cityBoundsNorth = verySlimPlaces[i].placeLocation.coordinates.lat
                cityBoundsSouth = verySlimPlaces[i].placeLocation.coordinates.lat
                cityBoundsWest = verySlimPlaces[i].placeLocation.coordinates.lng
                cityBoundsEast = verySlimPlaces[i].placeLocation.coordinates.lng
              }
              else{
                if (verySlimPlaces[i].placeLocation.coordinates.lat>cityBoundsNorth){
                  cityBoundsNorth = verySlimPlaces[i].placeLocation.coordinates.lat
                }
                if (verySlimPlaces[i].placeLocation.coordinates.lat<cityBoundsSouth){
                  cityBoundsSouth = verySlimPlaces[i].placeLocation.coordinates.lat
                }
                if (verySlimPlaces[i].placeLocation.coordinates.lng<cityBoundsWest){
                  cityBoundsWest = verySlimPlaces[i].placeLocation.coordinates.lng
                }
                if (verySlimPlaces[i].placeLocation.coordinates.lng>cityBoundsEast){
                  cityBoundsEast = verySlimPlaces[i].placeLocation.coordinates.lng
                }
              }
            }
            else if (regionPlacesIDs.includes(verySlimPlaces[i].placeID)){
              regionPlaces.push(verySlimPlaces[i])
            }
          }
          //now find if any places in region are in city(area) boundaries
          for (let i =0 ;i<regionPlaces.length ; i++){
            if (regionPlaces[i].placeLocation.coordinates.lat<cityBoundsNorth&&
              regionPlaces[i].placeLocation.coordinates.lat>cityBoundsSouth&&
              regionPlaces[i].placeLocation.coordinates.lat>cityBoundsWest&&
              regionPlaces[i].placeLocation.coordinates.lat<cityBoundsEast){
                cityPlaces.push(regionPlaces[i])
              
              }
          }
          geoPlacesIDs = cityPlaces.map(place=>{return place.placeID})
        }
        else{
          geoPlacesIDs = cityPlacesIDs
        }
        
      }
     let entireCountryPlaces = []
      await firebase.database()
        .ref(this.props.environment + "/systemData/slimPlacesData/"+countryName)
        .once("value")
        .then(snapshot => {
        if (snapshot.val()!==null){
            //covering every country LOADING ONLY APPROVED PLACES
            if (tag.type!=="country"&&loadEntireCountry){
              entireCountryPlaces = Object.values(snapshot.val()).filter(place=>place.placeStatus==="approved"||(place.owner==this.props.userID&&place.placeStatus==="potential"));
            }
            if (tag.type==="country"){
              placesList=Object.values(snapshot.val()).filter(place=>place.placeStatus==="approved"||(place.owner==this.props.userID&&place.placeStatus==="potential"));
            }
            else{
              snapshot.forEach(child => {
                if (geoPlacesIDs.includes(child.val().placeID)&&child.val().placeStatus==="approved"||(child.val().owner==this.props.userID&&child.val().placeStatus==="potential")){
                  placesList.push(child.val());
                }
              });
            }
            if (tag.type==="city"&&cityUnderRegion){
              snapshot.forEach(child => {
                if (regionPlacesIDs.includes(child.val().placeID)&&child.val().placeStatus==="approved"||(child.val().owner==this.props.userID&&child.val().placeStatus==="potential")){
                  cityRegionPlacesList.push(child.val());
                }
              });
              
            }
            
        }
        });
      let result = {placesList: placesList, cityRegionPlacesList:cityRegionPlacesList}
      if (tag.type!=="country"){
        result.entireCountryPlaces = entireCountryPlaces
      }
      resolve(result);
    });
  }

  getPlacesListForPlaceCreationValidation = async (countryName) =>{
    return new Promise(async (resolve, reject) => {
        await this.setState({processing:true})
        let slimPlaces;
        await firebase.database()
        .ref(this.props.environment + "/systemData/placeCreationData/placesListForPlaceCreation/"+countryName)
        .once("value")
        .then(snapshot => {
        if (snapshot.val()!==null){
            //covering every country
            slimPlaces=Object.values(snapshot.val());
        }
        });
        resolve(slimPlaces);
        await this.setState({processing:false})
   });
  }




  getUserLatestViewedPlaces = async userLastViewedPlacesIDs =>{
    let placesIDs = []
    let latestViewedPlaces =[];
    if (userLastViewedPlacesIDs.length === 0){
      await firebase.database()
      .ref(
        this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics/latestViewedPlaces"
      )
      .once("value")
      .then(snapshot => {
        snapshot.forEach(child => {
          placesIDs.push(child.val());
        
        });
      });
    }
   else{
    placesIDs = userLastViewedPlacesIDs
   }
    for (let i=0 ; i<placesIDs.length ; i++){
      let country="Israel";
      let placeID;
      if (placesIDs[i].placeID!==undefined){
        placeID = placesIDs[i].placeID;
        if (placesIDs[i].country.treeID!==undefined){
          country = placesIDs[i].country.treeID;
        }
        else{
          country = placesIDs[i].country;
        }
        
      }
      else{
        placeID = placesIDs[i]
      }
      await firebase.database()
      .ref(this.props.environment + "/systemData/slimPlacesData/"+country+"/"+placeID)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          latestViewedPlaces.push(snapshot.val())
        }
      });
    }
    this.props.updateLoadData(latestViewedPlaces, "LatestViewedPlaces");
  }

  getFavoritePlaceTags = async place =>{
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
      let placeCountry = ""
      if (place.placeStatus==="approved") {
        placeCountry = place.placeLocation.locationTree.placeCountry
      }
      else{
        placeCountry = place.placeLocation.locationTree.placeCountry.treeID
      }
      let favoriteSearchTags = [];
      await firebase.database()
        .ref(
          this.props.environment +
            "/usersData/" +
            this.props.userID +
            "/favorite/placesByCountry/"+placeCountry+"/"+place.placeID+"/favoriteSearchTags"
        )
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            favoriteSearchTags=Object.values(snapshot.val())
          }
        });
        
        resolve(favoriteSearchTags);
        await this.setState({processing:false})
  });
  }

  getFavoritePlaces = () =>{
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
      let userFavoritePlaces={}
      let countriesList = [];
      //favorite places are also sorted by country to easily get the places list
      await firebase.database()
        .ref(
          this.props.environment +
            "/usersData/" +
            this.props.userID +
            "/favorite/placesByCountry"
        )
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            countriesList=Object.keys(snapshot.val());
            userFavoritePlaces=snapshot.val();
          }
        });

    let favoritePlaces = []

    for (let i=0 ; i<countriesList.length ; i++){
      let IDs = Object.values(userFavoritePlaces[countriesList[i]])
      await firebase.database()
      .ref(this.props.environment + "/systemData/slimPlacesData/"+countriesList[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
        //covering every country
        snapshot.forEach(child => {
          const favoriteIndex = IDs.findIndex(favPlace => {
            return favPlace.placeID === child.key
          });
          if (favoriteIndex!==-1){
            let favoritePlace = child.val();
            if (IDs[favoriteIndex].favoriteSearchTags!==undefined){
              favoritePlace.favoriteSearchTags=IDs[favoriteIndex].favoriteSearchTags
            }
            favoritePlaces.unshift(favoritePlace);
          }
        });
      } 
    });
    }
    resolve(favoritePlaces);
    await this.setState({processing:false})
  });
  }

  getFavoriteTripPlans = () =>{
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
      let favoriteTripsIDs = [];
      //favorite places are also sorted by country to easily get the places list
      await firebase.database()
        .ref(
          this.props.environment +
            "/usersData/" +
            this.props.userID +
            "/favorite/trips"
        )
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            favoriteTripsIDs=Object.values(snapshot.val());
          }
        });

      let favoriteTrips=[]
      let usersIDs = []
      let usersTripsIds = {}
      for (let i=0 ; i<favoriteTripsIDs.length ; i++){
        if (usersIDs.includes(favoriteTripsIDs[i].planID.split('_')[1])){
          let userTripIds = usersTripsIds[favoriteTripsIDs[i].planID.split('_')[1]]
          userTripIds.push(favoriteTripsIDs[i].planID)
          usersTripsIds[favoriteTripsIDs[i].planID.split('_')[1]] = userTripIds
          
        }
        else{
          let userTripIds = [favoriteTripsIDs[i].planID]
          usersTripsIds[favoriteTripsIDs[i].planID.split('_')[1]] = userTripIds
          usersIDs.push(favoriteTripsIDs[i].planID.split('_')[1])
        }
      }
      for (let i=0 ; i<usersIDs.length ; i++){
        let tripIDs = usersTripsIds[usersIDs[i]]
        await firebase.database()
        .ref(this.props.environment+"/usersData/"+usersIDs[i]+"/userSlimPlans")
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
          //covering every country
          snapshot.forEach(child => {
            const favoriteIndex = favoriteTripsIDs.findIndex(favTrip => {
              return favTrip.planID === child.key
            });
            if (favoriteIndex!==-1){
              let favoriteTrip = child.val();
              if (favoriteTripsIDs[favoriteIndex].favoriteSearchTags!==undefined){
                favoriteTrip.favoriteSearchTags=favoriteTripsIDs[favoriteIndex].favoriteSearchTags
              }
              favoriteTrips.unshift(favoriteTrip);
            }
          });
        } 
      });
    }
  
    resolve(favoriteTrips);
    await this.setState({processing:false})
  });
  }

  getUserCreatedPlaces = async () =>{
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
    let userCreatedPlaces={}
    let userCreatedPlacesList = []
    let userSystemPlaces = []
    let countriesList = [];
    await firebase.database()
      .ref(
        this.props.environment +
          "/usersData/" +
          this.props.userID +
          "/userInfo/statistics/createdPlaces"
      )
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          countriesList=Object.keys(snapshot.val());
          userCreatedPlaces=snapshot.val();
        }
      });
      for (let i=0; i<countriesList.length ; i++){
        let placesList = userCreatedPlaces[countriesList[i]].list
        userCreatedPlacesList=userCreatedPlacesList.concat(placesList)
      }
      let sortedUserCreatedPlacesList=await userCreatedPlacesList.sort((a, b) => {
        var keyA = a.placeCreationDate;
        var keyB = b.placeCreationDate;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      for (let i=0 ; i<countriesList.length ; i++){
        await firebase.database()
        .ref(this.props.environment + "/systemData/slimPlacesData/"+countriesList[i])
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
          //covering every country
          snapshot.forEach(child => {
            if (sortedUserCreatedPlacesList.includes(child.val().placeID)){
              userSystemPlaces.unshift(child.val());
            }
          });
        } 
      });
      }
  
     
      let result=userSystemPlaces
      resolve(result);
      await this.setState({processing:false})
    });

  }

  loadUserExperiences = async () =>{
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
      let userExperiences = [];
      const experiencesRef = firebase.firestore().collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences');
      let snapshot =  await experiencesRef.orderBy('experienceLastModified', 'desc').limit(5).get();
      snapshot.forEach(doc => {
        userExperiences.push(doc.data());
      });
      let result={list:userExperiences,limit:5}
      resolve(result);
      await this.setState({processing:false})
    });
  }


  

  loadFavoritePlacesIDs = async() =>{
    let userFavoritePlaces={}
    let favoritePlacesIDs = [];
    let countriesList = [];
    //favorite places are also sorted by country to easily get the places list
    await firebase.database()
      .ref(
        this.props.environment +
          "/usersData/" +
          this.props.userID +
          "/favorite/placesByCountry"
      )
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          countriesList=Object.keys(snapshot.val());
          userFavoritePlaces=snapshot.val();
        }
      });
      for (let i=0; i<countriesList.length ; i++){
        let placesList = Object.values(userFavoritePlaces[countriesList[i]])
        favoritePlacesIDs=favoritePlacesIDs.concat(placesList)
      }
      let sortedFavoritePlacesID=await favoritePlacesIDs.sort((a, b) => {
        var keyA = a.placeLastModified;
        var keyB = b.placeLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      sortedFavoritePlacesID=sortedFavoritePlacesID.map(place=>{return place.placeID})
      this.props.updateLoadData(sortedFavoritePlacesID,"FavoritePlacesIDs")
  }

  loadFavoriteTripsIDs = async() =>{
    let favoriteTripsIDs = [];
    //favorite places are also sorted by country to easily get the places list
    await firebase.database()
      .ref(
        this.props.environment +
          "/usersData/" +
          this.props.userID +
          "/favorite/trips"
      )
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          favoriteTripsIDs=Object.keys(snapshot.val());
        }
      });
      let sortedFavoriteTripsIDs=await favoriteTripsIDs.sort((a, b) => {
        var keyA = a.planLastModified;
        var keyB = b.planLastModified;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      //sortedFavoriteTripsIDs=sortedFavoriteTripsIDs.map(trip=>{return trip.planID})
      this.props.updateLoadData(sortedFavoriteTripsIDs,"FavoriteTripsIDs")
  }

  getExistingPlaceData = async (country,placeID) =>{
   
    return new Promise(async (resolve, reject) => {
      await this.setState({processing:true})
      let placePath = this.props.environment + "/systemData/slimPlacesData/"+country+"/"+placeID;
      let systemPlace;
      await firebase.database()
      .ref( placePath)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          systemPlace = snapshot.val()
        }
      });
      resolve(systemPlace);
      await this.setState({processing:false})
    }); 
  }

  loadUserPlans = async () =>{
    let userPlans = [];
    await firebase.database()
    .ref(
     this.props.environment+"/usersData/"+this.props.userID+"/userSlimPlans"
    )
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        userPlans = Object.values(snapshot.val())
      }
    });

    let sharedPlanDetails=[];
    let sharedPlans = [];
    await firebase.database()
      .ref(
        this.props.environment +
          "/usersData/" +
          this.props.userID +
          "/sharedPlans"
      )
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        sharedPlanDetails = Object.values(snapshot.val())
      }
    });
   
    for (let i=0 ; i<sharedPlanDetails.length ; i++){
      let userID = sharedPlanDetails[i].planOwnerID
      let planID=  sharedPlanDetails[i].planID
      await firebase.database()
      .ref(
        this.props.environment + "/usersData/" +userID + "/userSlimPlans/"+planID
      )
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          sharedPlans.push(snapshot.val())
        }
      });
    }
    let allUserPlans = [...userPlans]
    allUserPlans=allUserPlans.concat(sharedPlans)

   let sortedAllUserPlans = await allUserPlans.sort((a, b) => {
      var keyA = a.planLastModified;
      var keyB = b.planLastModified;
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    this.props.updateLoadData(sortedAllUserPlans, "UserPlans");
  }

  getAllUsers = async () =>{
    await this.setState({processing:true})
    //load all system users
    let allUsers=[];
    await firebase.database()
    .ref( this.props.environment+"/users")
    .once("value")
    .then(snapshot => {
      snapshot.forEach(child => {   
        if (child.val().displayName!==undefined){
          allUsers.push(child.val());
        }  
          
      });
    });
    this.props.updateLoadData(allUsers, "AllUsers");
    await this.setState({processing:false})
  }

  loadMyFriends = async () =>{
    //load my friends and requests/pending
    let myFriends = [];
    let myFriendsIDs = [];
    await firebase.database()
    .ref(
      this.props.environment +
        "/usersData/" +
        this.props.userID +
        "/userInfo/friends"
    ).once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        myFriendsIDs = Object.values(snapshot.val()).map(user=>{return user.userID})
        myFriends = snapshot.val()
      }
    });
    //now get these users info
    for (let i=0 ; i<myFriendsIDs.length ; i++){
      await firebase.database()
      .ref( this.props.environment+"/users/"+myFriendsIDs[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          myFriends[myFriendsIDs[i]].displayName=snapshot.val().displayName
          myFriends[myFriendsIDs[i]].photoUrl=snapshot.val().photoUrl
          myFriends[myFriendsIDs[i]].userEmail=snapshot.val().userEmail
        }
      });
    }
    let myFriendsArray = Object.values(myFriends)
    let myFriendsSorted = myFriendsArray.sort((a, b) => {
      var keyA = a.displayName;
      var keyB = b.displayName;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    for (let i=0;i<myFriendsSorted.length;i++){
      let user = myFriendsSorted[i];
      if (user.status==="New Request"){
        myFriendsSorted.splice(i,1);
        myFriendsSorted.unshift(user);
      }
    }

    this.props.updateLoadData(myFriendsSorted, "MyFriends");  
  }

  loadUsersIFollow = async () =>{
    //load my friends and requests/pending
    let usersIFollow = [];
    await firebase.database()
    .ref(
      this.props.environment +
        "/usersData/" +
        this.props.userID +
        "/userInfo/following"
    ).once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        usersIFollow = Object.values(snapshot.val())
      }
    });

    let usersIFollowSorted = usersIFollow.sort((a, b) => {
      var keyA = a.displayName;
      var keyB = b.displayName;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    this.props.updateLoadData(usersIFollowSorted, "UserIFollow");  
    
  }
  ////////////////////

  //--------ADMIN FUNCTIONS-------
  getSystemMetrics = async () =>{
    return new Promise(async (resolve, reject) => {
    let usersActivity;
    let activitiesLogDates = [];
    
    await firebase.database()
    .ref(this.props.environment +  "/systemMetrics/usersActivity")
    .on("value",(snapshot)=>{
      if (snapshot.val()!==null){
        usersActivity=snapshot.val();
        this.props.updateLoadData(usersActivity, "UsersActivity");  
      }
    })
     await firebase.database()
    .ref(this.props.environment +  "/systemMetrics/activitiesLog")
    .on("value",(snapshot)=>{
      if (snapshot.val()!==null){
        activitiesLogDates=Object.keys(snapshot.val());
       this.props.updateLoadData(activitiesLogDates, "ActivitiesLog");  
      }
    }) 
    
      //handle loading places need to be approved, and places with edit suggestion
      //(perhaps when saving a place while it is potential need to have it in another place)
      this.loadEditSuggestions()
      this.loadPotentialPlaces();
      this.loadUsersRequests();
      this.loadPotentialTripPlans()
    });
      
   }

   loadPotentialTripPlans = async () =>{
     let potentialPlans = [];
    const tripPlans = await firebase.firestore().collection(this.state.firestorePrefix+'publicTripPlans').where('approvedPlan', '==', false).orderBy('planLastModified', 'desc').get();
    tripPlans.forEach(doc => {
      potentialPlans.push(doc.data());
    });
    this.props.updateLoadData(potentialPlans, "PotentialTripPlans");  
   }

   discoverPublicPlans = async (args,limit) =>{
    return new Promise(async (resolve, reject) => {
    let publicTripPlans = [];
    let publicTripPlansByLocation =[];
    let publicTripPlansByLocationTemp = []
    let publicTripPlansByLocationIDs = [];
    let publicTripPlansByAdditionalTags = [];
    let tripPlans = await firebase.firestore().collection(this.state.firestorePrefix+'publicTripPlans').where('planDuration', '>=', args.tripDuration[0]).where('planDuration', '<=', args.tripDuration[1]).where('approvedPlan', '==', true)
    let tripPlansByLocation;
    let tripPlansByAdditionalTags
   
    if (args.locationFilters.length>0){
      if (args.includeAllLocations){
        //try to get from db more trips because they will be filtered
        let newLimit = 2*limit
        for (let i=0 ; i<args.locationFilters.length ; i++){
          tripPlansByLocation=await tripPlans.where('tripLocationTags', 'array-contains', args.locationFilters[i])
          const tripPlansByLocationSnapshot=await tripPlansByLocation.orderBy('planDuration', 'desc').orderBy('planLastModified', 'desc').limit(newLimit).get();
          tripPlansByLocationSnapshot.forEach(doc => {
            if (!publicTripPlansByLocationIDs.includes(doc.data().planID)){
              publicTripPlansByLocationTemp.push(doc.data());
              publicTripPlansByLocationIDs.push(doc.data().planID);
            }
           
          });
        }
        for (let i = 0 ;i<publicTripPlansByLocationTemp.length ; i++){
          let include = true
          for (let j=0 ; j<args.locationFilters.length ; j++){
            if (!publicTripPlansByLocationTemp[i].tripLocationTags.includes(args.locationFilters[j])){
              include = false
            }
          }
          if (include){
            publicTripPlansByLocation.push(publicTripPlansByLocationTemp[i])
          }
        }
        //no leave only the trips which have all the location tags
      }
      else{
        tripPlansByLocation=await tripPlans.where('tripLocationTags', 'array-contains-any', args.locationFilters)
        const tripPlansByLocationSnapshot=await tripPlansByLocation.orderBy('planDuration').orderBy('planLastModified', 'desc').limit(limit).get();
        tripPlansByLocationSnapshot.forEach(doc => {
          publicTripPlansByLocation.push(doc.data());
        });
      }
      
    }
    if (args.additionalTags.length>0){
      let newLimit = limit
      if (args.locationFilters.length>0){
        newLimit = 3*limit;
      }
      tripPlansByAdditionalTags=await tripPlans.where('publicPlanTags', 'array-contains-any', args.additionalTags)
        const tripPlansByAdditionalTagsSnapshot=await tripPlansByAdditionalTags.orderBy('planDuration').orderBy('planLastModified', 'desc').limit(newLimit).get();
        tripPlansByAdditionalTagsSnapshot.forEach(doc => {
          publicTripPlansByAdditionalTags.push(doc.data());
        });
     

      if (args.locationFilters.length>0){
        let tripsIDsFilteredByTag = publicTripPlansByAdditionalTags.map(trip=>{return trip.planID})
        for (let i = 0 ;i<publicTripPlansByLocation.length ; i++){
          if (tripsIDsFilteredByTag.includes(publicTripPlansByLocation[i].planID)){
            publicTripPlans.push(publicTripPlansByLocation[i])
          }
        }
      }
      else{
        publicTripPlans = publicTripPlansByAdditionalTags
      }
      
    }
    else{
      publicTripPlans = publicTripPlansByLocation
    }
   if (args.locationFilters.length === 0 && args.additionalTags.length===0){
    const tripPlansSnapshot=await tripPlans.orderBy('planDuration', 'desc').orderBy('planLastModified', 'desc').limit(limit).get();
    tripPlansSnapshot.forEach(doc => {
      publicTripPlans.push(doc.data());
    });
   }
    resolve({tripsList:publicTripPlans,loadMoreTrips:!(publicTripPlans.length<limit)})
    });
  }

  discoverPublicPlansTemp = async (args,limit) =>{
    return new Promise(async (resolve, reject) => {
    let publicTripPlans = [];
    let publicTags = ["שביל ישראל","a","b","c","d","e","f","g"]
    let tripPlans = await firebase.firestore().collection(this.state.firestorePrefix+'publicTripPlans').where('planDuration', '>=', args.tripDuration[0]).where('planDuration', '<=', args.tripDuration[1]).where('approvedPlan', '==', true)

   let searchTags = [];

    if (args.locationFilters.length>0){
      searchTags = searchTags.concat(args.locationFilters)
      //tripPlans=await tripPlans.where('tripLocationTags', 'array-contains-any', args.locationFilters)
    }
    else if (args.countriesFilters.length>0){
      searchTags = searchTags.concat(args.countriesFilters)
      //tripPlans= await tripPlans.where('tripCountries', 'array-contains-any', args.countriesFilters)
    }
    if (publicTags.length>0){
      searchTags = searchTags.concat(publicTags)
      //tripPlans= await tripPlans.where('planPublicTags', 'array-contains-any', publicTags)
    }
    if (searchTags.length>0){
      tripPlans= await tripPlans.where('planSearchTags', 'array-contains-any', searchTags)
    }
    if (args.tripParticipants.includes("Kids")){
      tripPlans= await tripPlans.where('participants', 'array-contains', "Kids")
    }
    if (args.tripParticipants.includes("Toddlers")){
      tripPlans= await tripPlans.where('participants', 'array-contains', "Toddlers")
    }
    const tripPlansSnapshot=await tripPlans.orderBy('planDuration', 'desc').orderBy('planLastModified', 'desc').limit(limit).get();
    tripPlansSnapshot.forEach(doc => {
      publicTripPlans.push(doc.data());
    });
    resolve({tripsList:publicTripPlans,loadMoreTrips:!(publicTripPlans.length<limit)})
    });
  }

   loadUsersRequests = async () =>{
    let usersRequests;

    await firebase.database()
    .ref(this.props.environment +  "/systemData/usersContactRequests/newRequests")
    .once("value")
    .then(snapshot =>{
      if (snapshot.val()!==null){
        //covering every country
        usersRequests=Object.keys(snapshot.val());
      }
    });
    this.props.updateLoadData(usersRequests, "UsersRequests");  
  }

   loadPotentialPlaces = async () =>{
    let potentialPlaces = [];
    let countriesList = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesList=snapshot.val();
      }
    });
    for (let i = 0 ; i<countriesList.length ; i++){
        await firebase.database()
        .ref(this.props.environment + "/systemData/slimPlacesData/"+countriesList[i])
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            snapshot.forEach(child => {
              if (child.val().placeStatus==="potential"){
                potentialPlaces.unshift(child.val());
              }
             
            });
            //covering every country
          }
        });
     }
     this.props.updateLoadData(potentialPlaces, "PotentialPlacesToApprove");  
   }

   
   loadEditSuggestions = async () =>{
    let countriesList = [];
    let editSuggestions = [];
    await firebase.database()
      .ref(this.props.environment + "/systemData/places")
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          countriesList=Object.keys(snapshot.val());
        }
        
      });
    for (let i=0; i<countriesList.length ; i++){
      await firebase.database()
      .ref(this.props.environment + "/systemData/placesEditSuggestions/"+countriesList[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          snapshot.forEach(child => {
            let place=child.val();
            editSuggestions.unshift(place);
          });
        }
        
      });
    }
    this.props.updateLoadData(editSuggestions, "PlacesEditSuggestions");
   }

   

  finishLoading = () => {
      if (this.state.preloadData !== undefined&&
        this.state.categoriesTranslator !== undefined &&
        this.state.locationsTranslator !== undefined)  { 
          this.props.finish();
      }  
    }

renderProcessing = () => {
    return (
      <div className={classes.Saving}>
        <img src={saving} alt="" className={classes.SavingGif} />
      </div>
    );
};

render(){
  if (this.state.processing){
    return <div className={classes.Body}>{this.renderProcessing()}</div>;
  }
  else{
    return <div></div>;
  }
}
}
export default LoadDatabase;

