import React, { Component } from "react";
import classes from "./TripAlbum.module.css";

import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import ModalTopSection from "../../../Components/UI/Misc/ModalTopSection"

import {isMobile, isMobileOnly} from 'react-device-detect';
import { Editor, EditorState , convertFromRaw, convertToRaw} from 'draft-js';
import PlanJourneyDay from "../../../Components/Complex/Plan/PlanJourneyDay";
import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop";
import Backdrop from "../../../Components/UI/Backdrop/Backdrop";
import TripAlbumDay from "../../../Components/Complex/Plan/Album/TripAlbumDay";
import TripPlaceAlbum from "../../../Components/Complex/Plan/Album/TripPlaceAlbum";
import TripAlbumImage from "../../../Components/Complex/Plan/Album/TripAlbumImage"
import AlbumPhotoViewer from "../../../Components/Complex/Plan/Album/AlbumPhotoViewer"
import ExperiencesWall from "../Experiences/ExperiencesWall"

let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowLeft.png?alt=media&token=8020b73f-93be-478c-a1c3-c3cee641fbe8"
let arrowIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowRight.png?alt=media&token=e0fca8aa-a34f-47cf-80d3-79b36b305e88"


let placeIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"

const SortableItem = sortableElement(({index, value}) =>
{ 
  return(<img className={classes.MovingImage} alt="" src={value}/>)});

const SortableContainer = sortableContainer(({children}) => {
  return <div  className={classes.ImagesSection}>{children}</div>;
});


class TripAlbum extends Component {
    state={
        daysNames: [],
        selectedDayIndex: 0,
        albumRef:React.createRef(),
        albumNavigation: "AlbumDays",
        planExperiences: [],
        allPhotosList: []
    }

    componentDidMount = () =>{
        let daysNames = [];
        for (let i=0 ; i<this.props.planDays.length ; i++){
            daysNames.push(this.props.planDays[i].name)
        }
        this.setState({daysNames:daysNames})

        let daysWithPlaces = [];
        for (let i=0 ; i<this.props.planDays.length ; i++){
            let hasPlace = false
            if (this.props.planDays[i].dayItems!==undefined){
                for (let j=0 ; j<this.props.planDays[i].dayItems.length; j++){
                    if (this.props.planDays[i].dayItems[j].type==="place"){
                        hasPlace = true
                    }
                }
            }
            daysWithPlaces.push(hasPlace)
        }
        this.setState({daysWithPlaces:daysWithPlaces})
        
        var scrollComponent = this;
        var frame =  this.state.albumRef.current
        frame.addEventListener("scroll", function(e) {
          scrollComponent.albumScrollToggle();
        });      
        frame.scrollTo({
            left:0,
            behavior: "smooth"
          });  
        if (frame.scrollWidth>frame.offsetWidth){
            this.setState({showForwardIcon:true})
        }
        else {
            this.setState({showForwardIcon:false})
        }
        let planExperiences = []
        let experiencesIDs = []
        for (let i=0 ; i<this.props.planExperiences.length; i++){
            if (!experiencesIDs.includes(this.props.planExperiences[i].experienceID)&&
            this.props.planExperiences[i].owner.ownerID===this.props.user.userID){
                planExperiences.push(this.props.planExperiences[i])
                experiencesIDs.push(this.props.planExperiences[i].experienceID)
            }
        }
        this.setState({planExperiences:planExperiences})
        this.updateAllPhotosList()
    }

    componentDidUpdate = prevProps =>{
        if (JSON.stringify(this.props.planExperiences)!==JSON.stringify(prevProps.planExperiences)){
            let planExperiences = []
            let experiencesIDs = []
            for (let i=0 ; i<this.props.planExperiences.length; i++){
                if (!experiencesIDs.includes(this.props.planExperiences[i].experienceID)&&
                this.props.planExperiences[i].owner.ownerID===this.props.user.userID){
                    planExperiences.push(this.props.planExperiences[i])
                    experiencesIDs.push(this.props.planExperiences[i].experienceID)
                }
            }
            this.setState({planExperiences:planExperiences})
            this.updateAllPhotosList()
        }
    }

    updateAllPhotosList = () =>{
        let allPhotosList = []
        for (let dayIndex=0 ; dayIndex<this.props.planDays.length ; dayIndex ++){
            if (this.props.planDays[dayIndex].dayItems!==undefined){
                for (let itemIndex=0 ; itemIndex<this.props.planDays[dayIndex].dayItems.length ; itemIndex++ ){
                    if ( this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences!==undefined){
                        if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[this.props.user.userID]!==undefined){
                            if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[this.props.user.userID].userJourneyImages!==undefined){
                                console.log(this.props.planDays[dayIndex].dayItems[itemIndex].item)
                                allPhotosList = allPhotosList.concat(this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[this.props.user.userID].userJourneyImages)
                            }
                        }
                    }
                }
            }
           
        }
        let images = []
        if (isMobileOnly){
            images = allPhotosList.map(img => {return img.thumbnailImage.url})
        }
        else{
            images = allPhotosList.map(img => {return img.smallImage.url})
        }
        this.setState({allPhotosList:allPhotosList, imagesPreview:images})
    }

    albumScrollToggle = () =>{
        var frame =  this.state.albumRef.current
        if (frame===null || this.state.preventScroll) return
        this.setState({lastScroll: new Date()})
            setTimeout(e => {
              if (this.state.lastScroll.getTime() < (new Date()).getTime() - 200) {
                
                this.setState({albumScroll:frame.scrollLeft})
              }        
            }, 200); 
        let scrollLeft = Math.abs(frame.scrollLeft)
        if (scrollLeft>=(frame.scrollWidth-frame.offsetWidth-10)){
            this.setState({showForwardIcon:false})
        }
        else if(frame.scrollWidth>frame.offsetWidth){
            this.setState({showForwardIcon:true})
        }
        this.setState({imageScrollLeft:scrollLeft})
       
        if (scrollLeft>5){
            this.setState({showBackIcon:true})
        }
        else{
            this.setState({showBackIcon:false, selectedDayIndex:0})
        }
    }

    forwardHandler = () =>{
        var frame = this.state.albumRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let val =1
        if (this.props.RTL){
            val = -1
        }
        let leftValue =scrollLeft+(window.innerWidth/2);
        frame.scrollTo({
            left:val*leftValue,
            behavior: "smooth",
            duration:500
          });
      }

      backwardHandler = () =>{
        var frame = this.state.albumRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let val =1
        if (this.props.RTL){
            val = -1
        }
        let leftValue = 0
        if ((scrollLeft-(window.innerWidth))>0){
            leftValue =(scrollLeft-(window.innerWidth/2))
        }
        frame.scrollTo({
            left:val*leftValue,
            behavior: "smooth",
            duration:500
          });
      }

      deleteExperienceHandler= index =>{
        let experience = this.state.planExperiences[index];
        this.props.deleteExperienceHandler(experience);
      }

      editExperienceHandler= index =>{
        let experience  = this.state.planExperiences[index];
        this.props.editExperienceHandler(experience);
      }
    
      removeExperienceFromPlanHandler= index =>{
        let experience  = this.state.planExperiences[index];
        this.props.removeExperienceFromPlanHandler(experience);
      }

    renderTopSection = () =>{
        return (
            <div className={classes.TopSection}>
               <ModalTopSection
               title={""}
               discard={this.props.discard}
               showButton={false}
               RTL = {this.props.RTL}
               />
           </div>
         );
          
      }

      showPlaceAlbum = async (dayIndex,itemIndex,place) =>{
        await  this.setState({
            showPlaceAlbum: true,
            selectedPlace: place,
            selectedPlaceDay: dayIndex+1,
            selectedPlaceIndex: itemIndex
        })
      }

      renderPlaceAlbum = () =>{
        if (this.state.showPlaceAlbum && this.state.selectedPlace!==undefined){
            return(
                <TripPlaceAlbum
                    isEditor = {this.props.isEditor}
                    selectedPlace = {this.state.selectedPlace}
                    planExperiences = {this.props.planExperiences}
                    viewUserJourneyID = {this.props.viewUserJourneyID}

                    updateExperienceText = {this.props.updateExperienceText}
                    updateJourneyImagesOrder = {(images)=>{
                        this.props.updateJourneyImagesOrder(this.state.selectedPlaceDay,this.state.selectedPlaceIndex,images)}}

                    updateSelectedImages = {(userJourneyImages)=>{
                        this.props.updateSelectedImages(this.state.selectedPlaceDay,this.state.selectedPlaceIndex,userJourneyImages)}}
                    /* removeJourneyImage = {(imageIndex,userID)=>{
                        this.props.removeJourneyImage(this.state.selectedPlaceDay,this.state.selectedPlaceIndex,imageIndex,userID)}}

                        addJourneyImage = {(image,userID)=>{
                        this.props.addJourneyImage(this.state.selectedPlaceDay,this.state.selectedPlaceIndex,image,userID)}} */
                   
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    user={this.props.user}
                    discard = {()=>{this.setState({showPlaceAlbum:false})}}
                    RTL = {this.props.RTL}
                />
            )
        }
    }

      renderBody = () =>{
          return(
            <div className={classes.Body}>
                {this.renderDays()}
                {this.renderWall()}
            </div>
          )
      }

    
      imageFullScreenHandler = index =>{
          this.setState({showAlbumImageViewer: true, imageViewerIndex: index})
      }

      renderAlbumImageViewer = () =>{
          if (this.state.showAlbumImageViewer){
              return(
                  <AlbumPhotoViewer
                  allPhotosList = {this.state.allPhotosList}
                  index = {this.state.imageViewerIndex}

                  updateIndex = {(index)=>{this.setState({imageViewerIndex:index})}}
                    RTL = {this.props.RTL}
                    discard={()=>{this.setState({showAlbumImageViewer:false})}}
                  />
              )
          }
      }
     
      renderAlbum = () =>{
          switch(this.state.albumNavigation){
              case "Experiences":{
                  /* console.log(this.state.planExperiences)
                  console.log('-----')
                  console.log(this.props.planExperiences) */
                  return(
                    <div className={classes.Wall}>
                        <ExperiencesWall
                            experiencesList={this.state.planExperiences}
                            user={this.props.user}
                            viewStyle={"wall"}
                            board = {"plan"}
                            deleteHandler={this.deleteExperienceHandler}
                            editHandler={this.editExperienceHandler}
                            removeTaggingHandler = {this.removeTaggingHandler}
                            viewExperiencePlace={this.props.viewExperiencePlace}
                            selectedContentWall={this.props.selectedContentWall}
                            addToPlan={this.addExperienceToPlanHandler}
                            removeFromPlan={this.removeExperienceFromPlanHandler}
                            viewImagesFullscreen = {this.props.viewImagesFullscreen}

                            writeAcitivityLog = {this.props.writeAcitivityLog}
                            RTL = {this.props.RTL}
                            userLanguage={this.props.userLanguage}
                            systemText={this.props.systemText}
                        />
                    </div>
                  )
              }
              case "AlbumDays":{
                if (isMobile){
                    return(
                        <div className={classes.Album} ref={this.state.albumRef}>
                            {this.props.planDays.map((day,index)=>(
                            /*     <div style={{width:"100%"}} key={index}> */
                                <TripAlbumDay
                                  dayIndex = {index}
                                  dateName = {this.state.daysNames[index]}
                                  selectedDayIndex = {this.state.selectedDayIndex}
                                  isEditor = {this.props.isEditor}
                                  planDays={this.props.planDays}
                                  planExperiences = {this.props.planExperiences}
                                  viewUserJourneyID = {this.props.viewUserJourneyID}
                                  albumScroll = {this.state.albumScroll}
                                  updateSelectedDay = {this.selectDate}
                                  viewPlaceHandler={this.props.viewPlaceHandler}
          
                                  shareExperience={(dayIndex,itemIndex)=>{this.props.shareExperience(dayIndex,itemIndex);
                                      this.setState({showBackdrop:true})}}
                                  showPlaceAlbum = {this.showPlaceAlbum}

                                  preventScroll = {(value)=>{this.setState({preventScroll:value})}}
                          
                                  writeAcitivityLog = {this.props.writeAcitivityLog}
                                  
                                  loggedUser={this.props.loggedUser}
                                  user={this.props.user}
                                  RTL = {this.props.RTL}
                                  userLanguage={this.props.userLanguage}
                                  systemText={this.props.systemText}
                                />
                          /*   </div> */
                                ))}
                        </div>
                    )
                }
                else{
                    return(
                        <div className={classes.Album} ref={this.state.albumRef}>
                            {this.props.planDays.map((day,index)=>(
                                <div style={{height:"100%"}} key={index}>
                                <TripAlbumDay
                                  dayIndex = {index}
                                  dateName = {this.state.daysNames[index]}
                                  selectedDayIndex = {this.state.selectedDayIndex}
                                  isEditor = {this.props.isEditor}
                                  planDays={this.props.planDays}
                                  planExperiences = {this.props.planExperiences}
                                  viewUserJourneyID = {this.props.viewUserJourneyID}
                                  albumScroll = {this.state.albumScroll}
                                  updateSelectedDay = {this.selectDate}
                                  viewPlaceHandler={this.props.viewPlaceHandler}
          
                                  shareExperience={(dayIndex,itemIndex)=>{this.props.shareExperience(dayIndex,itemIndex);
                                      this.setState({showBackdrop:true})}}
                                  showPlaceAlbum = {this.showPlaceAlbum}

                                  preventScroll = {(value)=>{this.setState({preventScroll:value})}}
                          
                                  writeAcitivityLog = {this.props.writeAcitivityLog}
                                  
                                  loggedUser={this.props.loggedUser}
                                  user={this.props.user}
                                  RTL = {this.props.RTL}
                                  userLanguage={this.props.userLanguage}
                                  systemText={this.props.systemText}
                                />
                            </div>
                                ))}
                        </div>
                    )   
                }
            }
            case "AlbumPhotos":{
                return(
                    <div className={classes.AlbumPhotos}>
                       {this.state.imagesPreview.map((img,imgIndex)=>(
                           <TripAlbumImage
                                index = {imgIndex}
                                imageViewerIndex = {this.state.imageViewerIndex}
                                url = {img}
                                imageClicked = {this.imageFullScreenHandler}
                           />
                            ))}
                    </div>
                )
            }
              default: return null;
          }
          
      }

      selectDate = (index) =>{
          this.setState({selectedDayIndex:index,showDatesSelection:false,showTransparentBackdrop:false})
      }

      renderDatesSelection = () =>{
        if (this.state.showDatesSelection){
            return(
                <div className={classes.DatesSelection}>
                    {this.state.daysNames.map((date,index)=>{
                        if (this.state.daysWithPlaces[index]){
                            return(
                                <div className={classes.Date} key={index}
                                     onClick={()=>{this.selectDate(index)}}>
                                    {date}
                                </div>
                            )
                        }
                        else{
                            return(
                            <div className={classes.DateGrey} key={index}>
                                {date}
                             </div>
                            )
                        }
                        
                         })}
                </div>
            )
        }
      }

      renderAlbumNavigation = () =>{
        let albumNavigationClass = "AlbumNavigaition"
        if (this.props.RTL){
          albumNavigationClass = "AlbumNavigationRTL"
        }
        return(
            <div className={classes[albumNavigationClass]}>
                {this.renderAlbumDate()}
                {this.renderAlbumExperiencesButton()}
                {this.renderAlbumPhotosButton()}
            </div>
        )
      }

      renderAlbumExperiencesButton = () =>{
          let buttonClass = "Button"
          if (this.state.albumNavigation==="Experiences"){
            buttonClass = "ButtonSelected"
          }
          return(
            <div className={classes[buttonClass]}
                onClick={()=>{this.setState({albumNavigation:"Experiences"})}}>
                    {this.props.systemText.plan.viewPlan.experiencesAlbum.experiences[this.props.userLanguage]}
            </div>
          )
      }

      renderAlbumPhotosButton = () =>{
        let buttonClass = "Button"
        if (this.state.albumNavigation==="AlbumPhotos"){
          buttonClass = "ButtonSelected"
        }
        return(
          <div className={classes[buttonClass]}
              onClick={()=>{this.setState({albumNavigation:"AlbumPhotos"})}}>
                  {this.props.systemText.plan.viewPlan.experiencesAlbum.allPhotos[this.props.userLanguage]}
          </div>
        )
    }

      renderAlbumDate = () =>{
          let datesOptions = "DatesOptions"
          if (this.props.RTL){
              datesOptions = "DatesOptionsRTL"
          }
          let buttonClass = "Button"
          if (this.state.albumNavigation==="AlbumDays"){
            buttonClass = "ButtonSelected"
          }
          if (this.state.albumNavigation==="AlbumDays"){
            return(
                <div className={classes.AlbumDate}>
                  <div className={classes[buttonClass]} 
                  onClick={()=>{this.setState({showDatesSelection:true,showTransparentBackdrop:true})}}>
                      {this.state.daysNames[this.state.selectedDayIndex]}
                  </div>
                  <div className={classes.DatesOptions}>
                      {this.renderDatesSelection()}
                  </div>
              </div>
            )
          }
          else{
            return(
                <div className={classes[buttonClass]}
                    onClick={()=>{this.setState({albumNavigation:"AlbumDays"})}}>
                         {this.props.systemText.plan.viewPlan.experiencesAlbum.albumDays[this.props.userLanguage]}
                </div>
              )
          }
          
      }

      backdropHandler = () =>{
          this.setState({
            showDatesSelection:false,
            showTransparentBackdrop:false
          })
      }

      discardExperienceCreation = () =>{
          this.setState({showBackdrop:false})
      }

     

    renderArrows = () =>{
        if (!isMobile && this.state.albumNavigation==="AlbumDays"){
            let rotateArrow = "RotateArrow"
            if (this.props.RTL){
                rotateArrow = ""
            }
            let hideBack=""
            if(!this.state.showBackIcon){
                hideBack="HideBack"
            }
            let hideForward=""
            if(!this.state.showForwardIcon){
                hideForward="HideForward"
            }
            return(
                <div className={classes.ArrowsSection}>
                    <img src={arrowIconRight} className={[classes.Arrow,classes[rotateArrow],classes[hideBack]].join(" ")} alt="" onClick={this.backwardHandler}/>
                    <img className={[classes.Arrow,classes[rotateArrow],classes[hideForward]].join(" ")} alt = "" src={arrowIconLeft} onClick={this.forwardHandler}/>
                    
                </div>
            )
        }
        
    } 

    render(){
        return(
            <div className={classes.Modal}>
                <Backdrop show={this.state.showBackdrop}/>
                <TransparentBackdrop show={this.state.showTransparentBackdrop} clicked={this.backdropHandler}/>
                {this.renderPlaceAlbum()}
                {this.renderAlbumImageViewer()}
                {this.renderAlbumNavigation()}
                {this.renderTopSection()}
                {this.renderArrows()}
                {this.renderAlbum()}
            </div>
        )
    }
}

export default TripAlbum;