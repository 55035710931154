import React, { Component } from "react";
import classes from "./PlaceCharacteristics.module.css";

import OptionSelection from "../../Complex/OptionSelection/OptionSelection"
import Backdrop from "../../UI/Backdrop/Backdrop";
import Tag from "../../UI/Tag/Tag"
import FilterButton from "../../UI/Buttons/FilterButton"

let accessibleIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAccessibleIcon%20(1).png?alt=media&token=e64cfced-ab31-4265-b669-ac4b582b2c9c"
let accessibleIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAccessibleIconSelected.png?alt=media&token=e91638ea-649c-4438-b5b6-2b36bf69ce75"
let notAccessibleIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FNotAccessibleIcon.png?alt=media&token=36e8d639-4388-45f6-8d23-ecfbeda935ce"
let notAccessibleIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FNotAccessibleIconSelected.png?alt=media&token=dcc6fdda-8a5f-4a83-b390-64a1c41d2121"
let parkingIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FParkingIcon%20(1).png?alt=media&token=f5823a5e-0f47-49e1-bba7-1cad0bf4d239"
let parkingIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FParkingIconSelected.png?alt=media&token=ecf4a855-124a-4e2f-b202-c2c57fcc1a54"
let petsAllowedIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPetsFriendly%20(1).png?alt=media&token=c2fc933b-e746-4319-8e01-acbe8b30382b"
let petsAllowedIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPetsFriendlySelected.png?alt=media&token=25cf53bc-a12b-4aeb-976b-a77efc3ab10c"
let petsNotAllowedIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPetsNotAllowed.png?alt=media&token=ed63e527-a754-409e-928a-8d725a6d2db2"
let petsNotAllowedIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPetsNotAllowedSelected.png?alt=media&token=4fbf381b-1e59-4f44-98c9-e71415028a84"
let freeEntranceIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FFreeIcon.png?alt=media&token=f96765c9-7b76-4040-9c9f-dfe6a53c54f3"
let freeEntranceIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FFreeIconSelected%20(1).png?alt=media&token=e2f7f755-3e52-4171-8c6f-424357829f4b"
let durationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDurationIcon.png?alt=media&token=3e02db71-8c60-4a85-bba8-5a30ba9ce277"

class PlaceCharacteristics extends Component {
    state={
        includedGeneralTags: [],
        includedAudienceTags: [],
        includedMainTags:[],
        includedSubTags:[],
        natureGeneralTags:[],
        selectedCuisines: [],
        generalTags: this.props.placesCharacteristicsData.generalTags,
        audienceTags:  Object.values(this.props.placesCharacteristicsData.audienceTags),
        natureGeneral : Object.values(this.props.placesCharacteristicsData.natureTags),
        natureSeasons : Object.values(this.props.placesCharacteristicsData.natureSeasons),
        natureToWatch : Object.values(this.props.placesCharacteristicsData.natureThingToWatch),
        foodServices : Object.values(this.props.placesCharacteristicsData.foodPlaceServices),
        foodDiningOptions : Object.values(this.props.placesCharacteristicsData.foodDiningOptions),
        foodKosher : Object.values(this.props.placesCharacteristicsData.foodKosher),
        museumTypes : Object.values(this.props.placesCharacteristicsData.museumTypes),
        trailCharacteristics : Object.values(this.props.placesCharacteristicsData.trailCharacteristics),
        dificultyTags : [],
        durationUnits:"hours",
        durationValue:0,
        distanceUnits:"km",
        distanceValue:0,
        mtbTrailType : Object.values(this.props.placesCharacteristicsData.mtbTrailType),
        bikeRideLevel : [],
        beachCharacteristics : Object.values(this.props.placesCharacteristicsData.beachCharacteristics),
        beachFacilities : Object.values(this.props.placesCharacteristicsData.beachFacilities),
        beachActivities : Object.values(this.props.placesCharacteristicsData.beachActivities),
        parkOrCampingFacilities : Object.values(this.props.placesCharacteristicsData.parkOrCampingFacilities),
        barType : Object.values(this.props.placesCharacteristicsData.barTypes),
        restaurantServiceType : Object.values(this.props.placesCharacteristicsData.restaurantServiceType),
        restaurantsCuisine : Object.values(this.props.placesCharacteristicsData.restaurantsCuisine),
    }

    componentDidMount = () =>{
        //update difficulty tags by order
        let dificultyTags = [];
        dificultyTags.push(this.props.placesCharacteristicsData.dificultyTags.easy)
        dificultyTags.push(this.props.placesCharacteristicsData.dificultyTags.medium)
        dificultyTags.push(this.props.placesCharacteristicsData.dificultyTags.hard)
        dificultyTags.push(this.props.placesCharacteristicsData.dificultyTags.extreme)
        this.setState({dificultyTags:dificultyTags})

        //update bike ride level by tags order
        let bikeRideLevel = []
        bikeRideLevel.push(this.props.placesCharacteristicsData.bikeRideLevel.bikeRideLevelBeginner)
        bikeRideLevel.push(this.props.placesCharacteristicsData.bikeRideLevel.bikeRideLevelIntermidiate)
        bikeRideLevel.push(this.props.placesCharacteristicsData.bikeRideLevel.bikeRideLevelAdvanced)
        bikeRideLevel.push(this.props.placesCharacteristicsData.bikeRideLevel.bikeRideLevelExtreme)
        this.setState({bikeRideLevel:bikeRideLevel})

        this.setState({
            placeCharacteristics: this.props.placeCharacteristics
        })
        let includedGeneralTags = [];
        if (this.props.placeCharacteristics.generalTags!==undefined){
            includedGeneralTags = includedGeneralTags.concat(this.props.placeCharacteristics.generalTags)
            this.setState({includedGeneralTags:includedGeneralTags})
        }
        let includedAudienceTags = [];
        if (this.props.placeCharacteristics.audienceTags!==undefined){
            includedAudienceTags = includedAudienceTags.concat(this.props.placeCharacteristics.audienceTags)
            this.setState({includedAudienceTags:includedAudienceTags})
        }
        let includedMainTags = [];
        if (this.props.placeCharacteristics.mainTags!==undefined){
            includedMainTags = includedMainTags.concat(this.props.placeCharacteristics.mainTags)
            this.setState({includedMainTags:includedMainTags})
        }
        let includedSubTags = [];
        if (this.props.placeCharacteristics.subTags!==undefined){
            includedSubTags = includedSubTags.concat(this.props.placeCharacteristics.subTags)
            this.setState({includedSubTags:includedSubTags})
        }
        if (this.props.placeCharacteristics.durationUnits!==undefined){
            this.setState({durationUnits:this.props.placeCharacteristics.durationUnits})
        }
        if (this.props.placeCharacteristics.durationValue!==undefined){
            this.setState({durationValue:this.props.placeCharacteristics.durationValue})
        }
        if (this.props.placeCharacteristics.distanceUnits!==undefined){
            this.setState({distanceUnits:this.props.placeCharacteristics.distanceUnits})
        }
        if (this.props.placeCharacteristics.distanceValue!==undefined){
            this.setState({distanceValue:this.props.placeCharacteristics.distanceValue})
        }
        if (this.props.placeCharacteristics.restaurantCuisine!==undefined){
            let selectedCuisines = [];
            for (let i=0 ; i<this.props.placeCharacteristics.restaurantCuisine.length ; i++){
                selectedCuisines.push(this.props.placesCharacteristicsData.restaurantsCuisine[this.props.placeCharacteristics.restaurantCuisine[i]])
            }
            this.setState({selectedCuisines:selectedCuisines})
        }
        let selectedMainCategoriesCount = 0;
        for (let i =0 ; i<this.props.placeMainCategory.length ; i++){
            if (this.props.placeMainCategory[i]===1){
                selectedMainCategoriesCount+=1 
            }
        }
        this.setState({selectedMainCategoriesCount:selectedMainCategoriesCount})

    }

    componentDidUpdate = prevProps => {
        if (JSON.stringify(this.props.placeMainCategory)!==JSON.stringify(prevProps.placeMainCategory)){
            let selectedMainCategoriesCount = 0;
            for (let i =0 ; i<this.props.placeMainCategory.length ; i++){
                if (this.props.placeMainCategory[i]===1){
                    selectedMainCategoriesCount+=1 
                }
            }
            this.setState({selectedMainCategoriesCount:selectedMainCategoriesCount})
        }
    }


    updateGeneralTags = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.generalTags!==undefined){
            tags = this.state.placeCharacteristics.generalTags
        }
        let includedGeneralTags = this.state.includedGeneralTags;
        if (add){
            tags.push(tagID)
            includedGeneralTags.push(tagID)
            if (tagID==="accessible"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "notAccessible";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedGeneralTags.findIndex(id => {
                    return id === "notAccessible";
                  });
                if (idIndex!==-1){
                    includedGeneralTags.splice(idIndex,1);
                }
            }
            if (tagID==="notAccessible"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "accessible";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedGeneralTags.findIndex(id => {
                    return id === "accessible";
                  });
                if (idIndex!==-1){
                    includedGeneralTags.splice(idIndex,1);
                }
            }
            if (tagID==="petsAllowed"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "petsNotAllowed";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedGeneralTags.findIndex(id => {
                    return id === "petsNotAllowed";
                  });
                if (idIndex!==-1){
                    includedGeneralTags.splice(idIndex,1);
                }
                
            }
            if (tagID==="petsNotAllowed"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "petsAllowed";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedGeneralTags.findIndex(id => {
                    return id === "petsAllowed";
                  });
                if (idIndex!==-1){
                    includedGeneralTags.splice(idIndex,1);
                }
            }
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedGeneralTags.findIndex(id => {
                return id === tagID;
              });
              includedGeneralTags.splice(idIndex,1);
            if (tagID==="hasParking"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "freeParking";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedGeneralTags.findIndex(id => {
                    return id === "freeParking";
                  });
                if (idIndex!==-1){
                    includedGeneralTags.splice(idIndex,1);
                }
            }
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.generalTags=tags
        if (placeCharacteristics.generalTags.length === 0){
            delete placeCharacteristics.generalTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedGeneralTags:includedGeneralTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateAudienceTags = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.audienceTags!==undefined){
            tags = this.state.placeCharacteristics.audienceTags
        }
        let includedAudienceTags = this.state.includedAudienceTags;
        if (add){            
            if (tagID==="adultsOnly"){
                tags = [];
                let includeGoodForGroups = false;
                const idIndex = includedAudienceTags.findIndex(id => {
                    return id === "goofForGroups";
                  });
                if (idIndex!==-1){
                    includeGoodForGroups = true;
                }
                includedAudienceTags = [];
                if (includeGoodForGroups){
                    tags.push("goofForGroups")
                    includedAudienceTags.push("goofForGroups") 
                }
                tags.push(tagID)
                includedAudienceTags.push(tagID)
            }
            else if (tagID!=="goofForGroups"){
                tags.push(tagID)
                includedAudienceTags.push(tagID)
                const tagIndex = tags.findIndex(tag => {
                    return tag === "adultsOnly";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedAudienceTags.findIndex(id => {
                    return id === "adultsOnly";
                  });
                if (idIndex!==-1){
                    includedAudienceTags.splice(idIndex,1);
                }
            }
            else{
                tags.push(tagID)
                includedAudienceTags.push(tagID)
            }
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedAudienceTags.findIndex(id => {
                return id === tagID;
              });
              includedAudienceTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.audienceTags=tags
        if (placeCharacteristics.audienceTags.length === 0){
            delete placeCharacteristics.audienceTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedAudienceTags:includedAudienceTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

   
    updateNatureGeneralTags = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.natureGeneralTags!==undefined){
            tags = this.state.placeCharacteristics.natureGeneralTags
        }
        let includedMainTags = this.state.includedMainTags;
        if (add){            
            tags.push(tagID)
            includedMainTags.push(tagID)
            if (tagID==="waterEntry"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "noWaterEntry";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedMainTags.findIndex(id => {
                    return id === "noWaterEntry";
                  });
                if (idIndex!==-1){
                    includedMainTags.splice(idIndex,1);
                }
            }
            if (tagID==="noWaterEntry"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "waterEntry";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedMainTags.findIndex(id => {
                    return id === "waterEntry";
                  });
                if (idIndex!==-1){
                    includedMainTags.splice(idIndex,1);
                }
            }
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedMainTags.findIndex(id => {
                return id === tagID;
              });
              includedMainTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.natureGeneralTags=tags
        placeCharacteristics.mainTags=includedMainTags
        if (placeCharacteristics.natureGeneralTags.length === 0){
            delete placeCharacteristics.natureGeneralTags;
        }
        if (placeCharacteristics.mainTags.length === 0){
            delete placeCharacteristics.mainTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedMainTags:includedMainTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateSeasonsTags = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.seasonsTags!==undefined){
            tags = this.state.placeCharacteristics.seasonsTags
        }
        let includedMainTags = this.state.includedMainTags;
        if (add){            
            tags.push(tagID)
            includedMainTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedMainTags.findIndex(id => {
                return id === tagID;
              });
              includedMainTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.seasonsTags=tags
        placeCharacteristics.mainTags=includedMainTags
        if (placeCharacteristics.seasonsTags.length === 0){
            delete placeCharacteristics.seasonsTags;
        }
        if (placeCharacteristics.mainTags.length === 0){
            delete placeCharacteristics.mainTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedMainTags:includedMainTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateNatureThingsToWatch = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.natureThingsToWatch!==undefined){
            tags = this.state.placeCharacteristics.natureThingsToWatch
        }
        let includedMainTags = this.state.includedMainTags;
        if (add){            
            tags.push(tagID)
            includedMainTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedMainTags.findIndex(id => {
                return id === tagID;
              });
              includedMainTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.natureThingsToWatch=tags
        placeCharacteristics.mainTags=includedMainTags
        if (placeCharacteristics.natureThingsToWatch.length === 0){
            delete placeCharacteristics.natureThingsToWatch;
        }
        if (placeCharacteristics.mainTags.length === 0){
            delete placeCharacteristics.mainTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedMainTags:includedMainTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateFoodService = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.foodServices!==undefined){
            tags = this.state.placeCharacteristics.foodServices
        }
        let includedMainTags = this.state.includedMainTags;
        if (add){            
            tags.push(tagID)
            includedMainTags.push(tagID)
            if (tagID==="hasSmokingArea"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "noSmoking";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedMainTags.findIndex(id => {
                    return id === "noSmoking";
                  });
                if (idIndex!==-1){
                    includedMainTags.splice(idIndex,1);
                }
            }
            if (tagID==="noSmoking"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "hasSmokingArea";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedMainTags.findIndex(id => {
                    return id === "hasSmokingArea";
                  });
                if (idIndex!==-1){
                    includedMainTags.splice(idIndex,1);
                }
            }
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedMainTags.findIndex(id => {
                return id === tagID;
              });
              includedMainTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.foodServices=tags
        placeCharacteristics.mainTags=includedMainTags
        if (placeCharacteristics.foodServices.length === 0){
            delete placeCharacteristics.foodServices;
        }
        if (placeCharacteristics.mainTags.length === 0){
            delete placeCharacteristics.mainTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedMainTags:includedMainTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }


    updateFoodDiningOptions = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.foodDiningOptions!==undefined){
            tags = this.state.placeCharacteristics.foodDiningOptions
        }
        let includedMainTags = this.state.includedMainTags;
        if (add){            
            tags.push(tagID)
            includedMainTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedMainTags.findIndex(id => {
                return id === tagID;
              });
              includedMainTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.foodDiningOptions=tags
        placeCharacteristics.mainTags=includedMainTags
        if (placeCharacteristics.foodDiningOptions.length === 0){
            delete placeCharacteristics.foodDiningOptions;
        }
        if (placeCharacteristics.mainTags.length === 0){
            delete placeCharacteristics.mainTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedMainTags:includedMainTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateKosher = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.foodKosher!==undefined){
            tags = this.state.placeCharacteristics.foodKosher
        }
        let includedMainTags = this.state.includedMainTags;
        if (add){            
            tags.push(tagID)
            includedMainTags.push(tagID)
            if (tagID==="kosher"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "nonKosher";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedMainTags.findIndex(id => {
                    return id === "nonKosher";
                  });
                if (idIndex!==-1){
                    includedMainTags.splice(idIndex,1);
                }
            }
            if (tagID==="nonKosher"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "kosher";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedMainTags.findIndex(id => {
                    return id === "kosher";
                  });
                if (idIndex!==-1){
                    includedMainTags.splice(idIndex,1);
                }
            }
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedMainTags.findIndex(id => {
                return id === tagID;
              });
              includedMainTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.foodKosher=tags
        placeCharacteristics.mainTags=includedMainTags
        if (placeCharacteristics.foodKosher.length === 0){
            delete placeCharacteristics.foodKosher;
        }
        if (placeCharacteristics.mainTags.length === 0){
            delete placeCharacteristics.mainTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedMainTags:includedMainTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }


    updateMuseumTypes = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.museumTypes!==undefined){
            tags = this.state.placeCharacteristics.museumTypes
        }
        let includedSubTags = this.state.includedSubTags;
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.museumTypes=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.museumTypes.length === 0){
            delete placeCharacteristics.museumTypes;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateTrailCharacteristics = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.trailCharacteristics!==undefined){
            tags = this.state.placeCharacteristics.trailCharacteristics
        }
        let includedSubTags = this.state.includedSubTags;
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.trailCharacteristics=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.trailCharacteristics.length === 0){
            delete placeCharacteristics.trailCharacteristics;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        console.log(placeCharacteristics)
        console.log(includedSubTags)
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateDifficulty = (add,tagID) =>{
        let tags = [];
        let includedSubTags = this.state.includedSubTags;

        let placeCharacteristics = this.state.placeCharacteristics
        if (placeCharacteristics.difficulty!==undefined){
            if ( placeCharacteristics.difficulty.length>0){
                let oldDifficultyTag = placeCharacteristics.difficulty[0]
                const tagIndex = tags.findIndex(tag => {
                    return tag === oldDifficultyTag;
                  });
                tags.splice(tagIndex,1);
                const idIndex = includedSubTags.findIndex(id => {
                    return id === oldDifficultyTag;
                  });
                  includedSubTags.splice(idIndex,1);
            }
        }

        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        //if difficulty already was selected then remove the old difficulty
       
        
       
        placeCharacteristics.difficulty=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.difficulty.length === 0){
            delete placeCharacteristics.difficulty;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    durationIncreaseHandler = () =>{
        let durationValue;
        /* if (this.state.durationUnits==="days"){
            durationValue=this.state.durationValue+0.5;
            
        }
        else{
            durationValue=Math.floor(this.state.durationValue+1);
        } */
        durationValue=this.state.durationValue+0.5;
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.durationValue=durationValue
        this.setState({placeCharacteristics:placeCharacteristics,durationValue:durationValue})
        this.props.updateCharacteristics(placeCharacteristics);
        if (this.state.placeCharacteristics.durationUnits===undefined){
            this.durationUnitsHandler(this.state.durationUnits)
        }
    }

    durationDecreaseHandler = () =>{
        if (this.state.durationValue===0) return;
        let durationValue;
        /* if (this.state.durationUnits==="days"){
           
          durationValue=this.state.durationValue-0.5;
        }
        else{
            durationValue=Math.ceil(this.state.durationValue-1);
        } */
        durationValue=this.state.durationValue-0.5;
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.durationValue=durationValue
        this.setState({placeCharacteristics:placeCharacteristics,durationValue:durationValue})
        this.props.updateCharacteristics(placeCharacteristics);
        if (this.state.placeCharacteristics.durationUnits===undefined){
            this.durationUnitsHandler(this.state.durationUnits)
        }
    }

    updateDurationNumber = e =>{
        if (e.currentTarget.value<0) return
        let durationValue=parseInt(e.currentTarget.value, 10);
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.durationValue=durationValue
        this.setState({placeCharacteristics:placeCharacteristics,durationValue:durationValue})
        this.props.updateCharacteristics(placeCharacteristics);
        if (this.state.placeCharacteristics.durationUnits===undefined){
            this.durationUnitsHandler(this.state.durationUnits)
        }
    }

    durationUnitsHandler = value =>{
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.durationUnits=value
        this.setState({placeCharacteristics:placeCharacteristics,durationUnits:value})
        this.props.updateCharacteristics(placeCharacteristics);
    }

    distanceIncreaseHandler = () =>{
        let distanceValue=this.state.distanceValue+1;
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.distanceValue=distanceValue
        this.setState({placeCharacteristics:placeCharacteristics,distanceValue:distanceValue})
        this.props.updateCharacteristics(placeCharacteristics);
        if (this.state.placeCharacteristics.distanceUnits===undefined){
            this.distanceUnitsHandler(this.state.distanceUnits)
        }
    }

    distanceDecreaseHandler = () =>{
        if (this.state.distanceValue===0) return;
        let distanceValue=this.state.distanceValue-1;
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.distanceValue=distanceValue
        this.setState({placeCharacteristics:placeCharacteristics,distanceValue:distanceValue})
        this.props.updateCharacteristics(placeCharacteristics);
        if (this.state.placeCharacteristics.distanceUnits===undefined){
            this.distanceUnitsHandler(this.state.distanceUnits)
        }
    }

    updateDistanceNumber = e =>{
        if (e.currentTarget.value<0) return
        let distanceValue=parseInt(e.currentTarget.value, 10);
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.distanceValue=distanceValue
        this.setState({placeCharacteristics:placeCharacteristics,distanceValue:distanceValue})
        this.props.updateCharacteristics(placeCharacteristics);
        if (this.state.placeCharacteristics.distanceUnits===undefined){
            this.distanceUnitsHandler(this.state.distanceUnits)
        }
    }



    distanceUnitsHandler = value =>{
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.distanceUnits=value
        this.setState({placeCharacteristics:placeCharacteristics,distanceUnits:value})
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateMTBTrailType = (add,tagID) =>{
        let tags = [];
        let includedSubTags = [];
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.mtbTrailType=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.mtbTrailType.length === 0){
            delete placeCharacteristics.mtbTrailType;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateBikeRideLevel = (add,tagID) =>{
        let tags = [];
        let includedSubTags = [];
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.bikeRideLevel=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.bikeRideLevel.length === 0){
            delete placeCharacteristics.bikeRideLevel;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateBeachCharacteristics = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.beachCharacteristics!==undefined){
            tags = this.state.placeCharacteristics.beachCharacteristics
        }
        let includedSubTags = this.state.includedSubTags;
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
            if (tagID==="quietRequired"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "canMakeNoise";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedSubTags.findIndex(id => {
                    return id === "canMakeNoise";
                  });
                if (idIndex!==-1){
                    includedSubTags.splice(idIndex,1);
                }
            }
            if (tagID==="canMakeNoise"){
                const tagIndex = tags.findIndex(tag => {
                    return tag === "quietRequired";
                  });
                if (tagIndex!==-1){
                    tags.splice(tagIndex,1);
                }
                const idIndex = includedSubTags.findIndex(id => {
                    return id === "quietRequired";
                  });
                if (idIndex!==-1){
                    includedSubTags.splice(idIndex,1);
                }
            }
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.beachCharacteristics=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.beachCharacteristics.length === 0){
            delete placeCharacteristics.beachCharacteristics;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateBeachFacilities  = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.beachFacilities!==undefined){
            tags = this.state.placeCharacteristics.beachFacilities
        }
        let includedSubTags = this.state.includedSubTags;
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.beachFacilities=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.beachFacilities.length === 0){
            delete placeCharacteristics.beachFacilities;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateBeachActivities = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.beachActivities!==undefined){
            tags = this.state.placeCharacteristics.beachActivities
        }
        let includedSubTags = this.state.includedSubTags;
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.beachActivities=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.beachActivities.length === 0){
            delete placeCharacteristics.beachActivities;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateParkOrCampingFacilities = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.parkOrCampingFacilities!==undefined){
            tags = this.state.placeCharacteristics.parkOrCampingFacilities
        }
        let includedSubTags = this.state.includedSubTags;
        if (add){          
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.parkOrCampingFacilities=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.parkOrCampingFacilities.length === 0){
            delete placeCharacteristics.parkOrCampingFacilities;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateBarType = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.barType!==undefined){
            tags = this.state.placeCharacteristics.barType
        }
        let includedSubTags = this.state.includedSubTags;
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.barType=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.barType.length === 0){
            delete placeCharacteristics.barType;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateRestaurantServiceType = (add,tagID) =>{
        let tags = [];
        if (this.state.placeCharacteristics.restaurantServiceType!==undefined){
            tags = this.state.placeCharacteristics.restaurantServiceType
        }
        let includedSubTags = this.state.includedSubTags;
        if (add){            
            tags.push(tagID)
            includedSubTags.push(tagID)
        }
        else{
            const tagIndex = tags.findIndex(tag => {
                return tag === tagID;
              });
            tags.splice(tagIndex,1);
            const idIndex = includedSubTags.findIndex(id => {
                return id === tagID;
              });
              includedSubTags.splice(idIndex,1);
        }
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.restaurantServiceType=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.restaurantServiceType.length === 0){
            delete placeCharacteristics.restaurantServiceType;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    updateRestaurantCuisine = value =>{
        let tagID = value.id
        let tags = [];
        if (this.state.placeCharacteristics.restaurantCuisine!==undefined){
            tags = this.state.placeCharacteristics.restaurantCuisine
        }
        let includedSubTags = this.state.includedSubTags;
        let selectedCuisines = this.state.selectedCuisines
        tags.push(tagID)
        includedSubTags.push(tagID)
        selectedCuisines.push(value)
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.restaurantCuisine=tags
        placeCharacteristics.subTags=includedSubTags
       
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags,selectedCuisines:selectedCuisines});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    removeRestaurantCuisine = tagID =>{
        let tags = [];
        if (this.state.placeCharacteristics.restaurantCuisine!==undefined){
            tags = this.state.placeCharacteristics.restaurantCuisine
        }
        let includedSubTags = this.state.includedSubTags;
        let selectedCuisines = this.state.selectedCuisines
        const tagIndex = tags.findIndex(tag => {
            return tag === tagID;
          });
        tags.splice(tagIndex,1);
        const idIndex = includedSubTags.findIndex(id => {
            return id === tagID;
          });
          includedSubTags.splice(idIndex,1);
        const selectedIndex = selectedCuisines.findIndex(tag => {
            return tag.id === tagID;
          });
          selectedCuisines.splice(selectedIndex,1);
        let placeCharacteristics = this.state.placeCharacteristics
        placeCharacteristics.restaurantCuisine=tags
        placeCharacteristics.subTags=includedSubTags
        if (placeCharacteristics.restaurantCuisine.length === 0){
            delete placeCharacteristics.restaurantCuisine;
        }
        if (placeCharacteristics.subTags.length === 0){
            delete placeCharacteristics.subTags;
        }
        this.setState({placeCharacteristics:placeCharacteristics,includedSubTags:includedSubTags,selectedCuisines:selectedCuisines});
        this.props.updateCharacteristics(placeCharacteristics);
    }

    

    renderGeneralCharacteristics = () =>{
        return (
            <div className= {classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.generalCharacteristics.title[this.props.userLanguage]}
                </div>
                {this.renderAccessibility()}
                {this.renderParking()}
                {this.renderFreeEntrance()}
                {this.renderPetsAllowed()}
                {this.renderDuration(true)}
            </div>
        )
    }

    renderAccessibility = () =>{
        let accessibleIncluded = this.state.includedGeneralTags.includes("accessible")
        let notAccessibleIncluded = this.state.includedGeneralTags.includes("notAccessible")
        let iconClass = "Icon"
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            iconClass = "IconRTL"
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.Filters}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.generalCharacteristics.accessibilityHeader[this.props.userLanguage]}
                </div>
                {accessibleIncluded?(
                    <img src = {accessibleIconSelected} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(false,"accessible")}}/>
                ):(
                    <img src = {accessibleIcon} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(true,"accessible")}}/>
                )}
                {notAccessibleIncluded?(
                    <img src = {notAccessibleIconSelected} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(false,"notAccessible")}}/>
                ):(
                    <img src = {notAccessibleIcon} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(true,"notAccessible")}}/>
                )}
             </div>
        )   
    }

    renderParking = () =>{
        let hasParkingIncluded = this.state.includedGeneralTags.includes("hasParking")
        let freeParkingIncluded = this.state.includedGeneralTags.includes("freeParking")
        let freeParkingStyle = "Characteristic"
        if (freeParkingIncluded){
            freeParkingStyle = "CharacteristicSelected"
        } 
        let iconClass = "Icon"
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            iconClass = "IconRTL"
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.Filters}>
               <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.generalCharacteristics.parkingHeader[this.props.userLanguage]}
                </div>
                 {hasParkingIncluded?(
                    <img src = {parkingIconSelected} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(false,"hasParking")}}/>
                ):(
                    <img src = {parkingIcon} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(true,"hasParking")}}/>
                )}
                {hasParkingIncluded?(
                    <Tag 
                    clickable={true}
                    style={freeParkingStyle}
                    clicked={()=>{ this.updateGeneralTags(!freeParkingIncluded,"freeParking")}}
                    text= {this.state.generalTags.freeParking[this.props.userLanguage]}
                    RTL={this.props.RTL}/>
                ):null}    
             </div>
        )
    }

    renderFreeEntrance = () =>{
        let freeEntranceIncluded = this.state.includedGeneralTags.includes("freeEntrance")
        let iconClass = "Icon"
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            iconClass = "IconRTL"
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.Filters}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.generalCharacteristics.freeEntranceHeader[this.props.userLanguage]}
                </div>
                {freeEntranceIncluded?(
                    <img src = {freeEntranceIconSelected} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(false,"freeEntrance")}}/>
                ):(
                    <img src = {freeEntranceIcon} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(true,"freeEntrance")}}/>
                )} 
             </div>
        )
    }

    renderPetsAllowed = () =>{
        let petsAllowedIncluded = this.state.includedGeneralTags.includes("petsAllowed")
        let petsNotAllowedIncluded = this.state.includedGeneralTags.includes("petsNotAllowed")
        let iconClass = "Icon"
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            iconClass = "IconRTL"
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.Filters}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.generalCharacteristics.petsAllowedHeader[this.props.userLanguage]}
                </div>
                {petsAllowedIncluded?(
                    <img src = {petsAllowedIconSelected} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(false,"petsAllowed")}}/>
                ):(
                    <img src = {petsAllowedIcon} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(true,"petsAllowed")}}/>
                )}
                {petsNotAllowedIncluded?(
                    <img src = {petsNotAllowedIconSelected} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(false,"petsNotAllowed")}}/>
                ):(
                    <img src = {petsNotAllowedIcon} alt="" className={classes[iconClass]}
                    onClick = {()=>{ this.updateGeneralTags(true,"petsNotAllowed")}}/>
                )}
             </div>
        )
    }

    renderAudienceCharacterirstics = () =>{
        return (
            <div className= {classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.audienceCharacteristics.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                    {this.state.audienceTags.map((tag,index)=>{
                        let included = this.state.includedAudienceTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateAudienceTags(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderMainCategoryCharacteristics = () =>{
       let render = false
       if (this.props.filterMode){
           //show characteristics if only one main category selected
           if (this.state.selectedMainCategoriesCount===1){
               render = true
           }
        }
        else{
             //show characteristics for each of all the selected main categories
            if (this.state.selectedMainCategoriesCount>0){
                render = true
            }
        }
       if (render){
           return(
               <div>
                   {this.props.placeMainCategory[0]?(
                    <div>
                        {this.renderSightseeingCharacteristics()}
                    </div>
                   ):null}
                   {this.props.placeMainCategory[1]?(
                    <div>
                        {this.renderNatureCharacteristics()}
                    </div>
                   ):null}
                   {this.props.placeMainCategory[2]?(
                    <div>
                        {this.renderActivitiesCharacteristics()}
                    </div>
                   ):null}
                   {this.props.placeMainCategory[3]?(
                    <div>
                        {this.renserSportsCharacteristics()}
                    </div>
                   ):null}
                   {this.props.placeMainCategory[4]?(
                    <div>
                        {this.renderFoodCharacteristics()}
                    </div>
                   ):null}
                   {this.props.placeMainCategory[5]?(
                    <div>
                        {this.renderAccommodationCharacteristics()}
                    </div>
                   ):null}
               </div>
           )
       }
    }

    renderSightseeingCharacteristics = () =>{

    }

    renderNatureCharacteristics = () =>{
        return (
            <div className= {classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.natureCharacteristics.title[this.props.userLanguage]}
                </div>
                {this.renderNatureSeasons()}
                {this.renderNatureGeneral()}
                {this.renderNatureToWatch()}
            </div>
        )
    }

    renderNatureGeneral = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.natureCharacteristics.general[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.natureGeneral.map((tag,index)=>{
                        let included = this.state.includedMainTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        let renderTag = true
                        if (tag.id==="waterEntry"||tag.id==="noWaterEntry"){
                            if (this.state.includedMainTags.includes("hasWater")){
                                renderTag=true
                            }
                            else{
                                renderTag=false
                            }
                        }
                        if (renderTag){
                            return(
                                <Tag 
                                key = {index}
                                clickable={true}
                                style={style}
                                clicked={()=>{this.updateNatureGeneralTags(!included,tag.id)}}
                                text= {tag[this.props.userLanguage]}
                                RTL={this.props.RTL}/>
                            )
                        }
                        
                    })}
                    </div>
             </div>
        )
    }

    renderNatureSeasons = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.natureCharacteristics.seasons[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.natureSeasons.map((tag,index)=>{
                        let included = this.state.includedMainTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateSeasonsTags(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                     </div>
             </div>
        )
    }

    renderNatureToWatch = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.natureCharacteristics.thingsToSee[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.natureToWatch.map((tag,index)=>{
                        let included = this.state.includedMainTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateNatureThingsToWatch(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderActivitiesCharacteristics = () =>{

    }

    renserSportsCharacteristics = () =>{

    }

    renderFoodCharacteristics = () =>{
        return (
            <div className= {classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.foodPlaceCharacteristics.title[this.props.userLanguage]}
                </div>
                {this.renderFoodServices()}
                {this.renderFoodDiningOptions()}
                {this.renderKosher()}
            </div>
        )
    }

    renderFoodServices = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.foodPlaceCharacteristics.placeServices[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.foodServices.map((tag,index)=>{
                        let included = this.state.includedMainTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateFoodService(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderFoodDiningOptions = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.foodPlaceCharacteristics.diningOptions[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.foodDiningOptions.map((tag,index)=>{
                        let included = this.state.includedMainTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateFoodDiningOptions(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderKosher = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.foodPlaceCharacteristics.placeKosher[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.foodKosher.map((tag,index)=>{
                        let included = this.state.includedMainTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateKosher(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderAccommodationCharacteristics = () =>{

    }

    renderCategoryCharacteristics = () =>{
        let render = false;
        if (this.props.filterMode){
            //show 
            if (this.props.placeCategory.length===1){
                render = true
            }
        }
        else{
            //show characteristics for each of all the selected sub categories
            if (this.props.placeCategory.length>0){
                render = true
            }
        }
        let categoriesIDs = this.props.placeCategory.map(cat=>{return cat.id})
        if (render){
            return(
                <div>
                     {categoriesIDs.includes("culturalSightseeing_museum")?(
                        <div>
                         {this.renderMuseumCharacteristics()}
                     </div>
                    ):null}
                     {categoriesIDs.includes("nature_hikingTrail")?(
                        <div>
                         {this.renderHikingTrailCharacteristics()}
                     </div>
                    ):null}
                    {categoriesIDs.includes("nature_nationalPark")||
                    categoriesIDs.includes("accommodation_campingSite")||
                    categoriesIDs.includes("nature_beach")||
                    categoriesIDs.includes("nature_forest")?(
                        <div>
                         {this.renderParkOrCampingFacilities()}
                     </div>
                    ):null}
                    {categoriesIDs.includes("nature_beach")?(
                        <div>
                         {this.renderBeachCharacteristics()}
                     </div>
                    ):null}
                    {categoriesIDs.includes("sport_MTBTrail")?(
                        <div>
                         {this.renderMTBTrailCharacteristics()}
                     </div>
                    ):null}
                   {/*  {categoriesIDs.includes("sport_divingSite")?(
                        <div>
                         {this.renderDivingSiteCharacteristics()}
                     </div>
                    ):null} */}
                    {categoriesIDs.includes("foodDrink_bar")?(
                        <div>
                         {this.renderBarCharacteristics()}
                     </div>
                    ):null}
                    {categoriesIDs.includes("foodDrink_restaurant")?(
                        <div>
                         {this.renderRestaurantCharacteristics()}
                     </div>
                    ):null}
                    {categoriesIDs.includes("accommodation_campingSite")?(
                        <div>
                         {this.renderCampingSiteCharacteristics()}
                     </div>
                    ):null}
                </div>
            )
        }
    }

    renderMuseumCharacteristics = () =>{
        return(
            <div className={classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.museumCharacteristics.museumType[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.museumTypes.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateMuseumTypes(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderHikingTrailCharacteristics = () =>{
        return(
            <div className={classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.trailCharacteristics.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.trailCharacteristics.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateTrailCharacteristics(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
                {this.renderDifficulty()}
                {this.renderDuration()}
                {this.renderDistance()}
             </div>
        )
    }

    renderDifficulty = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.trailCharacteristics.difficulty.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.dificultyTags.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateDifficulty(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderDuration = (generalChar) =>{
        if (!this.props.filterMode){
            let subHeaderClass = "SubHeader"
            let iconClass = "Icon"
            if (this.props.RTL){
                subHeaderClass = "SubHeaderRTL"
                iconClass = "IconRTL"
            }
            let sectionClass = "SubSection"
            let text = this.props.systemText.placeCharacteristics.trailCharacteristics.duration[this.props.userLanguage]
            if (generalChar){
                sectionClass = "Filters"
                text = this.props.systemText.placeCharacteristics.trailCharacteristics.visitDuration[this.props.userLanguage]
            }
        
            return(
                <div className={classes[sectionClass]}>
                    <div className={classes[subHeaderClass]}>
                        <img src={durationIcon} alt="" className={classes[iconClass]}/>
                        {text}
                    </div>
                    <div className={classes.Filters}>
                        <div className={classes.NumberInput}>
                            <div className={classes.NumberInputButton} onClick={this.durationDecreaseHandler}>
                                {"-"}
                            </div>
                            <input
                                type="number"
                                value={this.state.durationValue}
                                onChange={this.updateDurationNumber}
                                className={classes.DistanceValue}
                            />
                            <div className={classes.NumberInputButton}  onClick={this.durationIncreaseHandler}>
                                {"+"}
                            </div>
                        </div>
                        {!generalChar?(
                            <div className={classes.Options}>
                            <FilterButton selected={this.state.durationUnits==="hours"} clicked={()=>{this.durationUnitsHandler("hours")}}
                                text={this.props.systemText.placeCharacteristics.trailCharacteristics.hours[this.props.userLanguage]}/>
                            <FilterButton selected={this.state.durationUnits==="days"} clicked={()=>{this.durationUnitsHandler("days")}}
                                text={this.props.systemText.placeCharacteristics.trailCharacteristics.days[this.props.userLanguage]}/>  
                        </div>
                        ):null}
                        
                    </div>
                 </div>
            )
        }
      
    }

    renderDistance = () =>{
        if (!this.props.filterMode){
            let subHeaderClass = "SubHeader"
            if (this.props.RTL){
                subHeaderClass = "SubHeaderRTL"
            }
            return(
                <div className={classes.SubSection}>
                    <div className={classes[subHeaderClass]}>
                        {this.props.systemText.placeCharacteristics.trailCharacteristics.distance[this.props.userLanguage]}
                    </div>
                    <div className={classes.Filters}>
                        <div className={classes.NumberInput}>
                            <div className={classes.NumberInputButton} onClick={this.distanceDecreaseHandler}>
                                {"-"}
                            </div>
                            <input
                                type="number"
                                value={this.state.distanceValue}
                                onInput={this.updateDistanceNumber}
                                className={classes.DistanceValue}
                            />
                            <div className={classes.NumberInputButton}  onClick={this.distanceIncreaseHandler}>
                                {"+"}
                            </div>
                        </div>
            
                        <div className={classes.Options}>
                            <FilterButton selected={this.state.distanceUnits==="km"} clicked={()=>{this.distanceUnitsHandler("km")}}
                                text={this.props.systemText.placeCharacteristics.trailCharacteristics.km[this.props.userLanguage]}/>
                            <FilterButton selected={this.state.distanceUnits==="miles"} clicked={()=>{this.distanceUnitsHandler("miles")}}
                                text={this.props.systemText.placeCharacteristics.trailCharacteristics.miles[this.props.userLanguage]}/>  
                        </div>
                    </div>
                 </div>
            )
        }
        
    }
    

    renderParkOrCampingFacilities = () =>{    
        return(
            <div className={classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.parkOrCampingCharacteristics.parkOrCampingFacilities.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.parkOrCampingFacilities.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateParkOrCampingFacilities(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderBeachCharacteristics = () =>{
        return(
            <div className={classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.beachCharacteristics.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.beachCharacteristics.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateBeachCharacteristics(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
                {this.renderBeachFacilities()}
                {this.renderBeachActivities()}
             </div>
        )
    }

    renderBeachFacilities = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.beachCharacteristics.beachFacilities.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.beachFacilities.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateBeachFacilities(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderBeachActivities = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.beachCharacteristics.beachActivities.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.beachActivities.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateBeachActivities(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderMTBTrailCharacteristics = () =>{
        return(
            <div className={classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.mtbTrailCharacteristics.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.mtbTrailType.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateMTBTrailType(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
                {this.renderBikeRideLevel()}
             </div>
        )
    }

    renderBikeRideLevel = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.mtbTrailCharacteristics.bikeRideLevel.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.bikeRideLevel.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateBikeRideLevel(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderDivingSiteCharacteristics = () =>{
        let subHeaderClass = "SectionHeader"
        if (this.props.RTL){
            subHeaderClass = "SectionHeader"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.foodPlaceCharacteristics.placeKosher[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.foodKosher.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateNatureTags(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderBarCharacteristics = () =>{
        return(
            <div className={classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.barCharacteristics.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.renderBarType()}
                </div>
             </div>
        )
    }

    renderBarType = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.barCharacteristics.barType[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.barType.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateBarType(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderRestaurantCharacteristics = () =>{
        return(
            <div className={classes.Section}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.placeCharacteristics.restaurantCharacteristics.title[this.props.userLanguage]}
                </div>
                {this.renderRestaurantCuisine()}
                {this.renderRestaurantServiceType()}
             </div>
        )
    }

    renderRestaurantServiceType = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.restaurantCharacteristics.restaurantCategory[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.restaurantServiceType.map((tag,index)=>{
                        let included = this.state.includedSubTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateRestaurantServiceType(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })}
                </div>
             </div>
        )
    }

    renderRestaurantCuisine = () =>{
        let subHeaderClass = "SubHeader"
        if (this.props.RTL){
            subHeaderClass = "SubHeaderRTL"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.restaurantCharacteristics.restuarantCuisine[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                {this.state.selectedCuisines.map((tag, index) => (
                        <Tag
                        key={index}
                        text={tag[this.props.userLanguage]}
                        removable={true}
                        style={"CharacteristicSelected"}
                        remove={() => {this.removeRestaurantCuisine(tag.id)}}
                        RTL = {this.props.RTL}
                      />
                    ))}          
                
                </div>
                <div className={classes.OptionSelection}>
                <OptionSelection
                    multiple={true}
                    selectedOptions={this.state.restaurantsCuisine}
                    currentSelection={""}
                    optionsList={this.state.restaurantsCuisine}
                    updateSelectedOption={this.updateRestaurantCuisine}
                    placeholder={""}
                    required={false}
                    onlyFromList={true}
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}
                    inputLanguage = {this.props.inputLanguage}
                    detectInputLanguage = {this.props.detectInputLanguage}
                /> 
                </div>
             </div>
        )
    }

    renderCampingSiteCharacteristics = () =>{
        let subHeaderClass = "SectionHeader"
        if (this.props.RTL){
            subHeaderClass = "SectionHeader"
        }
        return(
            <div className={classes.SubSection}>
                <div className={classes[subHeaderClass]}>
                    {this.props.systemText.placeCharacteristics.restaurantCharacteristics.title[this.props.userLanguage]}
                </div>
                <div className={classes.Filters}>
                
               {/*  {this.state.foodKosher.map((tag,index)=>{
                        let included = this.state.includedMainTags.includes(tag.id)
                        let style = "Characteristic"
                        if (included){
                            style = "CharacteristicSelected"
                        }
                        return(
                            <Tag 
                            key = {index}
                            clickable={true}
                            style={style}
                            clicked={()=>{ this.updateNatureTags(!included,tag.id)}}
                            text= {tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        )
                    })} */}
                </div>
             </div>
        )
    }


    renderCharacteristics = () =>{
        return(
            <div className= {classes.Characteristics}>
                <div className={classes.CharacteristicsTitle}>
                    {this.props.systemText.placeCharacteristics.placeCharacteristicsTitle[this.props.userLanguage]}
                </div>
                {this.renderGeneralCharacteristics()}
                {this.renderAudienceCharacterirstics()}
                {this.renderMainCategoryCharacteristics()}
                {this.renderCategoryCharacteristics()}
            </div>
        )
    }


    render(){
        return(
            <div className={classes.Body}>
                  <Backdrop show={this.state.showBackdrop} />
                {this.renderCharacteristics()}
            </div>
        )
    }
}

export default PlaceCharacteristics;