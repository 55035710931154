import React, { Component } from "react";
import classes from "./WelcomeWall.module.css";

import DiscoverSearchBar from "../../Components/Complex/SearchBars/DiscoverSearchBar"
import SearchBarContentType from "../../Components/UI/ContentTypeSelector/SearchBarContentType"
import PlacesList from "../Content/Places/PlacesList";
import WelcomePlacesList from "../Content/Places/WelcomePlacesList"
import PlansWall from "../Content/TripPlans/PlansWall"
import ExperiencesWall from "../Content/Experiences/ExperiencesWall"
import ScrollToTop from "../../Components/UI/Buttons/ScrollToTop"
import IconTab from "../../Components/UI/Tabs/IconTab"

import { isMobile, isMobileOnly } from "react-device-detect";

let placesIconGrey="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIconGrey.png?alt=media&token=14eefd33-8dd4-4473-aa17-babc75342117"
let placesIcon="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"
let tripsIconGrey="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTripIconGrey.png?alt=media&token=eb61b497-5906-4b11-bd33-413e77cc4e45"
let tripsIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTripIcon.png?alt=media&token=42112c10-971f-4b71-9c5b-5c2bc19e6245"
let experiencesIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FExperienceIconGrey.png?alt=media&token=e54dfc0d-7cef-4432-81e6-fd4643a8a75b"
let experiencesIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FExperienceIcon.png?alt=media&token=e6f8c146-ff24-4798-99e5-f1eeedad36d5"



export default class WelcomeWall extends Component {

    state={

    }

    componentDidMount = () =>{        
        var scrollComponent = this;
        var frame = document.getElementById("WelcomeBody");
        frame.addEventListener("scroll", function(e) {
            scrollComponent.WelcomeBodyScrollToggle();
        }, { passive: true });   
    }
    

    scrollToTop = () =>{
        var frame = document.getElementById("WelcomeBody");
        frame.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        this.props.writeAcitivityLog({
            activityKey:"WELCOMEPAGE_SCROLL_TO_TOP",
            userScreen:"WELCOMEPAGE"
        })
    }

    WelcomeBodyScrollToggle = () =>{
        var frame = document.getElementById("WelcomeBody");
        if (frame.scrollTop>window.innerHeight){
            if (!this.state.showScrollUpButton)
                this.setState({showScrollUpButton:true})
        }
        else{
            if (this.state.showScrollUpButton)
            this.setState({showScrollUpButton:false})
        }
    }

   

    viewSuggestedPlaceHandler = (categoryIndex,index) =>{
        let place = this.props.recommendedPlacesByCategory[categoryIndex].places[index]
        this.props.viewPlaceHandler(place);
    }

    renderMobileNewButton = () => {
        if (isMobileOnly){
        /*   if (this.props.loggedUser){ */
            return (
              <div className={classes.NewButton} onClick={this.props.createNewHandler}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FPlusIconNew.png?alt=media&token=072ff29e-53c9-4e1b-b4e3-7102ea9f417b"} 
                alt="" className={classes.CreateIcon}/>
                </div>
              );
        /*   }
          else{
            return (
              <div className={classes.NewButton} onClick={this.props.loginHandler}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FPlusIconNewGrey.png?alt=media&token=70c91de1-d21c-402f-bffb-90cd728c6303"} 
                alt="" className={classes.CreateIcon}  />
              </div>
              );
          } */
        }
      };

    renderSearchSection = () =>{
        return(
            <div className={classes.TopSection}>
                 {this.renderMobileNewButton()}
               {/*  <img src={welcomeImage} alt="" className={classes.WelcomeImage}/> */}
               <div className={classes.WelcomeImage} />
                    <div className={classes.SearchSection}>
                    {this.renderWelcomeMessage()}
                    {this.renderWelcomeSearchBar()}
                    {this.renderContentTypeSelection()}
                    {this.renderUserCountryWelcomeOption()}
                </div>
                
            </div>
        )       
    }

    renderContentTypeSelection = () =>{

        return(
            <div className={classes.ContentTypeSelection}>
                 
                
                 <IconTab
                    icon = {this.props.selectedContentType==="Places"? placesIcon:placesIconGrey}
                    text = {this.props.systemText.misc.placesName[this.props.userLanguage]}
                    selected = {this.props.selectedContentType==="Places"}
                    style={"Style1"}
                    clicked={()=>{this.props.updateSelectedContentType("Places")}}
                />
                <div className={classes.TabSeperator}/>
                <IconTab
                    icon = {this.props.selectedContentType==="Trips"? tripsIcon:tripsIconGrey}
                    text = {this.props.systemText.misc.tripsName[this.props.userLanguage]}
                    selected = {this.props.selectedContentType==="Trips"}
                    style={"Style1"}
                    clicked={()=>{this.props.updateSelectedContentType("Trips")}}
                /> 
            {this.props.userExperiencesFeed.length>0?(
                 <div className={classes.TabSeperator}/>  ):null}
                {this.props.userExperiencesFeed.length>0?(
                    <IconTab
                    icon = {this.props.selectedContentType==="Experiences"? experiencesIcon:experiencesIconGrey}
                    text = {this.props.systemText.misc.experiencesName[this.props.userLanguage]}
                    selected = {this.props.selectedContentType==="Experiences"}
                    style={"Style1"}
                    clicked={()=>{this.props.loadMoreExperiencesFeedHandler("SEEALLBUTTON")}}
                    />
                ):null} 
            </div>
        )
    }

    renderWelcomeMessage = () =>{
        return(
            <div className={classes.WelcomeMessage}>
                <div className={classes.WelcomeMainMessage}>
                  {this.props.systemText.welcomePage.welcomeMainMessage[this.props.userLanguage]}
                </div>
                <div className={classes.WelcomeSubMessage}>
                  {this.props.systemText.welcomePage.welcomeSubMessage[this.props.userLanguage]}
                </div>
            </div>
        )
    }

    renderUserCountryWelcomeOption = () =>{
        if (this.props.selectedContentType==="Places"){
            if (this.props.userCountryIsInCountriesToExploreList && this.props.userLanguage!==undefined){
                let countryName=this.props.systemText.countries[this.props.userCountry][this.props.userLanguage];
                return(
                  <div className={classes.UserCountryOption} 
                    onClick={this.props.userCountryHandler}>
                    {this.props.systemText.welcomePage.searchSection.currentCountry[this.props.userLanguage]+countryName}
                  </div>
                )
            }
        }
        else if (this.props.selectedContentType==="Trips"){
            return(
                <div className={classes.UserCountryOption} 
                onClick={this.props.createTemplateTrips}>
                    {this.props.systemText.welcomePage.generateTrips[this.props.userLanguage]}
                </div>
            )
        }
        else if (this.props.selectedContentType==="Experiences"){

        }
        
      }

      renderWelcomeSearchBar = () =>{
        let searchBarSectionClass = "SearchBarSection"
        if (this.props.RTL){
            searchBarSectionClass = "SearchBarSectionRTL"
        }
        // in case searching places
        let searchTags = []
        if (this.props.selectedContentType==="Places"){
            if (this.props.placesLocationSearchTags!==undefined){
                searchTags = this.props.placesLocationSearchTags
            };
            if (this.props.placesListForPlaceCreation[this.props.userCountry]!==undefined){
                searchTags = searchTags.concat(this.props.placesListForPlaceCreation[this.props.userCountry].map(place=>{return place.placeName}))
            }
        }

        let placeholderMessage = this.props.systemText.welcomePage.searchSection.searchBarPlaceholder[this.props.userLanguage];
        if (this.props.selectedContentType==="Trips"){
            searchTags = this.props.discoverTripsLocationSearchTags.filter(tag=>tag!==undefined)
            placeholderMessage = this.props.systemText.welcomePage.searchSection.searchBarTripsPlaceholder[this.props.userLanguage];
        }
        return(
            <div className={classes[searchBarSectionClass]}>
               {/*   <SearchBarContentType
                    selectedContentType = {this.props.selectedContentType}
                    updateSelectedContentType = {this.props.updateSelectedContentType}
                    RTL = {this.props.RTL}
                    showTrips = {true}
                    
                    systemText = {this.props.systemText}
                    userLanguage = {this.props.userLanguage}
                 /> */}
                <DiscoverSearchBar
                    searchTags = {searchTags}
                    placeholderMessage = {placeholderMessage}
                    detectLanguage = {this.props.detectInputLanguage}
                    inputLanguage = {this.props.inputLanguage}
                    RTL = {this.props.RTL}
                    updateFilterTags = {this.props.searchDiscoverLocationHandler}
                    noSearchResultsHandler = {this.props.noSearchResultsHandler}
                    locationsTranslator = {this.props.locationsTranslator}

                    fullWidth={true}
                />
               
            </div>
        )
      }

      renderLatestPlaces = () =>{
        if (this.props.latestViewedPlaces!==undefined && this.props.loggedUser && this.props.categoriesTranslator!==undefined){
            if (this.props.latestViewedPlaces.length >0){
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionTop}>
                        <div className={classes.SectionHeader}>
                            {this.props.systemText.welcomePage.lastPlaces.title[this.props.userLanguage]}
                        </div>
                    </div>
                    <WelcomePlacesList
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                        RTL = {this.props.RTL}
                        loggedUser = {this.props.loggedUser}
                        loginHandler = {this.props.loginHandler}

                        viewPlaceHandler={this.props.viewPlaceHandler}
                        addToPlan = {this.props.addToPlan}
                        addPlaceToFavorite = {this.props.addPlaceToFavorite}
                        removePlaceFromFavorite = {this.props.removePlaceFromFavorite}
                        updateFavoriteTags = {this.props.updateFavoriteTags}
                        shareExperience = {this.props.shareExperience}

                        favoritePlacesIDs={this.props.favoritePlacesIDs}
                        hoverHandler={()=>{return null}}
                        placesList={this.props.latestViewedPlaces}

                        categoriesTranslator = {this.props.categoriesTranslator}
                        locationsTranslator = {this.props.locationsTranslator}
                        viewType={"WelcomeList"}
                        user={this.props.user} 
                        discoverSearchLanguage={this.props.discoverSearchLanguage}
                        selectedContentWall={this.props.selectedContentWall}
                    />
                    
                </div>
            )}
        }
        else if(this.props.userLastViewedPlacesIDs!==undefined&&this.props.loggedUser){
            if (this.props.userLastViewedPlacesIDs.length >0){
                return(
                    <div className={classes.Section}>
                        <div className={classes.SectionTop}>
                            <div className={classes.SectionHeader}>
                            {this.props.systemText.welcomePage.lastPlaces.title[this.props.userLanguage]}
                            </div>
                        </div>
                        <WelcomePlacesList
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                        RTL = {this.props.RTL}
                        loggedUser = {this.props.loggedUser}
                        loginHandler = {this.props.loginHandler}

                        viewPlaceHandler={()=>{return}}
                      


                        hoverHandler={()=>{return null}}
                        placesList={this.props.userLastViewedPlacesIDs}

                        categoriesTranslator = {this.props.categoriesTranslator}
                        locationsTranslator = {this.props.locationsTranslator}
                        placeHolder={true}
                        viewType={""}
                        user={this.props.user} 
                        discoverSearchLanguage={this.props.discoverSearchLanguage}
                        selectedContentWall={this.props.selectedContentWall}
                    />
                    </div>
                )
            }
           
        }
    }

    renderLastTripPlan = () =>{
        if (this.props.latestUserCreatedTripPlan!==undefined){
           return(
            <div className={classes.Section}>
            <div className={classes.SectionTop}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.welcomePage.plans.title[this.props.userLanguage]}
                </div>
                {this.props.loggedUser?(
                     <div className={classes.SeeAllButtonDesktop} onClick={()=>{
                        this.props.userPlansHandler()
                        this.props.writeAcitivityLog({
                            activityKey:"VIEW_ALL_MY_TRIPS",
                            userScreen:"WELCOMEPAGE"
                        })
                        }}>
                            {this.props.systemText.welcomePage.plans.allUserPlansButton[this.props.userLanguage]}
                    </div>
                ):null}
               
            </div>
            
            <div className={classes.SectionContent}>
                <PlansWall
                viewHandler={this.props.viewPlanHandler}
                favoritePlansIDs={[]}
                hoverHandler={()=>{return null}}
                plansList={[this.props.latestUserCreatedTripPlan]}
                viewType={""}
                placeHolder={true}
                viewOnly={true}
                user={this.props.user} 

                locationsTranslator = {this.props.locationsTranslator}
                selectedContentWall={this.props.selectedContentWall}
                systemText = {this.props.systemText}
                userLanguage = {this.props.userLanguage}
                RTL = {this.props.RTL}
                />

             </div>
             <div className={classes.SeeAllButtonMobile} onClick={this.props.userPlansHandler}>
                {this.props.systemText.welcomePage.plans.allUserPlansButton[this.props.userLanguage]}
            </div>
        </div>
         )
        }
    }


      renderRecommendedPlaces = () =>{
        if (this.props.userCountryIsInCountriesToExploreList && this.props.categoriesTranslator!==undefined){
            let recommendedPlaces = [];
            let recommendedPlacesCatIds = []
            let viewType = ""
            let placeHolder = true
            if (this.props.recommendedPlacesByCategory!==undefined){
                recommendedPlaces=this.props.recommendedPlacesByCategory
                viewType = "WelcomeList"
                placeHolder = false
            }
            recommendedPlacesCatIds = recommendedPlaces.map(category=>{return category.id})
           /*  if (this.props.userRecommendedPlacesByCategory!==undefined){
                for (let i=0 ; i<this.props.userRecommendedPlacesByCategory.length ; i++){
                    if (!recommendedPlacesCatIds.includes(this.props.userRecommendedPlacesByCategory[i].id) &&
                    this.props.userRecommendedPlacesByCategory[i].places.length>2){
                        recommendedPlaces.unshift(this.props.userRecommendedPlacesByCategory[i])
                    }
                }
            } */
            /* console.log("recommendedPlaces")
            console.log(recommendedPlaces) */
            let countryName=this.props.systemText.countries[this.props.userCountry][this.props.userLanguage];
            return(
                <div className={classes.Section}>
                    <div className={classes.SectionTop}>
                        <div className={classes.SectionHeader}>
                            {this.props.systemText.welcomePage.recommendedMyCountry.title[this.props.userLanguage]+countryName}
                        </div>
                    </div>
                    {recommendedPlaces.slice(0,10).map((category, categoryIndex) => {
                        let header = ""
                        let placesList = [{},{},{},{},{}]
                        if (Object.keys(category).length !== 0 && category.constructor === Object){
                            if (category.userRecommended){
                                //add /* this.props.systemText.welcomePage.recommendedMyCountry.userRecommendedCategory[this.props.userLanguage]+ */
                                if (this.props.categoriesTranslator[category.id]!==undefined){
                                    header = this.props.categoriesTranslator[category.id][this.props.userLanguage]
                                }
                                else{
                                    header = category.id
                                }
                            }
                            else{
                                //add /* this.props.systemText.welcomePage.recommendedMyCountry.defaultRecommendedCategory[this.props.userLanguage] + */
                                if (this.props.categoriesTranslator[category.id]!==undefined){
                                    header = this.props.categoriesTranslator[category.id][this.props.userLanguage]
                                }
                                else{
                                    header = category.id
                                }
                            }
                            placesList = category.places
                        }
                       
                        return(
                            <div className={classes.SubSection} key ={categoryIndex}>
                                <div className={classes.SubSectionHeader} >
                                    
                                    <div className={classes.SuggestionHeader} onClick={()=>{if (this.props.categoriesTranslator[category.id]!==undefined) {this.props.expandOnCategory(this.props.categoriesTranslator[category.id])}}} >{header}</div>
                                    </div>
                                    <WelcomePlacesList
                                    userLanguage={this.props.userLanguage}
                                    systemText={this.props.systemText}
                                    RTL = {this.props.RTL}
                                    loggedUser = {this.props.loggedUser}
                                    loginHandler = {this.props.loginHandler}

                                    viewPlaceHandler={this.props.viewPlaceHandler}
                                    indexViewHandler = {true}
                                    viewHandler={(index)=>{this.viewSuggestedPlaceHandler(categoryIndex,index)}}
                                    addToPlan = {this.props.addToPlan}
                                    addPlaceToFavorite = {this.props.addPlaceToFavorite}
                                    removePlaceFromFavorite = {this.props.removePlaceFromFavorite}
                                    updateFavoriteTags = {this.props.updateFavoriteTags}
                                    shareExperience = {this.props.shareExperience}

                                    favoritePlacesIDs={this.props.loggedUser? this.props.favoritePlacesIDs : []}
                                    hoverHandler={()=>{return null}}
                                    placesList={placesList}
                                    placeHolder = {placeHolder}

                                    categoriesTranslator = {this.props.categoriesTranslator}
                                    locationsTranslator = {this.props.locationsTranslator}
                                    viewType={"WelcomeList"}
                                    user={this.props.user} 
                                    discoverSearchLanguage={this.props.discoverSearchLanguage}
                                    selectedContentWall={this.props.selectedContentWall}
                                />
                               
                            </div>)
                        }   )}
                   
                </div>
            )
        }
    }

    renderExperiencesFeed = () =>{
        if (this.props.userExperiencesFeed!==undefined&&this.props.loggedUser){
            let seeAllButtonClass = "SeeAllButton"
            if (this.props.RTL){
                seeAllButtonClass = "SeeAllButtonRTL"
            }
            if (this.props.userExperiencesFeed.length>0){
                return (
                    <div  className={classes.ExperiencesWall}>
                         <div className={classes.ExperiencesHeader}>
                            <div className={classes.SectionHeader}>
                                {this.props.systemText.welcomePage.experiencesFeed.title[this.props.userLanguage]}
                            </div>
                            {this.props.loadedMoreExperiencesToFeed?(
                            <div className={classes[seeAllButtonClass]} onClick={()=>{this.props.loadMoreExperiencesFeedHandler("SEEALLBUTTON")}}>
                                 {this.props.systemText.welcomePage.experiences.showMoreExperiences[this.props.userLanguage]}
                            </div>
                            ):null}
                          
                        </div>
                   
                        <ExperiencesWall
                            experiencesList={this.props.userExperiencesFeed}
                            user={this.props.user}
                            viewStyle={"wall"}
                            deleteHandler={(index)=>{this.props.deleteExperienceHandler(this.props.userExperiencesFeed[index])}}
                            editHandler={(index)=>{this.props.editExperienceHandler(this.props.userExperiencesFeed[index])}}
                            removeTaggingHandler={(index)=>{this.props.removeTaggingHandler(this.props.userExperiencesFeed[index])}}
                            viewExperiencePlace={this.props.viewExperiencePlace}
                            viewExperiencePlan={this.props.viewExperiencePlan}
                            selectedContentWall={this.props.selectedContentWall}
                            addToPlan={(index)=>{this.props.addToPlan(this.props.userExperiencesFeed[index],"Experience")}}
                            removeFromPlan={(index)=>{this.props.removeExperienceFromPlanHandler(this.props.userExperiencesFeed[index])}}
                        
                            viewUserProfileHandler = {this.props.viewUserProfileHandler}
                            viewImagesFullscreen = {this.props.viewImagesFullscreen}

                            writeAcitivityLog = {this.props.writeAcitivityLog}

                            followingUsersIDs = {this.props.followingUsersIDs}
                            myFriendsIDs = {this.props.myFriends.map(friend=>{return friend.userID})}
                            RTL = {this.props.RTL}
                            userLanguage={this.props.userLanguage}
                            systemText={this.props.systemText}
                        />
                        {this.props.loadedMoreExperiencesToFeed?(
                            <div className={classes.ExperiencesFeedButton} onClick={()=>{this.props.loadMoreExperiencesFeedHandler("LOADMOREBUTTON")}}>
                                {this.props.systemText.welcomePage.experiences.showMoreExperiences[this.props.userLanguage]}
                            </div>):null}
                    </div>
                );
            }
            else{
                return(
                    <div  className={classes.EmptyWall}>
                        <div className={classes.NoExperiencesMessage}>
                        {this.props.systemText.welcomePage.experiences.noExperiencesMessage[this.props.userLanguage]}
                        </div>
                        <div className={classes.CallToAction} onClick={()=>{this.props.shareExperience("Experience")}}>
                        {this.props.systemText.welcomePage.experiences.shareExperience[this.props.userLanguage]}
                        </div>
                    </div>
                )
            }
            
        }
    }

    renderWall = () =>{
        if (!this.props.loadingPreloadData){
            return(
                <div className={classes.Wall}>
                    {this.renderLatestPlaces()}
                    {this.renderLastTripPlan()}
                    {this.renderRecommendedPlaces()}
                    {this.renderExperiencesFeed()}
                </div>
            )
        }
       
    }

   render(){
    return(
        <div className={[classes.Body,classes[this.props.RTLClass]].join(" ")} id="WelcomeBody">
            {this.state.showScrollUpButton?(<ScrollToTop scrollToTop={this.scrollToTop}/>):null}
            {this.renderSearchSection()}
            {this.renderWall()}
        </div>
    )
}
}