import React from "react";
import Place from "./Place";


const PlacesList = (props) =>
        props.placesList.map((place, index) =>{
          return(
            <Place 
            placeID={place.placeID}
            placeName={place.placeName}
            imagePreview={place.imagePreview}
            placeLocation={place.placeLocation}
            placeStatus = {place.placeStatus}
            contributors= {place.contributors}
            owner= {place.owner}
            placeCategory = {place.placeCategory}
            placeNewCategory={place.placeNewCategory}
            placePermanentlyClosed={place.placePermanentlyClosed}
            placeTemporaryClosed={place.placeTemporaryClosed}
            placeCharacteristics={place.placeCharacteristics}

            viewType={props.viewType}
            planMap = {props.planMap}
            placeHolder={props.placeHolder}
            planPlacesIDs={props.planPlacesIDs}
            dayNames = {props.dayNames}

            favoritePlacesIDs={props.favoritePlacesIDs}
            selectedContentWall={props.selectedContentWall}
            user={props.user} 
            loginHandler = {props.loginHandler}

            categoriesTranslator = {props.categoriesTranslator}
            locationsTranslator = {props.locationsTranslator}
            

            addPlaceToPlan = {props.addPlaceToPlan}
            removeFromPlanHandler = {props.removeFromPlanHandler}
            placeInPlan={props.placeInPlan}

            addPlaceToFavorite={props.addPlaceToFavorite}
            updateFavoriteTags={props.updateFavoriteTags}
            removePlaceFromFavorite={props.removePlaceFromFavorite}
            addToPlan={props.addToPlan}
            viewHandler={props.viewHandler}
            editPlaceHandler={props.editPlaceHandler}
            deletePlaceHandler={props.deletePlaceHandler}
            shareExperience={props.shareExperience}
            
            hoverHandler={props.hoverHandler}
            clickedMarkerIndex={props.clickedMarkerIndex}
            listLenght = {props.placesList.length -1}

            userLanguage={props.userLanguage}
            systemText={props.systemText}
            loggedUser={props.loggedUser}
            RTL = {props.RTL}

            adminUser={props.adminUser}
            adminMode={props.adminMode}

            index={index}
            key={place.placeID}
            />
            );
          });
  
  export default PlacesList;