import React, { Component } from "react";

import classes from "./AddToFavorite.module.css";

import ModalTopSection from "../../UI/Misc/ModalTopSection"
import Tag from "../../UI/Tag/Tag"
import TaggingSection from "../Tagging/TaggingSection"

import DetectInputLanguage from "../../Functions/DetectInputLanguage"

class AddToFavorite extends Component {

    state={name:"",favoriteTags:[...this.props.object.favoriteSearchTags], definedTags:[] ,tagsInputField:"", add:this.props.add,update:this.props.update, changesMade:false}

    componentDidMount=()=>{
        document.body.style.overflow = "hidden";
        switch (this.props.contentType){
            case "Place":{
                let tags = [];
                let placeName="";
                //get the relevant language or at least any language name
                if (this.props.object.placeName[this.props.inputLanguage]!==undefined){
                  placeName=this.props.object.placeName[this.props.inputLanguage]
                }
                else if(this.props.object.placeName["English"]!==undefined){
                  placeName=this.props.object.placeName["English"]
                }
                else{
                  let placesNames =  Object.keys(this.props.object.placeName);
                  placeName=this.props.object.placeName[placesNames[0]]
                }
                tags.push(placeName);

                let placeCategory="";
                for (let i=0;i<this.props.object.placeCategory.length ; i++){
                  let category = this.props.categoriesTranslator[this.props.object.placeCategory[i]]
                  if (category[this.props.userLanguage]!==undefined){
                    placeCategory=category[this.props.userLanguage]
                  }
                  else if(category["English"]!==undefined){
                    placeCategory=category["English"]
                  }
                  else{
                    let categoryNames =  Object.keys(category);
                    placeCategory=category[categoryNames[0]]
                  }
                  tags.push(placeCategory);
                }
                

               

                let placeCountryTag={}
                let placeCountryText=""
                if (this.props.object.placeLocation.locationTree.placeCountry.treeID!==undefined){
                  placeCountryTag = this.props.locationsTranslator[this.props.object.placeLocation.locationTree.placeCountry.treeID]
                }
                else{
                  placeCountryTag = this.props.locationsTranslator[this.props.object.placeLocation.locationTree.placeCountry]
                }
                if (placeCountryTag[this.props.userLanguage]!==undefined){
                  placeCountryText=placeCountryTag[this.props.userLanguage]
                }
                else if(placeCountryTag["English"]!==undefined){
                  placeCountryText=placeCountryTag["English"]
                }
                else{
                  let countryNames =  Object.keys(placeCountryTag).filter(key=>key!=="treeID");;
                  placeCountryText=placeCountryTag[countryNames[0]]
                }
                tags.push(placeCountryText)
              

                if (this.props.object.placeLocation.locationTree.placeCity!==undefined){
                  let placeCity={}
                  let placeCityText = ""
                  if (this.props.object.placeLocation.locationTree.placeCity.treeID!==undefined){
                    placeCity = this.props.locationsTranslator[this.props.object.placeLocation.locationTree.placeCity.treeID]
                  }
                  else{
                    placeCity = this.props.locationsTranslator[this.props.object.placeLocation.locationTree.placeCity]
                  }
                  if (placeCity[this.props.userLanguage]!==undefined){
                    placeCityText=placeCity[this.props.userLanguage]
                  }
                  else if(placeCity["English"]!==undefined){
                    placeCityText=placeCity["English"]
                  }
                  else{
                    let cityNames =  Object.keys(placeCity).filter(key=>key!=="treeID");;
                    placeCityText=placeCity[cityNames[0]]
                  }
                  tags.push(placeCityText)
                  }
                if (this.props.object.placeLocation.locationTree.placeRegion!==undefined){
                  let placeRegion={}
                  let placeRegionText = ""
                  if (this.props.object.placeLocation.locationTree.placeRegion.treeID!==undefined){
                    placeRegion = this.props.locationsTranslator[this.props.object.placeLocation.locationTree.placeRegion.treeID]
                  }
                  else{
                    placeRegion = this.props.locationsTranslator[this.props.object.placeLocation.locationTree.placeRegion]
                  }
                  if (placeRegion[this.props.userLanguage]!==undefined){
                    placeRegionText=placeRegion[this.props.userLanguage]
                  }
                  else if(placeRegion["English"]!==undefined){
                    placeRegionText=placeRegion["English"]
                  }
                  else{
                    let regionNames =  Object.keys(placeRegion).filter(key=>key!=="treeID");;
                    placeRegionText=placeRegion[regionNames[0]]
                  }
                  tags.push(placeRegionText)
                  }
                this.setState({name:this.props.object.placeName[this.props.userLanguage], definedTags:tags});
                  
                break;
            }
            case "Trip":{
              let tags = [];
              //name 
              tags.push(this.props.object.planName)

              //owner
              tags.push(this.props.object.owner.ownerName)


              //location tags
              let tripCountries = []
              if (this.props.object.tripCountries!==undefined){
                tripCountries = this.props.object.tripCountries
              }
              let tripLocationTags = []
              if (this.props.object.tripLocationTags!==undefined){
                tripLocationTags = this.props.object.tripLocationTags
              }
              for (let j=0 ; j<tripCountries.length ; j++){
                if (this.props.locationsTranslator[tripCountries[j]]!==undefined){
                  tags.push(this.props.locationsTranslator[tripCountries[j]][this.props.userLanguage])
                }
              }
              for (let j=0 ; j<tripLocationTags.length ; j++){
                if (this.props.locationsTranslator[tripLocationTags[j]]!==undefined){
                  tags.push(this.props.locationsTranslator[tripLocationTags[j]][this.props.userLanguage])
                }
              }

              //main categories
             /*  if (this.props.object.mainCategories[0]){
                tags.push(this.props.systemLanguage.categories.categoriesSectionsNames.cultural[this.props.userLanguage])
              }
              if (this.props.object.mainCategories[1]){
                tags.push(this.props.systemLanguage.categories.categoriesSectionsNames.nature[this.props.userLanguage])
              }
              if (this.props.object.mainCategories[2]){
                tags.push(this.props.systemLanguage.categories.categoriesSectionsNames.activities[this.props.userLanguage])
              }
              if (this.props.object.mainCategories[3]){
                tags.push(this.props.systemLanguage.categories.categoriesSectionsNames.sport[this.props.userLanguage])
              }
              if (this.props.object.mainCategories[4]){
                tags.push(this.props.systemLanguage.categories.categoriesSectionsNames.food[this.props.userLanguage])
              }
              if (this.props.object.mainCategories[5]){
                tags.push(this.props.systemLanguage.categories.categoriesSectionsNames.accommodation[this.props.userLanguage])
              } */

              //duration
            /*   let planStartDate = new Date(this.props.object.planDates.startDate)
              let planEndDate = new Date(this.props.object.planDates.endDate)
              let planDuration = planEndDate.getDate() - planStartDate.getDate() + 1; 
              tags.push(planDuration+" "+this.props.systemLanguage.plan.planWall.days[this.props.userLanguage])

              //participants
              if (this.props.object.participants.adults>0 && 
                this.props.object.participants.teen===0 &&
                this.props.object.participants.kids===0 &&
                this.props.object.participants.infants===0){
                tags.push(this.props.systemLanguage.plan.createPlan.participants.adults[this.props.userLanguage])
              }
              if (this.props.object.participants.teen>0){
                tags.push(this.props.systemLanguage.plan.createPlan.participants.teen[this.props.userLanguage])
              }
              if (this.props.object.participants.kids>0){
                tags.push(this.props.systemLanguage.plan.createPlan.participants.kid[this.props.userLanguage])
              }
              if (this.props.object.participants.infants>0){
                tags.push(this.props.systemLanguage.plan.createPlan.participants.infant[this.props.userLanguage])
              }

              //locations
              for (let i=0 ; i<this.props.object.locationsTags.length ; i++){
                let locationTag=""
                if (this.props.object.locationsTags[i][this.props.discoverSearchLanguage]!==undefined){
                  locationTag=this.props.object.locationsTags[i][this.props.discoverSearchLanguage]
                }
                else if(this.props.object.locationsTags[i]["English"]!==undefined){
                  locationTag=this.props.object.locationsTags[i]["English"]
                }
                else{
                  let languagesList =  Object.keys(this.props.object.locationsTags[i]).filter(key=>key!=="treeID");
                  locationTag=this.props.object.locationsTags[i][languagesList[0]]
                }
                tags.push(locationTag)
                } */
             
              this.setState({name:this.props.object.planName, definedTags:tags});
              break;
            }
            default: return null;
        }
    }

    componentWillUnmount=()=>{
        document.body.style.overflow = "unset"
    }


    updateTags = (tags, tagsInputField) => {  
        this.setState({ favoriteTags:  tags, tagsInputField: tagsInputField,changesMade:true });
      };

      saveHandler= async ()=>{
        let newTags = [];
        for (let i=0 ; i<this.state.favoriteTags.length ; i++){
          await this.refs.detectInputLanguage.detectLanguage(this.state.favoriteTags[i]).then(result => {
            let newTag={};
            newTag[result]=this.state.favoriteTags[i]
            newTags.push(newTag)
         });
        }
          this.props.addToFavorite(newTags);
          this.props.discard();
      }

    renderTopSection = () => {
        let title = this.props.systemText.misc.favorites.addToFavorite.title[this.props.userLanguage];
        if (this.state.update){
          title = this.props.systemText.misc.favorites.addToFavorite.updateTitle[this.props.userLanguage];
        }
        let buttonName ="";
        if (this.state.add){
          buttonName=this.props.systemText.misc.buttons.saveButton[this.props.userLanguage]
        }
        else if (this.state.update){
          buttonName=this.props.systemText.misc.buttons.updateButton[this.props.userLanguage]
        }
        return (
          <ModalTopSection
           saveReady={true}
            title={title}
            discard={this.props.discard}
            buttonText={buttonName}
            clicked={this.saveHandler}
            showButton={true}
            RTL = {this.props.RTL}
            modalView = {true}
          />
        );
      };

    renderTitle = () =>{
        return(<div className={classes.Title}>{this.state.name}</div>)
    }

    renderUserTags = () => {
        return (
          <TaggingSection
              selectedTags={this.state.favoriteTags}
              userTags={[]}
              updateTags={this.updateTags}
              placeholder= {this.props.systemText.misc.favorites.addToFavorite.tagsPlaceholder[this.props.userLanguage]}
              userLanguage={this.props.userLanguage}
              RTL = {this.props.RTL}
          />
        ); 
      };
      renderExistingTags = () =>{
        return(
          <div className={classes.ExistingTags}>
              <div className={classes.SubHeader} >{this.props.systemText.misc.favorites.addToFavorite.predefinedTags[this.props.userLanguage]}</div>
              <div className={classes.TagItems}>
               {this.state.definedTags.map((tag, index) => (
                <Tag key={tag} 
                text={tag} 
                style="FavoritePreviewTag" 
                RTL = {this.props.RTL}/>
              ))} 
            </div>
          </div>
         
        )
      }
      detectInputLanguage = () =>{
        return (<DetectInputLanguage
            ref="detectInputLanguage"
          />)
      }
   

    render(){
        let modalClass = ""
        if (this.props.RTL){
          modalClass = "ModalRTL"
        }
        return(
            <div className={[classes.Modal,classes[modalClass]].join(" ")}>
               {this.detectInputLanguage()}
              {this.renderTopSection()}
              <div className={classes.Content}>
                {this.renderTitle()}
                {this.renderExistingTags()}
                {this.renderUserTags()}
              </div>
            </div>)
    }
}
export default AddToFavorite;