import React from "react";
import classes from "./NavigationMenu.module.css";

const navigationMenu = props => {

  const renderBody = () =>{
    let RTL = "LTR"
    let iconClass = "Icon"
    let toolOptionClass = "ToolOption"
    if (props.RTL){
      iconClass = "IconRTL"
      toolOptionClass = "ToolOptionRTL"
    }
    if (props.bodyRTL){
      RTL = "RTL"
    }
    return(
      <div className={[classes.Body,classes[RTL]].join(" ")} >
         {props.tools.map((tool,index) => (
           <div key={index} className={classes[toolOptionClass]} onClick={(e) => {e.stopPropagation();
            tool.action();
          }}>
              {tool.icon!==undefined?(
                <img src={tool.icon} alt="" className={classes[iconClass]}/>
              ):null}
              {tool.text}
          </div>
          ))}
      </div>
    )
  }
  return (
    <div className={classes[props.modalClass]}>
        {renderBody()}
    </div>
  );
};

export default navigationMenu;
