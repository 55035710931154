import React, { Component } from "react";
import classes from "./UsersSearch.module.css";

import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop";
import UserItem from "./UserItem";

class UsersSearch extends Component {
  state = {
    searchInput: "",
    showSuggestions: false,
    showBackdrop: false,
    searchSuggestions: []
  };


resetInput = () =>{
  this.setState({
    searchInput: "",
    showSuggestions: false,
    showBackdrop: false,
    searchSuggestions: []
  })
}

  //Handling input field for new tag
  searchInputHandler = event => {
    //In case user pressed 'esc' key, clean the input field
    if (event.keyCode === 27) {
      this.setState({
        searchInput: "",
        showSuggestions: false,
      
        searchSuggestions: []
      });
      this.props.updateSearchingFriend(false);
      return;
    }
    //In case user pressed 'enter' key, add the tag to experience tags array
    //Use the selectTagSuggestion function for that
    if (event.key === "Enter") {
      //In case user pressed 'enter' with blank input, do nothing
    
      return;

    } else {
      //Get suggestions for user existing tags based in sub-input
      let searchSuggestions = this.getSuggestions(event.target.value);
      this.setState({ searchInput: event.target.value });

      //In case user entered some characters, the suggestions should be shown
      if (event.target.value !== "" && searchSuggestions.length !== 0) {
        this.props.updateSearchingFriend(true)
        this.setState({
          showSuggestions: true,
        
          searchSuggestions: searchSuggestions
        });

        //If no suggetions exist based on entered value, don't show the suggestions
      } else if (searchSuggestions.length === 0) {
        this.props.updateSearchingFriend(false)
        this.setState({
          showSuggestions: false,
        
          searchSuggestions: []
        });
      } else {
        this.props.updateSearchingFriend(false)
        //Input is blank
        this.setState({
          showSuggestions: false,
         
          searchSuggestions: []
        });
      }
    }
  };

  //Adding tag of (tagValue) to list of experience's tags
  //In case tag already in the list nothing will happen


  //Getting tags suggestions from user existing tags
  //Using the value entered so far to filter tags list
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
        return inputLength === 0
        ? []
        : this.props.usersList.filter(
            user => user.displayName.toLowerCase().includes(inputValue)
          );    
  };

  backdropHandler = () => {
    this.setState({
      showBackdrop: false,
      showSuggestions: false
    });
  };



  renderSuggestions = () => {
    if (this.state.showSuggestions) {
      return (
        <div className={classes.Suggestions}>
          {this.state.searchSuggestions.map((user, index) => (
               
               <UserItem
                key={user.userID} 
               user={user} 
               type={this.props.type}
               existingList={this.props.existingList}
               usersListFollow = {this.props.usersListFollow}
               requestFriendship={()=>{this.props.friendRequest(user)}}
               followUser={()=>{this.props.followUser(user)}}
               stopFollow = {this.props.stopFollow}
               removeFriend={this.props.removeFriend}
               acceptFriendship={this.props.acceptFriendship}
               addToList={(user)=>{this.setState({ searchInput: "",
               showSuggestions: false,
               searchSuggestions: []})
               this.props.addToList(user)}}
               isOwner={this.props.isOwner}
               viewHandler = {this.props.viewHandler}

               userLanguage={this.props.userLanguage}
               systemText={this.props.systemText}
               RTL = {this.props.RTL}
               />
           ))}
        </div>
      );
    }
  };


  renderSearchBar = () => {
    return (
      <div className={classes.SearchBar}>
        {this.renderSearchInput()}
        {this.renderSuggestions()}

      </div>
    );
  };
  /*  className={classes.SearchInputWithSuggestions} */
  renderSearchInput = () => {
      return (
        <div className={classes.SearchInput}>
          <input
            ref={input => {
              this.nameInput = input;
            }}
            className={classes.InputText}
            type="text"
            placeholder={this.props.placeholder}
            onChange={this.searchInputHandler}
            onKeyDown={this.searchInputHandler}
            onKeyPress={this.searchInputHandler}
            value={this.state.searchInput}
          />
        </div>
      );
    
  };

 
  


  render() {
    return (
      <div style={{ width: "100%" }}>
        <TransparentBackdrop
          show={this.state.showBackdrop}
          clicked={this.backdropHandler}
        />
        <div className={classes.Body}>{this.renderSearchBar()}</div>
      </div>
    );
  }
}
export default UsersSearch;
