

import React, { Component } from "react";

import classes from "./ViewPlace.module.css";

import { Editor,EditorState , convertFromRaw, convertToRaw} from 'draft-js';

import ModalTopSection from "../../../Components/UI/Misc/ModalTopSection"
import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop";
import Backdrop from "../../../Components/UI/Backdrop/Backdrop";
import Toolkit from "../../../Components/UI/Toolbar/Toolkit";
import TextButton from "../../../Components/UI/Buttons/TextButton";
import ImageCarusel from "../../../Components/Complex/Images/ImageCarusel"
import OpenHoursPreview from "../../../Components/Complex/OpenHours/OpenHoursPreview"
import ViewPlaceCharacteristics from "../../../Components/Complex/PlaceCharacteristics/ViewPlaceCharacteristics";
import EditPlace from "../Places/PlaceEditor";
import Tag from "../../../Components/UI/Tag/Tag"
import PlaceFeed from "./PlaceFeed";
import addToPlanIcon from "../../../visuals/ContentObjects/AddItemToPlan.png"
import addToPlanIconFade from "../../../visuals/ContentObjects/AddItemToPlanFade.png"
import experienceIcon from "../../../visuals/ContentObjects/ExperienceIcon.png"
import experienceIconFade from "../../../visuals/ContentObjects/ExperienceIconFade.png"
import inFavoriteIcon from "../../../visuals/ContentObjects/InFavorites.png"
import addToFavoriteIcon from "../../../visuals/ContentObjects/InFavoritesGrey.png"
import addToFavoriteIconFade from "../../../visuals/ContentObjects/InFavoritesFade.png"

import { isMobile, isMobileOnly } from "react-device-detect";
import {
    StaticGoogleMap,
    Marker,
  } from 'react-static-google-map';


let phoneIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FPhoneIcon.png?alt=media&token=5a4c9e74-a37e-4ab4-b65e-3fecb4e219d7";
let facebookIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFacebookIcon.png?alt=media&token=69773ce6-12e9-4f9a-a136-df9d0f153433"
let instagramIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FInstagramIcon.png?alt=media&token=2714ec72-d1e6-4a84-8044-9baf80619a48"
let twitterIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FTwitterIcon.png?alt=media&token=c74c2fef-0d79-43fd-a666-db6526472731"
let websiteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FWebsiteIcon.png?alt=media&token=ff1f4e34-a55c-4ec6-b95b-fadccb109327"
let linkIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FWebLink.png?alt=media&token=96064df3-8f6c-44fa-adb3-1da869d468e2"

let wazeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FWazeIcon.png?alt=media&token=4c088378-33c8-4d04-8c27-5872760f5b31"
let googleMapsIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FGoogleMapsIcon.png?alt=media&token=8268d47c-2c90-41b6-9b09-6c1c8b0ea75d"

let likedIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLikeIcon.png?alt=media&token=2c9382fc-0df7-4e31-8cbe-a06caded454a"
let dislikedIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDislikeIcon.png?alt=media&token=7c1b777c-384c-4276-b47a-f3d4f7883aa7"

let starIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FStarIcon.png?alt=media&token=e6850dda-95a3-49e7-bb49-dddb9a1c3331"
let googleIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FGoogleIcon%20(1).png?alt=media&token=833703e9-db19-427e-87a2-0a179c63c13c"

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"


class ViewPlace extends Component {
    state={
        editMode:this.props.editMode,
        favoriteTools: false,
        showTransparentBackdrop: false,
        showBackdrop: false,
        showLocation: false,
        placeLanguage:this.props.placeLanguage,
        shownPlaceHighlights:[],

        hideTop:false
    }

    componentWillMount = () =>{
        document.body.style.overflow = "hidden"   
        this.handlePlaceLangaugeUpdate(this.props.placeLanguage)
       this.handleHoursRows() 
    }

    handleHoursRows =async () =>{
        await this.setState({hoursAreSet: false})
        let hoursRows=[...this.props.selectedPlace.hoursRows];
        for (let i=0 ; i<hoursRows.length ; i++){
            if (hoursRows[i].specialDates!==undefined){
                let specialDates =  hoursRows[i].specialDates
                specialDates.startDate=new Date(specialDates.startDate);
                specialDates.endDate=new Date(specialDates.endDate);
                hoursRows[i].specialDates=specialDates
            }
            if (hoursRows[i].hoursArray!==undefined){
                for (let j=0 ; j<hoursRows[i].hoursArray.length ; j++){
                    if (hoursRows[i].hoursArray[j].open !=="Not specified"){
                        hoursRows[i].hoursArray[j].open = new Date(hoursRows[i].hoursArray[j].open);
                    }
                    if (hoursRows[i].hoursArray[j].close !=="Not specified"){
                        hoursRows[i].hoursArray[j].close = new Date(hoursRows[i].hoursArray[j].close);
                    }
                    
                }
            }
            else{
                hoursRows[i].hoursArray=[];
            }
        }
        this.setState({hoursRows:hoursRows,hoursAreSet:true})
    }

    componentDidUpdate = (prevProps,prevState) =>{
        if (this.props.selectedPlace!==prevProps.selectedPlace){
            this.handlePlaceLangaugeUpdate(this.state.placeLanguage)
        }
        if (this.props.placeLastModified!==prevProps.placeLastModified){
            this.handleHoursRows()
            this.quickLinksHandler()
        }
        
    }

    componentDidMount = () =>{
        this.setState({ screenWidth: window.innerWidth, screenHeight: window.innerHeight });
        this.quickLinksHandler()
        var scrollComponent = this;
        var frame = document.getElementById("placeBodyContent");
        frame.addEventListener("scroll", function(e) {
          scrollComponent.bodyScrollToggle();
        });      
    }

    bodyScrollToggle = () =>{
        var frame = document.getElementById("placeBodyContent");
        if (frame===null) return
        let scrollTop = frame.scrollTop
        if (scrollTop>200&&!this.state.hideTop){
            this.setState({hideTop:true})
        }
        else if (scrollTop<200&&this.state.hideTop){
            this.setState({hideTop:false})
        }
    }

    loadFriendsExperiences = () =>{
        if (this.props.placeFriendsExperiences===undefined){
            this.props.loadFriendsExperiencesForPlace()
        }
    }

    loadMyExperiences = () =>{
        if (this.props.placeMyExperiences===undefined){
            this.props.loadMyExperiencesForPlace()
        }
    }

    quickLinksHandler = ()=>{
        let quickLinks = {}
        if (this.props.navigation!==undefined){
            if (this.props.navigation.googleMapsLink!==undefined){
                quickLinks.googleMapsLink = this.props.navigation.googleMapsLink
            }
            if (this.props.navigation.wazeLink!==undefined){
                quickLinks.wazeLink = this.props.navigation.wazeLink
            }    
        }
        if (this.props.contactInfo!==undefined){
            if (this.props.contactInfo.phoneNumber!==undefined){
                quickLinks.phoneNumber = "tel:"+this.props.contactInfo.phoneNumber    
            }
            if (this.props.contactInfo.officialWebsiteLink!==undefined){
                quickLinks.officialWebsiteLink = this.props.contactInfo.officialWebsiteLink
                if (!quickLinks.officialWebsiteLink.startsWith("http")){
                    quickLinks.officialWebsiteLink = "https://"+quickLinks.officialWebsiteLink
                }
            }  
            if (this.props.contactInfo.facebookLink!==undefined){
                quickLinks.facebookLink = this.props.contactInfo.facebookLink
            }
            if (this.props.contactInfo.instagramLink!==undefined){
                quickLinks.instagramLink = this.props.contactInfo.instagramLink
            }  
            if (this.props.contactInfo.twitterLink!==undefined){
                quickLinks.twitterLink = this.props.contactInfo.twitterLink
            }
           
        }
      this.setState({quickLinks: quickLinks})
    }
    
    componentWillUnmount=()=>{
        document.body.style.overflow = "auto"
        this.setState({ screenWidth: 0, screenHeight: 0});
    }


    handlePlaceLangaugeUpdate = language =>{
        try {
            JSON.parse(this.props.placeDescription[language]);
            this.setState({placeDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.placeDescription[language]))),
              jsonString: true
              })
        } catch (e) {
       
        }
        let placeName=this.props.placeName[language];
        this.setState({placeName:placeName})
    } 

    handleLanguageSelection = async language =>{
        this.setState({showLanguageOptions:false,showLanguageBackdrop:false})
        if (language===this.state.placeLanguage){
            return;
        }
        else{
            if (this.props.placeLanguages.includes(language)){
                await this.setState({placeLanguage:language})
                this.handlePlaceLangaugeUpdate(language)
            }
          /*   else{
                this.setState({showCreateNewLanguage:true,showBackdrop:true,newLanguage:language})
            } */
        }
        
      }

    requestOwnershipHandler = () =>{
        let requestDetails = {
            placeID: this.props.placeID,
            placeName: this.state.placeName,
            requestTopic: "requestOwnership"
        }
        this.props.contactFormHandler(requestDetails)
    }

    copyrightHandler = () =>{
        let requestDetails = {
            placeID: this.props.placeID,
            placeName: this.state.placeName,
            requestTopic: "imageCopyrights"
        }
        this.props.contactFormHandler(requestDetails)
    }

    discardBackdrop = () =>{
        this.setState({favoriteTools: false,
            showTransparentBackdrop: false,
              viewImages:false,
              showLanguageOptions:false,
            showAdditionalTools:false});
    }

    editHandler =()=>{
        if (this.props.contributors.map(user=>{return user.ownerID}).includes(this.props.user.userID)||this.props.adminUser){
            this.setState({editMode:true,showBackdrop:true});
            this.props.writeAcitivityLog({
                activityKey:"PLACE_EDIT",
                userScreen:"VIEW_PLACE"
              })
        }
        else{ 
            this.setState({suggestionMode:true,editMode:true, showBackdrop:true})
            this.props.writeAcitivityLog({
                activityKey:"PLACE_SUGGEST_EDIT",
                userScreen:"VIEW_PLACE"
              })
         } 
       
    }
    

    
    discardEdit = () =>{
        this.setState({editMode:false,showBackdrop:false});
        if (this.state.suggestionMode){
            this.props.writeAcitivityLog({
                activityKey:"DISCARD_SUGGESTED_EDIT_PLACE",
                userScreen:"SUGGEST_EDIT_PLACE"
              })
        }
        else{
            this.props.writeAcitivityLog({
                activityKey:"DISCARD_EDIT_PLACE",
                userScreen:"EDIT_PLACE"
              })
        }
       
    }

    viewUserProfileHandler = user =>{
        this.props.viewUserProfileHandler(user)
        this.props.discard()
    }

    ViewPhotosHandler = () =>{
        this.setState({viewImages:true, showBackdrop: true});
    }

    discardViewPhotosHandler = () =>{
        this.props.writeAcitivityLog({
            activityKey:"PLACE_DISCARD",
            userScreen:"VIEW_PLACE"
          })
        this.setState({viewImages:false, showBackdrop: false});
    }

    showNearByHandler =(coordinates,placeCountryTreeID)=> {
        this.props.showNearByHandler(coordinates,placeCountryTreeID,this.props.placeID)

        this.props.writeAcitivityLog({
            activityKey:"PLACE_SHOWNEARBY_BUTTON",
            userScreen:"VIEW_PLACE"
        })
    }

    discardView=()=>{
        this.props.discard();
     }

      
      
      discardChangesHandler = () => {
          switch(this.state.confirmScenario){
              case "EditMode":{
                this.props.discard();
                break;
              }
              default: return null;
          }
        
      };


    handleLanguageUpdate = (placeName,placeDescription)=>{
        let selectedPlace=this.props.selectedPlace;
        selectedPlace.placeName = placeName;
        selectedPlace.placeDescription=placeDescription;
        let date = new Date();
        let timestamp = date.getTime();
        selectedPlace.placeLastModified=timestamp;
        let placeLanguages=this.props.placeLanguages;
        placeLanguages.push(this.state.newLanguage)
        selectedPlace.placeLanguages=placeLanguages;
        this.setState({placeLanguage:this.state.newLanguage});
        this.handlePlaceLangaugeUpdate(this.state.newLanguage)
        this.props.updatePlaceLanguage(selectedPlace,this.state.newLanguage)
    }

    renderTopSection = () => {
         return (
             <div className={classes.TopSection}>
                <ModalTopSection
                title={""}
                discard={this.discardView}
                showButton={false}
                RTL = {this.props.RTL}
                />
            </div>
          );
      };

      renderPlaceLikeScore = () =>{
          let likedClass = "LikedSection"
          if (this.props.RTL){
              likedClass = "LikedSectionRTL"
          }
          if (this.props.placeStats.usersExperienced>0){
              return(
                  <div className={classes[likedClass]}>
                      <div className={classes.RankItem}>
                        <img src={likedIcon} alt="" className={classes.LikedIcon}/>
                        {this.props.placeStats.usersLiked}
                      </div>
                      <div className={classes.RankItem}>
                      <img src={dislikedIcon} alt="" className={classes.LikedIcon}/>
                      {this.props.placeStats.usersDisliked}
                      </div>
                    
                  </div>
              )
          }
      }

      renderTitleSection=()=>{
        let toolsClass = "Tools"
        let placeShortcutsDirection = "PlaceShortcutsLTR"
        if (this.props.RTL){
            placeShortcutsDirection = "PlaceShortcutsRTL"
            toolsClass = "ToolsRTL"
        }
        if (this.state.hideTop&&isMobile){
            return (
                <div className={classes.TitleTopSectionSlim} >
                  {this.renderPlaceName()} 
                  {this.renderGoogleRank()} 
                </div>
            );
        }
        else{
            return (
                <div className={classes.TitleTopSection} >
                    {this.renderPlaceLikeScore()}
                  {this.renderPlaceName()} 
                  {this.renderGoogleRank()} 
                  {!this.props.placeEditSuggestion?(
                      <div className={[classes.PlaceShortcuts,classes[placeShortcutsDirection]].join(" ")}>
                      {this.renderLanguage()}
                      {this.renderFavoriteIcon()}
                      <div className={classes[toolsClass]}>
                    {this.renderFavoriteTools()}
                    </div> 
                      <div className={classes.TabSeperator}/>
                      {this.renderAddToPlanIcon()}
                      <div className={classes.TabSeperator}/>
                      {this.renderShareExperienceIcon()} 
                      <div className={classes.TabSeperator}/>
                      {this.renderApproveIcon()}
                      {this.renderTopTouristSitenAdmin()}
                      {this.renderAdditionalToolsIcon()}
                      </div> 
                  ):null}
                  
                    <div className={classes[toolsClass]}>
                    {this.renderAdditionalTools()}
                    </div> 
                 
                </div>
            );
        }
        
    }

    renderPlaceName = () =>{
        if (this.props.placeLanguage==="Hebrew"){
            return (

                <div className={classes.Title} style={{direction:"rtl"}}>
                      {this.state.placeName}
                  </div>
            );
        }
        else{
            return (

                <div className={classes.Title} >
                      {this.state.placeName}
                  </div>
            );
        }          
    }

    renderLanguage = () =>{
        let placeLanguageClass = "PlaceLanguage"
        let languageTools = "LanguageTools"
        if (this.props.RTL){
            placeLanguageClass = "PlaceLanguageRTL"
            languageTools = "LanguageToolsRTL"
        }
        if (this.props.placeLanguages.length>1){
            return (
                <div className={classes[placeLanguageClass]}>
                    <TransparentBackdrop show={this.state.showLanguageBackdrop} clicked={
                        ()=>{this.setState({showLanguageOptions:false,showLanguageBackdrop:false})}} />
                    <TextButton
                        clicked={()=>{this.setState({showLanguageOptions:true,showLanguageBackdrop:true})
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_LANGUAGE_BUTTON",
                            userScreen:"VIEW_PLACE"
                          })
                        }}
                        text={this.props.systemText.languages.longLanguage[this.state.placeLanguage]}
                        style="Style1"
                    />
                    <div className={classes[languageTools]}>
                      {this.renderLanguageOptions()}
                    </div>
                </div>)
        }
       
    }

     
    renderLanguageOptions = () =>{
        let toolKitStyle="Style1"
        if (this.state.showLanguageOptions){
            let tools = [];
            let languagesList = Object.keys(this.props.systemLanguages).filter(language=>this.props.placeLanguages.includes(language))
            for (let i = 0 ; i<languagesList.length ; i++){
                tools.push({
                    action: ()=>{this.handleLanguageSelection(languagesList[i]);
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_LANGUAGE_CHANGE",
                            userScreen:"VIEW_PLACE"
                          })
                    },
                    text: this.props.systemText.languages.longLanguage[languagesList[i]]
                    }); 
            }
            return(
                <Toolkit
                    style={toolKitStyle}
                    tools={tools}
                />
            )
        }
    }
  
   
    renderFavoriteIcon = ()=>{
        let greyedToolsClass = "GreyedTools"
        let toolsIconGreyedClass = "ToolsIconGreyed"
        let toolsIconClass = "ToolsIcon"
        if (this.props.loggedUser){
            if (this.props.inFavorite){
                return(
                    <div className={classes[greyedToolsClass]}>
                      <img src={inFavoriteIcon} 
                          alt="" className={classes[toolsIconClass]} onClick={(e)=>{ e.stopPropagation();this.setState({favoriteTools: true, showTransparentBackdrop: true})}}/>
                     <div className={classes.ToolText}>
                     {this.props.systemText.place.tools.addToFavorite[this.props.userLanguage]}</div>
                    </div>)}
              
            else{
                return(
                    <div className={classes[greyedToolsClass]}>
                      <img src={addToFavoriteIcon} 
                    alt="" className={classes[toolsIconClass]}  onClick={(e)=>{ e.stopPropagation();this.props.addPlaceToFavorite(this.props.selectedPlace,"Place")}}/>
                     <div className={classes.ToolText}>
                     {this.props.systemText.place.tools.addToFavorite[this.props.userLanguage]}</div>
                    </div>) }
            
        }
        else{
            return(<div className={classes[greyedToolsClass]} >
                <img src={addToFavoriteIconFade} 
                alt="" className={classes[toolsIconGreyedClass]} data-tip data-for="favoriteTip"
                onClick={(e)=>{ e.stopPropagation();this.props.loginHandler()}}
                />
                <div className={classes.ToolTextFade}>
                {this.props.systemText.place.tools.addToFavorite[this.props.userLanguage]}</div>
            </div>)}  
    }

    renderAddToPlanIcon = () =>{
        let greyedToolsClass = "GreyedTools"
        let toolsIconClass = "ExperienceIcon"
        if (this.props.loggedUser){
            return(
            <div className={classes[greyedToolsClass]}>
            <img src={addToPlanIcon} 
           alt="" className={classes[toolsIconClass]} onClick={()=>{this.props.addPlaceToPlan(this.props.selectedPlace,true)}}/>
               <div className={classes.ToolText}>
           {this.props.systemText.place.tools.addToPlan[this.props.userLanguage]}</div>
          </div>)
        }
        else{
            return(
                <div className={classes[greyedToolsClass]} >
                    <img src={addToPlanIconFade} 
                    alt="" className={classes[toolsIconClass]} onClick={(e)=>{ e.stopPropagation();this.props.loginHandler()}}/>
             <div className={classes.ToolTextFade}>
           {this.props.systemText.place.tools.addToPlan[this.props.userLanguage]}</div>
        </div>)
        }
      
      }
  
      renderShareExperienceIcon= () =>{
        let greyedToolsClass = "GreyedTools"
        let toolsIconClass = "ExperienceIcon"
        if (this.props.loggedUser){
            return(
            <div className={classes[greyedToolsClass]}>
                <img src={experienceIcon} 
           alt="" className={classes[toolsIconClass]} onClick={(e)=>{ e.stopPropagation();this.props.createExperience()}}/>
             <div className={classes.ToolText}>
           {this.props.systemText.place.tools.shareExperience[this.props.userLanguage]}</div>
          </div>)
        }
        else{
            return(
            <div className={classes[greyedToolsClass]} >
            <img src={experienceIconFade} 
            alt="" className={classes[toolsIconClass]} onClick={(e)=>{ e.stopPropagation();this.props.loginHandler()}}/>
              <div className={classes.ToolTextFade}>
           {this.props.systemText.place.tools.shareExperience[this.props.userLanguage]}</div>
        </div>) 
        }
       
      }
  
      renderApproveIcon = () =>{
        if (this.props.adminMode){
          return(<img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FApproveIcon.png?alt=media&token=35fb1846-494d-4a50-b822-48e30d33c048"} 
          alt="" className={classes.ToolsIcon} onClick={()=>{this.props.approvePlace()}}/>)
        }
      }

      renderTopTouristSitenAdmin = () =>{
        if (this.props.adminUser){
            let checkIconClass = "CheckIcon"
            let uncheckBoxClass = "UncheckBox"
            if (this.props.RTL){
              checkIconClass = "CheckIconRTL"
              uncheckBoxClass = "UncheckBoxRTL"
            }
            if (this.props.topTouristSite){
                return(<div className={classes.TopTourist}>
                    <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.props.popularTouristSiteHandler(false)}}/>
                    {"Tourist"}
                </div>)
            }
            else{
                return(<div className={classes.TopTourist}>
                         <div className={classes[uncheckBoxClass]} onClick={()=>{this.props.popularTouristSiteHandler(true)}}/>
                         {"Tourist"}
                </div>)
            }
        }
      }
  
  
      renderFavoriteTools = () =>{
          if (this.state.favoriteTools){
            let toolKitStyle="Style2"
              let tools = [];
               tools.push({
              action:  ()=>{this.props.updateFavoriteTags(this.props.selectedPlace,"Place");
                  this.setState({showTransparentBackdrop:false, favoriteTools:false})},
              text: this.props.systemText.place.favorite.editFavoriteTags[this.props.userLanguage],
              icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTagIcon.png?alt=media&token=6fbf97d9-a636-4b33-baf9-e0412cadc9c9"
            });
              tools.push({
              action: ()=>{this.props.removePlaceFromFavorite(this.props.selectedPlace,"Place");
                  this.setState({showTransparentBackdrop:false, favoriteTools:false})},
              text: this.props.systemText.place.favorite.removeFromFavorite[this.props.userLanguage],
              icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2Fdelete.png?alt=media&token=8e0e899b-354c-45e8-9f47-dc70471f1f81"
            });
            return(
                <Toolkit
                style={toolKitStyle}
                tools={tools}
                RTL = {this.props.RTL}
                bodyRTL={this.props.RTL}
                />)
              }
      }

      renderAdditionalToolsIcon=()=>{
        if (this.props.loggedUser){
            return (
            <div className={classes.AdditionalTools}>
            <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditMode.png?alt=media&token=53018dfb-bdc0-4dcb-92b1-600bcfac76a7"} 
            alt="" className={classes.ToolsIcon} onClick={(e)=>{ e.stopPropagation();this.setState({showAdditionalTools: true, showTransparentBackdrop: true})}}/>
            </div>)
        }
        else{
            return null;
        }
       
    }

      renderAdditionalTools = () =>{
        let editMessage = ""
        let toolKitStyle="Style2"
        if (this.props.contributors.map(user=>{return user.ownerID}).includes(this.props.user.userID)||this.props.adminUser){
            editMessage = this.props.systemText.place.tools.editPlace[this.props.userLanguage]
        }
        else{
            editMessage = this.props.systemText.place.tools.suggestEdit[this.props.userLanguage]
        }
        if (this.state.showAdditionalTools){
            let tools = [];
             tools.push({
            action:  ()=>{this.editHandler();
                this.setState({showTransparentBackdrop:false, showAdditionalTools:false})},
            text: editMessage,
            icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIcon.png?alt=media&token=c964d68a-44ba-45bf-afd7-b4b8eaeb6fba"
          });
             tools.push({
            action: ()=>{this.requestOwnershipHandler();
                this.setState({showTransparentBackdrop:false, showAdditionalTools:false})},
            text: this.props.systemText.place.tools.requestOwnership[this.props.userLanguage],
            icon: ""
          }); 
          return(
            <Toolkit
            style={toolKitStyle}
            tools={tools}
            RTL = {this.props.RTL}
            bodyRTL={this.props.RTL}
            />)
          }
    }

    renderGoogleRank = () =>{
        if (this.props.placeGoogleRating!==undefined){
            return(
                <div className={classes.GoogleRank}>
                    <img src = {googleIcon} alt={""} className={classes.GoogleRankIcon}/>
                    {"("+this.props.placeGoogleRating}
                    <img src = {starIcon} alt={""} className={classes.GoogleRankIcon}/>
                    {")"}
                </div>
            )
        }
        
    }

      renderPlaceOverview = () =>{
        return (
            <div className={classes.PlaceOverview}>
                {this.renderOwner()} 
                 {this.renderPlaceCategory()}
                 {this.renderImageCarusel()}
                 {this.renderQuickLinks()}
               
                 <div className={classes.PlaceInfoSection}>
                    
                     {this.renderOpenHours()} 
                   
                     {this.renderLinks()}
                     {this.renderPlaceCharacteristics()}
                </div>
                {this.renderDescriptionSection()} 
                {this.renderPlaceStaticMap()} 
            </div>
        )       
    }

    viewImagesFullscreen = index =>{
        let imagesFullScreenList=[];
        if (isMobileOnly){
            if (this.props.imagePreview.length>0){
                if (Object.keys(this.props.imagePreview[0]).includes("mediumImage")){
                    imagesFullScreenList=this.props.imagePreview.map(img=>{return img.mediumImage.url})
                    }
                else{
                    imagesFullScreenList=this.props.imagePreview.map(img=>{return img.smallImage.url})
                }
            }
        }
        else{
            if (this.props.imagePreview.length>0){
                if (Object.keys(this.props.imagePreview[0]).includes("largeImage")){
                    imagesFullScreenList=this.props.imagePreview.map(img=>{return img.largeImage.url})
                    }
            }
        }
        this.props.viewImagesFullscreen(imagesFullScreenList,index)
        this.props.writeAcitivityLog({
            activityKey:"PLACE_IMAGES_FULLSCREEN_ENTER",
            userScreen:"VIEW_PLACE"
            })
    }

    renderImageCarusel = () =>{
        let imagesFullScreenList=[];
        let imagesPreviewList = [];
        if (this.props.imagePreview!==undefined){
            if (isMobileOnly){
                if (this.props.imagePreview.length>0){
                    if (Object.keys(this.props.imagePreview[0]).includes("mediumImage")){
                        imagesFullScreenList=this.props.imagePreview.map(img=>{return img.mediumImage.url})
                      }
                    else{
                        imagesFullScreenList=this.props.imagePreview.map(img=>{return img.smallImage.url})
                    }
                    if (Object.keys(this.props.imagePreview[0]).includes("smallImage")){
                         imagesPreviewList=this.props.imagePreview.map(img=>{return img.smallImage.url})
                      }
                }
            }
            else{
                if (this.props.imagePreview.length>0){
                    if (Object.keys(this.props.imagePreview[0]).includes("mediumImage")){
                        imagesPreviewList=this.props.imagePreview.map(img=>{return img.mediumImage.url})
                      }
                      else{
                        imagesPreviewList=this.props.imagePreview.map(img=>{return img.largeImage.url})
                      }
                    if (Object.keys(this.props.imagePreview[0]).includes("largeImage")){
                        imagesFullScreenList=this.props.imagePreview.map(img=>{return img.largeImage.url})
                      }
                }
            }
            
        }
        return(<ImageCarusel
            placeImage={true}
            imagesUrls={imagesPreviewList}
            showCopyrightsMessage={true}
            systemText={this.props.systemText}
            userLanguage={this.props.userLanguage}
            RTL={this.props.RTL}
            viewImagesFullscreen = {this.viewImagesFullscreen}

            
            writeAcitivityLog = {this.props.writeAcitivityLog}
            showCopyrightMessage = {true}
            copyrightHandler = {this.copyrightHandler}
            />)
    }

    renderPlaceStaticMap = () =>{
        let location=this.props.placeLocation.coordinates.lat+","+this.props.placeLocation.coordinates.lng
        let height = this.state.screenHeight;
        let width = this.state.screenWidth;
        let mapHeight = height*0.48
        let mapWidth = width*0.8;
        if (isMobile){
          mapWidth = width*0.95; 
          mapHeight = height*0.45
        }
        let mapSize = Math.round(mapWidth)+"x"+Math.round(mapHeight)
        return(
            <div className={classes.Map}>
                  {this.renderLocationPath()}
                  <StaticGoogleMap 
                 style={{borderRadius:"15px"}}
                 size={mapSize.toString()} apiKey="AIzaSyA_W-95jQC01JNveKBCvSjWQzr-W7_VSa0"   zoom="12">
                   <Marker location={location} color="red" />
              </StaticGoogleMap>   
          </div>
        )
    }

    renderLocationPath = () =>{
        let placeCountryName = "";
        let placeRegionName = "";
        let placeCityName = "";
        let placeCountryTreeID;

        let placeCountry = this.props.placeLocation.locationTree.placeCountry
        let placeRegion = this.props.placeLocation.locationTree.placeRegion
        let placeCity = this.props.placeLocation.locationTree.placeCity
        if (this.props.placeStatus==="potential"){
            if (placeCountry!==undefined){
                if (placeCountry[this.props.userLanguage]!==undefined){
                    placeCountryName=placeCountry[this.props.userLanguage]
                }
                else if(placeCountry["English"]!==undefined){
                placeCountryName=placeCountry["English"]
                }
                else{
                    let names =  Object.keys(placeCountry);
                    placeCountryName=placeCountry[names[0]]
                }
    
            }
            placeCountryTreeID = placeCountry.treeID

            if (placeRegion!==undefined){
                if (placeRegion[this.props.userLanguage]!==undefined){
                    placeRegionName=placeRegion[this.props.userLanguage]
                }
                else if(placeRegion["English"]!==undefined){
                    placeRegionName=placeRegion["English"]
                }
                else{
                    let names =  Object.keys(placeRegion);
                    placeRegionName=placeRegion[names[0]]
                }
            }
            
            if (placeCity!==undefined){
                if (placeCity[this.props.userLanguage]!==undefined){
                    placeCityName=placeCity[this.props.userLanguage]
                }
                else if(placeCity["English"]!==undefined){
                    placeCityName=placeCity["English"]
                }
                else{
                    let names =  Object.keys(placeCity);
                    placeCityName=placeCity[names[0]]
                }
            }

            
        }
        else{
            //place approved - use the location translator
            if (placeCountry!==undefined){
                if (this.props.locationsTranslator[placeCountry]!==undefined){
                    if (this.props.locationsTranslator[placeCountry][this.props.userLanguage]!==undefined){
                        placeCountryName=this.props.locationsTranslator[placeCountry][this.props.userLanguage]
                    }
                    else if(this.props.locationsTranslator[placeCountry]["English"]!==undefined){
                         placeCountryName=this.props.locationsTranslator[placeCountry]["English"]
                    }
                    else{
                        let names =  Object.keys(this.props.locationsTranslator[placeCountry]);
                        placeCountryName=this.props.locationsTranslator[placeCountry][names[0]]
                    }
                }
            }
            placeCountryTreeID = placeCountry

            if (placeRegion!==undefined){
                if (this.props.locationsTranslator[placeRegion]!==undefined){
                    if (this.props.locationsTranslator[placeRegion][this.props.userLanguage]!==undefined){
                        placeRegionName=this.props.locationsTranslator[placeRegion][this.props.userLanguage]
                    }
                    else if(this.props.locationsTranslator[placeRegion]["English"]!==undefined){
                        placeRegionName=this.props.locationsTranslator[placeRegion]["English"]
                    }
                    else{
                        let names =  Object.keys(this.props.locationsTranslator[placeRegion]);
                        placeRegionName=this.props.locationsTranslator[placeRegion][names[0]]
                    }
                }
            }
            
            if (placeCity!==undefined){
                if (this.props.locationsTranslator[placeCity]!==undefined){
                    if (this.props.locationsTranslator[placeCity][this.props.userLanguage]!==undefined){
                        placeCityName=this.props.locationsTranslator[placeCity][this.props.userLanguage]
                    }
                    else if(this.props.locationsTranslator[placeCity]["English"]!==undefined){
                        placeCityName=this.props.locationsTranslator[placeCity]["English"]
                    }
                    else{
                        let names =  Object.keys(this.props.locationsTranslator[placeCity]);
                        placeCityName=this.props.locationsTranslator[placeCity][names[0]]
                    }
                }
            }
        }
        
        
        if (placeRegionName!==""&&placeCityName!==""){
            return(<div className={classes.LocationPath}>
                <div className={classes.LocationString}>{placeCityName+", "+placeRegionName+", "+placeCountryName}</div>
                {!this.props.planMode/*&& this.props.selectedContentWall==="Discover" */?(
                <div onClick={()=>{this.showNearByHandler(this.props.placeLocation.coordinates,placeCountryTreeID)}} className={classes.ShowMap}>
                      {this.props.systemText.place.viewPlace.showNearby[this.props.userLanguage]}
                      </div>):null}
                </div>)
        }
        else if(placeRegionName!==""){
             return(<div className={classes.LocationPath}>
                <div className={classes.LocationString}>{placeRegionName+", "+placeCountryName}</div>
                {!this.props.planMode/* &&this.props.selectedContentWall==="Discover" */?(
                <div onClick={()=>{this.showNearByHandler(this.props.placeLocation.coordinates,placeCountryTreeID)}} className={classes.ShowMap}>
                      {this.props.systemText.place.viewPlace.showNearby[this.props.userLanguage]}</div>):null}
                </div>)
        }
        else{
             return(<div className={classes.LocationPath}>
               <div className={classes.LocationString}> {placeCityName+" ,"+placeCountryName}</div>
                {!this.props.planMode/* &&this.props.selectedContentWall==="Discover" */?(
                <div onClick={()=>{this.showNearByHandler(this.props.placeLocation.coordinates,placeCountryTreeID)}} className={classes.ShowMap}>
                      {this.props.systemText.place.viewPlace.showNearby[this.props.userLanguage]}</div>):null}
                </div>)
        }
    }

    renderDescriptionSection = () =>{
        let RTLClass =""
        if (this.props.placeLanguage==="Hebrew"){
            RTLClass ="DescriptionRTL"
        }
        if (this.state.jsonString){
             return(
               <div className={[classes.DescriptionSection,classes[RTLClass]].join(" ")}>
               <Editor
               className={classes.DescriptionInputField}
               editorState={this.state.placeDescription}
               readOnly={true}
               />
               </div>
             )
           }
    }

    renderCategoryIcon = () =>{
        let iconClass = "CategoryIcon"
        if (this.props.RTL){
            iconClass = "CategoryIconRTL"
        }
        return (<div className={classes.MainCategories}>
                {this.props.placeMainCategory[0]===1?(
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSightSeeingIcon.png?alt=media&token=3b7f3202-493e-4f2e-981f-47944e78c5a4"}
                     alt="" className={classes[iconClass]}/>
                ):null}
                {this.props.placeMainCategory[1]===1?(
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FNatureIcon.png?alt=media&token=69ef82b9-4af1-44e2-b713-a240fba4022e"} 
                    alt="" className={classes[iconClass]}/>
                ):null}
                {this.props.placeMainCategory[2]===1?(
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FActivityIcon.png?alt=media&token=e46ab679-3b01-4172-9efc-1f528c9f76a7"} 
                    alt="" className={classes[iconClass]}/>
                ):null}
                {this.props.placeMainCategory[3]===1?(
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSportIcon.png?alt=media&token=3d936de1-0f2b-40de-9313-69b90977602d"} 
                    alt="" className={classes[iconClass]}/>
                ):null}
                {this.props.placeMainCategory[4]===1?(
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFoodIcon.png?alt=media&token=f9f81121-4c3e-4290-8b3e-94fee05d149a"} 
                    alt="" className={classes[iconClass]}/>
                ):null}
                {this.props.placeMainCategory[5]===1?(
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIcon.png?alt=media&token=3f3fbed6-2d65-4896-a2d0-6036f7e1ac5b"} 
                    alt="" className={classes[iconClass]}/>
                ):null}
            
        </div>)
       
    }

    renderPlaceCategory = () =>{
            //get the relevant language or at least any language name
            let categoriesListClass = "CategoriesList"
            let categoryLastClass = "CategoryLast"
            let categoryClass = "Category"
            let categoryDirectionClass = "CategoryLTR"
            let categoryLastDirectionClass = "CategoryLastLTR"
            if (this.props.RTL){
              categoryDirectionClass = "CategoryRTL"
              categoryLastDirectionClass = "CategoryLastRTL"
            }
            return(
                <div className={classes.CategoriesSection}>
                    {this.renderCategoryIcon()}
                    <div className={classes.CategoryList}>
                    {this.props.placeCategory.map((category, index) => {
                    let categoryText = ""
                    if (this.props.categoriesTranslator[category]!==undefined){
                        categoryText = this.props.categoriesTranslator[category][this.props.userLanguage]
                    }
                    else{
                        categoryText = category
                    }
                   if (index===(this.props.placeCategory.length-1)&&this.props.placeNewCategories.length===0){
                        return(
                        <div key={index} className={classes[categoryLastClass]} >{categoryText}</div>
                        )
                    }
                    else{
                        return(
                        <div key={index} className={[classes[categoryClass],classes[categoryDirectionClass]].join(" ")}>{categoryText}</div>
                        )
                    }
                    })}
                    </div>
                    {this.props.placeNewCategories.map((category, index) =>(
                        <div key={index} className={classes.NewCategory}>{category.id}</div>
                        
                    ))}
                </div>
            )
    }

    renderOpenHours = () =>{
        if (this.state.hoursAreSet){
            return(
                <OpenHoursPreview
                openHours={[...this.state.hoursRows]}                    
                hoursHandler={this.hoursHandler}

                placeTemporaryClosed={this.props.placeTemporaryClosed}
                temporaryClosedNote={this.props.temporaryClosedNote}
                placePermanentlyClosed={this.props.placePermanentlyClosed}
                permanentlyClosedDate={new Date(this.props.permanentlyClosedDate)}

                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
                />
            )
        }
  }


renderQuickLinks = () =>{
    let iconClass = "ContactInfoIcon"
    if (this.props.RTL){
        iconClass = "ContactInfoIconRTL"
    }
    if (this.state.quickLinks!==undefined){
        return(<div className={classes.QuickLinks}>
            {this.state.quickLinks.googleMapsLink!==undefined?(
                   <a href={this.state.quickLinks.googleMapsLink} target="_blank">
                    <img src={googleMapsIcon} alt="" className={classes[iconClass]}
                    onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_GOOGLEMAPS_BUTTON",
                            userScreen:"VIEW_PLACE"
                        })
                    }}
                    />
                </a>
            ):null}
            {this.state.quickLinks.wazeLink!==undefined?(
                <a href={this.state.quickLinks.wazeLink} target="_blank">
                    <img src={wazeIcon} alt="" className={classes[iconClass]} 
                     onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_WAZE_BUTTON",
                            userScreen:"VIEW_PLACE"
                        })
                    }}
                    />
                </a>
            ):null}  
            {this.state.quickLinks.phoneNumber!==undefined?(
                <a   href={this.state.quickLinks.phoneNumber}
                key={this.state.quickLinks.phoneNumber}
                rel='noopener'
                className='customLink'>
                     <img src={phoneIcon} alt="" className={classes[iconClass]}
                      onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_PHONE_BUTTON",
                            userScreen:"VIEW_PLACE"
                        })
                    }}
                     />
                </a>
            ):null} 
             {this.state.quickLinks.officialWebsiteLink!==undefined?(
                <a href={this.state.quickLinks.officialWebsiteLink} target="_blank">
                    <img src={websiteIcon} alt="" className={classes[iconClass]}
                    onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_WEBSITE_BUTTON",
                            userScreen:"VIEW_PLACE"
                        })
                    }}
                    />
                </a>
            ):null}
            {this.state.quickLinks.facebookLink!==undefined?(
                <a href={this.state.quickLinks.facebookLink} target="_blank">
                    <img src={facebookIcon} alt="" className={classes[iconClass]} 
                     onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_FACEBOOK_BUTTON",
                            userScreen:"VIEW_PLACE"
                        })
                    }}
                    />
                </a>
            ):null}
            {this.state.quickLinks.instagramLink!==undefined?(
                    <a href={this.state.quickLinks.instagramLink} target="_blank">
                    <img src={instagramIcon} alt="" className={classes[iconClass]} 
                     onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_INSTAGRAM_BUTTON",
                            userScreen:"VIEW_PLACE"
                        })
                    }}
                    />
                    </a>
            ):null}
            {this.state.quickLinks.twitterLink!==undefined?(
                    <a href={this.state.quickLinks.twitterLink} target="_blank">
                    <img src={twitterIcon} alt="" className={classes[iconClass]} 
                     onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_TWITTER_BUTTON",
                            userScreen:"VIEW_PLACE"
                        })
                    }}
                    />
                </a>
            ):null}             
        </div>)
    }
    
}


renderLinks = () =>{
    if (this.props.placeLinks.length>0){
        let linkClass = "Link"
        let iconClass = "LinkIcon"
        if (this.props.RTL){
            linkClass = "LinkRTL"
            iconClass = "LinkIconRTL"
        }
        return(
            <div className={classes.LinksSection}>
                <div className={classes.SectionHeaderTitle}>
                    <img src={linkIcon} alt="" className={classes[iconClass]}/>
                </div>
                 <div className={classes.Links}>
                  {this.props.placeLinks.map((link, index) => (
                    <a href={link.url} target="_blank" key={index}>
                    <div className={classes[linkClass]} key={index}
                     onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"PLACE_ADDITIONAL_LINK_BUTTON",
                            userScreen:"VIEW_PLACE"
                        })
                    }}>
                        {link.name}
                        </div>
                      </a> 
                  ))}
                  </div>
            </div>
        )
    }
}

   renderPlaceCharacteristics = () =>{
      let render = false;
      if (this.props.placeCharacteristics.generalTags!==undefined || 
        this.props.placeCharacteristics.audienceTags!==undefined ||
        this.props.placeCharacteristics.mainTags !== undefined ||
        this.props.placeCharacteristics.subTags !== undefined ||
        this.props.placeTags.length>0){
            render = true
        }
        if (render){
            let RTLClass = ""
            if (this.props.RTL){
                RTLClass = "RTLBody"
            }
            return(
                <div className={classes.PlaceCharacteristcs}>
                    <ViewPlaceCharacteristics
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTLClass = {RTLClass}
                    RTL = {this.props.RTL}
                    placeCharacteristics={this.props.placeCharacteristics}
                    placesCharacteristicsData = {this.props.placesCharacteristicsData}
                    />
                   
                    {this.renderPlaceTags()} 
                </div>
            )
        }
       
    }
    renderPlaceTags=()=>{
        if (this.props.placeTags.length>0){
            return(
                <div className={classes.PlaceTags}>
                     {this.props.placeTags.map((tag, index) => {
                          let tagLanguages =  Object.keys(tag);
                          if (tagLanguages[0]===this.state.placeLanguage){
                            return(
                                <Tag 
                                key={index}
                                text={tag[tagLanguages[0]]}
                                style={"AdditionalTagFilter"}
                                RTL = {this.props.RTL}
                                />
                              )
                          }
                         
                     }
                   
                    )} 
                </div>
            )
        }
    } 

/* 
    renderPlaceExtraInformation = () =>{
        return (
            <div className={classes.PlaceExtraInformation}>
                 <PlaceExtraInfo
                    tryOut={this.props.tryOut}
                    myFriendsIDs={this.props.myFriends.map(user=>{return user.userID})}
                    placeName={this.props.placeName[this.state.placeLanguage]}
                    userLanguage={this.props.userLanguage}
                    systemLanguage={this.props.systemLanguage}
                    placeHighlights = {this.props.placeHighlights}
                    placeExperiences={this.props.placeExperiences}
                    personalNotes={this.props.personalNotes}
                    inFavorite={this.props.inFavorite}
                    user={this.props.user}
                    highlightsTopicsList={this.props.highlightsTopicsList}
                    placeLanguage={this.state.placeLanguage}

                    addHighlightHandler={(highlight)=>{this.props.addHighlightHandler(highlight,this.state.placeLanguage,this.props.selectedPlace)}}
                    updatePlaceHighlight={(highlight)=>{this.props.updatePlaceHighlight(highlight,this.state.placeLanguage,this.props.selectedPlace)}}
                    deletePlaceHighlight={(highlight)=>{this.props.deletePlaceHighlight(highlight,this.state.placeLanguage,this.props.selectedPlace)}}
                
                    addNoteHandler={(note)=>{this.props.addNoteHandler(note,this.props.selectedPlace.placeID)}}
                    deleteNote={(note,noteIndex)=>{this.props.deleteNoteHandler(note,noteIndex,this.props.selectedPlace)}}
                    updateNote={(note,noteIndex)=>{this.props.updateNoteHandler(note,noteIndex,this.props.selectedPlace)}}

                    shareExperience={this.props.createExperience}
                />
            </div>
        )
    }
*/
    

    renderPlaceFeed = () =>{
        return(
            <PlaceFeed
            placeExperiences={this.props.placeExperiences}
            placeFriendsExperiences = {this.props.placeFriendsExperiences}
            placeMyExperiences = {this.props.placeMyExperiences}
            user={this.props.user}
            loggedUser = {this.props.loggedUser}

            loadFriendsExperiences = {this.loadFriendsExperiences}
            loadMoreExperiencesFeedHandler = {this.props.loadMoreExperiencesFeedHandler}
            loadedMoreExperiencesToFeed = {this.props.loadedMoreExperiencesToFeed}
            loadMyExperiences = {this.loadMyExperiences}
            
            followingUsersIDs = {this.props.followingUsersIDs}
            myFriends = {this.props.myFriends}

            viewUserProfileHandler = {this.viewUserProfileHandler}

            writeAcitivityLog = {this.props.writeAcitivityLog}
           
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
            />
        )
    }

    renderOwner = () =>{
        if (this.props.adminMode){
          return(<div className={classes.Owner}>{this.props.owner.ownerName}
          <div>{this.props.placeID}</div>
          </div>)
        }
      }

      renderPlaceEditor = () =>{
        if (this.state.editMode){
            return(
                   <EditPlace 
                      initializeLanguage = {this.state.placeLanguage}
                       userLanguage={this.props.userLanguage}
                       systemText={this.props.systemText}
                       RTL = {this.props.RTL}
                       inputLanguage = {this.props.inputLanguage}
                       detectInputLanguage = {this.props.detectInputLanguage}
                       writeAcitivityLog = {this.props.writeAcitivityLog}

                       selectedPlace={this.props.selectedPlace}
                       slimPlacesByCountry={this.props.slimPlacesByCountry}
                       systemCategories = {this.props.systemCategories}
                       locationsTree={this.props.locationsTree}
                       placeLanguage={this.state.placeLanguage}
                       systemLanguages = {this.props.systemLanguages}
                       placesCharacteristicsData={this.props.placesCharacteristicsData}
                       categoriesTranslator = {this.props.categoriesTranslator}
                       locationsTranslator = {this.props.locationsTranslator}
                       user={this.props.user} 
                       editMode = {this.state.suggestionMode? "SuggestEdit" : "Edit"}
                       getExistingPlaceData={this.props.getExistingPlaceData}
                       getPlacesListForPlaceCreationValidation = {this.props.getPlacesListForPlaceCreationValidation}
                       existingPlace = {this.props.existingPlace}
                       discard={this.discardEdit}
                       update={(place)=>{this.props.update(place);this.setState({hoursAreSet:false});
                           this.props.writeAcitivityLog({
                              activityKey:"SAVE_EDIT_PLACE",
                              userScreen:"EDIT_PLACE"
                          })
                          }}
                       submitSuggestion={(place)=>{this.props.submitPlaceSuggestion(place);this.handleHoursRows();
                          this.props.writeAcitivityLog({
                              activityKey:"SUBMIT_PLACE_EDIT_SUGGESTION",
                              userScreen:"SUGGEST_EDIT_PLACE"
                            })
                          }}
                   />
               ) 
           }
      }
     
    render(){
        let RTLBody=""
            if (this.props.RTL){
                RTLBody="RTLBody"
            }
            return(
                <div className={[classes.Body,classes[RTLBody]].join(" ")}>
                     <TransparentBackdrop show={this.state.showTransparentBackdrop} clicked={this.discardBackdrop} />
                     <Backdrop show={this.state.showBackdrop} />
                    {this.renderPlaceEditor()}
                     {this.renderTopSection()}
                     {this.renderTitleSection()}
                     
                     <div className={classes.BodyContent} id="placeBodyContent">
                       
                        {this.renderPlaceOverview()}
                        {this.renderPlaceFeed()}
                    </div>
                      
                </div>)
    }
}
export default ViewPlace;