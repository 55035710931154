import React, { Component } from "react";

import classes from "./ImageUploader.module.css";

import ImageItem from "./ImageItem";

import Backdrop from "../../UI/Backdrop/Backdrop";
import ConfirmModal from "../../UI/Modals/ConfirmationModal";


import imageCompression from "browser-image-compression";


import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isMobile } from "react-device-detect";

const SortableItem = sortableElement(({index, number,value,deleteImage,userLanguage}) =>
{ 
  return(<ImageItem
            index={number}
            imgSrc={value}
            deleteImage={deleteImage}
            userLanguage={userLanguage}
            fullWidth={""}
        />)});

const SortableContainer = sortableContainer(({children}) => {
  return <div  className={classes.ImagesSection}>{children}</div>;
});


let saving = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FSavingGif.png?alt=media&token=34dfb2f2-8c58-4ffc-8d8a-b92919286d13"

class ImageUploader extends Component {
  state = {
    imagePreview: [],
    deleteMessage: false,
    usedNames:[],
    saving:false
  };
  componentWillMount = () =>{
  }
  componentDidMount = () => {
    this.setState({
      imagePreview: this.props.imagePreview,
      imageGallery: this.props.imageGallery,
      deletedImages: [],
    });
   this.updateUsedNames()
  };

  updateUsedNames = () =>{
    let usedNames = [];
    if (this.props.imagePreview!==undefined){
      if (this.props.imagePreview.length>0){
        usedNames=this.props.imagePreview.map(img=>{return img.name})
      }
    }
    this.setState({usedNames:usedNames})
  }

  componentWillUnmount=()=>{
    window.removeEventListener("paste", (e)=>{
     
  });
  }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.imagePreview !== prevProps.imagePreview) {
      this.setState({
        imagePreview: [...this.props.imagePreview]
       
      });
      this.updateUsedNames()
    }
    if (this.props.imageGallery !== prevProps.imageGallery) {
      this.setState({
        imageGallery: [...this.props.imageGallery]
        
      });
    }
    if (this.props.deletedImages !== prevProps.deletedImages) {
      this.setState({
        deletedImages: [...this.props.deletedImages]
      });
    }
  }

  //handling input of file for uploading images
  //Accessing only the first image - TBD
  //URL.createObjectURL(event.target.files[0])

  copyImage = async (pasteEvent,btnTrigger) =>{
  /*   let pastedText = pasteEvent.clipboardData.getData('image/png');
    this.setState({saving:true})
    this.props.handleSaving(true)
    let items;
    if (pasteEvent.type==="paste"){
      items = pasteEvent;
    }
    else{
      if(pasteEvent.clipboardData === false){
        this.setState({saving:false})
        this.props.handleSaving(false)
        console.log("1")
        return;
      };
  
      items = pasteEvent.clipboardData.items;
    }
    if(items === undefined){
      this.setState({saving:false})
      this.props.handleSaving(false)
      console.log("2")
        return;
    };
    console.log(items)
    if (items[0].type.indexOf("image") == -1){
      this.setState({saving:false})
      this.props.handleSaving(false)
      console.log("3")
      return
    }
    if (this.state.imageGallery.length + items.length > 15) {
      alert("You can upload up to 15 images");
      this.setState({saving:false})
      this.props.handleSaving(false)
      console.log("4")
      return;
    }
    let imagePreview = [...this.state.imagePreview];
    for (var i = 0; i < items.length; i++) {
      if(!items[i].type.startsWith("image/")) {
        alert("Unsupported file format")
        this.setState({saving:false})
        this.props.handleSaving(false)
        console.log("5")
        return}
    }
    
    for (var i = 0; i < items.length; i++) {
        // Skip content if not image
       
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();
        let include=false;
      console.log(blob)
        for (let j=0 ; j<imagePreview.length ; j++){
          if (imagePreview[j].name===blob.name){
            if (imagePreview[j].size===blob.size){
              include = true;
            }
            else{
            }
           
          }
        }
        if (include){
          alert("Image named "+blob.name+" already uploaded")
        }
         else{
          const imageUrl = window.URL.createObjectURL(blob);
          let myBlob;
          await this.converImage(imageUrl).then(result => {
            myBlob = result;
          }); 
            if (myBlob.type.includes("image")){
              let imgToAdd = {
                name: myBlob.name,
                url: URL.createObjectURL(blob),
                size:myBlob.size
              }
              this.compressUploadedImage(myBlob,imgToAdd);
            }
     
        } 
        
    } */

  }

  converImage = (url)=>{
    return new Promise(async (resolve, reject) => {
    var xhr = new XMLHttpRequest();

    // Use JSFiddle logo as a sample image to avoid complicating
    // this example with cross-domain issues.
    xhr.open( "GET", url, true );
    
    // Ask for the result as an ArrayBuffer.
    xhr.responseType = "arraybuffer";
    let myBlob
  
     xhr.onload =  function( e ) { 
        var arrayBufferView = new Uint8Array( this.response );
         myBlob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
        resolve(myBlob);
     }; 
    xhr.send();
  });
  }

  uploadImages = async event => {
    this.setState({saving:true})
    this.props.handleSaving(true)
    let files = [...event.target.files]
    //This is checking that there wil lbe a total of max 5 images, but if the new uploaded images are duplicates it will not check it
    if (this.state.imageGallery.length + files.length > 15) {
      alert("You can upload up to 15 images");
      this.setState({saving:false})
      this.props.handleSaving(false)
      return;
    }
    
    let imagePreview = [...this.state.imagePreview];
    let validFiles = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      let include=false;
      for (let j=0 ; j<imagePreview.length ; j++){
        if (imagePreview[j].name===file.name){
          include = true;
        }
      }
      if(!file.type.startsWith("image/")) {
        alert("Unsupported file format")
        this.setState({saving:false})
        this.props.handleSaving(false)
        return}
      else{
        let objectURL = URL.createObjectURL(file)
        imagePreview.push({
          name: file.name,
          url: objectURL
        });
        let myBlob;
        await this.converImage(objectURL).then(result => {
            myBlob = result;
            myBlob.name=file.name
          }); 
        validFiles.push(myBlob)
      }
     
    }
    this.setState({
      imagePreview: imagePreview
    });
    for (let i = 0; i < validFiles.length; i++) {
      await this.compressUploadedImage(validFiles[i]);
    }
    this.saveHandler();
  };

  //Compressing images
  compressUploadedImage = async (image,imgToAdd) => {
    let imageFile = image;
    let optionsLarge = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 2048,
      useWebWorker: true,
      maxIteration:20
    };
    let optionsMedium = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1280,
      useWebWorker: true,
      maxIteration:30
    }; 
    let optionsSmall = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 768,
      useWebWorker: true,
      maxIteration:30
    }; 
    let optionsThumbnail = {
      maxSizeMB: 0.04,
      maxWidthOrHeight: 480,
      useWebWorker: true,
      maxIteration:40
    }; 
    try {
      const compressedFileThumbnail = await imageCompression(imageFile, optionsThumbnail);
      const compressedFileMedium = await imageCompression(imageFile, optionsMedium);
      const compressedFileLarge = await imageCompression(imageFile, optionsLarge);
      const compressedFileSmall = await imageCompression(imageFile, optionsSmall);
      await this.updateCompressedImages(compressedFileLarge,compressedFileSmall,compressedFileMedium,compressedFileThumbnail,imgToAdd); // write your own logic
    } catch (error) {
      console.log(error);
    }
  };

  //Updating files after compression
  updateCompressedImages = (largeImage,smallImage,mediumImage,thumbnailImage,imgToAdd) => {
  
    let imageGallery = this.state.imageGallery;
    let usedNames = [...this.state.usedNames];
    let newImage={largeImage:largeImage,smallImage:smallImage,mediumImage:mediumImage,thumbnailImage:thumbnailImage};
    if (largeImage.name===undefined){
      let count=0;
      let newNameString = ("name_"+count).toString();
      while(usedNames.includes(newNameString)){
        count=count+1;
        newNameString = ("name_"+count).toString();
      }
      let name = newNameString;
      usedNames.push(name);
      newImage.largeImage.name=name;
      newImage.smallImage.name=name;
      newImage.mediumImage.name=name;
      newImage.thumbnailImage.name=name
      
      let prevImage = imgToAdd;
      prevImage.name = name
      imageGallery.push(newImage);

      let imagePreview = [...this.state.imagePreview];
      imagePreview.push(prevImage)
      this.setState({imagePreview:imagePreview})
      this.setState({ imageGallery: imageGallery, usedNames:usedNames});
       this.saveHandler();
    }
    else{
      let include=false;
      for (let j=0; j<usedNames.length ; j++){
        if (largeImage.name===usedNames[j]){
          include = true;
        }
      }
      if (!include){
        usedNames.push(largeImage.name);
        imageGallery.push(newImage);
      }
      else{
        let extensionString="_"+usedNames.length;
        let newName = largeImage.name.substring(0, largeImage.name.lastIndexOf(".")) + extensionString + largeImage.name.substring(largeImage.name.lastIndexOf("."));
        newImage.largeImage.name=newName;
        newImage.smallImage.name=newName;
        newImage.mediumImage.name=newName
        newImage.thumbnailImage.name=newName
        usedNames.push(newName);
        imageGallery.push(newImage);
      }
      this.setState({ imageGallery: imageGallery, usedNames:usedNames});
    }
    
  };

  //deleting uploaded image (before saving experience)
  deleteImage = index => {
    this.setState({
        deleteMessage: true,
        deleteIndex: index
    })
  };


   discardDeletion = () => {
    this.setState({
      deleteMessage: false,
    });
    this.props.handleSaving(false)
  };

   confirmDeletion = () => {
    this.setState({deleteMessage: false})
    let index=this.state.deleteIndex;
    let imageGallery = [...this.state.imageGallery];
    let imagePreview = [...this.state.imagePreview];
    let deletedImages = this.state.deletedImages;
    let image = imagePreview[index];
    deletedImages.push(image);
    imageGallery.splice(index, 1);
    imagePreview.splice(index, 1);
    this.setState({
      imageGallery: imageGallery,
      imagePreview: imagePreview,
      deletedImages: deletedImages
    });
    this.props.update(imagePreview, imageGallery, deletedImages);
    this.props.handleSaving(false)
  };

  saveHandler = () => {
    let imagePreview = [...this.state.imagePreview];
    let imageGallery = [...this.state.imageGallery];
    let deletedImages = [...this.state.deletedImages];
    this.props.update(imagePreview, imageGallery, deletedImages);
    this.setState({saving:false})
    this.props.handleSaving(false)
  };


  onSortEnd = async ({oldIndex, newIndex}) => {
    await this.setState(({imagePreview}) => ({
      imagePreview: arrayMove(imagePreview, oldIndex, newIndex),
    }));
    await this.setState(({imageGallery}) => ({
      imageGallery: arrayMove(imageGallery, oldIndex, newIndex),
    }));
    this.saveHandler()
  };


  renderImagesSection = () => {
    if (this.state.imagePreview !== undefined) {
      if (this.props.singleImage){
        if (this.state.imagePreview.length >0){
          return(
            <div className={classes.SingleImage}>
              <ImageItem
                   key={this.state.imagePreview[0].url}
                   imgSrc={this.state.imagePreview[0].url}
                   RTL = {this.props.RTL}
                   fullWidth={"FullWidth"}
                   hideRemoveButton = {true}
              />
            </div>
          )
        }
        
      }
      else{
        let delay = 0;
        if (isMobile){
          delay = 200;
        }
        return (
          <div className={classes.ImagesSection}>
           <SortableContainer onSortEnd={this.onSortEnd} axis={"x"} pressDelay={delay}>
              {this.state.imagePreview.filter(img=>img!==undefined).map((value, index) => {
                return(
                <SortableItem key={index} index={index} number={index} value={value.url} deleteImage={()=>this.deleteImage(index)} userLanguage={this.props.userLanguage}/>)
                   })}
            </SortableContainer>
            </div>
        );
      }
      
    }
  };
  renderUploadSection = () => {
      if (this.state.saving){
        return(
          <div className={classes.Saving}>
                <img src={saving} alt=""className={classes.SavingGif} />
                </div>
        );
      }
  };

  handlePaste =async ()=>{
     try {
       this.setState({saving:true})
       this.props.handleSaving(true)
      const clipboardItems = await navigator.clipboard.read();
      let hasImage = false
      for (let i=0 ; i<clipboardItems[0].types.length ; i++){
        if(clipboardItems[0].types[i].startsWith("image/")) {
          hasImage = true
         }
      }
      if (!hasImage){
        alert("Unsupported file format")
        this.setState({saving:false})
        this.props.handleSaving(false)
        return
      }
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          const blob = await clipboardItem.getType(type);
          const imageUrl = window.URL.createObjectURL(blob);
          let myBlob;
          await this.converImage(imageUrl).then(result => {
            myBlob = result;
          }); 
            if (type.includes("image")){
              let imgToAdd = {
                name: myBlob.name,
                url: URL.createObjectURL(blob),
                size:myBlob.size
              }
              this.compressUploadedImage(myBlob,imgToAdd);
            }
     

        }
      }
    } catch (err) {
      console.error(err.name, err.message);
    }

  }

  renderConfirmModal = () =>{
    if (this.state.deleteMessage){
      return(
          <ConfirmModal
             header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
             primaryAction={this.confirmDeletion}
             secondaryAction={this.discardDeletion}
            message={this.props.systemText.extraModals.confirmationModal.imageMessage[this.props.userLanguage]}
            primaryBtn={this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage]}
            secondaryBtn={this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage]}
            primaryStyle = {"DeleteButton"}
            secondaryStyle = {"DiscardButton"}
            RTL = {this.props.RTL}
          
          /> 
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderConfirmModal()}
          <Backdrop show={this.state.showBackdrop} />
          <div className={classes.Body}>
          {this.renderUploadSection()}
          {this.renderImagesSection()}
        </div>
      </div>
    );
  }
}
export default ImageUploader;
