import React, { Component } from "react";
import classes from "./PlanTransportEditor.module.css";

import ModalTopSection from "../../../UI/Misc/ModalTopSection"
import SearchGoogle from "../../Map/SearchGoogle";


import imageCompression from "browser-image-compression";

import { Editor,EditorState , convertFromRaw, convertToRaw} from 'draft-js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let attachIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAttachment.png?alt=media&token=c49f6954-8969-4f28-bf62-39bf5b0e78ee"
let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"
let removeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"
let planeIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FPlaneIcon.png?alt=media&token=3574b5a9-3843-48fc-b0b1-ecf204c7bad8"
let planeIconGrey  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FPlaneIconGrey.png?alt=media&token=baeb1d76-9150-45fe-97c6-cc272d04f6bc"
let ferryIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FBoatIcon.png?alt=media&token=6da8cb34-0e00-42cf-918d-09221bfe318b"
let ferryIconGrey  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FBoatIconGrey.png?alt=media&token=9659b3b7-8bd9-4958-8312-49618f65d1ad"
let trainIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FTrainIcon.png?alt=media&token=bba234c7-cb6b-4a34-a392-693d3b0a83ae"
let trainIconGrey  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FTrainIconGrey.png?alt=media&token=a3502bfe-c138-4430-aa1c-684c742e916a"
let carIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FCarIcon.png?alt=media&token=52ac5d7f-9851-4107-835b-440dfe48b1dc"
let carIconGrey  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FCarIconGrey.png?alt=media&token=4b6fdcfb-51cc-4a58-afed-854ad8f9e0b3"
let taxiIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FTaxiIcon.png?alt=media&token=07a325ac-4fad-46a1-a5a1-119cd038c1e3"
let taxiIconGrey  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FTaxiIconGrey.png?alt=media&token=100fea2f-b5fb-4757-b3a4-11cd4cc0e38c"
let busIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FBusIcon.png?alt=media&token=502a7516-6c4f-4f19-a791-3b57752cdcd9"
let busIconGrey  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FBusIconGrey.png?alt=media&token=16181f49-e14f-4c6b-ab0f-c123ac1fba8c"
let otherIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FOtherIcon.png?alt=media&token=20d90554-a0d5-4558-8e11-149dc1b03058"
let otherIconGrey  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FOtherIconGrey.png?alt=media&token=97c290d2-a052-469a-b174-17fd35dd120b"

const customStyleMap = {
    STRIKETHROUGH: {
      textDecoration: "line-through"
    },
    FONT_SIZE_30: {
      fontSize: "30px"
    }
  };
  

class PlanTransportEditor extends Component {
    state={
        transportType:"",
        otherType:"",
        origin: { address: "" },
        destination: { address: "" },
        departure:new Date(),
        arrival: new Date(),
        transportNote:EditorState.createEmpty(), 
        attachedFiles:[],
        transportOptions:["plane","ferry","train","car","taxi","bus","other"],
        transportPrice:0,
        saveReady:false,
        showDatesSection:false
    }

    componentDidMount = () =>{
        if (this.props.edit){
            this.setState({
                transportType:this.props.transportType,
                otherType:this.props.otherType,
                origin: this.props.origin,
                destination:this.props.destination,
                departure:new Date(this.props.departure),
                arrival: new Date(this.props.arrival),
                transportNote: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.transportNote))),
                attachedFiles:[...this.props.attachedFiles],
                saveReady:true,
                showDatesSection:this.props.showDatesSection,
                deletedFiles:[]
            })
        }
        else{
            if (this.props.planStartDate!==undefined&&this.props.selectedDayIndex!==0){
                let departure=new Date(this.props.planStartDate);
                departure.setDate(departure.getDate() + this.props.selectedDayIndex-1);
                departure.setHours(0);
                departure.setMinutes(0);
                let arrival=new Date(this.props.planStartDate);
                arrival.setDate(arrival.getDate() + this.props.selectedDayIndex-1);
                arrival.setHours(0);
                arrival.setMinutes(0);
                this.setState({departure:departure,arrival:arrival})
              }
              else{
                let departure=new Date();
                departure.setHours(0);
                departure.setMinutes(0);
                let arrival=new Date();
                arrival.setHours(0);
                arrival.setMinutes(0);
                this.setState({departure:departure,arrival:arrival})
              }
        }
    }

    converImage = (url)=>{
      return new Promise(async (resolve, reject) => {
      var xhr = new XMLHttpRequest();
  
      // Use JSFiddle logo as a sample image to avoid complicating
      // this example with cross-domain issues.
      xhr.open( "GET", url, true );
      
      // Ask for the result as an ArrayBuffer.
      xhr.responseType = "arraybuffer";
      let myBlob
    
       xhr.onload =  function( e ) { 
          var arrayBufferView = new Uint8Array( this.response );
           myBlob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
          resolve(myBlob);
       }; 
      xhr.send();
    });
    }

    compressUploadedImage = async image => {
      let imageFile = image;
      let optionsLarge = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 2048,
        useWebWorker: true,
        maxIteration:20
      };
     
      try {
        const compressedFileLarge = await imageCompression(imageFile, optionsLarge);
        let attachedFiles = this.state.attachedFiles;
        attachedFiles.push(compressedFileLarge);
        this.setState({ attachedFiles: attachedFiles,
            changesMade: true });
      } catch (error) {
        console.log(error);
      }
    };


    attachmentUpploader = async event => {
        if (event.target.files[0]!==undefined){

          if (event.target.files[0].type.includes('image')){
            let objectURL = URL.createObjectURL(event.target.files[0])
            let myBlob;
            await this.converImage(objectURL).then(result => {
                myBlob = result;
                myBlob.name=event.target.files[0].name
              });
              this.compressUploadedImage(myBlob);
            //if uploading image compress image before attachment save
          }
          else{
            let attachedFiles = this.state.attachedFiles;
            attachedFiles.push(event.target.files[0]);
            this.setState({ attachedFiles: attachedFiles,
                changesMade: true });
          }
          this.props.writeAcitivityLog({
            activityKey:"TRIP_TRANSPORT_EDIT_SELECT_TIMES_SHOW",
            userScreen:"VIEW_TRIP",
            attachmentType:event.target.files[0].type
          })
        }
    };

    deleteFileHandler = async index =>{
            let attachedFiles = this.state.attachedFiles;
            if (this.props.edit){
                let deletedFiles = this.state.deletedFiles
                let deletedFile = attachedFiles[index]
                deletedFiles.push(deletedFile)
                this.setState({ deletedFiles:deletedFiles})
            }
            attachedFiles.splice(index,1);
            await this.setState({attachedFiles:attachedFiles})
      }

    
  updateOrigin = location => {
    this.setState({ origin: location });
    if (
      location.placeID !== undefined &&
      this.state.destination.placeID !== undefined
    ) {
      this.setState({ saveReady: true });
    } else {
      this.setState({ saveReady: false });
    }
    this.props.writeAcitivityLog({
      activityKey:"TRIP_TRANSPORT_EDIT_SELECT_ORIGIN",
      userScreen:"VIEW_TRIP"
    })
  };

  updateDestination = location => {
    this.setState({ destination: location });
    if (
      location.placeID !== undefined &&
      this.state.origin.placeID !== undefined
    ) {
      this.setState({ saveReady: true });
    } else {
      this.setState({ saveReady: false });
    }
    this.props.writeAcitivityLog({
      activityKey:"TRIP_TRANSPORT_EDIT_SELECT_DESTINATION",
      userScreen:"VIEW_TRIP"
    })
  };

  updateArrivalDate = date => {
    this.setState({ arrival: date });
    this.props.writeAcitivityLog({
      activityKey:"TRIP_TRANSPORT_EDIT_SELECT_ARRIVAL",
      userScreen:"VIEW_TRIP"
    })
  };


  updateDepartureDate = date => {

    this.setState({ departure: date });
    this.props.writeAcitivityLog({
      activityKey:"TRIP_TRANSPORT_EDIT_SELECT_DEPARTURE",
      userScreen:"VIEW_TRIP"
    })
  };

  updateTransportNote = transportNote => {
    this.setState({ transportNote: transportNote});
  };
  

  discardHandler = () =>{
    
    this.props.writeAcitivityLog({
      activityKey:"TRIP_TRANSPORT_EDIT_DISCARD",
      userScreen:"VIEW_TRIP"
    })

    this.props.discard()

  }

    finishHandler = () =>{
        let date = new Date();
        let timestamp = date.getTime();
        let transportType=this.state.transportType;
        let otherType=this.state.otherType;
        let origin=this.state.origin;
        let destination=this.state.destination;
        let departure=new Date(this.state.departure.getTime()).getTime();
        let arrival=new Date(this.state.arrival.getTime()).getTime();
        let transportNote =JSON.stringify(convertToRaw(this.state.transportNote.getCurrentContent()));
        let attachedFiles=[...this.state.attachedFiles];
        let transportID;
        let owner;
        if (this.props.edit){
            transportID = this.props.transportID
            owner = this.props.owner
        }
        else{
            transportID = timestamp
            owner = {
                ownerID: this.props.user.userID,
                ownerName: this.props.user.displayName,
                ownerPhoto: this.props.user.photoUrl,
                ownerEmail: this.props.user.userEmail
              };
        }
        let transport = {
            transportLastModified: timestamp,
            transportID:transportID,
            transportType:transportType,
            otherType:otherType,
            origin:origin,
            destination:destination,
            departure:departure,
            arrival:arrival,
            transportNote:transportNote,
            attachedFiles:attachedFiles,
            owner:owner,
            showDatesSection:this.state.showDatesSection
        }
        if (this.props.edit){
          transport.deletedFiles = this.state.deletedFiles
        }

         
      this.props.writeAcitivityLog({
        activityKey:"TRIP_TRANSPORT_EDIT_UPDATE",
        userScreen:"VIEW_TRIP"
      })

       this.props.save(transport)
       this.props.discard()
    }

    renderTopSection = () =>{
        let title = this.props.systemText.plan.viewPlan.transport.title[this.props.userLanguage];
        let buttonName = this.props.systemText.extraModals.addToPlan.button[this.props.userLanguage];
        if (this.props.edit){
            title = this.props.systemText.plan.viewPlan.transport.editTitle[this.props.userLanguage];
            buttonName = this.props.systemText.misc.buttons.updateButton[this.props.userLanguage];
        }
        let saveReady = false;
        if (this.state.destination.placeID !== undefined &&
          this.state.origin.placeID !== undefined &&
          this.state.transportType!==""){
            saveReady=true
          }
        return (
          <div className={classes.TopSection}>
            <ModalTopSection
             title={title}
             discard={this.discardHandler}
             saveReady={saveReady}
             showButton={true}
             buttonText={buttonName}
             clicked={this.finishHandler}
             saveButton={true}
             RTL = {this.props.RTL}
             modalView = {true}
            />
           
          </div>
        );
    }

    renderTransportIcon = () =>{
        switch(this.state.transportType){
            case "plane":{
                return(<img src={planeIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "ferry":{
                return(<img src={ferryIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "train":{
                return(<img src={trainIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "car":{
                return(<img src={carIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "taxi":{
                return(<img src={taxiIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "bus":{
                return(<img src={busIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "other":{
                return(<img src={otherIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            default: return null;
        }
    }

    transportSelectionHandler = type =>{
      this.setState({transportType: type})

      this.props.writeAcitivityLog({
        activityKey:"TRIP_TRANSPORT_EDIT_SELECT_TRANSPORT",
        userScreen:"VIEW_TRIP",
        transportType:type
      })
    }

    otherTransportInputBlurHandler = () =>{
      this.props.writeAcitivityLog({
        activityKey:"TRIP_TRANSPORT_EDIT_SELECT_OTHER_TRANSPORT",
        userScreen:"VIEW_TRIP",
        transportType:this.state.otherType
      })
    }

    renderTransportSeclection = () =>{
        return(
            <div className={classes.TransportSelection}>
              <div className={classes.TransportHeader}>
                {this.props.systemText.plan.viewPlan.transport.transportHeader[this.props.userLanguage]}
              </div>
              <div className={classes.TransportOptions}>
                {this.state.transportType==="plane"?(
                  <img src={planeIcon} alt="" className={classes.TransportIcon}/>
                ):(
                  <img src={planeIconGrey} alt="" className={classes.TransportIcon}
                  onClick={()=>{this.transportSelectionHandler("plane")}}
                  />
                )}
                {this.state.transportType==="ferry"?(
                  <img src={ferryIcon} alt="" className={classes.TransportIcon}/>
                ):(
                  <img src={ferryIconGrey} alt="" className={classes.TransportIcon}
                  onClick={()=>{this.transportSelectionHandler("ferry")}}
                  />
                )}
                {this.state.transportType==="train"?(
                  <img src={trainIcon} alt="" className={classes.TransportIcon}/>
                ):(
                  <img src={trainIconGrey} alt="" className={classes.TransportIcon}
                  onClick={()=>{this.transportSelectionHandler("train")}}
                  />
                )}
                 {this.state.transportType==="bus"?(
                  <img src={busIcon} alt="" className={classes.TransportIcon}/>
                ):(
                  <img src={busIconGrey} alt="" className={classes.TransportIcon}
                  onClick={()=>{this.transportSelectionHandler("bus")}}
                  />
                )}
                 {this.state.transportType==="car"?(
                  <img src={carIcon} alt="" className={classes.TransportIcon}/>
                ):(
                  <img src={carIconGrey} alt="" className={classes.TransportIcon}
                  onClick={()=>{this.transportSelectionHandler("car")}}
                  />
                )}
                 {this.state.transportType==="taxi"?(
                  <img src={taxiIcon} alt="" className={classes.TransportIcon}/>
                ):(
                  <img src={taxiIconGrey} alt="" className={classes.TransportIcon}
                  onClick={()=>{this.transportSelectionHandler("taxi")}}
                  />
                )}
                {this.state.transportType==="other"?(
                  <img src={otherIcon} alt="" className={classes.TransportIcon}/>
                ):(
                  <img src={otherIconGrey} alt="" className={classes.TransportIcon}
                  onClick={()=>{this.transportSelectionHandler("other")}}
                  />
                )}
                {this.renderOtherTransportInput()}
              </div>
            </div>
        )
    }

    renderOtherTransportInput = () =>{
        if (this.state.transportType==="other"){
            return(<input className={classes.OtherInput} 
              value={this.state.otherType} 
              placeholder= {this.props.systemText.plan.viewPlan.transport.otherPlaceholder[this.props.userLanguage]}
              onChange={e=>{this.setState({otherType:e.target.value})}}
              onBlur = {this.otherTransportInputBlurHandler}
              />)
        }
    }

    renderTransportType = () =>{
        return (<div className={classes.TransportType}>
            {this.renderTransportSeclection()}
            
        </div>)
    }

    renderOriginDestination = () =>{
        return (<div className={classes.OriginDestination}>
            <div className={classes.Origin}>
            <SearchGoogle
                update={this.updateOrigin}
                location={this.state.origin}
                onlySearch={true}
                placeholder={this.props.systemText.plan.viewPlan.transport.origin[this.props.userLanguage]}

                RTL = {this.props.RTL}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                />
            </div>
            <div className={classes.Destination}>
            <SearchGoogle
                update={this.updateDestination}
                location={this.state.destination}
                onlySearch={true}
                placeholder={this.props.systemText.plan.viewPlan.transport.destination[this.props.userLanguage]}

                RTL = {this.props.RTL}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                />
            </div>
        </div>)
    }
  
    renderDepartureArrival = () =>{
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
      if (this.state.showDatesSection){
        return (
        <div className={classes.DepartureArrivalSection}>
           <div className={classes.DepartureArrival}>
              <img src={checkedBox} alt="" className={classes[checkIconClass]} 
              onClick={()=>{this.setState({showDatesSection:false})
              this.props.writeAcitivityLog({
                activityKey:"TRIP_TRANSPORT_EDIT_SELECT_TIMES_HIDE",
                userScreen:"VIEW_TRIP"
              })
              }}/>
             <div className={classes.DepArrHeader}>{this.props.systemText.plan.viewPlan.transport.showArrivalDeparture[this.props.userLanguage]}</div>
             </div>
        <div className={classes.DepartureArrival}>
            <div className={classes.DepArrSection}>
            <div className={classes.DepArrHeader}>{this.props.systemText.plan.viewPlan.transport.departure[this.props.userLanguage]}</div>
            <div className={classes.Departure}>
                
                <DatePicker
                    className={classes.customDatePickerWidth}
                    selected={this.state.departure}
                    onChange={this.updateDepartureDate}
                    showTimeSelect
                    timeIntervals={5}
                    dateFormat="Pp"
                    withPortal
                    dateFormat="dd/MM/yy HH:mm"
                    timeFormat="HH:mm"
                />
            </div>
            </div>
            <div className={classes.DepArrSection}>
            <div className={classes.DepArrHeader}>{this.props.systemText.plan.viewPlan.transport.arrival[this.props.userLanguage]}</div>
            <div className={classes.Arrival}>
            
            <DatePicker
                className={classes.customDatePickerWidth}
                selected={this.state.arrival}
                onChange={this.updateArrivalDate}
                showTimeSelect
                dateFormat="Pp"
                withPortal
                timeIntervals={5}
                dateFormat="dd/MM/yy HH:mm"
                timeFormat="HH:mm"
              />
            </div>
            </div>
            </div>
            </div>)
      }
      else{
        return(
          <div className={classes.DepartureArrivalSection}>
            <div className={classes.DepartureArrival}>
              <div  className={classes[uncheckBoxClass]} 
              onClick={()=>{this.setState({showDatesSection:true})
              this.props.writeAcitivityLog({
                activityKey:"TRIP_TRANSPORT_EDIT_SELECT_TIMES_SHOW",
                userScreen:"VIEW_TRIP"
              })
              }}/>
             <div className={classes.DepArrHeader}>{this.props.systemText.plan.viewPlan.transport.showArrivalDeparture[this.props.userLanguage]}</div>
             </div>
          </div>
        )
      }
    }

    renderAttachmentSection = () => {
      /*   if (this.props.edit||this.state.attachedFiles.length > 0){ */
            return (
            <div className={classes.AttachmentSection}>
                {/* {this.props.edit?( */}
                    <div className={classes.UploadSection}>
                        <div className={classes.ImageUploadWrapper}>
                            <input
                                type="file"
                                name="upload"
                                accept="application/pdf,image/*"
                                onChange={this.attachmentUpploader}
                                className={classes.FileInput}
                                multiple={false}
                            />
                            <div className={classes.UploadButton}>
                                <img src={attachIcon} alt="" className={classes.AttachIcon} />
                            </div>
                        </div>
                        <div className={classes.AttachmentMessage}>
                            {this.props.systemText.plan.viewPlan.attachments.attachmentsMessage[this.props.userLanguage]}
                        </div>
                    </div>{/* ):null} */}
                
                {this.renderAttachedFiles()}
            </div>);
      /* } */
    }
    
    renderAttachedFiles = () => {
        if (this.state.attachedFiles.length > 0) {
          return (
            <div className={classes.AttachedFiles}>
              <div className={classes.SectionTitle}>
                {this.props.systemText.plan.viewPlan.attachments.attachedFiles[this.props.userLanguage]}
              </div>
              <div className={classes.Files}>
                {this.state.attachedFiles.map((file, index) => (
                  <div className={classes.FileItem}>
                      {file.url!==undefined?(
                            <a href={file.url} target="_blank" key={index}>
                            {file.name}
                            </a>
                      ):(
                        <div> {file.name}</div>
                      )}
                    {/*  {this.props.edit?( */}
                        <img
                        src={removeIcon}
                        alt=""
                        className={classes.RemoveIcon}
                        onClick={() => {
                            this.deleteFileHandler(index);
                        }}
                        />
                   {/*   ):null} */}
                  </div>
                ))}
              </div>
            </div>
          );
        }
      }; 

    renderNoteSection = () => {
        return(
            <div className={classes.NoteSection}>
                <Editor
                 toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                 
                  }}
                    placeholder={this.props.systemText.plan.viewPlan.transport.addNotes[this.props.userLanguage]}
                    className={classes.NoteInputField}
                    customStyleMap={customStyleMap}
                    editorState={this.state.transportNote}
                    onChange={this.updateTransportNote}
                    
                    resize="none"
                />
            </div>
        )
    }

   /*  renderPrice = () =>{
      return(<div className={classes.PriceSection}>
         <div className={classes.DepArrHeader}>{this.props.systemText.plan.viewPlan.transport.price[this.props.userLanguage]}</div>
        <input type="number" 
        className={classes.PriceInput}
        value={this.state.transportPrice} onChange={e=>{this.setState({transportPrice:e.target.value})}}/>
      </div>)
    } */

    renderBody = () =>{
        return(<div className={classes.Body}>
            {this.renderTransportType()}
            {this.renderOriginDestination()}
            {this.renderDepartureArrival()}
            {this.renderAttachmentSection()}
            {this.renderNoteSection()}
        </div>)
    }

    render(){
        let RTL = ""
        if (this.props.RTL){
            RTL = "RTL"
        }
        return(
            <div className={[classes.Modal,classes[RTL]].join(" ")}>
            {this.renderTopSection()}
            {this.renderBody()}
        </div>)
    }
}

export default PlanTransportEditor;