
import React, { Component } from "react";

import classes from "./WelcomeWizardV2.module.css";

import Backdrop from "../../UI/Backdrop/Backdrop"

import { isMobile, isMobileOnly } from "react-device-detect"; 

let discoverScreenshotPlaces = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FDiscoverScreenshot_12.jpg?alt=media&token=fc2c2ed9-db3c-4267-8c03-c9042e5fc0a2"
let discoverScreenshotTrip = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FDiscoverScreenshot_31.jpg?alt=media&token=b1beee1a-5cb9-4bb7-a3a1-a126b18ddd6d"
let mobileDiscoverScreenshotPlaces = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobileDiscoverScreenshot_12.jpg?alt=media&token=989ffbc4-46d0-42af-b2e4-34acad6535f8"
let mobileDiscoverScreenshotTrip = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobileDiscoverScreenshot_3.jpg?alt=media&token=08d99955-5d5a-42fe-b585-dd3773aac240"
let planScreenshot = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FPlanScreenshot_1.jpg?alt=media&token=62759db1-b68b-4cab-b186-3977cf93b883"
let mobilePlanScreenshot = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobilePlanScreenshot_1.jpg?alt=media&token=458198fd-6308-4f2e-a592-d67f4449f6ec"
let experienceScreenshot = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FExperienceScreenshot_12.jpg?alt=media&token=eea04754-caca-4d10-9fbd-23a8afe7f76e"
let mobileExperienceScreenshot = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWelcomeWizard%2FMobileExperienceScreenshot_12.jpg?alt=media&token=d7921b17-0649-4c9e-835f-ebb65ae00c70"
let mobileLogo = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FLogo.png?alt=media&token=9c05c21e-ea61-4000-ba7f-adf9e69edac0"


class WelcomeWizard extends Component {
    state = {
    };

    renderSkipButton = () =>{
        let buttonClass = "SkipButton"
        if (this.props.RTL){
          buttonClass = "SkipButtonRTL"
        }
        return(
            <div className={classes[buttonClass]}
            onClick={()=>{
              this.props.writeAcitivityLog({
                  activityKey:"WELCOME_WIZARD_SKIP",
                  userScreen:"WELCOME_WIZARD",
                  eventLevel:1
              });
              this.props.discrad()}}>
                {this.props.systemText.welcomeWizard.skipButton[this.props.userLanguage]}
            </div>
        )
    }

    renderBody = () =>{
        return(
            <div className={classes.Body}>
                <div className={classes.TopLogoSection}>
                    <img src = {mobileLogo} alt="" className={classes.TopLogo}/>
                </div>
                <div className={classes.MainMessage}>
                {this.props.systemText.welcomeWizard.secondVersionText.mainMessage[this.props.userLanguage]}
                </div>
                {this.renderMessage1()}
                {this.renderMessage2()}
                {this.renderMessage3()}
                {this.renderMessage4()}
                {this.renderOptionsSelection()}
            </div>
        )
    }

    renderMessage1 = () =>{
        let discoverScreen = discoverScreenshotPlaces
        if (isMobile){
            discoverScreen = mobileDiscoverScreenshotPlaces
        }
        return(
            <div className={classes.Message}>
                <div className={classes.MessageHeader}>

                </div>
                <div className={classes.MessageContent}>
                    <div className={classes.MessageText}>
                   {/*  <div className={classes.Number}>{"1"}</div> */}
                        <div className={classes.MessageTextPart1}>
                        {this.props.systemText.welcomeWizard.secondVersionText.discoverPlacesPart1[this.props.userLanguage]}
                        </div>
                        <div className={classes.MessageTextPart2}>
                        {this.props.systemText.welcomeWizard.secondVersionText.discoverPlacesPart2[this.props.userLanguage]}
                        </div>
                    </div>
                <img
                    placeholderSrc={""}
                    src={discoverScreen}
                    alt=""
                    className={classes.ScreenshotImageLeft}
                />
                </div>
            </div>
        )
    }

    renderMessage2 = () =>{
      
        let discoverScreen = planScreenshot
        if (isMobile){
            discoverScreen = mobilePlanScreenshot
        }
        return(
            <div className={classes.Message}>
                <div className={classes.MessageHeader}>

                </div>
                <div className={classes.MessageContent}>
                    <div className={classes.MessageText}>
                  {/*   <div className={classes.Number}>{"2"}</div> */}
                        <div className={classes.MessageTextPart1}>
                        {this.props.systemText.welcomeWizard.secondVersionText.suggestedTipsPart1[this.props.userLanguage]}
                        </div>
                        <div className={classes.MessageTextPart2}>
                        {this.props.systemText.welcomeWizard.secondVersionText.suggestedTipsPart2[this.props.userLanguage]}
                        </div>
                    </div>
                <img
                    placeholderSrc={""}
                    src={discoverScreen}
                    alt=""
                    className={classes.ScreenshotImageRight}
                />
                </div>
            </div>
        )
    }

    renderMessage3 = () =>{
        let discoverScreen = discoverScreenshotTrip
        if (isMobile){
            discoverScreen = mobileDiscoverScreenshotTrip
        }
        return(
            <div className={classes.Message}>
                <div className={classes.MessageHeader}>

                </div>
                <div className={classes.MessageContent}>
                    <div className={classes.MessageText}>
                   {/*  <div className={classes.Number}>{"3"}</div> */}
                        <div className={classes.MessageTextPart1}>
                        {this.props.systemText.welcomeWizard.secondVersionText.planPart1[this.props.userLanguage]}
                        </div>
                        <div className={classes.MessageTextPart2}>
                        {this.props.systemText.welcomeWizard.secondVersionText.planPart2[this.props.userLanguage]}
                        </div>
                    </div>
                <img
                    placeholderSrc={""}
                    src={discoverScreen}
                    alt=""
                    className={classes.ScreenshotImageLeft}
                />
                </div>
            </div>
        )
    }

    renderMessage4 = () =>{
        let discoverScreen = experienceScreenshot
        if (isMobile){
            discoverScreen = mobileExperienceScreenshot
        }
        return(
            <div className={classes.Message}>
                <div className={classes.MessageHeader}>

                </div>
                <div className={classes.MessageContent}>
                    <div className={classes.MessageText}>
                      {/*   <div className={classes.Number}>{"4"}</div> */}
                        <div className={classes.MessageTextPart1}>
                        {this.props.systemText.welcomeWizard.secondVersionText.experiencesPart1[this.props.userLanguage]}
                        </div>
                        <div className={classes.MessageTextPart2}>
                        {this.props.systemText.welcomeWizard.secondVersionText.experiencesPart2[this.props.userLanguage]}
                        </div>
                    </div>
                <img
                    placeholderSrc={""}
                    src={discoverScreen}
                    alt=""
                    className={classes.ScreenshotImageRight}
                />
                </div>
                
            </div>
        )
    }

    renderOptionsSelection = () =>{
        return(
            <div className={classes.Options}>
                <div className={classes.GetPersonalizedTrips} 
                        onClick={()=>{this.props.getPersonalizedTrips()
                            this.props.writeAcitivityLog({
                                activityKey:"WELCOME_WIZARD_GET_SUGGESTIONS",
                                userScreen:"WELCOME_WIZARD",
                                eventLevel:1
                            });
                            this.props.discrad()}}>
                            {this.props.systemText.welcomeWizard.getPersonalizedTrips[this.props.userLanguage]}
                        </div>
                    <div className={classes.DiscoverNewPlaces}
                        onClick={()=>{this.props.getUserCountryPlaces();
                            this.props.writeAcitivityLog({
                                activityKey:"WELCOME_WIZARD_DISCOVER",
                                userScreen:"WELCOME_WIZARD",
                                eventLevel:1
                            });
                            this.props.discrad()}}>
                            {this.props.systemText.welcomeWizard.discoverPlaces[this.props.userLanguage]}
                        </div>
                    <div>
                    <div className={classes.DirectMeToHomePage} 
                    onClick={()=>{
                        this.props.writeAcitivityLog({
                            activityKey:"WELCOME_WIZARD_HOMEPAGE",
                            userScreen:"WELCOME_WIZARD",
                            eventLevel:1
                        });
                        this.props.discrad()}}>
                        {this.props.systemText.welcomeWizard.homePage[this.props.userLanguage]}
                    </div>
                    </div>
            </div>
        )
    }

 render() {
    let RTL = "LTR"
    if (this.props.RTL){
        RTL = "RTL"
    }
    
  return (
     
      <div className={[classes.Modal,classes[RTL]].join(" ")}>
          <Backdrop show={this.state.showBackdrop}/>
          {this.renderSkipButton()}
         {this.renderBody()}
    </div>
  );
}
}
export default WelcomeWizard;