import React from "react";
import classes from "./ScrollToTop.module.css";

let scrollIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2Fscrolltop.png?alt=media&token=92d5ab2c-0bb2-4da0-ae32-250c3167d526"

const scrollToTop = props => {

  return (
    <div className={classes.Scroll}>
          <img src={scrollIcon} alt ="" className={classes.Icon} onClick={props.scrollToTop}>
          </img>
    </div>
  );
};

export default scrollToTop;
