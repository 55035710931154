import React, { Component } from "react";
import classes from "./Plan.module.css";

import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop"
import Backdrop from "../../../Components/UI/Backdrop/Backdrop"
import ConfirmationModal from "../../../Components/UI/Modals/ConfirmationModal"
import Toolkit from "../../../Components/UI/Toolbar/Toolkit"
import Tag from "../../../Components/UI/Tag/Tag"
import ModalTopSection from "../../../Components/UI/Misc/ModalTopSection"
import TextareaAutosize from 'react-textarea-autosize';

import {isMobile, isMobileOnly} from 'react-device-detect';
import TextButton from "../../../Components/UI/Buttons/TextButton";

let tripPlaceholder = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTripPlaceholderLarge-min.jpg?alt=media&token=d7aa0cfd-a154-4d2e-9763-6cbd5106326a"
let deleteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2Fdelete.png?alt=media&token=1d88eb4a-6c67-43d8-81ed-0bb919c7d5fa"
let closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"
let cloneIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FSaveIcon%20(1).png?alt=media&token=6c7ced36-c59d-4801-9982-f5e7b5a3f3e4"
let cloneIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FSaveIconFade.png?alt=media&token=3b0c8980-1dd3-48f1-9db0-816d85f5e733"

let sightseeingIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSightSeeingIcon.png?alt=media&token=3b7f3202-493e-4f2e-981f-47944e78c5a4";
let foodDrinkIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFoodIcon.png?alt=media&token=f9f81121-4c3e-4290-8b3e-94fee05d149a";
let natureIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FNatureIcon.png?alt=media&token=69ef82b9-4af1-44e2-b713-a240fba4022e";
let activitiesIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FActivityIcon.png?alt=media&token=e46ab679-3b01-4172-9efc-1f528c9f76a7";
let sportIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSportIcon.png?alt=media&token=3d936de1-0f2b-40de-9313-69b90977602d";
let accomodationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIcon.png?alt=media&token=3f3fbed6-2d65-4896-a2d0-6036f7e1ac5b";

let toddlerIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FInfantIcon.png?alt=media&token=5297c1cc-4e67-4393-8b05-28802ffe739d"
let kidIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FKidIcon.png?alt=media&token=50ac147e-3e9b-4c60-bab7-88efa9fc8a3a"

var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);


class Plan extends Component {
    state={

    }

    discard = () =>{
        this.setState({showBackdrop: false, optionsTools:false, favoriteTools: false})
    }

    deletePlanHandler = () =>{
        let deleteMessage = this.props.systemText.plan.tools.leaveMessage[this.props.userLanguage]
        let primaryBtn = this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage];
        let secondaryBtn = this.props.systemText.plan.tools.leaveButton[this.props.userLanguage]
        if (this.props.owner.ownerID===this.props.user.userID){
          deleteMessage = this.props.systemText.plan.tools.deleteMessage[this.props.userLanguage]
          secondaryBtn =  this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage];
        }
        this.setState({
        confirmMessage:deleteMessage,
        primaryBtn: primaryBtn,
        secondaryBtn: secondaryBtn,
        confirmMode: true,
        showBackdrop: true,
        optionsTools: false
        });
    }
    
    
    confirmDelete = () => {
        this.setState({
            confirmMode: false,
            showBackdrop: false
        });
        if (this.props.owner.ownerID===this.props.user.userID){
        this.props.deleteHandler(this.props.index);
        }
        else{
        let sharedPlan ={
            planID:this.props.planID,
            planOwnerID:this.props.owner.ownerID,
        }
        let planEditors = this.props.planEditors;
        const index = planEditors.findIndex(editor => {
            return editor.userID === this.props.user.userID
        });
        planEditors.splice(index,1)
        this.props.leavePlan(sharedPlan,planEditors)
        }
    };
  
    discardDelete = () => {
        this.setState({
            confirmMode: false,
            showBackdrop: false
        });
    };

    renderImageSection = () =>{
        let imageSectionClass = "ImageSection"
        let photoPreviewClass = "PhotoPreview"
        if (this.props.RTL){
            photoPreviewClass = "PhotoPreviewRTL"
        }
        if (this.props.selectedContentWall==="AddToPlan"){
          imageSectionClass = "SelectPlanImageSection"
          photoPreviewClass = "AddToPlanPhotoPreview"
          if (this.props.RTL){
            photoPreviewClass = "AddToPlanPhotoPreviewRTL"
             }
        }
        if (this.props.selectedContentWall==="SelectPlan"){
          imageSectionClass = "SelectPlanImageSection"
          photoPreviewClass = "SelectPlanPhotoPreview"
          if (this.props.RTL){
            photoPreviewClass = "SelectPlanPhotoPreviewRTL"
             }
        }
        if (this.props.imagePreview.length>0){
            let image =""
            if (isMobileOnly){
                image = this.props.imagePreview[0].thumbnailImage.url
            }
            else{
                image = this.props.imagePreview[0].smallImage.url
            }
            return (<div className={classes[imageSectionClass]}>
                <img
                src={image}
                alt=""
                className={classes[photoPreviewClass]}
              ></img>
            </div>)       
        }
        else{
          
          return (<div className={classes[imageSectionClass]} >
            <img
            src={tripPlaceholder}
            alt=""
            className={classes[photoPreviewClass]}
          ></img>
        </div>)
        }
       
      }

    renderInfo = () =>{
        let planDurationClass = "PlanDuration"
        if (this.props.RTL){
            planDurationClass = "PlanDurationRTL"
        }
        return(
            <div className={classes.InfoContent}>
              <div className={classes.TopContent}>
                <div className={classes.PlanName}>{this.props.planName}</div>
                <div className={classes.PlanDurationInfo}>
                  <div className={classes[planDurationClass]}>{this.props.planDuration+" "+this.props.systemText.plan.planWall.days[this.props.userLanguage]}</div>
                  {this.props.noDatesSelected?(null):(
                  <div className={classes.PlanDates}> {moment(this.props.planDates.startDate).format("DD/MM/YYYY") +" - "+moment(this.props.planDates.endDate).format("DD/MM/YYYY")}</div>)}
                </div>
               </div>
               {this.renderPublicInfo()}
               {this.renderEditors()}
            </div>   
        )
    }

    renderEditors = () =>{
        if (this.props.planEditors.length>1){
            if (this.props.selectedContentWall==="WelcomePage"||
            this.props.selectedContentWall==="MyContent"){
                return(
                    <div className={classes.PlanEditors}>
                        {this.props.planEditors.map((user,index)=>(
                            <img key={index} className={classes.EditorImage} src = {user.photoUrl}/>
                        ))}
                    </div>
                )
            }
        }
       
        
    }

    renderStyleIcon = (index) =>{
        let icon = ""
        switch(index){
            case 0:{
                icon=sightseeingIcon
                break;
            }
            case 1:{
                icon=natureIcon
                break;
            }
            case 2:{
                icon=activitiesIcon
                break;
            }
            case 3:{
                icon=sportIcon
                break;
            }
            case 4:{
                icon=foodDrinkIcon
                break;
            }
            case 5:{
                icon=accomodationIcon
                break;
            }
            default: return null;
        }
        if (icon!==""){
            return(
                <img src={icon} alt="" className={classes.StyleIcon}/>
            )
        }
        
    }

    renderPublicInfo = () =>{
        if (this.props.selectedContentWall==="Favorite"||this.props.selectedContentWall==="Discover"){
            let tripStyle= []
            if (this.props.tripStyle.placesByMainCategory!==undefined){
                tripStyle = this.props.tripStyle.placesByMainCategory
            }
            
                /* .sort((a, b) => {
                var keyA = a;
                var keyB = b;
                // Compare the 2 dates
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
              }); */
            return(
                <div className={classes.PublicInfo}>
                    {this.props.tripCountries.length>1?(
                        <div className={classes.LocationTags}>
                             {this.props.tripCountries.map((treeID, index) => {
                                 let text = treeID
                                 if (this.props.locationsTranslator[treeID]!==undefined){
                                     text=this.props.locationsTranslator[treeID][this.props.userLanguage]
                                 }
                                 return(
                                    <Tag 
                                    key={index}
                                    style={"LocationFilter"}
                                    text={text}
                                    RTL={this.props.RTL}/>
                                 )
                             })}
                        </div>
                    ):(
                        <div className={classes.LocationTags}>
                             {this.props.tripLocationTags.slice(0,7).map((treeID, index) =>{
                                 let text = treeID
                                 if (this.props.locationsTranslator[treeID]!==undefined){
                                     text = this.props.locationsTranslator[treeID][this.props.userLanguage]
                                 }
                                return(
                                    <Tag 
                                    key={index}
                                    noHover
                                    style={"LocationFilter"}
                                    text={text}
                                    RTL={this.props.RTL}/>
                                    )
                                }
                                  
                             )}
                             {this.props.tripLocationTags.length>7?(
                                 <div>...</div>
                             ):null}
                        </div>
                    )}
                    <div className={classes.StyleAndParticipants}>
                        <div className={classes.TripStyle}>
                            {tripStyle.map((val,index)=>{
                                if (val!==0){
                                    return(
                                        <div key={index} className={classes.Style}>
                                            {this.renderStyleIcon(index)}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        {Array.isArray(this.props.participants)?(
                            <div className={classes.Participants}>
                            {this.props.participants.includes("Kids")?(
                                <img src={kidIcon} alt="" className={classes.ParticipantIcon}/>
                            ):null}
                             {this.props.participants.includes("Toddlers")?(
                                <img src={toddlerIcon} alt="" className={classes.ParticipantIcon}/>
                            ):null}
                        </div>
                        ):null}
                        
                    </div>
                    
                </div>
            )
        }
       
    }

    renderBodyContent = ()=>{
       
        let bodyContentClass = "BodyContent"
        if ((this.props.selectedContentWall==="AddToPlan"||
        this.props.selectedContentWall==="SelectPlan") && isMobile){
            bodyContentClass = "BodyContentFlex"
        }
        return(
            <div className={classes[bodyContentClass]} onClick={()=>{ this.props.viewHandler(this.props.index); }}>
              
                {this.renderImageSection()}
                {this.renderInfo()}   
               
              
            </div>
        )
    }

    renderTools = () =>{
        let planToolsClass = "PlanTools"
        let toolsRTL = ""
        if (this.props.RTL){
            planToolsClass = "PlanToolsRTL"
            toolsRTL = "GreyedToolsRTL"
        }
        let greyedToolsClass = "GreyedTools"
        if (this.props.selectedContentWall==="MyContent"||this.props.selectedContentWall==="AdminWall"){
            let optionsIcon="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditMode.png?alt=media&token=53018dfb-bdc0-4dcb-92b1-600bcfac76a7"
            if (isMobile){
              optionsIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditModeHorizontal.png?alt=media&token=91cf1eb7-39b7-423a-85ee-fb963daaa3a1"
            }
            return(
                <div className={classes[planToolsClass]}>
                    <img src={optionsIcon} alt="" className={classes.OptionsIcon}
                        onClick={(e)=>{ e.stopPropagation();this.setState({optionsTools: true, showBackdrop: true})}}
                    />
                </div>
            )
        }
        else if (this.props.selectedContentWall!=="WelcomePage"&&this.props.selectedContentWall!=="AddToPlan"&&this.props.selectedContentWall!=="SelectPlan"){
            return(
                <div className={classes[planToolsClass]}>
                    {this.renderFavoriteIcon()}
                    {this.props.loggedUser||this.props.selectedContentWall==="SuggestedTrips"?(
                    <div className={[classes[greyedToolsClass],classes[toolsRTL]].join(" ")}>
                        <img src={cloneIcon} alt="" className={classes.ToolsIcon}
                        onClick={(e)=>{ e.stopPropagation();this.props.clonePlan(this.props.index)}}
                        />
                        <div className={classes.ToolText}>
                            {this.props.systemText.plan.tools.savePlan[this.props.userLanguage]}</div>
                    </div>
                    ):(
                        <div className={[classes[greyedToolsClass],classes[toolsRTL]].join(" ")}>
                        <img src={cloneIconFade} alt="" className={classes.ToolsIcon}
                        onClick={(e)=>{ e.stopPropagation();this.props.loginHandler()}}
                        />
                        <div className={classes.ToolText}>
                            {this.props.systemText.plan.tools.savePlan[this.props.userLanguage]}</div>
                    </div>
                    )}
                    {this.renderDislikeIcon()}
                </div>
            )

        }
    }

    dislikeHandler = async () =>{
        await this.setState({showDislikedModal:true,showNullBackdrop:true,dislikeMessageText:""})
    }

    renderDislikeIcon = () =>{
        let planToolsClass = "PlanTools"
        let toolsRTL = ""
        if (this.props.RTL){
            planToolsClass = "PlanToolsRTL"
            toolsRTL = "GreyedToolsRTL"
        }
        let greyedToolsClass = "GreyedTools"
        if (this.props.selectedContentWall==="SuggestedTrips"&&!this.state.tripDisliked){
            let toolsIconClass = "DislikeIcon"
            return(
                <div className={[classes[greyedToolsClass],classes[toolsRTL]].join(" ")}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDislikeIcon.png?alt=media&token=7c1b777c-384c-4276-b47a-f3d4f7883aa7"}
                alt="" className={classes[toolsIconClass]}
                onClick={(e)=>{ e.stopPropagation(); this.dislikeHandler()}}
                />
                <div className={classes.ToolText}>
                    {this.props.systemText.plan.tools.dislikeSuggestion[this.props.userLanguage]}</div>
            </div>
            )
        }
    }

    dislikeReportHandler = () =>{
        this.setState({showDislikedModal:false,showNullBackdrop:false,tripDisliked:true})

        this.props.reportDislikedSuggestedTrip(this.props.index, this.state.dislikeMessageText)
    }

    renderDislikedModal = () =>{
        if (this.state.showDislikedModal){
            return(
                <div className={classes.DislikedModal}>
                    <ModalTopSection
                        title={""}
                        discard={()=>{this.setState({showDislikedModal:false,showNullBackdrop:false})}}
                        showButton={false}
                        RTL = {this.props.RTL}
                        modalView={true}
                        />
                    <div className={classes.DislikeModalBody}>
                        <div className={classes.DislikeMessage}>
                        {this.props.systemText.tripGenerator.generatedContent.dislikedMessage[this.props.userLanguage].split('\n').map(str => <p>{str}</p>)}
                        </div>
                        <div className={classes.DislikeInputSection}>
                            <TextareaAutosize  className={classes.DislikeInput} value={this.state.dislikeMessageText} 
                                    placeholder="..."
                                    onChange={(e)=>{this.setState({dislikeMessageText:e.target.value})}}
                                    /> 
                        </div>
                    </div>
                    <div className={classes.ButtonSection}>
                        <TextButton
                         clicked={this.dislikeReportHandler}
                          text={this.props.systemText.tripGenerator.generatedContent.dislikedButton[this.props.userLanguage]}
                          style = {"RemoveButton2"}
                        />                        
                    </div>
                </div>
            )
        }
    }

    renderFavoriteIcon = ()=>{
        if (this.props.selectedContentWall!=="SuggestedTrips"){
            let greyedToolsClass = "GreyedTools"
            let toolsRTL = ""
            if (this.props.RTL){
                toolsRTL = "GreyedToolsRTL"
            }
            if (this.props.loggedUser){
              if (this.props.selectedContentWall==="Favorite"||this.props.favoriteTripsIDs.includes(this.props.planID)){
                return(
                    <div className={[classes[greyedToolsClass],classes[toolsRTL]].join(" ")}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FInFavorites.png?alt=media&token=a5d129b8-23a0-4051-9993-731ba1325bdf"} 
                alt="" className={classes.ToolsIcon} onClick={(e)=>{ e.stopPropagation();this.setState({favoriteTools: true, showBackdrop: true})}}/>
                 <div className={classes.ToolText}>
                    {this.props.systemText.plan.tools.favorite[this.props.userLanguage]}</div>
            </div>
            )
               }
              else{
                  return(
                    <div className={[classes[greyedToolsClass],classes[toolsRTL]].join(" ")}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FInFavoritesGrey.png?alt=media&token=7063ef15-2b81-43be-ae29-151b614c880a"} 
                    alt="" className={classes.ToolsIcon} onClick={(e)=>{ e.stopPropagation();this.props.addPlanToFavorite(this.props.index)}}/> <div className={classes.ToolText}>
                    {this.props.systemText.plan.tools.favorite[this.props.userLanguage]}</div>
            </div>
            )
              }
            }
            else{
              return(  <div className={[classes[greyedToolsClass],classes[toolsRTL]].join(" ")}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FInFavoritesFade.png?alt=media&token=56f2e0c3-2c04-4305-8448-1acf9fddcb25"} 
                alt="" className={classes.ToolsIcon} data-tip data-for="favoriteTip"
                 onClick={(e)=>{ e.stopPropagation();this.props.loginHandler()}}
                />
                 <div className={classes.ToolText}>
                    {this.props.systemText.plan.tools.favorite[this.props.userLanguage]}</div>
            </div>)
             
            }
        }
      }

    renderFavoriteTools = () =>{
        if (this.state.favoriteTools){
            let toolKitStyle="Style2"
            let tools = [];
             tools.push({
              action:  ()=>{this.props.updateFavoriteTags(this.props.index);
                this.setState({showBackdrop:false, favoriteTools:false})},
              text: this.props.systemText.place.favorite.editFavoriteTags[this.props.userLanguage],
              icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTagIcon.png?alt=media&token=6fbf97d9-a636-4b33-baf9-e0412cadc9c9"
            });
            tools.push({
              action: ()=>{this.props.removePlanFromFavorite(this.props.index);
                this.setState({showBackdrop:false, favoriteTools:false})},
              text: this.props.systemText.place.favorite.removeFromFavorite[this.props.userLanguage],
              icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2Fdelete.png?alt=media&token=8e0e899b-354c-45e8-9f47-dc70471f1f81"
            });
            return(
              <Toolkit
              style={toolKitStyle}
              tools={tools}
              RTL = {this.props.RTL}
              bodyRTL={this.props.RTL}
              />)
          }
    }

    renderOptionsTools = () => {
        if (this.state.optionsTools){
            let toolKitStyle="Style2"
            let tools = [];
            tools.push({
                action: ()=>{
                    this.setState({optionsTools:false,showBackdrop: false});this.props.clonePlan(this.props.index)},
                text: this.props.systemText.plan.tools.clonePlan[this.props.userLanguage],
                icon: cloneIcon
                });
            if (this.props.owner.ownerID===this.props.user.userID)
            { 
            tools.push({
            action: ()=>{
                this.setState({optionsTools:false,showBackdrop: false});this.deletePlanHandler()},
                text: this.props.systemText.plan.tools.deletePlan[this.props.userLanguage],
            icon: deleteIcon
            });
            }
            else if (this.props.planEditors.map(user=>{return user.userID}).includes(this.props.user.userID)){
            tools.push({
                action: ()=>{
                    this.setState({optionsTools:false,showBackdrop: false});this.deletePlanHandler()},
                text: this.props.systemText.plan.tools.leavePlan[this.props.userLanguage],
                icon: closeIcon
                });
            }
            return(
                <Toolkit
                style={toolKitStyle}
                tools={tools}
                RTL = {this.props.RTL}
                bodyRTL={this.props.RTL}
                />)
          }
    }

    
    renderConfirmModal = () => {
        if (this.state.confirmMode) {
          return (
            <ConfirmationModal
              header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
              primaryAction={this.discardDelete}
              secondaryAction={this.confirmDelete}
              message={this.state.confirmMessage}
              primaryBtn={this.state.primaryBtn}
              secondaryBtn={this.state.secondaryBtn}
              primaryStyle = {"DiscardButton"}
              secondaryStyle = {"DeleteButton"}
              RTL = {this.props.RTL}
  
            />
          );
        }
      };

    render(){
        let bodyClass = "DiscoverBody"
        let RTLClass = ""
        let toolsClass = "Tools"
        if (this.props.RTL){
          RTLClass = "RTL"
          toolsClass = "ToolsRTL"
        }
        if (this.props.selectedContentWall==="WelcomePage"){
            bodyClass = "WelcomeBody"
        }
        else if (this.props.selectedContentWall==="AddToPlan"){
            bodyClass = "AddToPlanBody"
        }
        else if (this.props.selectedContentWall==="SelectPlan"){
            bodyClass = "SelectPlanBody"
        }
        return(
            <div className={[classes[bodyClass],classes[RTLClass]].join(" ")}>
                {this.renderConfirmModal()}
                {this.renderDislikedModal()}
                <TransparentBackdrop show={this.state.showBackdrop} clicked={this.discard} />
                <Backdrop show={this.state.showNullBackdrop}/>
                {this.renderTools()}
                <div className={classes[toolsClass]}>
                  {this.renderFavoriteTools()}
                  {this.renderOptionsTools()}
                </div> 
                {this.renderBodyContent()}
            </div>)
          
      }
}
 
export default Plan;