import React, { Component } from "react";
import classes from "./TripPlanOverview.module.css";

import PlanDaysScroller from "../../../Components/Complex/Plan/PlanDaysScroller"
import PlanTasksWall from "../../../Components/Complex/Plan/PlanTasksWall";
import IconTab from "../../../Components/UI/Tabs/IconTab"
import { isMobile, isMobileOnly } from "react-device-detect";

import { Editor,EditorState , convertFromRaw} from 'draft-js';

let tripIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTripIconGrey.png?alt=media&token=eb61b497-5906-4b11-bd33-413e77cc4e45"
let tripIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTripIcon.png?alt=media&token=42112c10-971f-4b71-9c5b-5c2bc19e6245"
let tasksIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTasksIconGrey.png?alt=media&token=7a90a2e2-fe2f-4eea-b978-3cb32ae2c561"
let tasksIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTasksIcon.png?alt=media&token=3f9dff6b-9539-43c8-add4-f902949a9b7c"
let plusIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPlusIconSquare.png?alt=media&token=65fcb735-831b-435c-8fbf-06538e6118ca"
let editIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIcon.png?alt=media&token=c964d68a-44ba-45bf-afd7-b4b8eaeb6fba"


var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);


class TripPlanOverview extends Component {
    state={
        planDescription:EditorState.createEmpty(),
    }

    componentDidMount = () =>{
        if (this.props.planDescription!==undefined){
            try {
                JSON.parse(this.props.planDescription);
                this.setState({planDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.planDescription))),
                  jsonString: true
                  })
            } catch (e) {
           
            }
        }
    }

    componentDidUpdate = (prevProps) =>{
        if (JSON.stringify(this.props.planDescription)!==JSON.stringify(prevProps.planDescription)){
            try {
                JSON.parse(this.props.planDescription);
                this.setState({planDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.planDescription))),
                  jsonString: true
                  })
            } catch (e) {
           
            }
        }
    }

    renderPlanName = () =>{
        return(
            <div className={classes.PlanName}>
                {this.props.planName}
            </div>
        )
    }

    renderPlanImage = () =>{
        if (this.props.imagePreview.length > 0){
            let image =""
            if (isMobileOnly){
                if (this.props.imagePreview[0].smallImage!==undefined){
                    image = this.props.imagePreview[0].smallImage.url
                }
                else{
                    image = this.props.imagePreview[0].url
                }
            }
            else{
                if (this.props.imagePreview[0].mediumImage!==undefined){
                    image = this.props.imagePreview[0].mediumImage.url
                }
                else{
                    image = this.props.imagePreview[0].url
                }
            }
            return(
                <div className={classes.PlanImage}>
                    <img src={image} alt="" className={classes.ImagePreview}/>
                </div>
            )
        }
        else{
            return(
                <div className={classes.PlanImagePlaceholder}>
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTripPlaceholderLarge-min.jpg?alt=media&token=d7aa0cfd-a154-4d2e-9763-6cbd5106326a"} 
                    alt="" className={classes.ImagePreview}/>
                   
                </div>
            )
        }
    }

    renderPlanRemainingDays = () =>{
        if (!(this.props.isPublic&&!this.props.isEditor)){
            let today = new Date();
  
            var todayDate = moment(today).startOf('day');;
            var startDate = moment(this.props.planDates.startDate).startOf('day');
            var endDate = moment(this.props.planDates.endDate).startOf('day');;

            let daysLeft = startDate.diff(todayDate, 'days')
            let daysAfter = todayDate.diff(endDate, 'days')
            if (this.props.planDays.length===1 || this.props.noDatesSelected){
                return(
                    <div className={classes.DaysLeft}>
                    <div className={classes.DreamMessage}> {this.props.systemText.plan.viewPlan.planning.dreamingMessage[this.props.userLanguage]}</div>
                </div>
                )
            }
             else if (daysLeft>0){
            return(
                <div className={classes.DaysLeft}>
                    <div className={classes.DaysCalendar}>
                            <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCalendarIcon.png?alt=media&token=c1943d92-651b-4ca7-a758-7a5ef189157c"} 
                            alt="" className={classes.CalendarIcon}/>
                            <div className={classes.CalendarDays}>{daysLeft}</div>
                    </div>
                    
                    <div className={classes.DaysMessage}> {this.props.systemText.plan.viewPlan.planning.daysToGo[this.props.userLanguage]}</div>
                </div>
            )
            }
            else if (daysAfter>0){

            }
        }
        
    }

    renderPlanDescription = () =>{
        if (this.props.isPublic && this.props.planDescription!==undefined){
            return(
                <div className={classes.PlanDescription}>
                <Editor
                    blockStyleFn={this.blockStyleFn}
                    customStyleMap={this.styleMap}
                    className={classes.TextInput}
                    editorState={this.state.planDescription}
                    readOnly={true}
                    resize="none"
                    ref="editor"
                    />
                
            </div>
            )
        }
    }

    renderPlanDates = () =>{
        if (this.props.noDatesSelected){
            return(
                <div className={classes.PlanDates}>
                      {this.props.planDuration+" "+this.props.systemText.plan.planWall.days[this.props.userLanguage]}
                    </div>);
        }
        else{
            return(
                <div className={classes.PlanDates}>
                    {this.props.planDuration+" "+this.props.systemText.plan.planWall.days[this.props.userLanguage] +"  "+moment(this.props.planDates.startDate).format("DD/MM/YYYY")+" - "+moment(this.props.planDates.endDate).format("DD/MM/YYYY")}
                </div>
            )
        }
    }

    renderWallSelection = () =>{
        if (this.props.planOverview && this.props.isEditor){
            let tripIcon=tripIconGrey
            if (this.props.overviewScrollSelected==="Trip"){
                tripIcon=tripIconSelected
            }
            let tasksIcon=tasksIconGrey
            if (this.props.overviewScrollSelected==="Tasks"){
                tasksIcon=tasksIconSelected
            }
            let openTasks = this.props.planTasks.filter(task => task.taskStatus==="open").length
            return(
                <div className={classes.Selection}>
                     <IconTab
                    icon = {tripIcon}
                    text =  {this.props.systemText.plan.viewPlan.planning.scrollerHeader[this.props.userLanguage]}
                    selected = {this.props.overviewScrollSelected==="Trip"}
                    style={"Style2"}
                    clicked={()=>{this.props.updateScrollerSection("Trip")
                        this.props.writeAcitivityLog({
                            activityKey:"TRIP_OVERVIEW_PLAN_BUTTON",
                            userScreen:"VIEW_TRIP",
                            isEditor:this.props.isEditor
                        })
                    }}
                    RTL = {this.props.RTL}
                />
                <div className={classes.TabSeperator}/>
                 <IconTab
                    icon = {tasksIcon}
                    text = {this.props.systemText.plan.viewPlan.planning.tasksHeader[this.props.userLanguage]+" ("+openTasks+")"}
                    selected = {this.props.overviewScrollSelected==="Tasks"}
                    style={"Style2"}
                    clicked={()=>{this.props.updateScrollerSection("Tasks")
                    this.props.writeAcitivityLog({
                        activityKey:"TRIP_OVERVIEW_TASKS_BUTTON",
                        userScreen:"VIEW_TRIP",
                        isEditor:this.props.isEditor
                        })
                    }}
                    RTL = {this.props.RTL}
                />
                </div>
            )
        }
    }


    renderWall = () =>{
        switch(this.props.overviewScrollSelected){
            case "Trip":{
                let height = "80vh"
                if (this.props.isEditor){
                    height = "70vh"
                }
                if (isMobile){
                    height = "100%"
                }
                return( <PlanDaysScroller
                    planLastModified = {this.props.planLastModified}
                     height = {height}
                    editorsNumber={this.props.planEditors.length}
                    planEditors={this.props.planEditors}
                    isEditor={this.props.isEditor}
                    planDays={this.props.planDays}
                    planning={true}
                    ongoingDay={this.props.ongoingDay}
                    planOverview={this.props.planOverview}
                    overviewScrollSelected={this.props.overviewScrollSelected}
                    updateScrollerSection={this.props.updateScrollerSection}
                    locationsTranslator = {this.props.locationsTranslator}
                    categoriesTranslator = {this.props.categoriesTranslator}
                    
                    showDaySelection={false}
                    viewHandler={this.props.viewHandler}
                    planOverviewMode = {true}
                    overviewAddPlaceHandler = {this.props.overviewAddPlaceHandler}

                    updateColor={this.props.updateColor}
                    updatePlaceTime = {this.props.updatePlaceTime}
                    removeFromPlan={this.props.removeFromPlan}
                    voteHandler={this.props.voteHandler}
                    moveDayHandler={this.props.moveDayHandler}
                    updateDayOrder={this.props.updateDayOrder}
                    hoverHandler={()=>{return null}}

                    newNoteHandler = {this.props.newNoteHandler}
                    openModeNoteId = {this.props.openModeNoteId}
                    newNoteID = {this.props.newNoteID}
                    editNoteID = {this.props.editNoteID}
                    noteOpenHandler = {this.props.noteOpenHandler}
                    updateNote = {this.props.updateNote}

                    newTransportHandler={this.props.newTransportHandler}

                    addAccommodationHandler = {this.props.addAccommodationHandler}
    
                    showSuggestedTrips = {this.props.showSuggestedTrips}
                    
                    RTL = {this.props.RTL}
                    user={this.props.user}
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    writeAcitivityLog = {this.props.writeAcitivityLog}
                    />)
            }
            case "Tasks":{
                return(
                    <PlanTasksWall
                        isEditor={this.props.isEditor}
                        planEditors={this.props.planEditors}
                        planTasks={this.props.planTasks}
                        newTaskHandler = {this.props.newTaskHandler}
                        openModeTaskID={this.props.openModeTaskID}
                        newTaskID={this.props.newTaskID}
                        confirmDeleteTask = {this.props.confirmDeleteTask}

                        taskOpenHandler={this.props.taskOpenHandler}
                        deleteTask={this.props.deleteTask}
                        updateTasksOrder={this.props.updateTasksOrder}
                        updateTask={this.props.updateTask}

                        RTL = {this.props.RTL}
                        user={this.props.user}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                        writeAcitivityLog = {this.props.writeAcitivityLog}
                    />
                )
            }
            default: return null
        }
    }

    renderEditIcon = () =>{
        let editIconClass = "EditIcon"
        if (this.props.RTL){
            editIconClass = "EditIconRTL"
        }
        return(
            <img className={classes[editIconClass]} src={editIcon} alt=""
            onClick={this.props.editTrip}/>
        )
    }

    renderPlanEditors = () =>{
        return(
            <div className={classes.PlanEditors}>
                 {this.props.planEditors.map((user,index)=>(
                    <img key={index} className={classes.EditorImage} src = {user.photoUrl}/>
                ))}
                <img src={plusIcon} className={classes.ManageEditorsIcon} alt="" onClick={this.props.showEditors}/>
            </div>
        )
    }

    render(){
        return(<div className={classes.Body}>
             <div className={classes.PlanOverview}>
                <div className={classes.OverviewBox}>
                    {this.renderEditIcon()}
                    <div className={classes.InfoBox}>
                        {this.renderPlanName()}
                        {this.renderPlanDates()}  
                    </div>
                    {this.renderPlanImage()}
                    {this.renderPlanEditors()}
                </div>
                {this.renderPlanRemainingDays()}
                {this.renderPlanDescription()}
            </div>
            <div className={classes.WallSection}>
                {this.renderWallSelection()}
                {this.renderWall()}
            </div>
            
        </div>)
    }
}

export default TripPlanOverview;