import React, { Component } from "react";

import classes from "./Links.module.css";

import TextButtom from "../../UI/Buttons/TextButton";
import ModalTopSection from "../../UI/Misc/ModalTopSection";
import ConfirmModal from "../../UI/Modals/ConfirmationModal";

let deleteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01";

class Links extends Component {
  state = { LinkUrlInput: "", 
  linkNameInput: "", 
  invalidUrl: false ,
  confirmMode: false,
  linksList: [...this.props.links],
  changesMade: false};

  componentDidMount = () => {
    this.setState({
      linksList: [...this.props.links],
      linkUrlInput: ""
    });
  };


  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.links !== prevProps.links) {
      this.setState({
        linksList: [...this.props.links]
      });
    }
  }

  saveHandler = () => {
    let linksList = [...this.state.linksList];
    this.props.update(linksList);
    this.props.discard();
  };

 
  discardHandler=()=>{
    if (JSON.stringify(this.state.linksList)!==JSON.stringify(this.props.links)||this.state.changesMade) {
     this.setState({
      confirmMessage: this.props.systemText.extraModals.confirmationModal.message[this.props.userLanguage],
      primaryBtn:this.props.systemText.extraModals.confirmationModal.stay[this.props.userLanguage],
      secondaryBtn:this.props.systemText.extraModals.confirmationModal.leave[this.props.userLanguage],
         confirmMode: true,
         showBackdrop: true,
       });
    }
    else{
     this.props.discard();
    }
 }

 discardChangesHandler = () => {
  this.props.discard();

};

discardDiscardHandler = () => {
     this.setState({
       confirmMode: false,
       showBackdrop: false
     });
   };

  deleteLink = async index => {
    let linksList = this.state.linksList;
    linksList.splice(index, 1);
    await this.setState({ linksList: linksList,
      changesMade: true });
  };

  addLinkHandler = () => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i');
    
    if (
      /*       this.state.linkNameInput != "" && */
      !pattern.test(this.state.linkUrlInput)
    ) {
      this.setState({ invalidUrl: true,
         });
      return;
    } else {
      this.setState({ invalidUrl: false,
        });
    }

    let url = this.state.linkUrlInput;
    let name = this.state.linkNameInput;

    let link = { name: name, url: url };
    let linksList = this.state.linksList;
    linksList.unshift(link);
    this.setState({
      linksList: linksList,
      linkNameInput: "",
      linkUrlInput: "",
      changesMade: true
    });
  };

  addLinkNameHandler = event => {
    this.setState({ linkNameInput: event.target.value ,
    });
  };

  addLinkUrlHandler = event => {
    if (event.key === "Enter") {
      this.addLinkHandler();
    } else {
     
      this.setState({ linkUrlInput: event.target.value ,
       });
      let url = event.target.value;
      if (this.state.linkNameInput===""){
      let name = "";
      if (url.startsWith("https://www.")||url.startsWith("http://www.")) {
        let subUrl = url.substring(12);
        name = subUrl.substr(0, subUrl.indexOf("."));
      } 
      else if (url.startsWith("https://")||url.startsWith("https://"))
      {
        let subUrl = url.substring(8);
        name = subUrl.substr(0, subUrl.indexOf("."));
      }else {
        name = url.substr(
          0,
          url.indexOf(".")
        );
      }
      this.setState({ linkNameInput: name ,});}
    }
  };

  editLink = index =>{
    let linksList = this.state.linksList;
    let link = linksList[index];
    linksList.splice(index,1);
    this.setState({linkUrlInput: link.url, linkNameInput:link.name, linksList:linksList,});
  }
 

  renderTopSection = () => {
    let title = this.props.systemText.extraModals.manageLinks.title[this.props.userLanguage];
    let buttonName = this.props.systemText.extraModals.manageLinks.updateButton[this.props.userLanguage];
    let saveReady=this.state.changesMade&&!this.state.invalidUrl;
    return (
      <ModalTopSection
        title={title}
        discard={this.discardHandler}
        saveReady={saveReady}
        clicked={this.saveHandler}
        showButton={true}
        buttonText={buttonName}
        saveButton={true}
        modalView = {true}
        RTL = {this.props.RTL}
      />
    );
  };

  renderAddButton = () => {
    return <TextButtom clicked={this.addLinkHandler} 
    style="Style1"
    text={this.props.systemText.extraModals.manageLinks.addButton[this.props.userLanguage]} />;
  };

  renderInputSection = () => {
    return (
      <div className={classes.InputSection}>
        
        <div className={classes.LinkInputSection}>
        <div className={classes.LinkTitle}>
            {this.props.systemText.extraModals.manageLinks.urlTitle[this.props.userLanguage]}
          </div>
          <input
            className={classes.LinkUrlInput}
            type="url"
            onChange={this.addLinkUrlHandler}
            onKeyDown={this.addLinkUrlHandler}
            required
            value={this.state.linkUrlInput}
          />
         
        </div>
        {this.state.invalidUrl ? (
          <div className={classes.InvalidUrl}>
              {this.props.systemText.extraModals.manageLinks.invalidUrl[this.props.userLanguage]}
          </div>
        ) : null}
        <div className={classes.LinkInputSection}>
          <div className={classes.LinkTitle}>
            {this.props.systemText.extraModals.manageLinks.namePlaceholder[this.props.userLanguage]}
          </div>
          <input
            placeholder={""}
            className={classes.LinkNameInput}
            onChange={this.addLinkNameHandler}
            value={this.state.linkNameInput}
          />
        </div>
        <div className={classes.AddButton}>  {this.renderAddButton()}</div>
      
      </div>
    );
  };

  renderLinksSection = () => {
    let iconClass = "Icon"
    if (this.props.RTL){
      iconClass = "IconRTL"
    }
    return (
      <div className={classes.LinksSection}>
        {this.state.linksList !== undefined ? (
          <div>
            {this.state.linksList.map((link, index) => (
              <div className={classes.LinkObject} key={index} onClick={()=>{this.editLink(index)}}>
                <div className={classes.LinkName}>{link.name}</div>
                <div>
                  <img
                    src={deleteIcon}
                    className={classes[iconClass]}
                    alt=""
                    onClick={e => {
                      e.stopPropagation(); this.deleteLink(index);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  renderConfirmModal = () => {
    if (this.state.confirmMode) {
      return (
        <ConfirmModal
        header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
        primaryAction={this.discardDiscardHandler}
        secondaryAction={this.discardChangesHandler}
        message={this.state.confirmMessage}
        primaryBtn={this.state.primaryBtn}
        secondaryBtn={this.state.secondaryBtn}
        primaryStyle = {"PositiveButton"}
        secondaryStyle = {"DiscardButton"}
        RTL = {this.props.RTL}
        />
        
      );
    }
  };

  render() {
    if (this.state.linkUrlInput !== undefined) {
      return (
        <div className={classes.Modal}>
            {this.renderConfirmModal()}
          {this.renderTopSection()}
          <div className={classes.Body}>
            {this.renderInputSection()}
            {this.renderLinksSection()}
          </div>
        </div>
      );
    } else return null;
  }
}

export default Links;
