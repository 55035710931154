import React, { Component } from "react";

import classes from "./AdminBoard.module.css";

import Chart from "react-google-charts";
import Tab from "../../Components/UI/Tabs/Tab"
import PlacesList from "../Content/Places/PlacesList"
import PlansWall from "../Content/TripPlans/PlansWall"

import TransparentBackdrop from "../../Components/UI/Backdrop/TransparentBackdrop"
import Backdrop from "../../Components/UI/Backdrop/Backdrop"
import { Editor,EditorState , convertFromRaw, convertToRaw} from 'draft-js';

import { CSVLink, CSVDownload } from "react-csv";

export default class AdminBoard extends Component {
    state={
        usersData: [
            ["Date", "New users", "Active users"],
          ],
          usersOptions: {
            title: "Users Metrics",
            curveType: "function",
            format:"0",
            legend: { position: "bottom" }
          },
          placesData: [
            ["Date", "Total places", "Unique users"],
          ],
          placesOptions: {
            title: "Places Created",
            curveType: "function",
            legend: { position: "bottom" }
          },
          selectionOptions:  ["SelectedTabV1","RegularTabV1", "RegularTabV1","RegularTabV1","RegularTabV1"],
          selection: "Stats",

    }

    componentWillMount = () =>{
        this.initialize()
        
    }

    componentDidUpdate = prevProps =>{
      if (this.props.usersActivityLastUpdate!==prevProps.usersActivityLastUpdate){
        this.initialize()
      }
    }

    initialize = async () =>{
      let usersData = this.state.usersData;
      let placesData = this.state.placesData;
      let newUsersKeys = []
       if(this.props.usersActivity.newUsers!==undefined){
        newUsersKeys = Object.keys(this.props.usersActivity.newUsers).map(key=>{return key.toString()});
       } 
      let activeUsersKeys = []
      if(this.props.usersActivity.activeUsers!==undefined){
        activeUsersKeys = Object.keys(this.props.usersActivity.activeUsers).map(key=>{return key.toString()});
       } 
      
      var today = new Date();
      var priorDate = new Date();
      priorDate.setDate(today.getDate()-6);
      for (let i=0 ; i<7 ; i++){
          let dataRow = [];
          let date = priorDate.getFullYear()+ '-'+ ('0' + (priorDate.getMonth()+1)).slice(-2) + '-'
             +  ('0' + priorDate.getDate()).slice(-2) ;
          dataRow.push(date)
          const newIndex = newUsersKeys.findIndex(key => {
              return key === date.toString();
            });
           if (newIndex!==-1){
             if (this.props.usersActivity.newUsers[newUsersKeys[newIndex]]!==undefined){
              dataRow.push(this.props.usersActivity.newUsers[newUsersKeys[newIndex]].count)
             }
              
          }
          else{
              dataRow.push(0)
          } 
          const activeIndex = activeUsersKeys.findIndex(key => {
              return key === date.toString();
            });
           if (activeIndex!==-1){
            if (this.props.usersActivity.activeUsers[activeUsersKeys[activeIndex]]!==undefined){
               dataRow.push(this.props.usersActivity.activeUsers[activeUsersKeys[activeIndex]].count)
            }
          }
          else{
              dataRow.push(0)
          } 
          priorDate.setDate(priorDate.getDate()+1);
          usersData.push(dataRow)
      }

    }

    archiveRequest = (request) =>{

    }

    renderPotentialPlaces = () =>{
        let places;
        if (this.state.selection==="PotentialPlaces"){
            places=this.props.places
        }
        else if (this.state.selection==="EditSuggestions"){
            places=this.props.editSuggestions
        }
         return(
         
            <div className={classes.PlacesWall}>   
               <PlacesList
                 selectedContentType={"Places"}
                 selectedContentWall = {this.props.selectedContentWall}
                 placesList={places}
                 favoritePlacesIDs={[]}
                 user={this.props.user}
                 adminMode={true}
                 loggedUser = {true}
                 categoriesTranslator = {this.props.categoriesTranslator}
                 locationsTranslator = {this.props.locationsTranslator}

                 systemText={this.props.systemText}
                 userLanguage={this.props.userLanguage}
                 RTL = {this.props.RTL}

                 deletePlaceHandler = {(index)=>{this.props.deletePlaceHandler(places[index])}}
                 viewHandler = {(index)=>{if (this.state.selection==="PotentialPlaces"){this.props.viewPlaceHandler(this.props.places[index])}
                    else if(this.state.selection==="EditSuggestions"){this.props.viewPlaceEditSuggestion(this.props.editSuggestions[index])}}}
                 /> 
             </div>
    ) 
    }

    renderPotentialPlans = () =>{
      return (
        <div  className={classes.PlansWall}>
        <PlansWall
            plansList={this.props.plans}
            user={this.props.user}
            viewHandler={this.props.viewPlanHandler}
            adminMode={true}

            locationsTranslator = {this.props.locationsTranslator}

            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
        />
        </div>
    );
    }

    renderStats = () =>{
       
            return(<div className={classes.Stats}>
                 <Chart
                    chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={this.state.usersData}
                    options={this.state.usersOptions}
                    format={"#"} 
                    />
                    {this.renderActivitiesLogExport()}
            </div>)
    }

    renderActivitiesLogExport = () =>{
      if (this.props.activitiesLog!==undefined){
        return(
          <div className={classes.ActivitiesLogExport}>
           
           <div className={classes.ActivitiesLogExportTitle}>Activities Log</div>
            {this.props.activitiesLog.map((date,index)=>(
              <div className={classes.DateRow} key = {index} >
                <div className={classes.LogDate} onClick={()=>{this.props.downloadActivityLogHandler(date)}}>
                  {date}
                </div>
               {this.renderDownloadLogButton(date)}
               </div>
            ))}
          </div>
        )
      }
    }

    renderDownloadLogButton = (date) =>{
      if (this.props.activitiesLogData!==undefined){
        if (this.props.activitiesLogData[date]!==undefined){
          return(
            <CSVLink data={this.props.activitiesLogData[date]}>Download me</CSVLink>
          )
        }
      }
    }

    renderDownloadRequestsButton = (date) =>{
      if (this.props.usersRequestsData!==undefined){
        if (this.props.usersRequestsData[date]!==undefined){
          return(
            <CSVLink data={this.props.usersRequestsData[date]}>Download me</CSVLink>
          )
        }
      }
    }

    renderRequests = () =>{
      if (this.props.usersRequests!==undefined){
        return(
          <div className={classes.ActivitiesLogExport}>
           
           <div className={classes.ActivitiesLogExportTitle}>Activities Log</div>
            {this.props.usersRequests.map((date,index)=>(
              <div className={classes.DateRow} key = {index} >
                <div className={classes.LogDate} onClick={()=>{this.props.downloadRequestsHandler(date)}}>
                  {date}
                </div>
                {this.renderDownloadRequestsButton(date)} 
               </div>
            ))}
          </div>
        )
      }
    }
/* 
    renderRequests = () =>{
      let allRequests = [];
      if (this.props.usersRequests.requestOwnership!==undefined){
        allRequests = allRequests.concat(Object.values(this.props.usersRequests.requestOwnership))
      }
      if (this.props.usersRequests.imageCopyrights!==undefined){
        allRequests = allRequests.concat(Object.values(this.props.usersRequests.imageCopyrights))
      }
      if (this.props.usersRequests.other!==undefined){
        allRequests = allRequests.concat(Object.values(this.props.usersRequests.other))
      }
      allRequests = allRequests.sort((a, b) => {
        var keyA = a.createdDate;
        var keyB = b.createdDate;
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      return(
        <div className={classes.Requests}>
          <div className={classes.Title}>
            {"Requests"}
          </div>
          {allRequests.map((request,index)=>{
            let requestMessage = ""
            if (request.requestTopic=="requestOwnership"){
              requestMessage = request.userID+" requested ownership for "+request.placeID
            }
            if (request.requestTopic=="imageCopyrights"){
              requestMessage = request.userID+" issued copyright image violation within place "+request.placeID
            }
            if (request.requestTopic=="other"){
              requestMessage = request.userID+" contacted about "+request.placeID
            }
            let requestContent=EditorState.createEmpty();
            if (request.requestContent!==undefined){
                try {
                    JSON.parse(request.requestContent);
                    requestContent= EditorState.createWithContent(convertFromRaw(JSON.parse(request.requestContent)))
                } catch (e) {
                  requestContent=EditorState.createEmpty()
                }
            }
            let requestClass = "Request"
            if (index%2 == 0){
              requestClass = "RequestGrey"
            }
            return(
              <div className={classes[requestClass]} key={index}>
                <div className={classes.RequestTopSection}>
                  <div className={classes.RequestMessage}> {requestMessage}</div>
                   
                    <div className={classes.ArchiveButton} onClick={()=>{this.archiveRequest(request)}}>{"archive"}</div>
                  </div>
                {request.requestContent!==undefined?(  
                <div className={classes.RequestContent}>
                   <Editor
                    className={classes.DescriptionInputField}
                    editorState={requestContent}
                    />
                </div>):null}
              </div>
            )
          })}
        </div>
      )
    } */

    renderSelection = () =>{
        return(
            <div className={classes.Selection}>
                 <Tab
                tabType={this.state.selectionOptions[0]}
                text={"Stats"}
                clicked={() => {
                  this.setState({
                    selection: "Stats",
                    selectionOptions: ["SelectedTabV1","RegularTabV1", "RegularTabV1","RegularTabV1","RegularTabV1"],
                  });
                }}
              />
                <Tab
                tabType={this.state.selectionOptions[1]}
                text={"Potential Places"}
                clicked={() => {
                  this.setState({
                    selection: "PotentialPlaces",
                    selectionOptions: ["RegularTabV1", "SelectedTabV1","RegularTabV1","RegularTabV1","RegularTabV1"],
                  });
                }}
              />  
               <Tab
                tabType={this.state.selectionOptions[2]}
                text={"Edit suggestions"}
                clicked={() => {
                  this.setState({
                    selection: "EditSuggestions",
                    selectionOptions: ["RegularTabV1","RegularTabV1", "SelectedTabV1","RegularTabV1","RegularTabV1"],
                  });
                }}
              />  
                <Tab
                tabType={this.state.selectionOptions[3]}
                text={"Requests"}
                clicked={() => {
                  this.setState({
                    selection: "Requests",
                    selectionOptions: ["RegularTabV1","RegularTabV1","RegularTabV1", "SelectedTabV1","RegularTabV1"],
                  });
                }}
              />  
                <Tab
                tabType={this.state.selectionOptions[4]}
                text={"Plans"}
                clicked={() => {
                  this.setState({
                    selection: "Plans",
                    selectionOptions: ["RegularTabV1","RegularTabV1","RegularTabV1","RegularTabV1", "SelectedTabV1"],
                  });
                }}
              />  
            </div>
        )
    }
        
    renderContent = () =>{
        switch (this.state.selection){
            case "Stats":{
                return(
                    <div>
                        {this.renderStats()}
                    </div>
                )
                break;
            }
            case "PotentialPlaces":{
                return(
                    <div>
                        {this.renderPotentialPlaces()}
                    </div>
                )
                break;
            }
            case "EditSuggestions":{
                return(
                    <div>
                        {this.renderPotentialPlaces()}
                    </div>
                )
                break;
            }
            case "Requests":{
              return(
                  <div>
                      {this.renderRequests()}
                  </div>
              )
              break;
          }
          case "Plans":{
            return(
                <div>
                    {this.renderPotentialPlans()}
                </div>
            )
            break;
        }
            default: return null;
        }
        
    }

render(){
    return(<div className={classes.Body}> 
    <TransparentBackdrop show={this.state.showTransparentBackdrop} clicked={this.discard}/>
    <Backdrop show={this.state.showBackdrop}/>
    {this.renderSelection()}
    {this.renderContent()}
    

    
    </div>)
}
}