import React, { Component } from "react";

import classes from "./Settings.module.css";
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'


import imageCompression from "browser-image-compression";

import ModalTopSection from "../../UI/Misc/ModalTopSection"
import OptionSelection from "../../Complex/OptionSelection/OptionSelection";

import Tag from "../../UI/Tag/Tag"
import Tab from "../../UI/Tabs/Tab"

import { isMobile, isMobileOnly } from "react-device-detect";

let sightseeingIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSightSeeingIcon.png?alt=media&token=3b7f3202-493e-4f2e-981f-47944e78c5a4";
let foodIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFoodIcon.png?alt=media&token=f9f81121-4c3e-4290-8b3e-94fee05d149a";
let natureIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FNatureIcon.png?alt=media&token=69ef82b9-4af1-44e2-b713-a240fba4022e";
let sportIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSportIcon.png?alt=media&token=3d936de1-0f2b-40de-9313-69b90977602d";
let accomodationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIcon.png?alt=media&token=3f3fbed6-2d65-4896-a2d0-6036f7e1ac5b";
let activitiesIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FActivityIcon.png?alt=media&token=e46ab679-3b01-4172-9efc-1f528c9f76a7";

let cropIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCropIcon.png?alt=media&token=f5b7e5bc-a302-4294-87c5-81cbf2a5009c"
let editIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIcon.png?alt=media&token=c964d68a-44ba-45bf-afd7-b4b8eaeb6fba"

let imagePlaceholder = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FProfilePlaceholder.png?alt=media&token=74c2310e-bb35-42b2-8cab-b91ad0d773b5"


class Settings extends Component {
  state = {
    oldLanguage:this.props.userLanguage,
    selectedLanguage:this.props.userLanguage,
    displayName:this.props.displayName,
    residenceCountry: this.props.userResidenceCountry,
    changesMade:false,
    profileImage: { name: "", url: "" },
    uploading: false,
    saveReady: false,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 3 / 3,
    croppedAreaPixels:null,
    selectedSettings:"Settings",
    selectionOptions: ["SelectedTabV1", "RegularTabV1" ],
    newImageCropped:false};

  componentWillMount = () =>{
    if (isMobile){
      this.setState({
        selectedSettings:"Profile",
        selectionOptions: ["SelectedMobileWide", "RegularMobileWide", "RegularMobileWide"],
      })
    } 
}

  componentDidMount = () => {
    let profileImage;
    if (this.props.userSettings.profileImage.largeImage!==undefined){
      profileImage={name: this.props.userSettings.profileImage.largeImage.name, url: this.props.userSettings.profileImage.largeImage.url }
    }
    else{
      profileImage = { name: "", url: this.props.photoUrl }
    }
    this.setState({
      profileImage:profileImage,
      userSettings: this.props.userSettings
      
    });
    let userInterestCategories = [];
    if (this.props.userSettings.userInterestCategories!==undefined){
      userInterestCategories=this.props.userSettings.userInterestCategories
    }
    let userDislikedCategories = [];
    if (this.props.userSettings.userDislikedCategories!==undefined){
      userDislikedCategories=this.props.userSettings.userDislikedCategories
    }
    let potentialDislikeCategories =[]
    for (let i=0; i< this.props.allSystemCategories.length ; i++){
      if (this.props.allSystemCategories[i].isInterest&&!userDislikedCategories.includes(this.props.allSystemCategories[i].id)){
        potentialDislikeCategories.push(this.props.allSystemCategories[i])
      }
    }

    this.setState({userInterestCategories:userInterestCategories, 
      userDislikedCategories:userDislikedCategories,
      potentialDislikeCategories:potentialDislikeCategories})
  };

  addDislikedCateogry = category =>{
    let userDislikedCategories = this.state.userDislikedCategories
    let potentialDislikeCategories = this.state.potentialDislikeCategories
    let userInterestCategories = this.state.userInterestCategories
    userDislikedCategories.push(category.id)
    const index = potentialDislikeCategories.findIndex(cat => {
      return cat.id === category.id;
    });
    potentialDislikeCategories.splice(index,1)

    const interestsIndex = userInterestCategories.findIndex(id => {
      return id === category.id;
    });
    if (interestsIndex!==-1){
      userInterestCategories.splice(interestsIndex,1)
    }
    this.setState({userDislikedCategories:userDislikedCategories,
      potentialDislikeCategories:potentialDislikeCategories,
      userInterestCategories:userInterestCategories, 
      changesMade:true})
  }

  removeDislikeCategory = catID =>{
    let userDislikedCategories = this.state.userDislikedCategories
    let potentialDislikeCategories = this.state.potentialDislikeCategories
    potentialDislikeCategories.push(this.props.categoriesTranslator[catID])
    const index = userDislikedCategories.findIndex(id => {
      return id === catID;
    });
    userDislikedCategories.splice(index,1)
    this.setState({userDislikedCategories:userDislikedCategories,
      potentialDislikeCategories:potentialDislikeCategories, 
      changesMade:true})
  }
  

  onCropChange = crop => {
    this.setState({ crop })
  }

  updateImageAfterCrop=(image,pixelCrop)=>{
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
   
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
   
    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');
   
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        this.uploadImages(file)
        resolve(file);
      }, 'image/jpeg');
    });
  }
 
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({croppedAreaPixels:croppedAreaPixels});

  }

  finalizeCrop = async () =>{
     try {
      const croppedImage = await getCroppedImg(
        this.state.uploadedImage.url,
        this.state.croppedAreaPixels,
        0
      )
      this.setState({ uploading: true });
   
      let url = URL.createObjectURL(croppedImage)
      this.compressUploadedImage(croppedImage);
       let profileImage=this.state.profileImage;
       profileImage.url = url;
       this.setState({
        profileImage: profileImage,
        newImageCropped:true
      });
    } catch (e) {
      console.error(e)
    } 
 } 
 
  onZoomChange = zoom => {
    this.setState({ zoom })
  }
 

  uploadImages = async file => {
    //This is checking that there wil lbe a total of max 5 images, but if the new uploaded images are duplicates it will not check it
    if(!file.type.startsWith("image/")) {
      alert("Unsupported file format")
      return}
    let profileImage;
    this.setState({ uploading: true,newImageCropped:false });
   
    profileImage = {
      name: file.name,
      url: URL.createObjectURL(file)
    };
    let uploadedImage = {
      name: file.name,
      url: URL.createObjectURL(file)
    };

    this.setState({
      profileImage: profileImage,
      uploadedImage:uploadedImage,
      cropEnabled:true,
      imageWasUploaded:true
    });

    this.compressUploadedImage(file);
  };

  updateUserLanguage = (language) =>{
    let selectedLanguage = language.id;
    if (this.props.userLanguage!==selectedLanguage){
      //new language is defined
      let userSettings=this.state.userSettings;
      userSettings.userLanguage=selectedLanguage;
      this.setState({userSettings:userSettings,selectedLanguage:selectedLanguage, changesMade:true,languageUpdated:true});
      //update language now!
      this.props.languageHandler(selectedLanguage)
      this.props.writeAcitivityLog({
        activityKey:"SETTINGS_LANGUAGE_UPDATE",
        userScreen:"SETTINGS"
      })
    }
    else{
      this.setState({languageUpdated:false})
    }
  }

  updateUserResidence = (country) =>{
    let selectedCountry = country.English
    if (this.props.residenceCountry!==selectedCountry){
      this.setState({residenceCountry:selectedCountry, changesMade:true});
    }
    this.props.writeAcitivityLog({
      activityKey:"SETTINGS_RESIDENCE_COUNTRY_UPDATE",
      userScreen:"SETTINGS"
    })
  }

  //Compressing images
  compressUploadedImage = async image => {
    let imageFile = image;
    let largeOptions = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 768,
      useWebWorker: true
    };
    let smallOptions = {
      maxSizeMB: 0.04,
      maxWidthOrHeight: 320,
      useWebWorker: true
    }
    try {
      const compressedFileLarge = await imageCompression(imageFile, largeOptions);
      const compressedFileSmall = await imageCompression(imageFile, smallOptions);
      await this.updateCompressedImages(compressedFileLarge,compressedFileSmall); // write your own logic
    } catch (error) {
    }
  };

  updateCompressedImages = async (largeImage,smallImage) => {
   let imageFile={largeImage:largeImage,smallImage:smallImage}
    imageFile.largeImage.name = "profileImage";
    imageFile.smallImage.name = "profileImage";
    await this.setState({ imageFile: imageFile, uploading: false, saveReady: true, imageChanged:true });
    this.props.writeAcitivityLog({
      activityKey:"SETTINGS_PROFILEIMAGE_UPDATE",
      userScreen:"SETTINGS"
    })
  };

  categoryInterestClicked = tagID =>{
    let userInterestCategories = this.state.userInterestCategories
    const index = userInterestCategories.findIndex(id => {
      return id === tagID;
    });
    if (index===-1){
      userInterestCategories.push(tagID)
      this.props.writeAcitivityLog({
        activityKey:"SETTINGS_ADD_CATEGORY",
        userScreen:"SETTINGS",
        tagID:tagID
      })
    }
    else{
      userInterestCategories.splice(index,1)
      this.props.writeAcitivityLog({
        activityKey:"SETTINGS_REMOVE_CATEGORY",
        userScreen:"SETTINGS",
        tagID:tagID
      })
    }
    this.setState({userInterestCategories:userInterestCategories,changesMade:true,interestsUpdated:true})
  }

  discardHandler = () => {
    this.props.discard();
    if (this.state.languageUpdated){
       this.props.languageHandler(this.state.oldLanguage)
       this.props.writeAcitivityLog({
        activityKey:"SETTINGS_DISCARD",
        userScreen:"SETTINGS"
      })
    }
    this.setState({
      profileImage: {
        name: "",
        url: this.props.photoUrl,
        userSettings: this.props.userSettings
      }
    });
  };

  saveHandler = () => {
    let userSettings = this.state.userSettings;
    let personalInfoChanged = false;
    if (this.state.displayName!==this.props.displayName||this.state.imageChanged){
      personalInfoChanged = true
    }
    userSettings.personalInfoChanged = personalInfoChanged;
    userSettings.displayName=this.state.displayName
    userSettings.residenceCountry = this.state.residenceCountry
    userSettings.userInterestCategories = this.state.userInterestCategories
    userSettings.userDislikedCategories = this.state.userDislikedCategories
    if (this.state.imageChanged) {
      userSettings.profileImage.imageFile = this.state.imageFile
    }
    //console.log(userSettings)
    this.props.update(userSettings);
    this.props.discard();

    this.props.writeAcitivityLog({
      activityKey:"SETTINGS_UPDATE",
      userScreen:"SETTINGS"
    })
  };


  renderTopSection = () => {
    let title = this.props.systemText.navBar.settings.title[this.props.userLanguage];
    let saveReady = this.state.changesMade&&!this.state.uploading;
    let buttonText = this.props.systemText.misc.buttons.updateButton[this.props.userLanguage];
    return (
      <ModalTopSection
            saveReady = {saveReady}
            title={title}
            discard={this.discardHandler}
            buttonText={buttonText}
            clicked={this.saveHandler}
            showButton={true}
            RTL = {this.props.RTL}
          />
    );
  };

  renderImagesSection = () => {
      if (this.state.cropEnabled){
        return (
          <div className={classes.ProfileImageSection}>
          <div className={classes.CropImage}>
              <Cropper
              image={this.state.uploadedImage.url}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
            /> 
            </div>
            <div className={classes.CropButtons}>
              <div className={classes.DoneButton}  onClick={()=>{this.setState({cropEnabled:false,changesMade:true});this.finalizeCrop()}}>
                {this.props.systemText.navBar.settings.finishCrop[this.props.userLanguage]}
              </div>
              <div className={classes.DiscardButton}  
              onClick={()=>{
                if (this.state.newImageCropped) {
                  this.setState({cropEnabled:false})
                }
                else{
                  this.setState({cropEnabled:false,profileImage: { name: "", url: this.props.photoUrl },imageWasUploaded:false})
                }
                }}>
                {this.props.systemText.navBar.settings.discardCrop[this.props.userLanguage]}
              </div>
            </div>
           
        </div>
        )
      }
      else{
       let imageUrl = this.state.profileImage.url
       if (this.state.profileImage.url===null){
        imageUrl = imagePlaceholder
       }
       return (
        <div className={classes.ProfileImageSection}>
          <div className={classes.ImageSection}>
       {/*   {this.state.imageWasUploaded?(
            <img src={cropIcon} alt="" className={classes.CropIcon} onClick={()=>{this.setState({cropEnabled:true})}}/>
           ):null} 
           */}
            {this.state.imageWasUploaded?(
           <img src={imageUrl} alt="" className={[classes.Image,classes.Clickable].join(" ")} onClick={()=>{this.setState({cropEnabled:true})}}/> 
           ):(
            <img src={imageUrl} alt="" className={classes.Image}/> 
           )} 
          
          </div>
          <div className={classes.UploadSection}>
            <div className={classes.ImageUploadWrapper}>
            <input
                   type="file"
                   onChange={e=>{this.uploadImages(e.target.files[0])}}
                   multiple={false}
                   accept="image/*"
                  className={classes.FileInput}
                />
              <div className={classes.UploadButton}>
              <img
              src={editIcon}
              alt=""
              className={classes.ImageIcon}
              /> 
              </div>
            </div>
          </div>
    </div>
      )
      }
     
  };

  renderLanguageSection = () =>{
   let currentSelection = this.props.systemText.languages.longLanguage[this.props.userLanguage]
   let optionsList = Object.values(this.props.systemLanguages)
    return(
      <div className={classes.LanguageSection}>
        <div className={classes.LanguageMessage}>
          {this.props.systemText.navBar.settings.languageMessage[this.props.userLanguage]}
        </div>
        <div className={classes.LanguageOptions}>
        <OptionSelection
           currentSelection={currentSelection}
           optionsList={optionsList}
           updateSelectedOption={this.updateUserLanguage}
           required={false}
           onlyFromList = {true}
           showCurrentSelection = {true}
           systemText={this.props.systemText}
           userLanguage={this.props.userLanguage}
           RTL = {this.props.RTL}
           inputLanguage = {this.props.inputLanguage}
           detectInputLanguage = {this.props.detectInputLanguage}
           />
        </div>
       
      </div>
    )
  }
 
 
  renderBody = () =>{
    if (isMobileOnly){
       return(
        <div className={classes.Body}>
          {this.renderMobileSettingsSelection()}
          <div className={classes.SettingsContent}>
      
           
            {this.renderSelectedSettings()}
          </div>
        </div>
      ) 
    }
    else{
      return(
        <div className={classes.Body}>
          {this.renderUserProfile()}
          <div className={classes.SettingsContent}>
            {this.renderSettingsSelection()}
            {this.renderSelectedSettings()} 
          </div> 
        </div>
      )
    }
  } 

  renderUserProfile = () =>{
    let imageUrl = this.state.profileImage.url
       if (this.state.profileImage.url===null){
        imageUrl = imagePlaceholder
       }
    return(
      <div className={classes.UserProfile}>
          <img src={imageUrl} alt="" className={classes.ProfileImage} /> 
          <div className={classes.UserName}>
            {this.props.displayName}
          </div>
          <div className={classes.Country}>
            {this.props.systemText.countries[this.state.residenceCountry][this.props.userLanguage]}
          </div>
      </div>
    )
  } 

 /*  renderProfileStats = () =>{
    let statClass = "Stat"
    if (isMobile){
      statClass = "MobileStat"
    }
    let registerDate = this.props.user.registerDate.split(',')[0]
    return(
      <div className={classes.ProfileStats}>
        <div className={classes.DatesStats}>
          <div className={classes[statClass]}>
              <div className={classes.StatTitle}>
                {this.props.systemLanguage.navBar.settings.profileStats.joined[this.props.userLanguage]}
              </div>
              <div className={classes.StatValue}>
                {registerDate}
              </div>
            </div>
            </div>
      </div>
    )
  }  */

   renderSettingsSelection = () =>{
    return(
      <div className={classes.SettingsSelection}>
         <Tab
            tabType={this.state.selectionOptions[0]}
            text={this.props.systemText.navBar.settings.settingsTitle[this.props.userLanguage]}
            clicked={() => {
              this.setState({
                selectedSettings: "Settings",
                selectionOptions: ["SelectedTabV1", "RegularTabV1"],
              });
            }}
          />
            <Tab
            tabType={this.state.selectionOptions[1]}
            text={this.props.systemText.navBar.settings.interestsTitle[this.props.userLanguage]}
            clicked={() => {
              this.setState({
                selectedSettings: "Interests",
                selectionOptions: ["RegularTabV1", "SelectedTabV1"],
              });
            }}
          />  
      </div>
    )
  } 

   renderMobileSettingsSelection = () =>{
    return(
      <div className={classes.SettingsSelection}>
         <Tab
                tabType={this.state.selectionOptions[0]}
                text={this.props.systemText.navBar.settings.profileTitle[this.props.userLanguage]}
                clicked={() => {
                  this.setState({
                    selectedSettings: "Profile",
                    selectionOptions: ["SelectedMobileWide", "RegularMobileWide","RegularMobileWide"],
                  });
                  this.props.writeAcitivityLog({
                    activityKey:"SETTINGS_PROFILE_BUTTON",
                    userScreen:"SETTINGS"
                  })
                }}
              />
               <div className={classes.MobileSeperator}/>
         <Tab
                tabType={this.state.selectionOptions[1]}
                text={this.props.systemText.navBar.settings.settingsTitle[this.props.userLanguage]}
                clicked={() => {
                  this.setState({
                    selectedSettings: "Settings",
                    selectionOptions: ["RegularMobileWide","SelectedMobileWide", "RegularMobileWide"],
                  });
                  this.props.writeAcitivityLog({
                    activityKey:"SETTINGS_SETTINGS_BUTTON",
                    userScreen:"SETTINGS"
                  })
                }}
              />
               <div className={classes.MobileSeperator}/>
                <Tab
                tabType={this.state.selectionOptions[2]}
                text={this.props.systemText.navBar.settings.interestsTitle[this.props.userLanguage]}
                clicked={() => {
                  this.setState({
                    selectedSettings: "Interests",
                    selectionOptions: ["RegularMobileWide", "RegularMobileWide","SelectedMobileWide"],
                  });
                  this.props.writeAcitivityLog({
                    activityKey:"SETTINGS_INTERESTS_BUTTON",
                    userScreen:"SETTINGS"
                  })
                }}
              />  
      </div>
    )
   
  } 

  renderUserData = () =>{
    let currentSelection = ""
    if (this.props.systemText.countries[this.state.residenceCountry]!==undefined){
      currentSelection = this.props.systemText.countries[this.state.residenceCountry][this.props.userLanguage]
    }
    else if (this.props.systemText.countries[this.props.userCountry]!==undefined){
      currentSelection = this.props.systemText.countries[this.props.userCountry][this.props.userLanguage]
    }
    let optionsList = Object.values(this.props.systemText.countries)

    return(
      <div className={classes.UserDataSection}>
         <div className={classes.Data}>
          <div className={classes.DataTitle}>
          {this.props.systemText.navBar.settings.userData.displayName[this.props.userLanguage]}
          </div>
         
          <input className={classes.DataInput}
              value ={this.state.displayName}
              onChange={(e)=>{this.setState({displayName:e.target.value,changesMade:true})}}
          />
        </div>
        <div className={classes.Data}>
        <div className={classes.DataTitle}>
          {this.props.systemText.navBar.settings.userData.residenceCountry[this.props.userLanguage]}
          </div>
          <div className={classes.LanguageOptions}>
         <OptionSelection
            currentSelection={currentSelection}
            optionsList={optionsList}
            updateSelectedOption={this.updateUserResidence}
            required={false}
            onlyFromList = {true}
            showCurrentSelection = {true}
            systemText={this.props.systemText}
            userLanguage={this.props.userLanguage}
            RTL = {this.props.RTL}
            inputLanguage = {this.props.inputLanguage}
            detectInputLanguage = {this.props.detectInputLanguage}
            />
         </div>
         </div>
      </div>
    )
  }

  renderSelectedSettings = () =>{
    switch (this.state.selectedSettings){
      case "Profile":{
        return(
          <div className={classes.SelectedSettingsProfile}>
            {this.renderUserProfile()}
          </div>
        )
        break
      }
      case "Settings":{
        return(
          <div className={classes.SelectedSettings}>
                {this.renderImagesSection()}
                <div className={classes.InfoSection}>
                  {this.renderUserData()} 
                  {this.renderLanguageSection()}
                </div>
              
          </div>
        )
        break;
      }
      case "Interests":{
        return(
          <div className={classes.InterestsSettings}>
              {this.renderInterests()}
          </div>
        )
        break;
      }
      default: return null;
    }
    
  }

  renderInterests = () =>{
    let categoryIconClass = "CategoryIcon"
    if (this.props.RTL){
      categoryIconClass = "CategoryIconRTL"
    }
   
    return(
      <div className={classes.Interests}>
        <div className={classes.InterestsHeader}>
        {this.props.systemText.navBar.settings.interestsHeader[this.props.userLanguage]}
        </div>
        <div className={classes.InterestsSection}>
          <div className={classes.InterestsSectionHeader}>
            <img src={sightseeingIcon} alt="" className={classes[categoryIconClass]}/>
            {this.props.systemText.misc.mainCategories.cultural[this.props.userLanguage]}
          </div>
          <div className={classes.CategoriesTags}>
          {Object.values(this.props.systemCategories[0]).filter(category=>category.isInterest===true).map((category, index) => {
                  let style = "CategoryFilter"
                  if (this.state.userInterestCategories.includes(category.id)){
                    style = "CategoryFilterSelected"
                  }
                  return(
                    <Tag 
                    key={index}
                    clickable={true}
                    style={style}
                    clicked={()=>{this.categoryInterestClicked(category.id)}}
                    text={category[this.props.userLanguage]}
                    RTL={this.props.RTL}/>
                   )
          })}
          </div>
        </div>
        <div className={classes.InterestsSection}>
          <div className={classes.InterestsSectionHeader}>
            <img src={natureIcon} alt="" className={classes[categoryIconClass]}/>
            {this.props.systemText.misc.mainCategories.nature[this.props.userLanguage]}
          </div>
          <div className={classes.CategoriesTags}>
          {Object.values(this.props.systemCategories[1]).filter(category=>category.isInterest===true).map((category, index) => {
                  let style = "CategoryFilter"
                  if (this.state.userInterestCategories.includes(category.id)){
                    style = "CategoryFilterSelected"
                  }
                  return(
                    <Tag 
                    key={index}
                    clickable={true}
                    style={style}
                    clicked={()=>{this.categoryInterestClicked(category.id)}}
                    text={category[this.props.userLanguage]}
                    RTL={this.props.RTL}/>
                   )
          })}
          </div>
        </div>
        <div className={classes.InterestsSection}>
          <div className={classes.InterestsSectionHeader}>
            <img src={activitiesIcon} alt="" className={classes[categoryIconClass]}/>
            {this.props.systemText.misc.mainCategories.activities[this.props.userLanguage]}
          </div>
          <div className={classes.CategoriesTags}>
          {Object.values(this.props.systemCategories[2]).filter(category=>category.isInterest===true).map((category, index) => {
                  let style = "CategoryFilter"
                  if (this.state.userInterestCategories.includes(category.id)){
                    style = "CategoryFilterSelected"
                  }
                  return(
                    <Tag 
                    key={index}
                    clickable={true}
                    style={style}
                    clicked={()=>{this.categoryInterestClicked(category.id)}}
                    text={category[this.props.userLanguage]}
                    RTL={this.props.RTL}/>
                   )
          })}
          </div>
        </div>
        <div className={classes.InterestsSection}>
          <div className={classes.InterestsSectionHeader}>
            <img src={sportIcon} alt="" className={classes[categoryIconClass]}/>
            {this.props.systemText.misc.mainCategories.sport[this.props.userLanguage]}
          </div>
          <div className={classes.CategoriesTags}>
          {Object.values(this.props.systemCategories[3]).filter(category=>category.isInterest===true).map((category, index) => {
                  let style = "CategoryFilter"
                  if (this.state.userInterestCategories.includes(category.id)){
                    style = "CategoryFilterSelected"
                  }
                  return(
                    <Tag 
                    key={index}
                    clickable={true}
                    style={style}
                    clicked={()=>{this.categoryInterestClicked(category.id)}}
                    text={category[this.props.userLanguage]}
                    RTL={this.props.RTL}/>
                   )
          })}
          </div>
        </div>
        <div className={classes.InterestsSection}>
          <div className={classes.InterestsSectionHeader}>
            <img src={foodIcon} alt="" className={classes[categoryIconClass]}/>
            {this.props.systemText.misc.mainCategories.food[this.props.userLanguage]}
          </div>
          <div className={classes.CategoriesTags}>
          {Object.values(this.props.systemCategories[4]).filter(category=>category.isInterest===true).map((category, index) => {
                  let style = "CategoryFilter"
                  if (this.state.userInterestCategories.includes(category.id)){
                    style = "CategoryFilterSelected"
                  }
                 return(
                  <Tag 
                  key={index}
                  clickable={true}
                  style={style}
                  clicked={()=>{this.categoryInterestClicked(category.id)}}
                  text={category[this.props.userLanguage]}
                  RTL={this.props.RTL}/>
                 )
          })}
          </div>
        </div>
        <div className={[classes.InterestsSection,classes.DislikesSection].join(" ")}>
          <div className={classes.InterestsSectionHeader}>
            {this.props.systemText.misc.mainCategories.dislikes[this.props.userLanguage]}
          </div>
          <OptionSelection
           currentSelection={""}
           optionsList={this.state.potentialDislikeCategories}
           updateSelectedOption={this.addDislikedCateogry}
           required={false}
           onlyFromList = {true}
           showCurrentSelection = {false}
           systemText={this.props.systemText}
           userLanguage={this.props.userLanguage}
           RTL = {this.props.RTL}
           inputLanguage = {this.props.inputLanguage}
           detectInputLanguage = {this.props.detectInputLanguage}
           />
          <div className={classes.CategoriesTags}>
            {this.state.userDislikedCategories.map((category,index)=>{
              if (this.props.categoriesTranslator[category]!==undefined){
                return(
                  <Tag 
                  key={index}
                  removable={true}
                  style={"DislikeCategory"}
                  clicked={()=>{this.removeDislikeCategory(category)}}
                  text={this.props.categoriesTranslator[category][this.props.userLanguage]}
                  RTL={this.props.RTL}/>
                 
                )
              }
            })}
          </div>
        </div>
       
      </div>
    )
  }
 
  renderWelcomeMessage = () =>{
    if (this.props.welcomeUser&&this.props.showWelcomeMessage){ 
      return(
        <div className={classes.WelcomeMessage}>
            <div className={classes.MessageTitle}>
              {this.props.systemText.navBar.settings.welcomeMessage.title[this.props.userLanguage]}
            </div>
            <div className={classes.MessageBody}>
            {this.props.systemText.navBar.settings.welcomeMessage.message[this.props.userLanguage]}
            </div>
            <div className={classes.MessageButton} onClick={this.props.closeWelcomeMessage}>
            {this.props.systemText.navBar.settings.welcomeMessage.closeButton[this.props.userLanguage]}
            </div>
        </div>
      )
    } 
  }

  render() {
    let RTLClass = ""
    if (this.props.RTL){
      RTLClass = "RTL"
    }
    return (
      <div className={[classes.Modal,classes[RTLClass]].join(" ")}>
        {this.renderWelcomeMessage()}
        {this.renderTopSection()}
        {this.renderBody()}
      </div>
    );
  }
}
export default Settings;
