import React from "react";
import classes from "./ViewPlaceCharacteristics.module.css";

import Tag from "../../UI/Tag/Tag"

let accessibleIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAccessibleIconSelected.png?alt=media&token=e91638ea-649c-4438-b5b6-2b36bf69ce75"
let notAccessibleIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FNotAccessibleIconSelected.png?alt=media&token=dcc6fdda-8a5f-4a83-b390-64a1c41d2121"
let parkingIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FParkingIconSelected.png?alt=media&token=ecf4a855-124a-4e2f-b202-c2c57fcc1a54"
let petsAllowedIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPetsFriendlySelected.png?alt=media&token=25cf53bc-a12b-4aeb-976b-a77efc3ab10c"
let petsNotAllowedIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPetsNotAllowedSelected.png?alt=media&token=4fbf381b-1e59-4f44-98c9-e71415028a84"
let freeEntranceIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FFreeIconSelected%20(1).png?alt=media&token=e2f7f755-3e52-4171-8c6f-424357829f4b"
let durationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDurationIcon.png?alt=media&token=3e02db71-8c60-4a85-bba8-5a30ba9ce277"


const ViewPlaceCharacteristics = props => {
/* 
    const renderTags = () =>{
        if (props.placeCharacteristics.tags!==undefined){   
            return(
                <div className={classes.CharRow}>
                     {props.placeCharacteristics.tags.map((tag,index)=>(
                        <div className={classes.Characteristic} key={index}>
                         {props.systemText.placeCharacteristics[tag][props.userLanguage]}
                        </div>
                    ))}
                </div>)
        }
    }

    const renderPlaceAudience= () =>{
        if (props.placeCharacteristics.placeAudience!==undefined){
            return(
                <div className={classes.CharRow}>
                     {props.placeCharacteristics.placeAudience.map((tag,index)=>(
                        <div className={classes.Characteristic} key={index}>
                         {tag[props.userLanguage]}
                        </div>
                    ))}
                </div>)
        }
    }

    const renderDuration = () =>{
        if (props.placeCharacteristics.duration!==undefined){
            if (props.placeCharacteristics.durationUnits==="days"){
                if (props.placeCharacteristics.duration===0.5){
                    return(
                        <div className={classes.Characteristic}>
                            {props.systemText.placeCharacteristics.halfDay[props.userLanguage]}
                        </div>
                    )
                }
                else if (props.placeCharacteristics.duration===1){
                    return(
                        <div className={classes.Characteristic}>
                            {props.systemText.placeCharacteristics.fullDay[props.userLanguage]}
                        </div>
                    )
                }
                else{
                    return(
                        <div className={classes.Characteristic}>
                            {props.placeCharacteristics.duration+
                            props.systemText.placeCharacteristics[props.placeCharacteristics.durationUnits][props.userLanguage]}
                        </div>
                    )
                }
            }
            else{
                return(
                    <div className={classes.Characteristic}>
                        {props.placeCharacteristics.duration+
                        props.systemText.placeCharacteristics[props.placeCharacteristics.durationUnits][props.userLanguage]}
                    </div>
                )
            }
            
        }
    }

    const renderDistance = () =>{
        if (props.placeCharacteristics.distance!==undefined){
            return(
                <div className={classes.Characteristic}>
                   
                    { props.systemText.placeCharacteristics.distance[props.userLanguage]+": "+props.placeCharacteristics.distance+" "+
                    props.systemText.placeCharacteristics[props.placeCharacteristics.distanceUnits][props.userLanguage]}
                </div>
            )
        }
    }


    const renderBikeTrail = () =>{
        if (props.placeCharacteristics.bikeTrailType!==undefined){

        }
    }

    

    const renderRestaurantCuisine = () =>{
        if (props.placeCharacteristics.restaurantCuisine!==undefined){
            return(
                <div className={classes.CharRow}>
                     {props.placeCharacteristics.restaurantCuisine.map((tag,index)=>(
                        <div className={classes.Characteristic} key={index}>
                         {tag[props.userLanguage]}
                        </div>
                    ))}
                </div>)
        }
    }

    const renderRestaurantServiceType = () =>{
        if (props.placeCharacteristics.restaurantServiceType!==undefined){
            return(
                <div className={classes.CharRow}>
                     {props.placeCharacteristics.restaurantServiceType.map((tag,index)=>(
                        <div className={classes.Characteristic} key={index}>
                         {tag[props.userLanguage]}
                        </div>
                    ))}
                </div>)
        }
    }

    const renderBarType = () =>{
        if (props.placeCharacteristics.barType!==undefined){
           
            return(
                <div className={classes.CharRow}>
                     {props.placeCharacteristics.barType.map((tag,index)=>(
                        <div className={classes.Characteristic} key={index}>
                         {tag[props.userLanguage]}
                        </div>
                    ))}
                </div>)
        }
    }
  
    const renderMuseumType = () =>{
        if (props.placeCharacteristics.museumType!==undefined){
           
            return(
                <div className={classes.CharRow}>
                     {props.placeCharacteristics.museumType.map((tag,index)=>(
                        <div className={classes.Characteristic} key={index}>
                         {tag[props.userLanguage]}
                        </div>
                    ))}
                </div>)
        }
    } */

    const renderGeneralChars = () =>{
        let generalChars = []
        if (props.placeCharacteristics.generalTags!==undefined){
            generalChars = props.placeCharacteristics.generalTags
        }
        let iconClass = "Icon"
        if (props.RTL){
            iconClass = "IconRTL"
        }
        if (generalChars.length>0){
            return (
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.generalCharacteristics.title[props.userLanguage]}
                    </div>
                   <div className={classes.Characteristic}>
                       {generalChars.includes("accessible")?(
                           <img src = {accessibleIconSelected} alt="" className={classes[iconClass]}/>
                       ):null}
                       {generalChars.includes("notAccessible")?(
                           <img src = {notAccessibleIconSelected} alt="" className={classes[iconClass]}/>
                       ):null}
                       {generalChars.includes("petsAllowed")?(
                           <img src = {petsAllowedIconSelected} alt="" className={classes[iconClass]}/>
                       ):null}
                        {generalChars.includes("petsNotAllowed")?(
                           <img src = {petsNotAllowedIconSelected} alt="" className={classes[iconClass]}/>
                       ):null}
                        {generalChars.includes("freeEntrance")?(
                           <img src = {freeEntranceIconSelected} alt="" className={classes[iconClass]}/>
                       ):null}
                        {generalChars.includes("hasParking")?(
                           <img src = {parkingIconSelected} alt="" className={classes[iconClass]}/>
                       ):null}
                       {generalChars.includes("freeParking")?(
                            <Tag 
                            style={"CharacteristicSelected"}
                            text= {props.placesCharacteristicsData.generalTags.freeParking[props.userLanguage]}
                            RTL={props.RTL}/>
                       ):null}
                        
                   </div>
                </div>
            )
        }
        
    }

    const renderAudience = () =>{
        let audienceTags = []
        if (props.placeCharacteristics.audienceTags!==undefined){
            audienceTags = props.placeCharacteristics.audienceTags
        }
        if (audienceTags.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.audienceCharacteristics.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {audienceTags.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.audienceTags[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderNatureGeneral = () =>{
        let natureGeneralTags = []
        if (props.placeCharacteristics.natureGeneralTags!==undefined){
            natureGeneralTags = props.placeCharacteristics.natureGeneralTags
        }
        if (natureGeneralTags.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.natureCharacteristics.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {natureGeneralTags.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.natureTags[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderSeasons = () =>{
        let seasonsTags = []
        if (props.placeCharacteristics.seasonsTags!==undefined){
            seasonsTags = props.placeCharacteristics.seasonsTags
        }
        if (seasonsTags.length>0){
            if (seasonsTags.length ===4){
                return(
                    <div className= {classes.CharRow}>
                        <div className={classes.SectionHeader}>
                            {props.systemText.placeCharacteristics.natureCharacteristics.seasons[props.userLanguage]}
                     </div>
                        <div className={classes.Characteristic}>
                            <Tag 
                            style={"CharacteristicSelected"}
                            text=  {props.systemText.placeCharacteristics.natureCharacteristics.allSeasons[props.userLanguage]}
                            RTL={props.RTL}/>
                        </div>
                    </div>
                ) 
            }
            
            else{
                return(
                    <div className= {classes.CharRow}>
                        <div className={classes.SectionHeader}>
                            {props.systemText.placeCharacteristics.natureCharacteristics.seasons[props.userLanguage]}
                     </div>
                        <div className={classes.Characteristic}>
                            {seasonsTags.map((tag,index)=>{
                                return(
                                    <Tag 
                                    key = {index}
                                    style={"CharacteristicSelected"}
                                    text= {props.placesCharacteristicsData.natureSeasons[tag][props.userLanguage]}
                                    RTL={props.RTL}/>
                                )
                            })}
                        </div>
                    </div>
                ) 
            }
            }
          
    }

    const renderNatureToWatch = () =>{
        let natureThingsToWatch = []
        if (props.placeCharacteristics.natureThingsToWatch!==undefined){
            natureThingsToWatch = props.placeCharacteristics.natureThingsToWatch
        }
        if (natureThingsToWatch.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.natureCharacteristics.thingsToSee[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {natureThingsToWatch.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.natureThingToWatch[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderFoodServices = () =>{
        let foodServices = []
        if (props.placeCharacteristics.foodServices!==undefined){
            foodServices = props.placeCharacteristics.foodServices
        }
        if (foodServices.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.foodPlaceCharacteristics.placeServices[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {foodServices.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.foodPlaceServices[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderFoodDiningOptions = () =>{
        let foodDiningOptions = []
        if (props.placeCharacteristics.foodDiningOptions!==undefined){
            foodDiningOptions = props.placeCharacteristics.foodDiningOptions
        }
        if (foodDiningOptions.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.foodPlaceCharacteristics.diningOptions[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {foodDiningOptions.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.foodDiningOptions[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderKosher = () =>{
        let foodKosher = []
        if (props.placeCharacteristics.foodKosher!==undefined){
            foodKosher = props.placeCharacteristics.foodKosher
        }
        if (foodKosher.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.foodPlaceCharacteristics.placeKosher[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {foodKosher.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.foodKosher[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderMuseumCharacteristics = () =>{
        let museumTypes = []
        if (props.placeCharacteristics.museumTypes!==undefined){
            museumTypes = props.placeCharacteristics.museumTypes
        }
        if (museumTypes.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.museumCharacteristics.museumType[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {museumTypes.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.museumTypes[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderHikingTrailCharacteristics = () =>{
        let trailCharacteristics = []
        if (props.placeCharacteristics.trailCharacteristics!==undefined){
            trailCharacteristics = props.placeCharacteristics.trailCharacteristics
        }
        let difficulty = []
        if (props.placeCharacteristics.difficulty!==undefined){
            difficulty = props.placeCharacteristics.difficulty
        }
        if (trailCharacteristics.length>0||difficulty.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.trailCharacteristics.titleWithDifficulty[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {trailCharacteristics.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.trailCharacteristics[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                         {difficulty.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.dificultyTags[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderDuration = () =>{
        let iconClass = "Icon"
        if (props.RTL){
            iconClass = "IconRTL"
        }
        if (props.placeCharacteristics.durationValue!==undefined){
            if (props.placeCharacteristics.durationUnits==="days"){
                if (props.placeCharacteristics.durationValue===0.5){
                    return(
                        <div className={classes.Characteristic}>
                             <img src={durationIcon} alt="" className={classes[iconClass]}/>
                            {props.systemText.placeCharacteristics.trailCharacteristics.halfDay[props.userLanguage]}
                        </div>
                    )
                }
                else if (props.placeCharacteristics.durationValue===1){
                    return(
                        <div className={classes.Characteristic}>
                             <img src={durationIcon} alt="" className={classes[iconClass]}/>
                            {props.systemText.placeCharacteristics.trailCharacteristics.fullDay[props.userLanguage]}
                        </div>
                    )
                }
                else{
                    return(
                        <div className={classes.Characteristic}>
                             <img src={durationIcon} alt="" className={classes[iconClass]}/>
                            {props.placeCharacteristics.durationValue+" "+
                            props.systemText.placeCharacteristics.trailCharacteristics[props.placeCharacteristics.durationUnits][props.userLanguage]}
                        </div>
                    )
                }
            }
            else if (props.placeCharacteristics.durationUnits!==undefined){
                return(
                    <div className={classes.Characteristic}>
                         <img src={durationIcon} alt="" className={classes[iconClass]}/>
                        {props.placeCharacteristics.durationValue+" "+
                        props.systemText.placeCharacteristics.trailCharacteristics[props.placeCharacteristics.durationUnits][props.userLanguage]}
                    </div>
                )
            }
            
        }
    }

    const renderDistance = () =>{
        if (props.placeCharacteristics.distanceValue!==undefined){
            return(
                <div className={classes.Characteristic}>
                   
                    { props.systemText.placeCharacteristics.trailCharacteristics.distance[props.userLanguage]+": "+props.placeCharacteristics.distanceValue+" "+
                    props.systemText.placeCharacteristics.trailCharacteristics[props.placeCharacteristics.distanceUnits][props.userLanguage]}
                </div>
            )
        }
    }

    const renderMTBTrailCharacteristics = () =>{
        let mtbTrailType = []
        if (props.placeCharacteristics.mtbTrailType!==undefined){
            mtbTrailType = props.placeCharacteristics.mtbTrailType
        }
        if (mtbTrailType.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.mtbTrailCharacteristics.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {mtbTrailType.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.mtbTrailType[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderBikeRideLevel = () =>{
        let bikeRideLevel = []
        if (props.placeCharacteristics.bikeRideLevel!==undefined){
            bikeRideLevel = props.placeCharacteristics.bikeRideLevel
        }
        if (bikeRideLevel.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.mtbTrailCharacteristics.bikeRideLevel.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {bikeRideLevel.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.bikeRideLevel[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderBeachCharacteristics = () =>{
        let beachCharacteristics = []
        if (props.placeCharacteristics.beachCharacteristics!==undefined){
            beachCharacteristics = props.placeCharacteristics.beachCharacteristics
        }
        if (beachCharacteristics.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.beachCharacteristics.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {beachCharacteristics.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.beachCharacteristics[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderBeachFacilities = () =>{
        let beachFacilities = []
        if (props.placeCharacteristics.beachFacilities!==undefined){
            beachFacilities = props.placeCharacteristics.beachFacilities
        }
        if (beachFacilities.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.beachCharacteristics.beachFacilities.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {beachFacilities.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.beachFacilities[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderBeachActivities = () =>{
        let beachActivities = []
        if (props.placeCharacteristics.beachActivities!==undefined){
            beachActivities = props.placeCharacteristics.beachActivities
        }
        if (beachActivities.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.beachCharacteristics.beachActivities.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {beachActivities.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.beachActivities[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderParkOrCampingFacilities = () =>{
        let parkOrCampingFacilities = []
        if (props.placeCharacteristics.parkOrCampingFacilities!==undefined){
            parkOrCampingFacilities = props.placeCharacteristics.parkOrCampingFacilities
        }
        if (parkOrCampingFacilities.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.parkOrCampingCharacteristics.parkOrCampingFacilities.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {parkOrCampingFacilities.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.parkOrCampingFacilities[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderBarCharacteristics = () =>{
        let barType = []
        if (props.placeCharacteristics.barType!==undefined){
            barType = props.placeCharacteristics.barType
        }
        if (barType.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.barCharacteristics.title[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {barType.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.barTypes[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderRestaurantServiceType = () =>{
        let restaurantServiceType = []
        if (props.placeCharacteristics.restaurantServiceType!==undefined){
            restaurantServiceType = props.placeCharacteristics.restaurantServiceType
        }
        if (restaurantServiceType.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.restaurantCharacteristics.restaurantCategory[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {restaurantServiceType.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.restaurantServiceType[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }

    const renderRestaurantCuisine = () =>{
        let restaurantCuisine = []
        if (props.placeCharacteristics.restaurantCuisine!==undefined){
            restaurantCuisine = props.placeCharacteristics.restaurantCuisine
        }
        if (restaurantCuisine.length>0){
           return(
                <div className= {classes.CharRow}>
                    <div className={classes.SectionHeader}>
                        {props.systemText.placeCharacteristics.restaurantCharacteristics.restuarantCuisine[props.userLanguage]}
                 </div>
                    <div className={classes.Characteristic}>
                        {restaurantCuisine.map((tag,index)=>{
                            return(
                                <Tag 
                                key = {index}
                                style={"CharacteristicSelected"}
                                text= {props.placesCharacteristicsData.restaurantsCuisine[tag][props.userLanguage]}
                                RTL={props.RTL}/>
                            )
                        })}
                    </div>
                </div>
            ) 
        }
    }



  return (
    <div className={[classes.PlaceCharacteristics,classes[props.RTLClass]].join(" ")}>
            {renderGeneralChars()}
            {renderDuration()}
            {renderAudience()}
            {renderSeasons()}
            {renderNatureGeneral()}
            {renderNatureToWatch()}
            {renderFoodServices()}
            {renderFoodDiningOptions()}
            {renderKosher()}
            {renderMuseumCharacteristics()}
            {renderHikingTrailCharacteristics()}
         
            {renderDistance()}
            {renderMTBTrailCharacteristics()}
            {renderBikeRideLevel()}
            {renderBeachCharacteristics()}
            {renderBeachFacilities()}
            {renderBeachActivities()}
            {renderParkOrCampingFacilities()}
            {renderBarCharacteristics()}
            {renderRestaurantServiceType()}
            {renderRestaurantCuisine()}
          </div>
  );
};

export default ViewPlaceCharacteristics;
