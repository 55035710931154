import React from "react"
import { compose, withProps } from "recompose" //Have some low security issue
import { withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer, Circle } from "react-google-maps" // have some low security issue

import MarkerObject from "./MarkerObject"

const defaultMapOptions = {
  fullscreenControl: false,zoomControl : false
};


const MyMapComponent = compose(
  withProps({
     /*  googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false",  */
      googleMapURL:"https://maps.google.com/maps/api/js?key=AIzaSyA_W-95jQC01JNveKBCvSjWQzr-W7_VSa0",       
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  )((props) =>
  <GoogleMap
    defaultZoom={props.defaultZoom}
    defaultCenter={props.focusCoordinates}
    center={props.focusCoordinates}
    zoom={props.defaultZoom}
    options = {props.boundOption}
    ref = {map =>{ map&&props.updateMapRef(map)}} 
    onIdle={props.mapReady}
    onZoomChanged={()=>props.handleZoomChanged()}
/*     onDblClick={props.onClick} */
    onClick={props.onClick}
    defaultOptions={defaultMapOptions}
  >
   <DirectionsRenderer origin={{ lat: 40.756795, lng: -73.954298 }} destination={{ lat: 41.756795, lng: -78.954298 }} />
  {/*   {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} onClick={props.onMarkerClick} />} */}
    {!props.draggingInProcess&&props.markers.map((marker, index) => (
       <MarkerObject
       key ={index}
         lat={marker.placeLocation.coordinates.lat}
         lng={marker.placeLocation.coordinates.lng}  
         placeColorNumber={marker.placeColorNumber}  
         ideaMarker={marker.ideaMarker}
         ideasOnMapCount = {props.ideasOnMapCount}
         mainCategory={marker.mainCategory}
         placeID={marker.placeID}   
         placeName={marker.placeName}
         placeAudience={marker.placeAudience} 
         placeCharacteristics={marker.placeCharacteristics}
         index={index}
         isClickable={props.isClickable}
         markerClicked={props.markerClicked}
         hoverIndex={props.hoverIndex}
         discoverHover = {props.discoverHover}
         showOrder={props.showOrder}
         board={props.board}
         showSpecialIcon={props.showSpecialIcon}
         filteredPlacesAudience={props.filteredPlacesAudience}
         discoverPlacesAudienceFilter={props.discoverPlacesAudienceFilter}
         placeCharacteristicsFilters={props.placeCharacteristicsFilters}
         discoverPlacesNameFilterTag={props.discoverPlacesNameFilterTag}
         discoverSearchLanguage={props.discoverSearchLanguage}
         creationBoard={props.creationBoard}
       />
    ))}
    {props.additionalMarkersList.map((marker, index) => (
       <MarkerObject
       key ={index}
         lat={marker.placeLocation.coordinates.lat}
         lng={marker.placeLocation.coordinates.lng}  
         index={index}
         isClickable={props.isClickable}
         placeID={marker.placeID}   
         markerClicked={props.additionalMarkerClicked}
         hoverIndex={props.additionalMarkerIndex}
         discoverHover = {props.discoverHover}
         board={props.board}
         discoverSearchLanguage={props.discoverSearchLanguage}
         creationBoard={props.creationBoard}
       />
    ))}
    {props.tripAccommodations!==undefined?(
      <div>
        {props.tripAccommodations.map((marker, index) => (
       <MarkerObject
       key ={index}
         lat={marker.placeLocation.coordinates.lat}
         lng={marker.placeLocation.coordinates.lng}  
         isAccommodation = {true}
         placeID={marker.placeID}   
         placeName={marker.placeName}
         markerClicked={(placeID,index)=>{props.markerClicked(placeID,index,"accommodation")}}
         index={index}
         isClickable={props.accommodationIsClickable}
         hoverIndex={false}
         showOrder={false}
         board={props.board}
         discoverSearchLanguage={props.discoverSearchLanguage}
         creationBoard={false}
       />
    ))}
      </div>

    ):null}
     {props.selectedPlaces!==undefined?(
      <div>
        {props.selectedPlaces.map((marker, index) => (
       <MarkerObject
       key ={index}
         lat={marker.placeLocation.coordinates.lat}
         lng={marker.placeLocation.coordinates.lng}  
         selectedPlace = {true}
         placeID={marker.placeID}   
         placeName={marker.placeName}
         index={index}
         isClickable={false}
         hoverIndex={false}
         showOrder={false}
         board={props.board}
         discoverSearchLanguage={props.discoverSearchLanguage}
         creationBoard={false}
       />
    ))}
      </div>

    ):null}
      {props.showCurrentLocation?(
            <MarkerObject
              lat={props.currentLocation.lat}
              lng={props.currentLocation.lng}  
              placeColorNumber={"CurrentLocation"}  
              placeID={"currentLocation"}   
              isCurrentLocation={props.showCurrentLocation} 
              board={props.board}
              isClickable={false}
            
            />
        ):null}
        {props.showCircle?(
          <Circle
          center={props.focusCoordinates}
          radius={props.radius}
          onCenterChanged = {props.circleCenterHandler}
          onRadiusChanged={props.circleRadiusHandler}
          options={{fillColor:"#2a5777", strokeColor:'rgb(34,121,190,0.7)'}}  
          draggable = {true}  
          editable={true}
         ref = {circle =>{ circle&&props.updateCircleRef(circle)}} 
         onDragEnd={props.onCircleDragEnd}
         onDragStart = {props.onCircleDragStart}
          />
          

        ):null}
  </GoogleMap>
)

class MyGoogleMap extends React.PureComponent {
  state = {
    isMarkerShown: false,
    defaultZoom:3,
    mapCenter:this.props.focusCoordinates,
    boundOption:{
      minZoom: 1,
      maxZoom: 19,
      gestureHandling: "greedy",
      fullscreenControl: false,
      zoomControl : false,
      mapTypeControl: false
    },
    mapRef : React.createRef(),
    circleRef: React.createRef(),
    circleInitiadted:false,
    boundsPending: true,
    updateBoundsAttempts:0,
    radius:this.props.circleRadius,
    circleRadius : this.props.circleRadius,
    circleCenter: this.props.focusCoordinates
  }

 /*  */

/* updateBounds = (bounds) =>{
  this.setState({bounds:bounds})
}
 */
 updateCenter = center =>{
  this.setState({mapCenter:center})
}

updateZoom = zoom =>{
  this.setState({defaultZoom:zoom})
}

 updateMapRef=(map)=>{
   this.state.mapRef=map;
  
 }

 updateCircleRef = circle =>{
  this.state.circleRef=circle;
  if (!this.state.circleInitiadted){
    const radius = this.props.circleRadius;
    this.setState({circleRadius:radius})
    this.props.updateTripRadius(radius)
    const center = Object.assign({}, this.state.circleRef.getCenter().toJSON())
    this.setState({circleCenter:center})
    this.props.updateTripCenter(center)
    this.setState({circleInitiadted:true})
  }
 
 }

 circleCenterHandler = () =>{
    const center = Object.assign({}, this.state.circleRef.getCenter().toJSON())
    this.setState({circleCenter:center})
    if (!this.state.draggingInProcess && this.props.showCircle){
      this.props.updateTripCenter(center)
    }
 }
 circleRadiusHandler = () =>{
  const radius = this.state.circleRef.getRadius();
  this.setState({circleRadius:radius})
  if (this.props.showCircle){
    this.props.updateTripRadius(radius)
  }
 }

 onCircleDragStart = () =>{
   this.setState({draggingInProcess:true})
 }

 onCircleDragEnd = () =>{
  this.setState({draggingInProcess:false})
  if (this.props.showCircle && this.state.circleCenter!==null){
   this.props.updateTripCenter(this.state.circleCenter)
    this.updateCenter(this.state.circleCenter)
   }
  
 }


 onClick = (e) => {
  if (this.props.mapClicked!==undefined){
    this.props.mapClicked(e)
  }
}

updateBounds = async (bounds) =>{
  this.state.mapRef.fitBounds(bounds)
}

 mapReady=async()=>{

   if (this.state.boundsPending){
     this.setState({boundsPending:false});
     if (this.state.mapRef!==undefined){
     await this.state.mapRef.fitBounds(this.state.bounds.toJSON())
     if (this.props.creationBoard){
       this.setState({defaultZoom:15})
     }
    }
    this.handleCenterChanged()
   
   }
 }

 componentWillMount = () =>{
 }

 componentDidUpdate = async (prevProps,prevState) =>{
   if (this.state.mapRef!==prevState.mapRef){
   }
   if (JSON.stringify(prevProps.markers)!=JSON.stringify(this.props.markers)){
       if ((this.props.board==="Plan"&&!this.props.onlyMapAreaResults&&this.props.markers.length>0)||(
        this.props.board==="Discover"&&this.props.markers.length>0
       )){
      const bounds = new window.google.maps.LatLngBounds();
      this.props.markers.forEach((child) => {
                bounds.extend(new window.google.maps.LatLng(child.placeLocation.coordinates.lat, child.placeLocation.coordinates.lng));
        }) 
      let boundOption=this.state.boundOption;
      if (this.props.markers.length===1){
        boundOption.maxZoom=10;
      }
  
      this.setState({bounds:bounds,mapCenter: {lat: (bounds.toJSON().south+bounds.toJSON().north)/2,
      lng: (bounds.toJSON().east+bounds.toJSON().west)/2},boundOption:boundOption})
      if (this.state.mapRef!==undefined&&this.state.mapRef!==null){
        if (this.props.markers.length===1){
            this.setState({defaultZoom:10})
         }
         else{
           this.state.mapRef.fitBounds(bounds.toJSON())
         }
      
       
      }
     
    }
    if (this.props.creationBoard){
      const bounds = new window.google.maps.LatLngBounds();
      this.props.markers.forEach((child) => {
                bounds.extend(new window.google.maps.LatLng(child.placeLocation.coordinates.lat, child.placeLocation.coordinates.lng));
        }) 
      let boundOption=this.state.boundOption;
     
        boundOption.maxZoom=15;

   
      this.setState({bounds:bounds,mapCenter: {lat: (bounds.toJSON().south+bounds.toJSON().north)/2,
      lng: (bounds.toJSON().east+bounds.toJSON().west)/2},boundOption:boundOption})
    }
  }
/*   if (this.props.googlePlaceID!==prevProps.googlePlaceID){
    console.log("changed")
    const request = {
      placeId:this.props.googlePlaceID,
      fields: ["name", "formatted_address", "place_id", "geometry","rating","international_phone_number","website","opening_hours"],
    };
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails(request, (place, status) => {
      console.log(place)
    })
  
  } */
 
 }
 componentDidMount = () =>{
    const bounds = new window.google.maps.LatLngBounds();
     this.props.boundsPlaces.forEach((child) => {
              bounds.extend(new window.google.maps.LatLng(child.placeLocation.coordinates.lat, child.placeLocation.coordinates.lng));
      }) 
    let boundOption=this.state.boundOption;
    if (this.props.board==="Discover"||this.props.board==="Plan"){
      boundOption.maxZoom=17;
    }
     this.setState({bounds:bounds,mapCenter: {lat: this.props.focusCoordinates.lat,
      lng: this.props.focusCoordinates.lng},boundOption:boundOption})
     
 }
 

  handleZoomChanged = () =>{
    if (this.props.mapBoundsHandler!==undefined){
      let zoom = this.state.mapRef.getZoom();
      let mapBounds = Object.assign({}, this.state.mapRef.getBounds().toJSON());
      let mapCenter = Object.assign({}, this.state.mapRef.getCenter().toJSON())
      this.props.mapBoundsHandler(mapBounds,zoom,mapCenter)
    }
  }
  
  handleCenterChanged = ()=>{
    if (this.props.useMapClick){
      this.setState({defaultZoom:10})
    }
    if (this.props.mapBoundsHandler!==undefined){
      let zoom = this.state.mapRef.getZoom();
      let mapBounds = Object.assign({}, this.state.mapRef.getBounds().toJSON());
      let mapCenter = Object.assign({}, this.state.mapRef.getCenter().toJSON())
      if (this.props.mapCenter!==undefined){
        if ( Math.abs(this.props.mapCenter.lat - mapCenter.lat)<0.005&&
        Math.abs(this.props.mapCenter.lng - mapCenter.lng)<0.005){

        }
        else{
          this.props.mapBoundsHandler(mapBounds,zoom,mapCenter)
        }
      }
      else{
        this.props.mapBoundsHandler(mapBounds,zoom,mapCenter)
      }
      
    }
  }

  render() {
    let height = "70vh";
    if (this.props.fullScreen) height = "85vh";
    if (this.props.height !==undefined) {
      height = this.props.height}
      return (
        <div style={{ width: "100%", height: height }}>
          <MyMapComponent
            isMarkerShown={this.state.isMarkerShown}
            markers={this.props.markers}
            onMarkerClick={this.handleMarkerClick}
            updateMapRef={this.updateMapRef}
            focusCoordinates={this.state.mapCenter}
            defaultZoom={this.state.defaultZoom}
            boundOption={this.state.boundOption}
            bounds={this.state.bounds}
            mapReady={this.mapReady}
            handleZoomChanged={this.handleZoomChanged}
            hoverIndex={this.props.hoverIndex}
            discoverHover = {this.props.discoverHover}
            showOrder={this.props.showOrder}
            board={this.props.board}
            showSpecialIcon={this.props.showSpecialIcon}
            currentLocation={this.props.currentLocation}
            showCurrentLocation={this.props.showCurrentLocation}
            isClickable={this.props.isClickable}
            markerClicked={this.props.markerClicked}
            filteredPlacesAudience={this.props.filteredPlacesAudience}
            discoverPlacesAudienceFilter={this.props.discoverPlacesAudienceFilter}
            placeCharacteristicsFilters={this.props.placeCharacteristicsFilters}
            discoverPlacesNameFilterTag={this.props.discoverPlacesNameFilterTag}
            discoverSearchLanguage={this.props.discoverSearchLanguage}
            creationBoard={this.props.creationBoard}
            onClick={this.onClick}
            showCircle = {this.props.showCircle}
            radius = {this.props.circleRadius}
            circleCenterHandler = {this.circleCenterHandler}
            circleRadiusHandler = {this.circleRadiusHandler}
            updateCircleRef = {this.updateCircleRef}
            onCircleDragEnd = {this.onCircleDragEnd}
            onCircleDragStart = {this.onCircleDragStart}
            tripAccommodations = {this.props.tripAccommodations}
            accommodationIsClickable = {this.props.accommodationIsClickable}
            selectedPlaces = {this.props.selectedPlaces}
            additionalMarkersList = {this.props.additionalMarkersList!==undefined? this.props.additionalMarkersList:[]}
            additionalMarkerClicked = {this.props.additionalMarkerClicked}
            additionalMarkerIndex={this.props.additionalMarkerIndex}
            draggingInProcess = {this.state.draggingInProcess}
            ideasOnMapCount = {this.props.ideasOnMapCount}
          />
        </div>
      )    
  }
}

export default MyGoogleMap;