import React, { Component } from "react";
import classes from "./NavBar.module.css";

import TransparentBackdrop from "../../Components/UI/Backdrop/TransparentBackdrop";
import TextButton from "../../Components/UI/Buttons/TextButton";
import Toolkit from "../../Components/UI/Toolbar/Toolkit";
import Settings from "../../Components/Complex/UserSettings/Settings"

import { isMobile, isMobileOnly } from "react-device-detect";
import NavigationMenu from "./NavigationMenu";

let logo = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FLogo.png?alt=media&token=9c05c21e-ea61-4000-ba7f-adf9e69edac0"
let imagePlaceholder = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FProfilePlaceholder.png?alt=media&token=74c2310e-bb35-42b2-8cab-b91ad0d773b5"


export default class NavBar extends Component {

    state={
        showTransparentBackdrop: false
    }

     
    componentDidMount = () =>{
      if (this.props.welcomeUser){
        this.setState({showSettings:true,welcomeUser:true,showWelcomeMessage:true})
      }
  }

  componentDidUpdate = prevProps =>{
    if (prevProps.welcomeUser!==this.props.welcomeUser){
      if (this.props.welcomeUser){
        this.setState({showSettings:true,welcomeUser:true,showWelcomeMessage:true})
      }
    }
  }

    showProfileToolbarHandler = () => {
        if (this.state.showProfileToolbar) {
          this.setState({
            showProfileToolbar: false,
            showTransparentBackdrop: false
          });
        } else {
          this.setState({
            showProfileToolbar: true,
            showTransparentBackdrop: true
          });
        }

        this.props.writeAcitivityLog({
          activityKey:"HAMBURG_BUTTON",
          userScreen:this.props.selectedContentWall.toUpperCase()
        })
      };

      settingsHandler = () => {
        this.setState({
          showProfileToolbar: false,
          showSettings: true,
          showTransparentBackdrop: false
        });
      };  

      updateSettingsHandler = userSettings =>{
        this.setState({
          showSettings: false
        });
        this.props.updateSettings(userSettings);
      }

    discardBackdrop = () =>{
        this.setState({showTransparentBackdrop: false,
            showLanguageOptions:false,
            showProfileToolbar:false})
    }

    handleLanguageSelection = async language =>{
        this.setState({showLanguageOptions:false,showTransparentBackdrop:false})
        this.props.writeAcitivityLog({
          activityKey:"LANGUAGE_BUTTON_UPDATE",
          userScreen:this.props.selectedContentWall.toUpperCase()
        })
        this.props.updateSystemLanguage(language)
      }

    renderLogo = () =>{
        return(
            <img src={logo} alt="" className={classes.Logo} onClick={this.props.logoHandler}/>)
    }

    
    renderLanguageOptions = () =>{
        let toolKitStyle="Style1"
        if (this.state.showLanguageOptions){
            let tools = [];
            let languagesList = Object.keys(this.props.systemInitializeData.configurations.systemLanguages)
            for (let i = 0 ; i<languagesList.length ; i++){
                tools.push({
                    action: ()=>{this.handleLanguageSelection(languagesList[i])},
                    text: this.props.systemText.languages.longLanguage[languagesList[i]]
                    }); 
            }
            return(
                <Toolkit
                    style={toolKitStyle}
                    tools={tools}
                />
            )
        }
    }

    renderFavoriteIcon = () =>{
        if (this.props.selectedContentWall==="Favorite"){
            return(
                <div className={classes.IconSection}>
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FInFavorites.png?alt=media&token=a5d129b8-23a0-4051-9993-731ba1325bdf"} 
                    alt="" className={classes.NavBarIconSelected}
                    onClick={()=>{this.props.favoritesHandler();this.setState({showProfileToolbar:false,showTransparentBackdrop:false})}}/>          
                </div>
                )
        }
        else{
            return(
                <div className={classes.IconSection}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FInFavoritesGrey.png?alt=media&token=7063ef15-2b81-43be-ae29-151b614c880a"} 
                alt="" className={classes.NavBarIcon} 
                 onClick={()=>{this.props.favoritesHandler();this.setState({showProfileToolbar:false,showTransparentBackdrop:false})}}/>         
              </div>
            )
        }
    }

    renderHamburgIcon = () =>{
      return(
        <div className={classes.HamburgOnly} onClick={this.showProfileToolbarHandler}>
           <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FHamburgerMenuIcon.png?alt=media&token=9c60bbd7-44f1-4bfe-ab9c-1b726dd2836f"} 
                     alt="" className={classes.HamburgIcon} />
        </div>
      )
    }

    renderUserProfileImage = () =>{
      let photoUrl = this.props.user.photoUrl
      if (this.props.user.photoUrl===null){
        photoUrl = imagePlaceholder
      }
        if (this.props.selectedContentWall==="MyContent"){
            return(
                <div className={classes.ToolbarProfileSelected} onClick={this.showProfileToolbarHandler}>
                     <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FHamburgerMenuIconWhite.png?alt=media&token=e14f9208-8ec3-4819-a3be-1c8839e1ccc0"} 
                     alt="" className={classes.HamburgIcon} />
                    <img
                    src={photoUrl}
                    alt=""
                    className={classes.ProfilePicture}
                    
                    />
                  </div>
            )
        }
        else{
            return(
                <div className={classes.ToolbarProfile}>
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FHamburgerMenuIcon.png?alt=media&token=9c60bbd7-44f1-4bfe-ab9c-1b726dd2836f"} 
                     onClick={this.showProfileToolbarHandler}
                    alt="" className={classes.HamburgIcon}/>
                    <img
                    onClick={()=>{this.props.myContentHandler()
                      this.props.writeAcitivityLog({
                        activityKey:"PROFILE_IMAGE_BUTTON",
                        userScreen:this.props.selectedContentWall.toUpperCase()
                      })
                    }}
                    src={photoUrl}
                    alt=""
                    className={classes.ProfilePicture}
                    
                />
              </div>
            )
        }
    }
    renderCreateIcon = () =>{
        if (!isMobileOnly){
         /*  if (this.props.loggedUser){ */
            return(
                <div className={classes.IconSection} >
                  <img className={classes.NavBarIcon} 
                      src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FCreateIcon.png?alt=media&token=ea1d34ef-e969-489a-afac-027cf9f2e801"} alt=""
                      onClick={this.props.createNewHandler}/>
              </div>
              )
          /*   }
            else{
              return(
                <div className={classes.IconSection} >
                  <img className={classes.NavBarIcon} 
                      src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FCreateIconFade.png?alt=media&token=45425006-81c9-4ae2-9a7a-3280c4e6bd15"} alt=""
                      onClick={this.props.loginHandler}/>
              </div>
              )
            } */
        }
      }

      renderExploreIcon = () =>{
        if (!this.props.hideDiscoverButton){
          if (this.props.selectedContentWall==="Discover"){
            return(
              <div className={classes.IconSection}>
                    <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FExploreIconSelected.png?alt=media&token=ace2753d-e8af-4474-a156-94e7800fd850"} 
                    alt="" className={classes.NavBarIconSelected} 
                     onClick={()=>{this.props.discoverHandler();this.setState({showProfileToolbar:false,showTransparentBackdrop:false})}}/>            
                  </div>
            )
          }
          else{
              return(
                <div className={classes.IconSection}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FExploreIcon.png?alt=media&token=01fb42ea-047f-4aad-8c5c-8b61c0609f54"} 
                alt="" className={classes.NavBarIcon} 
                 onClick={()=>{this.props.discoverHandler();this.setState({showProfileToolbar:false,showTransparentBackdrop:false})}}/>    
              </div>
              )
          }
        }
      }

    renderFriendsIcon = () =>{
        return(
            <div className={classes.IconSection}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FFriendsIcon.png?alt=media&token=3af92ddc-c38a-4c85-bb81-03151a4f1189"} 
                alt="" className={classes.NavBarIcon} 
                onClick={()=>{this.props.showFriends(); this.setState({showFriendsNotification:false})
                this.props.writeAcitivityLog({
                  activityKey:"FRIENDS_BUTTON",
                  userScreen:this.props.selectedContentWall.toUpperCase()
                })
                }}/>
                 {this.props.friendsRequests.length>0 ? ( 
                <div className={classes.NotifyNumber}>
                    {this.props.friendsRequests.length}
                </div> 
                ) : null}  
        
        </div> 
        )
    }
 

    renderNavBarTools = () =>{
        if (this.props.loggedUser){
            return (
                <div className={classes.NavBarTools}>
                     {this.renderCreateIcon()}
                     {this.renderExploreIcon()}
                     {this.renderFavoriteIcon()}
                    {this.renderFriendsIcon()}
                    {this.renderUserProfileImage()}
                {/*   <div className={classes.Tools}>{this.renderUserToolbar()}</div> */}
                </div>
              );
        }
        else{
            return(
                <div className={classes.NavBarTools}>
                   {this.renderCreateIcon()}
                   {this.renderExploreIcon()}
                     <div className={classes.LanguageButton}>
                        <TextButton
                            clicked={()=>{this.setState({showLanguageOptions:true,showTransparentBackdrop:true})
                            this.props.writeAcitivityLog({
                              activityKey:"LANGUAGE_BUTTON",
                              userScreen:this.props.selectedContentWall.toUpperCase()
                            })
                          }}
                            text={this.props.systemText.languages.shortLanguage[this.props.userLanguage]}
                            style="Style1"
                        />
                        <div className={classes.Tools}>{this.renderLanguageOptions()}</div>
                     </div>
                       
                          {this.renderHamburgIcon()}
                </div>
            )
        }
    }

    renderUserToolbar = () =>{
        let toolKitStyle="Style2"
        if (this.state.showProfileToolbar){
            let tools = [];
            if (this.props.loggedUser){
              tools.push({
                action: ()=>{this.settingsHandler()
                  this.props.writeAcitivityLog({
                    activityKey:"SETTINGS_BUTTON",
                    userScreen:this.props.selectedContentWall.toUpperCase()
                  })},
                text: this.props.systemText.navBar.menu.settings[this.props.userLanguage],
                icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FSettingsIcon.png?alt=media&token=8344338e-a44e-4b16-ac24-956c6af14f52"
                }); 
              tools.push({
                action: ()=>{this.setState({showProfileToolbar:false,showTransparentBackdrop:false});this.props.myContentHandler()
                this.props.writeAcitivityLog({
                  activityKey:"MYCONTENT_BUTTON",
                  userScreen:this.props.selectedContentWall.toUpperCase()
                })},
                text: this.props.systemText.navBar.menu.myContent[this.props.userLanguage],
                icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FMyContent.png?alt=media&token=5f7525c5-5e9e-49d7-b0af-30369ee407cf"
                });
              tools.push({
                action: ()=> {
                    this.props.logout();
                    this.setState({showProfileToolbar:false,showTransparentBackdrop:false})
                    this.props.writeAcitivityLog({
                      activityKey:"LOGOUT_BUTTON",
                      userScreen:this.props.selectedContentWall.toUpperCase()
                    })
                },
                text: this.props.systemText.navBar.menu.logout[this.props.userLanguage],
                icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FLogout.png?alt=media&token=a694fd88-d40a-458b-a49a-d2f571898ce9"
                }); 
            }
            
            if (this.props.adminUser){
                tools.push({
                    action: ()=>{this.setState({showProfileToolbar:false,showTransparentBackdrop:false});this.props.adminHandler()},
                    text: "Admin",
                    icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FAdmin.png?alt=media&token=c276f1ae-4d7f-445f-ac1f-f592ef615750"
                    }); 
            }
            
            
            tools.push({
              action: ()=> {
                  this.props.contactForm();
                  this.setState({showProfileToolbar:false,showTransparentBackdrop:false})
                  this.props.writeAcitivityLog({
                    activityKey:"CONTACTUS_BUTTON",
                    userScreen:this.props.selectedContentWall.toUpperCase()
                  })
              },
              text: this.props.systemText.navBar.menu.contactUs[this.props.userLanguage],
               icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FContactUsIcon.png?alt=media&token=ba137785-5a97-4d76-81d0-fc6831e68019"
              }); 
              
            return(
                <Toolkit
                    style={toolKitStyle}
                    tools={tools}
                    RTL = {this.props.RTL}
                />
            )
        }
    }

    renderToolbarModals = () => {
        if (this.state.showSettings) {
          return (
                <Settings
                  systemText={this.props.systemText}
                  userResidenceCountry = {this.props.userResidenceCountry}
                  writeAcitivityLog = {this.props.writeAcitivityLog}

                  welcomeUser = {this.state.welcomeUser}
                  showWelcomeMessage={this.state.showWelcomeMessage}
                  closeWelcomeMessage = {()=>{this.setState({showWelcomeMessage:false})}}
                  systemLanguages={this.props.systemInitializeData.configurations.systemLanguages}
                 
                  userLanguage={this.props.userLanguage}
                  RTL = {this.props.RTL}
                  inputLanguage = {this.props.inputLanguage}
                  detectInputLanguage = {this.props.detectInputLanguage}
                    
                  photoUrl={this.props.user.photoUrl}
                  displayName={this.props.user.displayName}
                  user={this.props.user}
                  discard={()=>{this.setState({showSettings:false})}}
                  update={this.updateSettingsHandler}
                  userSettings={this.props.userInfo.userSettings}
                  systemCategories={this.props.systemCategories}
                  allSystemCategories = {this.props.allSystemCategories}
                  categoriesTranslator = {this.props.categoriesTranslator}
                  updateUserLanguage={this.props.updateUserLanguage}
                  languageHandler={this.props.updateSystemLanguage}
                />
          );
        }
      }

      renderSelectedWallName = () =>{
        if (!isMobile){
          switch(this.props.selectedContentWall){
            case "Discover":{
              return (<div className={classes.SelectedContent}>{this.props.systemText.navBar.selectedContentMessage.discover[this.props.userLanguage]}</div>)
            }
            case "Favorite":{
              return (<div className={classes.SelectedContent}>{this.props.systemText.navBar.selectedContentMessage.favorite[this.props.userLanguage]}</div>)
            }
            case "My Mind":{
              return (<div className={classes.SelectedContent}>{this.props.systemText.navBar.selectedContentMessage.myContent[this.props.userLanguage]}</div>)
            }
            case "WelcomePage":{
              return (<div className={classes.SelectedContent}>{this.props.systemText.navBar.selectedContentMessage.welcome[this.props.userLanguage]}</div>)
            }
            default: return null;
          }
        }
      }

    renderNavbar = () =>{
        return(
            <div className={classes.NavBar}>
                {this.renderLogo()}
                {this.renderSelectedWallName()}
                {this.renderNavBarTools()}
            </div>
        )
    }

    renderNavigationMenu= () =>{
      let tools = [];
      if (this.props.loggedUser){
        tools.push({
          action: ()=>{this.settingsHandler()
            this.props.writeAcitivityLog({
              activityKey:"SETTINGS_BUTTON",
              userScreen:this.props.selectedContentWall.toUpperCase()
            })},
          text: this.props.systemText.navBar.menu.settings[this.props.userLanguage],
          icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FSettingsIcon.png?alt=media&token=8344338e-a44e-4b16-ac24-956c6af14f52"
          }); 
        tools.push({
          action: ()=>{this.setState({showProfileToolbar:false,showTransparentBackdrop:false});this.props.myContentHandler()
          this.props.writeAcitivityLog({
            activityKey:"MYCONTENT_BUTTON",
            userScreen:this.props.selectedContentWall.toUpperCase()
          })},
          text: this.props.systemText.navBar.menu.myContent[this.props.userLanguage],
          icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FMyContent.png?alt=media&token=5f7525c5-5e9e-49d7-b0af-30369ee407cf"
          });
        tools.push({
          action: ()=> {
              this.props.logout();
              this.setState({showProfileToolbar:false,showTransparentBackdrop:false})
              this.props.writeAcitivityLog({
                activityKey:"LOGOUT_BUTTON",
                userScreen:this.props.selectedContentWall.toUpperCase()
              })
          },
          text: this.props.systemText.navBar.menu.logout[this.props.userLanguage],
          icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FLogout.png?alt=media&token=a694fd88-d40a-458b-a49a-d2f571898ce9"
          }); 
      }
      else{
        tools.push({
          action: ()=>{this.props.loginHandler()
            this.props.writeAcitivityLog({
              activityKey:"LOGIN_BUTTON",
              userScreen:this.props.selectedContentWall.toUpperCase()
            });
            this.setState({showProfileToolbar:false,showTransparentBackdrop:false})},
          text: this.props.systemText.navBar.loginMessage[this.props.userLanguage],
          icon:"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLoginIcon.png?alt=media&token=ab52fc54-1f9b-4c20-8525-dd7d31b3335a"
          }); 
      }
   
    if (this.props.adminUser){
        tools.push({
            action: ()=>{this.setState({showProfileToolbar:false,showTransparentBackdrop:false});this.props.adminHandler()},
            text: "Admin",
            icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FAdmin.png?alt=media&token=c276f1ae-4d7f-445f-ac1f-f592ef615750"
            }); 
    }
   
    
    tools.push({
      action: ()=> {
          this.props.contactForm();
          this.setState({showProfileToolbar:false,showTransparentBackdrop:false})
          this.props.writeAcitivityLog({
            activityKey:"CONTACTUS_BUTTON",
            userScreen:this.props.selectedContentWall.toUpperCase()
          })
      },
      text: this.props.systemText.navBar.menu.contactUs[this.props.userLanguage],
        icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FContactUsIcon.png?alt=media&token=ba137785-5a97-4d76-81d0-fc6831e68019"
      }); 
        
      return(
        <NavigationMenu
         modalClass = {this.state.showProfileToolbar? "Modal" : "HideModal"}
          RTLClass = {this.props.RTL? "RTL" : ""}
          RTL={this.props.RTL}
          tools = {tools}
        />
      )
    }

    render(){
        return(
            <div className={classes.Body}>
                  <TransparentBackdrop
                    show={this.state.showTransparentBackdrop}
                    clicked={this.discardBackdrop}
                    />
                  {this.renderNavigationMenu()}
                  {this.renderToolbarModals()}
                {this.renderNavbar()}
            </div>
        )
    }
}