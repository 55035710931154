import React from "react";

import classes from "./ImageItem.module.css";

let  deleteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"
/* let upIcon = "../../../Visuals/ContentObjects/MoveUp.png" */




const ImageItem = props => {

  return (
    <div className={[classes.Body,classes[props.fullWidth]].join(" ")}>

    
    
      <div className={classes.ImageWraper}>
        {props.index!==undefined?(
          <div className={classes.UpIcon}>{props.index+1}</div>
        ):null}
     
        <img className={classes.ImagePreview} alt="" src={props.imgSrc} />
        {props.hideRemoveButton?(
          null
        ):(
          <img className={classes.Icon} alt="" src={deleteIcon} onClick={ props.deleteImage} />
        )}
        
       {/*  {props.index!==0?(
          <img className={classes.UpIcon} alt="" src={upIcon} onClick={ props.orderFirst} />
        ): (null)} */}
      
      </div>
    </div>
  );
};

export default ImageItem;
