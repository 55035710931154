import React, {useEffect } from "react";

import classes from "./InformationModal.module.css";

import TextButton from "../Buttons/TextButton";

const InformationModal = props => {


  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const renderButtons=()=>{
    return(
      <div className={classes.Buttons}>
          <TextButton
            clicked={() => {
              props.discard();
            }}
            text={props.btnText}
            style = {"DiscardButton"}
          />
        </div>
    ) 
  }

  const renderHeader = ()=>{
    return(
      <div className={[classes.Title,classes[props.infoType]].join(" ")}>{props.header}</div>
  )
  }

  const renderBody = () =>{
    let bodyClass = "Body"
    if (props.RTL){
      bodyClass = "BodyRTL"
    }
    return (<div className={classes[bodyClass]}>
      {renderHeader()}
     <div className={classes.Message}>
     {props.message.split('\n').map((str,index) => <p key={index}>{str}</p>)}</div>
     {renderButtons()}
   </div>)
  }

  return (
    <div
      className={classes.Modal}>
      {renderBody()} 
    </div>
  );
};

export default InformationModal;
