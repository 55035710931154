import React, { Component } from "react";
import classes from "./PlanJourneyDay.module.css";

import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {isMobileOnly,isMobile} from 'react-device-detect';
import { Editor, EditorState , convertFromRaw, convertToRaw} from 'draft-js';

let placeIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"

const SortableItem = sortableElement(({index, value}) =>
{ 
  return(<img className={classes.MovingImage} alt="" src={value}/>)});

const SortableContainer = sortableContainer(({children}) => {
  return <div  className={classes.ImagesSection}>{children}</div>;
});


class PlanJourneyDay extends Component {
    state={
        dayRef:React.createRef(),
    }

    componentDidMount = () =>{
    }

    componentDidUpdate = prevProps =>{
        if (prevProps.selectedDayIndex!==this.props.selectedDayIndex){
            if (this.props.selectedDayIndex===this.props.dayIndex){
                this.state.dayRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
             
            }
        }
    }


    onSortEnd = async (dayIndex,itemIndex,{oldIndex, newIndex}) => {
        let userID = this.props.user.userID
        let userJourneyImages = []
        if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID]!==undefined){
            if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID].userJourneyImages!==undefined){
                userJourneyImages = this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID].userJourneyImages
            }
        }
        userJourneyImages = arrayMove(userJourneyImages, oldIndex, newIndex)
        this.props.updateJourneyImagesOrder(dayIndex,itemIndex,userJourneyImages)
      };



    renderDayPlaces = (dayIndex) =>{
        let iconClass = "Icon"
        if (this.props.RTL){
            iconClass = "IconRTL"
        }
        return(
            <div className={classes.DayPlaces}>
                {this.props.planDays[dayIndex].dayItems.map((item,index)=>{
                    if (item.type==="place"){
                     let placeName="";
                     //get the relevant language or at least any language name
                     if (item.item.placeName[this.props.userLanguage]!==undefined){
                     placeName=item.item.placeName[this.props.userLanguage]
                     }
                     else if(item.item.placeName["English"]!==undefined){
                     placeName=item.item.placeName["English"]
                     }
                     else{
                     let placesNames =  Object.keys(item.item.placeName);
                     placeName=item.item.placeName[placesNames[0]]
                     }
                    return(<div className={classes.PlaceSection} key={index}>
                       <div className={classes.PlaceTopSection} >
                           <div className={classes.PlaceNameSection}>
                                <img src = {placeIcon} alt="" className={classes[iconClass]}/>
                                <div className={classes.PlaceName} onClick={()=>{this.props.viewPlaceHandler(item.item)}}>
                                    {placeName}
                                </div>
                            </div>
                            {this.renderPlaceTools(dayIndex,index)}
                        </div>
                        {this.renderPlaceExperiences(dayIndex,index)}
                   </div>)
                }
            })}
               
             </div>
        )
    }

    editHandler = async (dayIndex,itemIndex) =>{
        let placeExperienceText=EditorState.createEmpty();
        if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[this.props.user.userID]!==undefined){
            try {
                JSON.parse(this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[this.props.user.userID].experienceText);
                placeExperienceText= EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[this.props.user.userID].experienceText)))
            } catch (e) {
                placeExperienceText=EditorState.createEmpty()
            }
        }
        await this.setState({placeExperienceTempText:placeExperienceText})
        this.setState({editMode:true,editIndex:{dayIndex:dayIndex,itemIndex:itemIndex}})
    }

    updateHandler = async (dayIndex,itemIndex)=>{
        if (this.state.editMode){
            this.props.updateExperienceText(dayIndex,itemIndex,this.state.placeExperienceTempText)
        }
        this.setState({editMode:false,reorderMode:false})
    }

    renderPlaceTools = (dayIndex,index) =>{
        if (this.props.isEditor && Object.keys(this.props.planDays[dayIndex].dayItems[index].item.placeExperiences).length>0){
            return(
                <div className={classes.PlaceTools}>
                   <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FExperienceIcon.png?alt=media&token=b0a60690-11d3-4422-87f6-cfff83ad6ddb"} 
                        alt="" className={classes.ToolsIcon} onClick={()=>{this.props.shareExperience(dayIndex,index)}}/>
                    {(this.state.editMode||this.state.reorderMode)&&this.state.editIndex.dayIndex===dayIndex&&this.state.editIndex.itemIndex===index?(
                        <div className={classes.UpdateButton} 
                        
                            onClick={()=>{this.updateHandler(dayIndex,index)
                                this.props.writeAcitivityLog({
                                    activityKey:"TRIP_ALBUM_DAY_UPDATE",
                                    userScreen:"VIEW_TRIP"
                                  })
                            }}>
                            {this.props.systemText.plan.viewPlan.experiencesAlbum.done[this.props.userLanguage]}</div>
                    ):(
                        <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIcon.png?alt=media&token=c964d68a-44ba-45bf-afd7-b4b8eaeb6fba"} 
                        alt="" className={classes.ToolsIcon} 
                        
                            onClick={()=>{this.editHandler(dayIndex,index)
                            this.props.writeAcitivityLog({
                                activityKey:"TRIP_ALBUM_DAY_EDIT",
                                userScreen:"VIEW_TRIP"
                              })
                            }}/>
                    )}
                    
                     {!this.state.editMode&&!this.state.reorderMode?(
                     <div className={classes.UpdateButton}
                         onClick={()=>{this.setState({reorderMode:true,editIndex:{dayIndex:dayIndex,itemIndex:index}})
                         this.props.writeAcitivityLog({
                            activityKey:"TRIP_ALBUM_DAY_REORDER",
                            userScreen:"VIEW_TRIP"
                          })
                         }}>
                        {this.props.systemText.plan.viewPlan.experiencesAlbum.reOrder[this.props.userLanguage]}</div>):null}
                </div>
            )
        }
        else{
            return(
                <div className={classes.PlaceTools}>
                     <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FExperienceIcon.png?alt=media&token=b0a60690-11d3-4422-87f6-cfff83ad6ddb"} 
                        alt="" className={classes.ToolsIcon} onClick={()=>{this.props.shareExperience(dayIndex,index)}}/>
                </div>
            )
        }
       
    }

    experienceTextBlurHandler = () =>{
        this.props.writeAcitivityLog({
            activityKey:"TRIP_ALBUM_EXPERIENCE_TEXT_UPDATE",
            userScreen:"VIEW_TRIP"
          })
    }

    renderPlaceExperiences = (dayIndex,itemIndex) =>{
        if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences!==undefined){
            let userID = this.props.user.userID
            if (!this.props.isEditor){
                userID = this.props.viewUserJourneyID
            }
            let placeExperienceText=EditorState.createEmpty();
            if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID]!==undefined){
                try {
                    JSON.parse(this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID].experienceText);
                    placeExperienceText= EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID].experienceText)))
                } catch (e) {
                    placeExperienceText=EditorState.createEmpty()
                }
            }
            let images = []
            
            let userJourneyImages = []
            if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID]!==undefined){
                if (this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID].userJourneyImages!==undefined){
                    userJourneyImages = this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[userID].userJourneyImages
                }
            }
           
            if (isMobileOnly){
                images = userJourneyImages.map(img => {return img.smallImage.url})
            }
            else{
                images = userJourneyImages.map(img => {return img.mediumImage.url})
            }
            if (this.props.isEditor){
                
                if (this.state.editMode&&this.state.editIndex.dayIndex===dayIndex&&this.state.editIndex.itemIndex===itemIndex){
                    let allPlaceImages = [];
                    let showImages = []
                    let placeExperiencesIDs = [];
                    let usersIDs = Object.keys(this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences)
                    for (let i=0 ; i<usersIDs.length ; i++){
                        placeExperiencesIDs = placeExperiencesIDs.concat(this.props.planDays[dayIndex].dayItems[itemIndex].item.placeExperiences[usersIDs[i]].userExperiences)  
                    }
                    for (let i=0 ; i<placeExperiencesIDs.length ; i++){
                        const expIndex = this.props.planExperiences.findIndex(exp => {
                            return exp.experienceID === placeExperiencesIDs[i];
                        });
                        if (expIndex!==-1){
                            allPlaceImages = allPlaceImages.concat(this.props.planExperiences[expIndex].imagePreview)
                        }
                    }
                    if (isMobileOnly){
                        showImages = allPlaceImages.map(img => {return img.smallImage.url})
                    }
                    else{
                        showImages = allPlaceImages.map(img => {return img.mediumImage.url})
                    }
                    return(
                        <div className={classes.PlaceExperiencesSection}>
                            <div className={classes.ExperienceText}>
                                <Editor
                                    blockStyleFn={this.blockStyleFn}
                                    customStyleMap={this.styleMap}
                                    className={classes.TextInput}
                                    editorState={this.state.placeExperienceTempText}
                                    onChange={(text)=>{this.setState({placeExperienceTempText:text})}}
                                    resize="none"
                                    ref="editor"
                                    onBlur = {this.experienceTextBlurHandler}
                                    placeholder={this.props.systemText.plan.viewPlan.experiencesAlbum.textPlaceholder[this.props.userLanguage]}
                                    />
                                
                            </div>
                            <div className={classes.ImagesGallery}>
                                {showImages.map((url,index)=>{
                                    const imgIndex = images.findIndex(imgUrl => {
                                        return imgUrl === url;
                                    });
                                    if (imgIndex!==-1){
                                        return(
                                            <div className={classes.EditImageSection} onClick={()=>{this.props.removeJourneyImage(dayIndex,itemIndex,imgIndex,userID)}}>
                                                <div className={classes.SelectedImageBullet}/>
                                                <img className={classes.EditImage} alt="" src={url} key={index} />
                                            </div>
                                        )
                                    }
                                else{
                                    return(
                                        <div className={classes.EditImageSection}  onClick={()=>{this.props.addJourneyImage(dayIndex,itemIndex,allPlaceImages[index],userID)}}>
                                            <div className={classes.NotSelectedImageBullet}/>
                                            <img className={classes.EditImage} alt="" src={url} key={index} />
                                        </div>
                                    )
                                }
                                })}
                            </div>
                        </div>
                    )
                }
                else if(this.state.reorderMode&&this.state.editIndex.dayIndex===dayIndex&&this.state.editIndex.itemIndex===itemIndex){
                    return(
                        <div className={classes.PlaceExperiencesSection}>
                            <div className={classes.ImagesGallery}>
                            <SortableContainer onSortEnd={({oldIndex, newIndex})=>this.onSortEnd(dayIndex,itemIndex,{oldIndex, newIndex})} axis={"x"}>
                            {images.filter(img=>img!==undefined).map((value, index) => {
                                return(
                                <SortableItem key={index} index={index} value={value} />)
                                })}
                            </SortableContainer>
                            </div>
                        </div>
                    )
                   
                }
                else{
                    return(
                        <div className={classes.PlaceExperiencesSection}>
                            {placeExperienceText.getCurrentContent().hasText()?(
                                <div className={classes.ExperienceText}>
                                <Editor
                                    blockStyleFn={this.blockStyleFn}
                                    customStyleMap={this.styleMap}
                                    className={classes.TextInput}
                                    editorState={placeExperienceText}
                                    readOnly={true}
                                    resize="none"
                                    ref="editor"
                                    />
                                
                            </div>
                            ):null}
                            
                            <div className={classes.ImagesGallery}>
                            {images.map((url,index)=>(
                                <img className={classes.ExpImage} alt="" src={url} key={index} onClick={()=>{return null}}/>
                            ))}
                            </div>
                        </div>
                    )
                }
            }
            else{
                return(
                    <div className={classes.PlaceExperiencesSection}>
                         {placeExperienceText.getCurrentContent().hasText()?(
                                <div className={classes.ExperienceText}>
                                <Editor
                                    blockStyleFn={this.blockStyleFn}
                                    customStyleMap={this.styleMap}
                                    className={classes.TextInput}
                                    editorState={placeExperienceText}
                                    readOnly={true}
                                    resize="none"
                                    ref="editor"
                                    />
                                
                            </div>
                            ):null}
                        <div className={classes.ImagesGallery}>
                            {images.map((url,index)=>(
                                <img className={classes.ExpImage} alt="" src={url} key={index} onClick={()=>{return null}}/>
                            ))}
                        </div>
                    </div>
                )
            }
        }
    }


    render(){
        return(
            <div className={classes.DaySection} ref={this.state.dayRef}>
            {!isMobile?(
                <div className={classes.DayTitle}>
                    {this.props.dayName}
                </div>
            ):null}
           
            {this.renderDayPlaces(this.props.dayIndex)}
        </div>
        )
    }
}

export default PlanJourneyDay;