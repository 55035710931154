import React, { Component } from "react";
import classes from "./DiscoverSearchBar.module.css";

import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"

export default class DiscoverSearchBar extends Component {

    state={
        searchInput:"",
        showSuggestions:false
    }

    backdropHandler = () =>{
        this.setState({showBackdrop:false,
        showSuggestions:false})
    }

    searchInputHandler = event => {
        if(event.target.value.length===1){
          this.props.detectLanguage(event.target.value)
        }
        //In case user pressed 'esc' key, clean the input field
        if (event.keyCode === 27) {
          this.setState({
            searchInput: "",
            showSuggestions: false,
            showBackdrop: false,
            searchSuggestions: []
          });
          return;
        }
        //In case user pressed 'enter' key, add the tag to experience tags array
        //Use the selectTagSuggestion function for that
        if (event.key === "Enter") {
          //In case user pressed 'enter' with blank input, do nothing
          if (event.target.value === "") return;
          if (this.state.searchSuggestions.length > 0){
            this.selectSearchSuggestion(this.state.searchSuggestions[0]);
          }
          else{
            this.props.noSearchResultsHandler(event.target.value)
          }
          return;
        } else {
          //Get suggestions for user existing tags based in sub-input
          let searchSuggestions = this.getSuggestions(event.target.value);
          this.setState({ searchInput: event.target.value }); 
    
          //In case user entered some characters, the suggestions should be shown
          if (event.target.value != "" && searchSuggestions.length != 0) {
            this.setState({
              showSuggestions: true,
              showBackdrop: true,
              searchSuggestions: searchSuggestions
            });
    
            //If no suggetions exist based on entered value, don't show the suggestions
          } else if (searchSuggestions.length === 0) {
            this.setState({
              showSuggestions: false,
              showBackdrop: false,
              searchSuggestions: []
            });
          } else {
            //Input is blank
            this.setState({
              showSuggestions: false,
              showBackdrop: false,
              searchSuggestions: []
            });
          }
        }
      };

      getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let searchTags = this.props.searchTags.filter(option=>option[this.props.inputLanguage]!==undefined)
        return inputLength === 0
          ? []
          : searchTags.filter(
              tag =>
                tag[this.props.inputLanguage].toLowerCase().includes(inputValue)
            );
      };

      selectSearchSuggestion = (tagValue) => {
        let tag=tagValue
        if (tag === undefined) return;
        this.setState({
          searchInput: "",
          showSuggestions: false,
          showBackdrop: false,
          tagSuggestions: []
        });
        this.props.updateFilterTags(tag);
      };

    renderSearchBar = () => {
        return (
          <div className={classes.SearchBar}>
            {this.renderSearchInput()}
            {this.renderSuggestions()}
          </div>
        );
      };

    renderSearchInput = () =>{
        let inputTextClass = "InputText"
        if (this.props.RTL){
            inputTextClass = "InputTextRTL" 
        }
        let inputWidth =""
        if (this.props.fullWidth){
          inputWidth ="InputFullWidth"
        }
        let placeHolder = this.props.placeholderMessage
        if (this.props.specialPlaceHolderMessage!==""&&this.state.inputOnFocus){
          placeHolder=this.props.specialPlaceHolderMessage
        }
        if (this.state.showSuggestions) {
          return (
            <div className={classes.SearchInputWithSuggestions}>
              <input
              id="discoverSearch"
               ref={input => {
                this.nameInput = input;
              }}
                className={[classes[inputTextClass],classes[inputWidth]].join(" ")}
                type="text"
                placeholder={placeHolder}
                onChange={this.searchInputHandler}
                onKeyDown={this.searchInputHandler}
                onKeyPress={this.searchInputHandler}
                value={this.state.searchInput}
                autoComplete="off"
                onFocus={()=>{this.setState({inputOnFocus:true})}}
                onBlur={()=>{this.setState({inputOnFocus:false})}}
              />
            </div>
          );
        } else {
          return (
            <div className={classes.SearchInput}>
              <input
                ref={input => {
                  this.nameInput = input;
                }}
                className={[classes[inputTextClass],classes[inputWidth]].join(" ")}
                type="text"
                placeholder={placeHolder}
                onChange={this.searchInputHandler}
                onKeyDown={this.searchInputHandler}
                onKeyPress={this.searchInputHandler}
                value={this.state.searchInput}
                autoComplete="off"
                onFocus={()=>{this.setState({inputOnFocus:true})}}
                onBlur={()=>{this.setState({inputOnFocus:false})}}
              />
            </div>
          );
        }
    }

    renderSuggestions = () => {
        let suggestionsClass = "Suggestions"
       /*  if (this.props.RTL){
            suggestionsClass = "SuggestionsRTL"
        } */
        let suggestionsWidth =""
        if (this.props.fullWidth){
          suggestionsWidth ="FullWidth"
        }
        if (this.state.showSuggestions) {
            let suggestions = this.state.searchSuggestions;
            return (
        
                <div className={[classes[suggestionsClass],classes[suggestionsWidth]].join(" ")}>
                  {suggestions.map((tag, index) => {
                    let showTag=tag[this.props.inputLanguage]
                    let isLocationIcon = false;
                    let isCategoryTag = false
                    let isPlaceTag = false
                    let isPlaceName = true;
                    let locationTagClass = "LocationTagIcon"
                    if (this.props.RTL){
                      locationTagClass = "LocationTagIconRTL"
                    }
                    if (tag.treeID!==undefined){
                      showTag=tag[this.props.inputLanguage]
                      if (tag.treeID.split('_')[1]!==undefined){
                        showTag = showTag +", "+this.props.locationsTranslator[tag.treeID.split('_')[0]][this.props.inputLanguage];
                      }
                      isLocationIcon = true;
                      isPlaceName = false;
                    }
                    if (tag.id!==undefined){
                      isCategoryTag = true
                      isPlaceName = false;
                    }
                    if (tag.placeTag!==undefined){
                      isPlaceTag = true
                      isPlaceName = false;
                    }
                    return(
                      <div className={classes.SearchValue}
                        key={index}
                        onClick={()=>{this.selectSearchSuggestion(tag)}}>
                          {isLocationIcon?(
                            <img alt=""
                            src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLocationIconGrey.png?alt=media&token=603bd54f-2130-4f63-9b6b-17de74cc164d"}
                            className={classes[locationTagClass]}/>
                          ):null}
                          {isCategoryTag?(
                            <img alt=""
                            src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategoryIconGrey.png?alt=media&token=441ac028-77fb-4000-81c3-4187bd9ee368"}
                            className={classes[locationTagClass]}/>
                          ):null}
                           {isPlaceTag?(
                            <img alt=""
                            src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTagIconGrey.png?alt=media&token=ca178511-61d2-4d30-898c-510a54af3a2b"}
                            className={classes[locationTagClass]}/>
                          ):null}
                          {isPlaceName?(
                            <img alt=""
                            src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIconGrey.png?alt=media&token=14eefd33-8dd4-4473-aa17-babc75342117"}
                            className={classes[locationTagClass]}/>
                          ):null}

                        {showTag}
                      </div>)
                    })}
                </div>
              );
        }

    }

   render(){
    return(
        <div style={{ width: "100%" }}>
            <TransparentBackdrop
                show={this.state.showBackdrop}
                clicked={this.backdropHandler}
            />
            <div className={classes.Body}>{this.renderSearchBar()}</div>
      </div>
    )
}
}