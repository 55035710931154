import React, { Component } from "react";

import classes from "./OptionSelection.module.css";

import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop";
import Tag from "../../UI/Tag/Tag"

let  dropdownIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDetail.png?alt=media&token=2945590b-6232-4f58-afbe-358781245f57";
let collapseIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCollapse.png?alt=media&token=0a19f67c-7fad-4e02-8449-20d7e13e615c";


class OptionSelection extends Component {
  state = {
    showSuggestions: false,
    searchInput: "",
    showBackdrop: false,
    searchSuggestions: [],
    errorMessage: "",
    inputLanguage:this.props.userLanguage,
    focusTagIndex:-1,
    inputChanged: false,
    bodyRef:React.createRef(),
  };

componentDidMount = () => {
  if (this.props.currentSelection!== "") {
    this.setState({ selectedOption: this.props.currentSelection, searchInput:this.props.currentSelection ,showSearchInput:false});
  } else {
    this.setState({ selectedOption: this.props.placeholder,showSearchInput:true });
  }
};

  componentDidUpdate = prevProps => {
    if (this.props.currentSelection !== prevProps.currentSelection) {
      if (this.props.currentSelection!== "") {
        this.setState({ selectedOption: this.props.currentSelection, searchInput:this.props.currentSelection ,showSearchInput:false});
      } else {
        this.setState({ selectedOption: this.props.placeholder,showSearchInput:true });
      }
     
    }
  };

  scrollToViewHandler = () =>{
    this.state.bodyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    if (this.props.inputInFocus!==undefined){
      this.props.inputInFocus(true)
    }
  }


  searchInputHandler = async event => {
     let string = event.target.value;
    if(string.length===1){
      this.props.detectInputLanguage(string)
    } 
    if (string===""){
      await this.setState({searchInput:""})
      this.backdropHandler()
      return;
    }
    if (event.keyCode===38||event.keyCode===40){
      event.preventDefault()
      event.stopPropagation()
      if (this.state.showSuggestions){
        if (event.keyCode===38){
          if (this.state.focusTagIndex!==-1){
            this.setState({focusTagIndex:this.state.focusTagIndex-1})
          }
          return;
         
        }
        if (event.keyCode===40){
          if (this.state.focusTagIndex!==this.state.searchSuggestions.length-1){
            this.setState({focusTagIndex:this.state.focusTagIndex+1})
          }
          return;
         
        }
      }
    else{
        await this.setState({searchSuggestions:[...this.props.optionsList],showSuggestions:true})
        if (event.keyCode===38){
          if (this.state.focusTagIndex!==-1){
            this.setState({focusTagIndex:this.state.focusTagIndex-1})
          }
          return;
         
        }
        if (event.keyCode===40){
          if (this.state.focusTagIndex!==this.state.searchSuggestions.length-1){
            this.setState({focusTagIndex:this.state.focusTagIndex+1})
          }
          return;
         
        }
      }
      return;
    }
    else{
      if (event.keyCode === 27) {
        this.setState({
          searchInput: "",
          selectedOption: this.state.placeholder,
          showSuggestions: false,
          showBackdrop: false,
          searchSuggestions: []
        });
        return;
      }
      //In case user pressed 'enter' key, add the tag to experience tags array
      //Use the selectTagSuggestion function for that
      if (event.key === "Enter") {
        if (event.target.value === "") return;
        if (this.state.searchSuggestions.length > 0){
          this.selectSearchSuggestion(this.state.searchSuggestions[0]);
        }
        return;
      } else {
        //Get suggestions for user existing tags based in sub-input
        let searchSuggestions = this.getSuggestions(event.target.value);
        this.setState({ searchInput: event.target.value,inputChanged: true }); 
  
        //In case user entered some characters, the suggestions should be shown
        if (event.target.value != "" && searchSuggestions.length != 0) {
          this.setState({
            showSuggestions: true,
            showBackdrop: true,
            searchSuggestions: searchSuggestions
          });
  
          //If no suggetions exist based on entered value, don't show the suggestions
        } else if (string === "") {
          await this.setState({searchInput:""})
          this.backdropHandler()

        } else if (searchSuggestions.length ===0){
          this.setState({
            showBackdrop: false,
            searchSuggestions: []
          });
         /*  if (!this.props.onlyFromList){
            this.selectSearchSuggestion(this.state.searchInput)
          } */
        }
      }
    }
  };

  //Adding tag of (tagValue) to list of experience's tags
  //In case tag already in the list nothing will happen
  selectSearchSuggestion = (selectedValue) => {
    let option=selectedValue
    if (option === undefined) return;
    this.setState({     
      showSuggestions: false,
      showBackdrop: false,
      searchSuggestions: [],
      inputChanged: false
    });
    if (this.props.currentSelection===undefined || this.props.multiple){
      this.setState({ searchInput: ""})
    }
    this.props.updateSelectedOption(option);
    if (this.props.inputInFocus!==undefined){
      this.props.inputInFocus(false)
    }
  };

  //Getting tags suggestions from user existing tags
  //Using the value entered so far to filter tags list
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let searchTags = this.props.optionsList.filter(option=>option[this.props.inputLanguage]!==undefined)
    return inputLength === 0
      ? []
      : searchTags.filter(
          tag =>
            tag[this.props.inputLanguage].toLowerCase().includes(inputValue)
        );
  };

  onBlurHandler = () =>{
    setTimeout(() => {
      this.backdropHandler()
      if (this.props.inputInFocus!==undefined){
        this.props.inputInFocus(false)
      }
    }, 500);
    
  }

  backdropHandler = () => {
    this.setState({
      showBackdrop: false,
      showSuggestions: false,
      searchSuggestions:[]
    });
    if (this.state.inputChanged){
      if (this.props.onlyFromList){
        this.selectSearchSuggestion(this.state.searchSuggestions[0])
      }
      else{
        this.selectSearchSuggestion(this.state.searchInput)
      }
      
    }  
  };

  handleDropdown = async () =>{
    this.scrollToViewHandler()
    let optionsList=[];
    if (this.state.searchInput!==""&&this.state.showSearchInput){
    
      optionsList = this.getSuggestions(this.state.searchInput.trim());
     
      optionsList = await optionsList.sort((a, b) => {
        var keyA = a[this.props.inputLanguage];
        var keyB = b[this.props.inputLanguage];
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    }
    else{
     
      optionsList = await [...this.props.optionsList].sort((a, b) => {
        var keyA = a[this.props.userLanguage];
        var keyB = b[this.props.userLanguage];
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      this.setState({useUserLanguage:true})
    }
    
    await this.setState({
      showSuggestions: true,
      showBackdrop: true,
      searchSuggestions: optionsList
    });
  }

  renderSuggestions = () => {
    if (this.state.showSuggestions) {
      let suggestionsClass = "Suggestions"
      if (this.props.RTL){
          suggestionsClass = "SuggestionsRTL"
      }
      let language = this.props.inputLanguage
      if (this.state.useUserLanguage){
        language = this.props.userLanguage
      }
      if (this.state.searchSuggestions.length>0){
        return (
          <div className={classes[suggestionsClass]}>
            {this.state.searchSuggestions.map((option, index) => (
              <div className={classes.SearchValue}
                key={index}
                onClick={()=> this.selectSearchSuggestion(option)}>
                {option[language]}
              </div>
            ))}
            {this.props.noResultPlaceholder!==undefined?(
               <div className={classes.SearchValue}
                onClick={()=> this.selectSearchSuggestion(this.props.noResultPlaceholder)}>
                {this.props.noResultPlaceholder[language]}
             </div>
            ):null}
            
          </div>
        );
      }
      else if (this.props.noResultPlaceholder!==undefined){
        return(
          <div className={classes[suggestionsClass]}>
              <div className={classes.SearchValue}
                onClick={()=> this.selectSearchSuggestion(this.props.noResultPlaceholder)}>
                {this.props.noResultPlaceholder[language]}
              </div>
          </div>
        )
      }
      else{
        return(
          <div className={classes.SearchValueGrey}>
               {this.props.systemText.searchErrorMessages.title[this.props.userLanguage]}
              </div>
        )
      }  
      
    }
     
  };

  renderInputSection = () => {
    return (
      <div className={classes.InputSection} ref={this.state.bodyRef}>
        <div className={classes.SearchInputWrapper}>
          {this.renderSearchInput()}
          {this.renderSuggestions()}
        </div>
      </div>
    );
  };

  renderSearchInput = () => {
    let inputRequired = ""
    if (this.props.valueRequired){
      inputRequired = "ValueRequired"
    }
    let boldInput = ""
    if (this.props.bold){
      boldInput = "BoldInput"
    }
    let dropdownButtonClass = "DropdownButton"
    if (this.props.RTL){
      dropdownButtonClass = "DropdownButtonRTL"
    }
    if (this.state.showSuggestions) {
      return (
        <div className={[classes.SearchInput,classes[inputRequired],classes[boldInput]].join(" ")}>
          <input
            className={classes.InputText}
            type="text"
            placeholder={this.props.placeholder}
            onChange={this.searchInputHandler}
            onKeyDown={this.searchInputHandler}
            onKeyPress={this.searchInputHandler}
            value={this.state.searchInput}
            onBlur = {this.onBlurHandler}
          
          />
          <img
            className={classes[dropdownButtonClass]}
            src={collapseIcon}
            alt=""
            onClick={() => {
              this.setState({
                showSuggestions: false,
                showBackdrop: false,
              });
            }}
          />
        </div>
      );
    } else {
      return (
        <div className={[classes.SearchInput,classes[inputRequired]].join(" ")}>
          <input
            className={classes.InputText}
            type="text"
            placeholder={this.props.placeholder}
            onChange={this.searchInputHandler}
            onKeyDown={this.searchInputHandler}
            onKeyPress={this.searchInputHandler}
            value={this.state.searchInput}
            onBlur = {this.onBlurHandler}
            onFocus={this.scrollToViewHandler}
          />
          <img
            className={classes[dropdownButtonClass]}
            alt=""
            src={dropdownIcon}
            onClick={this.handleDropdown}
          />
        </div>
      );
    }
  };

  renderErrorMessage = () =>{
    if (this.state.errorMessage!==""){
    return (<div className={classes.ErrorMessage}>{this.state.errorMessage}</div>)
    }
  }

  render() {
    let bodyRTL = ""
    if (this.props.RTL){
      bodyRTL = "BodyRTL"
    }
    return (
      <div style={{ width: "100%" }}>
        <TransparentBackdrop
          show={this.state.showBackdrop}
          clicked={this.backdropHandler}
        />
        <div className={[classes.Body,classes[bodyRTL]].join(" ")}>
          {this.renderInputSection()}
          {this.renderErrorMessage()}
        </div>
      </div>
    );
  }
}
export default OptionSelection;
