import React, { Component } from "react";
import classes from "./PlanDayObject.module.css";

import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"
import PlanDayItem from "./PlanDayItem";
import Toolkit from "../../UI/Toolbar/Toolkit"


import { ReactSortable, MultiDrag } from "react-sortablejs";
import { isMobile } from "react-device-detect";

let plusIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPlusIconSquare.png?alt=media&token=65fcb735-831b-435c-8fbf-06538e6118ca"
let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"


class PlanDayObject extends Component {
    state={
        open:true,
        showAddMenu:false,
        showTransparentBackdrop:false,
        dayItems:this.props.dayItems,
        scrollerDayRef:React.createRef(),
        showAddAccommodation:false
    }

    componentDidMount = () =>{
        let itemsIDs = [];
        let hasAccommodation = false
        let canHaveAccommodation = false
        if (this.props.index!==this.props.numberOfPlanDays&&this.props.index!==0&&this.props.numberOfPlanDays>2&&this.props.index!==this.props.numberOfPlanDays-1){
            canHaveAccommodation = true
        }
        for (let i=0 ; i<this.props.dayItems.length ; i++){
            let id =""
            if (this.props.dayItems[i].type==="place")
            {
                id=  this.props.dayItems[i].item.placeID}
            if (this.props.dayItems[i].type==="accommodation")
            {
                id=  this.props.dayItems[i].item.placeID
                //hasAccommodation=true
            }
            if (this.props.dayItems[i].type==="note")
            {
                id= this.props.dayItems[i].item.noteID}
            if (this.props.dayItems[i].type==="transport")
            {
                id= this.props.dayItems[i].item.transportID}
            itemsIDs[i]=id;

        }   
        this.setState({canHaveAccommodation:canHaveAccommodation,hasAccommodation:hasAccommodation})
        this.setState({itemsIDs:itemsIDs}) 
        if ((this.props.ongoingDay+1)===(this.props.index)){
            if (this.props.calendarDay){
                setTimeout(() => {
                    this.state.scrollerDayRef.current.scrollIntoView({
                        behavior: 'smooth',
                        inline: 'start',
                      });
                   }, 500);
            }
            else{
                setTimeout(() => {
                    this.state.scrollerDayRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                   }, 500);
            }
          
            
        }
    }

    componentDidUpdate = prevProps =>{
        if (this.state.dayItems.length!==this.props.dayItems.length||this.props.planLastModified!==prevProps.planLastModified){
            this.setState({dayItems:this.props.dayItems})
            let itemsIDs = [];
            for (let i=0 ; i<this.props.dayItems.length ; i++){
                let id =""
                if (this.props.dayItems[i].type==="place")
                {
                    id=  this.props.dayItems[i].item.placeID}
                if (this.props.dayItems[i].type==="note")
                {
                    id= this.props.dayItems[i].item.noteID}
                if (this.props.dayItems[i].type==="transport")
                {
                    id= this.props.dayItems[i].item.transportID}
                itemsIDs[i]=id;
    
            }   
            this.setState({itemsIDs:itemsIDs})  
        }
    }

    scrollToViewOnMouseEnter = () =>{
        if (this.props.calendarDay){
            this.state.scrollerDayRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
              });
        }
        else{
            this.state.scrollerDayRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
        }
    }


    discardBackdrop = () =>{
        this.setState({ showAddMenu:false,
            showTransparentBackdrop:false})
    }

    openDayHandler = () =>{
        if (!this.props.calendarDay){
            let activityKey = "TRIP_DAY_COLLAPSE"
            if (this.state.open){
                activityKey = "TRIP_DAY_EXPAND"
            }
            if (this.props.scrollerView){
                this.setState({open:!this.state.open})
            }
            if (this.props.writeAcitivityLog!==undefined){
                this.props.writeAcitivityLog({
                    activityKey:activityKey,
                    userScreen:"VIEW_TRIP"
                })
            }
        }
    }

    renderDaySelectedIcon = () =>{
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
        if (this.props.showDaySelection){
            if (this.props.selected){
                return(
                    <img src={checkedBox} alt="" className={classes[checkIconClass]} 
                    onClick={(e)=>{ e.stopPropagation();this.props.daySelectHandler()
                    this.props.writeAcitivityLog({
                        activityKey:"TRIP_DAY_UNSELECT",
                        userScreen:"VIEW_TRIP"
                        })
                    }}/>
                )          
            }
            else{
                return(
                    <div className={classes[uncheckBoxClass]}
                     onClick={(e)=>{ e.stopPropagation();this.props.daySelectHandler()
                    this.props.writeAcitivityLog({
                        activityKey:"TRIP_DAY_SELECT",
                        userScreen:"VIEW_TRIP"
                        })
                    }}/>
                )     
            }
        }
    }
    
    renderDayOpenIcon = () =>{
        if (this.props.scrollerView){
            let detailIconClass="DetailIcon"
            if (this.props.RTL){
                detailIconClass="DetailIconRTL"
            }
            if ((this.props.ongoingDay+1)===(this.props.index)&&!this.props.showSuggestedTrips){
                detailIconClass = "DetailIconWhite"
                if (this.props.RTL){
                    detailIconClass="DetailIconWhiteRTL"
                }
            }
            if (this.state.open){
                return(
                    <div className={classes[detailIconClass]}>
                        {"[ - ]"}
                    </div>

                )          
            }
            else{
                return(
                    <div className={classes[detailIconClass]}>
                    {"[ + ]"}
                </div>
                  
                )     
            }
        }
    }

    renderTitle = () =>{
        
        let name = this.props.dayName;
        let titleClass = "Title"
        if (name==="Interesting"){
            name=this.props.systemText.plan.viewPlan.planning.interesting[this.props.userLanguage];
        }
        else{
            name= this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage] +" "+ this.props.dayName.substr(this.props.dayName.indexOf(' ')+1);
        }
        if ((this.props.ongoingDay+1)===(this.props.index)&&!this.props.showSuggestedTrips){
            titleClass = "OngoingTitle"
            name = "Today " +name 
        }
        let titleHover=""
        if (!this.props.calendarDay){
            titleHover = "TitleHover"
        }
        let numberOfPlaces = this.props.dayItems.filter(item=>item.type==="place").length
        
        return(
            <div className={[classes[titleClass],classes[titleHover]].join(" ")} onClick={this.openDayHandler} >
                {this.renderDaySelectedIcon()}
                <div className={classes.Name}>{name+" ("+numberOfPlaces+")"}</div>
                {this.renderDayOpenIcon()}
            </div>
        )
    }



    updateItemsOrder = async (dayItems)=>{
       
        await this.setState({dayItems: dayItems})
        let itemsIDs = [];
        for (let i=0 ; i<dayItems.length ; i++){
            let id =""
            if (dayItems[i].type==="place")
            {
                id=  dayItems[i].item.placeID}
            if (dayItems[i].type==="note")
            {
                id= dayItems[i].item.noteID}
            if (dayItems[i].type==="transport")
            {
                id= dayItems[i].item.transportID}
            itemsIDs[i]=id;

        }   
        if (JSON.stringify(this.state.itemsIDs)!==JSON.stringify(itemsIDs)){
        this.setState({itemsIDs:itemsIDs})  
        this.props.updateDayOrder(this.props.index,dayItems) 
      } 
        
    }

    renderDayContent= () =>{
        let delayOnTouchStart=false;
        let delay = 0;
        if (isMobile){
            delayOnTouchStart=true
            delay=500;
        }
        if (this.state.open && this.props.dayItems!==undefined &&this.state.itemsIDs!==undefined){
            let dayContentClass = "DayContent";
            if (this.props.calendarDay){
                dayContentClass="CalendarDayContent"
            }
            return(
                <div className={classes[dayContentClass]}  onMouseEnter={this.scrollToViewOnMouseEnter}>
                     <ReactSortable
                     animation={200}
                      delayOnTouchStart={delayOnTouchStart}
                     delay={delay}
                        list={this.state.dayItems}
                        setList={(newState) => this.updateItemsOrder(newState)}
                    >
                    {this.state.dayItems.map((object,index) => {
                        let zIndex=0;
                        if (object.type==="note"){
                            if (object.item.noteID===this.props.openModeNoteId){
                                zIndex=1;
                            }
                        }
                      return( <div style={{width:"100%",height:"100%",position:"relative",zIndex:zIndex}} key = {this.state.itemsIDs[index]}>
                       <PlanDayItem
                                isEditor={this.props.isEditor}
                                addToPlan={this.props.addToPlan}
                                editorsNumber={this.props.editorsNumber}
                                externalAdd={this.props.externalAdd}
                                updateColor={(colorNumber)=>{this.props.updateColor(colorNumber,index)}}
                                updatePlaceTime={(time)=>{this.props.updatePlaceTime(time,index)}}
                                index={index}
                                dayIndex={this.props.index}
                                hoverDayIndex = {this.props.hoverDayIndex}
                                object={object}
                                dayNames={this.props.dayNames}
                                dayName={this.props.dayName}
                                viewHandler={()=>{this.props.viewHandler(index);}}
                               
                                clickedMarkerPlaceID={this.props.clickedMarkerPlaceID}
                                removeFromPlan={this.props.removeFromPlan}
                                voteHandler={this.props.voteHandler}
                                moveDayHandler={(newDayIndex)=>{this.props.moveDayHandler(index,newDayIndex,object.type)}}
                                hoverHandler={index=>{if (index===-1) return this.props.hoverHandler(-1); else return this.props.hoverHandler(index,object.item.placeID)}}
                                
                                locationsTranslator = {this.props.locationsTranslator}
                                categoriesTranslator = {this.props.categoriesTranslator}

                                openModeNoteId = {this.props.openModeNoteId}
                                newNoteID = {this.props.newNoteID}
                                editNoteID = {this.props.editNoteID}
                                noteOpenHandler={value=>{this.props.noteOpenHandler(index,value)}}
                                updateNote = {(note)=>{this.props.updateNote(note,index)}}

                                writeAcitivityLog = {this.props.writeAcitivityLog}
                                
                                RTL = {this.props.RTL}
                                user={this.props.user}
                                userLanguage={this.props.userLanguage}
                                systemText={this.props.systemText}
                        />   
                        </div>);
                       
                     })}
                    </ReactSortable>
            </div>
            )
        }
       
    }

    addItem = () =>{
        if (this.props.externalAdd){
            this.props.addPlaceToDay(this.props.index)
            this.props.writeAcitivityLog({
                activityKey:"ADDTOPLAN_ADD_PLACE",
                userScreen:"ADDTOPLAN"
              })
        }
        else{
            this.setState({showTransparentBackdrop:true,showAddMenu:true})
            this.props.writeAcitivityLog({
                activityKey:"TRIP_DAY_ADD_BUTTON",
                userScreen:"VIEW_TRIP"
              })
        }
    }

    renderAddButton = () => {
        if (this.props.isEditor){
            return(
                <div className={classes.AddButton}>
                    <img src={plusIcon} alt="" className={classes.PlusIcon} onClick={this.addItem}/>
                </div>
            )
        }
        else if (this.props.externalAdd)
            return(
                <div className={classes.AddButton} onClick={this.addItem}>
                {this.props.systemText.extraModals.addToPlan.addHereButton[this.props.userLanguage]}
                </div>
            )
        
    }
  

    renderAddMenu = () =>{
        if (this.state.showAddMenu){
            return(
                <div className={classes.AddMenu}>
                    {this.props.planOverviewMode?(
                        <div className={classes.MenuItem}
                            onClick={()=>{this.props.overviewAddPlaceHandler();this.setState({showAddMenu:false,showTransparentBackdrop:false})
                            this.props.writeAcitivityLog({
                                activityKey:"TRIP_ADD_PLACE_REDIRECT",
                                userScreen:"VIEW_TRIP"
                              })
                            }}>
                            {this.props.systemText.plan.viewPlan.planning.addPlace[this.props.userLanguage]}</div>
                    
                    ):null}
                     {!this.state.hasAccommodation&&this.state.canHaveAccommodation?(
                        <div className={classes.MenuItem}
                            onClick={()=>{this.props.addAccommodationHandler(this.props.index);this.setState({showAddMenu:false,showTransparentBackdrop:false})
                            this.props.writeAcitivityLog({
                                activityKey:"TRIP_ADD_ACCOMMODATION",
                                userScreen:"VIEW_TRIP"
                              })
                            }}>
                            {this.props.systemText.plan.viewPlan.planning.addAccommodation[this.props.userLanguage]}</div>
                    
                    ):null}
                    <div className={classes.MenuItem}
                     onClick={()=>{this.props.newNoteHandler();this.setState({showAddMenu:false,showTransparentBackdrop:false})
                     this.props.writeAcitivityLog({
                        activityKey:"TRIP_NOTE_NEW",
                        userScreen:"VIEW_TRIP"
                      })
                     }}>
                         {this.props.systemText.plan.viewPlan.planning.createNote[this.props.userLanguage]}</div>
                    <div className={classes.MenuItem}
                     onClick={()=>{this.props.newTransportHandler();this.setState({showAddMenu:false,showTransparentBackdrop:false})
                     this.props.writeAcitivityLog({
                        activityKey:"TRIP_TRANSPORT_NEW",
                        userScreen:"VIEW_TRIP"
                      })
                     }} >
                           {this.props.systemText.plan.viewPlan.planning.createTransport[this.props.userLanguage]}</div>
                    
                </div>
            )
        }
        
    }

    

    render(){
        let RTL = ""
        if (this.props.RTL){
            RTL = "RTL"
        }
        return(
        <div className={[classes.Body,classes[RTL]].join(" ")} ref={this.state.scrollerDayRef}>
            <TransparentBackdrop show = {this.state.showTransparentBackdrop} clicked = {this.discardBackdrop}/>
            {this.renderTitle()}
            {this.renderDayContent()}
            {this.renderAddButton()}
            {this.renderAddMenu()}
        </div>)
    }
}

export default PlanDayObject;