import React, { Component } from "react";

import classes from "./UsersLocator.module.css";

import Tab from "../../UI/Tabs/Tab"
import UserItem from "./UserItem"
import UsersSearch from "./UsersSearch";

import ConfirmModal from "../../UI/Modals/ConfirmationModal";
import ModalTopSection from "../../UI/Misc/ModalTopSection";


class UsersLocator extends Component {
  state = {
    selectedSection: "My Friends",
    selectionOptions: ["SelectedMobileWide", "RegularMobileWide", "RegularMobileWide"],
    confirmMode: false,
    searchingFriend: false,
  };

  updateSearchingFriend = (value)=>{
    this.setState({searchingFriend:value})
  }

  updateFollowing = value =>{
    this.setState({searchingFollowing:value})
  }
  removeFriend = (user,message) =>{
    this.setState({
      confirmMessage: message,
      potentialRemoveFriend:user,
      confirmMode: true,
      showBackdrop: true,
      optionsTools: false,
      removeType:"removeFriend"
    });
  }

  viewHandler = (user) =>{
    if (this.props.viewUserProfileHandler!==undefined){
      
      this.props.viewUserProfileHandler(user)

      this.props.writeAcitivityLog({
        activityKey:"VIEW_USER_PROFILE",
        userScreen:"FRIENDS"
      })
    }
  }

  confirmDeleteHandler = () => {
    this.setState({
      confirmMode: false,
      showBackdrop: false
    });
    if (this.state.removeType==="removeFriend"){
      this.props.removeFriend(this.state.potentialRemoveFriend);
    }
    else if(this.state.removeType==="stopFollow"){
      this.props.stopFollow(this.state.potentialStopFollowUser);
    }
  };
  
  discardDeleteHandler = () => {
    this.setState({
      confirmMode: false,
      showBackdrop: false
    });
  };

  stopFollow = (user,message) =>{
    this.setState({
      confirmMessage: message,
      potentialStopFollowUser:user,
      confirmMode: true,
      showBackdrop: true,
      optionsTools: false,
      removeType:"stopFollow"
    });
  }

  renderSelection = () =>{
    return (
        <div className={classes.ContentSelections}>
          <Tab
            tabType={this.state.selectionOptions[0]}
            text={this.props.systemText.users.myFriendsTab[this.props.userLanguage]}
            clicked={() => {
              this.setState({
                selectedSection: "My Friends",
                selectionOptions: ["SelectedMobileWide", "RegularMobileWide", "RegularMobileWide"],
                searchingFriend:false,
              });
              if (this.refs.userSearch!==undefined){
                this.refs.userSearch.resetInput()
              }
              this.props.writeAcitivityLog({
                activityKey:"FRIENDS_MYFRIENDS_BUTTON",
                userScreen:"FRIENDS"
              })
            }}
          />
           <div className={classes.TabSeperator}/>
          <Tab
            tabType={this.state.selectionOptions[1]}
            text={this.props.systemText.users.usersIFollow[this.props.userLanguage]}
            clicked={() => {
              this.setState({
                selectedSection: "My Following",
                selectionOptions: ["RegularMobileWide", "SelectedMobileWide", "RegularMobileWide"],
                searchingFriend:false,
              });
              if (this.refs.userSearch!==undefined){
                this.refs.userSearch.resetInput()
              }
              this.props.writeAcitivityLog({
                activityKey:"FRIENDS_FOLLOWING_BUTTON",
                userScreen:"FRIENDS"
              })
            }}
          />  
           <div className={classes.TabSeperator}/>   
          <Tab
            tabType={this.state.selectionOptions[2]}
            text={this.props.systemText.users.findFriendTab[this.props.userLanguage]}
            clicked={() => {
              this.setState({
                selectedSection: "Find Friends",
                selectionOptions: ["RegularMobileWide", "RegularMobileWide", "SelectedMobileWide"],
                searchingFriend:false,
              });
              this.props.writeAcitivityLog({
                activityKey:"FRIENDS_SEARCH_BUTTON",
                userScreen:"FRIENDS"
              })
            }}
          />                    
        </div>
      );
  }

  renderBody = () =>{
      //My Friends
      //Following
      //Find Friends (include waiting for approval)
      switch(this.state.selectedSection){
        case "My Friends":{
            return(<div>{this.renderMyFriends()}</div>);
            break;
        }
        case "My Following":{
          return(<div>{this.renderIFollowing()}</div>);
          break;
      }
        case "Find Friends":{
            return(<div>{this.renderFindFriends()}</div>);
            break;
        }
        default: return null;
    }
  }

  renderMyFriends = () =>{
    if (this.state.searchingFriend){
      return(
        <div className={classes.UsersSection}>
          <UsersSearch
                placeholder={this.props.systemText.users.findFriend[this.props.userLanguage]}
                type="myFriends"
                usersList = {this.props.myFriends}
                existingList={this.props.myFriends}
                friendRequest={()=>{}}
                removeFriend={this.removeFriend}
                acceptFriendship={this.props.acceptFriendship}
                updateSearchingFriend={this.updateSearchingFriend}
                viewHandler = {this.viewHandler}

                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
            />
            </div>
            );
    }
    else{
      return (
        <div className={classes.UsersSection}>
          <UsersSearch
                placeholder={this.props.systemText.users.findFriend[this.props.userLanguage]}
                type="myFriends"
                usersList = {this.props.myFriends}
                existingList={this.props.myFriends}
                friendRequest={()=>{}}
                removeFriend={this.removeFriend}
                acceptFriendship={this.props.acceptFriendship}
                updateSearchingFriend={this.updateSearchingFriend}
                viewHandler = {this.viewHandler}

                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
            />
            {this.props.myFriends.map((user, index) => (
               
                <UserItem key={user.userID} user={user} type="myFriends" 
                select={()=>{return null}}
                removeFriend={this.removeFriend}
                acceptFriendship={this.props.acceptFriendship}
                viewHandler = {this.viewHandler}

                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
                />
            ))} 
          </div>
      )
    }
  }

  renderIFollowing = () =>{
    if (this.state.searchingFollowing){
      return(
        <div className={classes.UsersSection}>
          <UsersSearch
                placeholder={this.props.systemText.users.findFollowing[this.props.userLanguage]}
                type="followingList" 
                usersList = {this.props.usersIFollow}
                existingList={this.props.usersIFollow}
                friendRequest={()=>{}}
                stopFollow = {this.stopFollow}
                updateSearchingFriend={this.updateFollowing}
                viewHandler = {this.viewHandler}

                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
            />
            </div>
            );
    }
    else{
      return (
        <div className={classes.UsersSection}>
          <UsersSearch
                placeholder={this.props.systemText.users.findFollowing[this.props.userLanguage]}
                type="followingList" 
                usersList = {this.props.usersIFollow}
                existingList={this.props.usersIFollow}
                friendRequest={()=>{}}
                stopFollow = {this.stopFollow}
                updateSearchingFriend={this.updateFollowing}
                viewHandler = {this.viewHandler}

                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
            />
            {this.props.usersIFollow.map((user, index) => (
               
                <UserItem key={user.userID} user={user} type="followingList" 
                select={()=>{return null}}
                stopFollow = {this.stopFollow}
                viewHandler = {this.viewHandler}

                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
                />
            ))} 
          </div>
      )
    }
  }


  renderFindFriends=()=>{
      return(
        <div className={classes.UsersSection}>
            <UsersSearch
                ref="userSearch"
                placeholder={this.props.systemText.users.searchNewFriend[this.props.userLanguage]}
                type="searchFriends" 
                usersList = {this.props.allUsers.filter(user =>{return user.userID!==this.props.user.userID} )}
                usersListFollow = {this.props.usersIFollow}
                existingList={this.props.myFriends}
                friendRequest={this.props.friendRequest}
                followUser = {this.props.followUser}
                removeFriend={this.removeFriend}
                stopFollow = {this.stopFollow}
                acceptFriendship={this.props.acceptFriendship}
                updateSearchingFriend={()=>{return null}}
                viewHandler = {this.viewHandler}

                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
            />
          </div>
        )
  }

  discardHandler = () =>{
    this.props.discard()
    this.props.writeAcitivityLog({
      activityKey:"FRIENDS_DISCARD",
      userScreen:"FRIENDS"
    })
  }

  
  renderTopSection = () => {
    let title = this.props.systemText.users.friendsTitle[this.props.userLanguage];
    return (
      <ModalTopSection
        title={title}
        discard={this.discardHandler}
        saveButton={false}
        RTL = {this.props.RTL}
        modalView={true}
      />
    );
  };

  renderConfirmModal = () => {
    if (this.state.confirmMode) {
      return (
        <ConfirmModal
            header={this.props.systemText.extraModals.confirmationModal.confirmTitle[this.props.userLanguage]}
            show={this.state.confirmMode}
            primaryAction={this.confirmDeleteHandler}
            secondaryAction={this.discardDeleteHandler}
            primaryStyle = {"DeleteButton"}
            secondaryStyle = {"DiscardButton"}
            message={this.state.confirmMessage}
            primaryBtn={this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage]}
            secondaryBtn={this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage]}
          />
      );
    }
  };
  

  render() {
    let RTL = ""
    if (this.props.RTL){
      RTL = "RTL"
    }
    return (
        <div className={[classes.Modal,classes[RTL]].join(" ")}>
        {this.renderConfirmModal()}
        {this.renderTopSection()}
        <div className={classes.Body}>
            {this.renderSelection()}
            {this.renderBody()}</div>
      </div>
    );
  }
}
export default UsersLocator;
