import React, { Component } from "react";
import classes from "./ContactInfo.module.css";

/* import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' */

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

let phoneIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FPhoneIcon.png?alt=media&token=5a4c9e74-a37e-4ab4-b65e-3fecb4e219d7";
let facebookIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFacebookIcon.png?alt=media&token=69773ce6-12e9-4f9a-a136-df9d0f153433"
let instagramIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FInstagramIcon.png?alt=media&token=2714ec72-d1e6-4a84-8044-9baf80619a48"
let twitterIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FTwitterIcon.png?alt=media&token=c74c2fef-0d79-43fd-a666-db6526472731"
let websiteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FWebsiteIcon.png?alt=media&token=ff1f4e34-a55c-4ec6-b95b-fadccb109327"
let wazeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FWazeIcon.png?alt=media&token=4c088378-33c8-4d04-8c27-5872760f5b31"
let googleMapsIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FGoogleMapsIcon.png?alt=media&token=8268d47c-2c90-41b6-9b09-6c1c8b0ea75d"
let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"


class ContactInfo extends Component {

    state={
        is1800Number: false,
        phoneNumber: this.props.phoneNumber
    }
   /*  componentWillMount = () =>{ 
    if (this.props.phoneNumber!==undefined){
        this.setState({phoneNumber:this.props.phoneNumber})
    }  
    if (this.props.facebookLink!==undefined){
        this.setState({facebookLink:this.props.facebookLink})
    }  
    if (this.props.instagramLink!==undefined){
        this.setState({instagramLink:this.props.instagramLink})
    } 
    if (this.props.twitterLink!==undefined){
        this.setState({twitterLink:this.props.twitterLink})
    } 
    if (this.props.officialWebsiteLink!==undefined){
        this.setState({officialWebsiteLink:this.props.officialWebsiteLink})
    }      
}
 */
renderPhone=()=>{
    let contactIcon = "ContactIcon"
    if (this.props.RTL){
        contactIcon = "ContactIconRTL"
    }
        return(
        <div className={classes.PhoneSection}>

            {this.state.is1800Number?(
                <div className={classes.Phone}>
                    <img src={phoneIcon} alt="" className={classes[contactIcon]}/>
                    <input
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    placeholder={this.props.systemText.place.createPlace.specialPhoneNumberPlaceholder[this.props.userLanguage]}
                    value={this.state.phoneNumber}
                    onChange={(e)=>{this.setState({phoneNumber:e.target.value})}}
                    onBlur={()=>{this.props.updatePhoneNumber(this.state.phoneNumber)}}
                    className={classes.PhoneContainer}
                    />
                </div>
            ):(
                <div className={classes.Phone}>
                    <img src={phoneIcon} alt="" className={classes[contactIcon]}/>
                    {/* <PhoneInput
                    containerClass = {classes.PhoneContainer}
                        containerStyle={{width:'auto'}}
                        placeholder= {this.props.systemText.place.createPlace.contactPhonePlaceholder[this.props.userLanguage]}
                        value={this.props.phoneNumber}
                        onChange={(phone)=>{this.setState({phoneNumber:phone})}}
                        onBlur={()=>{this.props.updatePhoneNumber(this.state.phoneNumber)}}
                        enableLongNumbers={true}
                        /> */}
                        
                        <PhoneInput
                         inputStyle={{
                            background: "lightblue"
                          }}
                        placeholder={this.props.systemText.place.createPlace.contactPhonePlaceholder[this.props.userLanguage]}
                        value={this.props.phoneNumber}
                        onChange={(phone)=>{this.setState({phoneNumber:phone})}}
                        onBlur={()=>{this.props.updatePhoneNumber(this.state.phoneNumber)}}
                        className={classes.PhoneContainer}
                        />
                 </div>
            )}
            {this.render1800TelOption()}
        </div>)
}

render1800TelOption = () =>{
    let checkIconClass = "CheckIcon"
    let uncheckBoxClass = "UncheckBox"
    if (this.props.RTL){
      checkIconClass = "CheckIconRTL"
      uncheckBoxClass = "UncheckBoxRTL"
    }
    if (this.state.is1800Number){
        return(
            <div className={classes.PhoneOption}>
                 <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.setState({is1800Number:false})}}/>
                 {this.props.systemText.place.createPlace.specialPhoneNumber[this.props.userLanguage]}
            </div>
        )
    }
    else{
         return(
            <div className={classes.PhoneOption}>
                <div className={classes[uncheckBoxClass]} onClick={()=>{this.setState({is1800Number:true})}}/>
                {this.props.systemText.place.createPlace.specialPhoneNumber[this.props.userLanguage]}
            </div>
        )
    }
}

renderFacebook = () =>{
    let contactIcon = "ContactIcon"
    if (this.props.RTL){
        contactIcon = "ContactIconRTL"
    }
    return(
        <div className={classes.Social}>
            <img src={facebookIcon} alt="" className={classes[contactIcon]}/>
            <input type="text" value={this.props.facebookLink} onChange={this.props.updateFacebookLink} 
            className={classes.SocialLinkInput} 
            placeholder= {this.props.systemText.place.createPlace.contactFacebookPlaceholder[this.props.userLanguage]}
            />
        </div>
    )
}

renderInstagram = () =>{
    let contactIcon = "ContactIcon"
    if (this.props.RTL){
        contactIcon = "ContactIconRTL"
    }
    return(
        <div className={classes.Social}>
            <img src={instagramIcon} alt="" className={classes[contactIcon]}/>
            <input type="text" value={this.props.instagramLink} onChange={this.props.updateInstagramLink} 
            className={classes.SocialLinkInput} 
            placeholder= {this.props.systemText.place.createPlace.contactInstagramPlaceholder[this.props.userLanguage]}
            />
        </div>
    )
}

renderTwitter = () =>{
    let contactIcon = "ContactIcon"
    if (this.props.RTL){
        contactIcon = "ContactIconRTL"
    }
    return(
        <div className={classes.Social}>
            <img src={twitterIcon} alt="" className={classes[contactIcon]}/>
            <input type="text" value={this.props.twitterLink} onChange={this.props.updateTwitterLink}
            className={classes.SocialLinkInput} 
            placeholder= {this.props.systemText.place.createPlace.contactTwitterPlaceholder[this.props.userLanguage]}
            />
        </div>
    )
}

renderOfficialWebsite = () =>{
    let contactIcon = "ContactIcon"
    if (this.props.RTL){
        contactIcon = "ContactIconRTL"
    }
    return(
        <div className={classes.Social}>
            <img src={websiteIcon} alt="" className={classes[contactIcon]}/>
            <input type="text" value={this.props.officialWebsiteLink} onChange={this.props.updateWebsiteLink}
            className={classes.SocialLinkInput} 
            placeholder= {this.props.systemText.place.createPlace.websitePlaceholder[this.props.userLanguage]}
            />
        </div>
    )
}

/* renderWaze = () =>{
    let contactIcon = "ContactIcon"
    if (this.props.RTL){
        contactIcon = "ContactIconRTL"
    }
    return(
        <div className={classes.Social}>
            <img src={wazeIcon} alt="" className={classes[contactIcon]}/>
            <input type="text" value={this.props.wazeLink} onChange={this.props.updateWazeLink}
            className={classes.SocialLinkInput} 
            placeholder= {this.props.systemText.place.createPlace.wazePlaceholder[this.props.userLanguage]}
            />
        </div>
    )
} */

/* renderGoogleMaps = () =>{
    let contactIcon = "ContactIcon"
    if (this.props.RTL){
        contactIcon = "ContactIconRTL"
    }
    return(
        <div className={classes.Social}>
            <img src={googleMapsIcon} alt="" className={classes[contactIcon]}/>
            <input type="text" value={this.props.googleMapsLink} onChange={this.props.updateGoogleMapsLink}
            className={classes.SocialLinkInput} 
            placeholder= {this.props.systemText.place.createPlace.googleMapsPlaceholder[this.props.userLanguage]}
            />
        </div>
    )
} */

  render () {
      return(<div className={classes.Body}>
          {this.renderPhone()}
          {this.renderOfficialWebsite()}
          {this.renderFacebook()}
          {this.renderInstagram()}
          {this.renderTwitter()}
        {/*   {this.renderWaze()} */}
         {/*  {this.renderGoogleMaps()} */}
      </div>)
  }
}
export default ContactInfo; 