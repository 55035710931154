import React, { Component } from "react";

import classes from "./SearchGoogle.module.css";


import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import ModalTopSection from "../../UI/Misc/ModalTopSection"

let closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"


class SearchGoogle extends Component {
  state = { address: "" };

  componentDidMount = () => {
    this.setState({ address: this.props.location.address });
  };

  componentDidUpdate = prevProps =>{
    if (this.props.location!==prevProps.location){
      this.setState({ address: this.props.location.address });
    }
  }


  setCurrentLocation = async () => {
    await navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        currentLocation: [
          { lat: position.coords.latitude, lng: position.coords.longitude }
        ],
        coordinates: [
          { lat: position.coords.latitude, lng: position.coords.longitude }
        ]
      });
    });
  };

  saveHandler = () => {
    let location = {
      address:  this.state.address,
      coordinates: this.state.coordinates[0],
      placeAddress: this.state.placeAddress,
      placeID: this.state.placeID,
      placeTags: this.state.placeTags,
      placeGeoCode: this.state.placeGeoCode
    };
    this.props.update(location);
    this.props.discard();
  };

  discardHandler = () => {
    this.props.discard();
  };

  handleSelect = async value => {
    const result = await geocodeByAddress(value);
    let placeAddress = "";
    if (result[0].formatted_address !== undefined)
      placeAddress = result[0].formatted_address;

    let placeID = "";
    if (result[0].place_id !== undefined) placeID = result[0].place_id;
    let placeTags = [];
    if (result[0].types !== undefined) placeTags = result[0].types;
    let placeGeoCode = "";
    if (result[0].plus_code !== undefined) placeGeoCode = result[0].plus_code;

    const latlng = await getLatLng(result[0]);
    //console.log(result);
    if (latlng !== undefined) {
      this.setState({
        address: value,
        coordinates: { lat: latlng.lat, lng: latlng.lng },
        placeAddress: placeAddress,
        placeID: placeID,
        placeTags: placeTags,
        placeGeoCode: placeGeoCode
      });
      let location = {
        address: value,
        coordinates: { lat: latlng.lat, lng: latlng.lng },
        placeAddress: placeAddress,
        placeID: placeID,
        placeTags: placeTags,
        placeGeoCode: placeGeoCode
      };
      this.props.update(location);
    }
  };

  currentLocationHandler = () => {
    this.setState({
      address: "",
      coordinates: this.state.currentLocation,
      placeAddress: "",
      placeID: "",
      placeTags: [],
      placeGeoCode: null
    });
  };


  renderTopSection = () => {
    if (!this.props.onlySearch) {
      let title = "Set Location";
      let buttonName = "Update";
      return (
        <ModalTopSection
          title={title}
          discard={this.discardHandler}
          saveReady={true}
          saveButtonText={buttonName}
          clicked={this.saveHandler}
          saveButton={true}
        />
      );
    }
  };

  renderLocationSearch = () => {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={address => {
          this.setState({ address: address });
        }}
        onSelect={this.handleSelect}
      >
        {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
            
          }) => (
            <div>
             {suggestions.length > 0 ? ( 
         
            <div className={classes.InputSectionWithSuggestions}>
              <input
                className={classes.AutocompleteInput}
                {...getInputProps({
                  placeholder: this.props.placeholder
                })}
              />
              <div>{loading ? <div>...loading</div> : null}</div>
            
                <div className={classes.SuggestionsSection}>
                  {suggestions.map(suggestion => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                    };
                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              
            </div>) : ( <div className={classes.InputSection}>
              <input
                className={classes.AutocompleteInput}
                {...getInputProps({
                  placeholder:this.props.placeholder
                })}
              /> </div>)} 
          </div>
          )}
        </PlacesAutocomplete>
    );
  };

  renderClearText = () =>{
    if (this.state.address!==""){
      return( <img src={closeIcon} alt="" className={classes.Icon}
      onClick={(e)=>{e.stopPropagation();this.setState({address:""})} } />)
    }
  }

  renderSearchInput = () => {
    if (this.state.showSuggestions) {
      return (
        <div className={classes.SearchInputWithSuggestions}>
          <input
            className={classes.InputText}
            type="text"
            placeholder={this.props.placeholderMessage}
            onChange={this.searchInputHandler}
            onKeyDown={this.searchInputHandler}
            onKeyPress={this.searchInputHandler}
            value={this.state.searchInput}
          />
        </div>
      );
    } else {
      return (
        <div className={classes.SearchInput}>
          <input
            className={classes.InputText}
            type="text"
            placeholder={this.props.placeholderMessage}
            onChange={this.searchInputHandler}
            onKeyDown={this.searchInputHandler}
            onKeyPress={this.searchInputHandler}
            value={this.state.searchInput}
          />
        </div>
      );
    }
  };

/*   renderClearIcon = () =>{
    if (this.state.address!==""){
      return(<img src={closeIcon} onClick={(e)=>{e.stopPropagation();this.setState({address:""})} }alt="" className={classes.ClearIcon}/>)
    }
  } */

  render() {
    let RTL = ""
    if (this.props.RTL){
        RTL = "RTL"
    }
    return(
      <div className={[classes.Body,classes[RTL]].join(" ")}>
        {this.renderLocationSearch()}
        {/*  {this.renderClearIcon()} */}
    </div>);
  }
}

export default SearchGoogle;
