import React, { Component } from "react";

import classes from "./PlanPlaceItem.module.css";

import {isMobile,isMobileOnly} from 'react-device-detect';
import Toolkit from "../../UI/Toolbar/Toolkit"
import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"

let inPlanIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAddToPlanSelected.png?alt=media&token=54fe67fd-e0f8-47ed-956c-3f4054063466";
let addToPlanIcon =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAddToPlanWhite.png?alt=media&token=789abb85-aef9-4a48-9a5c-be959f5ed002";
let durationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDurationIcon.png?alt=media&token=3e02db71-8c60-4a85-bba8-5a30ba9ce277"



class PlanPlaceItem extends Component {
    state={
      placeRef:React.createRef(),
      language:this.props.userLanguage
    }


    componentDidUpdate = (prevProps) =>{
      if (prevProps.clickedMarkerPlaceID!==this.props.clickedMarkerPlaceID){
        if (this.props.clickedMarkerPlaceID===this.props.placeID){
          if (this.props.clickedMarkerPlaceID!==this.state.hoverPlaceID){
            if (this.props.hoverDayIndex!==undefined){
              if (this.props.hoverDayIndex===this.props.dayIndex){
                this.state.placeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest'});
              }
            }
            else{
              this.state.placeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest'});
            }
           
          }
          
        }
      }
    }
    discard = () =>{
      this.setState({
        showDaysOptions: false,
        showBackdrop: false});
  }
      
    handleMouseOver=()=>{
      this.setState({isHovered:true,hoverPlaceID:this.props.placeID})
      setTimeout(() => {
        if ( this.props.hoverHandler!==undefined){
          if (this.state.isHovered){
            this.props.hoverHandler(this.props.index);
          }

        }
      }, 100); 
    }
  
    handleMouseExit=async ()=>{
      this.setState({isHovered:false,hoverPlaceID:-1})
      if ( this.props.hoverHandler!==undefined){
       
        this.props.hoverHandler(-1);
      }
    }
 
    viewHandler=()=>{
      if (this.props.board==="Discover"||this.props.board==="AddPlace"){
        this.props.viewHandler()
      }
      else if (this.props.viewType==="searchSuggestion"){
        this.props.select()
      }
      else if (this.props.viewType==="slimSuggestion"){
        this.props.select()
      }
      else if (this.props.viewType==="JourneyMode"){
        this.props.viewHandler();
      }
      else if (this.props.board ==="TripsGenerator"){
        this.props.viewHandler();
      }
    }

    renderPhoto = () =>{
      let imageUrl = "";
      let imagePreview ={url:""}
      //distinguish between slim place or full data place based on window that was clicked
      if (Array.isArray(this.props.imagePreview)){
          imagePreview = this.props.imagePreview[0]
      }
      else{
          imagePreview = this.props.imagePreview
      }
      if (imagePreview!==undefined){
       if (isMobileOnly){
          imageUrl=imagePreview.thumbnailImage.url
         }
        else{
          imageUrl=imagePreview.smallImage.url
        } 
      }
      //ADD ELSE TO USE PLACEHOLDER
      let imageClass = ""
      if (this.props.viewType==="JourneyMode"){
        imageClass = "JourneyPhoto"
        if (this.props.RTL){
          imageClass = "JourneyPhotoRTL"
        }
      }
      else if(this.props.board==="Discover"||this.props.addToPlan){
        imageClass = "DiscoverPhotoPreview"
        if (this.props.RTL){
          imageClass = "DiscoverPhotoPreviewRTL"
        }
      }
      else{
        imageClass = "PhotoPreview"
        if (this.props.RTL){
          imageClass = "PhotoPreviewRTL"
        }
      }
      return (
        <img
          src={imageUrl}
          alt=""
          className={classes[imageClass]}
        ></img>
      );
    }


      renderTools = () =>{
        if (this.props.board==="Discover"||this.props.board==="TripsGenerator"){
          let toolkitClass = "Toolkit"
          let toolsClass = "Tools"
          if (this.props.RTL){
            toolkitClass = "ToolkitRTL"
            toolsClass = "ToolsRTL"
          }
          if (this.props.board==="TripsGenerator"){
            toolsClass = "FloatingTools"
            if (this.props.RTL){
              toolsClass = "FloatingToolsRTL"
            }
          }
            return(
               <div className={classes[toolkitClass]}>
               {this.renderAddToPlanIcon()}
               <div className={classes[toolsClass]}>
                     {this.renderDaysOptions()}</div>    
               </div>)
          }       
      }

      renderDaysOptions = () =>{
        if (this.state.showDaysOptions){ 
          let toolKitStyle="Style2" 
          let tools = [];
          for (let i=0; i<this.props.dayNames.length; i++){
            let day = this.props.dayNames[i]
            let text = day
            if (i===0&&this.props.board==="TripsGenerator"){
              text = this.props.systemText.plan.viewPlan.planning.anyDay[this.props.userLanguage]
            }
            else if (i===0){
              text = this.props.systemText.plan.viewPlan.planning.interestigMobile[this.props.userLanguage]
            }
            else{
              text = this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage]+day.substring(day.indexOf(" "))
            }
            tools.push({
            action:  ()=>{
                this.props.addPlaceToPlan(i); this.setState({showBackdrop: false, showDaysOptions: false})},
            text: text,
            });
          }
          return(
            <Toolkit
            style={toolKitStyle}
            tools={tools}
            RTL = {this.props.RTL}
            bodyRTL={this.props.RTL}
            scrollable={true}
            />)
         }
      }

      renderAddToPlanIcon=()=>{
        if (this.props.planPlacesIDs.includes(this.props.placeID)){
            return(<img src={inPlanIcon} alt="" className={classes.ToolkitIcon} /* onClick={(e)=>{e.stopPropagation();if (this.props.board!=="TripsGenerator"){this.setState({showDaysOptions:true, showBackdrop:true})}}}  *//>)
        }
        else{
          return(<img src={addToPlanIcon} alt="" className={classes.ToolkitIcon} onClick={(e)=>{e.stopPropagation();this.props.addPlaceToPlan()/* this.setState({showDaysOptions:true, showBackdrop:true}) */}} />)
        }
          
      }

      renderDuration = () =>{
        let iconClass = "DurationIcon"
        let durationClass = "Duration"
        if (this.props.RTL){
            iconClass = "DurationIcon"
            durationClass = "DurationRTL"
        }
       if(this.props.placeCharacteristics!==undefined){
          if (this.props.placeCharacteristics.durationValue!==undefined){
            if (this.props.placeCharacteristics.durationUnits==="days"){
                if (this.props.placeCharacteristics.durationValue===0.5){
                    return(
                        <div className={classes[durationClass]}>
                            <img src={durationIcon} alt="" className={classes[iconClass]}/>
                            {this.props.systemText.placeCharacteristics.trailCharacteristics.halfDay[this.props.userLanguage]}
                        </div>
                    )
                }
                else if (this.props.placeCharacteristics.durationValue===1){
                    return(
                        <div className={classes[durationClass]}>
                            <img src={durationIcon} alt="" className={classes[iconClass]}/>
                            {this.props.systemText.placeCharacteristics.trailCharacteristics.fullDay[this.props.userLanguage]}
                        </div>
                    )
                }
                else{
                    return(
                        <div className={classes[durationClass]}>
                            <img src={durationIcon} alt="" className={classes[iconClass]}/>
                            {this.props.placeCharacteristics.durationValue+" "+
                            this.props.systemText.placeCharacteristics.trailCharacteristics[this.props.placeCharacteristics.durationUnits][this.props.userLanguage]}
                        </div>
                    )
                }
            }
            else if (this.props.placeCharacteristics.durationUnits!==undefined){
                return(
                    <div className={classes[durationClass]}>
                        <img src={durationIcon} alt="" className={classes[iconClass]}/>
                        {this.props.placeCharacteristics.durationValue+" "+
                        this.props.systemText.placeCharacteristics.trailCharacteristics[this.props.placeCharacteristics.durationUnits][this.props.userLanguage]}
                    </div>
                )
            }
            
        }
       }
    }
  
      renderLocation = () =>{
        let placeCountryName = "";
        let placeRegionName = "";
        let placeCityName = "";
        let placeCountry = this.props.placeLocation.locationTree.placeCountry
        let placeRegion = this.props.placeLocation.locationTree.placeRegion
        let placeCity = this.props.placeLocation.locationTree.placeCity
        if (this.props.placeStatus==="potential"){
            if (placeCountry!==undefined){
                if (placeCountry[this.props.userLanguage]!==undefined){
                    placeCountryName=placeCountry[this.props.userLanguage]
                }
                else if(placeCountry["English"]!==undefined){
                placeCountryName=placeCountry["English"]
                }
                else{
                    let names =  Object.keys(placeCountry);
                    placeCountryName=placeCountry[names[0]]
                }
    
            }

            if (placeRegion!==undefined){
                if (placeRegion[this.props.userLanguage]!==undefined){
                    placeRegionName=placeRegion[this.props.userLanguage]
                }
                else if(placeRegion["English"]!==undefined){
                    placeRegionName=placeRegion["English"]
                }
                else{
                    let names =  Object.keys(placeRegion);
                    placeRegionName=placeRegion[names[0]]
                }
            }
            
            if (placeCity!==undefined){
                if (placeCity[this.props.userLanguage]!==undefined){
                    placeCityName=placeCity[this.props.userLanguage]
                }
                else if(placeCity["English"]!==undefined){
                    placeCityName=placeCity["English"]
                }
                else{
                    let names =  Object.keys(placeCity);
                    placeCityName=placeCity[names[0]]
                }
            }

            
        }
        else if (this.props.locationsTranslator!==undefined && this.props.placeStatus==="approved"){
            //place approved - use the location translator
            if (placeCountry!==undefined){
                if (this.props.locationsTranslator[placeCountry]!==undefined){
                  if (this.props.locationsTranslator[placeCountry][this.props.userLanguage]!==undefined){
                      placeCountryName=this.props.locationsTranslator[placeCountry][this.props.userLanguage]
                  }
                  else if(this.props.locationsTranslator[placeCountry]["English"]!==undefined){
                  placeCountryName=this.props.locationsTranslator[placeCountry]["English"]
                  }
                  else{
                      let names =  Object.keys(this.props.locationsTranslator[placeCountry]);
                      placeCountryName=this.props.locationsTranslator[placeCountry][names[0]]
                  }
                }
            }

            if (placeRegion!==undefined){
              if (this.props.locationsTranslator[placeRegion]!==undefined){
                if (this.props.locationsTranslator[placeRegion][this.props.userLanguage]!==undefined){
                    placeRegionName=this.props.locationsTranslator[placeRegion][this.props.userLanguage]
                }
                else if(this.props.locationsTranslator[placeRegion]["English"]!==undefined){
                    placeRegionName=this.props.locationsTranslator[placeRegion]["English"]
                }
                else{
                    let names =  Object.keys(this.props.locationsTranslator[placeRegion]);
                    placeRegionName=this.props.locationsTranslator[placeRegion][names[0]]
                }
              }
            }
            
            if (placeCity!==undefined){
              if (this.props.locationsTranslator[placeCity]!==undefined){
                if (this.props.locationsTranslator[placeCity][this.props.userLanguage]!==undefined){
                    placeCityName=this.props.locationsTranslator[placeCity][this.props.userLanguage]
                }
                else if(this.props.locationsTranslator[placeCity]["English"]!==undefined){
                    placeCityName=this.props.locationsTranslator[placeCity]["English"]
                }
                else{
                    let names =  Object.keys(this.props.locationsTranslator[placeCity]);
                    placeCityName=this.props.locationsTranslator[placeCity][names[0]]
                }
              }
            }
        }
        let address ="";
        if (placeCityName!==""){
            address=address+placeCityName+", ";
        }
        if (placeRegionName!==""&&!address.includes(placeRegionName)){
            address=address+placeRegionName+", ";
        }
        address=address+placeCountryName;
        let locationStatsClass = "LocationStats"
          return (
            <div className={classes[locationStatsClass]}>
              {address}
            </div>
          );
      }

       renderTopSection=()=>{
        let categoriesListClass = "CategoriesList"
        let categoryLastClass = "CategoryLast"
        let categoryClass = "Category"
        let categoryDirectionClass = "CategoryLTR"
        let categoryLastDirectionClass = "CategoryLastLTR"
        if (this.props.RTL){

          categoryDirectionClass = "CategoryRTL"
          categoryLastDirectionClass = "CategoryLastRTL"
        }
        let selectedPlaceCategoryClass = ""
        if (this.props.clickedMarkerPlaceID===this.props.placeID){
          selectedPlaceCategoryClass = "SelectedPlaceCategory"
          if (this.props.RTL){
            selectedPlaceCategoryClass = "SelectedPlaceCategoryRTL"
          }
        }
        
        let placeName="";
        //get the relevant language or at least any language name
        if (this.props.placeName[this.state.language]!==undefined){
          placeName=this.props.placeName[this.state.language]
        }
        else if(this.props.placeName["English"]!==undefined){
          placeName=this.props.placeName["English"]
        }
        else{
          let placesNames =  Object.keys(this.props.placeName);
          placeName=this.props.placeName[placesNames[0]]
        }

          return (
              <div className={classes.TopSection} >
                <div className={classes.TitleSection}>
                  <div className={classes.Title} >
                    <div> {placeName}</div>     
                  </div>
                 </div>
                 {this.props.categoriesTranslator!==undefined?(
                  <div className={classes[categoriesListClass]}>
                  {this.props.placeCategory.map((category, index) => {
                     let categoryText = ""
                     if (this.props.categoriesTranslator[category]!==undefined){
                         categoryText = this.props.categoriesTranslator[category][this.props.userLanguage]
                     }
                     else{
                         categoryText = category
                     }
                     if (index===(this.props.placeCategory.length-1)){
                       
                      return(
                        <div key={index} className={[classes[categoryLastClass],classes[categoryLastDirectionClass]].join(" ")}>{categoryText}</div>
                      )
                    }
                    else{
                      return(
                        <div key={index} className={[classes[categoryClass],classes[categoryDirectionClass],classes[selectedPlaceCategoryClass]].join(" ")}>{categoryText}</div>
                      )
                    }
                  })}
                 </div>
               ):null}   
              </div>
          );
      }

    render(){
        let RTL = ""
        if (this.props.RTL){
          RTL = "RTL"
        }
        if (this.props.viewType==="slimSuggestion"){
          return (
            <div className={classes.SlimBody}  onClick={this.viewHandler}>
                    <div className={classes.SlimContent}>
                        {this.renderTopSection()}      
                     
                        </div>  
                </div>
          );
        }
        else{
          let wideClass="RegularWidth";
          if (this.props.wideList){
            wideClass="WideWidth";
          }
          let mapViewClass="MapView";
          if (this.props.clickedMarkerPlaceID===this.props.placeID
         /*  &&this.props.viewType!=="searchSuggestion" */){
            mapViewClass="MapViewSelected"
          }
          else if (this.props.viewType==="JourneyMode"){
            mapViewClass="JourneyPlace"
          }
          else if (this.props.board==="Discover"||this.props.addToPlan){
            mapViewClass="PlanDiscover";
          }
          return (
            <div className={[classes[mapViewClass],classes[wideClass],classes[RTL]].join(' ')}  onClick={this.viewHandler}  ref={this.state.placeRef}
            onMouseEnter={this.handleMouseOver} onMouseLeave={this.handleMouseExit}>
                    <TransparentBackdrop show={this.state.showBackdrop}  clicked={(e)=>{ e.stopPropagation();this.discard()}} />
                    {this.renderPhoto()}
                    <div className={classes.Content}>
                        {this.renderTopSection()}     
                        {this.renderLocation()} 
                        {this.renderDuration()}      
                        {this.renderTools()}      
                        </div>
                </div>
          );
        }
          
      }
}
export default PlanPlaceItem;