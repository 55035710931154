import React, { Component } from "react";

import classes from "./OpenHours.module.css";

import ConfirmModal from "../../UI/Modals/ConfirmationModal";
import ModalTopSection from "../../UI/Misc/ModalTopSection";
import SpecialDates from "./SpecialDates";
import Backdrop from "../../UI/Backdrop/Backdrop"

import TimeInput from 'material-ui-time-picker'
import { Button, Input, InputAdornment, IconButton, Dialog, DialogActions } from '@material-ui/core'; 

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"
let closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"


class OpenHours extends Component {
  state = {
    rows:[...this.props.hoursRows],
    saveReady:false,
    changesMade: false,
    closeTimeRef : React.createRef(),
  };


  updateRowOpen24 = (index,value) =>{
    let rows = this.state.rows;
    let row=rows[index];
    
    if (value){
      row.open24=value;
      row.isClosed=false;
      row.hoursArray=[];
    }
    else{
      row.open24=value;
      this.addHoursRange(index)
    }
    
    rows[index]=row;
    this.setState({saveReady: true, rows:rows,
      changesMade: true});
  }

  updateRowClosed = (index,value) =>{
    let rows = this.state.rows;
    let row=rows[index];
    
    if (value){
      row.isClosed=value;
      row.open24=false;
      row.hoursArray=[];
    }
    else{
      row.isClosed=value;
      this.addHoursRange(index)
    }
    rows[index]=row;
    this.setState({saveReady: true, rows:rows,
      changesMade: true});
  }

  saveHandler = () => {
    let rows=[...this.state.rows];
    for (let i=0 ; i<rows.length ; i++){
      if (rows[i].hoursArray.length >0){
        for (let j=0 ; j<rows[i].hoursArray.length ; j++){
          if (rows[i].hoursArray[j].open==="Not Specified"||rows[i].hoursArray[j].close==="Not Specified"){
            rows[i].hoursArray[j].open="Not Specified"
            rows[i].hoursArray[j].close="Not Specified"
          }
        }
      }
     
    }
    /* console.log(rows) */
    this.props.update(rows);
    this.props.discard();
  };

 
  discardHandler=()=>{
    if (this.state.rows!==this.props.hoursRows||this.state.changesMade) {
     this.setState({
      confirmMessage: this.props.systemText.extraModals.confirmationModal.message[this.props.userLanguage],
         confirmMode: true,
         showBackdrop: true,
       });
    }
    else{
     this.props.discard();
    }
 }

 discardChangesHandler = () => {
  this.props.discard();

};

discardDiscardHandler = () => {
     this.setState({
       confirmMode: false,
       showBackdrop: false
     });
   };

   updateOpenHour = (time,index,subIndex) => {
    let rows = this.state.rows;
    let row=rows[index];
    row.hoursArray[subIndex].open=time;
    rows[index] = row;
    this.setState({saveReady: true, rows:rows,
      changesMade: true});
      //this.state.closeTimeRef.current.focus()
  }

  updateCloseHour = (time,index,subIndex) => {
    let rows = this.state.rows;
    let row=rows[index];
    row.hoursArray[subIndex].close=time;
    rows[index] = row;
    this.setState({saveReady: true, rows:rows,
      changesMade: true});
  }

  updateSelectedDay =( index,dayIndex,value)=>{
    let rows= this.state.rows;
    rows[index].selectedDays[dayIndex]=value;
  
    if (value){
      for (let i=0 ; i<rows.length; i++){
        if (index!==i){
          if (rows[i].specialDates===undefined){
            rows[i].selectedDays[dayIndex]=false;
          }
        }
      
      }
    }
    this.setState({rows:rows,
      changesMade: true});
  }

  addNewRow=()=>{
    let rows=this.state.rows;
    rows.push({hoursArray:[{open:"Not specified",close:"Not specified"}],selectedDays:[0,0,0,0,0,0,0],
        isClosed:false,open24:false});
    this.setState({rows:rows})
  }

  removeRow = (index)=>{
    let rows=this.state.rows;
    rows.splice(index,1);
    this.setState({rows:rows,
      changesMade: true})
  }

  addHoursRange = (index) =>{
    let rows=this.state.rows;
    let row=rows[index];
    row.hoursArray.push({open:"Not specified",close:"Not specified"})
    row.isClosed=false;
    row.open24=false;
    rows[index] = row;
    this.setState({saveReady: true, rows:rows,
      changesMade: true});
  }

  removeSubRow = (index,subIndex) =>{
    let rows=this.state.rows;
    let row=rows[index];
    row.hoursArray.splice(subIndex,1);
    if (row.hoursArray.length ===0){
      row.isClosed=true;
    }
    rows[index] = row;
    this.setState({saveReady: true, rows:rows,
      changesMade: true});
  }

  setSpecialDates = dates =>{
    let rows=this.state.rows;
    let row = rows[this.state.specialDatesIndex];
    row.specialDates=dates;
    rows[this.state.specialDatesIndex] = row;
    this.setState({saveReady: true, rows:rows,
      changesMade: true, showSpecialDaysRange:false,showBackdrop:false});
  }

  discardSpecialDates = () =>{
    let rows=this.state.rows;
    let row = rows[this.state.specialDatesIndex];
    row.specialDates=undefined;
    rows[this.state.specialDatesIndex] = row;
    this.setState({saveReady: true, rows:rows,
      changesMade: true, showSpecialDaysRange:false,showBackdrop:false});
  }

   renderTopSection = () => {
    let title = this.props.systemText.place.openHours.title[this.props.userLanguage];
    let buttonName =  this.props.systemText.place.openHours.saveButton[this.props.userLanguage];
    let saveReady=true;
    for (let i=0; i<this.state.rows.length-1 ; i++){
      if (this.state.rows[i].open===this.props.systemText.place.openHours.unspecifiedHours[this.props.userLanguage]||this.state.rows[i].close===this.props.systemText.place.openHours.unspecifiedHours[this.props.userLanguage]){
        saveReady=false;
      }
    }
    return (
      <ModalTopSection
        title={title}
        discard={this.discardHandler}
        saveReady={saveReady}
        clicked={this.saveHandler}
        showButton={true}
        buttonText={buttonName}
        modalView={true}
        saveButton={true}
        RTL = {this.props.RTL}
      />
    );
  };

 
  renderSubRow = (subRow,index,subIndex) =>{
    let openValue;
    
    let openValueDefault = new Date();
    openValueDefault.setHours(0);
    openValueDefault.setMinutes(0);
    let closeValue
    let closeValueDefault  = new Date();
    closeValueDefault.setHours(0);
    closeValueDefault.setMinutes(0);
    if (this.state.rows[index].hoursArray[subIndex].open!=="Not specified"){
      openValue= new Date(this.state.rows[index].hoursArray[subIndex].open);
    }
    if (this.state.rows[index].hoursArray[subIndex].close!=="Not specified"){
      closeValue= new Date(this.state.rows[index].hoursArray[subIndex].close);
    }
    return(
      <div className={classes.SubRow}>
        <div className={classes.SubRowHours}>
          <div className={classes.SubRowTime}>
           <div className={classes.TimeTitle}>{this.props.systemText.place.openHours.opensMessage[this.props.userLanguage]}</div>
             <div className={classes.TimeBody}>
                <TimeInput
                  mode='24h'
                  value={openValue}
                  defaultValue={openValueDefault}
                  onChange={(time) => this.updateOpenHour(time,index,subIndex)}
                />
              </div>
              </div>
              <div className={classes.SubRowTime}>
                <div className={classes.TimeTitle}>{this.props.systemText.place.openHours.closingTimeMessage[this.props.userLanguage]}</div>
                <div className={classes.TimeBody}>
                    <TimeInput
                    ref = {this.state.closeTimeRef}
                    mode='24h'
                    value={closeValue}
                    defaultValue={closeValueDefault}
                    container="inline"
                    inputStyle={{ textAlign: 'center' }}
                    onChange={(time) => this.updateCloseHour(time,index,subIndex)}
                    
                  />
              </div>
            </div>
          </div>
          <img src={closeIcon} alt="" className={classes.Icon} onClick={()=>{this.removeSubRow(index,subIndex)}}/>
      </div>
    )
  }

  renderSpecialCheckboxes = index =>{
    let checkIconClass = "CheckIcon"
    let uncheckBoxClass = "UncheckBox"
    if (this.props.RTL){
      checkIconClass = "CheckIconRTL"
      uncheckBoxClass = "UncheckBoxRTL"
    }
    return(
      <div className={classes.SpecialCheckboxes}>
        <div className={classes.SpecialCheck}>
          {this.state.rows[index].open24?(
            <img src={checkedBox} alt="" className={classes[checkIconClass]} onClick={()=>{this.updateRowOpen24(index,false)}}/>
          ):(
            <div className={classes[uncheckBoxClass]} onClick={()=>{this.updateRowOpen24(index,true)}}/>
          )}
          <div className={classes.CheckboxText}>
          {this.props.systemText.place.openHours.open24Hours[this.props.userLanguage]}</div>
        </div>
        <div className={classes.SpecialCheck}>
          {this.state.rows[index].isClosed?(
            <img src={checkedBox} alt="" className={classes[checkIconClass]} onClick={()=>{this.updateRowClosed(index,false)}}/>
          ):(
            <div className={classes[uncheckBoxClass]} onClick={()=>{this.updateRowClosed(index,true)}}/>
          )}
           <div className={classes.CheckboxText}>
           {this.props.systemText.place.openHours.closed[this.props.userLanguage]}</div>
          </div>
      </div>
    )
  }

  renderRow=(index)=>{
   let removeButtonClass = "RemoveButton"
   if (this.props.RTL){
    removeButtonClass = "RemoveButtonRTL"
   }
   let specialDatesDate = ""
   if (this.state.rows[index].specialDates!==undefined){
     let startDateTemp = new Date(Date.parse(this.state.rows[index].specialDates.startDate))
     let endDateTemp =  new Date(Date.parse(this.state.rows[index].specialDates.endDate))
     let startDateText = startDateTemp.getDate()+"/"+(startDateTemp.getMonth() + 1);
    let endDateText = endDateTemp.getDate()+"/"+(endDateTemp.getMonth() + 1);
    specialDatesDate=" ("+startDateText+"-"+endDateText+")";
  }
    return(<div className={classes.RowContent}>
        <div className={classes.RowTopSection}>
          <div className={classes.RowOptions}>
            {this.renderSpecialCheckboxes(index)}
            {this.state.rows[index].specialDates!==undefined?(
              <div className={classes.SpecialDatesButtonSelected} onClick={()=>{this.setState({showSpecialDaysRange:true,showBackdrop:true,specialDatesIndex:index})}}>
              {this.props.systemText.place.openHours.specialDates.button[this.props.userLanguage]}
              {specialDatesDate}
              </div>
            ):(
              <div className={classes.SpecialDatesButton} onClick={()=>{this.setState({showSpecialDaysRange:true,showBackdrop:true,specialDatesIndex:index})}}>
              {this.props.systemText.place.openHours.specialDates.button[this.props.userLanguage]}
              </div>
            )}
          </div>
          {index>0?(
          <div className={classes[removeButtonClass]}
          onClick={()=>{this.removeRow(index)}}
          >{this.props.systemText.place.openHours.removeRowMessage[this.props.userLanguage]}</div>
        ):null}   
        </div>
        
        
      <div className={classes.DaysList}>
      
          {this.state.rows[index].selectedDays[0]?
          (  <div className={classes.DaySelected}  onClick={()=>{this.updateSelectedDay(index,0,0)}}>
             {this.props.systemText.place.openHours.weekDaysShort.sunday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day}  onClick={()=>{this.updateSelectedDay(index,0,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.sunday[this.props.userLanguage]}
             </div>)}
           {this.state.rows[index].selectedDays[1]?
          (
            <div className={classes.DaySelected} onClick={()=>{this.updateSelectedDay(index,1,0)}}>
               {this.props.systemText.place.openHours.weekDaysShort.monday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day}  onClick={()=>{this.updateSelectedDay(index,1,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.monday[this.props.userLanguage]}
             </div>)}
           {this.state.rows[index].selectedDays[2]?
          ( 
            <div className={classes.DaySelected} onClick={()=>{this.updateSelectedDay(index,2,0)}}>
              {this.props.systemText.place.openHours.weekDaysShort.tuesday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day} onClick={()=>{this.updateSelectedDay(index,2,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.tuesday[this.props.userLanguage]}
             </div>)}
           {this.state.rows[index].selectedDays[3]?
          (   <div className={classes.DaySelected} onClick={()=>{this.updateSelectedDay(index,3,0)}}>
            {this.props.systemText.place.openHours.weekDaysShort.wednesday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day} onClick={()=>{this.updateSelectedDay(index,3,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.wednesday[this.props.userLanguage]}
             </div>)}
           {this.state.rows[index].selectedDays[4]?
          ( <div className={classes.DaySelected} onClick={()=>{this.updateSelectedDay(index,4,0)}}>
             {this.props.systemText.place.openHours.weekDaysShort.thursday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day} onClick={()=>{this.updateSelectedDay(index,4,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.thursday[this.props.userLanguage]}
             </div>
              )}
           {this.state.rows[index].selectedDays[5]?
          (   <div className={classes.DaySelected} onClick={()=>{this.updateSelectedDay(index,5,0)}}>
            {this.props.systemText.place.openHours.weekDaysShort.friday[this.props.userLanguage]}
             </div>
          ):
          (
            <div className={classes.Day} onClick={()=>{this.updateSelectedDay(index,5,1)}}>
              {this.props.systemText.place.openHours.weekDaysShort.friday[this.props.userLanguage]}
             </div>
              )}
           {this.state.rows[index].selectedDays[6]?
          (  <div className={classes.DaySelected} onClick={()=>{this.updateSelectedDay(index,6,0)}}>
             {this.props.systemText.place.openHours.weekDaysShort.saturday[this.props.userLanguage]}
             </div>
          ):
          ( <div className={classes.Day} onClick={()=>{this.updateSelectedDay(index,6,1)}}>            
            {this.props.systemText.place.openHours.weekDaysShort.saturday[this.props.userLanguage]}
             </div>)}
       
      </div>
      {this.state.rows[index].hoursArray.map((subRow, subIndex) => (
            <div style={{width:'100%'}} key={subIndex}>
                {this.renderSubRow(subRow,index,subIndex)}
            </div>
        ) )}
       
      <div className={classes.AddHoursButton} onClick={()=>{this.addHoursRange(index)}}>
      {this.props.systemText.place.openHours.addHours[this.props.userLanguage]}
      </div>
    </div>)
  }
  

  renderSpecialDates = () =>{
    if (this.state.showSpecialDaysRange){
      return(
        <SpecialDates
        selectionRange={this.state.rows[this.state.specialDatesIndex].specialDates}
        setSpecialDates = {this.setSpecialDates}
        reset ={this.discardSpecialDates}
        discardSpecialDates = {()=>{this.setState({showSpecialDaysRange:false,showBackdrop:false})}}
        range = {true}
        message = {this.props.systemText.place.openHours.specialDates.header[this.props.userLanguage]}

        RTL = {this.props.RTL}
        systemText={this.props.systemText}
        userLanguage={this.props.userLanguage}
        />
      )
    }
  }

  renderClosedDate = () =>{
    if (this.state.showClosedDate){
      let date = new Date()
      if (this.props.permanentlyClosedDate!==undefined){
        date=this.props.permanentlyClosedDate;
      }
      return(
        <SpecialDates
        date={date}
        setSpecialDates = {(value)=>{this.props.updatePlacePermanentlyClosedDate(value);
          this.setState({showClosedDate:false, showBackdrop:false})}}
        discardSpecialDates = {()=>{this.setState({showClosedDate:false, showBackdrop:false})}}
        reset={()=>{this.props.updatePlacePermanentlyClosedDate(undefined);
          this.setState({showClosedDate:false, showBackdrop:false})}}
        range = {false}
        message = {this.props.systemText.place.openHours.closedDateMessage[this.props.userLanguage]}

        RTL = {this.props.RTL}
        systemText={this.props.systemText}
        userLanguage={this.props.userLanguage}
        />
      )
    }
  }

  renderClosedDateButton = () =>{
    if (this.props.placePermanentlyClosed){
      if (this.props.permanentlyClosedDate!==undefined){
        let closeDateText = this.props.permanentlyClosedDate.getDate()+"/"+(this.props.permanentlyClosedDate.getMonth() + 1)+"/"+(this.props.permanentlyClosedDate.getFullYear());
        return <div className={classes.ClosedDateButton} onClick={()=>{this.setState({showClosedDate:true, showBackdrop:true})}}>{closeDateText}</div>
      }
      else{
        return <div className={classes.ClosedDateButton} onClick={()=>{this.setState({showClosedDate:true,  showBackdrop:true})}}>
          {this.props.systemText.place.openHours.closedDate[this.props.userLanguage]}</div>
      }
    }
    
  }
  renderPlaceClosedSection = () =>{
    let checkIconClass = "CheckIcon"
    let uncheckBoxClass = "UncheckBox"
    if (this.props.RTL){
      checkIconClass = "CheckIconRTL"
      uncheckBoxClass = "UncheckBoxRTL"
    }
    if (this.props.editPlace){
     return(
      <div className={classes.PlaceClosedSection}>
          {!this.props.placePermanentlyClosed?(
          <div className={classes.ClosedSubSection}>
          {this.props.placeTemporaryClosed?(
            <img src={checkedBox} alt="" className={classes[checkIconClass]} onClick={()=>{this.props.updatePlaceTemporaryClosedState(false)}}/>
          ):(
            <div className={classes[uncheckBoxClass]} onClick={()=>{this.props.updatePlaceTemporaryClosedState(true)}}/>
          )}
          <div className={classes.CheckboxText}>
          {this.props.systemText.place.openHours.temporaryClosedMessage[this.props.userLanguage]}</div>
          {this.props.placeTemporaryClosed?(
          <input type="text" className={classes.ClosedNoteInput} value={this.props.temporaryClosedNote} 
          placeholder= {this.props.systemText.place.openHours.addExplanationPlaceholder[this.props.userLanguage]}
           onChange={this.props.updatePlaceTemporaryClosedNote}/>):null}
        </div>):null}
        {!this.props.placeTemporaryClosed?(
          <div className={classes.ClosedSubSection}>
          {this.props.placePermanentlyClosed?(
            <img src={checkedBox} alt="" className={classes[checkIconClass]} onClick={()=>{this.props.updatePlacePermanentlyClosedState(false)}}/>
          ):(
            <div className={classes[uncheckBoxClass]} onClick={()=>{this.props.updatePlacePermanentlyClosedState(true)}}/>
          )}
          <div className={classes.CheckboxText}>
          {this.props.systemText.place.openHours.permanentlyClosedMessage[this.props.userLanguage]}</div>
            {this.renderClosedDateButton()}
          </div>
          
          
        ):null}
       
      </div>
    )
   }
  }

  renderConfirmModal = () => {
    if (this.state.confirmMode) {
      return (
        <ConfirmModal
        header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
        show={this.state.confirmMode}
        primaryAction={this.discardDiscardHandler}
        secondaryAction={this.discardChangesHandler}
        message={this.state.confirmMessage}
        primaryBtn={this.props.systemText.extraModals.confirmationModal.stay[this.props.userLanguage]}
        secondaryBtn={this.props.systemText.extraModals.confirmationModal.leave[this.props.userLanguage]}
        primaryStyle = {"PositiveButton"}
        secondaryStyle = {"DiscardButton"}
        RTL = {this.props.RTL}
        />
        
      );
    }
  };


   render() {
     let RTL = ""
     if (this.props.RTL){
       RTL = "RTL"
     }
    return (
      <div className={[classes.Modal,classes[RTL]].join(" ")}>
        <Backdrop show={this.state.showBackdrop}/>
          {this.renderConfirmModal()}
          {this.renderClosedDate()}
          {this.renderTopSection()}
        
          {this.renderSpecialDates()} 
           <div className={classes.Body}>
           {this.renderPlaceClosedSection()}
          {this.state.rows.map((row, index) => (
            <div className={classes.Row} key={index}>
            {this.renderRow(index)}
            </div>
            ) )}
            <div className={classes.NewButtonSection}>
            <div className={classes.NewButton} onClick={this.addNewRow}>{this.props.systemText.place.openHours.adTimeRangesButton[this.props.userLanguage]}</div>
            </div>
         
        </div>
      </div>
    );
}
}

export default OpenHours;