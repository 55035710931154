import React, { Component } from "react";
import classes from "./SpecialDates.module.css";

import TextButton from "../../UI/Buttons/TextButton"

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker ,SingleDatePicker} from "react-dates";

const moment = require('moment');
moment.locale('he-IL');
let closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"



class SpecialDates extends Component {

    state={
    }

    componentWillMount = () =>{ 
        if (this.props.range){
            if (this.props.selectionRange!==undefined){
                let selectionRange = this.props.selectionRange;
                selectionRange.startDate=new Date(selectionRange.startDate);
                selectionRange.endDate=new Date(selectionRange.endDate);
                let startDateMoment =  moment( selectionRange.startDate,'DD-MM-YYYY');
              
                let endDateMoment =  moment(selectionRange.endDate);
                selectionRange = {startDate:startDateMoment,endDate:endDateMoment}
                this.setState({ selectionRange:selectionRange})
            }    
            else{
                let startDate = new Date();
                let startDateMoment =  moment(startDate);
                let endDateMoment = startDateMoment
                let selectionRange = {startDate:startDateMoment,endDate:endDateMoment}
                this.setState({ selectionRange:selectionRange})
            } 
        }
        else{
            if (this.props.date!==undefined){
                let selectedDate = moment(new Date(this.props.date));
                this.setState({ date:selectedDate})
            }    
            else{
                let selectedDate = moment(new Date());
                this.setState({ date:selectedDate})
            } 
        }
        
}

datePickerHandler =  range =>{
    if (range.startDate===null||range.endDate===null){
        this.setState({selectionRange:range});
        return;
      }
    this.setState({selectionRange:range,datesRangeSelected:true});
}

renderDatesRange = () =>{
    if (this.props.range){
        let orientation="horizontal"
    let isRTL=false
    let direction="ltr"
    let anchorDirection ="left"
    if (this.props.userLanguage==="Hebrew"){
        direction="rtl"
        isRTL=true;
        anchorDirection="right"
    }
    return(
        <div className={classes.DatesSection}>
        <DateRangePicker
              startDate={this.state.selectionRange.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.selectionRange.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={this.datePickerHandler} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              orientation={orientation} verticalHeight={568}
              horizontalMargin={0}
              numberOfMonths={1}
              noBorder={true}
              anchorDirection={anchorDirection}
              isOutsideRange = {(day)=>{return false}}
              isRTL
              startDatePlaceholderText={this.props.systemText.extraModals.reactDates.startDatePlaceholder[this.props.userLanguage]}
              endDatePlaceholderText={this.props.systemText.extraModals.reactDates.endDatePlaceholder[this.props.userLanguage]}
            />
        </div>
    )
    }
}

renderSingleDate = () =>{
    if (!this.props.range){
        let orientation="horizontal"
        let isRTL=false
        let direction="ltr"
        let anchorDirection ="left"
        if (this.props.userLanguage==="Hebrew"){
            direction="rtl"
            isRTL=true;
            anchorDirection="right"
        }
        return(
            <div className={classes.DatesSection}>
            <SingleDatePicker
                  date={this.state.date} // momentPropTypes.momentObj or null,
                  onDateChange={date => this.setState({ date })} 
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  orientation={orientation} verticalHeight={568}
                  horizontalMargin={0}
                  numberOfMonths={1}
                  noBorder={true}
                  anchorDirection={anchorDirection}
                  isOutsideRange = {(day)=>{return false}}
                  isRTL
                  startDatePlaceholderText={this.props.systemText.extraModals.reactDates.startDatePlaceholder[this.props.userLanguage]}
                  endDatePlaceholderText={this.props.systemText.extraModals.reactDates.endDatePlaceholder[this.props.userLanguage]}
                />
            </div>
        )
    }
   
}

setSpecialDates = () =>{
    if (this.props.range){
        let selectionRange;
        let startDate = this.state.selectionRange.startDate.toDate()
        let endDate = this.state.selectionRange.endDate.toDate()
        selectionRange = {startDate:startDate, endDate:endDate}
        this.props.setSpecialDates(selectionRange)
    }
    else{
        let date = this.state.date.toDate()
        this.props.setSpecialDates(date)
    }
    
}

renderButtonsSection=()=>{
    return (<div className={classes.ButtonsSection}>
        <TextButton
            clicked={this.props.reset}
            text=  {this.props.systemText.place.openHours.specialDates.remove[this.props.userLanguage]}
            style={"RemoveButton2"}/>
          <TextButton
            clicked={this.setSpecialDates}
            text=  {this.props.systemText.place.openHours.specialDates.set[this.props.userLanguage]}
            style={"SetButton"}/>
    </div>)
}

renderHeader = () =>{
    return(
        <div className={classes.Header}>
            {this.props.message}
        </div>
    )
}

renderCloseButton = () =>{
    let discardCloseDate = "DiscardCloseDate"
    if (this.props.RTL){
      discardCloseDate = "DiscardCloseDateRTL"
    }
    return(
        <img className={classes[discardCloseDate]} 
        alt = ""
        src = {closeIcon}
        onClick={()=>{this.props.discardSpecialDates()}}/>
    )
}

    render() {
        return(
            <div className={classes.Modal}> 
                {this.renderCloseButton()}
                {this.renderHeader()}
                {this.renderDatesRange()}
                {this.renderSingleDate()}
                {this.renderButtonsSection()}
            </div>
        )
    }

}

export default SpecialDates;