import React from "react";

import classes from "./ModalTopSection.module.css";
import TextButton from "../Buttons/TextButton";

let backIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMobileBackIconRight.png?alt=media&token=21620f3f-dbc0-40c5-83a4-7fc6d30cbb16"
let backIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMobileBackIconLeft.png?alt=media&token=7ea23e39-01e8-436f-8831-85ab21e6487e"
/* let backIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FArrowIconLeft.png?alt=media&token=57bea1aa-0b1f-4553-80c0-aae0cf0cd2cb"
let backIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FArrowIcon.png?alt=media&token=b664f71d-dc5e-4e14-914c-505ed1794fa1"
 */

const modalTopSection = props => {

  const renderButton = () => {
    if (props.showButton) {
      if (props.saveReady){
        return (
          <TextButton
            clicked={props.clicked}
            text={props.buttonText}
            style="SaveButton"
          />
        );
      }
      else{
        return (
          <TextButton
            clicked={()=>{return null}}
            text={props.buttonText}
            style="SaveButtonGreyed"
          />
        );
      }
     
    } else return <div></div>;
  };

  const renderBody = () =>{
    let topSectionClass = "TopSection"
    let darkTop = ""
    if (props.dark){
      darkTop = "DarkTop"
    }
    let modalViewClass = ""
    let backIcon = backIconLeft
    if (props.RTL){
      backIcon = backIconRight
    }
    if (props.modalView){
      modalViewClass = "ModalView"
    }
    return(
      <div className={[classes[topSectionClass],classes[darkTop],classes[modalViewClass]].join(" ")}>
      <img src={backIcon} className={classes.Icon} onClick={props.discard} />
      <div className={classes.Header}>{props.title}</div>
      {renderButton()}
    </div>
    )
  }

  return (
    <div style={{width:"100%"}}>
      {renderBody()}
    </div>
  );
};

export default modalTopSection;
