import React, { Component } from "react";

class SystemText extends Component {
    
    state={
        systemText:{
            welcomeWizard:{
                welcomeTitle:{
                    English:"Welcome to",
                    Hebrew:"ברוכים הבאים ל"
                },
                discover:{
                    English:"DISCOVER",
                    Hebrew:"גלה"
                },
                plan:{
                    English:"PLAN",
                    Hebrew:"תכנן"
                },
                experience:{
                    English:"EXPERIENCE",
                    Hebrew:"תחווה"
                },
                welcomeMessageHeader:{
                    English:"Providing a single place where you can dream, plan, experience, capture and share your next trip",
                    Hebrew:"מקום אחד בו תוכלו לחלום, לתכנן, לחוות, לתעד ולשתף את הטיול הבא שלכם"
                },
                nextButton:{
                    English:"Continue",
                    Hebrew:"המשך"
                },
                skipButton:{
                    English:"Skip",
                    Hebrew:"דלג"
                },
                discoverScreenshot_1:{
                    English:"Discover and filter places with map display",
                    Hebrew:"גלו וסננו מקומות עם תצוגת מפה"
                },
                discoverScreenshot_2:{
                    English:"Use available shared trips for desired destination",
                    Hebrew:"השתמשו בטיולים שאנשים שיתפו עבור היעד המבוקש"
                },
                discoverScreenshot_3:{
                    English:"Get personalized trips suggestions, which you can customize",
                    Hebrew:"קבלו הצעות לטיולים מותאמים אישית אותם תוכלו להמשיך לתכנן ולשנות"
                },
                planScreenshot_1:{
                    English:"Continue planning trip visualizing it on the map and discover more places in the area",
                    Hebrew:"המשיכו בתכנון הטיול עם תצוגת מפה וחיפוש מקומות נוספים באזור"
                },
                planScreenshot_2:{
                    English:"Manage trip plan days, tasks, transport, and plan together with friends",
                    Hebrew:"נהלו את ימי הטיול, משימות, תחבורה, ותכננו את הטיול יחד עם חברים"
                },
                experienceScreenshot_1:{
                    English:"Capture experiences and add photos to places for each day of the trip, in a collaborative trip album",
                    Hebrew:"תעדו חוויות וצרפו תמונות לימי הטיול באלבום הטיול המשותף"
                },
                experienceScreenshot_2:{
                    English:"Share experiences and entire trips to inspire others and allow them reuse it as they like",
                    Hebrew:"שתפו חוויות וטיולים כדי לתת לאחרים השראה ולהשתמש במידע לטיולים שלהם"
                },
                imagineButton:{
                    English:"Imagine...",
                    Hebrew:"דמיינו..."
                },
                imagineFirstMessage:{
                    English:"Discover inspiring places, trips and friends experiences",
                    Hebrew:"גלו מקומות מעוררי השראה, טיולים וחוויות של חברים"
                },
                imagineSecondMessage:{
                    English:"With click of a button... you will get personalized trips suggestions to a desired destination",
                    Hebrew:"בלחיצת כפתור... תקבלו הצעות לטיולים מותאמים עבורכם ליעד המבוקש"
                },
                saveTripMessage:{
                    English:"Save trip you liked...",
                    Hebrew:"שמרו טיול שאהבתם..."
                },
                planFirstMessage:{
                    English:"Continue to plan and customize your trip with inspiring places",
                    Hebrew:"המשיכו לתכנן את הטיול והוסיפו מקומות שמעניינים אתכם"
                },
                planSecondMessage:{
                    English:"Join friends to trip and plan together",
                    Hebrew:"צרפו חברים לטיול ותכננו ביחד"
                },
                experienceButton:{
                    English:"Experience your trip",
                    Hebrew:"תחוו את הטיול שלכם"
                },
                experiencesFirstMessage:{
                    English:"Want to remember when and where you took your photos",
                    Hebrew:"רוצים לזכור איפה ומתי צילמתם את התמונות"
                },
                experiencesSecondMessage:{
                    English:"Capture an experience and connect it to trip album",
                    Hebrew:"שמרו חוויה וצרפו אותה לאלבום הטיול"
                },
                letsStartButton:{
                    English:"Let's Start",
                    Hebrew:"בוא נתחיל"
                },
                firstMessage:{
                    English:"By clicking on an orange button you could get personalized trips suggestions for desired destination",
                    Hebrew:"שבלחיצה על כפתור כתום הייתם מקבלים הצעות לטיולים מותאמים עבורכם ליעד המבוקש"
                },
                planButton:{
                    English:"Continue planning",
                    Hebrew:"המשיכו לתכנן"
                },
                planAdjustments:{
                    English:"Discover interesting places on map and add them to your teip",
                    Hebrew:"גלו מקומות מעניינים על המפה וצרפו אותם לטיול שלכם"
                },
                travelWithFriends:{
                    English:"Join friends to plan together",
                    Hebrew:"צרפו חברים ותכננו ביחד"
                },
                captureExperiences:{
                    English:"Capture experiences",
                    Hebrew:"שמרו חוויות"
                },
                experiencesMessage:{
                    English:"Want to remember where and when you took the photos? Share experiences for the trip places and connect them to the collaborative trip album",
                    Hebrew:"רוצים לזכור איפה ומתי צילמתם את התמונות? שמרו חוויה למקום וצרפו אותה לאלבום הטיול המשותף"
                },
                letsStart:{
                    English:"Lets start",
                    Hebrew:"בואו נתחיל"
                },
                getPersonalizedTrips:{
                    English:"Create personalized trips",
                    Hebrew:"צור עבורי טיול"
                },
                discoverPlaces:{
                    English:"Discover places",
                    Hebrew:"גלה מקומות"
                },
                homePage:{
                    English:"Go to homepage",
                    Hebrew:"נווט לדף הבית"
                },
                secondVersionText:{
                    discoverPlacesPart1:{
                        English:"Choose what to experience and where to travel",
                        Hebrew:"לבחור מה לחוות ולאן לטייל"
                    },
                    discoverPlacesPart2:{
                        English:"Just navigate on the map and filter results as you like",
                        Hebrew:"פשוט לנווט על המפה ולסנן תוצאות לפי מה שרוצים"
                    },
                    suggestedTipsPart1:{
                        English:"Plan your trip your way",
                        Hebrew:"לתכנן את הטיול שלך בדרך שלך"
                    },
                    suggestedTipsPart2:{
                        English:"Search and add places from map to trip days, do it by yourself or with friends",
                        Hebrew:"חיפוש והוספת מקומות מהפה לפי ימים, תכנון לבד או יחד עם חברים"
                    },
                    planPart1:{
                        English:"Personalized trips suggestions",
                        Hebrew:"המלצות מותאמות אישית לטיולים"
                    },
                    planPart2:{
                        English:"We will create trips suggestions for you to desired destination and in a style you like",
                        Hebrew:"אנחנו נייצר עבורך המלצות לטיולים ליעד ובסגנון המתאים לך"
                    },
                    experiencesPart1:{
                        English:"To capture the most precious moments from your trip",
                        Hebrew:"לתעד את הרגעים המיוחדים של הטיול"
                    },
                    experiencesPart2:{
                        English:"With click of a button to save and connect images to trip's collaborative album for each place by days",
                        Hebrew:"בלחיצת כפתור לשמור ולשייך תמונות מהטיול למקומות לפי ימים באלבום המשותף"
                    },
                    mainMessage:{
                        English:"We are here to help you plan and capture your next vacation, trip or experience",
                        Hebrew:"אנחנו כאן כדי לעזור לך לתכנן ולתעד את החופשה, טיול או החוויה הבאים שלך"
                    }
                }
            },
            searchErrorMessages:{
                title:{
                    English:"No results",
                    Hebrew:"אין תוצאות"
                },
                welcomePlaces:{
                    English:"There are no results for such keyword. Use only suggested search keywords \nYou can search either by destination or by place name for places in your current country\nIf the place you looking for not yet exist you can create it",
                    Hebrew:"אין תוצאות למילת החיפוש. השתמש רק במילות החיפוש המוצעות\nניתן לחפש או לפי יעד או לפי שם מקום עבור מקומות במדינה הנוכחית שלך\nכמו כן ניתן ליצור את המקום אם הוא עוד לא קיים"
                },
                discoverPlaces:{
                    English:"There are no results for such keyword. Use only suggested search keywords. You can search for places by following\nDestination, category, place name or additional tags\nIf the place you looking for not yet exist you can create it",
                    Hebrew:"אין תוצאות למילת החיפוש. השתמש רק במילות החיפוש המוצעות\n ניתן לחפש לפי יעד, קטגוריה, שם מקום או תגים נוספים שצורפו למקום\nכמו כן ניתן ליצור את המקום אם הוא עוד לא קיים "
                },
            },
            countries:{
                Israel:{
                    English:"Israel",
                    Hebrew:"ישראל"
                },
            },
            languages:{
                shortLanguage:{
                    English:"EN",
                    Hebrew:"עבר"
                },
                longLanguage:{
                    English:"English",
                    Hebrew:"עברית"
                }
               
            },
            users:{
                friendsTitle:{
                    English:"Manage my friends",
                    Hebrew:"ניהול חברים"
                },
                planEditorsTitle:{
                    English:"Join friends to the trip",
                    Hebrew:"צרף חברים לטיול"
                },
                experienceFriends:{
                    English:"Experienced with friends",
                    Hebrew:"חברים לחוויה"
                },
                findFriend:{
                    English:"Find a friend",
                    Hebrew:"חפש בין חברים"
                },
                findFollowing:{
                    English:"Search users I follow",
                    Hebrew:"חפש אנשים שאני עוקב אחרי"
                },
                searchNewFriend:{
                    English:"Search by name",
                    Hebrew:"חפש לפי שם"
                },
                searchPlanEditors:{
                    English:"Search friends to add to trip plan",
                    Hebrew:"חפש חברים להוסיף לתוכנית הטיול"
                },
                searchFriendsToTag:{
                    English:"Search friends to tag",
                    Hebrew:"חפש חברים לתייג"
                },
                myFriendsTab:{
                    English:"Friends",
                    Hebrew:"חברים"
                },
                usersIFollow:{
                    English:"Follwoing",
                    Hebrew:"עוקב אחרי"
                },
                findFriendTab:{
                    English:"Finder",
                    Hebrew:"חיפוש"
                },
                declineFriendship:{
                    English:"You are about to decline potential friendship",
                    Hebrew:"הינך עומד לסרב לחברות זו"
                },
                
                unfollowMessage:{
                    English:"You are about to unfollow this user",
                    Hebrew:"הינך עומד להפסיק לעקוב אחר משתמש זה"
                },
                userItem:{
                    followed:{
                        English:"Followed",
                        Hebrew:"עוקב"
                    },
                    friend:{
                        English:"Friend",
                        Hebrew:"חבר"
                    },
                    remove:{
                        English:"Remove",
                        Hebrew:"הסר"
                    },
                    stopFollow:{
                        English:"Unfollow",
                        Hebrew:"הפסק לעקוב"
                    },
                    add:{
                        English:"Add",
                        Hebrew:"הוסף"
                    },
                    requestSent:{
                        English:"Request Sent",
                        Hebrew:"בקשה נשלחה"
                    },
                    requestFriendship:{
                        English:"Request Friendship",
                        Hebrew:"בקש חברות"
                    },
                    requestFollow:{
                        English:"Follow",
                        Hebrew:"עקוב"
                    },
                    accept:{
                        English:"Accept",
                        Hebrew:"אשר"
                    },
                    decline:{
                        English:"Decline",
                        Hebrew:"דחה"
                    },
                    removePrefix:{
                        English:"You are about to remove ",
                        Hebrew:"הינך עומד להסיר את "
                    },
                    removePostfix:{
                        English:" from being your friend",
                        Hebrew:" מרשימת החברים"
                    },
                    removeMessage:{
                        English:"You are about to remove the user from the list",
                        Hebrew:"הינך עומד למחוק משתמש זה מרשימת החברים"
                    },
                    removePlanEditor:{
                        English:"You are about to remove users edit permissions for this trip plan",
                        Hebrew:"הינך עומד להסיר את הרשאות העריכה לטיול עבור משתמש זה"
                    },
                    removeTaggedUser:{
                        English:"You are about to remove the tag for this user",
                        Hebrew:"הינך עומד להסיר את התיוג למשתמש זה"
                    }
                }
            },
            extraModals:{
                reactDates:{
                    startDatePlaceholder:{
                        English:"Start Date",
                        Hebrew:"תאריך התחלה",
                    },
                    endDatePlaceholder:{
                        English:"End Date",
                        Hebrew:"תאריך סיום",
                    }
                },
                requestMessages:{
                    requestSent:{
                        title:{
                            English:"Request sent",
                            Hebrew:"בקשה נשלחה",
                        },
                        message:{
                            English:"We will handle your request shortly. \nIf we will find it necessary we will contact you through provided email",
                            Hebrew:"נתחיל טיפול בבקשתך בזמן קצר. \nאם נראה את הצורך לכך ניצור עמך קשר בכתובת האימייל שסופקה",
                        },
                        button:{
                            English:"Close",
                            Hebrew:"סגור",
                        }
                    }
                },
                createMenu:{
                    experience:{
                        mainMessage:{
                            English:"Capture and share personal experience",
                            Hebrew:"תעד ושתף חוויה",
                        },
                        subMessage:{
                            English:"Personal experience you had with specific place",
                            Hebrew:"חוויה אישית ממקום ספציפי"
                        }
                    },
                    place:{
                        mainMessage:{
                            English:"Create new place",
                            Hebrew:"צור מקום חדש",
                        },
                        subMessage:{
                            English:"Place, attraction or activity to experience (Places are public)",
                            Hebrew:"מקום, אטרקציה, פעילות שניתן לחוות (מקומות נראים לכלל המשתמשים במערכת)"
                        }
                    },
                    plan:{
                        mainMessage:{
                            English:"Start planning or start dreaming your next trip",
                            Hebrew:"התחל לתכנן או לחלום את הטיול הבא",
                        },
                        subMessage:{
                            English:"Organize trip places, notes transportation and more",
                            Hebrew:"ארגן מקומות של הטיול, הערות, תחבורה ועוד לפי ימים"
                        },
                        selectionTitle:{
                            English:"How you would like to start?",
                            Hebrew:"איך תרצה להתחיל?"
                        },
                        createFromScratch:{
                            English:"Plan trip from scratch",
                            Hebrew:"תכנן טיול מאפס"
                        },
                        generateSuggestions:{
                            English:"Generate trips suggestions",
                            Hebrew:"יצר המלצות לטיולים"
                        }
                    },
                },
                confirmationModal:{
                    noteMessage:{
                        English:"You are about to discard note creation",
                        Hebrew:"הינך עלול לבטל את יצירת ההערה",
                    },
                    message:{
                        English:"You are about to loose all of your changes",
                        Hebrew:"הינך עלול לאבד את כל השינויים שביצעת",
                    },
                    highlightMessage:{
                        English:"You are about to discard additional information creation",
                        Hebrew:"הינך הולך לבטל את יצירת המידע הנוסף",
                    },
                    removeMessage:{
                        English:"You are about to remove this item",
                        Hebrew:"הינך עומד להסיר פריט זה",
                    },
                    imageMessage:{
                        English:"You are about to remove this photo",
                        Hebrew:"הינך עומד למחוק תמונה זו לצמיתות",
                    },
                    title:{
                        English:"Attention!",
                        Hebrew:"אזהרה!",
                    },
                    confirmTitle:{
                        English:"Are you sure?",
                        Hebrew:"האם הינך בטוח?",
                    },
                    stay:{
                        English:"Stay",
                        Hebrew:"הישאר",
                    },
                    leave:{
                        English:"Leave",
                        Hebrew:"עזוב"
                    },
                    delete:{
                        English:"Delete",
                        Hebrew:"מחק"
                    },
                    remove:{
                        English:"Remove",
                        Hebrew:"הסר"
                    },
                    cancel:{
                        English:"Cancel",
                        Hebrew:"בטל"
                    },
                    disconnect:{
                        English:"Disconnect",
                        Hebrew:"נתק"
                    },
                    deleteMessage:{
                        English:"You are going to delete this information permanently",
                        Hebrew:"הינך עומד למחוק את המידע הזה באופן קבוע"
                    },
                    removeExpFromPlan:{
                        English:"You are going to remove this experience and its photos for all trip editors",
                        Hebrew:"הינך עומד להסיר את החווייה וכל התמונות שלה לך ולכל עורכי הטיול"
                    },
                    removeTaskFromPlan:{
                        English:"You are about to delete this task permanenetly",
                        Hebrew:"הינך עומד למחוק משימה זו לצמיתות"
                    },
                    removeItemFromPlan:{
                        English:"You are about to remove this item from the trip plan",
                        Hebrew:"הינך עומד להסיר פריט זה מתכנית הטיול"
                    },
                    removeUserTag:{
                        English:"You are about to remove the tagging to this experience",
                        Hebrew:"הינך עומד להסיר את התיוג לחווייה זו"
                    }
                },
                infoModal:{
                    closeButton:{
                        English:"Close",
                        Hebrew:"סגור"
                    }
                },
                manageLinks:{
                    title:{
                        English:"Manage links",
                        Hebrew:"נהל קישורים",
                    },
                    addLinkPlaceHolder: {
                        English:"Add a link",
                        Hebrew:"הוסף קישור",
                    },
                    addButton:{
                        English:"Add",
                        Hebrew:"הוסף",
                    },
                    namePlaceholder:{
                        English:"Title",
                        Hebrew:"כותרת",
                    },
                    updateButton: {
                        English:"Update",
                        Hebrew:"עדכן",
                    },
                    urlTitle:{
                        English:"Url",
                        Hebrew:"קישור"
                    },
                    invalidUrl:{
                        English:"Invalid URL",
                        Hebrew:"קישור לא תקין"
                    }
                },
                addToPlan:{
                    experienceDateMessage:{
                        English:"Experience date is out of trip range, please update the experience date:",
                        Hebrew:"תאריך החוויה מחוץ לתאריכי הטיול, אנא עדכן את התאריך:"
                    },
                    updateDate:{
                        English:"Update Date",
                        Hebrew:"עדכן תאריך"
                    },
                    resetPlace:{
                        English:"Reset place",
                        Hebrew:"אפס מיקום",
                    },
                    resetPlan:{
                        English:"Reset trip",
                        Hebrew:"אפס טיול",
                    },
                    selectPlanPlaceholder:{
                        English:"Select relevant trip",
                        Hebrew:"בחר את הטיול המתאים",
                    },
                    placeTitle:{
                        English:"Add place to trip",
                        Hebrew:"הוסף מקום לטיול"
                    },
                    experienceTitle:{
                        English:"Connect experience to trip",
                        Hebrew:"צרף חוויה לטיול"
                    },
                    button:{
                        English:"Done",
                        Hebrew:"סיים"
                    },
                    choosePlan:{
                        English:"Choose plan from list",
                        Hebrew:"בחר תוכנית מהרשימה"
                    },
                    createNewPlan:{
                        English:"Create new plan",
                        Hebrew:"צור תוכנית חדשה"
                    },
                    chooseDay:{
                        English:"Add to desired day",
                        Hebrew:"הוסף ליום המבוקש"
                    },
                    addHereButton:{
                        English:"Add here",
                        Hebrew:"הוסף כאן"
                    }
                },
                setLocation:{
                    title:{
                        English:"Set place location",
                        Hebrew:"הגדר מיקום",
                    },
                    bySearchButton: {
                        English:"By search",
                        Hebrew:"חיפוש המיקום",
                    },
                    currentLocationButton:{
                        English:"Current location",
                        Hebrew:"מיקום נוכחי",
                    },
                    mapClickButton:{
                        English:"Click on map",
                        Hebrew:"סמן על המפה",
                    },
                    searchPlaceholder: {
                        English:"Choose location",
                        Hebrew:"בחר מיקום",
                    },
                    doneButton:{
                        English:"Update",
                        Hebrew:"עדכן",
                    },
                    locationMessage:{
                        English:"Location permissions are denied. \nPlease click the following link for instructions on how to allow it: ",
                        Hebrew:"הינך חוסם הרשאות לשימוש המיקום שלך. \nבכדי לאשר שימוש במיקומך פעל לפי ההוראות בקישור שלהלן: ",
                    },
                    instructions:{
                        English:"Instructions",
                        Hebrew:"הוראות",
                    },
                    setLocationTree:{
                        title:{
                            English:"Finish defining location",
                            Hebrew:"סיים להגדיר את המיקום",
                        },
                        titleEnding:{
                            English:"location",
                            Hebrew:"",
                        },
                        message:{
                            English:"Place must have a specified country and either region or city or both",
                            Hebrew:"מיקום תקין צריך להחיל מדינה ולפחות אחד מהשניים מבין מחוז / אזור או עיר",
                        },
                        country:{
                            English:"Country",
                            Hebrew:"מדינה",
                        },
                        region:{
                            English:"Region/District",
                            Hebrew:"מחוז",
                        },
                        city:{
                            English:"City/area",
                            Hebrew:"עיר או אזור",
                        },
                        finishButton:{
                            English:"Finish",
                            Hebrew:"סיים",
                        },
                        selectCountry:{
                            English:"Select Country",
                            Hebrew:"בחר מדינה",
                        }
                    }
                }
            },
            tripGenerator:{
                generatedContent:{
                    tripName:{
                        English:"Suggested trip ",
                        Hebrew:"אופציה לטיול",
                    },
                    dislikedMessage:{
                        English:"We want to improve our suggested trips, please let us know why you didn't liked this suggestion. \nMore detailed feedback will help us improve better",
                        Hebrew:"אנחנו רוצים להשתפר בטיולים שאנחנו מייצרים, בבקשה תשתפו איתנו למה לא אהבתם טיול זה. \nככל שנקבל פידבק מפורט יותר כך נוכל יותר להשתפר",
                    },
                    dislikedButton:{
                        English:"Report",
                        Hebrew:"דווח",
                    },
                    cloneMessage:{
                        English:"If you like to save the changes you made, you need to save a personal copy of this trip",
                        Hebrew:"בכדי לשמור את השינויים שביצעת עליך לשמור עותק אישי של הטיול",
                    },
                    cloneButton:{
                        English:"Save trip",
                        Hebrew:"שמור טיול",
                    },
                    discard:{
                        English:"Exit trip",
                        Hebrew:"צא מהטיול",
                    },
                    cloneMessageNotLoggedUser:{
                        English:"You need to be logged in to create or save a trip. We will save your trip if you will sign-up.",
                        Hebrew:"עליך להיותר משתמש מחובר בכדי לייצר או לשמור טיול. אנחנו נשמור את הטיול שלך אם תרשם",
                    },
                    cloneAndLogin:{
                        English:"Login/Sign up",
                        Hebrew:"הרשם/התחבר",
                    },
                    continue:{
                        English:"continue",
                        Hebrew:"המשך",
                    }
                },
                prefernecesForm:{
                    showAccommodationsOnMap:{
                        English:"Show accommodations on map",
                        Hebrew:"הצג מקומות לינה על המפה",
                    },
                    showMorePreferneces:{
                        English:"Show more preferneces",
                        Hebrew:"הצג עוד הגדרות",
                    },
                    preferencesView:{
                        English:"Preferences",
                        Hebrew:"הגדרות",
                    },
                    mapView:{
                        English:"Map view",
                        Hebrew:"הצג מפה",
                    },
                    noDates:{
                        English:"Don't have specific dates",
                        Hebrew:"אין תאריכים מסוימים",
                    },
                    durationAndDates:{
                        English:"Duration and dates",
                        Hebrew:"משך הטיול ותאריכים",
                    },
                    noDatesWarning:{
                        English:"Without dates we cannot check if a place will be open",
                        Hebrew:"ללא תאריכים לא נוכל לבדוק אם המקום פתוח",
                    },
                    title:{
                        English:"Create for me trips suggestions",
                        Hebrew:"צור עבורי המלצות לטיולים",  
                    },
                    tripDestination:{
                        English:"Trip destination",
                        Hebrew:"יעד הטיול",
                    },
                    generalInfo:{
                        English:"General preferences",
                        Hebrew:"העדפות כלליות",
                    },
                    accommodationHeader:{
                        English:"Accommodation",
                        Hebrew:"מקום לינה",
                    },
                    accommodationPlaceholder:{
                        English:"Search accommodation from available",
                        Hebrew:"חפש מקום לינה קיים",
                    },
                    includedPlaces:{
                        English:"Include places in area you don't want to miss",
                        Hebrew:"הוסף מקומות באזור שלא תרצה לפספס",
                    },
                    searchPlacesPlaceholder:{
                        English:"Search for place by name",
                        Hebrew:"חפש מקום לפי שם",
                    },
                    subCategories:{
                        English:"Categories preferences",
                        Hebrew:"העדפת קטגוריות",
                    },
                    searchDestinationPlaceholder:{
                        English:"Where To Go?",
                        Hebrew:"לאיזה יעד?",
                    },
                    selectDifferentDestination:{
                        English:"Change destination",
                        Hebrew:"שנה יעד",
                    },
                    generateTrips:{
                        English:"Generate trips",
                        Hebrew:"צור טיולים",  
                    },
                    tripDuration:{
                        English:"Trip duration in days (max 30)",
                        Hebrew:"אורך הטיול בימים (מקס' 30)",  
                    },
                    tripIntensity:{
                        title:{
                            English:"Trip days intensity",
                            Hebrew:"עומס ימי הטיול",  
                        },
                        medium:{
                            English:"Medium",
                            Hebrew:"בינוני",  
                        },
                        easy:{
                            English:"Easy",
                            Hebrew:"קליל",  
                        },
                        intensive:{
                            English:"Intensive",
                            Hebrew:"עמוס",  
                        }
                    },
                    maxPlacesPerDay:{
                        English:"Places per day (max 7)",
                        Hebrew:"מקומות ליום (מקס' 7)",  
                    },
                    tripRadius:{
                        English:"Trip maximum radius (min 5km)",
                        Hebrew:"רדיוס מקסימלי לטיול (מינ' 5קמ')",  
                    },
                    dailyTripRadius:{
                        English:"Daily maximum radius",
                        Hebrew:"רדיוס מקסימלי ליום",  
                    },
                    distancePostfix:{
                        English:"km",
                        Hebrew:"קמ'",  
                    },
                    mainCategories:{
                        English:"Which existing in destination type of places to include",
                        Hebrew:"אילו סוגי מקומות לכלול מהקיימים ביעד?",  
                    },
                    mainCateogriesMessage:{
                        English:"Trip must to include at least one type of places",
                        Hebrew:"טיול חייב לכלול לפחות סוג אחד של מקומות",
                    },
                    focusMainCateogry:{
                        English:"Trip focus",
                        Hebrew:"מיקוד הטיול",  
                    },
                    includedCategories:{
                        English:"Would you like to definetly include specific categories?",
                        Hebrew:"תרצה לכלול בוודאות קטגוריות מסוימות?",  
                    },
                    excludedCategories:{
                        English:"Exlude following categories from trip",
                        Hebrew:"אל תכלול כלל את הקטגוריות הבאות",  
                    },
                    filterAreaOnMap:{
                        English:"Mark area on the map",
                        Hebrew:"סמן אזור על המפה",  
                    },
                    setMapArea:{
                        English:"Set map area",
                        Hebrew:"הגדר אזור במפה",  
                    },
                    removeMapArea:{
                        English:"Discard map use",
                        Hebrew:"בטל שימוש במפה",  
                    },
                    setAccommodation:{
                        English:"Add accommodation",
                        Hebrew:"הוסף מקום לינה",  
                    },
                    removeAccommodation:{
                        English:"Remove accommodation",
                        Hebrew:"הסר מקום לינה",  
                    },
                    updateAccommodation:{
                        English:"Update accommodation",
                        Hebrew:"עדכן מקום לינה",  
                    },
                    mapTripRadius:{
                        English:"Trip radius",
                        Hebrew:"רדיוס הטיול",  
                    },
                    setAccommodationTitle:{
                        English:"Add accommodation to trip",
                        Hebrew:"הוסף מקום לינה לטיול",  
                    }
                    
                },
                initialScreen:{
                    header:{
                        English:"In planning a trip the hardest part is to start, let us help you with that",
                        Hebrew:"בתכנון טיול החלק הקשה ביותר זה להתחיל, תנו לנו לעזור לכם עם זה",  
                    },
                    messageFirstPart:{
                        English:"We can create for you list of personalized trips templates from which you can choose the best that suits you and proceed with planning right away",
                        Hebrew:"אנחנו יכולים ליצור עבורכם תבניות טיולים מותאמים אישית שתוכלו לבחור מהם את הטיול שהכי מתאים לכם, ומיד להתקדם בתכנון",  
                    },
                    messageSecondPart:{
                        English:"You can add these trips to favorites or clone and start adjusting them right away with all the available planning tools and options",
                        Hebrew:"ניתן להוסיף את הטיולים המוצעים למועדפים או לשכפל ולהתחיל לעשות שינויים בעזרת כלי התכנון הקיימים ",  
                    },
                    disclaimarMessage:{
                        English:"* Suggested trips not include transportation or accommodation information, you can manually add them",
                        Hebrew:"* הטיולים המוצעים לא כוללים מידע על לינה או תחבורה, ניתן להוסיף אותם באופן ידני",  
                    },
                    covidDisclaimar:{
                        English:"** Due to covid-19, places activity status may not be updated",
                        Hebrew:"** עקב נגיף הקורונה ייתכן ומידע על פעילות המקום לא מעודכן",  
                    },
                    searchMessage:{
                        English:"Simply provide a desired destination with some preferences and let our algorithms run for you",
                        Hebrew:"פשוט תגדירו יעד והעדפות ותנו לאלגוריתם שלנו לרוץ עבורכם",  
                    }
                },
                tripAreaIssue:{
                    areaOutOfBoundsTitle:{
                        English:"You moved trip area too far from selected destination",
                        Hebrew:"הזזת את שטח הטיול רחוק מדי מאזור היעד הנבחר",  
                    },
                    areaOutOfBoundsSubTitle:{
                        English:"You can either choose broader destination or recenter trip area back to destination center",
                        Hebrew:"הינך יכול לבחור יעד המכסה אזור נרחב יותר או להחזיר את מרכז שטח הטיול לברירת מחדל עבור היעד הנבחר",  
                    },
                    recenter:{
                        English:"Recenter",
                        Hebrew:"אפס מרכז טיול",  
                    },
                    resetDestination:{
                        English:"Change Destination",
                        Hebrew:"שנה יעד",  
                    },
                    smallTripRadiusTitle:{
                        English:"Trip radius is too small to include enough places for the trip",
                        Hebrew:"רדיוס הטיול קטן מדי בכדי לכלול מספיק מקומות לטיול",  
                    },
                    smallTripRadiusText:{
                        English:"Make sure there are enough potential places for trip generation in area, eithr increase area radius or select more places categories types",
                        Hebrew:"וודא כי ישנם מספיק מקומות פוטנציאליים ליצירת טיול, לשם כך אנא או הגדל רדיוס של אזור הטיול או/ו הוסף סוגי מקומות לטיול ",  
                    }
                }
            },
            misc:{
                loginHandler:{
                    saveCreatedTripMessage:{
                        English:"Would you like to keep the created trip",
                        Hebrew:"תרצה לשמור את הטיול שיצרת",
                    },
                    yesButton:{
                        English:"Yes",
                        Hebrew:"כן",
                    },
                    noButton:{
                        English:"No",
                        Hebrew:"לא",
                    }
                },
                favorites:{
                    addToFavorite:{
                        title:{
                            English:"Add to favorite",
                            Hebrew:"הוסף למועדפים",
                        },
                        predefinedTags:{
                            English:"Predefined search tags",
                            Hebrew:"תגיות מובנות שניתן לחפש לפיהם"
                        },
                        tagsPlaceholder:{
                            English:"Add tags for easy search",
                            Hebrew:"הוסף תגיות שיעזרו לחפש בקלות"
                        },
                        updateTitle:{
                            English:"Update favorite tags",
                            Hebrew:"עדכן תגיות מועדפות",
                        }
                    }
                },
                buttons:{
                    saveButton:{
                        English:"Save",
                        Hebrew:"שמור",
                    },
                    updateButton:{
                        English:"Update",
                        Hebrew:"עדכן",
                    },
                    createButton:{
                        English:"Create",
                        Hebrew:"צור",
                    }
                },
                placesName:{
                    English:"Places",
                    Hebrew:"מקומות"
                },
                tripsName:{
                    English:"Trips",
                    Hebrew:"טיולים"
                },
                experiencesName:{
                    English:"Experiences",
                    Hebrew:"חוויות"
                },
                placeFeedExperiences:{
                    English:"Users Experiences (public)",
                    Hebrew:"חוויות משתמשים (ציבוריות)"
                },
                navigation:{
                    pagesNavigation:{
                        next:{
                            English:"Next",
                            Hebrew:"הבא"
                        },
                        previous:{
                            English:"Prev",
                            Hebrew:"קודם"
                        }
                    },
                   
                },
                loadingMessage:{
                    English:"Loading...",
                    Hebrew:"טוען..."
                },
                mainCategories:{
                    cultural:{
                        English:"Cultural & sightseeing",
                        Hebrew:"תרבות ואתרי תיירות",
                    },
                    nature:{
                        English:"Nature",
                        Hebrew:"טבע",
                    },
                    activities:{
                        English:"Activities",
                        Hebrew:"פעילויות",
                    },
                    sport:{
                        English:"Sport",
                        Hebrew:"ספורט",
                    },
                    food:{
                        English:"Foods & drinks",
                        Hebrew:"אוכל ושתייה",
                    },
                    accommodation:{
                        English:"Accomodation",
                        Hebrew:"לינה",
                    },
                    dislikes:{
                        English:"Categories I'm not interested in",
                        Hebrew:"קטגוריות שלא מעניינות אותי",
                    }
                }
            },
            navBar:{
                selectedContentMessage:{
                    discover:{
                        English:"Discover",
                        Hebrew:"גלה מקומות",
                    },
                    favorite:{
                        English:"My Favorite",
                        Hebrew:"מועדפים",
                    },
                    myContent: {
                        English:"Personal Space",
                        Hebrew: "אזור אישי",
                    },
                    welcome: {
                        English:"Welcome",
                        Hebrew:"ברוכים הבאים",
                    }
                },
                settings:{
                    welcomeMessage:{
                        title:{
                            English:"Welcome to PlanEXp",
                            Hebrew:"ברוכים הבאים ל PlanEXp",
                        },
                        message:{
                            English:"Before you start exploring, discovering and planning your next experience, you can adjust personal information, and select interest you want tell us about",
                            Hebrew:"רגע לפני שתתחיל לנווט, לחפש ולתכנן את החווייה הבאה שלך, הינך יכול לעדכן פרטים אישיים ולבחור תחומי עניין שברצונך שנדע עליהם",
                        },
                        closeButton:{
                            English:"Let's start",
                            Hebrew:"בוא נתחיל",
                        }
                    },
                    title:{
                        English:"Settings",
                        Hebrew:"הגדרות",
                    },
                    languageMessage:{
                        English:"Please select prefered system language",
                        Hebrew:"אנא בחר את שפת המערכת המועדפת",
                    },
                    finishCrop:{
                        English:"Finish image adjustment",
                        Hebrew:"סיים התאמת תמונה",
                    },
                    discardCrop:{
                        English:"Discard",
                        Hebrew:"בטל",
                    },
                    uploadButton:{
                        English:"Upload profile image",
                        Hebrew:"העלה תמונת פרופיל",
                    },
                    settingsTitle:{
                        English:"General settings",
                        Hebrew:"הגדרות כלליות",
                    },
                    interestsTitle:{
                        English:"Interests",
                        Hebrew:"תחומי עניין",
                    },
                    profileTitle:{
                        English:"My Profile",
                        Hebrew:"פרופיל שלי",
                    },
                    profileStats:{
                        joined:{
                            English:"Joined:",
                            Hebrew:"הצטרף:", 
                        },
                        lastSeen:{
                            English:"Last seen:",
                            Hebrew:"התחבר לאחרונה:", 
                        },
                        createdPlaces:{
                            English:"Created places:",
                            Hebrew:"מקומות נוצרו:", 
                        },
                        favoritePlaces:{
                            English:"Places saved to favorites:",
                            Hebrew:"מקומות שמורים במועדפים:", 
                        },
                        sharedExperiences:{
                            English:"Shared experiences:",
                            Hebrew:"שיתף חוויות:", 
                        },
                        sharedAnnouncements:{
                            English:"Shared announcements:",
                            Hebrew:"שיתף הודעות:", 
                        },
                        createdPlans:{
                            English:"Created trips:",
                            Hebrew:"יצר טיולים:", 
                        },
                        sharedPlans:{
                            English:"Shared trip plans:",
                            Hebrew:"שיתף תוכניות טיולים:", 
                        },
                        sharedJourneys:{
                            English:"Shared journeys:",
                            Hebrew:"שיתף מסעות:", 
                        },
                    },
                    userData:{
                        displayName:{
                            English:"Full name:",
                            Hebrew:"שם מלא:", 
                        },
                        residenceCountry:{
                            English:"Country of residence:",
                            Hebrew:"מדינת מגורים:", 
                        },
                        defaultCountry:{
                            English:"Default country:",
                            Hebrew:"מדינת בררת מחדל:", 
                        }
                    },
                    interestsHeader:{
                        English:"Select your interests:",
                        Hebrew:"בחר את תחומי העניין שלך:", 
                    }
                },
                loginMessage:{
                    English:"Login",
                    Hebrew:"התחבר"
                },
                menu:{
                    settings:{
                        English:"Settings",
                        Hebrew:"הגדרות",
                    },
                    myContent:{
                        English:"Personal space",
                        Hebrew:"אזור אישי",
                    },
                    logout:{
                        English:"Logout",
                        Hebrew:"התנתק",
                    },
                    contactUs:{
                        English:"Contact us",
                        Hebrew:"צור קשר",
                    }
                },
            },
            loginForm:{
                resetPassword:{
                    English:"Reset password",
                    Hebrew:"אפס סיסמא"
                },
                signInTitle:{
                    English:"Sign In to PlanEXp",
                    Hebrew:"הירשם ל PlanEXp"
                },
                firstNamePlaceholder:{
                    English:"First Name",
                    Hebrew:"שם פרטי",
                },
                lastNamePlaceholder:{
                    English:"Last Name",
                    Hebrew:"שם משפחה",
                },
                emailPlaceholder:{
                    English:"Email",
                    Hebrew:"אימייל",
                },
                passwordPlaceholder:{
                    English:"Password",
                    Hebrew:"סיסמא"
                },
                emailErrorMessage:{
                    English:"Email is not valid",
                    Hebrew:"כתובת האימייל לא תקינה"
                },
                weakPasswordMessage:{
                    English:"Weak password. Include capital and non capital letters as well numbers and symbols",
                    Hebrew:"סיסמא חלשה. השתמשו באותיות גדולות, קטנות, מספרים וסימנים "
                },
                passwordErrorMessage:{
                    English:"Password not strong enough",
                    Hebrew:"הסיסמא אינה חזקה מספיק"
                },
                signInButton:{
                    English:"Sign Up",
                    Hebrew:"הירשם"
                },
                alreadyHaveAccountMessage:{
                    English:"Already have an account?",
                    Hebrew:"כבר רשום?"
                },
                dontHaveAccountMessage:{
                    English:"Don't have an account?",
                    Hebrew:"עדיין לא רשום?"
                },
                loginButton:{
                    English:"Login",
                    Hebrew:"כנס"
                },
                signupButton:{
                    English:"Sign Up",
                    Hebrew:"הרשם"
                },
                loginTitle:{
                    English:"Join PlanEXp and start planning your next experience",
                    Hebrew:"הצטרף ל PlanEXp והחל לתכנן את החווייה הבאה"
                },
                /* signInWithGoogle:{
                    English:"Sign Up with Google",
                    Hebrew:"הרשם עם Google"
                }, */
                continueWithGoogle:{
                    English:"Continue with Google",
                    Hebrew:"המשך עם Google"
                },
                continueWithFacebook:{
                    English:"Continue with Facebook",
                    Hebrew:"המשך עם Facebook"
                },
                goBack:{
                    English:"Return",
                    Hebrew:"חזור"
                },
                forgotPassword:{
                    English:"Forgot password",
                    Hebrew:"שכחתי סיסמא"
                },
            },
            contactForm:{
                requestPlace:{
                    English:"Request issued for place: ",
                    Hebrew:"בקשה הינה עבור מקום: ",
                },
                topics:{
                    requestOwnership:{
                        English:"Request place ownership",
                        Hebrew:"בקשה לבעלות על מקום",
                        id:"requestOwnership"
                    },
                    imageCopyrights:{
                        English:"Image violates copyrights",
                        Hebrew:"תמונה פוגעת בזכויות יוצרים",
                        id:"imageCopyrights"
                    },
                    reportIssue:{
                        English:"Reporting an issue in the site",
                        Hebrew:"דיווח על תקלה באתר",
                        id:"reportIssue"
                    },
                    suggestFeature:{
                        English:"Suggestign improvement or new feature",
                        Hebrew:"הצעה ליכולת חדשה או שיפור",
                        id:"suggestFeature"
                    },
                    deleteUser:{
                        English:"Delete my user",
                        Hebrew:"מחר את המשתמש שלי",
                        id:"deleteUser"
                    },
                    other:{
                        English:"Other",
                        Hebrew:"אחר",
                        id:"other"
                    }
                },
                contentPlaceholder:{
                    English:"Write your request or message content here",
                    Hebrew:"רשום כאן את תוכן הבקשה או הודעה",
                },
                topic:{
                    English:"How can we help you?",
                    Hebrew:"כיצד נוכל לעזור לך?",
                },
                displayNamePlaceholder:{
                    English:"Full Name",
                    Hebrew:"שם מלא",
                },
                emailPlaceholder:{
                    English:"Email",
                    Hebrew:"אימייל",
                },
                passwordPlaceholder:{
                    English:"Password",
                    Hebrew:"סיסמא"
                },
                emailErrorMessage:{
                    English:"Email is not valid",
                    Hebrew:"כתובת האימייל לא תקינה"
                },
               
                sendButton:{
                    English:"Send",
                    Hebrew:"שלח"
                },
                title:{
                    English:"Contact us",
                    Hebrew:"צור איתנו קשר"
                },
            },
            favorite:{
                searchSection:{
                    searchBarPlacesPlaceholder:{
                        English:"Search by name, location, categry, special tags",
                        Hebrew:"חפש לפי שם, מיקום, קטגוריה ותגיות מיוחדות",
                    },
                    searchBarTripsPlaceholder:{
                        English:"Search by name, location or special tags",
                        Hebrew:"חפש לפי שם, מיקום או תגיות מיוחדות",
                    }
                },
                noContentMessage:{
                    noPlaces:{
                        English:"You didn't saved yet any places under favorites",
                        Hebrew:"לא שמרת עדיין שום מקום במועדפים",
                    },
                    noPlacesResults:{
                        English:"No results for search keywords",
                        Hebrew:"אין תוצאות למילות החיפוש",
                    },
                    noTripsResults:{
                        English:"No results for search keywords",
                        Hebrew:"אין תוצאות למילות החיפוש",
                    },
                    noTrips:{
                        English:"You didn't saved any trip under favorites",
                        Hebrew:"עדיין לא שמרת שום טיול במועדפים",
                    }
                }
            },
            myContent:{
                tripsStages:{
                    dreamStageTrips:{
                        English:"Dreaming",
                        Hebrew: "חולם",
                    },
                    currentTrips:{
                        English:"Current",
                        Hebrew: "נוכחי",
                    },
                    futureTrips:{
                        English:"Future",
                        Hebrew: "עתידי",
                    },
                    pastTrips:{
                        English:"Past",
                        Hebrew: "מהעבר",
                    }
                },
                searchSection:{
                    searchBarPlacesPlaceholder:{
                        English:"Search by name, location, categry",
                        Hebrew:"חפש לפי שם, מיקום, קטגוריה",
                    },
                    searchBarTripsPlaceholder:{
                        English:"Search by name or location",
                        Hebrew:"חפש לפי שם או מיקום",
                    },
                    searchBarExperiencesPlaceholder:{
                        English:"Search by place name, location or category",
                        Hebrew:"חפש לפי שם מקום, מיקום או קטגוריה",
                    }
                },
                noContentMessage:{
                    noPlaces:{
                        English:"There are no places you have created or under your ownership",
                        Hebrew:"אין מקומות שיצרת או שהינם בבעלותך",
                    },
                    noTrips:{
                        English:"You didn't created any trips yet",
                        Hebrew:"עדיין לא יצרת שום טיול",
                    },
                    noExperiences:{
                        English:"You didn't captured yet any experience",
                        Hebrew:"עדיין לא שמרת ותיעדת שום חווייה",
                    },
                    noPlacesResults:{
                        English:"No results for search keywords",
                        Hebrew:"אין תוצאות למילות החיפוש",
                    },
                    noTripsResults:{
                        English:"No results for search keywords",
                        Hebrew:"אין תוצאות למילות החיפוש",
                    },
                    noExperiencesResults:{
                        English:"No results for search keywords",
                        Hebrew:"אין תוצאות למילות החיפוש",
                    },
                    createTrip:{
                        English:"Create new trip",
                        Hebrew:"צור טיול חדש",
                    }
                }
            },
            welcomePage:{
                searchSection:{
                    currentCountry:{
                        English:"Explore Places in ",
                        Hebrew:"גלה מקומות ב",
                    },
                    searchBarPlaceholder:{
                        English:"Choose country, region or city",
                        Hebrew: "בחר מדינה, אזור או עיר",
                    },
                    searchBarTripsPlaceholder:{
                        English:"Choose destination",
                        Hebrew: "בחר יעד",
                    },
                    discoverTripPlans:{
                        English:"Explore Trips",
                        Hebrew:"גלה טיולים",
                    }
                },
                welcomeMainMessage:{
                    English:"Plan Your Next Experience",
                    Hebrew:"תכנן את החווייה הבאה שלך",
                },
                welcomeSubMessage:{
                    English:"Discover . Plan . Experience",
                    Hebrew:"גלה . תכנן . תחווה",
                },
                generateTrips:{
                    English:"Generate personalized trips",
                    Hebrew:"צור עבורי טיולים",
                },
                recommendedMyCountry:{
                    title:{
                        English:"Recommended for you in ",
                        Hebrew:"מומלצים עבורך ב",
                    },
                    userRecommendedCategory:{
                        English:"You showed interest in ",
                        Hebrew:"הראת עניין ב",
                    },
                    defaultRecommendedCategory:{
                        English:"Popular ",
                        Hebrew:"פופלריים ",
                    }
                },
                experiencesFeed:{
                    title:{
                        English:"Experiences Feed",
                        Hebrew:"חוויות",
                    },
                },
                lastPlaces:{
                    title:{
                        English:"Latest Places Viewed",
                        Hebrew:"מקומות אחרונים שנצפו",
                    },
                    allFavoritePlacesButton:{
                        English:"See all your favorite places",
                        Hebrew:"צפה בכל המקומות המועדפים שלך"
                    },
                    allFavoritePlansButton:{
                        English:"See all your favorite plans",
                        Hebrew:"צפה בכל תוכניות הטיולים המועדפים שלך"
                    },
                   
                },
                plans:{
                    title:{
                        English:"My Latest Trip",
                        Hebrew:"הטיול האחרון שלי",
                    },
                    allUserPlansButton:{
                        English:"See all your trips",
                        Hebrew:"צפה בכל הטיולים שלך"
                    }
                },
                experiences:{
                    showMoreExperiences:{
                        English:"Load more experiences",
                        Hebrew:"טען עוד חוויות"
                    },
                    noExperiencesMessage:{
                        English:"Neither you or any of your friends shared any experience yet",
                        Hebrew:"אינך ואף אחד מחבריך לא שיתפתם עדיין שום חוויה"
                    },
                    shareExperience:{
                        English:"Share Experience",
                        Hebrew:"שתף חוויה"
                    }
                },
                
            },
            discover:{
                tripsToolbar:{
                    suggestedTripsTab:{
                        English:"Suggested trips",
                        Hebrew:"הצעות לטיול"
                    },
                    exploreTripsTab:{
                        English:"Search existing trips",
                        Hebrew:"חפש טיולים קיימים"
                    },
                    updatePreferneces:{
                        English:"Update preferences",
                        Hebrew:"עדכן העדפות"
                    },
                    generateNewTrips:{
                        English:"Run again",
                        Hebrew:"הרץ שוב"
                    }
                },
                noContentMessage:{
                    noTripsResults:{
                        English:"No trips found for your search criteria",
                        Hebrew:"לא נמצאו טיולים העונים לקריטריוני החיפוש",
                    },
                    noExperiencesFeed:{
                        English:"No trips found for your search criteria",
                        Hebrew:"לא נמצאו טיולים העונים לקריטריוני החיפוש",
                    },
                    noPlacesNoLocation:{
                        English:"You must to search for destination first (country,region,city...)",
                        Hebrew:"הינך חייב לחפש תחילה יעד גאוגרפי (מדינה, אזור, עיר...)",
                    },
                    noPlacesMessage:{
                        English:"No places found with specified filters, either change filters or you can create the place you are looking for if it not yet exist",
                        Hebrew:"לא מצאו מקומות עבור הפילטרים שנבחרו. הינך יכול או לשנות את הפילטרים או באם המקום הספציפי אינו קיים במערכת ליצור אותו",
                    },
                    createPlaceButton:{
                        English:"Create Place",
                        Hebrew:"צור מקום",
                    }
                },
                searchSection:{
                    searchBarPlaceholder:{
                        English:"Choose destination or search by name",
                        Hebrew: "בחר יעד או חפש לפי שם",
                    },
                    searchBarTripsPlaceholder:{
                        English:"Search trips by destination or by tags",
                        Hebrew: "חפש טיולים לפי יעד או תגיות",
                    },
                    openFilter:{
                        English:"Filters",
                        Hebrew:"פילטרים",
                    },
                    showMap:{
                        English:"Map View",
                        Hebrew:"תצוגת מפה",
                    },
                    hideMap:{
                        English:"Hide Map",
                        Hebrew:"הסתר מפה",
                    }
                },
                filters:{
                    title:{
                        English:"Filters",
                        Hebrew:"פילטרים",
                    },
                    mobileClose:{
                        English:"Show Results",
                        Hebrew:"הצג תוצאות",
                    },
                    onlyMapArea:{
                        English:"Map boundaries",
                        Hebrew:"בגבולות המפה"
                    },
                    showOnMap:{
                        English:"Show on map",
                        Hebrew:"הצג על המפה",
                    },
                    locationFilters:{
                        title:{
                            English:"Location filters",
                            Hebrew:"סינון מיקום"
                        },
                        showAllLocations:{
                            English:"Show all location filters",
                            Hebrew:"הצג את כל סנני מיקום"
                        },
                        closeLocationsFilters:{
                            English:"Close location filters",
                            Hebrew:"סגור את סנני המיקום"
                        }
                    },
                    categoryFilters:{
                        title:{
                            English:"Category filters",
                            Hebrew:"סינון קטגוריה"
                        },
                        showAllCategories:{
                            English:"Show all category filters",
                            Hebrew:"הצג את כל סנני קטגוריה"
                        },
                        closeCategoriesFilters:{
                            English:"Close category filters",
                            Hebrew:"סגור את סנני קטגוריה"
                        }
                    },
                    dayFilters:{
                        title:{
                            English:"Oppened on following days",
                            Hebrew:"פתוח בימים הבאים" 
                        }
                    },
                    planFilters:{
                        countrySelection:{
                            title:{
                                English:"Select countries included in trips",
                                Hebrew:"בחר מדינות הכלולות בטיול" 
                            }
                        },
                        filteredLocationTags:{
                            English:"Trip destination",
                            Hebrew:"יעד הטיול"
                        },
                        filteredAdditionalTags:{
                            English:"Filter tags applied",
                            Hebrew:"תגיות פילטור בשימוש"
                        },
                        combineLocationTags:{
                            English:"Trip must have all selected destinations",
                            Hebrew:"טיול צריך להכיל את כל היעדים שנבחרו"
                        },
                        combineLocationsMessage:{
                            English:"You can combine up to 3 destinations",
                            Hebrew:"ניתן לאחד עד 3 יעדים"
                        },
                        notCombineLocationsMessage:{
                            English:"You can choose up to 10 destinations",
                            Hebrew:"ניתן לבחור עד 10 יעדים"
                        },
                        filterParticipants:{
                            English:"Filter by age",
                            Hebrew:"סנן לפי גילאים"
                        },
                        durationFilter:{
                            English:"Trip duration",
                            Hebrew:"אורך הטיול"
                        },
                        participants:{
                            English:"Include children",
                            Hebrew:"כולל ילדים"
                        }
                    }

                },
                map:{
                    showInMapArea:{
                        English:"Search in map",
                        Hebrew:"חפש במפה",
                    },
                }
            },
            placeCharacteristics:{
                placeCharacteristicsTitle:{
                    English:"Places Characteristics",
                    Hebrew:"מאפייני מקום"
                },
                generalCharacteristics:{//comme with icons
                    title:{
                        English:"General Characteristics",
                        Hebrew:"מאפייני כלליים"
                    },
                    accessibilityHeader:{
                        English:"Accessbility",
                        Hebrew:"נגישות",
                    },
                    parkingHeader:{
                        English:"Parking exist",
                        Hebrew:"יש חנייה",
                    },
                    freeEntranceHeader:{
                        English:"Free entrance",
                        Hebrew:"כניסה חינם",
                    },
                    petsAllowedHeader:{
                        English:"Pets Friendsly",
                        Hebrew:"בעלי חיים",
                    },
                },
                audienceCharacteristics:{
                    title:{
                        English:"Audience",
                        Hebrew:"קהל יעד"
                    },
                    ageLimit:{
                        English:"Age limit",
                        Hebrew:"הגבלת גיל"
                    }
                },
                foodPlaceCharacteristics:{
                    title:{
                        English:"Food places related characteristscs",
                        Hebrew:"מאפייני מקומות אוכל ושתייה"
                    },
                    placeKosher:{
                        English:"Place Kosher",
                        Hebrew:"כשרות"
                    },
                    placeServices:{
                        English:"Place Services",
                        Hebrew:"שרותי המקום"
                    },
                    diningOptions:{
                        English:"Dining Options",
                        Hebrew:"אפשרויות שרות"
                    },
                },
                restaurantCharacteristics:{
                    title:{
                        English:"Restaurant specific characteristscs",
                        Hebrew:"מאפיינים יחודיים למסעדות"
                    },
                    restuarantCuisine:{
                        English:"Cuisine/restaurant type",
                        Hebrew:"מטבח/סוג מסעדה"
                    },
                    restaurantCategory:{
                        English:"Restaurant Category",
                        Hebrew:"סגנון מסעדה"
                    }
                },
                barCharacteristics:{
                    title:{
                        English:"Bar specific characteristscs",
                        Hebrew:"מאפיינים יחודיים לברים"
                    },
                    barType:{
                        English:"Bar Type",
                        Hebrew:"סגנון בר" 
                    }
                },
                natureCharacteristics:{
                    title:{
                        English:"Nature related characteristscs",
                        Hebrew:"מאפיינים קשורים לטבע"
                    },
                    thingsToSee:{
                        English:"Things to Watch",
                        Hebrew:"דברים לצפייה"
                    },
                    general:{
                        English:"General",
                        Hebrew:"כללי"
                    },
                    seasons:{
                        English:"Seasons",
                        Hebrew:"עונות",
                    },
                    allSeasons:{
                        English:"All year long",
                        Hebrew:"כל השנה",
                    }
                },
                trailCharacteristics:{
                    title:{
                        English:"Hiking Trail specific characteristscs",
                        Hebrew:"מאפיינים יחודיים למסלול טיולים"
                    },
                    titleWithDifficulty:{
                        English:"Hiking Trail specific characteristscs and difficulty",
                        Hebrew:"מאפיינים יחודיים ורמת קושי המסלול "
                    },
                    duration:{
                        English:"Duration",
                        Hebrew:"משך",
                    },
                    visitDuration:{
                        English:"Approx. Visit duration (hours)",
                        Hebrew:"משך פעילות משוער (בשעות)",
                    },
                    hours:{
                        English:"Hours",
                        Hebrew:"שעות",
                    },
                    days:{
                        English:"Days",
                        Hebrew:"ימים",
                    },
                    distance:{
                        English:"Distance",
                        Hebrew:"מרחק",
                    },
                    km:{
                        English:"KM",
                        Hebrew:"ק'מ",
                    },
                    miles:{
                        English:"Miles",
                        Hebrew:"מיל'",
                    },
                    halfDay:{
                        English:"Up to half a day",
                        Hebrew:"עד חצי יום"
                    },
                    fullDay:{
                        English:"Up to full day",
                        Hebrew:"עד יום שלם"
                    },   
                    difficulty:{
                        title:{
                            English:"Difficulty level",
                            Hebrew:"רמת קושי",
                        },
                    },

                },
                museumCharacteristics:{
                    title:{
                        English:"Museum specific characteristscs",
                        Hebrew:"מאפיינים יחודיים למוזאון"
                    },
                    museumType:{
                        English:"Museum Subjects",
                        Hebrew:"נושאי המוזאון"
                    }
                },
                mtbTrailCharacteristics:{ // include trail characteristics
                    title:{
                        English:"MTB specific characteristscs",
                        Hebrew:"מאפיינים יחודיים לאופני הרים"
                    },
                    mtbTrailType:{
                        English:"Trail type",
                        Hebrew:"סוג מסלול"
                    },
                    bikeRideLevel:{
                        title:{
                            English:"Riding Level",
                            Hebrew:"רמת רכיבה"
                        },     
                    }
                },
                parkOrCampingCharacteristics:{
                    parkOrCampingFacilities:{
                        title:{
                            English:"Site facilities",
                            Hebrew:"מתקני האתר" 
                        },
                    },
                },
                beachCharacteristics:{
                    beachFacilities:{
                        title:{
                            English:"Existing facilities",
                            Hebrew:"מתקני חוף קיימים" 
                        },
                    },
                    title:{
                        English:"Beach specific characteristics",
                        Hebrew:"מאפיינים יחודיים לחוף" 
                    },
                    beachActivities:{
                        title:{
                            English:"Beach activities allowed",
                            Hebrew:"פעילויות מותרות בחוף"
                        },
                    }
                    
                },
                guidingCharacteristics:{// e.g. for museums
                    title:{
                        English:"Guides",
                        Hebrew:"סיורים והדרכות"
                    },
                    hasGuidedTours:{
                        English:"Guided Tours",
                        Hebrew:"סיורים מודרכים"
                    },
                    hasAudioGuide:{
                        English:"Audio Guide",
                        Hebrew:"הדרכה קולית"
                    },
                }
            },
            plan:{
                planWall:{
                    days:{
                        English:"Days",
                        Hebrew:"ימים",
                    }
                },
                createPlan:{
                    title:{
                        English:"Create new trip plan",
                        Hebrew:"צור תוכנית לטיול חדש",
                    },
                    imageSectionHeader:{
                        English:"Add cover image for the trip",
                        Hebrew:"הוסף תמונת כריכה לטיול",
                    },
                    travelWithKids:{
                        English:"Travel with kids",
                        Hebrew:"מטיילים עם ילדים",
                    },
                    datesHeader:{
                        English:"Select dates",
                        Hebrew:"בחר תאריכים",
                    },
                    planDurationHeader:{
                        English:"Trip duration in days",
                        Hebrew:"משך הטיול בימים",
                    },
                    noDatesMessage:{
                        English:"Havn't decided on a date yet... Dream and get inspired",
                        Hebrew:"אין עדיין תאריך... חלום וקבל השראה",
                    },
                    durationMessage:{
                        English:"Trip duration",
                        Hebrew:"משך הטיול",
                    },
                    singleDay:{
                        English:"Single day",
                        Hebrew:"יום אחד",
                    },
                    multipleDays:{
                        English:"Multiple days",
                        Hebrew:"מספר ימים",
                    },
                    participantsHeader:{
                        English:"Participants",
                        Hebrew:"משתתפים",
                    },
                    adults:{
                        English:"Adults",
                        Hebrew:"מבוגרים",
                    },
                    kids:{
                        English:"Kids",
                        Hebrew:"ילדים",
                    },
                    toddlers:{
                        English:"Toddlers",
                        Hebrew:"פעוטות",
                    },
                    coverImageHeader:{
                        English:"Add cover image",
                        Hebrew:"הוסף תמונת כריכה",
                    },
                    titlePlaceholder:{
                        English:"Name your trip",
                        Hebrew:"הכנס שם לטיול",
                    },
                    startMessagePrefix:{
                        English:"Choose start date for your ",
                        Hebrew:"בחר את תאריך תחילת הטיול באורך ",
                    },
                    startMessagePostfix:{
                        English:" days trip",
                        Hebrew:" ימים",
                    },
                    participants:{
                        adults:{
                            English:"Adults only",
                            Hebrew:"מבוגרים בלבד",
                        },
                        teen:{
                            English:"Kids 6+",
                            Hebrew:"ילדים 6+",
                        },
                        kid:{
                            English:"Kids 3-5 ",
                            Hebrew:"ילדים 3-5",
                        },
                        infant:{
                            English:"Babies",
                            Hebrew:"תינוקות",
                        }
                    },
                    publicPlan:{
                        descriptionPlaceholder:{
                            English:"Add a description about this trip plan",
                            Hebrew:"הוסף תאור עבור תוכנית טיול זה",
                        },
                        addTagsPlaceHolder:{
                            English:"Add additional search tags to find this plan",
                            Hebrew:"הוסף תגיות נוספות לעזור למצוא תוכנית זו",
                        }
                    },
                    accommodation:{
                        categoryPlaceholder:{
                            English:"Accommodation type",
                            Hebrew:"סוג מקום הלינה",
                        },
                        accommodationCountry:{
                            English:"Accommodation country",
                            Hebrew:"מדינת מקום הלינה",
                        },
                        addButton:{
                            English:"Add",
                            Hebrew:"הוסף"
                        },
                        finish:{
                            English:"Finish",
                            Hebrew:"סיים"
                        },
                        allDays:{
                            English:"All nights",
                            Hebrew:"כל הלילות" 
                        },
                        daysSelectionTitl:{
                            English:"Select relevant nights",
                            Hebrew:"בחר לילות רלוונטיים" 
                        }
                    },
                    anonymousUser:{
                        messageTitle:{
                            English:"Signed users can do much more",
                            Hebrew:"משתמשים רשומים יכולים לעשות הרבה יותר"
                        },
                        subMessage:{
                            English:"Have more then one trip\nPlan together with firends\nSave experiences with photos to trip album\nShare trip with others",
                            Hebrew:"לשמור במקביל יותר מטיול אחד\nלתכנן ביחד עם חברים\nלשמור חוויות עם תמונות באלבום הטיול\nלשתף את הטיול עם אחרים"
                        },
                        signUpButton:{
                            English:"Sign-up (it's free)",
                            Hebrew:"הרשם (זה בחינם)"
                        },
                        continueButton:{
                            English:"Continue",
                            Hebrew:"המשך"
                        }
                    }
                },
                viewPlan:{
                    note:{
                        createTitle:{
                            English:"Create Note",
                            Hebrew:"צור הערה"
                        },
                        topicPlaceholder:{
                            English:"Topic...",
                            Hebrew:"נושא..."
                        },
                        textPlaceholder:{
                            English:"Add text",
                            Hebrew:"הוסף טקסט"
                        },
                        updateButton:{
                            English:"Update",
                            Hebrew:"עדכן"
                        }
                    },
                    vote:{
                        voteTitle:{
                            English:"Voting",
                            Hebrew:"הצבעה",
                        },
                        totalVotes:{
                            English:"Total votes: ",
                            Hebrew:"סך הכל הצביעו: ",
                        },
                        liked:{
                            English:" Liked",
                            Hebrew:" אהבו",
                        }
                    },
                    changesLog:{
                        ideasList:{
                            English:" ideas list",
                            Hebrew:" רשימת רעיונות",
                        },
                        day:{
                            English:" day ",
                            Hebrew:" יום ",
                        },
                        removedPlace:{
                            English:" removed place ",
                            Hebrew:" הסיר את ",
                        },
                        removedNote:{
                            English:" removed note ",
                            Hebrew:" הסיר הערה ",
                        },
                        removedTransport:{
                            English:" removed transport ",
                            Hebrew:" הסיר תחבורה ",
                        },
                        to:{
                            English:" to ",
                            Hebrew:" אל ",
                        },
                        from:{
                            English:" from",
                            Hebrew:" מ",
                        },
                        moved:{
                            English:" moved ",
                            Hebrew:" הזיז ",
                        },
                        addedPlace:{
                            English:" added place ",
                            Hebrew:" הוסיף מיקום ",
                        },
                        updatedTask:{
                            English:" updated task ",
                            Hebrew:" עדכן משימה ",
                        },
                        removedTask:{
                            English:" removed task ",
                            Hebrew:" מחק משימה ",
                        },
                        updatedNote:{
                            English:" updated note ",
                            Hebrew:" עדכן הערה ",
                        },
                        createdNote:{
                            English:" created note ",
                            Hebrew:" יצר הערה ",
                        },
                        createdTransport:{
                            English:" created transport ",
                            Hebrew:" יצר תחבורה ",
                        },
                        updatedTransport:{
                            English:" updated transport ",
                            Hebrew:" עדכן תחבורה ",
                        },
                        at:{
                            English:" at ",
                            Hebrew:" ב "
                        }
                    },
                    privacy:{
                        title:{
                            English:"Plan privacy",
                            Hebrew:"פרטיות התוכנית",
                        },
                        isPublic:{
                            English:"Public",
                            Hebrew:"פומבי",
                        },
                        isPrivate:{
                            English:"Private",
                            Hebrew:"פרטי",
                        },
                        planSummary:{
                            English:"Share a summary and guidelines that can be helpful for other users",
                            Hebrew:"שתף סיכום והנחיות שיכולים לעזור למשתמשים אחרים",
                        },
                        publicMessage:{
                            English:"Public plans after approval are visible for everyone to use and get inspired. \nOnly the trip places and summary will be bisible, notes, tasks and transport info will remain private",
                            Hebrew:"תוכניות פומביות אחרי אישור הינן זמינות לכלל המשתמשים. \nרק המקומות בטיול וסיכום הטיול יהיו זמינים, הערות משימות ומידע על תחבורה ישארו פרטיים.",
                        },
                        confirmation:{
                            title:{
                                English:"Attention",
                                Hebrew:"אזהרה",
                            },
                            message:{
                                English:"Other users will not be able anymore to see and get inspired by your plan",
                                Hebrew:"משתמשים אחרים לא יוכלו עוד לראות ולקבל השראה מהתוכנית שלך",
                            },
                            change:{
                                English:"Change",
                                Hebrew:"שנה",
                            },
                            discard:{
                                English:"Discard",
                                Hebrew:"בטל",
                            }
                        },
                        planSecurityTitle:{
                            English:"Selet Trip Plan Privacy",
                            Hebrew:"בחר הרשאות גישה לתכנית הטיול",
                        },
                        planSecurityMessage:{
                            English:"Plan privacy allows non-editors users to access trip overview and planning, without edit permissions (tasks remain private). \nAfter being approved public plan can be discovered and searched globally in the system.",
                            Hebrew:"הרשאות לתכנית הטיול מאפשרות למי שלא חבר בטיול לראות ולהעזר בתכנית הטיול ללא אפשרות עריכה (משימות נשארות פרטיות). \nאחרי קבלת אישור תכנית ציבורית ניתנת לחיפוש ונגישה לכלל משמשי המערכת דרך חיפוש טיולים ",
                        },
                        albumSecurityTitle:{
                            English:"Selet Access Permissions to Your Album",
                            Hebrew:"בחר הרשאות גישה לאלבום שלך",
                        },
                        albumSecurityMessage:{
                            English:"Album privacy allows non-editors users to view and get inspired your trip album. \nAll selected by you album images will be visible unless their owner applied privacy.",
                            Hebrew:"הרשאות לאלבום מאפשרות למי שלא חבר בטיול לראות ולקבל השראה מהאלבום שלך. \nכל התמונות שבחרת באלבום יהיו זמינות לצפייה למעט תמונות שהבעלים שלהם הגביל הרשאות צפייה.",
                        }
                    },
                    overviewMode:{
                        English:"Overview",
                        Hebrew:"כללי",
                    },
                    mobilePlanningMessage:{
                        English:"Planning mode available only in desktop",
                        Hebrew:"תצוגת תכנון זמינה רק במחשב",
                    },
                    planningView:{
                        English:"Plan and map",
                        Hebrew:"תכנון ומפה",
                    },
                    mapView:{
                        English:"Map view",
                        Hebrew:"תצוגת מפה",
                    },
                    journeyView:{
                        English:"Album",
                        Hebrew:"אלבום",
                    },
                    experiencesWall:{
                        English:"Experiences Wall",
                        Hebrew:"קיר חוויות",
                    },
                    experiencesAlbum:{
                        title:{
                            English:"Album",
                            Hebrew:"אלבום",
                        },
                        show:{
                            English:"Show",
                            Hebrew:"הצג",
                        },
                        hide:{
                            English:"Hide",
                            Hebrew:"הסתר",
                        },
                        done:{
                            English:"Done",
                            Hebrew:"סיים",
                        },
                        reOrder:{
                            English:"Order",
                            Hebrew:"סדר",
                        },
                        select:{
                            English:"Select",
                            Hebrew:"בחר",
                        },
                        shareExperience:{
                            English:"Share experience",
                            Hebrew:"שתף חוויה",
                        },
                        textPlaceholder:{
                            English:"Add text about your experience ",
                            Hebrew:"הוסף טקסט המתאר את החווייה שלך",
                        },
                        messageContent:{
                            English:"To save experiences and images to your trip, you must to be logged in",
                            Hebrew:"בכדי לשמר חוויות ותמונות לטיול שלך, עליך להיות מחובר למערכת",
                        },
                        messageButton:{
                            English:"Close",
                            Hebrew:"סגור",
                        },
                        friendsSharedPhotos:{
                            English:"Your friends already shared experiences and photos to this trip - click to watch",
                            Hebrew:"חברים שלך לטיול כבר הוסיפו חוויה ותמונות למקום זה - לחץ לצפייה",
                        },
                        albumDays:{
                            English:"By Days",
                            Hebrew:"לפי ימים",
                        },
                        allPhotos:{
                            English:"All Photos",
                            Hebrew:"כל התמונות",
                        },
                        experiences:{
                            English:"Experiences",
                            Hebrew:"חוויות",
                        }
                    },
                    planning:{
                        daysToGo:{
                            English:"Days To Go",
                            Hebrew:"ימים נישארו",
                        },
                        dreamingMessage:{
                            English:"Dream and get inspired for your next trip",
                            Hebrew:"תחלום וקבל השראה עבור הטיול הבא שלך",
                        },
                        calendarView:{
                            English:"Calendar",
                            Hebrew:"לוח שנה",
                        },
                        calendarHeader:{
                            English:"Trip plan calendar",
                            Hebrew:"לוח שנה של תוכנית הטיול",
                        },
                        mapView:{
                            English:"Map",
                            Hebrew:"מפה",
                        },
                        discoverView:{
                            English:"Discover",
                            Hebrew:"חיפוש",
                        },
                        discoverHeader:{
                            English:"Search and discover new places",
                            Hebrew:"חפש וגלה מקומות חדשים",
                        },
                        scrollerHeader:{
                            English:"Trip plan",
                            Hebrew:"תוכנית הטיול",
                        },
                        tasksHeader:{
                            English:"ToDos",
                            Hebrew:"משימות",
                        },
                        noTasksMessage:{
                            English:"No Todos",
                            Hebrew:"אין משימות",
                        },
                        day:{
                            English:"Day ",
                            Hebrew:" יום",
                        },
                        interesting:{
                            English:"Ideas list and overall info",
                            Hebrew:"רשימת רעיונות ומידע כללי",
                        },
                        interestigMobile:{
                            English:"Ideas list",
                            Hebrew:"רשימת רעיונות",
                        },
                        anyDay:{
                            English:"Any Day",
                            Hebrew:"לא משנה היום",
                        },
                        createNote:{
                            English:"Note",
                            Hebrew:"הערה",
                        },
                        addPlace:{
                            English:"Place",
                            Hebrew:"מקום",
                        },
                        addAccommodation:{
                            English:"Accommodation",
                            Hebrew:"מקום לינה",
                        },
                        createTransport:{
                            English:"Transport",
                            Hebrew:"תחבורה",
                        },
                        viewAllDays:{
                            English:"Show all days",
                            Hebrew:"הצג את כל הימים",
                        },
                        tasks:{
                            deleteMessage:{
                                English:"You are going to delete this task",
                                Hebrew:"הינך עומד למחוק משימה זו",
                            },
                            titlePlaceholder:{
                                English:"Task title",
                                Hebrew:"כותרת",
                            },
                            infoPlaceholder:{
                                English:"Additional information...",
                                Hebrew:"מידע נוסף...",
                            },
                            taskOwnerTitle:{
                                English:"Allocate task",
                                Hebrew:"הקצה משימה",
                            },
                            addTask:{
                                English:"Add sub task",
                                Hebrew:"הוסף תת משימה",
                            },
                            listContent:{
                                English:"Tasks list",
                                Hebrew:"רשימת משימות",
                            },
                            textContent:{
                                English:"Text",
                                Hebrew:"טקסט",
                            },
                            myTasks:{
                                English:"Filter my tasks",
                                Hebrew:"הצג משימות שלי",
                            },
                            notesPlaceholder:{
                                English:"Notes",
                                Hebrew:"הערות",
                            }
                        }
                    },
                    attachments:{
                        attachmentsMessage:{
                            English:"Add pdf files or images",
                            Hebrew:"הוסף תמונות או קבצי pdf",
                        },
                        attachedFiles:{
                            English:"Attached files:",
                            Hebrew:"קבצים מצורפים",
                        }
                    },
                    transport:{
                       
                        title:{
                            English:"Add transort",
                            Hebrew:"הוסף תחבורה",
                        },
                        editTitle:{
                            English:"Edit transort",
                            Hebrew:"ערוך תחבורה",
                        },
                        origin:{
                            English:"Choose origin",
                            Hebrew:"בחר מוצא",
                        },
                        destination:{
                            English:"Choose destination",
                            Hebrew:"בחר יעד",
                        },
                        departure:{
                            English:"Departure:",
                            Hebrew:"יציאה:",
                        },
                        arrival:{
                            English:"Arrival:",
                            Hebrew:"הגעה:",
                        },
                        addNotes:{
                            English:"Add notes...",
                            Hebrew:"הוסף הערות...",
                        },
                        price:{
                            English:"Price:",
                            Hebrew:":מחיר",
                        },
                        showArrivalDeparture:{
                            English:"Show arrival and departure time info",
                            Hebrew:"הצג מידע על זמני יציאה והגעה",
                        },
                        transportHeader:{
                            English:"Select transportation vehicle",
                            Hebrew:"בחר אמצעי תחבורה", 
                        },
                        otherPlaceholder:{
                            English:"Other type",
                            Hebrew:"סוג אחר", 
                        },
                        transportTypes:{
                            plane:{
                                English:"Plane",
                                Hebrew:"מטוס",
                            },
                            bus:{
                                English:"Bus",
                                Hebrew:"אוטובוס",
                            },
                            car:{
                                English:"Car",
                                Hebrew:"רכב",
                            },
                            ferry:{
                                English:"Ferry",
                                Hebrew:"ספינה",
                            },
                            taxi:{
                                English:"Taxi",
                                Hebrew:"מונית",
                            },
                            train:{
                                English:"Other",
                                Hebrew:"אחר",
                            },
                            other:{

                            }
                        }
                    },
                    discoverBoard:{
                        discoverSource:{
                            English:"Discover",
                            Hebrew:"חיפוש",
                        },
                        favoritesSource:{
                            English:"Favorites",
                            Hebrew:"מועדפים",
                        },
                        createNewPlace:{
                            English:"Create new place if not exist",
                            Hebrew:"צור מקום חדש אם לא קיים",
                        }
                    },
                    tripStartedMessage:{
                        messageTitle:{
                            English:"Your trip has started, time for experiences and fun!!",
                            Hebrew:"הטיול שלך התחיל, הזמן לחוויות והנאה!!"
                        },
                        messageContent:{
                            English:"Now you can save experiences and photos to trip album and update trip privacy to share with others",
                            Hebrew:"כעט הינך יכול לשתף חוויות ותמונות לאלבום הטיול וכמו כן לעדכן את הגדרות הפרטיות לטיול בכדי לשתף אותו עם אחרים"  
                        },
                        closeButton:{
                            English:"Close",
                            Hebrew:"סגור"  
                        }
                    }
                },
                tools:{
                    deletePlan:{
                        English:"Delete plan",
                        Hebrew:"מחק תוכנית"
                    },
                    clonePlan:{
                        English:"Clone plan",
                        Hebrew:"שכפל טיול"
                    },
                    savePlan:{
                        English:"Save plan",
                        Hebrew:"שמור טיול"
                    },
                    favorite:{
                        English:"Favorites",
                        Hebrew:"מועדפים"
                    },
                    dislikeSuggestion:{
                        English:"Bad suggestion",
                        Hebrew:"הצעה לא טובה"
                    },
                    leavePlan:{
                        English:"Leave plan",
                        Hebrew:"התנתק מהטיול"  
                    },
                    edit:{
                        English:"Edit",
                        Hebrew:"ערוך",
                    },
                    deleteMessage:{
                        English:"You are about to delete this trip permanently, including for everyone who is planning this trip together with you",
                        Hebrew:"הינך עומד למחוק טיול זה לצמיתות, כולל עבור כל מי שמתכנן אותו יחד איתך"  
                    },
                    leaveMessage:{
                        English:"You are about to leave this trip and will not be able to manage or use it anymore",
                        Hebrew:"הינך עומד לעזוב טיול זה ולא תוכל יותר לנהל או להשתמש בטיול זה"  
                    },
                    leaveButton:{
                        English:"Leave",
                        Hebrew:"התנתק"  
                    },
                    planPrivacy:{
                        English:"Privacy",
                        Hebrew:"פרטיות"  
                    },
                    planMembers:{
                        English:"Trip Members",
                        Hebrew:"חברי הטיול"  
                    }
                },
                addToPlan:{

                },
                
            },
            experience:{
                readMoreButton:{
                    English:"Read more",
                    Hebrew:"קרא עוד"
                },
                withUsers:{
                    English:"With ",
                    Hebrew:"יחד עם"
                },
                placeNamePrefix:{
                    English:"At ",
                    Hebrew:"ב"
                },
                planNamePrefix:{
                    English:"During ",
                    Hebrew:"בזמן"
                },
                tools:{
                    connectToPlan:{
                        English:"Connect to Trip",
                        Hebrew:"חבר לטיול"
                    },
                    disconnectFromPlan:{
                        English:"Disconnect from Trip",
                        Hebrew:"נתק מטיול"
                    },
                    deleteExperience:{
                        English:"Delete Experience",
                        Hebrew:"מחק חוויה"
                    },
                    editExperience:{
                        English:"Edit Experience",
                        Hebrew:"ערוך חוויה"
                    },
                    removeUserTag:{
                        English:"Remove tagging",
                        Hebrew:"הסר תיוג"
                    }
                },
                createExperience:{
                    title:{
                        English:"Create new experience",
                        Hebrew:"שתף חוויה חדשה",
                    },
                    editTitle:{
                        English:"Updating experience",
                        Hebrew:"עדכון חוויה",
                    },
                    placeNotExistMessage:{
                        English:"Place not exist yet? click to quickly create it",
                        Hebrew:"המקום עוד לא קיים? לחץ כדי ליצור אותו במהירות",
                    },
                    likedIt:{
                        English:"Liked it?",
                        Hebrew:"אהבת?",
                    },
                    addTrip:{
                        English:"Connect to a trip",
                        Hebrew:"חבר לטיול",
                    },
                    hideConnectToTrip:{
                        English:"Hide",
                        Hebrew:"הסתר",
                    },
                    resetPlace:{
                        English:"Reset place",
                        Hebrew:"אפס מיקום",
                    },
                    resetPlan:{
                        English:"Reset trip",
                        Hebrew:"אפס טיול",
                    },
                    createNewPlace:{
                        English:"Create new place",
                        Hebrew:"צור מקום חדש",
                    },
                    selectPlacePlaceholder:{
                        English:"Select place of experience",
                        Hebrew:"בחר את מקום החוויה",
                    },
                    selectPlaceCountry:{
                        English:"Select country of experience place",
                        Hebrew:"בחר את מדינת מקום החווייה",
                    },
                    selectPlanPlaceholder:{
                        English:"Select your relevant trip",
                        Hebrew:"בחר את הטיול המתאים שלך",
                    },
                    titlePlaceholder:{
                        English:"Title...",
                        Hebrew:"כותרת...",
                    },
                    friendsTitle:{
                        English:"Width ",
                        Hebrew:"עם ",
                    },
                    durationTitlePrefix:{
                        English:"Tell us how long it takes (in hours)?",
                        Hebrew:"ספר לנו כמה זמן לוקח (בשעות)?",
                    },
                    durationTitlePostfix:{
                        English:"hours",
                        Hebrew:"שעות",
                    },
                    privacy:{
                        public:{
                            English:"Public",
                            Hebrew:"ציבורי",
                        },
                        friends:{
                            English:"Friends only",
                            Hebrew:"חברים בלבד",
                        },
                        friendsFollowers:{
                            English:"Friends and Followers",
                            Hebrew:"חברים ועוקבים",
                        },
                        private:{
                            English:"Private",
                            Hebrew:"פרטי",
                        },
                        planHasNoPlacesMessage:{
                            English:"Empty plan cannot be public",
                            Hebrew:"תכנית ריקה אינה יכולה להיות ציבורית",
                        }
                    },
                    textPlaceholder:{
                        English:"Say something about your experience...",
                        Hebrew:"ספר משהו על החוויה שלך...",
                    },
                    addTagsPlaceholder:{
                        English:"Tag your experience",
                        Hebrew:"הוסף תגיות לתיאור החוויה",
                    },
                    setDate:{
                        English:"Set Date",
                        Hebrew:"בחר תאריך",
                    },
                    enterVideoUrl:{
                        English:"Paste link to video",
                        Hebrew:"הדבק לינק לוידאו",
                    },
                    videoUrlNotValid:{
                        English:"Video Url is not valid",
                        Hebrew:"הלינק לוידאו אינו תקין",
                    },
                    tagFriends:{
                        English:"Experience with friends",
                        Hebrew:"חברים לחווייה",
                    }
                },
            },
            place:{
                tools:{
                    addToFavorite:{
                        English:"Favorite",
                        Hebrew:"מועדפים",
                    },
                    requestOwnership:{
                        English:"Request ownership",
                        Hebrew:"בקש בעלות",
                    },
                    editPlace:{
                        English:"Edit place",
                        Hebrew:"ערוך",
                    },
                    suggestEdit:{
                        English:"Suggest update",
                        Hebrew:"הצע עדכון",
                    },
                    addToPlan:{
                        English:"Add to trip",
                        Hebrew:"הוסף לטיול",
                    },
                    shareExperience:{
                        English:"Share experience",
                        Hebrew:"שתף חוויה",
                    },
                    deletePlace:{
                        English:"Delete place",
                        Hebrew:"מחק מקום",
                    },
                    viewHandler:{
                        English:"Permanently closed",
                        Hebrew:"נסגר לצמיתות",
                    },
                    permanentlyClosedMessage:{
                        English:"Place is permanently closed",
                        Hebrew:"המקום נסגר לצמיתות",
                    },
                    temporaryClosedMessage:{
                        English:"Temporary closed",
                        Hebrew:"סגור זמנית",
                    },
                },
                favorite:{
                    editFavoriteTags:{
                        English:"Edit my search tags",
                        Hebrew:"ערוך מילות חיפוש"
                    },
                    removeFromFavorite:{
                        English:"Remove from favorite",
                        Hebrew:"הסר ממועדפים"
                    }
                },
                
                createPlace:{
                    updateMoreInfo:{
                        English:"Update more information",
                        Hebrew:"עדכן מידע נוסף"
                    },
                    placeLanguageSection:{
                        creationHeader:{
                            English:"Info Language:",
                            Hebrew:"שפת מידע:"
                        },
                    },
                    placeEditSuggestion:{
                        title:{
                            English:"Suggest place edit",
                            Hebrew:"הצע שינויים למקום"
                        },
                        button:{
                            English:"Send",
                            Hebrew:"שלח"
                        }
                    },
                    title:{
                        English:"Create new place",
                        Hebrew:"שמור מקום חדש",
                    },
                    createAccommodationTitle:{
                        English:"Add Accommodation",
                        Hebrew:"הוסף מקום לינה"
                    },
                    editTitle:{
                        English:"Updating information",
                        Hebrew:"עידכון פרטים",
                    },
                    creationMessage:{
                        English:"Please provide information for all the red sections",
                        Hebrew:"יש להזין מידע בכל השדות המסומנים באדום",
                    },
                    placeNamePlaceHolder:{
                        English: "Place name...",
                        Hebrew:"שם המקום...",
                    },
                    locationSectionHeader:{
                        English:"Place geo location",
                        Hebrew:"מיקום גאוגרפי של המקום",
                    },
                    locationInputMode:{
                        English:"Location set using map itself",
                        Hebrew:"מיקום הוגדר בשימוש במפה",
                    },
                    categorySectionHeader: {
                        English:"Select category",
                        Hebrew:"בחר קטגוריה",
                    },
                    characteristicsSectionHeader: {
                        English:"Place audience and tags",
                        Hebrew:"קהל יעד ותגים",
                    },
                    audienceSectionHeader: {
                        English:"Audience",
                        Hebrew:"קהל יעד",
                    },
                    audienceAgesTitle:{
                        English:"Ages: ",
                        Hebrew:"גילאים: ",
                    },
                    groupSize:{
                        English:"Group size: ",
                        Hebrew:"גודל קבוצה: ",
                    },
                    imagesSectionHeader: {
                        English:"Add images",
                        Hebrew:"הוסף תמונות",
                    },
                    generalInfoSectionHeader: {
                        English:"General information",
                        Hebrew:"מידע כללי",
                    },
                    linksSectionHeader:{
                        English:"Add link",
                        Hebrew:"הוסף קישור",
                    },
                    addAdditionalLinks:{
                        English:"Additional links",
                        Hebrew:"קישורים נוספים",
                    },
                    contactSectionHeader:{
                        English:"Contact info",
                        Hebrew:"מידע ליצירת קשר",
                    },
                    specialPhoneNumber:{
                        English:"Special # (1-800, *1234...)",
                        Hebrew:"מספר מיוחד (1-800, *1234...)",
                    },
                    specialPhoneNumberPlaceholder:{
                        English:"Enter special or short phone number",
                        Hebrew:"הכנס מספר טלפון מיוחד או קצר",
                    },
                    contactPhonePlaceholder:{
                        English:"Enter phone number",
                        Hebrew:"הכנס מספר טלפון",
                    },
                    contactFacebookPlaceholder:{
                        English:"Link to Facebook page",
                        Hebrew:"קישור לעמוד פייסבוק",
                    },
                    contactInstagramPlaceholder:{
                        English:"Link to Instagram page",
                        Hebrew:"קישור לעמוד אינסטגרם",
                    },
                    contactTwitterPlaceholder:{
                        English:"Link to Twitter page",
                        Hebrew:"קישור לעמוד טוויטר",
                    },
                    websitePlaceholder:{
                        English:"Official website",
                        Hebrew:"אתר רשמי",
                    },
                    wazePlaceholder:{
                        English:"Waze link",
                        Hebrew:"קישור לwaze",
                    },
                    googleMapsPlaceholder:{
                        English:"Google maps link",
                        Hebrew:"קישור ל Google Maps",
                    },
                    generalInformationPlaceHolder: {
                        English:"Share general information about this place",
                        Hebrew:"הזן מידע כללי אודות המקום",
                    },
                    setLocationButton:{
                        English:"Set location",
                        Hebrew:"הגדר מיקום",
                    },
                    resetLocationButton: {
                        English:"Reset location",
                        Hebrew:"אפס מיקום",
                    },
                    addTagsPlaceHolder:{
                        English:"Add tags helping describe the place...",
                        Hebrew:"הוסף תגיות המתארים את המקום...",
                    },
                    selectSubCategoryPlaceHolder:{
                        English:"Select category or create new one",
                        Hebrew:"בחר קטגוריה או צור קטגוריה חדשה",
                    },
                    subCategoryErrorMessage:{
                        English:"Please choose category from list or choose other to create new category",
                        Hebrew:"יש לבחור קטגוריה מהרשימה או לבחור אחר וליצור קטגוריה חדשה",
                    },
                    newCategoryPlaceHolder:{
                        English:"Enter new category",
                        Hebrew:"הכנס קטגוריה חדשה",
                    },
                    uplodaImageButton:{
                        English:"Upload images",
                        Hebrew:"העלה תמונות",
                    },
                    removeImage:{
                        English:"Remove image",
                        Hebrew:"מחק תמונה",
                    },
                    pasteImageButton:{
                        English:"Paste image from web",
                        Hebrew:"הדבק תמונה מהרשת",
                    },
                   
                    informationModal:{
                        infoHeader:{
                            English:"Attention",
                            Hebrew:"לתשומת לבך",
                        },
                        informationMessage:{
                            English:"You are about to create a new category. It will be checked by our system",
                            Hebrew:"הינך יוצר קטגוריה חדשה אשר תעבור אישורים של המערכת",
                        },
                        infoHeaderFinished: {
                            English:"Save Completed",
                            Hebrew:"שמירה הסתיימה",
                        },
                        informationMessageFinished: {
                            English:"You can use the created place, you will find it under 'My Space'. \nOnce it will be approved it will be available for everyone. \nIf you are the place owner, enter the place and request ownership.",
                            Hebrew:"הינך יכול להשתמש במקום שיצרת, ניתן למצוא את המקום תחת 'אזור אישי'. \nברגע שיעבור אישור, המקום יהיה זמין עבור כולם. \nאם הינך הבעלים של המקום, כנס למקום שיצרת ובקש לקבל בעלות קבועה.",
                        },
                        informationMessageUpdateFinished:{
                            English:"Place overview update is completed",
                            Hebrew:"עדכון פרטי המקום הסתיים בהצלחה",
                        },
                        informationMessageExperienceCreationFinished:{
                            English:"Experience successfully created",
                            Hebrew:"חווייה נשמרה בהצלחה",
                        },
                        informationMessageExperienceUpdateFinished:{
                            English:"Experience successfully updated",
                            Hebrew:"חווייה עודכנה בהצלחה",
                        },
                        deleteErrorHeader:{
                            English:"Delete is not possible",
                            Hebrew:"מחיקה אינה אפשרית",
                        },
                        deleteErrorMessage:{
                            English:"People already use this place for their personal use. \nFor special cases contact the support",
                            Hebrew:"אנשים כבר משתמשים במקום הזה לצרכים אישיים. \nבמקרים מיוחדים ניתן לפנות לשרות לקוחות",
                        }
                    },
                    placeExist:{
                        useThisPlace:{
                            English:"Use this place",
                            Hebrew:"השתמש במקום זה"
                        },
                        message:{
                            English:"Place is already exist",
                            Hebrew:"מקום כבר קיים"
                        },
                        createLanguagePrefix:{
                            English:"create ",
                            Hebrew:"צור גרסה ב"
                        },
                        createLanguagePostfix:{
                            English:" version",
                            Hebrew:""
                        },
                        resetLocation:{
                            English:"Reset location",
                            Hebrew:"אפס מיקום",
                        },
                        discardCreation:{
                            English:"Discard creation",
                            Hebrew:"בטל יצירה"
                        }
                    },
                },
                viewPlace:{
                    placeFeed:{
                        friendsExperiencesFilter:{
                            English:"Filter friends experiences",
                            Hebrew:"סנן חוויות חברים",
                        },
                        myExperiencesFilter:{
                            English:"Filter my experiences",
                            Hebrew:"סנן חוויות שלי",
                        }
                    },
                    informationModal:{
                        placeNotExistHeader:{
                            English:"Error",
                            Hebrew:"תקלה",
                        },
                        placeNotExistMessage:{
                            English:"This place not exist anymore",
                            Hebrew:"מקום זה לא קיים יותר",
                        },
                        planNotExistHeader:{
                            English:"Error",
                            Hebrew:"תקלה",
                        },
                        planNotExistMessage:{
                            English:"This trip not exist anymore",
                            Hebrew:"טיול זה לא קיים יותר",
                        },
                    },
                    contactInfoHeader:{
                        English:"Contact information",
                        Hebrew:"פרטי יצירת קשר",
                    },
                    showNearby:{
                        English:"Search places nearby",
                        Hebrew:"חפש מקומות באזור",
                    },
                    locationSectionHeader: {
                        English:"Show location",
                        Hebrew:"הצג מיקום",
                    },
                    imageCopyrightMessage:{
                        English:"Images may be subject to copyright. ",
                        Hebrew:"יתכן והתמונות מפרות את זכויות היוצרים. ",
                    },
                    imageCopyrightClick:{
                        English:"Click to open issue",
                        Hebrew:"לחץ לשליחת בקשה",
                    },
                    extraInfoHeader:{
                        English:"Anouncements, Experiences and Personal Notes",
                        Hebrew:"הודעות, חוויות ומידע אישי",
                    },
                    extraInfoHeaderPrefix:{
                        English:"",
                        Hebrew:"קיר מידע נוסף עבור -   ",
                    },
                    extraInfoHeaderPostfix:{
                        English:"   - Additional information wall",
                        Hebrew:"",
                    },
                    extraInfoEmptyWall:{
                        English:"The wall still is empty",
                        Hebrew:"הקיר עדיין ריק"
                    },
                    personalEmptyWall:{
                        English:"You don't have notes for this place",
                        Hebrew:"לא שמרת הערות למקום זה"
                    },
                    experienceEmptyWall:{
                        English:"No one yet shared an experiences for this place",
                        Hebrew:"אף אחד לא שיתף חווייה על מקום זה"
                    },
                    addHighlight:{
                        English:"Write post",
                        Hebrew:"כתוב פוסט"
                    },
                    addExperience:{
                        English:"Share Experience",
                        Hebrew:"שתף חוויה"
                    },
                    addNote:{
                        English:"Save note",
                        Hebrew:"שמור הערה"
                    },
                    addToFavoriteMessage:{
                        English:"To add recommendations, first add this place to favorites",
                        Hebrew:"על מנת להוסיף המלצות יש תחילה לצרף את המיקום למועדפים"
                    },
                    topicFilterPlaceholder:{
                        English:"Search information by topic",
                        Hebrew:"חפש מידע לפי נושא"
                    },
                    allTopicsPlaceholder:{
                        English:"Show all",
                        Hebrew:"הצג הכל"
                    },
                    extraInfoIconText:{
                        English:"Info Wall",
                        Hebrew:"קיר מידע"
                    },
                    
                    experiencesIconText:{
                        English:"Experiences",
                        Hebrew:"חוויות"
                    },
                    ideasIconText:{
                        English:"My notes",
                        Hebrew:"הערות שלי"
                    }
                },
                openHours:{
                    specialDates:{
                        button:{
                            English:"Special dates",
                            Hebrew:"תאריכים מיוחדים",
                        },
                        set:{
                            English:"Set",
                            Hebrew:"הגדר",
                        },
                        remove:{
                            English:"Remove",
                            Hebrew:"הסר",
                        },
                        header:{
                            English:"Set range for dates with different openning hours \n(e.g. summer/winter time)",
                            Hebrew:"הגדר טווח תאריכים לשעות פתיחה שונות \n(לדוגמה חורף-קיץ)"
                        }
                    },
                    closedDateMessage:{
                        English:"Set closed date for the place",
                        Hebrew:"הגדר תאריך סגירת המקום",
                    },
                    addHours:{
                        English:"Add hours range",
                        Hebrew:"הוסף טווח שעות",
                    },
                    open24Hours:{
                        English:"Open 24 hours",
                        Hebrew:"פתוח 24 שעות",
                    },
                    closed:{
                        English:"Closed",
                        Hebrew:"סגור",
                    },
                    weekDaysShort:{
                        sunday:{
                            English:"Sun",
                            Hebrew:"א'",
                        },
                        monday:{
                            English:"Mon",
                            Hebrew:"ב'",
                        },
                        tuesday:{
                            English:"Tue",
                            Hebrew:"ג'",
                        },
                        wednesday:{
                            English:"Wed",
                            Hebrew:"ד'",
                        },
                        thursday:{
                            English:"Thu",
                            Hebrew:"ה'",
                        },
                        friday:{
                            English:"Fri",
                            Hebrew:"ו'",
                        },
                        saturday:{
                            English:"Sat",
                            Hebrew:"שבת",
                        }
                    },
                    weekDaysLong:{
                        sunday:{
                            English:"Sunday",
                            Hebrew:"ראשון",
                        },
                        monday:{
                            English:"Monday",
                            Hebrew:"שני",
                        },
                        tuesday:{
                            English:"Tuesday",
                            Hebrew:"שלישי",
                        },
                        wednesday:{
                            English:"Wednesday",
                            Hebrew:"רביעי",
                        },
                        thursday:{
                            English:"Thursday",
                            Hebrew:"חמישי",
                        },
                        friday:{
                            English:"Friday",
                            Hebrew:"שישי",
                        },
                        saturday:{
                            English:"Saturday",
                            Hebrew:"שבת",
                        }
                    },
                    title:{
                        English:"Manage open hours",
                        Hebrew:"הגדר שעות פעילות",
                    },
                    saveButton:{
                        English:"Save",
                        Hebrew:"שמור",
                    },
                    unspecifiedHours: {
                        English:"Not specified",
                        Hebrew:"לא מוגדר",
                    },
                    adTimeRangesButton: {
                        English:"New time range",
                        Hebrew:"טווח זמנים חדש",
                    },
                    closedMessage:{
                        English:"Closed",
                        Hebrew:"סגור",
                    },
                    opensMessage:{
                        English:"Opens:",
                        Hebrew:"שעת פתיחה:",
                    },
                    closingTimeMessage:{
                        English:"Closes:",
                        Hebrew:"שעת סגירה:",
                    },
                    removeRowMessage:{
                        English:"Remove",
                        Hebrew:"הסר",
                    },
                    today:{
                        English:"Today",
                        Hebrew:"היום"
                    },
                    temporaryClosedMessage:{
                        English:"Place Temporary Closed",
                        Hebrew:"המקום סגור באופן זמני"
                    },
                    permanentlyClosedMessage:{
                        English:"Place PERMANENTLY Closed",
                        Hebrew:"המקום סגור לצמיתות"
                    },
                    addExplanationPlaceholder:{
                        English:"Add explanation...",
                        Hebrew:"הוסף הסבר..."
                    },
                    closedDate:{
                        English:"Closed date",
                        Hebrew:"תאריך סגירה"
                    },
                    setButton:{
                        English:"Set",
                        Hebrew:"הגדר"
                    }
                }
            },
        },

        systemLanguage:{
            
            registration:{
                registrationForm:{
                    resetPassword:{
                        English:"Reset password",
                        Hebrew:"אפס סיסמא"
                    },
                    goBack:{
                        English:"Return",
                        Hebrew:"חזור"
                    },
                    forgotPassword:{
                        English:"Forgot password",
                        Hebrew:"שכחתי סיסמא"
                    },
                    signInTitle:{
                        English:"Sign In to PlanEXp",
                        Hebrew:"הירשם ל PlanEXp"
                    },
                    firstNamePlaceholder:{
                        English:"First Name",
                        Hebrew:"שם פרטי",
                    },
                    lastNamePlaceholder:{
                        English:"Last Name",
                        Hebrew:"שם משפחה",
                    },
                    emailPlaceholder:{
                        English:"Email",
                        Hebrew:"אימייל",
                    },
                    passwordPlaceholder:{
                        English:"Password",
                        Hebrew:"סיסמא"
                    },
                    emailErrorMessage:{
                        English:"Email is not valid",
                        Hebrew:"כתובת האימייל לא תקינה"
                    },
                    passwordErrorMessage:{
                        English:"Password not strong enough",
                        Hebrew:"הסיסמא אינה חזקה מספיק"
                    },
                    signInButton:{
                        English:"Sign In",
                        Hebrew:"הירשם"
                    },
                    alreadyHaveAccountMessage:{
                        English:"Already have an account?",
                        Hebrew:"כבר רשום?"
                    },
                    dontHaveAccountMessage:{
                        English:"Don't have an account?",
                        Hebrew:"עדיין לא רשום?"
                    },
                    loginButton:{
                        English:"Login",
                        Hebrew:"כנס"
                    },
                    loginTitle:{
                        English:"Join PlanEXp and start planning your next experience",
                        Hebrew:"הצטרף ל PlanEXp והחל לתכנן את החווייה הבאה"
                    },
                    signInWithGoogle:{
                        English:"Sign In with Google",
                        Hebrew:"הכנס עם Google"
                    }
                }
            },
            navBar:{
                loginMessage:{
                    English:"Login",
                    Hebrew:"התחבר"
                },
                selectedContentMessage:{
                    discover:{
                        English:"Discover",
                        Hebrew:"גלה מקומות",
                    },
                    favorite:{
                        English:"My Favorite",
                        Hebrew:"מועדפים",
                    },
                    myContent: {
                        English:"Personal Space",
                        Hebrew: "אזור אישי",
                    },
                    welcome: {
                        English:"Welcome",
                        Hebrew:"ברוכים הבאים",
                    }
                },
                menu:{
                    settings:{
                        English:"Settings",
                        Hebrew:"הגדרות",
                    },
                    myContent:{
                        English:"Personal space",
                        Hebrew:"אזור אישי",
                    },
                    logout:{
                        English:"Logout",
                        Hebrew:"התנתק",
                    },
                    contactUs:{
                        English:"Share with us",
                        Hebrew:"שתף איתנו",
                    }
                },
                settings:{
                    welcomeMessage:{
                        title:{
                            English:"Welcome to PlanEXp",
                            Hebrew:"ברוכים הבאים ל PlanEXp",
                        },
                        message:{
                            English:"Before you start exploring, discovering and planning your next experience, you can adjust personal information, and select interest you want tell us about",
                            Hebrew:"רגע לפני שתתחיל לנווט, לחפש ולתכנן את החווייה הבאה שלך, הינך יכול לעדכן פרטים אישיים ולבחור תחומי עניין שברצונך שנדע עליהם",
                        },
                        closeButton:{
                            English:"Let's start",
                            Hebrew:"בוא נתחיל",
                        }
                    },
                    title:{
                        English:"Settings",
                        Hebrew:"הגדרות",
                    },
                    languageMessage:{
                        English:"Please select prefered system language",
                        Hebrew:"אנא בחר את שפת המערכת המועדפת",
                    },
                    finishCrop:{
                        English:"Finish image adjustment",
                        Hebrew:"סיים התאמת תמונה",
                    },
                    discardCrop:{
                        English:"Discard",
                        Hebrew:"בטל",
                    },
                    uploadButton:{
                        English:"Upload profile image",
                        Hebrew:"העלה תמונת פרופיל",
                    },
                    settingsTitle:{
                        English:"General settings",
                        Hebrew:"הגדרות כלליות",
                    },
                    interestsTitle:{
                        English:"Interests",
                        Hebrew:"תחומי עניין",
                    },
                    profileTitle:{
                        English:"My Profile",
                        Hebrew:"פרופיל שלי",
                    },
                    profileStats:{
                        joined:{
                            English:"Joined:",
                            Hebrew:"הצטרף:", 
                        },
                        lastSeen:{
                            English:"Last seen:",
                            Hebrew:"התחבר לאחרונה:", 
                        },
                        createdPlaces:{
                            English:"Created places:",
                            Hebrew:"יצר מקומות:", 
                        },
                        favoritePlaces:{
                            English:"Places saved to favorites:",
                            Hebrew:"מקומות שמורים במועדפים:", 
                        },
                        sharedExperiences:{
                            English:"Shared experiences:",
                            Hebrew:"שיתף חוויות:", 
                        },
                        sharedAnnouncements:{
                            English:"Shared announcements:",
                            Hebrew:"שיתף הודעות:", 
                        },
                        createdPlans:{
                            English:"Created trips:",
                            Hebrew:"יצר טיולים:", 
                        },
                        sharedPlans:{
                            English:"Shared trip plans:",
                            Hebrew:"שיתף תוכניות טיולים:", 
                        },
                        sharedJourneys:{
                            English:"Shared journeys:",
                            Hebrew:"שיתף מסעות:", 
                        },
                    },
                    userData:{
                        displayName:{
                            English:"Full name:",
                            Hebrew:"שם מלא:", 
                        },
                    },
                    interestsHeader:{
                        English:"Select your interests:",
                        Hebrew:"בחר את תחומי העניין שלך:", 
                    }
                } 
            },
            mainPage:{
                contentType:{
                    places:{
                        English:"Places",
                        Hebrew:"מקומות"
                    },
                    plans:{
                        English:"Trips",
                        Hebrew:"טיולים"
                    },
                    experiences:{
                        English:"Experiences",
                        Hebrew:"חוויות"
                    }
                },
                searchBar:{
                    discoverPlacesSearchBarMessage:{
                        English:"Destination, category, tag or place name",
                        Hebrew: "יעד, קטגוריה, תגית, שם המקום",
                    },
                    discoverPlansSearchBarMessage:{
                        English:"Choose your destination",
                        Hebrew: "תבחר את היעד שלך",
                    },
                    favoritePlacesSearchBarMessage:{
                        English:"Search your favorite places",
                        Hebrew: "חפש מקומות ששמרת במועדפים",
                    },
                    favoritePlansSearchBarMessage:{
                        English:"Filter your favorite trip plans by location",
                        Hebrew: "סנן טיולים השמורים במועדפים לפי מיקום",
                    },
                    favoriteExperiencesSearchBarMessage:{
                        English:"Search your favorite experiences",
                        Hebrew: "חפש בחוויות המועדפות שלך",
                    },
                    myContentPlacesSearchBarMessage:{
                        English:"Filter places you created",
                        Hebrew: "סנן מקומות שיצרת",
                    },
                    myContentPlansSearchBarMessage:{
                        English:"Filter your trips",
                        Hebrew: "חפש מבין הטיולים שלך",
                    },
                    myContentExperiencesSearchBarMessage:{
                        English:"Search your experiences",
                        Hebrew: "חפש מבין החוויות שלך",
                    },
                    location:{
                        English:"Choose desired destination",
                        Hebrew:"בחר יעד מבוקש",
                    },
                    category:{
                        English:"Choose what to experience",
                        Hebrew:"חפש לפי קטגוריה",
                    },
                    noResults:{
                        header:{
                            English:"Couldn't find any results",
                            Hebrew:"תוצאות לא נמצאו"
                        },
                        message:{
                            English:"Unfortunately we couldn't find any results for your search keyword",
                            Hebrew:"לצערנו לא הצלחנו למצוא אף תוצאה למילת החיפוש הנל"
                        }
                    }
                },
                createMenu:{
                    experience:{
                        mainMessage:{
                            English:"Capture and share personal experience",
                            Hebrew:"תעד ושתף חוויה",
                        },
                        subMessage:{
                            English:"Personal experience you had with specific place",
                            Hebrew:"חוויה אישית ממקום ספציפי"
                        }
                    },
                    place:{
                        mainMessage:{
                            English:"Save new place",
                            Hebrew:"שמור מקום חדש",
                        },
                        subMessage:{
                            English:"Place, attraction or activity to experience (Places are public)",
                            Hebrew:"מקום, אטרקציה, פעילות שניתן לחוות (מקומות נראים לכלל המשתמשים במערכת)"
                        }
                    },
                    plan:{
                        mainMessage:{
                            English:"Start planning or start dreaming your next trip",
                            Hebrew:"התחל לתכנן או לחלום את הטיול הבא",
                        },
                        subMessage:{
                            English:"Organize trip places, notes transportation and more",
                            Hebrew:"ארגן מקומות של הטיול, הערות, תחבורה ועוד לפי ימים"
                        }
                    },
                    toolTip:{
                        loggedUser:{
                            English:"Create something new",
                            Hebrew:"תיצור משהו חדש",
                        },
                        tryOutUser:{
                            English:"Join PlanEXp to be able create places, plans and experiences",
                            Hebrew:"הצטרף ל PlanEXp על מנת להיות מסוגל לייצר תוכן כגון מקומות ותכנון טיולים"
                        }
                    }
                },
                map:{
                    searchMap:{
                        English:"Search results by moving the map",
                        Hebrew:"חפש תוצאות בעזרת תזוזה המפה",
                    },
                    showInMapArea:{
                        English:"Show in map area",
                        Hebrew:"הצג בגבולות המפה",
                    },
                    filters:{
                        category:{
                            title:{
                                English:"Categories filters",
                                Hebrew:"סינון קטגוריות",
                            },
                            filterPlaceholder:{
                                English:"Choose category",
                                Hebrew:"בחר קטגוריה"
                            },
                            showAllCategories:{
                                English:"Show all category filters",
                                Hebrew:"הצג את כל סנני קטגוריות"
                            },
                            closeCategoryFilters:{
                                English:"Close cateogry filters",
                                Hebrew:"סגור את סנני קטגוריות"
                            }
                        },
                        audience:{
                            title:{
                                English:"Audience filters",
                                Hebrew:"סינון קהל יעד",
                            }
                        },
                        locations:{
                            title:{
                                English:"Location filters",
                                Hebrew:"סינון מיקום"
                            },
                            showAllLocations:{
                                English:"Show all location filters",
                                Hebrew:"הצג את כל סנני מיקום"
                            },
                            closeLocationsFilters:{
                                English:"Close location filters",
                                Hebrew:"סגור את סנני המיקום"
                            }
                        }
                    },
                    searchAroundTitle:{
                        English:"Around me",
                        Hebrew:"חפש מסביבי"
                    },
                    showAllTitle:{
                        English:"Show all",
                        Hebrew:"הצג הכל"
                    },
                    searchByName:{
                        English:"Search by name (in map boundaries)",
                        Hebrew:"חפש לפי שם (בתחומי המפה)"
                    },
                }
            },
            welcomeWall:{
                userInterestTitle:{
                    English:"You showed interest in ",
                    Hebrew:"הראת עניין ב",
                },
                defaultRecommendation:{
                    English:"You may like ",
                    Hebrew:"ייתכן ותתעניין ב",
                },
                discardRecommendation:{
                    English:"Remove recomendation",
                    Hebrew:"הסר הצעה",
                },
                recommendedMyCountry:{
                    title:{
                        English:"Recommended in Israel",
                        Hebrew:"מומלצים בישראל",
                    },
                },
                latestExperiences:{
                    title:{
                        English:"My Latest Experiences",
                        Hebrew:"החוויות האחרונות שלי"
                    },
                    allExperiences:{
                        English:"See all your experiences",
                        Hebrew:"צפה בכל החוויות שלך"
                    }
                },
                friendsExperiences:{
                    title:{
                        English:"Latest Friends Experiences",
                        Hebrew:"חוויות אחרונות של חברים"
                    },
                    allExperiences:{
                        English:"Discover all friends experiences",
                        Hebrew:"גלה את כל חוויות החברים"
                    },
                    noExperiences:{
                        English:"Your friends need to start sharing experiences for you to have an Inspiring Wall",
                        Hebrew:"חברים שלך צריכים להתחיל לשתף חוויות כדי שיהיה לך קיר השראה"
                    }
                },
                lastPlaces:{
                    title:{
                        English:"Latest Places Viewed",
                        Hebrew:"מקומות אחרונים שנצפו",
                    },
                    allFavoritePlacesButton:{
                        English:"See all your favorite places",
                        Hebrew:"צפה בכל המקומות המועדפים שלך"
                    },
                    allFavoritePlansButton:{
                        English:"See all your favorite plans",
                        Hebrew:"צפה בכל תוכניות הטיולים המועדפים שלך"
                    },
                   
                },
                plans:{
                    title:{
                        English:"My Latest Trip",
                        Hebrew:"הטיול האחרון שלי",
                    },
                    allUserPlansButton:{
                        English:"See all your trips",
                        Hebrew:"צפה בכל הטיולים שלך"
                    }
                }
            },
            discover:{
                navigation:{
                    pagesNavigation:{
                        previous:{
                            English:"Prebious Page",
                            Hebrew:"עמוד קודם",
                        },
                        next:{
                            English:"Next Page",
                            Hebrew:"עמוד הבא",
                        }
                    }
                },
                filters:{
                    title:{
                        English:"Filters",
                        Hebrew:"פילטרים",
                    },
                    experienceFilters:{
                        filterButton:{
                            English:"Filter Experiences",
                            Hebrew:"סנן חוויות",
                        },
                        onlyMyExperiences:{
                            English:"Show only my experiences",
                            Hebrew:"הצג רק חוויות שלי",
                        },
                        onlyFriendsExperiences:{
                            English:"Show only friends experiences",
                            Hebrew:"הצג רק חוויות של חברים",
                        },
                        onlyVideoExperiences:{
                            English:"Show only experiences with video",
                            Hebrew:"הצג רק חוויות עם וידאו",
                        },
                        onlyLikedExperiences:{
                            English:"Show only Liked experiences",
                            Hebrew:"הצג רק חוויות שאהבו ",
                        },
                        onlyDislikedExperiences:{
                            English:"Show only Disliked experiences",
                            Hebrew:"הצג רק חוויות שלא אהבו",
                        },
                    },
                    
                    desktopShow:{
                        English:"Filters",
                        Hebrew:"פילטרים"
                    },
                    desktopHide:{
                        English:"Hide filters",
                        Hebrew:"הסתר פילטרים"
                    },
                    mobileShow:{
                        English:"Filters",
                        Hebrew:"פילטרים"
                    },
                    onlyMapArea:{
                        English:"Show places within map boundries",
                        Hebrew:"הצג מקומות בתוך גבולות המפה"
                    },
                    onlyMapAreaTooltip:{
                        English:"Filter will show only places in current focused map area",
                        Hebrew:"הראה רק מיקומים בשטח המפה"
                    },
                    showResults:{
                        English:"Show results",
                        Hebrew:"הצג תוצאות"
                    }
                },
                planFilters:{
                    filterParticipants:{
                        English:"Filter by age",
                        Hebrew:"סנן לפי גילאים"
                    },
                    durationFilter:{
                        English:"Trip duration",
                        Hebrew:"אורך הטיול"
                    }
                },
                mapIcon:{
                    English:"Map view",
                    Hebrew:"תצוגת מפה",
                },
                
                categoriesFilter:{
                    categorySelectionPlaceholder:{
                        English:"Filter category",
                        Hebrew:"סנן קטגוריה",
                    },toolTip:{
                        English:"No places with this category in filtered location",
                        Hebrew:"אין מקומות מקטגוריה זו באזור המסונן"
                    }
                },
                extraFilters:{
                    title:{
                        English:"Advanced Filters",
                        Hebrew:"פילטרים מתקדמים",
                    }
                },
                locationsFilter:{
                    placeHolder:{
                        English:"Filter location",
                        Hebrew:"סנן מיקום",
                    }
                },
            },
            highlight:{
                topicPlaceholder:{
                    English:"Topic (recommendations, discounts, directions, parking, etc...)",
                    Hebrew:"נושא: (המלצות, מבצעים, דרכי הגעה, חניה וכו'...)"
                },
                textPlaceholder:{
                    English:"Add text",
                    Hebrew:"הוסף טקסט"
                },
                updateButton:{
                    English:"Update",
                    Hebrew:"עדכן"
                },
                discardButton:{
                    English:"Discard",
                    Hebrew:"בטל"
                },
                addLinks:{
                    English:"Add links",
                    Hebrew:"הוסף קישורים"
                }
            },
            note:{
                createTitle:{
                    English:"Create Note",
                    Hebrew:"צור הערה"
                },
                topicPlaceholder:{
                    English:"Topic...",
                    Hebrew:"נושא..."
                },
                textPlaceholder:{
                    English:"Add text",
                    Hebrew:"הוסף טקסט"
                } 
            },
          
            place:{
                tools:{
                    editPlace:{
                        English:"Edit place",
                        Hebrew:"ערוך",
                    },
                    addToPlan:{
                        English:"Add to plan",
                        Hebrew:"הוסף לתוכנית",
                    },
                    shareExperience:{
                        English:"Share experience",
                        Hebrew:"שתף חוויה",
                    },
                    deletePlace:{
                        English:"Delete place",
                        Hebrew:"מחק מקום",
                    },
                    viewHandler:{
                        English:"Permanently closed",
                        Hebrew:"נסגר לצמיתות",
                    },
                    permanentlyClosedMessage:{

                    },
                    temporaryClosedMessage:{
                        English:"Temporary closed",
                        Hebrew:"סגור זמנית",
                    },
                },
                favorite:{
                    editFavoriteTags:{
                        English:"Edit my search tags",
                        Hebrew:"ערוך מילות חיפוש"
                    },
                    removeFromFavorite:{
                        English:"Remove from favorite",
                        Hebrew:"הסר ממועדפים"
                    }
                },
                
                createPlace:{
                    placeEditSuggestion:{
                        title:{
                            English:"Suggest place edit",
                            Hebrew:"הצע שינויים למקום"
                        },
                        button:{
                            English:"Send",
                            Hebrew:"שלח"
                        }
                    },
                    title:{
                        English:"Create new place",
                        Hebrew:"שמור מקום חדש",
                    },
                    editTitle:{
                        English:"Updating information",
                        Hebrew:"עידכון פרטים",
                    },
                    creationMessage:{
                        English:"Please provide information for all the red sections",
                        Hebrew:"יש להזין מידע בכל השדות האדומים",
                    },
                    placeNamePlaceHolder:{
                        English: "Place name...",
                        Hebrew:"שם המקום...",
                    },
                    locationSectionHeader:{
                        English:"Set location",
                        Hebrew:"הגדר מיקום",
                    },
                    locationInputMode:{
                        English:"Location set using map itself",
                        Hebrew:"מיקום הוגדר בשימוש במפה",
                    },
                    categorySectionHeader: {
                        English:"Select category",
                        Hebrew:"בחר קטגוריה",
                    },
                    characteristicsSectionHeader: {
                        English:"Place audience and tags",
                        Hebrew:"קהל יעד ותגים",
                    },
                    audienceSectionHeader: {
                        English:"Audience",
                        Hebrew:"קהל יעד",
                    },
                    audienceAgesTitle:{
                        English:"Ages: ",
                        Hebrew:"גילאים: ",
                    },
                    groupSize:{
                        English:"Group size: ",
                        Hebrew:"גודל קבוצה: ",
                    },
                    imagesSectionHeader: {
                        English:"Add images",
                        Hebrew:"הוסף תמונות",
                    },
                    generalInfoSectionHeader: {
                        English:"General information",
                        Hebrew:"מידע כללי",
                    },
                    linksSectionHeader:{
                        English:"Add link",
                        Hebrew:"הוסף קישור",
                    },
                    contactSectionHeader:{
                        English:"Contact info",
                        Hebrew:"מידע ליצירת קשר",
                    },
                    contactPhonePlaceholder:{
                        English:"Enter phone number",
                        Hebrew:"הכנס מספר טלפון",
                    },
                    contactFacebookPlaceholder:{
                        English:"Link to Facebook page",
                        Hebrew:"קישור לעמוד פייסבוק",
                    },
                    contactInstagramPlaceholder:{
                        English:"Link to Instagram page",
                        Hebrew:"קישור לעמוד אינסטגרם",
                    },
                    contactTwitterPlaceholder:{
                        English:"Link to Twitter page",
                        Hebrew:"קישור לעמוד טוויטר",
                    },
                    websitePlaceholder:{
                        English:"Official website",
                        Hebrew:"אתר רשמי",
                    },
                    generalInformationPlaceHolder: {
                        English:"Share general information about this place",
                        Hebrew:"הזן מידע כללי אודות המקום",
                    },
                    setLocationButton:{
                        English:"Set location",
                        Hebrew:"הגדר מיקום",
                    },
                    resetLocationButton: {
                        English:"Reset location",
                        Hebrew:"אפס מיקום",
                    },
                    addTagsPlaceHolder:{
                        English:"Add tags helping describe the place...",
                        Hebrew:"הוסף תתגיות אגים המתארים את המקום...",
                    },
                    selectSubCategoryPlaceHolder:{
                        English:"Select category or create new one",
                        Hebrew:"בחר קטגוריה או צור קטגוריה חדשה",
                    },
                    subCategoryErrorMessage:{
                        English:"Please choose category from list or choose other to create new category",
                        Hebrew:"יש לבחור קטגוריה מהרשימה או לבחור אחר וליצור קטגוריה חדשה",
                    },
                    newCategoryPlaceHolder:{
                        English:"Enter new category",
                        Hebrew:"הכנס קטגוריה חדשה",
                    },
                    uplodaImageButton:{
                        English:"Upload images",
                        Hebrew:"העלה תמונות",
                    },
                    pasteImageButton:{
                        English:"Paste image from web",
                        Hebrew:"הדבק תמונה מהרשת",
                    },
                   
                    informationModal:{
                        infoHeader:{
                            English:"Attention",
                            Hebrew:"לתשומת לבך",
                        },
                        informationMessage:{
                            English:"You are about to create a new category. It will be checked by our system",
                            Hebrew:"הינך יוצר קטגוריה חדשה אשר תעבור אישורים של המערכת",
                        },
                        infoHeaderFinished: {
                            English:"Save Completed",
                            Hebrew:"שמירה הסתיימה",
                        },
                        informationMessageFinished: {
                            English:"You can use the created place. Once it will be approved it will be available for everyone/ If you are the place owner, enter the place and request ownership, otherwise the place owner will be permitted to take ownership on this place.",
                            Hebrew:"הינך יכול להשתמש במקום שיצרת. ברגע שהוא יעבור אישור, הוא יהיה זמין לכולם. אם הינך הבעלים של המקום, כנס למקום שיצרת ובקש לקבל בעלות קבועה, אחרת הבעלים יהיה רשאי לקחת בעלות על מקום זה.",
                        },
                        informationMessageUpdateFinished:{
                            English:"Place overview update is completed",
                            Hebrew:"עדכון פרטי המקום הסתיים בהצלחה",
                        },
                        deleteErrorHeader:{
                            English:"Delete is not possible",
                            Hebrew:"מחיקה אינה אפשרית",
                        },
                        deleteErrorMessage:{
                            English:"People already use this place for their personal use. For special cases contact the support",
                            Hebrew:"אנשים כבר משתמשים במקום הזה לצרכים אישיים. במקרים מיוחדים ניתן לפנות לשרות לקוחות",
                        }
                    },
                    placeExist:{
                        message:{
                            English:" is already exist",
                            Hebrew:" כבר קיים"
                        },
                        createLanguagePrefix:{
                            English:"create ",
                            Hebrew:"צור גרסה ב"
                        },
                        createLanguagePostfix:{
                            English:" version",
                            Hebrew:""
                        },
                        resetLocation:{
                            English:"Reset location",
                            Hebrew:"אפס מיקום",
                        },
                        discardCreation:{
                            English:"Discard creation",
                            Hebrew:"בטל יצירת מיקום"
                        }
                    },
                },
                placeLanguageForm:{
                    title:{
                        English:"Update info for  ",
                        Hebrew:"עדכן פרטים עבור ",
                    }
                },
                placeCharacteristics:{
                    placeCharacteristicsTitle:{
                        English:"Places Characteristics",
                        Hebrew:"מאפייני מקום"
                    },
                    includeTitle:{
                        English:"Include",
                        Hebrew:"מה קיים"
                    },
                    allowedTitle:{
                        English:"Allowed",
                        Hebrew:"מה מותר"
                    },
                    filterSectionHeader:{
                        English:"Advanced filters",
                        Hebrew:"פילטרים מתקדמים"
                    },
                    feePlaceholder:{
                        English:"Price",
                        Hebrew:"מחיר"
                    },
                    entranceFeeTitle:{
                        English:"Entrance fee",
                        Hebrew:"דמי כניסה"
                    },
                    entranceHasFee:{
                        English:"Has fee",
                        Hebrew:"בתשלום",
                    },
                    freeEntrance:{
                        English:"Free Entrance",
                        Hebrew:"כניסה חינם",
                    },
                    addTicketsLinks:{
                        English:"Add tickets link",
                        Hebrew:"הוסף קישור לכרטיסים",
                    },
                    duration:{
                        English:"Duration",
                        Hebrew:"משך",
                    },
                    hours:{
                        English:"Hours",
                        Hebrew:"שעות",
                    },
                    days:{
                        English:"Days",
                        Hebrew:"ימים",
                    },
                    distance:{
                        English:"Distance",
                        Hebrew:"מרחק",
                    },
                    km:{
                        English:"KM",
                        Hebrew:"ק'מ",
                    },
                    miles:{
                        English:"Miles",
                        Hebrew:"מיל'",
                    },
                    difficultyLevel:{
                        English:"Difficulty level",
                        Hebrew:"רמת קושי",
                    },
                    easy:{
                        English:"Easy",
                        Hebrew:"קל",
                    },
                    medium:{
                        English:"Medium",
                        Hebrew:"בינוני",
                    },
                    hard:{
                        English:"Hard",
                        Hebrew:"קשה",
                    },
                    extreme:{
                        English:"Extreme",
                        Hebrew:"קשה מאוד"
                    },
                    circular:{
                        English:"Circular",
                        Hebrew:"מעגלי",
                    },
                    notCircular:{
                        English:"Not Circular",
                        Hebrew:"לא מעגלי",
                    },
                    yes:{
                        English:"Yes",
                        Hebrew:"כן",
                    },
                    no:{
                        English:"No",
                        Hebrew:"לא"
                    },
                    seasons:{
                        English:"Seasons",
                        Hebrew:"עונות",
                    },
                    allSeasons:{
                        English:"All seasons",
                        Hebrew:"כל העונות",
                    },
                    winter:{
                        English:"Winter",
                        Hebrew:"חורף",
                    },
                    spring:{
                        English:"Spring",
                        Hebrew:"אביב",
                    },
                    summer:{
                        English:"Summer",
                        Hebrew:"קיץ",
                    },
                    autumn:{
                        English:"Autumn",
                        Hebrew:"סתיו"
                    },
                    cuisine:{
                        English:"Cuisine type",
                        Hebrew:"סוג מטבח"
                    },
                    serviceType:{
                        English:"Service type",
                        Hebrew:"סגנון הגשה"
                    },
                    blossoming:{
                        English:"Blossoming",
                        Hebrew:"פריחה"
                    },
                    wildAnimals:{
                        English:"Wild animals",
                        Hebrew:"חיות בר"
                    },
                    kosher:{
                        English:"Kosher",
                        Hebrew:"כשר"
                    },
                    nonKosher:{
                        English:"Non Kosher",
                        Hebrew:"לא כשר"
                    },
                    fastFood:{
                        English:"Fast food",
                        Hebrew:"מזון מהיר",
                    },
                    casual:{
                        English:"Casual dining",
                        Hebrew:"מסעדה רגילה"
                    },
                    highEnd:{
                        English:"High end",
                        Hebrew:"יוקרתי"
                    },
                    audienceFilterTitle:{
                        English:"Audience",
                        Hebrew:"קהל יעד"
                    },
                    barType:{
                        English:"Bar style",
                        Hebrew:"סגנון בר"
                    },
                    museumType:{
                        English:"Museum Subjects",
                        Hebrew:"סוג מוזאון"
                    },
                    mtbTrailType:{
                        English:"Trail type",
                        Hebrew:"סוג מסלול"
                    },
                    mtbTrailCharacteristicsTitle:{
                        English:"Trail characteristics",
                        Hebrew:"מאפייני מסלול"
                    },
                    waterEntry:{
                        English:"Water Entry Allowed",
                        Hebrew:"אפשרות כניסה למים"
                    },
                    noWaterEntry:{
                        English:"No entering water",
                        Hebrew:"אין כניסה למים"
                    },
                    petsAllowed:{
                        English:"Pets allowed",
                        Hebrew:"מותר בעלי חיים"
                    },
                    petsNotAllowed:{
                        English:"Pets not allowed",
                        Hebrew:"אסור בעלי חיים"
                    },
                    accessible:{
                        English:"Accessible",
                        Hebrew:"נגיש"
                    },
                    notAccessible:{
                        English:"Not accessible",
                        Hebrew:"לא נגיש"
                    },
                    bikeRideLevel:{
                        English:"Riding Level",
                        Hebrew:"רמת רכיבה"
                    },
                    bikeRideLevelBeginner:{
                        English:"Beginner",
                        Hebrew:"מתחיל"
                    },
                    bikeRideLevelIntermidiate:{
                        English:"Intermidiate",
                        Hebrew:"בינוני"
                    },
                    bikeRideLevelAdvanced:{
                        English:"Advanced",
                        Hebrew:"מתקדם"
                    },
                    bikeRideLevelExtreme:{
                        English:"Extreme",
                        Hebrew:"מטורף"
                    },
                    bikeTrailType:{
                        English:"Trail Type",
                        Hebrew:"סוג מסלול"
                    },
                    parkingExist:{
                        English:"Parking Exist",
                        Hebrew:"יש חנייה"
                    },
                    noParkingExist:{
                        English:"No Parking",
                        Hebrew:"אין חנייה"
                    },
                    freeParking:{
                        English:"Free Parking",
                        Hebrew:"חנייה חינם"
                    },
                    fitReligious:{
                        English:"Fit religious",
                        Hebrew:"מתאים לדתיים"
                    },
                    notFitReligious:{
                        English:"Doesn't fit religious",
                        Hebrew:"לא מתאים לדתיים"
                    },
                    quietRequired:{
                        English:"Quiet Required",
                        Hebrew:"שמירה על שקט"
                    },
                    canMakeNoise:{
                        English:"Can make noise",
                        Hebrew:"מותר להרעיש"
                    },
                    campingAllowd:{
                        English:"Camping Allowed",
                        Hebrew:"מותר קמפינג"
                    },
                    campingNotAllowd:{
                        English:"Camping Not Allowed",
                        Hebrew:"אסור קמפינג"
                    },
                    bbqAllowed:{
                        English:"BBQ Allowed",
                        Hebrew:"מותר מנגלים"
                    },
                    hasLighting:{
                        English:"Lighting",
                        Hebrew:"תאורה"
                    },
                    noLighting:{
                        English:"No Lighting",
                        Hebrew:"אין תאורה"
                    },
                    hasToilets:{
                        English:"Toilets",
                        Hebrew:"שרותים"
                    },
                    noToilets:{
                        English:"No Toilets",
                        Hebrew:"אין שרותים"
                    },
                    hasShowers:{
                        English:"Showers",
                        Hebrew:"מקלחות"
                    },
                    noShowers:{
                        English:"No Showers",
                        Hebrew:"אין מקלחות"
                    },
                    hasElectricity:{
                        English:"Has Electricity",
                        Hebrew:"יש חיבור לחשמל"
                    },
                    bbqNotAllowed:{
                        English:"BBQ Not Allowed",
                        Hebrew:"אסור מנגלים"
                    },
                    halfDay:{
                        English:"Up to half a day",
                        Hebrew:"עד חצי יום"
                    },
                    fullDay:{
                        English:"Up to full day",
                        Hebrew:"עד יום שלם"
                    },
                    hasWater:{
                        English:"Water",
                        Hebrew:"מים"
                    },
                    noWater:{
                        English:"No Water",
                        Hebrew:"אין מים"
                    },
                    hasTables:{
                        English:"Tables",
                        Hebrew:"שולחנות"
                    },
                    hasChairs:{
                        English:"Chairs",
                        Hebrew:"כיסאות"
                    },
                    hasTanningBeds:{
                        English:"Tanning Beds",
                        Hebrew:"מיטות שיזוף"
                    },
                    noTables:{
                        English:"No Tables",
                        Hebrew:"איו שולחנות"
                    },
                    hasGuidedTours:{
                        English:"Guided Tours",
                        Hebrew:"סיורים מודרכים"
                    },
                    noGuidedTours:{
                        English:"No Guided Tours",
                        Hebrew:"אין סיורים מודרכים"
                    },
                    hasAudioGuide:{
                        English:"Audio Guide",
                        Hebrew:"הדרכה קולית"
                    },
                    noAudioGuide:{
                        English:"No Audio Guide",
                        Hebrew:"אין הדרכה קולית"
                    },
                    hasTakeaway:{
                        English:"Takeaways",
                        Hebrew:"Takeaway"
                    },
                    noTakeaway:{
                        English:"No Takeaways",
                        Hebrew:"אין אפשרות לTakeaway"
                    },
                    hasDelivery:{
                        English:"Deliveries",
                        Hebrew:"משלוחים"
                    },
                    noDelivery:{
                        English:"No Deliveries",
                        Hebrew:"אין משלוחים"
                    },
                    hasOutdoorArea:{
                        English:"Outdoor Area",
                        Hebrew:"אזור בחוץ"
                    },
                    noOutdoorArea:{
                        English:"No Outdoor Area",
                        Hebrew:"אין אזור בחוץ"
                    },
                    hasSmokingArea:{
                        English:"Smoking Area",
                        Hebrew:"אזור עישון"
                    },
                    noSmoking:{
                        English:"No Smoking",
                        Hebrew:"אסור לעשן"
                    },
                    hasWifi:{
                        English:"Wifi",
                        Hebrew:"Wifi"
                    },
                    noWifi:{
                        English:"No Wifi",
                        Hebrew:"אין Wifi"
                    },
                    hasPool:{
                        English:"Pool",
                        Hebrew:"בריכה"
                    },
                    noPool:{
                        English:"No Pool",
                        Hebrew:"אין בריכה"
                    },
                    snorkeling:{
                        English:"Snorkeling",
                        Hebrew:"שנורקל"
                    },
                    kitesurfing:{
                        English:"Kitesurfing",
                        Hebrew:"קייט סרפינג"
                    },
                    surfing:{
                        English:"Surfing",
                        Hebrew:"גלישה"
                    },
                    jetSki:{
                        English:"Jet Ski",
                        Hebrew:"אופנוע ים"
                    }
                },
                viewPlace:{
                    informationModal:{
                        placeNotExistHeader:{
                            English:"Error",
                            Hebrew:"תקלה",
                        },
                        placeNotExistMessage:{
                            English:"This place not exist anymore",
                            Hebrew:"מקום זה לא קיים יותר",
                        },
                    },
                    contactInfoHeader:{
                        English:"Contact information",
                        Hebrew:"פרטי יצירת קשר",
                    },
                    showNearby:{
                        English:"Show places nearby",
                        Hebrew:"הצג מקומות באזור",
                    },
                    locationSectionHeader: {
                        English:"Show location",
                        Hebrew:"הצג מיקום",
                    },
                    imageCopyrightMessage:{
                        English:"Images may be subject to copyright",
                        Hebrew:"יתכן והתמונות מפרות את זכויות היוצרים",
                    },
                    extraInfoHeader:{
                        English:"Anouncements, Experiences and Personal Notes",
                        Hebrew:"הודעות, חוויות ומידע אישי",
                    },
                    extraInfoHeaderPrefix:{
                        English:"",
                        Hebrew:"קיר מידע נוסף עבור -   ",
                    },
                    extraInfoHeaderPostfix:{
                        English:"   - Additional information wall",
                        Hebrew:"",
                    },
                    extraInfoEmptyWall:{
                        English:"The wall still is empty",
                        Hebrew:"הקיר עדיין ריק"
                    },
                    personalEmptyWall:{
                        English:"You don't have notes for this place",
                        Hebrew:"לא שמרת הערות למקום זה"
                    },
                    experienceEmptyWall:{
                        English:"No one yet shared an experiences for this place",
                        Hebrew:"אף אחד לא שיתף חווייה על מקום זה"
                    },
                    addHighlight:{
                        English:"Write post",
                        Hebrew:"כתוב פוסט"
                    },
                    addExperience:{
                        English:"Share Experience",
                        Hebrew:"שתף חוויה"
                    },
                    addNote:{
                        English:"Save note",
                        Hebrew:"שמור הערה"
                    },
                    addToFavoriteMessage:{
                        English:"To add recommendations, first add this place to favorites",
                        Hebrew:"על מנת להוסיף המלצות יש תחילה לצרף את המיקום למועדפים"
                    },
                    topicFilterPlaceholder:{
                        English:"Search information by topic",
                        Hebrew:"חפש מידע לפי נושא"
                    },
                    allTopicsPlaceholder:{
                        English:"Show all",
                        Hebrew:"הצג הכל"
                    },
                    extraInfoIconText:{
                        English:"Info Wall",
                        Hebrew:"קיר מידע"
                    },
                    
                    experiencesIconText:{
                        English:"Experiences",
                        Hebrew:"חוויות"
                    },
                    ideasIconText:{
                        English:"My notes",
                        Hebrew:"הערות שלי"
                    }
                },
                openHours:{
                    specialDates:{
                        button:{
                            English:"Special dates",
                            Hebrew:"תאריכים מיוחדים",
                        },
                        set:{
                            English:"Set",
                            Hebrew:"הגדר",
                        },
                        remove:{
                            English:"Remove",
                            Hebrew:"הסר",
                        },
                        header:{
                            English:"Set range for dates with different openning hours (e.g. summer/winter time)",
                            Hebrew:"הגדר טווח תאריכים לשעות פתיחה שונות (לדוגמה חורף-קיץ)"
                        }
                    },
                    addHours:{
                        English:"Add hours range",
                        Hebrew:"הוסף טווח שעות",
                    },
                    open24Hours:{
                        English:"Open 24 hours",
                        Hebrew:"פתוח 24 שעות",
                    },
                    closed:{
                        English:"Closed",
                        Hebrew:"סגור",
                    },
                    weekDaysShort:{
                        sunday:{
                            English:"Sun",
                            Hebrew:"א'",
                        },
                        monday:{
                            English:"Mon",
                            Hebrew:"ב'",
                        },
                        tuesday:{
                            English:"Tue",
                            Hebrew:"ג'",
                        },
                        wednesday:{
                            English:"Wed",
                            Hebrew:"ד'",
                        },
                        thursday:{
                            English:"Thu",
                            Hebrew:"ה'",
                        },
                        friday:{
                            English:"Fri",
                            Hebrew:"ו'",
                        },
                        saturday:{
                            English:"Sat",
                            Hebrew:"שבת",
                        }
                    },
                    weekDaysLong:{
                        sunday:{
                            English:"Sunday",
                            Hebrew:"ראשון",
                        },
                        monday:{
                            English:"Monday",
                            Hebrew:"שני",
                        },
                        tuesday:{
                            English:"Tuesday",
                            Hebrew:"שלישי",
                        },
                        wednesday:{
                            English:"Wednesday",
                            Hebrew:"רביעי",
                        },
                        thursday:{
                            English:"Thursday",
                            Hebrew:"חמישי",
                        },
                        friday:{
                            English:"Friday",
                            Hebrew:"שישי",
                        },
                        saturday:{
                            English:"Saturday",
                            Hebrew:"שבת",
                        }
                    },
                    title:{
                        English:"Manage open hours",
                        Hebrew:"הגדר שעות פעילות",
                    },
                    saveButton:{
                        English:"Save",
                        Hebrew:"שמור",
                    },
                    unspecifiedHours: {
                        English:"Not specified",
                        Hebrew:"לא מוגדר",
                    },
                    adTimeRangesButton: {
                        English:"New time range",
                        Hebrew:"טווח זמנים חדש",
                    },
                    closedMessage:{
                        English:"Closed",
                        Hebrew:"סגור",
                    },
                    opensMessage:{
                        English:"Opens:",
                        Hebrew:"שעת פתיחה:",
                    },
                    closingTimeMessage:{
                        English:"Closes:",
                        Hebrew:"שעת סגירה:",
                    },
                    removeRowMessage:{
                        English:"Remove",
                        Hebrew:"הסר",
                    },
                    today:{
                        English:"Today",
                        Hebrew:"היום"
                    }
                }
            },
            extraModals:{
                customFilter:{
                    title:{
                        English:"Custom quick search filter",
                        Hebrew:"חיפוש מהיר מותאם אישית",
                    },
                    createButton:{
                        English:"Create",
                        Hebrew:"צור"
                    }
                },
                confirmationModal:{
                    noteMessage:{
                        English:"You are about to discard note creation",
                        Hebrew:"הינך עלול לבטל את יצירת ההערה",
                    },
                    message:{
                        English:"You are about to loose all of your changes",
                        Hebrew:"הינך עלול לאבד את כל השינויים שביצעת",
                    },
                    highlightMessage:{
                        English:"You are about to discard additional information creation",
                        Hebrew:"הינך הולך לבטל את יצירת המידע הנוסף",
                    },
                    removeMessage:{
                        English:"You are about to remove this item",
                        Hebrew:"הינך עומד להסיר פריט זה",
                    },
                    imageMessage:{
                        English:"You are about to remove this photo",
                        Hebrew:"הינך עומד למחוק תמונה זו לצמיתות",
                    },
                    title:{
                        English:"Attention!",
                        Hebrew:"אזהרה!",
                    },
                    stay:{
                        English:"Stay",
                        Hebrew:"הישאר",
                    },
                    leave:{
                        English:"Leave",
                        Hebrew:"עזוב"
                    },
                    delete:{
                        English:"Delete",
                        Hebrew:"מחק"
                    },
                    remove:{
                        English:"Remove",
                        Hebrew:"הסר"
                    },
                    cancel:{
                        English:"Cancel",
                        Hebrew:"בטל"
                    },
                    deleteMessage:{
                        English:"You are going to delete this information permanently",
                        Hebrew:"הינך עומד למחוק את המידע הזה באופן קבוע"
                    },
                    removeExpFromPlan:{
                        English:"You are going to remove this experience and its photos for all trip editors",
                        Hebrew:"הינך עומד להסיר את החווייה וכל התמונות שלה לך ולכל עורכי הטיול"
                    }
                },
                manageLinks:{
                    title:{
                        English:"Manage links",
                        Hebrew:"נהל קישורים",
                    },
                    addLinkPlaceHolder: {
                        English:"Add a link",
                        Hebrew:"הוסף קישור",
                    },
                    addButton:{
                        English:"Add",
                        Hebrew:"הוסף",
                    },
                    namePlaceholder:{
                        English:"Title",
                        Hebrew:"כותרת",
                    },
                    updateButton: {
                        English:"Update",
                        Hebrew:"עדכן",
                    },
                    urlTitle:{
                        English:"Url",
                        Hebrew:"קישור"
                    },
                    invalidUrl:{
                        English:"Invalid URL",
                        Hebrew:"קישור לא תקין"
                    }
                },
                addToPlan:{
                    experienceDateMessage:{
                        English:"Experience date is out of trip range, please update the experience date:",
                        Hebrew:"תאריך החוויה מחוץ לתאריכי הטיול, אנא עדכן את התאריך:"
                    },
                    updateDate:{
                        English:"Update Date",
                        Hebrew:"עדכן תאריך"
                    },
                    placeTitle:{
                        English:"Add place to plan",
                        Hebrew:"מוסיף מיקום לתוכנית"
                    },
                    experienceTitle:{
                        English:"Adding experience to plan",
                        Hebrew:"מוסיף חוויה לתוכנית"
                    },
                    button:{
                        English:"Done",
                        Hebrew:"סיים"
                    },
                    choosePlan:{
                        English:"Choose plan from list",
                        Hebrew:"בחר תוכנית מהרשימה"
                    },
                    createNewPlan:{
                        English:"Create new trip plan",
                        Hebrew:"צור תוכנית טיול חדשה"
                    },
                    chooseDay:{
                        English:"Choose plan day",
                        Hebrew:"בחר יום של הטיול"
                    }
                },
                setLocation:{
                    title:{
                        English:"Set place location",
                        Hebrew:"הגדר מיקום",
                    },
                    bySearchButton: {
                        English:"By search",
                        Hebrew:"חיפוש המיקום",
                    },
                    currentLocationButton:{
                        English:"Current location",
                        Hebrew:"מיקום נוכחי",
                    },
                    mapClickButton:{
                        English:"By map double click",
                        Hebrew:"סמן על המפה",
                    },
                    searchPlaceholder: {
                        English:"Choose location",
                        Hebrew:"בחר מיקום",
                    },
                    doneButton:{
                        English:"Update",
                        Hebrew:"עדכן",
                    },
                    locationMessage:{
                        English:"Location permissions are denied. Please click the following link for instructions on how to allow it: ",
                        Hebrew:"הינך חוסם הרשאות לשימוש המיקום שלך. בכדי לאשר שימוש במיקומך פעל לפי ההוראות בקישור שלהלן: ",
                    },
                    instructions:{
                        English:"Instructions",
                        Hebrew:"הוראות",
                    },
                    setLocationTree:{
                        title:{
                            English:"Finalize",
                            Hebrew:"סיים להגדיר את המיקום עבור ",
                        },
                        titleEnding:{
                            English:"location",
                            Hebrew:"",
                        },
                        message:{
                            English:"Place must have a specified country and either region or city or both",
                            Hebrew:"מיקום תקין צריך להחיל מדינה ולפחות אחד מהשניים מבין מחוזר/ אזור או עיר",
                        },
                        country:{
                            English:"Country",
                            Hebrew:"מדינה",
                        },
                        region:{
                            English:"Region/District",
                            Hebrew:"מחוז",
                        },
                        city:{
                            English:"City/Area",
                            Hebrew:"עיר או אזור",
                        },
                        finishButton:{
                            English:"Finish",
                            Hebrew:"סיים",
                        },
                        selectCountry:{
                            English:"Select Country",
                            Hebrew:"בחר מדינה",
                        }
                    }
                }
            },
            audience:{
                everyone:{
                    English:"Everyone",
                    Hebrew:"כולם",
                },
                families:{
                    English:"Families",
                    Hebrew:"משפחות",
                },
                couples:{
                    English:"Couples",
                    Hebrew:"זוגות",
                },
                solo:{
                    English:"Solo traveler",
                    Hebrew:"יחידים",
                },
                adults:{
                    English:"Adults only",
                    Hebrew:"מבוגרים בלבד",
                },
                kids:{
                    English:"Good for kids",
                    Hebrew:"מתאים לילדים",
                },
                audienceFilterTitle:{
                    English:"Specific audience ",
                    Hebrew:"מתאים לקהל יעד מסויים "
                },
                groups:{
                    English:"Good for groups",
                    Hebrew:"מתאים לקבוצות"
                }
            },
            
            categories:{
                categoriesSectionsNames:{
                    cultural:{
                        English:"Cultural & sightseeing",
                        Hebrew:"תרבות ואתרי תיירות",
                    },
                    nature:{
                        English:"Nature locations",
                        Hebrew:"טבע",
                    },
                    activities:{
                        English:"Leisure, recreations & fun activities",
                        Hebrew:"בילוי ופנאי",
                    },
                    sport:{
                        English:"Sport activities",
                        Hebrew:"ספורט",
                    },
                    food:{
                        English:"Foods & drinks locations",
                        Hebrew:"אוכל ושתייה",
                    },
                    accommodation:{
                        English:"Accomodation",
                        Hebrew:"לינה",
                    },
                    all:{
                        English:"All categories",
                        Hebrew:"כל הקטגוריות",
                    },
                }
            },  
            favorites:{
                addToFavorite:{
                    title:{
                        English:"Add to favorite",
                        Hebrew:"הוסף למועדפים",
                    },
                    predefinedTags:{
                        English:"Predefined search tags",
                        Hebrew:"תגיות מובנות שניתן לחפש לפיהם"
                    },
                    tagsPlaceholder:{
                        English:"Add tags to make search easy in the future",
                        Hebrew:"הוסף תגיות שיעזרו לחפש בקלות מאוחר יותר"
                    }
                }
            },
            buttons:{
                saveButton:{
                    English:"Save",
                    Hebrew:"שמור",
                },
                updateButton:{
                    English:"Update",
                    Hebrew:"עדכן",
                },
                createButton:{
                    English:"Create",
                    Hebrew:"צור",
                }
            },
            welcomePage:{
                welcomeMainMessage:{
                    English:"Plan Your Next Experience",
                    Hebrew:"תכנן את החווייה הבאה שלך",
                },
                welcomeSubMessage:{
                    English:"Get Inspired. Discover. Plan. Collaborate. Capture. Share",
                    Hebrew:"קבל השראה. גלה מקומות. תכנן. תעד. שתף",
                },
                welcomeOptions:{
                    currentCountry:{
                        English:"Explore ",
                        Hebrew:"גלה את ",
                    },
                    chooseDestanation:{
                        English:"Choose destination",
                        Hebrew:"בחר יעד",
                    },
                    selectCategory:{
                        English:"Discover a category",
                        Hebrew:"חפש סוג של חוויה",
                    },
                    createNew: {
                        English:"Create new",
                        Hebrew:"צור חדש",
                    }
                }
            }
        }
        

      
    }

    returnText=()=>{
      return this.state.systemText
    }

    render(){

    return (
        <div>
         </div>
      );
    };
}
    export default SystemText;
    