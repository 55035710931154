import React, { Component } from "react";
import classes from "./CreateTripTemplate.module.css";

import SimpleSearchBar from "../../../Components/Complex/SearchBars/SimpleSearchBar"
import Tag from "../../../Components/UI/Tag/Tag"
import MainCategory from "../../../Components/UI/Misc/MainCategory"
import OptionSelection from "../../../Components/Complex/OptionSelection/OptionSelection"
import GoogleMap from "../../../Components/Complex/Map/GoogleMap"
import PlanPlaceItem from "../../../Components/Complex/Plan/PlanPlaceItem"
import PlanDayItem from "../../../Components/Complex/Plan/PlanDayItem"
import Backdrop from "../../../Components/UI/Backdrop/Backdrop"
import PlaceSelection from "../../../Components/Complex/PlaceSelection/PlaceSelection"
import TextButton from "../../../Components/UI/Buttons/TextButton";
import Tab from "../../../Components/UI/Tabs/Tab"
import PlanDaysOptions from "../../../Components/Complex/Plan/PlanDaysOptions"
import { isMobile } from "react-device-detect";

import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const moment = require('moment');

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"
let resetIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FResetIcon.png?alt=media&token=c3be2f66-2cd2-42d7-b97c-b4da958a51f1";
let locationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLocationIcon.png?alt=media&token=7ab0f38c-21ab-4b86-abe3-97f41437e77f";
let accomodationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIcon.png?alt=media&token=3f3fbed6-2d65-4896-a2d0-6036f7e1ac5b";

let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowLeft.png?alt=media&token=8020b73f-93be-478c-a1c3-c3cee641fbe8"
let arrowIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowRight.png?alt=media&token=e0fca8aa-a34f-47cf-80d3-79b36b305e88"

let accomodationIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIconGrey.png?alt=media&token=2c1c88f1-8194-41c1-bed4-168f6069c4f2";

let radioButtonChecked = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FRadioButtonChecked.png?alt=media&token=c962a776-42f5-4063-822f-f1f4a0a6fecc"
let radioButtonClear = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FRadioButtonClear.png?alt=media&token=76d5e393-ae07-478b-85d8-fe4ee149831f"
/* let resetIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FResetIcon.png?alt=media&token=c3be2f66-2cd2-42d7-b97c-b4da958a51f1";
 */

export default class CreateTripTemplate extends Component {

    state={
        showNullBackdrop:false,
        destinationTags:[],
        destinationType:"",
        destinationCountry: "",
        selectedDestinations: [],
        tripDuration:1,
        tripDurationInput:1,
        tripRadius:10,
        tripRadiusDefined:false,
        dailyTripRadius: 5,
        dailyTripRadiusDefined: false,
        maxPlacesPerDay:3,
        tripIntensity: "medium",
        maxPlacesPerDayInput:3,
        tripDurationDefined:false,
        maxPlacesPerDayDefined: false,
        mainCategoriesIncluded:[0,0,0,0,0,0],
        mainCategoriesSelected:[0,0,0,0,0,0],
        potentialDestinationPlaces: [],
        selectedPotentialPlaces: [],
        availablePlaceCategories: [],
        includedCategories: [],
        excludedCategories: [],
        mainCategoriesAreSelected: false,
        useTripArea: false,
        tripCenterCoordinates:{},
        selectedPlaces:[],
        selectedPlacesIDs:[],
        selectedAccommodation:null,
        clickedMarkerIndex:-1,
        hoverIndex:-1,
        daysNames:["Any day","Day 1"],
        markersOnMap:[],
        travelWithKids: false,
        accommodationCategories:[],
        potentialAccommodations:[],
        showAccommodationsOnMap:false,
        viewSelection:["SelectedMobileWide", "RegularMobileWide"],
        viewMode:"preferneces",
        datesRangeSelected:false,
        selectionRange:{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
        noDatesSelected: true,
        oneDayTrip:true,
        extraInfoRef:React.createRef()
    }

    componentDidMount = () =>{
       /*  let destination = {
            English: "North District",
            Hebrew: "צפון",
            tagType: "LocationFilter",
            treeID: "Israel_North District",
            type: "region"
          }

        this.updateSearchDestination(destination) */
        if (this.props.selectedTripGeneratorParams!==undefined){

            this.setState({  
               
                destinationType:this.props.selectedTripGeneratorParams.destinationType,
                destinationCountry: this.props.selectedTripGeneratorParams.destinationCountry,
                selectedDestinations: this.props.selectedTripGeneratorParams.selectedDestinations,
                destinationPlacesCenterCoordinates:this.props.selectedTripGeneratorParams.destinationPlacesCenterCoordinates,
                destinationPlacesBoundsCoordinates:this.props.selectedTripGeneratorParams.destinationPlacesBoundsCoordinates,
                destinationAreaBounds:this.props.selectedTripGeneratorParams.destinationAreaBounds,
                tripDuration: this.props.selectedTripGeneratorParams.tripDuration,
                tripDurationInput: this.props.selectedTripGeneratorParams.tripDuration,
                tripRadius: this.props.selectedTripGeneratorParams.tripRadius,
                tripRadiusDefined:false,
                dailyTripRadius: this.props.selectedTripGeneratorParams.dailyTripRadius,
                dailyTripRadiusDefined: false,
                maxPlacesPerDay:this.props.selectedTripGeneratorParams.maxPlacesPerDay,
                maxPlacesPerDayInput:this.props.selectedTripGeneratorParams.maxPlacesPerDay,
                tripIntensity: this.props.selectedTripGeneratorParams.tripIntensity,
                tripDurationDefined:false,
                maxPlacesPerDayDefined: false,
                mainCategoriesIncluded:[0,0,0,0,0,0],//need to calc
                mainCategoriesSelected:this.props.selectedTripGeneratorParams.mainCategoriesSelected,
                selectedPotentialPlaces: [],//need to calc
                availablePlaceCategories: [],//need to calc
                includedCategories: this.props.selectedTripGeneratorParams.includedCategories,
                excludedCategories: this.props.selectedTripGeneratorParams.excludedCategories,
                mainCategoriesAreSelected: false,
                useTripArea: this.props.selectedTripGeneratorParams.useTripArea,
                tripCenterCoordinates:this.props.selectedTripGeneratorParams.tripCenterCoordinates,
                selectedPlaces:this.props.selectedTripGeneratorParams.selectedPlaces,
                selectedPlacesIDs:this.props.selectedTripGeneratorParams.selectedPlaces.map(place=>{return place.placeID}),
                destinationPlacesList: this.props.selectedTripGeneratorParams.destinationPlacesList,
                potentialDestinationPlaces: this.props.selectedTripGeneratorParams.potentialDestinationPlaces,
                cityRegionPlacesList: this.props.selectedTripGeneratorParams.cityRegionPlacesList,
                placesInTripArea: this.props.selectedTripGeneratorParams.placesInTripArea,
                allLoadedPlaces: [],
                selectedAccommodation:this.props.selectedTripGeneratorParams.selectedAccommodation,
                accommodationCategories:this.props.selectedTripGeneratorParams.accommodationCategories,
                daysNames:[],
                markersOnMap:[],//need to calc
                noDatesSelected:this.props.selectedTripGeneratorParams.noDatesSelected,
                oneDayTrip:this.props.selectedTripGeneratorParams.oneDayTrip,
                travelWithKids:this.props.selectedTripGeneratorParams.travelWithKids,
            })
            let allLoadedPlaces = [...this.props.selectedTripGeneratorParams.destinationPlacesList]
            let placesListIDs = this.props.selectedTripGeneratorParams.destinationPlacesList.map(place=>{return place.placeID})
            for (let i=0 ; i<this.props.selectedTripGeneratorParams.cityRegionPlacesList.length ; i++){
                if (!placesListIDs.includes(this.props.selectedTripGeneratorParams.cityRegionPlacesList[i].placeID)){
                 allLoadedPlaces.push(this.props.selectedTripGeneratorParams.cityRegionPlacesList[i])
                }
                
            }
            this.setState({allLoadedPlaces:allLoadedPlaces})
            this.tripDaysHandler(this.state.tripDuration)
          
            let potentialPlaces = this.props.selectedTripGeneratorParams.potentialDestinationPlaces
            if (this.props.selectedTripGeneratorParams.useTripArea){
                potentialPlaces = this.props.selectedTripGeneratorParams.placesInTripArea
            }
            this.calcReusedParamsData(potentialPlaces,this.props.selectedTripGeneratorParams.useTripArea)
            let planDates = this.props.selectedTripGeneratorParams.planDates;
            planDates.startDate=new Date(planDates.startDate);
            planDates.endDate=new Date(planDates.endDate);
            let planStartDate =  moment( planDates.startDate);
            let planEndDate =  moment(planDates.endDate);
            this.setState({planDates:planDates,planStartDate:planStartDate,planEndDate:planEndDate,datesRangeSelected:true})
        }
        else{
            let startDate = new Date();
            let planStartDate =  moment(startDate);
            let planDates={startDate:startDate,endDate:startDate}
            this.setState({planStartDate:planStartDate,planEndDate:planStartDate,planDates:planDates})
        }
    }

    calcReusedParamsData =  async (potentialPlaces,useTripArea) =>{
        await this.setState({
            potentialPlacesForAreaSelection:this.props.slimPlacesByCountry[this.props.selectedTripGeneratorParams.destinationCountry],
        })
        if (useTripArea){
            await this.generateTripAreaPlacesList()
        }
        await this.updateAvailableCategories(potentialPlaces)
        //based on selected categories
        await this.updateSelectedPotentialPlaces(potentialPlaces)
        /* if (this.state.showAccommodationsOnMap){ */
            this.updatePotentialAccommodations(potentialPlaces)
     /*    } */
    }

    

    componentDidUpdate = prevProps =>{
        if (prevProps.selectedAccommodation!==this.props.selectedAccommodation){
            this.setState({selectedAccommodation:this.props.selectedAccommodation})
            if (this.props.selectedAccommodation===null){
                this.setState({
                    selectedAccommodation:null
                })
            }
        }
        if (this.props.discardQuicPlaceCreation!==prevProps.discardQuicPlaceCreation){
            if (this.props.discardQuicPlaceCreation){
                this.setState({
                    showNullBackdrop:false
                })
            }
        }
    }

    
datePickerHandler = async (range) =>{
    let startDate=range.startDate;
    let endDate=range.endDate
    if (range.startDate===null||range.endDate===null){
      this.setState({planStartDate:startDate,planEndDate:endDate});
      return;
    }
    let planDates
    if (endDate===null){
      planDates ={startDate:startDate.toDate(),endDate:startDate.toDate()}
    }
    else{
      planDates ={startDate:startDate.toDate(),endDate:endDate.toDate()}
    }
    this.setState({planStartDate:startDate,planEndDate:endDate,datesRangeSelected:true,planDates:planDates});
    let tripDuration= 0
    if (endDate!==null){
        tripDuration= endDate.diff(startDate, 'days') +1; 
    }
    this.tripDurationHandler(tripDuration) 
  }
  
  singleDateHandler = date =>{
    this.datePickerHandler({startDate:date,endDate:date})
  }

    updateSearchDestination = async tag =>{
        let selectedDestinations = this.state.selectedDestinations
        selectedDestinations.push(tag)
       await this.setState({selectedDestinations: selectedDestinations, destinationType:tag.type,destinationCountry:tag.treeID.split('_')[0]})
       await this.resetTripRadiusToDefault(this.state.tripDuration)
       //SUPPORT ONLY ONE DESTINATION
       await this.props.gatherPlacesForSelectedTripDestination(tag).then(result=>{
        //in case filter by area on map, before loading from DB places by coordinates, have all the places for the country to use for it
        //potential places for area selection include at this point all country places    
        this.setState({potentialPlacesForAreaSelection:this.props.slimPlacesByCountry[tag.treeID.split('_')[0]]})
           //update potential places list for destination
           let allLoadedPlaces = [...result.placesList]
           let placesListIDs = result.placesList.map(place=>{return place.placeID})
           for (let i=0 ; i<result.cityRegionPlacesList.length ; i++){
               if (!placesListIDs.includes(result.cityRegionPlacesList[i].placeID)){
                allLoadedPlaces.push(result.cityRegionPlacesList[i])
               }
               
           }
         
            this.setState({
                destinationPlacesList: result.placesList,
                potentialDestinationPlaces: result.placesList,
                cityRegionPlacesList: result.cityRegionPlacesList,
                allLoadedPlaces:allLoadedPlaces
            })

            
            //calculate direct boundaries of selected destination
            let placesList = result.placesList
            //calculate places center coordinates
            let maxSouth = placesList[0].placeLocation.coordinates.lat
            let maxNorth = placesList[0].placeLocation.coordinates.lat
            let maxWest = placesList[0].placeLocation.coordinates.lng
            let maxEast = placesList[0].placeLocation.coordinates.lng
            for (let k = 0 ; k<placesList.length ; k++){
            
                if (placesList[k].placeStatus=="approved"){
                    if (placesList[k].placeLocation.coordinates.lat<maxSouth){
                        maxSouth = placesList[k].placeLocation.coordinates.lat
                    }
                    if (placesList[k].placeLocation.coordinates.lat>maxNorth){
                        maxNorth = placesList[k].placeLocation.coordinates.lat
                    }
                    if (placesList[k].placeLocation.coordinates.lng<maxWest){
                        maxWest = placesList[k].placeLocation.coordinates.lng
                    }
                    if (placesList[k].placeLocation.coordinates.lng>maxEast){
                        maxEast = placesList[k].placeLocation.coordinates.lng
                    }
        
                }
                
            }
            let destinationPlacesCenterCoordinates = {lat:(maxNorth+maxSouth)/2,lng:(maxEast+maxWest)/2}
            let northWestMapBound = {lat:maxNorth,lng:maxWest}
            let northEastMapBound = {lat:maxNorth,lng:maxEast}
            let sothWestMapBound = {lat:maxSouth,lng:maxWest}
            let southEastMapBound = {lat:maxSouth,lng:maxEast}
            let destinationPlacesBoundsCoordinates = []
            destinationPlacesBoundsCoordinates.push({
                placeLocation: { coordinates: northWestMapBound}
              });
            destinationPlacesBoundsCoordinates.push({
                placeLocation: { coordinates: northEastMapBound}
            });
            destinationPlacesBoundsCoordinates.push({
                placeLocation: { coordinates: sothWestMapBound}
            });
            destinationPlacesBoundsCoordinates.push({
                placeLocation: { coordinates: southEastMapBound}
            });
           
           let  destinationAreaBounds = {
                north:maxNorth,
                south:maxSouth,
                west:maxWest,
                east:maxEast
            }
            this.setState({destinationPlacesCenterCoordinates:destinationPlacesCenterCoordinates,
                destinationPlacesBoundsCoordinates:destinationPlacesBoundsCoordinates,
                destinationAreaBounds:destinationAreaBounds})

       });
       //in case of the destination being a city, consider using also the places from 
       if (this.state.destinationType==="city"){
           await this.generateCityPotentialPlacesList()
       }
       await this.updateAvailableCategories(this.state.potentialDestinationPlaces)
       //based on selected categories
       await this.updateSelectedPotentialPlaces(this.state.potentialDestinationPlaces)
      /*  if (this.state.showAccommodationsOnMap){ */
         this.updatePotentialAccommodations(this.state.potentialDestinationPlaces)
       /*  } */

    }

    generateCityPotentialPlacesList = async () =>{
        return new Promise(async (resolve, reject) => {
            //in case of a city let's calculate potential places around the city as well based on trip radius
            let tripRadius = this.state.tripRadius
            let destinationAreaBounds = this.state.destinationAreaBounds
            let cityRegionPlacesList = this.state.cityRegionPlacesList
            let destinationPlacesList = this.state.destinationPlacesList

            //to avoid using same place twice from city and from the region
            let includedPlacesIDs = destinationPlacesList.map(place=>{return place.placeID})

            //add the additional places on top of the city places
            let potentialDestinationPlaces = [...destinationPlacesList]
            let potentialBoundaries = {
                north:destinationAreaBounds.south+(tripRadius/100)*Math.sqrt(2),
                south:destinationAreaBounds.north-(tripRadius/100)*Math.sqrt(2),
                west:destinationAreaBounds.east-(tripRadius/70)*Math.sqrt(2),
                east:destinationAreaBounds.west+(tripRadius/70)*Math.sqrt(2),
            }
            for (let i = 0 ; i<cityRegionPlacesList.length ; i++){
                    let potentialPlaceCoords = cityRegionPlacesList[i].placeLocation.coordinates
                    if (potentialPlaceCoords.lat<potentialBoundaries.north&&
                        potentialPlaceCoords.lat>potentialBoundaries.south&&
                        potentialPlaceCoords.lng>potentialBoundaries.west&&
                        potentialPlaceCoords.lng<potentialBoundaries.east){
                            if (!includedPlacesIDs.includes(cityRegionPlacesList[i].placeID)){
                                potentialDestinationPlaces.push(cityRegionPlacesList[i])
                            }
                            
                    }
            }
            await this.setState({potentialDestinationPlaces:potentialDestinationPlaces})

            resolve()
        });
    }

 

     removeDestination = index =>{
        this.setState({selectedDestinations:[], 
            tripRadiusDefined:false, 
            dailyTripRadiusDefined:false,
            placesCenterCoordinates:undefined,
            useTripArea:false,
            tripCenterCoordinates:{},
            destinationPlacesCenterCoordinates:undefined})
       
     }

     updateAvailableCategories = async (placesList) =>{
        return new Promise(async (resolve, reject) => {
            let mainCategoriesIncluded = [0,0,0,0,0,0]
            let availablePlaceCategories = []
            for (let i = 0 ; i<placesList.length ; i++){
                for (let j=0 ; j<placesList[i].placeMainCategory.length ; j++){
                    if (placesList[i].placeMainCategory[j]===1){
                        mainCategoriesIncluded[j]=1
                    }
                }
                if (placesList[i].placeCategory!==undefined){
                    for (let j=0 ; j<placesList[i].placeCategory.length ; j++){
                        if (!availablePlaceCategories.includes(placesList[i].placeCategory[j])){
                            availablePlaceCategories.push(placesList[i].placeCategory[j])
                        }
                    }
                }
               
            }

        //if included categories exist that are not in the new list, remove them
            let includedCategories = this.state.includedCategories
            let remainedIncludedCategories = []
            for (let i =0 ; i<includedCategories.length ; i++){
                if (availablePlaceCategories.includes(includedCategories[i])){
                    remainedIncludedCategories.push(includedCategories[i])
                }
            }

            //if main categories selected but not included, remove them and recalculate if maincategoriesareselected
            let mainCategoriesSelected = this.state.mainCategoriesSelected
            let  mainCategoriesAreSelected = false
            for (let i = 0 ; i<mainCategoriesSelected.length; i++){
                if (mainCategoriesIncluded[i]===1){
                    if (mainCategoriesSelected[i]!==0){
                        mainCategoriesAreSelected = true
                    }
                }
                else{
                    mainCategoriesSelected[i]=0 
                }
                
            }
            
            let allowedPlacesCategories = []
            for (let i =0 ; i<this.props.systemCategories.length ; i++){
                if (mainCategoriesSelected[i]!==0){
                    let allowedCategoriesIDs = Object.values(this.props.systemCategories[i]).map(cat=>{return cat.id})
                    for (let j=0 ; j<availablePlaceCategories.length ; j++){
                        if (allowedCategoriesIDs.includes(availablePlaceCategories[j])){
                            allowedPlacesCategories.push(availablePlaceCategories[j])
                        }
                    }
                }
            }
            
            await this.setState({
                mainCategoriesIncluded:mainCategoriesIncluded,
                allowedPlacesCategories:allowedPlacesCategories,
                includedCategories:remainedIncludedCategories,
                mainCategoriesSelected:mainCategoriesSelected,
                mainCategoriesAreSelected:mainCategoriesAreSelected
            })
            resolve()
        });
     }

     generateTripAreaPlacesList = () =>{
        return new Promise(async (resolve, reject) => {
            let potentialPlacesList = this.state.potentialPlacesForAreaSelection
            let placesInTripArea = []
            let tripRadius = this.state.tripRadius
            var ky = 40000 / 360;
            var kx = Math.cos(Math.PI * this.state.tripCenterCoordinates.lat / 180.0) * ky;
            for (let i = 0 ; i<potentialPlacesList.length ; i++){
                let potentialPlaceCoords = potentialPlacesList[i].placeLocation.coordinates
                var dx = Math.abs(this.state.tripCenterCoordinates.lng - potentialPlaceCoords.lng) * kx;
                var dy = Math.abs(this.state.tripCenterCoordinates.lat - potentialPlaceCoords.lat) * ky;
                if ( Math.sqrt(dx * dx + dy * dy) <= tripRadius){
                    placesInTripArea.push(potentialPlacesList[i])
                }
            }
           
            
            await this.setState({placesInTripArea:placesInTripArea})
            resolve()
        });
     }


     tripRadiusInputHandler = async e =>{
        let radius = e.target.value
        await this.setState({tripRadius: radius})
       

     }

     tripRadiusOnBlur = async () =>{
         const today = new Date();
         let start = today.getTime();
         if (this.state.tripRadius<5){
             await this.setState({tripRadius:5})
         }
         this.setState({tripRadiusDefined:true})
         //in case of a city, if user change radius let's reacalculate what are the potential places list
         
         if (this.state.destinationType==="city"&&!this.state.useTripArea){
            await this.generateCityPotentialPlacesList()
        }
        //console.log(new Date()-start)
        let potentialPlaces = this.state.potentialDestinationPlaces
        if (this.state.useTripArea){
           await this.generateTripAreaPlacesList()
            potentialPlaces = this.state.placesInTripArea
        }
        //console.log(new Date()-start)
        await this.updateAvailableCategories(potentialPlaces)
        //console.log(new Date()-start)
        //based on selected categories
         await this.updateSelectedPotentialPlaces(potentialPlaces)
        //console.log(new Date()-start)
      /*   if (this.state.showAccommodationsOnMap){ */
            this.updatePotentialAccommodations(potentialPlaces)
     /*    } */
        //console.log(new Date()-start)
     }

     showAccommodationsOnMapHandler = async () =>{
        await this.setState({showAccommodationsOnMap:true})
        let potentialPlaces = this.state.potentialDestinationPlaces
        if (this.state.useTripArea){
            potentialPlaces = this.state.placesInTripArea
        }
        this.updatePotentialAccommodations(potentialPlaces)
    }

     dailyTripRadiusInputHandler = e =>{
       
        let radius = e.target.value
        if (radius>this.state.tripRadius){
            radius = this.state.tripRadius
        }
        this.setState({dailyTripRadius: radius, dailyTripRadiusDefined:true})
     }

     dailyTripRadiusOnBlur = () =>{
        if (this.state.dailyTripRadius<1){
            this.setState({dailyTripRadius: 1})
        }
     }

     mainCategorySelectionHandler = async index =>{
        let mainCategoriesSelected = this.state.mainCategoriesSelected
        let mainCategoriesAreSelected = false;
        if (mainCategoriesSelected[index]===0){
            mainCategoriesSelected[index]=1
            mainCategoriesAreSelected = true
        }
        else{
            mainCategoriesSelected[index]=0
            for (let i = 0 ; i<mainCategoriesSelected.length; i++){
                if (mainCategoriesSelected[i]!==0){
                    mainCategoriesAreSelected = true
                }
            }
        }
        

        
        await this.setState({mainCategoriesSelected:mainCategoriesSelected,
            mainCategoriesAreSelected:mainCategoriesAreSelected,})

        let potentialPlaces = this.state.potentialDestinationPlaces
        if (this.state.useTripArea){
            await this.generateTripAreaPlacesList()
            potentialPlaces = this.state.placesInTripArea
        }
        await this.updateAvailableCategories(potentialPlaces)
        this.updateSelectedPotentialPlaces(potentialPlaces)
        if (this.state.showAccommodationsOnMap){
            this.updatePotentialAccommodations(potentialPlaces)
        }
    }
    
    updatePotentialAccommodations = async (placesList) =>{
        return new Promise(async (resolve, reject) => {
            let potentialAccommodations = placesList.filter(place=>place.placeMainCategory[5]===1)
            let filteredAccommodations = []
            if (this.state.accommodationCategories.length>0){
                for (let i=0 ; i<potentialAccommodations.length ; i++){
                    let placeCategory = []
                    if (potentialAccommodations[i].placeCategory!==undefined){
                        placeCategory=potentialAccommodations[i].placeCategory
                    }
                    let include = false
                    for (let j=0 ; j<this.state.accommodationCategories.length ; j++){
                        if (placeCategory.includes(this.state.accommodationCategories[j].id)){
                            include = true
                        }
                    }
                    if (include){
                        filteredAccommodations.push(potentialAccommodations[i])
                    }
                }
               
            }
            else{
                filteredAccommodations = potentialAccommodations
            }
            this.setState({potentialAccommodations:filteredAccommodations})
            resolve()
        });
    }

    updateSelectedPotentialPlaces = async (placesList) =>{
        return new Promise(async (resolve, reject) => {    

            let selectedPotentialPlaces = []
            let mainCategoriesSelected = this.state.mainCategoriesSelected
            let excludedCategories = this.state.excludedCategories

            for (let i=0; i<placesList.length ; i++){
                let includePlace = false
                for (let j=0 ; j<placesList[i].placeMainCategory.length ; j++){
                    if (mainCategoriesSelected[j]!==0&&placesList[i].placeMainCategory[j]===1){
                        includePlace = true
                    }
                }
                if (placesList[i].placeCategory!==undefined){
                    for (let j=0 ; j<placesList[i].placeCategory.length ; j++){
                        if (excludedCategories.includes(placesList[i].placeCategory[j])){
                            includePlace = false
                        }
                    }
                }
               
                if (this.state.travelWithKids){
                    if (placesList[i].placeCharacteristics!==undefined){
                        if (placesList[i].placeCharacteristics.audienceTags!==undefined){
                            if (placesList[i].placeCharacteristics.audienceTags.includes("adultsOnly")||
                            !placesList[i].placeCharacteristics.audienceTags.includes("families")||
                            !placesList[i].placeCharacteristics.audienceTags.includes("forKids") ||
                            !placesList[i].placeCharacteristics.audienceTags.includes("goodForKids")){
                                 includePlace = false}
                        }
                    }
                }
                if (includePlace){
                    selectedPotentialPlaces.push(placesList[i])
                }
            }
            let potentialMarkers = []
            if (!this.state.useTripArea&&this.state.mapBounds!==undefined){
                let placesInBounds = []
                for (let i=0 ; i<selectedPotentialPlaces.length;i++){
                    let placeCoordinates = selectedPotentialPlaces[i].placeLocation.coordinates
                    if (placeCoordinates.lat<this.state.mapBounds.north&&
                        placeCoordinates.lat>this.state.mapBounds.south&&
                        placeCoordinates.lng>this.state.mapBounds.west&&
                        placeCoordinates.lng<this.state.mapBounds.east){
                            placesInBounds.push(selectedPotentialPlaces[i]) 
                        }
                }
                potentialMarkers = [...placesInBounds]
            }
            else{
                potentialMarkers = [...selectedPotentialPlaces]
            }
            await this.setState({selectedPotentialPlaces:selectedPotentialPlaces})
            //calculate what places to show on map
            if (potentialMarkers.length ===0){
                this.setState({markersOnMap:[]})
            }
            else if (potentialMarkers.length<20){
                let markersOnMap=[...potentialMarkers].filter(place=>!this.state.selectedPlacesIDs.includes(place.placeID))
                
               /*  let markers = []
                for (let i =0 ; i<markersOnMap.length ; i++){
                    markers.push({
                        placeLocation: { coordinates: markersOnMap[i].placeLocation.coordinates}
                      });
                } */
                this.setState({markersOnMap:markersOnMap})
            }
            else{
                //divide the area to 4
                let markersOnMap = [];
                let maxSouth = potentialMarkers[0].placeLocation.coordinates.lat
                let maxNorth = potentialMarkers[0].placeLocation.coordinates.lat
                let maxWest = potentialMarkers[0].placeLocation.coordinates.lng
                let maxEast = potentialMarkers[0].placeLocation.coordinates.lng
                for (let k = 0 ; k<potentialMarkers.length ; k++){
                    if (potentialMarkers[k].placeLocation.coordinates.lat<maxSouth){
                        maxSouth = potentialMarkers[k].placeLocation.coordinates.lat
                    }
                    if (potentialMarkers[k].placeLocation.coordinates.lat>maxNorth){
                        maxNorth = potentialMarkers[k].placeLocation.coordinates.lat
                    }
                    if (potentialMarkers[k].placeLocation.coordinates.lng<maxWest){
                        maxWest = potentialMarkers[k].placeLocation.coordinates.lng
                    }
                    if (potentialMarkers[k].placeLocation.coordinates.lng>maxEast){
                        maxEast = potentialMarkers[k].placeLocation.coordinates.lng
                    }
    
                }
                
               
                let firstQuarterBounds={
                    north:maxNorth,
                    south:(maxNorth+maxSouth)/2,
                    west:maxWest,
                    east: (maxWest+maxEast)/2
                }
                let secondQuarterBounds = {
                    north:maxNorth,
                    south:(maxNorth+maxSouth)/2,
                    west:(maxWest+maxEast)/2,
                    east: maxEast
                }
                let thirdQuarterBounds = {
                    north:(maxNorth+maxSouth)/2,
                    south:maxSouth,
                    west:maxWest,
                    east:(maxWest+maxEast)/2,
                }
                let fourthQuarterBounds = {
                    north:(maxNorth+maxSouth)/2,
                    south:maxSouth,
                    west:(maxWest+maxEast)/2,
                    east: maxEast
                }
                let usedPlacesIDs = []
                //first quarter
                for (let i=0 ; i<Math.min(6,potentialMarkers.length);i++){
                    let placeCoordinates = potentialMarkers[i].placeLocation.coordinates
                    if (placeCoordinates.lat<firstQuarterBounds.north&&
                        placeCoordinates.lat>firstQuarterBounds.south&&
                        placeCoordinates.lng>firstQuarterBounds.west&&
                        placeCoordinates.lng<firstQuarterBounds.east&&
                        !this.state.selectedPlacesIDs.includes(potentialMarkers[i].placeID)){
                            markersOnMap.push(potentialMarkers[i])
                            usedPlacesIDs.push(potentialMarkers[i].placeID)
                        }
                }
                //second quarter
                for (let i=0 ; i<Math.min(6,potentialMarkers.length);i++){
                    let placeCoordinates = potentialMarkers[i].placeLocation.coordinates
                    if (placeCoordinates.lat<secondQuarterBounds.north&&
                        placeCoordinates.lat>secondQuarterBounds.south&&
                        placeCoordinates.lng>secondQuarterBounds.west&&
                        placeCoordinates.lng<secondQuarterBounds.east&&
                        !this.state.selectedPlacesIDs.includes(potentialMarkers[i].placeID)&&
                        !usedPlacesIDs.includes(potentialMarkers[i].placeID)){
                            markersOnMap.push(potentialMarkers[i])
                            usedPlacesIDs.push(potentialMarkers[i].placeID)
                        }
                }
                //third quarter
                for (let i=0 ; i<Math.min(6,potentialMarkers.length);i++){
                    let placeCoordinates = potentialMarkers[i].placeLocation.coordinates
                    if (placeCoordinates.lat<thirdQuarterBounds.north&&
                        placeCoordinates.lat>thirdQuarterBounds.south&&
                        placeCoordinates.lng>thirdQuarterBounds.west&&
                        placeCoordinates.lng<thirdQuarterBounds.east&&
                        !this.state.selectedPlacesIDs.includes(potentialMarkers[i].placeID)&&
                        !usedPlacesIDs.includes(potentialMarkers[i].placeID)){
                            markersOnMap.push(potentialMarkers[i])
                            usedPlacesIDs.push(potentialMarkers[i].placeID)
                        }
                }
                //fourth quarter
                for (let i=0 ; i<Math.min(6,potentialMarkers.length);i++){
                    let placeCoordinates = potentialMarkers[i].placeLocation.coordinates
                    if (placeCoordinates.lat<fourthQuarterBounds.north&&
                        placeCoordinates.lat>fourthQuarterBounds.south&&
                        placeCoordinates.lng>fourthQuarterBounds.west&&
                        placeCoordinates.lng<fourthQuarterBounds.east&&
                        !this.state.selectedPlacesIDs.includes(potentialMarkers[i].placeID)&&
                        !usedPlacesIDs.includes(potentialMarkers[i].placeID)){
                            markersOnMap.push(potentialMarkers[i])
                            usedPlacesIDs.push(potentialMarkers[i].placeID)
                        }
                }
                //add the rest places
                for (let i=0; i<Math.min(40,potentialMarkers.length);i++){
                    if (!usedPlacesIDs.includes(potentialMarkers[i].placeID)&&
                    !this.state.selectedPlacesIDs.includes(potentialMarkers[i].placeID)){
                        markersOnMap.push(potentialMarkers[i])
                    }
                }
                /* let markers = []
                for (let i =0 ; i<markersOnMap.length ; i++){
                    markers.push({
                        placeLocation: { coordinates: markersOnMap[i].placeLocation.coordinates}
                      });
                } */
                this.setState({markersOnMap:markersOnMap})
            }
            resolve()
        });
    }

    
    tripDurationInputHandler = async e =>{
        this.setState({tripDurationInput:e.target.value})
      }

      tripDurationBlurHandler = () =>{
        if (this.state.tripDurationInput<1){
            this.setState({tripDurationInput:1})   
            return
        }
        else if(this.state.tripDurationInput>30){
            this.setState({tripDurationInput:30})   
            return
        }
        let tripDuration = parseInt(this.state.tripDurationInput, 10)
        this.tripDurationHandler(tripDuration)
      }

      tripDurationHandler = async tripDuration =>{
        await this.setState({tripDuration: tripDuration,tripDurationDefined:true})
        await this.resetTripRadiusToDefault(tripDuration)
        this.tripDaysHandler(tripDuration)
      }

      tripDaysHandler = tripDuration =>{
        let daysNames=["Any day"]
        for (let i=0;i<tripDuration;i++){
            let dayName = "Day "+(i+1)
            daysNames.push(dayName)
        }
        this.setState({daysNames:daysNames})
      }

      maxPlacesPerDayInputHandler = e =>{
        this.setState({maxPlacesPerDayInput:e.target.value})       
      }

      maxPlacesPerDayBlurHandler = () =>{
        if (this.state.maxPlacesPerDayInput<1){
            this.setState({maxPlacesPerDayInput:1})   
            return
        }
        else if (this.state.maxPlacesPerDayInput>7){
            this.setState({maxPlacesPerDayInput:7})   
            return
        }
        let maxPlacesPerDay = parseInt(this.state.maxPlacesPerDayInput, 10)
        this.setState({maxPlacesPerDay: maxPlacesPerDay,maxPlacesPerDayDefined:true})
      }

      tripIntensityHandler = id =>{
          this.setState({tripIntensity: id})
      }

      
    addExcludedCateogry = async category =>{
        let excludedCategories = this.state.excludedCategories
        excludedCategories.push(category.id)
 
        let includedCategories = this.state.includedCategories
        const index = includedCategories.findIndex(catID => {
         return catID === category.id;
       });
       if (index!==-1){
         includedCategories.splice(index,1)
       }
       await this.setState({excludedCategories:excludedCategories,includedCategories:includedCategories})
       let potentialPlaces = this.state.potentialDestinationPlaces
       if (this.state.useTripArea){
           potentialPlaces = this.state.placesInTripArea
       }
       this.updateSelectedPotentialPlaces(potentialPlaces)
     }
 
     removeExcludedCategory = async index =>{
        let excludedCategories = this.state.excludedCategories
        excludedCategories.splice(index,1)
        await this.setState({excludedCategories:excludedCategories})
        let potentialPlaces = this.state.potentialDestinationPlaces
        if (this.state.useTripArea){
            potentialPlaces = this.state.placesInTripArea
        }
        this.updateSelectedPotentialPlaces(potentialPlaces)
     }
 
     addIncludedCateogry = category =>{
         let includedCategories = this.state.includedCategories
         includedCategories.push(category.id)
  
         let excludedCategories = this.state.excludedCategories
         const index = excludedCategories.findIndex(catID => {
          return catID === category.id;
        });
        if (index!==-1){
         excludedCategories.splice(index,1)
        }
        this.setState({excludedCategories:excludedCategories,includedCategories:includedCategories})
      }
  
      removeIncludedCategory = index =>{
         let includedCategories = this.state.includedCategories
         includedCategories.splice(index,1)
         this.setState({includedCategories:includedCategories})
      }

      stopUsingTripArea = async () =>{
        await this.setState({tripCenterCoordinates:this.state.destinationPlacesCenterCoordinates,useTripArea:false})

        this.updateSelectedPotentialPlaces(this.state.potentialDestinationPlaces)
    }

    useTripArea = async () =>{
        await this.setState({useTripArea:true,tripCenterCoordinates:this.state.destinationPlacesCenterCoordinates})
        this.tripRadiusOnBlur()
    }
       
    
    mapClickedHandler = e =>{
        this.setState({clickedMarkerIndex:-1,hoverIndex:-1})
        //this.updateTripMapCenter({ lat: e.latLng.lat(), lng:e.latLng.lng() })
    }

    markerClicked= async (placeID,index,type) =>{
        let isAccommodation = false
        if (type!==undefined){
            if (type==="accommodation"){
                isAccommodation = true
                await this.setState({clickedMarkerIndex:index,hoverIndex:index,isAccommodationClicked:true})
            }
        }
        if (!isAccommodation){
            await this.setState({clickedMarkerIndex:index,hoverIndex:index,isAccommodationClicked:false})
        }
       
       /*  this.props.writeAcitivityLog({
          activityKey:"DISCOVER_PLACES_MAP_MARKER_CLICKED",
          userScreen:this.props.selectedContentWall.toUpperCase()
        }) */
    }

    updateTripMapCenter = async coord =>{
        await this.setState({tripCenterCoordinates:coord})
        this.tripRadiusOnBlur()
       // this.checkTripAreaInPlacesBounds()
    }
      
    updateTripRadiusByMap = async circleRadius =>{
        let radius = circleRadius
        if ((circleRadius/1000)<5) radius = 5
        await this.setState({tripRadius:Math.round(radius/1000)})
        this.tripRadiusOnBlur()
        //this.checkTripAreaInPlacesBounds()
    }

  /*   checkTripAreaInPlacesBounds = () =>{
        console.log("checkTripAreaInPlacesBounds")
        let destinationAreaBounds = this.state.destinationAreaBounds
        let tripRadius = this.state.tripRadius
        let tripCenterCoordinates = this.state.tripCenterCoordinates
        let tripOutOfBounds = false
        //update places list in trip area
        let potentialPlaces = this.state.destinationPotentialPlaces
        let placesInTripArea = []
        if (this.state.tripCenterSelected){
            let tripAreaNorth = tripCenterCoordinates.lat + (tripRadius/100)
            let tripAreaSouth = tripCenterCoordinates.lat - (tripRadius/100)
            let tripAreaWest = tripCenterCoordinates.lng - (tripRadius/70)
            let tripAreaEast = tripCenterCoordinates.lng + (tripRadius/70)
           
            //the 1.1 allowes ti take slightly more places to the list
            for (let i=0 ; i<potentialPlaces.length ; i++){
                if (potentialPlaces[i].placeLocation.coordinates.lat<1.1*tripAreaNorth&&
                    potentialPlaces[i].placeLocation.coordinates.lat>1.1*tripAreaSouth&&
                    potentialPlaces[i].placeLocation.coordinates.lng>1.1*tripAreaWest&&
                    potentialPlaces[i].placeLocation.coordinates.lng<1.1*tripAreaEast){
                        placesInTripArea.push(potentialPlaces[i])
                    }
            }
        }
        else{
            placesInTripArea = [...potentialPlaces]
        }
        


        let mainCategoriesIncluded = [0,0,0,0,0,0]
        let availablePlaceCategories = []
        for (let i = 0 ; i<placesInTripArea.length ; i++){
            for (let j=0 ; j<placesInTripArea[i].placeMainCategory.length ; j++){
                if (placesInTripArea[i].placeMainCategory[j]===1){
                    mainCategoriesIncluded[j]=1
                }
            }
            for (let j=0 ; j<placesInTripArea[i].placeCategory.length ; j++){
                if (!availablePlaceCategories.includes(placesInTripArea[i].placeCategory[j])){
                    availablePlaceCategories.push(placesInTripArea[i].placeCategory[j])
                }
            }
        }

        //if included categories exist that are not in the new list, remove them
        let includedCategories = this.state.includedCategories
        let remainedIncludedCategories = []
        for (let i =0 ; i<includedCategories.length ; i++){
            if (availablePlaceCategories.includes(includedCategories[i])){
                remainedIncludedCategories.push(includedCategories[i])
            }
        }

        //if main categories selected but not included, remove them and recalculate if maincategoriesareselected
        let mainCategoriesSelected = this.state.mainCategoriesSelected
        let  mainCategoriesAreSelected = false
        for (let i = 0 ; i<mainCategoriesSelected.length; i++){
            if (mainCategoriesIncluded[i]===1){
                if (mainCategoriesSelected[i]!==0){
                    mainCategoriesAreSelected = true
                }
            }
            else{
                mainCategoriesSelected[i]=0 
            }
            
        }

        let allowedPlacesCategories = []
        for (let i =0 ; i<this.props.systemCategories.length ; i++){
            if (mainCategoriesSelected[i]!==0){
                let allowedCategoriesIDs = Object.values(this.props.systemCategories[i]).map(cat=>{return cat.id})
                for (let j=0 ; j<availablePlaceCategories.length ; j++){
                    if (allowedCategoriesIDs.includes(availablePlaceCategories[j])){
                        allowedPlacesCategories.push(availablePlaceCategories[j])
                    }
                }
            }
        }
        console.log("placesInTripArea")
        this.setState({placesInTripArea:placesInTripArea,
            mainCategoriesIncluded:mainCategoriesIncluded,
            availablePlaceCategories:availablePlaceCategories,
            allowedPlacesCategories:allowedPlacesCategories,
            includedCategories:remainedIncludedCategories,
            mainCategoriesSelected:mainCategoriesSelected,
            mainCategoriesAreSelected:mainCategoriesAreSelected
        })

        if (this.state.tripCenterSelected){
            if (placesInTripArea.length<1.5*(this.state.tripDuration*this.state.maxPlacesPerDay)){
                this.setState({ tripRadiusTooSmall:true,showNullBackdrop:true})
            }

            //check if circle is outside the destination area boundaries
            if (tripCenterCoordinates.lat>destinationAreaBounds.north||
                tripCenterCoordinates.lat<destinationAreaBounds.south||
                tripCenterCoordinates.lng<destinationAreaBounds.west||
                tripCenterCoordinates.lng>destinationAreaBounds.east){
                    tripOutOfBounds=true
            }

            //as well check if at least 10% or enough places per day appear in the selected area
            if (placesInTripArea.length<(this.state.tripDuration*this.state.maxPlacesPerDay)||
            placesInTripArea.length<0.1*potentialPlaces.length){
                tripOutOfBounds=true
            }

            if (tripOutOfBounds){
                this.setState({
                    tripOutOfBounds:true, showNullBackdrop:true
                })
            }
        }
        else{
            //check if radius is not too small
            if (placesInTripArea.length<1.5*(this.state.tripDuration*this.state.maxPlacesPerDay)){
                this.setState({ tripRadiusTooSmall:true,showNullBackdrop:true})
            }
        }

       

        
    } */

     createHandler = () =>{
         if (this.state.selectedPotentialPlaces.length<this.state.tripDuration*this.state.maxPlacesPerDay*1.5){
            this.setState({tripRadiusTooSmall:true});
            if (!isMobile){
                this.setState({showNullBackdrop:true})
            }
         }
         else{
            let args = {
                destinationType: this.state.destinationType,
                destinationCountry: this.state.destinationCountry,
                tripDuration:this.state.tripDuration,
                maxPlacesPerDay: this.state.maxPlacesPerDay,
                tripIntensity: this.state.tripIntensity,
                tripRadius:this.state.tripRadius,
                dailyTripRadius: this.state.dailyTripRadius,
                mainCategoriesSelected:this.state.mainCategoriesSelected,
                placesList: this.state.selectedPotentialPlaces,
                includedCategories: this.state.includedCategories,
                excludedCategories: this.state.excludedCategories,
                useTripArea: this.state.useTripArea,
                tripCenterCoordinates:this.state.tripCenterCoordinates,
                selectedDestinations: this.state.selectedDestinations,
                destinationPlacesList: this.state.destinationPlacesList,
                potentialDestinationPlaces: this.state.potentialDestinationPlaces,
                cityRegionPlacesList: this.state.cityRegionPlacesList,
                selectedAccommodation: this.state.selectedAccommodation,
                accommodationCategories: this.state.accommodationCategories,
                selectedPlaces: this.state.selectedPlaces,
                destinationPlacesCenterCoordinates:this.state.destinationPlacesCenterCoordinates,
                destinationPlacesBoundsCoordinates:this.state.destinationPlacesBoundsCoordinates,
                destinationAreaBounds:this.state.destinationAreaBounds,
                placesInTripArea: this.state.placesInTripArea,
                noDatesSelected:this.state.noDatesSelected,
                planDates:this.state.planDates,
                oneDayTrip:this.state.oneDayTrip,
                travelWithKids:this.state.travelWithKids,
            }
            if (this.state.tripDuration===1){
                args.selectedAccommodation = null
            }

            let logUserParams = {
                selectedDestinations: this.state.selectedDestinations.map(tag=>{return tag.treeID}),
                destinationType: this.state.destinationType,
                destinationCountry: this.state.destinationCountry,
                tripDuration:this.state.tripDuration,
                maxPlacesPerDay: this.state.maxPlacesPerDay,
                tripRadius:this.state.tripRadius,
                dailyTripRadius: this.state.dailyTripRadius,
                mainCategoriesSelected:this.state.mainCategoriesSelected,
                includedCategories: this.state.includedCategories,
                excludedCategories: this.state.excludedCategories,
                useTripArea: this.state.useTripArea,
                accommodationIsSelected:this.state.selectedAccommodation!==null,
                hasSelectedPlaces: this.state.selectedPlaces.length>0,

                
            }
            this.props.create(args)

            this.props.logUserParams(logUserParams)
            this.props.discard()
         }
        
     }


     resetTripRadiusToDefault = async tripDuration =>{
        return new Promise(async (resolve, reject) => {
            let defaultTripRadius = 0
            let defaultTripDayRadius =0
            if (this.state.destinationType==="country"){
                if (tripDuration===1){
                    defaultTripRadius = 10
                }
                else if (tripDuration===2||tripDuration===3){
                    defaultTripRadius = 50
                    defaultTripDayRadius = 15
                }
                else{//trip duration 4+ days
                    defaultTripRadius = 100
                    defaultTripDayRadius = 15
                }
            }
            else if(this.state.destinationType==="region"){
                if (tripDuration===1){
                    defaultTripRadius = 10
                    defaultTripDayRadius = 10
                }
                else if (tripDuration===2||tripDuration===3){
                    defaultTripRadius = 30
                    defaultTripDayRadius = 15
                }
                else{//trip duration 4+ days
                    defaultTripRadius = 50
                    defaultTripDayRadius = 15
                }
            }

            else if (this.state.destinationType==="city"){
                if (tripDuration===1){
                    defaultTripRadius = 5
                    defaultTripDayRadius = 5
                }
                else if (tripDuration===2||tripDuration===3){
                    defaultTripRadius = 10
                    defaultTripDayRadius = 5
                }
                else{//trip duration 4+ days
                    defaultTripRadius = 15
                    defaultTripDayRadius = 5
                }
            }
            if (!this.state.tripRadiusDefined){
                await this.setState({tripRadius: defaultTripRadius})
            }
            if (!this.state.dailyTripRadiusDefined){
                await this.setState({dailyTripRadius: defaultTripDayRadius})
            }
            resolve()
        });
     }


     renderTopSection = () =>{
        let closeIconClass = "CloseIcon"
        let showResultsClass = "ShowResults"
        if (this.props.RTL){
            closeIconClass = "CloseIconRTL"
            showResultsClass = "ShowResultsRTL"
        }
        return(
            <div className={classes.TopSection} >
            <div className={classes.Title}>
            {this.props.systemText.tripGenerator.prefernecesForm.title[this.props.userLanguage]}</div>
            <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIconWhite.png?alt=media&token=3442a65b-bdd3-4f67-aa91-f1be2b40050a"} 
               alt="" className={classes[closeIconClass]} onClick={this.props.discard}/>
            
        </div>
        )
     }

    renderBody = () =>{
        if (this.state.selectedDestinations.length===0){
            return(
                <div className={classes.Body}>
                    {this.renderInitialScreen()}                   
                </div>
            )  
        }
        else{
            return(
                <div className={classes.Body}>
                    {this.renderMobileSelection()}
                    {this.renderPreferncesForm()}
                    {this.renderMap()}
                </div>
            )
           
    
        }
      
    }


    renderInitialScreen = () =>{
        let initialScreenMessageClass = "InitialScreenMessage"
        if (!isMobile&&this.props.RTL){
            initialScreenMessageClass = "InitialScreenMessageRTL"
        }
        return(
            <div className={classes.InitialScreen}>
                <div className={classes[initialScreenMessageClass]}>
                    <div className={classes.InitialScreenHeader}>
                        {this.props.systemText.tripGenerator.initialScreen.header[this.props.userLanguage]}
                    </div>
                    <div className={classes.MessageText}>
                        {this.props.systemText.tripGenerator.initialScreen.messageFirstPart[this.props.userLanguage]}
                    </div>
                </div>
                <div className={classes.DestinationSection}>
                    <div className={classes.InitialScreenHeader}>
                        {this.props.systemText.tripGenerator.initialScreen.searchMessage[this.props.userLanguage]}
                    </div>
                    {this.renderSelectDestination()}
                    <div className={classes.DisclaimarSection}>
                    <div className={classes.DisclaimarText}>
                        {this.props.systemText.tripGenerator.initialScreen.disclaimarMessage[this.props.userLanguage]}
                    </div>
                    <div className={classes.DisclaimarText}>
                        {this.props.systemText.tripGenerator.initialScreen.covidDisclaimar[this.props.userLanguage]}
                    </div>
                </div>
                </div>
               
            </div>
        )
    }

    updateSelectedCountry = country =>{
        this.setState({countrySelected:true,selectedCountry:country,destinationTags:this.props.destinationsSearchTags.filter(tag=>tag.treeID.split('_')[0]===country.treeID)})

    }

    resetCountry = () =>{
        this.setState({countrySelected:false,selectedCountry:null,destinationTags:[]})
    }

    renderSelectDestination = () =>{
        if (this.state.countrySelected){
            return(
                <div className={classes.DestinationSelection}>
                    <div className={classes.SearchSection}>
                        <div className={classes.Filters}>
                            <Tag 
                            removable={true}
                            style={"LocationFilter"}
                            remove={this.resetCountry}
                            text={this.state.selectedCountry[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        </div>
                        <SimpleSearchBar
                            searchTags = {this.state.destinationTags}
                            placeholderMessage = {this.props.systemText.tripGenerator.prefernecesForm.searchDestinationPlaceholder[this.props.userLanguage]}
                            detectLanguage = {this.props.detectInputLanguage}
                            inputLanguage = {this.props.inputLanguage}
                            RTL = {this.props.RTL}
                            updateFilterTags = {this.updateSearchDestination}
                            
                            systemText = {this.props.systemText}
                            userLanguage = {this.props.userLanguage}
                            locationsTranslator = {this.props.locationsTranslator}
                        />
                    </div>
                    <div className={classes.Filters}>
                        {this.props.recommendedDestinations[this.state.selectedCountry.treeID].map((tag,index)=>(
                            <Tag 
                            key={index}
                            clickable={true}
                            style={"LocationFilter"}
                            clicked={() => {this.updateSearchDestination(tag)}}
                            text={tag[this.props.userLanguage]}
                            RTL={this.props.RTL}/>
                        ))}
                    </div>
                </div>
            )
        }
        else{
            let countriesList = [];
            for (let i=0;i<this.props.countriesToExplore.length ; i++){
                let country = JSON.parse(JSON.stringify(this.props.locationsTree[this.props.countriesToExplore[i]]))
                 delete country.regions;
                delete country.cities; 
                countriesList.push(country)
            }
            return(
                <div className={classes.DestinationSelection}>
                    <div className={classes.SearchSection}>
                        <OptionSelection
                                currentSelection={""}
                                optionsList={countriesList}
                                updateSelectedOption={this.updateSelectedCountry}
                                placeholder={this.props.systemText.extraModals.setLocation.setLocationTree.selectCountry[this.props.userLanguage]}
                                bold={false}
                                valueRequired={true}
                                onlyFromList={true}
                                userLanguage={this.props.userLanguage}
                                RTL = {this.props.RTL}
                                inputLanguage = {this.props.inputLanguage}
                                detectInputLanguage = {this.props.detectInputLanguage}
                        /> 
                    </div>
                </div>
            )
        }
    }

    renderDestination = () =>{
        //start with only one possible destination
        let tag = this.state.selectedDestinations[0]
        let selectedDestinationText =tag[this.props.userLanguage]
        if (tag.treeID.split('_')[1]!==undefined){
            selectedDestinationText = selectedDestinationText +", "+this.props.locationsTranslator[tag.treeID.split('_')[0]][this.props.userLanguage];
        }
        return(
            <div className={classes.InfoSection}>
                <div className={classes.SectionHeader}>
                        {this.props.systemText.tripGenerator.prefernecesForm.tripDestination[this.props.userLanguage]}
                    </div>
                <div className={classes.SelectedDestination}>
                   <div className={classes.DestinationValue}>
                       {selectedDestinationText}
                       <img src={resetIcon} alt="" className={classes.ResetIcon}
                        onClick={this.removeDestination}
                       />
                       </div>
                   {this.renderUseMapArea()}
                </div>
               
              {/*   <div className={classes.SelectDifferentDestination}>
                <TextButton
                        style="DiscardButton"
                        text={this.props.systemText.tripGenerator.prefernecesForm.selectDifferentDestination[this.props.userLanguage]}
                        clicked={this.removeDestination}
                    />
                </div> */}
            </div>
        )
       
    }

    renderTripDuration = () =>{
        if (this.state.selectedDestinations.length>0 && this.state.noDatesSelected){
            let inputClass = "NumberInput"
            if (!this.state.tripDurationDefined){
                inputClass = "NumberInputFade"
            }
            return(
                <div className={classes.InfoRow}>
                    <div className={classes.SectionSubHeader}>
                        {this.props.systemText.tripGenerator.prefernecesForm.tripDuration[this.props.userLanguage]}
                    </div>
                    <input type="number" 
                     className={classes[inputClass]}
                     value={this.state.tripDurationInput} onChange={this.tripDurationInputHandler}
                     onBlur={this.tripDurationBlurHandler}
                     />
                </div>
            )
        }
    }

    renderMaxPlacesPerDay = () =>{
        if (this.state.selectedDestinations.length>0){
            let inputClass = "NumberInput"
            if (!this.state.maxPlacesPerDayDefined){
                inputClass = "NumberInputFade"
            }

            return(
                <div className={classes.InfoColumnRow}>
                    <div className={classes.SectionSubHeader}>
                        {this.props.systemText.tripGenerator.prefernecesForm.tripIntensity.title[this.props.userLanguage]}
                    </div>
                   {/*  <input type="number" 
                     className={classes[inputClass]}
                     value={this.state.maxPlacesPerDayInput} onChange={this.maxPlacesPerDayInputHandler}
                     onBlur = {this.maxPlacesPerDayBlurHandler}/> */}
                     <div className={classes.TripIntensityOptions}>
                         {this.state.tripIntensity==="easy"?(
                            <div className={classes.IntensityOptionSelected}
                              onClick={()=>{this.tripIntensityHandler("easy")}}>
                                 {this.props.systemText.tripGenerator.prefernecesForm.tripIntensity.easy[this.props.userLanguage]}
                              </div>
                         ):(
                            <div className={classes.IntensityOption}
                            onClick={()=>{this.tripIntensityHandler("easy")}}>
                               {this.props.systemText.tripGenerator.prefernecesForm.tripIntensity.easy[this.props.userLanguage]}
                            </div>
                         )}
                        {this.state.tripIntensity==="medium"?(
                             <div className={classes.IntensityOptionSelected}
                             onClick={()=>{this.tripIntensityHandler("medium")}}>
                                {this.props.systemText.tripGenerator.prefernecesForm.tripIntensity.medium[this.props.userLanguage]}
                             </div>
                        ):(
                            <div className={classes.IntensityOption}
                            onClick={()=>{this.tripIntensityHandler("medium")}}>
                               {this.props.systemText.tripGenerator.prefernecesForm.tripIntensity.medium[this.props.userLanguage]}
                            </div>
                        )}
                         {this.state.tripIntensity==="intensive"?(
                              <div className={classes.IntensityOptionSelected}
                              onClick={()=>{this.tripIntensityHandler("intensive")}}>
                                 {this.props.systemText.tripGenerator.prefernecesForm.tripIntensity.intensive[this.props.userLanguage]}
                              </div>
                         ):(
                            <div className={classes.IntensityOption}
                            onClick={()=>{this.tripIntensityHandler("intensive")}}>
                               {this.props.systemText.tripGenerator.prefernecesForm.tripIntensity.intensive[this.props.userLanguage]}
                            </div>
                         )}
                        
                     </div>
                </div>
            )
        }
    }

    renderTripRadius = () =>{
        if (this.state.selectedDestinations.length>0){
            let inputClassTrip = "NumberInput"
            if (!this.state.tripRadiusDefined){
                inputClassTrip = "NumberInputFade"
            }
            let inputClassDay = "NumberInput"
            if (!this.state.dailyTripRadiusDefined){
                inputClassDay = "NumberInputFade"
            }
            return(
                <div className={classes.SubInfoSection}>
                   <div className={classes.InfoRow}>
                        <div className={classes.SectionSubHeader}>
                            {this.props.systemText.tripGenerator.prefernecesForm.tripRadius[this.props.userLanguage]}
                        </div>
                        <input type="number" 
                        className={classes[inputClassTrip]}
                        value={this.state.tripRadius} onChange={this.tripRadiusInputHandler}
                        onBlur ={this.tripRadiusOnBlur}/>
                         <div className={classes.SectionSubHeader}>
                                {this.props.systemText.tripGenerator.prefernecesForm.distancePostfix[this.props.userLanguage]}
                            </div>
                     </div>
                    {this.state.tripDuration>1?(
                         <div className={classes.InfoRow}>
                            <div className={classes.SectionSubHeader}>
                                {this.props.systemText.tripGenerator.prefernecesForm.dailyTripRadius[this.props.userLanguage]}
                            </div>
                            <input type="number" 
                            className={classes[inputClassDay]}
                            value={this.state.dailyTripRadius} onChange={this.dailyTripRadiusInputHandler}
                            onBlur ={this.dailyTripRadiusOnBlur}/>
                            <div className={classes.SectionSubHeader}>
                                {this.props.systemText.tripGenerator.prefernecesForm.distancePostfix[this.props.userLanguage]}
                            </div>

                        </div>
                    ):null}
                </div>
            )
        }
    }



    renderMainCategories = () =>{
        if (this.state.selectedDestinations.length>0){
            return(
                <div className={classes.InfoSection}>
                    <div className={classes.SectionHeader}>
                        {this.props.systemText.tripGenerator.prefernecesForm.mainCategories[this.props.userLanguage]}
                    </div>
                    {this.state.mainCategoriesAreSelected?(
                        null
                    ):(
                    <div className={classes.Message}>
                        {this.props.systemText.tripGenerator.prefernecesForm.mainCateogriesMessage[this.props.userLanguage]}
                    </div>
                    )}
                    {this.state.mainCategoriesIncluded.map((category,index)=>{
                        let disabled = true
                        if (category===1){
                            disabled=false
                        }
                        if (index!==3&&index!==5){
                            return(
                                <div className={classes.Category}  key = {index}>
                                    <MainCategory
                                    index = {index}
                                    selected={this.state.mainCategoriesSelected[index]>0}
                                    disabled={disabled}
                                    clicked={()=>{this.mainCategorySelectionHandler(index)}}
                                    systemText = {this.props.systemText}
                                    userLanguage = {this.props.userLanguage}
                                    RTL = {this.props.RTL}
                                    horizontal = {true}
                                />
                                {this.renderCategoryFocus(index)}
                            </div>
                            )
                        }
                    })}
                    
                </div>
            )
        }
    }

    mainCategoryFocusHandler = (index,value)=>{
        let mainCategoriesSelected = this.state.mainCategoriesSelected
        mainCategoriesSelected[index]=value
        this.setState({mainCategoriesSelected:mainCategoriesSelected})
    }

    renderCategoryFocus = index =>{
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
        if (this.state.mainCategoriesSelected[index]===2){
           return(
               <div className={classes.CheckMarkSection}>
                    <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.mainCategoryFocusHandler(index,1)}}/>
                    <div className={classes.MainCategoryFocus}> {this.props.systemText.tripGenerator.prefernecesForm.focusMainCateogry[this.props.userLanguage]}</div>
               </div>
           )
        }
        else if (this.state.mainCategoriesSelected[index]===1){
            return(
                <div className={classes.CheckMarkSection}>
                    <div className={classes[uncheckBoxClass]} onClick={()=>{this.mainCategoryFocusHandler(index,2)}}/>
                    <div className={classes.MainCategoryFocus}> {this.props.systemText.tripGenerator.prefernecesForm.focusMainCateogry[this.props.userLanguage]}</div>
                </div>
            )
            
        }
    }

    renderDisplayAccommodationsOnMap = index =>{
        if (this.state.accommodationCategories.length>0){
            let checkIconClass = "CheckIcon"
            let uncheckBoxClass = "UncheckBox"
            if (this.props.RTL){
              checkIconClass = "CheckIconRTL"
              uncheckBoxClass = "UncheckBoxRTL"
            }
            if (this.state.showAccommodationsOnMap){
               return(
                   <div className={classes.CheckMarkSection}>
                        <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.setState({showAccommodationsOnMap:false})}}/>
                        <div className={classes.NoDatesButtonSelected}> {this.props.systemText.tripGenerator.prefernecesForm.showAccommodationsOnMap[this.props.userLanguage]}</div>
                   </div>
               )
            }
            else {
                return(
                    <div className={classes.CheckMarkSection}>
                        <div className={classes[uncheckBoxClass]} onClick={()=>{this.showAccommodationsOnMapHandler()}}/>
                        <div className={classes.NoDatesButtonSelected}> {this.props.systemText.tripGenerator.prefernecesForm.showAccommodationsOnMap[this.props.userLanguage]}</div>
                    </div>
                )
                
            }
        }
    }


    renderIncludeExcludeCategories = () =>{
        if (this.state.selectedDestinations.length>0 && this.state.mainCategoriesAreSelected){
            let availableIncludedCategories = this.state.allowedPlacesCategories.filter(catID=>!this.state.includedCategories.includes(catID)).map(catID=>{return this.props.categoriesTranslator[catID]}).filter(cat=>cat!==undefined)
            let availableExcludedCategories = this.state.allowedPlacesCategories.filter(catID=>!this.state.excludedCategories.includes(catID)).map(catID=>{return this.props.categoriesTranslator[catID]}).filter(cat=>cat!==undefined)
            return(
                <div className={classes.SubCategoriesSection}>
                    <div className={classes.SectionHeader}>
                    {this.props.systemText.tripGenerator.prefernecesForm.subCategories[this.props.userLanguage]}
                    </div>
                    <div className={classes.SectionSubHeader}>
                        {this.props.systemText.tripGenerator.prefernecesForm.includedCategories[this.props.userLanguage]}
                        </div>
                        <div className={classes.CategorySelection}>
                            <OptionSelection
                            currentSelection={""}
                            optionsList={availableIncludedCategories}
                            updateSelectedOption={this.addIncludedCateogry}
                            required={false}
                            onlyFromList = {true}
                            showCurrentSelection = {false}
                            systemText={this.props.systemText}
                            userLanguage={this.props.userLanguage}
                            RTL = {this.props.RTL}
                            inputLanguage = {this.props.inputLanguage}
                            detectInputLanguage = {this.props.detectInputLanguage}
                            />
                        </div>
                        <div className={classes.CategoriesTags}>
                            {this.state.includedCategories.map((category,index)=>{
                            if (this.props.categoriesTranslator[category]!==undefined){
                                return(
                                <Tag 
                                key={index}
                                removable={true}
                                style={"CategoryFilter"}
                                clicked={()=>{this.removeIncludedCategory(index)}}
                                text={this.props.categoriesTranslator[category][this.props.userLanguage]}
                                RTL={this.props.RTL}/>
                                
                                )
                            }
                            })}
                        </div>
                        <div className={classes.SectionSubHeader}>
                        {this.props.systemText.tripGenerator.prefernecesForm.excludedCategories[this.props.userLanguage]}
                        </div>
                        <div className={classes.CategorySelection}>
                            <OptionSelection
                            currentSelection={""}
                            optionsList={availableExcludedCategories}
                            updateSelectedOption={this.addExcludedCateogry}
                            required={false}
                            onlyFromList = {true}
                            showCurrentSelection = {false}
                            systemText={this.props.systemText}
                            userLanguage={this.props.userLanguage}
                            RTL = {this.props.RTL}
                            inputLanguage = {this.props.inputLanguage}
                            detectInputLanguage = {this.props.detectInputLanguage}
                            />
                        </div>
                        <div className={classes.CategoriesTags}>
                            {this.state.excludedCategories.map((category,index)=>{
                            if (this.props.categoriesTranslator[category]!==undefined){
                                return(
                                <Tag 
                                key={index}
                                removable={true}
                                style={"DislikeCategory"}
                                clicked={()=>{this.removeExcludedCategory(index)}}
                                text={this.props.categoriesTranslator[category][this.props.userLanguage]}
                                RTL={this.props.RTL}/>
                                
                                )
                            }
                            })}
                        </div>
                </div>
            )
        }
    }

  

    renderRemoveAccommodation = () =>{
        let mapIconClass = "MapIcon"
        if (this.props.RTL){
            mapIconClass = "MapIconRTL"
        }
        return(
            <div className={classes.LocationContent}>
                <div className={classes.MapButton} onClick={()=>{this.setState({selectedAccommodation:null})}}>
                        <img src={resetIcon} className={classes[mapIconClass]} alt="" />
                        <div className={classes.ImageText}>
                        {this.props.systemText.tripGenerator.prefernecesForm.removeAccommodation[this.props.userLanguage]}
                            </div>
                    </div>
            </div>
        )
    }

    renderSetAccommodation = () =>{
        let mapIconClass = "MapIcon"
        if (this.props.RTL){
            mapIconClass = "MapIconRTL"
        }
        let icon = accomodationIconGrey
        let text = this.props.systemText.tripGenerator.prefernecesForm.setAccommodation[this.props.userLanguage]
        if (this.state.selectedAccommodation!==null){
            text = this.props.systemText.tripGenerator.prefernecesForm.updateAccommodation[this.props.userLanguage]
            icon = accomodationIcon
        }
        return(
            <div className={classes.LocationContent}>
                 <div className={classes.MapButton}onClick={()=>{this.props.createQuickPlaceHandler([0,0,0,0,0,1])}}>
                    <img src={icon} className={classes[mapIconClass]} alt="" />
                    <div className={classes.PlaceNotExist} >
                            {text}
                    </div>
                </div>
            </div>
        )
    }

    selectAccommodationPlace = place =>{
        if (place.placeID!==undefined){
            this.setState({selectedAccommodation:place})
        }
      

    }

    renderAccommodationSection = () =>{
        if (this.state.selectedDestinations.length>0&&(this.state.tripDuration>1||!this.state.oneDayTrip)){
            if (this.state.selectedAccommodation!==null){
                let imagePreview ={url:""}
                if (Array.isArray(this.state.selectedAccommodation.imagePreview)){
                    imagePreview = this.state.selectedAccommodation.imagePreview[0]
                }
                else{
                    imagePreview = this.state.selectedAccommodation.imagePreview
                }
                return (
                <div className={classes.InfoSection}>
                    <div className={classes.SectionHeader}>
                        {this.props.systemText.tripGenerator.prefernecesForm.accommodationHeader[this.props.userLanguage]}
                    </div>
                     <div>
                     {this.state.selectedAccommodation!==null?(
                        <div className={classes.ResetPlace} onClick={()=>{this.setState({selectedAccommodation:null})}}>
                            {this.props.systemText.experience.createExperience.resetPlace[this.props.userLanguage]}
                        </div>
                    ):null}
                        <PlanPlaceItem
                        placeID={this.state.selectedAccommodation.placeID}
                        placeName={this.state.selectedAccommodation.placeName}
                        placeCategory={this.state.selectedAccommodation.placeCategory}
                        imagePreview={imagePreview}
                        placeLocation = {this.state.selectedAccommodation.placeLocation}
                        placeStatus = {this.state.selectedAccommodation.placeStatus}
                        addToPlan= {true}
    
                        categoriesTranslator = {this.props.categoriesTranslator}
                        locationsTranslator = {this.props.locationsTranslator}
                        RTL = {this.props.RTL}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                    />
                   
                    </div>
                   
                </div>)
            }
            else{
                return (
                    <div className={classes.InfoSection}>
                         <div className={classes.SectionHeader}>
                             {this.props.systemText.tripGenerator.prefernecesForm.accommodationHeader[this.props.userLanguage]}
                        </div>
                        {this.renderDisplayAccommodationsOnMap()}
                        {this.renderAccommodationCategories()}
                        {this.state.accommodationCategories.length>0?(
                            <div className={classes.AccommodationSelection}>
                            <PlaceSelection
                                
                                placeholderMessage= {this.props.systemText.tripGenerator.prefernecesForm.accommodationPlaceholder[this.props.userLanguage]}
                                places={this.state.potentialAccommodations}
                                placeName={""}
                                slimPlaces = {true}
                                userLanguage={this.props.userLanguage}
                                required = {false}

                                detectInputLanguage = {this.props.detectInputLanguage}
                                inputLanguage = {this.props.inputLanguage}
                                RTL = {this.props.RTL}
                                systemText={this.props.systemText}
                                updatePlace={this.selectAccommodationPlace}
                            />
                            </div>
                        ):null}
                         {this.state.accommodationCategories.length>0?(
                         <div className={classes.PlaceNotExist} onClick={()=>{this.props.createQuickPlaceHandler([0,0,0,0,0,1],this.state.accommodationCategories);
                         this.setState({showNullBackdrop:true})}}>
                             {this.props.systemText.experience.createExperience.placeNotExistMessage[this.props.userLanguage]}
                         </div>):null}
                    </div>)  
            }
        }
    }

    removeAccommodationCategory = async index =>{
        let accommodationCategories = this.state.accommodationCategories;
        accommodationCategories.splice(index,1)
        await this.setState({ accommodationCategories: accommodationCategories});
        let potentialPlaces = this.state.potentialDestinationPlaces
        if (this.state.useTripArea){
            potentialPlaces = this.state.placesInTripArea
        }
        if (this.state.showAccommodationsOnMap){
            this.updatePotentialAccommodations(potentialPlaces)
        }
    }

    updateAccommodationCategory = async category =>{
        let accommodationCategories = this.state.accommodationCategories;
        if (!accommodationCategories.map(cat=>cat.id).includes(category.id)){
            accommodationCategories.push(category)
        }
        await this.setState({ accommodationCategories: accommodationCategories});
        let potentialPlaces = this.state.potentialDestinationPlaces
        if (this.state.useTripArea){
            potentialPlaces = this.state.placesInTripArea
        }
        if (this.state.showAccommodationsOnMap){
            this.updatePotentialAccommodations(potentialPlaces)
        }
    }


renderAccommodationCategories = () =>{
    return(
        <div className={classes.AccommodationCategories}>
            <div className={classes.SelectedCategories}>
                {this.state.accommodationCategories.map((tag, index) => (
                    <Tag
                    key={index}
                    text={tag[this.props.userLanguage]}
                    removable={true}
                    style={"CategoryFilter"}
                    remove={() => {this.removeAccommodationCategory(index)}}
                    RTL = {this.props.RTL}
                  />
                ))}
            </div>                                                                                    
            <div className={classes.CategoriesSelectionList}>
             <OptionSelection
                multiple={true}
                currentSelection={""}
                optionsList={ Object.values(this.props.systemCategories[5])}
                updateSelectedOption={this.updateAccommodationCategory}
                placeholder={this.props.systemText.plan.createPlan.accommodation.categoryPlaceholder[this.props.userLanguage]}
                valueRequired={false}
                onlyFromList={true}
                userLanguage={this.props.userLanguage}
                errorMesage={""}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
                inputLanguage = {this.props.inputLanguage}
                detectInputLanguage = {this.props.detectInputLanguage}
            /> 
            </div>
        </div>
    )  
}


 renderUseMapArea = () =>{
    if (this.state.selectedDestinations.length>0){
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
        if (this.state.useTripArea){
           return(
               <div className={[classes.CheckMarkSection,classes.ShowOnMap].join(" ")}>
                    <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={this.stopUsingTripArea}/>
                    <div className={classes.NoDatesButtonSelected}> {this.props.systemText.tripGenerator.prefernecesForm.filterAreaOnMap[this.props.userLanguage]}</div>
               </div>
           )
        }
        else {
            return(
                <div className={[classes.CheckMarkSection,classes.ShowOnMap].join(" ")}>
                    <div className={classes[uncheckBoxClass]} onClick={this.useTripArea}/>
                    <div className={classes.NoDatesButtonSelected}> {this.props.systemText.tripGenerator.prefernecesForm.filterAreaOnMap[this.props.userLanguage]}</div>
                </div>
            )
            
        }
    }

 }
    addSelectedPlace = (place,index)=>{
        if (this.state.isAccommodationClicked){
            this.selectAccommodationPlace(place)
        }
        else{
            this.setState({daysIndices:[], showDaysOptions:true, showNullBackdrop:true, newPlace:true,newPlaceItem:place,singleDayMove:true})
        }
       
    }

    selectedDaysHandler = (selectedDays) =>{
        this.setState({showDaysOptions:false, showNullBackdrop:false,showAdditionalInfo:true})
        setTimeout(() => {
            this.state.extraInfoRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                delay:300,
              });
           }, 100);
        const index = selectedDays.findIndex(day => {
            return day === true;
        });
        let place = this.state.newPlaceItem
        let selectedPlaces = this.state.selectedPlaces
        let selectedPlacesIDs = this.state.selectedPlacesIDs
        let selectedPlace = {...place}
        selectedPlace.dayIndex = index
        if (this.state.moveSelectedPlace){
            selectedPlaces[this.state.movePlaceIndex]=selectedPlace
        }
        else{
            selectedPlaces.push(selectedPlace)
            selectedPlacesIDs.push(selectedPlace.placeID)
        }
       
        this.setState({
            selectedPlaces:selectedPlaces,
            selectedPlacesIDs: selectedPlacesIDs,
            moveSelectedPlace:false,
            movePlaceIndex:-1,
            clickedMarkerIndex:-1,
            hoverIndex:-1
        })
    }

    removeSelectedPlace = index =>{
        let selectedPlaces = this.state.selectedPlaces
        let selectedPlacesIDs = this.state.selectedPlacesIDs
        selectedPlaces.splice(index,1)
        selectedPlacesIDs.splice(index,1)
        this.setState({
            selectedPlaces:selectedPlaces,
            selectedPlacesIDs: selectedPlacesIDs
        })
    }

    updateSelectedPlaceIndex = (index,dayIndex) =>{
        let selectedPlaces = this.state.selectedPlaces
        let selectedPlace = selectedPlaces[index]
        this.setState({daysIndices:[selectedPlace.dayIndex], showDaysOptions:true, showNullBackdrop:true, newPlace:true,newPlaceItem:selectedPlace,singleDayMove:true,moveSelectedPlace:true,movePlaceIndex:index})

    }

    renderIncludedPlaces = () =>{
       /*  if (this.state.mainCategoriesAreSelected){ */
            let searchPlacesList = this.state.allLoadedPlaces.filter(place=>!this.state.selectedPlacesIDs.includes(place.placeID))
            return(
            <div className={classes.InfoSection}>
                <div className={classes.SectionHeader}>
                {this.props.systemText.tripGenerator.prefernecesForm.includedPlaces[this.props.userLanguage]}
                </div>
                <div className={classes.AccommodationSelection}>
                    <PlaceSelection
                        
                        placeholderMessage= {this.props.systemText.tripGenerator.prefernecesForm.searchPlacesPlaceholder[this.props.userLanguage]}
                        places={searchPlacesList}
                        placeName={""}
                        slimPlaces = {true}
                        userLanguage={this.props.userLanguage}
                        required = {false}

                        detectInputLanguage = {this.props.detectInputLanguage}
                        inputLanguage = {this.props.inputLanguage}
                        RTL = {this.props.RTL}
                        systemText={this.props.systemText}
                        updatePlace={(place)=>{this.addSelectedPlace(place,0)}}
                    />
                </div>
                <div className={classes.CategoriesTags}>
                    {this.state.selectedPlaces.map((place,index)=>(
                         <PlanDayItem
                         isEditor={true}
                         tripGenerator={true}
                         index={index}
                         object={{type:"place",item:place}}
                         dayNames={this.state.daysNames}
                         dayName={place.dayIndex}
                         viewHandler={()=>{this.props.viewHandler(index);}}
                        
                         removeFromPlan={(index)=>{this.removeSelectedPlace(index)}}
                         moveDayHandler={(dayIndex)=>{this.updateSelectedPlaceIndex(index,dayIndex)}}
   
                         locationsTranslator = {this.props.locationsTranslator}
                         categoriesTranslator = {this.props.categoriesTranslator}

                         writeAcitivityLog = {this.props.writeAcitivityLog}
                         
                         RTL = {this.props.RTL}
                         user={this.props.user}
                         userLanguage={this.props.userLanguage}
                         systemText={this.props.systemText}
                        />  
   
                    ))}
                </div>
            </div>
            )
      /*   } */
        
    }

   renderMobileSelection = () =>{
       if (isMobile &&this.state.selectedDestinations.length>0){
        return(
            <div className={classes.ViewMode}>
              <Tab
                tabType={this.state.viewSelection[0]}
                text= {this.props.systemText.tripGenerator.prefernecesForm.preferencesView[this.props.userLanguage]}
                clicked={() => {
                  this.setState({
                    viewMode: "preferneces",
                    viewSelection: ["SelectedMobileWide", "RegularMobileWide"]
                  })}}
              />
              <div className={classes.MobileSeperator}/>
               <Tab
                tabType={this.state.viewSelection[1]}
                text= {this.props.systemText.tripGenerator.prefernecesForm.mapView[this.props.userLanguage]}
                clicked={() => {
                  this.setState({
                    viewMode: "map",
                    viewSelection: ["RegularMobileWide", "SelectedMobileWide"]
                  })}}
              />
             </div>)
       }
    
   }

   mapBoundsHandler = async (mapBounds,zoom,mapCenter) =>{
        await this.setState({mapBounds:mapBounds})
        let potentialPlaces = this.state.potentialDestinationPlaces
        if (!this.state.useTripArea){
            potentialPlaces = this.state.placesInTripArea
            this.updateSelectedPotentialPlaces(this.state.potentialDestinationPlaces)
        }
       
   }


    renderMap = () =>{
        if (this.state.destinationPlacesCenterCoordinates!==undefined){
            let mapClass = "MapModal"
            if (this.state.viewMode==="preferneces" &&isMobile){
                mapClass = "HideMap"
            }
            let closeIconClass = "CloseIcon"
            let mapRadiusText = "MapRadiusText"
            if (this.props.RTL){
                closeIconClass = "CloseIconRTL"
                mapRadiusText = "MapRadiusTextRTL"
            }

            let markers = this.state.markersOnMap;
           
            let tripAccommodations = []
            if (this.state.selectedAccommodation!==null){
                tripAccommodations.unshift({
                    placeLocation: { coordinates: this.state.selectedAccommodation.placeLocation.coordinates}
                  });
            }
            else  if(this.state.tripDuration>1&&this.state.showAccommodationsOnMap) {
                for (let i =0 ; i<this.state.potentialAccommodations.length ; i++){
                    tripAccommodations.push({
                        placeLocation: { coordinates: this.state.potentialAccommodations[i].placeLocation.coordinates}
                      });
                }
                
            }
            let selectedPlaces = [];
            for (let i =0 ; i<this.state.selectedPlaces.length ; i++){
                selectedPlaces.push({
                    placeLocation: { coordinates: this.state.selectedPlaces[i].placeLocation.coordinates}
                  });
            }
            return(
            <div className={classes[mapClass]}  onMouseUp={()=>{this.refs.map.handleCenterChanged()}} onTouchEnd={()=>{this.refs.map.handleCenterChanged()}}>
                {this.state.useTripArea?(
                    <div className={classes.MapRadiusSection}>
                    <div className={classes.MapRadius}>
                        <div className={classes[mapRadiusText]}>
                        {this.props.systemText.tripGenerator.prefernecesForm.mapTripRadius[this.props.userLanguage]}
                        </div>
                        <input type="number" 
                        className={classes.NumberInput}
                        value={this.state.tripRadius} onChange={this.tripRadiusInputHandler} onBlur={this.tripRadiusOnBlur}/>
                    </div>
                </div>
                ):null}
                
                
                <GoogleMap
                 ref="map"
                    focusCoordinates={this.state.destinationPlacesCenterCoordinates}
                    markers={markers}
                    boundsPlaces={this.state.destinationPlacesBoundsCoordinates}
                    height={"100%"}
                    mapClicked={this.mapClickedHandler}
                    markerClicked={this.markerClicked}
                    accommodationIsClickable={true}
                    creationBoard={false}
                    isClickable={true}
                    board="TripAlgorithm"
                    mapBoundsHandler={this.mapBoundsHandler}
                    tripAccommodations = {tripAccommodations}
                    selectedPlaces={selectedPlaces}
                    hoverIndex={this.state.hoverIndex}
                    useMapClick={true}
                    showCircle = {this.state.useTripArea}
                    circleRadius = {this.state.tripRadius*1000}
                    updateTripRadius = {this.updateTripRadiusByMap}
                    updateTripCenter = {this.updateTripMapCenter}
                />
                {this.renderClickedPlace()}
               
            </div>)
        }
    }

    renderClickedPlace = () =>{
        if (this.state.clickedMarkerIndex!==-1){
            let place
            if (this.state.isAccommodationClicked){
                place = this.state.potentialAccommodations[this.state.clickedMarkerIndex]
            }
            else{
                place = this.state.markersOnMap[this.state.clickedMarkerIndex]
            }
            if (place!==undefined){
                return(
                    <div className={classes.ClickedPlace}>
                            <PlanPlaceItem
                            placeName={place.placeName}
                            placeID={place.placeID}
                            placeCategory={place.placeCategory}
                            placeLocation = {place.placeLocation}
                            imagePreview={place.imagePreview}
                            placeStatus = {place.placeStatus}
                            board={"TripsGenerator"}
                            viewHandler = {()=>{this.props.viewPlaceHandler(place)}}
                            planPlacesIDs={this.state.selectedPlacesIDs}
                            locationsTranslator = {this.props.locationsTranslator}
                            categoriesTranslator = {this.props.categoriesTranslator}
                            dayNames = {this.state.daysNames}
                            addPlaceToPlan ={(index)=>{this.addSelectedPlace(place,index)}}
            
                            userLanguage={this.props.userLanguage}
                            discoverSearchLanguage={this.props.userLanguage}
                            systemText={this.props.systemText}
                            RTL = {this.props.RTL}
                        />
                         </div>
                )
            } 
        }
    }

   
    renderShowAdditionalInfo = () => {
        let showExtraInfo = "ShowExtraInfo"
        if (this.props.RTL){
          showExtraInfo = "ShowExtraInfoRTL"
        }
        if (this.state.showAdditionalInfo){
          return(
              <div className={classes[showExtraInfo]} onClick={()=>{this.setState({showAdditionalInfo:false})}}>
                  <img src = {arrowIconLeft} className={classes.UpArrow} />
              </div>
          )
        }
        else{
            return(
                <div className={[classes[showExtraInfo],classes.WhiteBackground].join(" ")} onClick={this.showAdditionalInfo}>
                    <div className={classes.ExtraInfoText}>
                    {this.props.systemText.tripGenerator.prefernecesForm.showMorePreferneces[this.props.userLanguage]}
                    </div>
                    <img src = {arrowIconLeft} className={classes.Arrow} />
                </div>
            )
        }
    }

    showAdditionalInfo = () =>{
        this.setState({showAdditionalInfo:true})
        setTimeout(() => {
            this.state.extraInfoRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                delay:300,
              });
           }, 100);
    }

    renderPreferncesForm = () =>{
        let preferencesClass = "PreferencesForm"
        if (this.state.viewMode==="map"&&isMobile){
            preferencesClass = "HidePreferencesForm"
        }
        return(
            <div className = {classes[preferencesClass]}>
                {this.renderDestination()}
                
                {this.renderTripDates()}
                {this.renderTripGeneralInfo()}
                {this.renderAccommodationSection()}
                {this.renderMainCategories()}
                {this.renderShowAdditionalInfo()}
                {this.state.showAdditionalInfo?(
                <div ref={this.state.extraInfoRef}>
                    {this.renderIncludedPlaces()}
                    {this.renderIncludeExcludeCategories()}
                </div>):null}
          </div>
        )
    }

    renderTripDates = () =>{
        return(<div className={classes.InfoSection}>
            <div className={classes.SectionHeader}>
            {this.props.systemText.tripGenerator.prefernecesForm.durationAndDates[this.props.userLanguage]}
           </div>
           {this.renderTripDuration()}
           {/* {this.renderNoDatesButton()}
           {this.renderDurationSelection()}
            {this.state.noDatesSelected||!this.state.datesRangeSelected?(
               <div className={classes.WarningMessage}>
                   {this.props.systemText.tripGenerator.prefernecesForm.noDatesWarning[this.props.userLanguage]}
                </div>
             ):(
            null
             )}
               {this.state.noDatesSelected?(
               null
             ):(
                <div>{this.renderDatesSelection()}</div>
             )}
 */}

           </div>)
    }

    
    renderNoDatesButton = () =>{
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
        if (this.state.noDatesSelected){
          return(
            <div className={classes.CheckMarkSection} >
              <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.setState({noDatesSelected:false})}}/>
              <div className={classes.NoDatesButtonSelected}>
              {this.props.systemText.tripGenerator.prefernecesForm.noDates[this.props.userLanguage]}</div>
            </div>
          )
        }
        else{
          return(
            <div className={classes.CheckMarkSection}>
               <div className={classes[uncheckBoxClass]} onClick={()=>{this.setState({noDatesSelected:true})}}/>
              <div className={classes.NoDatesButton}>
              {this.props.systemText.tripGenerator.prefernecesForm.noDates[this.props.userLanguage]}</div>
            </div>
          )
        }
      }

      renderDatesSelection = () =>{
        let orientation="horizontal"
        let numberOfMonths = 2
        if (isMobile){
          numberOfMonths = 1
        } 
        let isRTL=false
        let direction="ltr"
        let anchorDirection ="left"
        if (this.props.RTL){
            direction="rtl"
            isRTL=true;
            anchorDirection="right"
        }
        if (this.state.tripDuration>1&&!this.state.oneDayTrip){
            return(
                <DateRangePicker
                  startDate={this.state.planStartDate} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                  endDate={this.state.planEndDate} // momentPropTypes.momentObj or null,
                  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                  onDatesChange={this.datePickerHandler} // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  orientation={orientation} verticalHeight={568}
                  horizontalMargin={0}
                  numberOfMonths = {numberOfMonths}
                  noBorder={true}
                  anchorDirection={anchorDirection}
                  isOutsideRange={(day)=>{ return false
                    }}
                    isRTL = {isRTL}
                  startDatePlaceholderText={this.props.systemText.extraModals.reactDates.startDatePlaceholder[this.props.userLanguage]}
                  endDatePlaceholderText={this.props.systemText.extraModals.reactDates.endDatePlaceholder[this.props.userLanguage]}
                />
              )
        }
        else{
            return(
                <SingleDatePicker
                placeholder={this.props.systemText.experience.createExperience.setDate[this.props.userLanguage]}
                date={this.state.planStartDate} // momentPropTypes.momentObj or null
                onDateChange={this.singleDateHandler} // PropTypes.func.isRequired
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                id="experience" // PropTypes.string.isRequired,
                orientation={orientation} 
                verticalHeight={568}
                  horizontalMargin={0}
                  noBorder={true}
                  small={false}
                  numberOfMonths={1}
                  anchorDirection={anchorDirection}
                  isOutsideRange={(day)=>{ return false
                  }}
                  isRTL = {isRTL}
                />
              )
            
        }
      }

      renderDurationSelection = () =>{
        if (!this.state.noDatesSelected){ 
          let radioButtonIcon = "RadioButton"
          let durationMessageClass = "DurationMessage"
          if (this.props.RTL){
            radioButtonIcon = "RadioButtonRTL"
            durationMessageClass = "DurationMessageRTL"
          }
         
          return(
            <div className={classes.DurationSection}>
              <div className={classes.DurationButton}>
                {this.state.oneDayTrip?(
                  <img src={radioButtonChecked} alt="" className={classes[radioButtonIcon]}/>
                ):(
                  <img src={radioButtonClear} alt="" className={classes[radioButtonIcon]}
                  onClick={()=>{this.setState({oneDayTrip:true})
                 /*  let screen = "NEW_TRIP"
                  if (this.props.editMode==="Edit"){
                      screen = "EDIT_TRIP"
                  }
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_EDIT_OVERVIEW_DURATION_SINGLEDAY_BUTTON",
                    userScreen:screen,
                  }) */}}/>
                )}
                {this.props.systemText.plan.createPlan.singleDay[this.props.userLanguage]}
              </div>
              <div className={classes.DurationButton}>
                {this.state.oneDayTrip?(
                  <img src={radioButtonClear} alt="" className={classes[radioButtonIcon]}
                  onClick={()=>{this.setState({oneDayTrip:false})
                 /*  let screen = "NEW_TRIP"
                  if (this.props.editMode==="Edit"){
                      screen = "EDIT_TRIP"
                  }
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_EDIT_OVERVIEW_DURATION_MULTIPLEDAYS_BUTTON",
                    userScreen:screen,
                  }) */}}/>
                ):(
                  <img src={radioButtonChecked} alt="" className={classes[radioButtonIcon]}/>
                )}
                {this.props.systemText.plan.createPlan.multipleDays[this.props.userLanguage]}
              </div>
            </div>
          )
        } 
      }

    renderTripGeneralInfo = () =>{
        return(
            <div className={classes.InfoSection}>
                <div className={classes.SectionHeader}>
                    {this.props.systemText.tripGenerator.prefernecesForm.generalInfo[this.props.userLanguage]}
                </div>
               
                {this.renderTripRadius()}
                {this.renderMaxPlacesPerDay()}
                {this.renderTravelWithKids()}
            </div>
        )
    }

    renderTravelWithKids = () =>{
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
        if (this.state.travelWithKids){
          return(
            <div className={classes.CheckMarkSection} >
              <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.setState({travelWithKids:false})}}/>
              <div className={classes.NoDatesButtonSelected}>{this.props.systemText.plan.createPlan.travelWithKids[this.props.userLanguage]}</div>
            </div>
          )
        }
        else{
          return(
            <div className={classes.CheckMarkSection}>
               <div className={classes[uncheckBoxClass]}  onClick={()=>{this.setState({travelWithKids:true})}}/>
              <div className={classes.NoDatesButtonSelected}>{this.props.systemText.plan.createPlan.travelWithKids[this.props.userLanguage]}</div>
            </div>
          )
        }
      }

    renderCreateButton = () =>{
        if (this.state.selectedDestinations.length>0){
            let createReady = false
            if (this.state.mainCategoriesAreSelected){
                    createReady=true
                }
            if (createReady){
                return(
                    <div className={classes.CreateButton} onClick={this.createHandler}>
                        {this.props.systemText.tripGenerator.prefernecesForm.generateTrips[this.props.userLanguage]}
                    </div>
                )
            }
            else{
                return(
                    <div className={classes.CreateButtonFade} >
                        {this.props.systemText.tripGenerator.prefernecesForm.generateTrips[this.props.userLanguage]}
                    </div>
                )
            }
        }
    }

    renderTripOutOfBoundsMessage = () =>{
        if (this.state.tripOutOfBounds){
            return(
                <div className={classes.IssueMessageModal}>
                    <div className={classes.IssueMessageText}>
                        {this.props.systemText.tripGenerator.tripAreaIssue.areaOutOfBoundsTitle[this.props.userLanguage]}
                    </div>
                    <div className={classes.IssueMessageSubText}>
                        {this.props.systemText.tripGenerator.tripAreaIssue.areaOutOfBoundsSubTitle[this.props.userLanguage]}
                    </div>
                    <div className={classes.IssueMessageOptions}>
                        <div className={classes.MessageOption} onClick={
                            ()=>{this.removeDestination(); 
                            this.setState({tripOutOfBounds:false, showNullBackdrop:false, tripRadiusTooSmall:false})}}>
                             {this.props.systemText.tripGenerator.tripAreaIssue.resetDestination[this.props.userLanguage]}
                        </div>
                        <div className={classes.MessageOption}  onClick={
                            ()=>{ this.setState({tripOutOfBounds:false,
                                tripRadiusTooSmall:false,
                            tripCenterCoordinates: this.state.destinationPlacesCenterCoordinates,
                            showNullBackdrop:false})}}>
                            {this.props.systemText.tripGenerator.tripAreaIssue.recenter[this.props.userLanguage]}
                           
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderTripRadiusTooSmall = () =>{
        if (this.state.tripRadiusTooSmall){
            return(
                <div className={classes.IssueMessageModal}>
                    <div className={classes.IssueMessageText}>
                        {this.props.systemText.tripGenerator.tripAreaIssue.smallTripRadiusTitle[this.props.userLanguage]}
                    </div>
                    <div className={classes.IssueMessageSubText}>
                        {this.props.systemText.tripGenerator.tripAreaIssue.smallTripRadiusText[this.props.userLanguage]}
                    </div>
                    <div style={{justifyContent:"center"}}className={classes.IssueMessageOptions}>
                    <TextButton
                        style="Style1"
                        text={"Close"}
                        clicked = {()=>{
                            this.setState({tripRadiusTooSmall:false, showNullBackdrop:false, tripOutOfBounds:false});
                        }}
                    />
                    </div>
                </div>
            )
        }
    }

    renderDaysOption = () =>{
        if (this.state.showDaysOptions){
          return(
            <PlanDaysOptions
              planDays = {this.state.daysNames.map(day=>{return {name:day}})}
              daysIndices = {this.state.daysIndices}
              singleDayMove = {this.state.singleDayMove}
              accommodationDaysOptions={this.state.accommodationDaysOptions}
              tripGenerator = {true}
              systemText = {this.props.systemText}
              userLanguage = {this.props.userLanguage}
              RTL = {this.props.RTL}
              finishHandler = {this.selectedDaysHandler}
              discard = {()=>{ this.setState({showDaysOptions:false, showNullBackdrop:false})}}

            />
          )
        }
      }

   render(){
    let RTLClass = "LTR"
    if (this.props.RTL){
        RTLClass = "RTL"
    }
    return(
        <div className={[classes.Modal,classes[RTLClass]].join(" ")}>
             <Backdrop show={this.state.showNullBackdrop}/>
           
          {/*   {this.renderTripOutOfBoundsMessage()}*/}
            {this.renderDaysOption()}
            {this.renderTripRadiusTooSmall()} 
            {this.renderTopSection()}
            
            {this.renderBody()}    
            {this.renderCreateButton()}      
      </div>
    )
}
}