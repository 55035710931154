import React, { Component } from "react";
import classes from "./AlbumPhotoViewer.module.css";

import ModalTopSection from "../../../UI/Misc/ModalTopSection"

import TripAlbumImage from "./TripAlbumImage"

import ImageGallery from 'react-image-gallery';
 import "react-image-gallery/styles/css/image-gallery.css"; 

import {isMobile, isMobileOnly} from 'react-device-detect';
class AlbumPhotoViewer extends Component {
    state={
        selectedImageIndex: this.props.index,
        images:[]
    }

    componentDidMount = () =>{
        let allPhotosList = this.props.allPhotosList
        let images = []
        for (let i=0 ; i<allPhotosList.length ; i++){
            let largeImage = allPhotosList[i].largeImage.url
            if (isMobileOnly){
                largeImage = allPhotosList[i].mediumImage.url
            }
            let smallImage = allPhotosList[i].smallImage.url
            if (isMobileOnly){
                smallImage = allPhotosList[i].thumbnailImage.url
            }
            let image ={
                original:largeImage,
                thumbnail:smallImage
            }
            images.push(image)
        }
        this.setState({images:images})
    }

    renderTopSection = () =>{
        return (
            <div className={classes.TopSection}>
               <ModalTopSection
               title={""}
               discard={this.props.discard}
               showButton={false}
               dark = {true}
               RTL = {this.props.RTL}
               />
           </div>
         );
      }

      swipeImageLeft = () =>{
        let selectedImageIndex = this.state.selectedImageIndex
        if (selectedImageIndex===0){
            selectedImageIndex = this.props.allPhotosList.length-1
        }
        else{
            selectedImageIndex -=1
        }
        this.setState({selectedImageIndex:selectedImageIndex})
      }

      swipeImageRight = () =>{
        let selectedImageIndex = this.state.selectedImageIndex
        if (selectedImageIndex<this.props.allPhotosList.length){
            selectedImageIndex+=1
        }
        else{
            selectedImageIndex=0
        }
        this.setState({selectedImageIndex:selectedImageIndex})
      }

      renderAlbum = () =>{
        let allPhotosList = this.props.allPhotosList
        let images = []
        let largeImage = ""
        let nextLargeImage = ""
        let prevLargeImage = ""
        if (isMobileOnly){
            images = allPhotosList.map(img => {return img.smallImage.url})
            if (allPhotosList[this.state.selectedImageIndex]!==undefined){
                largeImage = allPhotosList[this.state.selectedImageIndex].mediumImage.url
            }
            if (allPhotosList.length>1){
                if (this.state.selectedImageIndex<allPhotosList.length-1){
                    nextLargeImage = allPhotosList[this.state.selectedImageIndex+1].mediumImage.url
                }
                else{
                    nextLargeImage = allPhotosList[0].mediumImage.url
                }
                if (this.state.selectedImageIndex===0){
                    prevLargeImage = allPhotosList[allPhotosList.length-1].mediumImage.url
                }
                else{
                    prevLargeImage =allPhotosList[this.state.selectedImageIndex-1].mediumImage.url
                }
            }
           
        }
        else{
            images = allPhotosList.map(img => {return img.mediumImage.url})
            if (allPhotosList[this.state.selectedImageIndex]!==undefined){
                largeImage = allPhotosList[this.state.selectedImageIndex].largeImage.url
            }
        }
        let mobileBorder = ""
        let selectedPlaceBorder =""
        if (isMobile){
            mobileBorder="BorderTop"
        }
        else{
            selectedPlaceBorder = "BorderLeft"
            if (this.props.RTL){
                selectedPlaceBorder = "BorderRight"
            }
        }
        if (isMobile){
            return(
                <div className={classes.PlaceAlbum}>
                    <div className={classes.ImagesScrollSection}>
                        <div className={[classes.ImagesScroller,classes[mobileBorder]].join(" ")} >
                        
                            {images.map((img,imgIndex)=>(
                                    <TripAlbumImage
                                    key={imgIndex}
                                    index = {imgIndex}
                                    imageViewerIndex = {this.state.selectedImageIndex}
                                    url = {img}
                                    scrollImage = {true}
                                    imageClicked = {()=>{this.setState({selectedImageIndex:imgIndex})
                                    this.props.updateIndex(imgIndex)}}
                               />
                                ))}
                        </div>
                    </div>
                   {images.length>0?(
                       <div className={[classes.SelectedImage,classes[selectedPlaceBorder]].join(" ")}>
                      {/*   <SwipeableListItem
                        swipeLeft={{
                          content:  <img src={prevLargeImage} alt="" className={classes.LargeImage}/>,
                          action: () =>this.swipeImageLeft()
                        }}
                        swipeRight={{
                            content:  <img src={nextLargeImage} alt="" className={classes.LargeImage}/>,
                            action: () =>this.swipeImageRight()
                        }}
                        onSwipeEnd={this.performTaskSwipeAction}
                      >
                           
                            <img src={largeImage} alt="" className={classes.LargeImage}/>
                        </SwipeableListItem> */}
                        </div>
                   ):(
                    <div className={[classes.SelectedImage,classes[selectedPlaceBorder]].join(" ")}>
                    <img src={largeImage} alt="" className={classes.LargeImage}/>
                </div>
                   )}
                   
                </div>
              )
        }
        else{
            return(
                <div className={classes.PlaceAlbum}>
                    <div className={classes.ImagesScrollSection}>
                        <div className={[classes.ImagesScroller,classes[mobileBorder]].join(" ")} >
                        
                            {images.map((img,imgIndex)=>(
                                    <TripAlbumImage
                                    key={imgIndex}
                                    index = {imgIndex}
                                    imageViewerIndex = {this.state.selectedImageIndex}
                                    url = {img}
                                    scrollImage = {true}
                                    imageClicked = {()=>{this.setState({selectedImageIndex:imgIndex})
                                    this.props.updateIndex(imgIndex)}}
                               />
                                ))}
                        </div>
                    </div>
                    <div className={[classes.SelectedImage,classes[selectedPlaceBorder]].join(" ")}>
                    <img src={largeImage} alt="" className={classes.LargeImage}/>
                </div>
                   
                </div>
              )
        }
          
      }


      gallerySwipeHandler = index =>{
        this.setState({selectedImageIndex:index})
        this.props.updateIndex(index)
      }

      renderSwapAlbum = () =>{
        let allPhotosList = this.props.allPhotosList
        let images = []
        let largeImage = ""
        if (isMobileOnly){
            images = allPhotosList.map(img => {return img.smallImage.url})
            if (allPhotosList[this.state.selectedImageIndex]!==undefined){
                largeImage = allPhotosList[this.state.selectedImageIndex].mediumImage.url
            }
        }
        else{
            images = allPhotosList.map(img => {return img.mediumImage.url})
            if (allPhotosList[this.state.selectedImageIndex]!==undefined){
                largeImage = allPhotosList[this.state.selectedImageIndex].largeImage.url
            }
        }
        let mobileBorder = ""
        let selectedPlaceBorder =""
        if (isMobile){
            mobileBorder="BorderTop"
        }
        return(
        <div className={classes.PlaceGallery}> <ImageGallery items={this.state.images}
                startIndex={this.state.selectedImageIndex} 
                showBullets={false}
                isRTL={this.props.isRTL}
                showThumbnails={false}
                onSlide={this.gallerySwipeHandler}/>
          <div className={classes.ImagesScrollSection}>
            <div className={[classes.ImagesScroller,classes[mobileBorder]].join(" ")} >
            
                {images.map((img,imgIndex)=>(
                        <TripAlbumImage
                        key={imgIndex}
                        index = {imgIndex}
                        imageViewerIndex = {this.state.selectedImageIndex}
                        url = {img}
                        scrollImage = {true}
                        imageClicked = {()=>{this.setState({selectedImageIndex:imgIndex})
                        this.props.updateIndex(imgIndex)}}
                    />
                    ))}
            </div>
        </div>
        </div>);
      }

    render(){
        return(
            <div className={classes.Modal}>
                {this.renderTopSection()}
                {this.renderSwapAlbum()}
               {/*  {this.renderAlbum()} */}
            </div>
        )
    }
}

export default AlbumPhotoViewer;
