import React, { Component } from "react";
import classes from "./LoginForm.module.css";

import ModalTopSection from "../../UI/Misc/ModalTopSection"
import { isMobile } from "react-device-detect";

let googleIcon =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FGoogleIcon.png?alt=media&token=a4c1e47c-12c6-49fe-9e38-13be06ce38c7"
let eyeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FEyeIcon.png?alt=media&token=0fb8512a-f600-41cb-9666-631c10664044";
let PlanEXp =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FPlanEXp.png?alt=media&token=acf0f2c9-2a4d-4bf1-b533-4a7938107207";


export default class LoginForm extends Component {
  
state={
    screen:"Register",
    firstName:"",
    lastName:"",
    email:"",
    password:"",
    passwordType:"password",
    resetPassword:false,
}


togglePassword =()=>{
    if (this.state.passwordType==="password"){
        this.setState({passwordType: "text"});
    }
    else{
        this.setState({passwordType: "password"});
    }
}


emailHandler=event =>{
    if (event.keyCode === 27) {
    this.setState({
      email: ""
    });
    return;
    }
    //In case user pressed 'enter' key, add the tag to experience tags array
    //Use the selectTagSuggestion function for that
    if (event.key === "Enter") {
    //In case user pressed 'enter' with blank input, do nothing
    if (event.target.value === "") return;
    this.setState({ email: event.target.value });
    this.validateEmal(event.target.value)
    }
    this.setState({ email: event.target.value });
  }

  
  validateEmal = (email) =>{
    let regx= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regx.test(email))
    {
      this.setState({invalidEmail:false,emailInputFocused:false})
    }
    else{
    this.setState({invalidEmail:true,emailInputFocused:false})}
  }


  passwordHandler=event =>{
    if (event.keyCode === 27) {
    this.setState({
      password: ""
    });
    return;
    }
    //In case user pressed 'enter' key, add the tag to experience tags array
    //Use the selectTagSuggestion function for that
    if (event.key === "Enter") {
    //In case user pressed 'enter' with blank input, do nothing
    if (event.target.value === "") return;
    this.setState({ password: event.target.value });
    }
    this.setState({ password: event.target.value });
  }

  registerScreenHandler = () =>{
    this.setState({
        screen:"Register",
        firstName:"",
        lastName:"",
        password:"",
        passwordType:"password",
    })
  }

  loginScreenHandler = () =>{
    this.setState({
        screen:"Login",
        firstName:"",
        lastName:"",
        password:"",
        passwordType:"password",
    })
  }

  firstNameHandler=event =>{
    if (event.keyCode === 27) {
    this.setState({
      firstName: ""
    });
    return;
    }
    //In case user pressed 'enter' key, add the tag to experience tags array
    //Use the selectTagSuggestion function for that
    if (event.key === "Enter") {
    //In case user pressed 'enter' with blank input, do nothing
    if (event.target.value === "") return;
    let firstName= event.target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    this.setState({ firstName:firstName });
    }
    let firstName= event.target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    
    this.setState({ firstName: firstName});
}

lastNameHandler=event =>{
    if (event.keyCode === 27) {
    this.setState({
      lastName: ""
    });
    return;
    }
    //In case user pressed 'enter' key, add the tag to experience tags array
    //Use the selectTagSuggestion function for that
    if (event.key === "Enter") {
    //In case user pressed 'enter' with blank input, do nothing
    if (event.target.value === "") return;
    let lastName= event.target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    this.setState({ lastName: lastName});
    }
    let lastName= event.target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    this.setState({ lastName: lastName});
}



loginHandler=()=>{
    this.props.emailLogin(this.state.email, this.state.password);
}

validatePassword = (password) =>{
  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  if (strongRegex.test(password))
  {
    this.setState({weakPassword:false,passwordIsEntered:false})
  }
  else{
  this.setState({weakPassword:true,passwordIsEntered:false})}
}


resetPasswordHandler = () =>{
    this.props.resetPassword(this.state.email);
}

    renderLogo = () =>{
        return(
            <div className={classes.LogoSection}>
                <img src={PlanEXp} alt="" className={classes.Logo}/>
            </div>
        )
    }


    renderFacebookLogin = () =>{
      let message = this.props.systemText.loginForm.continueWithFacebook[this.props.userLanguage]
      if (this.state.screen==="Register"){
          message = this.props.systemText.loginForm.continueWithFacebook[this.props.userLanguage]
      }
      return(<div className={classes.ExternalLoginBox} onClick={this.props.facebookLogin}>
        {message}</div>)
    }

    renderGooglLogin = ()=>{
        let iconClass = "ExternalIcon"
        if (this.props.RTL){
            iconClass = "ExternalIconRTL"
        }
        let message = this.props.systemText.loginForm.continueWithGoogle[this.props.userLanguage]
        if (this.state.screen==="Register"){
            message = this.props.systemText.loginForm.continueWithGoogle[this.props.userLanguage]
        }
        return(<div className={classes.ExternalLoginBox} onClick={this.props.googleLogin}>
                <img className={classes[iconClass]} alt="" src={googleIcon}/>
                {message}</div>)
    }


    renderEmailPasswordLogin = () =>{
        return (<div className={classes.EmailOption}>
            <div className={classes.InputField}>
                <input 
                    placeholder= {this.props.systemText.loginForm.emailPlaceholder[this.props.userLanguage]}
                    className={classes.InputText}
                    value={this.state.email}
                    onChange={this.emailHandler}
                    onKeyDown={this.emailHandler}
                    onKeyPress={this.emailHandler}
                    onFocus={()=>{this.setState({emailInputFocused:true})}}
                    onBlur={()=>{this.validateEmal(this.state.email)}}
                />
                {this.renderEmailError()}
            </div>
            {!this.state.resetPassword?(
            <div className={classes.InputField}>
                <input
                    type={this.state.passwordType} 
                    placeholder= {this.props.systemText.loginForm.passwordPlaceholder[this.props.userLanguage]}
                    className={classes.InputText} 
                    value={this.state.password} 
                    onChange={this.passwordHandler}
                    onKeyDown={this.passwordHandler}
                    onKeyPress={this.passwordHandler}
                />
            <img src={eyeIcon} alt="" className={classes.EyeIcon} onClick={this.togglePassword}/>
            </div>):null}
         
        </div>);
    }

    renderLoginButton = () =>{
      if (this.state.resetPassword){
        if (this.state.invalidEmail||this.state.emailInputFocused){
          return(   <div className={classes.LoginFade} >
            {this.props.systemText.loginForm.resetPassword[this.props.userLanguage]}
           </div>)
        }
        else{
          return(   <div className={classes.Login} onClick={this.resetPasswordHandler}>
            {this.props.systemText.loginForm.resetPassword[this.props.userLanguage]}
     </div>)
        }
      }
      else{
        if (this.state.invalidEmail || this.state.password.length<8){
          return(   <div className={classes.LoginFade}>
            {this.props.systemText.loginForm.loginButton[this.props.userLanguage]}
            </div>)
        } 
        else{
          return(   <div className={classes.Login} onClick={this.loginHandler}>
            {this.props.systemText.loginForm.loginButton[this.props.userLanguage]}
           </div>)
        } 
      }
           
    }

    renderRegisterButton=()=>{
        return(<div className={classes.Register}>
              {this.props.systemText.loginForm.dontHaveAccountMessage[this.props.userLanguage]}
            <div className={classes.RegisterButton} onClick={this.registerScreenHandler}>
            {this.props.systemText.loginForm.signInButton[this.props.userLanguage]}
            </div>
        </div>)
    }

    renderForgotPassword = () =>{
        if (this.state.resetPassword){
            return(<div className={classes.ResetPasswordSection}>
                <div className={classes.ResetPassword} onClick={()=>{this.setState({resetPassword:false})}}>
                {this.props.systemText.loginForm.goBack[this.props.userLanguage]}
                    </div>
            </div>)
        }
        else{
            return(<div className={classes.ResetPasswordSection}>
                <div className={classes.ResetPassword} onClick={()=>{this.setState({resetPassword:true})}}>
                {this.props.systemText.loginForm.forgotPassword[this.props.userLanguage]}
                    </div>
            </div>)
        }
       
    }

    renderEmailError = () =>{
        if (this.state.invalidEmail){
          return(
            <div className={classes.ErrorMessage}>
             {this.props.systemText.loginForm.emailErrorMessage[this.props.userLanguage]}
            </div>
          )
        }
      }

      
    renderPasswordError = () =>{
      if (this.state.weakPassword){
        return(
          <div className={classes.ErrorMessage}>
           {this.props.systemText.loginForm.weakPasswordMessage[this.props.userLanguage]}
          </div>
        )
      }
    }
  
    renderBody = () =>{
        if (this.state.screen==="Login"){
            return(
                <div className={classes.Body}>
                    {this.renderLogo()}
                    {this.renderTitle()}
                    <div className={classes.Content}>
                      {this.renderGooglLogin()}
                   {/*    {this.renderFacebookLogin()} */}
                      {this.renderEmailPasswordLogin()}
                      {this.renderForgotPassword()}
                      {this.renderLoginButton()}
                      {this.renderRegisterButton()}
                      <div
                      onClick={()=>{this.props.handleEmailLink(this.state.email)}}
                      >{"email link"}</div>
                    </div>
                    
                </div>
            )
        }
        else{
            return(
                <div className={classes.Body}>
                     {this.renderLogo()}
                     {this.renderTitle()}
                     <div className={classes.Content}>
                      {this.renderGooglLogin()}
                  {/*     {this.renderFacebookLogin()} */}
                      {this.renderRegisterForm()}
                      {this.renderSignUpButton()}
                      {this.renderSwitchToLoginButton()}
                    </div>
                </div>
            )
        }
    }

    renderSignUpButton=()=>{ 
        let notReady = false;
        if (this.state.firstName.length<2||
          this.state.lastName.length<2||
          this.state.password.length <8 ||
          this.weakPassword||
          this.state.passwordIsEntered){
            notReady=true;
          }
        if (this.state.invalidEmail || notReady){
          return(   
            <div className={classes.LoginFade}>
              {this.props.systemText.loginForm.signupButton[this.props.userLanguage]}
              </div>
            )
        } 
        else{
          return(   
            <div className={classes.Login} 
            onClick={()=>{this.props.emailRegister(this.state.email, this.state.password,this.state.firstName,this.state.lastName)}}>
              {this.props.systemText.loginForm.signupButton[this.props.userLanguage]}
              </div>
            )
        } 
       }
  
      renderSwitchToLoginButton=()=>{
          return(<div className={classes.Register}>
              {this.props.systemText.loginForm.alreadyHaveAccountMessage[this.props.userLanguage]}
              <div className={classes.RegisterButton} onClick={this.loginScreenHandler}>
              {this.props.systemText.loginForm.loginButton[this.props.userLanguage]}
                </div>
          </div>)
      }

    renderRegisterForm=()=>{
        return (<div className="Form">
                  <div className={classes.InputField}>  
                    <input 
                      placeholder={this.props.systemText.loginForm.firstNamePlaceholder[this.props.userLanguage]}
                      className={classes.InputText}
                      value={this.state.firstName}
                      onChange={this.firstNameHandler}
                      onKeyDown={this.firstNameHandler}
                      onKeyPress={this.firstNameHandler}
                    />
                  </div>
                  <div className={classes.InputField}> 
                    <input 
                      placeholder={this.props.systemText.loginForm.lastNamePlaceholder[this.props.userLanguage]}
                      className={classes.InputText}
                      value={this.state.lastName}
                      onChange={this.lastNameHandler}
                      onKeyDown={this.lastNameHandler}
                      onKeyPress={this.lastNameHandler}
                    />
                  </div>
                  <div className={classes.InputField}>  
                    <input 
                      placeholder={this.props.systemText.loginForm.emailPlaceholder[this.props.userLanguage]}
                      className={classes.InputText}
                      value={this.state.email}
                      onChange={this.emailHandler}
                      onKeyDown={this.emailHandler}
                      onKeyPress={this.emailHandler}
                      onFocus={()=>{this.setState({emailInputFocused:true})}}
                      onBlur={()=>{this.validateEmal(this.state.email)}}
                    />
                   
                  </div>
                  {this.renderEmailError()}
                  <div className={classes.InputField}> 
                    <input 
                      placeholder={this.props.systemText.loginForm.passwordPlaceholder[this.props.userLanguage]}
                      className={classes.InputText}
                      value={this.state.password} 
                      onChange={this.passwordHandler}
                      onKeyDown={this.passwordHandler}
                      onKeyPress={this.passwordHandler}
                      onFocus={()=>{this.setState({passwordIsEntered:true})}}
                      onBlur={()=>{this.validatePassword(this.state.password)}}
                    />
                   
                  </div>
                  {this.renderPasswordError()}
     
    </div>);
    }

    renderTitle = () =>{
        return(<div className={classes.Title}>   
            {this.props.systemText.loginForm.loginTitle[this.props.userLanguage]}</div>)
    }

    renderTopSection = () =>{
      if (isMobile){
        return (
          <div className={classes.TopSection}>
             <ModalTopSection
             title={""}
             discard={this.props.discard}
             showButton={false}
             modalView={true}
             RTL = {this.props.RTL}
             />
         </div>
       );
      }
   
  }

  render() {
    let directionClass = ""
    if (this.props.RTL){
        directionClass="RTL"
    }
    return (
      <div className={[classes.Modal,classes[directionClass]].join(" ")}>
          {this.renderTopSection()}
          {this.renderBody()}
      </div>
    );
  }
}