import React, { Component } from "react";


import classes from "./SetLocationTree.module.css";

import OptionSelection from "../OptionSelection/OptionSelection"
import DetectInputLanguage from "../../Functions/DetectInputLanguage"

let closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01";


class SetLocationTree extends Component {

    state={
        placeCountry: {inputText:""},
        placeRegion: {inputText:""},
        placeCity:{inputText:""},
        countriesList:[],
        regionsList:[],
        citiesList:[],
        countryLanguage: "",
        regionLanguage:"",
        cityLanguage:"",
        ableToSave:true,
        locationTreeIDs:[],
        countryChanged: true,
        initialCalc:!this.props.locationPathUpdate,
        render:false,
        detectInputLanguage:React.createRef(),
    }

    componentWillMount = () =>{
        if (this.props.placeCountry!==""){
            this.setState({placeCountry:{
                inputText:this.props.placeCountry,
                treeID : this.props.placeCountry.charAt(0).toUpperCase() + this.props.placeCountry.slice(1)
            }})
        }      
        if (this.props.placeRegion!==""){
            this.setState({placeRegion:{inputText:this.props.placeRegion}})
        }
        if (this.props.placeCity!==""){
            this.setState({placeCity:{inputText:this.props.placeCity}})
        } 
      }

    componentDidMount=()=>{
        this.generateLists();
    }

    generateLists = async () =>{
        //getting all the countries
        let countriesList=Object.values(JSON.parse(JSON.stringify(this.props.locationsTree)));
        //want to get all the search ids for this place based on location filters
        let existingCountry = false;
        let existingRegion = false;
        let existingCity = false;

        let regionWasChanged = this.state.regionChanged

        let regionsList = [];
        let citiesList = [];

        //handle country
        let placeCountry={inputText:""};
        if (this.state.countryChanged){
            for (let i=0 ; i<countriesList.length ; i++){
                if (this.state.placeCountry.treeID === countriesList[i].treeID){
                    placeCountry = countriesList[i]
                    this.setState({placeCountry:placeCountry, countryChanged:false})
                    existingCountry = true;
                }
            }
        }
        else{
            placeCountry = this.state.placeCountry
            existingCountry = true;
        }
        let countryText = "";
        if (placeCountry.inputText!==""){
            if (placeCountry[this.props.userLanguage]!==undefined){
                countryText = placeCountry[this.props.userLanguage]
            }
            else if (placeCountry[this.props.inputLanguage]!==undefined){
                countryText = placeCountry[this.props.inputLanguage]
            }
            else{
                countryText = placeCountry["English"]
            }
        }
       
        placeCountry.inputText = countryText

        //get regions and cities list for country
        if (existingCountry){
            if(placeCountry.regions!==undefined){
                regionsList=Object.values(placeCountry.regions).filter(value=>value.type!==undefined);
            } 
            if(placeCountry.cities!==undefined){
                citiesList=Object.values(placeCountry.cities).filter(value=>value.type!==undefined);
            }
            for (let i=0 ; i<regionsList.length ; i++){
                if(regionsList[i].cities!==undefined){
                    citiesList=citiesList.concat(Object.values(regionsList[i].cities).filter(value=>value.type!==undefined));
                }
            }
        }

        //handle region
        let placeRegion;
        if (this.state.placeRegion.treeID!==undefined&&this.state.regionChanged){
            for (let i=0 ; i<regionsList.length ; i++){
                if (this.state.placeRegion.treeID === regionsList[i].treeID){
                    placeRegion = regionsList[i]
                    this.setState({placeRegion:placeRegion})
                    existingRegion = true;
                }
            }
        }
        else{
             // try to find region by text
             if (this.state.placeRegion.inputText){
                let regionTextLanguage;
                await this.state.detectInputLanguage.current.detectLanguage(this.state.placeRegion.inputText).then(result => {
                   regionTextLanguage = result
                 }); 
                 if (regionTextLanguage!==undefined){
                   for (let i=0 ; i<regionsList.length ; i++){
                       if (this.state.placeRegion.inputText === regionsList[i][regionTextLanguage]){
                           placeRegion = regionsList[i]
                           this.setState({placeRegion:placeRegion, regionChanged:false})
                           existingRegion = true;
                       }
                   }
                 }
             }             
            if (!existingRegion){
                placeRegion = this.state.placeRegion
                existingRegion = this.state.existingRegion
            } 
            
        }

        if (placeRegion.treeID!==undefined){
            let regionText = "";
            if (placeRegion[this.props.userLanguage]!==undefined){
                regionText = placeRegion[this.props.userLanguage]
            }
            else if (placeRegion[this.props.inputLanguage]!==undefined){
                regionText = placeRegion[this.props.inputLanguage]
            }
            else{
                regionText = placeRegion["English"]
            }
            placeRegion.inputText = regionText
        }
        
        
        if (placeRegion.treeID!==undefined){
            //update cities list to the region
            citiesList = []
            if(placeRegion.cities!==undefined){
                citiesList=Object.values(placeRegion.cities).filter(value=>value.type!==undefined);
            }
        }

         //handle city
         let placeCity;
         if (this.state.placeCity.treeID!==undefined&&this.state.cityChanged){
             for (let i=0 ; i<citiesList.length ; i++){
                 if (this.state.placeCity.treeID === citiesList[i].treeID){
                    placeCity = citiesList[i]
                     this.setState({placeCity:placeCity, cityChanged:false})
                     existingCity = true;
                 }
             }
         }
         else{
             // try to find city by text
             if (this.state.placeCity.inputText!==undefined){
                 if (this.state.placeCity.inputText!==""){
                    let cityTextLanguage;
                    await this.state.detectInputLanguage.current.detectLanguage(this.state.placeCity.inputText).then(result => {
                       cityTextLanguage = result
                     }); 
                     if (cityTextLanguage!==undefined){
                        let i=0;
                       while (i<citiesList.length && !existingCity){
                           if (this.state.placeCity.inputText === citiesList[i][cityTextLanguage]){
                              placeCity = citiesList[i]
                               this.setState({placeCity:placeCity, cityChanged:false})
                               existingCity = true;
                           }
                           i++;
                       }
                     }
                 }
                 //trying again to use the address component from google search result for selecting existing city
                 else if (this.state.initialCalc){
                    for (let i=0 ; i<this.props.addressComponents.length ; i++ ){
                        let addressComponent = this.props.addressComponents[i]
                        if (addressComponent.types.includes("locality")){
                            let cityTextLanguage;
                            await this.state.detectInputLanguage.current.detectLanguage(addressComponent.long_name).then(result => {
                            cityTextLanguage = result
                            }); 

                            if (cityTextLanguage!==undefined){
                                for (let i=0 ; i<citiesList.length ; i++){
                                    if (addressComponent.long_name === citiesList[i][cityTextLanguage]){
                                       placeCity = citiesList[i]
                                        this.setState({placeCity:placeCity, cityChanged:false})
                                        existingCity = true;
                                    }
                                }
                              }
                        }
                      }
                 }
             }             
            if (!existingCity){
                placeCity = this.state.placeCity
                existingCity = this.state.existingCity
            } 
         }
 
         if (placeCity.treeID!==undefined){
            let cityText = "";
            if (placeCity[this.props.userLanguage]!==undefined){
               cityText = placeCity[this.props.userLanguage]
            }
            else if (placeCity[this.props.inputLanguage]!==undefined){
               cityText = placeCity[this.props.inputLanguage]
            }
            else{
               cityText = placeCity["English"]
            }
            placeCity.inputText = cityText
         }
        await this.setState({
            countriesList:countriesList,
            placeCountry:placeCountry,
            placeRegion:placeRegion,
            placeCity:placeCity,
            existingCountry:existingCountry,
            existingRegion:existingRegion,
            existingCity:existingCity,
            regionsList: regionsList,
            citiesList: citiesList, 
            regionChanged:false,
            countryChanged:false,
            cityChanged:false
        })
        if (existingCountry&&existingRegion&&existingCity&&this.state.initialCalc){
            this.finishHandler()
        }
        else if (!regionWasChanged){
            this.tryToAdjustRegion()
        }
        else{
            this.setState({initialCalc:false,render:true})
        }
       
    }

    tryToAdjustRegion = async () =>{
        //try adjusting region based on the selected city/area
        if (this.state.placeRegion.treeID===undefined&&this.state.placeCountry.treeID!==undefined&&this.state.placeCity.treeID!==undefined){
            for (let i=0 ; i<this.state.regionsList.length ; i++){
                if(this.state.regionsList[i].cities!==undefined){
                    let citiesList=Object.values(this.state.regionsList[i].cities).filter(value=>value.type!==undefined);
                    for (let j=0 ; j<citiesList.length ; j++){
                        if(citiesList[j].treeID===this.state.placeCity.treeID){
                            let placeRegion = this.state.regionsList[i]
                            let existingRegion = true

                            let regionText = "";
                            if (placeRegion[this.props.userLanguage]!==undefined){
                                regionText = placeRegion[this.props.userLanguage]
                            }
                            else if (placeRegion[this.props.inputLanguage]!==undefined){
                                regionText = placeRegion[this.props.inputLanguage]
                            }
                            else{
                                regionText = placeRegion["English"]
                            }
                            placeRegion.inputText = regionText
                            
                            if (existingRegion){
                                //update cities list to the region
                                citiesList = []
                                if(placeRegion.cities!==undefined){
                                    citiesList=Object.values(placeRegion.cities).filter(value=>value.type!==undefined);
                                }
                            }
                            await this.setState({placeRegion:placeRegion,existingRegion:existingRegion,citiesList:citiesList})
                            if (this.state.existingCountry&&existingRegion&&this.state.existingCity&&this.state.initialCalc){
                                this.finishHandler()
                            }
                            else{
                                this.setState({initialCalc:false,render:true})
                            }
                            return
                        }
                    }
                }
                else{
                    this.setState({initialCalc:false,render:true})
                }
            }
        }
        else{
            this.setState({initialCalc:false,render:true})
        }
    }

    finishHandler= async ()=>{
        let placeCountry=this.state.placeCountry;
        if (placeCountry.regions!==undefined) delete placeCountry.regions;
        if (placeCountry.cities!==undefined) delete placeCountry.cities;
        let placeRegion=this.state.placeRegion;
        if (placeRegion.cities!==undefined) delete placeRegion.cities;
        let placeCity = this.state.placeCity
        //create locations list of treeIDS
        let locationTreeIDs = [];
        if (placeCountry.treeID!==undefined){
            locationTreeIDs.push(placeCountry.treeID)
        }
        if (placeRegion.treeID!==undefined){
            locationTreeIDs.push(placeRegion.treeID)
        }
        else{
            placeRegion.treeID=placeCountry.treeID+"_"+placeRegion.inputText
            await this.state.detectInputLanguage.current.detectLanguage(placeRegion.inputText).then(result => {
                placeRegion[result] = placeRegion.inputText
              }); 
        }
        if (placeCity.treeID!==undefined){
            locationTreeIDs.push(placeCity.treeID)
        }
        else{
            placeCity.treeID=placeCountry.treeID+"_"+placeCity.inputText
            await this.state.detectInputLanguage.current.detectLanguage(placeCity.inputText).then(result => {
                placeCity[result] = placeCity.inputText
              }); 
        }
         //get slim places for selected country to search for duplicates
        await this.props.getPlacesListForPlaceCreationValidation(placeCountry.treeID)
         //finilaze update
         setTimeout(e => {
            let placeTree ={
                placeCountry: placeCountry,
                placeRegion: placeRegion,
                placeCity:placeCity,
                locationTreeIDs:locationTreeIDs
            }
            /* console.log(placeTree) */
            this.props.finish(placeTree);
          }, 400);  
    }

    

    countryInputHandler = async selection =>{
        await this.setState({placeCountry:selection, countryChanged:true});
        this.generateLists();

     }
    regionInputHandler = async selection =>{
        await this.setState({placeRegion:{inputText:""}, regionChanged:true});
        if (selection.treeID!==undefined){
            await this.setState({placeRegion:selection});
        }
        else{
            await this.setState({placeRegion:{inputText:selection}});
        }
        this.generateLists();
     }
     cityInputHandler = async selection =>{
        await this.setState({placeCity:{inputText:""},cityChanged:true});
        if (selection.treeID!==undefined){
            await this.setState({placeCity:selection});
        }
        else{
            await this.setState({placeCity:{inputText:selection}});
        }
        this.generateLists();
     }
 

    renderTopSection = () =>{
        return(
            <div className={classes.TopSection}>
                <div className={classes.Title}>
                    {this.props.systemText.extraModals.setLocation.setLocationTree.title[this.props.userLanguage]}
                </div>
            </div>
        )
    }

    renderMessage = () =>{
        let renderMessage  = true;
        if (this.state.placeCountry.inputText!==undefined){
            if (this.state.placeCountry.inputText.length>2){
                //country updated
                if (this.state.placeRegion.inputText!==undefined){
                    if (this.state.placeRegion.inputText.length > 2){
                        renderMessage = false
                    }
                }
                if (this.state.placeCity.inputText!==undefined){
                    if (this.state.placeCity.inputText.length > 2){
                        renderMessage = false
                    }
                }
            }
        }
        if (renderMessage){
            return(
                <div className={classes.Message}>
                    {this.props.systemText.extraModals.setLocation.setLocationTree.message[this.props.userLanguage]}
                </div>
            )
        }
    }

    renderContent = () =>{
        return(
            <div className={classes.Content}>
                <div className={classes.Section}>
                    <div className={classes.SectionName}>
                        {this.props.systemText.extraModals.setLocation.setLocationTree.country[this.props.userLanguage]}
                    </div>
                    <OptionSelection
                        currentSelection={this.state.placeCountry.inputText}
                        optionsList={this.state.countriesList}
                        updateSelectedOption={this.countryInputHandler}
                        valueRequired={this.state.placeCountry.inputText===""}
                        onlyFromList = {true}
                        showCurrentSelection = {true}
                        systemText={this.props.systemText}
                        userLanguage={this.props.userLanguage}
                        RTL = {this.props.RTL}
                        inputLanguage = {this.props.inputLanguage}
                        detectInputLanguage = {this.props.detectInputLanguage}
                       
                        />
                </div>
                {this.state.placeCountry.inputText!==""?(
                    <div className={classes.Section}>
                    <div className={classes.SectionName}>
                        {this.props.systemText.extraModals.setLocation.setLocationTree.region[this.props.userLanguage]}
                    </div>
                    <OptionSelection
                         currentSelection={this.state.placeRegion.inputText}
                         optionsList={this.state.regionsList}
                         updateSelectedOption={this.regionInputHandler}
                         valueRequired={this.state.placeRegion.inputText===""&&this.state.placeCity.inputText===""}
                        showCurrentSelection = {true}
                        systemText={this.props.systemText}
                        userLanguage={this.props.userLanguage}
                        RTL = {this.props.RTL}
                        inputLanguage = {this.props.inputLanguage}
                        detectInputLanguage = {this.props.detectInputLanguage}
                        inputInFocus = {(value)=>{this.setState({optionsInputInFocus:value})}}
                        />
                    
                </div>
                ):null}
                 {this.state.placeCountry.inputText!==""?(
                     <div className={classes.Section}>
                     <div className={classes.SectionName}>
                         {this.props.systemText.extraModals.setLocation.setLocationTree.city[this.props.userLanguage]}
                     </div>
                     <OptionSelection
                         currentSelection={this.state.placeCity.inputText}
                         optionsList={this.state.citiesList}
                         updateSelectedOption={this.cityInputHandler}
                         valueRequired={this.state.placeRegion.inputText===""&&this.state.placeCity.inputText===""}
                        showCurrentSelection = {true}
                        systemText={this.props.systemText}
                        userLanguage={this.props.userLanguage}
                        RTL = {this.props.RTL}
                        inputLanguage = {this.props.inputLanguage}
                        detectInputLanguage = {this.props.detectInputLanguage}
                        inputInFocus = {(value)=>{this.setState({optionsInputInFocus:value})}}
                        />
                 </div>
                ):null}
            </div>
        )
    }

    

    renderBottomSection = () =>{
        let saveReady  = false;
        if ((this.state.placeCountry.treeID!==undefined||this.props.locationPathUpdate) && !this.state.optionsInputInFocus){
            if (this.state.placeRegion.inputText!==undefined){
                if (this.state.placeRegion.inputText.length > 1){
                    saveReady = true
                }
            }
            if (this.state.placeCity.inputText!==undefined){
                if (this.state.placeCity.inputText.length > 1){
                    saveReady = true
                }
            }
        }
       if (saveReady){ 
            return(
                <div className={classes.BottomSection}>
                    <div className={classes.ResultsButton} onClick={this.finishHandler}>
                        {this.props.systemText.extraModals.setLocation.setLocationTree.finishButton[this.props.userLanguage]}
                    </div>
                </div>
              )
        }
        else{          
            return(
                <div className={classes.BottomSection}>
                    <div className={classes.ResultsButtonFade}>
                        {this.props.systemText.extraModals.setLocation.setLocationTree.finishButton[this.props.userLanguage]}
                    </div>
                </div>
              )
        }     
      }

      detectInputLanguage = () =>{
        return (<DetectInputLanguage
            /* ref="detectInputLanguage" */
            ref={this.state.detectInputLanguage}
          />)
      }
   
    render(){
        if (this.state.render){
            let clossIconClass = "CloseIcon"
            if (this.props.RTL){
                clossIconClass = "CloseIconRTL"
            }
            if (this.props.inline){
                return(
                    <div className={classes.Inline}>
                    {this.detectInputLanguage()}
                    {this.renderContent()}
                </div>
                )
               
            }
            else{
                return(<div className={classes.Modal}>
                     {this.detectInputLanguage()}
                    <img src={closeIcon} alt="" className={classes[clossIconClass]} onClick={this.props.discard}/>
                    <div className={classes.Body}>
                        {this.renderTopSection()}
                        {this.renderMessage()}
                        {this.renderContent()}
                        {this.renderBottomSection()}
                    </div>
                   </div>)
            }
           
        }
       else return(<div>
           {this.detectInputLanguage()}
       </div>)
    }
}
export default SetLocationTree;