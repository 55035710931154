import React, { Component } from "react";
import classes from "./PlanTask.module.css";

import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"
import TaskOwner from "./TaskOwner"
import TextButton from "../../UI/Buttons/TextButton"

import TextareaAutosize from 'react-textarea-autosize';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState , convertFromRaw, convertToRaw} from 'draft-js';

import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import {isMobile} from 'react-device-detect'; 

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"
let deleteIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2Fdelete.png?alt=media&token=1d88eb4a-6c67-43d8-81ed-0bb919c7d5fa"
let removeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"


class PlanTask extends Component {
    state={
        taskTitle:this.props.task.taskTitle,
        taskInfo:this.props.task.taskInfo,
        taskStatus:this.props.task.taskStatus,
        taskLinks:this.props.task.taskLinks,
        tasksList:this.props.task.tasksList,
    }

    componentDidUpdate=(prevProps)=>{
        if (this.props.task.taskLastModified!==prevProps.task.taskLastModified){
            this.setState({
                taskTitle:this.props.task.taskTitle,
                taskID:this.props.task.taskID,
                taskStatus:this.props.task.taskStatus,
            })
            try {
                JSON.parse(this.props.task.taskInfo);
                this.setState({taskInfo: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.task.taskInfo))),
                  jsonString: true
                  })
            } catch (e) {
           
            }
            if (this.props.task.taskLinks===undefined){
                this.setState({taskLinks:[]})
            }
            else{
                this.setState({taskLinks:this.props.task.taskLinks})
            }
            if (this.props.task.tasksList===undefined){
                this.setState({tasksList:[]})
            }
            else{
                this.setState({tasksList:this.props.task.tasksList})
            }
        }
      }

      componentDidMount=()=>{
        if (this.props.task.taskLinks===undefined){
            this.setState({taskLinks:[]})
        }
        else{
            this.setState({taskLinks:this.props.task.taskLinks})
        }
        if (this.props.task.tasksList===undefined){
            this.setState({tasksList:[]})
        }
        else{
            this.setState({tasksList:this.props.task.tasksList})
        }
        try {
          JSON.parse(this.props.task.taskInfo);
          this.setState({taskInfo: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.task.taskInfo))),
            jsonString: true
            })
      } catch (e) {
     
      }
      }

    discard = () =>{
        this.setState({
            showBackdrop: false
        })
    }

    topSectionClickedHandler = () =>{
        if (!this.props.openMode){
            this.props.taskOpenHandler(this.props.index)
        }
    }

    updateTaskTitle = async (e) =>{
        await this.setState({taskTitle:e.target.value});
    }

    updateTaskInfo = async (taskInfo) =>{
        this.setState({ taskInfo: taskInfo });
    }

    updateSubTaskStatus = async index =>{
        let tasksList=this.state.tasksList;
        if (tasksList[index].status==="open"){
            tasksList[index].status="completed"
            let allCompleted=true;
            for (let i=0;i<tasksList.length ; i++){
                if (tasksList[i].status==="open"){
                    allCompleted=false
                }
            }
            if (allCompleted){
                 await this.setState({
                    taskStatus:"completed"
                })
            }
            this.props.writeAcitivityLog({
                activityKey:"TRIP_TASK_SUBTASK_COMPLETED",
                userScreen:"VIEW_TRIP"
              })
        }
        else{
            tasksList[index].status="open"
            if (this.state.taskStatus==="completed"){
                await this.setState({
                    taskStatus:"open"
                })
            }
            this.props.writeAcitivityLog({
                activityKey:"TRIP_TASK_SUBTASK_UNCOMPLETED",
                userScreen:"VIEW_TRIP"
              })
        }
        await this.setState({tasksList:tasksList})
       this.updateTask()
    }

    addSubTaskHandler = () =>{
        let tasksList=this.state.tasksList;
        tasksList.unshift(
            {status:"open",text:"",owner:this.props.user}
        )
        this.setState({tasksList:tasksList})
        this.updateTask()

        this.props.writeAcitivityLog({
            activityKey:"TRIP_TASK_SUBTASK_ADD",
            userScreen:"VIEW_TRIP"
          })
    } 

    removeSubTaskHandler = index =>{
        let tasksList=this.state.tasksList;
        tasksList.splice(index,1);
        this.setState({tasksList:tasksList})
        this.updateTask()

        this.props.writeAcitivityLog({
            activityKey:"TRIP_TASK_SUBTASK_REMOVE",
            userScreen:"VIEW_TRIP"
          })
    }

    ownerHandler = index =>{
        this.setState({selectedTaskIndex: index, showManageOwners: true, showBackdrop: true})
    }

    
    updateSubTaskText = (e,index)=>{
        let tasksList=this.state.tasksList;
        tasksList[index].text=e.target.value
        this.setState({tasksList:tasksList})
        this.setState({needToUpdate: true});
    }

    updateTaskOwner = async user =>{
        let tasksList=this.state.tasksList;
        tasksList[this.state.selectedTaskIndex].owner=user;
        await this.setState({tasksList:tasksList})
        this.updateTask();

        this.props.writeAcitivityLog({
            activityKey:"TRIP_TASK_SUBTASK_ASSIGN",
            userScreen:"VIEW_TRIP"
          })
    }

    updateStatus = async () =>{
        if (this.props.task.taskStatus==="completed"){
            await this.setState({
                taskStatus:"open"
            })
            this.updateTask()

            this.props.writeAcitivityLog({
                activityKey:"TRIP_TASK_UNCOMPLETED",
                userScreen:"VIEW_TRIP"
              })
        }
        else{
            await this.setState({
                taskStatus:"completed"
            })
            this.updateTask()
            this.props.taskOpenHandler(-1)

            this.props.writeAcitivityLog({
                activityKey:"TRIP_TASK_COMPLETED",
                userScreen:"VIEW_TRIP"
              })
        }
      }

      performTaskSwipeAction =() =>{
        if (this.state.taskSwipeAction==="Delete"){
            this.removeSubTaskHandler(this.state.taskSwipeIndex)
        }
      }

    updateTask = () =>{
        let date = new Date();
        let timestamp = date.getTime();
        let taskInfo=JSON.stringify(convertToRaw(this.state.taskInfo.getCurrentContent()));
        let task={
            taskCreationDate:this.props.task.taskCreationDate,
            taskLastModified:timestamp,
            taskTitle:this.state.taskTitle,
            taskID:this.props.task.taskID,
            taskInfo:taskInfo,
            tasksList:this.state.tasksList,
            taskStatus:this.state.taskStatus,
            owner:this.props.task.owner,
            taskLinks:this.state.taskLinks,
        }
        //console.log(task)
        this.props.updateTask(task,this.props.index)
    }

    renderDeleteIcon = () =>{
        let checkIconClass = "CheckIcon"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
        }
        return(
            <img src = {deleteIcon} alt="" onClick={(e)=>{ e.stopPropagation();this.props.deleteTask(this.props.index)}}
             className={classes[checkIconClass]}/>
        )
    }

    renderStatus = () => {
        let checkIconClass = "CheckIcon"
        let uncheckBoxClass = "UncheckBox"
        if (this.props.RTL){
          checkIconClass = "CheckIconRTL"
          uncheckBoxClass = "UncheckBoxRTL"
        }
        if (this.props.task.taskStatus==="open"){
            return(
                <div className={classes[uncheckBoxClass]}  onClick={(e)=>{ e.stopPropagation();this.updateStatus()}}/>  
               
            )
        }
        else{
            return(
                <img src={checkedBox} alt="" className={classes[checkIconClass]} onClick={(e)=>{ e.stopPropagation();this.updateStatus()}}/>  
            )
        }
    }

    renderTools = () =>{
        let toolsClass = "Tools"
        if (this.props.RTL){
            toolsClass = "ToolsRTL"
        }
        if (isMobile){
            return(
                <div className={classes[toolsClass]}>
                    {this.renderStatus()}
                </div>
            )
        }
        else{
            return(
                <div className={classes[toolsClass]}>
                    {this.renderDeleteIcon()}
                    {this.renderStatus()}
                </div>
            )
        }
        
    }

    renderTaskTopSection = () =>{
        let topSectionClass = "TopSection"
        if (this.props.openMode){
            topSectionClass = "TopSectionOpen"
        }
        return(
            <div className = {classes[topSectionClass]} onClick={this.topSectionClickedHandler} >
                {this.renderTitle()}
                <div className={classes.TaskTools}>
                    {this.renderTools()}
                </div>
            </div>
        )
    }

    renderTitle = () =>{
        if (this.props.openMode){
            return(
                <div className={classes.TitleSection}>
                    <input value={this.state.taskTitle} 
                    className={classes.TitleInput} 
                    placeholder={
                        this.props.systemText.plan.viewPlan.planning.tasks.titlePlaceholder[this.props.userLanguage]
                    }
                    onChange={this.updateTaskTitle}
                    onBlur={this.updateTask}
                    />
                </div>
            )
        }
        else{
            let completedTitleClass = ""
            if (this.state.taskStatus==="completed"){
                completedTitleClass = "CompletedTitle"
            }
            return(
                <div className={[classes.TitleSection,classes[completedTitleClass]].join(" ")}>
                    {this.props.task.taskTitle}
                </div>
            )
            
        }
    }

    
renderInfoEdit = () =>{
    let infoInputClass = "InfoInput"
    if (this.props.RTL){
        infoInputClass = "InfoInputRTL"
    }
    if (this.state.jsonString){
        return (
          <div className={classes.TextSection}>
            <Editor
             toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
           
            }}
            placeholder={this.props.systemText.plan.viewPlan.planning.tasks.notesPlaceholder[this.props.userLanguage]}
               editorClassName={classes[infoInputClass]}
               toolbarClassName={classes.ToolbarClassname}
                editorState={this.state.taskInfo}
                onEditorStateChange={this.updateTaskInfo}
                onBlur={this.updateTask}
              />
          </div>
        );}
  }

  renderTasksListEdit = () =>{
    let checkIconClass = "CheckIcon"
    let removeIconClass = "RemoveIcon"
    let uncheckBoxClass = "UncheckBox"
    let ownerClass = "Owner"
    if (this.props.RTL){
      checkIconClass = "CheckIconRTL"
      uncheckBoxClass = "UncheckBoxRTL"
      removeIconClass = "RemoveIconRTL"
      ownerClass = "OwnerRTL"
    }
     if (isMobile){
        return(
            <div className={classes.TasksList}>
                <div className={classes.TaskToolkit}>
                    <div className={classes.AddTaskSection} >
                    <TextButton
                            clicked={this.addSubTaskHandler}
                            text={this.props.systemText.plan.viewPlan.planning.tasks.addTask[this.props.userLanguage]}
                            style="Style1"
                        />
                        
                    </div>
                </div>
               
                 <SwipeableList>
               
                {this.state.tasksList.map((subTask,index)=>{
                  /*   if ((this.state.showOnlyMyTasks&&subTask.owner.userID===this.props.user.userID)||
                    !this.state.showOnlyMyTasks){ */
                        return(
                            <SwipeableListItem
                            key = {index}
                            swipeLeft={{
                              content: <div className={classes.DeleteSwipe}>{this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage]}</div>,
                              action: () =>this.setState({taskSwipeAction:"Delete",taskSwipeIndex:index})
                            }}
                            onSwipeEnd={this.performTaskSwipeAction}
                          >
                             <div className={classes.SubTask}>
                        <div className={classes.TaskBody}>
                        <div className={classes.SubTaskTools}>
                            <div className={classes[ownerClass]}>
                                <img src={subTask.owner.photoUrl} className={classes.OwnerImage} onClick={()=>{this.ownerHandler(index)}} alt="" />
                            </div>
                           <div className={classes.SubTaskToolkit}>
                                {subTask.status==="completed"?(
                                    <img src={checkedBox} alt="" className={classes[checkIconClass]} onClick={()=>{this.updateSubTaskStatus(index)}}/>
                                ):(
                                    <div className={classes[uncheckBoxClass]} onClick={()=>{this.updateSubTaskStatus(index)}}/>
                                )}
                            </div>
                        </div>
                            
                            <div  className={classes.TaskText}>
                                
                                 <TextareaAutosize  className={classes.TaskTextInput} value={subTask.text} 
                                   placeholder="..."
                                onChange={(e)=>{this.updateSubTaskText(e,index)}}
                                onBlur = {this.updateTask}
                                /> 
                            </div>
                        </div>
                       
                       
                    </div>
                             </SwipeableListItem>
                          )
                   /*  } */
                      
                   
                  })}
                  </SwipeableList>
            </div>
        )
    }
    else{
        return(
            <div className={classes.TasksList}>
                 <div className={classes.TaskToolkit}>
                 <div className={classes.AddTaskSection} >
                    <TextButton
                            clicked={this.addSubTaskHandler}
                            text={this.props.systemText.plan.viewPlan.planning.tasks.addTask[this.props.userLanguage]}
                            style="Style1"
                        />
                        
                    </div>
                 </div>
                {this.state.tasksList.map((subTask,index)=>{
                     /*  if ((this.state.showOnlyMyTasks&&task.owner.userID===this.props.user.userID)||
                      !this.state.showOnlyMyTasks){ */
                    return(
                    <div key={index} className={classes.SubTask}>
                        <div className={classes.TaskBody}>
                        <div className={classes.SubTaskTools}>
                            <div className={classes[ownerClass]}>
                                <img src={subTask.owner.photoUrl} className={classes.OwnerImage} onClick={()=>{this.ownerHandler(index)}} alt="" />
                            </div>
                           <div className={classes.SubTaskToolkit}>
                                <img src={removeIcon} alt="" className={classes[removeIconClass]} onClick={()=>{this.removeSubTaskHandler(index)}}/>
                                {subTask.status==="completed"?(
                                    <img src={checkedBox} alt="" className={classes[checkIconClass]} onClick={()=>{this.updateSubTaskStatus(index)}}/>
                                ):(
                                    <div className={classes[uncheckBoxClass]} onClick={()=>{this.updateSubTaskStatus(index)}}/>
                                )}
                            </div>
                        </div>
                            
                            <div  className={classes.TaskText}>
                                
                                 <TextareaAutosize  className={classes.TaskTextInput} value={subTask.text} 
                                 placeholder="..."
                                onChange={(e)=>{this.updateSubTaskText(e,index)}}
                                onBlur = {this.updateTask}
                                /> 
                            </div>
                        </div>
                       
                       
                    </div>)
                    /*   } */
              })}
              
            </div>
        )
    } 
 
}

    renderContent = () =>{
        return(
            <div className={classes.TaskContent}>
                {this.renderInfoEdit()}

                {this.renderTasksListEdit()}
            </div>
        )
    }

    renderBody = () =>{
        if (this.props.openMode){
            return(
                <div className={classes.OpenBody}>
                    {this.renderTaskTopSection()}
                    {this.renderContent()}
                </div>
            )
        }
        else{
            return(
                <div className={classes.ClosedBody}>
                    {this.renderTaskTopSection()}
                </div>
            )
        }
    }

    renderLinksModal = () =>{

    }

    renderManageOwner = () =>{
        if (this.state.showManageOwners){
            return(
                <TaskOwner
                    planEditors={this.props.planEditors}
                    systemText={this.props.systemText}
                    userLanguage={this.props.userLanguage}
                    updateOwner={this.updateTaskOwner}
                    discard={()=>{
                        this.setState({showManageOwners: false, showBackdrop: false});
                    }}

                    RTL = {this.props.RTL}
                />
            )
        }
    }
    render () {
        return(
            <div className={classes.Wrapper}>
                 {this.renderLinksModal()}
                 {this.renderManageOwner()}
                <TransparentBackdrop show={this.state.showBackdrop} clicked={this.discard} />
                <TransparentBackdrop show={this.props.openMode} clicked={()=>{this.props.taskOpenHandler(-1);}} />
                {this.renderBody()}
            </div>
        )
       
    }

}

export default PlanTask;
