import React, { Component } from "react";
import classes from "./PlacesWall.module.css"
import Place from "./Place";

 import ShouldPlaceRender from "../../../Components/Functions/ShouldPlaceRender"

import {isMobile} from 'react-device-detect';

class PlacesWall extends Component {
state={gettingData:false,currentList:[],count:0,
      pageNumber:0,
      placesList:[...this.props.placesList],
     
    }
componentWillMount=()=>{
  if (isMobile){
    this.setState({maxAddedPlaces:20});
  }
  else{
    this.setState({maxAddedPlaces:30});
  }
 
  

}

componentDidMount = () =>{
  this.getMoreData()
}

scrollToTop() {
  var frame = document.getElementById("PlacesWall");
  frame.scrollTo({
    top: 0,
    behavior: "smooth",
    duration:1000
    
  });
  frame.scrollTop=0;
}

getMoreData = async () => {
  return new Promise(async (resolve, reject) => {
  if (this.state.count === this.state.placesList.length) {
    this.setState({gettingData:false})
    return
  }
  let count=this.state.count;

  let addedObject=0;
  let maxAddedPlaces=0;
  if (isMobile){
    maxAddedPlaces=20;
  }
  else{
    maxAddedPlaces=30;
  }
  let currentList
 /*  if (this.props.selectedContentWall==='Discover'){
    currentList = this.state.currentList;
    while (count<this.state.placesList.length){
        let args = {
        place:this.state.placesList[count],
        discoverPlacesNameFilterTag:this.props.discoverPlacesNameFilterTag,
        discoverPlacesAdditionalTagFilterTag: this.props.discoverPlacesAdditionalTagFilterTag,
        inputLanguage:this.props.inputLanguage,
        placeCharacteristicsFilters:this.props.discoverPlacesCharacteristicsFilter,
        discoverPlacesDaysFilter: this.props.discoverPlacesDaysFilter
      } 
       await this.refs.shouldPlaceRender.shouldRender(args).then(result => {
        if (result){ 
          currentList.push(this.state.placesList[count])
          addedObject=addedObject+1;
         } 
        count=count+1;
       }); 
    }
    //sort first places that openned in the selected day and then these that are undefined
    if (JSON.stringify(this.props.discoverPlacesDaysFilter)!==JSON.stringify([0,0,0,0,0,0,0])){
      let sortArgs ={
        discoverPlacesDaysFilter: this.props.discoverPlacesDaysFilter,
        currentList: currentList
      }
      await this.refs.shouldPlaceRender.sortByOpenDay(sortArgs).then(result => {
        if (result){ 
          currentList=result
         } 
       }); 
    }
    this.props.updateFilteredList(currentList,maxAddedPlaces)
  }
 else{ */
  currentList = this.state.placesList
  this.props.updateFilteredList(currentList,maxAddedPlaces)
 /* } */
  await this.setState({ count:count,currentList:currentList,gettingData:false})
  });
}


filtersChangedHandler = async () =>{
  await this.setState({count:0,currentList:[], pageNumber:0,})
  while (!this.state.gettingData){
    await this.setState({gettingData:true})
    await setTimeout(() => {
      this.setState({count:0,currentList:[], pageNumber:0,})
     this.setState({placesList:[...this.props.placesList]})
      this.getMoreData();
   }, 300)
  }
}

componentDidUpdate= prevProps=>{
  if (JSON.stringify(this.props.placesList)!==JSON.stringify(prevProps.placesList)){
    this.filtersChangedHandler()
   }
  if (this.props.selectedContentWall==="Discover"){
    
      if ( this.props.discoverPlacesNameFilterTag.length!==prevProps.discoverPlacesNameFilterTag.length){
       this.filtersChangedHandler()
     }
     if ( this.props.discoverPlacesAdditionalTagFilterTag.length!==prevProps.discoverPlacesAdditionalTagFilterTag.length){
      this.filtersChangedHandler()
    }
     if (JSON.stringify(this.props.discoverPlacesCharacteristicsFilter)!==JSON.stringify(prevProps.discoverPlacesCharacteristicsFilter)){
      this.filtersChangedHandler()

     }
     if (JSON.stringify(this.props.discoverPlacesDaysFilter)!==JSON.stringify(prevProps.discoverPlacesDaysFilter)){
      this.filtersChangedHandler()
     }
    /* if (JSON.stringify(this.props.filteredPlacesAudience)!==JSON.stringify(prevProps.filteredPlacesAudience)){
       this.filtersChangedHandler()
     } */
  }
 
}
viewHandler= index =>{
  let place  = this.state.currentList[(this.state.pageNumber*this.state.maxAddedPlaces)+index];
  this.props.viewHandler(place);
}

addToPlan= index =>{
  let place  = this.state.currentList[index];
  this.props.addToPlan(place,"Place");
}

editPlaceHandler= index =>{
  let place  = this.state.currentList[index];
 // this.props.editPlaceHandler(place);
}

addPlaceToFavorite = index =>{
  let place  = this.state.currentList[index];
  this.props.addPlaceToFavorite(place,"Place");
}

updateFavoriteTags = index =>{
  let place  = this.state.currentList[index];
  this.props.updateFavoriteTags(place,"Place");
}

removePlaceFromFavorite = index =>{
  let place  = this.state.currentList[index];
  this.props.removePlaceFromFavorite(place,"Place");
}


shareExperience= index =>{
  let place  = this.state.currentList[index];
  this.props.shareExperience("Experience",place);
}

deletePlaceHandler = (index) =>{
  let place  = this.state.currentList[index];
  this.props.deletePlaceHandler(place);
}

nextPageHandler = async () =>{
  await this.setState({pageNumber:this.state.pageNumber+1})
  if (this.props.nextPageHandler!==undefined){
    this.props.nextPageHandler(this.state.currentList.slice(this.state.pageNumber*this.state.maxAddedPlaces,Math.min((this.state.pageNumber+1)*this.state.maxAddedPlaces,this.state.currentList.length)),this.state.pageNumber)
  }
  else{
    this.scrollToTop()
  }
 }

previousPageHandler = async () =>{
  await this.setState({pageNumber:this.state.pageNumber-1})
  if (this.props.previousPageHandler!==undefined){
    this.props.previousPageHandler(this.state.currentList.slice(this.state.pageNumber*this.state.maxAddedPlaces,Math.min((this.state.pageNumber+1)*this.state.maxAddedPlaces,this.state.currentList.length)),this.state.pageNumber)
  }
  else{
    this.scrollToTop()
  }
  }

  render(){
    let wallClass = "Wall"
    if (isMobile){
      wallClass = "MobileWall"
    }
    return(<div className={classes[wallClass]} id="PlacesWall">
      <ShouldPlaceRender
        ref={"shouldPlaceRender"}
      />
      {this.state.placesToShowCounter}
       {this.state.currentList && this.state.currentList.slice(this.state.pageNumber*this.state.maxAddedPlaces,Math.min((this.state.pageNumber+1)*this.state.maxAddedPlaces,this.state.currentList.length)).map((place, index) =>(
         <Place 
         placeID={place.placeID}
         placeName={place.placeName}
         imagePreview={place.imagePreview}
         placeLocation={place.placeLocation}
         contributors= {place.contributors}
         owner= {place.owner}
         placeStatus = {place.placeStatus}
         placeCategory = {place.placeCategory}
         placeNewCategory={place.placeNewCategory}
         placePermanentlyClosed={place.placePermanentlyClosed}
         placeTemporaryClosed={place.placeTemporaryClosed}
         placeCharacteristics = {place.placeCharacteristics}

         viewType={this.props.viewType}
         placeHolder={this.props.placeHolder}

         categoriesTranslator = {this.props.categoriesTranslator}
         locationsTranslator = {this.props.locationsTranslator}

         favoritePlacesIDs={this.props.favoritePlacesIDs}
         selectedContentWall={this.props.selectedContentWall}
         user={this.props.user} 
         loginHandler = {this.props.loginHandler}

         clickedMarkerIndex={this.props.clickedMarkerIndex}
         hoverHandler={this.props.hoverHandler}

         addPlaceToFavorite={this.addPlaceToFavorite}
         updateFavoriteTags={this.updateFavoriteTags}
         removePlaceFromFavorite={this.removePlaceFromFavorite}
         viewHandler={this.viewHandler}
         editPlaceHandler={this.editPlaceHandler}
         addToPlan={this.addToPlan}
         shareExperience={this.shareExperience}
         deletePlaceHandler = {this.deletePlaceHandler}
         
         userLanguage={this.props.userLanguage}
         systemText={this.props.systemText}
         loggedUser={this.props.loggedUser}
         RTL = {this.props.RTL}

         adminUser={this.props.adminUser}
         adminMode={this.props.adminMode}

         index={index}
         key={place.placeID}
         />
     ))}
     {this.state.gettingData&&this.state.count !== this.state.placesList.length?(
       <div className={classes.GettingData}>
          {this.props.systemText.misc.loadingMessage[this.props.userLanguage]}</div>
     ):null}
     {isMobile?(
        <div className={classes.MobileNextBackButtons}>
        {this.state.pageNumber>0?(
          <img src = {"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPageLeft.png?alt=media&token=7f909dd6-ae48-4259-9a7d-283e5db96f17"} 
          alt="" className={classes.MobilePagesButton}
          onClick={this.previousPageHandler}/> 
          
        ):(
          <img src = {"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPageLeftGrey.png?alt=media&token=588cae10-97fd-4de6-b2d8-18cf894c651e"}
           className={classes.MobilePagesButton} alt =""/> 
        )}
          {((this.state.pageNumber+1)*this.state.maxAddedPlaces)<this.state.currentList.length?(
           <img src = {"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPageRight.png?alt=media&token=fdbb5fc2-e4b2-42f2-94bd-75865c8b7cb7"} 
           className={classes.MobilePagesButton}
           onClick={this.nextPageHandler} alt =""/> 
        ):(
          <img src = {"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPageRightGrey.png?alt=media&token=1fb5b547-318e-45ae-b44f-acc1baa7594f"} 
          className={classes.MobilePagesButton} alt =""/> 
        )}
        </div>
     ):(
      <div className={classes.NextBackButtons}>
      {this.state.pageNumber>0?(
        <div className={classes.PagesButton} onClick={this.previousPageHandler}>
           {this.props.systemText.misc.navigation.pagesNavigation.previous[this.props.userLanguage]}
        </div>
      ):(
        <div className={classes.PagesButtonGreyed}>
         {this.props.systemText.misc.navigation.pagesNavigation.previous[this.props.userLanguage]} 
      </div>
      )}
       {((this.state.pageNumber+1)*this.state.maxAddedPlaces)<this.state.currentList.length?(
        <div className={classes.PagesButton} onClick={this.nextPageHandler}>
           {this.props.systemText.misc.navigation.pagesNavigation.next[this.props.userLanguage]}
        </div>
      ):(
        <div className={classes.PagesButtonGreyed}>
           {this.props.systemText.misc.navigation.pagesNavigation.next[this.props.userLanguage]}
      </div>
      )}
   </div>
     )}
    
    </div>)
  }
}
export default PlacesWall;