import React, { Component } from "react";
import classes from "./PlanTransport.module.css";


import { EditorState , convertFromRaw,} from 'draft-js';

let planeIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FPlaneIcon.png?alt=media&token=3574b5a9-3843-48fc-b0b1-ecf204c7bad8"
let ferryIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FBoatIcon.png?alt=media&token=6da8cb34-0e00-42cf-918d-09221bfe318b"
let trainIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FTrainIcon.png?alt=media&token=bba234c7-cb6b-4a34-a392-693d3b0a83ae"
let carIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FCarIcon.png?alt=media&token=52ac5d7f-9851-4107-835b-440dfe48b1dc"
let taxiIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FTaxiIcon.png?alt=media&token=07a325ac-4fad-46a1-a5a1-119cd038c1e3"
let busIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FBusIcon.png?alt=media&token=502a7516-6c4f-4f19-a791-3b57752cdcd9"
let otherIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FOtherIcon.png?alt=media&token=20d90554-a0d5-4558-8e11-149dc1b03058"

let arrowIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FArrowIcon.png?alt=media&token=b664f71d-dc5e-4e14-914c-505ed1794fa1"

class PlanTransport extends Component {
    state={
        transportLastModified: this.props.transportLastModified
    }


    componentDidMount=()=>{
        try {
          JSON.parse(this.props.transportNote);
          this.setState({transportNote: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.transportNote))),
            jsonString: true
            })
      } catch (e) {
     
      }
      }

      componentDidUpdate=(prevProps)=>{
        if (this.props.transportLastModified!==this.state.transportLastModified){
            try {
                JSON.parse(this.props.transportNote);
                this.setState({transportNote: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.transportNote))),
                  jsonString: true
                  })
            } catch (e) {
           
            }
        }
      }


      renderTransportIcon = () =>{
        switch(this.props.transportType){
            case "plane":{
                return(<img src={planeIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "ferry":{
                return(<img src={ferryIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "train":{
                return(<img src={trainIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "car":{
                return(<img src={carIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "taxi":{
                return(<img src={taxiIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "bus":{
                return(<img src={busIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            case "other":{
                return(<img src={otherIcon} alt="" className={classes.TransportIcon}/>)
                break;
            }
            default: return null;
        }
    }


      renderType = () =>{
          if (this.state.hovered){
            return(
                <div className={classes.TypeHover}>
                    {this.renderTransportIcon()}
                </div>
            )
          }
          else{
            return(
                <div className={classes.Type}>
                    {this.renderTransportIcon()}
                </div>
            )
          }
        
      }

      renderOrigin = () =>{
       /*  if (this.state.hovered){
          return(<div className={[classes.TicketPartHover,classes.LeftClass].join(" ")}>
              {this.props.origin.address}
          </div>)
        }
        else{ */
            let departure = new Date(this.props.departure)
            var hours = departure.getHours();
            var minutes = departure.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ampm;
            let date = departure.getDate()+"/"+(departure.getMonth()+1)+"/"+departure.getFullYear()+" - "+strTime
            return(<div className={[classes.TicketPart,classes.LeftClass].join(" ")}>
                {this.props.origin.address}
                <div className={classes.Time}>
                    {date}
                </div>
            </div>)
      /*   } */
      }
      renderDestination = () =>{
        /* if (this.state.hovered){
            return(<div className={classes.TicketPartHover}>
                {this.props.destination.address}
           </div>)
        }
        else{ */
            let arrival = new Date(this.props.arrival)
            var hours = arrival.getHours();
            var minutes = arrival.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ampm;
            let date = arrival.getDate()+"/"+(arrival.getMonth()+1)+"/"+arrival.getFullYear()+" - "+strTime
            return(<div className={[classes.TicketPart,classes.RightClass].join(" ")}>
               
               {this.props.destination.address}
                <div className={classes.Time}>
                    {date}
                </div>
            </div>)
     /*    } */
       
    }

      renderTicket = () =>{
          return(<div className={classes.Ticket}>
              {this.renderOrigin()}
              <img className={classes.Arrow} src={arrowIcon} alt=""/>
              {this.renderDestination()}
          </div>)
      }

    render () {
        return(<div>
             
            <div className={classes.Body} onClick={this.viewHandler}
            onMouseEnter={()=>{this.setState({hovered:true})}}
            onMouseLeave={()=>{this.setState({hovered:false})}}
            >
            <div className={classes.Content}>
                {this.renderType()}
                {this.renderTicket()}
            </div>
            </div>
        </div>)
    }

}

export default PlanTransport;