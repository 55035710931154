
import React, { Component } from "react";

class UpdateFunctions extends Component {

    updateFavoritePlacesSearchTags = placesList =>{
        return new Promise(async (resolve, reject) => {
           
          resolve ()
        });
    }


render(){
    return (
        <div></div>
      );
  }
    
  }
  export default UpdateFunctions;