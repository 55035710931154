import React, { Component } from "react";
import classes from "./TripPlanning.module.css";

import PlanDiscover from "./PlanDiscover"
import GoogleMap from "../../../Components/Complex/Map/GoogleMap"
import PlanCalendar from "../../../Components/Complex/Plan/PlanCalendar"
import PlanDaysScroller from "../../../Components/Complex/Plan/PlanDaysScroller"
import PlacesList from "../Places/PlacesList"
import PlanDayItem from "../../../Components/Complex/Plan/PlanDayItem"
import DiscoverFilter from "../../../Components/Complex/DiscoverFilters/DiscoverFilter"

import { isMobile } from "react-device-detect";

/* import ConfirmModal from "../../../Containers/UI/Modals/ConfirmationModal";
import PlanDaysCalendar from "./PlanDaysCalendar";
import PlanDaysScroller from "./PlanDaysScroller";

import backIcon from "../../../Visuals/ContentObjects/BackIcon.png"
import checkedIcon from "../../../Visuals/ContentObjects/CheckedIcon.png"
import uncheckedIcon from "../../../Visuals/ContentObjects/UncheckedIcon.png"
import mapIcon from "../../../Visuals/ContentObjects/LocationIcon.png";
import closeIcon from "../../../Visuals/ContentObjects/DeleteIconNoCircle.png"

import ViewPlanDiscover from "./ViewPlanDiscover";
import GoogleMap from "../../../Containers/Components/Map/GoogleMapNew";

 */
let currentLocationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCurrentLocation.png?alt=media&token=54ea82af-3b95-42bb-b624-d600768bc1fe"


class TripPlanning extends Component {
    state={
        calendarSelected:true,
        mapSelected:false,
        discoverSelected:true,
        mapVisible:false,
        boundariesFilteredDiscoverPlacesForMap:this.props.planMapPlaces,
        lockBounds:false,
        hoverIndex:-1,
        clickedMarkerPlaceID:-1,
        discoverTop:0,
        discoverPlacesShowOnMap:[],
        discoverPageNumber:0,
        additionalMarkerClicked:-1,
        tripAccommodations:this.props.planMapAccommodations,
        mobileDisplayTripPlaces:true,
      /*   mobilePlacesList: this.props.planMapPlaces, */
        mobilePreviewScrollLeft:0,
        mobilePlanPlaceSelected:null,
    }
    componentDidMount = () =>{ 
        if (this.props.planMapPlaces.length>0){
            this.setState({ focusCoordinates:this.props.planMapPlaces[0].placeLocation.coordinates,showMap:true})
        }
       
        else{
            this.setState({showMap:false})

        }  
        if (!this.props.isEditor)   {
            this.setState({calendarSelected:true,
            mapSelected:false,
            discoverSelected:false,})
            //this.props.onlyMapResultsHandler(true)
        }
        
        
    }

    componentDidUpdate = prevProps =>{
        if (JSON.stringify(prevProps.planMapPlaces)!==JSON.stringify(this.props.planMapPlaces)){
            if (this.props.planMapPlaces.length>0){
                this.setState({ focusCoordinates:this.props.planMapPlaces[0].placeLocation.coordinates,showMap:true,
                    boundariesFilteredDiscoverPlacesForMap:this.props.planMapPlaces})
            }
        }
        if (JSON.stringify(prevProps.planMapAccommodations)!==JSON.stringify(this.props.planMapAccommodations)){
            this.setState({tripAccommodations:this.props.planMapAccommodations})
        }
        else if (JSON.stringify(prevProps.filteredDiscoverPlaces)!==JSON.stringify(this.props.filteredDiscoverPlaces)){
            if(this.props.filteredDiscoverPlaces.length>0){
                this.setState({ focusCoordinates:this.props.filteredDiscoverPlaces[0].placeLocation.coordinates,showMap:true,
                    boundariesFilteredDiscoverPlacesForMap:this.props.filteredDiscoverPlaces})
                }
        }
            
        else if (JSON.stringify(prevProps.filteredFavoritePlaces)!==JSON.stringify(this.props.filteredFavoritePlaces)){
            if (this.props.filteredFavoritePlaces.length>0){
                this.setState({ focusCoordinates:this.props.filteredFavoritePlaces[0].placeLocation.coordinates,showMap:true,
                    boundariesFilteredDiscoverPlacesForMap:this.props.filteredFavoritePlaces})
            }
        }
        if (prevProps.selectedDiscoverSource!==this.props.selectedDiscoverSource){
            if (this.props.selectedDiscoverSource==="discover"){
                if(this.props.filteredDiscoverPlaces.length>0){
                  
                    this.setState({ focusCoordinates:this.props.filteredDiscoverPlaces[0].placeLocation.coordinates,showMap:true,
                        boundariesFilteredDiscoverPlacesForMap:this.props.filteredDiscoverPlaces})
                }
            }
            else{
                if (this.props.filteredFavoritePlaces.length>0){
                    this.setState({ focusCoordinates:this.props.filteredFavoritePlaces[0].placeLocation.coordinates,showMap:true,
                        boundariesFilteredDiscoverPlacesForMap:this.props.filteredFavoritePlaces})
                }
            }
        }
    }

 /*    updateMobilePlacesList = () =>{
        if (isMobile){
            if (!this.state.mobileDisplayTripPlaces){
                this.setState({mobilePlacesList:this.props.planMapPlaces,additionalMarkerIndex:-1})
            }
        }
    } */

    mobileMapPreviewScrollToggle = async () =>{
        if (!this.state.scrollStopped){ 
          this.setState({lastScroll: new Date()})
          setTimeout(e => {
            if (this.state.lastScroll.getTime() < (new Date()).getTime() - 200) {
              
              this.mobileMapPreviewScollHandler()
            }        
          }, 200); 
        }
      }
    
        mobileMapPreviewScollHandler= async () =>{
            if (!this.state.scrollStopped){ 
                var frame = document.getElementById("MobilePlanMapPlacesPreview");
            
                let screenWidth = window.innerWidth*0.85;
                let scrollLeft = Math.abs(frame.scrollLeft)
                let val =1
                if (scrollLeft===-1*frame.scrollLeft){
                val=-1
                }
                if (scrollLeft>this.state.mobilePreviewScrollLeft){
            
                //moving to the right
                if (Math.floor(scrollLeft/screenWidth) === Math.round(scrollLeft/screenWidth)){
                    //to the right part
                
                    frame.scrollTo({
                    left:val*screenWidth*Math.floor(scrollLeft/screenWidth),
                    behavior: "smooth",
                    duration:500
                    });
                this.mobileHoverHandler(Math.round(scrollLeft/screenWidth))
                }
                else{
                    
                    if ( Math.round(scrollLeft/screenWidth)<=19){
                    frame.scrollTo({
                        left:val*screenWidth* Math.round(scrollLeft/screenWidth),
                        behavior: "smooth",
                        duration:500
                    });
                    this.mobileHoverHandler(Math.round(scrollLeft/screenWidth))
                    }
                }
                }
                else{
                //moving to the left
            
                if (Math.floor(scrollLeft/screenWidth) === Math.round(scrollLeft/screenWidth)){
                    //to the right part
                    frame.scrollTo({
                        left:val*screenWidth*Math.floor(scrollLeft/screenWidth),
                        behavior: "smooth",
                        duration:500
                    });
                    this.mobileHoverHandler(Math.round(scrollLeft/screenWidth))
                }
                else{
                    frame.scrollTo({
                    left:val*screenWidth* Math.round(scrollLeft/screenWidth),
                    behavior: "smooth",
                    duration:500
                    });
                    this.mobileHoverHandler(Math.round(scrollLeft/screenWidth))
                }
                }
                await this.setState({scrollStopped:false,mobilePreviewScrollLeft:scrollLeft}) 
            }
          }

          mobileHoverHandler = index =>{
            if (this.state.discoverPlacesShowOnMap[index]!==undefined){
                if (this.state.additionalMarkerClicked!==-1){
                    this.setState({additionalMarkerClicked:this.state.discoverPlacesShowOnMap[index].placeID})
                 }
                  else if(!this.state.mobileDisplayTripPlaces){
                    this.setState({additionalMarkerClicked:this.state.discoverPlacesShowOnMap[index].placeID})
                  }
            } 
          }

    updateFilteredPlaces = async (mapBounds,zoom,mapCenter)=>{
        this.setState({mapBounds:{...mapBounds}})
        if (this.props.planMapPlaces.length===0||mapBounds===undefined) {
            
            return;
        }
        let boundariesFilteredDiscoverPlacesForMap=[];
        for (let i=0 ; i<this.props.planMapPlaces.length ; i++){
            let place = this.props.planMapPlaces[i];
            if (this.props.planMapPlaces[i].placeLocation.coordinates.lat>this.state.mapBounds.south&&
                this.props.planMapPlaces[i].placeLocation.coordinates.lat<this.state.mapBounds.north&&
                this.props.planMapPlaces[i].placeLocation.coordinates.lng>this.state.mapBounds.west&&
                this.props.planMapPlaces[i].placeLocation.coordinates.lng<this.state.mapBounds.east
                ){
                    boundariesFilteredDiscoverPlacesForMap.push(place)  
            }
        }
  
       let focusCoordinates;
       if (mapCenter!==undefined){
        focusCoordinates=mapCenter;
       }
       else if (boundariesFilteredDiscoverPlacesForMap.length>0){
        focusCoordinates = boundariesFilteredDiscoverPlacesForMap[0].placeLocation.coordinates
       } 
       else if(this.props.filteredDiscoverPlaces.length>0){
        focusCoordinates=this.props.filteredDiscoverPlaces[0].placeLocation.coordinates;
        }
        else if (
        this.props.filteredFavoritePlaces.length>0){
            focusCoordinates = this.props.filteredFavoritePlaces[0].placeLocation.coordinates;
        }
       else{
        focusCoordinates = this.props.planMapPlaces[0].placeLocation.coordinates
       }
        await this.setState({defaultZoon:zoom,focusCoordinates:focusCoordinates})
        await this.setState({mapBounds:{...mapBounds},lockBounds:true,
            boundariesFilteredDiscoverPlacesForMap:boundariesFilteredDiscoverPlacesForMap,mapReady:true}) 
        if (isMobile){
            if (!this.state.mapSelected){
                //only first time once there are map focus coordinates and bounds
                this.mapIconClickedHandler()
            }
           
            var scrollComponent = this;
            var frame = document.getElementById("MobilePlanMapPlacesPreview");
            frame.addEventListener("scroll", function(e) {
                scrollComponent.mobileMapPreviewScrollToggle();
            });  
        }
    }

    shouldUpdateFilteredPlaces = () =>{
        this.updateFilteredPlaces(this.state.mapBounds)
    }

    mapBoundsHandler = async (mapBounds,zoom,mapCenter) =>{
        if (mapBounds!==undefined&&JSON.stringify(mapBounds)!==JSON.stringify(this.state.mapBounds)){
            if (this.state.mapBounds!==undefined){
              this.updateFilteredPlaces(mapBounds,zoom,mapCenter)
              this.props.mapBoundsHandler(mapBounds)
              //this.updateMobilePlacesList()
            }
            else{
              this.setState({mapBounds:mapBounds})
            }
            this.setState({mapBounds:mapBounds,mapCenter:mapCenter})
        }  
        
        
    }


    hoverDiscoverHandler = async (index,place) =>{
        if (this.state.mapSelected){
            this.props.hoverDiscoverHandler(index,place)
        }
        
    }

    updateHover = index =>{
        this.setState({hoverIndex:index,discoverHover:true,additionalMarkerClicked:-1})
    }

    mobileMarkerClicked = (index) =>{
        var frame = document.getElementById("MobilePlanMapPlacesPreview");
        let screenWidth = window.innerWidth*0.85
        let scrollLeft = Math.abs(frame.scrollLeft)
        let val =1
        if (scrollLeft===-1*frame.scrollLeft&&scrollLeft!==0){
          val=-1
        }
        frame.scrollTo({
          left:val*screenWidth*index,
          behavior: "smooth",
          duration:500
        });
    }

    markerClicked = (placeID,index) =>{
        this.setState({clickedMarkerPlaceID:placeID,hoverIndex:placeID,mobileDisplayTripPlaces:true})
        this.props.writeAcitivityLog({
            activityKey:"TRIP_PLANNING_DISCOVER_MAP_MARKER_CLICKED",
            userScreen:"VIEW_TRIP"
        })
        if (isMobile){
            this.setState({mobilePlanPlaceSelected:this.props.planMapPlaces[index]})
        }

      }
  
      mapClickedHandler = () =>{
        this.setState({clickedMarkerPlaceID:-1,hoverIndex:-1,additionalMarkerClicked:-1,mobileDisplayTripPlaces:true,mobilePlanPlaceSelected:null})
        this.props.writeAcitivityLog({
            activityKey:"TRIP_PLANNING_DISCOVER_MAP_CLICKED",
            userScreen:"VIEW_TRIP"
          })
      }

    mapIconClickedHandler = () =>{
        this.setState({mapSelected:true,mapVisible:true})
        if (this.props.selectedDiscoverSource==="discover"){
            if(this.props.filteredDiscoverPlaces[0]!=undefined&&this.props.onlyMapAreaResults){
                this.setState({ focusCoordinates:this.props.filteredDiscoverPlaces[0].placeLocation.coordinates,showMap:true,
                    boundariesFilteredDiscoverPlacesForMap:this.props.filteredDiscoverPlaces})
            }
        }
        else{
            if (this.props.filteredFavoritePlaces[0]!=undefined&&this.props.onlyMapAreaResults){
                this.setState({ focusCoordinates:this.props.filteredFavoritePlaces[0].placeLocation.coordinates,showMap:true,
                    boundariesFilteredDiscoverPlacesForMap:this.props.filteredFavoritePlaces})
            }
           
        }
    }

    searchAroundHandler = async () =>{
        await navigator.geolocation.getCurrentPosition(position => {
          this.setState({
            currentLocation: 
              { lat: position.coords.latitude, lng: position.coords.longitude},
          });
           let mapBounds = {
            south:position.coords.latitude-0.02,
            north:position.coords.latitude+0.02,
            west:position.coords.longitude-0.02,
            east:position.coords.longitude+0.02,
            } 
            this.refs.map.updateBounds(mapBounds)
           /*  setTimeout(e => {
              this.setState({showCurrentLocation:true })  
              this.mapBoundariesPlacesHandler(mapBounds,this.state.mapCenter)
              }, 0);      
              this.props.writeAcitivityLog({
                activityKey:"DISCOVER_PLACES_SHOWNEARBY_BUTTON",
                success:true,
                userScreen:this.props.selectedContentWall.toUpperCase()
              }) */
        }, error=>{
          if (error.code===1){
           /*  this.props.writeAcitivityLog({
              activityKey:"DISCOVER_PLACES_SHOWNEARBY_BUTTON",
              success:false,
              userScreen:this.props.selectedContentWall.toUpperCase()
            })
            alert("Geolocation permission has been blocked as the user has dismissed the permission prompt several times. This can be reset in Page Info which can be accessed by clicking the info icon left to the URL")
          */ }
        }, {
          timeout: 1000,
          maximumAge: 10000,
          enableHighAccuracy: true
        });
       
      }

    closeMapHandler = () =>{
        this.setState({mapSelected:false});
        this.props.unselectMap()
        
    }

    showDiscoverPlacesOnMapHandler = (value,placesList) =>{
        this.setState({showDiscoverPlacesOnMap:value})
        if (value){
            let discoverPlacesShowOnMap = placesList.filter(place=>!this.props.planPlacesIDs.includes(place.placeID))
            this.setState({discoverPlacesShowOnMap:discoverPlacesShowOnMap})
        }
        else{
            this.setState({discoverPlacesShowOnMap:[],additionalMarkerClicked:-1})
        }
        if (this.state.focusCoordinates===undefined&&value&&placesList.length>0){
            this.setState({focusCoordinates:this.props.filteredDiscoverPlaces[0].placeLocation.coordinates,showMap:true,
                boundariesFilteredDiscoverPlacesForMap:this.props.filteredDiscoverPlaces});
        }
       
    }

    additionalMarkerClickedHandler  = async (placeID,index) =>{
        await this.setState({additionalMarkerClicked:placeID,mobileDisplayTripPlaces:false,mobilePlanPlaceSelected:null,hoverIndex:-1})
        this.props.writeAcitivityLog({
            activityKey:"TRIP_PLANNING_DISCOVER_MAP_MARKER_CLICKED",
            userScreen:"VIEW_TRIP"
          })
        if (isMobile){
            this.mobileMarkerClicked(index)
        }
      }

      removeFromPlanHandler = index =>{

      }

      renderPlanDiscoverSection = () =>{
          if (this.props.isEditor){
              return(
                <div className={classes.DiscoverSection}>
                <div className={classes.DiscoverResults} id="planDiscoverWall">
                    <PlanDiscover
                         ref="viewPlanDiscover"
                         discardMobileDiscover = {this.props.discardMobileDiscover}
                         filteredDiscoverPlaces = {this.props.filteredDiscoverPlaces.filter(place=>!this.props.planPlacesIDs.includes(place.placeID))}
                         discoverPlacesLocationFilterTags = {this.props.discoverPlacesLocationFilterTags}
                         discoverPlacesCategoriesFilterTags = {this.props.discoverPlacesCategoriesFilterTags}
                         discoverPlacesSelectedMainCategory = {this.props.discoverPlacesSelectedMainCategory}
                         discoverPlacesNameFilterTag = {this.props.discoverPlacesNameFilterTag}
                         discoverPlacesAdditionalTagFilterTag = {this.props.discoverPlacesAdditionalTagFilterTag}
                         discoverPlacesCharacteristicsFilter={JSON.parse(JSON.stringify(this.props.discoverPlacesCharacteristicsFilter))}
                         discoverPlacesDaysFilter = {this.props.discoverPlacesDaysFilter}
                         suggestedLocationsFilters = {this.props.suggestedLocationsFilters}
                         discoverPlacesAvailableCategories = {this.props.discoverPlacesAvailableCategories}
                         discoverPlacesAvailableMainCategories = {this.props.discoverPlacesAvailableMainCategories}
                         countrySelected = {this.props.countrySelected}
                         discoverPlacesLocationsSearchTags = {this.props.discoverPlacesLocationsSearchTags}
                         discoverPlacesNameSearchTags = {this.props.discoverPlacesNameSearchTags}
                         discoverPlacesAdditionalTagsSearchTags = {this.props.discoverPlacesAdditionalTagsSearchTags}

                         filteredFavoritePlaces = {this.props.filteredFavoritePlaces.filter(place=>!this.props.planPlacesIDs.includes(place.placeID))}
                         favoritePlacesSearchTags = {this.props.favoritePlacesSearchTags}
                         favoritePlacesFilterTags = {this.props.favoritePlacesFilterTags}
                         favoriteSearchHandler = {this.props.favoriteSearchHandler}
                         favoriteRemoveTagHandler = {this.props.favoriteRemoveTagHandler}
                         
                         locationsTranslator = {this.props.locationsTranslator}
                         categoriesTranslator = {this.props.categoriesTranslator}
                         locationsTree = {this.props.locationsTree}
                         countriesToExplore = {this.props.countriesToExplore}
                         placesCharacteristicsData= {this.props.placesCharacteristicsData}

                         additionalMarkerClicked = {isMobile? -1 : this.state.additionalMarkerClicked}

                         planDays = {this.props.planDays}
                         planPlacesIDs = {this.props.planPlacesIDs}
                       
                         wideList = {!this.state.calendarSelected && !this.state.mapSelected}
                         mapSelected = {this.state.mapSelected}
                         onlyMapResultsHandler = {(value)=>{this.props.onlyMapResultsHandler(value,this.state.mapBounds)}}
                         showDiscoverPlacesOnMapHandler = {this.showDiscoverPlacesOnMapHandler}
                         onlyMapAreaResults={this.props.onlyMapAreaResults}
                         showDiscoverPlacesOnMap = {this.state.showDiscoverPlacesOnMap}
                         
                         removePlaceNameFilterTag = {this.props.removePlaceNameFilterTag}
                         removePlaceAdditionalTagFilterTag = {this.props.removePlaceAdditionalTagFilterTag}
                         searchDiscoverLocationHandler = {this.props.searchDiscoverLocationHandler}
                         removePlaceLocationFilterTag = {this.props.removePlaceLocationFilterTag}
                         addPlaceLocationTagHandler = {this.props.addPlaceLocationTagHandler}
                         discoverPlacesMainCategoryHandler= {this.props.discoverPlacesMainCategoryHandler}
                         addPlaceCategoryTagHandler = {this.props.addPlaceCategoryTagHandler}
                         removePlaceCategoryFilterTag = {this.props.removePlaceCategoryFilterTag}
                         updatePlaceCharacteristicsFilters = {this.props.updatePlaceCharacteristicsFilters}
                         updateDiscoverPlacesSelectedDay = {this.props.updateDiscoverPlacesSelectedDay}
                         
                         selectedDiscoverSource = {this.props.selectedDiscoverSource}
                         updateDiscoverSource = {this.props.updateDiscoverSource}
                         loadFavoritePlacesForPlanning = {this.props.loadFavoritePlacesForPlanning}
                         updateSelectedCountry = {this.props.updateSelectedCountry}

                         noSearchResultsHandler={this.props.noSearchResultsHandler}
                         noResultsFavoritePlaces = {this.props.noResultsFavoritePlaces}

                         hoverDiscover = {this.hoverDiscoverHandler}
                         addPlaceToPlan={this.props.addPlaceToPlan}
                       

                         viewHandler={this.props.viewPlaceHandler}

                         writeAcitivityLog = {this.props.writeAcitivityLog}
                         showFilters = {this.props.showFilters}
                      
                         RTL = {this.props.RTL}
                         userLanguage={this.props.userLanguage}
                         systemText={this.props.systemText}
                         inputLanguage = {this.props.inputLanguage}
                         detectInputLanguage = {this.props.detectInputLanguage}
                    />
                 </div>     
             </div>
              )
          }
      }

    renderSelectedContent = () =>{
        if (isMobile){
            return(<div className={classes.SelectedContent}>
                 {this.renderMap()} 
                 {this.renderPlanDiscoverSection()}
            </div>)
        }
        else{
            return(<div className={classes.SelectedContent}>
                {this.renderPlanDiscoverSection()}
                {this.renderPlanDaysContent()}
                 {this.renderMap()} 
            </div>)
        }
       
    }

    renderPlanDaysContent = () =>{
        if (!isMobile){
            let planCalendarClass = "PlanDaysCalendarWide"
            if (this.state.mapSelected){
                planCalendarClass = "PlanDaysCalendar"
            }
            if (this.props.isEditor){
                if (this.state.mapSelected){
                    return(
                        <div className={classes.PlanDaysScroller}>
                            {this.renderPlanDaysScroller()}
                     </div>
                    )
                }
                else{
                    return(
                        <div className={classes.PlanDaysCalendar}>
                            {this.renderPlanCalendar()}
                        </div>
                    )
                }
            }
            else{
                return(
                    <div className={classes[planCalendarClass]}>
                        {this.renderPlanCalendar()}
                    </div>
                )
            }
        }
       
    }

    renderPlanCalendar = () =>{
        return(
            <PlanCalendar
            ongoingDay = {this.props.ongoingDay}
            planLastModified = {this.props.planLastModified}
            isEditor={this.props.isEditor}
            editorsNumber={this.props.planEditors.length}
            planDays={this.props.planDays}
            viewHandler={this.props.viewHandler}
            hoverHandler={()=>{return null}}

            updateColor={this.props.updateColor}
            updatePlaceTime = {this.props.updatePlaceTime}
            updateDayOrder = {this.props.updateDayOrder}
            moveDayHandler={this.props.moveDayHandler}
            removeFromPlan={this.props.removeFromPlan}
            
            locationsTranslator = {this.props.locationsTranslator}
            categoriesTranslator = {this.props.categoriesTranslator}

            newNoteHandler = {this.props.newNoteHandler}
            openModeNoteId = {this.props.openModeNoteId}
            newNoteID = {this.props.newNoteID}
            editNoteID = {this.props.editNoteID}
            noteOpenHandler = {this.props.noteOpenHandler}
            updateNote = {this.props.updateNote}

            newTransportHandler={this.props.newTransportHandler}

            addAccommodationHandler = {this.props.addAccommodationHandler}

            voteHandler={this.props.voteHandler}

            writeAcitivityLog = {this.props.writeAcitivityLog}

            showSuggestedTrips = {this.props.showSuggestedTrips}
            
            user={this.props.user}
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
       />
        )
    }

    renderPlanDaysScroller = () =>{
        return(
            <PlanDaysScroller
            planLastModified = {this.props.planLastModified}
            height = {"80vh"}
            ongoingDay={this.props.ongoingDay}
            isEditor={this.props.isEditor}
            editorsNumber={this.props.planEditors.length}
            planDays={this.props.planDays}
            viewHandler={this.props.viewHandler}
            showDaySelection = {this.props.showDaySelection}
            daySelectHandler={this.props.daySelectHandler}
            hoverDayIndex = {this.state.hoverDayIndex}

            hoverHandler={(index,placeID,dayIndex)=>{if (!this.props.planMapAccommodations.map(place=>{return place.placeID}).includes(placeID)){this.setState({hoverIndex:placeID,clickedMarkerPlaceID:placeID,discoverHover:false,hoverDayIndex:dayIndex})}}}
            updateColor={this.props.updateColor}
            updatePlaceTime = {this.props.updatePlaceTime}
            updateDayOrder = {this.props.updateDayOrder}
            moveDayHandler={this.props.moveDayHandler}
            removeFromPlan={this.props.removeFromPlan}
            clickedMarkerPlaceID={this.state.clickedMarkerPlaceID} 
         
            locationsTranslator = {this.props.locationsTranslator}
            categoriesTranslator = {this.props.categoriesTranslator}

            newNoteHandler = {this.props.newNoteHandler}
            openModeNoteId = {this.props.openModeNoteId}
            newNoteID = {this.props.newNoteID}
            editNoteID = {this.props.editNoteID}
            noteOpenHandler = {this.props.noteOpenHandler}
            updateNote = {this.props.updateNote}

            newTransportHandler={this.props.newTransportHandler}

            addAccommodationHandler = {this.props.addAccommodationHandler}

            voteHandler={this.props.voteHandler}

            writeAcitivityLog = {this.props.writeAcitivityLog}

            showSuggestedTrips = {this.props.showSuggestedTrips}
            user={this.props.user}
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
        />
        )
    }

    renderMap = () =>{
    /*     if (!isMobile){ */
            let height="83vh"
            if (isMobile){
                height="100%"
            }
            if (this.state.mapVisible&& this.state.showMap&&this.state.focusCoordinates!==undefined||(isMobile&&this.state.focusCoordinates!==undefined)){
                let showMapDesktopClass =""
                if ((!this.state.mapSelected&&!isMobile)||(isMobile&&(this.props.planMapPlaces.length===0||this.state.discoverPlacesShowOnMap.length)) ){
                    showMapDesktopClass="HideDesktopMap"
                }
                let hidePlacesWall = ""
                if (this.state.mobileDisplayTripPlaces){
                    hidePlacesWall="HidePlacesWall"
                }
                return(
                    <div className={[classes.MapSection,classes[showMapDesktopClass]].join(" ")} 
                    onMouseUp={()=>{this.refs.map.handleCenterChanged()}} onTouchEnd={()=>{this.refs.map.handleCenterChanged()}}>
                    {!isMobile?(
                        <img className={classes.CloseMapView} src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"} 
                        alt="" onClick={(e)=>{e.stopPropagation();this.closeMapHandler()}}/>
                    ):null}
                      <img src={currentLocationIcon}  onClick={this.searchAroundHandler} alt="" className={classes.CurrentLocationIcon} />
                    <GoogleMap
                     mapCenter={this.state.mapCenter}
                    onlyMapAreaResults={this.props.onlyMapAreaResults}
                    ref="map"
                    focusCoordinates={this.state.focusCoordinates}
                    boundsPlaces={this.state.boundariesFilteredDiscoverPlacesForMap}
                    additionalMarkersList = {this.state.discoverPlacesShowOnMap}
                    markers={this.props.planMapPlaces}
                    board={"Plan"}
                    ideasOnMapCount ={this.props.planMapIdeasCount}
                    showOrder={true}
                    lockBounds={this.state.lockBounds}
                    isClickable={true}
                    tripAccommodations = {this.state.tripAccommodations}
                    markerClicked={this.markerClicked}
                    accommodationIsClickable = {true}
                    mapClicked={this.mapClickedHandler}
                    additionalMarkerClicked = {this.additionalMarkerClickedHandler}
                    additionalMarkerIndex = {this.state.additionalMarkerClicked}
                    mapBoundsHandler={this.mapBoundsHandler}
                    height={height}
                    defaultZoom={this.state.zoom}
                    mapBounds={this.state.mapBounds}
                    hoverIndex={this.state.hoverIndex}
                    discoverHover = {this.state.discoverHover}
                    pinnedPlacesIDs={this.state.pinnedPlacesIDs}
                    currentLocation={this.state.currentLocation}
                    showCurrentLocation={this.state.showCurrentLocation}
                    discoverSearchLanguage={this.props.discoverSearchLanguage}
                    discoverPlacesNameFilterTag={this.props.discoverPlacesNameFilterTag}
                />
                {isMobile?(
                    <div className={[classes.PlacesWall,classes[hidePlacesWall]].join(" ")}  id="MobilePlanMapPlacesPreview"
                    onScroll={()=>{return null}}
                    onTouchEnd={this.mobileMapPreviewScollHandler}
                    onTouchStart={()=>{return null}}>
                        <PlacesList
                          userLanguage={this.props.userLanguage}
                          systemText = {this.props.systemText}
                          RTL = {this.props.RTL}
                          user={this.props.user}
              
                          categoriesTranslator = {this.props.categoriesTranslator}
                          locationsTranslator = {this.props.locationsTranslator}
              
                            placesList={this.state.mobileDisplayTripPlaces? []: this.state.discoverPlacesShowOnMap}
                            favoritePlacesIDs={[]}
                            viewType={"Map"}
                            planMap = {true}
                            placeInPlan={this.state.mobileDisplayTripPlaces}
                            selectedContentWall={this.props.selectedContentWall}
                            viewHandler={(index)=>{this.props.viewPlaceHandler(this.state.discoverPlacesShowOnMap[index])}}
                            addPlaceToPlan={(index)=>{this.props.addPlaceToPlan(this.state.discoverPlacesShowOnMap[index])}}
                            removeFromPlanHandler = {this.removeFromPlanHandler}

                            dayNames = {this.props.planDays.map(day=>{return day.name})}
                            planPlacesIDs = {this.props.planPlacesIDs}
                            
                
                            hoverHandler={(index,placeID)=>{this.setState({hoverIndex:placeID,clickedMarkerIndex:placeID,})}}
                            clickedMarkerIndex={this.state.clickedMarkerIndex}
                        /> 
                    </div>
                ):null}
                {isMobile&&this.state.mobileDisplayTripPlaces&&this.state.mobilePlanPlaceSelected!==null?(
                    <div className={classes.PlanPlace}>
                    <PlanDayItem
                        isEditor={this.props.isEditor}
                        editorsNumber={this.props.editorsNumber}
                        updateColor={(colorNumber)=>{
                            const itemIndex = this.props.planDays[this.props.mobileDaySelectedIndex].dayItems.findIndex(item => {
                                return item.type==="place"&&item.item.placeID === this.state.hoverIndex;
                              });
                              if (itemIndex!==-1){
                                this.props.updateColor(colorNumber,this.props.mobileDaySelectedIndex,itemIndex)
                              }
                           } }
                        updatePlaceTime={
                            (time)=>{
                                const itemIndex = this.props.planDays[this.props.mobileDaySelectedIndex].dayItems.findIndex(item => {
                                    return item.type==="place"&&item.item.placeID === this.state.hoverIndex;
                                  });
                                  if (itemIndex!==-1){
                                    this.props.updatePlaceTime(time,this.props.mobileDaySelectedIndex,itemIndex)
                                  }
                            }}
                           
                        object={{item:this.state.mobilePlanPlaceSelected,type:"place"}}
                        viewHandler={
                            ()=>{
                                const itemIndex = this.props.planDays[this.props.mobileDaySelectedIndex].dayItems.findIndex(item => {
                                    return item.type==="place"&&item.item.placeID === this.state.hoverIndex;
                                  });
                                  if (itemIndex!==-1){
                                    this.props.viewHandler(this.props.mobileDaySelectedIndex,itemIndex)
                                  }
                            }}
                    
                        clickedMarkerPlaceID={this.props.clickedMarkerPlaceID}
                        removeFromPlan={
                            ()=>{
                                const itemIndex = this.props.planDays[this.props.mobileDaySelectedIndex].dayItems.findIndex(item => {
                                    return item.type==="place"&&item.item.placeID === this.state.hoverIndex;
                                  });
                                  if (itemIndex!==-1){
                                    this.props.removeFromPlan(this.props.mobileDaySelectedIndex,itemIndex)
                                    this.setState({mobilePlanPlaceSelected:null})
                                  }
                            }}
                        voteHandler={this.props.voteHandler}
                       moveDayHandler={
                            ()=>{
                                const itemIndex = this.props.planDays[this.props.mobileDaySelectedIndex].dayItems.findIndex(item => {
                                    return item.type==="place"&&item.item.placeID === this.state.hoverIndex;
                                });
                                if (itemIndex!==-1){
                                    this.props.moveDayHandler(this.props.mobileDaySelectedIndex,itemIndex,-1,"place")
                                }
                            }}   

                        locationsTranslator = {this.props.locationsTranslator}
                        categoriesTranslator = {this.props.categoriesTranslator}
                        dayNames = {this.props.planDays.map(day=>{return day.name})}

                        openModeNoteId = {this.props.openModeNoteId}
                        newNoteID = {this.props.newNoteID}
                        editNoteID = {this.props.editNoteID}
                        writeAcitivityLog = {this.props.writeAcitivityLog}
                        
                        RTL = {this.props.RTL}
                        user={this.props.user}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}

                    />
                    </div>
                ):null}
                </div>
                )
            }
      /*   } */
    }

    renderDays = () =>{
        if (isMobile &&!this.props.hideMobileTopbar){
            let daysClass = "Days"
            if (this.props.RTL){
            daysClass = "DaysRTL"
            }
            return(
              <div className={classes[daysClass]}>
                  {this.props.planDays.map((day,index)=>{
                      let dayClass = "Day"
                      if (index===this.props.mobileDaySelectedIndex){
                          dayClass = "DaySelected"
                      }
                      let name = day.name;
                      if (name==="Interesting"){
                          name=this.props.systemText.plan.viewPlan.planning.interestigMobile[this.props.userLanguage];
                      }
                      else{
                          name= this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage] +" "+ day.name.substr(day.name.indexOf(' ')+1);
                      }
                          
                      return(
                          <div key={index} className={classes[dayClass]} onClick={()=>{this.props.mobileDaySelectHandler(index)}}>
                          {name}
                      </div>  )
                     
                  })}
              </div>
          )
        }
      }

      renderFiltersModal = () =>{
        if (this.props.selectedDiscoverSource==="discover"&&this.props.countrySelected){
            let modalClass = ""
            if (this.props.hideMobileTopbar){
              modalClass = "ModalLTR"
              if (this.props.RTL&&!isMobile){
                modalClass = "ModalRTL"
              }
            }
            else{
              modalClass = "HideModalLTR"
              if (this.props.RTL&&!isMobile){
                modalClass = "HideModalRTL"
              }
            }
            return(
              <DiscoverFilter
              modalClass = {modalClass}
                systemText = {this.props.systemText}
                userLanguage = {this.props.userLanguage}
                RTL = {this.props.RTL}
                detectInputLanguage = {this.props.detectInputLanguage}
                inputLanguage = {this.props.inputLanguage}
                selectedContentType={"Places"}

                categoriesTranslator = {this.props.categoriesTranslator}
                placesCharacteristicsData = {this.props.placesCharacteristicsData}

                discoverPlacesDaysFilter = {this.props.discoverPlacesDaysFilter}
                discoverPlacesCharacteristicsFilter = {this.props.discoverPlacesCharacteristicsFilter}
                locationFilterTags = {this.props.discoverPlacesLocationFilterTags}
                categoriesFilterTags = {this.props.discoverPlacesCategoriesFilterTags}
                namesFilterTag = {this.props.discoverPlacesNameFilterTag}
                additionalTagsFilterTag = {this.props.discoverPlacesAdditionalTagFilterTag}

                suggestedLocationsFilters = {this.props.suggestedLocationsFilters}
                discoverPlacesAvailableCategories = {this.props.discoverPlacesAvailableCategories}
                discoverPlacesAvailableMainCategories = {this.props.discoverPlacesAvailableMainCategories}
                discoverPlacesSelectedMainCategory = {this.props.discoverPlacesSelectedMainCategory}

                closeFilters = {()=>{
                this.props.showFilters(false)
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_PLANNING_DISCOVER_FILTER_CLOSE",
                    userScreen:"VIEW_TRIP"
                  })
                }}

                writeAcitivityLog = {this.props.writeAcitivityLog}

                hideLocationSuggestions = {this.props.onlyMapAreaResults}

                removePlaceLocationFilterTag = {this.props.removePlaceLocationFilterTag}
                addPlaceLocationTagHandler = {this.props.addPlaceLocationTagHandler}
                discoverPlacesMainCategoryHandler = {this.props.discoverPlacesMainCategoryHandler}
                addPlaceCategoryTagHandler = {this.props.addPlaceCategoryTagHandler}
                removePlaceCategoryFilterTag = {this.props.removePlaceCategoryFilterTag}
                removePlaceNameFilterTag = {this.props.removePlaceNameFilterTag}
                removePlaceAdditionalTagFilterTag = {this.props.removePlaceAdditionalTagFilterTag}
                updatePlaceCharacteristicsFilters = {this.props.updatePlaceCharacteristicsFilters}
                updateSelectedDay = {this.props.updateDiscoverPlacesSelectedDay}
              />
            )
        }
      }
  

    render(){
        return(<div className={classes.Body}>
            {this.renderFiltersModal()}
            {this.renderDays()}
            {this.renderSelectedContent()}
        </div>)
    }
}

export default TripPlanning;