import React from "react";
import classes from "./MainCategory.module.css";

let sightseeingIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSightSeeingIcon.png?alt=media&token=3b7f3202-493e-4f2e-981f-47944e78c5a4";
let foodIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFoodIcon.png?alt=media&token=f9f81121-4c3e-4290-8b3e-94fee05d149a";
let natureIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FNatureIcon.png?alt=media&token=69ef82b9-4af1-44e2-b713-a240fba4022e";
let sportIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSportIcon.png?alt=media&token=3d936de1-0f2b-40de-9313-69b90977602d";
let accomodationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIcon.png?alt=media&token=3f3fbed6-2d65-4896-a2d0-6036f7e1ac5b";
let activitiesIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FActivityIcon.png?alt=media&token=e46ab679-3b01-4172-9efc-1f528c9f76a7";

let sightseeingIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSightSeeingIconGrey.png?alt=media&token=5952d358-3708-42b4-a1c3-21031c245893";
let foodIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFoodIconGrey.png?alt=media&token=0ef521c5-6a9b-491f-a68b-66f44c89c6eb";
let natureIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FNatureIconGrey.png?alt=media&token=c2a615c0-e0dd-4d1e-86b1-33db39da60b8";
let sportIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSportIconGrey.png?alt=media&token=cbc7f4df-a7e0-420a-aa2d-23e0de538145";
let accomodationIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIconGrey.png?alt=media&token=2c1c88f1-8194-41c1-bed4-168f6069c4f2";
let activitiesIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FActivityIconGrey.png?alt=media&token=5ff3c1b6-f0ed-47f2-bbb6-02cdada7045e";

let sightseeingIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSightSeeingIconFade.png?alt=media&token=fa6188b3-0595-4c6f-a047-f05aba78abcc";
let foodIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFoodIconFade.png?alt=media&token=5bff40ba-7c83-4b73-b133-9efb0c549b48";
let natureIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FNatureIconFade.png?alt=media&token=61ee8293-aff7-4459-9fe9-07cca7011036";
let sportIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSportIconFade.png?alt=media&token=324d4c55-fa65-438c-b2fb-d5bcbb5b43cc";
let accomodationIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIconFade.png?alt=media&token=a584a223-3eec-4af3-a45e-09f20ce2aea7";
let activitiesIconFade = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FActivityIconFade.png?alt=media&token=772c5de0-68d8-4aca-b31d-2364c1ba3622";


const mainCategory = props => {

  const renderBody = () =>{
    let icon;
    let text = "";
    switch(props.index){
        case 0:{
            text=props.systemText.misc.mainCategories.cultural[props.userLanguage]
            if (props.selected){
                icon = sightseeingIcon
            }
            else if(props.disabled){
                icon = sightseeingIconFade
            }
            else{
                icon = sightseeingIconGrey
            }
            break;
        }
        case 1:{
            text=props.systemText.misc.mainCategories.nature[props.userLanguage]
            if (props.selected){
                icon = natureIcon
            }
            else if(props.disabled){
                icon = natureIconFade
            }
            else{
                icon = natureIconGrey
            }
            break;
        }
        case 2:{
            text=props.systemText.misc.mainCategories.activities[props.userLanguage]
            if (props.selected){
                icon = activitiesIcon
            }
            else if(props.disabled){
                icon = activitiesIconFade
            }
            else{
                icon = activitiesIconGrey
            }
            break;
        }
        case 3:{
            text=props.systemText.misc.mainCategories.sport[props.userLanguage]
            if (props.selected){
                icon = sportIcon
            }
            else if(props.disabled){
                icon = sportIconFade
            }
            else{
                icon = sportIconGrey
            }
            break;
        }
        case 4:{
            text=props.systemText.misc.mainCategories.food[props.userLanguage]
            if (props.selected){
                icon = foodIcon
            }
            else if(props.disabled){
                icon = foodIconFade
            }
            else{
                icon = foodIconGrey
            }
            break;
        }
        case 5:{
            text=props.systemText.misc.mainCategories.accommodation[props.userLanguage]
            if (props.selected){
                icon = accomodationIcon
            }
            else if(props.disabled){
                icon = accomodationIconFade
            }
            else{
                icon = accomodationIconGrey
            }
            break;
        }
        default: return null;
    }
    let bodyClass = "Body"
    let iconClass = "Icon"
    let selectedClass = ""
    let styleClass = ""
    let textClass = "Text"
    if (props.RTL){
      bodyClass = "BodyRTL"
    }
    if (props.selected) {
        selectedClass = "Selected"
        textClass = "TextSelected"
    }
    if (props.style!==undefined){
        styleClass=props.style
    }
    let horizontal=""
    let horizontalMargin = ""
    if (props.horizontal){
        horizontalMargin = "HorizontalMargin"
        horizontal = "Horizontal"
        if (props.RTL){
            horizontalMargin = "HorizontalMarginRTL"
        }
    }
    return(
      <div className={[classes[bodyClass],classes[styleClass],classes[selectedClass],classes[horizontal]].join(" ")}
      onClick={props.clicked}
      >
          <img src={icon} alt="" className={[classes[iconClass],classes[horizontalMargin]].join(" ")}/>
          <div className={classes[textClass]}>
            {text}
            </div>
      </div>
    )
  }
  return (
    <div className={classes.Wrapper}>
        {renderBody()}
    </div>
  );
};

export default mainCategory;
