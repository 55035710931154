import React, { Component } from "react";

import classes from "./PlaceSelection.module.css";

import PlanPlaceItem from "../Plan/PlanPlaceItem"
import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"

class PlaceSelection extends Component {
  state = {
    placeName: "",
    filterTags: [],
    showSuggestions: false,
    showBackdrop: false,
    searchSuggestions: [],
    showInfo: false,
    selected: false,
    bodyRef:React.createRef(),
  };

  componentDidMount = () => {
    this.setState({ placeName: this.props.placeName });
  };

  componentDidUpdate = (prevProps) =>{
      if (this.props.placeName!==prevProps.placeName){
        this.setState({ placeName: this.props.placeName });
      }
  }

  backdropHandler = () =>{
    this.setState({
        placeName: "",
        showSuggestions: false,
        showBackdrop: false,
        searchSuggestions: []
      });
  }



  //Handling input field for new tag
  placeInputHandler = async event => {
    let inputValue = event.target.value;
    if(inputValue.length===1){
        this.props.detectInputLanguage(inputValue)
      }
    //In case user pressed 'esc' key, clean the input field
    if (event.keyCode === 27) {
      this.setState({
        placeName: "",
        showSuggestions: false,
        showBackdrop: false,
        searchSuggestions: []
      });
      return;
    }
    //In case user pressed 'enter' key, add the tag to experience tags array
    //Use the selectTagSuggestion function for that
    if (event.key === "Enter") {
      //In case user pressed 'enter' with blank input, do nothing
      if (inputValue === "") return;
      if (this.state.searchSuggestions.length > 0) {
        this.selectSearchSuggestion(this.state.searchSuggestions[0]);
      }
      this.newPlaceHandler(inputValue);
      return;
    } else {
      //Get suggestions for user existing tags based in sub-input
      let searchSuggestions = this.getPlaceSuggestions(inputValue);
      this.setState({ placeName: inputValue });
      //In case user entered some characters, the suggestions should be shown
      if (inputValue != "" && searchSuggestions.length != 0) {
        this.setState({
          showSuggestions: true,
          showBackdrop: true,
          searchSuggestions: searchSuggestions
        });
        this.scrollToViewHandler()
        //If no suggetions exist based on entered value, don't show the suggestions
      } else if (searchSuggestions.length === 0) {
        this.setState({
          showSuggestions: false,
          showBackdrop: false,
          searchSuggestions: []
        });
      } else {
        //Input is blank
        this.setState({
          showSuggestions: false,
          showBackdrop: false,
          searchSuggestions: []
        });
      }
    }
  };

  newPlaceHandler = placeName => {
    if (placeName === "") {
      return;
    }
    this.setState({
      showSuggestions: false,
      showBackdrop: false
    });

    this.props.updatePlace(placeName, false);
  };

  selectSearchSuggestion = place => {
    this.setState({
      showSuggestions: false,
      showBackdrop: false,
      searchSuggestions: []
    });
    this.setState({ selected: true });
    this.props.updatePlace(place, true);
  };


  //Getting tags suggestions from user existing tags
  //Using the value entered so far to filter tags list
  getPlaceSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let placesNames = this.props.places.filter(place=>place.placeName!==undefined).filter(place=>place.placeName[this.props.inputLanguage]!==undefined)
    
    return inputLength === 0
      ? []
      : placesNames.filter(
        place =>
          place.placeName[this.props.inputLanguage].toLowerCase().includes(inputValue)
        );
  };

  blurHandler = () => {
    if (this.state.searchSuggestions.length == 0) {
      this.newPlaceHandler(this.state.placeName);
    }
    /*  setTimeout(() => {
      if (this.state.selected) {
        this.setState({
          showSuggestions: false,
          showBackdrop: false,
          searchSuggestions: []
        });
      } else {
       
      }
    }, 300); */
  };

  renderInputArea = () => {
      let inputClassRequired = ""
      if (this.props.required){
        inputClassRequired = "InputRequired"
      }
      return (
        <div className={classes.PlaceSection} ref={this.state.bodyRef}>
            <div className={[classes.PlaceSelectSection,classes[inputClassRequired]].join(" ")}>
            <input
              value={this.state.placeName}
              onChange={this.placeInputHandler}
              onKeyDown={this.placeInputHandler}
              onKeyPress={this.placeInputHandler}
              placeholder={this.props.placeholderMessage}
              className={classes.InputText}
              onFocus={this.scrollToViewHandler}
              onBlur={this.blurHandler}
            />
            </div>
          {this.state.searchSuggestions.length > 0 ? (
            <div className={classes.Suggestions}>
              {this.state.searchSuggestions.map((place, index) => (
                  <div className={classes.SearchValue}>
                    <PlanPlaceItem
                        key={index}
                        viewType={"slimSuggestion"}
                        placeName={place.placeName}
                        placeCategory={""}
                        placeLocationTree={""}
                        imagePreview={{url:""}}
                        select={() => {
                        this.selectSearchSuggestion(place);
                        }}
                        inputLanguage={this.props.inputLanguage}
                        userLanguage={this.props.userLanguage}
                        systemLanguage={this.props.systemLanguage}
                    />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      );   
  };

  scrollToViewHandler = () =>{
    this.state.bodyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }

  render() {
    return <div className={classes.Body}>
        <TransparentBackdrop  show={this.state.showBackdrop}
                clicked={this.backdropHandler}/>
        {this.renderInputArea()}</div>;
  }
}
export default PlaceSelection;
