import React, { Component } from "react";


import classes from "./SetLocation.module.css";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import GoogleMap from "./GoogleMap";
import ModalTopSection from "../../UI/Misc/ModalTopSection"
import Backdrop from "../../UI/Backdrop/Backdrop";
import SetLocationTree from "./SetLocationTree";


let mapClickIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMapClick.png?alt=media&token=9e2fd48b-8144-48c2-8598-b4fb15dfd722";
let searchIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FSearchIcon.png?alt=media&token=a5b0fe5e-d6f7-463e-854e-2c3332e8a067"
let currentLocIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCurrentLocation.png?alt=media&token=54ea82af-3b95-42bb-b624-d600768bc1fe"




class SetLocation extends Component {
  state = { 
  address: "",
  selectedAddress:"",
  showBackdrop:false,
  showLocationTree: false,
  locationSuggestions:[],
  showMap: false,
  locationWasEdited:false,
  inputMode:"Search",
  currentLocation: [
    { lat: "", lng:""}
  ],
  coordinates: [
    { lat: "", lng: "" }
  ],
  changesMade: false,
  placeStreet:"",
  placeName:"",
  placeCity:"",
  placeRegion:"",
  placeCountry:"",
  addressComponents:[],
};


 handleUserCountryForMapClickedFocus = () =>{
  if (this.props.location.coordinates!==undefined){
    this.setState({coordinates:[{lat:this.props.location.coordinates.lat,lng:this.props.location.coordinates.lng}]})
    return;
  }
  else if(this.props.settingAccommodation){
    this.setState({coordinates:[this.props.locationFocusCoordinates]})
  }
  switch (this.props.userCountry){
    case "Israel":{
      this.setState({coordinates:[{lat:31.0461,lng:34.8516}]})
      break;
    }
    default: return null;
  } 
  this.setCurrentLocation();
} 

  componentDidMount = () => {
    this.handleUserCountryForMapClickedFocus() 
    if (this.props.location.address!==undefined){
      this.setState({ address: this.props.location.address });
    }
    
    if (this.props.location.inputMode!==undefined){
      this.setState({inputMode:this.props.location.inputMode,showMap:true})
    }
   //this.setCurrentLocation();
   // this.askLocationPermissions()
     document.body.style.overflow = "hidden"; 
     if (this.props.showLocationPath){
       this.setState({showLocationTree:true,
        placeCountry:this.props.locationPath.placeCountry,
        placeRegion:this.props.locationPath.placeRegion,
        placeCity:this.props.locationPath.placeCity})
     }
  };

  componentWillUnmount=()=>{
    document.body.style.overflow = "unset"
  }

  setCurrentLocation = async () => {
    await navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        currentLocation: [
          { lat: position.coords.latitude, lng: position.coords.longitude }
        ],
        coordinates: [
          { lat: position.coords.latitude, lng: position.coords.longitude }
        ],showMap:true,
        addressComponents:[],
        address:"",
        placeID:"",
        placeAddress:"",
        placeTags:[],
        placeGeoCode:"",
        selectedAddress:"",
        editLocation:true,
        changesMade: true
      });
    }, error=>{
      if (error.code===1){
        alert("Geolocation permission has been blocked as the user has dismissed the permission prompt several times. This can be reset in Page Info which can be accessed by clicking the info icon left to the URL")
      }
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    });
   
  };

  askLocationPermissions = () =>{
    navigator.permissions.query({name:'geolocation', userVisibleOnly:true})
    .then(result => {
      if (result.state === 'granted'||result.state === 'prompt')
      {
        this.allowPermissions();}
      else{
        this.permissionsDenied();
      }
      result.onchange = event=> {
        if (event.target.state === 'granted'||result.state === 'prompt') {
          this.locationError = false

          this.allowPermissions()
        } else if (event.target.state === 'denied') {
          this.locationError = true
          this.permissionsDenied();
        }
       
      };
    }); 
  }

  allowPermissions = async ()=>{
    
    await this.setCurrentLocation();
    this.setState({showMap: true});
  }

  permissionsDenied =() =>{
    this.setCurrentLocation()
    this.setState({showMap: false});
  }

  addComponentHandler =(value,componenetIndex,anotherInput)=>{
    let index = componenetIndex;
    let inputValue = value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});   
    let selectedAddress=this.state.selectedAddress.split(',');
    selectedAddress.splice(index+1, 0, inputValue);
    let selectedAddressString="";
    for (let i=0; i<selectedAddress.length; i++){
      if (selectedAddress[i]!==""){
        selectedAddressString=selectedAddressString+selectedAddress[i];
        if (i!==selectedAddress.length-1){
          selectedAddressString=selectedAddressString+",";
        }
      }
    }
    if (!anotherInput){
      this.setState({addComponent:false});
    }
    this.setState({addedComponent:"",selectedAddress:selectedAddressString,locationSuggestions:[],
    locationWasEdited:true,
    changesMade: true,
    showBackdrop: false});
  }


 
  discard = () =>{
    this.setState({
      locationSuggestions:[],
      addComponent:false,
      showBackdrop:false
    })
  }

  saveHandler = (locationTree) => {
      let location = {
        locationTree:locationTree,
        inputMode:this.state.inputMode
      }
      if (this.state.addressComponents!==undefined){
        location.addressComponents=this.state.addressComponents
      }
      if (this.state.coordinates!==undefined){
        location.coordinates=this.state.coordinates[0]
      }
      if (this.state.selectedAddress!==undefined){
        location.address=this.state.selectedAddress
      }
      if (this.state.placeAddress!==undefined){
        location.placeAddress=this.state.placeAddress
      }
      if (this.state.placeID!==undefined){
        location.placeID=this.state.placeID
      }
      if (this.state.placeTags!==undefined){
        location.placeTags=this.state.placeTags
      }
      if (this.state.placeGeoCode!==undefined){
        location.placeGeoCode=this.state.placeGeoCode
      }

      if (this.state.placeGoogleRating!==undefined){
        location.placeGoogleRating=this.state.placeGoogleRating
      }
      if (this.state.placePhoneNumber!==undefined){
        location.placePhoneNumber=this.state.placePhoneNumber
      }
      if (this.state.placeWebsite!==undefined){
        location.placeWebsite=this.state.placeWebsite
      }
    this.props.update(location,this.state.locationWasEdited); 
    this.props.discard();
  };

  discardHandler = () => {
    this.props.discard();
  };

  handleSelect = async value => {
    this.setState({selectedAddress:value})
    this.props.disableShowLocationPath()
    const result = await geocodeByAddress(value);

    let placeAddress = "";
    if (result[0].formatted_address !== undefined)
      placeAddress = result[0].formatted_address;
    let placeID = "";
    if (result[0].place_id !== undefined) placeID = result[0].place_id;
    let placeTags = [];
    if (result[0].types !== undefined) placeTags = result[0].types;
    let placeGeoCode = "";
    if (result[0].plus_code !== undefined) placeGeoCode = result[0].plus_code;
    const latlng = await getLatLng(result[0]);
    const addressComponents = result[0].address_components;
    let placeCountry = "";
    let placeCity = "";
    let placeRegion = "";
    let placeStreet = "";
    let placeName="";
    let includeTwoAreas = false;
  /*   console.log("addressComponents")
    console.log(addressComponents) */
    for (let i=0 ; i<addressComponents.length ; i++){
      if (addressComponents[i].types.includes("administrative_area_level_2")){
        includeTwoAreas=true;
      }
    }

    for (let i=0 ; i<addressComponents.length ; i++ ){
      if (addressComponents[i].types.includes("country")){
        placeCountry = addressComponents[i].long_name;
      }
    }
    for (let i=0 ; i<addressComponents.length ; i++ ){
      if (addressComponents[i].types.includes("locality") && value.includes(addressComponents[i].long_name)){
        placeCity = addressComponents[i].long_name;
      }
    }
    if (includeTwoAreas){
      for (let i=0 ; i<addressComponents.length ; i++ ){
        if (addressComponents[i].types.includes("administrative_area_level_2") && value.includes(addressComponents[i].long_name)&&
        placeCity!==addressComponents[i].long_name){
          placeRegion = addressComponents[i].long_name;
        }
      }
      
    }
    else{
      for (let i=0 ; i<addressComponents.length ; i++ ){
        if (addressComponents[i].types.includes("administrative_area_level_1") && value.includes(addressComponents[i].long_name)&&
        placeCity!==addressComponents[i].long_name){
          placeRegion = addressComponents[i].long_name;
        }
      }
    }
    placeName=value.split(',')[0];


    if (latlng !== undefined||placeID!=="") {
      await this.setState({
        addressComponents:addressComponents,
        address: value,
        coordinates: [{ lat: latlng.lat, lng: latlng.lng }],
        placeAddress: placeAddress,
        placeID: placeID,
        placeTags: placeTags,
        placeGeoCode: placeGeoCode,
        changesMade: true,
        placeStreet:placeStreet,
        placeName:placeName,
        placeCity:placeCity,
        placeRegion:placeRegion,
        placeCountry:placeCountry
      });
      const request = {
        placeId: placeID,
        fields: ["rating","international_phone_number","website"],
      };
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      await service.getDetails(request, (place, status) => {
        this.setState({
          placeGoogleRating:place.rating,
          placePhoneNumber: place.international_phone_number,
          placeWebsite:place.website
        })
      })
      await this.setState({showMap: true});
    }
  };

  currentLocationHandler = () => {
    this.setState({
      addressComponents:[],
      address: "",
      coordinates: this.state.currentLocation,
      placeAddress: "",
      placeID: "",
      placeTags: [],
      placeGeoCode: null,
      changesMade: true
    });
  };



  renderTopSection = () => {
    if (!this.props.withoutTop){
    let title = this.props.title;
    let buttonName = this.props.systemText.extraModals.setLocation.doneButton[this.props.userLanguage];
    let saveReady=false;
    if (this.state.inputMode==="Search"){
      if (this.state.placeID!==undefined){
        if (this.state.placeID!==""){
          saveReady=true;
        }     
      }
    }
    else if (this.state.coordinates.lat!==""&&this.state.coordinates.lng!==""){
      saveReady=true;
    }
    
    return (
      <ModalTopSection
        title={title}
        discard={this.discardHandler}
        saveReady={saveReady}
        buttonText={buttonName}
        showButton={true}
        clicked={()=>{this.setState({showLocationTree: true, showBackdrop:true})}}
        saveButton={true}
        modalView = {true}
        RTL = {this.props.RTL}
      />
    );}
  };

  renderLocationSearch = () => {
    if (this.state.inputMode==="Search"){
    return (
      <div className={classes.Autocomplete}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={address => {
            this.setState({ address: address });
            this.props.detectInputLanguage(address)
          }}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
            
          }) => (
            <div>
             {suggestions.length > 0 ? ( 
         
            <div className={classes.InputSectionWithSuggestions}>
              <input
                className={classes.AutocompleteInput}
                {...getInputProps({
                  placeholder: "Search for place location"
                })}
              />
              <div>{loading ? <div>...loading</div> : null}</div>
            
                <div className={classes.SuggestionsSection}>
                  {suggestions.map((suggestion,index) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                    };
                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })} key={index}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              
            </div>) : ( <div className={classes.InputSection}>
              <input
                className={classes.AutocompleteInput}
                {...getInputProps({
                  placeholder: this.props.systemText.extraModals.setLocation.searchPlaceholder[this.props.userLanguage]
                })}
              /> </div>)} 
          </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
              }
  };


  mapClickedHandler = e =>{
    this.setState({
      coordinates: [
        { lat: e.latLng.lat(), lng:e.latLng.lng() }
      ],
     
    })
    this.props.disableShowLocationPath()
    
  }

  renderGoogleMap = () => {
    let markers = [];
    let focusCoordinates=this.state.coordinates[0];
    if (/* this.state.inputMode==="MapClick"|| */this.state.inputMode==="CurrentLocation"){
      focusCoordinates=this.state.currentLocation[0];
    }
    if (this.state.coordinates[0].lat===""&&this.state.coordinates[0].lng===""){

    }
    else{
      markers.push({
        placeLocation: { coordinates: this.state.coordinates[0] }
      });
    }
    let height = this.props.mapHeight
    return (
      <GoogleMap
        focusCoordinates={focusCoordinates}
        markers={markers}
        boundsPlaces={markers}
        height={height}
        mapClicked={this.mapClickedHandler}
        creationBoard={true}
        useMapClick={this.state.inputMode==="MapClick"}
      />
    );
  };

  renderAskPermissions = () =>{
    if (this.state.selectedAddress===""&&this.state.inputMode==="Search"){
      return null;
    }
    else{
      return (<div className={classes.LocationInstructions}>
        {this.props.systemText.extraModals.setLocation.locationMessage[this.props.userLanguage].split('\n').map(str => <p>{str}</p>)}
        <a href={"https://support.google.com/chrome/answer/142065?hl=en"} target="_blank" >
        {this.props.systemText.extraModals.setLocation.instructions[this.props.userLanguage]}
        </a>
      </div> )
    }
   
  }



  renderInputType = () =>{
    return(<div className={classes.InputTypeBar}>
      
        {this.state.inputMode==="Search"?(
          <div className={classes.InputTypeSelected}>
            <div className={classes.InputType}>
              {this.props.systemText.extraModals.setLocation.bySearchButton[this.props.userLanguage]}
              <img className={classes.TypeIcon} alt ="" src={searchIcon} />
              </div>
            </div>
        ):(<div className={classes.InputTypeRegular} 
          onClick={()=>{this.setState({inputMode:"Search",editLocation:false,});
            this.props.writeAcitivityLog({
              activityKey:"PLACE_EDIT_LOCATION_SEARCH_BUTTON",
              userScreen:this.props.activityScreen
          })
          }}>
           <div className={classes.InputType}>
              {this.props.systemText.extraModals.setLocation.bySearchButton[this.props.userLanguage]}
              <img className={classes.TypeIcon} alt ="" src={searchIcon} />
              </div></div>)}
          {this.state.inputMode==="CurrentLocation"?(
          <div className={classes.InputTypeSelected} 
          onClick={()=>{this.setCurrentLocation();this.setState({inputMode:"CurrentLocation",editLocation:true});
          this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_LOCATION_CURRENTLOCATION_BUTTON",
            userScreen:this.props.activityScreen
        })
          }}>
            <div className={classes.InputType}>
              {this.props.systemText.extraModals.setLocation.currentLocationButton[this.props.userLanguage]}
              <img className={classes.TypeIcon} alt ="" src={currentLocIcon} />
              </div>
            </div>
        ):(<div className={classes.InputTypeRegular} 
          onClick={()=>{this.setCurrentLocation();this.setState({inputMode:"CurrentLocation",editLocation:true, placeName:"",placeCity:"",
          placeRegion:"",
          placeCountry:""});
          this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_LOCATION_CURRENTLOCATION_BUTTON",
            userScreen:this.props.activityScreen
        })
          }}>
           <div className={classes.InputType}>
              {this.props.systemText.extraModals.setLocation.currentLocationButton[this.props.userLanguage]}
              <img className={classes.TypeIcon} alt ="" src={currentLocIcon} />
              </div></div>)}
          {this.state.inputMode==="MapClick"?(
          <div className={classes.InputTypeSelected} 
          onClick={()=>
            {this.setState({inputMode:"MapClick"})
            this.props.writeAcitivityLog({
              activityKey:"PLACE_EDIT_LOCATION_MAPCLICK_BUTTON",
              userScreen:this.props.activityScreen
          })
          }}> 
            <div className={classes.InputType}>
              {this.props.systemText.extraModals.setLocation.mapClickButton[this.props.userLanguage]}
              <img className={classes.TypeIcon} alt ="" src={mapClickIcon} />
              </div>
            </div>
        ):(<div className={classes.InputTypeRegular} 
        onClick={()=>{
          if (this.state.coordinates===undefined){
            this.setCurrentLocation();
          }
          this.props.disableShowLocationPath();
          this.setState({inputMode:"MapClick", showMap:true,
                                    editLocation:true, placeName:"",placeCity:"",
                                    placeRegion:"",
                                    placeCountry:""});
          this.props.writeAcitivityLog({
            activityKey:"PLACE_EDIT_LOCATION_MAPCLICK_BUTTON",
            userScreen:this.props.activityScreen
        })}}>
           <div className={classes.InputType}>
              {this.props.systemText.extraModals.setLocation.mapClickButton[this.props.userLanguage]}
              <img className={classes.TypeIcon} alt ="" src={mapClickIcon} />
              </div></div>)}
    </div>)
  }

  renderSetLocationTree = () =>{
    if (this.state.showLocationTree){
      return(
        <SetLocationTree
          placeName={this.state.placeName}
          placeStreet={this.state.placeStreet}
          placeCity={this.state.placeCity}
          placeRegion={this.state.placeRegion}
          placeCountry={this.state.placeCountry}
          address={this.state.address}
          addressComponents = {this.state.addressComponents}
          discard ={()=>{this.setState({showBackdrop: false,showLocationTree:false})}}
          finish={this.saveHandler}
          locationPathUpdate = {this.props.showLocationPath}
          userLanguage={this.props.userLanguage}
          systemText={this.props.systemText}
          RTL = {this.props.RTL}
          locationsTree={this.props.locationsTree}
          detectInputLanguage = {this.props.detectInputLanguage}
          inputLanguage = {this.props.inputLanguage}

          getPlacesListForPlaceCreationValidation = {this.props.getPlacesListForPlaceCreationValidation}
        />
      )
    }
  }

  renderMapArea=()=>{
    if(this.state.coordinates !== undefined && this.state.showMap){
        return(
          <div>{this.renderGoogleMap()}</div>
         )
    }
     else{
       return(
        <div>{this.renderAskPermissions()}</div>
       )
     }
  }

  render() {
    let RTL = ""
    if (this.props.RTL){
      RTL = "RTL"
    }
    let bodyClass = ""
    let modalViewClass = ""
    if (this.props.modalView){
      bodyClass = "BodyWide"
      modalViewClass = "ModalView"
    }
    return (
      <div className={[classes.Modal,classes[modalViewClass]].join(" ")}>
        <Backdrop show={this.state.showBackdrop}/> 
        {this.renderTopSection()}
        {this.renderSetLocationTree()}
         <div className={[classes.Body,classes[bodyClass],classes[RTL]].join(" ")}>
           {this.renderInputType()}
           <div className={classes.Content}>
              {this.renderLocationSearch()}
            {this.renderMapArea()}
           </div>
          
        </div> 
      </div>
    );
  }
}

export default SetLocation;
