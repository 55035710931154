import React, { Component } from "react";

import classes from "./PlanEditors.module.css";

import UsersSearch from "../Users/UsersSearch"
import UserItem from "../Users/UserItem";
import ConfirmModal from "../../UI/Modals/ConfirmationModal"
import ModalTopSection from "../../UI/Misc/ModalTopSection"

class PlanEditors extends Component {
  state = {
    confirmMode: false,
    searchingFriend: false,
    usersList : this.props.friends
  };


  updateSearchingFriend = (value)=>{
    this.setState({searchingFriend:value})
  }

  addToList = async user =>{
      let usersList=this.state.usersList;
      let saveUser = {
        userID:user.userID,
        photoUrl:user.photoUrl,
        displayName:user.displayName,
        userEmail: user.userEmail
      }
      usersList.push(saveUser);
      await  this.setState({usersList:usersList})
      this.updatePlanEditors(true,saveUser)

      this.props.writeAcitivityLog({
        activityKey:"TRIP_EDITORS_ADD_USER",
        userScreen:"VIEW_TRIP"
    })
  }

  removeFriend = (user,message) =>{
    let secondaryBtn = this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage]
    let confirmMessage = this.props.systemText.users.userItem.removePlanEditor[this.props.userLanguage]
    if (this.props.experienceTagging){
      secondaryBtn = this.props.systemText.extraModals.confirmationModal.remove[this.props.userLanguage]
      confirmMessage = this.props.systemText.users.userItem.removeTaggedUser[this.props.userLanguage]
    }
    this.setState({
      confirmMessage: confirmMessage,
      potentialRemoveFriend:user,
      confirmMode: true,
      primaryBtn: this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage],
      secondaryBtn: secondaryBtn,
      showBackdrop: true,
      optionsTools: false
    });

    this.props.writeAcitivityLog({
      activityKey:"TRIP_EDITORS_REMOVE_USER",
      userScreen:"VIEW_TRIP"
    })
  }


  confirmDeleteHandler = async () => {
    this.setState({
      confirmMode: false,
      showBackdrop: false
    });
    let usersList=this.state.usersList;
    const index = usersList.findIndex(user => {
        return user.userID === this.state.potentialRemoveFriend.userID;
      });
    usersList.splice(index,1);
    await  this.setState({usersList:usersList})
    this.updatePlanEditors(false,this.state.potentialRemoveFriend)
  };
  
  discardDeleteHandler = () => {
    this.setState({
      confirmMode: false,
      showBackdrop: false
    });
  };

  updatePlanEditors =(added,user)=>{
    this.props.updatePlanEditors(this.state.usersList,added,user)
  }


  renderBody=()=>{
    let placeholder = this.props.systemText.users.searchPlanEditors[this.props.userLanguage]
    if (this.props.experienceTagging){
      placeholder = this.props.systemText.users.searchFriendsToTag[this.props.userLanguage]
    }
      return(
        <div className={classes.UsersSection}>
            <UsersSearch
                placeholder={placeholder}
                type="searchExistingFriend" 
                usersList={this.props.myFriends}
                existingList={this.props.friends}
                removeFriend={this.removeFriend}
                addToList={this.addToList}
                updateSearchingFriend={()=>{return null}}
                isOwner={this.props.isOwner}

                RTL = {this.props.RTL}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
            />
           {this.props.friends.map((user, index) => (
               
                <UserItem key={user.userID} user={user} type="searchExistingFriend" 
                existingList={this.props.friends}
                removeFriend={this.removeFriend}
                isOwner={this.props.isOwner}
                ownerID={this.props.ownerID}
                select={()=>{return null}}

                RTL = {this.props.RTL}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                />
            ))} 
          </div>
        )
  }

  
  renderTopSection = () => {
    let title = this.props.title;
    return (
      <ModalTopSection
        title={title}
        discard={this.props.discard}
        saveButton={false}

       RTL= {this.props.RTL}
      />
    );
  };

  renderConfirmModal = () => {
    if (this.state.confirmMode) {
      return (
        <ConfirmModal
            header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
            show={this.state.confirmMode}
            primaryAction={this.discardDeleteHandler}
            secondaryAction={this.confirmDeleteHandler}
            message={this.state.confirmMessage}
            primaryBtn={this.state.primaryBtn}
            secondaryBtn={this.state.secondaryBtn}
            primaryStyle = {"DiscardButton"}
            secondaryStyle = {"DeleteButton"}
            RTL = {this.props.RTL}
          />
      );
    }
  };
  

  render() {
    return (
        <div className={classes.Body}>
        {this.renderConfirmModal()}
        {this.renderTopSection()}
        <div className={classes.Content}>
            {this.renderBody()}</div>
      </div>
    );
  }
}
export default PlanEditors;
