import React from "react";
import Experience from "./Experience";

const ExperiencesWall = props =>
  props.experiencesList.map((experience, index) => {
    let experienceID=experience.experienceID;
    let experienceLastModified=experience.experienceLastModified;
    let experienceCreationDate=experience.experienceCreationDate;
    let experienceTitle=experience.experienceTitle;
    let experienceLocation = experience.experienceLocation
    let experiencePlaceID=experience.experiencePlaceID;
    let experiencePlaceName =experience.experiencePlaceName;
    let experienceDuration = experience.experienceDuration;

    let experienceText = experience.experienceText;
    let experienceRank = experience.experienceRank;
    let experienceReview = experience.experienceReview;
    let experienceStats = experience.experienceStats;
    let experienceTags=[];
    if (experience.experienceTags!==undefined){
        experienceTags=experience.experienceTags;
    }
    let experienceLikedTags =[];
    if (experience.experienceLikedTags!==undefined){
        experienceLikedTags=experience.experienceLikedTags;
    }
    let experienceDislikedTags =[];
    if (experience.experienceDislikedTags!==undefined){
        experienceDislikedTags=experience.experienceDislikedTags;
    }
    let imageGallery =[];
    if (experience.imageGallery!==undefined){
        imageGallery=experience.imageGallery;
    }
    let imagePreview =[];
    if (experience.imagePreview!==undefined){
        imagePreview=experience.imagePreview;
    }
    let taggedUsers =[];
    if (experience.taggedUsers!==undefined){
        taggedUsers=experience.taggedUsers;
    }
    let owner = experience.owner;
    let videoUrl =""
    if (experience.videoUrl !== undefined){
        videoUrl=experience.videoUrl;
    }
    let experienceDate = experienceCreationDate;
    if (experience.experienceDate!==undefined){
        experienceDate=experience.experienceDate;
    }
    let experiencePrivacy ="private";
    if (experience.experiencePrivacy!==undefined){
        experiencePrivacy=experience.experiencePrivacy;
    }
    let experiencePlanSecurity = undefined;
    let experienceAlbumSecurity = undefined;
    let experiencePlanID = experience.experiencePlanID
    if (experiencePlanID!==undefined){
        experiencePlanSecurity = experience.experiencePlanSecurity;
        experienceAlbumSecurity = experience.experienceAlbumSecurity;
    }
    return (
      
      <Experience 
      experienceID={experienceID}
      experienceLastModified={experienceLastModified}
      experienceCreationDate={experienceCreationDate}
      experienceTitle={experienceTitle}
      experienceDate={experienceDate}
      experienceTags={experienceTags}
      experienceText={experienceText}
      experiencePlaceID={experiencePlaceID}
      experiencePlaceName={experiencePlaceName}
      experiencePlanID = {experiencePlanID}
      experiencePlanName={experience.experiencePlanName}
      experienceLocation={experienceLocation}
      imagePreview={imagePreview}
      imageGallery={imageGallery}
      taggedUsers={taggedUsers}
      videoUrl={videoUrl}
      experienceRank={experienceRank}
      experienceReview={experienceReview}
      experienceStats={experienceStats}
      experienceLikedTags={experienceLikedTags}
      experienceDislikedTags={experienceDislikedTags}
      experiencePrivacy={experiencePrivacy}
      experienceDuration={experienceDuration}
      mapView={props.mapView}
      user={props.user} 
      owner={owner}
      experiencePlanSecurity={experiencePlanSecurity}
      experienceAlbumSecurity={experienceAlbumSecurity}
      board={props.board}
      selectedContentWall={props.selectedContentWall}

      followingUsersIDs = {props.followingUsersIDs}
      myFriendsIDs = {props.myFriendsIDs}

      viewUserProfileHandler = {props.viewUserProfileHandler}
      viewHandler={props.viewHandler}
      editHandler={props.editHandler}
      removeTaggingHandler = {props.removeTaggingHandler}
      deleteHandler={props.deleteHandler}
      viewExperiencePlace={props.viewExperiencePlace}
      viewExperiencePlan={props.viewExperiencePlan}
      addToPlan={props.addToPlan}
      removeFromPlan={props.removeFromPlan}
      addToFavorite={props.addToFavorite}
      removeFromFavorite={props.removeFromFavorite}
      viewStyle={props.viewStyle}
      experienceFullScreenHandler={props.experienceFullScreenHandler}

      viewImagesFullscreen = {props.viewImagesFullscreen}

      writeAcitivityLog = {props.writeAcitivityLog}

      RTL = {props.RTL}
      userLanguage={props.userLanguage}
      systemText={props.systemText}
      index={index}
      key={index}
      />
      );
    });
  
  export default ExperiencesWall;