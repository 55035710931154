import React, { Component } from "react";
import classes from "./UserProfileWall.module.css";

import PlansWall from "../Content/TripPlans/PlansWall"
import ExperiencesWall from "../Content/Experiences/ExperiencesWall"
import SimpleSearchBar from "../../Components/Complex/SearchBars/SimpleSearchBar";
import SearchBarContentType from "../../Components/UI/ContentTypeSelector/SearchBarContentType"
import ScrollToTop from "../../Components/UI/Buttons/ScrollToTop"
import ModalTopSection from "../../Components/UI/Misc/ModalTopSection"

import Tag from "../../Components/UI/Tag/Tag";
import Tab from "../../Components/UI/Tabs/Tab"
import { isMobile, isMobileOnly } from "react-device-detect";

export default class UserProfileWall extends Component {

    state={
        wallSelection: "Experiences",
        wallSelectionOptions: ["SelectedTabV2", "RegularTabV2"],
    }

    componentDidMount = () =>{
        this.updateUserStatus()
        var scrollComponent = this;
        var frame = document.getElementById("UserProfileWallScroll");
        frame.addEventListener("scroll", function(e) {
          scrollComponent.wallScrollToggle();
        });      
    }

    componentDidUpdate = prevProps =>{
        if (this.props.followingUsersIDs!==prevProps.followingUsersIDs||this.props.myFriendsIDs!==prevProps.myFriendsIDs){
            this.updateUserStatus()
        }
    }

    scrollWallToTop = () =>{
        var frame = document.getElementById("UserProfileWallScroll");
        frame.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }
    
      wallScrollToggle = () =>{
        var frame = document.getElementById("UserProfileWallScroll");
        let scrollTop = frame.scrollTop
        let topLimit = 300;
        if (isMobileOnly){
            topLimit=450;
        }
        if (frame.scrollTop>window.innerHeight){
            this.setState({showScrollUpButton:true})
        }
        else{
            this.setState({showScrollUpButton:false})
        }
        if (frame.scrollTop>topLimit){
            this.setState({showLeanToolbar:true})
        }
        else{
            this.setState({showLeanToolbar:false})
        }
      }

    updateUserStatus = () =>{
        if (this.props.myFriendsIDs.includes(this.props.selectedUser.userID)){
            this.setState({userStatus:"Friend"})
        }
        else if (this.props.followingUsersIDs.includes(this.props.selectedUser.userID)){
            this.setState({userStatus:"Following"})
        }
        else{
            this.setState({userStatus:undefined})
        }
    }

    scrollExperiencesToTop = () =>{
      
    }
  
    experiencesScrollToggle = () =>{
     
    }

   
    clonePlan = index =>{
        let plan = this.props.plans[index];
        this.props.clonePlanHandler(plan);
    }

    addPlanToFavorite = (index) =>{
    let plan = this.props.plans[index];
    this.props.addToFavoriteHandler(plan,"Trip");
    }


    updatePlanFavoriteTags = (index) =>{  
    let plan = this.props.plans[index];
    this.props.updateFavoriteTagsHandler(plan,"Trip");
    }

    
    removePlanFromFavorite= (index) =>{  
    let plan = this.props.plans[index];
    this.props.removeFromFavoriteHandler(plan,"Trip");
    }

  searchHandler = tag =>{
      if (this.state.wallSelection==="Experiences"){
          this.props.selectedUserExperiencesSearchHandler(tag)
      }
      else if (this.state.wallSelection==="Trips"){
          this.props.selectedUserTripsSearchHandler(tag)
      }
  }

    renderSearchBarSection = () =>{
        if (this.state.wallSelection==="Experiences"|| this.state.wallSelection==="Trips"){
            let searchBarSectionClass = "SearchBarSection"
            if (this.props.RTL){
                searchBarSectionClass = "SearchBarSectionRTL"
            }
            let searchTags =[];
            let placeHolderMessage = ""
            if (this.state.wallSelection==="Experiences"){
                searchTags = this.props.experiencesSearchTags
                placeHolderMessage = this.props.systemText.myContent.searchSection.searchBarExperiencesPlaceholder[this.props.userLanguage];
            }
            else if (this.state.wallSelection==="Trips"){
                searchTags = this.props.tripsSearchTags
                placeHolderMessage = this.props.systemText.myContent.searchSection.searchBarTripsPlaceholder[this.props.userLanguage];
            }
            
            return(
                <div className={classes.Toolbar}>
                    <div className={classes[searchBarSectionClass]}>
                        <SimpleSearchBar
                            searchTags = {searchTags}
                            placeholderMessage = {placeHolderMessage}
                            detectLanguage = {this.props.detectInputLanguage}
                            inputLanguage = {this.props.inputLanguage}
                            RTL = {this.props.RTL}
                            updateFilterTags = {this.searchHandler}
                            locationsTranslator = {this.props.locationsTranslator}
                        />
                        </div>
                    {this.renderFilterTags()}
                </div>
            )
        }
    }

    renderFilterTags = () =>{
        if (this.state.wallSelection==="Experiences" && this.props.experiencesFilterTags.length>0){
            return(
                <div className={classes.TagsList}>
                {this.props.experiencesFilterTags.map((tag, index) => (
                      <Tag
                      key={index}
                      text={tag.tag[tag.language]}
                      removable={true}
                      style={"FavoritePreviewTag"}
                      remove={() => {this.props.selectedUserExperiencesRemoveTagHandler(index)}}
                      RTL = {this.props.RTL}
                    />
              ))} 
             
              </div>
              )
        }
        else if (this.state.wallSelection==="Trips" && this.props.tripsFilterTags.length>0){
            return(
                <div className={classes.TagsList}>
                {this.props.tripsFilterTags.map((tag, index) => (
                      <Tag
                      key={index}
                      text={tag.tag[tag.language]}
                      removable={true}
                      style={"FavoritePreviewTag"}
                      remove={() => {this.props.selectedUserTripsRemoveTagHandler(index)}}
                      RTL = {this.props.RTL}
                    />
              ))} 
             
              </div>
              )
        }
    }

    renderTripsPlans = () =>{
      return (
        <div  className={classes.PlansWall}>
        <PlansWall
            plansList={this.props.plans}
            user={this.props.user}
            viewHandler={this.props.viewPlanHandler}
            selectedContentWall={"UserProfile"}
            clonePlan={this.clonePlan}

            addPlanToFavorite={this.addPlanToFavorite}
            removePlanFromFavorite={this.removePlanFromFavorite}
            updateFavoriteTags={this.updatePlanFavoriteTags}

            favoriteTripsIDs = {this.props.favoriteTripsIDs}

            locationsTranslator = {this.props.locationsTranslator}

            writeAcitivityLog={this.props.writeAcitivityLog}

            loggedUser = {this.props.loggedUser}
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
        />
        </div>
    );
    }

    renderExperiences = () =>{
      return (
        <div  className={classes.ExperiencesWall} id="UserExperiences">
        <ExperiencesWall
            experiencesList={this.props.experiences}
            user={this.props.user}
            viewStyle={"wall"}
            viewExperiencePlace={this.props.viewExperiencePlace}
            viewExperiencePlan={this.props.viewExperiencePlan}
            selectedContentWall={"UserWall"}

            viewImagesFullscreen = {this.props.viewImagesFullscreen}
            writeAcitivityLog={this.props.writeAcitivityLog}
           
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
        />
        </div>
    );
    }

    renderWall = () =>{
        switch (this.state.wallSelection) {
            case "Trips":{
              return  <div className={classes.Wall} style={{minWidth:"100%"}}>{this.renderTripsPlans()}</div>;
              break;
            }
            case "Experiences":{
              return  <div className={classes.Wall} style={{minWidth:"100%"}} >{this.renderExperiences()}</div>;
              break;
           }
            default:
              return null;
          }
    }

    renderTopSection = () =>{
        return (
            <div className={classes.TopSection}>
               <ModalTopSection
               title={this.props.selectedUser.displayName}
               discard={this.props.discard}
               showButton={false}
               RTL = {this.props.RTL}
               />
           </div>
         );
    }

    renderUserTools = () =>{
        let removeMessage = this.props.systemText.users.userItem.removePrefix[this.props.userLanguage]+this.props.user.displayName+this.props.systemText.users.userItem.removePostfix[this.props.userLanguage]
        
        if (this.state.userStatus==="Friend"){
            return(
                <div className={classes.UserTools}>
                     <div className={classes.Friend}>
                        {this.props.systemText.users.userItem.friend[this.props.userLanguage]}
                    </div>
                    <div className={classes.Seperator}/>
                    <div className={classes.Remove}  onClick={(e)=>{ e.stopPropagation();this.props.removeFriend(this.props.selectedUser,removeMessage)}}>
                        {this.props.systemText.users.userItem.remove[this.props.userLanguage]}
                    </div>
                </div>
            )
        }
        else if (this.state.userStatus==="Following"){
            return(
                <div className={classes.UserTools}>
                    <div className={classes.RequestSent}>
                        {this.props.systemText.users.userItem.followed[this.props.userLanguage]}
                    </div>
                    <div className={classes.Seperator}/>
                    <div className={classes.Remove}  onClick={(e)=>{ e.stopPropagation();this.props.stopFollow(this.props.selectedUser,this.props.systemText.users.unfollowMessage[this.props.userLanguage])}}>
                        {this.props.systemText.users.userItem.stopFollow[this.props.userLanguage]}
                    </div>
                </div>
            )
        }
        else{
            return(
                <div className={classes.UserTools}>
                    <div className={classes.AskRequest}  onClick={(e)=>{ e.stopPropagation();this.props.requestFriendship(this.props.selectedUser)}}>
                        {this.props.systemText.users.userItem.requestFriendship[this.props.userLanguage]}
                    </div>
                    <div className={classes.AskFollow}  onClick={(e)=>{ e.stopPropagation();this.props.followUser(this.props.selectedUser)}}>
                        {this.props.systemText.users.userItem.requestFollow[this.props.userLanguage]}
                    </div>
                </div>
            )
        }
    }

    renderUserProfile = () =>{
        let profileImageClass = "ProfileImage"
        if (this.props.RTL){
            profileImageClass = "ProfileImageRTL"
        }
        let profileSectionHeight = "ProfileSectionHeight"
        if ((this.props.experiencesFilterTags.length>0&&this.state.wallSelection==="Experiences")||
        (this.props.tripsFilterTags.length>0&&this.state.wallSelection==="Trips")){
            profileSectionHeight = "ProfileSectionHeightExpand"
        }
        return(
            <div className={[classes.UserProfileSection,classes[profileSectionHeight]].join(" ")}>
                <div className={classes.UserProfile}>
                    <div className={classes.ProfileInfo}>
                        <img src={this.props.selectedUser.photoUrl} alt="" className={classes[profileImageClass]}/>
                        <div className={classes.DisplayName}>{this.props.selectedUser.displayName}</div>
                    </div>
                    {this.renderUserTools()}
                </div>
                <div className={classes.WallContentSelection}>
                    <div className={classes.SelectionTabs}>
                        <Tab
                            tabType={this.state.wallSelectionOptions[0]}
                            text={this.props.systemText.misc.experiencesName[this.props.userLanguage]}
                            clicked={() => {
                            this.setState({
                                wallSelection: "Experiences",
                                wallSelectionOptions: ["SelectedTabV2", "RegularTabV2"],
                            });
                            this.scrollWallToTop()
                            this.props.writeAcitivityLog({
                                activityKey:"EXPERIENCES_FEED_BUTTON",
                                userScreen:"USERWALL"
                            })
                            }}
                        />
                        <Tab
                            tabType={this.state.wallSelectionOptions[1]}
                            text={this.props.systemText.misc.tripsName[this.props.userLanguage]}
                            clicked={() => {
                            this.setState({
                                wallSelection: "Trips",
                                wallSelectionOptions: ["RegularTabV2", "SelectedTabV2"],
                            });
                            this.scrollWallToTop()
                            this.props.writeAcitivityLog({
                                activityKey:"TRIP_FEED_BUTTON",
                                userScreen:"USERWALL"
                            })
                            }}
                        />
                    </div>
                   {this.renderSearchBarSection()}
                </div>
               
            </div>
        )
    }

    renderLeanToolbar = () =>{
        if (this.state.showLeanToolbar){
            return(
                <div className={classes.LeanToolbar}>
                    <div className={classes.SelectionTabs}>
                    <Tab
                        tabType={this.state.wallSelectionOptions[0]}
                        text={this.props.systemText.misc.experiencesName[this.props.userLanguage]}
                        clicked={() => {
                        this.setState({
                            wallSelection: "Experiences",
                            wallSelectionOptions: ["SelectedTabV2", "RegularTabV2"],
                        });
                        this.scrollWallToTop()
                        }}
                    />
                     <Tab
                        tabType={this.state.wallSelectionOptions[1]}
                        text={this.props.systemText.misc.tripsName[this.props.userLanguage]}
                        clicked={() => {
                        this.setState({
                            wallSelection: "Trips",
                            wallSelectionOptions: ["RegularTabV2", "SelectedTabV2"],
                        });
                        this.scrollWallToTop()
                        }}
                    />
                    </div>
                   
                   {this.renderSearchBarSection()} 
                </div>
            )
        }
    }

    render(){
        let RTL = ""
        if (this.props.RTL){
            RTL = "RTL"
        }
          return(
            <div className={[classes.Modal,classes[RTL]].join(" ")}>
                {this.renderTopSection()}
              <div className={classes.Body} id="UserProfileWallScroll">
                  {this.renderLeanToolbar()}
                  {this.renderUserProfile()}
                  
                
                  {this.renderWall()}
                  {this.state.showScrollUpButton?(<ScrollToTop scrollToTop={this.scrollWallToTop}/>):null} 
              </div>
            </div>
          )
      }
  }