import React, {useState} from 'react';
import classes from "./ImageFullscreenViewer.module.css";

let closeIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"
let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowLeft.png?alt=media&token=8020b73f-93be-478c-a1c3-c3cee641fbe8"
let arrowIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowRight.png?alt=media&token=e0fca8aa-a34f-47cf-80d3-79b36b305e88"


const ImageFullscreenViewer = props => {
    const [state, setState] = useState({selectedImage:props.imagesUrls[props.imageIndex],imageIndex:props.imageIndex});
  

const leftHandler = () =>{
    let imageIndex = state.imageIndex;
    if (imageIndex===0) {
        imageIndex = props.imagesUrls.length -1;
        let selectedImage = props.imagesUrls[imageIndex];
        setState({imageIndex:imageIndex, selectedImage:selectedImage})
    }
    else{
        imageIndex = imageIndex -1;
        let selectedImage =props.imagesUrls[imageIndex];
        setState({imageIndex:imageIndex, selectedImage:selectedImage})
    }   
       
}

const selectBulletHandler = index =>{
    let imageIndex =index;
    let selectedImage = props.imagesUrls[imageIndex];
    setState({imageIndex:imageIndex, selectedImage:selectedImage})
}

const rightHandler = () =>{
    let imageIndex = state.imageIndex;
    if (imageIndex===props.imagesUrls.length-1) {
        imageIndex = 0;
        let selectedImage = props.imagesUrls[imageIndex];
        setState({imageIndex:imageIndex, selectedImage:selectedImage})
    }
    else{
        imageIndex = imageIndex+1;
        let selectedImage = props.imagesUrls[imageIndex];
        setState({imageIndex:imageIndex, selectedImage:selectedImage})
    }
    
}

const renderBullet = (index) =>{
    if (state.imageIndex===index){
        return(<div className={classes.SelectedBullet}>

        </div>)
    }
    else{
        return (<div className={classes.UnselectedBullet}></div>)
    }
}

const renderImageBullets = () =>{
    return (<div className={classes.ImageBullets}>
        {props.imagesUrls.map((bullet,index) => (
            <div key={index} className={classes.Bullet} onClick={()=>{selectBulletHandler(index)}}>
                {renderBullet(index)}
            </div>
        ))}
    </div>)
}

const renderDesktopImageCarusel = () =>{
    let rotateArrow = ""
    if (props.RTL){
        rotateArrow = "RotateArrow"
    }
    return(
        <div className={classes.ImageViewer}>
            <div className={classes.ArrowsSection}>
                {props.imagesUrls.length > 1 ?(
                    <img className={[classes.Arrow,classes[rotateArrow]].join(" ")} alt = "" src={arrowIconLeft} onClick={leftHandler}/>):null}
                    {props.imagesUrls.length > 1 ?(
                <img src={arrowIconRight} className={[classes.Arrow,classes[rotateArrow]].join(" ")} alt="" onClick={rightHandler}/>):null}
            </div>
            <div className={classes.ImagesSection}>
                <img src={state.selectedImage} className={classes.Image} alt=""/>
            </div>
            {renderImageBullets()}
        </div>
    )

   
}


const renderCloseIcon = () =>{
    return(
        <img src={closeIcon} alt="" className={classes.CloseIcon} onClick={props.discard}/>
    )
}

  return (
    <div className={classes.Modal}>
        {renderCloseIcon()}
        {renderDesktopImageCarusel()}
    </div>
  );
}

export default ImageFullscreenViewer;