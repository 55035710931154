import React, { Component } from "react";

import classes from "./PlanDaysOptions.module.css";

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"


class PlanDaysOptions extends Component {
  state = {
    selectedDays:[],
    dayIsSelected:false
  };

  componentDidMount = () =>{
      let selectedDays = []
      let dayIsSelected = false
      if (this.props.daysIndices.length===0){
        for (let i=0;i<this.props.planDays.length ; i++){
            selectedDays[i]= false
        }
        
      }
      else if (this.props.accommodationDaysOptions){
        if (this.props.planDays.length===3){
            //only one night
            selectedDays[0]=true;
            selectedDays[1]=true
            dayIsSelected = true
            
        }
        else{
          selectedDays[0]=false;
          for (let i=1;i<this.props.planDays.length-1 ; i++){
              selectedDays[i]= false
          }
          for (let i=0 ; i<this.props.daysIndices.length; i++){
            selectedDays[this.props.daysIndices[i]]=true;
          }
          
          dayIsSelected = true
        }
      }
      else{
        for (let i=0;i<this.props.planDays.length ; i++){
            selectedDays[i]= false
        }
        for (let i=0 ; i<this.props.daysIndices.length; i++){
            selectedDays[this.props.daysIndices[i]]=true;
          }
        dayIsSelected = true
      }
      this.setState({selectedDays:selectedDays,dayIsSelected:dayIsSelected})
  }

  dayClickHandler = (index,value)=>{
      let selectedDays = this.state.selectedDays
      if (index===0&&this.props.accommodationDaysOptions){
          //all days
          for (let i=0 ; i<selectedDays.length ; i++){
            selectedDays[i]=value
          }
      }
      else{
          selectedDays[index]=value
          if (value){
            //check if now all days selected
            if (this.props.accommodationDaysOptions){
                let allDaysSelected = true
                for (let i=1 ; i<selectedDays.length ; i++){
                    if (selectedDays[i]===false){
                        allDaysSelected = false
                    }
                }
                if (allDaysSelected){
                    selectedDays[0]=true
                }
            }
            else if (this.props.singleDayMove){
                for (let i=0 ; i<selectedDays.length ; i++){
                    if (selectedDays[i]===true&&i!==index){
                        selectedDays[i] = false
                    }
                }
            }
           
          }
          else if(this.props.accommodationDaysOptions){
            selectedDays[0]=false
          }
      }

      let dayIsSelected = false
      for (let i=0 ; i<selectedDays.length ; i++){
        if (selectedDays[i]===true){
            dayIsSelected = true
        }
    }
      this.setState({selectedDays:selectedDays,dayIsSelected:dayIsSelected})
  }




  renderDayRow = index =>{
    let checkIconClass = "CheckIcon"
    let uncheckBoxClass = "UncheckBox"
    if (this.props.RTL){
      checkIconClass = "CheckIconRTL"
      uncheckBoxClass = "UncheckBoxRTL"
    }
    let text =""
    if (index===0){
        if (this.props.tripGenerator){
            text = this.props.systemText.plan.viewPlan.planning.anyDay[this.props.userLanguage]
        }
        else if (this.props.accommodationDaysOptions){
            text = this.props.systemText.plan.createPlan.accommodation.allDays[this.props.userLanguage]
        }
        else{
            text = this.props.systemText.plan.viewPlan.planning.interestigMobile[this.props.userLanguage]
        }  
    }
    else{
        text = 
        this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage] +" "+ this.props.planDays[index].name.substr(this.props.planDays[index].name.indexOf(' ')+1)
    }
    if (this.state.selectedDays[index]){
       return(
           <div className={classes.CheckMarkSection}>
                <img src={checkedBox} alt="" className={classes[checkIconClass]}  onClick={()=>{this.dayClickHandler(index,false)}}/>
                <div className={classes.NoDatesButtonSelected}> 
                {text}</div>
           </div>
       )
    }
    else {
        return(
            <div className={classes.CheckMarkSection}>
                <div className={classes[uncheckBoxClass]} onClick={()=>{this.dayClickHandler(index,true)}}/>
                <div className={classes.NoDatesButtonSelected}> 
                {text}</div>
            </div>
        )
        
    }
  }

  renderDays = () =>{
    return(
        <div className={classes.DaysList}>
            {this.state.selectedDays.map((day,index)=>(
                <div className={classes.Day} key={index}>
                    {this.renderDayRow(index)}
                </div>
            ))}

        </div>
    )
  }

  renderButton = () =>{
    if (this.state.dayIsSelected){
        return(
            <div className={classes.CompleteButton} onClick={()=>{this.props.finishHandler(this.state.selectedDays)}}>
                {this.props.systemText.plan.createPlan.accommodation.finish[this.props.userLanguage]}
            </div>
        )
    }
    else{
        return(
            <div className={classes.CompleteButtonFade}>
                {this.props.systemText.plan.createPlan.accommodation.finish[this.props.userLanguage]}
            </div>
        )
    }
   
  }

  renderCloseIcon = () =>{
    let closeIconClass = "CloseIcon"
    if (this.props.RTL){
        closeIconClass = "CloseIconRTL"
    }
    return(
        <div className={classes.TopSection} >
        <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"} 
           alt="" className={classes[closeIconClass]} onClick={this.props.discard}/>
           <div className={classes.Title}>
              {this.props.systemText.plan.createPlan.accommodation.daysSelectionTitl[this.props.userLanguage]}
          </div>
    </div>
    )
  }

  render() {
    return (
        <div className={classes.Modal}>
            {this.renderCloseIcon()}
            {this.renderDays()}
            {this.renderButton()}
      </div>
    );
  }
}
export default PlanDaysOptions;
