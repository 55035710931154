import React, { Component } from "react";

import classes from "./WelcomePlacesList.module.css";

import PlacesList from "./PlacesList"

import { isMobile, isMobileOnly } from "react-device-detect"; 

let arrowIconLeft = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowLeft.png?alt=media&token=8020b73f-93be-478c-a1c3-c3cee641fbe8"
let arrowIconRight = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCircleArrowRight.png?alt=media&token=e0fca8aa-a34f-47cf-80d3-79b36b305e88"

let scrollConstant=0.5;
if (isMobile){
    scrollConstant = 1
}

class WelcomePlacesList extends Component {

    state={
        listRef:React.createRef(),
        showForwardIcon:true,
    }

    componentDidMount = () =>{
        var scrollComponent = this;
        var frame = this.state.listRef.current;
        frame.addEventListener("scroll", function(e) {
          scrollComponent.scrollToggleHandler();
        });      
        frame.scrollTo({
            left:0,
            behavior: "smooth"
          });  
          if (frame.scrollWidth>frame.offsetWidth){
            this.setState({showForwardIcon:true})
        }
        else {
            this.setState({showForwardIcon:false})
        }
    }
  
    scrollToggleHandler = () =>{
        var frame = this.state.listRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        if (scrollLeft>=(frame.scrollWidth-frame.offsetWidth-10)){
            this.setState({showForwardIcon:false})
            
        }
      
        else{
            this.setState({showForwardIcon:true})
        }
        this.setState({imageScrollLeft:scrollLeft})
       
        if (scrollLeft>5){
            this.setState({showBackIcon:true})
        }
        else{
            this.setState({showBackIcon:false})
        }
    }

    arrowLeftHandler = () =>{
        var frame = this.state.listRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let leftValue =0;
        let val =1
        if (this.props.RTL){
            val = -1
        }
        if ((scrollLeft-(window.innerWidth)*scrollConstant)>0){
            leftValue =(scrollLeft-(window.innerWidth)*scrollConstant)
        }
        frame.scrollTo({
            left:val*leftValue,
            behavior: "smooth",
            duration:500
          });

         /*  this.props.writeAcitivityLog({
            activityKey:"EXPERIENCE_IMAGES_ARROW",
            userScreen:this.props.selectedContentWall.toUpperCase()
        }) */
    }

    arrowRightHandler = () =>{
        var frame = this.state.listRef.current;
        let scrollLeft = Math.abs(frame.scrollLeft)
        let val =1
        if (this.props.RTL){
            val = -1
        }
        let leftValue =scrollLeft+(window.innerWidth)*scrollConstant;
        frame.scrollTo({
            left:val*leftValue,
            behavior: "smooth",
            duration:500
          });

         /*  this.props.writeAcitivityLog({
            activityKey:"EXPERIENCE_IMAGES_ARROW",
            userScreen:this.props.selectedContentWall.toUpperCase()
        }) */
    }

    render(){
        let leftArrowClass = "ArrowLeft"
        let rightArrowClass = "ArrowRight"
        let rotateArrow = ""
        if (this.props.RTL){
            rotateArrow = "RotateArrow"
            leftArrowClass = "ArrowLeftRTL"
            rightArrowClass = "ArrowRightRTL"
        }
        return(
            <div className={classes.Wrapper}>
                {this.state.showBackIcon ?(
                        <img className={[classes.Arrow,classes[leftArrowClass],classes[rotateArrow]].join(" ")} alt = "" src={arrowIconLeft} onClick={this.arrowLeftHandler}/>):null}
                <div className={classes.SectionContent}  ref={this.state.listRef}>
                <PlacesList
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}
                    loggedUser = {this.props.loggedUser}
                    loginHandler = {this.props.loginHandler}
                    viewHandler = {(index) =>{  
                        if (this.props.indexViewHandler){
                            this.props.viewHandler(index)
                        }
                        else{
                            let place = this.props.placesList[index];
                            this.props.viewPlaceHandler(place,"Place");
                        }
                        
                    }}
                    addToPlan={(index) =>{  
                        let place = this.props.placesList[index];
                        this.props.addToPlan(place,"Place");
                    }}
                    addPlaceToFavorite = {(index) =>{  
                        let place = this.props.placesList[index];
                        this.props.addPlaceToFavorite(place,"Place");
                    }}
                    removePlaceFromFavorite = {(index) =>{  
                        let place = this.props.placesList[index];
                        this.props.removeFromFavoriteHandler(place,"Place");
                        }}
                    updateFavoriteTags = {(index) =>{  
                        let place = this.props.placesList[index];
                        this.props.updateFavoriteTagsHandler(place,"Place");
                        }}
                    shareExperience = {(index) =>{  
                        let place = this.props.placesList[index];
                        this.props.shareExperience("Experience",place);
                        }}
                    favoritePlacesIDs={this.props.favoritePlacesIDs}
                    hoverHandler={()=>{return null}}
                    placesList={this.props.placesList}
                    placeHolder = {this.props.placeHolder}

                    categoriesTranslator = {this.props.categoriesTranslator}
                    locationsTranslator = {this.props.locationsTranslator}
                    viewType={"WelcomeList"}
                    user={this.props.user} 
                    discoverSearchLanguage={this.props.discoverSearchLanguage}
                    selectedContentWall={this.props.selectedContentWall}/>

            </div>
            {this.state.showForwardIcon ?(
                <img src={arrowIconRight} className={[classes.Arrow,classes[rightArrowClass],classes[rotateArrow]].join(" ")} alt="" onClick={this.arrowRightHandler}/>):null}
        </div>
        )
    }

}

export default WelcomePlacesList;