import React, { Component } from "react";
import classes from "./ContactForm.module.css";

import ModalTopSection from "../../UI/Misc/ModalTopSection"
import OptionSelection from "../OptionSelection/OptionSelection"

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {  EditorState , convertToRaw, convertFromRaw} from 'draft-js';

import { isMobile } from "react-device-detect";

let PlanEXp =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FPlanEXp.png?alt=media&token=acf0f2c9-2a4d-4bf1-b533-4a7938107207";
let facebookIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FNavigation%2FFacebookIcon%20(1).png?alt=media&token=bc6bb58d-1b10-4c9a-aae7-2647afb9a047"

export default class ContactForm extends Component {
  
state={
    subject:this.props.subject,
    requestContent: "",
    displayName:"",
    userEmail:"",
    requestTopic:""
}

componentDidMount = () =>{
  if (this.props.loggedUser){
    this.setState({displayName:this.props.user.displayName,
      userEmail:this.props.user.userEmail})
    this.validateEmal(this.props.user.userEmail)
  }
  if (this.props.selectedTopic!==undefined){
    this.setState({requestTopic:this.props.selectedTopic, topicSelected:true})
  }
  if (this.props.placeID!==undefined){
    this.setState({placeName:this.props.placeName,placeID:this.props.placeID})
  }
}

displayNameHandler=event =>{
  if (event.keyCode === 27) {
  this.setState({
    displayName: ""
  });
  return;
  }
  //In case user pressed 'enter' key, add the tag to experience tags array
  //Use the selectTagSuggestion function for that
  if (event.key === "Enter") {
  //In case user pressed 'enter' with blank input, do nothing
  if (event.target.value === "") return;
  let displayName= event.target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  this.setState({ displayName: displayName});
  }
  let displayName= event.target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  this.setState({ displayName: displayName});
}



emailHandler=event =>{
    if (event.keyCode === 27) {
    this.setState({
      email: ""
    });
    return;
    }
    //In case user pressed 'enter' key, add the tag to experience tags array
    //Use the selectTagSuggestion function for that
    if (event.key === "Enter") {
    //In case user pressed 'enter' with blank input, do nothing
    if (event.target.value === "") return;
    this.setState({ userEmail: event.target.value });
    this.validateEmal(event.target.value)
    }
    this.setState({ userEmail: event.target.value });
  }

  
  validateEmal = (userEmail) =>{
    let regx= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regx.test(userEmail))
    {
      this.setState({invalidEmail:false})
    }
    else{
    this.setState({invalidEmail:true})}
  }


sendHandler=()=>{
  let date = new Date();
  let timestamp = date.getTime();
  let request = {
    requestID: this.props.user.userID+"_"+timestamp,
    createdDate:timestamp,
    userID: this.props.user.userID,
    displayName:this.state.displayName,
    userEmail: this.state.userEmail,
    requestTopic: this.state.requestTopic,
    requestContent: /* JSON.stringify(convertToRaw(this.state.requestContent.getCurrentContent())) */this.state.requestContent
  }
  if (this.props.placeID!==undefined){
    request.placeID = this.props.placeID
  }
  this.props.sendRequest(request);

  this.props.writeAcitivityLog({
    activityKey:"SEND_CONTACT_REQUEST",
    userScreen:"CONTACT_FORM",
    requestTopic:this.state.requestTopic
  })

  this.props.discard();
}


    renderLogo = () =>{
      return(
          <div className={classes.LogoSection}>
              <img src={PlanEXp} alt="" className={classes.Logo}/>
          </div>
      )
    }

    renderUserEmail = () =>{
      return(
        <div className={classes.InputField}>
                <input 
                    placeholder= {this.props.systemText.contactForm.emailPlaceholder[this.props.userLanguage]}
                    className={classes.InputText}
                    value={this.state.userEmail}
                    onChange={this.emailHandler}
                    onKeyDown={this.emailHandler}
                    onKeyPress={this.emailHandler}
                    onBlur={()=>{this.validateEmal(this.state.userEmail)}}
                />
                {this.renderEmailError()}
            </div>
      )
    }

    renderUserDisplayName = () =>{
      return(
        <div className={classes.InputField}>  
          <input 
            placeholder={this.props.systemText.contactForm.displayNamePlaceholder[this.props.userLanguage]}
            className={classes.InputText}
            value={this.state.displayName}
            onChange={this.displayNameHandler}
            onKeyDown={this.displayNameHandler}
            onKeyPress={this.displayNameHandler}
          />
        </div>
      )
    }


    renderSendButton = () =>{
      let validRequest = false;
      if (!this.state.invalidEmail && this.state.requestContent!=="" &&
        this.state.displayName!==""&&this.state.topicSelected){
          validRequest = true; 
        }
      if (validRequest){
        return(   
          <div className={classes.Send} onClick={this.sendHandler}>
                {this.props.systemText.contactForm.sendButton[this.props.userLanguage]}
          </div>)
      }
      else{
        return(   
          <div className={classes.SendFade}>
              {this.props.systemText.contactForm.sendButton[this.props.userLanguage]}
        </div>)
      }
    }

    renderEmailError = () =>{
        if (this.state.invalidEmail){
          return(
            <div className={classes.ErrorMessage}>
             {this.props.systemText.contactForm.emailErrorMessage[this.props.userLanguage]}
            </div>
          )
        }
      }

      renderPlaceName = () =>{
        if (this.props.placeName!==undefined){
          let message = this.props.systemText.contactForm.requestPlace[this.props.userLanguage]+this.props.placeName
          return(
            <div className={classes.RequestPlaceName}>
              {message}
            </div>
          )
        }
      }

      renderRequestTopic = () =>{
        let currentSelection = ""
        if (this.state.requestTopic!==""){
          currentSelection = this.props.systemText.contactForm.topics[this.state.requestTopic][this.props.userLanguage]
        }
        let optionsList = Object.values(this.props.systemText.contactForm.topics)
        if (!this.props.loggedUser){
          //remove the option to delete user
          const deleteOptionIndex = optionsList.findIndex(option => {
            return option.id === "deleteUser";
          }); 
          optionsList.splice(deleteOptionIndex,1)
        }
         return(
           <div className={classes.TopicSection}>
             <div className={classes.TopicHeader}>
               {this.props.systemText.contactForm.topic[this.props.userLanguage]}
             </div>
             <div className={classes.TopicOptions}>
             <OptionSelection
                currentSelection={currentSelection}
                optionsList={optionsList}
                updateSelectedOption={option=>{this.setState({requestTopic:option.id,topicSelected:true})}}
                required={true}
                onlyFromList = {true}
                showCurrentSelection = {true}
                systemText={this.props.systemText}
                userLanguage={this.props.userLanguage}
                RTL = {this.props.RTL}
                inputLanguage = {this.props.inputLanguage}
                detectInputLanguage = {this.props.detectInputLanguage}
                />
             </div>
            
           </div>
         )
      }

      renderRequestContent = () =>{
       return(
        <div className={classes.RequestSection}>
       {/*  <Editor
          toolbarHidden={true}
          stripPastedStyles={true}
          placeholder={
            this.props.systemText.contactForm.contentPlaceholder[this.props.userLanguage]
          }
          editorClassName={classes.RequestInputField}
          editorState={this.state.requestContent}
          onEditorStateChange={request =>{this.setState({requestContent:request})}}
        /> */}
       <textarea
          resize={"none"}
          placeholder={this.props.systemText.contactForm.contentPlaceholder[this.props.userLanguage]}
          className={classes.RequestInputField}
          value = {this.state.requestContent}
          onChange={e =>{this.setState({requestContent:e.target.value})}}
        />
      </div>
       )
      }
  
    renderBody = () =>{
      return(
        <div className={classes.Body}>
            {this.renderLogo()}
           
            {this.renderTitle()}
            <div className={classes.Content}>
              {this.renderUserEmail()}
              {this.renderUserDisplayName()}
              {this.renderPlaceName()}
              {this.renderRequestTopic()}
              {this.renderRequestContent()}
            </div>
            <div className={classes.ContactOptions}>
              {this.renderOurEmail()}
              {this.renderFacebookLink()}
            </div>
            {this.renderSendButton()}
           
            
        </div>
    )
    }

    renderFacebookLink = () =>{
      return(
        <div className={classes.FacebookLink}>
          <a href="https://www.facebook.com/PlanEXperiences" target="_blank"
          onClick={()=>{
            this.props.writeAcitivityLog({
              activityKey:"FACEBOOK_ICON_CLICKED",
              userScreen:"CONTACT_FORM"
            })
          }}>
            <img src={facebookIcon} alt="" className={classes.ContactIcon}/>
          </a>
        </div>
      )
    }

    renderOurEmail = () =>{
      return(
        <div className={classes.OurEmailSection}>
         {"support@planexp.com"}
        </div>
      )
    }

    renderTitle = () =>{
        return(<div className={classes.Title}>   
            {this.props.systemText.contactForm.title[this.props.userLanguage]}</div>)
    }

    renderTopSection = () =>{
      if (isMobile){
        return (
          <div className={classes.TopSection}>
             <ModalTopSection
             title={""}
             discard={this.props.discard}
             showButton={false}
             RTL = {this.props.RTL}
             />
         </div>
       );
      }
        
    }

  render() {
    let directionClass = ""
    if (this.props.RTL){
        directionClass="RTL"
    }
    return (
      <div className={[classes.Modal,classes[directionClass]].join(" ")}>
          {this.renderTopSection()}
          {this.renderBody()}
      </div>
    );
  }
}