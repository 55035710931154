import React, { Component } from "react";


import classes from "./TaggingSection.module.css";

import Tag from "../../UI/Tag/Tag"

let plusIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCreateIcon.png?alt=media&token=7d74a6ca-d7a9-4552-98bb-2e9e1662ed79"
let  plusIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCreateIconFade.png?alt=media&token=5911e30e-da8a-44e9-aa78-3bd3f1e450ac"


class TaggingSection extends Component {
  state = { tagSuggestions: [],tagInput: "",};

  componentDidMount = () => {
    this.setState({
      tagInput: "",
      tagSuggestions: [],
      selectedTags: [...this.props.selectedTags]
    });
    }

    deleteTag = index => {
      let selectedTags = this.state.selectedTags;
      selectedTags.splice(index, 1);
      this.setState({ selectedTags: selectedTags });
      this.props.updateTags(selectedTags, this.state.entireInput);
    };

    tagInputHandler = (event) =>{
      if (event.keyCode === 27) {
        this.setState({
          tagInput: "",
          showSuggestions: false,
          tagSuggestions: []
        });
        return;
      }
      if (event.key === "Enter" || event.keyCode===9) {
        //In case user pressed 'enter' with blank input, do nothing
        event.preventDefault();
        let tagValue = event.target.value.replace(/(\r\n|\n|\r)/gm,"");
        if (tagValue.length===0 ) {
          this.setState({
            tagInput: "",
            showSuggestions: false,
            tagSuggestions: []
          });
          return;}
       
        this.selectTagSuggestion(event.target.value.trim());
        return;
      }
      else if (event.target.value.slice(-1)===','){
        let tagValue = event.target.value.replace(/(\r\n|\n|\r)/gm,"");
        let updatedValue = tagValue.slice(0, -1);
        if (tagValue.length===0 ) {
          this.setState({
            tagInput: "",
            showSuggestions: false,
            tagSuggestions: []
          });
          return;}
       
        this.selectTagSuggestion(updatedValue.trim());
        return;
      } else {
        //Get suggestions for user existing tags based in sub-input
        let tagSuggestions = this.getTagSuggestions(event.target.value.trim());
        this.setState({ tagInput: event.target.value });
  
        //In case user entered some characters, the suggestions should be shown
        if (event.target.value !== "" && tagSuggestions.length !== 0) {
          this.setState({
            showSuggestions: true,
            tagSuggestions: tagSuggestions
          });
  
          //If no suggetions exist based on entered value, don't show the suggestions
        } else if (tagSuggestions.length === 0) {
          this.setState({
            showSuggestions: false,
            tagSuggestions: []
          });
        } else {
          //Input is blank
          this.setState({
            showSuggestions: false,
            tagSuggestions: []
          });
        }
      }
  
      this.setState({ tagInput: event.target.value });
    }

    selectTagSuggestion = tag => {
      if (tag.length <3) return;
     
        let selectedTags = [...this.state.selectedTags];
        if (selectedTags.includes(tag)) {
          this.setState({ tagInput: "", tagSuggestions: [] });
          return;
        }
        selectedTags.push(tag);
        selectedTags = selectedTags.map(tag=>{return tag.toLowerCase()});
        let uniqueValues = Array.from(new Set(selectedTags));
        for (let i=0; i< uniqueValues.length ; i++){
          uniqueValues[i]= uniqueValues[i].replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        }
        this.props.updateTags(uniqueValues, "");
        this.setState({
          selectedTags: uniqueValues,
          tagInput: "",
          tagSuggestions: [],
          showRecentTags: false,
          showBackdrop: false
        });
      
  
      this.nameInput.focus();
    };

    getTagSuggestions = value => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0
        ? []
        : this.props.userTags.filter(
            searchTags => searchTags.toLowerCase().includes(inputValue)
          );
    };

    renderTextArea = () =>{
    return(
        <div className={classes.TextArea}>
            <input className={classes.TextInput}
             ref={input => {
              this.nameInput = input;
            }}
                value={this.state.tagInput}
                onChange={this.tagInputHandler}
                onKeyPress={this.tagInputHandler}
                onKeyDown={this.tagInputHandler}
                placeholder={this.props.placeholder}
            />
            {this.state.tagInput.length<3?(
                <img alt="" src={plusIconGrey} className={classes.PlusIcon}/>
            ):(
                <img alt="" src={plusIcon} className={classes.PlusIcon} onClick={()=>{ this.selectTagSuggestion(this.state.tagInput.trim())}}/>
            )}
            
        </div>
    )
  }

  renderTagsList = () =>{
    if (this.state.selectedTags!==undefined){
        return(
            <div className={classes.TagsList}>
                {this.state.selectedTags.map((tag, index) => (
                    <Tag
                    key={index}
                    text={tag}
                    removable={true}
                    style={"FavoritePreviewTag"}
                    remove={() => {this.deleteTag(index)}}
                    RTL = {this.props.RTL}
                  />
            ))}
            </div>
        )
    }
   else{
       return null;
   }
  }

  render() {
    let bodyRTL = ""
    if (this.props.RTL){
      bodyRTL = "BodyRTL"
    }
    return <div className={[classes.Body,classes[bodyRTL]].join(" ")}>
        {this.renderTextArea()}
        {this.renderTagsList()}
        </div>;
  }
}
export default TaggingSection;