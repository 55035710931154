import React from "react";
import classes from "./FilterButton.module.css";


const FilterButton = props => {

  return (
    <div>
        {props.selected?(
          <div className={classes.SelectedFilter} onClick={props.clicked}>
              {props.text}
        </div>
        ):
        (
      <div className={[classes.GreyedFilter,classes[props.type]].join(" ")} onClick={props.clicked}>
         {props.text}
        </div>
        )}
    </div>
  );
};

export default FilterButton;
