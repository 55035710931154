import React, { Component } from "react";
import classes from "./MainPage.module.css";

import LoadDatabse from "../../Backend/LoadDatabase";
import WriteDatabase from "../../Backend/WriteDatabase";
import Backdrop from "../../Components/UI/Backdrop/Backdrop"
import NavBar from "./NavBar"
import WelcomeWall from "../Wall/WelcomeWall";
import Discover from "../Wall/Discover";
import Favorite from "../Wall/Favorite"
import MyContent from "../Wall/MyContent"
import SearchFunctions from "../../Components/Functions/SearchFunctions"
import CreatePlace from "../Content/Places/PlaceEditor";
import ViewPlace from "../Content/Places/ViewPlace";
import AddToFavorite from "../../Components/Complex/AddToFavorite/AddToFavorite"
import CreateMenu from "../../Components/Complex/CreateMenu/CreateMenu"
import DBScripts from "../../Backend/DBScripts";
import AdminBoard from "../Wall/AdminBoard"
import UsersLocator from "../../Components/Complex/Users/UsersLocator"
import InformationModal from "../../Components/UI/Modals/InformationModal"
import ConfirmModal from "../../Components/UI/Modals/ConfirmationModal"
import UpdateFunctions from "../../Components/Functions/UpdateFunctions";
import ContactForm from "../../Components/Complex/ContactForm/ContactForm"
import TripPlanOverviewEditor from "../Content/TripPlans/TripPlanOverviewEditor"
import ViewTripPlan from "../Content/TripPlans/ViewTripPlan";
import AddToPlan from "../../Components/Complex/AddToPlan/AddToPlan"
import ExperienceEditor from "../Content/Experiences/ExperienceEditor"
import UserProfileWall from "../Wall/UserProfileWall";
import ImageFullscreenViewer from "../../Components/Complex/Images/ImageFullscreenViewer"
import DetectInputLanguage from "../../Components/Functions/DetectInputLanguage"
import TripTemplateCreator from "../../Components/Functions/TripTemplateCreator"
import CreateTripTemplate from "../Content/TripPlans/CreateTripTemplate"
import ModalTopSection from "../../Components/UI/Misc/ModalTopSection"
import TextButton from "../../Components/UI/Buttons/TextButton"

import { isMobile, isMobileOnly } from "react-device-detect";
import WelcomeWizard from "../../Components/Complex/WelcomeWizard/WelcomeWizard";
import WelcomeWizardV2 from "../../Components/Complex/WelcomeWizard/WelcomeWizardV2";

let saving = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FSavingGif.png?alt=media&token=34dfb2f2-8c58-4ffc-8d8a-b92919286d13"


export default class MainPage extends Component {

    state={
   /*    showWelcomeWizard:true, */
        //--------GENERAL----------
        loadingPreloadData:true,
        selectedContentWall: "WelcomePage",
        selectedContentType: "Places",

        //-------USERS-------
        allUsers:[],
        myFriends:[],
        usersIFollow:[],
        userExperiencesFeed:[],

        //-----DISCOVER PLACES------
        //--- for creation or edit
        systemCategories:[],
        allSystemCategories: [],
        placesCharacteristicsData:{},
        
        
        //discover places - search, filter, etc
        userCategoriesScore:{},
        locationsTree:{},
        slimPlacesByCountry:{},
        placesListForPlaceCreation:{},
        filteredPlacesCountry:"",
        discoverCountryPlaces:[],
        discoverLocationFilteredPlaces:[],
        filteredDiscoverPlaces:[],
        filteredDiscoverPlacesWithChars:[],
        discoverPlacesLocationFilterTags:[],
        discoverPlacesLocationsSearchTags:[],
        discoverPlacesAvailableCategories: [],
        discoverPlacesAvailableMainCategories: [],
        discoverPlacesCategoriesFilterTags:[],
        discoverPlacesSelectedMainCategory:[1,0,0,0,0,0,0],
        discoverPlacesNameFilterTag:[],
        discoverPlacesCharacteristicsFilter: {},
        discoverPlacesDaysFilter: [0,0,0,0,0,0,0],
        discoverPlacesAdditionalTagsSearchTags:[],
        discoverPlacesAdditionalTagFilterTag :[],

        //-----FAVORITE PLACES-----
        favoritePlacesIDs:[],
        favoritePlaces:[],
        favoritePlacesFilterTags:[],


        //------USER CREATED PLACES-----
        userCreatedPlaces:[],
        filteredUserCreatedPlaces:[],
        userCreatedPlacesFilterTags:[],


        //-----USER CREATED PLANS-------
        userCreatedTripPlans: [],
        filteredUserCreatedTripPlans: [],
        userCreatedTripPlansFilterTags: [],
        userCreatedTripPlansSearchTags:[],


        //-----FAVORITE PLANS-----
        favoritePlansIDs:[],



        //-----USER CREATED EXPERIENCES
        userExperiences:[],
        filteredUserExperiences:[],
        userExperiencesFilterTags:[],
        userExperiencesSearchTags:[],



        //-----SELECTED USER--------
        selectedUserTripPlans:[],
        filteredSelectedUserTripPlans: [],
        selectedUserTripPlansFilterTags: [],
        selectedUserTripPlansSearchTags:[],

        selectedUserExperiences:[],
        filteredSelectedUserExperiences:[],
        selectedUserExperiencesFilterTags:[],
        selectedUserExperiencesSearchTags:[],



        //--------ADMIN-----------
        potentialPlacesToApprove:[],
        placesEditSuggestions:[],


        //------DISCVOER TRIP PLANS-----
        discoverPublicTripPlans: [],
        discoverTripsLocationSearchTags:[], 
        discoverTripsCountriesFilters: [],
        discoverTripsLocationFilters: [],
        discoverTripsCategoriesFilters: [],
        discoverTripsStyleFocus:[],
        discoverPlansDurationLimits:{min:1,max:20},
        discoverPlansDurationFilter:[1,20],
        discoverPlansParticipants:[1,0,0],
        discoverTripsParticipantsTags :["Adults"],
        discoverTripsLimit:30,
        discoverTripsAdditionalSearchTags:[],
        discoverTripsPlanAdditionalTagsFilters: [],
        filteredDiscoverPublicTripPlans:[],
        discoverTripsCombineLocations: false,

        generatedSuggestedTrips:[],
        generateTripsDestinations:[],
        recommendedDestinations:[],

        //----FAVORITE TRIPS------
        favoriteTripPlans:[],
        filteredFavoriteTripPlans:[],
        favoriteTripPlansFilterTags: [],
        favoriteTripPlansSearchTags:[],
        favoriteTripsIDs:[],
    }

    //======== FUNCTIONS===========
    componentDidUpdate = prevProps =>{
        if (this.props.userInfo!==prevProps.userInfo){
            if (this.props.userInfo!==null && !this.state.userInfoInitialized){
                this.calculateUserCategoriesScore(this.props.userInfo.userBehaviorProfile,this.props.userInfo.userSettings)
                this.userLatestViewedPlacesLoadHandler()
                this.userLatestTripCachHandler();
                setTimeout(e => {
                  this.loadExperiencesFeed()
                }, 500); 
            }
            
        }
        if (this.props.showWelcomeWizard!==prevProps.showWelcomeWizard){
          if (this.props.showWelcomeWizard){
            this.setState({showWelcomeWizard:true,showNullBackdrop:true})
          }
        }
    }

   

    componentDidMount = () =>{
      if (this.props.showWelcomeWizard){
        this.setState({showWelcomeWizard:true,showNullBackdrop:true})
      }
      
      //this.checkAvailableTutorialMessages()
      this.loadTranslators();
      if (this.props.userInfo!==null&&this.props.userInfo!==undefined){
          this.setState({userInfoInitialized:true})
          this.calculateUserCategoriesScore(this.props.userInfo.userBehaviorProfile,this.props.userInfo.userSettings)
          this.userLatestViewedPlacesLoadHandler();
          this.userLatestTripCachHandler();
          if (this.props.loggedUser){
            this.triggerUserLoggedLoadingFunctions();
            setTimeout(e => {
              this.loadExperiencesFeed()
            }, 500); 
          } 
      }
      else{
        //no data about user - probably anonymous
        this.refs.loadDatabase.loadRecommendedPlaces()
      }
      //load anonymous user cached trip
      if (!this.props.loggedUser){
        this.userLatestTripCachHandler();
      }
      this.refs.writeDatabase.writeAcitivityLog({activityKey:this.props.loginActivity})

      //create not logged user cached cloned trip
      if (localStorage.getItem('cachedTrip')!==null){
        let plan =JSON.parse(localStorage.getItem('cachedTrip'))
        let editedTrip = false
        if (plan.editedTrip!==undefined){
          editedTrip=plan.editedTrip
          delete plan.editedTrip
        }
        delete plan.systemGenerated
        delete plan.userAction
        let date = new Date();
        let timestamp = date.getTime();
        plan.planID = timestamp.toString()+"_"+this.props.user.userID;
        plan.owner = {
          ownerID: this.props.user.userID,
          ownerName: this.props.user.displayName,
          ownerPhoto: this.props.user.photoUrl,
          ownerEmail: this.props.user.userEmail
        };
        let editorUser = {...this.props.user};
        editorUser.planLastModified=timestamp;
        plan.planEditors =[editorUser];
        this.newTripPlanCreationHandler(plan,true,editedTrip)
        localStorage.removeItem('cachedTrip')
      }
    
    }


    //------USERS MESSAGES ---------

    checkAvailableTutorialMessages = () =>{
      let showCreateNewPlacesMessage = false
      let showSaveToFavoriteMessage = false
      if (this.props.userInfo!==null&&this.props.userInfo!==undefined){

      }
      else{
        if (localStorage.getItem('showCreateNewPlacesMessage')===null){
          showCreateNewPlacesMessage = true
        }
        if (localStorage.getItem('showSaveToFavoriteMessage')===null){
          showSaveToFavoriteMessage = true
        }
      }
    }
    //-------LOADING FUNCTIONS---------

    loadTranslators = () =>{
      if (localStorage.getItem('categoriesTranslator')!==null){
        let categoriesTranslator =JSON.parse(localStorage.getItem('categoriesTranslator'))
        this.setState({categoriesTranslator:categoriesTranslator})
      }
      if (localStorage.getItem('locationsTranslator')!==null){
        let locationsTranslator =JSON.parse(localStorage.getItem('locationsTranslator'))
        this.setState({locationsTranslator:locationsTranslator})
      }
    }

    triggerUserLoggedLoadingFunctions = () =>{
        this.refs.loadDatabase.loadFavoritePlacesIDs()
        this.refs.loadDatabase.loadFavoriteTripsIDs()
    }

    userLatestTripCachHandler = () =>{
      let latestUserCreatedTripPlan
      if (localStorage.getItem('latestUserCreatedTripPlan')!==null){
       
        latestUserCreatedTripPlan = JSON.parse(localStorage.getItem('latestUserCreatedTripPlan'))
        if (latestUserCreatedTripPlan!==null){
          this.setState({latestUserCreatedTripPlan:latestUserCreatedTripPlan})
        }
        
      }
      
    }

    userLatestViewedPlacesLoadHandler = async () =>{
        if (this.props.userInfo.statistics!==undefined){
            if (this.props.userInfo.statistics.latestViewedPlaces!==undefined){
                let latestViewedPlaces =[]
                if (localStorage.getItem('latestViewedPlaces')!==null){
                  latestViewedPlaces = await JSON.parse(localStorage.getItem('latestViewedPlaces'))
                }
                let uniquePlaces = []
                let includedPlacesIDs = [];
                for (let i =0 ;i<latestViewedPlaces.length ; i++){
                  if (!includedPlacesIDs.includes(latestViewedPlaces[i].placeID)){
                    includedPlacesIDs.push(latestViewedPlaces[i].placeID)
                    uniquePlaces.push(latestViewedPlaces[i])
                  }
                }
                this.setState({latestViewedPlaces:uniquePlaces})
                let userLastViewedPlacesIDs = uniquePlaces.map(place=>{return place.placeID})
                this.refs.loadDatabase.getUserLatestViewedPlaces(userLastViewedPlacesIDs)
                await this.setState({userLastViewedPlacesIDs:userLastViewedPlacesIDs})
            }
            else{
              this.setState({latestViewedPlaces:[]})
            }
        }
        else{
          this.setState({latestViewedPlaces:[]})
        }
        
    }

    loadExperiencesFeed = () =>{
    
       if (localStorage.getItem('userExperiencesFeed')!==null&&localStorage.getItem('followingUsersIDs')!==null){
        let userExperiencesFeed = JSON.parse(localStorage.getItem('userExperiencesFeed'));
        let followingUsersIDs = JSON.parse(localStorage.getItem('followingUsersIDs'));
        this.setState({
          followingUsersIDs: followingUsersIDs,
          userExperiencesFeed:userExperiencesFeed,
          experiencesFeedLoadedLimit:5,
          loadedMoreExperiencesToFeed:true
        })
      }
      this.refs.loadDatabase.loadFollowExperiences()
    }

    logoHandler = () =>{
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"HOME_BUTTON_CLICKED",
        userScreen:this.state.selectedContentWall.toUpperCase()
      })
        
      this.setState({selectedContentWall:"WelcomePage"})
      if (this.state.selectedContentType==="Experiences"){
        this.setState({selectedContentType:"Places"})
      }
    }

    loginHandler = () =>{
      //in case of anonymous user having a created trip, ask him if he would like to keep it
      let latestUserCreatedTripPlan
      if (localStorage.getItem('latestUserCreatedTripPlan')!==null){
       
        latestUserCreatedTripPlan = JSON.parse(localStorage.getItem('latestUserCreatedTripPlan'))
        if (latestUserCreatedTripPlan!==null){
            //ask user if to keep the trip
            this.setState({showKeepCachedTripBeforeLogin:true})
        }
        else{
          this.props.loginHandler()
        }
        
      }
      else{
        this.props.loginHandler()
      }
    }

    calculateUserCategoriesScore = (userBehaviorProfile,userSettings) =>{
        let interestFactor=10;
        let dislikeFactor = -50
        if (userBehaviorProfile!==undefined){
          if (userBehaviorProfile.userCategoriesProfile!==undefined){
            let categoriesIDs=Object.keys(userBehaviorProfile.userCategoriesProfile);
            let categoires=Object.values(userBehaviorProfile.userCategoriesProfile);
            let userCategoriesScore={};
            for (let i=0; i<categoriesIDs.length ; i++){
              let categoryScore = {id:categoriesIDs[i]}
              let searchScoreFactor=1;
              let viewedScoreFactor=1;
              let experiencedScoreFactor=1;
              let favoriteScoreFactor=1;
              let score=0;
              if(categoires[i].searchedCategory!==undefined){
                score = score+searchScoreFactor*categoires[i].searchedCategory.count
              }
              if(categoires[i].viewedPlace!==undefined){
                score = score+viewedScoreFactor*categoires[i].viewedPlace.count
              }
              if(categoires[i].experiencedPlace!==undefined){
                score = score+experiencedScoreFactor*categoires[i].experiencedPlace.count
              }
              if(categoires[i].addedToFavoritePlace!==undefined){
                score = score+favoriteScoreFactor*categoires[i].addedToFavoritePlace.count
              }
              categoryScore.score=score;
              userCategoriesScore[categoriesIDs[i]]=categoryScore
            }
            if (userSettings.userInterestCategories!==undefined){
              for (let i=0 ; i<userSettings.userInterestCategories.length ; i++){
                let catID = userSettings.userInterestCategories[i]
                if (userCategoriesScore[catID]!==undefined){
                    userCategoriesScore[catID].score+=interestFactor
                }
                else{
                    userCategoriesScore[catID] = {id:catID ,score:interestFactor}
                }
              }
            }
            if (userSettings.userDislikedCategories!==undefined){
              for (let i=0 ; i<userSettings.userDislikedCategories.length ; i++){
                let catID = userSettings.userDislikedCategories[i]
                if (userCategoriesScore[catID]!==undefined){
                    userCategoriesScore[catID].score+=dislikeFactor
                }
                else{
                    userCategoriesScore[catID] = {id:catID ,score:dislikeFactor}
                }
              }
            }
            localStorage.setItem('userCategoriesScore',JSON.stringify(userCategoriesScore));
            this.setState({userCategoriesScore:userCategoriesScore})
            this.refs.loadDatabase.loadRecommendedPlaces(Object.values(userCategoriesScore),userSettings.userDislikedCategories)
          }
          else{
            this.setState({userCategoriesScore:[]})
            let userDislikedCategories = []
            if (userSettings.userDislikedCategories!==undefined){
              userDislikedCategories = userSettings.userDislikedCategories
            }
            this.refs.loadDatabase.loadRecommendedPlaces([],userDislikedCategories)
          }
        }
        else{
            this.setState({userCategoriesScore:[]})
            this.refs.loadDatabase.loadRecommendedPlaces([],[])
        }
    }

    loadFinishedHandler = () => {
        this.setState({loadingPreloadData: false})
        /* if (this.props.firstLogin){
          this.refs.navBar.settingsHandler();
        } */
    }

    updateLoadData = async (data,scenario) =>{
        switch(scenario){
            case "UsersActivity":{
              let date = new Date();
              let timestamp = date.getTime();
              await this.setState({usersActivity:data})
              this.setState({selectedContentWall:"AdminWall", usersActivityLastUpdate:timestamp})
              break;
            }
            case "ActivitiesLog":{
              await this.setState({activitiesLog:data})
              break;
            }
           case "PlacesListForPlaceCreation":{
            this.setState({placesListForPlaceCreation:data})
            break;
           }
            case "UsersRequests":{
              this.setState({usersRequests:data})
              break;
            }
            case "DiscoverPlacesLocationsSearchTags":{
                await this.setState({placesLocationSearchTags:data})
                if (this.state.waitingToExplore){
                    this.userCountryHandler()
                }
                break;
            }
            case "PreloadData":{
                this.setState({preloadData:data})
                break;
            }
            case "PlacesCharacteristicsData":{
                this.setState({placesCharacteristicsData:data})
                break;
            }
            case "CategoriesTranslator":{
              await this.setState({categoriesTranslator:data})
              break;
            }
            case "LocationsTranslator":{
              await this.setState({locationsTranslator:data})
              break;
            }
            case "DiscoverTripsLocationSearchTags":{
              await this.setState({discoverTripsLocationSearchTags:data})
              break;
            }
            
            case "RecommendedDestinationsByCountry":{
              await this.setState({recommendedDestinations:data})
              break;
            }
            case "GenerateTripsDestinations":{
              await this.setState({generateTripsDestinations:data})
              break;
            }
            case "DiscoverTripsAdditionalSearchTags":{
              let tags = []
              for (let i=0 ; i<data.length ; i++){
                await this.refs.detectInputLanguage.detectLanguage(data[i]).then(result => {
                  let tag = {}
                  tag[result] = data[i]
                  tag.placeTag = true
                  tags.push(tag)
                }); 
              }
              this.setState({discoverTripsAdditionalSearchTags:tags})
              break;
            }
            case "SystemLocationsTree":{
                let userCountryTag = JSON.parse(JSON.stringify(data[this.props.userCountry]));
                delete userCountryTag.regions
                delete userCountryTag.cities
                this.setState({locationsTree:data,userCountryTag:userCountryTag})
                break;
            }
            case "RecommendedPlacesByCategory":{
                this.setState({recommendedPlacesByCategory:data});
                break;
            }
            case "UserRecommendedPlacesByCategory":{
              this.setState({userRecommendedPlacesByCategory:data});
              break;
          }
            case "SystemCategories":{
                this.setState({systemCategories:data});
                break;
            }
            case "AllCategoriesList":{
              this.setState({allSystemCategories:data});
              break;
          }
            case "LatestViewedPlaces":{
                localStorage.setItem('latestViewedPlaces',JSON.stringify(data));
                let dbLatestsViewedPlaces = data.map(place=>{return {country:place.placeLocation.locationTree.placeCountry , placeID:place.placeID}})
                this.refs.writeDatabase.updateLatestViewedPlacesList(dbLatestsViewedPlaces)
                this.setState({latestViewedPlaces:data});
                break;
            }
            case "FavoritePlacesIDs":{
                this.setState({favoritePlacesIDs: data})
                break;
              }
            case "FavoriteTripsIDs":{
              this.setState({favoriteTripsIDs: data})
              break;
            }
            case "AllUsers":{
                this.setState({allUsers: data})
                break;
            }
            case "MyFriends":{
                this.setState({myFriends: data})
                break;
            }
            case "UserIFollow":{
              this.setState({usersIFollow: data})
              break;
          }
            case "PotentialPlacesToApprove":{
              this.setState({potentialPlacesToApprove: data})
              break;
            }
            case "PlacesEditSuggestions":{
                this.setState({placesEditSuggestions: data})
                break;
            }
            case "PotentialTripPlans":{
              this.setState({potentialTripPlans:data})
              break;
            }
            case "UserPlans":{
              this.setState({userCreatedTripPlans: data, filteredUserCreatedTripPlans: data})
              await this.refs.searchFunctions.updateTripsSearchTags(data).then(result => {
                this.setState({
                  userCreatedTripPlansSearchTags:result, allUserCreatedTripPlansSearchTags:result,
                })
                
              }); 
              if (data.length>0){
                if (localStorage.getItem('latestUserCreatedTripPlan')!==null){
       
                  let latestUserCreatedTripPlan = JSON.parse(localStorage.getItem('latestUserCreatedTripPlan'))
                  if (latestUserCreatedTripPlan!==null){
                    if (latestUserCreatedTripPlan.planID!==data[0].planID||latestUserCreatedTripPlan.planLastModified!==data[0].planLastModified){
                      localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(data[0]));
                      this.setState({latestUserCreatedTripPlan: data[0]})
                    }
                  }
                  else{
                    localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(data[0]));
                    this.setState({latestUserCreatedTripPlan: data[0]})
                  }
                }
                else{
                  localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(data[0]));
                  this.setState({latestUserCreatedTripPlan: data[0]})
                }             
              }
              else{
                localStorage.removeItem('latestUserCreatedTripPlan')
                this.setState({latestUserCreatedTripPlan: undefined})
              }
             
              break;
            }
            case "FollowingUsersIDs":{
              localStorage.setItem('followingUsersIDs',JSON.stringify(data));
              this.setState({followingUsersIDs: data})
              break;
            }
            case "UserExperiencesFeed":{
              localStorage.setItem('userExperiencesFeed',JSON.stringify(data.list));
              await this.setState({userExperiencesFeed: data.list, 
                experiencesFeedLoadedLimit:data.limit,
                loadedMoreExperiencesToFeed:true})
              let loadedLimit = this.state.experiencesFeedLoadedLimit
              await this.setState({loadedMoreExperiencesToFeed:false})
              await this.refs.loadDatabase.loadMoreExperiencesFeed(this.state.followingUsersIDs,loadedLimit+5,this.state.userExperiencesFeed).then(result => {
                this.setState({userExperiencesFeed: result.list, 
                  experiencesFeedLoadedLimit:result.limit,
                  loadedMoreExperiencesToFeed:result.loadedMoreExperiencesToFeed})
                localStorage.setItem('userExperiencesFeed',JSON.stringify(result.list.slice(0,20)));
              }); 
              break;
            }
            default: return null;
        }
    }

    //------GENERAL FUNCTIONS---------
    updateSelectedContentType = (selection) =>{
      let activityKey = ""
      if (selection==="Places"){
        activityKey = "PLACES_SELECTION"
      }
      else if (selection==="Trips"){
        activityKey = "TRIPS_SELECTION"
      }
      else if (selection==="Experiences"){
        activityKey = "EXPERIENCES_SELECTION"
      }
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:activityKey,
        userScreen:this.state.selectedContentWall.toUpperCase(),
        eventLevel:1
      })


      this.setState({selectedContentType:selection})
      if (this.state.selectedContentWall==="Discover"&&selection==="Trips"){
        this.discoverPlansHandler()
      }
    }

    discardBackdrop = () =>{
        this.setState({
            showBackdrop: false,
            showCreateMenu: false,
            showContantForm: false
        })
    }

    createNewHandler = () => {
        this.setState({
            showCreateMenu: true,
            showBackdrop: true
          });
          
        let screen = this.state.selectedContentWall.toUpperCase()
        
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"CREATE_BUTTON_CLIKCED",
          userScreen:screen
        })
      };

      contactRequestHandler = request=>{
        this.refs.writeDatabase.sendContactRequest(request)
      }

      friendsHandler = () =>{
        this.refs.loadDatabase.getAllUsers()
        this.setState({showUsersLocator:true,showNullBackdrop:true})
      }

      friendRequestHandler = user =>{
        this.refs.writeDatabase.friendRequest(user)

        let screen = ""
        if (this.state.showUsersLocator){
          screen = "FRIENDS"
        }
        else if (this.state.showUserWall){
          screen = "USERWALL"
        }
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"FRIEND_REQUEST",
          userScreen:screen
        })
      }

      followUserHandler = user =>{
        this.refs.writeDatabase.followUser(user)

        let screen = ""
        if (this.state.showUsersLocator){
          screen = "FRIENDS"
        }
        else if (this.state.showUserWall){
          screen = "USERWALL"
        }
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"FOLLOW_USER",
          userScreen:screen
        })
      }
  
      removeFriendHandler = user =>{
        this.refs.writeDatabase.removeFriend(user)

        //no decline friendship...
        let screen = ""
        if (this.state.showUsersLocator){
          screen = "FRIENDS"
        }
        else if (this.state.showUserWall){
          screen = "USERWALL"
        }
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"REMOVE_FRIEND",
          userScreen:screen
        })
        
      }

      stopFollowHandler = user =>{
        this.refs.writeDatabase.stopFollow(user)
        
        let screen = ""
        if (this.state.showUsersLocator){
          screen = "FRIENDS"
        }
        else if (this.state.showUserWall){
          screen = "USERWALL"
        }
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"UNFOLLOW_USER",
          userScreen:screen
        })
      }
  
      acceptFriendshipHandler = user =>{
        this.refs.writeDatabase.acceptFriendship(user)

        let screen = ""
        if (this.state.showUsersLocator){
          screen = "FRIENDS"
        }
        else if (this.state.showUserWall){
          screen = "USERWALL"
        }
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"ACCEPT_FRIENDSHIP",
          userScreen:screen
        })
      }

      newPlaceCreationHandler = (place) =>{
        this.refs.writeDatabase.createPlace(place);
        this.setState({quickPlaceCreationCategories:undefined,quickPlaceCreationMainCategory:undefined})

        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"SAVE_NEW_PLACE",
          userScreen:"NEW_PLACE",
          placeID:place.placeID,
          placeCountry: place.placeLocation.locationTree.placeCountry.treeID
        })
      }

      tripCreateLogin = async (plan) =>{
        let savePlan = {...plan}
        savePlan.systemGenerated = false
        savePlan.userAction = "create"
        await localStorage.setItem('cachedTrip',JSON.stringify(savePlan));
        this.props.loginHandler()
      }

      keepCreatedTrip = () =>{
        this.setState({showKeepCachedTripBeforeLogin:false})
        let plan = JSON.parse(localStorage.getItem('latestUserCreatedTripPlan'))
        this.tripCreateLogin(plan)
      }

      newTripPlanCreationHandler = async (plan,cachedTrip,editedTrip) => {
        //editedTrip meaning that user edited the suggested trip he receieved from the trip creation algorithm and saved
        //chcedTrip - meaning the function was called after user logged in and wanted to save a trip he created while not logged
        if (cachedTrip){
          await this.setState({creatingCachedTrip:true})
        }
        if (this.props.loggedUser){
          //if the user is logged then save his trip to the DB
          this.refs.writeDatabase.createTripPlan(plan);
        }
        else{
          //else save the trip only to the cach of last trip
          localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(plan));
          await this.setState({latestUserCreatedTripPlan: plan})
         this.viewAnonymousUserCachedPlanHandler(plan)
        }
        
       
        if (this.state.clonningPlan){
          this.refs.writeDatabase.updateTripCloneStats(this.state.clonedPlan.planID)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"TRIP_CLONE_SAVE",
            userScreen:"NEW_TRIP",
          })
        }
        else{
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"TRIP_CREATION_COMPLETE",
            userScreen:"NEW_TRIP",
          })
        }
        //just closing the create trip window
        this.discardCreateTripPlanAfterSave()

        if (this.state.clonningSuggestedTrip){
          this.refs.writeDatabase.saveSystemGeneratedTrip(this.state.suggestedTrip)
          this.setState({clonningSuggestedTrip:false})
        }
        if (cachedTrip){
          let savePlan = {...plan}
          savePlan.systemGenerated = true
          savePlan.userAction = "clone"
          if (editedTrip){
            savePlan.editedTrip=editedTrip
          }
          this.refs.writeDatabase.saveSystemGeneratedTrip(savePlan)
        }
      }

      newExperienceCreationHandler = experience => {
        this.refs.writeDatabase.createExperience(experience);

        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"SAVE_NEW_EXPERIENCE",
          userScreen:"NEW_EXPERIENCE",
          eventLevel:1
        })
      }

      discoverWallHandler = () =>{
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"DISCOVER_BUTTON",
          userScreen:this.state.selectedContentWall.toUpperCase(),
          eventLevel:1
        })
        this.setState({selectedContentWall: "Discover"});
        /* if (!this.state.discoverPlansLoaded){
          this.discoverPlansHandler()
        } */
      }

    favoriteWallHandler = async () =>{
          if (!this.state.favoritePlacesLoaded&&!this.state.favoriteTripPlansLoaded){
            let favoritePlaces = [];
            await this.refs.loadDatabase.getFavoritePlaces().then(result => {
             favoritePlaces = result
              this.setState({
                favoritePlaces: result,
                filteredFavoritePlaces: result,
                favoritePlacesLoaded:true
              })
            }); 
            await this.refs.searchFunctions.updateFavoritePlacesSearchTags(favoritePlaces).then(result => {
                this.setState({
                    favoritePlacesSearchTags:result, allFavoritePlacesSearchTags:result
                })
            }); 

            let favoriteTripPlans = [];
            await this.refs.loadDatabase.getFavoriteTripPlans().then(result => {
              favoriteTripPlans = result
              this.setState({
                favoriteTripPlans: result,
                filteredFavoriteTripPlans: result,
                favoriteTripPlansLoaded:true
              })
            }); 
            await this.refs.searchFunctions.updateFavoriteTripsSearchTags(favoriteTripPlans).then(result => {
                this.setState({
                    favoriteTripPlansSearchTags:result, allFavoriteTripPlansSearchTags:result
                })
            }); 
          }
        

          if (this.state.selectedContentType==="Experiences"&&this.state.selectedContentWall!=="Favorite"){
            this.setState({selectedContentType:"Places"})
          }
          this.setState({selectedContentWall:"Favorite"})
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"FAVORITES_BUTTON",
            userScreen:this.state.selectedContentWall.toUpperCase(),
            eventLevel:1
          })
      }

      myContentWallHandler = async () =>{
         if (this.state.userCreatedPlacesLoaded&&this.state.userExperiencesLoaded){
          this.setState({selectedContentWall:"MyContent"})
        }
        else{
          let userCreatedPlaces = [];
          let userExperiences = [];
          await this.refs.loadDatabase.getUserCreatedPlaces().then(result => {
            userCreatedPlaces = result;
            this.setState({
              userCreatedPlaces: result,
              filteredUserCreatedPlaces: result,
              
              userCreatedPlacesLoaded:true
            })
            
          }); 
          await this.refs.searchFunctions.updateUserCreatedPlacesSearchTags(userCreatedPlaces).then(result => {
            this.setState({
              userCreatedPlacesSearchTags:result, allUserCreatedPlacesSearchTags:result,
            })
            
          }); 
          await this.refs.loadDatabase.loadUserExperiences().then(result => {
            userExperiences = result;
            this.setState({
              userExperiences: result.list,
              filteredUserExperiences: result.list,
              userExperiencesLoadedLimit:result.limit,
              loadedMoreUserExperiences:true,
              userExperiencesLoaded:true
            })
            this.refs.searchFunctions.updateExperienceSearchTags(result.list).then(result => {
              this.setState({
                userExperiencesSearchTags:result, allUserExperiencesSearchTags:result,
              })
              
            }); 
          }); 
          this.setState({selectedContentWall:"MyContent"})
        } 
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"MYCONTENT_BUTTON",
          userScreen:this.state.selectedContentWall.toUpperCase(),
          eventLevel:1
        })
     
      }

    //------USER INTERACTION FUNCTIONS----

    viewUserProfileHandler = async user =>{
      let status;
      if (this.state.myFriends.map(user=>{return user.userID}).includes(user.userID)){
        status="Friend"
      }
      else if (this.state.followingUsersIDs.includes(user.userID)){
        status="Following"
      }
      await this.setState({showUserWall:true, selectedUser: user})
      await this.refs.loadDatabase.loadSelectedUserContent(user.userID,status).then(result => {
        this.setState({
          selectedUserExperiences: result.experiences,
          filteredSelectedUserExperiences:result.experiences,
          selectedUserTripPlans: result.trips,
          filteredSelectedUserTripPlans: result.trips
        })
        this.refs.searchFunctions.updateExperienceSearchTags(result.experiences).then(result => {
          this.setState({
            selectedUserExperiencesSearchTags:result, allSelectedUserExperiencesSearchTags:result,
          })
          
        }); 
        this.refs.searchFunctions.updateTripsSearchTags(result.trips).then(result => {
          this.setState({
            selectedUserTripPlansSearchTags:result, allSelectedUserTripPlansSearchTags:result,
          })
          
        }); 
      }); 
      

      let screen = ""
      if (this.state.viewPLace){
        screen = "VIEW_PLACE"
      }
      else{
        screen = this.state.selectedContentWall.toUpperCase()
      }
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"VIEW_USER_PROFILE",
        userScreen:screen
      })
    }

    contactFormHandler = (request,isContactUs) =>{
      if (isContactUs){
        this.setState({showContantForm:true,
          showBackdrop:true
        })
      }
      else{
        this.setState({showContantForm:true,
          showBackdrop:true,
          requestPlaceID: request.placeID,
          requestPlaceName: request.placeName,
          requestTopic: request.requestTopic
        })
      }
      
    }

    discardCreatePlace = () =>{
        this.setState({createPlace: false, quickPlaceCreation:false, showNullBackdrop:false,showBackdrop: false,discardQuicPlaceCreation:true});

        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"DISCARD_NEW_PLACE",
          userScreen:"NEW_PLACE"
        })
    }

    
    discardCreateTripPlan = () =>{
      this.setState({createTripPlan: false,
        clonedPlan:undefined,
        clonningPlan:false,
        showNullBackdrop: false});

      if (this.state.clonningPlan){
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"TRIP_CLONE_SAVE",
          userScreen:"NEW_TRIP",
        })
      }
      else{
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"DISCARD_NEW_TRIP",
          userScreen:"NEW_TRIP"
        })
      }
      
    }

    discardCreateTripPlanAfterSave = () =>{
      this.setState({createTripPlan: false,
        clonedPlan:undefined,
        showNullBackdrop: false});

      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCARD_NEW_TRIP",
        userScreen:"NEW_TRIP"
      })
    }

    discardCreateExperience = () =>{
      this.setState({createExperience: false,
        showNullBackdrop: false});
      if (this.refs.viewPlan!==undefined){
        this.refs.viewPlan.discardExperienceCreation()
      }
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCARD_NEW_EXPERIENCE",
        userScreen:"NEW_EXPERIENCE"
      })
    }

    createQuickPlaceHandler = (placeMainCategory,categories) =>{
      if (placeMainCategory!==undefined){
        this.setState({quickPlaceCreationMainCategory: placeMainCategory,quickPlaceCreationCategories:categories,discardQuicPlaceCreation:false})
        
      }
      this.setState({quickPlaceCreation:true})
      this.createNewSelectionHandler("Place")
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"CREATE_QUICK_PLACE",
      })
    }

    createNewSelectionHandler = async (selection,object,addToPlan,date)=>{
        switch(selection){
            case "Place":{
                this.setState({createPlace: true,showCreateMenu: false, showNullBackdrop: true});

                this.refs.writeDatabase.writeAcitivityLog({
                  activityKey:"CREATE_NEW_PLACE",
                  eventLevel:1
                })
                break;
            }
            case "Experience":{

              
              if (object!==undefined){
                //sharing experience for a specific place
                await this.setState({newExperiencePlace: object})
               
                if (addToPlan){
                  this.refs.writeDatabase.writeAcitivityLog({
                    activityKey:"SHARE_PLACE_EXPERIENCE_FROM_TRIP"
                  })
                }
                else{
                  let screen = ""
                  if (this.state.viewPLace){
                    screen = "VIEW_PLACE"
                    this.refs.writeDatabase.writeAcitivityLog({
                      activityKey:"SHARE_PLACE_EXPERIENCE_INTERNAL_BUTTON",
                      userScreen:screen
                    })
                  }
                  else{
                    screen = this.state.selectedContentWall.toUpperCase()
                    this.refs.writeDatabase.writeAcitivityLog({
                      activityKey:"SHARE_PLACE_EXPERIENCE_EXTERNAL_BUTTON",
                      userScreen:screen
                    })
                  }
                  
                }
                
              }
              else{
                //just creating new experience
                await this.setState({newExperiencePlace:null})

                if (addToPlan){
                  //at this point not really happens - must choose a place when sharing experience from a trip
                  this.refs.writeDatabase.writeAcitivityLog({
                    activityKey:"SHARE_PLACE_EXPERIENCE_FROM_TRIP"
                  })
                }
                else{
                  this.refs.writeDatabase.writeAcitivityLog({
                    activityKey:"CREATE_NEW_EXPERIENCE",
                    eventLevel:1
                  })
                }
                
              }
              if (this.state.viewPlan){
                await this.setState({newExperiencePlan: this.state.selectedPlan})
                
              }
              else{
                await this.setState({newExperiencePlan: null})
              }
              if (date){
                await this.setState({newExperienceDate: date})
              }
              else{
                await this.setState({newExperienceDate: null})
              }
              this.setState({createExperience: true,showCreateMenu: false, showBackdrop: false,
                showNullBackdrop: true });
              break;
              
            }
            case "Trip":{
              this.setState({createTripPlan: true,showCreateMenu: false, showBackdrop: false,
                showNullBackdrop: true, });
              this.refs.writeDatabase.writeAcitivityLog({
                activityKey:"CREATE_NEW_TRIP",
                eventLevel:1
              })
              break;
          }
            default: return null;
        }
    }
    
    updateFavoriteTagsHandler = async (object,type) =>{
     
        switch (type){
          case "Place":{
            let favoriteSearchTags =[];
            let favoriteTags = [];
            await this.refs.loadDatabase.getFavoritePlaceTags(object).then(result => {
                favoriteSearchTags = result
              }); 
            
            let newObject = object;
            for (let j=0 ; j<favoriteSearchTags.length; j++){
                let tagLanguages =  Object.keys(favoriteSearchTags[j]);
                let tag = favoriteSearchTags[j][tagLanguages[0]];
                favoriteTags.push(tag);
              }
            newObject.favoriteSearchTags = favoriteTags
            await this.setState({
              updateFavoriteTags: true,
              showNullBackdrop: true,
              favoriteContentType:type,
              favoriteObject: newObject
            });

            let log = {userScreen:"",activityKey:""}
            if (this.state.viewPLace){
              log.userScreen = "VIEW_PLACE"
            }
            else{
              log.userScreen = this.state.selectedContentWall.toUpperCase()
            }
            
            if (type === "Place"){
              log.activityKey = "UPDATE_FAVORITE_PLACE_TAGS"
              log.placeID = object.placeID
            }
            
            this.refs.writeDatabase.writeAcitivityLog(log)
            break;
          }
        case "Trip":{
            const index = this.state.favoriteTripPlans.findIndex(favPlan => {
              return favPlan.planID === object.planID;
            });
            let newObject = object;
            let favoriteTags=[];
            if (this.state.favoriteTripPlans[index].favoriteSearchTags!==undefined){
             
              for (let i=0 ; i<this.state.favoriteTripPlans[index].favoriteSearchTags.length; i++){
                let tagLanguages =  Object.keys(this.state.favoriteTripPlans[index].favoriteSearchTags[i]);
                let tag = this.state.favoriteTripPlans[index].favoriteSearchTags[i][tagLanguages[0]];
                favoriteTags.push(tag);
              }
            }
            newObject.favoriteSearchTags = [...favoriteTags]
            await this.setState({
              updateFavoriteTags: true,
              showNullBackdrop: true,
              favoriteContentType:type,
              favoriteObject: newObject
            });
            break;
          } 
          default: return null;
        }
      }

    addToFavoriteHandler = async (object,type) =>{
        let newObject = object;
        newObject.favoriteSearchTags=[];
        await this.setState({
          addToFavorite: true,
          showNullBackdrop: true,
          favoriteContentType:type,
          favoriteObject: newObject
        });

        let log = {userScreen:"",activityKey:""}
        if (this.state.viewPLace){
          log.userScreen = "VIEW_PLACE"
          if (type === "Place"){
            log.activityKey = "ADD_PLACE_TO_FAVORITE_INTERNAL_BUTTON"
            log.placeID = object.placeID
          }
          else if (type==="Trip"){
            log.activityKey = "ADD_TRIP_TO_FAVORITE_iNTERNAL_BUTTON"
          }
        }
        else{
          log.userScreen = this.state.selectedContentWall.toUpperCase()
          if (type === "Place"){
            log.activityKey = "ADD_PLACE_TO_FAVORITE_EXTERNAL_BUTTON"
            log.placeID = object.placeID
          }
          else if (type==="Trip"){
            log.activityKey = "ADD_TRIP_TO_FAVORITE_EXTERNAL_BUTTON"
          }
        }
        
        this.refs.writeDatabase.writeAcitivityLog(log)
      }

      addToPlanHandler = async (object,type)=>{
        if (this.state.viewPlan){
          //adding place from within a trip
          if (this.refs.viewPlan!==undefined){
            this.refs.viewPlan.addPlaceToPlanHandler(object)
            this.setState({viewPlace:false})
          }
        }
        else{
          await this.setState({
            viewedPlanPlaces:[],
            addToPlan: true,
            showNullBackdrop: true,
            selectedObject:object,
            addToPlanType:type
          });
        }
       

        let log = {userScreen:"",activityKey:""}
        if (this.state.viewPLace){
          log.userScreen = "VIEW_PLACE"
        }
        else{
          log.userScreen = this.state.selectedContentWall.toUpperCase()
        }
        
        if (type === "Place"){
          log.activityKey = "ADD_PLACE_TO_PLAN_BUTTON"
          log.placeID = object.placeID
        }
        if (type === "Experience"){
          log.activityKey = "ADD_EXPERIENCE_TO_PLAN_BUTTON"
        }

        this.refs.writeDatabase.writeAcitivityLog(log)
      }

      viewPlanHandler = async (plan,latestTrip) =>{
        if (!this.props.loggedUser&&latestTrip){
          await this.refs.writeDatabase.loadTripPlanPlacesList(plan).then(result=>{
            this.setState({viewedPlanPlaces:result,selectedPlan: plan, viewPlan:true})
          })
        }
        else{
          this.refs.writeDatabase.viewPlan(plan.planID, true,)
          this.setState({showSuggestedTrips:false})
          let screen = this.state.selectedContentWall.toUpperCase()
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"VIEW_TRIP",
            userScreen:screen,
            eventLevel:1
          })
        }        
      }

      viewSuggestedPlanHandler = async plan =>{
        await this.refs.writeDatabase.loadTripPlanPlacesList(plan).then(result=>{
          this.setState({viewedPlanPlaces:result,selectedPlan: plan, viewPlan:true})
        })
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"VIEW_SUGGESTED_TRIP"
        })
      }

      viewAnonymousUserCachedPlanHandler = async plan =>{
        await this.refs.writeDatabase.loadTripPlanPlacesList(plan).then(result=>{
          this.setState({viewedPlanPlaces:result,selectedPlan: plan, viewPlan:true})
        })
      }

      viewExperiencePlanHandler = (planID, experienceOwnerID) =>{
        this.setState({viewUserJourneyID:experienceOwnerID })
        this.refs.writeDatabase.viewPlan(planID,true)

        let screen = ""
        if (this.state.showUserWall){
          screen = "USERWALL"
        }
        else{
          screen = this.state.selectedContentWall.toUpperCase()
        }
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"VIEW_EXPERIENCE_TRIP",
          userScreen:screen
        })
      }

      viewImagesFullscreenHandler = async (imagesFullScreenList,index) =>{
        await this.setState({viewImagesFullscreen:true,imagesFullScreenList:imagesFullScreenList,imagesFullScreenIndex:index})
      }

      addToPlanSelectPlanHandler = plan =>{
        this.refs.writeDatabase.viewPlan(plan.planID, false)
      }

      connectExperienceToPlan = (planID, experience) =>{
        this.refs.writeDatabase.addExperienceToPlan(planID, experience, true)

        let screen = this.state.selectedContentWall.toUpperCase()
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"EXPERIENCE_CONNECT_TO_TRIP",
          userScreen:screen
        })
      }

      removeExperienceFromPlanHandler= async (experience,fromPlan) =>{
        if (fromPlan){
          let planExperiences = this.state.selectedPlanExperiences
          const index = planExperiences.findIndex(exp => {
            return exp.experienceID === experience.experienceID;
          })
          planExperiences.splice(index,1)
          await this.setState({selectedPlanExperiences:planExperiences,removingExperienceFromPlan: true}) 
          this.refs.writeDatabase.removeExperienceFromPlan(experience);
        }
        else{
          this.refs.writeDatabase.removeExperienceFromPlan(experience);
        }
       

        let screen = this.state.selectedContentWall.toUpperCase()
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"EXPERIENCE_DISCONNECT_TRIP",
          userScreen:screen
        })
      }

      updatePlanDays = planData =>{
        this.refs.writeDatabase.updatePlanDays(planData);
      }

      
    updatePlan = (plan,planPlacesList) =>{
      if (this.props.loggedUser){
        if (this.refs.writeDatabase!==undefined){this.refs.writeDatabase.updatePlan(plan,planPlacesList);}
      }
      else{
        localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(plan));
        this.setState({latestUserCreatedTripPlan: plan})
      }
     
    }

    updateSlimPlan = () =>{
      if (this.props.loggedUser){
        //if the user is logged then the trip is in the db and no need to save all information about it, otherwise save all in cache
        let selectedPlan = this.state.selectedPlan;
        let slimPlan = {
          planID: selectedPlan.planID,
          planLastModified: selectedPlan.planLastModified,
          planCreationDate: selectedPlan.planCreationDate,
          planName:selectedPlan.planName,
          planDates:selectedPlan.planDates,
          owner:selectedPlan.owner,
          planDuration:selectedPlan.planDuration,
          noDatesSelected:selectedPlan.noDatesSelected,
          planEditors:selectedPlan.planEditors,
        }
        if (selectedPlan.participants!==undefined){
          slimPlan.participants = selectedPlan.participants
        }
        if (selectedPlan.imagePreview!==undefined){
          slimPlan.imagePreview = selectedPlan.imagePreview
        }
        localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(slimPlan));
        this.setState({latestUserCreatedTripPlan: slimPlan})

        let userCreatedTripPlans = [...this.state.userCreatedTripPlans];
        const index = userCreatedTripPlans.findIndex(plan => {
            return plan.planID === slimPlan.planID;
        });
        userCreatedTripPlans[index] = slimPlan
        this.setState({userCreatedTripPlans:userCreatedTripPlans});

        if (this.state.userCreatedTripPlansFilterTags.length>0){
          let filteredUserCreatedTripPlans = [...this.state.filteredUserCreatedTripPlans];
          const filterIndex = filteredUserCreatedTripPlans.findIndex(plan => {
              return plan.planID === slimPlan.planID;
          });
          filteredUserCreatedTripPlans[filterIndex] = slimPlan
          this.setState({filteredUserCreatedTripPlans:filteredUserCreatedTripPlans});

          //---UPDATE SEARCH TAGS
        }
      }
    }

    updatePlanEditors = (sharedPlan, user, added,planEditors)=>{
      this.refs.writeDatabase.updatePlanEditors(sharedPlan, user, added,planEditors);
    }

    updateExperiencesPlanSecurity = (experiencesIDs, planID,planSecurity) =>{
      this.refs.writeDatabase.updateExperiencesPlanSecurity(experiencesIDs, planID,planSecurity);
    }

    updateExperiencesAlbumSecurity = (experiencesIDs, planID,albumSecurity) =>{
      this.refs.writeDatabase.updateExperiencesAlbumSecurity(experiencesIDs, planID,albumSecurity);
    }

    updatePlanNote = (note, dayIndex,itemIndex,planID,ownerID) =>{
      this.refs.writeDatabase.updatePlanNote(note, dayIndex,itemIndex,planID,ownerID)
    } 

    removeNoteFromPlan = (note, dayIndex,itemIndex,planID,ownerID) =>{
      this.refs.writeDatabase.removeNoteFromPlan(note, dayIndex,itemIndex,planID,ownerID)
    } 

    updatePlanTransport = (transport, dayIndex,itemIndex,planID,ownerID,newTransport) =>{
      this.refs.writeDatabase.updatePlanTransport(transport, dayIndex,itemIndex,planID,ownerID,newTransport)
    } 

    removeTransportFromPlanHandler = (transport, dayIndex,itemIndex,planID,ownerID) =>{
      this.refs.writeDatabase.removeTransportFromPlan(transport, dayIndex,itemIndex,planID,ownerID)
    } 

    updatePlanPlacesListHandler = async (placesList) =>{
      return new Promise(async (resolve, reject) => {
        await this.setState({viewedPlanPlaces:placesList})
      resolve();
    });
    }

    getAddedPlaceToPlanHandler = async (newPlace) =>{
      return new Promise(async (resolve, reject) => {
        let place
        await this.refs.writeDatabase.getAddedPlaceToPlan(newPlace).then(result=>{
          place = result
        })
      resolve(place);
    });
    }


    deletePlanHandler = plan =>{
      this.refs.writeDatabase.deletePlan(plan);

      let screen = this.state.selectedContentWall.toUpperCase()
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"TRIP_DELETE",
        userScreen:screen
      })
    }

    editExperienceHandler = experience =>{
      this.setState({editExperience:true,selectedExperience:experience,showNullBackdrop:true})

      let screen = this.state.selectedContentWall.toUpperCase()
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"EXPERIENCE_EDIT",
        userScreen:screen
      })
    }

    deleteExperienceHandler = experience =>{
      this.refs.writeDatabase.deleteExperience(experience);
      let screen = this.state.selectedContentWall.toUpperCase()
      if (this.state.viewPlan){
        screen = "VIEW_TRIP"
        let planExperiences = this.state.selectedPlanExperiences
        const index = planExperiences.findIndex(exp => {
          return exp.experienceID === experience.experienceID;
        })
        planExperiences.splice(index,1)
        this.setState({selectedPlanExperiences:planExperiences})
      }
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"EXPERIENCE_DELETE",
        userScreen:screen
      })
    }

    removeTaggingHandler = experience =>{
      this.refs.writeDatabase.removeTaggingHandler(experience);

      let screen = this.state.selectedContentWall.toUpperCase()
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"EXPERIENCE_REMOVE_USER_TAG",
        userScreen:screen
      })
    }

    
    discardEditExperience = () =>{
      this.setState({editExperience: false,showNullBackdrop:false})

       
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCARD_EDIT_EXPERIENCE",
        userScreen:"UPDATE_EXPERIENCE"
      })
    }

    reportDislikedSuggestedTrip = async (plan,userInput) =>{
      let savePlan = {...plan}
      savePlan.systemGenerated = true
      savePlan.userAction = "dislike"
      savePlan.userInput = userInput
      this.refs.writeDatabase.saveSystemGeneratedTrip(savePlan)
    }

    clonePlanHandler = async (plan,suggestedTrip,editedTrip) =>{
      if (this.props.loggedUser||this.state.userChoseContinueAnonymous){
        await this.setState({
          clonningPlan:true,
          viewPlan:false,
          showCreateMenu: false, 
          showNullBackdrop: true,
          clonningSuggestedTrip:false,
          suggestedTrip:undefined,
          userChoseContinueAnonymous:false
        })
        if (suggestedTrip){
          await this.setState({createTripPlan: true,clonedPlan:plan})
          await this.refs.writeDatabase.loadTripPlanPlacesList(plan).then(result => {
            this.setState({viewedPlanPlaces:result})
          }); 
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"CLONE_SUGGESTED_PLAN_BUTTON",
            userScreen:this.state.selectedContentWall.toUpperCase()
          })
  
          //save the cloned plan to the system without regards to the user copy
          let savePlan = {...plan}
          savePlan.systemGenerated = true
          savePlan.userAction = "clone"
          if (editedTrip){
            savePlan.editedTrip=editedTrip
          }
          this.setState({clonningSuggestedTrip: true, suggestedTrip:savePlan})
        }
        else{
          this.refs.writeDatabase.viewPlan(plan.planID, false)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"CLONE_PLAN_BUTTON",
            userScreen:this.state.selectedContentWall.toUpperCase()
          })
        }  
      }
      else{
        let savePlan = {...plan}
        savePlan.systemGenerated = true
        savePlan.userAction = "clone"
        if (editedTrip){
          savePlan.editedTrip=editedTrip
        }
        this.setState({clonnedTripToCache :savePlan, showNotLoggedUserCloneTripMessage:true,showNullBackdrop:true,clonnedSuggestedTrip:true})
      }     
    }

   
   

    renderNotLoggedUserCloneTripMessage = () =>{
      if (this.state.showNotLoggedUserCloneTripMessage){
        let RTL = ""
        let closeIconClass = "CloseIcon"
        if (this.props.RTL){
          RTL = "RTL"
          closeIconClass = "CloseIconRTL"
        }
        return(
          <div className={[classes.CloneMessageModal,classes[RTL]].join(" ")}>
             <div className={classes.ModalTopSection} >
           
              <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"} 
                  alt="" className={classes[closeIconClass]} 
                  onClick={()=>{this.setState({showNotLoggedUserCloneTripMessage:false,showNullBackdrop:false})}}/>
             </div>
             
               <div className={classes.CloneMessage}>
                    {this.props.systemText.tripGenerator.generatedContent.cloneMessageNotLoggedUser[this.props.userLanguage]}
                </div>
              <div className={classes.ButtonSection}>
                  <TextButton
                   clicked={this.saveClonnedTripToCache}
                    text={this.props.systemText.tripGenerator.generatedContent.cloneAndLogin[this.props.userLanguage]}
                    style = {"OrangeButton"}
                  />   
                  <TextButton
                   clicked={this.userChoseContinueAnonymousHandler}
                    text={this.props.systemText.tripGenerator.generatedContent.continue[this.props.userLanguage]}
                    style = {"DiscardButton"}
                  />                       
              </div>
          </div>
        )
      }
    }

    userChoseContinueAnonymousHandler = async () =>{
      let plan = this.state.clonnedTripToCache
      delete plan.systemGenerated
      delete plan.userAction
      if (plan.editedTrip!==undefined){
        delete plan.editedTrip
      }
      this.setState({showNotLoggedUserCloneTripMessage:false,userChoseContinueAnonymous:true})

      this.clonePlanHandler(plan,this.state.clonnedSuggestedTrip,this.state.clonnedTripToCache.editedTrip)
    }

    saveClonnedTripToCache = async () =>{
      await localStorage.setItem('cachedTrip',JSON.stringify(this.state.clonnedTripToCache));
      this.props.loginHandler()
    }
    
    viewPlaceHandler = place =>{
      let country = ""
        if (place.placeStatus==="approved") {
          country = place.placeLocation.locationTree.placeCountry
        }
        else{
          country = place.placeLocation.locationTree.placeCountry.treeID
        }
      this.viewPlaceDBHandler(place.placeID,country)
      let screen = ""
      if (this.state.viewPlan){
        screen = "VIEW_TRIP"
      }
      else{
        screen = this.state.selectedContentWall.toUpperCase()
      }
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"VIEW_PLACE",
        placeID:place.placeID,placeCountry:country,
        userScreen:screen,
        eventLevel:1
      })

      this.refs.writeDatabase.googleAnalyticsWrite(
        {
          activityKey:'select_content',
          googleParams:{
            content_type: 'place',
            content_id: place.placeID,
            placeCountry: country
          }
        }
      )
    }

   
    viewExperiencePlaceHandler = (placeID,country) =>{
      let placeCountry = country
      if (country.treeID!==undefined){
        placeCountry = country.treeID
      }
      this.viewPlaceDBHandler(placeID,placeCountry)

      let screen = ""
      if (this.state.showUserWall){
        screen = "USERWALL"
      }
      else{
        screen = this.state.selectedContentWall.toUpperCase()
      }
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"VIEW_EXPERIENCE_PLACE",
        placeID:placeID,placeCountry:placeCountry,
        userScreen:screen
      })
    }

    viewPlaceDBHandler = async (placeID,country) =>{
        
        await this.refs.writeDatabase.viewPlace(placeID,country).then(result => {
            if (result.notExist){
                this.setState({
                showInfo:true,
                showNullBackdrop:true, 
                infoType:"Negative",
                infoHeader: this.props.systemText.place.viewPlace.informationModal.placeNotExistHeader[this.props.userLanguage], 
                informationMessage:this.props.systemText.place.viewPlace.informationModal.placeNotExistMessage[this.props.userLanguage],
                btnText: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage],  
              })
              //remove it from all possible places
              //remove from latest viewed
              let latestViewedPlaces = this.state.latestViewedPlaces
              let userLastViewedPlacesIDs = latestViewedPlaces.map(place=>{return place.placeID})
              if (userLastViewedPlacesIDs.includes(placeID)){
                const index = latestViewedPlaces.findIndex(listPlace => {
                  return listPlace.placeID === placeID;
                });
                if (index!==-1){
                  //remove from latest list if place not exist
                  latestViewedPlaces.splice(index,1)
                  this.setState({latestViewedPlaces:latestViewedPlaces})
                  localStorage.setItem('latestViewedPlaces',JSON.stringify(latestViewedPlaces));
                  let dbLatestsViewedPlaces = latestViewedPlaces.map(place=>{return {country:place.placeLocation.locationTree.placeCountry.treeID , placeID:place.placeID}})
                  this.refs.writeDatabase.updateLatestViewedPlacesList(dbLatestsViewedPlaces)
                }
              }

              //remove from recommendations

              //remove from favorites list

              //remove from discover list
            }
            else{
                this.setState({selectedPlace: result.place, viewPlace: true})
            }
          }); 
      }

      viewPlaceEditSuggestion = async place =>{
        await this.refs.writeDatabase.viewPlaceEditSuggestion(place).then(result => {
            if (result.notExist){
                this.setState({
                showInfo:true,
                showNullBackdrop:true, 
                infoType:"Negative",
                infoHeader: this.props.systemText.place.viewPlace.informationModal.placeNotExistHeader[this.props.userLanguage], 
                informationMessage:this.props.systemText.place.viewPlace.informationModal.placeNotExistMessage[this.props.userLanguage],
                btnText: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage],  
              })
            }
            else{
                this.setState({selectedPlace: result.place, viewPlace: true, placeEditSuggestion:true})
            }
          }); 
      }

    updateExperienceHandler = experience =>{
      this.refs.writeDatabase.updateExperience(experience);

      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"SAVE_EXPERIENCE_UPDATE",
        userScreen:"UPDATE_EXPERIENCE",
      })
    }
    
    updatePlaceHandler = place =>{
      this.refs.writeDatabase.updatePlace(place);
    } 

    deletePlaceHandler = place =>{
      this.refs.writeDatabase.deletePlace(place)
    }

    submitPlaceChanges = (place) =>{
      this.refs.writeDatabase.submitPlaceChanges(place);
    }

    getPlacesListForPlaceCreationValidation = async (countryName) =>{
        if(this.state.placesListForPlaceCreation[countryName]===undefined){
            await this.refs.loadDatabase.getPlacesListForPlaceCreationValidation(countryName).then(result => {
                let placesListForPlaceCreation = this.state.placesListForPlaceCreation
                placesListForPlaceCreation[countryName]=result
                this.setState({placesListForPlaceCreation:placesListForPlaceCreation})
              }); 
        }

    }

    updateQuickCreationPlaceSelection = async place =>{
      await this.setState({existingPlaceFullData: undefined,quickPlaceWasCreated:false})
      this.setState({existingPlaceFullData:place})
    }

    popularTouristSiteHandler = async value =>{
      let selectedPlace = this.state.selectedPlace
      let placeCountry=selectedPlace.placeLocation.locationTree.placeCountry.treeID;
      if (selectedPlace.placeLocation.locationTree.placeCountry.treeID===undefined){
        placeCountry=selectedPlace.placeLocation.locationTree.placeCountry
      }
      selectedPlace.topTouristSite=value
      
      await this.refs.writeDatabase.popularTouristSiteHandler(selectedPlace.placeID,placeCountry,value)
      this.setState({selectedPlace:selectedPlace})
    }

    getExistingPlaceData = async (locationCountry,placeID) =>{
        await this.refs.loadDatabase.getExistingPlaceData(locationCountry,placeID).then(result => {
          this.setState({existingPlaceFullData:result})       
        }); 
      }

      loadCountryPlacesForPlanning = async (tag) =>{
        let countryName = tag.treeID
        if (this.state.slimPlacesByCountry[countryName]===undefined){
          await this.refs.loadDatabase.loadCountryPlaces(countryName).then(result => {
            let slimPlacesByCountry = this.state.slimPlacesByCountry
            slimPlacesByCountry[countryName]=result
            this.setState({slimPlacesByCountry:slimPlacesByCountry})
            this.refs.viewPlan.discoverPlacesLocationSearchTagHandler(tag)
          }); 
          
        }
        else{
          this.refs.viewPlan.discoverPlacesLocationSearchTagHandler(tag)
        }
      }

      loadCountryPlacesForExperience = async () =>{

      }

      loadFavoritePlacesForPlanning = async () =>{
        if (this.state.favoritePlacesIDs.length>0){
          if (this.state.favoritePlacesLoaded){
              //favorite places are already loaded
              this.refs.viewPlan.favoritePlacesLoaded()
          }
        else{
          await this.refs.loadDatabase.getFavoritePlaces().then(result => {
            this.setState({
              favoritePlaces: result,
              filteredFavoritePlaces: result,
              favoritePlacesLoaded:true
            })
          }); 
        }
      }
      this.refs.viewPlan.favoritePlacesLoaded()
    }

    noSearchResultsHandler = (searchValue) =>{
      let message = ""
      /* if (this.state.selectedContentWall==="WelcomePage"&&this.state.selectedContentType==="Places"){ */
      if (this.state.selectedContentWall==="WelcomePage"&&this.state.selectedContentType==="Places"){
        message=this.props.systemText.searchErrorMessages.welcomePlaces[this.props.userLanguage]
      }
      else if (this.state.selectedContentWall==="Discover"&&this.state.selectedContentType==="Places"){
        message=this.props.systemText.searchErrorMessages.discoverPlaces[this.props.userLanguage]
      }
     /*  }
      if (this.state.selectedContentWall==="Discover"&&this.state.selectedContentType==="Places"){
        message=this.props.systemText.searchErrorMessages.discoverPlaces[this.props.userLanguage]
      } */
      this.setState({
        showErrorMessage:true,
        showNullBackdrop:true,
        errorHeader:this.props.systemText.searchErrorMessages.title[this.props.userLanguage],
        errorMessage:message,
        errorButton: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage], 
      })
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_PLACES_NO_SEARCH_RESULTS",
        userScreen:this.state.selectedContentWall.toUpperCase(),
        searchValue:searchValue
      })

    }

    discoverExperiencesHandler = (buttonClicked) =>{
      this.loadMoreExperiencesFeedHandler();
      this.setState({selectedContentWall:"Discover",
      selectedContentType:"Experiences"})

      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_EXPERIENCES_FEED",
        buttonClicked:buttonClicked,
        userScreen:"WELCOMEPAGE"
      })
    }

    loadMoreExperiencesFeedHandler = async () =>{
      if (this.state.loadedMoreExperiencesToFeed){
        let loadedLimit = this.state.experiencesFeedLoadedLimit
        await this.setState({loadedMoreExperiencesToFeed:false})
        await this.refs.loadDatabase.loadMoreExperiencesFeed(this.state.followingUsersIDs,loadedLimit+5,this.state.userExperiencesFeed).then(result => {
          this.setState({userExperiencesFeed: result.list, 
            experiencesFeedLoadedLimit:result.limit,
            loadedMoreExperiencesToFeed:result.loadedMoreExperiencesToFeed})
          localStorage.setItem('userExperiencesFeed',JSON.stringify(result.list.slice(0,20)));
        }); 
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"EXPERIENCE_FEED_LOAD_MORE",
          userScreen:"DISCOVER"
        })
      }    
    }

    loadMoreExperiencesFeedForPlace = async () =>{
      if (this.state.loadedMoreExperiencesToPlaceFeed){
        let loadedLimit = this.state.placeExperiencesFeedLoadedLimit
        await this.setState({loadedMoreExperiencesToPlaceFeed:false})
        await this.refs.loadDatabase.loadMoreExperiencesFeedForPlace(loadedLimit+5,this.state.placeExperiences,this.state.selectedPlace.placeID).then(result => {
          this.setState({placeExperiences: result.list, 
            placeExperiencesFeedLoadedLimit:result.limit,
            loadedMoreExperiencesToPlaceFeed:result.loadedMoreExperiencesToFeed})
        }); 
      }    
    }

    loadMoreUserExperiences = async () =>{
      if (this.state.loadedMoreUserExperiences){
        let loadedLimit = this.state.userExperiencesLoadedLimit
        await this.setState({loadedMoreUserExperiences:false})
        await this.refs.loadDatabase.loadMoreUserExperiences(loadedLimit+10,this.state.userExperiences).then(result => {
          this.setState({userExperiences: result.list, 
            filteredUserExperiences: result.list,
            userExperiencesLoadedLimit:result.limit,
            loadedMoreUserExperiences:result.loadedMoreUserExperiences})
        }); 
       
      }    
    }


    loadFriendsExperiencesForPlace = async () =>{
      await this.refs.loadDatabase.loadFriendsExperiencesForPlace(this.state.selectedPlace.placeID,this.state.followingUsersIDs).then(result => {
        this.setState({placeFriendsExperiences:result})
      }); 
    }

    loadMyExperiencesForPlace = async () =>{
      await this.refs.loadDatabase.loadMyExperiencesForPlace(this.state.selectedPlace.placeID).then(result => {
        this.setState({placeMyExperiences:result})
      }); 
    }

    expandOnCategoryHandler = async tag =>{
        this.refs.writeDatabase.categorySearchLogHandler(tag.id,this.state.userCountryTag.treeID.split('_')[0])
        let discoverPlacesCategoriesFilterTags = [];
        discoverPlacesCategoriesFilterTags.push({tag:tag,type:"CategoryFilter",language:this.props.userLanguage})
        await this.setState({discoverPlacesCategoriesFilterTags:discoverPlacesCategoriesFilterTags})
        this.loadCountryPlaces(this.props.userCountry,undefined,null,true)


        //ADD INDICATION IF GENERIC OR USER SPECIFIC
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"DISCOVER_RECOMMENDED_CATEGORY",
          tagID:tag.id,
          userScreen:"WELCOMEPAGE"
        })
      }

      discoverAllPlansHandler = async () =>{
        await this.setState({
          discoverTripsCountriesFilters:[],
          discoverTripsLocationFilters: [],
          discoverPlansDurationFilter: [],
          discoverTripsParticipantsTags: ["Adults"],
          discoverPlansDurationFilter:[1,20],
          discoverPlansParticipants:[1,0,0],
          discoverTripsLimit:30
        })
        this.discoverPlansHandler()
      }

      loadMoreDiscoverTripPlans = async () =>{
        if (this.state.loadMoreDiscoverTrips){
          await this.setState({discoverTripsLimit:this.state.discoverTripsLimit+30,loadMoreDiscoverTrips:false})
          this.discoverPlansHandler()
        }
      }  

    discoverPlansHandler = async () =>{
      //need to handle when it is for user country and when it is global..
      let args = {
        /* countriesFilters:this.state.discoverTripsCountriesFilters, */
        locationFilters: this.state.discoverTripsLocationFilters,
        tripDuration:this.state.discoverPlansDurationFilter,
        additionalTags : this.state.discoverTripsPlanAdditionalTagsFilters,
        includeAllLocations: this.state.discoverTripsCombineLocations
       /*  tripParticipants:this.state.discoverTripsParticipantsTags, */
      }
      await this.refs.loadDatabase.discoverPublicPlans(args,this.state.discoverTripsLimit).then(result => {
       this.setState({discoverPublicTripPlans:result.tripsList, filteredDiscoverPublicTripPlans:result.tripsList, selectedContentWall:"Discover",discoverPlansLoaded:true, loadMoreDiscoverTrips:result.loadMoreTrips})
          
      }); 
      /* if (this.state.discoverTripsPlanAdditionalTagsFilters.length>0){
        args.tripsList = this.state.discoverPublicTripPlans
        args.discoverTripsPlanTagsFilters = this.state.discoverTripsPlanTagsFilters
        let filteredTrips = 0
        await this.refs.searchFunctions.discoverTripsFilter(args).then(result => {
          this.setState({
            filteredDiscoverPublicTripPlans: result.filteredDiscoverPublicTripPlans,

          })
          filteredTrips = result.filteredDiscoverPublicTripPlans.length
          
       });
      } */
    }

    discoverTripsCombineLocationsHandler = async value =>{
      //if there are more then 3 tags remove the additional
      let discoverTripsLocationFilters = this.state.discoverTripsLocationFilters.slice(0,3)

      await this.setState({discoverTripsCombineLocations:value, discoverTripsLocationFilters:discoverTripsLocationFilters})
      let activityKey = ""
      if (value){
        activityKey="DISCOVER_TRIPS_SEARCH_LOCATIONS_ADD"
      }
      else{
        activityKey="DISCOVER_TRIPS_SEARCH_LOCATIONS_OR"
      }
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:activityKey,
        userScreen:this.state.selectedContentWall.toUpperCase(),
      })
      this.discoverPlansHandler()
    }


    addDiscoverTripsCountry = async selection =>{
      let country = selection.treeID
      let discoverTripsCountriesFilters = this.state.discoverTripsCountriesFilters
      if (!discoverTripsCountriesFilters.includes(country))
      {discoverTripsCountriesFilters.push(country)}
      await this.setState({discoverTripsCountriesFilters:discoverTripsCountriesFilters, discoverTripsLimit:30})

      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_TRIPS_SEARCH_COUNTRY_ADD",
        userScreen:this.state.selectedContentWall.toUpperCase(),
        searchValue:selection.treeID
      })
      this.discoverPlansHandler()
    }

    removeDiscoverTripsCountry = async index =>{
      let discoverTripsCountriesFilters = this.state.discoverTripsCountriesFilters
      let removedCountry = discoverTripsCountriesFilters[index]
      discoverTripsCountriesFilters.splice(index,1)
      await this.setState({discoverTripsCountriesFilters:discoverTripsCountriesFilters, discoverTripsLimit:30})
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_TRIPS_SEARCH_COUNTRY_REMOVE",
        userScreen:this.state.selectedContentWall.toUpperCase(),
        searchValue:removedCountry
      })
      this.discoverPlansHandler()
    }



    addDiscoverTripsLocationsFilters = async (tag) =>{
      if (this.state.discoverTripsCombineLocations){
        if (this.state.discoverTripsLocationFilters.length===3) return
      }
      else{
        if (this.state.discoverTripsLocationFilters.length===10) return
      }
      let discoverTripsLocationFilters = this.state.discoverTripsLocationFilters
      if (discoverTripsLocationFilters.includes(tag.treeID)) return
      discoverTripsLocationFilters.push(tag.treeID)
      
      await this.setState({discoverTripsLocationFilters:discoverTripsLocationFilters, discoverTripsLimit:30})
     
      this.discoverPlansHandler()
    }

    removeDiscoverTripsLocationsFilters = async index =>{
      let discoverTripsLocationFilters = this.state.discoverTripsLocationFilters
      let removedLocation = discoverTripsLocationFilters[index]
      discoverTripsLocationFilters.splice(index,1)
      await this.setState({discoverTripsLocationFilters:discoverTripsLocationFilters, discoverTripsLimit:30})
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_TRIPS_SEARCH_LOCATION_REMOVE",
        userScreen:this.state.selectedContentWall.toUpperCase(),
        searchValue:removedLocation
      })
      this.discoverPlansHandler()
    }


    addDiscoverTripsAdditionalTagsFilters = async tag=>{
      let tagKeys = Object.keys(tag)
      let tagValue = tag[tagKeys[0]]
      let discoverTripsPlanAdditionalTagsFilters = this.state.discoverTripsPlanAdditionalTagsFilters
      if (discoverTripsPlanAdditionalTagsFilters.includes(tagValue)) return
      discoverTripsPlanAdditionalTagsFilters.push(tagValue)
      
      await this.setState({discoverTripsPlanAdditionalTagsFilters:discoverTripsPlanAdditionalTagsFilters, discoverTripsLimit:30})
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_TRIPS_SEARCH_ADDITIONAL_TAG_ADD",
        userScreen:this.state.selectedContentWall.toUpperCase(),
        searchValue:tagValue
      })
      this.discoverPlansHandler()
    }

    removeDiscoverTripsAdditionalTag = async index =>{
      let discoverTripsPlanAdditionalTagsFilters = this.state.discoverTripsPlanAdditionalTagsFilters
      let removedTag = discoverTripsPlanAdditionalTagsFilters[index]
      discoverTripsPlanAdditionalTagsFilters.splice(index,1)
      await this.setState({discoverTripsPlanAdditionalTagsFilters:discoverTripsPlanAdditionalTagsFilters, discoverTripsLimit:30})
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_TRIPS_SEARCH_ADDITIONAL_TAG_REMOVE",
        userScreen:this.state.selectedContentWall.toUpperCase(),
        searchValue:removedTag
      })
      this.discoverPlansHandler()
    }

    updateDiscoverTripsCategoriesFilters = async (tags) =>{
      let discoverTripsCategoriesFilters = this.state.discoverTripsCategoriesFilters
      discoverTripsCategoriesFilters = [...tags]
      await this.setState({discoverTripsCategoriesFilters:discoverTripsCategoriesFilters})
      this.discoverPlansHandler()
    }

    updateDiscoverTripsStyleFocus = async (tags) =>{
      let discoverTripsStyleFocus = this.state.discoverTripsStyleFocus
      discoverTripsStyleFocus = [...tags]
      await this.setState({discoverTripsStyleFocus:discoverTripsStyleFocus})
      this.discoverPlansHandler()
    }

    discoverPlansDurationHandler = async value =>{
      await this.setState({discoverPlansDurationFilter:value, discoverTripsLimit:30})
      let args = {
        countriesFilters:this.state.discoverTripsCountriesFilters,
        tripDuration:this.state.discoverPlansDurationFilter,
        tripParticipants:this.state.discoverTripsParticipantsTags,
      }
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_TRIPS_DURATION_FILTER",
        userScreen:this.state.selectedContentWall.toUpperCase(),
        searchValue:value
      })
      this.discoverPlansHandler(args)
    }

    discoverPlansParticipantsHandler = async index =>{
      let discoverPlansParticipants = this.state.discoverPlansParticipants
      discoverPlansParticipants[index] = 1-discoverPlansParticipants[index]
      let discoverTripsParticipantsTags=["Adults"]
      if (discoverPlansParticipants[1]===1){
        discoverTripsParticipantsTags.push("Kids")
      }
      if (discoverPlansParticipants[2]===1){
        discoverTripsParticipantsTags.push("Toddlers")
      }
      await this.setState({discoverPlansParticipants:discoverPlansParticipants,discoverTripsParticipantsTags:discoverTripsParticipantsTags, discoverTripsLimit:30})
      
      this.discoverPlansHandler()
    }

    getUserCountryPlaces = () =>{
      if (this.props.systemInitializeData.countriesToExplore.includes(this.props.userCountry)){
        this.userCountryHandler()
      }
      else{
        this.userCountryHandler("Israel")
      }
    }         

    userCountryHandler = (countryParam) =>{
      let country = this.props.userCountry
      if (typeof countryParam==="string"){
        country = countryParam
      }
        if (this.state.placesLocationSearchTags!==undefined){
          let screen = ""
          if (this.state.viewPlan){
            screen = "VIEW_TRIP"
          }
          else{
            screen = this.state.selectedContentWall.toUpperCase()
          }
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"DISCOVER_USER_COUNTRY_PLACES",
            userScreen:screen,
            eventLevel:1
          })
           /*  if (this.state.selectedContentType==="Places"){ */
                //load places for country
                this.setState({selectedContentType:"Places"})
                this.loadCountryPlaces(country)
           /*  } */
        }
       else{
           this.setState({waitingToExplore:true})
       }
    }

    loadCountryPlaces = async (countryName,tag,search,expandCategory) =>{
        let searchTag = tag;
        if (tag===undefined){
            searchTag = this.state.userCountryTag
        }
        // if searching for new country
        if (countryName!==this.state.filteredPlacesCountryName){
            await this.setState({
                discoverCountryPlaces:[],
                discoverLocationFilteredPlaces:[],
                filteredDiscoverPlaces:[],
                filteredDiscoverPlacesWithChars:[],
                discoverPlacesLocationFilterTags:[],
                discoverPlacesSelectedMainCategory:[1,0,0,0,0,0,0],
                discoverPlacesCharacteristicsFilter: {},
                discoverPlacesDaysFilter:[0,0,0,0,0,0,0],
                discoverPlacesAdditionalTagFilterTag:[],
            })
            if (!expandCategory){
                await this.setState({
                    discoverPlacesCategoriesFilterTags: [],
                })
            }
        }
        //if handling country tag or searching for location in different country
        

        // if already loaded this country places
        if(this.state.slimPlacesByCountry[countryName]!==undefined){
         
            await this.setState({discoverCountryPlaces:this.state.slimPlacesByCountry[countryName],filteredPlacesCountry:countryName})
            this.discoverPlacesLocationSearchTagHandler(searchTag,search)
        }
        // if need to load the country places
        else{
            await this.refs.loadDatabase.loadCountryPlaces(countryName).then(result => {
            let slimPlacesByCountry = this.state.slimPlacesByCountry
            slimPlacesByCountry[countryName]=result
            let discoverCountryPlaces = this.state.discoverCountryPlaces
            if (result!==undefined){
              discoverCountryPlaces = result
            }
            this.setState({discoverCountryPlaces:discoverCountryPlaces, slimPlacesByCountry:slimPlacesByCountry,filteredPlacesCountry:countryName})
            this.discoverPlacesLocationSearchTagHandler(searchTag,search)
          }); 
        }
        this.setState({selectedContentWall:"Discover",selectedContentType:"Places"})
    }

    searchDiscoverLocationHandler = async tag =>{
      this.refs.writeDatabase.googleAnalyticsWrite(
        {
          activityKey:'search',
          googleParams:{
            search_term: tag[this.props.inputLanguage],
          }
        }
      )
    
        if (this.state.selectedContentType==="Places"){
            if (tag.treeID!==undefined){
                //location tag
                let countryName = tag.treeID.split('_')[0]
                this.loadCountryPlaces(countryName,tag,true)
             
                this.refs.writeDatabase.writeAcitivityLog({
                  activityKey:"DISCOVER_PLACES_SEARCH_LOCATION",
                  userScreen:this.state.selectedContentWall.toUpperCase(),
                  searchValue:tag.treeID,
                  eventLevel:1
                })
            }
            else if(tag.id!==undefined){
              this.addPlaceCategoryTagHandler(tag.id)
              this.refs.writeDatabase.writeAcitivityLog({
                activityKey:"DISCOVER_PLACES_SEARCH_CATEGORY",
                userScreen:this.state.selectedContentWall.toUpperCase(),
                searchValue:tag.id,
                eventLevel:1
              })
            }
            else if (tag.placeTag!==undefined){
              //place tag
              this.discoverPlacesAdditionalTagSearchTagHandler(tag)

              this.refs.writeDatabase.writeAcitivityLog({
                activityKey:"DISCOVER_PLACES_SEARCH_ADDITIONALTAG",
                userScreen:this.state.selectedContentWall.toUpperCase(),
                searchValue:tag[this.props.inputLanguage],
                eventLevel:1
              })
             
            }
            else{
                //if search for place directly from welcome page need to make sure that user country places are already loaded and if not do this first
                if (this.state.slimPlacesByCountry[this.props.userCountry]===undefined){
                  await this.setState({welcomePageSearchName:true,welcomePageSearchNameTag:tag})
                  this.loadCountryPlaces(this.props.userCountry)
                  return
                }
               
                //name tag
                this.discoverPlacesNameSearchTagHandler(tag)

                this.refs.writeDatabase.writeAcitivityLog({
                  activityKey:"DISCOVER_PLACES_SEARCH_NAME",
                  userScreen:this.state.selectedContentWall.toUpperCase(),
                  searchValue:tag[this.props.inputLanguage],
                  eventLevel:1
                })
            }
           
        }
        if (this.state.selectedContentType==="Trips"){
          if (this.state.selectedContentWall==="WelcomePage"){
            await this.setState({
              discoverTripsCountriesFilters:[],
              discoverTripsLocationFilters: [],
              discoverTripsPlanAdditionalTagsFilters:[],
              discoverPlansDurationFilter: [],
              discoverTripsParticipantsTags: ["Adults"],
              discoverPlansDurationFilter:[1,20],
              discoverPlansParticipants:[1,0,0],
            })
          }
         /*  if (tag.treeID.split('_')[1]!==undefined){
            this.addDiscoverTripsLocationsFilters(tag)
          }
          else{
            this.addDiscoverTripsCountry(tag)
          } */
          if (tag.treeID!==undefined){
            this.addDiscoverTripsLocationsFilters(tag)
            this.refs.writeDatabase.writeAcitivityLog({
              activityKey:"DISCOVER_TRIPS_SEARCH_LOCATION",
              userScreen:this.state.selectedContentWall.toUpperCase(),
              searchValue:tag.treeID,
              eventLevel:1
            })
          }
          else{
            this.addDiscoverTripsAdditionalTagsFilters(tag)
          }
          
        }
    }

    discoverPlacesNameSearchTagHandler = async tag =>{
      if (this.state.selectedContentWall!=="Discover"){
        this.setState({selectedContentWall:"Discover"})
      }
        let discoverPlacesNameFilterTag=this.state.discoverPlacesNameFilterTag;
        //don't add a tag that already is filtered
        for (let i = 0 ; i<discoverPlacesNameFilterTag.length ; i++){
          if (discoverPlacesNameFilterTag[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
            return
          }
        }
     
        discoverPlacesNameFilterTag.push({tag:tag,type:"NameFilter",language:this.props.inputLanguage});

        await this.setState({
            discoverPlacesNameFilterTag: discoverPlacesNameFilterTag
        })
        this.discoverPlacesCharacteristicsSearchHandler()
      }
  
      discoverPlaceNameSearchRemoveHandler = async index =>{
          if (index===undefined){
            await this.setState({discoverPlacesNameFilterTag:[]})
          }
          else{
            let discoverPlacesNameFilterTag = this.state.discoverPlacesNameFilterTag;
            discoverPlacesNameFilterTag.splice(index,1);
            await this.setState({discoverPlacesNameFilterTag:discoverPlacesNameFilterTag})
          }  
          this.discoverPlacesCharacteristicsSearchHandler()     
      }

      discoverPlacesAdditionalTagSearchTagHandler = async tag =>{
        let discoverPlacesAdditionalTagFilterTag=this.state.discoverPlacesAdditionalTagFilterTag;
        //don't add a tag that already is filtered
        for (let i = 0 ; i<discoverPlacesAdditionalTagFilterTag.length ; i++){
          if (discoverPlacesAdditionalTagFilterTag[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
            return
          }
        }
     
        discoverPlacesAdditionalTagFilterTag.push({tag:tag,type:"AdditionalTagFilter",language:this.props.inputLanguage});

        await this.setState({
          discoverPlacesAdditionalTagFilterTag: discoverPlacesAdditionalTagFilterTag
        })
        this.discoverPlacesCharacteristicsSearchHandler()
      }

      discoverPlaceAddiotnalTagSearchRemoveHandler = async index =>{
        if (index===undefined){
          await this.setState({discoverPlacesAdditionalTagFilterTag:[]})
        }
        else{
          let discoverPlacesAdditionalTagFilterTag = this.state.discoverPlacesAdditionalTagFilterTag;
          discoverPlacesAdditionalTagFilterTag.splice(index,1);
          await this.setState({discoverPlacesAdditionalTagFilterTag:discoverPlacesAdditionalTagFilterTag})
        }    
        this.discoverPlacesCharacteristicsSearchHandler()   
    }

    discoverPlacesHideMapHandler = async () =>{
        //need to show all the places based on current location, category and other filters
        let discoverPlacesLocationFilterTags = this.state.discoverPlacesLocationFilterTags
        let lastTag = discoverPlacesLocationFilterTags[discoverPlacesLocationFilterTags.length-1]
        discoverPlacesLocationFilterTags.splice(discoverPlacesLocationFilterTags.length-1,1)
        await this.setState({discoverPlacesLocationFilterTags:discoverPlacesLocationFilterTags})
        this.discoverPlacesLocationSearchTagHandler(lastTag.tag,undefined,undefined,true)
    }

    discoverPlacesLocationSearchTagHandler = async (tag,search,tagWasRemoved,userClosedMap) =>{
        if (tag===undefined) return
        // if search tag already filtered - do nothing
        if (!tagWasRemoved&&this.state.discoverPlacesLocationFilterTags.map(tag=>{return tag.tag.treeID}).includes(tag.treeID)){
            return
        }
        if (!userClosedMap){
          this.refs.writeDatabase.locationSearchLogHandler(tag,tag.treeID.split('_')[0])
        }
        
        if (tag.type==="country"||this.state.selectedContentWall==="WelcomePage"){
            //get location search tags
            let locationSearchTags = [];
            for (let i = 0 ; i<this.state.placesLocationSearchTags.length ; i++){
                if (this.state.placesLocationSearchTags[i].treeID!==undefined){
                    if (this.state.placesLocationSearchTags[i].treeID.split('_')[0]===tag.treeID.split('_')[0]){
                        locationSearchTags.push(this.state.placesLocationSearchTags[i])
                    }
                }
            }
            this.setState({discoverPlacesLocationsSearchTags:locationSearchTags})
        }
        let args = {
            searchTag: tag,
            discoverPlacesLocationFilterTags: this.state.discoverPlacesLocationFilterTags,
            discoverCountryPlaces: this.state.discoverCountryPlaces,
            locationsTree: this.state.locationsTree,
            search: search
        }
        await this.refs.searchFunctions.discoverPlacesLocationSearchFunction(args).then(result => {
            this.setState({
                discoverLocationFilteredPlaces: result.discoverLocationFilteredPlaces,
                discoverPlacesLocationFilterTags: result.discoverPlacesLocationFilterTags,
                suggestedLocationsFilters: result.suggestedLocationsFilters,
                locationTagSuggestedLocationsFilters: result.suggestedLocationsFilters,
                filteredDiscoverPlaces: result.discoverLocationFilteredPlaces,
                filteredDiscoverPlacesWithChars:result.discoverLocationFilteredPlaces,
                discoverPlacesAvailableCategories: result.discoverPlacesAvailableCategories,
                discoverPlacesAvailableMainCategories: result.discoverPlacesAvailableMainCategories,
                discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags,
                discoverPlacesAdditionalTagsSearchTags: result.discoverPlacesTagsSearchTags
            })
            this.refs.discover.useLocationFilterPlaces(result.discoverLocationFilteredPlaces,true)
            
        });
        //handling for the show near by handler in case it was used not during discover mode 
        if (this.state.awaitingShowNearby){
          await this.setState({awaitingShowNearby:false})
          this.showNearByHandler(this.state.showNearByCoordinates,null,this.state.showNearbyPlaceID)
        }
        //if category filters exist filter by them as well
        if (this.state.discoverPlacesCategoriesFilterTags.length > 0){
            this.discoverPlacesCategorySearchTagHandler()
        }
        //else if main category is filtered apply it
        else if (this.state.discoverPlacesSelectedMainCategory[0]===0){
            this.discoverPlacesMainCategoryHandler(-1)
        }
        if (this.state.welcomePageSearchName){
          let tag = this.state.welcomePageSearchNameTag
          this.setState({welcomePageSearchName:false,welcomePageSearchNameTag:undefined})
          this.discoverPlacesNameSearchTagHandler(tag)
        }
    }


    removeDiscoverPlacesLocationSearchTagHandler = async (index)=>{
        if (index===0){
            //removing country
            this.setState({
                discoverCountryPlaces:[],
                discoverLocationFilteredPlaces:[],
                filteredDiscoverPlaces:[],
                filteredDiscoverPlacesWithChars:[],
                discoverPlacesLocationFilterTags:[],
                discoverPlacesSelectedMainCategory:[1,0,0,0,0,0,0],
                discoverPlacesCategoriesFilterTags: [],

                discoverPlacesNameFilterTag:[],
                discoverPlacesCharacteristicsFilter: {},
                discoverPlacesDaysFilter: [0,0,0,0,0,0,0],
                discoverPlacesAdditionalTagFilterTag :[],

                selectedContentWall:"WelcomePage",

                hideNavbar:false
            })

            this.refs.writeDatabase.writeAcitivityLog({
              activityKey:"DISCOVER_PLACES_REMOVE_COUNTRY_FILTER",
              userScreen:this.state.selectedContentWall.toUpperCase()
            })
        }
        else{
            let discoverPlacesLocationFilterTags = this.state.discoverPlacesLocationFilterTags;
            let searchTag = discoverPlacesLocationFilterTags[index-1].tag
            discoverPlacesLocationFilterTags.splice(index)
            await this.setState({discoverPlacesLocationFilterTags:discoverPlacesLocationFilterTags})
            this.discoverPlacesLocationSearchTagHandler(searchTag,true,true)

            this.refs.writeDatabase.writeAcitivityLog({
              activityKey:"DISCOVER_PLACES_REMOVE_LOCATION_FILTER",
              userScreen:this.state.selectedContentWall.toUpperCase()
            })
        }
    }

    discoverPlacesMainCategoryHandler = async index =>{
        let discoverPlacesSelectedMainCategory = this.state.discoverPlacesSelectedMainCategory;
        if (index===-1){
            //in case map bounds changed or location filter applied and need to re-apply the category filter
            const mainIndex = discoverPlacesSelectedMainCategory.findIndex(cat => {
                return cat === 1;
              });
            await this.setState({discoverPlacesSelectedMainCategory:discoverPlacesSelectedMainCategory })
            let args = {
                selectedMainCategoryIndex: mainIndex-1,
                discoverLocationFilteredPlaces: this.state.discoverLocationFilteredPlaces,
            }
            await this.refs.searchFunctions.discoverPlacesMainCategoryFunction(args).then(result => {
                this.setState({
                    suggestedLocationsFilters: result.suggestedLocationsFilters,
                    filteredDiscoverPlaces: result.filteredPlaces,
                    filteredDiscoverPlacesWithChars:result.filteredPlaces,
                    discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags
                })
            });
               //handle the sub categories applied
               if (this.state.discoverPlacesCategoriesFilterTags.length > 0){
                this.discoverPlacesCategorySearchTagHandler()
              } 
        }
        else if (discoverPlacesSelectedMainCategory[index]===1){
            //unselect the main category
            //get back to previous location suggestions (based on location filter or map bounds)
            //all categories will be abailable, and places will be filtered by location
            //if category is filtered then run it ouside
            discoverPlacesSelectedMainCategory = [1,0,0,0,0,0,0];
            await this.setState({suggestedLocationsFilters:this.state.locationTagSuggestedLocationsFilters,
                discoverPlacesSelectedMainCategory:discoverPlacesSelectedMainCategory,
                filteredDiscoverPlaces:this.state.discoverLocationFilteredPlaces,
                filteredDiscoverPlacesWithChars:this.state.discoverLocationFilteredPlaces
               
            })
            if (this.state.discoverPlacesCategoriesFilterTags.length > 0){
              this.discoverPlacesCategorySearchTagHandler()
            }

             //in this scenario all sub  and main category chars will be reseted
             let newChars = {}
             if (this.state.discoverPlacesCharacteristicsFilter.generalTags!==undefined){
               newChars.generalTags = this.state.discoverPlacesCharacteristicsFilter.generalTags
             }
             if (this.state.discoverPlacesCharacteristicsFilter.audienceTags!==undefined){
               newChars.audienceTags = this.state.discoverPlacesCharacteristicsFilter.audienceTags
             }
             this.setState({discoverPlacesCharacteristicsFilter: newChars})
        }
        else{
            //select main category
            //update main category selected - which will show only categories under that main category in filters
            //places will be filtered based on this category
            //if filtered categories are under this main cateogry they will be applied otherwise neglected
            discoverPlacesSelectedMainCategory = [0,0,0,0,0,0,0];
            discoverPlacesSelectedMainCategory[index] = 1;
            await this.setState({discoverPlacesSelectedMainCategory:discoverPlacesSelectedMainCategory })
            let args = {
                selectedMainCategoryIndex: index-1,
                discoverLocationFilteredPlaces: this.state.discoverLocationFilteredPlaces,
            }
            await this.refs.searchFunctions.discoverPlacesMainCategoryFunction(args).then(result => {
                this.setState({
                    suggestedLocationsFilters: result.suggestedLocationsFilters,
                    filteredDiscoverPlaces: result.filteredPlaces,
                    filteredDiscoverPlacesWithChars:result.filteredPlaces,
                    discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags,
                    discoverPlacesCategoriesFilterTags: [],
                    discoverPlacesCharacteristicsFilter: {},
                    /* discoverPlacesDaysFilter:[0,0,0,0,0,0,0],
                    discoverPlacesAdditionalTagFilterTag:[], */
                })
            }); 
              //handle the sub categories applied
            
        }

      
    }

    addPlaceCategoryTagHandler = category => {
      this.discoverPlacesCategorySearchTagHandler(category,"add");
      this.refs.writeDatabase.categorySearchLogHandler(category,this.state.discoverPlacesLocationFilterTags[0].tag.treeID.split('_')[0])
    
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"DISCOVER_PLACES_ADD_CATEGORY_TAG",
        userScreen:this.state.selectedContentWall.toUpperCase()
      })
    }

    discoverPlacesCategorySearchTagHandler = async (tag,scenario) =>{
        if (scenario==="remove"){
            //in this case tag is index
            let discoverPlacesCategoriesFilterTags = this.state.discoverPlacesCategoriesFilterTags
            discoverPlacesCategoriesFilterTags.splice(tag,1)
            await this.setState({discoverPlacesCategoriesFilterTags:discoverPlacesCategoriesFilterTags})

            //in this scenario all sub category chars will be reseted
            let newChars = {}
            if (this.state.discoverPlacesCharacteristicsFilter.generalTags!==undefined){
              newChars.generalTags = this.state.discoverPlacesCharacteristicsFilter.generalTags
            }
            if (this.state.discoverPlacesCharacteristicsFilter.audienceTags!==undefined){
              newChars.audienceTags = this.state.discoverPlacesCharacteristicsFilter.audienceTags
            }
            if (this.state.discoverPlacesCharacteristicsFilter.mainTags!==undefined){
              newChars.mainTags = this.state.discoverPlacesCharacteristicsFilter.mainTags
            }
            this.setState({discoverPlacesCharacteristicsFilter: newChars})
            
        }
        else if(scenario==="add"){
          let searchTag = this.state.categoriesTranslator[tag]
            let discoverPlacesCategoriesFilterTags = this.state.discoverPlacesCategoriesFilterTags
            let tagLanguage="";
            if (searchTag[this.props.userLanguage]!==undefined){
                tagLanguage=this.props.userLanguage;
            }
            else {
                tagLanguage="English";
            }
            discoverPlacesCategoriesFilterTags.push({tag:searchTag,type:"CategoryFilter",language:tagLanguage})
            await this.setState({discoverPlacesCategoriesFilterTags:discoverPlacesCategoriesFilterTags})
        }
        
        let args = {
            discoverPlacesCategoriesFilterTags: this.state.discoverPlacesCategoriesFilterTags,
            discoverLocationFilteredPlaces: this.state.discoverLocationFilteredPlaces
        }

        if (this.state.discoverPlacesCategoriesFilterTags.length===0){
            await this.setState({suggestedLocationsFilters:this.state.locationTagSuggestedLocationsFilters,
                filteredDiscoverPlaces:this.state.discoverLocationFilteredPlaces,
                filteredDiscoverPlacesWithChars:this.state.discoverLocationFilteredPlaces,
            })
        }
        //categories search is an OR, meaning place need to have at least one from the least
        else{
            await this.refs.searchFunctions.discoverPlacesCategorySearchTagFunction(args).then(result => {
                this.setState({
                    filteredDiscoverPlaces: result.filteredPlaces,
                    filteredDiscoverPlacesWithChars:result.filteredPlaces,
                    suggestedLocationsFilters: result.suggestedLocationsFilters,
                    discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags,
                })
            }); 
        }
    }



    mapBoundariesPlacesHandler = async (mapBounds) =>{
        //handle updating location filter tags based on results in bounds
        let args = {
            mapBounds: mapBounds,
            discoverCountryPlaces: this.state.discoverCountryPlaces,
            discoverPlacesSelectedMainCategory: this.state.discoverPlacesSelectedMainCategory,
            discoverPlacesLocationFilterTags: this.state.discoverPlacesLocationFilterTags
        }
        await this.refs.searchFunctions.discoverPlacesMapBoundsFunction(args).then(result => {
            this.setState({
                filteredDiscoverPlaces: result.filteredPlaces,
                discoverLocationFilteredPlaces: result.filteredPlaces,
                filteredDiscoverPlacesWithChars: result.filteredPlaces,
                suggestedLocationsFilters: result.suggestedLocationsFilters,
                discoverPlacesAvailableCategories: result.discoverPlacesAvailableCategories,
                discoverPlacesAvailableMainCategories: result.discoverPlacesAvailableMainCategories,
                discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags,
                discoverPlacesLocationFilterTags: result.discoverPlacesLocationFilterTags
            })
        }); 
         //if category filters exist filter by them as well
         if (this.state.discoverPlacesCategoriesFilterTags.length > 0){
            this.discoverPlacesCategorySearchTagHandler()
        }
        //else if main category is filtered apply it
        else if (this.state.discoverPlacesSelectedMainCategory[0]===0){
            this.discoverPlacesMainCategoryHandler(-1)
        }
        
    }

    updatePlaceCharacteristicsFilters = async (char)=>{
      await this.setState({discoverPlacesCharacteristicsFilter:char});
      this.discoverPlacesCharacteristicsSearchHandler()
    }

    discoverPlacesCharacteristicsSearchHandler = async () =>{
      let args = {
        placesList:this.state.filteredDiscoverPlaces,
        discoverPlacesNameFilterTag:this.state.discoverPlacesNameFilterTag,
        discoverPlacesAdditionalTagFilterTag: this.state.discoverPlacesAdditionalTagFilterTag,
        discoverPlacesCharacteristicsFilter:this.state.discoverPlacesCharacteristicsFilter,
        discoverPlacesDaysFilter: this.state.discoverPlacesDaysFilter
      } 
      await this.refs.searchFunctions.placeCharacteristicsHandler(args).then(result => {
        this.setState({
            filteredDiscoverPlacesWithChars: result.filteredPlaces,
          /*   suggestedLocationsFilters: result.suggestedLocationsFilters,
            discoverPlacesAvailableCategories: result.discoverPlacesAvailableCategories,
            discoverPlacesAvailableMainCategories: result.discoverPlacesAvailableMainCategories,
            discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags, */
        })
    }); 
    }

    showNearByHandler = async (coordinates, country,placeID) =>{
      if (this.state.selectedContentWall!=="Discover"){
        await this.setState({awaitingShowNearby:true,showNearByCoordinates:coordinates,showNearbyPlaceID:placeID,selectedContentWall:"Discover"})
        //reset all discover places filters
        await this.setState({
          discoverCountryPlaces:[],
          discoverLocationFilteredPlaces:[],
          filteredDiscoverPlaces:[],
          discoverPlacesLocationFilterTags:[],
          discoverPlacesLocationsSearchTags:[],
          discoverPlacesAvailableCategories: [],
          discoverPlacesAvailableMainCategories: [],
          discoverPlacesCategoriesFilterTags:[],
          discoverPlacesSelectedMainCategory:[1,0,0,0,0,0,0],
          discoverPlacesNameFilterTag:[],
          discoverPlacesCharacteristicsFilter: {},
          discoverPlacesDaysFilter: [0,0,0,0,0,0,0],
          discoverPlacesAdditionalTagsSearchTags:[],
          discoverPlacesAdditionalTagFilterTag :[],
        })
        await this.loadCountryPlaces(country)
      }
      else if(!this.state.awaitingShowNearby){
        let placeData = {
          coordinates:coordinates,
          placeID:placeID
        }
        this.refs.discover.enableMap(placeData)
        this.setState({viewPlace:false})
         /*  setTimeout(e => {
            
            this.refs.discover.enableMap()
            }, 500);    
          setTimeout(e => {
          
            this.setState({viewPlace:false})
            this.refs.discover.showNearByHandler(coordinates,placeID)
            }, 1000);   */
        }
    }

      //------SELECTED USER SEARCH FUNCTIONS-----

      selectedUserExperiencesSearchHandler  = async (tag,tagWasRemoved) =>{
        let selectedUserExperiencesFilterTags = this.state.selectedUserExperiencesFilterTags
          if (!tagWasRemoved){
              
            if (selectedUserExperiencesFilterTags.length>0){
                for (let i =0 ; i<selectedUserExperiencesFilterTags.length ; i++){
                  if (selectedUserExperiencesFilterTags[i].tag[this.props.inputLanguage]!==undefined){
                    if (selectedUserExperiencesFilterTags[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                      return;
                    }
                  }
                }
              } 
             
              //add options for places,plans etc...
              selectedUserExperiencesFilterTags.push({tag:tag,language:this.props.inputLanguage});
              this.refs.writeDatabase.writeAcitivityLog({
                activityKey:"USERWALL_EXPERIENCES_ADD_FILTERTAG",
                userScreen:"USERWALL"
              })
          }
          let args = {
            searchTag: tag,
            filterTags: selectedUserExperiencesFilterTags,
            experiencesList: this.state.selectedUserExperiences,
            filteredExperiencesList: this.state.filteredSelectedUserExperiences,
        }
        await this.refs.searchFunctions.experiencesSearchFunction(args).then(result => {
             this.setState({
              filteredSelectedUserExperiences: result.filteredExperiences,
                selectedUserExperiencesSearchTags: result.searchTags,
                selectedUserExperiencesFilterTags: result.filterTags,
            })  
        }); 
      }
      

      selectedUserExperiencesRemoveTagHandler = async index =>{
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"USERWALL_EXPERIENCES_REMOVE_TAG",
          userScreen:"USERWALL"
        })

        let selectedUserExperiencesFilterTags=this.state.selectedUserExperiencesFilterTags;
        selectedUserExperiencesFilterTags.splice(index,1);
        this.setState({selectedUserExperiencesFilterTags:selectedUserExperiencesFilterTags})
        if (selectedUserExperiencesFilterTags.length===0){
            this.setState({selectedUserExperiencesSearchTags:this.state.allSelectedUserExperiencesSearchTags,
              filteredSelectedUserExperiences: this.state.selectedUserExperiences})
        }
        else{
          for (let i=0; i<selectedUserExperiencesFilterTags.length ; i++){
            await this.selectedUserExperiencesSearchHandler(selectedUserExperiencesFilterTags[i].tag,true)
          }
        }
      }

      selectedUserTripsSearchHandler = async (tag,tagWasRemoved) =>{
        let selectedUserTripPlansFilterTags = this.state.selectedUserTripPlansFilterTags
          if (!tagWasRemoved){
              
            if (selectedUserTripPlansFilterTags.length>0){
                for (let i =0 ; i<selectedUserTripPlansFilterTags.length ; i++){
                  if (selectedUserTripPlansFilterTags[i].tag[this.props.inputLanguage]!==undefined){
                    if (selectedUserTripPlansFilterTags[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                      return;
                    }
                  }
                }
              } 
             
              //add options for places,plans etc...
              selectedUserTripPlansFilterTags.push({tag:tag,language:this.props.inputLanguage});
          }
        let args = {
            searchTag: tag,
            filterTags: selectedUserTripPlansFilterTags,
            tripsList: this.state.selectedUserTripPlans,
            filteredTripsList: this.state.filteredSelectedUserTripPlans,
        }
        await this.refs.searchFunctions.tripsSearchFunction(args).then(result => {
             this.setState({
              filteredSelectedUserTripPlans: result.filteredTrips,
                selectedUserTripPlansSearchTags: result.searchTags,
                selectedUserTripPlansFilterTags: result.filterTags,
            })  
        }); 

      }

      selectedUserTripsRemoveTagHandler = async (index) =>{
        let selectedUserTripPlansFilterTags=this.state.selectedUserTripPlansFilterTags;
        selectedUserTripPlansFilterTags.splice(index,1);
        this.setState({selectedUserTripPlansFilterTags:selectedUserTripPlansFilterTags})
        if (selectedUserTripPlansFilterTags.length===0){
            this.setState({selectedUserTripPlansSearchTags:this.state.allSelectedUserTripPlansSearchTags,
            filteredSelectedUserTripPlans: this.state.selectedUserTripPlans})
        }
        else{
          for (let i=0; i<selectedUserTripPlansFilterTags.length ; i++){
            await this.userContentPlansSearchHandler(selectedUserTripPlansFilterTags[i].tag,true)
          }
        }
      }

      //------MY CONTENT SEARCH FUNCTIONS--------
      
      userContentSearchHandler = (tag) =>{
        if (this.state.selectedContentType==="Places"){
          this.userContentPlacesSearchHandler(tag)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"MYCONTENT_PLACES_ADD_FILTERTAG",
            userScreen:this.state.selectedContentWall.toUpperCase()
          })
        }
        else if  (this.state.selectedContentType==="Trips"){
          this.userContentPlansSearchHandler(tag)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"MYCONTENT_TIPS_ADD_FILTERTAG",
            userScreen:this.state.selectedContentWall.toUpperCase()
          })
        }
        else if  (this.state.selectedContentType==="Experiences"){
          this.userContentExperiencesSearchHandler(tag)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"MYCONTENT_EXPERIENCES_ADD_FILTERTAG",
            userScreen:this.state.selectedContentWall.toUpperCase()
          })
        }
      }

      userContentPlacesSearchHandler = async (tag,tagWasRemoved) =>{
        let userCreatedPlacesFilterTags = this.state.userCreatedPlacesFilterTags
          if (!tagWasRemoved){
              
            if (userCreatedPlacesFilterTags.length>0){
                for (let i =0 ; i<userCreatedPlacesFilterTags.length ; i++){
                  if (userCreatedPlacesFilterTags[i].tag[this.props.inputLanguage]!==undefined){
                    if (userCreatedPlacesFilterTags[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                      return;
                    }
                  }
                }
              } 
             
              //add options for places,plans etc...
              userCreatedPlacesFilterTags.push({tag:tag,language:this.props.inputLanguage});
          }
        let args = {
            searchTag: tag,
            userCreatedPlacesFilterTags: userCreatedPlacesFilterTags,
            userCreatedPlaces: this.state.userCreatedPlaces,
            filteredUserCreatedPlaces: this.state.filteredUserCreatedPlaces,
        }
        await this.refs.searchFunctions.userCreatedPlacesSearchFunction(args).then(result => {
             this.setState({
              filteredUserCreatedPlaces: result.filteredPlaces,
                userCreatedPlacesSearchTags: result.userCreatedPlacesSearchTags,
                userCreatedPlacesFilterTags: result.userCreatedPlacesFilterTags,
            })  
        });  
      }

      userContentExperiencesSearchHandler = async (tag,tagWasRemoved) =>{
        let userExperiencesFilterTags = this.state.userExperiencesFilterTags
          if (!tagWasRemoved){
              
            if (userExperiencesFilterTags.length>0){
                for (let i =0 ; i<userExperiencesFilterTags.length ; i++){
                  if (userExperiencesFilterTags[i].tag[this.props.inputLanguage]!==undefined){
                    if (userExperiencesFilterTags[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                      return;
                    }
                  }
                }
              } 
             
              //add options for places,plans etc...
              userExperiencesFilterTags.push({tag:tag,language:this.props.inputLanguage});
          }
        let args = {
            searchTag: tag,
            filterTags: userExperiencesFilterTags,
            experiencesList: this.state.userExperiences,
            filteredExperiencesList: this.state.filteredUserExperiences,
        }
        await this.refs.searchFunctions.experiencesSearchFunction(args).then(result => {
             this.setState({
              filteredUserExperiences: result.filteredExperiences,
                userExperiencesSearchTags: result.searchTags,
                userExperiencesFilterTags: result.filterTags,
            })  
        }); 

      }

      userContentPlansSearchHandler = async (tag,tagWasRemoved) =>{
        let userCreatedTripPlansFilterTags = this.state.userCreatedTripPlansFilterTags
          if (!tagWasRemoved){
              
            if (userCreatedTripPlansFilterTags.length>0){
                for (let i =0 ; i<userCreatedTripPlansFilterTags.length ; i++){
                  if (userCreatedTripPlansFilterTags[i].tag[this.props.inputLanguage]!==undefined){
                    if (userCreatedTripPlansFilterTags[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                      return;
                    }
                  }
                }
              } 
             
              //add options for places,plans etc...
              userCreatedTripPlansFilterTags.push({tag:tag,language:this.props.inputLanguage});
          }
        let args = {
            searchTag: tag,
            filterTags: userCreatedTripPlansFilterTags,
            tripsList: this.state.userCreatedTripPlans,
            filteredTripsList: this.state.filteredUserCreatedTripPlans,
        }
        await this.refs.searchFunctions.tripsSearchFunction(args).then(result => {
             this.setState({
              filteredUserCreatedTripPlans: result.filteredTrips,
                userCreatedTripPlansSearchTags: result.searchTags,
                userCreatedTripPlansFilterTags: result.filterTags,
            })  
        }); 

      }

      userContentRemoveTagHandler = async (index) =>{
        if (this.state.selectedContentType==="Places"){
          this.userContentRemovePlaceTagHandler(index)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"MYCONTENT_PLACES_REMOVE_TAG",
            userScreen:this.state.selectedContentWall.toUpperCase()
          })
        }
        else if  (this.state.selectedContentType==="Trips"){
          this.userContentRemovePlanTagHandler(index)
        }
        else if  (this.state.selectedContentType==="Experiences"){
          this.userContentRemoveExperienceTagHandler(index)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"MYCONTENT_EXPERIENCES_REMOVE_TAG",
            userScreen:this.state.selectedContentWall.toUpperCase()
          })
        }
      }

      
      userContentRemovePlaceTagHandler = async (index)=>{
        let userCreatedPlacesFilterTags=this.state.userCreatedPlacesFilterTags;
        userCreatedPlacesFilterTags.splice(index,1);
        this.setState({userCreatedPlacesFilterTags:userCreatedPlacesFilterTags})
        if (userCreatedPlacesFilterTags.length===0){
            this.setState({userCreatedPlacesSearchTags:this.state.allUserCreatedPlacesSearchTags,
            filteredUserCreatedPlaces: this.state.userCreatedPlaces})
        }
        else{
          for (let i=0; i<userCreatedPlacesFilterTags.length ; i++){
            await this.userCreatedPlacesSearchHandler(userCreatedPlacesFilterTags[i].tag,true)
          }
        }
      }

      userContentRemoveExperienceTagHandler = async (index) =>{
        let userExperiencesFilterTags=this.state.userExperiencesFilterTags;
        userExperiencesFilterTags.splice(index,1);
        this.setState({userExperiencesFilterTags:userExperiencesFilterTags})
        if (userExperiencesFilterTags.length===0){
            this.setState({userExperiencesSearchTags:this.state.allUserExperiencesSearchTags,
            filteredUserExperiences: this.state.userExperiences})
        }
        else{
          for (let i=0; i<userExperiencesFilterTags.length ; i++){
            await this.userContentExperiencesSearchHandler(userExperiencesFilterTags[i].tag,true)
          }
        }
      }

      userContentRemovePlanTagHandler = async (index) =>{
        let userCreatedTripPlansFilterTags=this.state.userCreatedTripPlansFilterTags;
        userCreatedTripPlansFilterTags.splice(index,1);
        this.setState({userCreatedTripPlansFilterTags:userCreatedTripPlansFilterTags})
        if (userCreatedTripPlansFilterTags.length===0){
            this.setState({userCreatedTripPlansSearchTags:this.state.allUserCreatedTripPlansSearchTags,
            filteredUserCreatedTripPlans: this.state.userCreatedTripPlans})
        }
        else{
          for (let i=0; i<userCreatedTripPlansFilterTags.length ; i++){
            await this.userContentPlansSearchHandler(userCreatedTripPlansFilterTags[i].tag,true)
          }
        }
      }

      //------FAVORITE SEARCH FUNCTIONS---------
      favoriteSearchHandler = (tag) =>{
        if (this.state.selectedContentType==="Places"){
          this.favoritePlacesSearchHandler(tag)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"FAVORITE_PLACES_ADD_FILTERTAG",
            userScreen: this.state.selectedContentWall.toUpperCase(),
            tagID:tag[this.props.inputLanguage]
          })
        }
        else if  (this.state.selectedContentType==="Trips"){
          this.favoritePlansSearchHandler(tag)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"FAVORITE_TRIPS_ADD_FILTERTAG",
            userScreen: this.state.selectedContentWall.toUpperCase(),
            tagID:tag[this.props.inputLanguage]
          })
        }
      }

      favoritePlacesSearchHandler = async (tag,tagWasRemoved) =>{
        if (tag.noResults!==undefined){
          this.setState({noResultsFavoritePlaces:true})
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"FAVORITE_PLACES_ADD_FILTERTAG_NORESULTS",
            userScreen: this.state.selectedContentWall.toUpperCase(),
            tagID:tag[this.props.inputLanguage]
          })
        }
        let favoritePlacesFilterTags = this.state.favoritePlacesFilterTags
          if (!tagWasRemoved){
              
            if (favoritePlacesFilterTags.length>0){
                for (let i =0 ; i<favoritePlacesFilterTags.length ; i++){
                  if (favoritePlacesFilterTags[i].tag[this.props.inputLanguage]!==undefined){
                    if (favoritePlacesFilterTags[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                      return;
                    }
                  }
                }
              } 
             
              //add options for places,plans etc...
              favoritePlacesFilterTags.push({tag:tag,language:this.props.inputLanguage});
          }
        let args = {
            searchTag: tag,
            favoritePlacesFilterTags: favoritePlacesFilterTags,
            favoritePlaces: this.state.favoritePlaces,
            filteredFavoritePlaces: this.state.filteredFavoritePlaces,
        }
        await this.refs.searchFunctions.favoritePlacesSearchFunction(args).then(result => {
             this.setState({
                filteredFavoritePlaces: result.filteredPlaces,
                favoritePlacesSearchTags: result.favoritePlacesSearchTags,
                favoritePlacesFilterTags: result.favoritePlacesFilterTags,
            })  
        });  
      }

      favoritePlansSearchHandler = async (tag,tagWasRemoved) =>{
        if (tag.noResults!==undefined){
          this.setState({noResultsFavoriteTrips:true})
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"FAVORITE_TRIPS_ADD_FILTERTAG_NORESULTS",
            userScreen: this.state.selectedContentWall.toUpperCase(),
            tagID:tag[this.props.inputLanguage]
          })
        }
        let favoriteTripPlansFilterTags = this.state.favoriteTripPlansFilterTags
          if (!tagWasRemoved){
              
            if (favoriteTripPlansFilterTags.length>0){
                for (let i =0 ; i<favoriteTripPlansFilterTags.length ; i++){
                  if (favoriteTripPlansFilterTags[i].tag[this.props.inputLanguage]!==undefined){
                    if (favoriteTripPlansFilterTags[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                      return;
                    }
                  }
                }
              } 
             
              //add options for places,plans etc...
              favoriteTripPlansFilterTags.push({tag:tag,language:this.props.inputLanguage});
          }
        let args = {
            searchTag: tag,
            favoriteTripPlansFilterTags: favoriteTripPlansFilterTags,
            favoriteTripPlans: this.state.favoriteTripPlans,
            filteredFavoriteTripPlans: this.state.filteredFavoriteTripPlans,
        }
        await this.refs.searchFunctions.favoriteTripPlansSearchFunction(args).then(result => {
             this.setState({
              filteredFavoriteTripPlans: result.filteredTrips,
              favoriteTripPlansSearchTags: result.favoriteTripPlansSearchTags,
                favoriteTripPlansFilterTags: result.favoriteTripPlansFilterTags,
            })  
        });  
      }

      favoriteRemoveTagHandler = async (index) =>{
        if (this.state.selectedContentType==="Places"){
          this.favoriteRemovePlaceTagHandler(index)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"FAVORITE_PLACES_REMOVE_FILTERTAG",
            userScreen: this.state.selectedContentWall.toUpperCase(),
          })
        }
        else if  (this.state.selectedContentType==="Trips"){
          this.favoriteRemovePlanTagHandler(index)
          this.refs.writeDatabase.writeAcitivityLog({
            activityKey:"FAVORITE_TRIPS_REMOVE_FILTERTAG",
            userScreen: this.state.selectedContentWall.toUpperCase()
          })
        }
      }



      favoriteRemovePlaceTagHandler = async (index)=>{
        let favoritePlacesFilterTags=this.state.favoritePlacesFilterTags;
        let oldTag = favoritePlacesFilterTags[index]
        if (oldTag.tag.noResults!==undefined){
          this.setState({noResultsFavoritePlaces:false})
        }
        favoritePlacesFilterTags.splice(index,1);
        this.setState({favoritePlacesFilterTags:favoritePlacesFilterTags})
        if (favoritePlacesFilterTags.length===0){
            this.setState({favoritePlacesSearchTags:this.state.allFavoritePlacesSearchTags,
            filteredFavoritePlaces: this.state.favoritePlaces})
        }
        else{
          for (let i=0; i<favoritePlacesFilterTags.length ; i++){
            await this.favoritePlacesSearchHandler(favoritePlacesFilterTags[i].tag,true)
          }
        }
      }

      favoriteRemovePlanTagHandler = async (index)=>{
        let favoriteTripPlansFilterTags=this.state.favoriteTripPlansFilterTags;
        let oldTag = favoriteTripPlansFilterTags[index]
        if (oldTag.tag.noResults!==undefined){
          this.setState({noResultsFavoriteTrips:false})
        }
        favoriteTripPlansFilterTags.splice(index,1);
        this.setState({favoriteTripPlansFilterTags:favoriteTripPlansFilterTags})
        if (favoriteTripPlansFilterTags.length===0){
            this.setState({favoriteTripPlansSearchTags:this.state.allFavoriteTripPlansSearchTags,
              filteredFavoriteTripPlans: this.state.favoriteTripPlans})
        }
        else{
          for (let i=0; i<favoriteTripPlansFilterTags.length ; i++){
            await this.favoritePlansSearchHandler(favoriteTripPlansFilterTags[i].tag,true)
          }
        }
      }

    //---------Database interactive functions ----------

    removeFromFavoriteHandler = (object,type)=>{
        switch (type){
          case "Place":{
            this.refs.writeDatabase.removePlaceFromFavorite(object)

            let screen = ""
            if (this.state.viewPLace){
              screen = "VIEW_PLACE"
            }
            else{
              screen = this.state.selectedContentWall.toUpperCase()
            }
            this.refs.writeDatabase.writeAcitivityLog({
              activityKey:"REMOVE_PLACE_FROM_FAVORITE",
              userScreen: screen,
              placeID: object.placeID
            })
            break;
          }
          case "Trip":{
            this.refs.writeDatabase.removePlanFromFavorite(object)

            let screen = this.state.selectedContentWall.toUpperCase()
          
            this.refs.writeDatabase.writeAcitivityLog({
              activityKey:"REMOVE_TRIP_FROM_FAVORITE",
              userScreen: screen
            })
            break;
          }
          default: return null;
        }
      }

    addToFavoriteDBHandler = favoriteTags =>{
        switch (this.state.favoriteContentType){
          case "Place":{
            this.refs.writeDatabase.addPlaceToFavorite(this.state.favoriteObject,favoriteTags,this.state.addToFavorite)

            let activityKey = ""
            if (this.state.addToFavorite){
              activityKey = "ADD_PLACE_TO_FAVORITE_SAVE_BUTTON"
            }
            else{
              activityKey = "UPDATE_PLACE_FAVORITE_TAGS_SAVE_BUTTON"
            }
            let screen = "ADD_TO_FAVORITE"

            this.refs.writeDatabase.writeAcitivityLog({
              activityKey:activityKey,
              userScreen: screen,
              favoriteTags:favoriteTags,
              placeID: this.state.favoriteObject.placeID
            })
            break;
          }
          case "Trip":{
            this.refs.writeDatabase.addPlanToFavorite(this.state.favoriteObject,favoriteTags,this.state.addToFavorite)

           let activityKey = ""
            if (this.state.addToFavorite){
              activityKey = "ADD_TRIP_TO_FAVORITE_SAVE_BUTTON"
            }
            else{
              activityKey = "UPDATE_TRIP_FAVORITE_TAGS_SAVE_BUTTON"
            }
            let screen = "ADD_TO_FAVORITE"

            this.refs.writeDatabase.writeAcitivityLog({
              activityKey:activityKey,
              userScreen: screen,
              favoriteTags:favoriteTags,
            })
            break;
          }
          default: return null;
        }
      }

      discardUserProfileWall = () =>{
        this.setState({
          showUserWall:false,
          selectedUser:undefined,
          selectedUserExperiences:[],
          filteredSelectedUserExperiences:[],
          selectedUserTripPlans:[],
          filteredSelectedUserTripPlans:[],
          selectedUserTripPlansSearchTags:[],
          allSelectedUserTripPlansSearchTags:[],
          selectedUserExperiencesSearchTags:[],
          selectedUserExperiencesFilterTags:[],
          allSelectedUserExperiencesSearchTags:[]
         })
         this.refs.writeDatabase.writeAcitivityLog({
          activityKey:"USERWALL_DIDSCARD",
          userScreen:"USERWALL"
        })
      }
  
      discardAddToFavorite = () =>{
        this.setState({
          addToFavorite: false,
          updateFavoriteTags:false,
          showNullBackdrop: false,
        });

        let activityKey = ""
        if (this.state.favoriteContentType === "Place"){
          activityKey = "ADD_PLACE_TO_FAVORITE_DISCARD_BUTTON"
        }
        else if (this.state.favoriteContentType==="Trip"){
          activityKey = "ADD_TRIP_TO_FAVORITE_DISCARD_BUTTON"
        }
        this.refs.writeDatabase.writeAcitivityLog({
          activityKey:activityKey,
          userScreen:"ADD_TO_FAVORITE"
        })
      }

      discardAddToPlan = () =>{
        this.setState({
          addToPlan: false,
          showNullBackdrop: false,
        });
      }

      newPlanHandler = () =>{
        this.setState({
          viewPlace:false,
          addToPlan:false,
          createTripPlan: true,showCreateMenu: false, showBackdrop: false,
                  showNullBackdrop: true,
        })
      }

      //----------Pure DB update functions----------
      updateSettingsHandler = userSettings =>{
        this.refs.writeDatabase.updateUserSettings(userSettings);
      }

      adminHandler = async () =>{
        await this.refs.loadDatabase.getSystemMetrics()
        
      }
      

      writeDatabaseCallBackHandler =async (scenario,data)=>{
          switch (scenario){
              case "Reload":{
                this.props.reloadApp();
                break;
              }
              case "TripNotExist":{
                //need to handle auto removal from where it was tried to be accessed...
                this.setState({
                  showInfo:true,
                  showNullBackdrop:true, 
                  infoType:"Negative",
                  infoHeader: this.props.systemText.place.viewPlace.informationModal.planNotExistHeader[this.props.userLanguage], 
                  informationMessage:this.props.systemText.place.viewPlace.informationModal.planNotExistMessage[this.props.userLanguage],
                  btnText: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage],  
                })
                //if this is user trip then delete it
                if (this.state.selectedContentWall==="WelcomePage"||this.state.selectedContentWall==="MyContent"){
                  let userCreatedTripPlans = this.state.userCreatedTripPlans
                  const index = userCreatedTripPlans.findIndex(plan => {
                    return plan.planID === data;
                });
                if (index!==-1){
                  this.deletePlanHandler(userCreatedTripPlans[index])
                }
                  //remove this trip from user list
                }
                break;
              }
              case "ActivitiesLogData":{
                let activitiesLogData = {}
                if (this.state.activitiesLogData!==undefined){
                  activitiesLogData=this.state.activitiesLogData
                }
                activitiesLogData[data.date] = data.log
                this.setState({activitiesLogData:activitiesLogData})
                break;
              }
              case "UsersRequestsData":{
                let usersRequestsData = {}
                if (this.state.usersRequestsData!==undefined){
                  usersRequestsData=this.state.usersRequestsData
                }
                usersRequestsData[data.date] = data.requests
                this.setState({usersRequestsData:usersRequestsData})
                break;
              }
              case "RequestSent":{
                this.setState({
                  showInfo: true,
                  infoType:"Positive",
                  infoHeader: this.props.systemText.extraModals.requestMessages.requestSent.title[this.props.userLanguage],
                  informationMessage:this.props.systemText.extraModals.requestMessages.requestSent.message[this.props.userLanguage],
                  btnText: this.props.systemText.extraModals.requestMessages.requestSent.button[this.props.userLanguage],
                  showNullBackdrop:true
                })
                break;
              }
              case "LoadPlaceExperiences":{
                this.setState({placeExperiences:data.list,
                  placeExperiencesFeedLoadedLimit:data.limit,
                  loadedMoreExperiencesToPlaceFeed:true})
                break;
              }
              case "AddLatestViewedPlaces":{
                let latestViewedPlaces = this.state.latestViewedPlaces;
                if (latestViewedPlaces.length===7){
                    latestViewedPlaces.splice(6,1)
                  }
                latestViewedPlaces.unshift(data)
                this.setState({latestViewedPlaces:latestViewedPlaces})
                localStorage.setItem('latestViewedPlaces',JSON.stringify(latestViewedPlaces));
                
                break;
              }
              case "UpdateLatestViewedPlaces":{
                let latestViewedPlaces = [...this.state.latestViewedPlaces];
                const index = latestViewedPlaces.findIndex(place => {
                    return place.placeID === data.placeID;
                });
                latestViewedPlaces[index] = data
                await this.setState({latestViewedPlaces:latestViewedPlaces});
                break;
              }
              case "AddFavoritePlace":{
                 //update favorite places list
                 let favoritePlacesIDs = this.state.favoritePlacesIDs
                 favoritePlacesIDs.push(data.place.placeID)
                 this.setState({favoritePlacesIDs:favoritePlacesIDs})

                let favoritePlaces = this.state.favoritePlaces
                let favoritePlace = data.place;
                favoritePlace.favoriteSearchTags=data.tags;
                favoritePlaces.unshift(favoritePlace)
                await this.setState({favoritePlaces:favoritePlaces})

                //update favorite places search tags
                await this.refs.searchFunctions.updateFavoritePlacesSearchTags(favoritePlaces).then(result => {
                    this.setState({
                        favoritePlacesSearchTags:result
                    })
                    if (this.state.favoritePlacesFilterTags.length === 0){
                        this.setState({
                            allFavoritePlacesSearchTags:result
                        })
                    }
                    //+++++++++++++++++++++perhaps there is something strange here=+++++++++++++++++++++
                    else{
                        this.setState({
                            allFavoritePlacesSearchTags:result
                        })
                    }
                }); 
                break;
              }
              case "UpdateFavoritePlace":{
                //only if favorite places are loaded need to update these lists otherwise will be updated on load
                if (this.state.favoritePlaces.length>0){
                    let favoritePlace = data.place;
                    favoritePlace.favoriteSearchTags=data.tags;

                    let favoritePlaces = [...this.state.favoritePlaces];
                    const favIndex = favoritePlaces.findIndex(place => {
                        return place.placeID === data.place.placeID;
                    });
                    favoritePlaces[favIndex] = favoritePlace
                    await this.setState({favoritePlaces:favoritePlaces});

                    if (this.state.favoritePlacesFilterTags.length>0){
                        //favorite plaes list is filtered
                        let filteredFavoritePlaces = [...this.state.filteredFavoritePlaces];
                        const filteredIndex = filteredFavoritePlaces.findIndex(place => {
                            return place.placeID === data.place.placeID;
                        });
                        filteredFavoritePlaces[filteredIndex]=favoritePlace
                        await this.setState({filteredFavoritePlaces:filteredFavoritePlaces});
                        await this.refs.searchFunctions.updateFavoritePlacesSearchTags(filteredFavoritePlaces).then(result => {
                            this.setState({
                                favoritePlacesSearchTags:result
                            })
                        }); 
                    }
                    else{
                        this.setState({filteredFavoritePlaces:favoritePlaces}); 
                        await this.refs.searchFunctions.updateFavoritePlacesSearchTags(favoritePlaces).then(result => {
                            this.setState({
                                favoritePlacesSearchTags:result, allFavoritePlacesSearchTags:result
                            })
                        }); 
                    }
                }
                break;
              }
              case "RemovePlaceFromFavorite":{
                let favoritePlacesIDs=this.state.favoritePlacesIDs;
                const idIndex = favoritePlacesIDs.findIndex(placeID => {
                  return placeID === data.placeID;
                });
                favoritePlacesIDs.splice(idIndex,1);
                this.setState({favoritePlacesIDs:favoritePlacesIDs});
                //only if favorite places are loaded need to update these lists otherwise will be updated on load
                if (this.state.favoritePlaces.length>0){
                    let favoritePlaces = [...this.state.favoritePlaces];
                    const favIndex = favoritePlaces.findIndex(place => {
                        return place.placeID === data.placeID;
                    });
                    favoritePlaces.splice(favIndex,1);
                    await this.setState({favoritePlaces:favoritePlaces});

                    if (this.state.favoritePlacesFilterTags.length>0){
                        //favorite plaes list is filtered
                        let filteredFavoritePlaces = [...this.state.filteredFavoritePlaces];
                        const filteredIndex = filteredFavoritePlaces.findIndex(place => {
                            return place.placeID === data.placeID;
                        });
                        filteredFavoritePlaces.splice(filteredIndex,1);
                        await this.setState({filteredFavoritePlaces:filteredFavoritePlaces});
                        await this.refs.searchFunctions.updateFavoritePlacesSearchTags(filteredFavoritePlaces).then(result => {
                            this.setState({
                                favoritePlacesSearchTags:result
                            })
                        }); 
                    }
                    else{
                        this.setState({filteredFavoritePlaces:favoritePlaces}); 
                        await this.refs.searchFunctions.updateFavoritePlacesSearchTags(favoritePlaces).then(result => {
                            this.setState({
                                favoritePlacesSearchTags:result, allFavoritePlacesSearchTags:result
                            })
                        }); 
                    }
                }
                break;
              }
              case "AddFavoriteTrip":{
                //update favorite places list
                let favoriteTripsIDs = this.state.favoriteTripsIDs
                favoriteTripsIDs.push(data.plan.planID)
                this.setState({favoriteTripsIDs:favoriteTripsIDs})

               let favoriteTripPlans = this.state.favoriteTripPlans
               let favoriteTrip = data.plan;
               favoriteTrip.favoriteSearchTags=data.tags;
               favoriteTripPlans.unshift(favoriteTrip)
               await this.setState({favoriteTripPlans:favoriteTripPlans})

               //update favorite places search tags
               await this.refs.searchFunctions.updateFavoriteTripsSearchTags(favoriteTripPlans).then(result => {
                   this.setState({
                    favoriteTripPlansSearchTags:result
                   })
                   if (this.state.favoriteTripPlansFilterTags.length === 0){
                       this.setState({
                        allFavoriteTripPlansSearchTags:result
                       })
                   }
                   //+++++++++++++++++++++perhaps there is something strange here=+++++++++++++++++++++
                   else{
                       this.setState({
                        allFavoriteTripPlansSearchTags:result
                       })
                   }
               }); 
               break;
             }
             case "UpdateFavoriteTrip":{
               //only if favorite places are loaded need to update these lists otherwise will be updated on load
               if (this.state.favoriteTripPlansLoaded){
                   let favoriteTrip = data.plan;
                   favoriteTrip.favoriteSearchTags=data.tags;

                   let favoriteTripPlans = [...this.state.favoriteTripPlans];
                   const favIndex = favoriteTripPlans.findIndex(plan => {
                       return plan.planID === data.plan.planID;
                   });
                   favoriteTripPlans[favIndex] = favoriteTrip
                   await this.setState({favoriteTripPlans:favoriteTripPlans});

                   if (this.state.favoriteTripPlansFilterTags.length>0){
                       //favorite plaes list is filtered
                       let filteredFavoriteTripPlans = [...this.state.filteredFavoriteTripPlans];
                       const filteredIndex = filteredFavoriteTripPlans.findIndex(plan => {
                           return plan.planID === data.plan.planID;
                       });
                       filteredFavoriteTripPlans[filteredIndex]=favoriteTrip
                       await this.setState({filteredFavoriteTripPlans:filteredFavoriteTripPlans});
                       await this.refs.searchFunctions.updateFavoriteTripsSearchTags(filteredFavoriteTripPlans).then(result => {
                           this.setState({
                            favoriteTripPlansSearchTags:result
                           })
                       }); 
                   }
                   else{
                       this.setState({filteredFavoriteTripPlans:favoriteTripPlans}); 
                       await this.refs.searchFunctions.updateFavoriteTripsSearchTags(favoriteTripPlans).then(result => {
                           this.setState({
                            favoriteTripPlansSearchTags:result, allFavoriteTripPlansSearchTags:result
                           })
                       }); 
                   }
               }
               break;
             }
             case "RemoveTripFromFavorite":{
               let favoriteTripsIDs=this.state.favoriteTripsIDs;
               const idIndex = favoriteTripsIDs.findIndex(planID => {
                 return planID === data.planID;
               });
               favoriteTripsIDs.splice(idIndex,1);
               this.setState({favoriteTripsIDs:favoriteTripsIDs});
               //only if favorite places are loaded need to update these lists otherwise will be updated on load
               if (this.state.favoriteTripPlansLoaded){
                   let favoriteTripPlans = [...this.state.favoriteTripPlans];
                   const favIndex = favoriteTripPlans.findIndex(plan => {
                       return plan.planID === data.planID;
                   });
                   favoriteTripPlans.splice(favIndex,1);
                   await this.setState({favoriteTripPlans:favoriteTripPlans});

                   if (this.state.favoriteTripPlansFilterTags.length>0){
                       //favorite plaes list is filtered
                       let filteredFavoriteTripPlans = [...this.state.filteredFavoriteTripPlans];
                       const filteredIndex = filteredFavoriteTripPlans.findIndex(plan => {
                           return plan.planID === data.planID;
                       });
                       filteredFavoriteTripPlans.splice(filteredIndex,1);
                       await this.setState({filteredFavoriteTripPlans:filteredFavoriteTripPlans});
                       await this.refs.searchFunctions.updateFavoriteTripsSearchTags(filteredFavoriteTripPlans).then(result => {
                           this.setState({
                               favoriteTripPlansSearchTags:result
                           })
                       }); 
                   }
                   else{
                       this.setState({filteredFavoriteTripPlans:favoriteTripPlans}); 
                       await this.refs.searchFunctions.updateFavoriteTripsSearchTags(favoriteTripPlans).then(result => {
                           this.setState({
                            favoriteTripPlansSearchTags:result, allFavoriteTripPlansSearchTags:result
                           })
                       }); 
                   }
               }
               break;
             }
              case "CreatePlace":{
               let userCreatedPlaces = this.state.userCreatedPlaces
               userCreatedPlaces.unshift(data.place)
               await this.setState({userCreatedPlaces:userCreatedPlaces,
                filteredUserCreatedPlaces: userCreatedPlaces,
                userCreatedPlacesFilterTags:[]})

               //update favorite places search tags
               await this.refs.searchFunctions.updateUserCreatedPlacesSearchTags(userCreatedPlaces).then(result => {
                   this.setState({
                       userCreatedPlacesSearchTags:result,
                       allUserCreatedPlacesSearchTags:result
                   })
                }); 
                if (this.state.quickPlaceWasCreated){
                  this.updateQuickCreationPlaceSelection(data.place)
                }

                if (data.inFavorite){
                  this.writeDatabaseCallBackHandler("AddFavoritePlace",{place:data.place,tags:[]})
                }
                break;
              }
              case "UpdatePlace":{
                this.setState({selectedPlace:data.systemPlace})

                if (this.state.userCreatedPlacesLoaded){
                    let userCreatedPlaces = [...this.state.userCreatedPlaces];
                    const index = userCreatedPlaces.findIndex(place => {
                        return place.placeID === data.slimPlace.placeID;
                    });
                    userCreatedPlaces[index] = data.slimPlace
                    await this.setState({userCreatedPlaces:userCreatedPlaces});

                    if (this.state.userCreatedPlacesFilterTags.length>0){
                        //favorite plaes list is filtered
                        let filteredUserCreatedPlaces = [...this.state.filteredUserCreatedPlaces];
                        const filteredIndex = filteredUserCreatedPlaces.findIndex(place => {
                            return place.placeID === data.slimPlace.placeID;
                        });
                        filteredUserCreatedPlaces[filteredIndex]=data.slimPlace
                        await this.setState({filteredUserCreatedPlaces:filteredUserCreatedPlaces});
                        await this.refs.searchFunctions.updateUserCreatedPlacesSearchTags(filteredUserCreatedPlaces).then(result => {
                            this.setState({
                              userCreatedPlacesSearchTags:result
                            })
                        }); 
                    }
                    else{
                        this.setState({filteredUserCreatedPlaces:userCreatedPlaces}); 
                        await this.refs.searchFunctions.updateUserCreatedPlacesSearchTags(userCreatedPlaces).then(result => {
                            this.setState({
                              userCreatedPlacesSearchTags:result, allUserCreatedPlacesSearchTags:result
                            })
                        }); 
                    }
                }               
                break;
              }
              case "DeletePlace":{
                if (this.state.userCreatedPlacesLoaded){
                  let userCreatedPlaces = [...this.state.userCreatedPlaces];
                  const index = userCreatedPlaces.findIndex(place => {
                      return place.placeID === data;
                  });
                  userCreatedPlaces.splice(index,1);
                  await this.setState({userCreatedPlaces:userCreatedPlaces});

                  if (this.state.userCreatedPlacesFilterTags.length>0){
                      //favorite plaes list is filtered
                      let filteredUserCreatedPlaces = [...this.state.filteredUserCreatedPlaces];
                      const filteredIndex = filteredUserCreatedPlaces.findIndex(place => {
                          return place.placeID === data;
                      });
                      filteredUserCreatedPlaces.splice(filteredIndex,1)
                      await this.setState({filteredUserCreatedPlaces:filteredUserCreatedPlaces});
                      await this.refs.searchFunctions.updateUserCreatedPlacesSearchTags(filteredUserCreatedPlaces).then(result => {
                          this.setState({
                            userCreatedPlacesSearchTags:result
                          })
                      }); 
                  }
                  else{
                      this.setState({filteredUserCreatedPlaces:userCreatedPlaces}); 
                      await this.refs.searchFunctions.updateUserCreatedPlacesSearchTags(userCreatedPlaces).then(result => {
                          this.setState({
                            userCreatedPlacesSearchTags:result, allUserCreatedPlacesSearchTags:result
                          })
                      }); 
                  }
                }
                break;
              }
              case "FriendRequest":{
                let myFriends = this.state.myFriends;
                myFriends.push(data);
                this.setState({myFriends})
                break;
              }
              case "AcceptFriendship":{
                let myFriends = this.state.myFriends;
                const index = myFriends.findIndex(user => {
                    return user.userID === data.userID;
                    });
                myFriends[index]=data;
                this.setState({myFriends})
                break;
              }
              case "RemoveFriend":{
                let myFriends = this.state.myFriends;
                const index = myFriends.findIndex(user => {
                  return user.userID === data.userID;
                });
                myFriends.splice(index,1);
                this.setState({myFriends})

                let followingUsersIDs = this.state.followingUsersIDs;
                const followingIndex = followingUsersIDs.findIndex(userID => {
                  return userID === data.userID;
                });
                followingUsersIDs.splice(followingIndex,1);
                this.setState({followingUsersIDs})

                //remove all user experiences from experiences feed
                let newExperiencesFeed = this.state.userExperiencesFeed.filter(exp=>exp.owner.ownerID!==data.userID)
                this.setState({userExperiencesFeed:newExperiencesFeed})
                break;
              }
              case "NewFollowing":{
                let usersIFollow = this.state.usersIFollow;
                usersIFollow.push(data);
                this.setState({usersIFollow})

                let followingUsersIDs = this.state.followingUsersIDs;
                followingUsersIDs.push(data.userID);
                this.setState({followingUsersIDs})
                this.setState({loadedMoreExperiencesToFeed:true})
                break;
              }
              case "StopFollowing":{
                let usersIFollow = this.state.usersIFollow;
                const index = usersIFollow.findIndex(user => {
                  return user.userID === data.userID;
                });
                usersIFollow.splice(index,1);
                this.setState({usersIFollow})

                let followingUsersIDs = this.state.followingUsersIDs;
                const followingIndex = followingUsersIDs.findIndex(userID => {
                  return userID === data.userID;
                });
                followingUsersIDs.splice(followingIndex,1);
                this.setState({followingUsersIDs})

                 //remove all user experiences from experiences feed
                 let newExperiencesFeed = this.state.userExperiencesFeed.filter(exp=>exp.owner.ownerID!==data.userID)
                 this.setState({userExperiencesFeed:newExperiencesFeed})
                break;
              }
              case "CreateTripPlan":{
                let userCreatedTripPlans = this.state.userCreatedTripPlans
                userCreatedTripPlans.unshift(data);
                //handle user plans search tags

                this.setState({
                  userCreatedTripPlans:userCreatedTripPlans,
                  filteredUserCreatedTripPlans: userCreatedTripPlans
                })

                localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(data));
                this.setState({latestUserCreatedTripPlan: data})
                break;
              }
              case "ViewPlan":{
                if (this.state.clonningPlan){
                  this.setState({clonningPlan:false})
                }
                else{
                  await this.setState({viewedPlanPlaces:[...data.planPlacesList]})
                } 
                //creating cached trip is only when trip created from cache after user logged in, then no reason to open it
                if (!this.state.creatingCachedTrip){
                  this.setState({selectedPlan: data, viewPlan:true});
                }
                else{
                  this.setState({creatingCachedTrip:false})
                }
                if (!(this.state.showSuggestedTrips&&this.state.selectedContentWall==="Discover")){
                  if (this.state.latestUserCreatedTripPlan!==undefined){
                    if (this.state.latestUserCreatedTripPlan.planID!==data.planID){
                      localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(data));
                      this.setState({latestUserCreatedTripPlan: data})
                    }
                  }
                }
              
               
                break;
              }
              case "UpdatePlanExperiences":{
                
                await this.setState({selectedPlanExperiences: data});
                break;
              }
              case "PlanSelected":{
                
                if (this.refs.addToPlan!==undefined){
                  await this.setState({selectedPlan: data});
                  this.refs.addToPlan.finishLoadingSelectedPlan()
                }
                else if (this.state.clonningPlan){
                  await this.setState({createTripPlan: true,clonedPlan:data, viewedPlanPlaces:data.planPlacesList})
                  
                }
                break;
              }
              case "UpdateTripPlan":{
                let userCreatedTripPlans=[...this.state.userCreatedTripPlans];
                const planIndex = userCreatedTripPlans.findIndex(plan => {
                  return plan.planID === data.planID;
                });
                userCreatedTripPlans[planIndex]=data;
                this.setState({userCreatedTripPlans: userCreatedTripPlans});
        
                let filteredUserCreatedTripPlans = [...this.state.filteredUserCreatedTripPlans]
                const filteredIndex = filteredUserCreatedTripPlans.findIndex(plan => {
                  return plan.planID === data.planID;
                });
                filteredUserCreatedTripPlans[filteredIndex]=data;
                this.setState({filteredUserCreatedTripPlans: filteredUserCreatedTripPlans});

                localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(data));
                  this.setState({latestUserCreatedTripPlan: data})
                break;
              }
              case "DeleteUserTripPlan":{
                let userCreatedTripPlans=[...this.state.userCreatedTripPlans];
                const planIndex = userCreatedTripPlans.findIndex(plan => {
                  return plan.planID === data;
                });
                userCreatedTripPlans.splice(planIndex,1);
                this.setState({userCreatedTripPlans: userCreatedTripPlans});
        
                let filteredUserCreatedTripPlans = [...this.state.filteredUserCreatedTripPlans]
                const filteredIndex = filteredUserCreatedTripPlans.findIndex(plan => {
                  return plan.planID === data;
                });
                filteredUserCreatedTripPlans.splice(filteredIndex,1);
                this.setState({filteredUserCreatedTripPlans: filteredUserCreatedTripPlans});


                if (userCreatedTripPlans.length>0){
                  localStorage.setItem('latestUserCreatedTripPlan',JSON.stringify(userCreatedTripPlans[0]));
                  this.setState({latestUserCreatedTripPlan: userCreatedTripPlans[0]})
                }
                else{
                  localStorage.removeItem('latestUserCreatedTripPlan');
                  this.setState({latestUserCreatedTripPlan: undefined})
                }
                //===UPDATE PLANS SEARCH TAGS
                break;
              }
              case "CreateExperience":{
                //update feed wall
                let userExperiencesFeed = [];
                if (this.state.userExperiencesFeed!==undefined){ userExperiencesFeed = [...this.state.userExperiencesFeed]}
                userExperiencesFeed.unshift(data)
                this.setState({userExperiencesFeed:userExperiencesFeed})

                //update user created experiences
               
                if (this.state.userExperiencesLoaded){
                  let userExperiences = [...this.state.userExperiences];
                  userExperiences.unshift(data)
                  this.setState({userExperiences:userExperiences, filteredUserExperiences:userExperiences})

                  //update search tags
                this.refs.searchFunctions.updateExperienceSearchTags(userExperiences).then(result => {
                  this.setState({
                    userExperiencesSearchTags:result, allUserExperiencesSearchTags:result,
                  })
                  
                }); 
                }
                //update trip experiences if experience was shared from within a trip
                if (this.state.viewPlan){
                  let selectedPlanExperiences = this.state.selectedPlanExperiences
                  selectedPlanExperiences.push(data)
                  this.setState({selectedPlanExperiences:selectedPlanExperiences})
                }
                
                break;
              }
              case "UpdateExperience":{
                let userExperiencesFeed = [...this.state.userExperiencesFeed]
                const feedIndex = userExperiencesFeed.findIndex(exp => {
                  return exp.experienceID === data.experienceID;
                });
                if (feedIndex!==-1){
                  userExperiencesFeed[feedIndex] = data
                }
                else{
                  userExperiencesFeed.unshift(data)
                }
                this.setState({userExperiencesFeed:userExperiencesFeed})

                if (this.state.userExperiencesLoaded){
                  let userExperiences = [...this.state.userExperiences];
                  const index = userExperiences.findIndex(exp => {
                    return exp.experienceID === data.experienceID;
                  });
                  userExperiences[index]=data
                  this.setState({userExperiences:userExperiences, filteredUserExperiences:userExperiences})

                  this.refs.searchFunctions.updateExperienceSearchTags(userExperiences).then(result => {
                    this.setState({
                      userExperiencesSearchTags:result, allUserExperiencesSearchTags:result,
                    })
                    
                  }); 
                }
                if (this.state.viewPlan&&!this.state.removingExperienceFromPlan){
                  let selectedPlanExperiences = this.state.selectedPlanExperiences
                  const index = selectedPlanExperiences.findIndex(exp => {
                    return exp.experienceID === data.experienceID;
                  });
                  if (index!==-1){
                    selectedPlanExperiences[index]=data
                  }
                 
                  this.setState({selectedPlanExperiences:selectedPlanExperiences})
                }
                else if (this.state.removingExperienceFromPlan){
                  this.setState({removingExperienceFromPlan:false})
                }
                break;
              }
              case "DeleteExperience":{
                let userExperiencesFeed = [...this.state.userExperiencesFeed]
                const feedIndex = userExperiencesFeed.findIndex(exp => {
                  return exp.experienceID === data;
                });
                if (feedIndex!==-1){
                  userExperiencesFeed.splice(feedIndex,1)
                }
                this.setState({userExperiencesFeed:userExperiencesFeed})

                if (this.state.userExperiencesLoaded){
                  let userExperiences = [...this.state.userExperiences];
                  const index = userExperiences.findIndex(exp => {
                    return exp.experienceID === data;
                  });
                  userExperiences.splice(index,1)
                  this.setState({userExperiences:userExperiences, filteredUserExperiences:userExperiences})

                  this.refs.searchFunctions.updateExperienceSearchTags(userExperiences).then(result => {
                    this.setState({
                      userExperiencesSearchTags:result, allUserExperiencesSearchTags:result,
                    })
                    
                  }); 
                }
                break;
              }
              default: return null
          }
      }

     
      finishWriteDataMessage = (scenario) =>{
        switch (scenario){
          case "createPlaceFinished":{
            this.setState({
              showInfo: true,
              infoType:"Positive",
              infoHeader: this.props.systemText.place.createPlace.informationModal.infoHeaderFinished[this.props.userLanguage], 
              informationMessage:this.props.systemText.place.createPlace.informationModal.informationMessageFinished[this.props.userLanguage],
              btnText: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage],
              showNullBackdrop:true
            })
            break;
          }
          case "updatePlaceFinished":{
            this.setState({
              showInfo: true,
              infoType:"Positive",
              infoHeader: this.props.systemText.place.createPlace.informationModal.infoHeaderFinished[this.props.userLanguage], 
              informationMessage:this.props.systemText.place.createPlace.informationModal.informationMessageUpdateFinished[this.props.userLanguage],
              btnText: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage],
              showNullBackdrop:true
            })
            break;
          }
          case "createExperienceFinished":{
            this.setState({
              showInfo: true,
              infoType:"Positive",
              infoHeader: this.props.systemText.place.createPlace.informationModal.infoHeaderFinished[this.props.userLanguage], 
              informationMessage:this.props.systemText.place.createPlace.informationModal.informationMessageExperienceCreationFinished[this.props.userLanguage],
              btnText: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage],
              showNullBackdrop:true
            })
            break;
          }
          case "updateExperienceFinished":{
            this.setState({
              showInfo: true,
              infoType:"Positive",
              infoHeader: this.props.systemText.place.createPlace.informationModal.infoHeaderFinished[this.props.userLanguage], 
              informationMessage:this.props.systemText.place.createPlace.informationModal.informationMessageExperienceUpdateFinished[this.props.userLanguage],
              btnText: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage],
              showNullBackdrop:true
            })
            break;
          }
          default: return null;
        }
       
      }
    //========RENDERING===================

    //--------ASSIST COMPONENTS RENDER-----------------

    loadDatabase = () =>{
        return(
            <LoadDatabse
              ref="loadDatabase"
              user = {this.props.user}
              userID = {this.props.user.userID}
              userCountry={this.props.userCountry}
              finish={this.loadFinishedHandler}
              environment={this.props.environment}
              updateLoadData={this.updateLoadData}
              loggedUser={this.props.loggedUser}
          />
          )
    }

    writeDatabase = () =>{
        return(
            <WriteDatabase
              ref="writeDatabase"
              user = {this.props.user}
              userID = {this.props.user.userID}
              userSessionID = {this.props.userSessionID}
              adminUser = {this.props.isAdminUser}
              userCountry={this.props.userCountry}
              userResidenceCountry = {this.props.userResidenceCountry}
              environment={this.props.environment}
              loggedUser={this.props.loggedUser}
              callBack = {this.writeDatabaseCallBackHandler}
              finishWriteDataMessage = {this.finishWriteDataMessage}
          />
          )
    }


    renderSearchFunctions = () =>{
        return (<SearchFunctions
            ref="searchFunctions"
            userLanguage={this.props.userLanguage}
            inputLanguage = {this.props.inputLanguage}
            user={this.props.user}

            locationsTranslator = {this.state.locationsTranslator}
            categoriesTranslator = {this.state.categoriesTranslator}
            userCategoriesScore={this.state.userCategoriesScore}
        />)
      }

      renderUpdateFunctions = () =>{
        return (<UpdateFunctions
            ref="updateFunctions"
            userLanguage={this.props.userLanguage}
            inputLanguage = {this.props.inputLanguage}
            user={this.props.user}

        />)
      }

      

    //----------THIS COMPONENT RENDER----------

    runAgainTripsGenerator = async () =>{
      let searchArgs = this.state.selectedTripGeneratorParams
      let generatedSuggestedTrips = []
      await this.setState({showTemplateCreatorProgress:true,templateCreatorProgress:20})
      await this.refs.tripTemplateCreator.createTripTemplates(searchArgs).then(result => {
        generatedSuggestedTrips = result
        this.setState({generatedSuggestedTrips:generatedSuggestedTrips, showSuggestedTrips:true})
        this.setState({showTemplateCreatorProgress:false,templateCreatorProgress:20})
      });
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"TRIP_SUGGESTIONS_RUN_AGAIN",
        userScreen:this.state.selectedContentWall.toUpperCase(),
      })
    }

    createTripTemplates = async (args) =>{
      this.refs.writeDatabase.writeAcitivityLog({
        activityKey:"GENERATE_TRIPS_SUGGESTIONS"
      })
      //TEMP
     /*  let destination = {
        English: "North District",
        Hebrew: "צפון",
        tagType: "LocationFilter",
        treeID: "Israel_North District",
        type: "region"
      } */
     
      /*  await this.refs.loadDatabase.loadPlacesForDestination(destination).then(result => {
        localStorage.setItem('temporaryPlaces',JSON.stringify(result));
        this.refs.tripTemplateCreator.createTripTemplates({placesList:result})
      }); */
      let  searchArgs ={...args}
     
      //save the params if user would like to adjust them
      this.setState({generateTripsParams:args})
      searchArgs.userCategoriesScore=this.state.userCategoriesScore

      //localStorage.setItem('generateTripsParams',JSON.stringify(searchArgs)); 
      //let searchArgs = JSON.parse(localStorage.getItem('generateTripsParams')); 
      //console.log(searchArgs)
      this.setState({selectedTripGeneratorParams:searchArgs})
      let generatedSuggestedTrips = []
      await this.refs.tripTemplateCreator.createTripTemplates(searchArgs).then(result => {
        generatedSuggestedTrips = result
        this.setState({generatedSuggestedTrips:generatedSuggestedTrips, showSuggestedTrips:true,selectedContentWall:"Discover",selectedContentType:"Trips"})
      }); 
    }

    gatherPlacesForSelectedTripDestination = async destination =>{
      return new Promise(async (resolve, reject) => {
        let destinationPlacesResult = {}
        let entireCountryPlaces = []
        let loadEntireCountry = true
        let destinationCountry =  destination.treeID.split('_')[0]
        if (this.state.slimPlacesByCountry[destinationCountry]!==undefined){
          entireCountryPlaces = this.state.slimPlacesByCountry[destinationCountry]
          loadEntireCountry = false
        }
/*         let tag = {
          English: "North District",
          Hebrew: "צפון",
          tagType: "LocationFilter",
          treeID: "Israel_North District",
          type: "region"
        } */
          await this.refs.loadDatabase.loadPlacesForDestination(destination,loadEntireCountry).then(result => {
          destinationPlacesResult = result
          if (loadEntireCountry){
            let slimPlacesByCountry = this.state.slimPlacesByCountry
            let loadedCountryPlaces = []
            if (destination.type!=="country"){
              loadedCountryPlaces = [...destinationPlacesResult.entireCountryPlaces]
            }
            else{
              loadedCountryPlaces = [...destinationPlacesResult.placesList]
            }
            delete destinationPlacesResult.entireCountryPlaces
            slimPlacesByCountry[destinationCountry] = loadedCountryPlaces
            this.setState({slimPlacesByCountry:slimPlacesByCountry})
          }
          //localStorage.setItem('temporaryPlaces',JSON.stringify(result));
        });    
         //destinationPlacesResult=JSON.parse(localStorage.getItem('temporaryPlaces')); 
        resolve(destinationPlacesResult);
      });
    }

    renderViewCreateTripTemplate = () =>{
      if (this.state.showCreateTripTemplate){
        return(
          <CreateTripTemplate

            destinationsSearchTags = {this.state.placesLocationSearchTags} //consider changing to this.state.generateTripsDestinations
            gatherPlacesForSelectedTripDestination = {this.gatherPlacesForSelectedTripDestination}
            create = {this.createTripTemplates}
            logUserParams = {this.refs.writeDatabase.logUserTripGenerationParams}
            selectedTripGeneratorParams = {this.state.selectedTripGeneratorParams}
            discard={()=>{this.setState({showCreateTripTemplate:false,showNullBackdrop:false})}}
            loggedUser = {this.props.loggedUser}
            writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
            user={this.props.user}
            locationsTree={this.state.locationsTree}
            locationsTranslator = {this.state.locationsTranslator}
            categoriesTranslator = {this.state.categoriesTranslator}
            systemCategories = {this.state.systemCategories}
            systemText = {this.props.systemText}
            userLanguage={this.props.userLanguage}
            inputLanguage = {this.props.inputLanguage}
            detectInputLanguage = {this.props.detectInputLanguage}
            RTL = {this.props.RTL}
            slimPlacesByCountry = {this.state.slimPlacesByCountry}

            selectedAccommodation={this.state.existingPlaceFullData}

            countriesToExplore = {this.props.systemInitializeData.countriesToExplore}
            locationsTree = {this.state.locationsTree}
            recommendedDestinations = {this.state.recommendedDestinations}

            createQuickPlaceHandler = {this.createQuickPlaceHandler}
            discardQuicPlaceCreation={this.state.discardQuicPlaceCreation}
            viewPlaceHandler = {this.viewPlaceHandler}

          />
        )
      }
    }

    renderContent = () =>{
        return(
            <div /* className={classes.Wrapper} */>
              
              {/* <div onClick={()=>{this.refs.DBScripts.removeOldAnonymousUsers()}}>{"removeOldAnonymousUsers"}</div> */}
              {/* list and delete all anonymous users longer than month non active */}
              {/* <div onClick={()=>{this.refs.DBScripts.addToWineryActivityCategory()}}>{"addToWineryActivityCategory"}</div> */}
            {/*  <div onClick={()=>{this.refs.DBScripts.updatePlaceGoogleRating()}}>{"updatePlaceGoogleRating"}</div>  */} 
               {/* <div onClick={()=>{this.refs.DBScripts.createRecommendedDestinationsByCountry()}}>{"createRecommendedDestinationsByCountry"}</div>  */}
                 {/*  <div onClick={()=>{this.createTripTemplates()}}>{"create trip template"}</div>  */}
               {/* <div onClick={()=>{this.setState({showCreateTripTemplate:true,showNullBackdrop:true})}}>{"create trip template"}</div>   */}
               {/*  <div onClick={()=>{this.refs.DBScripts.updateSystemTopCateogies()}}>{"updateSystemTopCateogies"}</div> */}
            {/*  <div onClick={()=>{this.refs.DBScripts.createPlacesListForPlaceCreation()}}>{"createPlacesListForPlaceCreation"}</div>   */}
           {/*   <div onClick={()=>{this.refs.DBScripts.updateCategoriesTranslator()}}>{"updateCategoriesTranslator"}</div>  */} 
      {/*   <div onClick={()=>{this.refs.DBScripts.updateLocationsTranslator()}}>{"updateLocationsTranslator"}</div>  */}
              {/* <div onClick={()=>{this.refs.DBScripts.updateLocationsTree()}}>{"updateLocationsTree"}</div>  */} 
               {/*   <div onClick={()=>{this.refs.DBScripts.convertPlacesCharacteristics()}}>{"convertPlacesCharacteristics"}</div>  */}
                  {/*  <div onClick={()=>{this.refs.DBScripts.updateSlimPlaces()}}>{"updateSlimPlaces"}</div>       */}
                {/* <div onClick={()=>{this.refs.DBScripts.createNavigationLinks()}}>{"createNavigationLinks"}</div>  */}
                {/* <div onClick={()=>{this.refs.DBScripts.convertMuseumChars()}}>{"convertMuseumChars"}</div> */}
            {/*  <div onClick={()=>{this.refs.DBScripts.usersWelcomeMessage()}}>{"users welcome message"}</div> */}
              {/*   <div onClick={()=>{this.refs.DBScripts.convertPlaceTags()}}>{"convertPlaceTags"}</div>  */}
              {/*  <div onClick={()=>{this.refs.DBScripts.createUserSlimPlans()}}>{"createUserSlimPlans"}</div>  */}
             {/*  <div onClick={()=>{this.refs.DBScripts.firestoreTest()}}>{"firestoreTest"}</div> */}
             {/* <div onClick={()=>{this.refs.DBScripts.updateUsersFollowingIDsList()}}>{"updateUsersFollowingIDsList"}</div>  */}
               {/* <div onClick={()=>{this.refs.DBScripts.clearPlacesExperiencesData()}}>{"clearPlacesExperiencesData"}</div>  */}
           {/*  <div onClick={()=>{this.emailTest()}}>{"emailTest"}</div> */}
            {/*  <div onClick={()=>{this.refs.DBScripts.loadPlacesToFirestore()}}>{"loadPlacesToFirestore"}</div> */}
          {/*   <div onClick={()=>{this.refs.DBScripts.tempFindCulturalPark()}}>{"tempFindCulturalPark"}</div> */}
            
                {this.renderNavBar()}
                {this.renderMainBody()}
            </div>
        )
    }

  

    renderNavBar = () =>{
        if (!this.state.hideNavbar){
          let writeAcitivityLog = {}
           if (this.refs.writeDatabase!==undefined){
            writeAcitivityLog = this.refs.writeDatabase.writeAcitivityLog
           }
            return(
                <NavBar
                    ref="navBar"
                    loggedUser = {this.props.loggedUser}
                    welcomeUser={this.props.welcomeUser}
                    logoHandler = {this.logoHandler}
                    logout = {this.props.logout}
                    contactForm={()=>{this.contactFormHandler(null,true)}}
                    updateSystemLanguage={this.props.updateSystemLanguage}
                    inputLanguage = {this.props.inputLanguage}
                    detectInputLanguage = {this.props.detectInputLanguage}
                    loginHandler = {this.loginHandler}
                    user={this.props.user}
                    userInfo = {this.props.userInfo}
                    adminUser={this.props.isAdminUser}
                    userResidenceCountry = {this.props.userResidenceCountry}
    
                    RTL = {this.props.RTL}
                    userLanguage = {this.props.userLanguage}
                    systemText = {this.props.systemText}
                    systemInitializeData = {this.props.systemInitializeData}
                    selectedContentWall = {this.state.selectedContentWall}
                    systemCategories = {this.state.systemCategories}
                    allSystemCategories = {this.state.allSystemCategories}
                    categoriesTranslator = {this.state.categoriesTranslator}
                    writeAcitivityLog = {writeAcitivityLog}

                    /* hideDiscoverButton={this.state.discoverCountryPlaces.length===0} */

                    friendsRequests={this.state.myFriends.filter(user=>{return user.status==="New Request"})}

                    discoverHandler = {()=>{ 
                      this.discoverWallHandler()}}
                    favoritesHandler = {()=>{
                        this.favoriteWallHandler()}}
                    myContentHandler = {()=>{
                      this.myContentWallHandler()}}
                    adminHandler={this.adminHandler}
                    updateSettings={this.updateSettingsHandler}
                    createNewHandler={this.createNewHandler}
                    showFriends={this.friendsHandler}
                />
            )
        }
        
    }

    renderMainBody = () =>{
        switch(this.state.selectedContentWall){
            case "WelcomePage":{
              let writeAcitivityLog = {}
              if (this.refs.writeDatabase!==undefined){
                writeAcitivityLog = this.refs.writeDatabase.writeAcitivityLog
              }
                return(
                    <div className={classes.Body}>
                       <WelcomeWall
                            RTLClass = {this.props.RTL? "RTL" : ""}
                            RTL = {this.props.RTL}
                            loggedUser = {this.props.loggedUser}
                            user = {this.props.user}
                            userCountryIsInCountriesToExploreList = {this.props.userCountryIsInCountriesToExploreList}
                            userLanguage = {this.props.userLanguage}
                            userCountry = {this.props.userCountry}
                            systemText = {this.props.systemText}
                            inputLanguage = {this.props.inputLanguage}
                            detectInputLanguage = {this.props.detectInputLanguage}
                            writeAcitivityLog = {writeAcitivityLog}
                            loadingPreloadData = {this.state.loadingPreloadData}

                            categoriesTranslator = {this.state.categoriesTranslator}
                            locationsTranslator = {this.state.locationsTranslator}

                            followingUsersIDs = {this.state.followingUsersIDs}
                            myFriends = {this.state.myFriends}

                            createNewHandler={this.createNewHandler}
                            loginHandler = {this.props.loginHandler}

                            selectedContentType = {this.state.selectedContentType}
                            selectedContentWall = {this.state.selectedContentWall}
                            updateSelectedContentType = {this.updateSelectedContentType}

                            placesLocationSearchTags = {this.state.placesLocationSearchTags}
                            placesListForPlaceCreation = {this.state.placesListForPlaceCreation}
                            discoverTripsLocationSearchTags = {this.state.discoverTripsLocationSearchTags}
                            userCountryHandler = {this.userCountryHandler}
                            createTemplateTrips = {()=>{this.setState({showCreateTripTemplate:true});if(!isMobile){this.setState({showNullBackdrop:true})}}}
                            searchDiscoverLocationHandler = {this.searchDiscoverLocationHandler}
                            expandOnCategory = {this.expandOnCategoryHandler}
                            noSearchResultsHandler = {this.noSearchResultsHandler}

                            recommendedPlacesByCategory = {this.state.recommendedPlacesByCategory}
                            userRecommendedPlacesByCategory = {this.state.userRecommendedPlacesByCategory}
                            latestViewedPlaces={this.state.latestViewedPlaces}
                            userLastViewedPlacesIDs={this.state.userLastViewedPlacesIDs}
                            favoritePlacesIDs = {this.state.favoritePlacesIDs}
                            viewPlaceHandler = {this.viewPlaceHandler}
                            addPlaceToFavorite={this.addToFavoriteHandler}
                            removeFromFavoriteHandler={this.removeFromFavoriteHandler}
                            updateFavoriteTagsHandler={this.updateFavoriteTagsHandler}
                            addToPlan = {this.addToPlanHandler}

                            latestUserCreatedTripPlan = {this.state.latestUserCreatedTripPlan}
                            viewPlanHandler = {(plan)=>{this.viewPlanHandler(plan,true)}}
                            userPlansHandler= {()=>{
                              this.setState({
                                  selectedContentWall: "MyContent",
                                  selectedContentType:"Trips",
                              }); 
                              this.myContentWallHandler()}}
                            shareExperience = {this.createNewSelectionHandler}

                            userExperiencesFeed = {this.state.userExperiencesFeed}
                            loadedMoreExperiencesToFeed = {this.state.loadedMoreExperiencesToFeed}
                            loadMoreExperiencesFeedHandler = {this.discoverExperiencesHandler}
                            viewExperiencePlace={this.viewExperiencePlaceHandler}
                            viewExperiencePlan = {this.viewExperiencePlanHandler}
                            editExperienceHandler = {this.editExperienceHandler}
                            removeTaggingHandler = {this.removeTaggingHandler}
                            deleteExperienceHandler = {this.deleteExperienceHandler}
                            removeExperienceFromPlanHandler = {this.removeExperienceFromPlanHandler}

                            viewUserProfileHandler = {this.viewUserProfileHandler}
                            viewImagesFullscreen = {this.viewImagesFullscreenHandler}
                        /> 
                    </div>
                )
                break;
            }
            case "Discover":{
                return(
                    <div className={classes.Body}>
                        <Discover
                            ref="discover"
                            selectedContentType = {this.state.selectedContentType}
                            selectedContentWall = {this.state.selectedContentWall}
                            userLanguage = {this.props.userLanguage}
                            inputLanguage = {this.props.inputLanguage}
                            systemText = {this.props.systemText}
                            RTL = {this.props.RTL}
                            loggedUser={this.props.loggedUser}
                            detectInputLanguage = {this.props.detectInputLanguage}
                            user = {this.props.user}
                            adminUser={this.props.isAdminUser}
                            loginHandler = {this.props.loginHandler}
                            writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}

                            categoriesTranslator = {this.state.categoriesTranslator}
                            locationsTranslator = {this.state.locationsTranslator}
                            placesCharacteristicsData={this.state.placesCharacteristicsData}
                            placesLocationSearchTags = {this.state.placesLocationSearchTags}

                            followingUsersIDs = {this.state.followingUsersIDs}
                            myFriends = {this.state.myFriends}

                            places = {this.state.filteredDiscoverPlacesWithChars}
                            favoritePlacesIDs = {this.state.favoritePlacesIDs}
                            discoverCountryPlaces = {this.state.discoverCountryPlaces}

                            plans = {this.state.filteredDiscoverPublicTripPlans} 
                            favoriteTripsIDs = {this.state.favoriteTripsIDs}

                            discoverPlacesDaysFilter = {this.state.discoverPlacesDaysFilter}
                            discoverPlacesCharacteristicsFilter = {JSON.parse(JSON.stringify(this.state.discoverPlacesCharacteristicsFilter))}
                            discoverPlacesLocationFilterTags = {this.state.discoverPlacesLocationFilterTags}
                            discoverPlacesLocationsSearchTags = {this.state.discoverPlacesLocationsSearchTags}
                           /*  discoverPlacesNameSearchTags = {this.state.discoverPlacesNameSearchTags} */
                            discoverPlacesNameSearchTags = {this.state.filteredDiscoverPlaces.map(place=>{return place.placeName})}
                            discoverPlacesAdditionalTagsSearchTags = {this.state.discoverPlacesAdditionalTagsSearchTags}
                            suggestedLocationsFilters = {this.state.suggestedLocationsFilters}
                            discoverPlacesAvailableCategories = {this.state.discoverPlacesAvailableCategories}
                            discoverPlacesAvailableMainCategories = {this.state.discoverPlacesAvailableMainCategories}
                            discoverPlacesSelectedMainCategory = {this.state.discoverPlacesSelectedMainCategory}
                            discoverPlacesCategoriesFilterTags = {this.state.discoverPlacesCategoriesFilterTags}
                            discoverPlacesNameFilterTag = {[...this.state.discoverPlacesNameFilterTag]}
                            discoverPlacesAdditionalTagFilterTag = {[...this.state.discoverPlacesAdditionalTagFilterTag]}

                            updateSelectedContentType = {this.updateSelectedContentType}
                            searchDiscoverLocationHandler = {this.searchDiscoverLocationHandler}
                            noSearchResultsHandler = {this.noSearchResultsHandler}

                            mapBoundariesPlacesHandler = {this.mapBoundariesPlacesHandler}

                            removePlaceLocationFilterTag = {this.removeDiscoverPlacesLocationSearchTagHandler}
                            addPlaceLocationTagHandler = {(tag)=>{
                              this.discoverPlacesLocationSearchTagHandler(tag)
                              this.refs.writeDatabase.writeAcitivityLog({
                                activityKey:"DISCOVER_PLACES_ADD_LOCATION_FILTER",
                                userScreen:this.state.selectedContentWall.toUpperCase()
                              })
                            }}
                            discoverPlacesMainCategoryHandler = {(index)=>{
                              this.discoverPlacesMainCategoryHandler(index)
                              this.refs.writeDatabase.writeAcitivityLog({
                                activityKey:"DISCOVER_PLACES_MAINCATEGORY_FILTER",
                                userScreen:this.state.selectedContentWall.toUpperCase()
                              })
                            }}
                            addPlaceCategoryTagHandler = {this.addPlaceCategoryTagHandler}
                            removePlaceCategoryFilterTag = {(index)=>{
                              this.discoverPlacesCategorySearchTagHandler(index,"remove")
                              this.refs.writeDatabase.writeAcitivityLog({
                                activityKey:"DISCOVER_PLACES_REMOVE_CATEGORY_FILTER",
                                userScreen:this.state.selectedContentWall.toUpperCase()
                              })
                            }}
                            removePlaceNameFilterTag = {(index)=>{
                              this.discoverPlaceNameSearchRemoveHandler(index)
                              this.refs.writeDatabase.writeAcitivityLog({
                                activityKey:"DISCOVER_PLACES_REMOVE_NAME_FILTER",
                                userScreen:this.state.selectedContentWall.toUpperCase()
                              })
                            }}
                            removePlaceAdditionalTagFilterTag = {(index)=>{
                              this.discoverPlaceAddiotnalTagSearchRemoveHandler(index)
                              this.refs.writeDatabase.writeAcitivityLog({
                                activityKey:"DISCOVER_PLACES_REMOVE_ADDITIONALTAG_FILTER",
                                userScreen:this.state.selectedContentWall.toUpperCase()
                              })
                            }}
                            discoverPlacesHideMapHandler = {this.discoverPlacesHideMapHandler}
                            
                            updatePlaceCharacteristicsFilters = {this.updatePlaceCharacteristicsFilters}
                            updateDiscoverPlacesSelectedDay = {(index,value)=>{let discoverPlacesDaysFilter= this.state.discoverPlacesDaysFilter
                                discoverPlacesDaysFilter[index]=value; this.setState({discoverPlacesDaysFilter:discoverPlacesDaysFilter})
                                this.discoverPlacesCharacteristicsSearchHandler()
                                let activityKey = "DISCOVER_PLACES_DAY_SELECT"
                                let dayIndex = index
                                if (value===0){
                                  activityKey = "DISCOVER_PLACES_DAY_UNSELECT"
                                }
                                this.refs.writeDatabase.writeAcitivityLog({
                                  activityKey:activityKey,
                                  userScreen:this.state.selectedContentWall.toUpperCase(),
                                  dayIndex:dayIndex
                                })
                              }}

                            hideNavbar = {(value)=>{this.setState({hideNavbar:value})}}


                            //place tools handling functions
                            viewPlaceHandler = {this.viewPlaceHandler}
                            addToFavoriteHandler={this.addToFavoriteHandler}
                            removeFromFavoriteHandler={this.removeFromFavoriteHandler}
                            updateFavoriteTagsHandler={this.updateFavoriteTagsHandler}
                            addToPlanHandler = {this.addToPlanHandler}
                            createNew = {this.createNewSelectionHandler}

                            userExperiencesFeed = {this.state.userExperiencesFeed}
                            loadedMoreExperiencesToFeed = {this.state.loadedMoreExperiencesToFeed}
                            loadMoreExperiencesFeedHandler = {this.loadMoreExperiencesFeedHandler}
                            viewExperiencePlace={this.viewExperiencePlaceHandler}
                            viewExperiencePlan = {this.viewExperiencePlanHandler}
                            editExperienceHandler = {this.editExperienceHandler}
                            removeTaggingHandler = {this.removeTaggingHandler}
                            deleteExperienceHandler = {this.deleteExperienceHandler}
                            removeExperienceFromPlanHandler = {this.removeExperienceFromPlanHandler}
                            viewImagesFullscreen = {this.viewImagesFullscreenHandler}

                            viewUserProfileHandler = {this.viewUserProfileHandler}
                            viewPlanHandler = {this.viewPlanHandler}
                            clonePlanHandler = {this.clonePlanHandler}
                            reportDislikedSuggestedTrip = {this.reportDislikedSuggestedTrip}

                            countriesToExplore = {this.props.systemInitializeData.countriesToExplore}
                            locationsTree = {this.state.locationsTree}

                            discoverTripsCountriesFilters = {this.state.discoverTripsCountriesFilters}
                            discoverTripsLocationFilters={this.state.discoverTripsLocationFilters}
                            discoverTripsPlanAdditionalTagsFilters = {this.state.discoverTripsPlanAdditionalTagsFilters}
                            discoverTripsCategoriesFilters = {this.state.discoverTripsCategoriesFilters}
                            discoverTripsStyleFocus = {this.state.discoverTripsStyleFocus}
                            discoverPlansDurationLimits = {this.state.discoverPlansDurationLimits}
                            discoverPlansDurationFilter = {this.state.discoverPlansDurationFilter}
                            discoverPlansParticipants = {this.state.discoverPlansParticipants}
                            discoverTripsLocationSearchTags = {this.state.discoverTripsLocationSearchTags}
                            discoverTripsAdditionalSearchTags = {this.state.discoverTripsAdditionalSearchTags}
                            discoverTripsCombineLocationsHandler = {this.discoverTripsCombineLocationsHandler}
                            discoverTripsCombineLocations = {this.state.discoverTripsCombineLocations}

                            addDiscoverTripsCountry = {this.addDiscoverTripsCountry}
                           addDiscoverTripsLocationFilterTag = {tag=>{this.addDiscoverTripsLocationsFilters(tag);
                            this.refs.writeDatabase.writeAcitivityLog({
                              activityKey:"DISCOVER_TRIPS_ADD_LOCATION_FILTER",
                              userScreen:this.state.selectedContentWall.toUpperCase(),
                              searchValue:tag.treeID
                            })}}
                            removeDiscoverTripsCountry = {this.removeDiscoverTripsCountry}
                            removeDiscoverTripsLocationsFilters = {this.removeDiscoverTripsLocationsFilters}
                            removeDiscoverTripsAdditionalTag = {this.removeDiscoverTripsAdditionalTag}
                            updateDiscoverTripsCategoriesFilters = {this.updateDiscoverTripsCategoriesFilters}
                            updateDiscoverTripsStyleFocus = {this.updateDiscoverTripsStyleFocus}
                            discoverPlansDurationHandler={this.discoverPlansDurationHandler}
                            discoverPlansParticipantsHandler = {this.discoverPlansParticipantsHandler}

                            loadMoreTrips = {this.loadMoreDiscoverTripPlans}
                            
                            showSuggestedTrips = {this.state.showSuggestedTrips}
                            generatedSuggestedTrips = {this.state.generatedSuggestedTrips}
                            viewSuggestedPlanHandler = {this.viewSuggestedPlanHandler}
                            showSuggestedTripsHandler = {value=>{this.setState({showSuggestedTrips:value})}}
                            createTemplateTrips = {()=>{this.setState({showCreateTripTemplate:true});if(!isMobile){this.setState({showNullBackdrop:true})}}}
                            runAgainTripsGenerator = {this.runAgainTripsGenerator}
                            
                        />
                    </div>
                )
            }
            case "Favorite":{
                return(
                    <div className={classes.Body}>
                        <Favorite
                            ref="favorite"
                            selectedContentType = {this.state.selectedContentType}
                            selectedContentWall = {this.state.selectedContentWall}
                            userLanguage = {this.props.userLanguage}
                            inputLanguage = {this.props.inputLanguage}
                            systemText = {this.props.systemText}
                            RTL = {this.props.RTL}
                            loggedUser={true}
                            detectInputLanguage = {this.props.detectInputLanguage}
                            user = {this.props.user}
                            adminUser={this.props.isAdminUser}
                            writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}

                            places = {this.state.filteredFavoritePlaces}
                            plans = {this.state.filteredFavoriteTripPlans}

                            categoriesTranslator = {this.state.categoriesTranslator}
                            locationsTranslator = {this.state.locationsTranslator}


                            favoritePlacesFilterTags = {this.state.favoritePlacesFilterTags}
                            favoritePlacesSearchTags = {this.state.favoritePlacesSearchTags}

                            favoriteTripPlansSearchTags = {this.state.favoriteTripPlansSearchTags}
                            favoriteTripPlansFilterTags = {this.state.favoriteTripPlansFilterTags}

                            updateSelectedContentType = {this.updateSelectedContentType}

                            favoriteSearchHandler = {this.favoriteSearchHandler}
                            favoriteRemoveTagHandler = {this.favoriteRemoveTagHandler}
                            hideNavbar = {(value)=>{this.setState({hideNavbar:value})}}

                            noResultsFavoritePlaces = {this.state.noResultsFavoritePlaces}
                            noResultsFavoriteTrips = {this.state.noResultsFavoriteTrips}

                            //place tools handling functions
                            viewPlaceHandler = {this.viewPlaceHandler}
                            removeFromFavoriteHandler={this.removeFromFavoriteHandler}
                            updateFavoriteTagsHandler={this.updateFavoriteTagsHandler}    
                            addToPlanHandler = {this.addToPlanHandler}    
                            shareExperience = {this.createNewSelectionHandler}     
                            
                            viewPlanHandler = {this.viewPlanHandler}
                            clonePlanHandler = {this.clonePlanHandler}
                        />
                    </div>
                )
            }
            case "MyContent":{
              return(
                  <div className={classes.Body}>
                      <MyContent
                          ref="myContent"
                          selectedContentType = {this.state.selectedContentType}
                          selectedContentWall = {this.state.selectedContentWall}
                          userLanguage = {this.props.userLanguage}
                          inputLanguage = {this.props.inputLanguage}
                          systemText = {this.props.systemText}
                          RTL = {this.props.RTL}
                          loggedUser={true}
                          detectInputLanguage = {this.props.detectInputLanguage}
                          user = {this.props.user}
                          writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}

                          places = {this.state.filteredUserCreatedPlaces}
                          favoritePlacesIDs = {this.state.favoritePlacesIDs}

                          plans = {this.state.filteredUserCreatedTripPlans}

                          experiences = {this.state.filteredUserExperiences}
                          createNew = {this.createNewSelectionHandler}

                          categoriesTranslator = {this.state.categoriesTranslator}
                          locationsTranslator = {this.state.locationsTranslator}


                          userCreatedPlacesFilterTags = {this.state.userCreatedPlacesFilterTags}
                          userCreatedPlacesSearchTags = {this.state.userCreatedPlacesSearchTags}

                          userExperiencesSearchTags = {this.state.userExperiencesSearchTags}
                          userExperiencesFilterTags = {this.state.userExperiencesFilterTags}

                          userCreatedTripPlansSearchTags = {this.state.userCreatedTripPlansSearchTags}
                          userCreatedTripPlansFilterTags = {this.state.userCreatedTripPlansFilterTags}

                          updateSelectedContentType = {this.updateSelectedContentType}

                          userContentSearchHandler = {this.userContentSearchHandler}
                          userContentRemoveTagHandler = {this.userContentRemoveTagHandler}
                          hideNavbar = {(value)=>{this.setState({hideNavbar:value})}}

                          clonePlanHandler = {this.clonePlanHandler}
                          //place tools handling functions
                          viewPlaceHandler = {this.viewPlaceHandler}
                          removeFromFavoriteHandler={this.removeFromFavoriteHandler}
                          updateFavoriteTagsHandler={this.updateFavoriteTagsHandler}   
                          deletePlaceHandler = {this.deletePlaceHandler}   
                          addToPlanHandler = {this.addToPlanHandler}  
                          
                          //trip plans
                          viewPlanHandler = {this.viewPlanHandler}
                          updatePlanEditors={this.updatePlanEditors}
                          updateList = {this.writeDatabaseCallBackHandler}
                          deletePlanHandler = {this.deletePlanHandler}
                          shareExperience = {this.createNewSelectionHandler}

                          editExperienceHandler={this.editExperienceHandler}
                          removeTaggingHandler = {this.removeTaggingHandler}
                          viewExperiencePlace={this.viewExperiencePlaceHandler}
                          viewExperiencePlan = {this.viewExperiencePlanHandler}
                          deleteExperienceHandler = {this.deleteExperienceHandler}
                          loadedMoreExperiencesToFeed = {this.state.loadedMoreUserExperiences}
                          loadMoreExperiencesFeedHandler = {this.loadMoreUserExperiences}
                          removeExperienceFromPlanHandler = {this.removeExperienceFromPlanHandler}

                          viewImagesFullscreen = {this.viewImagesFullscreenHandler}
                      />
                  </div>
              )
          }
            case "AdminWall":{
                return(
                    <div className={classes.Body}>
                        <AdminBoard
                        selectedContentWall = {this.state.selectedContentWall}
                        viewPlaceHandler={this.viewPlaceHandler}
                        viewPlaceEditSuggestion={this.viewPlaceEditSuggestion}
                        deletePlaceHandler = {this.deletePlaceHandler}
                        user={this.props.user}
                        places={this.state.potentialPlacesToApprove}
                        plans = {this.state.potentialTripPlans}
                        viewPlanHandler = {this.viewPlanHandler}

                        categoriesTranslator = {this.state.categoriesTranslator}
                        locationsTranslator = {this.state.locationsTranslator}

                        /* places={this.state.systemPlaces.filter(place=>place.placeStatus==="potential")} */
                        editSuggestions={this.state.placesEditSuggestions}
                        usersActivity={this.state.usersActivity}
                        activitiesLog = {this.state.activitiesLog}
                        activitiesLogData = {this.state.activitiesLogData}
                        usersRequestsData={this.state.usersRequestsData}
                        downloadActivityLogHandler = {this.refs.writeDatabase.downloadActivityLogHandler}
                        downloadRequestsHandler = {this.refs.writeDatabase.downloadRequestsHandler}
                        usersRequests = {this.state.usersRequests}
                        usersActivityLastUpdate={this.state.usersActivityLastUpdate}
                        systemText={this.props.systemText}
                        userLanguage={this.props.userLanguage}
                        RTL = {this.props.RTL}
                        />
                    </div>
                  )
            }
            default: return null;
        }
    }

    //render open modals (view place, view plan, etc...)
    renderViewImagesFullscreen = () =>{
      if (this.state.viewImagesFullscreen){
        return (
          <ImageFullscreenViewer
          imageIndex={this.state.imagesFullScreenIndex}
          imagesUrls={this.state.imagesFullScreenList}
          discard={()=>{this.setState({viewImagesFullscreen:false})
            this.refs.writeDatabase.writeAcitivityLog({
                activityKey:"IMAGES_FULLSCREEN_EXIT",
                userScreen:this.state.selectedContentWall.toUpperCase()
            })
          }}

          RTL={!this.props.RTL}
          />
      )
      }
    }

    renderViewPlace= () =>{
        if (this.state.viewPlace){
            let editMode = this.state.editPlace;
            let selectedPlace=this.state.selectedPlace;
            let placeID=selectedPlace.placeID;
            let placeLastModified=selectedPlace.placeLastModified;
            let placeCreationDate=selectedPlace.placeCreationDate;
            let placeName=selectedPlace.placeName;
    
            let placeDescription=selectedPlace.placeDescription;
            let placeTags=[];
            if (selectedPlace.placeTags!==undefined){
              placeTags=selectedPlace.placeTags;
            }
            else{
              selectedPlace.placeTags=[];
            }
            let placeLinks =[];
            if (selectedPlace.placeLinks!==undefined){
              placeLinks=selectedPlace.placeLinks;
            }
            else{
              selectedPlace.placeLinks=[];
            }
            let imagePreview =[];
            if (selectedPlace.imagePreview!==undefined){
                imagePreview=selectedPlace.imagePreview;
            }
            else{
              selectedPlace.imagePreview=[];
            }
            let imageGallery =[];
            if (selectedPlace.imageGallery!==undefined){
                imageGallery=selectedPlace.imageGallery;
            }
            else{
              selectedPlace.imageGallery=[];
            }
            let placeLocation=selectedPlace.placeLocation;
            let placeExperiences =[];
            if (this.state.placeExperiences!==undefined){
              placeExperiences=this.state.placeExperiences;
            }
            let placeFriendsExperiences=this.state.placeFriendsExperiences;
            let placeMyExperiences = this.state.placeMyExperiences;
            let placeStatus= selectedPlace.placeStatus;
            let hoursRows =[...selectedPlace.hoursRows];
            let contributors= selectedPlace.contributors;
            let placeStats= selectedPlace.placeStats;
            let placeMainCategory = [...selectedPlace.placeMainCategory]
            let placeCategory= []
            if (selectedPlace.placeCategory!==undefined){
              placeCategory=selectedPlace.placeCategory;
            }
            else{
              selectedPlace.placeCategory=[];
            }
            let placeNewCategories =[];
            if (selectedPlace.placeNewCategories!==undefined){
              placeNewCategories=selectedPlace.placeNewCategories;
            }
            else{
              selectedPlace.placeNewCategories=[];
            }
            let inFavorite = false;
            if (this.state.favoritePlacesIDs.includes(selectedPlace.placeID)){
              inFavorite=true;
            }
            selectedPlace.inFavorite=inFavorite;
            let placeHighlights = {};
            if (selectedPlace.placeHighlights!==undefined){
              placeHighlights=Object.assign({}, selectedPlace.placeHighlights);
            }
            else{
              selectedPlace.placeHighlights=placeHighlights;
            }
            let placeLanguages=[];
            if (selectedPlace.placeLanguages!==undefined){
              placeLanguages=[...selectedPlace.placeLanguages];
            }
            else{
              selectedPlace.placeLanguages=[];
            }
            //get language
            let placeLanguage;
            //get the relevant language or at least any language name
            if (selectedPlace.placeLanguages.includes(this.props.userLanguage)){
              placeLanguage=this.props.userLanguage
            }
            else if(selectedPlace.placeLanguages.includes("English")){
              placeLanguage="English";
            }
            else{
              placeLanguage=selectedPlace.placeLanguages[0];
            }
            let placeCharacteristics={};
            if (selectedPlace.placeCharacteristics!==undefined){
              placeCharacteristics=selectedPlace.placeCharacteristics;
            }
            else{
              selectedPlace.placeCharacteristics={};
            }
            let contactInfo={}
            if (selectedPlace.contactInfo!==undefined){
              contactInfo=selectedPlace.contactInfo;
            }
            else{
              selectedPlace.contactInfo={};
            }
            let navigation={}
            if (selectedPlace.navigation!==undefined){
              navigation=selectedPlace.navigation;
            }
            else{
              selectedPlace.navigation={};
            }
            let personalNotes=[];
            if (selectedPlace.personalNotes!==undefined){
              personalNotes=[...selectedPlace.personalNotes];
            }
            else{
              selectedPlace.personalNotes=[];
            }
    
            let placeTemporaryClosed = false;
            if (selectedPlace.placeTemporaryClosed!==undefined){
              placeTemporaryClosed=selectedPlace.placeTemporaryClosed
            }
            else{
              selectedPlace.placeTemporaryClosed=false;
            }
            let temporaryClosedNote = ""
            if (selectedPlace.temporaryClosedNote!==undefined){
              temporaryClosedNote=selectedPlace.temporaryClosedNote
            }
            else{
              selectedPlace.temporaryClosedNote=""
            }
    
            let placePermanentlyClosed = false;
            if (selectedPlace.placePermanentlyClosed!==undefined){
              placePermanentlyClosed=selectedPlace.placePermanentlyClosed
            }
            else{
              selectedPlace.placePermanentlyClosed=false;
            }
            let permanentlyClosedDate;
            if (selectedPlace.permanentlyClosedDate!==undefined){
              permanentlyClosedDate=selectedPlace.permanentlyClosedDate
            }

            let averageVisitDuration;
            if (selectedPlace.averageVisitDuration!==undefined){
              averageVisitDuration = selectedPlace.averageVisitDuration
            }
            let placeGoogleRating
            if (selectedPlace.placeGoogleRating!==undefined){
              placeGoogleRating = selectedPlace.placeGoogleRating
            }
           // console.log(placeID)
        /*     
            console.log(selectedPlace) */
            if (this.state.viewPlace){
            return(
            
              <ViewPlace
              adminMode = {this.state.selectedContentWall==="AdminWall"}
              adminUser = {this.props.isAdminUser}
              loggedUser={this.props.loggedUser}
              loginHandler = {this.props.loginHandler}
              RTL = {this.props.RTL}
              inputLanguage = {this.props.inputLanguage}
              detectInputLanguage = {this.props.detectInputLanguage}
              editMode={editMode}
              placeID={placeID}
              placeLastModified={placeLastModified}
              placeCreationDate={placeCreationDate}
              placeName={placeName}
              placeDescription={placeDescription}
              placeTags={placeTags}
              placeLinks={placeLinks}
              imagePreview={imagePreview}
              imageGallery={imageGallery}
              placeLocation={placeLocation}
              placeExperiences={placeExperiences}
              placeFriendsExperiences = {placeFriendsExperiences}
              placeMyExperiences = {placeMyExperiences}
              placeStatus = {placeStatus}
              contributors= {contributors}
              placeStats = {placeStats}
              placeMainCategory={placeMainCategory}
              placeCategory = {placeCategory}
              placeNewCategories={placeNewCategories}
              inFavorite= {inFavorite}
              placeHighlights = {placeHighlights}
              personalNotes={personalNotes}
              placeLanguage={placeLanguage}
              placeLanguages={placeLanguages}
              placeTemporaryClosed={placeTemporaryClosed}
              temporaryClosedNote={temporaryClosedNote}
              placePermanentlyClosed={placePermanentlyClosed}
              permanentlyClosedDate={permanentlyClosedDate}
              placeCharacteristics={placeCharacteristics}
              hoursRows={hoursRows}
              contactInfo={contactInfo}
              navigation = {navigation}
              placeGoogleRating = {placeGoogleRating}
              selectedPlace={selectedPlace}
              highlightsTopicsList={[]}
              owner = {selectedPlace.owner}
              topTouristSite = {this.state.selectedPlace.topTouristSite}
              placeEditSuggestion = {this.state.placeEditSuggestion}
              viewImagesFullscreen = {this.viewImagesFullscreenHandler}
    
              discard={()=>{ this.setState({viewPlace: false, placeExperiences:[],placeFriendsExperiences:undefined,placeMyExperiences:undefined,placeEditSuggestion:false})}}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
              systemLanguages = {this.props.systemInitializeData.configurations.systemLanguages}
              adminMode={this.state.selectedContentWall==="AdminWall"}
              adminUser={this.props.isAdminUser}
              selectedContentWall={this.state.selectedContentWall}
              myFriends={this.state.myFriends}
              followingUsersIDs = {this.state.followingUsersIDs}
    
              slimPlacesByCountry={this.state.slimPlacesByCountry}
              systemCategories = {this.state.systemCategories}
              locationsTree={this.state.locationsTree}
              user={this.props.user} 
              placesCharacteristicsData={this.state.placesCharacteristicsData}
              categoriesTranslator = {this.state.categoriesTranslator}
              locationsTranslator = {this.state.locationsTranslator}

              update={this.updatePlaceHandler}
              submitPlaceSuggestion={this.submitPlaceChanges}

              addPlaceToPlan = {(place)=>{this.addToPlanHandler(place,"Place")}}
              
              showNearByHandler = {this.showNearByHandler}
    
              addPlaceToFavorite={this.addToFavoriteHandler}
              removePlaceFromFavorite={this.removeFromFavoriteHandler}
              updateFavoriteTags={this.updateFavoriteTagsHandler}
    
              addHighlightHandler={this.addPlaceHighlightHandler}
              updatePlaceHighlight={this.updatePlaceHighlightHandler}
              deletePlaceHighlight={this.deletePlaceHighlightHandler}
    
              addNoteHandler={this.addPlaceNoteHandler}
              updateNoteHandler={this.updatePlaceNoteHandler}
              deleteNoteHandler={this.deletePlaceNoteHandler}
              
              getExistingPlaceData={this.getExistingPlaceData}
              getPlacesListForPlaceCreationValidation = {this.getPlacesListForPlaceCreationValidation}
              existingPlace={this.state.existingPlaceFullData}

              loadFriendsExperiencesForPlace = {this.loadFriendsExperiencesForPlace}
              loadMoreExperiencesFeedHandler = {this.loadMoreExperiencesFeedForPlace}
              loadMyExperiencesForPlace = {this.loadMyExperiencesForPlace}
              loadedMoreExperiencesToFeed= {this.state.loadedMoreExperiencesToPlaceFeed}
            
              createExperience={()=>{this.createNewSelectionHandler("Experience",this.state.selectedPlace)}}
              approvePlace={()=>{this.refs.writeDatabase.approvePlace(this.state.selectedPlace)}}
              popularTouristSiteHandler = {this.popularTouristSiteHandler}

              contactFormHandler = {this.contactFormHandler}

              viewUserProfileHandler = {this.viewUserProfileHandler}

              writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
              />
         
            )
          }
        }
    }

    renderCreatePlace = () =>{
        if (this.state.createPlace){
            return(
                 <CreatePlace 
                    editMode = "Create"
                    slimPlacesByCountry={this.state.placesListForPlaceCreation}
                    systemCategories = {this.state.systemCategories}
                    placesCharacteristicsData = {this.state.placesCharacteristicsData}
                    locationsTree={this.state.locationsTree}
                    user={this.props.user} 
                    discard={this.discardCreatePlace} 
                    create={this.newPlaceCreationHandler}
                    viewPlaceHandler = {this.viewPlaceHandler}
                    getPlacesListForPlaceCreationValidation = {this.getPlacesListForPlaceCreationValidation}
                    getExistingPlaceData={this.getExistingPlaceData}
                    existingPlace={this.state.existingPlaceFullData}
                    quickCreation={this.state.quickPlaceCreation}
                    updateQuickCreationPlaceSelection = {this.updateQuickCreationPlaceSelection}
                    quickPlaceWasCreated = {()=>{this.setState({quickPlaceWasCreated:true})}}
                    quickPlaceCreationMainCategory = {this.state.quickPlaceCreationMainCategory}
                    quickPlaceCreationCategories = {this.state.quickPlaceCreationCategories}

                    writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
                    userLanguage={this.props.userLanguage}
                    initializeLanguage = {this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}
                    detectInputLanguage = {this.props.detectInputLanguage}
                    inputLanguage = {this.props.inputLanguage}
                    userCountry = {this.props.userCountry}
                    systemLanguages={this.props.systemInitializeData.configurations.systemLanguages}
                 /> 
            )
        }
    }

    renderViewPlan = () =>{
      if (this.state.viewPlan){
        let selectedPlan=this.state.selectedPlan;
        let planID = selectedPlan.planID;
        let planLastModified=selectedPlan.planLastModified;
        let planCreationDate=selectedPlan.planCreationDate;
        let planName=selectedPlan.planName;
        let planDates = selectedPlan.planDates;
        let planDays = [...selectedPlan.planDays];
        let owner = selectedPlan.owner;
        let planEditors = [...selectedPlan.planEditors];
        let imagePreview =[];
        if (selectedPlan.imagePreview!==undefined){
            imagePreview=selectedPlan.imagePreview;
        }
        else{
          selectedPlan.imagePreview=[];
        }
        let imageGallery =[];
        if (selectedPlan.imageGallery!==undefined){
            imageGallery=selectedPlan.imageGallery;
        }
        else{
          selectedPlan.imageGallery=[];
        }
        let planPlacesIDs =[];
        if (selectedPlan.planPlacesIDs!==undefined){
          planPlacesIDs=selectedPlan.planPlacesIDs;
        }
        else{
          selectedPlan.planPlacesIDs=[];
        }
        let isEditor = false;
        const index = planEditors.findIndex(user => {
          return user.userID === this.props.user.userID;
        });
        let discoverPlacesLocationFilterTags = [];
        if (index!==-1){
          if (selectedPlan.planEditors[index].discoverPlacesLocationFilterTags!==undefined){
            discoverPlacesLocationFilterTags=selectedPlan.planEditors[index].discoverPlacesLocationFilterTags;
          }
          else{
            selectedPlan.planEditors[index].discoverPlacesLocationFilterTags=[];
          }
          isEditor = true;
        }
       
       
        let participants={adults:0,kids:0,toddler:0};
        if (selectedPlan.participants!==undefined){
          participants=selectedPlan.participants;
        }
        else{
          selectedPlan.participants=participants;
        }
        let travelWithKids = false
        if (selectedPlan.travelWithKids!==undefined){
          travelWithKids=selectedPlan.travelWithKids;
        }
        else if (participants.kids!==0||participants.toddler!==0){
          travelWithKids = true
        }
        selectedPlan.travelWithKids=travelWithKids;
        let isPublic = selectedPlan.isPublic;
        let planTasks = [];
        if (selectedPlan.planTasks!==undefined){
          planTasks=selectedPlan.planTasks;
        }
        else{
          selectedPlan.planTasks=[];
        }
        let inFavorite = false;
        if (this.state.favoritePlansIDs.includes(planID)){
          inFavorite=true;
        }
        let planDuration=0;
        if (selectedPlan.planDuration!==undefined){
          planDuration=selectedPlan.planDuration;
        }
        else{
          selectedPlan.planDuration=0;
        }
        let noDatesSelected = false;
        if (selectedPlan.noDatesSelected!==undefined){
          noDatesSelected=selectedPlan.noDatesSelected
        }
        let planExperiences = [];
        let planExperiencesIDs = [];
        if (this.state.selectedPlanExperiences!==undefined){
          for (let i = 0 ;i<this.state.selectedPlanExperiences.length ; i++){
            if (!planExperiencesIDs.includes(this.state.selectedPlanExperiences[i].experienceID)){
              planExperiences.push(this.state.selectedPlanExperiences[i])
              planExperiencesIDs.push(this.state.selectedPlanExperiences[i].experienceID)
            }
          }
        }

        let planPlacesList = this.state.viewedPlanPlaces; 

        let planSecurity = "private"
        if (selectedPlan.planSecurity!==undefined){
          planSecurity=selectedPlan.planSecurity;
        }
        let albumUser = this.props.user.userID
        if (!isEditor&&this.state.viewUserJourneyID!==undefined){
          albumUser = this.state.viewUserJourneyID
        }
        let albumSecurity ={}
        if (selectedPlan.albumSecurity!==undefined){
          albumSecurity=selectedPlan.albumSecurity;
        }
        if (albumSecurity[albumUser]===undefined){
          albumSecurity[albumUser]="private"
        }
        let showAlbum = false;
        if (isEditor){
          showAlbum = true
        }
        else if( albumSecurity[albumUser]==="public"){
          showAlbum = true
        }
        else if ( albumSecurity[albumUser]==="friends"){
          if (this.state.myFriends.includes(albumUser)){
            showAlbum = true
          }
        }
        let planDescription = selectedPlan.planDescription;
        let publicPlanTags = []
        if (selectedPlan.publicPlanTags!==undefined){
          publicPlanTags = selectedPlan.publicPlanTags
        }

        let dailyAnchors = []
        if (selectedPlan.dailyAnchors!==undefined){
          dailyAnchors = selectedPlan.dailyAnchors
        }
        //console.log(planPlacesList)

        return(
          <ViewTripPlan
          adminMode = {this.state.selectedContentWall==="AdminWall"}
          ref="viewPlan"
          RTL = {this.props.RTL}
          loggedUser={this.props.loggedUser}
          isEditor = {isEditor}
          selectedPlan={selectedPlan}
          isPublic={isPublic}
          planID={planID}
          inFavorite={inFavorite}
          planLastModified={planLastModified}
          planCreationDate={planCreationDate}
          planName={planName}
          planDates={planDates}
          planDuration={planDuration}
          noDatesSelected={noDatesSelected}
          participants={participants}
          travelWithKids = {travelWithKids}
          planDays={planDays}
          planPlacesIDs={planPlacesIDs}
          owner={owner}
          planEditors={planEditors}
          imagePreview={imagePreview}
          imageGallery={imageGallery}
          planTasks={planTasks}
          planExperiences={[...planExperiences]}
          planSecurity = {planSecurity}
          albumSecurity = {albumSecurity}
          showAlbum = {showAlbum}
          planDescription = {planDescription}
          publicPlanTags = {publicPlanTags}
          discoverPlacesLocationFilterTags={discoverPlacesLocationFilterTags}
          myFriends={this.state.myFriends.filter(user=>user.status="Friend")}
          viewUserJourneyID = {this.state.viewUserJourneyID}
          showSuggestedTrips = {this.state.showSuggestedTrips}
          dailyAnchors = {dailyAnchors}

          countriesToExplore = {this.props.systemInitializeData.countriesToExplore}
          locationsTree= {JSON.parse(JSON.stringify(this.state.locationsTree))}
          placesLocationSearchTags = {this.state.placesLocationSearchTags}
          userCategoriesScore={this.state.userCategoriesScore}
          placesCharacteristicsData = {this.state.placesCharacteristicsData}
          
          addNoteToPlan = {this.addNoteToPlanHandler}
          addTransportToPlan={this.addTransportToPlanHandler}
          updateNoteInPlan = {this.updateNoteInPlanHandler}
          updateTransportInPlan={this.updateTransportInPlanHandler}

          updatePlanEditors={this.updatePlanEditors}
          updatePlanNote = {this.updatePlanNote}
          removeNoteFromPlan = {this.removeNoteFromPlan}

          updatePlanTransport={this.updatePlanTransport}
          removeTransportFromPlan = {this.removeTransportFromPlanHandler}

          addPlanToFavorite={this.addToFavoriteHandler}
          updateFavoriteTags={this.updateFavoriteTagsHandler}
          removePlanFromFavorite={this.removeFromFavoriteHandler}

          cloneTrip = {this.clonePlanHandler}

          viewPlaceHandler={this.viewPlaceHandler}
          planPlacesList={planPlacesList}
          getAddedPlaceToPlan = {this.getAddedPlaceToPlanHandler}
         /*  updatePlanPlacesList={this.updatePlanPlacesListHandler} */
          slimPlacesByCountry={this.state.slimPlacesByCountry}
          systemCategories = {this.state.systemCategories}
       /*    discoverPlacesCategoriesSearchTags={this.state.discoverPlacesCategoriesSearchTags}
          discoverPlacesNamesSearchTags={this.state.discoverPlacesNamesSearchTags}
          discoverPlacesLocationsSearchTags={this.state.discoverPlacesLocationsSearchTags} */
          favoritePlacesLoaded = {this.state.favoritePlacesLoaded}
          favoritePlaces={this.state.favoritePlaces}
          favoritePlacesIDs = {this.state.favoritePlacesIDs}
          favoritePlacesSearchTags={this.state.favoritePlacesSearchTags}
          allFavoritePlacesSearchTags={this.state.allFavoritePlacesSearchTags}
          usersCategories={this.state.usersCategories}
          placesCharacteristicsData={this.state.placesCharacteristicsData}
          userLanguage={this.props.userLanguage}
          systemText={this.props.systemText}
          user={this.props.user}

          adminUser = {this.props.isAdminUser}
          inputLanguage = {this.props.inputLanguage}
          detectInputLanguage = {this.props.detectInputLanguage}

          locationsTranslator = {this.state.locationsTranslator}
          categoriesTranslator = {this.state.categoriesTranslator}

          shareExperience = {this.createNewSelectionHandler}

          deleteExperienceHandler = {this.deleteExperienceHandler}
          editExperienceHandler = {this.editExperienceHandler}
          viewExperiencePlace = {this.viewExperiencePlaceHandler}
          removeExperienceFromPlanHandler = {this.removeExperienceFromPlanHandler}
          viewImagesFullscreen = {this.viewImagesFullscreenHandler}

          updatePlan = {this.updatePlan}
          discardView = {() =>{this.refs.writeDatabase.closeTripPlanView(planID); if (isEditor){this.updateSlimPlan()}this.setState({viewPlan: false,showNullBackdrop:false,viewUserJourneyID:undefined/* ,viewPlanJourney:false */})}}

          loadCountryPlacesForPlanning={this.loadCountryPlacesForPlanning}
          loadFavoritePlacesForPlanning={this.loadFavoritePlacesForPlanning}

          updateExperiencesPlanSecurity = {this.updateExperiencesPlanSecurity}
          updateExperiencesAlbumSecurity = {this.updateExperiencesAlbumSecurity}

          addAccommodationHandler =  {this.createQuickPlaceHandler}

          categorySearchLogHandler={this.refs.writeDatabase.categorySearchLogHandler}
          locationSearchLogHandler={this.refs.writeDatabase.locationSearchLogHandler}

          approvePlan={()=>{this.refs.writeDatabase.approvePlan(planID)}}
          selectedTripGeneratorParams = {this.state.selectedTripGeneratorParams}
          selectedAccommodation={this.state.existingPlaceFullData}

          writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
          />)
      }
    }

    

    renderCreateTripPlan = () =>{
      if (this.state.createTripPlan){
        return(
          <TripPlanOverviewEditor
            user={this.props.user} 
            editMode = {"Create"}
            writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
            clonedPlan={this.state.clonedPlan}
            discard={this.discardCreateTripPlan} 
            create={this.newTripPlanCreationHandler}
            tripCreateLogin = {this.tripCreateLogin}

            adminUser={this.props.isAdminUser}
            loggedUser={this.props.loggedUser}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
            RTL = {this.props.RTL}
          />
        )
      }
    }

    renderCreateExperience = () =>{
      if (this.state.createExperience){
        return(
          <ExperienceEditor
            editMode = "Create"
            slimSystemPlaces={this.state.placesListForPlaceCreation} 
            experiencePlace={this.state.newExperiencePlace}
            experienceDate = {this.state.newExperienceDate}
            experiencePlan = {this.state.newExperiencePlan}
            discard={this.discardCreateExperience} 
            save={this.newExperienceCreationHandler}
            viewExperiencePlace = {this.viewExperiencePlaceHandler}

            loadCountryPlaces = {this.getPlacesListForPlaceCreationValidation}
            getExistingPlaceData={this.getExistingPlaceData}
            existingPlace={this.state.existingPlaceFullData}
            newPlaceHandler ={()=>{this.createNewSelectionHandler("Place");this.setState({createExperience:false})}}
            userPlans={this.state.userCreatedTripPlans}

            countriesToExplore = {this.props.systemInitializeData.countriesToExplore}
            locationsTree= {JSON.parse(JSON.stringify(this.state.locationsTree))}

            createQuickPlaceHandler = {this.createQuickPlaceHandler}

            myFriends = {this.state.myFriends}
            writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
            categoriesTranslator = {this.state.categoriesTranslator}
            locationsTranslator = {this.state.locationsTranslator}
            detectInputLanguage = {this.props.detectInputLanguage}
            inputLanguage = {this.props.inputLanguage}
            user={this.props.user} 
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            locationCountry={this.props.locationCountry}
            systemText={this.props.systemText}
          />
        )
      }
    } 

    renderEditExperience = () =>{
      if (this.state.editExperience){
        let selectedExperience=this.state.selectedExperience
        let experienceCreationDate = selectedExperience.experienceCreationDate;
        let experienceLastModified = selectedExperience.experienceLastModified;
        let experienceID = selectedExperience.experienceID;
        let experiencePlaceID = selectedExperience.experiencePlaceID
        let experiencePlaceName = selectedExperience.experiencePlaceName
        let experiencePlanID = selectedExperience.experiencePlanID;
        let experiencePlanName = selectedExperience.experiencePlanName;
        let experienceLocation = selectedExperience.experienceLocation
        let experienceCategory= selectedExperience.experienceCategory
        let experienceDate = selectedExperience.experienceDate;
        let experienceText = selectedExperience.experienceText;
        let experienceRank = selectedExperience.experienceRank;
        let experienceStats = selectedExperience.experienceStats;
        let experienceDuration = selectedExperience.experienceDuration;
        let videoUrl = selectedExperience.videoUrl;
        let owner = selectedExperience.owner;
        let experiencePrivacy = selectedExperience.experiencePrivacy;
        let imagePreview =[];
        if (selectedExperience.imagePreview!==undefined){
            imagePreview=selectedExperience.imagePreview;
        }
        else{
          selectedExperience.imagePreview=[];
        }
        let imageGallery =[];
        if (selectedExperience.imageGallery!==undefined){
            imageGallery=selectedExperience.imageGallery;
        }
        else{
          selectedExperience.imageGallery=[];
        }
        let taggedUsers =[];
        if (selectedExperience.taggedUsers!==undefined){
          taggedUsers=selectedExperience.taggedUsers;
        }
        else{
          selectedExperience.taggedUsers=[];
        }
        let experienceTags =[];
        if (selectedExperience.experienceTags!==undefined){
          experienceTags=selectedExperience.experienceTags;
        }
        else{
          selectedExperience.experienceTags=[];
        }
        return(
          <ExperienceEditor
          editMode = "Edit"
          selectedExperience={selectedExperience}
          experienceCreationDate={experienceCreationDate}
          experienceLastModified={experienceLastModified}
          experienceID={experienceID}
          experiencePlaceID={experiencePlaceID}
          experiencePlaceName={experiencePlaceName}
          experiencePlanID={experiencePlanID}
          experiencePlanName={experiencePlanName}
          experienceLocation={experienceLocation}
          experienceCategory={experienceCategory}
          experienceDate={experienceDate}
          experienceText={experienceText}
          experienceRank={experienceRank}
          experienceStats={experienceStats}
          experienceDuration={experienceDuration}
          experienceTags={experienceTags}
          videoUrl={videoUrl}
          owner={owner}
          experiencePrivacy={experiencePrivacy}
          imagePreview={imagePreview}
          imageGallery={imageGallery}
          taggedUsers={taggedUsers}

          myFriends={this.state.myFriends.filter(user=>user.status="Friend")}
          user={this.props.user}

          userPlans={this.state.userCreatedTripPlans}

          categoriesTranslator = {this.state.categoriesTranslator}
          locationsTranslator = {this.state.locationsTranslator}

          discard={this.discardEditExperience}
          save={this.updateExperienceHandler}

          writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
          RTL = {this.props.RTL}
          systemText={this.props.systemText}
          userLanguage={this.props.userLanguage}
          />
        )
      }
    }

    renderAddToFavorite=()=>{
        let update = this.state.updateFavoriteTags? true : false
        let add = this.state.addToFavorite? true : false
        if (this.state.updateFavoriteTags || this.state.addToFavorite){
            return(
                <AddToFavorite 
                update={update}
                add={add}
                contentType={this.state.favoriteContentType}
                object = {this.state.favoriteObject}
                userTags = {[]}
                discard={this.discardAddToFavorite}
                addToFavorite={this.addToFavoriteDBHandler}

                writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
                
                systemText={this.props.systemText}
                userLanguage={this.props.userLanguage}
                inputLanguage={this.state.inputLanguage}
                categoriesTranslator = {this.state.categoriesTranslator}
                locationsTranslator = {this.state.locationsTranslator}
                RTL = {this.props.RTL}
               />
             )
        }
      }

      renderAddToPlan = () =>{
        if (this.state.addToPlan){
          let planPlacesList = [];
          let selectedPlanPlanID;
          if (this.state.selectedPlan!==undefined){
            planPlacesList=this.state.selectedPlan.planPlacesList
            selectedPlanPlanID = this.state.selectedPlan.planID
          }
          return(
            <AddToPlan 
            ref = "addToPlan"
              objectType={this.state.addToPlanType}
              selectedObject={this.state.selectedObject}
              discard={this.discardAddToPlan}
              selectPlanHandler={this.addToPlanSelectPlanHandler}
              newPlanHandler={this.newPlanHandler}

              updatePlanDays={this.updatePlanDays}

              connectExperienceToPlan={this.connectExperienceToPlan} 

              updatePlanPlacesList={this.updatePlanPlacesListHandler}
              resetSelectedPlan={()=>{this.setState({selectedPlan:undefined})}}
              
              userPlans={this.state.userCreatedTripPlans}
              selectedPlan={this.state.selectedPlan}
              selectedPlanPlanID = {selectedPlanPlanID}
              planPlacesList={planPlacesList}

              categoriesTranslator = {this.state.categoriesTranslator}
              locationsTranslator = {this.state.locationsTranslator}

              writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
              
              RTL = {this.props.RTL}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
            />
          );
        }
      }

      renderCreateMenu=()=>{
        if (this.state.showCreateMenu){
            let RTLBody=""
            if (this.props.RTL){
                RTLBody = "RTLBody"
            }
            return(<CreateMenu 
                createNewHandler ={this.createNewSelectionHandler}
                loginHandler = {this.props.loginHandler}
                loggedUser = {this.props.loggedUser}
                createTemplateTrips = {()=>{
                  this.refs.writeDatabase.writeAcitivityLog({
                    activityKey:"TRIP_SUGGESTIONS_OPEN",
                    userScreen:this.state.selectedContentWall.toUpperCase(),
                  })
                  this.setState({showCreateTripTemplate:true,showCreateMenu: false, showBackdrop: false});
                if(!isMobile){this.setState({showNullBackdrop:true})}}}
                systemText={this.props.systemText}
                userLanguage={this.props.userLanguage}
                RTL = {this.props.RTL}
                RTLBody = {RTLBody}
                />)
        }
    }

    renderUsersLocator = () =>{
        if (this.state.showUsersLocator){
          return(<UsersLocator
            allUsers={this.state.allUsers}
            myFriends={this.state.myFriends}
            usersIFollow = {this.state.usersIFollow}
            user={this.props.user}
            friendRequest={this.friendRequestHandler}
            followUser={this.followUserHandler}
            removeFriend = {this.removeFriendHandler}
            stopFollow = {this.stopFollowHandler}
            acceptFriendship={this.acceptFriendshipHandler}
            discard={()=>{  this.setState({showUsersLocator:false,showNullBackdrop:false})}}
            viewUserProfileHandler = {this.viewUserProfileHandler}
  
            writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
            RTL = {this.props.RTL}
          />)
        }
      }

    renderDBScripts = () =>{
        return(
            <DBScripts
                environment={this.props.environment}
                ref = "DBScripts"
            />
        )
    }

    renderTripTemplateCreator = () =>{
      return(
          <TripTemplateCreator
              user={this.props.user}
              systemText={this.props.systemText}
              userLanguage = {this.props.userLanguage}
              allSystemCategories = {this.state.allSystemCategories}
              showProgress = {(show,progress)=>{this.setState({showTemplateCreatorProgress:show,templateCreatorProgress:progress})}}
              ref = "tripTemplateCreator"
          />
      )
  }

  renderShowTemplateCreatorProgress = () =>{
    let width= (2*this.state.templateCreatorProgress)+"%"
    if (this.state.showTemplateCreatorProgress){
      return (
        <div className={classes.Saving}>
          <img src={saving} alt="" className={classes.SavingGif} />
          <div className={classes.ProgressBar}>
                <div  style={{width:width}} className={classes.Progress}/>
            </div>
        </div>
      );
    }
  }

    renderInformationModal = () =>{
      if (this.state.showInfo) {
        return (
          <InformationModal 
            infoType={this.state.infoType}
            header={this.state.infoHeader}
            discard={()=>{this.setState({showNullBackdrop:false,showInfo:false})}}
            message={this.state.informationMessage}
            btnText = {this.state.btnText}
            RTL = {this.props.RTL}
            />)
      
      }
    }

    renderErrorMessage = () =>{
      if (this.state.showErrorMessage) {
        return (
          <InformationModal 
            infoType={"Negative"}
            header={this.state.errorHeader}
            discard={()=>{this.setState({showNullBackdrop:false,showErrorMessage:false})}}
            message={this.state.errorMessage}
            btnText = {this.state.errorButton}
            RTL = {this.props.RTL}
            />)
      
      }
    }

    renderContantForm = () =>{
      if (this.state.showContantForm){
        return (<ContactForm
          placeID={this.state.requestPlaceID}
          placeName={this.state.requestPlaceName}
          userLanguage={this.props.userLanguage}
          selectedTopic = {this.state.requestTopic}
          loggedUser = {this.props.loggedUser}
          user={this.props.user}
          systemText = {this.props.systemText}
          RTL = {this.props.RTL}
          writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
          inputLanguage = {this.props.inputLanguage}
          detectInputLanguage = {this.props.detectInputLanguage}
          discard = {()=>{this.setState({showContantForm:false, showBackdrop:false})}}
          sendRequest = {this.contactRequestHandler}
      />)
      }
     
    }

    renderUserWall = () =>{
      if (this.state.showUserWall){
       return(
        <UserProfileWall
        selectedUser = {this.state.selectedUser}
        experiences={this.state.filteredSelectedUserExperiences}
        plans = {this.state.filteredSelectedUserTripPlans}

        locationsTranslator = {this.state.locationsTranslator}
        categoriesTranslator = {this.state.categoriesTranslator}

        experiencesFilterTags = {this.state.selectedUserExperiencesFilterTags}
        experiencesSearchTags = {this.state.selectedUserExperiencesSearchTags}

        tripsFilterTags = {this.state.selectedUserTripPlansFilterTags}
        tripsSearchTags = {this.state.selectedUserTripPlansSearchTags}

        followingUsersIDs = {this.state.followingUsersIDs}
        myFriendsIDs = {this.state.myFriends.map(friend=>{return friend.userID})}

        favoriteTripsIDs = {this.state.favoriteTripsIDs}

        viewExperiencePlace={this.viewExperiencePlaceHandler}
        viewExperiencePlan = {this.viewExperiencePlanHandler}
        viewPlanHandler = {this.viewPlanHandler}
        viewImagesFullscreen = {this.viewImagesFullscreenHandler}

        requestFriendship={this.friendRequestHandler}
        followUser={this.followUserHandler}
        removeFriend = {this.removeFriendHandler}
        stopFollow = {this.stopFollowHandler}
        acceptFriendship={this.acceptFriendshipHandler}

        selectedUserExperiencesSearchHandler = {this.selectedUserExperiencesSearchHandler}
        selectedUserExperiencesRemoveTagHandler = {this.selectedUserExperiencesRemoveTagHandler}

        selectedUserTripsSearchHandler = {this.selectedUserTripsSearchHandler}
        selectedUserTripsRemoveTagHandler = {this.selectedUserTripsRemoveTagHandler}

        addToFavoriteHandler={this.addToFavoriteHandler}
        removeFromFavoriteHandler={this.removeFromFavoriteHandler}
        updateFavoriteTagsHandler={this.updateFavoriteTagsHandler}

        discard={this.discardUserProfileWall}

        clonePlanHandler = {this.clonePlanHandler}

        loginHandler = {this.props.loginHandler}
        loggedUser = {this.props.loggedUser}
        writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
        user={this.props.user}
        systemText = {this.props.systemText}
        userLanguage={this.props.userLanguage}
        inputLanguage = {this.props.inputLanguage}
        detectInputLanguage = {this.props.detectInputLanguage}
        RTL = {this.props.RTL}
        />
       ) 
      }
    }

    detectInputLanguage = () =>{
      return (<DetectInputLanguage
          ref="detectInputLanguage"
        />)
    }

    renderWelcomeWizard = () =>{
      if (this.state.showWelcomeWizard){
       /*  return(
          <WelcomeWizard
            loggedUser={this.props.loggedUser}
            systemText = {this.props.systemText}
            userLanguage={this.props.userLanguage}
            RTL = {this.props.RTL}
  
            writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
            getPersonalizedTrips = {()=>{this.setState({showCreateTripTemplate:true});if(!isMobile){this.setState({showNullBackdrop:true})}}}
            getUserCountryPlaces = {this.getUserCountryPlaces}
            discrad = {()=>{this.setState({showWelcomeWizard:false,showNullBackdrop:false})
                if (this.props.loggedUser){this.refs.writeDatabase.disableUserWelcomeWizardFlag()}
                localStorage.setItem('showWelcomeWizard',false);
              }}
  
          />
        )  */
        return(
          <WelcomeWizardV2
          loggedUser={this.props.loggedUser}
          systemText = {this.props.systemText}
          userLanguage={this.props.userLanguage}
          RTL = {this.props.RTL}

          writeAcitivityLog = {this.refs.writeDatabase.writeAcitivityLog}
          getPersonalizedTrips = {()=>{this.setState({showCreateTripTemplate:true});if(!isMobile){this.setState({showNullBackdrop:true})}}}
          getUserCountryPlaces = {this.getUserCountryPlaces}
          discrad = {()=>{this.setState({showWelcomeWizard:false,showNullBackdrop:false})
              if (this.props.loggedUser){this.refs.writeDatabase.disableUserWelcomeWizardFlag()}
              localStorage.setItem('showWelcomeWizard',false);
            }}

          />
        )
      }
       
    }

   renderKeepCachedTrip = () =>{
     if (this.state.showKeepCachedTripBeforeLogin){
       return(
        <ConfirmModal
        header={""}
        primaryAction={this.keepCreatedTrip}
        secondaryAction={this.props.loginHandler}
        message={this.props.systemText.misc.loginHandler.saveCreatedTripMessage[this.props.userLanguage]}
        primaryBtn={this.props.systemText.misc.loginHandler.yesButton[this.props.userLanguage]}
        secondaryBtn={this.props.systemText.misc.loginHandler.noButton[this.props.userLanguage]}
        primaryStyle = {"PositiveButton"}
        secondaryStyle = {"DiscardButton"}
        RTL = {this.props.RTL}

      />
       )
     }
   }

    render(){
        return(
            <div className={classes.Wrapper}>
                <Backdrop show={this.state.showBackdrop} clicked={this.discardBackdrop} />
                <Backdrop show={this.state.showNullBackdrop}/>
                {this.detectInputLanguage()}
                {this.renderInformationModal()}
                {this.renderKeepCachedTrip()}
                {this.renderErrorMessage()}
                {this.renderWelcomeWizard()}
                {this.renderNotLoggedUserCloneTripMessage()}
                {this.renderContantForm()}
                {this.renderDBScripts()}
                {this.renderViewCreateTripTemplate()}
                {this.renderTripTemplateCreator()}
               {/*  {this.renderShowTemplateCreatorProgress()} */}
                {this.renderCreateMenu()}
                {this.renderUsersLocator()}
                {this.renderCreatePlace()}
                {this.renderCreateTripPlan()}
                {this.renderCreateExperience()}
                {this.renderViewImagesFullscreen()}
                {this.renderViewPlace()}
                {this.renderViewPlan()}
                {this.renderEditExperience()}
                {this.renderAddToFavorite()}
                {this.renderAddToPlan()}
                {this.renderUserWall()}
                {this.loadDatabase()}
                {this.writeDatabase()}
                {this.renderSearchFunctions()}
                {this.renderUpdateFunctions()}
                {this.renderContent()}
            </div>
        )
    }
}