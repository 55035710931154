import React, { Component } from "react";

import classes from "./Place.module.css";

import Toolkit from "../../../Components/UI/Toolbar/Toolkit"
import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop";
import ConfirmationModal from "../../../Components/UI/Modals/ConfirmationModal";

import {isMobile, isMobileOnly} from 'react-device-detect';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import addToPlanIcon from "../../../visuals/ContentObjects/AddItemToPlan.png"
import addToPlanIconFade from "../../../visuals/ContentObjects/AddItemToPlanFade.png"
import experienceIcon from "../../../visuals/ContentObjects/ExperienceIcon.png"
import experienceIconFade from "../../../visuals/ContentObjects/ExperienceIconFade.png"
import inFavoriteIcon from "../../../visuals/ContentObjects/InFavorites.png"
import addToFavoriteIcon from "../../../visuals/ContentObjects/InFavoritesGrey.png"
import addToFavoriteIconFade from "../../../visuals/ContentObjects/InFavoritesFade.png"

let inPlanIconGrey =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAddToPlanWhite.png?alt=media&token=789abb85-aef9-4a48-9a5c-be959f5ed002";
let removeIcon  =  "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"
let durationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDurationIcon.png?alt=media&token=3e02db71-8c60-4a85-bba8-5a30ba9ce277"


class Place extends Component {
    state={favoriteTools: false,
      optionsTools: false,
        showBackdrop: false,
        confirmMode: false,
        inPlan:false,
        showPlace:true,
        charFiltersPass:true,
        placeRef:React.createRef(),
      }

      componentWillMount=()=>{
        //handle the image
        let imageUrl = "";
        if (this.props.imagePreview!==undefined){
          if (isMobileOnly){
            if (this.props.imagePreview.thumbnailImage!==undefined){
              imageUrl=this.props.imagePreview.thumbnailImage.url
            }
           
          }
          else{
            if (this.props.imagePreview.smallImage!==undefined){
              imageUrl=this.props.imagePreview.smallImage.url
            }
            
          }
        }
        this.setState({imageUrl:imageUrl})
      }
      

      componentDidUpdate = (prevProps) =>{
        if (JSON.stringify(prevProps.imagePreview)!==JSON.stringify(this.props.imagePreview)){
          let imageUrl = "";
          if (this.props.imagePreview!==undefined){
            if (isMobileOnly){
              if (this.props.imagePreview.thumbnailImage!==undefined){
                imageUrl=this.props.imagePreview.thumbnailImage.url
              }
              
            }
            else{
              if (this.props.imagePreview.smallImage!==undefined){
                imageUrl=this.props.imagePreview.smallImage.url
              }
              
            }
          }
          this.setState({imageUrl:imageUrl})
  
        }
        if (prevProps.clickedMarkerIndex!==this.props.clickedMarkerIndex){
          if (this.props.clickedMarkerIndex===this.props.index){
            if (this.props.clickedMarkerIndex!==this.state.hoverIndex&&!isMobile){
              this.state.placeRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
           
          }
        }
      }

    discard = () =>{
        this.setState({favoriteTools: false,
          optionsTools: false,
          showBackdrop: false});
    }

    handleMouseOver=()=>{
      this.setState({placeHovered:true,hoverIndex:this.props.index})
      if (this.props.hoverHandler!==undefined){
        this.props.hoverHandler(this.props.index,this.props.placeID);
      }
      
    }
  
    handleMouseExit=()=>{
      this.setState({placeHovered:false,hoverIndex:-1})
      if (this.props.hoverHandler!==undefined){
        this.props.hoverHandler(-1,-1);
      }
      
    }

    viewHandler = () =>{
      if (!this.state.confirmMode){
        this.props.viewHandler(this.props.index);
      }
        
    }

    deletePlaceHandler = () =>{
      this.setState({
        confirmMessage: this.props.systemText.extraModals.confirmationModal.deleteMessage[this.props.userLanguage],
        primaryBtn: this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage],
        secondaryBtn: this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage],
        confirmMode: true,
        showBackdrop: true,
        optionsTools: false
      });
    }


    confirmDelete = () => {
     
 /*      let inFavorite = false;
      if (this.props.inFavorite) inFavorite=true;
      if (this.props.selectedContentWall==="Discover"&&this.props.favoritePlacesIDs.includes(this.props.placeID)) inFavorite=true; */
      this.props.deletePlaceHandler(this.props.index);
      this.setState({
        confirmMode: false,
        showBackdrop: false
      });
    };
    
    discardDelete = () => {
      this.setState({
        confirmMode: false,
        showBackdrop: false
      });
    };

    renderTopSection=()=>{
      let placeName="";
      //get the relevant language or at least any language name
      if (this.props.placeName[this.props.userLanguage]!==undefined){
        placeName=this.props.placeName[this.props.userLanguage]
      }
      else if(this.props.placeName["English"]!==undefined){
        placeName=this.props.placeName["English"]
      }
      else{
        let placesNames =  Object.keys(this.props.placeName);
        placeName=this.props.placeName[placesNames[0]]
      }
      let topSectionClass = "TopSection"
      let titleClass = "Title"
      let categoriesListClass = "CategoriesList"
      let categoryLastClass = "CategoryLast"
      let categoryClass = "Category"
      let categoryDirectionClass = "CategoryLTR"
      let categoryLastDirectionClass = "CategoryLastLTR"
      if (this.props.RTL){
        categoryDirectionClass = "CategoryRTL"
        categoryLastDirectionClass = "CategoryLastRTL"
      }
      let selectedPlaceCategoryClass = ""
      if (this.props.clickedMarkerIndex===this.props.index){
        selectedPlaceCategoryClass = "SelectedPlaceCategory"
        if (this.props.RTL){
          selectedPlaceCategoryClass = "SelectedPlaceCategoryRTL"
        }
      }
        return (
            <div className={classes[topSectionClass]} >
                <div className={classes[titleClass]} >
                  <div> {placeName}</div>     
               </div>
               {this.props.categoriesTranslator!==undefined&&this.props.placeCategory!==undefined?(
                  <div className={classes[categoriesListClass]}>
                  {this.props.placeCategory.map((category, index) => {
                     let categoryText = ""
                     if (this.props.categoriesTranslator[category]!==undefined){
                         categoryText = this.props.categoriesTranslator[category][this.props.userLanguage]
                     }
                     else{
                         categoryText = category
                     }
                    if (index===(this.props.placeCategory.length-1)){
                    
                      return(
                        <div key={index} className={[classes[categoryLastClass],classes[categoryLastDirectionClass]].join(" ")}>{categoryText}</div>
                      )
                    }
                    else{
                      return(
                        <div key={index} className={[classes[categoryClass],classes[categoryDirectionClass],classes[selectedPlaceCategoryClass]].join(" ")}>{categoryText}</div>
                      )
                    }
                     
                  })}
                 </div>
               ):null}
              
            </div>
        );
    }

    renderTools = () =>{
      let render = this.state.placeHovered/* &&!this.props.hideTools */
      if (isMobile/* &&!this.props.hideTools */){
        render = true
      }
     
     if (render)
       { 
        let placeToolsClass = "PlaceTools"
        if (this.props.RTL){
          placeToolsClass = "PlaceToolsRTL"
        }

        return(
          
          <div className={classes[placeToolsClass]}>
               {this.renderFavoriteIcon()}
               <div className={classes.TabSeperator}/>
               {this.renderAddToPlanIcon()}
               <div className={classes.TabSeperator}/>
               {this.renderShareExperienceIcon()}      
               {this.renderOptionsIcon()}
           </div>
      
      )
    } 
    }

    renderClosedMessage = () =>{
      if (this.props.placePermanentlyClosed!==undefined){
        if (this.props.placePermanentlyClosed){
          return(
            <div className={classes.ClosedMessage}>
               {this.props.systemText.place.tools.permanentlyClosedMessage[this.props.userLanguage]}
             </div>
        )
        }
        
      }
      if (this.props.placeTemporaryClosed!==undefined){
        if(this.props.placeTemporaryClosed){
          return(
            <div className={classes.ClosedMessage}>
               {this.props.systemText.place.tools.temporaryClosedMessage[this.props.userLanguage]}
             </div>
        )
        }
        
      }
    }

    renderOptionsIcon = () =>{
      let isOwner = false;
      if (this.props.owner.ownerID!==undefined){
        if (this.props.owner.ownerID===this.props.user.userID){
          isOwner = true
        }
      }
      else if (this.props.owner===this.props.user.userID){
        isOwner = true
      }
      if (this.props.loggedUser){
        let toolsIconClass = "ToolsIcon"
        if ((isOwner &&
        this.props.selectedContentWall==="MyContent")||this.props.selectedContentWall==="AdminWall"){
          let optionsIcon="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditMode.png?alt=media&token=53018dfb-bdc0-4dcb-92b1-600bcfac76a7"
          if (isMobile){
            optionsIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditModeHorizontal.png?alt=media&token=91cf1eb7-39b7-423a-85ee-fb963daaa3a1"
          }
          return(
            <img src={optionsIcon} alt="" className={classes[toolsIconClass]}
          onClick={(e)=>{ e.stopPropagation();this.setState({optionsTools: true, showBackdrop: true})}}
          />
          )
        }
      }
        
    }

    renderAddToPlanIcon = ()=>{
      let greyedToolsClass = "GreyedTools"
      let toolsIconClass = "ExperienceIcon"
      if (this.props.loggedUser){
        
         return(
          <div className={classes[greyedToolsClass]} onClick={(e)=>{ e.stopPropagation();this.props.addToPlan(this.props.index)}}>
            <img src={addToPlanIcon} 
          alt="" className={classes[toolsIconClass]} />
           <div className={classes.ToolText}>
           {this.props.systemText.place.tools.addToPlan[this.props.userLanguage]}</div>
          </div>)
          
      }
      else{
        return(<div className={classes[greyedToolsClass]} onClick={(e)=>{ e.stopPropagation();this.props.loginHandler()}}>
          <img src={addToPlanIconFade} 
          alt="" className={classes[toolsIconClass]}
           
          />
          <div className={classes.ToolTextFade}>
           {this.props.systemText.place.tools.addToPlan[this.props.userLanguage]}</div>
        </div>)

      }
    }

    renderShareExperienceIcon= ()=>{
      let greyedToolsClass = "GreyedTools"
      let toolsIconClass = "ExperienceIcon"
      if (this.props.loggedUser){
        
        return(
          <div className={classes[greyedToolsClass]} onClick={(e)=>{ e.stopPropagation();this.props.shareExperience(this.props.index)}}>
            <img src={experienceIcon} 
          alt="" className={classes[toolsIconClass]} />
           <div className={classes.ToolText}>
           {this.props.systemText.place.tools.shareExperience[this.props.userLanguage]}</div>
          </div>)
      }
      else{
        return(<div className={classes[greyedToolsClass]}  onClick={(e)=>{ e.stopPropagation();this.props.loginHandler()}}>
          <img src={experienceIconFade} 
          alt="" className={classes[toolsIconClass]}
          
          />
           <div className={classes.ToolTextFade}>
           {this.props.systemText.place.tools.shareExperience[this.props.userLanguage]}</div>
        </div>) 

      }
    }

    renderNewCategory = () =>{
      let category="";
      if (this.props.placeNewCategory[this.props.userLanguage]!==undefined){
        category=this.props.placeNewCategory[this.props.userLanguage]
      }
      else if(this.props.placeNewCategory["English"]!==undefined){
        category=this.props.placeNewCategory["English"]
      }
      else{
        let names =  Object.keys(this.props.placeNewCategory);
        category=this.props.placeNewCategory[names[0]]
      }
      return(
        <div>{category}</div>
      )
    }


    renderFavoriteIcon = ()=>{
      let greyedToolsClass = "GreyedTools"
      let toolsIconGreyedClass = "ToolsIconGreyed"
      let toolsIconClass = "ToolsIcon"
      if (this.props.loggedUser){
        if (this.props.selectedContentWall==="Favorite"||this.props.favoritePlacesIDs.includes(this.props.placeID)){
          return(
          <div className={classes[greyedToolsClass]} onClick={(e)=>{ e.stopPropagation();this.setState({favoriteTools: true, showBackdrop: true})}}>
            <img src={inFavoriteIcon} 
                alt="" className={classes[toolsIconClass]} />
           <div className={classes.ToolText}>
           {this.props.systemText.place.tools.addToFavorite[this.props.userLanguage]}</div>
          </div>)
         }
        else{
            return(
              <div className={classes[greyedToolsClass]} onClick={(e)=>{ e.stopPropagation();this.props.addPlaceToFavorite(this.props.index)}}>
                <img src={addToFavoriteIcon} 
              alt="" className={classes[toolsIconClass]} />
               <div className={classes.ToolText}>
               {this.props.systemText.place.tools.addToFavorite[this.props.userLanguage]}</div>
              </div>)
        }
      }
      else{
        return(<div className={classes[greyedToolsClass]} onClick={(e)=>{ e.stopPropagation();this.props.loginHandler()}}>
          <img src={addToFavoriteIconFade} 
          alt="" className={classes[toolsIconGreyedClass]} data-tip data-for="favoriteTip"
           
          />
          <div className={classes.ToolTextFade}>
          {this.props.systemText.place.tools.addToFavorite[this.props.userLanguage]}</div>
      </div>)
       
      }
      
    }

    renderDuration = () =>{
      let iconClass = "DurationIcon"
      let durationClass = "Duration"
      if (this.props.RTL){
          iconClass = "DurationIcon"
          durationClass = "DurationRTL"
      }
     if(this.props.placeCharacteristics!==undefined){
        if (this.props.placeCharacteristics.durationValue!==undefined){
          if (this.props.placeCharacteristics.durationUnits==="days"){
              if (this.props.placeCharacteristics.durationValue===0.5){
                  return(
                      <div className={classes[durationClass]}>
                          <img src={durationIcon} alt="" className={classes[iconClass]}/>
                          {this.props.systemText.placeCharacteristics.trailCharacteristics.halfDay[this.props.userLanguage]}
                      </div>
                  )
              }
              else if (this.props.placeCharacteristics.durationValue===1){
                  return(
                      <div className={classes[durationClass]}>
                          <img src={durationIcon} alt="" className={classes[iconClass]}/>
                          {this.props.systemText.placeCharacteristics.trailCharacteristics.fullDay[this.props.userLanguage]}
                      </div>
                  )
              }
              else{
                  return(
                      <div className={classes[durationClass]}>
                          <img src={durationIcon} alt="" className={classes[iconClass]}/>
                          {this.props.placeCharacteristics.durationValue+" "+
                          this.props.systemText.placeCharacteristics.trailCharacteristics[this.props.placeCharacteristics.durationUnits][this.props.userLanguage]}
                      </div>
                  )
              }
          }
          else if (this.props.placeCharacteristics.durationUnits!==undefined){
              return(
                  <div className={classes[durationClass]}>
                      <img src={durationIcon} alt="" className={classes[iconClass]}/>
                      {this.props.placeCharacteristics.durationValue+" "+
                      this.props.systemText.placeCharacteristics.trailCharacteristics[this.props.placeCharacteristics.durationUnits][this.props.userLanguage]}
                  </div>
              )
          }
          
      }
     }
  }

      renderLocation = () =>{
        let placeCountryName = "";
        let placeRegionName = "";
        let placeCityName = "";
        let placeCountry = this.props.placeLocation.locationTree.placeCountry
        let placeRegion = this.props.placeLocation.locationTree.placeRegion
        let placeCity = this.props.placeLocation.locationTree.placeCity
        if (this.props.placeStatus==="potential"){
            if (placeCountry!==undefined){
                if (placeCountry[this.props.userLanguage]!==undefined){
                    placeCountryName=placeCountry[this.props.userLanguage]
                }
                else if(placeCountry["English"]!==undefined){
                placeCountryName=placeCountry["English"]
                }
                else{
                    let names =  Object.keys(placeCountry);
                    placeCountryName=placeCountry[names[0]]
                }
    
            }

            if (placeRegion!==undefined){
                if (placeRegion[this.props.userLanguage]!==undefined){
                    placeRegionName=placeRegion[this.props.userLanguage]
                }
                else if(placeRegion["English"]!==undefined){
                    placeRegionName=placeRegion["English"]
                }
                else{
                    let names =  Object.keys(placeRegion);
                    placeRegionName=placeRegion[names[0]]
                }
            }
            
            if (placeCity!==undefined){
                if (placeCity[this.props.userLanguage]!==undefined){
                    placeCityName=placeCity[this.props.userLanguage]
                }
                else if(placeCity["English"]!==undefined){
                    placeCityName=placeCity["English"]
                }
                else{
                    let names =  Object.keys(placeCity);
                    placeCityName=placeCity[names[0]]
                }
            }

            
        }
        else if (this.props.locationsTranslator!==undefined && this.props.placeStatus==="approved"){
            //place approved - use the location translator
            if (placeCountry!==undefined){
              if (this.props.locationsTranslator[placeCountry]!==undefined){
                if (this.props.locationsTranslator[placeCountry][this.props.userLanguage]!==undefined){
                  placeCountryName=this.props.locationsTranslator[placeCountry][this.props.userLanguage]
                }
                else if(this.props.locationsTranslator[placeCountry]["English"]!==undefined){
                placeCountryName=this.props.locationsTranslator[placeCountry]["English"]
                }
                else{
                    let names =  Object.keys(this.props.locationsTranslator[placeCountry]);
                    placeCountryName=this.props.locationsTranslator[placeCountry][names[0]]
                }
              }
               
            }

            if (placeRegion!==undefined){
              if (this.props.locationsTranslator[placeRegion]!==undefined){
                if (this.props.locationsTranslator[placeRegion][this.props.userLanguage]!==undefined){
                    placeRegionName=this.props.locationsTranslator[placeRegion][this.props.userLanguage]
                }
                else if(this.props.locationsTranslator[placeRegion]["English"]!==undefined){
                    placeRegionName=this.props.locationsTranslator[placeRegion]["English"]
                }
                else{
                    let names =  Object.keys(this.props.locationsTranslator[placeRegion]);
                    placeRegionName=this.props.locationsTranslator[placeRegion][names[0]]
                }
              }
            }
            
            if (placeCity!==undefined){
              if (this.props.locationsTranslator[placeCity]!==undefined){
                if (this.props.locationsTranslator[placeCity][this.props.userLanguage]!==undefined){
                    placeCityName=this.props.locationsTranslator[placeCity][this.props.userLanguage]
                }
                else if(this.props.locationsTranslator[placeCity]["English"]!==undefined){
                    placeCityName=this.props.locationsTranslator[placeCity]["English"]
                }
                else{
                    let names =  Object.keys(this.props.locationsTranslator[placeCity]);
                    placeCityName=this.props.locationsTranslator[placeCity][names[0]]
                }
              }
            }
        }
        let address ="";
        if (placeCityName!==""){
            address=address+placeCityName+", ";
        }
        if (placeRegionName!==""&&!address.includes(placeRegionName)){
            address=address+placeRegionName+", ";
        }
        address=address+placeCountryName;
        let locationStatsClass = "LocationStats"
          return (
            <div className={classes[locationStatsClass]}>
              {address}
            </div>
          );
     }

      renderPlacePhotoSection = ()=>{  
        let photoPreviewClass = "PhotoPreview"
        let height="PhotoPreviewHeightPlus";
        let mapPhotoBorder = ""
        if (isMobile&&this.props.viewType==="Map"){
          mapPhotoBorder = "MapPhotoBorderLTR"
          height="MobilePhotoPreviewMap";
          photoPreviewClass="MobilePhotoPreviewMap"
          if (this.props.RTL){
            mapPhotoBorder = "MapPhotoBorderRTL"
          }
         
        }
        return (
          <LazyLoadImage
           threshold = {200}
            src={this.state.imageUrl}
            alt=""
            className={[classes[photoPreviewClass],classes[mapPhotoBorder],classes[height]].join(" ")}
          />
      );
      }
    
    renderFavoriteTools = () =>{
        if (this.state.favoriteTools){
            let toolKitStyle="Style2"
            let tools = [];
             tools.push({
              action:  ()=>{this.props.updateFavoriteTags(this.props.index);
                this.setState({showBackdrop:false, favoriteTools:false})},
              text: this.props.systemText.place.favorite.editFavoriteTags[this.props.userLanguage],
              icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTagIcon.png?alt=media&token=6fbf97d9-a636-4b33-baf9-e0412cadc9c9"
            });
            tools.push({
              action: ()=>{this.props.removePlaceFromFavorite(this.props.index);
                this.setState({showBackdrop:false, favoriteTools:false})},
              text: this.props.systemText.place.favorite.removeFromFavorite[this.props.userLanguage],
              icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2Fdelete.png?alt=media&token=8e0e899b-354c-45e8-9f47-dc70471f1f81"
            });
            return(
              <Toolkit
              style={toolKitStyle}
              tools={tools}
              RTL = {this.props.RTL}
              bodyRTL={this.props.RTL}
              />)
          }
    }

    renderOptionsTools = () =>{
      if (this.state.optionsTools){
        let tools = [];
        tools.push({
          action:  ()=>{this.props.editPlaceHandler(this.props.index);
              this.setState({showBackdrop:false, optionsTools:false})},
          text: this.props.systemText.place.tools.editPlace[this.props.userLanguage],
          icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIcon.png?alt=media&token=c964d68a-44ba-45bf-afd7-b4b8eaeb6fba"
          });
        tools.push({
          action:  ()=>{this.deletePlaceHandler();
              this.setState({showBackdrop:false, optionsTools:false})},
          text: this.props.systemText.place.tools.deletePlace[this.props.userLanguage],
          icon: "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2Fdelete.png?alt=media&token=1d88eb4a-6c67-43d8-81ed-0bb919c7d5fa"
          });
        let toolKitStyle="Style1"
        return(
          <Toolkit
          style={toolKitStyle}
          tools={tools}
          RTL = {this.props.RTL}
          bodyRTL={this.props.RTL}
          />)  
        }
    }


    renderOwner = () =>{
      if (this.props.adminMode){
        return(<div className={classes.Owner}>{this.props.owner.ownerName}</div>)
      }
    }

    renderPlanMapTools = () =>{
      if (this.props.planMap){
        let toolkitClass = "PlanToolkit"
        let toolsClass = "PlanTools"
        if (this.props.RTL){
          toolkitClass = "PlanToolkitRTL"

        }
          return(
             <div className={classes[toolkitClass]}>
                {this.renderPlanAddToPlanIcon()}
             </div>)
        }       
    }

    renderPlanAddToPlanIcon = () =>{
      if (this.props.planPlacesIDs.includes(this.props.placeID)){
        if (this.props.placeInPlan){
          return(<img src={removeIcon} alt="" className={classes.ToolkitIcon} />)
        }
       
      }
      else if(!this.props.placeInPlan){
        return(<img src={inPlanIconGrey} alt="" className={classes.ToolkitIcon} onClick={(e)=>{e.stopPropagation();this.props.addPlaceToPlan(this.props.index)}} />)
      }
    }


    renderConfirmModal = () => {
      if (this.state.confirmMode) {
        return (
          <ConfirmationModal
            header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
            primaryAction={this.discardDelete}
            secondaryAction={this.confirmDelete}
            message={this.state.confirmMessage}
            primaryBtn={this.state.primaryBtn}
            secondaryBtn={this.state.secondaryBtn}
            primaryStyle = {"DiscardButton"}
            secondaryStyle = {"DeleteButton"}
            RTL = {this.props.RTL}

          />
        );
      }
    };

    render(){
      let bodyClass = "Body"
      let RTLClass = ""
      let toolsClass = "Tools"
      if (this.props.RTL){
        RTLClass = "RTL"
        toolsClass = "ToolsRTL"
      }
      if (this.props.viewType==="Preview"){
          bodyClass = "PreviewBody"
      }
      let contentClass = "Content"
      let marginAlignmentClass = ""
      if (isMobile && this.props.viewType==="WelcomeList"){
        bodyClass = "MobileBodyWelcomeList"
      }
      //handle map icon clicked
      let selectedBody=""
      if (this.props.clickedMarkerIndex===this.props.index){
        selectedBody="SelectedBody"
      }   
      //handle mobile map body      
      if (isMobile && this.props.viewType==="Map"){
          bodyClass = "MobileMapBody"
          contentClass = "MapContent"
          if (this.props.RTL){
            bodyClass = "MobileMapBodyRTL"
          }
          if (this.props.index===0){
            if (this.props.RTL){
              marginAlignmentClass = "FirstInMap"
            }
            else{
              marginAlignmentClass = "FirstInMap"
            }
          
          }
          if (this.props.index===this.props.listLenght){
            if (this.props.RTL){
              marginAlignmentClass = "LastInMap"
            }
            else{
              marginAlignmentClass = "LastInMap"
            }
          
          }
          return(
            <div key={this.props.index} className={[classes[bodyClass],classes[selectedBody],classes[marginAlignmentClass]].join(" ")}
                ref={this.state.placeRef}
                onClick={(e)=>{e.stopPropagation();this.viewHandler()}}>
                  {this.renderPlacePhotoSection()}
                  <div className={classes[contentClass]}>
                    {this.renderTopSection()}                       
                    {this.renderLocation()}  
                   {/*  {this.renderDuration()}  */}
                    {this.renderPlanMapTools()}        
                  </div>
            </div>
          )
      }
      else if (this.props.placeHolder){
        return (
          <div key={this.props.key} className={[classes[bodyClass],classes.PlaceHolder,classes[RTLClass]].join(" ")}/>
        );
      }
      else{
        return(
          <div key={this.props.placeID} className={[classes[bodyClass],classes[RTLClass],classes[selectedBody]].join(" ")}
              ref={this.state.placeRef}
              onMouseEnter={this.handleMouseOver} onMouseLeave={this.handleMouseExit}
              onClick={(e)=>{e.stopPropagation();this.viewHandler()}}>
                <TransparentBackdrop show={this.state.showBackdrop}  clicked={(e)=>{ e.stopPropagation();this.discard()}} />
                {this.renderConfirmModal()}
                {this.renderTools()}
                <div className={classes[toolsClass]}>
                  {this.renderFavoriteTools()}
                  {this.renderOptionsTools()}
                </div>
                {this.renderPlacePhotoSection()}
                <div className={classes[contentClass]}>
                  {this.renderTopSection()}   
                  {this.renderClosedMessage()}  
                              
                  {this.renderLocation()}     
                  {this.renderDuration()}
                </div>
          </div>
        )
      }
  }


     
}
export default Place;