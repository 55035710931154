import React, { Component } from "react";
import classes from "./PlanDiscover.module.css";

import Tab from "../../../Components/UI/Tabs/Tab"
import OptionSelection from "../../../Components/Complex/OptionSelection/OptionSelection"
import DiscoverSearchBar from "../../../Components/Complex/SearchBars/DiscoverSearchBar"
import SimpleSearchBar from "../../../Components/Complex/SearchBars/SimpleSearchBar"

import Tag from "../../../Components/UI/Tag/Tag"
import ShouldPlaceRender from "../../../Components/Functions/ShouldPlaceRender"
import PlanPlaceItem from "../../../Components/Complex/Plan/PlanPlaceItem"

import {isMobile} from 'react-device-detect';

let filterIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FFilterIconGrey.png?alt=media&token=d5e6c598-6ce3-4f95-b2dd-a611f8b0b442"
let filterIconApplied = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FFilterAppliedIcon.png?alt=media&token=81f47745-75ed-4919-9a84-4dfaefd24c29"
let mobileFilterIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMobileFilterIconGrey.png?alt=media&token=cc51e549-c739-484b-b429-f75321ecfdb5"
let mobileFilterIconApplied = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMobileFilterIcon.png?alt=media&token=c037918a-a1ad-40eb-a870-5a091751bf85"

let checkedBox = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCheckSquare.png?alt=media&token=25f70d60-d125-40b2-bfe6-953ed2d3c545"

var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);

class PlanDiscover extends Component {
    state={
        viewMode: this.props.selectedDiscoverSource,
        viewModeOptions: ["SelectedMobileWide", "RegularMobileWide"],

        gettingDiscoverData:false,gettingFavoriteData:false, currentDiscoverList:[],discoverCount:0,
        currentFavoriteList:[],favoriteCount:0,
        discoverPageNumber:0,
        favoritePageNumber:0,
        filteredDiscoverPlaces:[...this.props.filteredDiscoverPlaces],
        filteredFavoritePlaces:[...this.props.filteredFavoritePlaces],
    }

    componentDidMount = () =>{
        if (this.props.selectedDiscoverSource === 'discover'){
            this.props.updateDiscoverSource("discover")
            this.setState({  viewModeOptions: ["SelectedMobileWide", "RegularMobileWide"]})
        }
        else{
            this.props.updateDiscoverSource("favorites")
              this.setState({viewModeOptions: ["RegularMobileWide", "SelectedMobileWide"]})
        }
        if (isMobile){
            this.setState({maxAddedPlaces:20});
        }
        else{
            this.setState({maxAddedPlaces:30});
        }
        this.getMoreDiscoverData();
        this.getMoreFavoriteData();
        
    }

    componentDidUpdate= async prevProps=>{
        if (JSON.stringify(this.props.filteredDiscoverPlaces)!==JSON.stringify(prevProps.filteredDiscoverPlaces)){
          if (this.props.selectedDiscoverSource === 'discover'){
             this.discoverFiltersChangedHandler()
          }
        }
        if ( this.props.discoverPlacesNameFilterTag.length!==prevProps.discoverPlacesNameFilterTag.length){
          if (this.props.selectedDiscoverSource === 'discover'){
            this.discoverFiltersChangedHandler()
            
          }
        }
        if (this.props.discoverPlacesAdditionalTagFilterTag.length!==prevProps.discoverPlacesAdditionalTagFilterTag.length){
          
          if (this.props.selectedDiscoverSource === 'discover'){
            this.discoverFiltersChangedHandler()
          }
        }
        if (JSON.stringify(this.props.filteredFavoritePlaces)!==JSON.stringify(prevProps.filteredFavoritePlaces)){
           
            if (this.props.selectedDiscoverSource === 'favorites'){
              this.favoriteFiltersChangedHandler()
           }
        }
        if (JSON.stringify(this.props.discoverPlacesCharacteristicsFilter)!==JSON.stringify(prevProps.discoverPlacesCharacteristicsFilter)){
          if (this.props.selectedDiscoverSource === 'discover'){
            this.discoverFiltersChangedHandler()
         }
        }
        if (JSON.stringify(this.props.discoverPlacesDaysFilter)!==JSON.stringify(prevProps.discoverPlacesDaysFilter)){
          if (this.props.selectedDiscoverSource === 'discover'){
            this.discoverFiltersChangedHandler()
         }
           }
      }

    scrollToDiscoverTop() {
        var frame = document.getElementById("planDiscoverPlacesWall");
        frame.scrollTo({
          top: 0,
          behavior: "smooth",
          duration:1000
          
        });
        frame.scrollTop=0;
      }
  
  
      scrollToFavoriteTop() {
        var frame = document.getElementById("planFavoriteWall");
        frame.scrollTo({
          top: 0,
          behavior: "smooth",
          duration:1000
          
        });
        frame.scrollTop=0;
      }
  
      
  
      getMoreDiscoverData  = async () => {
          
          return new Promise(async (resolve, reject) => {
          if (this.state.currentDiscoverList.length === this.state.filteredDiscoverPlaces.length) {
              this.setState({gettingDiscoverData:false})
            return
          }
        
          let count=this.state.discoverCount;
          let addedObject=0;
          let maxAddedPlaces=0;
          if (isMobile){
            maxAddedPlaces=20;
          }
          else{
            maxAddedPlaces=30;
          }
          let currentList = this.state.currentDiscoverList;
          while (count<this.state.filteredDiscoverPlaces.length){
            let args = {
              place:this.state.filteredDiscoverPlaces[count],
              discoverPlacesNameFilterTag:this.props.discoverPlacesNameFilterTag,
              discoverPlacesAdditionalTagFilterTag: this.props.discoverPlacesAdditionalTagFilterTag,
              inputLanguage:this.props.inputLanguage,
              placeCharacteristicsFilters:this.props.discoverPlacesCharacteristicsFilter,
              discoverPlacesDaysFilter: this.props.discoverPlacesDaysFilter
            }
            await this.refs.shouldPlaceRender.shouldRender(args).then(result => {
              if (result){
                currentList.push(this.state.filteredDiscoverPlaces[count])
                addedObject=addedObject+1;
              }
              count=count+1;
            });
        
          
          }
          if (JSON.stringify(this.props.discoverPlacesDaysFilter)!==JSON.stringify([0,0,0,0,0,0,0])){
            let sortArgs ={
              discoverPlacesDaysFilter: this.props.discoverPlacesDaysFilter,
              currentList: currentList
            }
            await this.refs.shouldPlaceRender.sortByOpenDay(sortArgs).then(result => {
              if (result){ 
                currentList=result
               } 
             }); 
          }
          await this.setState({ discoverCount:count,currentDiscoverList:currentList,gettingDiscoverData:false})
          this.updateDiscoverPlacesOnMap()
        });
          
      }
  
        getMoreFavoriteData = async () => {
          return new Promise(async (resolve, reject) => {
              if (this.state.currentFavoriteList.length === this.state.filteredFavoritePlaces.length) {
                  this.setState({gettingFavoriteData:false})
                return
              }
              let count=this.state.favoriteCount;
            
              let addedObject=0;
              let maxAddedPlaces=0;
              if (isMobile){
                maxAddedPlaces=20;
              }
              else{
                maxAddedPlaces=30;
              }
              let currentList = this.state.currentFavoriteList;
              while (count<this.state.filteredFavoritePlaces.length){
                    currentList.push(this.state.filteredFavoritePlaces[count])
                    addedObject=addedObject+1;
                  count=count+1;
              }
              await this.setState({ favoriteCount:count,currentFavoriteList:currentList,gettingFavoriteData:false})
              this.updateDiscoverPlacesOnMap()
            });
  
        }

        discoverFiltersChangedHandler = async () =>{
            await this.setState({ currentDiscoverList:[],discoverCount:0,discoverPageNumber:0})
            while (!this.state.gettingDiscoverData){
                await this.setState({gettingDiscoverData:true})
                await setTimeout(() => {
                    this.setState({discoverCount:0,currentDiscoverList:[], discoverPageNumber:0,})
                    this.setState({filteredDiscoverPlaces:[...this.props.filteredDiscoverPlaces]})
                    this.getMoreDiscoverData();
                }, 300)
            }
          }
        
          favoriteFiltersChangedHandler = async () =>{
            await this.setState({ currentFavoriteList:[],favoriteCount:0,favoritePageNumber:0})
              while (!this.state.gettingFavoriteData){
                await this.setState({gettingFavoriteData:true})
                await setTimeout(() => {
                    this.setState({favoriteCount:0,currentFavoriteList:[], favoritePageNumber:0,})
                    this.setState({filteredFavoritePlaces:[...this.props.filteredFavoritePlaces]})
                    this.getMoreFavoriteData();
                }, 300)
            }
          }
    
  nextDiscoverPageHandler = async () =>{
    await this.setState({discoverPageNumber:this.state.discoverPageNumber+1})
    this.scrollToDiscoverTop()
    this.updateDiscoverPlacesOnMap()
    }
    
  previousDiscoverPageHandler = async () =>{
    await this.setState({discoverPageNumber:this.state.discoverPageNumber-1})
    this.scrollToDiscoverTop()
    this.updateDiscoverPlacesOnMap()
     }

    updateDiscoverPlacesOnMap = () =>{
      if (this.props.showDiscoverPlacesOnMap){
        let placesList = []
        if (this.props.selectedDiscoverSource==="discover"){
          placesList=this.state.currentDiscoverList.slice(this.state.discoverPageNumber*this.state.maxAddedPlaces,Math.min((this.state.discoverPageNumber+1)*this.state.maxAddedPlaces,this.state.currentDiscoverList.length))
        }
        else{
          placesList = this.state.currentFavoriteList.slice(this.state.favoritePageNumber*this.state.maxAddedPlaces,Math.min((this.state.favoritePageNumber+1)*this.state.maxAddedPlaces,this.state.currentFavoriteList.length))
        }
        this.props.showDiscoverPlacesOnMapHandler(true,placesList )
      }
    }

     nextFavoritePageHandler = async () =>{
      await this.setState({favoritePageNumber:this.state.favoritePageNumber+1})
      this.scrollToFavoriteTop()
      this.updateDiscoverPlacesOnMap()
      }
    
    previousFavoritePageHandler = async () =>{
      await this.setState({favoritePageNumber:this.state.favoritePageNumber-1})
      this.scrollToFavoriteTop()
      this.updateDiscoverPlacesOnMap()
       }

    

    renderDiscoverSource = () =>{
        return(
            <div className={classes.DiscoverSource}>
                 <Tab
                tabType={this.state.viewModeOptions[0]}
                text={this.props.systemText.plan.viewPlan.discoverBoard.discoverSource[this.props.userLanguage]}
                clicked={() => {
                  this.setState({
                    viewMode: "discover",
                    viewModeOptions: ["SelectedMobileWide", "RegularMobileWide"],
                  });
                  this.props.updateDiscoverSource("discover")
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_PLANNING_DISCOVER_DISCOVER_BUTTON",
                    userScreen:"VIEW_TRIP"
                  })
                }}
              />
               <div className={classes.MobileSeperator}/>
                <Tab
                tabType={this.state.viewModeOptions[1]}
                text={this.props.systemText.plan.viewPlan.discoverBoard.favoritesSource[this.props.userLanguage]}
                clicked={() => {
                  this.setState({
                    viewMode: "favorites",
                    viewModeOptions: ["RegularMobileWide", "SelectedMobileWide"],
                  });
                  this.props.updateDiscoverSource("favorites")
                  this.props.loadFavoritePlacesForPlanning()
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_PLANNING_DISCOVER_FAVORITE_BUTTON",
                    userScreen:"VIEW_TRIP"
                  })
                }}
              />  
            </div>
        )
    }

    renderFilterIcon = () =>{
        if (this.state.viewMode==="discover"&&this.props.countrySelected){
          let mobileFilterButtonClass = "MobileFiltersButton"
          if (this.props.RTL){
              mobileFilterButtonClass = "MobileFiltersButtonRTL"
          }
          let iconClass = "FilterIcon"
          if (this.props.RTL){
              iconClass = "FilterIconRTL"
          }
          let filterApplied = false
          if (this.props.discoverPlacesLocationFilterTags.length>1||
          this.props.discoverPlacesCategoriesFilterTags.length>0||
          this.props.discoverPlacesSelectedMainCategory[0]!==1||
          this.props.discoverPlacesNameFilterTag.length>0||
          this.props.discoverPlacesAdditionalTagFilterTag.length >0 ||
          Object.keys(this.props.discoverPlacesCharacteristicsFilter).length>0||
          JSON.stringify(this.props.discoverPlacesDaysFilter)!==JSON.stringify([0,0,0,0,0,0,0])){
              filterApplied = true
          }
          if (isMobile){
            return(
              <div className={classes[mobileFilterButtonClass]}>
                {filterApplied?          
                (
                  <img src={mobileFilterIconApplied} alt=""
                  className={classes[iconClass]}
                  onClick={()=>{
                  this.props.showFilters(true)
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_PLANNING_DISCOVER_FILTER_OPEN",
                    userScreen:"VIEW_TRIP"
                  })
                  }}/>
                  )
                  :
                  (
                    <img src={mobileFilterIcon} alt=""
                    className={classes[iconClass]} 
                    onClick={()=>{
                    this.props.showFilters(true)
                    this.props.writeAcitivityLog({
                      activityKey:"TRIP_PLANNING_DISCOVER_FILTER_OPEN",
                      userScreen:"VIEW_TRIP"
                    })
                    }}/>
                  )}
              </div>
            )
          }
          else{
            if (filterApplied){
                return(
                    <div className={classes.FiltersSelected} 
                      onClick={()=>{
                        this.props.showFilters(true)
                      this.props.writeAcitivityLog({
                        activityKey:"TRIP_PLANNING_DISCOVER_FILTER_OPEN",
                        userScreen:"VIEW_TRIP"
                      })
                      }}>
                    <img src={mobileFilterIconApplied} alt="" className={classes[iconClass]}/>
                  {/*   {this.props.systemText.discover.searchSection.openFilter[this.props.userLanguage]} */}
                </div>
                )
            }
            else{
                return(
                    <div className={classes.Filters} 
                    onClick={()=>{
                      this.props.showFilters(true)
                      this.props.writeAcitivityLog({
                        activityKey:"TRIP_PLANNING_DISCOVER_FILTER_OPEN",
                        userScreen:"VIEW_TRIP"
                      })
                    }}>
                    <img src={mobileFilterIcon} alt="" className={classes[iconClass]}/>
                   {/*  {this.props.systemText.discover.searchSection.openFilter[this.props.userLanguage]} */}
                </div>
                )
            }
          }
      }
    }

    renderDiscoverTopSection = () =>{
        return(<div className={classes.TopSection}>
            {this.renderDiscoverSource()}
            <div className={classes.FiltersSection}>
               
               
            </div>
        </div>)
    }

    renderContent = () =>{
        switch(this.state.viewMode){
            case "discover":{
                return(
                    <div>
                        {this.renderDiscoverPlacesView()}
                    </div>
                )
            }
            case "favorites":{
                return(
                    <div>
                        {this.renderFavoritePlacesView()}
                    </div>
                )
            }
            default: return null
        }
    }

    renderDiscoverPlacesView = () =>{
        if (!this.props.countrySelected){
            let countriesList = [];
            for (let i=0;i<this.props.countriesToExplore.length ; i++){
                let country = JSON.parse(JSON.stringify(this.props.locationsTree[this.props.countriesToExplore[i]]))
                 delete country.regions;
                delete country.cities; 
                countriesList.push(country)
            }
            return(
                <div className={classes.DiscoverBody}>
                    <div className={classes.Message}>
                        {this.props.systemText.extraModals.setLocation.setLocationTree.selectCountry[this.props.userLanguage]}
                    </div>
                    <div className={classes.CountrySelection}>
                        <OptionSelection
                            currentSelection={""}
                            optionsList={countriesList}
                            updateSelectedOption={this.props.updateSelectedCountry}
                            placeholder={""}
                            bold={true}
                            valueRequired={true}
                            onlyFromList={true}
                            userLanguage={this.props.userLanguage}
                            RTL = {this.props.RTL}
                            inputLanguage = {this.props.inputLanguage}
                            detectInputLanguage = {this.props.detectInputLanguage}
                     /> 
                    </div>
                </div>
            )
        }
        else{
            let searchTags = []
            if (!this.props.onlyMapAreaResults){
              searchTags = this.props.discoverPlacesLocationsSearchTags;
            }
            searchTags = searchTags.concat(this.props.discoverPlacesAvailableCategories.filter(tag=>!this.props.discoverPlacesCategoriesFilterTags.map(tag=>{return tag.tag.id}).includes(tag)).map(tag=>{return this.props.categoriesTranslator[tag]})).filter(tag=>tag!==undefined)         
            searchTags = searchTags.concat(this.props.discoverPlacesNameSearchTags)
            searchTags = searchTags.concat(this.props.discoverPlacesAdditionalTagsSearchTags)
            return(
            <div className={classes.DiscoverBody}>
                <div className={classes.SearchBar}>
                    <DiscoverSearchBar
                        searchTags = {searchTags}
                        placeholderMessage = { this.props.systemText.discover.searchSection.searchBarPlaceholder[this.props.userLanguage]}
                        detectLanguage = {this.props.detectInputLanguage}
                        inputLanguage = {this.props.inputLanguage}
                        RTL = {this.props.RTL}
                        updateFilterTags = {this.props.searchDiscoverLocationHandler}
                        noSearchResultsHandler={this.props.noSearchResultsHandler}
                        locationsTranslator = {this.props.locationsTranslator}

                    />
                     {this.renderFilterIcon()}
                </div>
                {this.renderMapOnlyButton()}
                <div className={classes.Results} id="planDiscoverPlacesWall">
                {this.state.currentDiscoverList && this.state.currentDiscoverList.slice(this.state.discoverPageNumber*this.state.maxAddedPlaces,Math.min((this.state.discoverPageNumber+1)*this.state.maxAddedPlaces,this.state.currentDiscoverList.length)).map((place, index) =>(
                    <PlanPlaceItem
                        key={index}
                        wideList={this.props.wideList}
                        placeName={place.placeName}
                        placeID={place.placeID}
                        placeCategory={place.placeCategory}
                        placeLocation = {place.placeLocation}
                        placeCharacteristics={place.placeCharacteristics}
                        imagePreview={place.imagePreview}
                        placeStatus = {place.placeStatus}
                        planPlacesIDs={this.props.planPlacesIDs}
                        dayNames = {this.props.planDays.map(day=>{return day.name})}
                        addPlaceToPlan={()=>{this.props.addPlaceToPlan(place)}}
                        hoverHandler={(placeIndex)=>{ this.props.hoverDiscover(placeIndex,place)}}
                        board={"Discover"}
                        clickedMarkerPlaceID={this.props.additionalMarkerClicked}

                        categoriesTranslator = {this.props.categoriesTranslator}
                        locationsTranslator = {this.props.locationsTranslator}
                        
                        viewHandler={()=>{this.props.viewHandler(place)}}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                        RTL = {this.props.RTL}
                        inputLanguage={this.props.inputLanguage}
                    />
                    ) )}
                    {this.state.gettingDiscoverData&&this.state.discoverCount !== this.props.filteredDiscoverPlaces.length?(
                        <div className={classes.GettingData}>{"Loading..."}</div>
                        ):null}
                        <div className={classes.NextBackButtons}>
                        {this.state.discoverPageNumber>0?(
                        <div className={classes.PagesButton} onClick={this.previousDiscoverPageHandler}>
                        {this.props.systemText.misc.navigation.pagesNavigation.previous[this.props.userLanguage]}
                        </div>
                        ):(
                        <div className={classes.PagesButtonGreyed}>
                         {this.props.systemText.misc.navigation.pagesNavigation.previous[this.props.userLanguage]}
                        </div>
                        )}
                        {((this.state.discoverPageNumber+1)*this.state.maxAddedPlaces)<this.state.currentDiscoverList.length?(
                        <div className={classes.PagesButton} onClick={this.nextDiscoverPageHandler}>
                         {this.props.systemText.misc.navigation.pagesNavigation.next[this.props.userLanguage]}
                        </div>
                        ):(
                        <div className={classes.PagesButtonGreyed}>
                         {this.props.systemText.misc.navigation.pagesNavigation.next[this.props.userLanguage]}
                        </div>
                        )}
                    </div>
                </div>  
            </div>
        )
        }
    }

    renderFavoritePlacesView = () =>{
        let searchTags = this.props.favoritePlacesSearchTags;
        let placeHolderMessage = this.props.systemText.favorite.searchSection.searchBarPlacesPlaceholder[this.props.userLanguage];
        return(
            <div className={classes.DiscoverBody}>
                {/* {!this.props.onlyMapAreaResults?( */}
                    <div className={classes.SearchBar}>
                      <SimpleSearchBar
                          searchTags = {searchTags}
                          placeholderMessage = {placeHolderMessage}
                          detectLanguage = {this.props.detectInputLanguage}
                          inputLanguage = {this.props.inputLanguage}
                          RTL = {this.props.RTL}
                          systemText = {this.props.systemText}
                          userLanguage = {this.props.userLanguage}
                          updateFilterTags = {this.props.favoriteSearchHandler}
                          locationsTranslator = {this.props.locationsTranslator}
                      />
                  </div>{/* 
                ):null} */}
                  
                <div className={classes.TagsList}>
                {this.props.favoritePlacesFilterTags.map((tag, index) => (
                        <Tag
                        key={index}
                        text={tag.tag[tag.language]}
                        removable={true}
                        style={"FavoritePreviewTag"}
                        remove={() => {this.props.favoriteRemoveTagHandler(index)}}
                        RTL = {this.props.RTL}
                    />
                ))} 
            
                </div>
                {this.renderMapOnlyButton()} 
                {this.props.noResultsFavoritePlaces?(
                    <div className={classes.Results}>
                         <div className={classes.NoContentMessage}>
                          {this.props.systemText.favorite.noContentMessage.noPlacesResults[this.props.userLanguage]}
                        </div>
                    </div>
                ):(
                  <div className={classes.Results} id="planFavoriteWall">
                {this.state.currentFavoriteList && this.state.currentFavoriteList.slice(this.state.favoritePageNumber*this.state.maxAddedPlaces,Math.min((this.state.favoritePageNumber+1)*this.state.maxAddedPlaces,this.state.currentFavoriteList.length)).map((place, index) =>(
                    <PlanPlaceItem
                    key={index}
                    wideList={this.props.wideList}
                    placeName={place.placeName}
                    placeID={place.placeID}
                    placeCategory={place.placeCategory}
                    placeLocation = {place.placeLocation}
                    placeCharacteristics={place.placeCharacteristics}
                    imagePreview={place.imagePreview}
                    placeStatus = {place.placeStatus}
                    planPlacesIDs={this.props.planPlacesIDs}
                    dayNames = {this.props.planDays.map(day=>{return day.name})}
                    addPlaceToPlan={(dayIndex)=>{this.props.addPlaceToPlan(dayIndex,place)}}
                    clickedMarkerPlaceID={this.props.clickedMarkerPlaceID}
                    removeFromPlan={this.props.removePlaceFromPlan}
                    hoverHandler={(placeIndex)=>{ this.props.hoverDiscover(placeIndex,place)}}
                    board={"Discover"}

                    clickedMarkerPlaceID={this.props.additionalMarkerClicked}

                    categoriesTranslator = {this.props.categoriesTranslator}
                    locationsTranslator = {this.props.locationsTranslator}
                    
                    viewHandler={()=>{this.props.viewHandler(place)}}
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}
                    inputLanguage={this.props.inputLanguage}
                    />
                    ) )}
                    {this.state.gettingFavoriteData&&this.state.favoriteCount !== this.props.filteredFavoritePlaces.length?(
                    <div className={classes.GettingData}>{"Loading..."}</div>
                    ):null}
                    <div className={classes.NextBackButtons}>
                    {this.state.favoritePageNumber>0?(
                    <div className={classes.PagesButton} onClick={this.previousFavoritePageHandler}>
                    {this.props.systemText.misc.navigation.pagesNavigation.previous[this.props.userLanguage]}
                    </div>
                    ):(
                    <div className={classes.PagesButtonGreyed}>
                        {this.props.systemText.misc.navigation.pagesNavigation.previous[this.props.userLanguage]}
                    </div>
                    )}
                    {((this.state.favoritePageNumber+1)*this.state.maxAddedPlaces)<this.state.filteredFavoritePlaces.length?(
                    <div className={classes.PagesButton} onClick={this.nextFavoritePageHandler}>
                        {this.props.systemText.misc.navigation.pagesNavigation.next[this.props.userLanguage]}
                    </div>
                    ):(
                    <div className={classes.PagesButtonGreyed}>
                        {this.props.systemText.misc.navigation.pagesNavigation.next[this.props.userLanguage]}
                    </div>
                    )}
                </div>
              </div>  
              )}
                
            
        </div>
        )
    }

    renderMapOnlyButton = () =>{
      let checkIconClass = "CheckIcon"
      let uncheckBoxClass = "UncheckBox"
      if (this.props.RTL){
        checkIconClass = "CheckIconRTL"
        uncheckBoxClass = "UncheckBoxRTL"
      }
      if (this.props.mapSelected||isMobile){
        if (this.props.onlyMapAreaResults){
              return(
                <div className={classes.DiscoverMapTools}>
                     <div className={classes.MapBoundariesSelection}>
                        <img src={checkedBox} alt="" className={classes[checkIconClass]} 
                        onClick={(e)=>{ e.stopPropagation();this.props.onlyMapResultsHandler(false)
                          this.props.writeAcitivityLog({
                            activityKey:"TRIP_PLANNING_DISCOVER_MAP_BOUNDARIES_UNSELECT",
                            userScreen:"VIEW_TRIP"
                          })
                        }}/>
                        {this.props.systemText.discover.filters.onlyMapArea[this.props.userLanguage]}
                    </div>
                   {this.showDesktopDiscoverPlacesOnMap()}
                </div>
               
              )          
          }
          else{
              return(
                <div className={classes.MapBoundariesSelection}>
                    <div className={classes[uncheckBoxClass]} 
                    onClick={(e)=>{ e.stopPropagation();this.props.onlyMapResultsHandler(true)
                      this.props.writeAcitivityLog({
                        activityKey:"TRIP_PLANNING_DISCOVER_MAP_BOUNDARIES_SELECT",
                        userScreen:"VIEW_TRIP"
                      })
                    }}/>
                    {this.props.systemText.discover.filters.onlyMapArea[this.props.userLanguage]}
                </div>
                  
              )     
          }
      }
    }

    showDesktopDiscoverPlacesOnMap = () =>{
      let checkIconClass = "CheckIcon"
      let uncheckBoxClass = "UncheckBox"
      if (this.props.RTL){
        checkIconClass = "CheckIconRTL"
        uncheckBoxClass = "UncheckBoxRTL"
      }
      if (this.props.mapSelected||(isMobile&&this.props.onlyMapAreaResults)){
        if (this.props.showDiscoverPlacesOnMap){
              return(
                <div className={classes.MapBoundariesSelection}>
                  <img src={checkedBox} alt="" className={classes[checkIconClass]} 
                  onClick={(e)=>{ e.stopPropagation();this.props.showDiscoverPlacesOnMapHandler(false)
                    this.props.writeAcitivityLog({
                      activityKey:"TRIP_PLANNING_DISCOVER_SHOW_ON_MAP_UNSELECT",
                      userScreen:"VIEW_TRIP"
                    })
                  }}/>
                  {this.props.systemText.discover.filters.showOnMap[this.props.userLanguage]}
               </div>
              )          
          }
          else{
              return(
                <div className={classes.MapBoundariesSelection}>
                    <div className={classes[uncheckBoxClass]} 
                    onClick={(e)=>{ e.stopPropagation();this.props.showDiscoverPlacesOnMapHandler(true,
                      this.state.currentDiscoverList.slice(this.state.discoverPageNumber*this.state.maxAddedPlaces,Math.min((this.state.discoverPageNumber+1)*this.state.maxAddedPlaces,this.state.currentDiscoverList.length)))
                      this.props.writeAcitivityLog({
                        activityKey:"TRIP_PLANNING_DISCOVER_SHOW_ON_MAP_SELECT",
                        userScreen:"VIEW_TRIP"
                      })
                    }}/>
                     {this.props.systemText.discover.filters.showOnMap[this.props.userLanguage]}
                </div>
                  
              )     
          }
      }
      
    }


    render(){
        return(<div className={classes.Body}>
             <ShouldPlaceRender
                ref={"shouldPlaceRender"}
            />
            {this.renderDiscoverTopSection()}
            {this.renderContent()}
        </div>)
    }
}

export default PlanDiscover;