import React, { Component } from "react";
import classes from "./MarkerObject.module.css";

import {
  Marker,
  
} from "react-google-maps";


let redMarker ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FRedMarker.png?alt=media&token=119b65f8-542e-4f0c-b90c-907d5c8b8a88";
let yellowMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FYellowMarker.png?alt=media&token=04f5ab71-cf8c-441b-9d04-fd3d81fcd429";
let greenMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FGreenMarker.png?alt=media&token=2da8f89d-0b73-4e5b-be47-b83a68cee98d";
let blueMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FBlueMarker.png?alt=media&token=dc67767f-a9e5-4a81-b6bb-40c0a538a3cd";
let purpleMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FPurpleMarker.png?alt=media&token=4eea73a8-8366-429a-bd3c-1257ce12aacc";
let greyMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FGreyMarker.png?alt=media&token=9ece10b5-dcc9-4a1b-b494-aec21bbd1d26";
let orangeMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FOrangeMarker.png?alt=media&token=e04ec3b2-276f-4c25-8238-a28619ff2c72"
let highlightMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FHighlightMarker.png?alt=media&token=ae87ca89-4f97-45fd-b4bc-caefb77adab8";
let aLocation = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FALocation.png?alt=media&token=4cc19333-d867-4a65-9482-a71209c43c30";
let bLocation = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FBLocation.png?alt=media&token=263964bb-11fc-4367-8589-5c30b5c40c2e";
let parkingMarker = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FParkingIcon.png?alt=media&token=5a49b7f7-e3aa-4699-8156-774538d0be5b";
let currentLocation = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FMarkers%2FCurrentLocation.png?alt=media&token=801c91a2-dc29-4213-8184-b5bbbce00d63";

let sightseeingIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSightSeeingIcon.png?alt=media&token=3b7f3202-493e-4f2e-981f-47944e78c5a4";
let foodIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFoodIcon.png?alt=media&token=f9f81121-4c3e-4290-8b3e-94fee05d149a";
let natureIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FNatureIcon.png?alt=media&token=69ef82b9-4af1-44e2-b713-a240fba4022e";
let sportIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSportIcon.png?alt=media&token=3d936de1-0f2b-40de-9313-69b90977602d";
let accomodationIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIcon.png?alt=media&token=3f3fbed6-2d65-4896-a2d0-6036f7e1ac5b";
let activitiesIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FActivityIcon.png?alt=media&token=e46ab679-3b01-4172-9efc-1f528c9f76a7";
let sightseeingIconHighlighted = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSightSeeingIconHover.png?alt=media&token=724f435d-41e4-4cda-8531-406ae8ab02b0";
let foodIconHighlighted = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FFoodIconHover.png?alt=media&token=3a7fd630-7875-4ccc-ac76-71fb988fa954";
let natureIconHighlighted = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FNatureIconHover.png?alt=media&token=8b2428a1-727a-4273-93ef-fe6070f2c543";
let sportIconHighlighted = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FSportIconHover.png?alt=media&token=b95d26da-ef72-4ba3-b04f-bca1f5774411";
let accomodationIcoHighlightedn = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FAccomodationIconHover.png?alt=media&token=c983e85b-b4a4-4432-b9a0-bd9b7c78aa84";
let activitiesIconHighlighted = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FActivityIconHover.png?alt=media&token=bba92f28-ae1f-4f22-9ce6-01e8d732aa61";




const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");


class MarkerObject extends Component {
  state = {
    isOpen: false,
    showColorPalete: false,
    showPlanMoveOptions: false,
    showSaveWindow: false,
    hoverIndex:this.props.hoverIndex,
  };


   componentDidUpdate = prevProps => {
    if (this.props.showMarkers!==prevProps.showMarkers){
    }
    if (this.props.hoverIndex!==prevProps.hoverIndex){
     this.setState({hoverIndex:this.props.hoverIndex,})
    }
  };


  renderMarker = () => {
    if (this.props.isAccommodation){
      return <div>{this.renderColoredMarker(accomodationIcon)}</div>;
    }
    else if (this.props.selectedPlace){
      return <div>{this.renderColoredMarker(blueMarker)}</div>;
    }
    else{
      if (this.props.board==="Discover"){
        if (this.props.placeColorNumber !== undefined) {
          if (this.props.placeColorNumber==="CurrentLocation"){
            return <div>{this.renderCurrentLocation()}</div>;
          }
        } 
        if (this.props.showSpecialIcon){
          return <div>{this.renderIconMarker()}</div>;
        }
        
        else{
           if (this.props.hoverIndex===this.props.index){
            return <div >{this.renderColoredMarker(highlightMarker,true)}</div>;
          } 
          else{
            return <div>{this.renderColoredMarker(redMarker)}</div>;
          }
        }
      }
      else if (this.props.board==="Plan"){
        if (this.props.hoverIndex===this.props.placeID){
          return <div >{this.renderColoredMarker(highlightMarker,true)}</div>;
        } 
        else if (this.props.placeColorNumber !== undefined) {
          return <div>{this.renderColorIcons()}</div>;
        } 
        else{
          return <div>{this.renderColoredMarker(redMarker)}</div>;
        }
      }
      else{
        if (this.props.isParking){
          return <div>{this.renderColoredMarker(parkingMarker)}</div>;
        }else{
        return <div>{this.renderColoredMarker(redMarker)}</div>;}
      }
    }
   
    
  };

  renderIconMarker = () =>{
    if (this.props.mainCategory[0]===1){
      return <div>{this.renderIcondMarker(sightseeingIconHighlighted,sightseeingIcon)}</div>;
    }
    else if (this.props.mainCategory[1]===1){
      return <div>{this.renderIcondMarker(natureIconHighlighted,natureIcon)}</div>;
    }
    else if (this.props.mainCategory[2]===1){
      return <div>{this.renderIcondMarker(activitiesIconHighlighted,activitiesIcon)}</div>;
    }
    else if (this.props.mainCategory[3]===1){
      return <div>{this.renderIcondMarker(sportIconHighlighted,sportIcon)}</div>;
    }
    else if (this.props.mainCategory[4]===1){
      return <div>{this.renderIcondMarker(foodIconHighlighted,foodIcon)}</div>;
    }
    else if (this.props.mainCategory[5]===1){
      return <div>{this.renderIcondMarker(accomodationIcoHighlightedn,accomodationIcon)}</div>;
    }
  }

  renderColorIcons = () => {

      switch (this.props.placeColorNumber) {
        case 0: {
          return <div>{this.renderColoredMarker(redMarker)}</div>;
          break;
        }
        case 1: {
          return <div>{this.renderColoredMarker(blueMarker)}</div>;
          break;
        }
        case 2: {
          return <div>{this.renderColoredMarker(greenMarker)}</div>;
          break;
        }
        case 3: {
          return <div>{this.renderColoredMarker(yellowMarker)}</div>;
          break;
        }
        case 4: {
          return <div>{this.renderColoredMarker(purpleMarker)}</div>;
          break;
        }
        case 5: {
          return <div>{this.renderColoredMarker(greyMarker)}</div>;
          break;
        }
        case 'A':{
          return <div>{this.renderColoredMarker(aLocation)}</div>;
          break;
        }
        case 'B':{
          return <div>{this.renderColoredMarker(bLocation)}</div>;
          break;
        }
        default:
          return null;
      }
    
  };

  handleMouseOver=()=>{
    //this.props.markerHoverHandler(this.props.index);
  }

  handleMouseExit=()=>{
    //this.props.markerHoverHandler(-1);
  }

  handleClick = () =>{
    if (this.props.isClickable) {
      if (this.props.board==="Discover"||this.props.board==="TripAlgorithm"){
        this.props.markerClicked(this.props.placeID,this.props.index);
      }
      if (this.props.board==="Plan"){
        this.props.markerClicked(this.props.placeID,this.props.index);
      }
    }
  }

  renderCurrentLocation = () =>{
    return(<Marker
     position={{
       lat: this.props.lat,
       lng: this.props.lng
     }}
     icon={{
       url: currentLocation,
       scaledSize: new window.google.maps.Size(15, 15)
     }}
     labelAnchor={new window.google.maps.Point(12, 45)}
     labelStyle={{backgroundColor: "white", width:"14px",height:"14px",fontSize: "1.2em",  textAlign: "center",fontWeight:"500", padding: "5px",borderRadius:"50%"}}
     />)
  }

  renderIcondMarker = (iconUrlHover, iconUrl) =>{
    if (this.state.hoverIndex===this.props.index){
        return(
          <div className={classes.MarkerWrapper}
          style={{zIndex: 2}}
          >
           
            <Marker
           onClick={()=>{this.handleClick()}}
            position={{
              lat: this.props.lat,
              lng: this.props.lng
            }}
            icon={{
              url: iconUrlHover,
              scaledSize: new window.google.maps.Size(40, 40),
              
            }}
            zIndex={100}
            labelStyle={{position:"absolute",zIndex:"200"}}
            labelAnchor={new window.google.maps.Point(12, 45)}
            />
             </div>)      
    }
    else{
      return(
        <div className={classes.MarkerWrapper}
        >
          <Marker
         onClick={()=>{this.handleClick()}}
          position={{
            lat: this.props.lat,
            lng: this.props.lng
          }}
          icon={{
            url: iconUrl,
            scaledSize: new window.google.maps.Size(30, 30)
          }}
          zIndex={10}
          labelAnchor={new window.google.maps.Point(12, 45)}
          labelStyle={{backgroundColor: "white", width:"34px",height:"14px",fontSize: "1.2em",  textAlign: "center",fontWeight:"500", padding: "5px",borderRadius:"50%",zIndex:"10"}}
          />
           </div>)
    }
   
  }

  renderColoredMarker = (iconUrl,highlight) => {
    if (highlight){
      return(
        <div className={classes.MarkerWrapperHighlight}
        style={{zIndex: 2}}>
        <Marker
        icon={{
          url: iconUrl,
          scaledSize: new window.google.maps.Size(45, 45),
        }}
        zIndex={100}
        position={{
          lat: this.props.lat,
          lng: this.props.lng
        }}
        labelStyle={{position:"relative",zIndex:"200"}}
      />
      </div>
      )
    }
    else if (this.props.showOrder && this.props.ideaMarker===undefined) {
      return(
        <div className={classes.MarkerWrapper}>
          <MarkerWithLabel
          onClick={this.handleClick}
          position={{
            lat: this.props.lat,
            lng: this.props.lng
          }}
          zIndex={20}
          icon={{
            url: iconUrl,
            scaledSize: new window.google.maps.Size(35, 35)
          }}
          labelAnchor={new window.google.maps.Point(12, 38)}
          labelStyle={{backgroundColor: "white", width:"14px",height:"14px",fontSize: "1.2em",  textAlign: "center",fontWeight:"500", padding: "5px",borderRadius:"50%"}}
        >
          <div style={{ zIndex:"20"}}>{this.props.index+1-this.props.ideasOnMapCount}</div>
    </MarkerWithLabel></div>)
    } else if (this.props.creationBoard){
      return(
        <div className={classes.MarkerWrapper}
        >
          <Marker
         onClick={()=>{this.handleClick()}}
          position={{
            lat: this.props.lat,
            lng: this.props.lng
          }}
          icon={{
            url: iconUrl,
            scaledSize: new window.google.maps.Size(45, 45)
          }}
          zIndex={10}
          labelAnchor={new window.google.maps.Point(12, 45)}
          labelStyle={{backgroundColor: "white", width:"34px",height:"14px",fontSize: "1.2em",  textAlign: "center",fontWeight:"500", padding: "5px",borderRadius:"50%"}}
          />
           </div>)
    }
    else{
      return(
        <div className={classes.MarkerWrapper}
        >
          <Marker
         onClick={()=>{this.handleClick()}}
          position={{
            lat: this.props.lat,
            lng: this.props.lng
          }}
          icon={{
            url: iconUrl,
            scaledSize: new window.google.maps.Size(35, 35)
          }}
          labelAnchor={new window.google.maps.Point(12, 45)}
          labelStyle={{backgroundColor: "white", width:"34px",height:"14px",fontSize: "1.2em",  textAlign: "center",fontWeight:"500", padding: "5px",borderRadius:"50%",zIndex:"10"}}
          />
           </div>)
    }
    
  };

  render() {
    return (
      <div>
        {this.renderMarker()}
      </div>
    );
  }
}

export default MarkerObject;
