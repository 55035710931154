

import React, { Component } from "react";

import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';

import 'firebase/auth';

/* import * as admin from 'firebase-admin' */

import firebaseConfig from "../Firebase"

import DetectInputLanguage from "../Components/Functions/DetectInputLanguage"

/* var refreshToken
if (!admin.apps.length) {
  admin.initializeApp(
    { credential: admin.credential.applicationDefault(),
  databaseURL: 'https://mindu-a42b7.firebaseio.com'}
  );
}else {
  admin.app(); // if already initialized, use that one
} */


const today = new Date();

class DBScripts extends Component {


  removeOldAnonymousUsers = async () =>{
    this.listAllUsers()
  }

  listAllUsers = (nextPageToken) => {
    // List batch of users, 1000 at a time.
  /*   admin
      .auth()
      .listUsers(1000, nextPageToken)
      .then((listUsersResult) => {
        listUsersResult.users.forEach((userRecord) => {
          console.log('user', userRecord.toJSON());
        });
        if (listUsersResult.pageToken) {
          // List next batch of users.
          this.listAllUsers(listUsersResult.pageToken);
        }
      })
      .catch((error) => {
        console.log('Error listing users:', error);
      }); */
  };

  updatePlaceGoogleRating = async () =>{
    let systemPlaces;
    let countriesList = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesList=snapshot.val();
      }
    });
    for (let i = 0 ; i<countriesList.length ; i++){
        await firebase.database()
        .ref(this.props.environment + "/systemData/places/"+countriesList[i])
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            systemPlaces=Object.values(snapshot.val());
          }
        });
        for (let j=0 ; j<systemPlaces.length ; j++){
          let place = systemPlaces[j];
          if (place.placeLocation.placeID!==undefined){
            setTimeout(() => {
              this.getPlaceRating(place,countriesList[i])
           }, j*1000);
          }
        }
    }
  }

  getPlaceRating = async (place,country) =>{
    //console.log(place)
    let savePlace = place
    const request = {
      placeId: place.placeLocation.placeID,
      fields: ["rating"],
    };
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    await service.getDetails(request, (googlePlace, status) => {
      if (googlePlace!==null){
        if (googlePlace.rating!==undefined){
          //console.log(googlePlace.rating)
          savePlace.placeGoogleRating = googlePlace.rating
        }
        console.log(savePlace)
        firebase.database()
        .ref(this.props.environment + "/systemData/places/"+country+"/"+savePlace.placeID)
        .set(savePlace); 
      }  
    })
    
  }
/* 
  addToWineryActivityCategory = async () =>{
    let systemPlaces;
    let countriesList = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesList=snapshot.val();
      }
    });
    for (let i = 0 ; i<countriesList.length ; i++){
        await firebase.database()
        .ref(this.props.environment + "/systemData/places/"+countriesList[i])
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            systemPlaces=Object.values(snapshot.val());
          }
        });
        let newPlaces = {};
        for (let j=0 ; j<systemPlaces.length ; j++){
  
          let place = systemPlaces[j]
          if (place.placeCategory!==undefined){
            if (place.placeCategory.includes("foodDrink_winery")){
              place.placeMainCategory[2]=1
            }
          }
         
          newPlaces[place.placeID]=place
            
        }
        console.log("finished")
         firebase.database()
        .ref(this.props.environment + "/systemData/places/"+countriesList[i])
        .set(newPlaces); 
     }
  } */

  

  updateUsersFollowingIDsList = async () =>{
    let allUsersIDs=[];
    await firebase.database()
    .ref( this.props.environment+"/usersData")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        allUsersIDs=Object.keys(snapshot.val());
      }
    });
    for (let i=0 ; i<allUsersIDs.length ; i++){
      let followingIDs = [];
      await firebase.database()
      .ref( this.props.environment+"/usersData/"+allUsersIDs[i]+"/userInfo/friends")
      .once("value")
      .then(snapshot => {
        snapshot.forEach(child => {
          if (child.val().status === "Friend") {
            followingIDs.push(child.key)
          }
        });
      });
      firebase.database()
    .ref(this.props.environment+"/usersData/"+allUsersIDs[i]+"/userInfo/followingUsersIDs")
    .set(followingIDs); 
    }

  }

  tempFindCulturalPark = async () =>{
    let systemPlaces;
    let countriesList = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesList=snapshot.val();
      }
    });
    for (let i = 0 ; i<countriesList.length ; i++){
        await firebase.database()
        .ref(this.props.environment + "/systemData/slimPlacesData/"+countriesList[i])
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            systemPlaces=Object.values(snapshot.val());
          }
        });
        for (let j=0 ; j<systemPlaces.length ; j++){
          let place = systemPlaces[j];
          if (place.placeCategory.includes("amusementArcade")){
           console.log(place)
          }
         
        }
    }
  }

  loadPlacesToFirestore = async () =>{
    let systemPlaces;
    let countriesList = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesList=snapshot.val();
      }
    });
    for (let i = 0 ; i<countriesList.length ; i++){
        await firebase.database()
        .ref(this.props.environment + "/systemData/slimPlacesData/"+countriesList[i])
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            systemPlaces=Object.values(snapshot.val());
          }
        });
        for (let j=0 ; j<systemPlaces.length ; j++){
          let place = systemPlaces[j];
          if (place.placeStatus==="approved"){
            await firebase.firestore().collection(this.props.environment+'systemPlaces').doc(countriesList[i]).collection('countryPlaces').doc(place.placeID).set(place);
          }
         
        }
    }
    console.log("finished")
  }

    createPlacesListForPlaceCreation = async () =>{
        let systemPlaces;
        let countriesList = [];
        await firebase.database()
        .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            countriesList=snapshot.val();
          }
        });
        for (let i = 0 ; i<countriesList.length ; i++){
            await firebase.database()
            .ref(this.props.environment + "/systemData/slimPlacesData/"+countriesList[i])
            .once("value")
            .then(snapshot => {
              if (snapshot.val()!==null){
                //covering every country
                systemPlaces=Object.values(snapshot.val());
              }
            });
            let slimPlaces = {};
            for (let j=0 ; j<systemPlaces.length ; j++){
              let place = systemPlaces[j];
              let newPlace ={
                placeID:place.placeID,
                placeName:place.placeName,
              }

              let placeLocation = {
                placeID:place.placeLocation.placeID,
                coordinates:place.placeLocation.coordinates
              }
              newPlace.placeLocation=placeLocation 
              slimPlaces[newPlace.placeID]=newPlace
            }
            firebase.database()
            .ref(this.props.environment + "/systemData/placeCreationData/placesListForPlaceCreation/"+countriesList[i])
            .set(slimPlaces);
        }
      
    }

    updateSystemTopCateogies = async () =>{
    let placesList = [];
    let countriesList = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesList=snapshot.val();
      }
    });
    for (let x = 0 ; x<countriesList.length ; x++){
        await firebase.database().ref(this.props.environment + "/systemData/slimPlacesData/"+countriesList[x])
        .once("value")
          .then(snapshot => {
            if (snapshot.val()!==null){
              //covering every country
              placesList=Object.values(snapshot.val());
            }
          });
        
          //check most frequent (NEED MOST POPULAR) categories
          let categoriesFrequency=[];
          for (let i=0 ; i<placesList.length ; i++){
            for (let j=0 ; j<placesList[i].placeCategory.length ; j++){
              const index = categoriesFrequency.findIndex(category => {
                return category.id === placesList[i].placeCategory[j];
              });
              if (index!==-1){
                categoriesFrequency[index].count = categoriesFrequency[index].count+1
              }
              else{
                categoriesFrequency.push({id:placesList[i].placeCategory[j],count:1})
              }
            }
           
          }
    
         /*  let sortedPlacesList =await placesList.sort((a, b) => {
            var keyA = a.placeScore;
            var keyB = b.placeScore;
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          }); */
          let sortedCategories  =await categoriesFrequency.sort((a, b) => {
            var keyA = a.count;
            var keyB = b.count;
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
         /*  let usedPlacesIDs=[];
          let userRecommendedPlacesInCountry={};
          let usedCategory=0;
          let i=0;
          for (  i= 0 ;i<sortedCategories.length ; i++){
            let recommendation={id:sortedCategories[i].id,places:[]}
            let j=0;
            let added=0;
            while (j<sortedPlacesList.length && added<10){
              let placeCategoriesIDs = sortedPlacesList[j].placeCategory
              if (placeCategoriesIDs.includes(sortedCategories[i].id)&&!usedPlacesIDs.includes(sortedPlacesList[j].placeID)){
                usedPlacesIDs.push(sortedPlacesList[j].placeID)
                recommendation.places.push(sortedPlacesList[j])
                added=added+1;
              }
              j=j+1;
            }
           
            if (added>0){
              userRecommendedPlacesInCountry[sortedCategories[i].id]=recommendation
              usedCategory=usedCategory+1;
            }
          } */
          let topCategories = {list:sortedCategories.map(category => {return category.id}).slice(0,10),updated:today.getTime()}
          /* userRecommendedPlacesInCountry.updated=today.getTime()

           firebase.database()
          .ref(this.props.environment + "/systemData/placesSortedByCategory/"+countriesList[x])
          .set(userRecommendedPlacesInCountry); */
          firebase.database()
          .ref(this.props.environment + "/systemData/topSystemCategories/"+countriesList[x])
          .set(topCategories);
    }
   
  }


 updateLocationsTree = async () =>{
   //need to create under preload data the slimer version of locationsTree
   let locationsTree;
   await firebase.database()
   .ref(this.props.environment + "/systemData/systemLocationsTree")
   .once("value")
   .then(snapshot => {
     if (snapshot.val()!==null){
       //covering every country
       locationsTree=snapshot.val();
     }
   }); 
   let countriesToExplore = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesToExplore=snapshot.val();
      }
    });
    let countriesList=[];
    let updatedLocationsTree ={};
    let tempLocationsTree =JSON.parse(JSON.stringify(locationsTree))
    countriesList= Object.keys(locationsTree);
    for (let i=0;i<countriesList.length ; i++){
        let country = tempLocationsTree[countriesList[i]];
        let countryObject=JSON.parse(JSON.stringify(country));
         
          let regionsList=[];
          if (country.regions!==undefined){
            regionsList = Object.keys(country.regions);
          }
          for (let j=0 ; j<regionsList.length ; j++){
            let region = country.regions[regionsList[j]];
            let regionObject=JSON.parse(JSON.stringify(region));

            let citiesList=[];
            if (region.cities!==undefined){
              citiesList = Object.keys(region.cities);
            }
            for (let k=0 ; k<citiesList.length ; k++){
              let city = region.cities[citiesList[k]];
              let cityObject=JSON.parse(JSON.stringify(city));
              
              if (cityObject.placesIDs!==undefined) delete cityObject.placesIDs
              if (cityObject.count!==undefined) delete cityObject.count
              regionObject.cities[citiesList[k]]=cityObject
            }

            if (regionObject.placesIDs!==undefined) delete regionObject.placesIDs
            if (regionObject.count!==undefined) delete regionObject.count
            countryObject.regions[regionsList[j]]=regionObject
          }
          let citiesList=[];
          if (country.cities!==undefined){
            citiesList = Object.keys(country.cities);
          }
          for (let j=0 ; j<citiesList.length ; j++){
            let city = country.cities[citiesList[j]];
            let cityObject=JSON.parse(JSON.stringify(city));
            
            if (cityObject.placesIDs!==undefined) delete cityObject.placesIDs
            if (cityObject.count!==undefined) delete cityObject.count
            countryObject.cities[citiesList[j]]=cityObject
          }
          if (countryObject.placesIDs!==undefined) delete countryObject.placesIDs
          if (countryObject.count!==undefined) delete countryObject.count
          updatedLocationsTree[countriesList[i]] = countryObject
      }
      firebase.database()
      .ref(this.props.environment + "/systemPreloadData/locationsTreeTags")
      .set(updatedLocationsTree);
      console.log("finished")
 }



 updateCategoriesTranslator = async () =>{
    let categoriesList = []
    let categoriesTranslator = {};
    await firebase.database()
    .ref(this.props.environment + "/systemData/systemCategories")
    .once("value")
    .then(snapshot => {
    
      snapshot.forEach(child => {
        categoriesList=categoriesList.concat(Object.values(child.val()))    
      });
    });
    for (let i =0 ; i<categoriesList.length ; i++){
    categoriesTranslator[categoriesList[i].id] = categoriesList[i]
    }
    firebase.database()
    .ref(this.props.environment + "/systemPreloadData/categoriesTranslator")
    .set(categoriesTranslator);
 }

 updateLocationsTranslator = async () =>{
  let preloadData;
  await firebase.database()
  .ref(this.props.environment + "/systemPreloadData")
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      //covering every country
      preloadData=snapshot.val();
    }
  }); 
  let locationsTree = preloadData.locationsTreeTags
  let countriesToExplore = [];
  /*  let locationsMap = []; */
   await firebase.database()
   .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
   .once("value")
   .then(snapshot => {
     if (snapshot.val()!==null){
       //covering every country
       countriesToExplore=snapshot.val();
     }
   });
   let countriesList=[];
   let discoverPlacesLocationsSearchTags ={};
   let tempLocationsTree =JSON.parse(JSON.stringify(locationsTree))
   countriesList= Object.keys(locationsTree);
   for (let i=0;i<countriesList.length ; i++){
       let country = tempLocationsTree[countriesList[i]];
       let countryObject=JSON.parse(JSON.stringify(country));
        delete countryObject.regions;
       delete countryObject.cities; 
       if (countriesToExplore.includes(countryObject.English)){
         discoverPlacesLocationsSearchTags[countryObject.treeID]=countryObject;
         let regionsList=[];
         if (country.regions!==undefined){
           regionsList = Object.keys(country.regions);
         }
         for (let j=0 ; j<regionsList.length ; j++){
           let region = country.regions[regionsList[j]];
           let regionObject=JSON.parse(JSON.stringify(region));
           //delete regionObject.type;
           delete regionObject.cities;
           discoverPlacesLocationsSearchTags[regionObject.treeID]=regionObject;
           let citiesList=[];
           if (region.cities!==undefined){
             citiesList = Object.keys(region.cities);
           }
           for (let k=0 ; k<citiesList.length ; k++){
             let city = region.cities[citiesList[k]];
             let cityObject=JSON.parse(JSON.stringify(city));
             //delete cityObject.type;
             discoverPlacesLocationsSearchTags[cityObject.treeID]=cityObject;
           }
         }
         let citiesList=[];
         if (country.cities!==undefined){
           citiesList = Object.keys(country.cities);
         }
         for (let j=0 ; j<citiesList.length ; j++){
           let city = country.cities[citiesList[j]];
           let cityObject=JSON.parse(JSON.stringify(city));
           //delete cityObject.type;
           discoverPlacesLocationsSearchTags[cityObject.treeID]=cityObject;
         }
       }
       
     } 

     firebase.database()
     .ref(this.props.environment + "/systemPreloadData/locationsTranslator")
     .set(discoverPlacesLocationsSearchTags);
 }

 clearPlacesExperiencesData = async () =>{
  let systemPlaces;
  let countriesList = [];
  await firebase.database()
  .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      //covering every country
      countriesList=snapshot.val();
    }
  });
  for (let i = 0 ; i<countriesList.length ; i++){
      await firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          systemPlaces=Object.values(snapshot.val());
        }
      });
      let newPlaces = {};
      for (let j=0 ; j<systemPlaces.length ; j++){

        let place = systemPlaces[j]
        if (place.experienceUsersRank!==undefined){
          delete place.experienceUsersRank
        }
        let placeStats = place.placeStats;
        if (placeStats.totalExperienced!==undefined){
          delete placeStats.totalExperienced
        }
        placeStats.totalExperiences = 0
        placeStats.usersDisliked = 0;
        placeStats.usersLiked = 0;
        placeStats.usersExperienced = 0;
        newPlaces[place.placeID]=place
          
      }
      console.log("finished")
       firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .set(newPlaces); 
   }
}

 createNavigationLinks = async () =>{
  let systemPlaces;
  let countriesList = [];
  await firebase.database()
  .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      //covering every country
      countriesList=snapshot.val();
    }
  });
  for (let i = 0 ; i<countriesList.length ; i++){
      await firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          systemPlaces=Object.values(snapshot.val());
        }
      });
      let newPlaces = {};
      for (let j=0 ; j<systemPlaces.length ; j++){

        let place = systemPlaces[j]
        let location = place.placeLocation
        let navigation = {}
        let googleMapsLink = ""
        if (location.inputMode==="Search"){
          googleMapsLink = "https://www.google.com/maps/search/?api=1&query=<address>&query_place_id="+location.placeID
        }
        else{
          googleMapsLink = "http://www.google.com/maps/place/"+location.coordinates.lat+","+location.coordinates.lng
        }
        
        let wazeLink = "https://www.waze.com/ul?ll="+location.coordinates.lat+","+location.coordinates.lng+"&navigate=yes&zoom=17"
        navigation ={
          googleMapsLink: googleMapsLink,
          wazeLink: wazeLink
        }
        place.navigation=navigation
        if (place.inFavorite!==undefined) delete place.inFavorite
        newPlaces[place.placeID]=place
          
      }
       firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .set(newPlaces); 
   }
}

 convertPlacesCharacteristics = async () =>{
  let systemPlaces;
  let countriesList = [];
  await firebase.database()
  .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      //covering every country
      countriesList=snapshot.val();
    }
  });
  for (let i = 0 ; i<countriesList.length ; i++){
      await firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          systemPlaces=Object.values(snapshot.val());
        }
      });
      let newPlaces = {};
      for (let j=0 ; j<systemPlaces.length ; j++){
        
        
          let place = systemPlaces[j]
          let placeCharacteristics = place.placeCharacteristics;
         /*  console.log(placeCharacteristics) */
          let newPlaceCharacteristics = {}
          if (place.placeID === "ChIJT2oD6upJHRURuwTMasOf9bY_1610540029147"){
            newPlaces[place.placeID]=place;
          }
          else if (placeCharacteristics!==undefined){
            //general tags
            let generalTags = [];
            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("accessible")){
                generalTags.push("accessible")
              }
              if (placeCharacteristics.tags.includes("petsAllowed")){
                generalTags.push("petsAllowed")
              }
              if (placeCharacteristics.tags.includes("petsNotAllowed")){
                generalTags.push("petsNotAllowed")
              }
              if (placeCharacteristics.tags.includes("freeEntrance")){
                generalTags.push("freeEntrance")
              }
              if (placeCharacteristics.tags.includes("parkingExist")){
                generalTags.push("hasParking")
              }
              if (placeCharacteristics.tags.includes("freeParking")){
                generalTags.push("freeParking")
              }

            }
            newPlaceCharacteristics.generalTags = generalTags
            

            //general tags
            let audienceTags = [];
            if (placeCharacteristics.placeAudience!==undefined){
              if (placeCharacteristics.placeAudience.includes("adultsOnly")){
                audienceTags.push("adultsOnly")
              }
              if (placeCharacteristics.placeAudience.includes("families")){
                audienceTags.push("families")
              }
              if (placeCharacteristics.placeAudience.includes("forGroups")){
                audienceTags.push("goofForGroups")
              }
              if (placeCharacteristics.placeAudience.includes("forKids")){
                audienceTags.push("forKids")
              }
              if (placeCharacteristics.placeAudience.includes("suitableForChildren")){
                audienceTags.push("goodForKids")
              }

              newPlaceCharacteristics.audienceTags = audienceTags
            }

            //main tags
            //nature tags 
            let mainTags = []
            let natureGeneralTags = []
            let seasonsTags = []
            let natureThingsToWatch = []
            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("hasWater")){
                mainTags.push("hasWater")
                natureGeneralTags.push("hasWater")
              }
              if (placeCharacteristics.tags.includes("waterEntry")){
                mainTags.push("waterEntry")
                natureGeneralTags.push("waterEntry")
              }
              if (placeCharacteristics.tags.includes("noWaterEntry")){
                mainTags.push("noWaterEntry")
                natureGeneralTags.push("noWaterEntry")
              }

              if (placeCharacteristics.tags.includes("blossoming")){
                mainTags.push("blossoming")
                natureThingsToWatch.push("blossoming")
              }
              if (placeCharacteristics.tags.includes("wildAnimals")){
                mainTags.push("wildAnimals")
                natureThingsToWatch.push("wildAnimals")
              }

            }
            if (placeCharacteristics.seasons!==undefined){
              if (placeCharacteristics.seasons.includes("winter")){
                mainTags.push("winter")
                seasonsTags.push("winter")
              }
              if (placeCharacteristics.seasons.includes("autumn")){
                mainTags.push("fall")
                seasonsTags.push("fall")
              }
              
              if (placeCharacteristics.seasons.includes("spring")){
                mainTags.push("spring")
                seasonsTags.push("spring")
              }
              
              if (placeCharacteristics.seasons.includes("summer")){
                mainTags.push("summer")
                seasonsTags.push("summer")
              }
            }
            newPlaceCharacteristics.natureGeneralTags = natureGeneralTags
            newPlaceCharacteristics.natureThingsToWatch = natureThingsToWatch
            newPlaceCharacteristics.seasonsTags = seasonsTags

            //food tags
            let foodServices = [];
            let foodDiningOptions = [];
            let foodKosher =[]
            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("hasOutdoorArea")){
                mainTags.push("hasOutdoorArea")
                foodServices.push("hasOutdoorArea")
              }
              if (placeCharacteristics.tags.includes("hasSmokingArea")){
                mainTags.push("hasSmokingArea")
                foodServices.push("hasSmokingArea")
              }
              if (placeCharacteristics.tags.includes("hasWifi")){
                mainTags.push("hasWifi")
                foodServices.push("hasWifi")
              }


              if (placeCharacteristics.tags.includes("hasTakeaway")){
                mainTags.push("takeaway")
                foodDiningOptions.push("takeaway")
              }
              if (placeCharacteristics.tags.includes("hasDelivery")){
                mainTags.push("delivery")
                foodDiningOptions.push("delivery")
              }

              if (placeCharacteristics.tags.includes("kosher")){
                mainTags.push("kosher")
                foodKosher.push("kosher")
              }
              if (placeCharacteristics.tags.includes("nonKosher")){
                mainTags.push("nonKosher")
                foodKosher.push("nonKosher")
              }
            }

            newPlaceCharacteristics.foodServices = foodServices
            newPlaceCharacteristics.foodDiningOptions = foodDiningOptions
            newPlaceCharacteristics.foodKosher = foodKosher

            newPlaceCharacteristics.mainTags = mainTags

            //handle sub tags
            let subTags = [];
            //handle museum tags
            let museumTypes = [];

            if (placeCharacteristics.museumType!==undefined){
              museumTypes = placeCharacteristics.museumType.map(type => {return type.id})
              subTags = subTags.concat(museumTypes)
            }

            newPlaceCharacteristics.museumTypes = museumTypes

            //trail characteristics
            let trailCharacteristics = [];
            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("circular")){
                subTags.push("circular")
                trailCharacteristics.push("circular")
              }
            }
            newPlaceCharacteristics.trailCharacteristics = trailCharacteristics

            //dificulty
            let difficulty = [];
            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("easy")){
                subTags.push("easy")
                difficulty=["easy"]
              }
              if (placeCharacteristics.tags.includes("medium")){
                subTags.push("medium")
                difficulty=["medium"]
              }
              if (placeCharacteristics.tags.includes("hard")){
                subTags.push("hard")
                difficulty=["hard"]
              }
              if (placeCharacteristics.tags.includes("extreme")){
                subTags.push("extreme")
                difficulty=["extreme"]
              }
            }
            newPlaceCharacteristics.difficulty = difficulty

            //distance and duration
            let durationValue="";
            if (placeCharacteristics.duration!==undefined){
              durationValue = placeCharacteristics.duration
            }
            newPlaceCharacteristics.durationValue = durationValue

            let distanceValue="";
            if (placeCharacteristics.distance!==undefined){
              distanceValue = placeCharacteristics.distance
            }
            newPlaceCharacteristics.distanceValue = distanceValue

            let durationUnits="";
            if (placeCharacteristics.durationUnits!==undefined){
              durationUnits = placeCharacteristics.durationUnits
            }
            newPlaceCharacteristics.durationUnits = durationUnits

            let distanceUnits="";
            if (placeCharacteristics.distanceUnits!==undefined){
              distanceUnits = placeCharacteristics.distanceUnits
            }
            newPlaceCharacteristics.distanceUnits = distanceUnits


            //bike ride level
            let bikeRideLevel = []
            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("bikeRideLevelBeginner")){
                subTags.push("bikeRideLevelBeginner")
                bikeRideLevel=["bikeRideLevelBeginner"]
              }
              if (placeCharacteristics.tags.includes("bikeRideLevelIntermidiate")){
                subTags.push("bikeRideLevelIntermidiate")
                bikeRideLevel=["bikeRideLevelIntermidiate"]
              }
              if (placeCharacteristics.tags.includes("bikeRideLevelAdvanced")){
                subTags.push("bikeRideLevelAdvanced")
                bikeRideLevel=["bikeRideLevelAdvanced"]
              }
              if (placeCharacteristics.tags.includes("bikeRideLevelExtreme")){
                subTags.push("bikeRideLevelExtreme")
                bikeRideLevel=["bikeRideLevelExtreme"]
              }
            }
            newPlaceCharacteristics.bikeRideLevel = bikeRideLevel

            //mtb trail type
            let mtbTrailType = [];
            if (placeCharacteristics.mtbTrailType!==undefined){
              mtbTrailType = placeCharacteristics.mtbTrailType.map(type => {return type.id})
              subTags = subTags.concat(mtbTrailType)
            }
            newPlaceCharacteristics.mtbTrailType = mtbTrailType


            //beach characteristics 
            let beachCharacteristics = [];

            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("quietRequired")){
                subTags.push("quietRequired")
                beachCharacteristics.push("quietRequired")
              }

              if (placeCharacteristics.tags.includes("fitReligious")){
                subTags.push("fitReligious")
                beachCharacteristics.push("fitReligious")
              }
            }
            newPlaceCharacteristics.beachCharacteristics = beachCharacteristics

            //beach activities
            let beachActivities = [];
            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("jetSki")){
                subTags.push("jetSki")
                beachActivities.push("jetSki")
              }

              if (placeCharacteristics.tags.includes("snorkeling")){
                subTags.push("snorkeling")
                beachActivities.push("snorkeling")
              }

              if (placeCharacteristics.tags.includes("surfing")){
                subTags.push("surfing")
                beachActivities.push("surfing")
              }

              if (placeCharacteristics.tags.includes("kitesurfing")){
                subTags.push("kitesurfing")
                beachActivities.push("kitesurfing")
              }
            }
            newPlaceCharacteristics.beachActivities = beachActivities

            //beach facilities
            let beachFacilities = [];
            if (placeCharacteristics.tags!==undefined){
              if (placeCharacteristics.tags.includes("hasChairs")){
                subTags.push("hasChairs")
                beachFacilities.push("hasChairs")
              }

              if (placeCharacteristics.tags.includes("hasTanningBeds")){
                subTags.push("hasTanningBeds")
                beachFacilities.push("hasTanningBeds")
              }

              if (placeCharacteristics.tags.includes("hasShowers")){
                subTags.push("hasShowers")
                beachFacilities.push("hasShowers")
              }

              if (placeCharacteristics.tags.includes("hasToilets")){
                subTags.push("hasToilets")
                beachFacilities.push("hasToilets")
              }
            }
            newPlaceCharacteristics.beachFacilities = beachFacilities
          

          //park or camping facilities
          let parkOrCampingFacilities = []
          if (placeCharacteristics.tags!==undefined){
            if (placeCharacteristics.tags.includes("bbqAllowed")){
              subTags.push("bbqAllowed")
              parkOrCampingFacilities.push("bbqAllowed")
            }

            if (placeCharacteristics.tags.includes("hasLighting")){
              subTags.push("hasLighting")
              parkOrCampingFacilities.push("hasLighting")
            }

            if (placeCharacteristics.tags.includes("hasShowers")){
              subTags.push("hasShowers")
              parkOrCampingFacilities.push("hasShowers")
            }

            if (placeCharacteristics.tags.includes("hasToilets")){
              subTags.push("hasToilets")
              parkOrCampingFacilities.push("hasToilets")
            }
            if (placeCharacteristics.tags.includes("hasTables")){
              subTags.push("hasTables")
              parkOrCampingFacilities.push("hasTables")
            }
          }
          newPlaceCharacteristics.parkOrCampingFacilities = parkOrCampingFacilities

          //bar type 
          let barType = []
          if (placeCharacteristics.barType!==undefined){
            barType = placeCharacteristics.barType.map(type => {return type.id})
            subTags = subTags.concat(barType)
          }
          newPlaceCharacteristics.barType = barType

          //restaurant service type
          let restaurantServiceType =[];
          if (placeCharacteristics.serviceType!==undefined){
            restaurantServiceType = [placeCharacteristics.serviceType.id]
            subTags = subTags.concat(restaurantServiceType)
          }
          newPlaceCharacteristics.restaurantServiceType = restaurantServiceType

          //restaurant cuisine
          let restaurantCuisine =[];
          if (placeCharacteristics.restaurantCuisine!==undefined){
            restaurantCuisine = placeCharacteristics.restaurantCuisine.map(type => {return type.id})
            subTags = subTags.concat(restaurantCuisine)
          }
          newPlaceCharacteristics.restaurantCuisine = restaurantCuisine

          //guiding characteristics
          let guidingCharacteristics = [];
          if (placeCharacteristics.tags!==undefined){
            if (placeCharacteristics.tags.includes("hasGuidedTours")){
              subTags.push("hasGuidedTours")
              guidingCharacteristics.push("hasGuidedTours")
            }

            if (placeCharacteristics.tags.includes("hasAudioGuide")){
              subTags.push("hasAudioGuide")
              guidingCharacteristics.push("hasAudioGuide")
            }

          }
          newPlaceCharacteristics.guidingCharacteristics = guidingCharacteristics

          newPlaceCharacteristics.subTags = subTags
         /*  console.log(newPlaceCharacteristics) */
        }
        place.placeCharacteristics = newPlaceCharacteristics

        if (place.placeID !== "ChIJT2oD6upJHRURuwTMasOf9bY_1610540029147"){
          //convert place category
        /*   let newPlaceCategory = place.placeCategory.map(cat=>{return cat.id});
          place.placeCategory = newPlaceCategory
          //convert place main category
          place.placeMainCategory = place.placeCategorySection
          delete place.placeCategorySection */
          //convert location tree
         
          let locationTree = place.placeLocation.locationTree
        
          locationTree.placeCountry = locationTree.placeCountry
          if (locationTree.placeRegion!==undefined){
            if (locationTree.placeRegion.treeID!==undefined){
              locationTree.placeRegion = locationTree.placeRegion.treeID
            }
            
          }
          if (locationTree.placeCity!==undefined){
            if (locationTree.placeCity.treeID!==undefined){
              locationTree.placeCity = locationTree.placeCity.treeID
            }
          }
          place.placeLocation.locationTree = locationTree
          //remove unesessary data
          if (place.audienceAgeRange!==undefined) delete place.audienceAgeRange
          if (place.placeAudienceEveryone!==undefined) delete place.placeAudienceEveryone
        }
      
        newPlaces[place.placeID]=place
          
      }
      
      /* firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .set(newPlaces);  */
   }

 }
    
  updateSlimPlaces = async () =>{
    let systemPlaces;
    let countriesList = [];
    await firebase.database()
    .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        countriesList=snapshot.val();
      }
    });
    for (let i = 0 ; i<countriesList.length ; i++){
        await firebase.database()
        .ref(this.props.environment + "/systemData/places/"+countriesList[i])
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            systemPlaces=Object.values(snapshot.val());
          }
        });
        let slimPlaces = {};
        for (let j=0 ; j<systemPlaces.length ; j++){
          let place = systemPlaces[j];
          let slimPlace ={
            placeID:place.placeID,
            placeName:place.placeName,
            owner:place.owner.ownerID,
            placeStatus:place.placeStatus,
            placeMainCategory:place.placeMainCategory,
            placeCategory:place.placeCategory,
            placeLanguages:place.placeLanguages
          }

          if (place.placeTags!==undefined){
            slimPlace.placeTags=place.placeTags
          }
    
          if (place.placeNewCategories!==undefined){
            slimPlace.placeNewCategories=place.placeNewCategories
          }
          let placeLocation = {
        
            coordinates:place.placeLocation.coordinates,
            locationTree:place.placeLocation.locationTree
          }
          if (place.placeLocation.placeID!==undefined){
            if (place.placeLocation.placeID!==null){
              placeLocation.placeID=place.placeLocation.placeID;
            }
          }
          
          slimPlace.placeLocation=placeLocation; 
    
    
          let placeScore = 0;
          let placeViewesFactor=1;
          let placeExperiencesFactor=1;
          let placeLikesFactor=1;
          let placeFavoriteFactor=1;
          
          placeScore = placeScore+placeViewesFactor*place.placeStats.viewed.count;
          placeScore = placeScore+placeExperiencesFactor*place.placeStats.usersExperienced;
          placeScore = placeScore+placeLikesFactor*place.placeStats.usersLiked;
          placeScore = placeScore+placeFavoriteFactor*place.placeStats.inspired.count;
          
          slimPlace.placeScore=placeScore;
      
          
          if ( place.imagePreview.length>0){
            slimPlace.imagePreview = {}
            slimPlace.imagePreview.smallImage = place.imagePreview[0].smallImage
            slimPlace.imagePreview.thumbnailImage = place.imagePreview[0].thumbnailImage
          }
          
      
          let placeCharacteristics = {};
          if (place.placeCharacteristics!==undefined){
            placeCharacteristics = place.placeCharacteristics
          }
          slimPlace.placeCharacteristics=placeCharacteristics 
      
          //open days
          let hoursRows=place.hoursRows;
          let openDays = [-1,-1,-1,-1,-1,-1,-1]
          for (let i=0 ; i<hoursRows.length ; i++){
            let rowHoursOpen = false
            let rowHoursClosed = false;
            if (hoursRows[i].hoursArray!==undefined){
              for (let j=0 ; j<hoursRows[i].hoursArray.length ; j++){
                if (hoursRows[i].hoursArray[j].open!=="Not specified"){
                  rowHoursOpen = true
                }
                if (hoursRows[i].hoursArray[j].close!=="Not specified"){
                  rowHoursOpen = true
                } 
              }
            }
              
            if (hoursRows[i].open24){
              rowHoursOpen = true
            }
            if (hoursRows[i].isClosed){
              rowHoursClosed = true
            }
            if (rowHoursOpen){
              for (let j=0 ; j<hoursRows[i].selectedDays.length ; j++){
                if (hoursRows[i].selectedDays[j]){
                  openDays[j]=1;
                }
              }
            }
            if (rowHoursClosed){
              for (let j=0 ; j<hoursRows[i].selectedDays.length ; j++){
                if (hoursRows[i].selectedDays[j]){
                  openDays[j]=0;
                }
              }
            }
          }
          slimPlace.openDays = openDays
    
          if (place.placePermanentlyClosed){
            slimPlace.placePermanentlyClosed=true
          }
          
          slimPlaces[slimPlace.placeID]=slimPlace
        }
        console.log("finished")
         firebase.database()
        .ref(this.props.environment + "/systemData/slimPlacesData/"+countriesList[i])
        .set(slimPlaces);
    }
  
}
/* 
convertMuseumChars = async () =>{
  let systemPlaces;
  let countriesList = [];
  await firebase.database()
  .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      //covering every country
      countriesList=snapshot.val();
    }
  });
  for (let i = 0 ; i<countriesList.length ; i++){
      await firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          systemPlaces=Object.values(snapshot.val());
        }
      });
      let newPlaces = {};
      for (let j=0 ; j<systemPlaces.length ; j++){
        let place = systemPlaces[j]
        //remove empty strings
        if (place.placeCharacteristics!==undefined){
          let chars = place.placeCharacteristics
          if (chars.distanceUnits!==undefined){
            if (chars.distanceUnits===""){
              delete chars.distanceUnits
            }
          }
          if (chars.distanceValue!==undefined){
            if (chars.distanceValue===""){
              delete chars.distanceValue
            }
          }
          if (chars.durationUnits!==undefined){
            if (chars.durationUnits===""){
              delete chars.durationUnits
            }
          }
          if (chars.durationValue!==undefined){
            if (chars.durationValue===""){
              delete chars.durationValue
            }
          }

          if (chars.museumTypes!==undefined){
            const historyTypeIndex = chars.museumTypes.findIndex(type => {
              return type === "history"
            });
            const historySubTagIndex = chars.subTags.findIndex(type => {
              return type === "history"
            });
            if (historyTypeIndex!==-1){
              chars.museumTypes.splice(historyTypeIndex,1)
              chars.museumTypes.push("historyMuseum")
            }
            if (historySubTagIndex!==-1){
              chars.subTags.splice(historySubTagIndex,1)
              chars.subTags.push("historyMuseum")
            }
  
            const artTypeIndex = chars.museumTypes.findIndex(type => {
              return type === "art"
            });
            const artSubTagIndex = chars.subTags.findIndex(type => {
              return type === "art"
            });
            if (artTypeIndex!==-1){
              chars.museumTypes.splice(artTypeIndex,1)
              chars.museumTypes.push("artMuseum")
            }
            if (artSubTagIndex!==-1){
              chars.subTags.splice(artSubTagIndex,1)
              chars.subTags.push("artMuseum")
            }
            
            const archeologyTypeIndex = chars.museumTypes.findIndex(type => {
              return type === "archaeology"
            });
            const archeologySubTagIndex = chars.subTags.findIndex(type => {
              return type === "archaeology"
            });
            if (archeologyTypeIndex!==-1){
              chars.museumTypes.splice(archeologyTypeIndex,1)
              chars.museumTypes.push("archeologyMuseum")
            }
            if (archeologySubTagIndex!==-1){
              chars.subTags.splice(archeologySubTagIndex,1)
              chars.subTags.push("archeologyMuseum")
            }
  
            const naturalTypeIndex = chars.museumTypes.findIndex(type => {
              return type === "naturalHistory"
            });
            const naturalSubTagIndex = chars.subTags.findIndex(type => {
              return type === "naturalHistory"
            });
            if (naturalTypeIndex!==-1){
              chars.museumTypes.splice(naturalTypeIndex,1)
              chars.museumTypes.push("naturalHistoryMuseum")
            }
            if (naturalSubTagIndex!==-1){
              chars.subTags.splice(naturalSubTagIndex,1)
              chars.subTags.push("naturalHistoryMuseum")
            }
  
            const scienceTypeIndex = chars.museumTypes.findIndex(type => {
              return type === "science"
            });
            const scienceSubTagIndex = chars.subTags.findIndex(type => {
              return type === "science"
            });
            if (scienceTypeIndex!==-1){
              chars.museumTypes.splice(scienceTypeIndex,1)
              chars.museumTypes.push("scienceMuseum")
            }
            if (scienceSubTagIndex!==-1){
              chars.subTags.splice(scienceSubTagIndex,1)
              chars.subTags.push("scienceMuseum")
            }
  
            
            const technologyTypeIndex = chars.museumTypes.findIndex(type => {
              return type === "technology"
            });
            const technologySubTagIndex = chars.subTags.findIndex(type => {
              return type === "technology"
            });
            if (technologyTypeIndex!==-1){
              chars.museumTypes.splice(technologyTypeIndex,1)
              chars.museumTypes.push("technologyMuseum")
            }
            if (technologySubTagIndex!==-1){
              chars.subTags.splice(technologySubTagIndex,1)
              chars.subTags.push("technologyMuseum")
            }
          }
         
          place.placeCharacteristics = chars
        }
        newPlaces[place.placeID]=place

          
      }
      firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .set(newPlaces); 
   }
}
 */

convertPlaceTags = async () =>{
  let systemPlaces;
  let countriesList = [];
  await firebase.database()
  .ref(this.props.environment + "/systemInitializeData/countriesToExplore")
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      //covering every country
      countriesList=snapshot.val();
    }
  });
  for (let i = 0 ; i<countriesList.length ; i++){
      await firebase.database()
      .ref(this.props.environment + "/systemData/places/"+countriesList[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          //covering every country
          systemPlaces=Object.values(snapshot.val());
        }
      });
      let newPlaces = {};
      for (let j=0 ; j<systemPlaces.length ; j++){
        let place = systemPlaces[j]
        //remove empty strings
        let placeTags = []
        if (place.placeTags!==undefined){
          for (let k=0 ; k<place.placeTags.length; k++){
            if (place.placeTags[k] !== Object(place.placeTags[k])){
              await this.refs.detectInputLanguage.detectLanguage(place.placeTags[k]).then(result => {
                let tag = {}
                tag[result] = place.placeTags[k]
                placeTags.push(tag)
              }); 
            }
          }
          
        }
        place.placeTags = placeTags
        newPlaces[place.placeID]=place

   }
   firebase.database()
   .ref(this.props.environment + "/systemData/places/"+countriesList[i])
   .set(newPlaces); 
  }
  console.log("finished")
}

createRecommendedDestinationsByCountry = async () =>{
  let recommendedDestinations = {Israel:[],France:[]}
  recommendedDestinations.Israel.push({English:"Israel",Hebrew:"ישראל",treeID:"Israel",type:"country"})
  recommendedDestinations.Israel.push({English:"Central District",Hebrew:"מרכז",treeID:"Israel_Central District",type:"region"})
  recommendedDestinations.Israel.push({English:"Tel Aviv-Yafo",Hebrew:"תל אביב יפו",treeID:"Israel_Tel Aviv-Yafo",type:"city"})
  recommendedDestinations.Israel.push({English:"Dead Sea area",Hebrew:"ים המלח ומדבר יהודה",treeID:"Israel_Dead Sea area",type:"region"})
  recommendedDestinations.Israel.push({English:"Haifa District",Hebrew:"חיפה וחוף הכרמל",treeID:"Israel_Haifa District",type:"region"})
  recommendedDestinations.Israel.push({English:"Jerusalem District",Hebrew:"מחוז ירושלים",treeID:"Israel_Jerusalem District",type:"region"})
  recommendedDestinations.Israel.push({English:"Jerusalem",Hebrew:"ירושלים",treeID:"Israel_Jerusalem",type:"city"})
  recommendedDestinations.Israel.push({English:"North District",Hebrew:"צפון",treeID:"Israel_North District",type:"region"})
  recommendedDestinations.Israel.push({English:"Golan Heights",Hebrew:"רמת הגולן",treeID:"Israel_Golan Heights",type:"city"})
  recommendedDestinations.Israel.push({English:"Sharon",Hebrew:"השרון",treeID:"Israel_Sharon",type:"region"})
  recommendedDestinations.Israel.push({English:"Southern District",Hebrew:"דרום",treeID:"Israel_Southern District",type:"region"})
  recommendedDestinations.Israel.push({English:"Eilat",Hebrew:"אילת",treeID:"Israel_Eilat",type:"city"})

  recommendedDestinations.France.push({English:"France",Hebrew:"צרפת",treeID:"France", type:"country"})
  recommendedDestinations.France.push({English:"Paris",Hebrew:"פריז",treeID:"France_Paris", type:"city"})

  firebase.database()
   .ref(this.props.environment + "/systemData/recommendedDestinationsByCountry")
   .set(recommendedDestinations); 
}

usersWelcomeMessage= async () =>{
  let allUsers=[];
  await firebase.database()
  .ref( this.props.environment+"/users")
  .once("value")
  .then(snapshot => {
    snapshot.forEach(child => {     
        allUsers.push(child.val());
    });
  });
  let usersObjet={};
  for (let i=0 ; i<allUsers.length ; i++){
    usersObjet[allUsers[i].userID] = allUsers[i]
    usersObjet[allUsers[i].userID].showWelcomeWizard=true
  }

  firebase.database()
  .ref(this.props.environment + "/users")
  .set(usersObjet); 
  console.log("finished")
}

createUserSlimPlans = async () =>{
  let allUsers=[];
  await firebase.database()
  .ref( this.props.environment+"/users")
  .once("value")
  .then(snapshot => {
    snapshot.forEach(child => {     
        allUsers.push(child.val());
    });
  });
  for (let i=0 ; i<allUsers.length ; i++){
    let userID = allUsers[i].userID
    let userPlans = [];
    await firebase.database()
    .ref(
     this.props.environment+"/usersData/"+userID+"/userPlans"
    )
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        userPlans = Object.values(snapshot.val())
      }
    });
    for (let j= 0 ; j<userPlans.length ; j++){
   
      let plan = userPlans[j]
      let slimPlan = {
        planID: plan.planID,
        planLastModified: plan.planLastModified,
        planCreationDate: plan.planCreationDate,
        planName:plan.planName,
        planDates:plan.planDates,
        owner:plan.owner,
        planDuration:plan.planDuration,
        planEditors:plan.planEditors,
      }
      if (plan.imagePreview!==undefined){
        slimPlan.imagePreview = plan.imagePreview
      }
      if (plan.noDatesSelected!==undefined){
        slimPlan.noDatesSelected = plan.noDatesSelected
      }
      if (plan.participants!==undefined){
        slimPlan.participants = plan.participants
      }
      let slimPath = this.props.environment+"/usersData/"+userID+"/userSlimPlans/"+ plan.planID;
      firebase.database()
      .ref(slimPath)
      .set(slimPlan); 
    }
    
  }
}

detectInputLanguage = () =>{
  return (<DetectInputLanguage
      ref="detectInputLanguage"
    />)
}

render(){
    return (
        <div>{this.detectInputLanguage()}</div>
      );
  }
    
  }
  export default DBScripts;