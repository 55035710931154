import React, { Component } from "react";
import classes from "./databaseProcessing.module.css"

import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';

import firebaseConfig from "../Firebase"

import { isMobile, isMobileOnly } from "react-device-detect";

if (!firebase.apps.length) { 
  firebase.initializeApp(firebaseConfig);
 }else {
  firebase.app(); // if already initialized, use that one
}

const moment = require('moment');
const analytics = firebase.analytics();

let saving = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FSavingGif.png?alt=media&token=34dfb2f2-8c58-4ffc-8d8a-b92919286d13"



class WriteDatabase extends Component {

    state={
      firestorePrefix: this.props.environment
    }
  //---------HELPER FUNCTIONS-----------

  
  addToDatabase = (path, object, key) => {
    firebase.database()
      .ref(path + "/" + key)
      .set(object);
  };

  updateToDatabase = (path, newValue) => {
    firebase.database()
      .ref(path)
      .set(newValue);
  };

  deleteDataFromDB = path => {
    firebase.database()
      .ref(path)
      .set(null);
  };

  updateLatestViewedPlacesList = async placesList =>{
    await this.updateToDatabase(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics/latestViewedPlaces", placesList);
  }

  updateLatestPlacesViewed = async (placeID,country) =>{
    return new Promise(async (resolve, reject) => {
      let added=false;
    let latestViewedPlaces = [];
    await firebase.database()
    .ref(
      this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics/latestViewedPlaces"
    )
    .once("value")
    .then(snapshot => {
      snapshot.forEach(child => {
        latestViewedPlaces.push(child.val());
      
      });
    });
    if (!latestViewedPlaces.map(place=>{return place.placeID}).includes(placeID)){
      if (latestViewedPlaces.length===7){
        latestViewedPlaces.splice(6,1)
      }
      latestViewedPlaces.unshift({placeID:placeID,country:country})
      added=true
      await this.updateToDatabase(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics/latestViewedPlaces", latestViewedPlaces);

    }
    resolve(added);
  });
  }

  updatePlaceUsersRank = async (usersRanks, userID, experienceID, rank,scenario) =>{
    return new Promise(async (resolve, reject) => {
  
      let experienceUsersRank = {}
      if (usersRanks!==undefined){
        experienceUsersRank = usersRanks
      }
      let userOverallRankOld = 0;
      let userOverallRankNew
      let likesUpdate = 0;
      let dislikesUpdate = 0;
      let experiencesList = [];
      let experiencesRanksList = [];
      let experienceUsers = 0;
      if (usersRanks[userID]!==undefined){
        userOverallRankOld = usersRanks[userID].overallRank;
        if (usersRanks[userID].list!==undefined){
          experiencesList = usersRanks[userID].list
        }
      }
      else{
        experienceUsersRank[userID]={}
        experienceUsersRank[userID].userID=userID
        experienceUsers = 1;
      }
      if (scenario==="CreateExperience"){
        let experienceRank = {experienceID: experienceID, experienceRank:rank}
        experiencesList.push(experienceRank)
      
      }
      if (scenario==="UpdateExperience"){
        let experienceRank = {experienceID: experienceID, experienceRank:rank}
        let experienceIndex = -1
        for (let i=0 ; i<experiencesList.length ; i++){
          if (experiencesList[i].experienceID === experienceID){
            experienceIndex=i;
          }
        }
        if (experienceIndex!==-1){
          experiencesList[experienceIndex] = experienceRank
        }
      }
      if (scenario==="DeleteExperience"){
        let experienceIndex = -1
        for (let i=0 ; i<experiencesList.length ; i++){
          if (experiencesList[i].experienceID === experienceID){
            experienceIndex=i;
          }
        }
        if (experienceIndex!==-1){
          experiencesList.splice(experienceIndex,1)

        }
        if (experiencesList.length===0){
          experienceUsers = -1
        }
      }
      experiencesRanksList = experiencesList.map(exp=>{return exp.experienceRank})
      userOverallRankNew = experiencesRanksList.reduce(function(a, b){
          return a + b;
      }, 0);

      if (userOverallRankOld<0){
        if (userOverallRankNew>0){
          likesUpdate=1;
          dislikesUpdate = -1
        }
        if (userOverallRankNew===0){
          dislikesUpdate=-1;
        }
      }
      if (userOverallRankOld===0){
        if (userOverallRankNew>0){
          likesUpdate=1;
        }
        if (userOverallRankNew<0){
          dislikesUpdate=1;
        }
      }
      if (userOverallRankOld>0){
        if (userOverallRankNew===0){
          likesUpdate=-1;
        }
        if (userOverallRankNew<0){
          likesUpdate = -1;
          dislikesUpdate=1;
        }
      }

      experienceUsersRank[userID].overallRank = userOverallRankNew
      experienceUsersRank[userID].list = experiencesList
      let result = {usersRanks:experienceUsersRank, likesUpdate:likesUpdate, dislikesUpdate:dislikesUpdate,experienceUsers:experienceUsers} ;
      resolve(result);
    });
  }

   
updateBehaviorProfile = async (placeID,dbPaths,behaviorType) =>{
  let date = new Date();
  let timestamp = date.getTime();
  for (let i=0 ; i<dbPaths.length ; i++){
    let  dbPath = dbPaths[i]
    let tagStats;
    await firebase.database()
    .ref( dbPath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        tagStats=snapshot.val();
      }
    });
    if (tagStats===undefined){
      tagStats={}
      tagStats[behaviorType]={
        count:1,
        timestamp:timestamp,
        placesIDs:[placeID]
      }
    }
    else{
      if (tagStats[behaviorType]===undefined){
        tagStats[behaviorType]={
          count:1,
          timestamp:timestamp,
          placesIDs:[placeID]
        }
      }
      else if (!tagStats[behaviorType].placesIDs.includes(placeID)){
        tagStats[behaviorType].count=tagStats[behaviorType].count+1;
        tagStats[behaviorType].timestamp=timestamp
        tagStats[behaviorType].placesIDs.push(placeID)
      }
    }
    
    this.updateToDatabase(dbPath,tagStats);
  }
  
}

updateGlobalBehaviorProfile = async (userID,dbPaths,behaviorType) =>{
  let date = new Date();
  let timestamp = date.getTime();
  for (let i=0 ; i<dbPaths.length ; i++){
    let  dbPath = dbPaths[i]
    let tagStats;
    await firebase.database()
    .ref( dbPath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        tagStats=snapshot.val();
      }
    });
    if (tagStats===undefined){
      tagStats={}
      tagStats[behaviorType]={
        count:1,
        timestamp:timestamp,
        users:[userID]
      }
    }
    else{
      if (tagStats[behaviorType]===undefined){
        tagStats[behaviorType]={
          count:1,
          timestamp:timestamp,
          users:[userID]
        }
      }
      else if (!tagStats[behaviorType].users.includes(userID)){
        tagStats[behaviorType].count=tagStats[behaviorType].count+1;
        tagStats[behaviorType].timestamp=timestamp
        tagStats[behaviorType].users.push(userID)
      }
    }
    
    this.updateToDatabase(dbPath,tagStats);
  }
  
}

updateSearchTagHandler = async (dbPath,behaviorType)=>{
  let date = new Date();
  let timestamp = date.getTime();
  let tagStats;
  await firebase.database()
  .ref( dbPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      //covering every country
      tagStats=snapshot.val();
    }
  });
  if (tagStats===undefined){
    tagStats={}
    tagStats[behaviorType]={
      count:1,
      timestamp:timestamp
    }
  }
  else{
    if (tagStats[behaviorType]===undefined){
      tagStats[behaviorType]={
        count:1,
        timestamp:timestamp
      }
    }
    else{
      tagStats[behaviorType].count=tagStats[behaviorType].count+1;
      tagStats[behaviorType].timestamp=timestamp
    }
   
  }
  this.updateToDatabase(dbPath,tagStats);
}


locationSearchLogHandler = async (tag,country) =>{
  //for individual user
  if (this.props.loggedUser){
    let dbPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+country+"/"+tag.treeID;
    this.updateSearchTagHandler(dbPath,"searchedLocation")
    dbPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+tag.treeID;
    this.updateSearchTagHandler(dbPath,"searchedLocation")

    //to have information by user residence country
    dbPath = [this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersLocationsProfile/"+country+"/"+tag.treeID];
    this.updateGlobalBehaviorProfile(this.props.userID,dbPath,"searchedLocation")
  }
  
 //overall in the system
 let globalPath = [this.props.environment + "/systemData/usersBehaviorData/usersLocationsProfile/"+country+"/"+tag.treeID];
 this.updateGlobalBehaviorProfile(this.props.userID,globalPath,"searchedLocation")
}

categorySearchLogHandler = async (tag,country) =>{
  //for individual user
  if (this.props.loggedUser){
    let dbPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+country+"/"+tag;
    this.updateSearchTagHandler(dbPath,"searchedCategory")
    dbPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+tag;
    this.updateSearchTagHandler(dbPath,"searchedCategory")

     //to have information by user residence country
     dbPath = [this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersCategoriesProfile/"+country+"/"+tag.id];
     this.updateGlobalBehaviorProfile(this.props.userID,dbPath,"searchedCategory")
  }
 
  //overall in the system
  let globalPath = [this.props.environment + "/systemData/usersBehaviorData/usersCategoriesProfile/"+country+"/"+tag.id];
  this.updateGlobalBehaviorProfile(this.props.userID,globalPath,"searchedCategory")
}


  updateStatistics = async (path, type, criteria,increase) => {
    let stats;
    if (increase){
    return new Promise(async (resolve, reject) => {
    await firebase.database()
      .ref(
       path
      )
      .once("value")
      .then(snapshot => {
        if(snapshot.exists()){
          snapshot.forEach(child => {
          if (child.key === type) {
            stats=child.val();  
         }
        });
        }
      });
    if (stats === undefined) {
      stats = { count: 1, list: [criteria] };
    } 
    else if (stats.count===0){
      stats = { count: 1, list: [criteria] };
    }
  else {
      let include = false;
      for (let i = 0; i < stats.list.length; i++) {
        if (stats.list[i] === criteria) {
          include = true;
        }
      }
      if (!include) {
        stats.count += 1;
        stats.list.push(criteria);
      }
    }   
    this.addToDatabase(
      path,
      stats,
      type
    );
    
    resolve(stats);
    });}
    else{
      return new Promise(async (resolve, reject) => {
        await firebase.database()
          .ref(
           path
          )
          .once("value")
          .then(snapshot => {
            if(snapshot.exists()){
              snapshot.forEach(child => {
              if (child.key === type) {
                stats=child.val();  
                let index;
                for (let i = 0; i < stats.list.length; i++) {
                  if (stats.list[i] === criteria) {
                    index = i;
                  }
                }
                stats.list.splice(index,1);
                stats.count -= 1;
                
              this.addToDatabase(
                path,
                stats,
                type
              );
             }
            });
            }
          });
        
    
        resolve(stats);
        });
    }
  };

  updateSystemLocationsTree = async (locationTree,placeID,temp)=>{
    return new Promise(async (resolve, reject) => {
        //handle country
        let countryText=locationTree.placeCountry.treeID;
        if (locationTree.placeCountry.treeID===undefined){
          countryText=locationTree.placeCountry
        }
        let path = this.props.environment+"/systemData/systemLocationsTree/"+countryText
        if (temp){
          path = this.props.environment+"/systemData/systemLocationsTreeTemp/"+countryText
        }
        let currentCountry;
        await firebase.database()
        .ref(path)
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            currentCountry=snapshot.val();
        }
        });
        if (currentCountry===undefined){
          //error
          resolve();
        }
       

        //country always exists - requirement during creation
        if (currentCountry.placesIDs!==undefined){
          if (!currentCountry.placesIDs.includes(placeID)){
            currentCountry.placesIDs.push(placeID);
            currentCountry.count+=1;
          }
        }
        else{
            currentCountry.placesIDs=[placeID];
            currentCountry.count=1;
          }            

        //handle region
        let placeRegion;
        let placeCity;
        if (locationTree.placeRegion!==undefined){
          //region was selected
          let regionText = ""
          if (locationTree.placeRegion.treeID!==undefined){
            regionText=locationTree.placeRegion.treeID.split('_')[1];
          }
          else{
            regionText=locationTree.placeRegion.split('_')[1];
          }
            
         
          let currentRegion;

          let regionsList=[];
          if (currentCountry.regions!==undefined){
            regionsList = Object.keys(currentCountry.regions);
          }
          for (let i=0 ; i<regionsList.length ; i++){
            if(regionText===regionsList[i]){
              currentRegion = currentCountry.regions[regionText]
            }
          }

          
          //if new region
          if(currentRegion===undefined) {
              placeRegion = locationTree.placeRegion
              let placesIDs=[placeID];
              let count=1;
              placeRegion.type="region"
              if (placeRegion.inputText!==undefined) delete placeRegion.inputText
              placeRegion.placesIDs=placesIDs;
              placeRegion.count=count;
            }
          else{
              placeRegion = currentRegion
              if(placeRegion.placesIDs!==undefined){
                if (!placeRegion.placesIDs.includes(placeID)){
                  placeRegion.placesIDs.push(placeID);
                  placeRegion.count+=1;
                }
              }
              else{
                placeRegion.placesIDs=[placeID];
                placeRegion.count=1;
              }
              
            }
            if (locationTree.placeCity!==undefined){
              let cityText = ""
              if (locationTree.placeCity.treeID!==undefined){
                cityText=locationTree.placeCity.treeID.split('_')[1];
              }
              else{
                cityText=locationTree.placeCity.split('_')[1];
              }
              let currentCity;
    
              let citiesList=[];
              if (placeRegion.cities!==undefined){
                citiesList = Object.keys(placeRegion.cities);
              }
              for (let i=0 ; i<citiesList.length ; i++){
                if(cityText===citiesList[i]){
                  currentCity = placeRegion.cities[cityText]
                }
              }

              //if new city
              if(currentCity===undefined) {
                placeCity = locationTree.placeCity
                let placesIDs=[placeID];
                let count=1;
                placeCity.type="city"
                if (placeCity.inputText!==undefined) delete placeCity.inputText
                placeCity.placesIDs=placesIDs;
                placeCity.count=count;
              }
              else{
                placeCity = currentCity
                  if(placeCity.placesIDs!==undefined){
                    if (!placeCity.placesIDs.includes(placeID)){
                      placeCity.placesIDs.push(placeID);
                      placeCity.count+=1;
                    }
                  }
                  else{
                    placeCity.placesIDs=[placeID];
                    placeCity.count=1;
                  }
                  
              }
             
              //update region city
              if (placeRegion.cities===undefined){
                //first city
                placeRegion.cities = {}
                
              }
              placeRegion.cities[cityText] = placeCity
            }
             //update country region
             if (currentCountry.regions===undefined){
              //first city
              currentCountry.regions = {}
              
            }
            currentCountry.regions[regionText] = placeRegion
        }
        else{
          //if region not exist then city/area must to be exist
            let cityText = ""
            if (locationTree.placeCity.treeID!==undefined){
              cityText = locationTree.placeCity.treeID.split('_')[1];
            }
            else{
              cityText = locationTree.placeCity.split('_')[1];
            }
           
            let currentCity;
  
            let citiesList=[];
            if (currentCountry.cities!==undefined){
              citiesList = Object.keys(currentCountry.cities);
            }
            for (let i=0 ; i<citiesList.length ; i++){
              if(cityText===citiesList[i]){
                currentCity = currentCountry.cities[cityText]
              }
            }

            //if new city
            if(currentCity===undefined) {
              placeCity = locationTree.placeCity
              let placesIDs=[placeID];
              let count=1;
              placeCity.placesIDs=placesIDs;
              placeCity.count=count;
            }
            else{
              placeCity = currentCity
              if(placeCity.placesIDs!==undefined){
                if (!placeCity.placesIDs.includes(placeID)){
                  placeCity.placesIDs.push(placeID);
                  placeCity.count+=1;
                }
              }
              else{
                placeCity.placesIDs=[placeID];
                placeCity.count=1;
              }
                
            }

            //update country city
            if (currentCountry.cities===undefined){
              //first city
              currentCountry.cities = {}
              
            }
            currentCountry.cities[cityText] = placeCity
        }
        await this.updateToDatabase(path,currentCountry);
      resolve();
    });
  }

  deleteFromLocationTree = async (locationTree,placeID,temp) =>{
      
    return new Promise(async (resolve, reject) => {
      
      let countryTreeID=locationTree.placeCountry;
      let path = this.props.environment+"/systemData/systemLocationsTree/"+countryTreeID
      if (temp && locationTree.placeCountry.treeID!==undefined){
        countryTreeID=locationTree.placeCountry.treeID
        path = this.props.environment+"/systemData/systemLocationsTreeTemp/"+countryTreeID
        
      }
      let currentCountry;
      await firebase.database()
        .ref(path )
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            currentCountry = snapshot.val()
          }
        });
        if (currentCountry.placesIDs!==undefined){
          const placeCountryIndex = currentCountry.placesIDs.findIndex(countryPlaceID => {
            return countryPlaceID === placeID;
          });
          //let dbPath =  this.props.environment+"/systemData/systemLocationsTree/"+countryTreeID;
          if (placeCountryIndex!==-1){
            currentCountry.placesIDs.splice(placeCountryIndex,1);
            currentCountry.count-=1;
          }
          
        }
        

        if (locationTree.placeRegion!==undefined){
          //has region
          let regionID = locationTree.placeRegion
          if (temp && locationTree.placeRegion.treeID!==undefined){
            regionID=locationTree.placeRegion.treeID
          }
          let regionText=regionID.split('_')[1];
          let currentRegion;

          let regionsList=[];
          if (currentCountry.regions!==undefined){
            regionsList = Object.keys(currentCountry.regions);
          }
          for (let i=0 ; i<regionsList.length ; i++){
            if(regionText===regionsList[i]){
              currentRegion = currentCountry.regions[regionText]
            }
          }

          if (currentRegion!==undefined){
            if (currentRegion.placesIDs!==undefined){
              const placeRegionIndex = currentRegion.placesIDs.findIndex(regionPlaceID => {
                return regionPlaceID === placeID;
              });
              
              if(placeRegionIndex!==-1){
                currentRegion.placesIDs.splice(placeRegionIndex,1);
                currentRegion.count-=1;
              }
              
            }
          }
                    

          if (locationTree.placeCity!==undefined){
            let cityID = locationTree.placeCity
            if (temp && locationTree.placeCity.treeID!==undefined){
              cityID=locationTree.placeCity.treeID
            }
            let cityText=cityID.split('_')[1];
            let currentCity;
  
            let citiesList=[];
            if (currentRegion.cities!==undefined){
              citiesList = Object.keys(currentRegion.cities);
            }
            for (let i=0 ; i<citiesList.length ; i++){
              if(cityText===citiesList[i]){
                currentCity = currentRegion.cities[cityText]
              }
            }

            if (currentCity!==undefined){
              if( currentCity.placesIDs!==undefined){
                const placeCityIndex = currentCity.placesIDs.findIndex(cityPlaceID => {
                  return cityPlaceID === placeID;
                });
                if (placeCityIndex!==-1){
                  currentCity.placesIDs.splice(placeCityIndex,1);
                  currentCity.count-=1;
                }
               
              }
            }
            
            
            
            currentRegion.cities[cityText] = currentCity
          }
          currentCountry.regions[regionText] = currentRegion
        }
        else{
          //has only city
          let cityID = locationTree.placeCity
          if (temp && locationTree.placeCity.treeID!==undefined){
            cityID=locationTree.placeCity.treeID
          }
          let cityText=cityID.split('_')[1];
          let currentCity;

          let citiesList=[];
          if (currentCountry.cities!==undefined){
            citiesList = Object.keys(currentCountry.cities);
          }
          for (let i=0 ; i<citiesList.length ; i++){
            if(cityText===citiesList[i]){
              currentCity = currentCountry.cities[cityText]
            }
          }

          if (currentCity.placesIDs!==undefined){
            const placeCityIndex = currentCity.placesIDs.findIndex(cityPlaceID => {
              return cityPlaceID === placeID;
            });
            if (placeCityIndex!==-1){
              currentCity.placesIDs.splice(placeCityIndex,1);
              currentCity.count-=1;
            }
            
          }
          

          currentCountry.cities[cityText] = currentCity
        }
        await this.updateToDatabase(path,currentCountry);
        
      resolve();
    });

  }

  uploadImageToFirebase = async (isNew, objectID, path, objectImageGallery,imgSize) => {
    const storageRef = firebase.storage().ref();
    let newImagesUrls = [];
    let imageGallery = objectImageGallery;
    return new Promise(async (resolve, reject) => {
    if (isNew) {
      if (imageGallery.length === 0) {
        resolve(newImagesUrls);
      } else {
        for (let i = 0; i < imageGallery.length; i++) {
          const imagesRef = storageRef.child(
            path + objectID + "/" +imgSize+"/"+ imageGallery[i].name
          );
          await imagesRef.put(imageGallery[i]).then(t => {
            imagesRef
              .getDownloadURL()
              .then(url => {
                newImagesUrls.push({ name: imageGallery[i].name, url: url });
              })
              .then(e => {
                if (i === imageGallery.length - 1) {
                    resolve(newImagesUrls);
                }
              });
          });
        }
      }
    } else {
      if (imageGallery.length === 0) {
        resolve(newImagesUrls);
      } 
      else {
        let imagesToUpload = [];
        for (let i = 0; i < imageGallery.length; i++) {
          if (imageGallery[i].type === "image/jpeg"||imageGallery[i].type === "image/png") {
            const imagesRef = storageRef.child(
              path + objectID+ "/" +imgSize+"/"+ imageGallery[i].name
            );
            await imagesRef.put(imageGallery[i]).then(t => {
              imagesRef
                .getDownloadURL()
                .then(url => {
                  newImagesUrls[i]=({ name: imageGallery[i].name, url: url });
                    let allFinished = true;
                    for (let i=0;i<imageGallery.length ; i++){
                      if (newImagesUrls[i]===undefined){
                        allFinished=false;
                      }
                    }
                    if (allFinished){
                      resolve(newImagesUrls);
                    }
                /*  } */
                }).catch(error => {
                  console.log(error)
              });
                
            }).catch(error => {
              console.log(error)
          });
          } else {
            newImagesUrls[i]=(imageGallery[i]);
              let allFinished = true;
              for (let i=0;i<imageGallery.length ; i++){
                if (newImagesUrls[i]===undefined){
                  allFinished=false;
                }
              }
              if (allFinished){
                resolve(newImagesUrls);
              }
          }
        }
      }
    }
 });
};

deleteFromStorage =async (path, deletedImages) => {
  return new Promise(async (resolve, reject) => {
  const storageRef = firebase.storage().ref();
  let deleteRef;
  for (let i = 0; i < deletedImages.length; i++) {
    deleteRef = storageRef.child(path + "/" + deletedImages[i]);
    deleteRef
      .delete()
      .then(function() {
        // File deleted successfully
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
        return;
      });
  }
});
};

uploadFiles = async (path, attachedFiles) => {
  const storageRef = firebase.storage().ref();
  let filesUrls = [];
  let filesToUpload = [];
  return new Promise(async (resolve, reject) => {
    for (let i = 0; i < attachedFiles.length; i++) {
      if (attachedFiles[i].type === "application/pdf"||attachedFiles[i].type ==="image/jpeg"||attachedFiles[i].type ==="image/png") {
        filesToUpload.push(attachedFiles[i]);
      } else {
        filesUrls.push(attachedFiles[i]);
      }
    }
    //store the new images that user upload
    if (filesToUpload.length === 0) {
      resolve(filesUrls);
    } else {
      for (let i = 0; i < filesToUpload.length; i++) {
        const filesRef = storageRef.child(path + filesToUpload[i].name);
        await filesRef.put(filesToUpload[i]).then(t => {
          filesRef
            .getDownloadURL()
            .then(url => {
              filesUrls.push({ name: filesToUpload[i].name, url: url });
            })
            .then(e => {
              if (i === filesToUpload.length - 1) {
                resolve(filesUrls);
              }
            });
        });
      }
    }
  });
};

  createSlimPlace = async (place) =>{
    return new Promise(async (resolve, reject) => {
      let slimPlace ={
        placeID:place.placeID,
        placeName:place.placeName,
        owner:place.owner.ownerID,
        placeStatus:place.placeStatus,
        placeMainCategory:place.placeMainCategory,
        placeCategory:place.placeCategory,
        placeLanguages:place.placeLanguages
      }
      if (place.placeTags!==undefined){
        slimPlace.placeTags=place.placeTags
      }

      if (place.placeNewCategories!==undefined){
        slimPlace.placeNewCategories=place.placeNewCategories
      }
      let placeLocation = {
    
        coordinates:place.placeLocation.coordinates,
        locationTree:place.placeLocation.locationTree
      }
      if (place.placeLocation.placeID!==undefined){
        if (place.placeLocation.placeID!==null){
          placeLocation.placeID=place.placeLocation.placeID;
        }
      }
      
      slimPlace.placeLocation=placeLocation; 


      let placeScore = 0;
      let placeViewesFactor=1;
      let placeExperiencesFactor=1;
      let placeLikesFactor=1;
      let placeFavoriteFactor=1;
      
      placeScore = placeScore+placeViewesFactor*place.placeStats.viewed.count;
      placeScore = placeScore+placeExperiencesFactor*place.placeStats.usersExperienced;
      placeScore = placeScore+placeLikesFactor*place.placeStats.usersLiked;
      placeScore = placeScore+placeFavoriteFactor*place.placeStats.inspired.count;
      
      slimPlace.placeScore=placeScore;
  
      
      if ( place.imagePreview.length>0){
        slimPlace.imagePreview = {}
        slimPlace.imagePreview.smallImage = place.imagePreview[0].smallImage
        slimPlace.imagePreview.thumbnailImage = place.imagePreview[0].thumbnailImage
      }
      
  
      let placeCharacteristics = {};
      if (place.placeCharacteristics!==undefined){
        placeCharacteristics = place.placeCharacteristics
      }
      slimPlace.placeCharacteristics=placeCharacteristics 
  
      //open days
      let hoursRows=place.hoursRows;
      let openDays = [-1,-1,-1,-1,-1,-1,-1]
      for (let i=0 ; i<hoursRows.length ; i++){
        let rowHoursOpen = false
        let rowHoursClosed = false;
        if (hoursRows[i].hoursArray!==undefined){
          for (let j=0 ; j<hoursRows[i].hoursArray.length ; j++){
            if (hoursRows[i].hoursArray[j].open!=="Not specified"){
              rowHoursOpen = true
            }
            if (hoursRows[i].hoursArray[j].close!=="Not specified"){
              rowHoursOpen = true
            } 
          }
        }
          
        if (hoursRows[i].open24){
          rowHoursOpen = true
        }
        if (hoursRows[i].isClosed){
          rowHoursClosed = true
        }
        if (rowHoursOpen){
          for (let j=0 ; j<hoursRows[i].selectedDays.length ; j++){
            if (hoursRows[i].selectedDays[j]){
              openDays[j]=1;
            }
          }
        }
        if (rowHoursClosed){
          for (let j=0 ; j<hoursRows[i].selectedDays.length ; j++){
            if (hoursRows[i].selectedDays[j]){
              openDays[j]=0;
            }
          }
        }
      }
      slimPlace.openDays = openDays

      if (place.placePermanentlyClosed){
        slimPlace.placePermanentlyClosed=true
      }
      
      resolve(slimPlace);
    });
  }

   //userInteraction functions

   viewPlace = async (placeID,country) =>{
    return new Promise(async (resolve, reject) => {
    const now = new Date();
    this.setState({processing:true})
    let systemPlace;
    let placePath = this.props.environment + "/systemData/places/"+country+"/"+ placeID;
    await firebase.database()
    .ref( placePath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        systemPlace=snapshot.val();
      }
    });
    if (systemPlace===undefined){
      resolve({place:systemPlace,notExist:true});
      this.setState({processing:false})
      return;
    }
    else{
      resolve({place:systemPlace});
      this.setState({processing:false})
    }

    //======get additional data about place to present and update stats====
    // get place experiences
    let placeExperiences = [];
    let firestore = firebase.firestore()
    let latestExperiences = await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(placeID.toString()).collection('experiences').where('experiencePrivacy', '==', 'public').orderBy('experienceDate', 'desc').limit(3).get();
    latestExperiences.forEach(doc => {
      placeExperiences.push(doc.data());
    });
    let result = {
      list:placeExperiences,
      limit: 3
    }
    this.props.callBack("LoadPlaceExperiences",result)
    //if user is logged then update his and the place statistics
    //NEED TO HANDLE STATS WHEN USER IS NOT LOGGED IN

   
      let statsPath = placePath+ "/placeStats";
       await this.updateStatistics(
        statsPath,
        "viewed",
        this.props.userID,
        true
        ).then(result => {
          systemPlace.placeStats.viewed = result;
      });

      if (this.props.loggedUser){
        statsPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics";  
        this.updateStatistics(
          statsPath,
          "viewedPlaces",
          placeID,
          true
        );

        await this.updateLatestPlacesViewed(placeID,country).then(result => {
          if (result){
            this.createSlimPlace(systemPlace).then(result =>{
              this.props.callBack("AddLatestViewedPlaces",result)
            })
              
          }
        });
     
      //get personal notes
     /*  let personalNotes=[];
      let notesPath = this.props.environment+"/usersData/"+this.props.userID+"/placesPersonalNotes/"+placeID
        await firebase.database()
        .ref( notesPath)
        .once("value")
        .then(snapshot => {
          if (snapshot.val()!==null){
            //covering every country
            snapshot.forEach(child => {
              personalNotes.push(child.val());
            });
          }
          
        });
        personalNotes=await personalNotes.sort((a, b) => {
          var keyA = a.noteLastModified;
          var keyB = b.noteLastModified;
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        systemPlace.personalNotes=personalNotes; */
 
    }
    
    //update view place category and location tags
    let locationTags = [...systemPlace.placeLocation.locationTree.locationTreeIDs];
    let categoryTags = [...systemPlace.placeCategory];
    let tagsDBPaths = [];
    let tagsGlobalDBPaths =[];
    for (let i=0 ; i<locationTags.length ; i++){
      //individual
      tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+country+"/"+locationTags[i])
      tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+locationTags[i])
      //global
      tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorData/usersLocationsProfile/"+country+"/"+locationTags[i]);

      if (this.props.userResidenceCountry!==undefined){
        tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersLocationsProfile/"+country+"/"+locationTags[i])
      }
      
    }
    for (let i=0 ; i<categoryTags.length ; i++){
      //individual
      tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+country+"/"+categoryTags[i])
      tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+categoryTags[i])
      //global
      tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorData/usersCategoriesProfile/"+country+"/"+categoryTags[i]);

      if (this.props.userResidenceCountry!==undefined){
        tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersCategoriesProfile/"+country+"/"+categoryTags[i])
      }
      
    }
    if (this.props.loggedUser){
        this.updateBehaviorProfile(systemPlace.placeID,tagsDBPaths,"viewedPlace")
    }
    this.updateGlobalBehaviorProfile(this.props.userID,tagsGlobalDBPaths,"viewedPlace")

  });
  }

  addPlaceToFavorite = async (place,tags,newSave) =>{
    this.setState({processing:true})
    //check if already in favorite and this is updating 
    let favoritePlace;
    let country = ""
    if (place.placeStatus==="approved") {
      country = place.placeLocation.locationTree.placeCountry
    }
    else{
      country = place.placeLocation.locationTree.placeCountry.treeID
    }
    let favoritesPath = this.props.environment+"/usersData/"+this.props.userID+"/favorite/placesByCountry/"+country

    let date = new Date();
    let timestamp = date.getTime();
    if (newSave){
        let savedFavoritePlace={
            placeID: place.placeID,
            placeLastModified: timestamp,
            favoriteSearchTags: tags
          }
        await this.addToDatabase(favoritesPath, savedFavoritePlace, place.placeID);
        let statsPath = this.props.environment +  "/systemData/places/"+country+"/" + place.placeID + "/placeStats";
        await this.updateStatistics(
        statsPath,
        "inspired",
        this.props.userID,
        true
        ).then(result => {
          this.updateToDatabase(statsPath+"/viewed",result);
      });;

        this.props.callBack("AddFavoritePlace",{place:place,tags:tags})
        // this.props.updateList([],{place:place,tags:tags},"AddFavoritePlaceTags")

        //update specific user and system statistics..
        let locationTags = [...place.placeLocation.locationTree.locationTreeIDs];
        let categoryTags = [...place.placeCategory];
        let tagsDBPaths = [];
        let tagsGlobalDBPaths = [];
        for (let i=0 ; i<locationTags.length ; i++){
          //individual
          tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+country+"/"+locationTags[i])
          tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+locationTags[i])
          //global
          tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorData/usersLocationsProfile/"+country+"/"+locationTags[i]);
        
          if (this.props.userResidenceCountry!==undefined){
            tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersLocationsProfile/"+country+"/"+locationTags[i])
          }
        }
        for (let i=0 ; i<categoryTags.length ; i++){
          //individual
          tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+country+"/"+categoryTags[i])
          tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+categoryTags[i])
          //global
          tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorData/usersCategoriesProfile/"+country+"/"+categoryTags[i]);
        
          if (this.props.userResidenceCountry!==undefined){
            tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersCategoriesProfile/"+country+"/"+categoryTags[i])
          }
        }
        if (this.props.loggedUser){
            this.updateBehaviorProfile(place.placeID,tagsDBPaths,"addedToFavoritePlace")
        }
        
        this.updateGlobalBehaviorProfile(this.props.userID,tagsGlobalDBPaths,"addedToFavoritePlace")
    }
    else{
        await firebase.database()
        .ref( favoritesPath+"/"+place.placeID)
        .once("value")
        .then(snapshot => {
            if (snapshot!==null){
                favoritePlace=snapshot.val()
              }
        });

        favoritePlace.favoriteSearchTags=tags;
        favoritePlace.placeLastModified=timestamp;
        await this.updateToDatabase(favoritesPath+"/"+place.placeID,favoritePlace);

        //update favorite places
        //this.props.updateList([],{place:place,tags:tags},"EditFavoritePlaceTags")
        this.props.callBack("UpdateFavoritePlace",{place:place,tags:tags})
    }

    this.setState({processing:false})
  }

  addPlanToFavorite = async (plan,tags,newSave) =>{
    this.setState({processing:true})
    let planID = plan.planID
    let favoritePlan;
    let favoritesPath = this.props.environment+"/usersData/"+this.props.userID+"/favorite/trips"
    let date = new Date();
    let timestamp = date.getTime();

    //check if plan still exist
    let dbPlan;
    let userID = planID.split('_')[1]
    let planPath = this.props.environment+"/usersData/"+userID+"/userPlans/"+planID;
    await firebase.database()
    .ref(planPath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        dbPlan = snapshot.val()
      }
    });
    if (dbPlan===undefined){
      this.props.callBack("TripNotExist");
      this.setState({processing:false})
      return;
    }
    if (newSave){
      let savedFavoritePlan={
          planID: plan.planID,
          planLastModified: timestamp,
          favoriteSearchTags: tags
        }
      await this.addToDatabase(favoritesPath, savedFavoritePlan, plan.planID);

      //update stats - both slim trip and if public then public as well
      let userID;
      userID = planID.split('_')[1]
      let statsPath = this.props.environment+"/usersData/"+userID+"/userPlans/"+planID + "/tripStats";
      await this.updateStatistics(
      statsPath,
      "inspired",
      this.props.userID,
      true
      );

      this.props.callBack("AddFavoriteTrip",{plan:plan,tags:tags})
    }
    else{
      await firebase.database()
      .ref( favoritesPath+"/"+plan.planID)
      .once("value")
      .then(snapshot => {
          if (snapshot!==null){
            favoritePlan=snapshot.val()
            }
      });

      favoritePlan.favoriteSearchTags=tags;
      favoritePlan.planLastModified=timestamp;
      await this.updateToDatabase(favoritesPath+"/"+plan.planID,favoritePlan);

      this.props.callBack("UpdateFavoriteTrip",{plan:plan,tags:tags})
    } 
    this.setState({processing:false})
  }

  removePlanFromFavorite = async (plan)=>{
    this.setState({processing:true})
    let planID = plan.planID
    let favoritesPath = this.props.environment+"/usersData/"+this.props.userID+"/favorite/trips/"+planID
    this.deleteDataFromDB(favoritesPath);

    this.props.callBack("RemoveTripFromFavorite",plan)
    //remove from DB
    let userID;
    userID = planID.split('_')[1]
    let statsPath = this.props.environment+"/usersData/"+userID+"/userPlans/"+planID + "/tripStats";
    await this.updateStatistics(
      statsPath,
      "inspired",
      this.props.userID,
      false
    );
    
    this.setState({processing:false})
  }


  removePlaceFromFavorite = async (place)=>{
    this.setState({processing:true})
    let placeCountry = ""
    if (place.placeStatus==="approved") {
      placeCountry = place.placeLocation.locationTree.placeCountry
    }
    else{
      placeCountry = place.placeLocation.locationTree.placeCountry.treeID
    }

    let favoritesPath = this.props.environment+"/usersData/"+this.props.userID+"/favorite/placesByCountry/"+placeCountry+"/"+place.placeID;
    this.deleteDataFromDB(favoritesPath);

    this.props.callBack("RemovePlaceFromFavorite",place)
    //remove from DB
    let statsPath = this.props.environment +  "/systemData/places/"+placeCountry+"/" + place.placeID + "/placeStats";
    await this.updateStatistics(
      statsPath,
      "inspired",
      this.props.userID,
      false
    );
    
    this.setState({processing:false})
  }

  updateUserSettings = async (userSettings)=>{
    await this.setState({processing:true})
    let personalInfoChanged = userSettings.personalInfoChanged;
    delete userSettings.personalInfoChanged 
    let displayName = userSettings.displayName;
    delete userSettings.displayName
    let photoUrl ;

    let storagePath = 
    this.props.environment+"/usersData/"+this.props.userID+"/userSettings/"
    if (userSettings.profileImage.imageFile!==undefined){
        let largeImages =  [userSettings.profileImage.imageFile.largeImage]
        let smallImages = [userSettings.profileImage.imageFile.smallImage]; 
        let savedLargeImages=[];
        let savedSmallImages=[];
        await this.uploadImageToFirebase(true,"profileImage",storagePath,largeImages,"largeImage").then(result => {
            savedLargeImages = result;
          }); 
        await this.uploadImageToFirebase(true,"profileImage",storagePath, smallImages,"smallImage").then(result => {
            savedSmallImages = result;
        }); 
        userSettings.profileImage.largeImage = savedLargeImages[0]
        userSettings.profileImage.smallImage = savedSmallImages[0]
        userSettings.profileImage.url=savedSmallImages[0].url
        photoUrl=userSettings.profileImage.smallImage.url

      delete userSettings.profileImage.imageFile;
    }
    else if (photoUrl=userSettings.profileImage.smallImage!==undefined){
      photoUrl=userSettings.profileImage.smallImage.url
    }
    else if(userSettings.profileImage.url!==null&&userSettings.profileImage.url!==undefined){
      photoUrl=userSettings.profileImage.url
    }
    else{
      photoUrl=null
    }
    

    let dbPath = this.props.environment+"/usersData/"+this.props.userID+"/userInfo/userSettings";
    this.updateToDatabase(dbPath,userSettings);

    let existingUserObject;
    let userObjectPath =  this.props.environment+"/users/"+this.props.userID
    await firebase
    .database()
    .ref(userObjectPath)
    .once("value")
    .then(snapshot => {
      if (snapshot!==null){
        existingUserObject=snapshot.val()
      }
    });
    existingUserObject.photoUrl=photoUrl
    existingUserObject.displayName = displayName
    existingUserObject.userCountry = userSettings.residenceCountry
    this.updateToDatabase(userObjectPath,existingUserObject);
    //upadte all relevant content - userCreatedExperiences, userCreatedTrips (incl. shared), all friends and followers
    //NEED TO BE DONE ON A SERVER SIDE
    if (personalInfoChanged){
      let allUserExperiences = [];
      const snapshot = await firebase.firestore().collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences').get();
      snapshot.forEach(doc => {
        allUserExperiences.push(doc.data());
      });
      for (let i=0 ; i<allUserExperiences.length ; i++){
        let experience = allUserExperiences[i]
        let owner = experience.owner
        owner.ownerName = displayName
        owner.ownerPhoto = photoUrl
        experience.owner = owner
        if (experience.experiencePlanID!==undefined){
          await firebase.firestore().collection(this.state.firestorePrefix+'plansExperiences').doc(experience.experiencePlanID).collection('experiences').doc(experience.experienceID.toString()).set(experience);
        }
        await firebase.firestore().collection(this.state.firestorePrefix+'placesExperiences').doc(experience.experiencePlaceID).collection('experiences').doc(experience.experienceID.toString()).set(experience);
        await firebase.firestore().collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences').doc(experience.experienceID.toString()).set(experience);
      }
    //update plans
    let allPlansIDs = [];
    await firebase.database()
    .ref(  this.props.environment +
      "/usersData/" +
      this.props.userID +
      "/userInfo/statistics/tripPlans/list")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        allPlansIDs=snapshot.val()
      }
    });
    for (let i =0 ;i<allPlansIDs.length ; i ++){
      let planOwnerID = allPlansIDs[i].split('_')[1]
      let planEditors;
      let dbPlanPath = this.props.environment+"/usersData/"+planOwnerID+"/userPlans/"+allPlansIDs[i]+"/planEditors";
      await firebase.database()
      .ref( dbPlanPath)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          planEditors = snapshot.val()
        }
      });
      const editorIndex = planEditors.findIndex(editor => {
        return editor.userID === this.props.userID
      });
      if (editorIndex!==-1){
        let editor = planEditors[editorIndex]
        editor.displayName = displayName
        editor.photoUrl = photoUrl
        planEditors[editorIndex] = editor
        await this.updateToDatabase(dbPlanPath,planEditors);
        let slimPlanPath = this.props.environment+"/usersData/"+planOwnerID+"/userSlimPlans/"+allPlansIDs[i]+"/planEditors";
        await this.updateToDatabase(slimPlanPath,planEditors);
      }

     
    }

    //update friends
    let allUserFriendsIDs = []
    await firebase.database()
    .ref(  this.props.environment +
      "/usersData/" +
      this.props.userID +
      "/userInfo/friends")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        allUserFriendsIDs=Object.keys(snapshot.val())
      }
    });
    for (let i=0 ; i<allUserFriendsIDs.length ; i++){
      let friendPathName = this.props.environment +
      "/usersData/" +
      allUserFriendsIDs[i] +
      "/userInfo/friends/"+this.props.userID+"/displayName"
        await this.updateToDatabase(friendPathName,displayName);
        let friendPathPhoto = this.props.environment +
        "/usersData/" +
        allUserFriendsIDs[i] +
        "/userInfo/friends/"+this.props.userID+"/photoUrl"
        await this.updateToDatabase(friendPathPhoto,photoUrl);
     } 
    } 
    

    var user = firebase.auth().currentUser;
    await user
      .updateProfile({
        photoURL: photoUrl,
        displayName: displayName
      })
      .then(function() {
        // Update successful.
      })
      .catch(function(error) {
        // An error happened.
      });
    this.props.callBack("Reload")
    this.setState({processing:false})

  }

  //=====FRIENDS========
  followUser = async friend =>{
    await this.setState({processing:true})
    
    let currentUser = this.props.user;
    let newFriend = friend;

    delete newFriend.lastActivity;
    delete newFriend.registerDate;
    delete currentUser.lastActivity;
    delete currentUser.registerDate;
  
    //add to current user friends list
    let myPath =  this.props.environment +
    "/usersData/" +
    this.props.userID +
    "/userInfo/following/"+newFriend.userID
    await this.updateToDatabase(myPath, newFriend);

    //update current user following list
    let currentUserFollowingList = []
    await firebase.database()
    .ref(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        currentUserFollowingList=snapshot.val();
      }
    });
    currentUserFollowingList.push(newFriend.userID)
    currentUserFollowingList = [...new Set(currentUserFollowingList)];
    await this.updateToDatabase(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs", currentUserFollowingList);
  
  
    let otherUserPath =  this.props.environment +
    "/usersData/" +
    newFriend.userID +
    "/userInfo/followedBy/"+this.props.userID
    await this.updateToDatabase(otherUserPath, currentUser);
  

    this.props.callBack("NewFollowing",newFriend)
    await this.setState({processing:false})
  }

  stopFollow  = async friend =>{
    await this.setState({processing:true})
    

    let removedUser = friend;
  
    //add to current user friends list
    let myPath =  this.props.environment +
    "/usersData/" +
    this.props.userID +
    "/userInfo/following/"+removedUser.userID
    await this.deleteDataFromDB(myPath);

    //update current user following list
    let currentUserFollowingList = []
    await firebase.database()
    .ref(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        currentUserFollowingList=snapshot.val();
      }
    });
    const currentIndex = currentUserFollowingList.findIndex(userID => {
     return userID === removedUser.userID;
   });
    currentUserFollowingList.splice(currentIndex,1)
    await this.updateToDatabase(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs", currentUserFollowingList);
  
  
    let otherUserPath =  this.props.environment +
    "/usersData/" +
    removedUser.userID +
    "/userInfo/followedBy/"+this.props.userID
    await this.deleteDataFromDB(otherUserPath);
  

    this.props.callBack("StopFollowing",removedUser)
    await this.setState({processing:false})
  }


  friendRequest = async (friend) =>{
    await this.setState({processing:true})
    
    let currentUser = this.props.user;
    let newFriend = friend;

    delete newFriend.lastActivity;
    delete newFriend.registerDate;
    delete currentUser.lastActivity;
    delete currentUser.registerDate;
  
    newFriend.status="Pending"
    //add to current user friends list
    let myPath =  this.props.environment +
    "/usersData/" +
    this.props.userID +
    "/userInfo/friends/"+newFriend.userID
    await this.updateToDatabase(myPath, newFriend);
  
    currentUser.status="New Request";
    let otherUserPath =  this.props.environment +
    "/usersData/" +
    newFriend.userID +
    "/userInfo/friends/"+this.props.userID
    await this.updateToDatabase(otherUserPath, currentUser);
  

    this.props.callBack("FriendRequest",newFriend)
    await this.setState({processing:false})
  }
  
  acceptFriendship = async (friend) =>{
    await this.setState({processing:true})
    let currentUser = this.props.user;
    let newFriend = friend;
    delete newFriend.lastActivity;
    delete newFriend.registerDate;
    delete currentUser.lastActivity;
    delete currentUser.registerDate;
  
    newFriend.status="Friend"
    //add to current user friends list
    let myPath =  this.props.environment +
    "/usersData/" +
    this.props.userID +
    "/userInfo/friends/"+newFriend.userID
    await this.updateToDatabase(myPath, newFriend);
    //update current user following list
    let currentUserFollowingList = []
    await firebase.database()
    .ref(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        currentUserFollowingList=snapshot.val();
      }
    });
    currentUserFollowingList.push(newFriend.userID)
    currentUserFollowingList = [...new Set(currentUserFollowingList)];
    await this.updateToDatabase(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs", currentUserFollowingList);
  
    currentUser.status="Friend";
    let otherUserPath =  this.props.environment +
    "/usersData/" +
    newFriend.userID +
    "/userInfo/friends/"+this.props.userID
    await this.updateToDatabase(otherUserPath, currentUser);
    //update new friend following list
    let friendUserFollowingList = []
    await firebase.database()
    .ref(this.props.environment+"/usersData/"+newFriend.userID+"/userInfo/followingUsersIDs")
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        //covering every country
        friendUserFollowingList=snapshot.val();
      }
    });
    friendUserFollowingList.push(this.props.userID)
    friendUserFollowingList = [...new Set(friendUserFollowingList)];
    await this.updateToDatabase(this.props.environment+"/usersData/"+newFriend.userID+"/userInfo/followingUsersIDs", friendUserFollowingList);
    
    this.props.callBack("AcceptFriendship",newFriend)
    await this.setState({processing:false})
  }

  removeFriend = async (friend)=>{
    await this.setState({processing:true})
    let removeFriend = friend;
   
  
    //add to current user friends list
    let myPath =  this.props.environment +
    "/usersData/" +
    this.props.userID +
    "/userInfo/friends/"+removeFriend.userID
    await this.deleteDataFromDB(myPath);

     //update current user following list
     let currentUserFollowingList = []
     await firebase.database()
     .ref(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs")
     .once("value")
     .then(snapshot => {
       if (snapshot.val()!==null){
         //covering every country
         currentUserFollowingList=snapshot.val();
       }
     });
     const currentIndex = currentUserFollowingList.findIndex(userID => {
      return userID === removeFriend.userID;
    });
     currentUserFollowingList.splice(currentIndex,1)
     await this.updateToDatabase(this.props.environment+"/usersData/"+this.props.userID+"/userInfo/followingUsersIDs", currentUserFollowingList);
   
  
    
    let otherUserPath =  this.props.environment +
    "/usersData/" +
    removeFriend.userID +
    "/userInfo/friends/"+this.props.userID
    await this.deleteDataFromDB(otherUserPath);

    let friendUserFollowingList = []
     await firebase.database()
     .ref(this.props.environment+"/usersData/"+removeFriend.userID+"/userInfo/followingUsersIDs")
     .once("value")
     .then(snapshot => {
       if (snapshot.val()!==null){
         //covering every country
         friendUserFollowingList=snapshot.val();
       }
     });
     const friendIndex = friendUserFollowingList.findIndex(userID => {
      return userID === this.props.userID;
    });
    friendUserFollowingList.splice(friendIndex,1)
     await this.updateToDatabase(this.props.environment+"/usersData/"+removeFriend.userID+"/userInfo/followingUsersIDs", friendUserFollowingList);
   
  
   
    this.props.callBack("RemoveFriend",friend)
    await this.setState({processing:false})
  }



  //==========PLACES===============

  createPlace =async  (place) =>{
    await this.setState({processing:true})
    this.setState({progress:2,showProgress:true})
    let inFavorite = place.inFavorite;
    let placeCountryText=place.placeLocation.locationTree.placeCountry.treeID;
    const today = new Date();
    let start = today.getTime();
    //store new place images to the storage
    let storagePath = 
    this.props.environment + "/systemPhotos/places/"+placeCountryText+"/";
    let largeImages =  place.imageGallery.map(img=>{return img.largeImage});
    let smallImages = place.imageGallery.map(img=>{return img.smallImage}); 
    let mediumImages =  place.imageGallery.map(img=>{return img.mediumImage});
    let thumbnailImages = place.imageGallery.map(img=>{return img.thumbnailImage}); 
    let savedLargeImages=[];
    let savedSmallImages=[];
    let savedMediumImages=[];
    let savedThumbnailImages=[];
    await this.uploadImageToFirebase(true,place.placeID,storagePath,largeImages,"largeImage").then(result => {
      savedLargeImages = result;
    }); 
    this.setState({progress:20})
    await this.uploadImageToFirebase(true,place.placeID,storagePath, smallImages,"smallImage").then(result => {
      savedSmallImages = result;
    }); 
    this.setState({progress:40})
    await this.uploadImageToFirebase(true,place.placeID,storagePath,mediumImages,"mediumImage").then(result => {
      savedMediumImages = result;
    }); 
    this.setState({progress:60})
    await this.uploadImageToFirebase(true,place.placeID,storagePath, thumbnailImages,"thumbnailImage").then(result => {
      savedThumbnailImages = result;
    }); 
    this.setState({progress:70})
   /*  console.log(new Date()-start) */
    place.imagePreview=[];
    place.imageGallery=[]
    for (let i=0 ; i<savedLargeImages.length ; i++){
      place.imagePreview.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
        mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
      place.imageGallery.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
        mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
    }


    //saving the place to the database under systemData
    let placePath = this.props.environment + "/systemData/places/"+placeCountryText;
    var dbPlace = { ...place };
    delete dbPlace.inFavorite;
    delete dbPlace.favoriteSearchTags;
    
    await this.addToDatabase(placePath, dbPlace, place.placeID);

    //create slim version of the place
   let slimPlace={}
   await this.createSlimPlace(place).then(result =>{
     slimPlace = result
   })

    let slimPath = this.props.environment + "/systemData/slimPlacesData/"+placeCountryText;
    await this.addToDatabase(slimPath, slimPlace, place.placeID);

    let placeCreationVersion ={
      placeID:place.placeID,
      placeName:place.placeName,
    }

    let createVersionPlaceLocation = {
      placeID:place.placeLocation.placeID,
      coordinates:place.placeLocation.coordinates
    }
    placeCreationVersion.placeLocation=createVersionPlaceLocation 
    let createVersionPath = this.props.environment + "/systemData/placeCreationData/placesListForPlaceCreation/"+placeCountryText+"/"+place.placeID;
    await this.updateToDatabase(createVersionPath,placeCreationVersion);

    this.setState({progress:80})
    //because place need to be approved it is only available under user personal space
    //the list and search tags will be updated in main page similar to favorites update
    //also if added to favorites it will be updating the favorites list as well

    await this.updateSystemLocationsTree(place.placeLocation.locationTree,place.placeID,true)
    this.setState({progress:90})
    //handle adding to favorites
   if (inFavorite){ 
      //updating that it was saved to favorites 
      let statsPath = placePath+"/" + place.placeID + "/placeStats";
      await this.updateStatistics(
        statsPath,
        "inspired",
        this.props.userID,
        true
      ).then(result => {
        place.placeStats.inspired = result;
      });

      let favoritesPath = this.props.environment+"/usersData/"+this.props.userID+"/favorite/placesByCountry/"+placeCountryText

      let savedFavoritePlace={
      placeID: place.placeID,
      placeLastModified: place.placeLastModified,
      favoriteSearchTags: []
      }

      this.addToDatabase(favoritesPath, savedFavoritePlace, place.placeID);
  } 

  //update latest places viewed
  await this.updateLatestPlacesViewed(place.placeID,placeCountryText).then(result => {
    if (result){
        this.props.callBack("AddLatestViewedPlaces",slimPlace)
    }
  });

  
   //send to main page the place to be updated - for user creation completed
   this.props.callBack("CreatePlace",{place:slimPlace,inFavorite:inFavorite})
   this.setState({processing:false,showProgress:false})

   this.props.finishWriteDataMessage("createPlaceFinished");
 
  //Update user statistics
  let statsPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics/createdPlaces";  
  this.updateStatistics(
    statsPath,
    placeCountryText,
    place.placeID,
    true
  );

  //update create place metric
  let date = new Date();
  const dateMetric = moment(date);
  let dateString = dateMetric.format('DD-MM-YYYY').toString();
  let metricPath = this.props.environment +  "/systemMetrics/createdContent/places/"+dateString;
  await this.updateStatistics(metricPath,"totalPlaces", place.placeID, true);
  metricPath = this.props.environment +  "/systemMetrics/createdContent/places/"+dateString;
  await this.updateStatistics(metricPath,"uniqueUsers",this.props.userID, true);

  if (inFavorite){ 
    //handle the behavior tags for adding place to favorites - personal and global
    let locationTags = [...place.placeLocation.locationTree.locationTreeIDs];
    let categoryTags = [...place.placeCategory];
    let tagsDBPaths = [];
    let tagsGlobalDBPaths=[];
    for (let i=0 ; i<locationTags.length ; i++){
      //individual
      tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+locationTags[i])
      tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+placeCountryText+"/"+locationTags[i])
      //global
      tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorData/usersLocationsProfile/"+placeCountryText+"/"+locationTags[i]);
    
      if (this.props.userResidenceCountry!==undefined){
        tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersLocationsProfile/"+placeCountryText+"/"+locationTags[i])
      }
    }
    for (let i=0 ; i<categoryTags.length ; i++){
      //individual
      tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+categoryTags[i])
      tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+placeCountryText+"/"+categoryTags[i])
      //global
      tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorData/usersCategoriesProfile/"+placeCountryText+"/"+categoryTags[i]);
    
      if (this.props.userResidenceCountry!==undefined){
        tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersCategoriesProfile/"+placeCountryText+"/"+categoryTags[i])
      }
    }
    this.updateBehaviorProfile(place.placeID,tagsDBPaths,"addedToFavoritePlace")

    this.updateGlobalBehaviorProfile(this.props.userID,tagsGlobalDBPaths,"addedToFavoritePlace")
  }  

}

updatePlace = async (place) =>{
  await this.setState({processing:true})
  this.setState({progress:2, showProgress:true})
  let placeID = place.placeID;
  let placeCountry = ""
  if (place.placeStatus==="approved") {
    placeCountry = place.placeLocation.locationTree.placeCountry
  }
  else{
    placeCountry = place.placeLocation.locationTree.placeCountry.treeID
  }

  let placePath = this.props.environment + "/systemData/places/"+placeCountry+"/"+placeID;
  let systemPlace={};
  await firebase.database()
  .ref( placePath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      systemPlace = snapshot.val()
    }
  });
  let wasPotentialPlace = systemPlace.placeStatus==="potential"
  let oldPlaceLocation = JSON.parse(JSON.stringify(systemPlace.placeLocation));
  systemPlace.placeLanguages = place.placeLanguages
  systemPlace.placeName=place.placeName;
  systemPlace.placeLastModified=place.placeLastModified;
  systemPlace.placeStatus=place.placeStatus
  systemPlace.placeDescription=place.placeDescription;
  systemPlace.placeLinks=[...place.placeLinks];
  systemPlace.hoursRows = [...place.hoursRows];
  systemPlace.placeMainCategory = [...place.placeMainCategory];
  systemPlace.placeCategory = [...place.placeCategory];
  systemPlace.placeNewCategories = [...place.placeNewCategories];
  systemPlace.placeTags = [...place.placeTags];
  systemPlace.placeCharacteristics = place.placeCharacteristics;
  systemPlace.placeTemporaryClosed = place.placeTemporaryClosed;
  systemPlace.temporaryClosedNote = place.temporaryClosedNote;
  systemPlace.placePermanentlyClosed = place.placePermanentlyClosed;
  if (place.permanentlyClosedDate!==undefined){
    systemPlace.permanentlyClosedDate = place.permanentlyClosedDate;
  }
  if (place.contactInfo!==undefined){
    systemPlace.contactInfo = place.contactInfo;
  }
  if (place.navigation!==undefined){
    systemPlace.navigation = place.navigation;
  }
  systemPlace.placeTags = [...place.placeTags];
  systemPlace.placeLocation=place.placeLocation;
  if (place.placeGoogleRating!==undefined){
    systemPlace.placeGoogleRating = place.placeGoogleRating;
  }
  

  //handle updating and deleting images
  let storagePath = 
  this.props.environment + "/systemPhotos/places/"+placeCountry+"/";

  if (place.deletedImages.length>0){
    if (place.deletedImages[0]!==undefined){
     // let deletePathOld =  storagePath+placeID;
      let deletePathSmall =  storagePath+placeID+"/smallImage";
      let deletePathLarge =  storagePath+placeID+"/largeImage";
      let deletePathMedium =  storagePath+placeID+"/mediumImage";
      let deletePathThumbnail =  storagePath+placeID+"/thumbnailImage";
      let deletedImages = [...place.deletedImages].map(img=>{return img.name});
      this.deleteFromStorage(deletePathSmall,deletedImages);
      this.deleteFromStorage(deletePathLarge,deletedImages);
      this.deleteFromStorage(deletePathMedium,deletedImages);
      this.deleteFromStorage(deletePathThumbnail,deletedImages);
    }
  
  } 

  let largeImages =  place.imageGallery.map(img=>{return img.largeImage});
  let smallImages = place.imageGallery.map(img=>{return img.smallImage}); 
  let mediumImages =  place.imageGallery.map(img=>{return img.mediumImage});
  let thumbnailImages = place.imageGallery.map(img=>{return img.thumbnailImage}); 
  let savedLargeImages=[];
  let savedSmallImages=[];
  let savedMediumImages=[];
  let savedThumbnailImages=[];
  await this.uploadImageToFirebase(false,place.placeID,storagePath,largeImages,"largeImage").then(result => {
    savedLargeImages = result;
  }); 
  this.setState({progress:20})
  await this.uploadImageToFirebase(false,place.placeID,storagePath, smallImages,"smallImage").then(result => {
    savedSmallImages = result;
}); 
this.setState({progress:30})
await this.uploadImageToFirebase(false,place.placeID,storagePath,mediumImages,"mediumImage").then(result => {
  savedMediumImages = result;
}); 
this.setState({progress:40})
await this.uploadImageToFirebase(false,place.placeID,storagePath, thumbnailImages,"thumbnailImage").then(result => {
  savedThumbnailImages = result;
}); 
this.setState({progress:50})

  place.imagePreview=[];
  place.imageGallery=[]
  systemPlace.imagePreview = [];
  systemPlace.imageGallery = [];
  for (let i=0 ; i<savedLargeImages.length ; i++){
    place.imagePreview.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
      mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
    place.imageGallery.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
      mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
    systemPlace.imagePreview.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
      mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
    systemPlace.imageGallery.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
      mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
    }
 
  this.setState({progress:60})
  this.updateToDatabase(placePath,systemPlace);

  //create slim version of the place
  let slimPlace={}
  await this.createSlimPlace(place).then(result =>{
    slimPlace = result
  })

  let slimPath = this.props.environment + "/systemData/slimPlacesData/"+placeCountry+"/"+place.placeID;
  await this.updateToDatabase(slimPath, slimPlace );

  if (slimPlace.placeStatus==="approved"){
    await firebase.firestore().collection(this.props.environment+'systemPlaces').doc(placeCountry).collection('countryPlaces').doc(slimPlace.placeID.toString()).set(slimPlace);
  }
  

  //if approved update place creation version
/*   if (place.status === "approved"){ */
    let placeCreationVersion ={
      placeID:place.placeID,
      placeName:place.placeName,
    }
  
    let createVersionPlaceLocation = {
      placeID:place.placeLocation.placeID,
      coordinates:place.placeLocation.coordinates
    }
    placeCreationVersion.placeLocation=createVersionPlaceLocation 
    let createVersionPath = this.props.environment + "/systemData/placeCreationData/placesListForPlaceCreation/"+placeCountry+"/"+place.placeID;
    await this.updateToDatabase(createVersionPath,placeCreationVersion);
/*   } */
  this.setState({progress:70})

  //update latest places viewed
  await this.updateLatestPlacesViewed(place.placeID,placeCountry).then(result => {
    if (result){
        this.props.callBack("AddLatestViewedPlaces",slimPlace)
    }
    else{
      //update that place
      this.props.callBack("UpdateLatestViewedPlaces",slimPlace)
    }
  });
  
   if (place.locationWasUpdated){
     //removing the old one, either from temp tree or final tree
    await this.deleteFromLocationTree(oldPlaceLocation.locationTree,place.placeID,wasPotentialPlace)
    this.setState({progress:80})
    //adding the new location tree
    //if location was update place will be again stored under temp tree
    await this.updateSystemLocationsTree(place.placeLocation.locationTree,place.placeID,true)

   }
   this.setState({progress:90})
  //update selected place with experiences, highlights, inFavorite
  this.props.callBack("UpdatePlace",{slimPlace:slimPlace,systemPlace:systemPlace})

  this.setState({processing:false,showProgress:false})
  this.props.finishWriteDataMessage("updatePlaceFinished");
}

deletePlace = async (place)=>{
  await this.setState({processing:true})
  
  let userID = place.owner;
  let placeID = place.placeID;
  let placeCountry = ""
  if (place.placeStatus==="approved") {
    placeCountry = place.placeLocation.locationTree.placeCountry
  }
  else{
    placeCountry = place.placeLocation.locationTree.placeCountry.treeID
  }
  let placePath = this.props.environment + "/systemData/places/"+placeCountry+"/"+placeID;
  let systemPlace={};
  await firebase.database()
  .ref( placePath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      systemPlace = snapshot.val()
    }
  });
  let tempPlace = systemPlace.placeStatus==="potential"
  let allowDelete="Allow";

  if (systemPlace.placeStats.totalExperiences!==0){
    allowDelete = "hasExperiences";
  }
  if (systemPlace.placeStats.inPlan!==undefined){
    if (systemPlace.placeStats.inPlan.count!==0){
      allowDelete = "existInPlans";
    }
  }
  if (systemPlace.placeStats.inspired.count>1){
    allowDelete = "hasFavorites";
  }
  if (systemPlace.placeStats.inspired.count===1&&systemPlace.owner.ownerID!==this.props.userID&&!this.props.adminUser){
    allowDelete = "hasFavorites";
  }
  if (allowDelete==="Allow"){
    

    //delete the place from db

    await this.deleteDataFromDB(placePath);

    let slimPath = this.props.environment + "/systemData/slimPlacesData/"+placeCountry+"/"+placeID;
    await this.deleteDataFromDB(slimPath);

    if (systemPlace.placeStatus==="approved"){
      await firebase.firestore().collection(this.props.environment+'systemPlaces').doc(placeCountry).collection('countryPlaces').doc(placeID.toString()).delete();
    }
    

    let createVersionPath = this.props.environment + "/systemData/placeCreationData/placesListForPlaceCreation/"+placeCountry+"/"+place.placeID;
    await this.deleteDataFromDB(createVersionPath);

    //delete location tree placeID and reduce counter
    await this.deleteFromLocationTree(place.placeLocation.locationTree,place.placeID,tempPlace)

    //delete the images from storage
    let storagePath = 
    this.props.environment + "/systemPhotos/places/";
    if (systemPlace.imagePreview!==undefined){
        let deletePathSmall =  storagePath+placeID+"/smallImage";
        let deletePathMedium =  storagePath+placeID+"/mediumImage";
        let deletePathLarge =  storagePath+placeID+"/largeImage";
        let deletePathThumbnail =  storagePath+placeID+"/thumbnailImage";
        let deletedImages = [...systemPlace.imagePreview.map(img=>{return img.smallImage.name})];
        this.deleteFromStorage(deletePathSmall,deletedImages);
        this.deleteFromStorage(deletePathMedium,deletedImages);
        this.deleteFromStorage(deletePathLarge,deletedImages);
        this.deleteFromStorage(deletePathThumbnail,deletedImages);
    } 


    //if in my favorite then delete it from my favorite
    if (place.inFavorite){
      let favoritesPath = this.props.environment+"/usersData/"+userID+"/favorite/placesByCountry/"+placeCountry+"/"+placeID;
      this.deleteDataFromDB(favoritesPath);
    }
     //Update user statistics
    let statsPath = this.props.environment + "/usersData/" + userID + "/userInfo/statistics/createdPlaces";  
    this.updateStatistics(
      statsPath,
      placeCountry,
      placeID,
      false
    );

    //remove from recommendations lists
    let recommendationPath = this.props.environment + "/systemData/placesSortedByCategory/"+placeCountry

    //update user main page
    this.props.callBack("DeletePlace", place.placeID)
  }
  else {
   // this.props.deleteNotAllowedMessage(allowDelete)
  }
  this.setState({processing:false})
}

submitPlaceChanges =async (place)=>{
  await this.setState({processing:true})
  this.setState({progress:2})
  let placeCountry = ""
  //This is not possible scenario as while it potential place only admin or place creator can edit and they don't have suggestion option
  if (place.placeStatus==="approved") {
    placeCountry = place.placeLocation.locationTree.placeCountry
  }
  else{
    placeCountry = place.placeLocation.locationTree.placeCountry.treeID
  }
   //store new place images to the storage
   let storagePath = 
    this.props.environment + "/systemPhotos/placesEditSuggestions/"+placeCountry+"/";
    let largeImages =  place.imageGallery.map(img=>{return img.largeImage});
    let smallImages = place.imageGallery.map(img=>{return img.smallImage}); 
    let mediumImages =  place.imageGallery.map(img=>{return img.mediumImage});
    let thumbnailImages = place.imageGallery.map(img=>{return img.thumbnailImage}); 
    let savedLargeImages=[];
    let savedSmallImages=[];
    let savedMediumImages=[];
    let savedThumbnailImages=[];
    await this.uploadImageToFirebase(true,place.placeID,storagePath,largeImages,"largeImage").then(result => {
      savedLargeImages = result;
    }); 
    this.setState({progress:20})
    await this.uploadImageToFirebase(true,place.placeID,storagePath, smallImages,"smallImage").then(result => {
      savedSmallImages = result;
    }); 
    this.setState({progress:40})
    await this.uploadImageToFirebase(true,place.placeID,storagePath,mediumImages,"mediumImage").then(result => {
      savedMediumImages = result;
    }); 
    this.setState({progress:60})
    await this.uploadImageToFirebase(true,place.placeID,storagePath, thumbnailImages,"thumbnailImage").then(result => {
      savedThumbnailImages = result;
    }); 
    this.setState({progress:70})
    place.imagePreview=[];
    place.imageGallery=[]
    for (let i=0 ; i<savedLargeImages.length ; i++){
      place.imagePreview.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
        mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
      place.imageGallery.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
        mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
    }
   
   let placePath = this.props.environment + "/systemData/placesEditSuggestions";
   var dbPlace = { ...place };
   if (dbPlace.permanentlyClosedDate===undefined){
     delete dbPlace.permanentlyClosedDate;
   }
   placePath+="/"+placeCountry;

   await this.addToDatabase(placePath, dbPlace, place.placeID);
   this.setState({processing:false})
}

viewPlaceEditSuggestion = async place =>{
  return new Promise(async (resolve, reject) => {
    this.setState({processing:true})
    let systemPlace;
    let country = ""
    if (place.placeStatus==="approved") {
      country = place.placeLocation.locationTree.placeCountry
    }
    else{
      country = place.placeLocation.locationTree.placeCountry.treeID
    }
    let placePath = this.props.environment + "/systemData/placesEditSuggestions/"+country+"/"+ place.placeID;
    await firebase.database()
    .ref( placePath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        systemPlace=snapshot.val();
      }
    });
    if (systemPlace===undefined){
      resolve({place:systemPlace,notExist:true});
      this.setState({processing:false})
      return;
    }
    else{
      resolve({place:systemPlace});
      this.setState({processing:false})
    }
    this.setState({processing:false})
  });
}


approvePlace = async (place) =>{
  this.setState({processing:true})
  //aprove main version of the place
  let systemPlace;
  let country = ""
  if (place.placeLocation.locationTree.placeCountry.treeID!==undefined)
  country = place.placeLocation.locationTree.placeCountry.treeID
  else {
    country = place.placeLocation.locationTree.placeCountry
  }
  let placePath = this.props.environment + "/systemData/places/"+country+"/"+place.placeID;
  await firebase.database()
  .ref( placePath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      systemPlace = snapshot.val()
    }
  });
  systemPlace.placeStatus="approved";

  let saveLocationTree = {...systemPlace.placeLocation.locationTree}
  //approved place holds only the treeIDs without the languages text - because it will be approved only after tree is updated
  let locationTree = {}
  locationTree.locationTreeIDs = [...place.placeLocation.locationTree.locationTreeIDs]
  if (systemPlace.placeLocation.locationTree.placeCountry!==undefined){
    if (place.placeLocation.locationTree.placeCountry.treeID!==undefined){
      locationTree.placeCountry = place.placeLocation.locationTree.placeCountry.treeID
    }
    else{
      locationTree.placeCountry = place.placeLocation.locationTree.placeCountry
    }
    
  }
  if (systemPlace.placeLocation.locationTree.placeRegion!==undefined){
    if (place.placeLocation.locationTree.placeRegion.treeID!==undefined){
      locationTree.placeRegion = place.placeLocation.locationTree.placeRegion.treeID
    }
    else{
      locationTree.placeRegion = place.placeLocation.locationTree.placeRegion
    }
    
  }
  if (systemPlace.placeLocation.locationTree.placeCity!==undefined){
    if (place.placeLocation.locationTree.placeCity.treeID!==undefined){
      locationTree.placeCity = place.placeLocation.locationTree.placeCity.treeID
    }
    else{
      locationTree.placeCity = place.placeLocation.locationTree.placeCity
    }
    
  }
  systemPlace.placeLocation.locationTree = locationTree;

  let dbPath =placePath;
  console.log(systemPlace)
  await this.updateToDatabase(dbPath,systemPlace);

//approve slim place
  let slimPlace={}
  await this.createSlimPlace(systemPlace).then(result =>{
    slimPlace = result
  })

  let slimPath = this.props.environment + "/systemData/slimPlacesData/"+country+"/"+place.placeID;
  await this.updateToDatabase(slimPath,slimPlace);

  //create very slim place for place creation search existing places
  let verySlimPlace ={
    placeID:systemPlace.placeID,
    placeName:systemPlace.placeName,
  }

  let placeLocation = {
    placeID:systemPlace.placeLocation.placeID,
    coordinates:systemPlace.placeLocation.coordinates
  }
  verySlimPlace.placeLocation=placeLocation 
  let verySlimPath = this.props.environment + "/systemData/placeCreationData/placesListForPlaceCreation/"+country+"/"+place.placeID;
  await this.updateToDatabase(verySlimPath,verySlimPlace);


  //update a version to firestore for various filteting uses
  await firebase.firestore().collection(this.props.environment+'systemPlaces').doc(country).collection('countryPlaces').doc(slimPlace.placeID.toString()).set(slimPlace);

  //update in permanent locations tree
  await this.updateSystemLocationsTree(saveLocationTree,place.placeID)
  await this.deleteFromLocationTree(saveLocationTree,place.placeID,true)
//approve place version for creation data

  

  this.setState({processing:false})
}

//===========PLANS===========

createTripPlan = async (plan) =>{
  await this.setState({processing:true})
  this.setState({progress:2,showProgress:true})
  let storagePath = 
  this.props.environment+"/usersData/"+this.props.userID+"/userPlans/"
    let largeImages =  plan.imageGallery.map(img=>{return img.largeImage});
    let smallImages = plan.imageGallery.map(img=>{return img.smallImage}); 
    let mediumImages =  plan.imageGallery.map(img=>{return img.mediumImage});
    let thumbnailImages = plan.imageGallery.map(img=>{return img.thumbnailImage}); 
    let savedLargeImages=[];
    let savedSmallImages=[];
    let savedMediumImages=[];
    let savedThumbnailImages=[];
    await this.uploadImageToFirebase(true,plan.planID,storagePath,largeImages,"largeImage").then(result => {
      savedLargeImages = result;
    }); 
    this.setState({progress:20})
    await this.uploadImageToFirebase(true,plan.planID,storagePath, smallImages,"smallImage").then(result => {
      savedSmallImages = result;
    }); 
    this.setState({progress:40})
    await this.uploadImageToFirebase(true,plan.planID,storagePath,mediumImages,"mediumImage").then(result => {
      savedMediumImages = result;
    }); this.setState({progress:60})
    await this.uploadImageToFirebase(true,plan.planID,storagePath, thumbnailImages,"thumbnailImage").then(result => {
      savedThumbnailImages = result;
    }); 
    this.setState({progress:80})
   plan.imagePreview=[];
   plan.imageGallery=[]
    for (let i=0 ; i<savedLargeImages.length ; i++){
      plan.imagePreview.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
        mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
      plan.imageGallery.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
        mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
    }

  var dbPlan = { ...plan };
  dbPlan.planPlacesList = [];
  let dbPath = this.props.environment+"/usersData/"+this.props.userID+"/userPlans/"+ plan.planID;
  this.updateToDatabase(dbPath,dbPlan);
  this.setState({progress:90})
  this.props.callBack("CreateTripPlan",dbPlan);
 

  let statsPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics";  
  this.updateStatistics(
    statsPath,
    "tripPlans",
    plan.planID,
    true
  );
  this.props.callBack("ViewPlan",dbPlan);
  let slimPlan = {
    planID: plan.planID,
    planLastModified: plan.planLastModified,
    planCreationDate: plan.planCreationDate,
    planName:plan.planName,
    planDates:plan.planDates,
    participants:plan.participants,
    imagePreview:plan.imagePreview,
    owner:plan.owner,
    planDuration:plan.planDuration,
    noDatesSelected:plan.noDatesSelected,
    planEditors:plan.planEditors,
  }
  let slimPath = this.props.environment+"/usersData/"+this.props.userID+"/userSlimPlans/"+ plan.planID;
  this.updateToDatabase(slimPath,slimPlan);
  await this.setState({processing:false, showProgress:false})

  let date = new Date();
  const dateMetric = moment(date);
  let dateString = dateMetric.format('DD-MM-YYYY').toString();
  let metricPath = this.props.environment +  "/systemMetrics/createdContent/privatePlans/"+dateString;
  await this.updateStatistics(
  metricPath,
  "totalPlans",
  plan.planID, true);
  metricPath = this.props.environment +  "/systemMetrics/createdContent/privatePlans/"+dateString;
  await this.updateStatistics(
  metricPath,
  "uniqueUsers",
  this.props.userID, true);


}

viewPlan = async (planID,showPlan) =>{
  await this.setState({processing:true})
  let userID = planID.split('_')[1]
 
  let dbPlan;
  let planPath = this.props.environment+"/usersData/"+userID+"/userPlans/"+planID;
  await firebase.database()
  .ref(planPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      dbPlan = snapshot.val()
    }
  });
  if (dbPlan===undefined){
    this.props.callBack("TripNotExist",planID);
    this.setState({processing:false})
    return;
  }
 
  let planPlacesList = [];
  await this.loadTripPlanPlacesList(dbPlan).then(result => {
    planPlacesList = result
  }); 

  if (showPlan){
    let planExperiences = []
    let firestore = firebase.firestore()
    const query = firestore.collection(this.state.firestorePrefix+'plansExperiences').doc(planID.toString()).collection('experiences').onSnapshot(
      querySnapshot=>{querySnapshot.forEach(doc => {
        planExperiences.push(doc.data())
        
      });
      this.props.callBack("UpdatePlanExperiences",planExperiences);
      }
    );



    await firebase.database()
    .ref( planPath)
    .on("value",(snapshot)=>{
      if (snapshot.val()!==null){
        dbPlan = snapshot.val()
        dbPlan.planPlacesList = planPlacesList
        this.props.callBack("ViewPlan",dbPlan);
      }
    })

    let statsPath = planPath+ "/tripStats";
       await this.updateStatistics(
        statsPath,
        "viewed",
        this.props.userID,
        true
        )/* .then(result => {
          this.updateToDatabase(statsPath+"/viewed",result);
      }); */
  }
  else{
    dbPlan.planPlacesList = planPlacesList
    this.props.callBack("PlanSelected",dbPlan);
  }

  await this.setState({processing:false})
}

updateTripCloneStats = async planID =>{
  let userID;
  userID = planID.split('_')[1]
  let planPath = this.props.environment+"/usersData/"+userID+"/userPlans/"+planID;
  let statsPath = planPath+ "/tripStats";
  await this.updateStatistics(
    statsPath,
    "cloned",
    this.props.userID,
    true
    ).then(result => {
      this.updateToDatabase(statsPath+"/cloned",result);
  });
}

closeTripPlanView = (planID)=>{
  let userID = planID.split('_')[1]

  let planPath = this.props.environment+"/usersData/"+userID+"/userPlans/"+planID;

  firebase.database()
  .ref(planPath)
  .off()
}

getAddedPlaceToPlan = async (placeData) =>{
  return new Promise(async (resolve, reject) => {
    let place

    let placeID = placeData.placeID
    let placeCountry = placeData.placeCountry
    await firebase.database()
      .ref(this.props.environment + "/systemData/slimPlacesData/"+placeCountry+"/"+placeID)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          place = snapshot.val()
        }
         
    })
  resolve(place);
});
}

loadTripPlanPlacesList = async (plan) =>{
  return new Promise(async (resolve, reject) => {
    let planPlacesByCountry = {};
    let placesCountriesList = [];
    for (let i=0;i<plan.planDays.length ; i++){
      if (plan.planDays[i].dayItems!==undefined){
        for (let j=0 ; j<plan.planDays[i].dayItems.length ; j++){
          if (plan.planDays[i].dayItems[j].type==="place"){
            let placeCountry;
            if (plan.planDays[i].dayItems[j].item.placeCountry.treeID!==undefined){
              placeCountry=plan.planDays[i].dayItems[j].item.placeCountry.treeID
            }
            else{
              placeCountry=plan.planDays[i].dayItems[j].item.placeCountry
            }
            if (planPlacesByCountry[placeCountry]!==undefined){
              planPlacesByCountry[placeCountry].push(plan.planDays[i].dayItems[j].item.placeID)
            }
            else{
              placesCountriesList.push(placeCountry)
              planPlacesByCountry[placeCountry]=[plan.planDays[i].dayItems[j].item.placeID]
            }
          }
        }

      }
    }
    let planPlacesList = []

    for (let i=0 ; i<placesCountriesList.length ; i++){
      let IDs = Object.values(planPlacesByCountry[placesCountriesList[i]])
      await firebase.database()
      .ref(this.props.environment + "/systemData/slimPlacesData/"+placesCountriesList[i])
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
         
            
        //covering every country
        snapshot.forEach(child => {
          const index = IDs.findIndex(placeID => {
            return placeID === child.key
          });
          if (index!==-1){
            planPlacesList.unshift(child.val());
          }
          
        });
       } 
      });
     
     
    }
    resolve(planPlacesList);
  });
}

saveSystemGeneratedTrip = async plan =>{
  delete plan.dailyAnchors
  await firebase.firestore().collection(this.state.firestorePrefix+'systemGeneratedTrip').doc(plan.planID.toString()).set(plan);
}


updatePlan = async (plan,planPlacesList) =>{
  await this.setState({processing:true})
  let userID = plan.owner.ownerID;
  let dbPlan;
  let dbPlanPath = this.props.environment+"/usersData/"+userID+"/userPlans/"+plan.planID;
  await firebase.database()
  .ref(dbPlanPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      dbPlan = snapshot.val()
    }
  });

  let tripStats = { 
    viewed: { count: 0, users: [] },
    inspired: { count: 0, users: [] },
    cloned: {count: 0, users:[]}
  };
  if (dbPlan.tripStats!==undefined){
    tripStats = dbPlan.tripStats
  }
  let storagePath = this.props.environment+"/usersData/"+userID+"/userPlans/"
  if (plan.deletedImages!==undefined){
   

    if (plan.deletedImages.length>0){
      if (plan.deletedImages[0]!==undefined){
      // let deletePathOld =  storagePath+placeID;
        let deletePathSmall =  storagePath+plan.planID+"/smallImage";
        let deletePathLarge =  storagePath+plan.planID+"/largeImage";
        let deletePathMedium =  storagePath+plan.planID+"/mediumImage";
        let deletePathThumbnail =  storagePath+plan.planID+"/thumbnailImage";
        let deletedImages = [...plan.deletedImages].map(img=>{return img.name});
        this.deleteFromStorage(deletePathSmall,deletedImages);
        this.deleteFromStorage(deletePathLarge,deletedImages);
        this.deleteFromStorage(deletePathMedium,deletedImages);
        this.deleteFromStorage(deletePathThumbnail,deletedImages);
      }
    
    } 
  }
  if (plan.imagePreview.length>0){
    if (plan.imagePreview[0].url!==undefined){
      let largeImages =  plan.imageGallery.map(img=>{return img.largeImage});
      let smallImages = plan.imageGallery.map(img=>{return img.smallImage}); 
      let mediumImages =  plan.imageGallery.map(img=>{return img.mediumImage});
      let thumbnailImages = plan.imageGallery.map(img=>{return img.thumbnailImage}); 
      let savedLargeImages=[];
      let savedSmallImages=[];
      let savedMediumImages=[];
      let savedThumbnailImages=[];
      await this.uploadImageToFirebase(false,plan.planID,storagePath,largeImages,"largeImage").then(result => {
        savedLargeImages = result;
      }); 
      await this.uploadImageToFirebase(false,plan.planID,storagePath, smallImages,"smallImage").then(result => {
        savedSmallImages = result;
      }); 
      await this.uploadImageToFirebase(false,plan.planID,storagePath,mediumImages,"mediumImage").then(result => {
        savedMediumImages = result;
      }); 
      await this.uploadImageToFirebase(false,plan.planID,storagePath, thumbnailImages,"thumbnailImage").then(result => {
        savedThumbnailImages = result;
      }); 
  
      plan.imagePreview=[];
      plan.imageGallery=[]
      for (let i=0 ; i<savedLargeImages.length ; i++){
        plan.imagePreview.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
          mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
        plan.imageGallery.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
          mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
      }
    }
  } 
  
    
 
 
  let updatePlanSearchTags = false;
  if (plan.updatePlanSearchTags!==undefined){
    updatePlanSearchTags = true
    delete plan.updatePlanSearchTags 
  }
  dbPlan = {...plan}
  dbPlan.tripStats = tripStats
  this.updateToDatabase(dbPlanPath,dbPlan);

  await this.updateSlimPlan(dbPlan,planPlacesList,updatePlanSearchTags).then(result=> {
    this.props.callBack("UpdateTripPlan",result);
  }); 

  await this.setState({processing:false})
}

updateSlimPlan = (plan,planPlacesList,updatePlanSearchTags) =>{
  return new Promise(async (resolve, reject) => {
    let userID = plan.owner.ownerID;
    let dbSlimPlan;
    let slimPath = this.props.environment+"/usersData/"+userID+"/userSlimPlans/"+ plan.planID;
    await firebase.database()
    .ref(slimPath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        dbSlimPlan = snapshot.val()
      }
    });
    let slimPlan = {
      isPublic: plan.isPublic,
      planID: plan.planID,
      planLastModified: plan.planLastModified,
      planCreationDate: plan.planCreationDate,
      planName:plan.planName,
      planDates:plan.planDates,
      tripStats:plan.tripStats,
      owner:plan.owner,
      noDatesSelected:plan.noDatesSelected,
      planEditors:plan.planEditors,
      planSecurity: plan.planSecurity,
      albumSecurity:plan.albumSecurity,
      planDuration: plan.planDuration
    }
    let planParticipants = ["Adults"];
    if (plan.participants.kids>0){
      planParticipants.push("Kids")
    }
    if (plan.participants.tiddler>0){
      planParticipants.push("Toddlers")
    }
    slimPlan.participants=planParticipants
    let imagePreview={};
    if (plan.imagePreview){
      imagePreview=plan.imagePreview;
    }
    slimPlan.imagePreview=imagePreview

    if (dbSlimPlan.approvedPlan!==undefined){
      slimPlan.approvedPlan=dbSlimPlan.approvedPlan;
    }
    else{
      slimPlan.approvedPlan=false
    }

    let planPlacesListOutOfInterests = []
    //use only places which are in specific days and not the trip interests list
    let planPlacesListOutOfInterestsIDs = [];
    if (plan.planDays.length>1){
      for (let i=1; i<plan.planDays.length ; i++){
        if (plan.planDays[i].dayItems!==undefined){
          for(let j=0; j<plan.planDays[i].dayItems.length ; j++){
          //now handling only places
            if (plan.planDays[i].dayItems[j].type==="place"){
              planPlacesListOutOfInterestsIDs.push(plan.planDays[i].dayItems[j].item.placeID)
            }

          }

        }
      }
    }
    planPlacesListOutOfInterests = planPlacesList.filter(place=>planPlacesListOutOfInterestsIDs.includes(place.placeID))
    let tripCountries = [];
    let tripLocationTags = [];
    let tripCategoriesTags = [];
    let tripStyle = {placesByMainCategory:[0,0,0,0,0,0],totalPlaces:0}
    let planMapBoundaries = {south:0,west:0,north:0,east:0 }
    let firstPlaceInList = true;
    for (let i=0 ; i<planPlacesListOutOfInterests.length ; i++){
      let placeLocation = planPlacesListOutOfInterests[i].placeLocation
      let placeCountry = placeLocation.locationTree.placeCountry
      //use only the treed IDs of approved places
      if (placeCountry.treeID===undefined){
        if (!tripCountries.includes(placeCountry)){
          tripCountries.push(placeCountry)
        }
        if (!tripLocationTags.includes(placeCountry)){
          tripLocationTags.push(placeCountry)
        }
      }
      
      if (placeLocation.locationTree.placeRegion!==undefined){
        if (placeLocation.locationTree.placeRegion.treeID===undefined){
          if (!tripLocationTags.includes(placeLocation.locationTree.placeRegion)){
            tripLocationTags.push(placeLocation.locationTree.placeRegion)
          }
        }
        
      }
      if (placeLocation.locationTree.placeCity!==undefined){
        if (placeLocation.locationTree.placeCity.treeID===undefined){
          if (!tripLocationTags.includes(placeLocation.locationTree.placeCity)){
            tripLocationTags.push(placeLocation.locationTree.placeCity)
          }
        }
        
      }
      if (firstPlaceInList){
        planMapBoundaries.north=placeLocation.coordinates.lat
        planMapBoundaries.south=placeLocation.coordinates.lat
        planMapBoundaries.east=placeLocation.coordinates.lng
        planMapBoundaries.west=placeLocation.coordinates.lng
      }
      else{
        if (placeLocation.coordinates.lat>planMapBoundaries.north){
          planMapBoundaries.north=placeLocation.coordinates.lat
        }
        if (placeLocation.coordinates.lat<planMapBoundaries.south){
          planMapBoundaries.south=placeLocation.coordinates.lat
        }
        if (placeLocation.coordinates.lng>planMapBoundaries.east){
          planMapBoundaries.east=placeLocation.coordinates.lng
        }
        if (placeLocation.coordinates.lng<planMapBoundaries.west){
          planMapBoundaries.west=placeLocation.coordinates.lng
        }
      }
      firstPlaceInList=false;
     
      for (let j=0 ; j<planPlacesListOutOfInterests[i].placeCategory.length ; j++){
        if (!tripCategoriesTags.includes(planPlacesListOutOfInterests[i].placeCategory[j])){
          tripCategoriesTags.push(planPlacesListOutOfInterests[i].placeCategory[j])
        }
      }

      if (planPlacesListOutOfInterests[i].placeMainCategory[0]===1){
        tripStyle.placesByMainCategory[0] +=1;
      }
      if (planPlacesListOutOfInterests[i].placeMainCategory[1]===1){
        tripStyle.placesByMainCategory[1] +=1;
      }
      if (planPlacesListOutOfInterests[i].placeMainCategory[2]===1){
        tripStyle.placesByMainCategory[2] +=1;
      }
      if (planPlacesListOutOfInterests[i].placeMainCategory[3]===1){
        tripStyle.placesByMainCategory[3] +=1;
      }
      if (planPlacesListOutOfInterests[i].placeMainCategory[4]===1){
        tripStyle.placesByMainCategory[4] +=1;
      }
      if (planPlacesListOutOfInterests[i].placeMainCategory[5]===1){
        tripStyle.placesByMainCategory[5] +=1;
      }
      tripStyle.totalPlaces = planPlacesListOutOfInterests.length

    }

    
    /* tripStyle.sightseeing =  (tripStyle.sightseeing/planPlacesList.length)*100
    tripStyle.nature =  (tripStyle.nature/planPlacesList.length)*100
    tripStyle.activities =  (tripStyle.activities/planPlacesList.length)*100
    tripStyle.foodDrink =  (tripStyle.foodDrink/planPlacesList.length)*100 */
    
    let result = {
      tripCountries:tripCountries,
      tripLocationTags:tripLocationTags,
      tripCategoriesTags:tripCategoriesTags,
      tripStyle:tripStyle,
      planMapBoundaries:planMapBoundaries,
    }
    if (updatePlanSearchTags){
      slimPlan.tripCountries = result.tripCountries;
      slimPlan.tripLocationTags = result.tripLocationTags;
      slimPlan.tripCategoriesTags = result.tripCategoriesTags;
      slimPlan.tripStyle = result.tripStyle;
      slimPlan.planMapBoundaries = result.planMapBoundaries;
    }
    else{
      if (dbSlimPlan.tripCountries!==undefined){
        slimPlan.tripCountries = dbSlimPlan.tripCountries;
      }
      if (dbSlimPlan.tripLocationTags!==undefined){
        slimPlan.tripLocationTags = dbSlimPlan.tripLocationTags;
      }
      if (dbSlimPlan.tripCategoriesTags!==undefined){
        slimPlan.tripCategoriesTags = dbSlimPlan.tripCategoriesTags;
      }
      if (dbSlimPlan.tripStyle!==undefined){
        slimPlan.tripStyle = dbSlimPlan.tripStyle;
      }
      if (dbSlimPlan.planMapBoundaries!==undefined){
        slimPlan.planMapBoundaries = dbSlimPlan.planMapBoundaries;
      } 
    }
    this.updateToDatabase(slimPath,slimPlan);
    if (plan.isPublic){
      slimPlan.publicPlanTags = plan.publicPlanTags
    }
    let firestore = firebase.firestore()
    //handle public plan
    if (plan.isPublic){
      //update public plan
      await firestore.collection(this.state.firestorePrefix+'publicTripPlans').doc(plan.planID.toString()).set(slimPlan);

      if (slimPlan.approvedPlan){
        let publicTripsLocationSearchTags = []
        let locationSearchTagsPath = this.props.environment +  "/systemPreloadData/publicTripsLocationSearchTags"
        await firebase.database()
          .ref(locationSearchTagsPath)
          .once("value")
          .then(snapshot =>{
            if (snapshot.val()!==null){
              //covering every country
              publicTripsLocationSearchTags=snapshot.val();
            }
          });
        for (let i=0 ; i<slimPlan.tripCountries.length ; i++){
          if (!publicTripsLocationSearchTags.includes(slimPlan.tripCountries[i])){
            publicTripsLocationSearchTags.push(slimPlan.tripCountries[i])
          }
        }
        for (let i=0 ; i<slimPlan.tripLocationTags.length ; i++){
          if (!publicTripsLocationSearchTags.includes(slimPlan.tripLocationTags[i])){
            publicTripsLocationSearchTags.push(slimPlan.tripLocationTags[i])
          }
        }
        this.updateToDatabase(locationSearchTagsPath,publicTripsLocationSearchTags);

        let publicTripsAdditionalTagsSearchTags = []
        let tagsPath = this.props.environment +  "/systemPreloadData/publicTripsAdditionalTagsSearchTags"
        await firebase.database()
          .ref(tagsPath)
          .once("value")
          .then(snapshot =>{
            if (snapshot.val()!==null){
              //covering every country
              publicTripsAdditionalTagsSearchTags=snapshot.val();
            }
          });
        for (let i=0 ; i<slimPlan.publicPlanTags.length ; i++){
          if (!publicTripsAdditionalTagsSearchTags.includes(slimPlan.publicPlanTags[i])){
            publicTripsAdditionalTagsSearchTags.push(slimPlan.publicPlanTags[i])
          }
        }
        this.updateToDatabase(tagsPath,publicTripsAdditionalTagsSearchTags);
      }
    }
    else{
      if (dbSlimPlan.isPublic!==undefined){
        if (dbSlimPlan.isPublic){
          //remove plan from public plans
          await firestore.collection(this.state.firestorePrefix+'publicTripPlans').doc(plan.planID.toString()).delete();
        }
      }
    }
   
    resolve(slimPlan)
  });
}

approvePlan = async planID =>{
  await this.setState({processing:true})
  let userID = planID.split('_')[1];
  let slimPath = this.props.environment+"/usersData/"+userID+"/userSlimPlans/"+ planID+"/approvedPlan";
  this.updateToDatabase(slimPath,true);
  let firestore = firebase.firestore()
  await firestore.collection(this.state.firestorePrefix+'publicTripPlans').doc(planID.toString()).update({approvedPlan:true});

  //update location search tags
  let planSnapshot = await firestore.collection(this.state.firestorePrefix+'publicTripPlans').where('planID', '==', planID).get()
  let plan
  planSnapshot.forEach(doc => {
    plan=doc.data()
  });
  let publicTripsLocationSearchTags = []
  let locationSearchTagsPath = this.props.environment +  "/systemPreloadData/publicTripsLocationSearchTags"
  await firebase.database()
    .ref(locationSearchTagsPath)
    .once("value")
    .then(snapshot =>{
      if (snapshot.val()!==null){
        //covering every country
        publicTripsLocationSearchTags=snapshot.val();
      }
    });
  for (let i=0 ; i<plan.tripCountries.length ; i++){
    if (!publicTripsLocationSearchTags.includes(plan.tripCountries[i])){
      publicTripsLocationSearchTags.push(plan.tripCountries[i])
    }
  }
  for (let i=0 ; i<plan.tripLocationTags.length ; i++){
    if (!publicTripsLocationSearchTags.includes(plan.tripLocationTags[i])){
      publicTripsLocationSearchTags.push(plan.tripLocationTags[i])
    }
  }
  this.updateToDatabase(locationSearchTagsPath,publicTripsLocationSearchTags);

  let publicTripsAdditionalTagsSearchTags = []
  let tagsPath = this.props.environment +  "/systemPreloadData/publicTripsAdditionalTagsSearchTags"
  await firebase.database()
    .ref(tagsPath)
    .once("value")
    .then(snapshot =>{
      if (snapshot.val()!==null){
        //covering every country
        publicTripsAdditionalTagsSearchTags=snapshot.val();
      }
    });
  for (let i=0 ; i<plan.publicPlanTags.length ; i++){
    if (!publicTripsAdditionalTagsSearchTags.includes(plan.publicPlanTags[i])){
      publicTripsAdditionalTagsSearchTags.push(plan.publicPlanTags[i])
    }
  }
  this.updateToDatabase(tagsPath,publicTripsAdditionalTagsSearchTags);
  await this.setState({processing:false})
}

updatePlanEditors = async (sharedPlan, user, added, planEditors)=>{
  await this.setState({processing:true})
  let dbPath = this.props.environment+"/usersData/"+user.userID+"/sharedPlans/"
  if (added){
    await this.updateToDatabase(dbPath+"/"+sharedPlan.planID,sharedPlan);
    let statsPath = this.props.environment + "/usersData/" + user.userID + "/userInfo/statistics";  
    this.updateStatistics(
      statsPath,
      "tripPlans",
      sharedPlan.planID,
      true
    );

  }
  else{
    await this.deleteDataFromDB(dbPath+"/"+sharedPlan.planID);
    let statsPath = this.props.environment + "/usersData/" + user.userID + "/userInfo/statistics";  
    this.updateStatistics(
      statsPath,
      "tripPlans",
      sharedPlan.planID,
      false
    );
    //remove all user experiences from the trip
    let firestore = firebase.firestore()
    let userExperiencesInPlan =[]
    const experiences = await  firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(user.userID).collection('experiences').where('experiencePlanID', '==', sharedPlan.planID).get();
    experiences.forEach(doc => {
      userExperiencesInPlan.push(doc.data());
    });
    for (let i=0 ; i<userExperiencesInPlan.length ; i++){
      await this.removeExperienceFromPlan(userExperiencesInPlan[i])
    }
    let planOwnerID = sharedPlan.planID.split('_')[1]
    let dbPlan;
    let dbPlanPath = this.props.environment+"/usersData/"+planOwnerID+"/userPlans/"+sharedPlan.planID;
    await firebase.database()
    .ref( dbPlanPath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        dbPlan = snapshot.val()
      }
    });
    for (let i=0 ; i<dbPlan.planDays.length ; i++){
      if (dbPlan.planDays[i].dayItems!==undefined){
        for (let j=0 ; j< dbPlan.planDays[i].dayItems.length ; j++){
          if ( dbPlan.planDays[i].dayItems[j].type==="place"){
            if (dbPlan.planDays[i].dayItems[j].item.placeExperiences!==undefined){
              if (dbPlan.planDays[i].dayItems[j].item.placeExperiences[user.userID]!==undefined)
              delete dbPlan.planDays[i].dayItems[j].item.placeExperiences[user.userID]
            }
          }
        }
      }  
    }
    this.updateToDatabase(dbPlanPath,dbPlan);
   
  }
   let date = new Date();
  let timestamp = date.getTime();
  let savedPlanPath = this.props.environment+"/usersData/"+sharedPlan.planOwnerID+"/userPlans/"+sharedPlan.planID;
  await this.updateToDatabase(savedPlanPath+"/planEditors",planEditors);
  await this.updateToDatabase(savedPlanPath+"/planLastModified",timestamp); 

  let slimPlanDatePath = this.props.environment+"/usersData/"+sharedPlan.planOwnerID+"/userSlimPlans/"+sharedPlan.planID+"/planLastModified";
  this.updateToDatabase(slimPlanDatePath,timestamp);
  await this.setState({processing:false})
}

updateExperiencesPlanSecurity = async (experiencesIDs, planID,planSecurity) =>{
  await this.setState({processing:true})
  let firestore = firebase.firestore()
  for (let i=0 ; i<experiencesIDs.length ; i++){
    let experienceID = experiencesIDs[i].experienceID
    let userID = experienceID.split('_')[0]
    let placeID = experiencesIDs[i].experiencePlaceID
    //update user experiences
    await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(userID).collection('experiences').doc(experienceID.toString()).update({experiencePlanSecurity:planSecurity});

    //update place experiences
    await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(placeID).collection('experiences').doc(experienceID.toString()).update({experiencePlanSecurity:planSecurity});
    //update plan experiences
    await firestore.collection(this.state.firestorePrefix+'plansExperiences').doc(planID).collection('experiences').doc(experienceID.toString()).update({experiencePlanSecurity:planSecurity});
  }
 
  await this.setState({processing:false})
}

updateExperiencesAlbumSecurity = async (experiencesIDs, planID,albumSecurity) =>{
  await this.setState({processing:true})
  let firestore = firebase.firestore()
  for (let i=0 ; i<experiencesIDs.length ; i++){
    let experienceID = experiencesIDs[i].experienceID
    let userID = experienceID.split('_')[0]
    let placeID = experiencesIDs[i].experiencePlaceID
    //update user experiences
    await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(userID).collection('experiences').doc(experienceID.toString()).update({experienceAlbumSecurity:albumSecurity});

    //update place experiences
    await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(placeID).collection('experiences').doc(experienceID.toString()).update({experienceAlbumSecurity:albumSecurity});
    //update plan experiences
    await firestore.collection(this.state.firestorePrefix+'plansExperiences').doc(planID).collection('experiences').doc(experienceID.toString()).update({experienceAlbumSecurity:albumSecurity});
  }
 
  await this.setState({processing:false})
}

updatePlanNote = async (note, dayIndex,itemIndex,planID,ownerID) =>{

  await this.setState({processing:true})
  let dbPlan;
  let planPath = this.props.environment+"/usersData/"+ownerID+"/userPlans/"+planID;
  await firebase.database()
  .ref( planPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      dbPlan = snapshot.val()
    }
  });
  let newNote = note;     

  let storagePath = planPath+"/planNotes/"+note.noteID+"/attachments/";
  if (note.attachedFiles !== undefined) {
    if (note.attachedFiles.length > 0) {
      await this.uploadFiles(storagePath, note.attachedFiles).then(result => {
        newNote.attachedFiles = result;
      });
    }
  }
  if (note.deletedFiles.length>0){
    let deletePath =   planPath+"/planNotes/"+note.noteID+"/attachments/";
    let deletedFiles = [...note.deletedFiles].map(file=>{return file.name});
    this.deleteFromStorage(deletePath,deletedFiles);
  }

  let item = {type:"note",item:newNote}
  dbPlan.planDays[dayIndex].dayItems[itemIndex]=item

  let date = new Date();
  let timestamp = date.getTime();
  dbPlan.planLastModified = timestamp
  this.updateToDatabase(planPath,dbPlan);

  let slimPlanDatePath = this.props.environment+"/usersData/"+ownerID+"/userSlimPlans/"+planID+"/planLastModified";
  this.updateToDatabase(slimPlanDatePath,timestamp);
  await this.setState({processing:false})
}

removeNoteFromPlan =  async (note, dayIndex,itemIndex,planID,ownerID) =>{
  await this.setState({processing:true})
  let dbPlan;
  let planPath = this.props.environment+"/usersData/"+ownerID+"/userPlans/"+planID;
  await firebase.database()
  .ref( planPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      dbPlan = snapshot.val()
    }
  });

  if (note.attachedFiles!==undefined){
    if (note.attachedFiles.length>0){
      let deletePath =   planPath+"/planNotes/"+note.noteID+"/attachments/";
      let deletedFiles = [...note.attachedFiles].map(file=>{return file.name});
      this.deleteFromStorage(deletePath,deletedFiles);
    }
  }

  dbPlan.planDays[dayIndex].dayItems.splice(itemIndex,1);

  let date = new Date();
  let timestamp = date.getTime();
  dbPlan.planLastModified = timestamp
  this.updateToDatabase(planPath,dbPlan);

  let slimPlanDatePath = this.props.environment+"/usersData/"+ownerID+"/userSlimPlans/"+planID+"/planLastModified";
  this.updateToDatabase(slimPlanDatePath,timestamp);

  await this.setState({processing:false})
}


updatePlanTransport = async (transport, dayIndex,itemIndex,planID,ownerID,isNew) =>{

  await this.setState({processing:true})
  let dbPlan;
  let planPath = this.props.environment+"/usersData/"+ownerID+"/userPlans/"+planID;
  await firebase.database()
  .ref( planPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      dbPlan = snapshot.val()
    }
  });
  let newTransport = transport;     

  let storagePath = planPath+"/planTransports/"+transport.transportID+"/attachments/";
  if (transport.attachedFiles !== undefined) {
    if (transport.attachedFiles.length > 0) {
      await this.uploadFiles(storagePath, transport.attachedFiles).then(result => {
        newTransport.attachedFiles = result;
      });
    }
  }
  if (!isNew){
    if (transport.deletedFiles.length>0){
      let deletePath =   planPath+"/planTransports/"+transport.transportID+"/attachments/";
      let deletedFiles = [...transport.deletedFiles].map(file=>{return file.name});
      this.deleteFromStorage(deletePath,deletedFiles);
    }
  }


  let item = {type:"transport",item:newTransport}
  dbPlan.planDays[dayIndex].dayItems[itemIndex]=item

  let date = new Date();
  let timestamp = date.getTime();
  dbPlan.planLastModified = timestamp
  this.updateToDatabase(planPath,dbPlan);

  let slimPlanDatePath = this.props.environment+"/usersData/"+ownerID+"/userSlimPlans/"+planID+"/planLastModified";
  this.updateToDatabase(slimPlanDatePath,timestamp);
  await this.setState({processing:false})
}

removeTransportFromPlan =  async (transport, dayIndex,itemIndex,planID,ownerID) =>{
  await this.setState({processing:true})
  let dbPlan;
  let planPath = this.props.environment+"/usersData/"+ownerID+"/userPlans/"+planID;
  await firebase.database()
  .ref( planPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      dbPlan = snapshot.val()
    }
  });

  if (transport.attachedFiles!==undefined){
    if (transport.attachedFiles.length>0){
      let deletePath =   planPath+"/planTransports/"+transport.transportID+"/attachments/";
      let deletedFiles = [...transport.attachedFiles].map(file=>{return file.name});
      this.deleteFromStorage(deletePath,deletedFiles);
    }
  }

  dbPlan.planDays[dayIndex].dayItems.splice(itemIndex,1);

  let date = new Date();
  let timestamp = date.getTime();
  dbPlan.planLastModified = timestamp
  this.updateToDatabase(planPath,dbPlan);

  let slimPlanDatePath = this.props.environment+"/usersData/"+ownerID+"/userSlimPlans/"+planID+"/planLastModified";
  this.updateToDatabase(slimPlanDatePath,timestamp);

  await this.setState({processing:false})
}

updatePlanDays = async planData =>{
  await this.setState({processing:true})
  let ownerID = planData.ownerID;
  let dbPlan;
  let planPath = this.props.environment+"/usersData/"+ownerID+"/userPlans/"+planData.planID;
  await firebase.database()
  .ref( planPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      dbPlan = snapshot.val()
    }
  });

  dbPlan.planLastModified=planData.planLastModified;
  dbPlan.planDays=[...planData.planDays]
  dbPlan.planPlacesIDs=[...planData.planPlacesIDs];
  this.updateToDatabase(planPath,dbPlan);

  let slimPlanDatePath = this.props.environment+"/usersData/"+ownerID+"/userSlimPlans/"+planData.planID+"/planLastModified";
  this.updateToDatabase(slimPlanDatePath,planData.planLastModified);

  await this.setState({processing:false})
}

deletePlan= async (plan) =>{
  await this.setState({processing:true})
  let userID = this.props.userID
  let dbPlanPath = this.props.environment+"/usersData/"+this.props.userID+"/userPlans/"+plan.planID;
  let dbPlan;

  await firebase.database()
  .ref( dbPlanPath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      dbPlan = snapshot.val()
    }
  });
  let storagePath = 
  this.props.environment + "/usersData/"+userID+"/userPlans/";
  if (plan.imagePreview!==undefined){
      let deletePathSmall =  storagePath+plan.planID+"/smallImage";
      let deletePathMedium =  storagePath+plan.planID+"/mediumImage";
      let deletePathLarge =  storagePath+plan.planID+"/largeImage";
      let deletePathThumbnail =  storagePath+plan.planID+"/thumbnailImage";
      let deletedImages = [...plan.imagePreview.map(img=>{return img.smallImage.name})];
      this.deleteFromStorage(deletePathSmall,deletedImages);
      this.deleteFromStorage(deletePathMedium,deletedImages);
      this.deleteFromStorage(deletePathLarge,deletedImages);
      this.deleteFromStorage(deletePathThumbnail,deletedImages);
  } 
  //Need to delete all the transport and notes files - can delete an entire folder in firebase?
  //Delete notes files and attachments
  if (dbPlan.planDays!==undefined){
    for (let i=0 ; i<dbPlan.planDays.length ; i++){
      if (dbPlan.planDays[i].dayItems!==undefined){
        for(let j=0; j<dbPlan.planDays[i].dayItems.length ; j++){
          //now handling only places
          if (dbPlan.planDays[i].dayItems[j].type==="note"){
            let note = dbPlan.planDays[i].dayItems[j].item;
            if (note.attachedFiles!==undefined){
              if (note.attachedFiles.length>0){
                let deletePath =   dbPlanPath+"/planNotes/"+note.noteID+"/attachments/";
                let deletedFiles = [...note.attachedFiles].map(file=>{return file.name});
                this.deleteFromStorage(deletePath,deletedFiles);
              }
            }
          }
          if (dbPlan.planDays[i].dayItems[j].type==="transport"){
            let transport = dbPlan.planDays[i].dayItems[j].item;
            if (transport.attachedFiles!==undefined){
              if (transport.attachedFiles!==undefined){
                if (transport.attachedFiles.length>0){
                  let deletePath =   dbPlanPath+"/planTransports/"+transport.transportID+"/attachments/";
                  let deletedFiles = [...transport.attachedFiles].map(file=>{return file.name});
                  this.deleteFromStorage(deletePath,deletedFiles);
                }
              }
            }
          }
        }
      }
    }
  }
  
  //delete transprt files and attachments


  await this.deleteDataFromDB(dbPlanPath);

  //delete slim version
  dbPlanPath = this.props.environment+"/usersData/"+userID+"/userSlimPlans/"+plan.planID;
  await this.deleteDataFromDB(dbPlanPath);
  this.props.callBack("DeleteUserTripPlan",plan.planID);

  //if plan is public delete it
  if (plan.isPublic){
    //HANDLE THIS BETTER WITH DOUBLE CHECKING WITH USER
   /*  let publicPlanID= plan.planID;
    let publicPath = this.props.environment+"/systemData/plans/publicPlans/"+publicPlanID;
    await this.updatePlansLocationsTags(plan.locationsTreeIDs,publicPlanID,"Remove")
    await this.deleteDataFromDB(publicPath); */
    let firestore = firebase.firestore()
    await firestore.collection(this.state.firestorePrefix+'publicTripPlans').doc(plan.planID.toString()).delete();
  }

  //deletr from all plan editors
  for (let i=0; i<plan.planEditors.length ; i++){
    if (plan.planEditors[i].userID!==this.props.userID){
      let sharedPlanPath = this.props.environment+"/usersData/"+plan.planEditors[i].userID+"/sharedPlans/"+plan.planID
      await this.deleteDataFromDB(sharedPlanPath);
      let statsPath = this.props.environment + "/usersData/" + plan.planEditors[i].userID + "/userInfo/statistics";  
      this.updateStatistics(
        statsPath,
        "tripPlans",
        plan.planID,
        false
      );
    }
  }

  let statsPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics";  
  this.updateStatistics(
    statsPath,
    "tripPlans",
    plan.planID,
    true
  );
  await this.setState({processing:false})
}


//-----EXPERIENCES---------

createExperience = async (experience) =>{
  await this.setState({processing:true})
  this.setState({progress:2,showProgress:true})
  let firestore = firebase.firestore()
  let placeCountry = experience.experienceLocation[0]
  let placeID = experience.experiencePlaceID
  let saveExperience = experience
  //update place stats
  let systemPlace;
  let placeScore;
  let placePath = this.props.environment +"/systemData/places/"+placeCountry+"/"+placeID;
  await firebase.database()
  .ref( placePath)
  .once("value")
  .then(snapshot => {
    if (snapshot.val()!==null){
      systemPlace = snapshot.val()
    }
  });
  if (systemPlace!==undefined){

  }
  let placeStats=systemPlace.placeStats;
  let experienceUsersRank = [];
  if (systemPlace.experienceUsersRank!==undefined){
    experienceUsersRank = systemPlace.experienceUsersRank;
  }
  await this.updatePlaceUsersRank(experienceUsersRank,this.props.userID,experience.experienceID,experience.experienceRank,"CreateExperience").then(result => {
    experienceUsersRank = result.usersRanks;
    placeStats.usersLiked+=result.likesUpdate; 
    if (placeStats.usersDisliked!==undefined){
      placeStats.usersDisliked+=result.dislikesUpdate; 
    }
    else{
      placeStats.usersDisliked=result.dislikesUpdate; 
    }
    
    placeStats.usersExperienced+=result.experienceUsers;
    placeStats.totalExperiences+=1;
  });
  systemPlace.placeStats=placeStats;
  systemPlace.experienceUsersRank = experienceUsersRank;
  placeScore = 0;
  let placeViewesFactor=1;
  let placeExperiencesFactor=1;
  let placeLikesFactor=1;
  let placeFavoriteFactor=1;
  
  placeScore = placeScore+placeViewesFactor*placeStats.viewed.count;
  placeScore = placeScore+placeExperiencesFactor*placeStats.usersExperienced;
  placeScore = placeScore+placeLikesFactor*placeStats.usersLiked;
  placeScore = placeScore+placeFavoriteFactor*placeStats.inspired.count;


  this.setState({progress:10})
  if (systemPlace!==undefined){
    this.updateToDatabase(placePath,systemPlace);
  }
 
  
  //update slim place score
  let slimPath = this.props.environment + "/systemData/slimPlacesData/"+placeCountry+"/"+placeID+"/"+placeScore;
  await this.updateToDatabase(slimPath, placeScore);
  this.setState({progress:20})

  //save experience images
  let storagePath = 
  this.props.environment+"/usersData/"+this.props.userID+"/userExperiences/"
    let largeImages =  experience.imageGallery.map(img=>{return img.largeImage});
    let smallImages = experience.imageGallery.map(img=>{return img.smallImage}); 
    let mediumImages =  experience.imageGallery.map(img=>{return img.mediumImage});
    let thumbnailImages = experience.imageGallery.map(img=>{return img.thumbnailImage}); 
    let savedLargeImages=[];
    let savedSmallImages=[];
    let savedMediumImages=[];
    let savedThumbnailImages=[];
    await this.uploadImageToFirebase(true,experience.experienceID,storagePath,largeImages,"largeImage").then(result => {
      savedLargeImages = result;
    }); 
    this.setState({progress:30})
    await this.uploadImageToFirebase(true,experience.experienceID,storagePath, smallImages,"smallImage").then(result => {
      savedSmallImages = result;
    }); 
    this.setState({progress:40})
    await this.uploadImageToFirebase(true,experience.experienceID,storagePath,mediumImages,"mediumImage").then(result => {
      savedMediumImages = result;
    }); 
    this.setState({progress:50})
    await this.uploadImageToFirebase(true,experience.experienceID,storagePath, thumbnailImages,"thumbnailImage").then(result => {
      savedThumbnailImages = result;
    }); 
    this.setState({progress:60})
    saveExperience.imagePreview=[];
    saveExperience.imageGallery=[]
    for (let i=0 ; i<savedLargeImages.length ; i++){
      saveExperience.imagePreview.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
        mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
      saveExperience.imageGallery.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
        mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
    }

  delete saveExperience.oldRank
  //update place Experiences collection
  await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(placeID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
  this.setState({progress:70})
  //save experience to collection
  await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
  
  //save experience to each tagged user collection
  for (let i=0 ; i<experience.taggedUsers.length ; i++){
    let userID = experience.taggedUsers[i].userID
    await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(userID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
  }

  this.setState({progress:80})

  //add experience to plan
  if (experience.experiencePlanID!==undefined){
    let planID = experience.experiencePlanID;
   // await firestore.collection('plansExperiences').doc(planID).collection('experiences').doc(experience.experienceID).set(saveExperience);
    await this.addExperienceToPlan(planID, saveExperience);
  }
  

  //update behavior tags
   //update specific user and system statistics..
   let locationTags = [...experience.experienceLocation];
   let categoryTags = [...experience.experienceCategory];
   let tagsDBPaths = [];
   let tagsGlobalDBPaths = [];
   for (let i=0 ; i<locationTags.length ; i++){
     //individual
     tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+placeCountry+"/"+locationTags[i])
     tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userLocationsProfile/"+locationTags[i])
     //global
     tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorData/usersLocationsProfile/"+placeCountry+"/"+locationTags[i]);
  
     if (this.props.userResidenceCountry!==undefined){
      tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersLocationsProfile/"+placeCountry+"/"+locationTags[i])
    }
    }
   for (let i=0 ; i<categoryTags.length ; i++){
     //individual
     tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+placeCountry+"/"+categoryTags[i])
     tagsDBPaths.push(this.props.environment + "/usersData/" + this.props.userID + "/userInfo/userBehaviorProfile/userCategoriesProfile/"+categoryTags[i])
     //global
     tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorData/usersCategoriesProfile/"+placeCountry+"/"+categoryTags[i]);
   
     if (this.props.userResidenceCountry!==undefined){
      tagsGlobalDBPaths.push(this.props.environment + "/systemData/usersBehaviorDataByResidence/"+this.props.userResidenceCountry+"/usersCategoriesProfile/"+placeCountry+"/"+categoryTags[i])
    }
    }
   if (this.props.loggedUser){
       this.updateBehaviorProfile(placeID,tagsDBPaths,"experiencedPlace")
   }
   
   this.updateGlobalBehaviorProfile(this.props.userID,tagsGlobalDBPaths,"experiencedPlace")

    this.setState({progress:90})
    
    //update main page user experiences lists (including if shared experience from plan - should be automatically)
    this.props.callBack("CreateExperience",saveExperience)
    //update user stats - created experiences
    let date = new Date();
    const dateMetric = moment(date);
    let dateString = dateMetric.format('DD-MM-YYYY').toString();
    let metricPath = this.props.environment +  "/systemMetrics/createdContent/experiences/"+dateString;
    await this.updateStatistics(
    metricPath,
    "totalExperiences",
    experience.experienceID, true);
    metricPath = this.props.environment +  "/systemMetrics/createdContent/experiences/"+dateString;
    await this.updateStatistics(
    metricPath,
    "uniqueUsers",
    this.props.userID, true);

    let statsPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics";  
    this.updateStatistics(
      statsPath,
      "createdExperiences",
      experience.experienceID,
      true
    );
    this.props.finishWriteDataMessage("createExperienceFinished");
    await this.setState({processing:false, showProgress:false})

  }

  updateExperience = async (experience,removedUserID) =>{
    await this.setState({processing:true})
    this.setState({progress:2,showProgress:true})
    let firestore = firebase.firestore()
    let placeCountry = experience.experienceLocation[0]
    let placeID = experience.experiencePlaceID
    let saveExperience = experience
    let experienceOwnerID = experience.owner.ownerID
    //update place stats
    if (experience.experienceRank!==experience.oldRank){
      let systemPlace;
      let placeScore;
      let placePath = this.props.environment +"/systemData/places/"+placeCountry+"/"+placeID;
      await firebase.database()
      .ref( placePath)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          systemPlace = snapshot.val()
        }
      });
      if (systemPlace!==undefined){

      }
      let placeStats=systemPlace.placeStats;
      let experienceUsersRank = [];
      if (systemPlace.experienceUsersRank!==undefined){
        experienceUsersRank = systemPlace.experienceUsersRank;
      }
      //await this.updatePlaceUsersRank(experienceUsersRank,this.props.userID,experience.experienceID,experience.oldRank,false)
      await this.updatePlaceUsersRank(experienceUsersRank,experienceOwnerID,experience.experienceID,experience.experienceRank,"UpdateExperience").then(result => {
        experienceUsersRank = result.usersRanks;
        placeStats.usersLiked+=result.likesUpdate; 
        if (placeStats.usersDisliked!==undefined){
          placeStats.usersDisliked+=result.dislikesUpdate; 
        }
        else{
          placeStats.usersDisliked=result.dislikesUpdate; 
        }
        });
      systemPlace.placeStats=placeStats;
      systemPlace.experienceUsersRank = experienceUsersRank;
      placeScore = 0;
      let placeViewesFactor=1;
      let placeExperiencesFactor=1;
      let placeLikesFactor=1;
      let placeFavoriteFactor=1;
      
      placeScore = placeScore+placeViewesFactor*placeStats.viewed.count;
      placeScore = placeScore+placeExperiencesFactor*placeStats.usersExperienced;
      placeScore = placeScore+placeLikesFactor*placeStats.usersLiked;
      placeScore = placeScore+placeFavoriteFactor*placeStats.inspired.count;

      //update place stats
      this.updateToDatabase(placePath,systemPlace);
      //update slim place score
      let slimPath = this.props.environment + "/systemData/slimPlacesData/"+placeCountry+"/"+placeID+"/"+placeScore;
      this.updateToDatabase(slimPath, placeScore);
      this.setState({progress:20})
      
    }
    //save experience images
    let storagePath = 
    this.props.environment+"/usersData/"+experienceOwnerID+"/userExperiences/"

    let removedImagesUrls = []
    let newImages = []
    let newImagesIndices = [];
    let imageGalleryCopy = [...experience.imageGallery]
    for (let i=0 ;i<imageGalleryCopy.length ; i++){
      if (imageGalleryCopy[i].largeImage.type === "image/jpeg"||imageGalleryCopy[i].largeImage.type === "image/png"){
        newImagesIndices.push(i)
      }
    }

    if (experience.deletedImages.length>0){
      if (experience.deletedImages[0]!==undefined){
        //for plan journey
        removedImagesUrls=[...experience.deletedImages].map(img=>{return img.url})

        let deletePathSmall =  storagePath+experience.experienceID+"/smallImage";
        let deletePathLarge =  storagePath+experience.experienceID+"/largeImage";
        let deletePathMedium =  storagePath+experience.experienceID+"/mediumImage";
        let deletePathThumbnail =  storagePath+experience.experienceID+"/thumbnailImage";
        let deletedImages = [...experience.deletedImages].map(img=>{return img.name});
        this.deleteFromStorage(deletePathSmall,deletedImages);
        this.deleteFromStorage(deletePathLarge,deletedImages);
        this.deleteFromStorage(deletePathMedium,deletedImages);
        this.deleteFromStorage(deletePathThumbnail,deletedImages);
      }
    
    } 
      let largeImages =  experience.imageGallery.map(img=>{return img.largeImage});
      let smallImages = experience.imageGallery.map(img=>{return img.smallImage}); 
      let mediumImages =  experience.imageGallery.map(img=>{return img.mediumImage});
      let thumbnailImages = experience.imageGallery.map(img=>{return img.thumbnailImage}); 
      let savedLargeImages=[];
      let savedSmallImages=[];
      let savedMediumImages=[];
      let savedThumbnailImages=[];
     
      await this.uploadImageToFirebase(false,experience.experienceID,storagePath,largeImages,"largeImage").then(result => {
        savedLargeImages = result;
      }); 
      this.setState({progress:30})
      await this.uploadImageToFirebase(false,experience.experienceID,storagePath, smallImages,"smallImage").then(result => {
        savedSmallImages = result;
      }); 
      this.setState({progress:40})
      await this.uploadImageToFirebase(false,experience.experienceID,storagePath,mediumImages,"mediumImage").then(result => {
        savedMediumImages = result;
      }); 
      this.setState({progress:50})
      await this.uploadImageToFirebase(false,experience.experienceID,storagePath, thumbnailImages,"thumbnailImage").then(result => {
        savedThumbnailImages = result;
      }); 
      this.setState({progress:60})
      saveExperience.imagePreview=[];
      saveExperience.imageGallery=[]
      for (let i=0 ; i<savedLargeImages.length ; i++){
        saveExperience.imagePreview.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
          mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
        saveExperience.imageGallery.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
          mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
        if (newImagesIndices.includes(i)){
          newImages.push({largeImage:savedLargeImages[i],smallImage:savedSmallImages[i],
            mediumImage:savedMediumImages[i],thumbnailImage:savedThumbnailImages[i]})
        }
      }

    //update place Experiences collection
    await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(placeID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
    this.setState({progress:70})
    //save experience to collection
    await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(experienceOwnerID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
    
    //save experience to each tagged user collection
    for (let i=0 ; i<experience.taggedUsers.length ; i++){
      let userID = experience.taggedUsers[i].userID
      if (removedUserID!==undefined){
        if (userID!==removedUserID){
          await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(userID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
        }
      }
     
    }

    //if user was removed from experience - delete experience from his collection
    for (let i = 0 ;i <experience.removedTaggedUsers.length ; i++){
      let userID = experience.removedTaggedUsers[i].userID
      await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(userID).collection('experiences').doc(experience.experienceID.toString()).delete();
    }
  
    this.setState({progress:80})
  
    //add experience to plan
    if (experience.experiencePlanID!==undefined){
      let planID = experience.experiencePlanID;
      await firestore.collection(this.state.firestorePrefix+'plansExperiences').doc(planID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
      this.updatePlanExperiencesImages(experience,removedImagesUrls,newImages)
      
    }
    
    this.setState({progress:90})
      
      //update main page user experiences lists (including if shared experience from plan - should be automatically)
      if (removedUserID===undefined){
        this.props.callBack("UpdateExperience",saveExperience)
        //update user stats - created experiences
        this.props.finishWriteDataMessage("updateExperienceFinished");
      }
     
  
      await this.setState({processing:false, showProgress:false})
  
    }

    deleteExperience = async (experience) =>{
      await this.setState({processing:true})
      this.setState({progress:2,showProgress:true})
      let firestore = firebase.firestore()
      let placeCountry = experience.experienceLocation[0]
      let placeID = experience.experiencePlaceID
      //update place stats
      let systemPlace;
      let placeScore;
      let placePath = this.props.environment +"/systemData/places/"+placeCountry+"/"+placeID;
      await firebase.database()
      .ref( placePath)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()!==null){
          systemPlace = snapshot.val()
        }
      });
      if (systemPlace!==undefined){
        let placeStats=systemPlace.placeStats;
          let experienceUsersRank = [];
          if (systemPlace.experienceUsersRank!==undefined){
            experienceUsersRank = systemPlace.experienceUsersRank;
          }
          await this.updatePlaceUsersRank(experienceUsersRank,this.props.userID,experience.experienceID,experience.experienceRank,"DeleteExperience").then(result => {
            experienceUsersRank = result.usersRanks;
            placeStats.usersLiked+=result.likesUpdate; 
            if (placeStats.usersDisliked!==undefined){
              placeStats.usersDisliked+=result.dislikesUpdate; 
            }
            else{
              placeStats.usersDisliked=result.dislikesUpdate; 
            }
            placeStats.usersExperienced+=result.experienceUsers;
            placeStats.totalExperiences-=1;
          });
          systemPlace.placeStats=placeStats;
          systemPlace.experienceUsersRank = experienceUsersRank;
          
          placeScore = 0;
          let placeViewesFactor=1;
          let placeExperiencesFactor=1;
          let placeLikesFactor=1;
          let placeFavoriteFactor=1;
          
          placeScore = placeScore+placeViewesFactor*placeStats.viewed.count;
          placeScore = placeScore+placeExperiencesFactor*placeStats.usersExperienced;
          placeScore = placeScore+placeLikesFactor*placeStats.usersLiked;
          placeScore = placeScore+placeFavoriteFactor*placeStats.inspired.count;

          //update place stats
          this.updateToDatabase(placePath,systemPlace);
          //update slim place score
          let slimPath = this.props.environment + "/systemData/slimPlacesData/"+placeCountry+"/"+placeID+"/"+placeScore;
          this.updateToDatabase(slimPath, placeScore);
          this.setState({progress:20})
      }
          
     

      //save experience images
      let storagePath = 
      this.props.environment+"/usersData/"+this.props.userID+"/userExperiences/"
  
      if (experience.imagePreview!==undefined){
        let deletePathSmall =  storagePath+experience.experienceID+"/smallImage";
        let deletePathLarge =  storagePath+experience.experienceID+"/largeImage";
        let deletePathMedium =  storagePath+experience.experienceID+"/mediumImage";
        let deletePathThumbnail =  storagePath+experience.experienceID+"/thumbnailImage";
        let deletedImages = [...experience.imagePreview].map(img=>{return img.smallImage.name});
        this.deleteFromStorage(deletePathSmall,deletedImages);
        this.deleteFromStorage(deletePathLarge,deletedImages);
        this.deleteFromStorage(deletePathMedium,deletedImages);
        this.deleteFromStorage(deletePathThumbnail,deletedImages);
      }
  
      //update place Experiences collection
      await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(placeID).collection('experiences').doc(experience.experienceID.toString()).delete();
      this.setState({progress:70})
      //save experience to collection
      await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences').doc(experience.experienceID.toString()).delete();
      
      //save experience to each tagged user collection
      for (let i=0 ; i<experience.taggedUsers.length ; i++){
        let userID = experience.taggedUsers[i].userID
        await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(userID).collection('experiences').doc(experience.experienceID.toString()).delete();
      }
    
      this.setState({progress:80})
    
      //add experience to plan
      if (experience.experiencePlanID!==undefined){
        await this.removeExperienceFromPlan(experience,true);
      }
      
      this.setState({progress:90})
        
      //update main page user experiences lists (including if shared experience from plan - should be automatically)
      this.props.callBack("DeleteExperience",experience.experienceID)
      //update user stats - created experiences
      
      let statsPath = this.props.environment + "/usersData/" + this.props.userID + "/userInfo/statistics";  
      this.updateStatistics(
        statsPath,
        "createdExperiences",
        experience.experienceID,
        false
      );
      await this.setState({processing:false, showProgress:false})
    }

    removeTaggingHandler = async experience =>{
      await this.setState({processing:true})
      //remove the experience for the tagged user
      let firestore = firebase.firestore()
      await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences').doc(experience.experienceID.toString()).delete()
      //update experience tagging for all instances
      let taggedUsers = experience.taggedUsers
      const index = taggedUsers.findIndex(user => {
        return user.userID === this.props.userID;
      });
      taggedUsers.splice(index,1)
      let newExperience = {...experience}
      newExperience.taggedUsers=[...taggedUsers]
      this.updateExperience(newExperience,this.props.userID)
      

      this.props.callBack("DeleteExperience",experience.experienceID)

      await this.setState({processing:false})
    }

  addExperienceToPlan = async (planID, experience, updateExperience) =>{
    await this.setState({processing:true})
     let planOwnerID = planID.split('_')[1]
     let dbPlan;
    let dbPlanPath = this.props.environment+"/usersData/"+planOwnerID+"/userPlans/"+planID;
    await firebase.database()
    .ref( dbPlanPath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        dbPlan = snapshot.val()
      }
    });
    
    //updating plan days places
    let experienceDate = new Date (experience.experienceDate)
    let expDate = moment (experienceDate)
    let startDate = new Date(dbPlan.planDates.startDate);
    let planStartDate =  moment(startDate);
    let dayIndex = expDate.diff(planStartDate,'days')+1
    let planDays = dbPlan.planDays;
    let placeIncluded = false;
    let dayItemsHasValues = false
    if (planDays[dayIndex].dayItems!==undefined){
      dayItemsHasValues = true
      for (let i=0 ; i< planDays[dayIndex].dayItems.length ; i++){
        if ( planDays[dayIndex].dayItems[i].type==="place"&&
             planDays[dayIndex].dayItems[i].item.placeID===experience.experiencePlaceID){
          placeIncluded = true;
          let placeExperiences = {};
          if (planDays[dayIndex].dayItems[i].item.placeExperiences!==undefined){
            placeExperiences=planDays[dayIndex].dayItems[i].item.placeExperiences
          }
          if (placeExperiences[experience.owner.ownerID]!==undefined){
            let userExperiences = [];
            if (placeExperiences[experience.owner.ownerID].userExperiences!==undefined){
              userExperiences=placeExperiences[experience.owner.ownerID].userExperiences
            }
            if (!userExperiences.includes(experience.experienceID)){
              userExperiences.push(experience.experienceID);
              let userJourneyImages = []
              if (placeExperiences[experience.owner.ownerID].userJourneyImages!==undefined){
                userJourneyImages=placeExperiences[experience.owner.ownerID].userJourneyImages
              }
              if (experience.imagePreview!==undefined){
                userJourneyImages=userJourneyImages.concat(experience.imagePreview)
              }
              placeExperiences[experience.owner.ownerID].userExperiences=userExperiences
              placeExperiences[experience.owner.ownerID].userJourneyImages=userJourneyImages
            }
           
          }
          else{
            let userContent={}
            let userExperiences = [experience.experienceID];
            let userJourneyImages = [];
            if (experience.imagePreview!==undefined){
              userJourneyImages=userJourneyImages.concat(experience.imagePreview)
            }
            userContent.userExperiences=userExperiences
            userContent.userJourneyImages=userJourneyImages
            placeExperiences[experience.owner.ownerID]=userContent
          }
          planDays[dayIndex].dayItems[i].item.placeExperiences = placeExperiences
        }
      }  
    }
    
    if (!placeIncluded || !dayItemsHasValues){
        let place = {}
        place.placeID = experience.experiencePlaceID;
        place.placeColorNumber=0;
        place.placeVote=[];
        let item={type:"place",item:place}

        let placeExperiences={};
        let userContent={}
        let userExperiences = [experience.experienceID];
        let userJourneyImages = [];
        userJourneyImages=userJourneyImages.concat(experience.imagePreview)
        userContent.userExperiences=userExperiences
        userContent.userJourneyImages=userJourneyImages
        placeExperiences[experience.owner.ownerID]=userContent
        item.item.placeExperiences=placeExperiences
        if (dayItemsHasValues){
          planDays[dayIndex].dayItems.push(item)
        }
        else{
          planDays[dayIndex].dayItems=[item]
        }
        if (!placeIncluded){
          let planPlacesList = [];
          await this.loadTripPlanPlacesList(dbPlan).then(result => {
            planPlacesList = result
          }); 
          this.updateSlimPlan(dbPlan,planPlacesList,true)
        }
      }
    
      dbPlan.planDays=planDays
      this.updateToDatabase(dbPlanPath,dbPlan);

      let firestore = firebase.firestore()
      let saveExperience = experience
      saveExperience.experiencePlanID=planID;
      saveExperience.experiencePlanName = dbPlan.planName;
      saveExperience.experiencePlanSecurity = dbPlan.planSecurity;
      saveExperience.experienceAlbumSecurity = dbPlan.albumSecurity[this.props.userID]
      await firestore.collection(this.state.firestorePrefix+'plansExperiences').doc(planID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
      await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(experience.experiencePlaceID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
        await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
      if (updateExperience){
        this.props.callBack("UpdateExperience",saveExperience)
      }

    await this.setState({processing:false})
  }

  removeExperienceFromPlan = async (experience,deleteExp) =>{
    return new Promise(async (resolve, reject) => {
    await this.setState({processing:true})
    let planID = experience.experiencePlanID;
    let planOwnerID = planID.split('_')[1]
    let dbPlan;
    let dbPlanPath = this.props.environment+"/usersData/"+planOwnerID+"/userPlans/"+planID;
    await firebase.database()
    .ref( dbPlanPath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        dbPlan = snapshot.val()
      }
    });
   
    let experienceDate = new Date (experience.experienceDate)
    let expDate = moment (experienceDate)
    let startDate = new Date(dbPlan.planDates.startDate);
    let planStartDate =  moment(startDate);
    let dayIndex = expDate.diff(planStartDate,'days')+1
    let planDays = dbPlan.planDays;
    let itemIndex;
    //find the location of the experience
    for (let i=0 ; i< planDays[dayIndex].dayItems.length ; i++){
      if ( planDays[dayIndex].dayItems[i].type==="place"&&
      planDays[dayIndex].dayItems[i].item.placeID===experience.experiencePlaceID){
        itemIndex=i
      }
    }
 
    let placeExperiences = planDays[dayIndex].dayItems[itemIndex].item.placeExperiences;
    if (placeExperiences!==undefined){
      if (placeExperiences[this.props.userID]!==undefined){
        let removeIndex=-1;
        if (placeExperiences[this.props.userID].userExperiences!==undefined){
          for (let j=0 ; j<placeExperiences[this.props.userID].userExperiences.length ; j++){
            if (placeExperiences[this.props.userID].userExperiences[j]===experience.experienceID){
              removeIndex=j; 
            }
          }
        }
        if (removeIndex!==-1)
        {

          placeExperiences[this.props.userID].userExperiences.splice(removeIndex,1)
          if (Object.keys(placeExperiences[this.props.userID].userExperiences).length===1&&placeExperiences[this.props.userID].userExperiences.experienceText!==undefined){
            delete placeExperiences[this.props.userID].userExperiences.experienceText
          }
        } 
       }
       let placeExperiencesKeys = Object.keys(placeExperiences)
        for (let i=0 ; i<placeExperiencesKeys.length ; i++){
          let updatedUserJourneyImages = [];
          if (placeExperiences[placeExperiencesKeys[i]].userJourneyImages!==undefined){
            let largeImageURLs = experience.imagePreview.map(img => {return img.largeImage.url})
            for (let j=0 ; j<placeExperiences[placeExperiencesKeys[i]].userJourneyImages.length ; j++){
              if (!largeImageURLs.includes(placeExperiences[placeExperiencesKeys[i]].userJourneyImages[j].largeImage.url)){
                updatedUserJourneyImages.push(placeExperiences[placeExperiencesKeys[i]].userJourneyImages[j])
              }
            }
            
          }
          placeExperiences[placeExperiencesKeys[i]].userJourneyImages=updatedUserJourneyImages
        }
        planDays[dayIndex].dayItems[itemIndex].item.placeExperiences=placeExperiences
    }
   
      let date = new Date();
      let timestamp = date.getTime();
      let planEditors = dbPlan.planEditors
      const index = planEditors.findIndex(user => {
        return user.userID === this.props.userID;
    });
    planEditors[index].planLastModified=timestamp;
      dbPlan.planLastModified = timestamp
      dbPlan.planEditors = planEditors
      dbPlan.planDays=planDays
      this.updateToDatabase(dbPlanPath,dbPlan);

      let firestore = firebase.firestore()
      let saveExperience = experience
      delete saveExperience.experiencePlanID;
      delete saveExperience.experiencePlanName;
      delete saveExperience.experiencePlanSecurity;
      delete saveExperience.experienceAlbumSecurity;

      await this.setState({processing:false})
    
      await firestore.collection(this.state.firestorePrefix+'plansExperiences').doc(planID).collection('experiences').doc(experience.experienceID.toString()).delete();
      await firestore.collection(this.state.firestorePrefix+'placesExperiences').doc(experience.experiencePlaceID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
      if(!deleteExp){
        await firestore.collection(this.state.firestorePrefix+'usersExperiences').doc(this.props.userID).collection('experiences').doc(experience.experienceID.toString()).set(saveExperience);
      }
      
        
      this.props.callBack("UpdateExperience",saveExperience)
      resolve(true);
    });
  }

  updatePlanExperiencesImages = async (experience,removeImages,addImages) =>{
    await this.setState({processing:true})
    let planID = experience.experiencePlanID;
    let planOwnerID = planID.split('_')[1]
    let dbPlan;
    let dbPlanPath = this.props.environment+"/usersData/"+planOwnerID+"/userPlans/"+planID;
    await firebase.database()
    .ref( dbPlanPath)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        dbPlan = snapshot.val()
      }
    });
   
    let experienceDate = new Date (experience.experienceDate)
    let expDate = moment (experienceDate)
    let startDate = new Date(dbPlan.planDates.startDate);
    let planStartDate =  moment(startDate);
    let dayIndex = expDate.diff(planStartDate,'days')+1
    let planDays = dbPlan.planDays;
    let itemIndex;
    //find the location of the experience
    for (let i=0 ; i< planDays[dayIndex].dayItems.length ; i++){
      if ( planDays[dayIndex].dayItems[i].type==="place"&&
      planDays[dayIndex].dayItems[i].item.placeID===experience.experiencePlaceID){
        itemIndex=i
      }
    }
 
    let placeExperiences = planDays[dayIndex].dayItems[itemIndex].item.placeExperiences;
    if (placeExperiences!==undefined){
      let updatedUserJourneyImages = []
      if (placeExperiences[this.props.userID].userJourneyImages!==undefined){
        updatedUserJourneyImages = placeExperiences[this.props.userID].userJourneyImages
      }
      updatedUserJourneyImages = updatedUserJourneyImages.concat(addImages)
      placeExperiences[this.props.userID].userJourneyImages = updatedUserJourneyImages
    }

    let placeExperiencesKeys = Object.keys(placeExperiences)
      for (let i=0 ; i<placeExperiencesKeys.length ; i++){
        let updatedUserJourneyImages = [];
        if (placeExperiences[placeExperiencesKeys[i]].userJourneyImages!==undefined){
          for (let j=0 ; j<placeExperiences[placeExperiencesKeys[i]].userJourneyImages.length ; j++){
            if (!removeImages.includes(placeExperiences[placeExperiencesKeys[i]].userJourneyImages[j].smallImage.url)){
              updatedUserJourneyImages.push(placeExperiences[placeExperiencesKeys[i]].userJourneyImages[j])
            }
          }
          
        }
        placeExperiences[placeExperiencesKeys[i]].userJourneyImages=updatedUserJourneyImages
      }
      planDays[dayIndex].dayItems[itemIndex].item.placeExperiences=placeExperiences
   

    dbPlan.planDays=planDays
    this.updateToDatabase(dbPlanPath,dbPlan);

    await this.setState({processing:false})
  }


//--------MISC-----------
sendContactRequest = async request =>{
  const today = new Date();
  const moment = require('moment');
  const todayDate = moment(today).format('YYYY-MM-DD').toString();
  let dbPath = this.props.environment + "/systemData/usersContactRequests/newRequests/"+todayDate+"/"+request.requestTopic
  await this.addToDatabase(dbPath, request, request.requestID);
  this.props.callBack("RequestSent")
}

writeAcitivityLog = async activityKey =>{
  /* console.log(activityKey) */
  //handles reduction of events collected only to essential
 /*  console.log(activityKey.eventLevel)
  if (activityKey.eventLevel!==1){
    return
  } */
  if (!this.props.adminUser){
  const today = new Date();
  let timestamp = today.getTime();
  const moment = require('moment');
  const todayDate = moment(today).format('YYYY-MM-DD').toString();
  let activity = activityKey
  activity.userID = this.props.userID
  activity.userSessionID = this.props.userSessionID
  activity.timestamp = timestamp
  activity.userCountry = this.props.userCountry
  activity.loggedUser = this.props.loggedUser
  let deviceType = "DESKTOP"
  if (isMobileOnly) {deviceType = "MOBILEONLY"}
  else if (isMobile) {deviceType = "MOBILE"}
  activity.deviceType = deviceType
  let dbPath = this.props.environment+"/systemMetrics/activitiesLog"

  await firebase.firestore().collection(this.state.firestorePrefix+'activitiesLog').doc(todayDate).collection('dayActivities').doc(activity.timestamp+"_"+activity.userID).set(activity);
  await this.addToDatabase(dbPath,  true,todayDate);

  //add to google analytics
  
    //collect google analythics only if not admin user
    let googleParams = {...activityKey}
    delete googleParams.activityKey
    analytics.logEvent(activityKey.activityKey, googleParams);
  } 
  
}

googleAnalyticsWrite = value =>{
  analytics.logEvent(value.activityKey, value.googleParams);
}

downloadActivityLogHandler = async date =>{
  let activitiesLog = []
/*   await firebase.database()
  .ref(this.props.environment +  "/systemMetrics/activitiesLog/"+date)
  .on("value",(snapshot)=>{
    if (snapshot.val()!==null){
      activitiesLog=Object.values(snapshot.val())
     this.props.callBack("ActivitiesLogData",{log:activitiesLog,date:date})
    }
  })
 */
  const activityDates = await firebase.firestore().collection(this.state.firestorePrefix+'activitiesLog').doc(date.toString()).collection("dayActivities").get()
  activityDates.forEach(doc => {
    activitiesLog.push(doc.data());
  });
  this.props.callBack("ActivitiesLogData",{log:activitiesLog,date:date})
}

downloadRequestsHandler = async date =>{
  let usersRequests = {}
  let usersRequestsData = []
  let requestsTypes = [] 
  await firebase.database()
    .ref( this.props.environment +  "/systemData/usersContactRequests/newRequests/"+date)
    .once("value")
    .then(snapshot => {
      if (snapshot.val()!==null){
        usersRequests=snapshot.val()
        requestsTypes =Object.keys(snapshot.val())
      }
    });

  for (let i=0 ; i<requestsTypes.length ; i++){
    usersRequestsData = usersRequestsData.concat(Object.values(usersRequests[requestsTypes[i]]))
  }
  this.props.callBack("UsersRequestsData",{requests:usersRequestsData,date:date})
}

logUserTripGenerationParams = async params =>{
  const today = new Date();
  let timestamp = today.getTime();

  params.timestamp = timestamp
  params.userID = this.props.userID
  params.loggedUser = this.props.loggedUser

  await firebase.firestore().collection(this.state.firestorePrefix+'generatedTripsParams').doc(params.timestamp+"_"+params.userID).set(params);
}

disableUserWelcomeWizardFlag = () =>{
  this.updateToDatabase(this.props.environment+"/users/" + this.props.userID + "/showWelcomeWizard", false);
}

popularTouristSiteHandler = async (placeID,placeCountry,value) =>{
  await this.setState({processing:true})
  this.updateToDatabase( this.props.environment + "/systemData/places/"+placeCountry+"/"+ placeID+"/topTouristSite", value);
  this.updateToDatabase(this.props.environment + "/systemData/slimPlacesData/"+placeCountry+"/"+ placeID+"/topTouristSite", value);
  await this.setState({processing:false})
}

renderProgressBar = () =>{
  if (this.state.showProgress){
    switch(this.state.progress){
      case 0:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.ZeroProgress].join(" ")}/>
          </div>
        )
      }
      case 2:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.TwoProgress].join(" ")}/>
          </div>
        )
      }
      case 10:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.TenProgress].join(" ")}/>
          </div>
        )
      }
      case 20:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.TwentyProgress].join(" ")}/>
          </div>
        )
      }
      case 30:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.ThirtyProgress].join(" ")}/>
          </div>
        )
      }
      case 40:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.FourtyProgress].join(" ")}/>
          </div>
        )
      }
      case 50:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.FiftyProgress].join(" ")}/>
          </div>
        )
      }
      case 60:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.SixtyProgress].join(" ")}/>
          </div>
        )
      }
      case 70:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.SeventyProgress].join(" ")}/>
          </div>
        )
      }
      case 80:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.EithyProgress].join(" ")}/>
          </div>
        )
      }
      case 90:{
        return(
          <div className={classes.ProgressBar}>
              <div className={[classes.Progress,classes.NintyProgress].join(" ")}/>
          </div>
        )
      }
      default: return null;
    }
  }
}

  renderProcessing = () => {
    return (
      <div className={classes.Saving}>
        <img src={saving} alt="" className={classes.SavingGif} />
        {this.renderProgressBar()}
      </div>
    );
};


  render(){
    if (this.state.processing){
      return <div className={classes.Body}>{this.renderProcessing()}</div>;
    }
    else{
      return <div></div>;
    }
  }
  }
  export default WriteDatabase;