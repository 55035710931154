/* const firebaseConfig = {
  apiKey: "AIzaSyAddAXW219MB1QizAtOe4kAIrgIMb1bUs8",
  authDomain: "mindu-a42b7.firebaseapp.com",
  databaseURL: "https://mindu-a42b7.firebaseio.com",
  projectId: "mindu-a42b7",
  storageBucket: "mindu-a42b7.appspot.com",
  messagingSenderId: "1026926938995",
  appId: "1:1026926938995:web:8876d4141817c47d0d1306",
  measurementId: "G-R9XHB102CM"
  };
  
  export default firebaseConfig;  */

const firebaseConfig = {
    apiKey: "AIzaSyCH2xTFFNlU9mam4kU8KFswhZiwij5WTc4",
    authDomain: "planexp.com",
    databaseURL: "https://mindu-alpha.firebaseio.com",
    projectId: "mindu-alpha",
    storageBucket: "mindu-alpha.appspot.com",
    messagingSenderId: "910005657888",
    appId: "1:910005657888:web:c2123dac02f5504b",
    measurementId: "G-XNPNNTNWT4"
  }; 
  export default firebaseConfig; 
  