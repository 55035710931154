import React, { Component, PureComponent } from "react";
import classes from "./SearchBarContentType.module.css";

import IconTab from "../Tabs/IconTab"

let placesIconGrey="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIconGrey.png?alt=media&token=14eefd33-8dd4-4473-aa17-babc75342117"
let placesIcon="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"
let tripsIconGrey="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTripIconGrey.png?alt=media&token=eb61b497-5906-4b11-bd33-413e77cc4e45"
let tripsIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FTripIcon.png?alt=media&token=42112c10-971f-4b71-9c5b-5c2bc19e6245"
let experiencesIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FExperienceIconGrey.png?alt=media&token=e54dfc0d-7cef-4432-81e6-fd4643a8a75b"
let experiencesIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FExperienceIcon.png?alt=media&token=e6f8c146-ff24-4798-99e5-f1eeedad36d5"

export default class SearchBarContentType extends Component {
    state={
        showAllContentTypes:false
    }

  renderBody = () =>{
    let bodyClass = "BodyWrapper"
    if (this.props.RTL){
        bodyClass = "BodyWrapperRTL"
    }
    if (this.state.showAllContentTypes||this.props.constantlyOpenned){
        let alwaysOpennedClass = ""
        if (this.props.constantlyOpenned){
            alwaysOpennedClass="ContentTypeSeclectionAlwaysOpen"
        }
        let trips = tripsIconGrey;
        if (this.props.selectedContentType==="Trips"){
            trips=tripsIcon
        }
        let places = placesIconGrey;
        if (this.props.selectedContentType==="Places"){
            places=placesIcon;
        }
        let experiences = experiencesIconGrey;
        if (this.props.selectedContentType==="Experiences"){
            experiences=experiencesIcon;
        }
        return(
            <div className={[classes.ContentTypeSeclectionOpen,classes[bodyClass],classes[alwaysOpennedClass]].join(" ")}
            onMouseLeave = {()=>{this.setState({showAllContentTypes:false})}}
            >
                
                 
                
                {this.props.showExperiences?(
                    <IconTab
                    icon = {experiences}
                    text = {this.props.systemText.misc.experiencesName[this.props.userLanguage]}
                    selected = {this.props.selectedContentType==="Experiences"}
                    style={"Style1"}
                    clicked={()=>{this.props.updateSelectedContentType("Experiences");this.setState({showAllContentTypes:false})}}
                    />
                ):null}
                
                {this.props.showTrips?(
                 <IconTab
                    icon = {trips}
                    text = {this.props.systemText.misc.tripsName[this.props.userLanguage]}
                    selected = {this.props.selectedContentType==="Trips"}
                    style={"Style1"}
                    clicked={()=>{this.props.updateSelectedContentType("Trips");this.setState({showAllContentTypes:false})}}
                /> ):null}
                 <IconTab
                    icon = {places}
                    text = {this.props.systemText.misc.placesName[this.props.userLanguage]}
                    selected = {this.props.selectedContentType==="Places"}
                    style={"Style1"}
                    clicked={()=>{this.props.updateSelectedContentType("Places");this.setState({showAllContentTypes:false})}}
                />
            </div>
        )
    }
    else{
        let name = this.props.selectedContentType.toLowerCase()+"Name"
        let icon = placesIcon;
        if (this.props.selectedContentType==="Trips"){
            icon=tripsIcon
        }
        if (this.props.selectedContentType==="Experiences"){
            icon=experiencesIcon
        }
        return(
            <div className={[classes.ContentTypeSeclectionClosed,classes[bodyClass]].join(" ")}
            onMouseEnter = {()=>{this.setState({showAllContentTypes:true})}}
            onClick={()=>{this.setState({showAllContentTypes:true})}}
            >
                 <IconTab
                    icon = {icon}
                    text = {this.props.systemText.misc[name][this.props.userLanguage]}
                    selected = {true}
                    style={"Style1"}
                    clicked={()=>{return null}}
                />
            </div>
        )
    }
  }

  render(){
      return(
        <div>
        {this.renderBody()}
    </div>
      )
  }

};

