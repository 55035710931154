import React, { useState, useEffect } from "react";

import classes from "./ConfirmationModal.module.css";

import TextButton from "../Buttons/TextButton";

const ConfirmationModal = props => {


  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const renderButtons=()=>{
    return(
      <div className={classes.Buttons}>
          <TextButton
            clicked={() => {
              props.primaryAction();
            }}
            text={props.primaryBtn}
            style = {props.primaryStyle}
          />
          <div className={classes.ButtonsSeperator} />
          <TextButton
            clicked={() => {
              props.secondaryAction();
            }}
            text={props.secondaryBtn}
            style = {props.secondaryStyle}
          />
        </div>
    ) 
  }

  const renderHeader = ()=>{
    return(
      <div className={classes.Title}>{props.header}</div>
  )
  }

  const renderBody = () =>{
    let bodyClass = "Body"
    if (props.RTL){
      bodyClass = "BodyRTL"
    }
    return (<div className={classes.Body}>
      {renderHeader()}
     <div className={classes.Message}>
     {props.message.split('\n').map((str,index) => <p key={index}>{str}</p>)}</div>
     {renderButtons()}
   </div>)
  }

  return (
    <div
      className={classes.Modal}>
      {renderBody()} 
    </div>
  );
};

export default ConfirmationModal;
