import React, { Component } from "react";
import classes from "./PlanDaysScroller.module.css";

import PlanDayObject from "./PlanDayObject";

class PlanDaysScroller extends Component {
    state={
        
    }


renderTrip = () =>{

    return(
        <div  className={classes.PlanWall} style={{height:this.props.height}}>
        {this.props.planDays.map((day, index) => {
            if (index!==0 || this.props.isEditor || (this.props.externalPlaceAdd&&this.props.externalAdd))
            return(
                <PlanDayObject
                    numberOfPlanDays = {this.props.planDays.length}
                    planLastModified = {this.props.planLastModified}
                    isEditor={this.props.isEditor}
                    addToPlan={this.props.addToPlan}
                    externalAdd={this.props.externalAdd}
                    ongoingDay={this.props.ongoingDay}
                    editorsNumber={this.props.editorsNumber}
                    key={index}
                    index={index}
                    planOverviewMode = {this.props.planOverviewMode}
                    overviewAddPlaceHandler = {this.props.overviewAddPlaceHandler}
                    dayName={day.name}
                    dayItems={[...day.dayItems]}
                    selected={day.selected}
                    scrollerView={true}
                    viewHandler={itemIndex=>{this.props.viewHandler(index,itemIndex)}}
                    updateColor={(colorNumber,itemIndex)=>{this.props.updateColor(colorNumber,index,itemIndex)}}
                    updatePlaceTime={(time,itemIndex)=>{this.props.updatePlaceTime(time,index,itemIndex)}}
                    showDaySelection={this.props.showDaySelection}
                    planning={this.props.planning}
                    dayNames = {this.props.planDays.map(day=>{return day.name})}
                    moveDayHandler={(itemIndex,newDayIndex,type)=>{this.props.moveDayHandler(index,itemIndex,newDayIndex,type)}}
                    hoverHandler={(itemIndex,placeID)=>{this.props.hoverHandler(itemIndex,placeID,index)}}
                    hoverDayIndex = {this.props.hoverDayIndex}
                    clickedMarkerPlaceID={this.props.clickedMarkerPlaceID}
                    updateDayOrder={this.props.updateDayOrder}
                    addPlaceToDay={this.props.addPlaceToDay}
                    daySelectHandler={()=>{this.props.daySelectHandler(index)}}
                    removeFromPlan={(itemIndex)=>{this.props.removeFromPlan(index,itemIndex)}}

                    //editNoteHandler={itemIndex=>{this.props.editNoteHandler(index,itemIndex)}}
                    
                    addAccommodationHandler = {this.props.addAccommodationHandler}

                    newNoteHandler={()=>{this.props.newNoteHandler(index)}}
                    openModeNoteId = {this.props.openModeNoteId}
                    newNoteID = {this.props.newNoteID}
                    editNoteID = {this.props.editNoteID}
                    noteOpenHandler={(itemIndex,value)=>{this.props.noteOpenHandler(index,itemIndex,value)}}
                    updateNote={(note,itemIndex)=>{this.props.updateNote(note,index,itemIndex)}}

                    newTransportHandler={()=>{this.props.newTransportHandler(index)}}

                    voteHandler={(itemIndex,value)=>{this.props.voteHandler(value,index,itemIndex)}}


                    locationsTranslator = {this.props.locationsTranslator}
                    categoriesTranslator = {this.props.categoriesTranslator}

                    writeAcitivityLog = {this.props.writeAcitivityLog}

                    showSuggestedTrips = {this.props.showSuggestedTrips}
                    
                    RTL = {this.props.RTL}
                    user={this.props.user}
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                />
            )
        })}
        </div>
    )
}


    render(){
        return(<div className={classes.Body}>
            {this.renderTrip()}
        </div>)
    }
}

export default PlanDaysScroller;