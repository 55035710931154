import React, { Component } from "react";

 
class DetectInputLanguage extends Component {
    
    detectLanguage = (input)=>{
        return new Promise(async (resolve, reject) => {
            let language="English";
            //check hebrew
            var isHebrew = input.search(/[\u0590-\u05FF]/);
            if(isHebrew >= 0){
            language="Hebrew";
            }
            let languageChanged=false;
            if(this.props.discoverSearchLanguage!==language){
            languageChanged=true;
            }
            resolve(language);
        });
    }

    render(){
    return (
        <div
        ></div>
    );}
};
export default DetectInputLanguage;