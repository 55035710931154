import React, { Component } from "react";
import classes from "./ViewTripPlan.module.css";

import ModalTopSection from "../../../Components/UI/Misc/ModalTopSection"
import TransparentBackdrop from "../../../Components/UI/Backdrop/TransparentBackdrop"
import Backdrop from "../../../Components/UI/Backdrop/Backdrop"
import TripPlanOverviewEditor from "./TripPlanOverviewEditor"
import Tab from "../../../Components/UI/Tabs/Tab"
import TripPlanOverview from "./TripPlanOverview"
import TripPlanning from "./TripPlanning"
import SearchFunctions from "../../../Components/Functions/SearchFunctions"
import TripTemplateCreator from "../../../Components/Functions/TripTemplateCreator"
import ConfirmationModal from "../../../Components/UI/Modals/ConfirmationModal"
import InformationModal from "../../../Components/UI/Modals/InformationModal"
import PlanEditors from "../../../Components/Complex/Plan/PlanEditors"
import PlanMap from "./PlanMap"
import PlanTransportEditor from "../../../Components/Complex/Plan/Transport/PlanTransportEditor"
import ViewTransport from "../../../Components/Complex/Plan/Transport/ViewTransport"
import PlanSecurity from "../../../Components/Complex/Plan/PlanSecurity"
import PlanAccommodationSelector from "../../../Components/Complex/Plan/PlanAccommodationSelector"
import PlanDaysOptions from "../../../Components/Complex/Plan/PlanDaysOptions"
import TextButton from "../../../Components/UI/Buttons/TextButton"
import Toolkit from "../../../Components/UI/Toolbar/Toolkit"

import { isMobile, isMobileOnly } from "react-device-detect";

import { EditorState , convertToRaw, convertFromRaw} from 'draft-js';
import TripJourney from "./TripJourney";
import TripAlbum from "./TripAlbum"

let mapIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FLocationIcon.png?alt=media&token=7ab0f38c-21ab-4b86-abe3-97f41437e77f"
let optionsIcon="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDotsIconWhite.png?alt=media&token=0f607cf1-0fbc-4054-a1d0-a001d1cf2c2b"

let hasEditorsIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditorsIcon.png?alt=media&token=7259f4db-7b92-4c8c-893b-b5d9a0259c3d"
let editorsIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditorsIconGrey.png?alt=media&token=87917115-6039-484b-9a63-1a50baaa25ae"
let editIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIcon.png?alt=media&token=c964d68a-44ba-45bf-afd7-b4b8eaeb6fba"
let privacyIconSelected = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPrivacyIconBlue.png?alt=media&token=76c8e37e-703b-43bd-a5ce-6b948ead21a3"
let privacyIconGrey = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FPrivacyIconRed.png?alt=media&token=e48d06eb-cea0-4425-8da0-e94c37ddfea5"

var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);

class ViewTripPlan extends Component {
    state={
        planID:this.props.planID,
        planLastModified:this.props.planLastModified,
        isPublic:this.props.isPublic,
        planName:this.props.planName,
        planMode:"overview",
        overviewScrollSelected: "Trip",
        planDates:this.props.planDates,
        planDuration:this.props.planDuration,
        noDatesSelected:this.props.noDatesSelected,
        participants:this.props.participants,
        travelWithKids: this.props.travelWithKids,
        planDays:this.props.planDays,
        planPlacesIDs:this.props.planPlacesIDs,
        owner:this.props.owner,
        planEditors:this.props.planEditors,
        imagePreview:this.props.imagePreview,
        imageGallery:this.props.imageGallery,
        planExperiences:this.props.planExperiences,
        planTasks: this.props.planTasks,
        planSecurity: this.props.planSecurity,
        albumSecurity: this.props.albumSecurity,
        planModeOptions:/* :isMobile? */ ["SelectedMobileWide", "RegularMobileWide", "RegularMobileWide"]/*  : ["SelectedTabV2", "RegularTabV2", "RegularTabV2"] */,
        discoverPlacesLocationFilterTags:[],
        selectedDiscoverSource: "discover",
        publicPlanTags: this.props.publicPlanTags,
        planDescription: this.props.planDescription,
        planPlacesList:this.props.planPlacesList,
        planMapIdeasCount: 0,

        //searching places
        discoverCountryPlaces:[],
        discoverPlacesCategoriesFilterTags: [],
        discoverPlacesSelectedMainCategory: [1,0,0,0,0,0,0],
        discoverPlacesNameFilterTag: [],
        discoverPlacesCharacteristicsFilter: {},
        discoverPlacesDaysFilter: [0,0,0,0,0,0,0],
        countrySelected: false,
        filteredDiscoverPlaces:[],
        discoverPlacesNameSearchTags: [],
        discoverPlacesLocationsSearchTags:[],
        suggestedLocationsFilters: [],
        discoverPlacesAdditionalTagFilterTag :[],
        discoverPlacesAdditionalTagsSearchTags: [],
        discoverPlacesAvailableCategories: [],
        discoverPlacesAvailableMainCategories: [],

        filteredFavoritePlaces: [],
        filteredFavoritePlacesToShow:[],
        favoritePlacesFilterTags: [],
        favoritePlacesSearchTags: [],

        //overview section
        daysStructured: false,
        ongoingDay:-2,
        openModeTaskID: -1,
        mobileDaySelectedIndex: 1,
        openModeNoteId: -1,
        onlyMapAreaResults: false,
        updatePlanSearchTags:false
    }

    componentDidMount = () =>{
        this.checkIfOngoingTrip();
        if (this.props.showSuggestedTrips){
            this.handleSuggestedTripInitializeDiscoverFilter()
            
        }
        else{
          if (this.props.discoverPlacesLocationFilterTags.length>0){
            this.setState({countrySelected:true,selectedCountry:this.props.discoverPlacesLocationFilterTags[0].tag})
          }
          else{
              this.setState({countrySelected:false})
          }
          if (this.props.discoverPlacesLocationFilterTags.length>0){
            let tag = this.props.discoverPlacesLocationFilterTags[0].tag
              setTimeout(() => {
                this.props.loadCountryPlacesForPlanning(tag)
              }, 500);       
          }
        }
        
        if (this.props.planDays.length===1){
            this.setState({mobileDaySelectedIndex:0})
        }
        this.structurePlanDays();
        if (this.props.adminMode){
          this.checkIfPotentialPlacesExist()
        }
    }

    handleSuggestedTripInitializeDiscoverFilter = async () =>{
      if (this.props.selectedTripGeneratorParams!==undefined){
        if (this.props.selectedTripGeneratorParams.selectedDestinations.length>0){
          this.setState({countrySelected:true,selectedCountry:this.props.selectedTripGeneratorParams.selectedDestinations[0]})
          if (this.props.selectedTripGeneratorParams.destinationType!=="country"){
            let countryID = this.props.selectedTripGeneratorParams.selectedDestinations[0].treeID.split('_')[0]
            let locationSearchTags =[this.props.locationsTranslator[countryID]]
            let discoverCountryPlaces=this.props.slimPlacesByCountry[countryID]
            await this.setState({discoverCountryPlaces:discoverCountryPlaces,locationSearchTags:locationSearchTags})
          }
          
         
         this.discoverPlacesLocationSearchTagHandler(this.props.selectedTripGeneratorParams.selectedDestinations[0],true)
        }
      }
    }

    checkIfPotentialPlacesExist = () =>{
      let potentialPlacesIDs = [];
      for (let i =0 ; i<this.props.planPlacesList.length ; i++){
        if (this.props.planPlacesList[i].placeStatus==="potential"){
          potentialPlacesIDs.push(this.props.planPlacesList[i].placeID)
        }
      }
      this.setState({potentialPlacesIDs:potentialPlacesIDs})
    }

    componentDidUpdate = prevProps =>{
        if (JSON.stringify(prevProps.selectedAccommodation)!==JSON.stringify(this.props.selectedAccommodation)){
          if (this.state.addingAccommodation){
            this.addAccommodationFinalize(this.props.selectedAccommodation)
          }
        }
        if (prevProps.planLastModified!==this.props.planLastModified){
            if (this.state.planLastModified!==this.props.planLastModified){
              console.log(this.props.planExperiences)
              console.log(this.props.planDays)
                this.setState({
                    planLastModified: this.props.planLastModified,
                    isPublic:this.props.isPublic,
                    planName:this.props.planName,
                    planDates:this.props.planDates,
                    planDuration:this.props.planDuration,
                    noDatesSelected:this.props.noDatesSelected,
                    participants:this.props.participants,
                    planPlacesIDs:this.props.planPlacesIDs,
                    planEditors:this.props.planEditors,
                    imagePreview:this.props.imagePreview,
                    imageGallery:this.props.imageGallery,
                    planExperiences:this.props.planExperiences,
                    planTasks: this.props.planTasks,
                    publicPlanTags: this.props.publicPlanTags,
                    planDescription : this.props.planDescription
                })
                setTimeout(() => {
                  this.structurePlanDays(true)
                 }, 1000)
              
            }
            else if (JSON.stringify(this.props.imagePreview)!==JSON.stringify(prevProps.imagePreview)){
              this.setState({  imagePreview:this.props.imagePreview,
                imageGallery:this.props.imageGallery,})
            }
        }
        else if (JSON.stringify(this.props.planExperiences)!==JSON.stringify(prevProps.planExperiences)){
          console.log(this.props.planExperiences)
          this.structurePlanDays()
        }
       
    }

    discardTransparentBackdrop = () =>{
      this.setState({
        showTransBackdrop:false, showMoveCloneQuestion: false, showMobileTools:false
      })
    }

    discardExperienceCreation = () =>{
      if (this.refs.tripAlbum!==undefined){
        this.refs.tripAlbum.discardExperienceCreation()
      }
    }

    structurePlanDays = async (dontUpdate) =>{
          let oldPlanDays=this.props.planDays;
          let planMapPlaces=[];
          let hasExperiences = false;
          let tripHasPlacesOutsideInterestsList = false
           //remove places that don't exist anymore for some reason
          let planDays = []
          if (dontUpdate){
            planDays = [...this.props.planDays]
            let newPlace;
            for (let i=0; i<this.props.planDays.length ; i++){
              if (this.props.planDays[i].dayItems!==undefined){
                for(let j=0; j<this.props.planDays[i].dayItems.length ; j++){
                  if (this.props.planDays[i].dayItems[j].type==="place"){
                    const placeIndex = this.state.planPlacesList.findIndex(place => {
                      return place.placeID === this.props.planDays[i].dayItems[j].item.placeID;
                    });
                    if (placeIndex===-1){
                      newPlace = this.props.planDays[i].dayItems[j].item
                    }
                  }
                }
              }
            }
            if (newPlace!==undefined){
              //a new place was added by another user
              let planPlacesList = this.state.planPlacesList
              await this.props.getAddedPlaceToPlan(newPlace).then(result=>{
                planPlacesList.push(result)
                this.setState({planPlacesList:planPlacesList})
              })
            }
          }
          else{
            for (let i=0; i<oldPlanDays.length ; i++){
              let day={
                name:oldPlanDays[i].name,
                selected:oldPlanDays[i].selected,
                dayItems:[],
              }
              planDays.push(day)
              if (oldPlanDays[i].dayItems!==undefined){
                for(let j=0; j<oldPlanDays[i].dayItems.length ; j++){
                  if (oldPlanDays[i].dayItems[j].type==="place"){
                    const placeIndex = this.state.planPlacesList.findIndex(place => {
                      return place.placeID === oldPlanDays[i].dayItems[j].item.placeID;
                    });
                    if (placeIndex!==-1){
                      planDays[i].dayItems.push(oldPlanDays[i].dayItems[j])
                    }
                  }
                  else{
                    planDays[i].dayItems.push(oldPlanDays[i].dayItems[j])
                  }
                }
              }
            }
          }
          

          for (let i=0; i<planDays.length ; i++){
            if (isMobile){
              if (planDays.length>1){
                planDays[1].selected=true;
              }
              else{
                planDays[0].selected=true;
              }
              
            }
             //don't include in map view the interests list by default
            
              if (planDays[i].dayItems!==undefined){
                
                  for(let j=0; j<planDays[i].dayItems.length ; j++){
                  //now handling only places
                  if (planDays[i].dayItems[j].type==="place"){
                    if (i!==0){
                      tripHasPlacesOutsideInterestsList = true
                    }

                    if (tripHasPlacesOutsideInterestsList&&planDays.length>1){
                      planDays[0].selected=false
                    }
                    let placeColorNumber=planDays[i].dayItems[j].item.placeColorNumber;
                    let placeTime =planDays[i].dayItems[j].item.placeTime;
                    let placeVote = planDays[i].dayItems[j].item.placeVote;
                    let placeExperiences = planDays[i].dayItems[j].item.placeExperiences
                    let placeCountry = planDays[i].dayItems[j].item.placeCountry
                    
                      const placeIndex = this.state.planPlacesList.findIndex(place => {
                        return place.placeID === planDays[i].dayItems[j].item.placeID;
                      });
                      let place = JSON.parse(JSON.stringify(this.state.planPlacesList[placeIndex]));
                      if (placeCountry===undefined){
                        place.placeCountry=place.placeLocation.locationTree.locationTreeIDs[0].split('_')[0]
                      }
                      else{
                        place.placeCountry=placeCountry
                      }
                      if (placeColorNumber===undefined){
                        place.placeColorNumber=0;
                      }
                      else{
                        place.placeColorNumber=placeColorNumber;
                      }
                      if (placeTime!==undefined){
                        place.placeTime=new Date(placeTime);
                      }
                      else{
                        place.placeTime=undefined;
                      }
                      if (placeVote===undefined){
                        place.placeVote=[];
                      }
                      else{
                        place.placeVote=[...placeVote];
                      }
                      if (placeExperiences===undefined){
                        place.placeExperiences={};
                      }
                      else{
                        hasExperiences=true;
                        place.placeExperiences=placeExperiences;
                      }
                      planDays[i].dayItems[j].item = place

                    /*   if (planDays[i].selected){
                          planMapPlaces.push(place)
                      } */
                    }
                  }
              }
              else{
                  planDays[i].dayItems=[];
              }
          }
          if (this.props.planExperiences.length>0){
            hasExperiences = true;
          }
          let existingPlanMapPlacesID = planMapPlaces.map(place=>{return place.placeID})
          await this.setState({
            hasExperiences:hasExperiences,
            daysStructured: true,planDays:planDays,planMapPlaces:planMapPlaces,existingPlanMapPlacesID:existingPlanMapPlacesID})
           if (!dontUpdate){
            this.updatePlan() 
           }  
          this.updateMapPlaces()
      }

      updateMapPlaces = () =>{
        let planDays=this.state.planDays;
        let planMapPlaces=[];
        let planMapAccommodations = []
        let planMapIdeasCount = 0 ;
        for (let i=0; i<planDays.length ; i++){
          if (planDays[i].dayItems!==undefined){
              for(let j=0; j<planDays[i].dayItems.length ; j++){
              //now handling only places
              if (planDays[i].dayItems[j].type==="place"){
                let place=planDays[i].dayItems[j].item;
                if (i===0){
                  place.ideaMarker = true
                  if (planDays[i].selected){
                    planMapIdeasCount+=1;
                  }
                  
                }
                else{
                  place.ideaMarker = undefined
                }
                if (planDays[i].selected){
                  planMapPlaces.push(place)
                }
              }
              else if (planDays[i].dayItems[j].type==="accommodation"){
                let place=planDays[i].dayItems[j].item;
                if (planDays[i].selected){
                  planMapAccommodations.push(place)
                }
              }
            }
          }
        }
        let existingPlanMapPlacesID = planMapPlaces.map(place=>{return place.placeID})
        this.setState({planMapPlaces:planMapPlaces,
          planMapAccommodations:planMapAccommodations,
          existingPlanMapPlacesID:existingPlanMapPlacesID,
          planMapIdeasCount:planMapIdeasCount})
      }

      addAccommodationFinalize = async (selectedAccommodation) =>{
        this.setState({accommodationToAdd : selectedAccommodation,addingAccommodation:false,
          showDaysOptions:true, showBackdrop:true, addAccommodation:true,accommodationDaysOptions:true,singleDayMove:false,
        movingObjectType:"accommodation"})
      }

      selectedDaysHandler = (selectedDays) =>{
        this.setState({showDaysOptions:false, showBackdrop:false})
        let noNewDays = true
        for (let i=0 ; i<selectedDays.length ; i++){
          if (selectedDays[i]===true&&i!==this.state.dayIndex){
            noNewDays = false
          }
        }
        if (noNewDays&&this.state.movingObjectType!=="accommodation") return;
        if (this.state.newPlace){
          this.addPlaceToPlanFinalize(selectedDays)
        }
        else if (this.state.movingObjectType==="accommodation"){
          this.updateAccommodationDays(selectedDays)
        }
        else  if (this.state.movingObjectType==="note"||this.state.movingObjectType==="transport"){
          this.moveDayFinalizeHandler(selectedDays)
        }
        else{
          this.clonePlaceFinalizeHandler(selectedDays)
        }
      }

      updateAccommodationDays = async (selectedDays)=>{
        let planDays = this.state.planDays
        let accommodationToAdd= this.state.accommodationToAdd
        if (selectedDays[this.state.dayIndex]===false){
          //accommodation is moved from it's current place
          let dayIndex = this.state.moveDayIndices.dayIndex;
          let itemIndex = this.state.moveDayIndices.itemIndex;
          planDays[dayIndex].dayItems.splice(itemIndex,1);
        }
        if (this.state.addAccommodation){
          for (let i = 1 ; i<planDays.length ; i++){
            if (selectedDays[i]){
              let hadAccommodation = false
              //if already has accommodation replace otherwise add
              if (planDays[i].dayItems!==undefined){
                for (let j=0 ; j<planDays[i].dayItems.length ; j++){
                  if (planDays[i].dayItems[j].type==="accommodation"){
                    hadAccommodation = true
                    planDays[i].dayItems[j].item = accommodationToAdd
                  }
                }
                if (!hadAccommodation){
                  planDays[i].dayItems.push({type:"accommodation",item:accommodationToAdd})
                }
              }
              else{
                planDays[i].dayItems=[{type:"accommodation",item:accommodationToAdd}]
              }
              planDays[i].selected = true
            }
          }
        }
   
        
        await this.setState({planDays: planDays})
        this.updateMapPlaces()
        this.updatePlan()
      }

      hoverDiscoverHandler = async (index,place) =>{
        let planMapPlaces = this.state.planMapPlaces;
        let hoverIndex=-1;
        if (index===-1){
          if(!this.state.existingPlanMapPlacesID.includes(place.placeID)){
            const existingIndex = this.state.planMapPlaces.findIndex(mapPlace => {
              return mapPlace.placeID === place.placeID;
            });
            if (existingIndex!==-1){
              planMapPlaces.splice(existingIndex,1); 
            }
          }
           await this.setState({planMapPlaces:planMapPlaces}) 
           this.refs.viewPlanPlanning.updateHover(-1)
        }
        else{
          const existingIndex = this.state.existingPlanMapPlacesID.findIndex(planPlaceID => {
            return planPlaceID === place.placeID;
          });
          if (existingIndex===-1){
            planMapPlaces.push(place);
            hoverIndex = planMapPlaces.length-1
          }
          else{
            hoverIndex=existingIndex;
          }
          await this.setState({planMapPlaces:planMapPlaces,}) 
          this.refs.viewPlanPlanning.updateHover(place.placeID)
        }  
      }
      
    updateDiscoverSource = async value =>{
      await this.setState({selectedDiscoverSource:value})
      this.onlyMapResultsHandler(this.state.onlyMapAreaResults,this.state.mapBounds)
    }
     

    onlyMapResultsHandler = async (value,mapBounds) =>{
      if (value){
        await this.setState({onlyMapAreaResults:true});
        if (this.state.mapBounds===undefined){
          await this.setState({mapBounds:mapBounds});
        }
        await this.mapBoundariesPlacesHandler(this.state.mapBounds);
        await this.updateFavoritePlacesList()
      } 
      else if (this.state.locationTagsFilteredPlaces!==undefined){
        await this.setState({onlyMapAreaResults:false});
        this.setState({
          discoverPlacesAvailableCategories: this.state.locationTagsAvailableCategories,
          discoverPlacesAvailableMainCategories: this.state.locationTagsAvailableMainCategories,
          discoverPlacesNameSearchTags: this.state.locationTagsNamesSearchTags,
          suggestedLocationsFilters: this.state.locationTagSuggestedLocationsFilters,
          filteredDiscoverPlaces: this.state.locationTagsFilteredPlaces,
          discoverLocationFilteredPlaces: this.state.locationTagsFilteredPlaces,
        })
        //get back to location filters
        await this.updateFavoritePlacesList()
      }
    }

    mapBoundariesPlacesHandler = async (mapBounds) =>{
      if (mapBounds===undefined) return;
      //handle updating location filter tags based on results in bounds
      let args = {
          mapBounds: mapBounds,
          discoverCountryPlaces: this.state.discoverCountryPlaces,
          discoverPlacesSelectedMainCategory: this.state.discoverPlacesSelectedMainCategory,
          discoverPlacesLocationFilterTags: [...this.state.discoverPlacesLocationFilterTags]
      }
      await this.refs.searchFunctions.discoverPlacesMapBoundsFunction(args).then(result => {
          this.setState({
              filteredDiscoverPlaces: result.filteredPlaces,
              discoverLocationFilteredPlaces: result.filteredPlaces,
             /*  suggestedLocationsFilters: result.suggestedLocationsFilters, */
              discoverPlacesAvailableCategories: result.discoverPlacesAvailableCategories,
              discoverPlacesAvailableMainCategories: result.discoverPlacesAvailableMainCategories,
              discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags,
              /* discoverPlacesLocationFilterTags: result.discoverPlacesLocationFilterTags */
          })
      }); 
       //if category filters exist filter by them as well
       if (this.state.discoverPlacesCategoriesFilterTags.length > 0){
          this.discoverPlacesCategorySearchTagHandler()
      }
      //else if main category is filtered apply it
      else if (this.state.discoverPlacesSelectedMainCategory[0]===0){
          this.discoverPlacesMainCategoryHandler(-1)
      }
  }

    updatedPlacesInMapArea = async () =>{
      /* let mapFilteredFavoritePlaces=[];
      let mapFilteredDiscoverPlaces=[];
      for (let i=0 ; i<this.state.filteredFavoritePlaces.length ; i++){
        let place = this.state.filteredFavoritePlaces[i]
        if (place.placeLocation.coordinates.lat>this.state.mapBounds.south&&
          place.placeLocation.coordinates.lat<this.state.mapBounds.north&&
          place.placeLocation.coordinates.lng>this.state.mapBounds.west&&
          place.placeLocation.coordinates.lng<this.state.mapBounds.east
          ){
            mapFilteredFavoritePlaces.push(place)  
        }
      }
      for (let i=0 ; i<this.state.countryFilteredDiscoverPlacesForMap.length ; i++){
        let place = this.state.countryFilteredDiscoverPlacesForMap[i]
        if (this.state.countryFilteredDiscoverPlacesForMap[i].placeLocation.coordinates.lat>this.state.mapBounds.south&&
          this.state.countryFilteredDiscoverPlacesForMap[i].placeLocation.coordinates.lat<this.state.mapBounds.north&&
          this.state.countryFilteredDiscoverPlacesForMap[i].placeLocation.coordinates.lng>this.state.mapBounds.west&&
          this.state.countryFilteredDiscoverPlacesForMap[i].placeLocation.coordinates.lng<this.state.mapBounds.east
          ){
            mapFilteredDiscoverPlaces.push(place)  
        }
      }
      let mapPlacesAvailableMainCategories = [];
      let mapPlacesAvailableCategoriesTagsIDs = [];
      let mapPlacesAvailableCategoriesTags = [];
      for (let i=0 ; i<mapFilteredDiscoverPlaces.length ; i++){
        let place = mapFilteredDiscoverPlaces[i];
        mapPlacesAvailableMainCategories[place.placeCategorySection]=1;
        for (let j=0 ; j<place.placeCategory.length ; j++){
          if (!mapPlacesAvailableCategoriesTagsIDs.includes(place.placeCategory[j].id)){
            mapPlacesAvailableCategoriesTagsIDs.push(place.placeCategory[j].id)
          }
        }
    }
    for (let i=0 ; i<this.props.discoverPlacesCategoriesSearchTags.length ; i++){
        if (mapPlacesAvailableCategoriesTagsIDs.includes(this.props.discoverPlacesCategoriesSearchTags[i].id)){
          mapPlacesAvailableCategoriesTags.push(this.props.discoverPlacesCategoriesSearchTags[i])
        }
    }
      await this.setState({mapFilteredFavoritePlaces:mapFilteredFavoritePlaces,mapFilteredDiscoverPlaces:mapFilteredDiscoverPlaces,
        mapPlacesAvailableCategoriesTags:mapPlacesAvailableCategoriesTags,mapPlacesAvailableMainCategories:mapPlacesAvailableMainCategories
      })
      if (this.state.onlyMapAreaResults){
        await this.setState({filteredDiscoverPlacesCategoriesSearchTags:mapPlacesAvailableCategoriesTags,
          filteredDiscoverPlacesMainCategories:mapPlacesAvailableMainCategories})
      } */
    }

    mapBoundsHandler = async mapBounds =>{
      if (this.state.onlyMapAreaResults){
        this.mapBoundariesPlacesHandler(mapBounds)
        await this.setState({mapBounds:mapBounds})
        await this.updateFavoritePlacesList()
     
      }
    
    }

    checkIfOngoingTrip = () =>{
      if (this.props.showSuggestedTrips){
        this.setState({ongoingDay:0,mobileDaySelectedIndex:1})
        return;
      }
        if (this.props.planDays.length===1||!this.props.isEditor||this.state.noDatesSelected){
          return
        }
      
        else{
          let tripStarted = false;
          let today = new Date();
      
          var todayDate = moment(today).startOf('day');;
          var startDate = moment(this.state.planDates.startDate).startOf('day');
          var endDate = moment(this.state.planDates.endDate).startOf('day');;
    
          if (todayDate>=startDate){
            tripStarted=true;
          }
          let dayNumber = todayDate.diff(startDate, 'days')
          let stillOngoin = todayDate.diff(endDate, 'days')
          if (dayNumber>=0&&stillOngoin<=0){
            //this is an ongoing trip
            this.setState({ongoingDay:dayNumber,mobileDaySelectedIndex:dayNumber+1})
          }
          else{
            this.setState({ongoingDay:-2,mobileDaySelectedIndex:1})
          }
          if (dayNumber>=0){
            this.setState({showJourney:true})
          }
          this.setState({tripStarted:tripStarted})
             
          if (this.props.isEditor&&tripStarted){
            //if user is part of plan editors and trip just started then for the first time this happens show him a message
            let planEditors = this.state.planEditors
            const userIndex = planEditors.findIndex(user => {
              return user.userID === this.props.user.userID;
            });
            if (userIndex!==-1){
               if (planEditors[userIndex].tripStartedMessageClosed===undefined){
                planEditors[userIndex].tripStartedMessageClosed=true
                this.setState({
                  showTripStartedMessage:true,
                  showTransBackdrop:true,
                  planEditors:planEditors
                })
              } 
            }
          }
         
        }
    
      }

      updateSelectedCountryHandler = country =>{
        this.setState({countrySelected:true,selectedCountry:country})
        this.props.loadCountryPlacesForPlanning(country)

        this.props.writeAcitivityLog({
          activityKey:"TRIP_PLANNING_SELECT_COUNTRY",
          userScreen:"VIEW_TRIP"
      })  
      }

      daySelectHandler = async index =>{
        let allDaysSelected = true;
         let planDays=this.state.planDays;
         planDays[index].selected=!planDays[index].selected;
         for (let i=0; i<planDays.length ; i++){
           if(planDays[i].selected===false){
             allDaysSelected=false;
           }
         }
         await this.setState({planDays:planDays,allDaysSelected:allDaysSelected})
         await this.updateMapPlaces();
        // this.refs.viewPlanPlanning.shouldUpdateFilteredPlaces()
         //this.updatePlan();
     }

     mobileDaySelectHandler = async index =>{
      let mobileDaySelectedIndex = index;
      let planDays=this.state.planDays;
      for (let i=0; i<planDays.length ; i++){
        planDays[i].selected=false
      }
      planDays[mobileDaySelectedIndex].selected=true;
      await this.setState({planDays:planDays,mobileDaySelectedIndex:mobileDaySelectedIndex})
      await this.updateMapPlaces();
      this.refs.viewPlanPlanning.updateFilteredPlaces()
      //this.updatePlan();
    }


      //========SEARCH FUNCTIONS==========

      loadFavoritePlacesForPlanning = () =>{
        if (this.props.favoritePlacesLoaded){
          this.favoritePlacesLoaded()
        }
        else{
          this.props.loadFavoritePlacesForPlanning()
          
        }
      }

      favoritePlacesLoaded = async () =>{
          this.setState({filteredFavoritePlaces:this.props.favoritePlaces, favoritePlaces:this.props.favoritePlaces,filteredFavoritePlacesToShow:this.props.favoritePlaces})
            await this.refs.searchFunctions.updateFavoritePlacesSearchTags(this.props.favoritePlaces).then(result => {
            this.setState({
                favoritePlacesSearchTags:result, allFavoritePlacesSearchTags:result
            })
         }); 
      }

      noSearchResultsHandler = (searchValue) =>{
        let message=this.props.systemText.searchErrorMessages.welcomePlaces[this.props.userLanguage]
        this.setState({
          showErrorMessage:true,
          showTransBackdrop:true,
          errorHeader:this.props.systemText.searchErrorMessages.title[this.props.userLanguage],
          errorMessage:message,
          errorButton: this.props.systemText.extraModals.infoModal.closeButton[this.props.userLanguage], 
        })

        this.props.writeAcitivityLog({
          activityKey:"TRIP_PLANNING_DISCOVER_NO_SEARCH_RESULTS",
          userScreen:"VIEW_TRIP",
          searchValue:searchValue
        })
      }

      searchDiscoverLocationHandler = tag =>{
        if (tag.treeID!==undefined){
          //location tag
          this.discoverPlacesLocationSearchTagHandler(tag,true)
          this.props.writeAcitivityLog({
            activityKey:"TRIP_PLANNING_DISCOVER_SEARCH_LOCATION_TAG",
            userScreen:"VIEW_TRIP",
            searchValue:tag.treeID
          })
        }
        else if(tag.id!==undefined){
          this.addPlaceCategoryTagHandler(tag.id)
        }
        else if (tag.placeTag!==undefined){
          //place tag
          this.discoverPlacesAdditionalTagSearchTagHandler(tag)

          this.props.writeAcitivityLog({
            activityKey:"DISCOVER_PLACES_SEARCH_ADDITIONALTAG",
            userScreen:this.state.selectedContentWall.toUpperCase(),
            searchValue:tag[this.props.inputLanguage]
          })
        }
        else{
            //name tag
            this.discoverPlacesNameSearchTagHandler(tag)

            this.props.writeAcitivityLog({
              activityKey:"TRIP_PLANNING_DISCOVER_SEARCH_NAME_TAG",
              userScreen:"VIEW_TRIP",
              searchValue:tag[this.props.inputLanguage]
            })
        }
    }

    discoverPlacesNameSearchTagHandler = tag =>{
        let discoverPlacesNameFilterTag=this.state.discoverPlacesNameFilterTag;
        //don't add a tag that already is filtered
        for (let i = 0 ; i<discoverPlacesNameFilterTag.length ; i++){
          if (discoverPlacesNameFilterTag[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
            return
          }
        }
     
        discoverPlacesNameFilterTag.push({tag:tag,type:"NameFilter",language:this.props.inputLanguage});

        this.setState({
            discoverPlacesNameFilterTag: discoverPlacesNameFilterTag
        })
      }
  
      discoverPlaceNameSearchRemoveHandler = index =>{
          if (index===undefined){
            this.setState({discoverPlacesNameFilterTag:[]})
          }
          else{
            let discoverPlacesNameFilterTag = this.state.discoverPlacesNameFilterTag;
            discoverPlacesNameFilterTag.splice(index,1);
            this.setState({discoverPlacesNameFilterTag:discoverPlacesNameFilterTag})

            this.props.writeAcitivityLog({
              activityKey:"TRIP_PLANNING_DISCOVER_REMOVE_NAME_TAG",
              userScreen:"VIEW_TRIP"
            })
          }       
      }

      discoverPlacesAdditionalTagSearchTagHandler = tag =>{
        let discoverPlacesAdditionalTagFilterTag=this.state.discoverPlacesAdditionalTagFilterTag;
        //don't add a tag that already is filtered
        for (let i = 0 ; i<discoverPlacesAdditionalTagFilterTag.length ; i++){
          if (discoverPlacesAdditionalTagFilterTag[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
            return
          }
        }
     
        discoverPlacesAdditionalTagFilterTag.push({tag:tag,type:"AdditionalTagFilter",language:this.props.inputLanguage});

        this.setState({
          discoverPlacesAdditionalTagFilterTag: discoverPlacesAdditionalTagFilterTag
        })
      }

      discoverPlaceAddiotnalTagSearchRemoveHandler = index =>{
        if (index===undefined){
          this.setState({discoverPlacesAdditionalTagFilterTag:[]})
        }
        else{
          let discoverPlacesAdditionalTagFilterTag = this.state.discoverPlacesAdditionalTagFilterTag;
          discoverPlacesAdditionalTagFilterTag.splice(index,1);
          this.setState({discoverPlacesAdditionalTagFilterTag:discoverPlacesAdditionalTagFilterTag})

          this.props.writeAcitivityLog({
            activityKey:"TRIP_PLANNING_DISCOVER_REMOVE_ADDITIONALTAG_TAG",
            userScreen:"VIEW_TRIP"
          })
        }       
    }

      discoverPlacesLocationSearchTagHandler = async (tag,search,tagWasRemoved) =>{
        if (tag===undefined) return
        // if search tag already filtered - do nothing
        if (!tagWasRemoved&&this.state.discoverPlacesLocationFilterTags.map(tag=>{return tag.tag.treeID}).includes(tag.treeID)){
            return
        }
        this.props.locationSearchLogHandler(tag,tag.treeID.split('_')[0])
        let discoverCountryPlaces = this.state.discoverCountryPlaces
        if (tag.type==="country"){
            //get location search tags
            discoverCountryPlaces = this.props.slimPlacesByCountry[tag.treeID]
            let locationSearchTags = [];
            for (let i = 0 ; i<this.props.placesLocationSearchTags.length ; i++){
                if (this.props.placesLocationSearchTags[i].treeID!==undefined){
                    if (this.props.placesLocationSearchTags[i].treeID.split('_')[0]===tag.treeID.split('_')[0]){
                        locationSearchTags.push(this.props.placesLocationSearchTags[i])
                    }
                }
            }
          
            this.setState({discoverPlacesLocationsSearchTags:locationSearchTags,discoverCountryPlaces:discoverCountryPlaces})
        }
        let args = {
            searchTag: tag,
            discoverPlacesLocationFilterTags: this.state.discoverPlacesLocationFilterTags,
            discoverCountryPlaces: discoverCountryPlaces,
            locationsTree: this.props.locationsTree,
            search: search
        }
        await this.refs.searchFunctions.discoverPlacesLocationSearchFunction(args).then(result => {
            this.setState({
                discoverLocationFilteredPlaces: result.discoverLocationFilteredPlaces,
                filteredDiscoverPlaces: result.discoverLocationFilteredPlaces,
                
                discoverPlacesLocationFilterTags: result.discoverPlacesLocationFilterTags,
                suggestedLocationsFilters: result.suggestedLocationsFilters,
                locationTagSuggestedLocationsFilters: result.suggestedLocationsFilters,

                discoverPlacesAvailableCategories: result.discoverPlacesAvailableCategories,
                discoverPlacesAvailableMainCategories: result.discoverPlacesAvailableMainCategories,
                discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags,

                locationTagsFilteredPlaces: result.discoverLocationFilteredPlaces,
                locationTagsAvailableCategories: result.discoverPlacesAvailableCategories,
                locationTagsAvailableMainCategories: result.discoverPlacesAvailableMainCategories,
                locationTagsNamesSearchTags: result.discoverPlacesNameSearchTags,

                discoverPlacesAdditionalTagsSearchTags: result.discoverPlacesTagsSearchTags
            })
        });
        
        //if category filters exist filter by them as well
        if (this.state.discoverPlacesCategoriesFilterTags.length > 0){
            this.discoverPlacesCategorySearchTagHandler()
        }
        //else if main category is filtered apply it
        else if (this.state.discoverPlacesSelectedMainCategory[0]===0){
            this.discoverPlacesMainCategoryHandler(-1)
        }
        this.updatePlan();
    }

    removeDiscoverPlacesLocationSearchTagHandler = async (index)=>{
        if (index===0){
            //removing country
            this.setState({
                discoverCountryPlaces:[],
                discoverLocationFilteredPlaces:[],
                filteredDiscoverPlaces:[],
                discoverPlacesLocationFilterTags:[],
                discoverPlacesSelectedMainCategory:[1,0,0,0,0,0,0],
                discoverPlacesCategoriesFilterTags: [],

                discoverPlacesNameFilterTag:[],
                discoverPlacesCharacteristicsFilter: {},
                discoverPlacesDaysFilter: [0,0,0,0,0,0,0],
                discoverPlacesAdditionalTagFilterTag :[],

                countrySelected:false,
                selectedCountry:undefined
            })
            this.props.writeAcitivityLog({
              activityKey:"TRIP_PLANNING_DISCOVER_REMOVE_COUNTRY_TAG",
              userScreen:"VIEW_TRIP"
            })
        }
        else{
            let discoverPlacesLocationFilterTags = this.state.discoverPlacesLocationFilterTags;
            let searchTag = discoverPlacesLocationFilterTags[index-1].tag
            discoverPlacesLocationFilterTags.splice(index)
            await this.setState({discoverPlacesLocationFilterTags:discoverPlacesLocationFilterTags})
            this.discoverPlacesLocationSearchTagHandler(searchTag,true,true)

            this.props.writeAcitivityLog({
              activityKey:"TRIP_PLANNING_DISCOVER_REMOVE_LOCATION_TAG",
              userScreen:"VIEW_TRIP"
            })
        }
    }

    discoverPlacesMainCategoryHandler = async index =>{
        let discoverPlacesSelectedMainCategory = this.state.discoverPlacesSelectedMainCategory;
        if (index===-1){
            //in case map bounds changed or location filter applied and need to re-apply the category filter
            const mainIndex = discoverPlacesSelectedMainCategory.findIndex(cat => {
                return cat === 1;
              });
            await this.setState({discoverPlacesSelectedMainCategory:discoverPlacesSelectedMainCategory })
            let args = {
                selectedMainCategoryIndex: mainIndex-1,
                discoverLocationFilteredPlaces: this.state.discoverLocationFilteredPlaces,
            }
            await this.refs.searchFunctions.discoverPlacesMainCategoryFunction(args).then(result => {
                this.setState({
                    suggestedLocationsFilters: result.suggestedLocationsFilters,
                    filteredDiscoverPlaces: result.filteredPlaces,
                    discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags
                })
            });
               //handle the sub categories applied
               if (this.state.discoverPlacesCategoriesFilterTags.length > 0){
                this.discoverPlacesCategorySearchTagHandler()
              } 
        }
        else if (discoverPlacesSelectedMainCategory[index]===1){
            //unselect the main category
            //get back to previous location suggestions (based on location filter or map bounds)
            //all categories will be abailable, and places will be filtered by location
            //if category is filtered then run it ouside
            discoverPlacesSelectedMainCategory = [1,0,0,0,0,0,0];
            await this.setState({suggestedLocationsFilters:this.state.locationTagSuggestedLocationsFilters,
                discoverPlacesSelectedMainCategory:discoverPlacesSelectedMainCategory,
                filteredDiscoverPlaces:this.state.discoverLocationFilteredPlaces,
               
            })
            if (this.state.discoverPlacesCategoriesFilterTags.length > 0){
              this.discoverPlacesCategorySearchTagHandler()
            }

             //in this scenario all sub  and main category chars will be reseted
             let newChars = {}
             if (this.state.discoverPlacesCharacteristicsFilter.generalTags!==undefined){
               newChars.generalTags = this.state.discoverPlacesCharacteristicsFilter.generalTags
             }
             if (this.state.discoverPlacesCharacteristicsFilter.audienceTags!==undefined){
               newChars.audienceTags = this.state.discoverPlacesCharacteristicsFilter.audienceTags
             }
             this.setState({discoverPlacesCharacteristicsFilter: newChars})
        }
        else{
            //select main category
            //update main category selected - which will show only categories under that main category in filters
            //places will be filtered based on this category
            //if filtered categories are under this main cateogry they will be applied otherwise neglected
            discoverPlacesSelectedMainCategory = [0,0,0,0,0,0,0];
            discoverPlacesSelectedMainCategory[index] = 1;
            await this.setState({discoverPlacesSelectedMainCategory:discoverPlacesSelectedMainCategory })
            let args = {
                selectedMainCategoryIndex: index-1,
                discoverLocationFilteredPlaces: this.state.discoverLocationFilteredPlaces,
            }
            await this.refs.searchFunctions.discoverPlacesMainCategoryFunction(args).then(result => {
                this.setState({
                    suggestedLocationsFilters: result.suggestedLocationsFilters,
                    filteredDiscoverPlaces: result.filteredPlaces,
                    discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags,
                    discoverPlacesCategoriesFilterTags: [],
                    discoverPlacesCharacteristicsFilter: {},
                })
            }); 
              //handle the sub categories applied
            
        }
        if (this.state.onlyMapAreaResults){
          await this.updatedPlacesInMapArea();
          }

      
    }

    addPlaceCategoryTagHandler = category => {
        this.discoverPlacesCategorySearchTagHandler(category,"add");
        this.props.categorySearchLogHandler(category,this.state.discoverPlacesLocationFilterTags[0].tag.treeID.split('_')[0])

        this.props.writeAcitivityLog({
          activityKey:"TRIP_PLANNING_DISCOVER_ADD_CATEGORY_TAG",
          userScreen:"VIEW_TRIP"
        })
      }
  
      discoverPlacesCategorySearchTagHandler = async (tag,scenario) =>{
          if (this.props.categoriesTranslator[tag]===undefined&&scenario==="add") return
          if (scenario==="remove"){
              //in this case tag is index
              let discoverPlacesCategoriesFilterTags = this.state.discoverPlacesCategoriesFilterTags
              discoverPlacesCategoriesFilterTags.splice(tag,1)
              await this.setState({discoverPlacesCategoriesFilterTags:discoverPlacesCategoriesFilterTags})
  
              //in this scenario all sub category chars will be reseted
              let newChars = {}
              if (this.state.discoverPlacesCharacteristicsFilter.generalTags!==undefined){
                newChars.generalTags = this.state.discoverPlacesCharacteristicsFilter.generalTags
              }
              if (this.state.discoverPlacesCharacteristicsFilter.audienceTags!==undefined){
                newChars.audienceTags = this.state.discoverPlacesCharacteristicsFilter.audienceTags
              }
              if (this.state.discoverPlacesCharacteristicsFilter.mainTags!==undefined){
                newChars.mainTags = this.state.discoverPlacesCharacteristicsFilter.mainTags
              }
              this.setState({discoverPlacesCharacteristicsFilter: newChars})
              
          }
          else if(scenario==="add"){
            let searchTag = this.props.categoriesTranslator[tag]
              let discoverPlacesCategoriesFilterTags = this.state.discoverPlacesCategoriesFilterTags
              let tagLanguage="";
              if (searchTag[this.props.userLanguage]!==undefined){
                  tagLanguage=this.props.userLanguage;
              }
              else {
                  tagLanguage="English";
              }
              discoverPlacesCategoriesFilterTags.push({tag:searchTag,type:"CategoryFilter",language:tagLanguage})
              await this.setState({discoverPlacesCategoriesFilterTags:discoverPlacesCategoriesFilterTags})
          }
          let args = {
              discoverPlacesCategoriesFilterTags: this.state.discoverPlacesCategoriesFilterTags,
              discoverLocationFilteredPlaces: this.state.discoverLocationFilteredPlaces
          }
  
          if (this.state.discoverPlacesCategoriesFilterTags.length===0){
              await this.setState({suggestedLocationsFilters:this.state.locationTagSuggestedLocationsFilters,
                  filteredDiscoverPlaces:this.state.discoverLocationFilteredPlaces
              })
          }
          //categories search is an OR, meaning place need to have at least one from the least
          else{
              await this.refs.searchFunctions.discoverPlacesCategorySearchTagFunction(args).then(result => {
                this.setState({
                    filteredDiscoverPlaces: result.filteredPlaces,
                    suggestedLocationsFilters: result.suggestedLocationsFilters,
                    discoverPlacesNameSearchTags: result.discoverPlacesNameSearchTags,
                })
              }); 
          }
          if (this.state.onlyMapAreaResults){
            await this.updatedPlacesInMapArea();
          }
      }

      updateFavoritePlacesList = async () =>{
        if (this.state.selectedDiscoverSource==="favorites"&&this.state.favoritePlaces!==undefined){
          let favoritePlacesInBounds = [];
          let filteredFavoritePlacesInBounds = []
         
          if (this.state.onlyMapAreaResults){
            let potentialPlacesInBounds = []
            for (let i=0; i< this.state.favoritePlaces.length ; i++){
              let placeCoordinates =  this.state.favoritePlaces[i].placeLocation.coordinates
              if (placeCoordinates.lat<this.state.mapBounds.north&&
                placeCoordinates.lat>this.state.mapBounds.south&&
                placeCoordinates.lng>this.state.mapBounds.west&&
                placeCoordinates.lng<this.state.mapBounds.east){
                  potentialPlacesInBounds.push( this.state.favoritePlaces[i])                      
              }
            }
            favoritePlacesInBounds = potentialPlacesInBounds
  
            if (this.state.favoritePlacesFilterTags.length>0){
              let filteredPotentialPlacesInBounds = []
              for (let i=0; i< this.state.filteredFavoritePlaces.length ; i++){
                let placeCoordinates =  this.state.filteredFavoritePlaces[i].placeLocation.coordinates
                if (placeCoordinates.lat<this.state.mapBounds.north&&
                  placeCoordinates.lat>this.state.mapBounds.south&&
                  placeCoordinates.lng>this.state.mapBounds.west&&
                  placeCoordinates.lng<this.state.mapBounds.east){
                    filteredPotentialPlacesInBounds.push( this.state.filteredFavoritePlaces[i])                      
                }
              }
              filteredFavoritePlacesInBounds = filteredPotentialPlacesInBounds
            }
            else{
              filteredFavoritePlacesInBounds = [...favoritePlacesInBounds]
            }
            await this.setState({favoritePlacesInBounds:favoritePlacesInBounds, filteredFavoritePlacesInBounds:filteredFavoritePlacesInBounds,filteredFavoritePlacesToShow:filteredFavoritePlacesInBounds})
            await this.refs.searchFunctions.updateFavoritePlacesSearchTags(filteredFavoritePlacesInBounds,true).then(result => {
              this.setState({
                  favoritePlacesSearchTags:result, allFavoritePlacesSearchTags:result
              })
            });
          }
         else{
          await this.setState({filteredFavoritePlacesToShow:this.state.filteredFavoritePlaces})
          await this.refs.searchFunctions.updateFavoritePlacesSearchTags(this.state.filteredFavoritePlaces).then(result => {
            this.setState({
                favoritePlacesSearchTags:result, allFavoritePlacesSearchTags:result
            })
          });
         }
        }
        
      }
  
      favoritePlacesSearchHandler = async (tag,tagWasRemoved) =>{
        if (tag.noResults!==undefined){
          this.setState({noResultsFavoritePlaces:true})
        }
        let favoritePlacesFilterTags = this.state.favoritePlacesFilterTags
          if (!tagWasRemoved){
              
            if (favoritePlacesFilterTags.length>0){
                for (let i =0 ; i<favoritePlacesFilterTags.length ; i++){
                  if (favoritePlacesFilterTags[i].tag[this.props.inputLanguage]!==undefined){
                    if (favoritePlacesFilterTags[i].tag[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                      return;
                    }
                  }
                }
              } 
             
              //add options for places,plans etc...
              favoritePlacesFilterTags.push({tag:tag,language:this.props.inputLanguage});
          }
        let args = {
            searchTag: tag,
            favoritePlacesFilterTags: favoritePlacesFilterTags,
            favoritePlaces: this.state.favoritePlaces,
            filteredFavoritePlaces: this.state.filteredFavoritePlaces,
            onlyMapAreaResults:this.state.onlyMapAreaResults
        }
        if (this.state.onlyMapAreaResults){
          args.favoritePlaces = this.state.favoritePlacesInBounds
          args.filteredFavoritePlaces = this.state.filteredFavoritePlacesInBounds
        }
        await this.refs.searchFunctions.favoritePlacesSearchFunction(args).then(result => {
             this.setState({
              filteredFavoritePlacesToShow:result.filteredPlaces,
                favoritePlacesSearchTags: result.favoritePlacesSearchTags,
                favoritePlacesFilterTags: result.favoritePlacesFilterTags,
            })  
            if (this.state.onlyMapAreaResults){
              this.setState({
                filteredFavoritePlacesInBounds: result.filteredPlaces,
               
             })  
            }
            else{
              this.setState({
                filteredFavoritePlaces: result.filteredPlaces,
             })  
            }
        });  
      }



      favoriteRemovePlaceTagHandler = async (index)=>{
        let favoritePlacesFilterTags=this.state.favoritePlacesFilterTags;
        let oldTag = favoritePlacesFilterTags[index]
        if (oldTag.tag.noResults!==undefined){
          this.setState({noResultsFavoritePlaces:false})
        }
        favoritePlacesFilterTags.splice(index,1);
        this.setState({favoritePlacesFilterTags:favoritePlacesFilterTags})
        if (favoritePlacesFilterTags.length===0){
            this.setState({favoritePlacesSearchTags:this.state.allFavoritePlacesSearchTags});
            if (this.state.onlyMapAreaResults){
              this.setState({
                filteredFavoritePlacesInBounds: this.state.favoritePlacesInBounds,filteredFavoritePlacesToShow:this.state.favoritePlacesInBounds,
                filteredFavoritePlaces:this.state.favoritePlaces,filteredFavoritePlacesToShow:this.state.favoritePlaces})
            }
            else{
              this.setState({
                
                filteredFavoritePlaces:this.state.favoritePlaces,filteredFavoritePlacesToShow:this.state.favoritePlaces})
            }
        }
        else{
          for (let i=0; i<favoritePlacesFilterTags.length ; i++){
            await this.favoritePlacesSearchHandler(favoritePlacesFilterTags[i].tag,true)
          }
        }
      }

      //==========USER INTERACTION FUNCTIONS========

      shareExperience =  (dayIndex,itemIndex) =>{
       
        let place = this.state.planDays[dayIndex].dayItems[itemIndex].item;
        let date = new Date(this.state.planDates.startDate)
        let experienceDate = moment(date).add(dayIndex-1,'days'); 
        this.props.shareExperience("Experience",place,true,experienceDate)
      }

      updateSelectedImages  = async (dayIndex,itemIndex,userJourneyImages) =>{
        let planDays = this.state.planDays;
        let placeExperiences = planDays[dayIndex].dayItems[itemIndex].item.placeExperiences;

        if (placeExperiences[this.props.user.userID]!==undefined){
          placeExperiences[this.props.user.userID].userJourneyImages = userJourneyImages
        }
        else{
          let userContent = {userJourneyImages:userJourneyImages}
          placeExperiences[this.props.user.userID] = userContent
        }
        planDays[dayIndex].dayItems[itemIndex].item.placeExperiences=placeExperiences;

        await this.setState({planDays:planDays});
        this.updatePlan();
      }

      addJourneyImage = async (dayIndex,itemIndex,imgItem,userID) =>{
        let planDays = this.state.planDays;
        let placeExperiences = planDays[dayIndex].dayItems[itemIndex].item.placeExperiences;
        let userJourneyImages =[]
        if (placeExperiences[userID]!==undefined){
          if (placeExperiences[userID].userJourneyImages!==undefined){
            userJourneyImages = placeExperiences[userID].userJourneyImages
          }
          userJourneyImages.push(imgItem)
          placeExperiences[userID].userJourneyImages = userJourneyImages
        }
        else{
          userJourneyImages.push(imgItem)
          let userContent = {userJourneyImages:userJourneyImages}
          placeExperiences[userID] = userContent
        }
        planDays[dayIndex].dayItems[itemIndex].item.placeExperiences=placeExperiences;

        await this.setState({planDays:planDays});
        this.updatePlan();
      }

      removeJourneyImage = async (dayIndex,itemIndex,imgIndex,userID) =>{
        let planDays = this.state.planDays;
        let placeExperiences = planDays[dayIndex].dayItems[itemIndex].item.placeExperiences;
        let userJourneyImages = placeExperiences[userID].userJourneyImages
        userJourneyImages.splice(imgIndex,1);
        placeExperiences[userID].userJourneyImages = userJourneyImages
        planDays[dayIndex].dayItems[itemIndex].item.placeExperiences=placeExperiences;

        await this.setState({planDays:planDays});
        this.updatePlan();
      }

      updateJourneyImagesOrder = async (dayIndex,itemIndex,images) =>{
        let planDays = this.state.planDays;
        let placeExperiences = planDays[dayIndex].dayItems[itemIndex].item.placeExperiences;
        placeExperiences[this.props.user.userID].userJourneyImages = images
        planDays[dayIndex].dayItems[itemIndex].item.placeExperiences=placeExperiences;

        await this.setState({planDays:planDays});
        this.updatePlan();
      }

      updateExperienceText = async (dayIndex,itemIndex,text) =>{
        let saveText = JSON.stringify(convertToRaw(text.getCurrentContent()));
        let planDays = this.state.planDays;
        let placeExperiences = planDays[dayIndex].dayItems[itemIndex].item.placeExperiences;
        placeExperiences[this.props.user.userID].experienceText = saveText
        planDays[dayIndex].dayItems[itemIndex].item.placeExperiences=placeExperiences;

        await this.setState({planDays:planDays});
        this.updatePlan();
      }

      addAccommodationHandler = (index) =>{
        
        this.setState({addingAccommodation:true,daysIndices:[index],showBackdrop:true})
      }
  
      addPlaceToPlanHandler = (place) =>{
        this.setState({daysIndices:[], showDaysOptions:true, showBackdrop:true, newPlace:true,newPlaceItem:place,movingObjectType:"place"})
        }

      addPlaceToPlanFinalize = async (selectedDays) =>{
        let place = this.state.newPlaceItem
        let planDays = this.state.planDays;
        let addedPlace = place;
        addedPlace.placeColorNumber=0;
        addedPlace.placeVote=[];
        addedPlace.placeExperiences={};
        if (place.placeStatus==="approved") {
            addedPlace.placeCountry  = place.placeLocation.locationTree.placeCountry
        }
        else{
          addedPlace.placeCountry  = place.placeLocation.locationTree.placeCountry.treeID
        }
        let placeItem={item:addedPlace,type:"place"}
        for (let dayIndex=0 ; dayIndex<planDays.length ; dayIndex++){
          if (selectedDays[dayIndex]){
            if (planDays[dayIndex].dayItems!==undefined){
              //check if exist
              let itemExist=false;
              for (let i=0 ;i<planDays[dayIndex].dayItems.length ; i++ ){
                  if (planDays[dayIndex].dayItems[i].type==="place"){
                      if (planDays[dayIndex].dayItems[i].item.placeID===placeItem.item.placeID){
                        itemExist=true;
                      }
                  }
              }
              if (!itemExist){
                planDays[dayIndex].dayItems.push(placeItem)
              }
              
            }
            else{
              planDays[dayIndex].dayItems=[placeItem]
            }
            planDays[dayIndex].selected=true;
          }
        }
        
        let planPlacesIDs = this.state.planPlacesIDs;
        if (!planPlacesIDs.includes(place.placeID)){
          planPlacesIDs.push(place.placeID);
          let planPlacesList = this.state.planPlacesList
          planPlacesList.push(place)
          await this.setState({planPlacesList:planPlacesList})
        }
        await this.setState({planDays:planDays,planPlacesIDs:planPlacesIDs,updatePlanSearchTags:true, newPlace:false});
        await this.updateMapPlaces();
        this.setState({changesMade:true})
        this.updatePlan();

        this.props.writeAcitivityLog({
          activityKey:"TRIP_PLACE_ADD",
          userScreen:"VIEW_TRIP",
        })
      }

      updateDayOrder= async (dayIndex,dayItems)=>{
        let planDays = this.state.planDays;
        planDays[dayIndex].dayItems=[...dayItems]
        setTimeout(() => {
          this.updateMapPlaces();
          this.setState({changesMade:true})
          this.updatePlan();
        }, 1000);

        this.props.writeAcitivityLog({
          activityKey:"TRIP_DAY_REORDER",
          userScreen:"VIEW_TRIP",
          dayIndex:dayIndex
        })
       }
   
       moveDayHandler = async (dayIndex,itemIndex,newDayIndex,type)=>{
      /*    if (type==="place"){
           this.setState({moveDayIndices:{dayIndex:dayIndex,itemIndex:itemIndex,newDayIndex:newDayIndex,type:type},
             showMoveCloneQuestion:true,showTransBackdrop:true})
         }
         else if (type==="note"||type==="transport"){
           await this.setState({moveDayIndices:{dayIndex:dayIndex,itemIndex:itemIndex,newDayIndex:newDayIndex,type:type}})
           await this.moveDayFinalizeHandler()
         }
         else if (type==="accommodation"){ */
         
            /* await this.setState({moveDayIndices:{dayIndex:dayIndex,itemIndex:itemIndex,newDayIndex:newDayIndex,type:type}})
            await this.moveDayFinalizeHandler() */
          //get the list of already included day indices in case of place or accommodation
          let daysIndices = []
          if (type==="accommodation"){
            let itemID = this.state.planDays[dayIndex].dayItems[itemIndex].item.placeID
            for (let i=0 ; i<this.state.planDays.length ; i++){
              if (this.state.planDays[i].dayItems!==undefined){
                for (let j=0 ; j<this.state.planDays[i].dayItems.length; j++){
                  if (this.state.planDays[i].dayItems[j].type==="accommodation"){
                    if (this.state.planDays[i].dayItems[j].item.placeID == itemID){
                      daysIndices.push(i)
                    }
                  }
                }
              }
            }
          }
          else if (type==="place"){
            let itemID = this.state.planDays[dayIndex].dayItems[itemIndex].item.placeID
            for (let i=0 ; i<this.state.planDays.length ; i++){
              if (this.state.planDays[i].dayItems!==undefined){
                for (let j=0 ; j<this.state.planDays[i].dayItems.length; j++){
                  if (this.state.planDays[i].dayItems[j].type==="place"){
                    if (this.state.planDays[i].dayItems[j].item.placeID == itemID){
                      daysIndices.push(i)
                    }
                  }
                }
              }
            }
          }
          else{
            daysIndices=[dayIndex]
          }
          this.setState({moveDayIndices:{dayIndex:dayIndex,itemIndex:itemIndex,newDayIndex:newDayIndex,type:type},
            daysIndices:daysIndices, showDaysOptions:true, showBackdrop:true, addAccommodation:true,movingObjectType:type})

          if (type==="accommodation"){
            this.setState({accommodationDaysOptions:true,accommodationToAdd: this.state.planDays[dayIndex].dayItems[itemIndex].item})
          }
          else{
            this.setState({accommodationDaysOptions:false})
          }
          if (type==="note"||type==="transport"){
            this.setState({singleDayMove:true})
          }
          else{
            this.setState({singleDayMove:false})
          }
        
       /*   } */
         this.props.writeAcitivityLog({
          activityKey:"TRIP_ITEM_MOVEDAY",
          userScreen:"VIEW_TRIP"
        })
       }
   
   
       moveDayFinalizeHandler = async (selectedDays) =>{
         //this.setState({showMoveCloneQuestion:false,showTransBackdrop:false});
         let dayIndex = this.state.moveDayIndices.dayIndex;
         let itemIndex = this.state.moveDayIndices.itemIndex;
         let newDayIndex  = selectedDays.findIndex(day => {
          return day === true;
        });
         let type = this.state.moveDayIndices.type;
         let planDays = this.state.planDays;
         let planItem = planDays[dayIndex].dayItems[itemIndex];
         planDays[dayIndex].dayItems.splice(itemIndex,1);
         if (newDayIndex!==-1){
          if (planDays[newDayIndex].dayItems!==undefined){
            //check if exist
            let itemExist=false;
            for (let i=0 ;i<planDays[newDayIndex].dayItems.length ; i++ ){
              if (type==="place"){
                if (planDays[newDayIndex].dayItems[i].type==="place"){
                  if (planDays[newDayIndex].dayItems[i].item.placeID===planItem.item.placeID){
                    itemExist=true;
                  }
               }
              }
              else if (type==="note"){
                if (planDays[newDayIndex].dayItems[i].type==="note"){
                  if (planDays[newDayIndex].dayItems[i].item.noteID===planItem.item.noteID){
                    itemExist=true;
                  }
               }
              }
              else if (type==="transport"){
                if (planDays[newDayIndex].dayItems[i].type==="transport"){
                  if (planDays[newDayIndex].dayItems[i].item.transportID===planItem.item.transportID){
                    itemExist=true;
                  }
               }
              }
               
            }
            if (!itemExist){
              planDays[newDayIndex].dayItems.push(planItem)
            }
            
          }
          else{
            planDays[newDayIndex].dayItems=[planItem]
          }
          planDays[newDayIndex].selected=true;
         }
        
        
         await this.setState({planDays:planDays});
         this.updateMapPlaces();
        
         this.setState({changesMade:true})
         this.updatePlan();

         this.props.writeAcitivityLog({
          activityKey:"TRIP_ITEM_MOVE",
          userScreen:"VIEW_TRIP"
        })
       }

       clonePlaceFinalizeHandler = async (selectedDays) =>{
        this.setState({showMoveCloneQuestion:false,showTransBackdrop:false})
        let dayIndex = this.state.moveDayIndices.dayIndex;
        let itemIndex = this.state.moveDayIndices.itemIndex;
        let newDayIndex = this.state.moveDayIndices.newDayIndex;
        let planDays = this.state.planDays;
        let planItem = planDays[dayIndex].dayItems[itemIndex];
        if (selectedDays[dayIndex]===false){
          //accommodation is moved from it's current place
          let dayIndex = this.state.moveDayIndices.dayIndex;
          let itemIndex = this.state.moveDayIndices.itemIndex;
          planDays[dayIndex].dayItems.splice(itemIndex,1);
        }

        for (let newIndex = 0 ; newIndex<planDays.length ; newIndex++){
          if (selectedDays[newIndex]){
            let itemExist = false
            //if already has accommodation replace otherwise add
            if (planDays[newIndex].dayItems!==undefined){
              for (let j=0 ; j<planDays[newIndex].dayItems.length ; j++){
                if (planDays[newIndex].dayItems[j].type==="place"){
                  if (planDays[newIndex].dayItems[j].item.placeID===planItem.item.placeID){
                    itemExist=true;
                  }
              }
              }
              if (!itemExist){
                planDays[newIndex].dayItems.push(planItem)
              }
            }
            else{
              planDays[newIndex].dayItems=[planItem]
            }
            planDays[newIndex].selected = true
          }
        }

        await this.setState({planDays:planDays});
        this.updateMapPlaces();
        this.setState({changesMade:true})
        this.updatePlan();

        this.props.writeAcitivityLog({
          activityKey:"TRIP_ITEM_CLONE",
          userScreen:"VIEW_TRIP"
        })
      }

       updateColorHandler = async (colorNumber,dayIndex,itemIndex)=>{
        let planDays=this.state.planDays;
        planDays[dayIndex].dayItems[itemIndex].item.placeColorNumber=colorNumber
        await this.setState({planDays:planDays});
        this.updatePlan();

        this.props.writeAcitivityLog({
          activityKey:"TRIP_PLACE_UPDATE_COLOR",
          userScreen:"VIEW_TRIP"
        })
      }

      voteHandler = async (voteValue,dayIndex,itemIndex)=>{
        let planDays=this.state.planDays;
        let placeVote = planDays[dayIndex].dayItems[itemIndex].item.placeVote
        if (voteValue===0){
          const index = placeVote.findIndex(vote => {
            return vote.userID === this.props.user.userID;
          });
          if (index!==-1){
            placeVote.splice(index,1)
          }
        }
        else{
          let vote = {userID:this.props.user.userID, 
            displayName:this.props.user.displayName,
            photoUrl:this.props.user.photoUrl,
            vote:voteValue
          }
          placeVote.push(vote)
        }
        planDays[dayIndex].dayItems[itemIndex].item.placeVote = placeVote
        await this.setState({planDays:planDays});
        this.updatePlan();

        this.props.writeAcitivityLog({
          activityKey:"TRIP_PLACE_UPDATE_VOTE",
          userScreen:"VIEW_TRIP",
          voteValue:voteValue
        })
      }

      updatePlaceTimeHandler  = async (time,dayIndex,itemIndex)=>{
        let planDays=this.state.planDays;
        planDays[dayIndex].dayItems[itemIndex].item.placeTime=time
        await this.setState({planDays:planDays});
        this.updatePlan();

        this.props.writeAcitivityLog({
          activityKey:"TRIP_PLACE_UPDATE_TIME",
          userScreen:"VIEW_TRIP"
        })
      }


      viewHandler = (dayIndex,itemIndex)=>{
        let item = this.state.planDays[dayIndex].dayItems[itemIndex];
        if (item.type==="place"){
          this.props.viewPlaceHandler(item.item);
        }
        if (item.type==="note"){
          this.setState({selectedDayIndex:dayIndex ,selectedItemIndex:itemIndex ,selectedNote:item.item})
          this.props.writeAcitivityLog({
            activityKey:"TRIP_NOTE_VIEW",
            userScreen:"VIEW_TRIP"
            })
        }
        if (item.type==="transport"){
          this.setState({selectedDayIndex:dayIndex ,selectedItemIndex:itemIndex , selectedTransport:item.item})
          if (this.props.isEditor){
            this.setState({editTransport:true,showBackdrop:true})
            this.props.writeAcitivityLog({
              activityKey:"TRIP_TRANSPORT_EDIT",
              userScreen:"VIEW_TRIP"
              })
          }
          else{
            this.setState({viewTransport:true,showBackdrop:true})
            this.props.writeAcitivityLog({
              activityKey:"TRIP_TRANSPORT_VIEW",
              userScreen:"VIEW_TRIP"
              })
          }
        }
      }
  

      newTaskHandler = async () =>{
        let planTasks = this.state.planTasks;
        let date = new Date();
        let timestamp = date.getTime();
        let owner = {
          ownerID: this.props.user.userID,
          ownerName: this.props.user.displayName,
          ownerPhoto: this.props.user.photoUrl,
          ownerEmail: this.props.user.userEmail
        };
        let taskID = this.props.user.userID+"_"+timestamp
        let taskInfo = EditorState.createEmpty()
        taskInfo=JSON.stringify(convertToRaw(taskInfo.getCurrentContent()));
        planTasks.push({
          taskTitle:"",
          taskID:taskID,
          taskCreationDate:timestamp,
          taskLastModified:timestamp,
          taskInfo:taskInfo,
          taskStatus:"open",
          creationDate: timestamp,
          taskLinks:[],
          tasksList:[],
          owner:owner
        })
        await this.setState({planTasks:planTasks,openModeTaskID:taskID,newTaskID:taskID})

        this.props.writeAcitivityLog({
          activityKey:"TRIP_TASK_NEW_BUTTON",
          userScreen:"VIEW_TRIP",
          isEditor:this.props.isEditor
          })
      }
      
      taskOpenHandler = async index =>{
        let oldTaskID;
        if (index===-1){
          oldTaskID = this.state.openModeTaskID
          this.setState({openModeTaskID:index,newTaskID:-1})
        }
        else{
          this.setState({openModeTaskID:this.state.planTasks[index].taskID})
        }
        if (oldTaskID!==undefined){
          const oldIndex = this.state.planTasks.findIndex(task=> {
            return task.taskID === oldTaskID;
          });
          if (oldIndex!==-1){
            let closedTask = this.state.planTasks[oldIndex]
            if (closedTask.taskTitle===""){
              await this.setState({deleteTaskIndex : oldIndex,emptyTitleTask:true})
              this.deleteTask(oldIndex)
            }
          }
        
        }
      }

      updateTasksOrder = async tasks =>{
        await this.setState({planTasks:tasks})
        setTimeout(() => {
          this.updatePlan();  
         }, 200);
         this.props.writeAcitivityLog({
          activityKey:"TRIP_TASK_REORDER",
          userScreen:"VIEW_TRIP",
          isEditor:this.props.isEditor
          })
      }

      deleteTask = async index =>{
        this.setState({
          confirmMessage: this.props.systemText.extraModals.confirmationModal.removeTaskFromPlan[this.props.userLanguage],
          primaryBtn: this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage],
          secondaryBtn: this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage],
          confirmDeleteTask: true,
          showBackdrop: true,
          optionsTools: false,
          deleteTaskIndex : index,
        });

        this.props.writeAcitivityLog({
          activityKey:"TRIP_TASK_DELETE",
          userScreen:"VIEW_TRIP",
          isEditor:this.props.isEditor
          })
      }
  
  
      confirmDeleteTask = async () =>{
        this.setState({
          confirmDeleteTask: false,
          showBackdrop: false
        });
        let planTasks = this.state.planTasks;
        planTasks.splice(this.state.deleteTaskIndex,1);
        await this.setState({planTasks:planTasks})
         this.updatePlan();
      }
  
      discardDeleteTask = () =>{
        this.setState({
          confirmDeleteTask: false,
          showBackdrop: false
        });
        if (this.state.emptyTitleTask){
          this.setState({
            openModeTaskID:this.state.planTasks[this.state.deleteTaskIndex].taskID,
            emptyTitleTask:false
          })
        }
      }
  
  
      updateTask = async (task,index)=>{
        let planTasks = this.state.planTasks;
        planTasks[index]=task;
        await this.setState({planTasks:planTasks})
        this.updatePlan();
      }

      newNoteHandler = async dayIndex =>{
        let date = new Date();
        let timestamp = date.getTime();
        let noteText = EditorState.createEmpty()
        noteText=JSON.stringify(convertToRaw(noteText.getCurrentContent()));
        let owner = {
          ownerID: this.props.user.userID,
          ownerName: this.props.user.displayName,
          ownerPhoto: this.props.user.photoUrl,
          ownerEmail: this.props.user.userEmail
        };
        let note = {
          noteTopic:"",
          noteID:timestamp,
          noteText:noteText,
          noteLinks:[],
          noteLastModified: timestamp,
          noteCreationDate: timestamp,
          imageGallery:[],
          imagePreview:[],
          attachedFiles: [],
          owner:owner
        }
   
         let planDays = this.state.planDays;
         let noteItem = {item:note, type:"note"}
         if (planDays[dayIndex].dayItems!==undefined){
           planDays[dayIndex].dayItems.push(noteItem)
        
         }
         else{
           planDays[dayIndex].dayItems=[noteItem]
         }
         await this.setState({planDays:planDays, openModeNoteId:note.noteID,newNoteID:note.noteID,editNoteID:note.noteID})
         this.setState({changesMade:true}) 
         this.updatePlan()
       }

       noteOpenHandler = async (dayIndex,itemIndex,value) =>{
        if (value===-1){
          /* if (this.state.planDays[dayIndex].dayItems[itemIndex].item.noteTopic===""){
            await this.setState({removeDayIndex: dayIndex,removeItemIndex:itemIndex})
            this.removeFromPlanHandler()
          }
          else{ */
            this.setState({openModeNoteId:itemIndex,newNoteID:-1})
          /* } */
         
        }
        else{
          this.setState({openModeNoteId:this.state.planDays[dayIndex].dayItems[value].item.noteID})
        }
       
      }

      updateNote = async (note,dayIndex,itemIndex) =>{
        if (note.noteTopic===""){
          await this.setState({removeDayIndex: dayIndex,removeItemIndex:itemIndex})
          //this.removeFromPlanHandler()
        }
        else{
          let planDays = this.state.planDays;
          planDays[dayIndex].dayItems[itemIndex].item=note;
          await this.setState({planDays:planDays})
          this.props.updatePlanNote(note,dayIndex,itemIndex,this.props.planID,this.props.owner.ownerID)
        }
        
      }

      createTransportHandler = async transport =>{
        let planDays = this.state.planDays;
        let transportItem = {item:transport, type:"transport"}
        if (planDays[this.state.selectedDayIndex].dayItems!==undefined){
          
          planDays[this.state.selectedDayIndex].dayItems.push(transportItem)
        }
        else{
          planDays[this.state.selectedDayIndex].dayItems=[transportItem]
        }
        let itemIndex = planDays[this.state.selectedDayIndex].dayItems.length-1
        await this.setState({planDays:planDays})
        this.setState({changesMade:true})
        this.props.updatePlanTransport(transport,this.state.selectedDayIndex,itemIndex,this.props.planID,this.props.owner.ownerID,true)
      }

      updateTransportHandler = (transport) =>{
        let planDays = this.state.planDays;
        planDays[this.state.selectedDayIndex].dayItems[this.state.selectedItemIndex].item=transport;
        this.setState({planDays:planDays})
        this.props.updatePlanTransport(transport,this.state.selectedDayIndex,this.state.selectedItemIndex,this.props.planID,this.props.owner.ownerID)
      }
      
  
      newTransportHandler = dayIndex =>{
        this.setState({selectedDayIndex:dayIndex,createTransport:true,showBackdrop:true})
      }
  
      discardCreateTransport = () =>{
        this.setState({createTransport: false,showBackdrop:false})
      }

      discardViewTransportHandler = () =>{
        this.setState({viewTransport:false,editTransport:false,showBackdrop:false})
      }

       removeFromPlan = (dayIndex,itemIndex) =>{
        this.setState({
          confirmMessage: this.props.systemText.extraModals.confirmationModal.removeItemFromPlan[this.props.userLanguage],
          primaryBtn: this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage],
          secondaryBtn: this.props.systemText.extraModals.confirmationModal.remove[this.props.userLanguage],
          confirmMode: true,
          showBackdrop: true,
          optionsTools: false,
          removeDayIndex : dayIndex,
          removeItemIndex: itemIndex
        });
      }
  
  
      confirmDelete = () => {
        this.removeFromPlanHandler()
        this.setState({
          confirmMode: false,
          showBackdrop: false
        });
      };
      
      discardDelete = () => {
        this.setState({
          confirmMode: false,
          showBackdrop: false
        });
      };

    removeFromPlanHandler = async () =>{
      let dayIndex = this.state.removeDayIndex;
      let itemIndex = this.state.removeItemIndex
      let planDays = this.state.planDays;
      let item = planDays[dayIndex].dayItems[itemIndex];
      planDays[dayIndex].dayItems.splice(itemIndex,1);
      await this.setState({planDays:planDays});
      if (item.type==="place"){
        let placeID = item.item.placeID;
        let exist = false;
        for (let i=0 ; i<planDays.length ; i++){
          if (planDays[i].dayItems!==undefined){
            for (let j=0 ; j<planDays[i].dayItems.length ; j++){
              if (planDays[i].dayItems[j].type==="place"){
                if (planDays[i].dayItems[j].item.placeID===placeID){
                  exist = true;
                }
              }
            }
          }
          
        }
        if (!exist){
          let planPlacesIDs = this.state.planPlacesIDs;
          const index = planPlacesIDs.findIndex(planPlaceID => {
            return planPlaceID === placeID;
          });
          planPlacesIDs.splice(index,1);
          await this.setState({planPlacesIDs:planPlacesIDs,updatePlanSearchTags:true})
          let planPlacesList = this.state.planPlacesList
          const listIndex = planPlacesList.findIndex(place => {
            return place.placeID === placeID;
          });
          if (listIndex!==-1){
            planPlacesList.splice(listIndex,1)
          }
          await this.setState({planPlacesList:planPlacesList})
        }
        await this.updateMapPlaces();  
        
        this.updatePlan();
        this.props.writeAcitivityLog({
          activityKey:"TRIP_PLACE_REMOVE",
          userScreen:"VIEW_TRIP"
        })
      }
      else if (item.type==="note"){
        this.props.removeNoteFromPlan(item,dayIndex,itemIndex,this.props.planID,this.props.owner.ownerID)
        this.props.writeAcitivityLog({
          activityKey:"TRIP_NOTE_REMOVE",
          userScreen:"VIEW_TRIP"
        })
      }
      else if (item.type==="transport"){
        this.props.removeTransportFromPlan(item,dayIndex,itemIndex,this.props.planID,this.props.owner.ownerID)
        this.props.writeAcitivityLog({
          activityKey:"TRIP_TRANSPORT_REMOVE",
          userScreen:"VIEW_TRIP"
        })
      }
      this.setState({changesMade:true}) 
      }


    //============ UPDATE FUNCTIONS===============

    
    updatePlanEditors = async (planEditors,added,user) =>{
      await this.setState({planEditors:planEditors});
      let sharedPlan ={
        planID:this.props.planID,
        planOwnerID:this.props.owner.ownerID,
      }
     this.props.updatePlanEditors(sharedPlan,user,added,planEditors)
    //this.updatePlan();
    }

    updatePlanPrivacyHandler = async (data) =>{
      let planSecurity = data.planSecurity
      let albumSecurity = data.albumSecurity
      let planDescription = data.planDescription
      let publicPlanTags = data.publicPlanTags
      let planSecurityChanged = this.state.planSecurity!==planSecurity
      let oldAlbumSecurity = this.state.albumSecurity[this.props.user.userID]
      let newAlbumSecurity = albumSecurity[this.props.user.userID]
      let albumSecurityChanged = oldAlbumSecurity!==newAlbumSecurity
      if (planSecurityChanged){
        //this is done so it will be seen that an experience is part of a trip
        let experiencesIDs = this.props.planExperiences.map(exp=>{return {experienceID:exp.experienceID,experiencePlaceID:exp.experiencePlaceID}})
        this.props.updateExperiencesPlanSecurity(experiencesIDs,this.props.planID,planSecurity)
      }
      if (albumSecurityChanged){
        let experiencesIDs = this.props.planExperiences.filter(exp=>exp.owner.ownerID===this.props.user.userID).map(exp=>{return {experienceID:exp.experienceID,experiencePlaceID:exp.experiencePlaceID}})
        this.props.updateExperiencesAlbumSecurity(experiencesIDs,this.props.planID,newAlbumSecurity)
      }

      let isPublic = false
      if (planSecurity==="public"){
        isPublic = true
      }
      await this.setState({planSecurity: planSecurity, albumSecurity: albumSecurity,isPublic: isPublic,publicPlanTags:publicPlanTags,planDescription:planDescription})
      
      this.updatePlan()
    }

    updatePlanOverview = async (plan) =>{
        await this.setState({
            planName:plan.planName,
            planOverview:plan.planOverview,
            imageGallery:plan.imageGallery,
            deletedImages: plan.deletedImages,
            imagePreview:plan.imagePreview,
            planDates:plan.planDates,
            participants:plan.participants,
            travelWithKids: plan.travelWithKids,
            datesUpdated:true,
            planDuration:plan.planDuration,
            noDatesSelected:plan.noDatesSelected
        });
        //handle plan days
        let newPlanDays=[{name:"Interesting",dayItems:[], selected:true}];
        if (plan.noDatesSelected && plan.planDuration===0){
          let dayItems = [];
          for (let i =0 ;i<this.state.planDays.length ; i++){
              if (this.state.planDays[i].dayItems!==undefined){
                dayItems=dayItems.concat([...this.state.planDays[i].dayItems])
              }
           
          }

          newPlanDays[0].dayItems=[...dayItems]
        }
        else{
          let planDuration
          let planStartDate
          if (plan.noDatesSelected){
            planDuration=plan.planDuration
          }
          else{
            var startDate = moment(plan.planDates.startDate).startOf('day');
            var endDate = moment(plan.planDates.endDate).startOf('day');;
      
            planDuration = endDate.diff(startDate, 'days')+1
            planStartDate = new Date(plan.planDates.startDate.getTime()); 
          }
        for (let i = 0; i < planDuration; i++) {
          if (plan.noDatesSelected){
            let dayName="Day " +(i+1)
            newPlanDays.push({name:dayName,dayItems:[], selected:false}); 
          }
          else{
            var localeData = moment.localeData();
            var format = localeData.longDateFormat('L');
            const startDate = moment(planStartDate);
            let date = startDate.format(format);
            let dayName="Day " +(i+1)+" - "+date;
            planStartDate.setDate(planStartDate.getDate() + 1);
            newPlanDays.push({name:dayName,dayItems:[], selected:false}); 
          }
        } 
        let oldPlanDays = this.state.planDays;
        if (newPlanDays.length>=oldPlanDays.length){
          for (let i=0; i<oldPlanDays.length ; i++){
            newPlanDays[i].selected=oldPlanDays[i].selected;
            if (oldPlanDays[i].dayItems!==undefined){
                for (let j=0 ; j<oldPlanDays[i].dayItems.length ; j++){
                    newPlanDays[i].dayItems.push(oldPlanDays[i].dayItems[j]);
                }
            }
         }
        }
        else{
          for (let i=0; i<newPlanDays.length ; i++){
            newPlanDays[i].selected=oldPlanDays[i].selected;
            if (oldPlanDays[i].dayItems!==undefined){
                for (let j=0 ; j<oldPlanDays[i].dayItems.length ; j++){
                    newPlanDays[i].dayItems.push(oldPlanDays[i].dayItems[j]);
                  }
            }
           
          }
          //moving places from days that were cut by shorteninig the trip to the ideas list
          let ideasPlacesIDs = []
          let ideasAccommodationsIDs = []
          if (newPlanDays[0].dayItems!==undefined){
            for (let i =0 ; i<newPlanDays[0].dayItems.length ; i++){
              if (newPlanDays[0].dayItems[i].type==="place"){
                ideasPlacesIDs.push(newPlanDays[0].dayItems[i].item)
              }
              if (newPlanDays[0].dayItems[i].type==="accommodation"){
                ideasAccommodationsIDs.push(newPlanDays[0].dayItems[i].item)
              }
            }
          }
          for(let i=newPlanDays.length ;i<oldPlanDays.length; i++ ){
            if (oldPlanDays[i].dayItems!==undefined){
                for (let j=0 ; j<oldPlanDays[i].dayItems.length ; j++){
                  if (oldPlanDays[i].dayItems[j].type==="place"){
                    if (!ideasPlacesIDs.includes(oldPlanDays[i].dayItems[j].item.placeID)){
                      newPlanDays[0].dayItems.push(oldPlanDays[i].dayItems[j]);
                      newPlanDays[0].selected=true;
                      ideasPlacesIDs.push(oldPlanDays[i].dayItems[j].item.placeID)
                    }
                  }
                  if (oldPlanDays[i].dayItems[j].type==="accommodation"){
                    if (!ideasAccommodationsIDs.includes(oldPlanDays[i].dayItems[j].item.placeID)){
                      newPlanDays[0].dayItems.push(oldPlanDays[i].dayItems[j]);
                      newPlanDays[0].selected=true;
                      ideasAccommodationsIDs.push(oldPlanDays[i].dayItems[j].item.placeID)
                    }
                  }
                  else{
                    newPlanDays[0].dayItems.push(oldPlanDays[i].dayItems[j]);
                    newPlanDays[0].selected=true;
                  }
               
                }
            }
          }
        }
        }
        await this.setState({planDays:newPlanDays})
        this.checkIfOngoingTrip()
        this.updatePlan();
    }

    updatePlan = () =>{
        if ((!this.props.isEditor||this.props.showSuggestedTrips)&&!this.state.clonningTrip){
          return
        }
        let date = new Date();
        let timestamp = date.getTime();
        let isPublic = this.state.isPublic;
        let planID = this.props.planID
        let planName = this.state.planName;
        let planDates = this.state.planDates;
        if (planDates.startDate instanceof Date){
            planDates.startDate=new Date(planDates.startDate.getTime()).getTime();
        }
        if (planDates.endDate instanceof Date){
            planDates.endDate=new Date(planDates.endDate.getTime()).getTime();
        }     
        this.setState({datesUpdated:false})  
        let planPlacesIDs = [...this.state.planPlacesIDs]
        let imagePreview = [...this.state.imagePreview]
        let imageGallery = [...this.state.imageGallery]
       

       /*  let planExperiences = []
        for (let i=0 ; i<this.state.planExperiences.length ; i++){
            planExperiences.push({experienceID:this.state.planExperiences[i].experienceID,
            ownerID:this.state.planExperiences[i].owner.ownerID})
        }*/
        let participants=this.state.participants; 
        let planDays =[];
        for (let i=0;i<this.state.planDays.length ; i++){
            let planDay = {name:this.state.planDays[i].name,selected:this.state.planDays[i].selected,dayItems:[]}
            if (this.state.planDays[i].dayItems!==undefined){
                for (let j=0 ; j<this.state.planDays[i].dayItems.length ; j++){
                    let dayItem={};
                    if (this.state.planDays[i].dayItems[j].type==="place"){
                        dayItem.type="place";
                        dayItem.item={
                            placeID:this.state.planDays[i].dayItems[j].item.placeID,
                            placeCountry:this.state.planDays[i].dayItems[j].item.placeCountry,
                            placeColorNumber:this.state.planDays[i].dayItems[j].item.placeColorNumber,
                            placeVote:this.state.planDays[i].dayItems[j].item.placeVote,
                            placeExperiences:this.state.planDays[i].dayItems[j].item.placeExperiences
                        }
                        if (this.state.planDays[i].dayItems[j].item.placeTime!==undefined){
                          if (this.state.planDays[i].dayItems[j].item.placeTime instanceof Date){
                            dayItem.item.placeTime = new Date(this.state.planDays[i].dayItems[j].item.placeTime.getTime()).getTime();
                          }
                        }
                        planDay.dayItems.push(dayItem )
                    }
                    else{
                        dayItem=this.state.planDays[i].dayItems[j]
                        planDay.dayItems.push(dayItem)
                    }
                }
            }
            planDays.push(planDay)
        }
        let planTasks = [...this.state.planTasks]
        let planEditors =[...this.state.planEditors];
        const index = planEditors.findIndex(user => {
            return user.userID === this.props.user.userID;
        });
        planEditors[index].planLastModified=timestamp;
        planEditors[index].discoverPlacesLocationFilterTags = [...this.state.discoverPlacesLocationFilterTags];
        let plan={
            planID: planID,
            isPublic:isPublic,
            planLastModified: timestamp,
            planCreationDate: this.props.planCreationDate,
            planName:planName,
            planDates:planDates,
            participants:participants,
            travelWithKids:this.state.travelWithKids,
            planDays:planDays,
            planPlacesIDs:planPlacesIDs,
            imagePreview:imagePreview,
            imageGallery:imageGallery,
            planTasks:planTasks,
            owner:this.props.owner,
            planDuration:parseInt(this.state.planDuration, 10),
            noDatesSelected:this.state.noDatesSelected,
            planEditors:planEditors,
            planSecurity: this.state.planSecurity,
            albumSecurity: this.state.albumSecurity,
            publicPlanTags: this.state.publicPlanTags
        }
        if (this.state.planDescription!==undefined){
          plan.planDescription = this.state.planDescription
        }
        if (this.state.deletedImages!==undefined){
          plan.deletedImages = [...this.state.deletedImages]
          this.setState({deletedImages:undefined})
        }
        this.setState({planLastModified: timestamp})
        if (this.state.updatePlanSearchTags){
          this.setState({updatePlanSearchTags:false})
          plan.updatePlanSearchTags=true
         
        }
       
        if (this.state.clonningTrip){
          this.props.cloneTrip(plan,true,true)
          this.props.discardView()
        }
        else{
        //console.log(plan)
          this.props.updatePlan(plan,this.state.planPlacesList)
        }
        
    }

    discard = () =>{
      if (this.state.changesMade&&this.props.showSuggestedTrips){
        this.setState({
          showSuggesteTripEditMessage:true,
          showBackdrop:true
        })
      }
      else this.props.discardView()
    }

    cloneHandler = async () =>{
      await this.setState({clonningTrip:true})
      this.updatePlan()
    }

    renderSuggestedTripMessage = () =>{
      if (this.state.showSuggesteTripEditMessage){
        let closeIconClass = "CloseIcon"
        if (this.props.RTL){
            closeIconClass = "CloseIconRTL"
        }
        return(
          <div className={classes.CloneMessageModal}>
            <div className={classes.ModalTopSection} >
           
           <img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCloseIcon.png?alt=media&token=b491351b-543c-41aa-9f49-0e7d44b6ac01"} 
              alt="" className={classes[closeIconClass]} 
              onClick={()=>{this.setState({showSuggesteTripEditMessage:false,showBackdrop:false})}}/>
             </div>
               <div className={classes.CloneMessage}>
                    {this.props.systemText.tripGenerator.generatedContent.cloneMessage[this.props.userLanguage]}
                </div>
              <div className={classes.ButtonSection}>
                  <TextButton
                   clicked={this.cloneHandler}
                    text={this.props.systemText.tripGenerator.generatedContent.cloneButton[this.props.userLanguage]}
                    style = {"OrangeButton"}
                  />  
                   <TextButton
                   clicked={this.props.discardView}
                    text={this.props.systemText.tripGenerator.generatedContent.discard[this.props.userLanguage]}
                    style = {"DiscardButton"}
                  />                        
              </div>
          </div>
        )
      }
    }

    renderTripStartedMessage = () =>{
      if (this.state.showTripStartedMessage){
        return (
          <InformationModal 
            infoType={""}
            header={this.props.systemText.plan.viewPlan.tripStartedMessage.messageTitle[this.props.userLanguage]}
            discard={()=>{this.setState({showTransBackdrop:false,showTripStartedMessage:false})}}
            message={this.props.systemText.plan.viewPlan.tripStartedMessage.messageContent[this.props.userLanguage]}
            btnText = {this.props.systemText.plan.viewPlan.tripStartedMessage.closeButton[this.props.userLanguage]}
            RTL = {this.props.RTL}
            />)
      }
    }

    renderTopSection = () =>{
      if (/* !this.state.hideMobileTopbar&& */!this.state.showTripAlbum){
        return (
          <div className={classes.TopSection} >
             <ModalTopSection
             title={""}
             discard={this.discard}
             showButton={false}
             RTL = {this.props.RTL}
             />
         </div>
       );
      }
        
    }

    renderBodyContent = () =>{
        switch (this.state.planMode){
            case "overview":{
                return(
                    <div>
                         {this.renderPlanOverview()}
                    </div>
                )
            }
            case "map":{
                return(
                    <div>
                         {this.renderPlanMap()}
                    </div>
                )
            }
            case "planning":{
                return(
                    <div>
                         {this.renderPlanPlanning()}
                    </div>
                )
            }
          /*   case "journey":{
                return(
                    <div>
                         {this.renderPlanExperiences()}
                    </div>
                )
            } */
            default: return null;
        }
    }

    renderPlanOverview = () =>{
        if (this.state.daysStructured){
            return(<div className={classes.PlanOverview}>
                <TripPlanOverview 
                   writeAcitivityLog = {this.props.writeAcitivityLog}
                    planLastModified = {this.state.planLastModified} 
                    isEditor={this.props.isEditor}
                    isPublic = {this.state.isPublic}
                    planEditors={this.state.planEditors}
                    ongoingDay={this.state.ongoingDay}
                    noDatesSelected={this.state.noDatesSelected}
                    planOverview = {true}
                    overviewScrollSelected = {this.state.overviewScrollSelected}
                    updateScrollerSection = {(value)=>{this.setState({overviewScrollSelected:value})}}
                    overviewAddPlaceHandler = {()=>{
                      this.setState({
                        planMode: "planning",
                        planModeOptions: ["RegularMobileWide", "SelectedMobileWide", "RegularMobileWide"],
                      });
                    }}

                    planDates = {this.state.planDates}
                    planDuration={this.state.planDuration}
                    planTasks={this.state.planTasks}
                    planName = {this.state.planName}
                    imagePreview={this.state.imagePreview}
                    planDays={this.state.planDays}
                    planDescription = {this.state.planDescription}
                    confirmDeleteTask = {this.state.confirmDeleteTask}

                    moveDayHandler={this.moveDayHandler}
                    updateDayOrder={this.updateDayOrder}
                    removeFromPlan = {this.removeFromPlan}
                    updateColor={this.updateColorHandler}
                    updatePlaceTime = {this.updatePlaceTimeHandler}
                    viewHandler = {this.viewHandler}
                    voteHandler={this.voteHandler}

                    showEditors={()=>{this.setState({showEditors:true,showBackdrop:true})}}
                    editTrip={()=>{this.setState({showOverviewEditor:true, showBackdrop:true})}}

                    newTaskHandler={this.newTaskHandler}
                    openModeTaskID={this.state.openModeTaskID}
                    newTaskID={this.state.newTaskID}
                    taskOpenHandler = {this.taskOpenHandler}
                    updateTask = {this.updateTask}
                    updateTasksOrder = {this.updateTasksOrder}
                    deleteTask = {this.deleteTask}

                    newNoteHandler = {this.newNoteHandler}
                    openModeNoteId = {this.state.openModeNoteId}
                    newNoteID = {this.state.newNoteID}
                    editNoteID = {this.state.editNoteID}
                    noteOpenHandler = {this.noteOpenHandler}
                    updateNote = {this.updateNote}

                    newTransportHandler={this.newTransportHandler}

                    locationsTranslator = {this.props.locationsTranslator}
                    categoriesTranslator = {this.props.categoriesTranslator}

                    addAccommodationHandler ={this.addAccommodationHandler}

                    showSuggestedTrips = {this.props.showSuggestedTrips}

                    user={this.props.user}
                    userLanguage={this.props.userLanguage}
                    systemText={this.props.systemText}
                    RTL = {this.props.RTL}
                />
            </div>)
        }
    }

    renderPlanMap = () =>{
        return(<div className={classes.PlanPlanning}>
          <PlanMap 
            ref="planMap"
             planMapPlaces={[...this.state.planMapPlaces]}
             planPlacesList = {this.state.planPlacesList}
             mapBoundsHandler={this.mapBoundsHandler}
             mobileDaySelectHandler={this.mobileDaySelectHandler}
             viewHandler={this.viewHandler}
             mobileDaySelectedIndex={this.state.mobileDaySelectedIndex}
             planDays={this.state.planDays}

             planMapIdeasCount = {this.state.planMapIdeasCount}

             categoriesTranslator = {this.props.categoriesTranslator}
             locationsTranslator = {this.props.locationsTranslator}

             RTL = {this.props.RTL}
             userLanguage={this.props.userLanguage}
             systemText={this.props.systemText}
          />
          </div>)
    }
    renderPlanPlanning = () =>{
        return(<div className={classes.PlanPlanning}>
            <TripPlanning 
               ongoingDay={this.state.ongoingDay}
              writeAcitivityLog = {this.props.writeAcitivityLog}
                planLastModified = {this.state.planLastModified}
                isEditor={this.props.isEditor}
                planEditors={this.state.planEditors}
                ref="viewPlanPlanning"
                discardMobileDiscover = {()=>{
                  this.setState({planMode:"overview", planModeOptions: ["SelectedMobileWide", "RegularMobileWide", "RegularMobileWide"],})
                }}

                systemCategories={this.props.systemCategories}

                selectedDiscoverSource = {this.state.selectedDiscoverSource}
                updateDiscoverSource = {this.updateDiscoverSource}
                showDaySelection = {true}
                daySelectHandler = {this.daySelectHandler}
                mobileDaySelectHandler = {this.mobileDaySelectHandler}

                mobileDaySelectedIndex = {this.state.mobileDaySelectedIndex}

                planMapIdeasCount = {this.state.planMapIdeasCount}

                filteredDiscoverPlaces = {this.state.filteredDiscoverPlaces}
                discoverPlacesLocationFilterTags = {this.state.discoverPlacesLocationFilterTags}
                discoverPlacesCategoriesFilterTags = {this.state.discoverPlacesCategoriesFilterTags}
                discoverPlacesSelectedMainCategory = {this.state.discoverPlacesSelectedMainCategory}
                discoverPlacesNameFilterTag = {[...this.state.discoverPlacesNameFilterTag]}
                discoverPlacesAdditionalTagsSearchTags = {this.state.discoverPlacesAdditionalTagsSearchTags}
                discoverPlacesCharacteristicsFilter = {JSON.parse(JSON.stringify(this.state.discoverPlacesCharacteristicsFilter))}
                discoverPlacesDaysFilter = {this.state.discoverPlacesDaysFilter}
                discoverPlacesLocationsSearchTags = {this.state.discoverPlacesLocationsSearchTags}
                discoverPlacesNameSearchTags = {this.state.discoverPlacesNameSearchTags}
                suggestedLocationsFilters = {this.state.suggestedLocationsFilters}
                discoverPlacesAvailableCategories = {this.state.discoverPlacesAvailableCategories}
                discoverPlacesAvailableMainCategories = {this.state.discoverPlacesAvailableMainCategories}
                discoverPlacesAdditionalTagFilterTag = {[...this.state.discoverPlacesAdditionalTagFilterTag]}

                loadFavoritePlacesForPlanning = {this.loadFavoritePlacesForPlanning}
                filteredFavoritePlaces = {this.state.filteredFavoritePlacesToShow}
                
                favoritePlacesSearchTags = {this.state.favoritePlacesSearchTags}
                favoritePlacesFilterTags={this.state.favoritePlacesFilterTags}
                favoriteSearchHandler = {this.favoritePlacesSearchHandler}
                favoriteRemoveTagHandler = {this.favoriteRemovePlaceTagHandler}
                

                countrySelected = {this.state.countrySelected}
                locationsTranslator = {this.props.locationsTranslator}
                categoriesTranslator = {this.props.categoriesTranslator}
                locationsTree = {this.props.locationsTree}
                
                searchDiscoverLocationHandler = {this.searchDiscoverLocationHandler}
                updateSelectedCountry={this.updateSelectedCountryHandler}
                removePlaceNameFilterTag = {this.discoverPlaceNameSearchRemoveHandler}
                removePlaceAdditionalTagFilterTag = {this.discoverPlaceAddiotnalTagSearchRemoveHandler}
                removePlaceLocationFilterTag = {this.removeDiscoverPlacesLocationSearchTagHandler}
                addPlaceLocationTagHandler = {
                  (tag)=>{this.discoverPlacesLocationSearchTagHandler(tag)
                    this.props.writeAcitivityLog({
                      activityKey:"TRIP_PLANNING_DISCOVER_ADD_LOCATION_TAG",
                      userScreen:"VIEW_TRIP"
                    })}}
                discoverPlacesMainCategoryHandler = {
                  (index)=>{this.discoverPlacesMainCategoryHandler(index)
                    this.props.writeAcitivityLog({
                      activityKey:"TRIP_PLANNING_DISCOVER_MAINCATEGORY_CLICKED",
                      userScreen:"VIEW_TRIP"
                    })
                  }}
                addPlaceCategoryTagHandler = {this.addPlaceCategoryTagHandler}
                removePlaceCategoryFilterTag = {
                  (index)=>{this.discoverPlacesCategorySearchTagHandler(index,"remove")
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_PLANNING_DISCOVER_REMOVE_CATEGORY_TAG",
                    userScreen:"VIEW_TRIP"
                  })
                }}
                updatePlaceCharacteristicsFilters = {char=>{this.setState({discoverPlacesCharacteristicsFilter:char})}}
                updateDiscoverPlacesSelectedDay = {
                  (index,value)=>{let discoverPlacesDaysFilter= this.state.discoverPlacesDaysFilter
                  discoverPlacesDaysFilter[index]=value; this.setState({discoverPlacesDaysFilter:discoverPlacesDaysFilter})
                  let activityKey = "TRIP_PLANNING_DISCOVER_DAY_SELECT"
                  let dayIndex = index
                  if (value===0){
                    activityKey = "TRIP_PLANNING_DISCOVER_DAY_UNSELECT"
                  }
                  this.props.writeAcitivityLog({
                    activityKey:activityKey,
                    userScreen:"VIEW_TRIP",
                    dayIndex:dayIndex
                  })
                }}

                noSearchResultsHandler={this.noSearchResultsHandler}
                noResultsFavoritePlaces={this.state.noResultsFavoritePlaces}

                countriesToExplore = {this.props.countriesToExplore}
                placesCharacteristicsData = {this.props.placesCharacteristicsData}

                planDays = {this.state.planDays}
                planPlacesIDs = {this.state.planPlacesIDs}
                planMapPlaces={[...this.state.planMapPlaces]}
                planMapAccommodations = {[...this.state.planMapAccommodations]}
                onlyMapResultsHandler = {this.onlyMapResultsHandler}
                onlyMapAreaResults={this.state.onlyMapAreaResults}
                mapBoundsHandler={this.mapBoundsHandler}

                addPlaceToPlan={this.addPlaceToPlanHandler}
                moveDayHandler={this.moveDayHandler}
                updateDayOrder={this.updateDayOrder}
                removeFromPlan = {this.removeFromPlan}
                updateColor={this.updateColorHandler}
                updatePlaceTime = {this.updatePlaceTimeHandler}
                viewHandler = {this.viewHandler}
                voteHandler={this.voteHandler}

                viewPlaceHandler = {this.props.viewPlaceHandler}
                hoverDiscoverHandler = {this.hoverDiscoverHandler}
                unselectMap={()=>{this.setState({planningMapSeclected:false});this.onlyMapResultsHandler(false)}}

                newNoteHandler = {this.newNoteHandler}
                openModeNoteId = {this.state.openModeNoteId}
                newNoteID = {this.state.newNoteID}
                editNoteID = {this.state.editNoteID}
                noteOpenHandler = {this.noteOpenHandler}
                updateNote = {this.updateNote}

                newTransportHandler={this.newTransportHandler}

                addAccommodationHandler ={this.addAccommodationHandler}

                showFilters = {value=>{this.setState({hideMobileTopbar:value})}}
                hideMobileTopbar = {this.state.hideMobileTopbar}

                showSuggestedTrips = {this.props.showSuggestedTrips}

                user={this.props.user}
                RTL = {this.props.RTL}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                inputLanguage = {this.props.inputLanguage}
                detectInputLanguage = {this.props.detectInputLanguage}
            />
        </div>)
    }

    renderPlanExperiences = () =>{
      return(
        <TripJourney
          isEditor = {this.props.isEditor}
          planDays={this.state.planDays}
          planExperiences = {this.props.planExperiences}
          viewPlaceHandler={this.props.viewPlaceHandler}
          shareExperience={this.shareExperience}
          viewUserJourneyID = {this.props.viewUserJourneyID}
          removeJourneyImage={this.removeJourneyImage}
          addJourneyImage={this.addJourneyImage}
          updateSelectedImages = {this.updateSelectedImages}
          updateJourneyImagesOrder = {this.updateJourneyImagesOrder}
          updateExperienceText = {this.updateExperienceText}


          writeAcitivityLog = {this.props.writeAcitivityLog}

          user={this.props.user}
          RTL = {this.props.RTL}
          userLanguage={this.props.userLanguage}
          systemText={this.props.systemText}
          inputLanguage = {this.props.inputLanguage}
          detectInputLanguage = {this.props.detectInputLanguage}
        />
      )
    }

    renderTripAlbum = () =>{
      if (this.state.showTripAlbum){
        return(
          <TripAlbum
            ref="tripAlbum"
            isEditor = {this.props.isEditor}
            planDays={this.state.planDays.slice(1)}
            planExperiences = {this.props.planExperiences}
            viewPlaceHandler={this.props.viewPlaceHandler}
            shareExperience={this.shareExperience}
            viewUserJourneyID = {this.props.viewUserJourneyID}
            removeJourneyImage={this.removeJourneyImage}
            addJourneyImage={this.addJourneyImage}
            updateSelectedImages = {this.updateSelectedImages}
            updateJourneyImagesOrder = {this.updateJourneyImagesOrder}
            updateExperienceText = {this.updateExperienceText}

            deleteExperienceHandler = {this.props.deleteExperienceHandler}
            editExperienceHandler = {this.props.editExperienceHandler}
            viewExperiencePlace = {this.props.viewExperiencePlace}
            removeExperienceFromPlanHandler = {experience=>{this.props.removeExperienceFromPlanHandler(experience,true)}}
            viewImagesFullscreen = {this.props.viewImagesFullscreen}

            writeAcitivityLog = {this.props.writeAcitivityLog}

            loggedUser = {this.props.loggedUser}
            user={this.props.user}
            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}
            inputLanguage = {this.props.inputLanguage}
            detectInputLanguage = {this.props.detectInputLanguage}
            discard = {()=>{this.setState({showTripAlbum:false,hideMobileTopbar:false})}}
          />
        )
      }
    }

    renderBody = () =>{
      if (!this.state.showTripAlbum){
        return(<div className={classes.Body}>
          {this.renderPlanToolsSection()}
         {this.renderBodyContent()} 
      </div>)
      } 
    }

    renderPlanToolsSection = () =>{
     
        return(<div className={classes.ToolsSection}>
          {this.renderPlanMode()}
          {this.renderTools()}
      </div>)

      
    }

    renderPlanMode = () =>{
      if (isMobile){
        let renderMapButton = true
        let renderAlbumButton = ((this.state.hasExperiences&&this.props.showAlbum)||this.state.tripStarted)&&!this.props.showSuggestedTrips
        if (!renderMapButton&&!renderAlbumButton||this.state.hideMobileTopbar){
         
        }
        else{
          return(
            <div className={classes.PlanMode}>
          <Tab
            tabType={this.state.planModeOptions[0]}
            text={this.props.systemText.plan.viewPlan.overviewMode[this.props.userLanguage]}
            clicked={() => {
              this.setState({
                planMode: "overview",
                planModeOptions: ["SelectedMobileWide", "RegularMobileWide", "RegularMobileWide"],
                planningMapSeclected:false
              });
              this.props.writeAcitivityLog({
                activityKey:"TRIP_OVERVIEW_BUTTON",
                userScreen:"VIEW_TRIP",
                isEditor:this.props.isEditor,
              
              })
            }}
          />
          <div className={classes.MobileSeperator}/>
            {renderMapButton?( 
               <Tab
               tabType={this.state.planModeOptions[1]}
               text={this.props.systemText.plan.viewPlan.planningView[this.props.userLanguage]}
               clicked={() => {
                 this.setState({
                   planMode: "planning",
                   planModeOptions: ["RegularMobileWide", "SelectedMobileWide", "RegularMobileWide"],
                   planningMapSeclected:false
                 });
                 this.props.writeAcitivityLog({
                  activityKey:"TRIP_MAP_BUTTON",
                  userScreen:"VIEW_TRIP",
                  isEditor:this.props.isEditor,
                
                })
               }}
             />   
          ):(null)} 
         
          {renderAlbumButton?( 
            <div style={{display:"flex",width:"100%",alignItems:"center"}}>
                <div className={classes.MobileSeperator}/>
            <Tab
            tabType={this.state.planModeOptions[2]}
            text={this.props.systemText.plan.viewPlan.journeyView[this.props.userLanguage]}
            clicked={() => {
              this.setState({
                showTripAlbum:true,
                hideMobileTopbar:true,
               /*  planMode: "journey",
                planModeOptions: ["RegularMobileWide", "RegularMobileWide", "SelectedMobileWide"],
                planningMapSeclected:false */
              });
              this.props.writeAcitivityLog({
                activityKey:"TRIP_ALBUM_BUTTON",
                userScreen:"VIEW_TRIP",
                isEditor:this.props.isEditor,
               
              })
            }}
          />  </div> ):null}
         
        </div>
          )
        }
       
      }
      else{
        return(
          <div className={classes.PlanMode}>
            <Tab
              tabType={this.state.planModeOptions[0]}
              text={this.props.systemText.plan.viewPlan.overviewMode[this.props.userLanguage]}
              clicked={() => {
                this.setState({
                  planMode: "overview",
                  planModeOptions: ["SelectedMobileWide", "RegularMobileWide", "RegularMobileWide"],
                  planningMapSeclected:false
                });
                this.props.writeAcitivityLog({
                  activityKey:"TRIP_OVERVIEW_BUTTON",
                  userScreen:"VIEW_TRIP",
                  isEditor:this.props.isEditor,
                
                })
              }}
            />
                <div className={classes.MobileSeperator}/>
            <Tab
              tabType={this.state.planModeOptions[1]}
              text={this.props.systemText.plan.viewPlan.planningView[this.props.userLanguage]}
              clicked={() => {
                this.setState({
                  planMode: "planning",
                  planModeOptions: ["RegularMobileWide", "SelectedMobileWide", "RegularMobileWide"],
                  planningMapSeclected:false
                });
                this.props.writeAcitivityLog({
                  activityKey:"TRIP_PLANNING_BUTTON",
                  userScreen:"VIEW_TRIP",
                  isEditor:this.props.isEditor,
                 
                })
              }}
            />  
            {((this.state.hasExperiences&&this.props.showAlbum)||this.state.tripStarted)&&!this.props.showSuggestedTrips?( 
              <div className={classes.MobileSeperator}/>):null}
            {((this.state.hasExperiences&&this.props.showAlbum)||this.state.tripStarted)&&!this.props.showSuggestedTrips?( 
              <Tab
              tabType={this.state.planModeOptions[2]}
              text={this.props.systemText.plan.viewPlan.journeyView[this.props.userLanguage]}
              clicked={() => {
                this.setState({
                  showTripAlbum:true
                 /*  planMode: "journey",
                  planModeOptions: ["RegularMobileWide", "RegularMobileWide", "SelectedMobileWide"],
                  planningMapSeclected:false */
                });
                this.props.writeAcitivityLog({
                  activityKey:"TRIP_ALBUM_BUTTON",
                  userScreen:"VIEW_TRIP",
                  isEditor:this.props.isEditor,
                 
                })
              }}
            />   ):null}
             {this.state.planMode==="planning"?(
              <div>{this.renderMapIcon()}</div>
            ):null}
          </div>
       )
      }
       
    }

    renderMapIcon = () =>{
      let iconClass = "MapIcon"
        if (this.props.RTL){
            iconClass = "MapIconRTL"
        }
      if (this.state.planningMapSeclected){
        return(
          <div className={classes.MapIconSection} 
          onClick={()=>{this.refs.viewPlanPlanning.closeMapHandler();this.setState({planningMapSeclected:false});
          this.onlyMapResultsHandler(false)
          this.props.writeAcitivityLog({
            activityKey:"TRIP_PLANNING_MAP_HIDE",
            userScreen:"VIEW_TRIP",
            isEditor:this.props.isEditor
          })
          }}>
          <img src={mapIcon} alt="" className={classes[iconClass]}/>
          {this.props.systemText.discover.searchSection.hideMap[this.props.userLanguage]}
        </div>
        )
      }
      else{
        return(
          <div className={classes.MapIconSection} 
          onClick={()=>{this.refs.viewPlanPlanning.mapIconClickedHandler();
          this.setState({planningMapSeclected:true})
          this.props.writeAcitivityLog({
            activityKey:"TRIP_PLANNING_MAP_SHOW",
            userScreen:"VIEW_TRIP",
            isEditor:this.props.isEditor
          })
          }}>
          <img src={mapIcon} alt="" className={classes[iconClass]}/>
          {this.props.systemText.discover.searchSection.showMap[this.props.userLanguage]}
        </div>
        )
      }
    }

    renderTools = () =>{
      let render = false
      if (isMobile){
       
      }
      else{
        render = this.props.isEditor&&!this.props.showSuggestedTrips||this.props.adminMode
      }
      if (render){
        return (<div className={classes.AdditionalTools}>
         {/*  {this.renderEditTool()} */}
          {this.renderPrivacyIcon()}
         {/*  {this.renderEditorsIcon()} */}
          {this.renderApproveIcon()}
          {this.renderScatterAgain()}
      </div>)
      }
       else if(this.props.showSuggestedTrips){
         return(
           <div className={classes.AdditionalTools}>
              {this.renderScatterAgain()}
           </div>
         )
       }
    }

    runScatterAgain = async () =>{
      let tripPlacesListByDay = []
      let tripPlacesList = []
      for (let i=1; i<this.state.planDays.length ; i++){
        tripPlacesListByDay.push(this.state.planDays[i].dayItems.map(item=>{return item.item}))
        tripPlacesList=tripPlacesList.concat(this.state.planDays[i].dayItems.map(item=>{return item.item}))
      }
      let args = {
        potentialAnchorCategories:[],
        tripDuration:this.state.planDuration,
        tripPlacesListByDay:tripPlacesListByDay,
        tripPlacesList:tripPlacesList,
        dailyAnchors: this.props.dailyAnchors,

      }
      await this.refs.tripTemplateCreator.improvePlacesScatter(args).then(result=>{
        let planDays = this.state.planDays
        for (let i =1 ; i<planDays.length ; i++){
          planDays[i].dayItems=[]
        }
        for (let i=0; i<result.length ; i++){
          for (let j=0 ; j<result[i].length ; j++){
            planDays[i+1].dayItems.push({type:"place",item:result[i][j]})
          }
        }
        this.setState({planDays:planDays})
      })
      this.updateMapPlaces()
    } 

    renderScatterAgain = () =>{
      if (this.props.showSuggestedTrips&&this.props.adminUser){
        return(
          <div onClick={this.runScatterAgain}>
            {"run again"}
          </div>
        )
      }
    }

    renderApproveIcon = () =>{
      if (this.props.adminMode){
        return(<img src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FApproveIcon.png?alt=media&token=35fb1846-494d-4a50-b822-48e30d33c048"} 
          alt="" className={classes.ToolsIcon} onClick={()=>{this.props.approvePlan()}}/>)
      }
    }

    renderEditTool = () =>{
        let toolsIconClass = "ToolsIcon"
        if (this.props.RTL){
            toolsIconClass = "ToolsIconRTL"
        }
        if (this.props.isEditor){
            return (
              <div className={classes.ToolsObject}><img alt="" src={editIcon}
             className={classes[toolsIconClass]}    
              onClick={()=>{this.setState({showOverviewEditor:true, showBackdrop:true})
              this.props.writeAcitivityLog({
                activityKey:"TRIP_EDIT_OVERVIEW_OPEN",
                userScreen:"EDIT_TRIP",
              })
            }}/></div>)
        }
    }

    renderPrivacyIcon = () =>{
        let toolsIconClass = "ToolsIcon"
        if (this.props.RTL){
            toolsIconClass = "ToolsIconRTL"
        }
      /*   if (this.props.user.userID===this.props.owner.ownerID){ */
            if (this.state.isPublic){
            return (
              <div className={classes.ToolsObject}>
            <img alt="" src={privacyIconSelected} 
            className={classes[toolsIconClass]} 
            onClick={()=>{this.setState({showBackdrop:true,showPrivacy:true})
                this.props.writeAcitivityLog({
                  activityKey:"TRIP_PUBLIC_BUTTON",
                  userScreen:"VIEW_TRIP"
              })
              }}/>
               <div className={classes.ToolText}>
                    {this.props.systemText.plan.tools.planPrivacy[this.props.userLanguage]}</div>
            </div>)
            }
            else{
            return (
              <div className={classes.ToolsObject}>
            <img alt="" src={privacyIconGrey} 
            className={classes[toolsIconClass]} 
            onClick={()=>{this.setState({showBackdrop:true,showPrivacy:true})
              this.props.writeAcitivityLog({
                activityKey:"TRIP_PUBLIC_BUTTON",
                userScreen:"VIEW_TRIP"
              })

              }}/>
                 <div className={classes.ToolText}>
                    {this.props.systemText.plan.tools.planPrivacy[this.props.userLanguage]}</div>
            </div>)
            }
      /*   }  */
    }

    renderEditorsIcon = () =>{
        let toolsIconClass = "ToolsIcon"
        if (this.props.RTL){
            toolsIconClass = "ToolsIconRTL"
        }
        if(this.props.planEditors.map(owner=>{return owner.ownerID}).includes(this.props.user.useriD)){
            if (this.props.planEditors.length>1){
            return (
              <div className={classes.ToolsObject}>
            <img alt="" src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditorsIcon.png?alt=media&token=7259f4db-7b92-4c8c-893b-b5d9a0259c3d"} 
            className={classes[toolsIconClass]} 
              onClick={()=>{this.setState({showEditors:true,showBackdrop:true})
                this.props.writeAcitivityLog({
                  activityKey:"TRIP_EDITORS_BUTTON",
                  userScreen:"VIEW_TRIP"
                })
              }}/>
               <div className={classes.ToolText}>
                    {this.props.systemText.plan.tools.planMembers[this.props.userLanguage]}</div>
            </div>)
            }
            else{
            return (
              <div className={classes.ToolsObject}>
            <img alt="" src={"https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditorsIconGrey.png?alt=media&token=87917115-6039-484b-9a63-1a50baaa25ae"} 
            className={classes[toolsIconClass]} 
              onClick={()=>{this.setState({showEditors:true,showBackdrop:true})
                this.props.writeAcitivityLog({
                  activityKey:"TRIP_EDITORS_BUTTON",
                  userScreen:"VIEW_TRIP"
                })
              }}/>
               <div className={classes.ToolText}>
                    {this.props.systemText.plan.tools.planMembers[this.props.userLanguage]}</div>
            </div>)
            }
            
        }
    }

    renderPrivacy = () =>{
      if (this.state.showPrivacy){
       return(
         <PlanSecurity
          planSecurity = {this.state.planSecurity}
          albumSecurity = {JSON.parse(JSON.stringify(this.state.albumSecurity))}
          planDescription = {this.state.planDescription}
          publicPlanTags = {this.state.publicPlanTags}
          discard = {()=>{this.setState({showPrivacy: false, showBackdrop: false})
            this.props.writeAcitivityLog({
                activityKey:"TRIP_PUBLIC_DISCARD",
                userScreen:"VIEW_TRIP"
            })
            }}
          update = {this.updatePlanPrivacyHandler}
          planIncludePlaces ={this.state.planPlacesIDs.length>0}

          writeAcitivityLog = {this.props.writeAcitivityLog}

          loggedUser = {this.props.loggedUser}
          user = {this.props.user}
          userLanguage={this.props.userLanguage}
          systemText={this.props.systemText}
          RTL = {this.props.RTL}
         />
       ) 
      }
    }

    renderOverviewEditor = () =>{
        if (this.state.showOverviewEditor){
           return(
            <TripPlanOverviewEditor
                editMode = {"Edit"}
                planName = {this.state.planName}
                noDatesSelected={this.state.noDatesSelected}
                planDuration={this.state.planDuration}
                planDates = { this.state.planDates}
                imageGallery={this.state.imageGallery}
                imagePreview={this.state.imagePreview}
                participants={this.state.participants}
                travelWithKids = {this.state.travelWithKids}
                update={this.updatePlanOverview}
                
                discard={()=>{this.setState({showOverviewEditor:false,showBackdrop:false})
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_EDIT_OVERVIEW_DISCARD",
                    userScreen:"EDIT_TRIP",
                  })
                }}

                loggedUser={this.props.loggedUser}
                writeAcitivityLog = {this.props.writeAcitivityLog}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
                RTL = {this.props.RTL}
            />
           ) 
        }
    }

    renderTripTemplateCreator = () =>{
      
      return (<TripTemplateCreator
        ref="tripTemplateCreator"
        
    />)
    }

    renderSearchFunctions = () =>{
        return (<SearchFunctions
            ref="searchFunctions"
            userCategoriesScore={this.props.userCategoriesScore}
            userLanguage={this.props.userLanguage}
            user={this.props.user}

            locationsTranslator = {this.props.locationsTranslator}
            categoriesTranslator = {this.props.categoriesTranslator}
            inputLanguage = {this.props.inputLanguage}
        />)
      }

      renderConfirmModal = () =>{
        if (this.state.confirmMode) {
          return (
            <ConfirmationModal
              header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
              primaryAction={this.discardDelete}
              secondaryAction={this.confirmDelete}
              message={this.state.confirmMessage}
              primaryBtn={this.state.primaryBtn}
              secondaryBtn={this.state.secondaryBtn}
              primaryStyle = {"DiscardButton"}
              secondaryStyle = {"DeleteButton"}
              RTL = {this.props.RTL}
  
            />
          );
        }
        if (this.state.confirmDeleteTask){
          return (
            <ConfirmationModal
              header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
              primaryAction={this.discardDeleteTask}
              secondaryAction={this.confirmDeleteTask}
              message={this.state.confirmMessage}
              primaryBtn={this.state.primaryBtn}
              secondaryBtn={this.state.secondaryBtn}
              primaryStyle = {"DiscardButton"}
              secondaryStyle = {"DeleteButton"}
              RTL = {this.props.RTL}
  
            />
          );
        }
      }

      renderMoveCloneQuestion = () =>{
        if (this.state.showMoveCloneQuestion){
          return(<div className={classes.MoveCloneQuestion}>
            <div className={classes.MoveButton} onClick={this.moveDayFinalizeHandler}>{"Move"}</div>
            <div className={classes.CloneButton} onClick={this.clonePlaceFinalizeHandler}>{"Clone"}</div>
          </div>)
        }
      }

      
      renderEditorsModal = () =>{
        if (this.state.showEditors){
          return(
            <PlanEditors 
            title={this.props.systemText.users.planEditorsTitle[this.props.userLanguage]}
            ownerID={this.props.owner.ownerID}
            isOwner={this.props.user.userID===this.props.owner.ownerID}
            updatePlanEditors={this.updatePlanEditors}
            discard={()=>{this.setState({showEditors:false,showBackdrop:false})
              this.props.writeAcitivityLog({
                activityKey:"TRIP_EDITORS_CLOSE",
                userScreen:"VIEW_TRIP"
              })
              }}
            friends ={this.state.planEditors}
            myFriends={this.props.myFriends}

            writeAcitivityLog = {this.props.writeAcitivityLog}

            RTL = {this.props.RTL}
            userLanguage={this.props.userLanguage}
            systemText={this.props.systemText}/>
          )
        }
      }

      renderCreateTransport = () =>{
        if (this.state.createTransport){
          return(
          <PlanTransportEditor
              user = {this.props.user}
              selectedDayIndex={this.state.selectedDayIndex}
              planStartDate={this.state.planDates.startDate}
            
              save = {this.createTransportHandler}
              discard = {this.discardCreateTransport}

              writeAcitivityLog = {this.props.writeAcitivityLog}

              RTL = {this.props.RTL}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
          />)
        }
      }
  
      renderTransportModal = () =>{
        if (this.state.viewTransport || this.state.editTransport){
          let selectedTransport=this.state.selectedTransport;
          let transportID=selectedTransport.transportID;
          let transportType=selectedTransport.transportType;
          let otherType=selectedTransport.otherType;
          let transportNote =selectedTransport.transportNote;
          let departure =selectedTransport.departure;
          let arrival =selectedTransport.arrival;
          let attachedFiles=[];
          if (selectedTransport.attachedFiles!==undefined){
            attachedFiles=selectedTransport.attachedFiles;
          }
          else{
            selectedTransport.attachedFiles=[];
          }
          let owner=selectedTransport.owner;
          let origin= selectedTransport.origin;
          let destination = selectedTransport.destination;
          let showDatesSection = selectedTransport.showDatesSection;
        if (this.state.viewTransport){
            return(
              <ViewTransport
               transportID={transportID}
               transportType= {transportType}
               otherType={otherType}
              origin={origin}
              destination={destination}
              transportNote={transportNote}
              departure={departure}
              arrival={arrival}
              attachedFiles={attachedFiles}
              owner={owner}
              discard={this.discardViewTransportHandler}

              RTL = {this.props.RTL}
              userLanguage={this.props.userLanguage}
              systemText={this.props.systemText}
               />
            )
          } 
          if (this.state.editTransport){
            return(
              <PlanTransportEditor
                edit = {true}
                transportID={transportID}
                transportType= {transportType}
                otherType={otherType}
                origin={origin}
                destination={destination}
                transportNote={transportNote}
                departure={departure}
                arrival={arrival}
                attachedFiles={attachedFiles}
                owner={owner}
                showDatesSection={showDatesSection}
                user={this.props.user} 
                save={this.updateTransportHandler}
                discard={this.discardViewTransportHandler}

                writeAcitivityLog = {this.props.writeAcitivityLog}

                RTL = {this.props.RTL}
                userLanguage={this.props.userLanguage}
                systemText={this.props.systemText}
               />
            )
          }
        }
      }
  

      renderErrorMessage = () =>{
        if (this.state.showErrorMessage) {
          return (
            <InformationModal 
              infoType={"Negative"}
              header={this.state.errorHeader}
              discard={()=>{this.setState({showTransBackdrop:false,showErrorMessage:false})}}
              message={this.state.errorMessage}
              btnText = {this.state.errorButton}
              RTL = {this.props.RTL}
              />)
        
        }
      }

      renderAdminSection = () =>{
        if (this.props.adminMode&&this.state.potentialPlacesIDs!==undefined){
          if (this.state.potentialPlacesIDs.length>0){
            return(
              <div className={classes.PotentialPlacesSection}>
                <div className={classes.PotentialPlacesMessage}>{"Potential Places Exist:"}</div>
                {this.state.potentialPlacesIDs.map((placeID,index)=>(
                <div>{placeID}</div>
              ))}
                </div>
            )
          }
          
        }
      }

      renderAccommodationSelector = () =>{
        if (this.state.addingAccommodation){
          return(
            <PlanAccommodationSelector

              userLanguage = {this.props.userLanguage}
              systemText = {this.props.systemText}
              RTL = {this.props.RTL}
              detectInputLanguage = {this.props.detectInputLanguage}
              inputLanguage = {this.props.inputLanguage}
              countriesToExplore = {this.props.countriesToExplore}
              locationsTree = {this.props.locationsTree}
              slimPlacesByCountry = {this.props.slimPlacesByCountry}

              selectedCountry = {this.state.selectedCountry}
              updateSelectedCountry = {this.updateSelectedCountryHandler}
              systemCategories = {this.props.systemCategories}
              addAccommodationHandler =  {this.addAccommodationFinalize}

              discard = {()=>{this.setState({addingAccommodation:false,showBackdrop:false})}}
              createNewAccommodation = {(mainCategory,categories)=>{this.props.addAccommodationHandler(mainCategory,categories);}}
            />
          )
        }
      }

      renderDaysOption = () =>{
        if (this.state.showDaysOptions){
          return(
            <PlanDaysOptions
              planDays = {this.state.planDays}
              daysIndices = {this.state.daysIndices}
              singleDayMove = {this.state.singleDayMove}
              accommodationDaysOptions={this.state.accommodationDaysOptions}
              systemText = {this.props.systemText}
              userLanguage = {this.props.userLanguage}
              RTL = {this.props.RTL}
              finishHandler = {this.selectedDaysHandler}
              discard = {()=>{ this.setState({showDaysOptions:false, showBackdrop:false})}}
            />
          )
        }
      }

      renderMobileToolsIcon = () =>{
        if (this.props.isEditor){
          let mobileToolsClass= "MobileToolsOptions"
          if (this.props.RTL){
            mobileToolsClass= "MobileToolsOptionsRTL"
          }
          return(
            <div className={classes.MobileTools}>
              <img src={optionsIcon} alt="" className={classes.MobileToolsIcon}
                onClick={()=>{this.setState({showMobileTools:true,showTransBackdrop:true})}}
              />
              <div className={classes[mobileToolsClass]}>
                 {this.renderMobileTools()}
              </div>
              
            </div>
          )
        }
       
      }

      renderMobileTools = () =>{
        if (this.state.showMobileTools){
          let toolKitStyle="Style2"
          let tools = [];


          /* tools.push({
              action: ()=>{
                  this.setState({showMobileTools:false, showTransBackdrop:false, showBackdrop: true, showOverviewEditor:true});
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_EDIT_OVERVIEW_OPEN",
                    userScreen:"EDIT_TRIP",
                  })},
              text: this.props.systemText.plan.tools.edit[this.props.userLanguage],
              icon: editIcon
              });*/
          let privacyIcon = privacyIconGrey
          if (this.state.isPublic){
            privacyIcon=privacyIconSelected
          } 
          tools.push({
              action: ()=>{
                  this.setState({showMobileTools:false,showTransBackdrop: false,showBackdrop:true,showPrivacy:true});
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_PUBLIC_BUTTON",
                    userScreen:"VIEW_TRIP"
                })},
                  text: this.props.systemText.plan.tools.planPrivacy[this.props.userLanguage],
                icon: privacyIcon
              });
          let editorsIcon = editorsIconGrey
          if (this.state.planEditors.length>1){
            editorsIcon = hasEditorsIcon
          }
          /* tools.push({
              action: ()=>{
                  this.setState({showMobileTools:false,showTransBackdrop: false,showEditors:true,showBackdrop:true})
                  this.props.writeAcitivityLog({
                    activityKey:"TRIP_EDITORS_BUTTON",
                    userScreen:"VIEW_TRIP"
                  })},
              text: this.props.systemText.plan.tools.planMembers[this.props.userLanguage],
              icon: editorsIcon
              }); */
          return(
              <Toolkit
              style={toolKitStyle}
              tools={tools}
              RTL = {this.props.RTL}
              bodyRTL={this.props.RTL}
              />)
        }
      }

      renderMobileTop = () =>{
        if(isMobile&&!this.state.hideMobileTopbar){
          let topSectionZIndex = 1
          if (this.state.showMobileTools){
            topSectionZIndex = 100
          }
          return(
            <div className={classes.MobileTopBar} style={{zIndex:topSectionZIndex}}>
                <div className={classes.PlanTitle}>{this.props.planName}</div>
                {this.renderMobileToolsIcon()}
            </div>
          )
        }

      }
    render(){
        let RTL = ""
        if (this.props.RTL){
            RTL = "RTL"
        }
        return(
        <div className={[classes.Modal,classes[RTL]].join(" ")}>
            <TransparentBackdrop  show={this.state.showTransBackdrop} clicked={this.discardTransparentBackdrop}/>
            <Backdrop show = {this.state.showBackdrop}/>
            {this.renderConfirmModal()}
            {this.renderErrorMessage()}
            {this.renderSuggestedTripMessage()}
            {this.renderTripStartedMessage()}
            {this.renderMoveCloneQuestion()}
            {this.renderSearchFunctions()}
            {this.renderTripTemplateCreator()}
            {this.renderOverviewEditor()}
            {this.renderPrivacy()}
            {this.renderEditorsModal()}
            {this.renderCreateTransport()}
            {this.renderTransportModal()}
            {this.renderAccommodationSelector()}
            {this.renderDaysOption()}
            {this.renderTopSection()}
            {this.renderTripAlbum()}
            {this.renderMobileTop()}
            {this.renderAdminSection()}
            {this.renderBody()}
        </div>)
    }
}

export default ViewTripPlan;