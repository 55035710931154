import React, { Component } from "react";
import classes from "./TripPlaceAlbum.module.css";

import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { ReactSortable, MultiDrag } from "react-sortablejs";

import ModalTopSection from "../../../UI/Misc/ModalTopSection"

import {isMobile, isMobileOnly} from 'react-device-detect';
import { Editor, EditorState , convertFromRaw, convertToRaw} from 'draft-js';
import PlanJourneyDay from "../PlanJourneyDay";
import TransparentBackdrop from "../../../UI/Backdrop/TransparentBackdrop";

let placeIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"
let editIconWhite = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FEditIconWhite.png?alt=media&token=f9c4ebc6-ec3c-472a-833d-7581d3b9272e"

const SortableItem = sortableElement(({index, value}) =>
{ 
  return(<img className={classes.MovingImage} alt="" src={value}/>)});

const SortableContainer = sortableContainer(({children}) => {
  return <div  className={classes.ImagesSection}>{children}</div>;
});


class TripPlaceAlbum extends Component {
    state={
        images: [],
        userJourneyImages:[],
        selectedImageIndex:0,
    }

    
    componentDidMount = () =>{
        let userJourneyImages = []
        let userID = this.props.user.userID
        if (!this.props.isEditor){
            userID = this.props.viewUserJourneyID
        }
        if (this.props.selectedPlace.placeExperiences[userID]!==undefined){
            if (this.props.selectedPlace.placeExperiences[userID].userJourneyImages!==undefined){
                userJourneyImages = this.props.selectedPlace.placeExperiences[userID].userJourneyImages
            }
        }
        this.setState({userJourneyImages:userJourneyImages})
        if (userJourneyImages.length===0){
            //in case of current user didn't shared any images,
            this.setState({editMode:true, selectPhotos:true})
        }
    }

    componentDidUpdate = prevProps =>{
        if (JSON.stringify(this.props.selectedPlace)!==JSON.stringify(prevProps.selectedPlace)){
            let userJourneyImages = []
            let userID = this.props.user.userID
            if (!this.props.isEditor){
                userID = this.props.viewUserJourneyID
            }
            if (this.props.selectedPlace.placeExperiences[userID]!==undefined){
                if (this.props.selectedPlace.placeExperiences[userID].userJourneyImages!==undefined){
                    userJourneyImages = this.props.selectedPlace.placeExperiences[userID].userJourneyImages
                }
            }
            this.setState({userJourneyImages:userJourneyImages})
        }
    }

    renderTopSection = () =>{
        return (
            <div className={classes.TopSection}>
               <ModalTopSection
               title={""}
               discard={this.props.discard}
               showButton={false}
               dark={true}
               RTL = {this.props.RTL}
               />
           </div>
         );
      }
    
      renderBody = () =>{
          if (this.state.editMode){
            if (this.state.orderPhotos){
                return(
                    <div className={classes.Body}>
                        {this.renderOrderMode()}
                    </div>
                )
            }
            else if (this.state.selectPhotos){
                return(
                    <div className={classes.Body}>
                    {this.renderSelectMode()}
                </div>
                )
            }
            
          }
          else{
              return(
                  <div className={classes.Body}>
                      {this.renderPlaceAlbum()}
                  </div>
              )
          }
      }

      onSortEnd = async ({oldIndex, newIndex}) => {
       /*  let userID = this.props.user.userID
        let userJourneyImages = []
        if (this.props.selectedPlace.placeExperiences[userID]!==undefined){
            if (this.props.selectedPlace.placeExperiences[userID].userJourneyImages!==undefined){
                userJourneyImages = this.props.selectedPlace.placeExperiences[userID].userJourneyImages
            }
        }
        userJourneyImages = arrayMove(userJourneyImages, oldIndex, newIndex)
       
        
        this.setState({userJourneyImages:userJourneyImages}) */
        this.setState(({userJourneyImages}) => ({
            userJourneyImages: arrayMove(userJourneyImages, oldIndex, newIndex),
          }));
        //this.props.updateJourneyImagesOrder(userJourneyImages)
      };

      renderSelectMode = () =>{
        let images = []       
        if (isMobileOnly){
            images = this.state.userJourneyImages.map(img => {return img.smallImage.url})
        }
        else{
            images = this.state.userJourneyImages.map(img => {return img.mediumImage.url})
        }

        let userID = this.props.user.userID
        if (!this.props.isEditor){
            userID = this.props.viewUserJourneyID
        }

        let allPlaceImages = [];
        let showImages = []
        let placeExperiencesIDs = [];
        let usersIDs = Object.keys(this.props.selectedPlace.placeExperiences)
        for (let i=0 ; i<usersIDs.length ; i++){
            placeExperiencesIDs = placeExperiencesIDs.concat(this.props.selectedPlace.placeExperiences[usersIDs[i]].userExperiences)  
        }
        for (let i=0 ; i<placeExperiencesIDs.length ; i++){
            const expIndex = this.props.planExperiences.findIndex(exp => {
                return exp.experienceID === placeExperiencesIDs[i];
            });
            if (expIndex!==-1){
                allPlaceImages = allPlaceImages.concat(this.props.planExperiences[expIndex].imagePreview)
            }
        }
        if (isMobileOnly){
            showImages = allPlaceImages.map(img => {return img.smallImage.url})
        }
        else{
            showImages = allPlaceImages.map(img => {return img.mediumImage.url})
        }
        return(
            <div className={classes.PlaceExperiencesSection}>
                <div className={classes.ImagesGallery}>
                    {showImages.map((url,index)=>{
                        const imgIndex = images.findIndex(imgUrl => {
                            return imgUrl === url;
                        });
                        if (imgIndex!==-1){
                            return(
                                <div className={classes.EditImageSection} onClick={()=>{this.removeJourneyImage(imgIndex)}}>
                                    <div className={classes.SelectedImageBullet}/>
                                    <img className={classes.EditImage} alt="" src={url} key={index} />
                                </div>
                            )
                        }
                    else{
                        return(
                            <div className={classes.EditImageSection}  onClick={()=>{this.addJourneyImage(allPlaceImages[index])}}>
                                <div className={classes.NotSelectedImageBullet}/>
                                <img className={classes.EditImage} alt="" src={url} key={index} />
                            </div>
                        )
                    }
                    })}
                </div>
            </div>
        )
      }

      addJourneyImage = async (imgItem) =>{
        let userJourneyImages =this.state.userJourneyImages
        userJourneyImages.push(imgItem)
        this.setState({userJourneyImages:userJourneyImages});
      }

      removeJourneyImage = (imgIndex) =>{
        let userJourneyImages = this.state.userJourneyImages
        userJourneyImages.splice(imgIndex,1);
        this.setState({userJourneyImages:userJourneyImages});
      }

      renderOrderMode = () =>{
        let delayOnTouchStart=false;
        let delay = 0;
        if (isMobile){
            delayOnTouchStart=true
            delay=500;
        }

        let userJourneyImages = this.state.userJourneyImages
        let images = []
        if (isMobileOnly){
            images = userJourneyImages.map(img => {return img.smallImage.url})
        }
        else{
            images = userJourneyImages.map(img => {return img.mediumImage.url})
        }
          return(
            
            <div className={classes.PlaceExperiencesSection}>
                <div className={classes.ImagesGallery}>
                    {/* <SortableContainer
                        axis = {"X"}
                    onSortEnd={({oldIndex, newIndex})=>this.onSortEnd({oldIndex, newIndex})} axis={"x"}>
                    {images.filter(img=>img!==undefined).map((value, index) => {
                        return(
                        <SortableItem key={index} index={index} value={value} />)
                        })}
                    </SortableContainer> */}
                    <ReactSortable
                        animation={200}
                        delayOnTouchStart={delayOnTouchStart}
                        delay={delay}
                            list={this.state.userJourneyImages}
                            setList={(newState) => this.setState({userJourneyImages:newState})}
                        >
                        {this.state.userJourneyImages.map((object,index) => {
                        let image = ""
                        if (isMobileOnly){
                            image = object.smallImage.url
                        }
                        else{
                            image = object.mediumImage.url
                        }
                        return(
                            <img className={classes.MovingImage} alt="" src={image} key={index}/>
                        )})}
                    </ReactSortable>
                </div>
            </div>          
          )
      }

      renderPlaceAlbum = () =>{
        let userJourneyImages = this.state.userJourneyImages
        let images = []
        let largeImage = ""
        if (isMobileOnly){
            images = userJourneyImages.map(img => {return img.smallImage.url})
            if (userJourneyImages[this.state.selectedImageIndex]!==undefined){
                largeImage = userJourneyImages[this.state.selectedImageIndex].mediumImage.url
            }
           
        }
        else{
            images = userJourneyImages.map(img => {return img.mediumImage.url})
            if (userJourneyImages[this.state.selectedImageIndex]!==undefined){
                largeImage = userJourneyImages[this.state.selectedImageIndex].largeImage.url
            }
        }
        

        let selectedPlaceBorder = "BorderLeft"
        if (this.props.RTL){
            selectedPlaceBorder = "BorderRight"
        }
        if (isMobileOnly){
            return(
                <div className={classes.PlaceAlbum}>
                     {this.renderPlaceName()}
                    
                   
                    <div className={[classes.SelectedImage,classes[selectedPlaceBorder]].join(" ")}>
                        <img src={largeImage} alt="" className={classes.LargeImage}/>
                    </div>
                    <div className={classes.ImagesScrollSection}>
                   
                        <div className={classes.ImagesScroller}>
                        
                            {images.map((img,imgIndex)=>(
                                    <img className={classes.SmallImage} alt="" src={img} key={imgIndex}
                                    onClick={()=>{this.setState({selectedImageIndex:imgIndex})}}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
              )
        }
        else{
            return(
                <div className={classes.PlaceAlbum}>
                    <div className={classes.ImagesScrollSection}>
                    {this.renderPlaceName()}
                        <div className={classes.ImagesScroller}>
                        
                            {images.map((img,imgIndex)=>(
                                    <img className={classes.SmallImage} alt="" src={img} key={imgIndex}
                                    onClick={()=>{this.setState({selectedImageIndex:imgIndex})}}
                                    />
                                ))}
                        </div>
                    </div>
                   
                    <div className={[classes.SelectedImage,classes[selectedPlaceBorder]].join(" ")}>
                        <img src={largeImage} alt="" className={classes.LargeImage}/>
                    </div>
                </div>
              )
        }
         
      }

      renderPlaceName = () =>{
        let place = this.props.selectedPlace
        let placeName="";
        //get the relevant language or at least any language name
        if (place.placeName[this.props.userLanguage]!==undefined){
        placeName=place.placeName[this.props.userLanguage]
        }
        else if(place.placeName["English"]!==undefined){
        placeName=place.placeName["English"]
        }
        else{
        let placesNames =  Object.keys(place.placeName);
        placeName=place.placeName[placesNames[0]]
        }
        //console.log(place)
        return(
            <div className={classes.PlaceName}>
                {!isMobileOnly?(
                 <img src={placeIcon} alt="" className={classes.PlaceIcon}/>
                ):null}
              
                {placeName}
            </div>
        )
      }

      updateHandler = async (dayIndex,itemIndex)=>{
       /*  if (this.state.editMode){
            this.props.updateExperienceText(dayIndex,itemIndex,this.state.placeExperienceTempText)
        } */
        if (this.state.orderPhotos){
            this.props.updateJourneyImagesOrder(this.state.userJourneyImages)
        }
        else if (this.state.selectPhotos){
            this.props.updateSelectedImages(this.state.userJourneyImages)
        }
        this.setState({editMode:false,orderPhotos:false,selectPhotos:false,selectedImageIndex:0})
    }

      renderTools = () =>{
          if (this.props.isEditor){
              let tools = "Tools"
              if (this.props.RTL){
                  tools="ToolsRTL"
              }
            return(
                <div className={classes[tools]}>
                    {this.state.editMode?(
                        <div  className={classes.UpdateButton} onClick={()=>{this.updateHandler()}}>
                              {this.props.systemText.plan.viewPlan.experiencesAlbum.done[this.props.userLanguage]}
                            </div>
                    ):(
                        <div className={classes.ToolsOptions}>
                             <div className={classes.UpdateButton}
                                onClick={()=>{this.setState({editMode:true, orderPhotos:true})}}>
                                   {this.props.systemText.plan.viewPlan.experiencesAlbum.reOrder[this.props.userLanguage]}
                                </div>
                                <div className={classes.UpdateButton}
                                onClick={()=>{this.setState({editMode:true, selectPhotos:true})}}>
                                   {this.props.systemText.plan.viewPlan.experiencesAlbum.select[this.props.userLanguage]}
                            </div>
                        </div>
                    )}
                    
                </div>
            )
          }
         
      }

    render(){
        return(
            <div className={classes.Modal}>
                {this.renderTools()}
                {this.renderTopSection()}
                {this.renderBody()}
            </div>
        )
    }
}

export default TripPlaceAlbum;