import React, { Component } from "react";
import classes from "./ViewTransport.module.css";

import ModalTopSection from "../../../UI/Misc/ModalTopSection";
import SearchGoogle from "../../Map/SearchGoogle";
import OptionSelection from "../../OptionSelection/OptionSelection"

import { Editor,EditorState , convertFromRaw, convertToRaw} from 'draft-js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let attachIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FAttachment.png?alt=media&token=c49f6954-8969-4f28-bf62-39bf5b0e78ee"
let planeIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FPlaneIcon.png?alt=media&token=3574b5a9-3843-48fc-b0b1-ecf204c7bad8"
let ferryIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FBoatIcon.png?alt=media&token=6da8cb34-0e00-42cf-918d-09221bfe318b"
let trainIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FTrainIcon.png?alt=media&token=bba234c7-cb6b-4a34-a392-693d3b0a83ae"
let carIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FCarIcon.png?alt=media&token=52ac5d7f-9851-4107-835b-440dfe48b1dc"
let taxiIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FTaxiIcon.png?alt=media&token=07a325ac-4fad-46a1-a5a1-119cd038c1e3"
let busIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FBusIcon.png?alt=media&token=502a7516-6c4f-4f19-a791-3b57752cdcd9"
let otherIcon  = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FTransport%2FOtherIcon.png?alt=media&token=20d90554-a0d5-4558-8e11-149dc1b03058"

let arrowIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FArrowIcon.png?alt=media&token=b664f71d-dc5e-4e14-914c-505ed1794fa1"


const customStyleMap = {
    STRIKETHROUGH: {
      textDecoration: "line-through"
    },
    FONT_SIZE_30: {
      fontSize: "30px"
    }
  };
  

class ViewTransport extends Component {
    state={
        transportType:"Plane",
        otherType:"",
        origin: { address: "" },
        destination: { address: "" },
        departure:new Date(),
        arrival: new Date(),
        transportNote:EditorState.createEmpty(), 
        attachments:[],
        transportOptions:[
            {title:"Plane"},
            {title:"Ferry"},
            {title:"Train"},
            {title:"Car"},
            {title:"Taxi"},
            {title:"Bus"},
            {title:"Other"},
        ],
    }
 
    componentDidMount = () =>{
      try {
        JSON.parse(this.props.transportNote);
        this.setState({transportNote: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.transportNote))),
          jsonString: true
          })
    } catch (e) {
   
    }
    }

    renderTopSection = () =>{
        return (
          <div className={classes.TopSection}>
             <ModalTopSection
                title={""}
                discard={this.props.discard}
                showButton={false}
                RTL = {this.props.RTL}
                modalView = {true}
                />
           
          </div>
        );
    }

   
    renderTransportIcon = () =>{
      switch(this.props.transportType){
          case "plane":{
              return(<img src={planeIcon} alt="" className={classes.TransportIcon}/>)
              break;
          }
          case "ferry":{
              return(<img src={ferryIcon} alt="" className={classes.TransportIcon}/>)
              break;
          }
          case "train":{
              return(<img src={trainIcon} alt="" className={classes.TransportIcon}/>)
              break;
          }
          case "car":{
              return(<img src={carIcon} alt="" className={classes.TransportIcon}/>)
              break;
          }
          case "taxi":{
              return(<img src={taxiIcon} alt="" className={classes.TransportIcon}/>)
              break;
          }
          case "bus":{
              return(<img src={busIcon} alt="" className={classes.TransportIcon}/>)
              break;
          }
          case "other":{
              return(<img src={otherIcon} alt="" className={classes.TransportIcon}/>)
              break;
          }
          default: return null;
      }
  }


    renderType = () =>{
      return(
        <div className={classes.Type}>
            {this.renderTransportIcon()}
        </div>
    )
    }

    renderOrigin = () =>{
          let departure = new Date(this.props.departure)
          var hours = departure.getHours();
          var minutes = departure.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12;
          minutes = minutes < 10 ? '0'+minutes : minutes;
          var strTime = hours + ':' + minutes + ampm;
          let date = departure.getDate()+"/"+(departure.getMonth()+1)+"/"+departure.getFullYear()+" - "+strTime
          return(<div className={[classes.TicketPart,classes.LeftClass].join(" ")}>
              {this.props.origin.address}
              <div className={classes.Time}>
                  {date}
              </div>
          </div>)
    /*   } */
    }
    renderDestination = () =>{
          let arrival = new Date(this.props.arrival)
          var hours = arrival.getHours();
          var minutes = arrival.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12;
          minutes = minutes < 10 ? '0'+minutes : minutes;
          var strTime = hours + ':' + minutes + ampm;
          let date = arrival.getDate()+"/"+(arrival.getMonth()+1)+"/"+arrival.getFullYear()+" - "+strTime
          return(<div className={[classes.TicketPart,classes.RightClass].join(" ")}>
             
             {this.props.destination.address}
              <div className={classes.Time}>
                  {date}
              </div>
          </div>)
   /*    } */
     
  }

    renderTicket = () =>{
        return(<div className={classes.Ticket}>
            {this.renderOrigin()}
            <img className={classes.Arrow} src={arrowIcon} alt=""/>
            {this.renderDestination()}
        </div>)
    }


    renderAttachmentSection = () => {
      if (this.props.attachedFiles.length > 0) {
        return (
          <div className={classes.AttachedFiles}>
            <div className={classes.SectionTitle}>
              {this.props.systemText.plan.viewPlan.attachments.attachedFiles[this.props.userLanguage]}
            </div>
            <div className={classes.Files}>
              {this.props.attachedFiles.map((file, index) => (
                <div className={classes.FileItem}>
                    {file.url!==undefined?(
                          <a href={file.url} target="_blank" key={index}>
                          {file.name}
                          </a>
                    ):(
                      <div> {file.name}</div>
                    )}
                </div>
              ))}
            </div>
          </div>
        );
      }
    }

    renderNoteSection = () => {
      if (this.state.transportNote.getCurrentContent().hasText()){
        return(
            <div className={classes.NoteSection}>
                <Editor
                    className={classes.NoteInputField}
                    customStyleMap={customStyleMap}
                    editorState={this.state.transportNote}
                    readOnly = {true}
                    
                    resize="none"
                />
            </div>
        )
      }
    }

    renderBody = () =>{
        return(<div className={classes.Body}>
              <div className={classes.Content}>
                {this.renderType()}
                {this.renderTicket()}
            </div>
            {this.renderAttachmentSection()}
            {this.renderNoteSection()}
        </div>)
    }

    render(){
      let RTL = ""
      if (this.props.RTL){
          RTL = "RTL"
      }
      return(
          <div className={[classes.Modal,classes[RTL]].join(" ")}>
            {this.renderTopSection()}
            {this.renderBody()}
        </div>)
    }
}

export default ViewTransport;