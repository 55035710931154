
import React, { Component } from "react";

import classes from "./TripTemplateCreator.module.css"

let saving = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FSavingGif.png?alt=media&token=34dfb2f2-8c58-4ffc-8d8a-b92919286d13"


class TripTemplateCreator extends Component {

    state={processing:false
    }

    createTripTemplates = async (args) =>{
        return new Promise(async (resolve, reject) => {
            await this.setState({processing:true,progress:0})

            let trips = []
            await setTimeout(() => {
                this.createTripTemplatesFunc(args).then(result=>{
                    trips = result
                    resolve(trips)
                })
                this.setState({processing:false,progress:0})
            }, 1000);
        })
    }

createTripTemplatesFunc = async (args) =>{
    return new Promise(async (resolve, reject) => {
        //await this.setState({processing:true,progress:2})
        let placesList = args.placesList;
        let destinationType = args.destinationType
        let userCategoriesScore = args.userCategoriesScore
        let tripDuration = args.tripDuration
        let tripRadiusDistance = args.tripRadius;
        let dailyRadiusDistance = args.dailyTripRadius;
        let selectedAccommodation = args.selectedAccommodation
        let selectedPlaces = args.selectedPlaces

        let potentialAnchorCategories = this.props.allSystemCategories.filter(category=>category.potentialAnchor!==undefined).map(cat=>{return cat.id})
        let allPotentialPlacesIncluded = args.placesList
        
        let tripIntensity = args.tripIntensity
        let maxPlacesPerDay=0
        switch(tripIntensity){
            case "easy":{
                maxPlacesPerDay =2
                break;
            }
            case "medium":{
                maxPlacesPerDay = 3
                break;
            }
            case "hard":{
                maxPlacesPerDay = 4;
                break;
            }
            default: return null
        }
        let placesPerDayUpperLimit = maxPlacesPerDay
        let placesPerDayLowerLimit = Math.round(maxPlacesPerDay/2)
        let mainCategoriesSelected = args.mainCategoriesSelected //heavy nature and little sightseeing
        let excludedCategories = []
        if (args.excludedCategories!==undefined){
            excludedCategories=args.excludedCategories
        }
        let includedCategories = []
        if (args.includedCategories!==undefined){
            includedCategories=args.includedCategories
        }
        let useTripArea = args.useTripArea
        let tripCenterCoordinates = args.tripCenterCoordinates
        let travelWithKids = args.travelWithKids 

        let numberOfPlacesToHaveInTripRadius = tripDuration*placesPerDayUpperLimit*1.2

        //IN CASE OF TRYING TO AVOID SAME PLACE BETWEEN TRIPS OPTIONS
        let usedPlacesIDs = [];

        //consider the user preferences for main categories and places per day to decide how many anchors per day
        let topInterestPlacesPerDayCount
        let topInterestCountArgs = {
            mainCategoriesSelected: mainCategoriesSelected,
            placesPerDayLowerLimit: placesPerDayLowerLimit,
            placesPerDayUpperLimit: placesPerDayUpperLimit,
            hasIncludedCategories:includedCategories.length>0
        }
        //by number of places per day and highliy prioritized main categories
        await this.calculateNumberOfAnchors(topInterestCountArgs).then(result => {
            topInterestPlacesPerDayCount = result
            
        });

        //IN CASE OF CHOOSING TO INCLUDE SPECIFIC PLACES
        //first add the places that have a specific day already selected
        let selectedPlacesTripDays = [];
        let selectedPlacesAdded = []
        let selectedPlacesWithoutSpecificDay = []
        for (let i=0 ; i<tripDuration ; i++){
            selectedPlacesTripDays.push([])
        }
        for (let j=0 ; j<selectedPlaces.length ; j++){  
            if (selectedPlaces[j].dayIndex!==0||tripDuration===1){
                selectedPlacesTripDays[(selectedPlaces[j].dayIndex)-1].push(selectedPlaces[j])
                selectedPlacesAdded.push(selectedPlaces[j])
            }
            else{
                selectedPlacesWithoutSpecificDay.push(selectedPlaces[j])
            }
        }

        //filter places list with selected main categories && remove excluded places
        
    /*   await this.filterPlacesByCategories(placesList,mainCategoriesSelected,excludedCategories).then(result => {
            filteredPlacesList = result
        }); */
        
        //prioritize list of places IDs by places score, user interest and specificly included categories
    
        //for anchors find the included categories places as well main caetgory
        let mainCategoryHighestValue = 1
        for (let i = 0 ; i<mainCategoriesSelected.length ; i++){
            if (mainCategoriesSelected[i]===2){
                mainCategoryHighestValue = 2
            }
        }
        let potentialTopPriorityPlaces = [];
        let anchorPlacesPrioritizedListIDs = []
        let includedCategoryPlaceAmplifier = 10
        let includedMainCategoryFocusedAmplifier = 2
        for (let i=0 ; i<placesList.length ; i++){
            let includedCategoryPlace = 0
            let includedMainCategory = 0
            for (let j=0 ; j<placesList[i].placeCategory.length ; j++){
                if (includedCategories.includes(placesList[i].placeCategory[j])){
                    includedCategoryPlace = 1
                }
            }
            for (let j=0 ; j<placesList[i].placeMainCategory.length ; j++){
                if (placesList[i].placeMainCategory[j]===1&&mainCategoriesSelected[j]===mainCategoryHighestValue){
                    includedMainCategory = 1
                }
            }
            let placeHasAnchorCategory = false
            for (let j=0; j<placesList[i].placeCategory.length; j++){
                if (potentialAnchorCategories.includes(placesList[i].placeCategory[j])){
                    placeHasAnchorCategory = true
                }
            }
            let numberOfInstances = includedCategoryPlace*includedCategoryPlaceAmplifier+includedMainCategory*includedMainCategoryFocusedAmplifier*mainCategoryHighestValue
            if (numberOfInstances>0&&placeHasAnchorCategory){
                potentialTopPriorityPlaces.push(placesList[i])
                for (let j=0 ; j<numberOfInstances ; j++){
                    anchorPlacesPrioritizedListIDs.push(placesList[i].placeID)
                }
            }
        }
        

        let trips = []
        //generate list of 10 trips
        let tripCount = 0
        let selectedTripsPlacesIDs = {};
        let maxAttempts = 50
        let attempt = 0;
        while (attempt<maxAttempts/* &&selectedTrips<5 */){
            await this.setState({progress:attempt})
            let tripPlacesList = []
            let tripPlacesListByDay = []
            let tripPlanDays = []
            let usedPlacesIDs = []
            let tripBounds = {}
            let secondRoundPrioritizedIDs = []
            let secondRoundPotentialPlaces = []
            let usedCategoriesIDs = []
            let dailyAnchors = []
            

            //GENERATE TRIP ANCHORS
            let firstPlaceArgs = {
                placesList:potentialTopPriorityPlaces,
                placesPrioritizedListIDs:anchorPlacesPrioritizedListIDs,
                tripDuration: tripDuration,
                tripRadiusDistance:tripRadiusDistance,
                useTripArea: useTripArea,
                tripCenterCoordinates: tripCenterCoordinates,
                tripPlacesListByDay:tripPlacesListByDay,
                tripPlacesList:tripPlacesList,
                placesToGenerateCount:1,
                selectedAccommodation:selectedAccommodation,
                numberOfPlacesToHaveInTripRadius:numberOfPlacesToHaveInTripRadius,
                allPotentialPlacesIncluded:allPotentialPlacesIncluded,
                selectedPlacesTripDays:selectedPlacesTripDays,
                selectedPlacesWithoutSpecificDay:selectedPlacesWithoutSpecificDay
            }
            await this.generateFirstAnchorPerDay(firstPlaceArgs).then(result => {
                tripPlacesList = result.tripPlacesList
                tripPlacesListByDay = result.tripPlacesListByDay
                dailyAnchors = [...result.tripPlacesListByDay]
                usedPlacesIDs= result.usedPlacesIDs
                tripBounds = result.tripBounds
                secondRoundPrioritizedIDs = result.placesPrioritizedListIDs
                secondRoundPotentialPlaces = result.remainingPlacesList
                usedCategoriesIDs = result.usedCategoriesIDs
            });
            //add to trip days the places that from focused main category or included categories
            let topInterestPlacesArgs = {
                usedPlacesIDs:usedPlacesIDs,
                placesList:secondRoundPotentialPlaces,
                placesPrioritizedListIDs:secondRoundPrioritizedIDs,
                tripDuration: tripDuration,
                dailyRadiusDistance:dailyRadiusDistance,
                tripBounds:tripBounds,
                tripPlacesListByDay:tripPlacesListByDay,
                tripPlacesList:tripPlacesList,
                placesToGenerateCount:topInterestPlacesPerDayCount,
                usedCategoriesIDs:usedCategoriesIDs,
            }
            await this.addPlacesToTripDays(topInterestPlacesArgs).then(result => {
                tripPlacesList = result.tripPlacesList
                tripPlacesListByDay = result.tripPlacesListByDay
                usedPlacesIDs= result.usedPlacesIDs
                usedCategoriesIDs = result.usedCategoriesIDs
            });
            
            //generate list of additional places within trip radius 
            let otherPotentialPlacesListInBounds = []
            let maxNorth = tripPlacesList[0].placeLocation.coordinates.lat
            let maxSouth = tripPlacesList[0].placeLocation.coordinates.lat
            let maxEast = tripPlacesList[0].placeLocation.coordinates.lng
            let maxWest = tripPlacesList[0].placeLocation.coordinates.lng
            for (let k = 0 ; k<tripPlacesList.length ; k++){
                if (tripPlacesList[k].placeLocation.coordinates.lat<maxSouth){
                    maxSouth = tripPlacesList[k].placeLocation.coordinates.lat
                }
                if (tripPlacesList[k].placeLocation.coordinates.lat>maxNorth){
                    maxNorth = tripPlacesList[k].placeLocation.coordinates.lat
                }
                if (tripPlacesList[k].placeLocation.coordinates.lng<maxWest){
                    maxWest = tripPlacesList[k].placeLocation.coordinates.lng
                }
                if (tripPlacesList[k].placeLocation.coordinates.lng>maxEast){
                    maxEast = tripPlacesList[k].placeLocation.coordinates.lng
                }

            }
            let updatedTripBounds = {
                south:(maxNorth+maxSouth)/2-(tripRadiusDistance/110)*Math.sqrt(2),
                north:(maxNorth+maxSouth)/2+(tripRadiusDistance/110)*Math.sqrt(2),
                west:(maxEast+maxWest)/2-(tripRadiusDistance/80)*Math.sqrt(2),
                east:(maxEast+maxWest)/2+(tripRadiusDistance/80)*Math.sqrt(2),
            }
            for (let j=0 ; j<placesList.length ; j++){
                let potentialPlaceCoords = placesList[j].placeLocation.coordinates
                if (potentialPlaceCoords.lat<updatedTripBounds.north&&
                    potentialPlaceCoords.lat>updatedTripBounds.south&&
                    potentialPlaceCoords.lng>updatedTripBounds.west&&
                    potentialPlaceCoords.lng<updatedTripBounds.east&&
                    !usedPlacesIDs.includes(placesList[j].placeID)){
                        otherPotentialPlacesListInBounds.push(placesList[j])
                }
            }
            let placesPrioritizedListIDs = []
            await this.prioritizePlaces(otherPotentialPlacesListInBounds,userCategoriesScore,includedCategories).then(result => {
                placesPrioritizedListIDs = result
            });
        
            //now try to add all selected places that are not for specific day and weren't added till this point
            if (selectedPlacesWithoutSpecificDay.filter(place=>!usedPlacesIDs.includes(place.placeID)).length>0){
                let remainingSelectedPlacesArgs = {
                    placesList: selectedPlacesWithoutSpecificDay.filter(place=>!usedPlacesIDs.includes(place.placeID)),
                    tripDuration: tripDuration,
                    dailyRadiusDistance:dailyRadiusDistance,
                    tripPlacesListByDay:tripPlacesListByDay,
                    tripPlacesList:tripPlacesList,
                }
    
                await this.addRemainingSelectedPlaces(remainingSelectedPlacesArgs).then(result => {
                    tripPlacesList = result.tripPlacesList
                    tripPlacesListByDay = result.tripPlacesListByDay
                    usedPlacesIDs= result.usedPlacesIDs
                    usedCategoriesIDs = result.usedCategoriesIDs
                    
                });
            }
           
        
            let finalizePlacesArgs = {
                usedPlacesIDs:usedPlacesIDs,
                placesList:otherPotentialPlacesListInBounds,
                placesPrioritizedListIDs:placesPrioritizedListIDs,
                tripDuration: tripDuration,
                dailyRadiusDistance:dailyRadiusDistance,
                tripBounds:tripBounds,
                tripPlacesListByDay:tripPlacesListByDay,
                tripPlacesList:tripPlacesList,
                placesToGenerateCount:placesPerDayUpperLimit,
                tripIntensity:tripIntensity,
                usedCategoriesIDs:usedCategoriesIDs,
            }
            await this.addPlacesToTripDays(finalizePlacesArgs).then(result => {
                tripPlacesList = result.tripPlacesList
                tripPlacesListByDay = result.tripPlacesListByDay
                usedPlacesIDs= result.usedPlacesIDs
                usedCategoriesIDs = result.usedCategoriesIDs
                
            });
            //switch places between days to maintain better location scatter
            let placesScatterArgs = {
                tripDuration: tripDuration,
                tripPlacesListByDay:tripPlacesListByDay,
                tripPlacesList:tripPlacesList,
                potentialAnchorCategories: potentialAnchorCategories,
                dailyAnchors:dailyAnchors
            }
            await this.improvePlacesScatter(placesScatterArgs).then(result => {
                tripPlacesListByDay = result
            });

            
            //sort day places for logical path
            let sortDaysArgs = {
                tripDuration: tripDuration,
                tripPlacesListByDay:tripPlacesListByDay,
                selectedAccommodation:selectedAccommodation
            }
            await this.sortPlacesOrderInDay(sortDaysArgs).then(result => {
                tripPlacesListByDay = result
            });
            
            //console.log([...tripPlacesListByDay])
            //generate trip ideas list for potential additional places for user to use
            let teipIdeasList = []
            let numberOfIdeasToGenerate = Math.min(10,tripDuration*placesPerDayUpperLimit/1.5)
            let tripIdeasArgs = {
                usedPlacesIDs:usedPlacesIDs,
                placesList:otherPotentialPlacesListInBounds,
                placesPrioritizedListIDs:placesPrioritizedListIDs,
                placesToGenerateCount:numberOfIdeasToGenerate,
            }
            await this.generateTripIdeasLIst(tripIdeasArgs).then(result => {
                teipIdeasList = result
            });


            tripPlanDays.push(teipIdeasList)
            for (let j=0 ; j<tripDuration ; j++){
                tripPlanDays.push(tripPlacesListByDay[j])
            }
            //prevent same place more then in 2 different trips
            /* let validTrip = true
            for (let i=0 ; i<tripPlacesList.length ; i++){
                if (selectedTripsPlacesIDs[tripPlacesList[i].placeID]!==undefined){
                    if (selectedTripsPlacesIDs[tripPlacesList[i].placeID]>1){
                        validTrip=false
                    }
                    
                }
            }
           // console.log(validTrip)
            if (validTrip){
                for (let i=0 ; i<tripPlacesList.length ; i++){
                    if (selectedTripsPlacesIDs[tripPlacesList[i].placeID]!==undefined){
                        selectedTripsPlacesIDs[tripPlacesList[i].placeID]+=1
                        
                    }
                    else{
                        selectedTripsPlacesIDs[tripPlacesList[i].placeID]=1
                    }
                }
                tripCount+=1
                //console.log(tripCount)
                await this.createTrip(tripPlanDays,tripCount,tripDuration,selectedAccommodation).then(result => {
                    let trip = result
                    trip.dailyAnchors=dailyAnchors
                    trips.push(trip)
                }); 
            }*/
            attempt+=1 
            tripCount+=1
            //calculate trip score
            let tripScore = 0
            let scoreArgs = {
                tripDuration: tripDuration,
                tripPlacesListByDay:tripPlacesListByDay,
                tripPlacesList:tripPlacesList,
                travelWithKids:travelWithKids,
                userCategoriesScore:userCategoriesScore,
                selectedPlacesTripDays: selectedPlacesTripDays,
                selectedPlacesWithoutSpecificDay: selectedPlacesWithoutSpecificDay,
                includedCategories:includedCategories,
                mainCategoriesSelected: mainCategoriesSelected,
                tripIntensity: tripIntensity,
            }
            //console.log("calc score")
            await this.calculateTripScore(scoreArgs).then(result => {
                tripScore = result
            });


            //generate trip
            await this.createTrip(tripPlanDays,tripCount,tripDuration,selectedAccommodation).then(result => {
                let trip = result
                trip.dailyAnchors=dailyAnchors
                trips.push({trip:trip,score:tripScore})
            }); 
           
        }
        //sort trips by score
        let sortedTrips  =await trips.sort((a, b) => {
            var keyA = a.score;
            var keyB = b.score;
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });

        let selectedTrips = sortedTrips.slice(0,5).map(obj=>{return obj.trip})
        for (let i = 0 ; i<selectedTrips.length ; i++){
            selectedTrips[i].planName = this.props.systemText.tripGenerator.generatedContent.tripName[this.props.userLanguage]+" #"+(i+1).toString();
        }
        this.setState({processing:false})
        resolve(selectedTrips);
    });
}

calculateTripScore = async (args)=>{
    return new Promise(async (resolve, reject) => {
        let tripDuration = args.tripDuration
        let tripPlacesListByDay = args.tripPlacesListByDay
        let tripPlacesList = args.tripPlacesList
        let travelWithKids = args.travelWithKids
        let userCategoriesScore = args.userCategoriesScore
        let selectedPlacesTripDays = args.selectedPlacesTripDays
        let selectedPlacesWithoutSpecificDay = args.selectedPlacesWithoutSpecificDay;
        let includedCategories = args.includedCategories
        let mainCategoriesSelected = args.mainCategoriesSelected;
        let tripIntensity = args.tripIntensity

        let score = 0;
        //==========what makes a good trip===========
        //you have within it some places that are main attractions and some that are minor
        //it meets your intentions of easy, medium an intensive activities load
        //if you prefered to include restaurants you will have one in the middle of the day and one at the end of it
        //if you travel with kids you will have activities that are good for families and kids and some specific for kids
        //if you wanted to include all the main attractions you will have them as well
        //if you selected to see specific places then they are all included
        //the trip days are clustered by areas
        //there is a logical path between day places, with consideration of the accommodation place if selected
        //check that included categories all exist
        //trip has variety of places from the different main categories selected by the user

        //trip includes all of the specifically selected places
        let notIncludedSpecificPlacesCount = 0
        for (let i=0 ; i<tripDuration ; i++){
            let tripDaysIDs = tripPlacesListByDay[i].map(place=>{return place.placeID})
            if (selectedPlacesTripDays[i]!==undefined){
                for (let j=0 ; j<selectedPlacesTripDays[i].length ; j++){
                    if (!tripDaysIDs.includes(selectedPlacesTripDays[i][j].placeID)){
                        notIncludedSpecificPlacesCount= notIncludedSpecificPlacesCount+1
                    }
                }
            }
        }
        let allTripPlacesIDs = tripPlacesList.map(place=>{return place.placeID})
        for (let j=0 ; j<selectedPlacesWithoutSpecificDay.length ; j++){
            if (!allTripPlacesIDs.includes(selectedPlacesWithoutSpecificDay[j].placeID)){
                notIncludedSpecificPlacesCount= notIncludedSpecificPlacesCount+1
            }
        }
        let selectedPlacesScore = 100-(notIncludedSpecificPlacesCount*10)

        //including selected categories
        let tripCategories = []
        for (let i=0 ; i<tripPlacesList.length ; i++){
            for (let j=0 ; j<tripPlacesList[i].placeCategory.length ; j++){
                if (!tripCategories.includes(tripPlacesList[i].placeCategory[j])){
                    tripCategories.push(tripPlacesList[i].placeCategory[j])
                }
            }
        }

        let missingCategoriesCount = 0
        for (let i=0 ; i<includedCategories.length ; i++){
            if (!tripCategories.includes(includedCategories[i])){
                missingCategoriesCount = missingCategoriesCount+1
            }
        }
        let categoriesScore = 100-(missingCategoriesCount*10)

        //check that main categories selected are included
        let includedMainCategories = [0,0,0,0,0,0]
        let mainCategoriesMissing = 0
        
        for (let i=0 ; i<tripPlacesList.length ; i++){
            for (let j=0 ; j<tripPlacesList[i].placeMainCategory.length ; j++){
                if (tripPlacesList[i].placeMainCategory[j]){
                    includedMainCategories[j]=1
                }
            }
        }
        for (let i = 0 ; i<mainCategoriesSelected.length; i++){
            if (mainCategoriesSelected[i]===1&&includedMainCategories[i]===0){
                mainCategoriesMissing = mainCategoriesMissing+1
            }
        }
        let mainCategoriesScore = 100-(mainCategoriesMissing*10)

        //check that the trip intensity is met
        let lowerDayDuration = 0;
        let upperDayDuration = 0
        switch(tripIntensity){
            case "easy":{
                lowerDayDuration = 2
                upperDayDuration = 4
                break;
            }
            case "medium":{
                lowerDayDuration = 4
                upperDayDuration  = 6
                break;
            }
            case "intensive":{
                lowerDayDuration = 6
                upperDayDuration = 9
                break;
            }
            default: return null;
        }
        let daysNotMeetIntensity = 0
        for (let i=0 ; i<tripDuration ; i++){
            let dayCurrentDuration = 0
            for (let j=0 ; j<tripPlacesListByDay[i].length ; j++){
                await this.getPlaceDuration(tripPlacesListByDay[i][j]).then(result=>{
                    dayCurrentDuration+=result;
                })
            }
           if (dayCurrentDuration<lowerDayDuration||dayCurrentDuration>upperDayDuration){
            daysNotMeetIntensity = daysNotMeetIntensity+1
           }
        }
        let intensityScore = 100-((Math.min(5,daysNotMeetIntensity))*10)

        score = (intensityScore+mainCategoriesScore+categoriesScore+selectedPlacesScore)/4
        //console.log(score)
        resolve(score)
    });
}

calculateNumberOfAnchors = async (args) =>{
    return new Promise(async (resolve, reject) => {
        let mainCategoriesSelected = args.mainCategoriesSelected
        let placesPerDayLowerLimit = args.placesPerDayLowerLimit
        let placesPerDayUpperLimit = args.placesPerDayUpperLimit
        let hasIncludedCategories = args.hasIncludedCategories
        
        let numberOfDailyAnchors

        //LOOKING AT MAIN CATEGORIES THAT HAVE FOCUS
        let numberOfMainCategoriesWithHightRank = 0
        for (let i = 0 ; i<mainCategoriesSelected.length ; i++){
            if (mainCategoriesSelected[i]===2){
                numberOfMainCategoriesWithHightRank += 1;
            }
        }

        let topPriorityPlaces = numberOfMainCategoriesWithHightRank
        if (hasIncludedCategories){
            topPriorityPlaces+=1
        }
       
        let numberOfTopPriorityPlacesPerDay = Math.max(topPriorityPlaces,Math.round(placesPerDayUpperLimit/2))
       /*  let averagePlacesPerDay = Math.floor((placesPerDayUpperLimit+placesPerDayLowerLimit)/2)-1

        if (numberOfMainCategoriesWithHightRank>placesPerDayUpperLimit){
            numberOfDailyAnchors = placesPerDayUpperLimit
        }
        else{
            numberOfDailyAnchors = Math.max(numberOfMainCategoriesWithHightRank,averagePlacesPerDay)
        } */

        resolve(numberOfTopPriorityPlacesPerDay);
    });
}

finalizeTripDayPlaces = args =>{
    return new Promise(async (resolve, reject) => {
        let finalizedTripPlacesListByDay = []
        let tripPlacesList = args.tripPlacesList
        let tripPlacesListByDay = args.tripPlacesListByDay
        let dailyRadiusDistance = args.dailyRadiusDistance;
        let tripRadiusDistance = args.tripRadiusDistance;
        let placesList = args.placesList;
        let placesPerDayLowerLimit = args.placesPerDayLowerLimit;
        let placesPerDayUpperLimit = args.placesPerDayUpperLimit;
        let tripDuration = args.tripDuration;
        let placesPrioritizedListIDs = args.placesPrioritizedListIDs;
        let userCategoriesScore = args.userCategoriesScore

        let tripPlacesListIDs = tripPlacesList.map(place=>{return place.placeID})
        let potentialPlaces = []
        for (let i=0 ; i<placesList.length ; i++){
            if (!tripPlacesListIDs.includes(placesList[i].placeID)){
                potentialPlaces.push(placesList[i])
            }
        }

        let potentialPlacesObjects = {}
        for (let i=0 ; i<potentialPlaces.length ; i++){
            potentialPlacesObjects[potentialPlaces[i].placeID] = potentialPlaces[i]
        }

        let updatedPrioritizedListIDs = []
        let placeScoreAmmplifier = 1.5
        let userCategoryScoreAmplifier = 5

        for (let i=0 ; i<potentialPlaces.length ; i++){
            let numberOfInstances = 1
            if (potentialPlaces[i].placeScore){
                numberOfInstances = potentialPlaces[i].placeScore*placeScoreAmmplifier
            }
            let userCategoryScore=1
            for (let j=0 ; j<potentialPlaces[i].placeCategory.length ; j++){
                if (userCategoriesScore[potentialPlaces[i].placeCategory[j]]!==undefined){
                    if (userCategoriesScore[potentialPlaces[i].placeCategory[j]].score>0){
                        userCategoryScore = userCategoryScore+userCategoriesScore[potentialPlaces[i].placeCategory[j]].score

                    }
                }
            }
            numberOfInstances += userCategoryScore*userCategoryScoreAmplifier
            for (let j = 0 ;j<numberOfInstances ; j++){
                updatedPrioritizedListIDs.push(potentialPlaces[i].placeID)
            }
        } 

        let totalPlacesListByDay =[];
        let usedPlacesIDs = [];
        let usedCategoriesID = [];
        for (let i=0 ; i<tripDuration ; i++){
            
            if((i+1) % 3 == 0){
                //for every 3 days of the trip you can reuse the places categories
                usedCategoriesID = []
            }
            let dailyBounds = {
                south:0,
                north:0,
                west:0,
                east:0,
            }
            let maxSouth = tripPlacesListByDay[i][0].placeLocation.coordinates.lat
            let maxNorth = tripPlacesListByDay[i][0].placeLocation.coordinates.lat
            let maxWest = tripPlacesListByDay[i][0].placeLocation.coordinates.lng
            let maxEast = tripPlacesListByDay[i][0].placeLocation.coordinates.lng
            let dayPlacesList = [...tripPlacesListByDay[i]]
            for (let k = 0 ; k<dayPlacesList.length ; k++){
                if (dayPlacesList[k].placeLocation.coordinates.lat<maxSouth){
                    maxSouth = dayPlacesList[k].placeLocation.coordinates.lat
                }
                if (dayPlacesList[k].placeLocation.coordinates.lat>maxNorth){
                    maxNorth = dayPlacesList[k].placeLocation.coordinates.lat
                }
                if (dayPlacesList[k].placeLocation.coordinates.lng<maxWest){
                    maxWest = dayPlacesList[k].placeLocation.coordinates.lng
                }
                if (dayPlacesList[k].placeLocation.coordinates.lng>maxEast){
                    maxEast = dayPlacesList[k].placeLocation.coordinates.lng
                }

            }
            dailyBounds = {
                south:maxNorth-(dailyRadiusDistance/100)*Math.sqrt(2),
                north:maxSouth+(dailyRadiusDistance/100)*Math.sqrt(2),
                west:maxEast-(dailyRadiusDistance/70)*Math.sqrt(2),
                east:maxWest+(dailyRadiusDistance/70)*Math.sqrt(2),
            }
            let j=dayPlacesList.length
            let randomAttempts = 0
            let mainAttempts = 0
            while (j<placesPerDayUpperLimit &&mainAttempts<100){
                randomAttempts = 0;

                let randomIndex = Math.floor(Math.random() * Math.floor(updatedPrioritizedListIDs.length))
                let randomPlaceID = updatedPrioritizedListIDs[randomIndex]
                let randomPlace = potentialPlacesObjects[randomPlaceID]

                let randomPlaceCoordinates = randomPlace.placeLocation.coordinates
                let randomPlaceOutOfBounds = true
                if (randomPlaceCoordinates.lat<dailyBounds.north&&
                    randomPlaceCoordinates.lat>dailyBounds.south&&
                    randomPlaceCoordinates.lng>dailyBounds.west&&
                    randomPlaceCoordinates.lng<dailyBounds.east){
                        randomPlaceOutOfBounds = false
                }
                let oldCategory = false
                for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                    if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                        oldCategory = true
                    }
                }
                while (randomPlaceOutOfBounds&&usedPlacesIDs.includes(randomPlace.placeID)&&randomAttempts<200){
                    randomAttempts+=1
                    randomIndex = Math.floor(Math.random() * Math.floor(updatedPrioritizedListIDs.length))
                    randomPlaceID = updatedPrioritizedListIDs[randomIndex]
                    randomPlace = potentialPlacesObjects[randomPlaceID]
                    randomPlaceCoordinates = randomPlace.placeLocation.coordinates
                    if (randomPlaceCoordinates.lat<dailyBounds.north&&
                        randomPlaceCoordinates.lat>dailyBounds.south&&
                        randomPlaceCoordinates.lng>dailyBounds.west&&
                        randomPlaceCoordinates.lng<dailyBounds.east){
                            randomPlaceOutOfBounds = false
                    }
                    oldCategory = false
                    for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                        if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                            oldCategory = true
                        }
                    }
                }
                if (randomAttempts<200&&!usedPlacesIDs.includes(randomPlace.placeID)&&!randomPlaceOutOfBounds){
                    //found a match
                    usedPlacesIDs.push(randomPlace.placeID)
                    dayPlacesList.push(randomPlace)
                    usedCategoriesID = usedCategoriesID.concat(randomPlace.placeCategory)
                    j+=1
                    randomAttempts=0
                    //update daily coordinates
                    let maxSouth = dayPlacesList[0].placeLocation.coordinates.lat
                    let maxNorth = dayPlacesList[0].placeLocation.coordinates.lat
                    let maxWest = dayPlacesList[0].placeLocation.coordinates.lng
                    let maxEast = dayPlacesList[0].placeLocation.coordinates.lng
                    for (let k = 0 ; k<dayPlacesList.length ; k++){
                        if (dayPlacesList[k].placeLocation.coordinates.lat<maxSouth){
                            maxSouth = dayPlacesList[k].placeLocation.coordinates.lat
                        }
                        if (dayPlacesList[k].placeLocation.coordinates.lat>maxNorth){
                            maxNorth = dayPlacesList[k].placeLocation.coordinates.lat
                        }
                        if (dayPlacesList[k].placeLocation.coordinates.lng<maxWest){
                            maxWest = dayPlacesList[k].placeLocation.coordinates.lng
                        }
                        if (dayPlacesList[k].placeLocation.coordinates.lng>maxEast){
                            maxEast = dayPlacesList[k].placeLocation.coordinates.lng
                        }

                    }
                    dailyBounds = {
                        south:maxNorth-(dailyRadiusDistance/100)*Math.sqrt(2),
                        north:maxSouth+(dailyRadiusDistance/100)*Math.sqrt(2),
                        west:maxEast-(dailyRadiusDistance/70)*Math.sqrt(2),
                        east:maxWest+(dailyRadiusDistance/70)*Math.sqrt(2),
                    }
                }
                else{
                    mainAttempts+=1
                }
            }
           
            finalizedTripPlacesListByDay.push(dayPlacesList)
            
        }
        
        resolve(finalizedTripPlacesListByDay)
    });
}

includeIncludedCategories = args =>{
    return new Promise(async (resolve, reject) => {
        let tripPlacesList = args.tripPlacesList
        let anchoredPlacesByDay = args.anchoredPlacesByDay
        let includedCategories = args.includedCategories
        let dailyRadiusDistance = args.dailyRadiusDistance;
        let tripRadiusDistance = args.tripRadiusDistance;
        let placesList = args.placesList;
        let placesPerDayLowerLimit = args.placesPerDayLowerLimit;
        let placesPerDayUpperLimit = args.placesPerDayUpperLimit;
        let tripDuration = args.tripDuration;

        let alreadyUsedCategoriesIDs = []
        for (let i=0 ; i<tripPlacesList.length ; i++){
            for (let j=0 ; j<tripPlacesList[i].placeCategory.length ; j++){
                if (!alreadyUsedCategoriesIDs.includes(tripPlacesList[i].placeCategory[j])){
                    alreadyUsedCategoriesIDs.push(tripPlacesList[i].placeCategory[j])
                }
            }
        }
        let missingIncludedCategories = []
        for (let i = 0 ; i<includedCategories.length ; i++){
            if (!alreadyUsedCategoriesIDs.includes(includedCategories[i])){
                missingIncludedCategories.push(includedCategories[i])
            }
        }
        if (missingIncludedCategories.length === 0 ){
            //already all included categories are in the trip
            let result ={
                tripPlacesList:tripPlacesList,
                totalPlacesListByDay:anchoredPlacesByDay
            }
            resolve(result)
            return
        }
       
        let tripPlacesListIds = tripPlacesList.map(place=>{return place.placeID})
        let filteredPlacesListObject = {}
        let filteredPlacesPrioritizedIDs = []
        for (let i=0 ; i<placesList.length ; i++){
            for (let j=0 ; j<placesList[i].placeCategory.length ; j++){
                if (missingIncludedCategories.includes(placesList[i].placeCategory[j])&&
                !tripPlacesListIds.includes(placesList[i].placeID)){
                    filteredPlacesListObject[placesList[i].placeID]=placesList[i]
                    let placeScore = 1
                    if (placesList[i].placeScore!==undefined){
                        placeScore = Math.min(1,placesList[i].placeScore)
                    }
                    for (let k = 0 ;k<placeScore*1.5 ; k++){
                        filteredPlacesPrioritizedIDs.push(placesList[i].placeID)
                    }
                }
            }
        }
        let updatedTripPlaces = []
        let totalPlacesListByDay =[];
        let usedPlacesIDs = [];
        let usedCategoriesID = [];
        for (let i=0 ; i<tripDuration ; i++){
            
            if((i+1) % 3 == 0){
                //for every 3 days of the trip you can reuse the places categories
                usedCategoriesID = []
            }
            let dailyBounds = {
                south:0,
                north:0,
                west:0,
                east:0,
            }
            let maxSouth = anchoredPlacesByDay[i][0].placeLocation.coordinates.lat
            let maxNorth = anchoredPlacesByDay[i][0].placeLocation.coordinates.lat
            let maxWest = anchoredPlacesByDay[i][0].placeLocation.coordinates.lng
            let maxEast = anchoredPlacesByDay[i][0].placeLocation.coordinates.lng
            let dayPlacesList = [...anchoredPlacesByDay[i]]
            for (let k = 0 ; k<dayPlacesList.length ; k++){
                if (dayPlacesList[k].placeLocation.coordinates.lat<maxSouth){
                    maxSouth = dayPlacesList[k].placeLocation.coordinates.lat
                }
                if (dayPlacesList[k].placeLocation.coordinates.lat>maxNorth){
                    maxNorth = dayPlacesList[k].placeLocation.coordinates.lat
                }
                if (dayPlacesList[k].placeLocation.coordinates.lng<maxWest){
                    maxWest = dayPlacesList[k].placeLocation.coordinates.lng
                }
                if (dayPlacesList[k].placeLocation.coordinates.lng>maxEast){
                    maxEast = dayPlacesList[k].placeLocation.coordinates.lng
                }

            }
            dailyBounds = {
                south:maxNorth-(dailyRadiusDistance/100)*Math.sqrt(2),
                north:maxSouth+(dailyRadiusDistance/100)*Math.sqrt(2),
                west:maxEast-(dailyRadiusDistance/70)*Math.sqrt(2),
                east:maxWest+(dailyRadiusDistance/70)*Math.sqrt(2),
            }
            let j=dayPlacesList.length
            let randomAttempts = 0
            let mainAttempts = 0
            while (j<placesPerDayUpperLimit &&mainAttempts<100){
                randomAttempts = 0;

                let randomIndex = Math.floor(Math.random() * Math.floor(filteredPlacesPrioritizedIDs.length))
                let randomPlaceID = filteredPlacesPrioritizedIDs[randomIndex]
                let randomPlace = filteredPlacesListObject[randomPlaceID]

                let randomPlaceCoordinates = randomPlace.placeLocation.coordinates
                let randomPlaceOutOfBounds = true
                if (randomPlaceCoordinates.lat<dailyBounds.north&&
                    randomPlaceCoordinates.lat>dailyBounds.south&&
                    randomPlaceCoordinates.lng>dailyBounds.west&&
                    randomPlaceCoordinates.lng<dailyBounds.east){
                        randomPlaceOutOfBounds = false
                }
                let oldCategory = false
                for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                    if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                        oldCategory = true
                    }
                }
                while (randomPlaceOutOfBounds&&usedPlacesIDs.includes(randomPlace.placeID)&&randomAttempts<200){
                    randomAttempts+=1
                    randomIndex = Math.floor(Math.random() * Math.floor(filteredPlacesPrioritizedIDs.length))
                    randomPlaceID = filteredPlacesPrioritizedIDs[randomIndex]
                    randomPlace = filteredPlacesListObject[randomPlaceID]
                    randomPlaceCoordinates = randomPlace.placeLocation.coordinates
                    if (randomPlaceCoordinates.lat<dailyBounds.north&&
                        randomPlaceCoordinates.lat>dailyBounds.south&&
                        randomPlaceCoordinates.lng>dailyBounds.west&&
                        randomPlaceCoordinates.lng<dailyBounds.east){
                            randomPlaceOutOfBounds = false
                    }
                    oldCategory = false
                    for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                        if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                            oldCategory = true
                        }
                    }
                }
                if (randomAttempts<200&&!usedPlacesIDs.includes(randomPlace.placeID)&&!randomPlaceOutOfBounds){
                    //found a match
                    usedPlacesIDs.push(randomPlace.placeID)
                    dayPlacesList.push(randomPlace)
                    usedCategoriesID = usedCategoriesID.concat(randomPlace.placeCategory)
                    j+=1
                    randomAttempts=0
                    //update daily coordinates
                    let maxSouth = dayPlacesList[0].placeLocation.coordinates.lat
                    let maxNorth = dayPlacesList[0].placeLocation.coordinates.lat
                    let maxWest = dayPlacesList[0].placeLocation.coordinates.lng
                    let maxEast = dayPlacesList[0].placeLocation.coordinates.lng
                    for (let k = 0 ; k<dayPlacesList.length ; k++){
                        if (dayPlacesList[k].placeLocation.coordinates.lat<maxSouth){
                            maxSouth = dayPlacesList[k].placeLocation.coordinates.lat
                        }
                        if (dayPlacesList[k].placeLocation.coordinates.lat>maxNorth){
                            maxNorth = dayPlacesList[k].placeLocation.coordinates.lat
                        }
                        if (dayPlacesList[k].placeLocation.coordinates.lng<maxWest){
                            maxWest = dayPlacesList[k].placeLocation.coordinates.lng
                        }
                        if (dayPlacesList[k].placeLocation.coordinates.lng>maxEast){
                            maxEast = dayPlacesList[k].placeLocation.coordinates.lng
                        }

                    }
                    dailyBounds = {
                        south:maxNorth-(dailyRadiusDistance/100)*Math.sqrt(2),
                        north:maxSouth+(dailyRadiusDistance/100)*Math.sqrt(2),
                        west:maxEast-(dailyRadiusDistance/70)*Math.sqrt(2),
                        east:maxWest+(dailyRadiusDistance/70)*Math.sqrt(2),
                    }
                }
                else{
                    mainAttempts+=1
                }
            }
           
            totalPlacesListByDay.push(dayPlacesList)
            
        }
        for (let i=0 ; i<totalPlacesListByDay.length ; i++){
            updatedTripPlaces = updatedTripPlaces.concat(totalPlacesListByDay[i])
        }
        let result ={
            tripPlacesList:updatedTripPlaces,
            totalPlacesListByDay:anchoredPlacesByDay
        }
        resolve(result)
    });

}

/* filterPlacesByCategories = (placesList,mainCategoriesSelected,excludedCategories) =>{
    return new Promise(async (resolve, reject) => {
        let filteredList = [] 

        for (let i=0 ; i<placesList.length ; i++){
            let mainCategoryMatch = false
            let categoriesAllowed = true
            for (let j=0 ; j<placesList[i].placeMainCategory.length ; j++){
                if (placesList[i].placeMainCategory[j]===1&&mainCategoriesSelected[j]!==0){
                    mainCategoryMatch = true
                }
            }
            for (let j=0 ; j<placesList[i].placeCategory.length ; j++){
                if (excludedCategories.includes(placesList[i].placeCategory[j])){
                    categoriesAllowed = false
                }
            }
            if (categoriesAllowed&&mainCategoryMatch){
                filteredList.push(placesList[i])
            }
        } 
        resolve(filteredList);
    }); 
} */

prioritizePlaces = (placesList,userCategoriesScore,includedCategories) =>{
    return new Promise(async (resolve, reject) => {
        let placesIDs = [] 
        let includedCategoriesScore = 5
        let placeScoreAmmplifier = 2
        let userCategoryScoreAmplifier = 3

        for (let i=0 ; i<placesList.length ; i++){
            let numberOfInstances = 1
            if (placesList[i].placeScore){
                numberOfInstances = placesList[i].placeScore*placeScoreAmmplifier
            }
            let includedCategoriesCount = 0
            let userCategoryScore=1
            for (let j=0 ; j<placesList[i].placeCategory.length ; j++){
                if (includedCategories.includes(placesList[i].placeCategory[j])){
                    includedCategoriesCount+=1
                }
                if (userCategoriesScore[placesList[i].placeCategory[j]]!==undefined){
                    if (userCategoriesScore[placesList[i].placeCategory[j]].score>0){
                        userCategoryScore = userCategoryScore+userCategoriesScore[placesList[i].placeCategory[j]].score

                    }
                }
            }
            numberOfInstances += includedCategoriesCount*includedCategoriesScore
            numberOfInstances += userCategoryScore*userCategoryScoreAmplifier
            for (let j = 0 ;j<numberOfInstances ; j++){
                placesIDs.push(placesList[i].placeID)
            }
        } 
        resolve(placesIDs);
    }); 
}

generateFirstAnchorPerDay = args =>{
    return new Promise(async (resolve, reject) => {
        //for each day of the trip generate the first place that will be the anchor for this day
        //all the anchors for the entire trip should still remain within trip radius boundaries
        //prioritize for these first anchors the specifically selected category if possible
        //if the trip longer than one day try to include that specific category in the additional trips
        //if no specific category or not enough for all trip days, try to prioritize the focused main categoy
        //******* try to include main touris attractions if such were selected and exist as anchors as well
        let tripPlacesListByDay = args.tripPlacesListByDay
        let tripPlacesList = args.tripPlacesList
        let placesList = [...args.placesList]
        let placesPrioritizedListIDs = args.placesPrioritizedListIDs
        let useTripArea= args.useTripArea
        let tripDuration = args.tripDuration
        let tripRadiusDistance = args.tripRadiusDistance;
        let selectedAccommodation = args.selectedAccommodation
        let numberOfPlacesToHaveInTripRadius = args.numberOfPlacesToHaveInTripRadius
        let allPotentialPlacesIncluded = args.allPotentialPlacesIncluded
        let selectedPlacesTripDays = args.selectedPlacesTripDays;
        let selectedPlacesWithoutSpecificDay = args.selectedPlacesWithoutSpecificDay;
        

        //choose the place for the first day
        let placesListObject = {}
        let remainingPlacesList = []
        let remainingPlacesListIDs = []
        let usedPlacesIDs = []
        let usedCategories = []
        let tripBounds = {}

        if (selectedPlacesTripDays[0].length>0){
            //already have a place selected for the first day and it will be the anchor
            //will use all selected places for that day - and will create tripBounds around them
            let firstPlace = selectedPlacesTripDays[0][0]
            let firstPlaceCoordinates = firstPlace.placeLocation.coordinates
            let maxNorth = firstPlaceCoordinates.lat
            let maxSouth = firstPlaceCoordinates.lat
            let maxWest = firstPlaceCoordinates.lng
            let maxEast = firstPlaceCoordinates.lng
            for (let i=1 ; i<selectedPlacesTripDays[0].length ;i++){
                let potentialCoords = selectedPlacesTripDays[0][i].placeLocation.coordinates
                if (potentialCoords.lat>maxNorth){
                    maxNorth = potentialCoords.lat
                }
                if (potentialCoords.lat<maxSouth){
                    maxSouth = potentialCoords.lat
                }
                if (potentialCoords.lng<maxWest){
                    maxWest = potentialCoords.lng
                }
                if (potentialCoords.lng>maxEast){
                    maxEast = potentialCoords.lng
                }
            }
           
            tripBounds = {
                north:maxSouth+(tripRadiusDistance/100)*2,
                south:maxNorth-(tripRadiusDistance/100)*2,
                west:maxEast-(tripRadiusDistance/80)*2,
                east:maxWest+(tripRadiusDistance/80)*2,
            }

            tripPlacesListByDay[0]=[...selectedPlacesTripDays[0]]
            for (let i=0 ; i<selectedPlacesTripDays[0].length ;i++){
                tripPlacesList.push(selectedPlacesTripDays[0][i])
                usedPlacesIDs.push(selectedPlacesTripDays[0][i].placeID)
                usedCategories = usedCategories.concat(selectedPlacesTripDays[0][i].placeCategory)
            }
            
            for (let i=0 ; i<allPotentialPlacesIncluded.length ; i++){
                
                //take only places that will be in the entire trip radius - if tria area was used then all the places will remain
                let potentialPlaceCoords = allPotentialPlacesIncluded[i].placeLocation.coordinates
                if (potentialPlaceCoords.lat<tripBounds.north&&
                    potentialPlaceCoords.lat>tripBounds.south&&
                    potentialPlaceCoords.lng>tripBounds.west&&
                    potentialPlaceCoords.lng<tripBounds.east&&
                    !usedPlacesIDs.includes(allPotentialPlacesIncluded[i].placeID)){
                        placesListObject[allPotentialPlacesIncluded[i].placeID] = allPotentialPlacesIncluded[i]
                        remainingPlacesList.push(allPotentialPlacesIncluded[i])
                        remainingPlacesListIDs.push(allPotentialPlacesIncluded[i].placeID)
                }
            }

            
        }
        else{
             //in case of using trip area then neglect accommodation location otherwise take it into account
            let accommodationBounds={
                north:0,
                south:0,
                west:0,
                east:0,
            }
            if (selectedAccommodation!==null){
                accommodationBounds={
                    north:selectedAccommodation.placeLocation.coordinates.lat+(tripRadiusDistance/100)*2,
                    south:selectedAccommodation.placeLocation.coordinates.lat-(tripRadiusDistance/100)*2,
                    west:selectedAccommodation.placeLocation.coordinates.lng-(tripRadiusDistance/80)*2,
                    east:selectedAccommodation.placeLocation.coordinates.lng+(tripRadiusDistance/80)*2,
                }
            }
            //if there are places that should be included in any day of the trip try to use one of them first
            let firstPlace
            let randomPlaceID
            let randomIndex
            if (selectedPlacesWithoutSpecificDay.length>0){
                let randomIndex = Math.floor(Math.random() * Math.floor(selectedPlacesWithoutSpecificDay.length))
                firstPlace = selectedPlacesWithoutSpecificDay[randomIndex]
            }
            else{
                randomIndex = Math.floor(Math.random() * Math.floor(placesPrioritizedListIDs.length))
                randomPlaceID= placesPrioritizedListIDs[randomIndex]
                const firstPlaceIndex = placesList.findIndex(place => {
                    return place.placeID === randomPlaceID
                });
                firstPlace = placesList[firstPlaceIndex]
            }
            
            let potentialPlaceCoords = firstPlace.placeLocation.coordinates
            tripBounds = {
                north:potentialPlaceCoords.lat+(tripRadiusDistance/100)*2,
                south:potentialPlaceCoords.lat-(tripRadiusDistance/100)*2,
                west:potentialPlaceCoords.lng-(tripRadiusDistance/80)*2,
                east:potentialPlaceCoords.lng+(tripRadiusDistance/80)*2,
            }

            let firstPlacInAccommodationTripRadius = false
            let enoughPlacesInFirstPlaceTripRadius = false
            //in case using trip area then user decided to have accommodation outside trip area and first place will be selected from the list of places in the area
            if (useTripArea){
                firstPlacInAccommodationTripRadius = true
            }
            //same goes if there is no selected accommodation then the first place will be randomly selected
            else if (!useTripArea&&selectedAccommodation===null){
                firstPlacInAccommodationTripRadius = true
            }
            //else check that the selected first place is in trip radius with the accommodation
            else if (!useTripArea&&selectedAccommodation!==null&&
                potentialPlaceCoords.lat<accommodationBounds.north&&
                potentialPlaceCoords.lat>accommodationBounds.south&&
                potentialPlaceCoords.lng>accommodationBounds.west&&
                potentialPlaceCoords.lng<accommodationBounds.east){
                    firstPlacInAccommodationTripRadius = true

            }
            
            for (let i=0 ; i<allPotentialPlacesIncluded.length ; i++){
                
                //take only places that will be in the entire trip radius - if tria area was used then all the places will remain
                let potentialPlaceCoords = allPotentialPlacesIncluded[i].placeLocation.coordinates
                if (potentialPlaceCoords.lat<tripBounds.north&&
                    potentialPlaceCoords.lat>tripBounds.south&&
                    potentialPlaceCoords.lng>tripBounds.west&&
                    potentialPlaceCoords.lng<tripBounds.east&&
                    allPotentialPlacesIncluded[i].placeID!==firstPlace.placeID){
                        placesListObject[allPotentialPlacesIncluded[i].placeID] = allPotentialPlacesIncluded[i]
                        remainingPlacesList.push(allPotentialPlacesIncluded[i])
                        remainingPlacesListIDs.push(allPotentialPlacesIncluded[i].placeID)
                }
            }
            if (remainingPlacesList.length>=numberOfPlacesToHaveInTripRadius){
                enoughPlacesInFirstPlaceTripRadius=true
            }
            //continue searching for that first place that will be in accommodation trip radius and will have enough places around it
            while (!firstPlacInAccommodationTripRadius||!enoughPlacesInFirstPlaceTripRadius){
                randomIndex = Math.floor(Math.random() * Math.floor(placesPrioritizedListIDs.length))
                randomPlaceID = placesPrioritizedListIDs[randomIndex]
                const firstPlaceIndex = placesList.findIndex(place => {
                    return place.placeID === randomPlaceID
                });
                firstPlace = placesList[firstPlaceIndex]
                if (potentialPlaceCoords.lat<accommodationBounds.north&&
                    potentialPlaceCoords.lat>accommodationBounds.south&&
                    potentialPlaceCoords.lng>accommodationBounds.west&&
                    potentialPlaceCoords.lng<accommodationBounds.east){
                        firstPlacInAccommodationTripRadius = true
                }
                potentialPlaceCoords = firstPlace.placeLocation.coordinates
                tripBounds = {
                    north:potentialPlaceCoords.lat+(tripRadiusDistance/100)*2,
                    south:potentialPlaceCoords.lat-(tripRadiusDistance/100)*2,
                    west:potentialPlaceCoords.lng-(tripRadiusDistance/80)*2,
                    east:potentialPlaceCoords.lng+(tripRadiusDistance/80)*2,
                }
                placesListObject = {}
                remainingPlacesList = []
                remainingPlacesListIDs = []
                for (let i=0 ; i<allPotentialPlacesIncluded.length ; i++){
                    
                    //take only places that will be in the entire trip radius - if tria area was used then all the places will remain
                    let potentialPlaceCoords = allPotentialPlacesIncluded[i].placeLocation.coordinates
                    if (potentialPlaceCoords.lat<tripBounds.north&&
                        potentialPlaceCoords.lat>tripBounds.south&&
                        potentialPlaceCoords.lng>tripBounds.west&&
                        potentialPlaceCoords.lng<tripBounds.east&&
                        allPotentialPlacesIncluded[i].placeID!==firstPlace.placeID){
                            placesListObject[allPotentialPlacesIncluded[i].placeID] = allPotentialPlacesIncluded[i]
                            remainingPlacesList.push(allPotentialPlacesIncluded[i])
                            remainingPlacesListIDs.push(allPotentialPlacesIncluded[i].placeID)
                    }
                }
                if (remainingPlacesList.length>=numberOfPlacesToHaveInTripRadius){
                    enoughPlacesInFirstPlaceTripRadius=true
                }
            }
            tripPlacesListByDay[0]=[firstPlace]
            tripPlacesList.push(firstPlace)
            usedPlacesIDs = [firstPlace.placeID]
            usedCategories = [...firstPlace.placeCategory]
        }
       
        
        
        //for the additional places
        
       
        placesPrioritizedListIDs = placesPrioritizedListIDs.filter(id=>remainingPlacesListIDs.includes(id))
        //try to avoid putting all days anchors in same area
       
        //now find first places for the rest of the trip days
        for (let i=1 ; i<tripDuration; i++){
            let randomPlace
            //if there are specific places for that day use them otherwise generate anchor place for that day
            if (selectedPlacesTripDays[i].length>0){
                tripPlacesListByDay[i]=[...selectedPlacesTripDays[i]]
                for (let j=0 ; j<selectedPlacesTripDays[i].length ;j++){
                    tripPlacesList.push(selectedPlacesTripDays[i][j])
                    usedPlacesIDs.push(selectedPlacesTripDays[i][j].placeID)
                    usedCategories = usedCategories.concat(selectedPlacesTripDays[i][j].placeCategory)
                }
            }
            else{
                
                //reset available categories
                if((i) % 2 == 0){
                    //for every 3 days of the trip you can reuse the places categories
                    usedCategories = []
                }
                let randomAttempts = 0
                let randomIndex
                let randomPlaceID
                let oldCategory = false
                if (selectedPlacesWithoutSpecificDay.length>0){
                    let remainingSelectedPlaces = selectedPlacesWithoutSpecificDay.filter(place=>!usedPlacesIDs.includes(place.placeID))
                    randomIndex = Math.floor(Math.random() * Math.floor(remainingSelectedPlaces.length))
                    randomPlace = remainingSelectedPlaces[randomIndex]
                }
                else{
                    //try to put the anchors across the trip radius 
                    let potentialPrioritizedIDs = placesPrioritizedListIDs.filter(placeID=>!usedPlacesIDs.includes(placeID))
                    randomIndex = Math.floor(Math.random() * Math.floor(potentialPrioritizedIDs.length))
                    randomPlaceID = potentialPrioritizedIDs[randomIndex]
                    randomPlace = placesListObject[randomPlaceID]
                    
                    for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                        if (usedCategories.includes(randomPlace.placeCategory[k])){
                            oldCategory = true
                        }
                    }
                }
               
                
                while (oldCategory&&randomAttempts<200){
                    randomAttempts+=1
                    let potentialPrioritizedIDs = placesPrioritizedListIDs.filter(placeID=>!usedPlacesIDs.includes(placeID))
                    randomIndex = Math.floor(Math.random() * Math.floor(potentialPrioritizedIDs.length))
                    randomPlaceID = potentialPrioritizedIDs[randomIndex]
                    randomPlace = placesListObject[randomPlaceID]
                    oldCategory = false
                    for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                        if (usedCategories.includes(randomPlace.placeCategory[k])){
                            oldCategory = true
                        }
                    }
                }
                if (!oldCategory&&randomAttempts<200){
                    //found a place to add to the trip
                    usedPlacesIDs.push(randomPlace.placeID)
                    usedCategories = usedCategories.concat(randomPlace.placeCategory)
                    tripPlacesList.push(randomPlace)
                    tripPlacesListByDay[i]=[randomPlace]
                }
                else if(oldCategory&&randomAttempts===200){
                    //take the last place even that couldn't find new category
                    usedPlacesIDs.push(randomPlace.placeID)
                    usedCategories = usedCategories.concat(randomPlace.placeCategory)
                    tripPlacesList.push(randomPlace)
                    tripPlacesListByDay[i]=[randomPlace]
                }
            }
            
        }
        let usedCategoriesIDs = []
        for (let i =0 ; i<tripPlacesList.length; i++){
            for (let j=0 ; j<tripPlacesList[i].placeCategory.length ; j++){
                if (!usedCategoriesIDs.includes(tripPlacesList[i].placeCategory[j])){
                    usedCategoriesIDs.push(tripPlacesList[i].placeCategory[j])
                }
            }
        }
        let result ={
            tripPlacesList:tripPlacesList,
            tripPlacesListByDay:tripPlacesListByDay,
            usedPlacesIDs:usedPlacesIDs,
            tripBounds:tripBounds,
            placesPrioritizedListIDs:placesPrioritizedListIDs,
            remainingPlacesList:remainingPlacesList,
            usedCategoriesIDs:usedCategoriesIDs
        }
        resolve(result)
    });
}

getPlaceDuration = place =>{
    return new Promise(async (resolve, reject) => {
        let duration = 0
        if (place.averageVisitDuration!==undefined){
            duration = place.averageVisitDuration
        }
        else{
            let placeIsSightseeing = place.placeMainCategory[0]===1
            let placeIsNature = place.placeMainCategory[1]===1
            let placeIsActivity = place.placeMainCategory[2]===1
            let placeIsFood = place.placeMainCategory[4]===2
            if (placeIsNature&&!placeIsSightseeing){
                duration = 3
            }
            else if (placeIsNature&&placeIsSightseeing){
                duration = 2
            }
            else if (!placeIsNature&&placeIsSightseeing){
                duration = 1.5
            }
            else if (placeIsActivity||placeIsFood){
                duration = 1.5
            }
            if (placeIsSightseeing && (
                place.placeCategory.includes("culturalSightseeing_bridge")||
                place.placeCategory.includes("culturalSightseeing_canal")||
                place.placeCategory.includes("culturalSightseeing_fountain")||
                place.placeCategory.includes("culturalSightseeing_memorial")||
                place.placeCategory.includes("culturalSightseeing_monument")||
                place.placeCategory.includes("culturalSightseeing_townSquare")||
                place.placeCategory.includes("culturalSightseeing_sculpture"))){
                    duration = 0.5
                }
        }

        resolve(duration)
    });
}

addPlacesToTripDays = args =>{
    return new Promise(async (resolve, reject) => {
        //this is a helper function
        //it is used both to add high interest places and then use user interests and add additional places

        let placesToGenerateCount = args.placesToGenerateCount
        let usedPlacesIDs = args.usedPlacesIDs
        let tripPlacesListByDay = args.tripPlacesListByDay
        let tripPlacesList = args.tripPlacesList
        let placesList = [...args.placesList]
        let tripBounds = args.tripBounds
        let tripIntensity = args.tripIntensity
        let placesPrioritizedListIDs = args.placesPrioritizedListIDs
        let useTripArea= args.useTripArea
        let tripDuration = args.tripDuration
        let dailyRadiusDistance = args.dailyRadiusDistance;

        let daysCurrentIntensity = []
        let dayDurationByIntensity = 0
        if (tripIntensity!==undefined){
            for (let i=0 ; i<tripDuration ; i++){
                let dayCurrentDuration = 0
                for (let j=0 ; j<tripPlacesListByDay[i].length ; j++){
                    await this.getPlaceDuration(tripPlacesListByDay[i][j]).then(result=>{
                        dayCurrentDuration+=result;
                    })
                }
                daysCurrentIntensity.push(dayCurrentDuration)
            }
            switch(tripIntensity){
                case "easy":{
                    dayDurationByIntensity = 3.5
                    break;
                }
                case "medium":{
                    dayDurationByIntensity  = 6
                    break;
                }
                case "intensive":{
                    dayDurationByIntensity = 9
                    break;
                }
                default: return null;
            }
        }
        

        let placesListObject = {}
        for (let i=0 ; i<placesList.length ; i++){
            placesListObject[placesList[i].placeID] = placesList[i]
        }
        let alreadyUsedCategoriesIDs =args.usedCategoriesIDs
        for (let i= 0 ; i <tripDuration ; i++){
            if((i+1) % 3 == 0){
                //for every 3 days of the trip you can reuse the places categories
                alreadyUsedCategoriesIDs = []
            }
            let anchoredCoordinates = tripPlacesListByDay[i][0].placeLocation.coordinates //the first place that was anchored
            let dailyBounds = {
                south:anchoredCoordinates.lat-(dailyRadiusDistance/100)*Math.sqrt(2),
                north:anchoredCoordinates.lat+(dailyRadiusDistance/100)*Math.sqrt(2),
                west:anchoredCoordinates.lng-(dailyRadiusDistance/70)*Math.sqrt(2),
                east:anchoredCoordinates.lng+(dailyRadiusDistance/70)*Math.sqrt(2),
            }
            let dayPlacesList = [...tripPlacesListByDay[i]];
            let j=dayPlacesList.length
            let randomAttempts = 0
            let mainAttempts = 0
            
            let condition = j<placesToGenerateCount &&mainAttempts<100
            if (tripIntensity!==undefined){
                //when adding additional places beyond anchors, try to fill out the day according to intensity
                condition = daysCurrentIntensity[i]<dayDurationByIntensity &&mainAttempts<100

            }
            while (condition){
                randomAttempts = 0;
                let potentialPrioritizedIDs = placesPrioritizedListIDs.filter(placeID=>!usedPlacesIDs.includes(placeID))
                if (potentialPrioritizedIDs.length>0){
                    let randomIndex = Math.floor(Math.random() * Math.floor(potentialPrioritizedIDs.length))
                    let randomPlaceID = potentialPrioritizedIDs[randomIndex]
                    let randomPlace = placesListObject[randomPlaceID]
                    let randomPlaceCoordinates = randomPlace.placeLocation.coordinates
                    let randomPlaceOutOfBounds = true
                    if (randomPlaceCoordinates.lat<dailyBounds.north&&
                        randomPlaceCoordinates.lat>dailyBounds.south&&
                        randomPlaceCoordinates.lng>dailyBounds.west&&
                        randomPlaceCoordinates.lng<dailyBounds.east){
                            randomPlaceOutOfBounds = false
                    }
                    let oldCategory = false
                    for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                        if (alreadyUsedCategoriesIDs.includes(randomPlace.placeCategory[k])){
                            oldCategory = true
                        }
                    }
                    while (oldCategory&&randomPlaceOutOfBounds&&usedPlacesIDs.includes(randomPlace.placeID)&&randomAttempts<200){
                        randomAttempts+=1
                        let potentialPrioritizedIDs = placesPrioritizedListIDs.filter(placeID=>!usedPlacesIDs.includes(placeID))
                        randomIndex = Math.floor(Math.random() * Math.floor(potentialPrioritizedIDs.length))
                        randomPlaceID = potentialPrioritizedIDs[randomIndex]
                        randomPlace = placesListObject[randomPlaceID]
                        randomPlaceCoordinates = randomPlace.placeLocation.coordinates
                        if (randomPlaceCoordinates.lat<dailyBounds.north&&
                            randomPlaceCoordinates.lat>dailyBounds.south&&
                            randomPlaceCoordinates.lng>dailyBounds.west&&
                            randomPlaceCoordinates.lng<dailyBounds.east){
                                randomPlaceOutOfBounds = false
                        }
                        oldCategory = false
                        for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                            if (alreadyUsedCategoriesIDs.includes(randomPlace.placeCategory[k])){
                                oldCategory = true
                            }
                        }
                    }
                    if (!oldCategory&&randomAttempts<200&&!usedPlacesIDs.includes(randomPlace.placeID)&&!randomPlaceOutOfBounds){
                        //found a match
                        usedPlacesIDs.push(randomPlace.placeID)
                        dayPlacesList.push(randomPlace)
                        tripPlacesList.push(randomPlace)
                        alreadyUsedCategoriesIDs = alreadyUsedCategoriesIDs.concat(randomPlace.placeCategory)
                        j+=1
                        if (tripIntensity!==undefined){
                            let placeDuration = 0
                            await this.getPlaceDuration(randomPlace).then(result=>{
                                placeDuration=result;
                            })
                            daysCurrentIntensity[i]=daysCurrentIntensity[i]+placeDuration
                            condition = daysCurrentIntensity[i]<dayDurationByIntensity &&mainAttempts<100
                        }
                        else{
                            condition = j<placesToGenerateCount &&mainAttempts<100
                        }
                        randomAttempts=0
                        //update daily coordinates
                        let maxSouth = dayPlacesList[0].placeLocation.coordinates.lat
                        let maxNorth = dayPlacesList[0].placeLocation.coordinates.lat
                        let maxWest = dayPlacesList[0].placeLocation.coordinates.lng
                        let maxEast = dayPlacesList[0].placeLocation.coordinates.lng
                        for (let k = 0 ; k<dayPlacesList.length ; k++){
                            if (dayPlacesList[k].placeLocation.coordinates.lat<maxSouth){
                                maxSouth = dayPlacesList[k].placeLocation.coordinates.lat
                            }
                            if (dayPlacesList[k].placeLocation.coordinates.lat>maxNorth){
                                maxNorth = dayPlacesList[k].placeLocation.coordinates.lat
                            }
                            if (dayPlacesList[k].placeLocation.coordinates.lng<maxWest){
                                maxWest = dayPlacesList[k].placeLocation.coordinates.lng
                            }
                            if (dayPlacesList[k].placeLocation.coordinates.lng>maxEast){
                                maxEast = dayPlacesList[k].placeLocation.coordinates.lng
                            }
    
                        }
                        dailyBounds = {
                            south:maxNorth-(dailyRadiusDistance/100)*Math.sqrt(2),
                            north:maxSouth+(dailyRadiusDistance/100)*Math.sqrt(2),
                            west:maxEast-(dailyRadiusDistance/70)*Math.sqrt(2),
                            east:maxWest+(dailyRadiusDistance/70)*Math.sqrt(2),
                        }
                    }
                    else{
                        mainAttempts+=1
                        if (tripIntensity!==undefined){
                            condition = daysCurrentIntensity[i]<dayDurationByIntensity &&mainAttempts<100
                        }
                        else{
                            condition = j<placesToGenerateCount &&mainAttempts<100
                        }
                    }
                }else{
                    mainAttempts+=1
                    if (tripIntensity!==undefined){
                        condition = daysCurrentIntensity[i]<dayDurationByIntensity &&mainAttempts<100
                    }
                    else{
                        condition = j<placesToGenerateCount &&mainAttempts<100
                    }
                }
               
            }
            tripPlacesListByDay[i]= dayPlacesList

        }
        let usedCategoriesIDs = []
        for (let i =0 ; i<tripPlacesList.length; i++){
            for (let j=0 ; j<tripPlacesList[i].placeCategory.length ; j++){
                if (!usedCategoriesIDs.includes(tripPlacesList[i].placeCategory[j])){
                    usedCategoriesIDs.push(tripPlacesList[i].placeCategory[j])
                }
            }
        }
        let result ={
            tripPlacesList:tripPlacesList,
            tripPlacesListByDay:tripPlacesListByDay,
            usedPlacesIDs:usedPlacesIDs,
            usedCategoriesIDs:usedCategoriesIDs
        }
        resolve(result)
    });
}

addRemainingSelectedPlaces = args =>{
    return new Promise(async (resolve, reject) => {
        let placesList = args.placesList
        let tripDuration = args.tripDuration
        let dailyRadiusDistance = args.dailyRadiusDistance
        let tripPlacesListByDay = args.tripPlacesListByDay
        let tripPlacesList= args.tripPlacesList;

        //generate the area of each trip day
        let tripDaysCenters =[]
        for (let i=0 ; i<tripDuration; i++){
            let dayPlaces = tripPlacesListByDay[i]
            let maxNorth = dayPlaces[0].placeLocation.coordinates.lat
            let maxSouth = dayPlaces[0].placeLocation.coordinates.lat
            let maxWest = dayPlaces[0].placeLocation.coordinates.lng
            let maxEast = dayPlaces[0].placeLocation.coordinates.lng
            for (let i=1 ; i<dayPlaces.length ;i++){
                let potentialCoords = dayPlaces[i].placeLocation.coordinates
                if (potentialCoords.lat>maxNorth){
                    maxNorth = potentialCoords.lat
                }
                if (potentialCoords.lat<maxSouth){
                    maxSouth = potentialCoords.lat
                }
                if (potentialCoords.lng<maxWest){
                    maxWest = potentialCoords.lng
                }
                if (potentialCoords.lng>maxEast){
                    maxEast = potentialCoords.lng
                }
            }
            let dayCenterCoord = {
                lat:(maxNorth+maxSouth)/2,
                lng:(maxWest+maxEast)/2
            }
            tripDaysCenters.push(dayCenterCoord)
        }

        for (let i=0 ; i<placesList.length ; i++){
            let minDistanceFromDayCenter
            let dayIndex = 0
            var ky = 40000 / 360;
            var kx = Math.cos(Math.PI * placesList[i].placeLocation.coordinates.lat / 180.0) * ky;

            var dx = Math.abs(placesList[i].placeLocation.coordinates.lng - tripDaysCenters[0].lng) * kx;
            var dy = Math.abs(placesList[i].placeLocation.coordinates.lat - tripDaysCenters[0].lat) * ky;
            minDistanceFromDayCenter = Math.sqrt(dx * dx + dy * dy) 

            for (let j=0 ; j<tripDaysCenters.length ; j++){
                var dx = Math.abs(placesList[i].placeLocation.coordinates.lng - tripDaysCenters[j].lng) * kx;
                var dy = Math.abs(placesList[i].placeLocation.coordinates.lat - tripDaysCenters[j].lat) * ky;
                if (Math.sqrt(dx * dx + dy * dy) <minDistanceFromDayCenter){
                    minDistanceFromDayCenter = Math.sqrt(dx * dx + dy * dy)
                    dayIndex = j
                }
            }
            tripPlacesListByDay[dayIndex].push(placesList[i])
            tripPlacesList.push(placesList[i])
        }
        let usedCategoriesIDs = []
        for (let i =0 ; i<tripPlacesList.length; i++){
            for (let j=0 ; j<tripPlacesList[i].placeCategory.length ; j++){
                if (!usedCategoriesIDs.includes(tripPlacesList[i].placeCategory[j])){
                    usedCategoriesIDs.push(tripPlacesList[i].placeCategory[j])
                }
            }
        }
        let usedPlacesIDs = tripPlacesList.map(place=>{return place.placeID})
        let result ={
            tripPlacesList:tripPlacesList,
            tripPlacesListByDay:tripPlacesListByDay,
            usedPlacesIDs:usedPlacesIDs,
            usedCategoriesIDs:usedCategoriesIDs
        }
        resolve(result)
    });
}

improvePlacesScatterExistingTrip = args =>{
    return new Promise(async (resolve, reject) => {
        let tripDuration = args.tripDuration
        let tripPlacesListByDay = [...args.tripPlacesListByDay];
        let potentialAnchorCategories = args.potentialAnchorCategories
        //for each trip day check if there is a place in following day that better suits this day
        for (let i=0;i<tripDuration-1;i++){
            let thisDayPlacesList = [...tripPlacesListByDay[i]]
            let nextDayPlacesList = [...tripPlacesListByDay[(i+1)]]
            //get this day middle coordinates
            let thisDayCenterLat = 0
            let thisDayCenterLng = 0
            for (let j =0 ; j<thisDayPlacesList.length ; j++){
                thisDayCenterLat = thisDayCenterLat+thisDayPlacesList[j].placeLocation.coordinates.lat
                thisDayCenterLng = thisDayCenterLng+thisDayPlacesList[j].placeLocation.coordinates.lng
            }
            thisDayCenterLat = thisDayCenterLat/thisDayPlacesList.length
            thisDayCenterLng = thisDayCenterLng/thisDayPlacesList.length

            let nextDayCenterLat = 0
            let nextDayCenterLng = 0
            for (let j =0 ; j<nextDayPlacesList.length ; j++){
                nextDayCenterLat = nextDayCenterLat+nextDayPlacesList[j].placeLocation.coordinates.lat
                nextDayCenterLng = nextDayCenterLng+nextDayPlacesList[j].placeLocation.coordinates.lng
            }
            nextDayCenterLat = nextDayCenterLat/nextDayPlacesList.length
            nextDayCenterLng = nextDayCenterLng/nextDayPlacesList.length

            var ky = 40000 / 360;
            var thisKX = Math.cos(Math.PI * thisDayCenterLat / 180.0) * ky;
            var nextKX = Math.cos(Math.PI * nextDayCenterLat / 180.0) * ky;

            let distanceThisFromThis = []
            for (let j =0 ; j<thisDayPlacesList.length ; j++){
                let placeCoordinates = thisDayPlacesList[j].placeLocation.coordinates
                var dx = Math.abs(thisDayCenterLng - placeCoordinates.lng) * thisKX;
                var dy = Math.abs(thisDayCenterLat - placeCoordinates.lat) * ky;
                distanceThisFromThis.push(  Math.sqrt(dx * dx + dy * dy) )
            }

            let distanceThisFromNext = []
            for (let j =0 ; j<thisDayPlacesList.length ; j++){
                let placeCoordinates = thisDayPlacesList[j].placeLocation.coordinates
                var dx = Math.abs(nextDayCenterLng - placeCoordinates.lng) * nextKX;
                var dy = Math.abs(nextDayCenterLat - placeCoordinates.lat) * ky;
                distanceThisFromNext.push(  Math.sqrt(dx * dx + dy * dy) )
            }
            
            let  distanceNextFromNext= []
            for (let j =0 ; j<nextDayPlacesList.length ; j++){
                let placeCoordinates = nextDayPlacesList[j].placeLocation.coordinates
                var dx = Math.abs(nextDayCenterLng - placeCoordinates.lng) * nextKX;
                var dy = Math.abs(nextDayCenterLat - placeCoordinates.lat) * ky;
                distanceNextFromNext.push(  Math.sqrt(dx * dx + dy * dy) )
            }

            let distanceNextFromThis = []
            for (let j =0 ; j<nextDayPlacesList.length ; j++){
                let placeCoordinates = nextDayPlacesList[j].placeLocation.coordinates
                var dx = Math.abs(thisDayCenterLng - placeCoordinates.lng) * thisKX;
                var dy = Math.abs(thisDayCenterLat - placeCoordinates.lat) * ky;
                distanceNextFromThis.push(  Math.sqrt(dx * dx + dy * dy) )
            }
            //now find the places in this day that are closer to next day scatter
            let placesIndexesFromThisDayCloserToNext = []
            for (let j =0 ; j<thisDayPlacesList.length ; j++){
              
                if (distanceThisFromThis[j]>distanceThisFromNext[j]){
                    placesIndexesFromThisDayCloserToNext.push(j)
                }
            }

             //now find the places in next day that are closer to this day scatter
             let placesIndexesFromNextDayCloserToThis = []
             for (let j =0 ; j<nextDayPlacesList.length ; j++){
                 if (distanceNextFromThis[j]<distanceNextFromNext[j]){
                    placesIndexesFromNextDayCloserToThis.push(j)
                 }
             }

             let newThisDayList = []
             let newNextDayList = []
             let addedToThisDay = 0;
             let addedToNextDay = 0;
             for (let j =0 ; j<thisDayPlacesList.length ; j++){
                let potentialAnchorCategory =false;
                for (let k=0 ; k<thisDayPlacesList[j].placeCategory.length ;k++){
                    if (potentialAnchorCategories.includes(thisDayPlacesList[j].placeCategory[k])){
                        potentialAnchorCategory = true
                    }
                }
                if (!placesIndexesFromThisDayCloserToNext.includes(j)/* &&potentialAnchorCategory */&&addedToThisDay===0){
                    newThisDayList.push(thisDayPlacesList[j])
                    addedToThisDay+=1
                }
                else{
                    newNextDayList.push(thisDayPlacesList[j])
                }
             }
             for (let j =0 ; j<nextDayPlacesList.length ; j++){
                let potentialAnchorCategory =false;
                for (let k=0 ; k<nextDayPlacesList[j].placeCategory.length ;k++){
                    if (potentialAnchorCategories.includes(nextDayPlacesList[j].placeCategory[k])){
                        potentialAnchorCategory = true
                    }
                }
                if (!placesIndexesFromNextDayCloserToThis.includes(j)/* &&potentialAnchorCategory */&&addedToNextDay===0){
                    newNextDayList.push(nextDayPlacesList[j])
                    addedToNextDay+=1
                }
                else{
                    newThisDayList.push(nextDayPlacesList[j])
                }
             }
             tripPlacesListByDay[i]=[...newThisDayList]
             tripPlacesListByDay[(i+1)]=[...newNextDayList]
        }
        resolve(tripPlacesListByDay)
    });
}

improvePlacesScatter = async  args =>{
    return new Promise(async (resolve, reject) => {
        let tripDuration = args.tripDuration
        let tripPlacesListByDay = [...args.tripPlacesListByDay];
        let tripPlacesList = args.tripPlacesList
        let potentialAnchorCategories = args.potentialAnchorCategories
        let dailyAnchors = args.dailyAnchors;

       
        let newTripPlacesListByDay = []
        let removeAnchorsIDs = []
        let numberOfPlacesPerDay = []
        for (let i=0 ; i<tripPlacesListByDay.length ; i++){
            numberOfPlacesPerDay.push(tripPlacesListByDay[i].length)
        }
        for (let i=0; i<tripDuration ; i++){
            
            newTripPlacesListByDay.push([dailyAnchors[i][0]])
            removeAnchorsIDs.push(dailyAnchors[i][0].placeID)
        }
        let usedPlacesIDs = removeAnchorsIDs
        let remainingPlaces = tripPlacesList.filter(place=>!usedPlacesIDs.includes(place.placeID))
        //for each trip day check if there is a place in following day that better suits this day
        let placesDistancesFromAnchors = {}
        for (let i=0 ; i<remainingPlaces.length ; i++){
            placesDistancesFromAnchors[remainingPlaces[i].placeID]=[]
        }
        for (let i=0;i<tripDuration;i++){
            let dayAnchorPlace = dailyAnchors[i][0];
            let anchorCoordinates = dayAnchorPlace.placeLocation.coordinates
           
            //find the closest places from all trip places to this anchor
            let placesByDistanceFromAnchor = []

            var ky = 40000 / 360;
            var anchorKX = Math.cos(Math.PI * anchorCoordinates.lat / 180.0) * ky;
            for (let j=0 ; j<remainingPlaces.length ; j++){
                let distanceFromAnchor;
                let placeCoords = remainingPlaces[j].placeLocation.coordinates
           

                var dx = Math.abs(anchorCoordinates.lng - placeCoords.lng) * anchorKX;
                var dy = Math.abs(anchorCoordinates.lat - placeCoords.lat) * ky;
                distanceFromAnchor = Math.sqrt(dx * dx + dy * dy)
                placesDistancesFromAnchors[remainingPlaces[j].placeID].push({distance:distanceFromAnchor,day:i})
            }
            /* let  placesByDistanceFromAnchorSorted  =await placesByDistanceFromAnchor.sort((a, b) => {
                var keyA = a.distance;
                var keyB = b.distance;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            for (let j=0 ; j<tripPlacesListByDay[i].length-1; j++){
                console.log(placesByDistanceFromAnchorSorted[j].place)
                newTripPlacesListByDay[i].push(placesByDistanceFromAnchorSorted[j].place)
                
            } */
          /*   let thisDayPlacesList = [...tripPlacesListByDay[i]]
            let nextDayPlacesList = [...tripPlacesListByDay[(i+1)]]
            //get this day middle coordinates
            let thisDayCenterLat = 0
            let thisDayCenterLng = 0
            for (let j =0 ; j<thisDayPlacesList.length ; j++){
                thisDayCenterLat = thisDayCenterLat+thisDayPlacesList[j].placeLocation.coordinates.lat
                thisDayCenterLng = thisDayCenterLng+thisDayPlacesList[j].placeLocation.coordinates.lng
            }
            thisDayCenterLat = thisDayCenterLat/thisDayPlacesList.length
            thisDayCenterLng = thisDayCenterLng/thisDayPlacesList.length

            let nextDayCenterLat = 0
            let nextDayCenterLng = 0
            for (let j =0 ; j<nextDayPlacesList.length ; j++){
                nextDayCenterLat = nextDayCenterLat+nextDayPlacesList[j].placeLocation.coordinates.lat
                nextDayCenterLng = nextDayCenterLng+nextDayPlacesList[j].placeLocation.coordinates.lng
            }
            nextDayCenterLat = nextDayCenterLat/nextDayPlacesList.length
            nextDayCenterLng = nextDayCenterLng/nextDayPlacesList.length

            var ky = 40000 / 360;
            var thisKX = Math.cos(Math.PI * thisDayCenterLat / 180.0) * ky;
            var nextKX = Math.cos(Math.PI * nextDayCenterLat / 180.0) * ky;

            let distanceThisFromThis = []
            for (let j =0 ; j<thisDayPlacesList.length ; j++){
                let placeCoordinates = thisDayPlacesList[j].placeLocation.coordinates
                var dx = Math.abs(thisDayCenterLng - placeCoordinates.lng) * thisKX;
                var dy = Math.abs(thisDayCenterLat - placeCoordinates.lat) * ky;
                distanceThisFromThis.push(  Math.sqrt(dx * dx + dy * dy) )
            }

            let distanceThisFromNext = []
            for (let j =0 ; j<thisDayPlacesList.length ; j++){
                let placeCoordinates = thisDayPlacesList[j].placeLocation.coordinates
                var dx = Math.abs(nextDayCenterLng - placeCoordinates.lng) * nextKX;
                var dy = Math.abs(nextDayCenterLat - placeCoordinates.lat) * ky;
                distanceThisFromNext.push(  Math.sqrt(dx * dx + dy * dy) )
            }
            
            let  distanceNextFromNext= []
            for (let j =0 ; j<nextDayPlacesList.length ; j++){
                let placeCoordinates = nextDayPlacesList[j].placeLocation.coordinates
                var dx = Math.abs(nextDayCenterLng - placeCoordinates.lng) * nextKX;
                var dy = Math.abs(nextDayCenterLat - placeCoordinates.lat) * ky;
                distanceNextFromNext.push(  Math.sqrt(dx * dx + dy * dy) )
            }

            let distanceNextFromThis = []
            for (let j =0 ; j<nextDayPlacesList.length ; j++){
                let placeCoordinates = nextDayPlacesList[j].placeLocation.coordinates
                var dx = Math.abs(thisDayCenterLng - placeCoordinates.lng) * thisKX;
                var dy = Math.abs(thisDayCenterLat - placeCoordinates.lat) * ky;
                distanceNextFromThis.push(  Math.sqrt(dx * dx + dy * dy) )
            }
            //now find the places in this day that are closer to next day scatter
            let placesIndexesFromThisDayCloserToNext = []
            for (let j =0 ; j<thisDayPlacesList.length ; j++){
              
                if (distanceThisFromThis[j]>distanceThisFromNext[j]){
                    placesIndexesFromThisDayCloserToNext.push(j)
                }
            }

             //now find the places in next day that are closer to this day scatter
             let placesIndexesFromNextDayCloserToThis = []
             for (let j =0 ; j<nextDayPlacesList.length ; j++){
                 if (distanceNextFromThis[j]<distanceNextFromNext[j]){
                    placesIndexesFromNextDayCloserToThis.push(j)
                 }
             }

             let newThisDayList = []
             let newNextDayList = []
             let addedThis = 0;
             let addedNext = 0;
             for (let j =0 ; j<thisDayPlacesList.length ; j++){
                let potentialAnchorCategory =false;
                for (let k=0 ; k<thisDayPlacesList[j].placeCategory.length ;k++){
                    if (potentialAnchorCategories.includes(thisDayPlacesList[j].placeCategory[k])){
                        potentialAnchorCategory = true
                    }
                }
                if (!placesIndexesFromThisDayCloserToNext.includes(j)&&potentialAnchorCategory&&addedThis===0){
                    newThisDayList.push(thisDayPlacesList[j])
                    addedThis+=1
                }
                else{
                    newNextDayList.push(thisDayPlacesList[j])
                }
             }
             for (let j =0 ; j<nextDayPlacesList.length ; j++){
                let potentialAnchorCategory =false;
                for (let k=0 ; k<nextDayPlacesList[j].placeCategory.length ;k++){
                    if (potentialAnchorCategories.includes(nextDayPlacesList[j].placeCategory[k])){
                        potentialAnchorCategory = true
                    }
                }
                if (!placesIndexesFromNextDayCloserToThis.includes(j)&&potentialAnchorCategory&&addedNext===0){
                    newNextDayList.push(nextDayPlacesList[j])
                    addedNext+=1
                }
                else{
                    newThisDayList.push(nextDayPlacesList[j])
                }
             }
             tripPlacesListByDay[i]=[...newThisDayList]
             tripPlacesListByDay[(i+1)]=[...newNextDayList] */
        }

        for (let i=0 ; i<remainingPlaces.length ; i++){
            let placeDistancesSorted =await placesDistancesFromAnchors[remainingPlaces[i].placeID].sort((a, b) => {
                var keyA = a.distance;
                var keyB = b.distance;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            let j=0 
            let placeAdded=false
            while (j<placeDistancesSorted.length&&!placeAdded){
                let day = placeDistancesSorted[j].day
                if (newTripPlacesListByDay[day].length<numberOfPlacesPerDay[day]){
                    newTripPlacesListByDay[day].push(remainingPlaces[i])
                    placeAdded=true
                }
                j++
            }
        }
        resolve(newTripPlacesListByDay)
    });
}

sortPlacesOrderInDay = args =>{
    return new Promise(async (resolve, reject) => {
        let tripDuration = args.tripDuration
        let tripPlacesListByDay = args.tripPlacesListByDay;
        let selectedAccommodation = args.selectedAccommodation;
       
        let sortedTripPlacesListByDay = []
        //for each trip day order the places in logical path
        for (let i=0;i<tripDuration;i++){
           // let sortedDayPlaces = []
             let sortedDayPlaces = []
            let dayPlacesList = tripPlacesListByDay[i]
            let firstPlace = tripPlacesListByDay[i][0]
            if (selectedAccommodation!==null){
                let closestDistance = 1000
                //find the closest place to accommodation
                for (let j=0 ; j<dayPlacesList.length ; j++){
                    let ky = Math.abs(dayPlacesList[j].placeLocation.coordinates.lat-selectedAccommodation.placeLocation.coordinates.lat)
                    let kx = Math.abs(dayPlacesList[j].placeLocation.coordinates.lng-selectedAccommodation.placeLocation.coordinates.lng)
                    if (Math.sqrt(kx*kx+ky*ky)<closestDistance){
                        firstPlace = dayPlacesList[j]
                        closestDistance = Math.sqrt(kx*kx+ky*ky)
                    }
                }
            }
            //now sort the places by next closest and so on
            let remainingPlaces = dayPlacesList.length-1
            sortedDayPlaces.push(firstPlace)
            let placeToCompare = firstPlace
            let includedPlacesIDs = [firstPlace.placeID]
            while(remainingPlaces>0){
                //find the next closest
                let closestDistance = 1000
                let nextPlace
                //find the closest place to accommodation
                for (let j=0 ; j<dayPlacesList.length ; j++){
                    if (!includedPlacesIDs.includes(dayPlacesList[j].placeID)){
                        let ky = Math.abs(dayPlacesList[j].placeLocation.coordinates.lat-placeToCompare.placeLocation.coordinates.lat)
                        let kx = Math.abs(dayPlacesList[j].placeLocation.coordinates.lng-placeToCompare.placeLocation.coordinates.lng)
                        if (Math.sqrt(kx*kx+ky*ky)<closestDistance){
                            nextPlace = dayPlacesList[j]
                            closestDistance = Math.sqrt(kx*kx+ky*ky)
                        }
                    }
                    
                }
                remainingPlaces = remainingPlaces-1
                sortedDayPlaces.push(nextPlace)
                placeToCompare = nextPlace
                includedPlacesIDs.push(nextPlace.placeID)
            }

            //if selected accommdation then for the first day reverse the places order
            if (selectedAccommodation!==null && i===0){
                sortedDayPlaces = sortedDayPlaces.reverse()
            } 
           

            sortedTripPlacesListByDay.push(sortedDayPlaces)
        }
        resolve(sortedTripPlacesListByDay)
    });
}

generateTripIdeasLIst = args =>{
    return new Promise(async (resolve, reject) => {
        let placesToGenerateCount = args.placesToGenerateCount
        let usedPlacesIDs = args.usedPlacesIDs
        let placesList = [...args.placesList]
        
        let placesPrioritizedListIDs = args.placesPrioritizedListIDs

        let placesListObject = {}
        for (let i=0 ; i<placesList.length ; i++){
            placesListObject[placesList[i].placeID] = placesList[i]
        }

        let tripIdeasList = []
        let usedCategoriesID = []
        let j=0;
        let mainAttempts = 0
        while (j<placesToGenerateCount &&mainAttempts<100){
            let randomAttempts = 0;

            let randomIndex = Math.floor(Math.random() * Math.floor(placesPrioritizedListIDs.length))
            let randomPlaceID = placesPrioritizedListIDs[randomIndex]
            let randomPlace = placesListObject[randomPlaceID]
            let oldCategory = false
            for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                    oldCategory = true
                }
            }
            while (oldCategory&&usedPlacesIDs.includes(randomPlace.placeID)&&randomAttempts<200){
                randomAttempts+=1
                randomIndex = Math.floor(Math.random() * Math.floor(placesPrioritizedListIDs.length))
                randomPlaceID = placesPrioritizedListIDs[randomIndex]
                randomPlace = placesListObject[randomPlaceID]
                oldCategory = false
                for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                    if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                        oldCategory = true
                    }
                }
            }
            if (!oldCategory&&randomAttempts<200&&!usedPlacesIDs.includes(randomPlace.placeID)){
                //found a match
                usedPlacesIDs.push(randomPlace.placeID)
                tripIdeasList.push(randomPlace)
                usedCategoriesID = usedCategoriesID.concat(randomPlace.placeCategory)
                j+=1
                randomAttempts=0
                //update daily coordinates
            }
            else{
                mainAttempts+=1
            }
        }
        resolve(tripIdeasList)
    });
}

/* generateDaysAnchorsOld = (args) =>{
    return new Promise(async (resolve, reject) => {
        let placesList = args.placesList
        let userCategoriesScore = args.userCategoriesScore
        let mainCategoriesSelected = args.mainCategoriesSelected
        let tripDuration = args.tripDuration
        let numberOfAnchorsPerDay = args.numberOfAnchorsPerDay
        let dailyRadiusDistance = args.dailyRadiusDistance
        let tripRadiusDistance = args.tripRadiusDistance
        let placesPrioritizedListIDs = args.placesPrioritizedListIDs
        let includedCategories = args.includedCategories
        let tripCenterSelected = args.tripCenterSelected
        let tripCenterCoordinates = args.tripCenterCoordinates

        let ideasList = []
        let anchorsList = [];
        let usedPlacesIDs = []
        let usedCategoriesID = []
        let addedPlaces = []


        let focusedMainCategories = [0,0,0,0,0,0]

        //get first priority main categories
        for (let i=0 ; i<mainCategoriesSelected.length ; i++){
            if (mainCategoriesSelected[i]===2){
                focusedMainCategories[i]=1
            }
        }

        //filter top priority places
        let filteredPlacesList = []
         for (let i=0 ; i<placesList.length ; i++){
            for (let j=0 ; j<focusedMainCategories.length ; j++){
                if (focusedMainCategories[j]===1&&placesList[i].placeMainCategory[j]===1){
                    filteredPlacesList.push(placesList[i])
                }
            }
        } 
        let filteredPlacesListObject = {}
        for (let i=0 ; i<filteredPlacesList.length ; i++){
            filteredPlacesListObject[filteredPlacesList[i].placeID] = filteredPlacesList[i]
        }
        let topPriorityPlacesPrioritizedListIDs = []
        await this.prioritizePlaces(filteredPlacesList,userCategoriesScore,includedCategories).then(result => {
            topPriorityPlacesPrioritizedListIDs = result
          });

        //generate for each day the first anchored place
        //need to take into account the trip radius
        for (let i= 0 ; i <tripDuration ; i++){
            //the first day places selected will limit the trip to be contained in that area
            let dailyBounds = {
                south:0,
                north:0,
                west:0,
                east:0,
            }
            let dayAnchorsList = [];
            let j=0
            let randomAttempts = 0
            while (j<1 &&(j+usedPlacesIDs.length)<filteredPlacesList.length){
                
                //randomizing a place with highest priority
                let randomIndex = Math.floor(Math.random() * Math.floor(topPriorityPlacesPrioritizedListIDs.length))
                let randomPlaceID = topPriorityPlacesPrioritizedListIDs[randomIndex]
                let randomPlace = filteredPlacesListObject[randomPlaceID]
                let oldCategory = false
                for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                    if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                        oldCategory = true
                    }
                }
                while (oldCategory&&usedPlacesIDs.includes(randomPlace.placeID)&&randomAttempts<200){
                    randomAttempts+=1
                    randomIndex = Math.floor(Math.random() * Math.floor(topPriorityPlacesPrioritizedListIDs.length))
                    randomPlaceID = topPriorityPlacesPrioritizedListIDs[randomIndex]
                    randomPlace = filteredPlacesListObject[randomPlaceID]
                    oldCategory = false
                    for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                        if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                            oldCategory = true
                        }
                    }
                }
                if (!oldCategory&&!usedPlacesIDs.includes(randomPlace.placeID)&&randomAttempts<200){
                    
                    usedPlacesIDs.push(randomPlace.placeID)
                    dayAnchorsList.push(randomPlace)
                    usedCategoriesID = usedCategoriesID.concat(randomPlace.placeCategory)
                    j+=1
                    randomAttempts=0

                    //update trip bounds
                    if (i===0){
                        dailyBounds = {
                            south:randomPlace.lat-(tripRadius/100)*Math.sqrt(2),
                            north:randomPlace.lat+(tripRadius/100)*Math.sqrt(2),
                            west:randomPlace.lng-(tripRadius/70)*Math.sqrt(2),
                            east:randomPlace.lng+(tripRadius/70)*Math.sqrt(2),
                        }
                    }
                    else{
                        let maxSouth = dayAnchorsList[0].placeLocation.coordinates.lat
                        let maxNorth = dayAnchorsList[0].placeLocation.coordinates.lat
                        let maxWest = dayAnchorsList[0].placeLocation.coordinates.lng
                        let maxEast = dayAnchorsList[0].placeLocation.coordinates.lng
                        for (let k = 0 ; k<dayAnchorsList.length ; k++){
                            if (dayAnchorsList[k].placeLocation.coordinates.lat<maxSouth){
                                maxSouth = dayAnchorsList[k].placeLocation.coordinates.lat
                            }
                            if (dayAnchorsList[k].placeLocation.coordinates.lat>maxNorth){
                                maxNorth = dayAnchorsList[k].placeLocation.coordinates.lat
                            }
                            if (dayAnchorsList[k].placeLocation.coordinates.lng<maxWest){
                                maxWest = dayAnchorsList[k].placeLocation.coordinates.lng
                            }
                            if (dayAnchorsList[k].placeLocation.coordinates.lng>maxEast){
                                maxEast = dayAnchorsList[k].placeLocation.coordinates.lng
                            }

                        }
                        dailyBounds = {
                            south:maxNorth-(dailyRadiusDistance/100)*Math.sqrt(2),
                            north:maxSouth+(dailyRadiusDistance/100)*Math.sqrt(2),
                            west:maxEast-(dailyRadiusDistance/70)*Math.sqrt(2),
                            east:maxWest+(dailyRadiusDistance/70)*Math.sqrt(2),
                        }
                    }
                }
            }
            anchorsList.push(dayAnchorsList)
           
        }

        //per each day generate the additional anchored places
        if (numberOfAnchorsPerDay>1){
            for (let i= 0 ; i <tripDuration ; i++){
                if((i+1) % 3 == 0){
                    //for every 3 days of the trip you can reuse the places categories
                    usedCategoriesID = []
                }
                let anchoredCoordinates = anchorsList[i][0].placeLocation.coordinates //the first place that was anchored
                let dailyBounds = {
                    south:anchoredCoordinates.lat-(dailyRadiusDistance/100)*Math.sqrt(2),
                    north:anchoredCoordinates.lat+(dailyRadiusDistance/100)*Math.sqrt(2),
                    west:anchoredCoordinates.lng-(dailyRadiusDistance/70)*Math.sqrt(2),
                    east:anchoredCoordinates.lng+(dailyRadiusDistance/70)*Math.sqrt(2),
                }
                let dayAnchorsList = [...anchorsList[i]];
                let j=1
                let randomAttempts = 0
                let mainAttempts = 0
                while (j<numberOfAnchorsPerDay &&mainAttempts<100){
                    randomAttempts = 0;

                    let randomIndex = Math.floor(Math.random() * Math.floor(topPriorityPlacesPrioritizedListIDs.length))
                    let randomPlaceID = topPriorityPlacesPrioritizedListIDs[randomIndex]
                    let randomPlace = filteredPlacesListObject[randomPlaceID]

                    let randomPlaceCoordinates = randomPlace.placeLocation.coordinates
                    let randomPlaceOutOfBounds = true
                    if (randomPlaceCoordinates.lat<dailyBounds.north&&
                        randomPlaceCoordinates.lat>dailyBounds.south&&
                        randomPlaceCoordinates.lng>dailyBounds.west&&
                        randomPlaceCoordinates.lng<dailyBounds.ast){
                            randomPlaceOutOfBounds = false
                    }
                    let oldCategory = false
                    for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                        if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                            oldCategory = true
                        }
                    }
                    while (oldCategory&&randomPlaceOutOfBounds&&usedPlacesIDs.includes(randomPlace.placeID)&&randomAttempts<200){
                        randomAttempts+=1
                        randomIndex = Math.floor(Math.random() * Math.floor(topPriorityPlacesPrioritizedListIDs.length))
                        randomPlaceID = topPriorityPlacesPrioritizedListIDs[randomIndex]
                        randomPlace = filteredPlacesListObject[randomPlaceID]
                        randomPlaceCoordinates = randomPlace.placeLocation.coordinates
                        if (randomPlaceCoordinates.lat<dailyBounds.north&&
                            randomPlaceCoordinates.lat>dailyBounds.south&&
                            randomPlaceCoordinates.lng>dailyBounds.west&&
                            randomPlaceCoordinates.lng<dailyBounds.east){
                                randomPlaceOutOfBounds = false
                        }
                        oldCategory = false
                        for (let k=0 ; k<randomPlace.placeCategory.length ; k++){
                            if (usedCategoriesID.includes(randomPlace.placeCategory[k])){
                                oldCategory = true
                            }
                        }
                    }
                    if (!oldCategory&&randomAttempts<200&&!usedPlacesIDs.includes(randomPlace.placeID)&&!randomPlaceOutOfBounds){
                        //found a match
                        usedPlacesIDs.push(randomPlace.placeID)
                        dayAnchorsList.push(randomPlace)
                        usedCategoriesID = usedCategoriesID.concat(randomPlace.placeCategory)
                        j+=1
                        randomAttempts=0
                        //update daily coordinates
                        let maxSouth = dayAnchorsList[0].placeLocation.coordinates.lat
                        let maxNorth = dayAnchorsList[0].placeLocation.coordinates.lat
                        let maxWest = dayAnchorsList[0].placeLocation.coordinates.lng
                        let maxEast = dayAnchorsList[0].placeLocation.coordinates.lng
                        for (let k = 0 ; k<dayAnchorsList.length ; k++){
                            if (dayAnchorsList[k].placeLocation.coordinates.lat<maxSouth){
                                maxSouth = dayAnchorsList[k].placeLocation.coordinates.lat
                            }
                            if (dayAnchorsList[k].placeLocation.coordinates.lat>maxNorth){
                                maxNorth = dayAnchorsList[k].placeLocation.coordinates.lat
                            }
                            if (dayAnchorsList[k].placeLocation.coordinates.lng<maxWest){
                                maxWest = dayAnchorsList[k].placeLocation.coordinates.lng
                            }
                            if (dayAnchorsList[k].placeLocation.coordinates.lng>maxEast){
                                maxEast = dayAnchorsList[k].placeLocation.coordinates.lng
                            }

                        }
                        dailyBounds = {
                            south:maxNorth-(dailyRadiusDistance/100)*Math.sqrt(2),
                            north:maxSouth+(dailyRadiusDistance/100)*Math.sqrt(2),
                            west:maxEast-(dailyRadiusDistance/70)*Math.sqrt(2),
                            east:maxWest+(dailyRadiusDistance/70)*Math.sqrt(2),
                        }
                    }
                    else{
                        mainAttempts+=1
                    }
                }
               
                anchorsList[i]= dayAnchorsList

            }
        }
        for (let i=0 ; i <anchorsList.length ; i++){
            addedPlaces = addedPlaces.concat(anchorsList[i])
        }
       let result = {
        anchorsListByDay: anchorsList,
        addedPlaces: addedPlaces
       }
        resolve(result);
    });
}
 */



createTrip = (tripPlanDays,tripNumber,tripDuration,selectedAccommodation) =>{
    return new Promise(async (resolve, reject) => {
        let date = new Date();
        let timestamp = date.getTime()+tripNumber;
        let planPlacesIDs=[]
        let planDates={startDate:timestamp,endDate:timestamp}

        let planIDPrefix = (timestamp).toString()
        let planID = planIDPrefix+"_"+this.props.user.userID;
        let planName = ""
        let participants = {adults:1,kids:0,toddler:0};
        let imagePreview = []
        let imageGallery = []
        let owner = {
            ownerID: this.props.user.userID,
            ownerName: this.props.user.displayName,
            ownerPhoto: this.props.user.photoUrl,
            ownerEmail: this.props.user.userEmail
        };
        let newPlanDays=[{name:"Interesting",dayItems:[], selected:true}];
        let planDuration = tripDuration
        for (let j = 0; j < planDuration; j++) {
    
            let dayName="Day " +(j+1)
            newPlanDays.push({name:dayName,dayItems:[], selected:false}); 
        }
        for (let j=0; j<tripPlanDays.length ; j++){
            newPlanDays[j].selected=true;
            newPlanDays[j].dayItems=[];
            let tripDayPlaces = tripPlanDays[j]
            for(let k=0; k<tripDayPlaces.length ; k++){
                let place = {
                    placeID:tripDayPlaces[k].placeID,
                    placeColorNumber:0,
                    placeVote: [],
                    placeCountry:  tripDayPlaces[k].placeLocation.locationTree.placeCountry
                }
                planPlacesIDs.push(place.placeID)
                let dayItem={type:"place",item:place}
                newPlanDays[j].dayItems.push(dayItem)
            }
            if (j<tripPlanDays.length-1&&j!==0){
                //add accommodation to the trip days do it to all days except the rest
                if (selectedAccommodation!==null){
                    let dayItem={type:"accommodation",item:selectedAccommodation}
                    newPlanDays[j].dayItems.push(dayItem)
                }
                
            }
        }         
        let editorUser = {...this.props.user};
        editorUser.planLastModified=timestamp;
        let planEditors =[editorUser];

        let tripStats = {
        viewed: { count: 0, users: [] },
        inspired: { count: 0, users: [] },
        cloned: {count: 0, users:[]}
        };
        let plan={
            planID: planID,
            isPublic:false,
            planLastModified: timestamp,
            planCreationDate: timestamp,
            planName:planName,
            planDates:planDates,
            planDuration:planDuration,
            participants:participants,
            planDays:newPlanDays,
            planPlacesIDs:planPlacesIDs,
            imagePreview:imagePreview,
            imageGallery:imageGallery,
            planTasks:[],
            owner:owner,
            planEditors:planEditors,
            noDatesSelected: true,
            planSecurity:"private",
            albumSecurity:{[this.props.user.userID]:"private"},
            tripStats:tripStats
        }
        resolve(plan)
    });
}


  
    renderProcessing = () => {
     let width= (2*this.state.progress)+"%"
   
    if (this.state.processing){
      return (
        <div className={classes.Saving}>
          <img src={saving} alt="" className={classes.SavingGif} /> 
       {/*  <div className={classes.ProgressBar}>
                <div  style={{width:width}} className={classes.Progress}/>
            </div>  */}
        </div>
      );
    } 
  };
  

render(){
   
        return <div  /* className={classes.Body} */ >{this.renderProcessing()}</div>;

}
    
}
export default TripTemplateCreator;