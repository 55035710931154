import React, { Component } from "react";
import classes from "./AddToPlan.module.css";

import ModalTopSection from "../../UI/Misc/ModalTopSection"
import PlanPlaceItem from "../Plan/PlanPlaceItem"
import PlansWall  from "../../../Containers/Content/TripPlans/PlansWall"
import ExperiencesWall from "../../../Containers/Content/Experiences/ExperiencesWall" 
import PlanDaysScroller from "../Plan/PlanDaysScroller"
import ConfirmationModal from "../../UI/Modals/ConfirmationModal"
import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop"

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";

let  dropdownIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDetail.png?alt=media&token=2945590b-6232-4f58-afbe-358781245f57";
let collapseIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCollapse.png?alt=media&token=0a19f67c-7fad-4e02-8449-20d7e13e615c";

var locale = window.navigator.userLanguage || window.navigator.language;
const moment = require('moment');
moment.locale(locale);


class AddToPlan extends Component {
    state={
        planIsSelected:false,
        dayIsSelected:false,
        filteredPlans:[],
        planName:"",
        experienceOutOfDate:false,
        placeAddedToDay:false
    }

    componentDidMount = () =>{
        if (this.props.objectType==="Experience"){
            this.setState({updatedExperience:this.props.selectedObject})
        }
    }

    backdropHandler = () =>{
        this.setState({showBackdrop:false,
            filteredPlans:[],
            planName:""
        })
    }

    finishLoadingSelectedPlan = async () =>{
        await this.setState({selectedPlan:this.props.selectedPlan})
        this.structurePlanDays()
    }


    renderTopSection = () =>{
        let title = "";
        if (this.props.objectType==="Place"){
            title = this.props.systemText.extraModals.addToPlan.placeTitle[this.props.userLanguage];
        }
        else if (this.props.objectType==="Experience"){
            title = this.props.systemText.extraModals.addToPlan.experienceTitle[this.props.userLanguage];
        }
        
        let buttonName = this.props.systemText.extraModals.addToPlan.button[this.props.userLanguage];
        let saveReady = false;
        if (this.state.planIsSelected
        ){ 
            if (this.props.objectType==="Experience"){
                if (!this.state.experienceOutOfDate){
                    saveReady=true;
                }
            }
            else{
                if (this.state.placeAddedToDay||this.state.placeRemoved){
                    saveReady=true;
                }
            }
         }
        return (
          <div className={classes.TopSection}>
                <ModalTopSection
                saveReady={saveReady}
                title={title}
                discard={this.props.discard}
                buttonText={buttonName}
                clicked={this.finishHandler}
                showButton={true}
                RTL = {this.props.RTL}
                modalView = {true}
                />
           
          </div>
        );
    }

    finishHandler = () =>{
        if (this.props.objectType==="Experience"){
            this.props.connectExperienceToPlan(this.state.selectedPlan.planID,this.state.updatedExperience)
            this.props.discard();
        }
        else if (this.props.objectType==="Place"){
            let date = new Date();
            let timestamp = date.getTime();
            let planLastModified= timestamp;
            let planDays = this.state.selectedPlan.planDays;
            for (let i=0;i<planDays.length ; i++){
                if (planDays[i].dayItems!==undefined){
                    for (let j=0 ; j<planDays[i].dayItems.length ; j++){
                        if (planDays[i].dayItems[j].type==="place"){
                        let place
                        
                            place = {
                                placeID:planDays[i].dayItems[j].item.placeID,
                                placeColorNumber:planDays[i].dayItems[j].item.placeColorNumber,
                                placeVote: planDays[i].dayItems[j].item.placeVote,
                                placeCountry:  planDays[i].dayItems[j].item.placeCountry
                            }
                            if (planDays[i].dayItems[j].item.placeTime!==undefined){
                                if (planDays[i].dayItems[j].item.placeTime instanceof Date){
                                    place.placeTime = new Date(planDays[i].dayItems[j].item.placeTime.getTime()).getTime();
                                }
                            }
                            if (planDays[i].dayItems[j].item.placeExperiences!==undefined){
                                place.placeExperiences = planDays[i].dayItems[j].item.placeExperiences
                            }
                            planDays[i].dayItems[j].item=place;
                        }
                    }
                }
            }
    
            let plan = {
                ownerID:this.props.selectedPlan.owner.ownerID,
                planID:this.state.selectedPlan.planID,
                planLastModified:planLastModified,
                planDays:planDays,
                planPlacesIDs:this.state.selectedPlan.planPlacesIDs
            }
           /*  console.log(plan) */
           this.props.updatePlanDays(plan)
           this.props.discard()
        }
       
    }

    unselectPlanHandler = () =>{
        this.setState({planIsSelected: false,dayIsSelected:false})
        this.props.resetSelectedPlan()
    }

    newPlanHandler = () =>{
        this.props.newPlanHandler();
        this.props.discard();
    }

    updateColorHandler = async (colorNumber,dayIndex,itemIndex)=>{
        let planDays=this.state.planDays;
        planDays[dayIndex].dayItems[itemIndex].item.placeColorNumber=colorNumber
        await this.setState({planDays:planDays});
      }

    structurePlanDays = async () =>{
        let plan = this.state.selectedPlan
        let planDays=plan.planDays;
        for (let i=0; i<planDays.length ; i++){
            if (planDays[i].dayItems!==undefined){
                for(let j=0; j<planDays[i].dayItems.length ; j++){
                //now handling only places
                if (planDays[i].dayItems[j].type==="place"){
                    const placeIndex = this.props.planPlacesList.findIndex(place => {
                        return place.placeID === planDays[i].dayItems[j].item.placeID;
                      });
                    let place = JSON.parse(JSON.stringify(this.props.planPlacesList[placeIndex]));
                    let placeColorNumber=planDays[i].dayItems[j].item.placeColorNumber;
                    let placeVote = planDays[i].dayItems[j].item.placeVote;
                    let placeExperiences = planDays[i].dayItems[j].item.placeExperiences
                    let placeCountry = planDays[i].dayItems[j].item.placeCountry
                    if (placeCountry===undefined){
                        place.placeCountry=place.placeLocation.locationTree.locationTreeIDs[0].split('_')[0]
                      }
                      else{
                        place.placeCountry=placeCountry
                      }
                    if (placeColorNumber===undefined){
                      place.placeColorNumber=0;
                    }
                    else{
                      place.placeColorNumber=placeColorNumber;
                    }
                    if (placeVote===undefined){
                      place.placeVote=[];
                    }
                    else{
                      place.placeVote=[...placeVote];
                    }
                    if (placeExperiences===undefined){
                        place.placeExperiences={};
                    }
                    else{
                        place.placeExperiences=placeExperiences;
                    }
                    planDays[i].dayItems[j].item = place
                  }
                  else{}
                }
            }
            else{
                planDays[i].dayItems=[];
            }
        }
        plan.planDays=planDays;
        await this.setState({planIsSelected: true,selectedPlan:plan})
    }

    selectPlanHandler = async plan =>{
        this.props.selectPlanHandler(plan)
        await this.setState({showSuggestions:false,planName:"",filteredPlans:[],showBackdrop:false})

        //for experiences, handle plan date
        if (this.props.objectType==="Experience"){
            let experienceDate =  moment(new Date(this.props.selectedObject.experienceDate));
            let planStartDate = moment(new Date(plan.planDates.startDate))
            let planEndDate = moment(new Date(plan.planDates.endDate))
            if(planEndDate.diff(experienceDate,'days')>=0&&experienceDate.diff(planStartDate,'days')>=0){
                
            }
            else{
                //not in zone
                this.setState({experienceOutOfDate:true,
                    experienceDate:experienceDate,
                    planStartDate:planStartDate,
                    planEndDate:planEndDate
                })
            }
        }
    }

    updateExperienceDate = async date =>{
        await this.setState({experienceDate:date})
        let planStartDate=this.state.planStartDate;
        let planEndDate = this.state.planEndDate
        if(planEndDate.diff(this.state.experienceDate,'days')>=0&&this.state.experienceDate.diff(planStartDate,'days')>=0){
            this.setState({experienceDateInRange:true})
        }
    }

    updateExperience = () =>{
        let experience = this.props.selectedObject;
        experience.experienceDate = new Date(this.state.experienceDate.toDate().getTime()).getTime(); 
        this.setState({updatedExperience:experience, experienceOutOfDate:false}); 
    }

    selectPlanDayHandler = day =>{
        let planDaysNames = this.state.selectedPlan.planDays.map(day=>{return day.name})
        const dayIndex = planDaysNames.findIndex(dayName => {
            return dayName ===day.title;
          });
        let selectedDay = this.state.selectedPlan.planDays[dayIndex];
        this.setState({dayIsSelected:true,daySelected:selectedDay});
    }

    addPlaceToDay = async index =>{
        let place=JSON.parse(JSON.stringify( this.props.selectedObject));
        place.addedPlace=true;
        place.placeColorNumber=0;
        place.placeVote=[];
        place.placeCountry = place.placeLocation.locationTree.locationTreeIDs[0].split('_')[0]
        let dayItem={type:"place",item:place}
        let selectedPlan = this.state.selectedPlan;
        let planDays = this.state.selectedPlan.planDays;
        if (planDays[index].dayItems!==undefined){
            let placeExist=false;
            for (let i=0 ;i<planDays[index].dayItems.length ; i++ ){
                if (planDays[index].dayItems[i].type==="place"){
                    if (planDays[index].dayItems[i].item.placeID===place.placeID){
                        placeExist=true;
                    }
                }
            }
            if (!placeExist){
                planDays[index].dayItems.push(dayItem)
            }
        }
        else{
            planDays[index].dayItems=[dayItem];
        }
        selectedPlan.planDays=planDays;
        let planPlacesIDs = [];
        if (this.state.selectedPlan.planPlacesIDs!==undefined){
            planPlacesIDs = this.state.selectedPlan.planPlacesIDs
        }
        if (!planPlacesIDs.includes(place.placeID)){
          planPlacesIDs.push(place.placeID);
          let planPlacesList = this.props.planPlacesList
            planPlacesList.push(place)
            await this.props.updatePlanPlacesList(planPlacesList)
        }
        selectedPlan.planPlacesIDs=planPlacesIDs;
        this.setState({selectedPlan:selectedPlan,placeAddedToDay:true})
    }

    removeFromPlan = (dayIndex,itemIndex) =>{
        this.setState({
          confirmMessage: this.props.systemText.extraModals.confirmationModal.deleteMessage[this.props.userLanguage],
          primaryBtn: this.props.systemText.extraModals.confirmationModal.cancel[this.props.userLanguage],
          secondaryBtn: this.props.systemText.extraModals.confirmationModal.delete[this.props.userLanguage],
          confirmMode: true,
          showBackdrop: true,
          optionsTools: false,
          removeDayIndex : dayIndex,
          removeItemIndex: itemIndex
        });
      }
  
  
      confirmDelete = () => {
        this.removeFromPlanHandler()
        this.setState({
          confirmMode: false,
          showBackdrop: false
        });
      };
      
      discardDelete = () => {
        this.setState({
          confirmMode: false,
          showBackdrop: false
        });
      };

    removeFromPlanHandler = async () =>{
        let dayIndex = this.state.removeDayIndex;
        let itemIndex = this.state.removeItemIndex
        let selectedPlan = this.state.selectedPlan;
        let planDays = this.state.selectedPlan.planDays;
        let planPlacesIDs = this.state.selectedPlan.planPlacesIDs;
        let item = planDays[dayIndex].dayItems[itemIndex];
        planDays[dayIndex].dayItems.splice(itemIndex,1);
        await this.setState({planDays:planDays});
        let placeID = item.item.placeID;
        let exist = false;
        for (let i=0 ; i<planDays.length ; i++){
            if (planDays[i].dayItems!==undefined){
            for (let j=0 ; j<planDays[i].dayItems.length ; j++){
                if (planDays[i].dayItems[j].type==="place"){
                if (planDays[i].dayItems[j].item.placeID===placeID){
                    exist = true;
                }
                }
            }
            }
            
        }
        if (!exist){
            const index = planPlacesIDs.findIndex(planPlaceID => {
            return planPlaceID === placeID;
            });
            planPlacesIDs.splice(index,1);
            await this.setState({planPlacesIDs:planPlacesIDs})
        }
        selectedPlan.planPlacesIDs=planPlacesIDs;
   
        selectedPlan.planDays = planDays
        this.setState({selectedPlan:selectedPlan,placeRemoved:true})
    }


    updateDayOrder = (dayIndex,dayItems) =>{
        let planDays = this.state.selectedPlan.planDays;
        let selectedPlan = this.state.selectedPlan;
        planDays[dayIndex].dayItems=[...dayItems]
        selectedPlan.planDays=planDays;
        this.setState({selectedPlan:selectedPlan})
    }

    planInputHandler = async event => {
        let inputValue = event.target.value;
        //In case user pressed 'esc' key, clean the input field
        if (event.keyCode === 27) {
          this.setState({
            planName: "",
            showSuggestions: false,
            showTransparentBackdrop: false,
            filteredPlans: []
          });
          return;
        }
        //In case user pressed 'enter' key, add the tag to experience tags array
        //Use the selectTagSuggestion function for that
        if (event.key === "Enter") {
          //In case user pressed 'enter' with blank input, do nothing
          if (inputValue === "") return;
          if (this.state.filteredPlans.length > 0) {
            this.selectSearchSuggestion(this.state.filteredPlans[0]);
          }
          return;
        } else {
          //Get suggestions for user existing tags based in sub-input
          let filteredPlans = this.getPlanSuggestions(inputValue);
          this.setState({ planName: inputValue });
          //In case user entered some characters, the suggestions should be shown
          if (inputValue != "" && filteredPlans.length != 0) {
            this.setState({
              showSuggestions: true,
              showTransparentBackdrop: true,
              filteredPlans: filteredPlans
            });
    
            //If no suggetions exist based on entered value, don't show the suggestions
          } else if (filteredPlans.length === 0) {
            this.setState({
              showSuggestions: false,
              showTransparentBackdrop: false,
              filteredPlans: []
            });
          } else {
            //Input is blank
            this.setState({
              showSuggestions: false,
              showTransparentBackdrop: false,
              filteredPlans: []
            });
          }
        }
      };

      getPlanSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        
        return inputLength === 0
          ? []
          : this.props.userPlans.filter(
            plan =>
            plan.planName.toLowerCase().includes(inputValue)
            );
      };

      blurHandler = () =>{

      }

    renderSelectedPlace = () =>{
        if (this.props.objectType==="Place"){
            
            let imagePreview ={url:""}
            //distinguish between slim place or full data place based on window that was clicked
            if (Array.isArray(this.props.selectedObject.imagePreview)){
                imagePreview = this.props.selectedObject.imagePreview[0]
            }
            else{
                imagePreview = this.props.selectedObject.imagePreview
            }
            return (<div className={classes.SelectedPlace}>
                    <PlanPlaceItem
                       placeName={this.props.selectedObject.placeName}
                       placeID={this.props.selectedObject.placeID}
                       placeCategory={this.props.selectedObject.placeCategory}
                       placeLocation = {this.props.selectedObject.placeLocation}
                       imagePreview={imagePreview}
                       placeStatus = {this.props.selectedObject.placeStatus}
                       addToPlan= {true}

                       categoriesTranslator = {this.props.categoriesTranslator}
                         locationsTranslator = {this.props.locationsTranslator}
                         RTL = {this.props.RTL}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                    />
            </div>)
        }
    }

     renderSelectedExperience = () =>{
        if (this.props.objectType==="Experience"){
            return (<div className={classes.SelectedExperience}>
                    <ExperiencesWall
                        experiencesList={[this.props.selectedObject]}
                        user={this.props.user}
                        board={"SelectPlan"}
                        viewHandler={()=>{return null}}
                        deleteHandler={()=>{return null}}
                        editHandler={()=>{return null}}
                        viewExperiencePlace={()=>{return null}}
                        selectedContentWall={this.props.selectedContentWall}
                        addToPlan={()=>{return null}}

                        RTL = {this.props.RTL}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                    />
            </div>)
        }
    } 

    renderSelectPlan = () =>{
      
        if (this.state.planIsSelected&&this.state.selectedPlan!==undefined && this.state.selectedPlan!==null){
            return(<div className={classes.PlanTop}>
                 <div className={classes.ResetPlace} onClick={this.unselectPlanHandler}>
                    {this.props.systemText.extraModals.addToPlan.resetPlan[this.props.userLanguage]}
                </div>
                <div className={classes.SelectedPlan}>
                <PlansWall
                        plansList={[this.state.selectedPlan]}
                        viewHandler={()=>{return null}}
                        selectedContentWall={"AddToPlan"}
                        hideTools={true}
                        viewOnly={true}

                        RTL = {this.props.RTL}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                    />
                    </div>
               
               
            </div>)
        }
        else{
            return(
            <div className={classes.PlanSelectionSection}>
                 <TransparentBackdrop show={this.state.showBackdrop} clicked={this.backdropHandler}/>
                 <div className={classes.CreateNewPlan} onClick={this.newPlanHandler}>
                        {this.props.systemText.extraModals.addToPlan.createNewPlan[this.props.userLanguage]}
                    </div>
                  <div className={classes.PlanSelectSection}>
                    <input
                        value={this.state.planName}
                        onChange={this.planInputHandler}
                        onKeyDown={this.planInputHandler}
                        onKeyPress={this.planInputHandler}
                        placeholder= {this.props.systemText.extraModals.addToPlan.selectPlanPlaceholder[this.props.userLanguage]}
                        className={classes.InputText}
                        onBlur={this.blurHandler}
                    />
                     {this.renderPlanSelectionDropdownIcon()}
                   
                </div>
                {this.state.filteredPlans.length > 0 ? (
                    <div className={classes.Suggestions}>
                    <PlansWall
                        plansList={this.state.filteredPlans}
                        viewHandler={this.selectPlanHandler}
                        selectedContentWall={"SelectPlan"}
                        hideTools={true}
                        viewOnly={true}

                        RTL = {this.props.RTL}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                    />
                    </div>
                ) : null}
            </div>)
                }
    }

    renderPlanSelectionDropdownIcon = () =>{
        let dropdownButtonClass = "DropdownButton"
        if (this.props.RTL){
          dropdownButtonClass = "DropdownButtonRTL"
        }
        if (this.state.filteredPlans.length === 0){
            return(
                <img alt="" className={classes[dropdownButtonClass]} 
                    onClick={()=>{this.setState({filteredPlans:this.props.userPlans,showBackdrop:true})}}
                    src={dropdownIcon}/>
            )
        }
        else if(this.state.planName===""){
            return(
                <img alt="" className={classes[dropdownButtonClass]} 
                    onClick={this.backdropHandler}
                    src={collapseIcon}/>
            )
        }
    }

    renderSelectDay = () =>{
        if (this.state.planIsSelected&&this.props.objectType==="Place"){
            return(<div className={classes.DaySelectionSection}>
                <div className={classes.ChooseMessage}>
                    {this.props.systemText.extraModals.addToPlan.chooseDay[this.props.userLanguage]}
                </div>
                      <PlanDaysScroller
                      planLastModified = {this.props.selectedPlan.planLastModified}
                        externalAdd={true}
                        externalPlaceAdd={this.props.objectType==="Place"}
                        updateColor={()=>{return null}}
                        voteHandler={()=>{return null}}
                        editMode={true}
                        addToPlan={true}
                        updateDayOrder={this.updateDayOrder}
                        planDays={this.state.selectedPlan.planDays}
                        viewHandler={()=>{return null}}
                        hoverHandler={()=>{return null}}
                        addPlaceToDay={this.addPlaceToDay}
                        removeFromPlan = {this.removeFromPlan}

                        writeAcitivityLog = {this.props.writeAcitivityLog}
                        
                        categoriesTranslator = {this.props.categoriesTranslator}
                        locationsTranslator = {this.props.locationsTranslator}
                        RTL = {this.props.RTL}
                        userLanguage={this.props.userLanguage}
                        systemText={this.props.systemText}
                />
            </div>)
        }
    }

    renderExperienceOutOfDate = () =>{
        if (this.state.experienceOutOfDate){
            return(
                <div className={classes.ExperienceDateMessage}>
                    <div className={classes.Message}>
                        { this.props.systemText.extraModals.addToPlan.experienceDateMessage[this.props.userLanguage]}
                    </div>
                    <div className={classes.DateSection}>
                    {this.renderDate()}
                    {this.state.experienceDateInRange?(
                          <div className={classes.UpdateDate} onClick={this.updateExperience}>
                          { this.props.systemText.extraModals.addToPlan.updateDate[this.props.userLanguage]}
                          </div>
                    ):(
                        <div className={classes.UpdateDateFade}>
                        { this.props.systemText.extraModals.addToPlan.updateDate[this.props.userLanguage]}
                        </div>
                    )}
                    </div>
                </div>
            )
        }
    }

    renderDate=()=>{
        let orientation="horizontal"
        /* if (isMobile){
          orientation="vertical"
        } */
        let isRTL=false
        let direction="rtl"
        let anchorDirection="left"
        if (this.props.userLanguage==="Hebrew"){
            anchorDirection ="right"
            direction="ltr"
            isRTL=true;
            
        }
        return(
            <div className={classes.Date}>
            <SingleDatePicker
            placeholder={"set date"}
            date={this.state.experienceDate} // momentPropTypes.momentObj or null
            onDateChange={date => this.updateExperienceDate(date)} // PropTypes.func.isRequired
            focused={this.state.focused} // PropTypes.bool
            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
            id="experience" // PropTypes.string.isRequired,
            orientation={orientation} 
            verticalHeight={568}
              horizontalMargin={0}
              noBorder={true}
              small={true}
              numberOfMonths={1}
              anchorDirection={anchorDirection}
              isOutsideRange={(day)=>{if (!this.state.planIsSelected){
                return false
                }
                else{
            
                if(this.state.planEndDate.diff(day)>=0&&
                day.diff(this.state.planStartDate)>=0){
                    return false
                }
                else return true
                }}}
                isRTL
            /></div>
        )
    }

    renderBody = () =>{
        return(<div className={classes.Body}>
            {this.renderSelectedPlace()}
            {this.renderSelectedExperience()}
            {this.renderExperienceOutOfDate()} 
            {this.renderSelectPlan()}
            {this.renderSelectDay()}
        </div>)
    }

    renderConfirmModal = () =>{
        if (this.state.confirmMode) {
          return (
            <ConfirmationModal
              header={this.props.systemText.extraModals.confirmationModal.title[this.props.userLanguage]}
              primaryAction={this.discardDelete}
              secondaryAction={this.confirmDelete}
              message={this.state.confirmMessage}
              primaryBtn={this.state.primaryBtn}
              secondaryBtn={this.state.secondaryBtn}
              primaryStyle = {"DiscardButton"}
              secondaryStyle = {"DeleteButton"}
              RTL = {this.props.RTL}
  
            />
          );
        }
    }

    render(){
        let modalClass = ""
        if (this.props.RTL){
          modalClass = "ModalRTL"
        }
        return(
           
            <div className={[classes.Modal,classes[modalClass]].join(" ")}>
           
            {this.renderConfirmModal()}
            {this.renderTopSection()}
            {this.renderBody()}
        </div>)
    }
}

export default AddToPlan;