import React from "react";
import classes from "./Tab.module.css";

const tab = props => (
  <div
    className={[classes[props.tabType]].join(" ")}
    onClick={props.clicked}
  >
    {props.text}
  </div>
);

export default tab;
