import React, { Component } from "react";

import classes from "./OpenHoursPreview.module.css";

import TransparentBackdrop from "../../UI/Backdrop/TransparentBackdrop";

let closeDetailsIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCollapse.png?alt=media&token=0a19f67c-7fad-4e02-8449-20d7e13e615c"; 
let detailIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FDetail.png?alt=media&token=2945590b-6232-4f58-afbe-358781245f57"; 
let hoursIcon = "https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FContentObjects%2FCategories%2FOpenHoursIcon.png?alt=media&token=ccf41a0f-afe9-48e5-81dd-f98ef5455213"; 


const moment = require('moment');

class OpenHoursPreview extends Component {

    state={
        showHoursList: false,
        showBackdrop:false  
    }

     
    componentWillMount = () =>{
      this.setHoursPreview()
      
  }

  componentDidUpdate=(prevProps)=>{
    if (this.props.openHours!==prevProps.openHours){
      this.setHoursPreview()
    }
  }

  setHoursPreview = () =>{
    let today = new Date();
        let daysArray = [];
        daysArray.push(today)
        let day2  = new Date();
        day2.setDate(day2.getDate() + 1);
        let day3 = new Date();
        day3.setDate(day3.getDate() + 2);
        let day4  = new Date();
        day4.setDate(day4.getDate() + 3);
        let day5  = new Date();
        day5.setDate(day5.getDate() + 4);
        let day6  = new Date();
        day6.setDate(day6.getDate() + 5);
        let day7  = new Date();
        day7.setDate(day7.getDate() + 6);
        daysArray.push(day2)
        daysArray.push(day3)
        daysArray.push(day4)
        daysArray.push(day5)
        daysArray.push(day6)
        daysArray.push(day7)
       /*  tomorrow.setDate(tomorrow.getDate() + 1);
        for (let i=0; i<6 ; i++){
          daysArray.push( Object.create(tomorrow))
          tomorrow.setDate(tomorrow.getDate() + 1);
        } */
        let daysOpenHours=[];
        for (let i=0;i<daysArray.length ; i++){
          let newDay = {dayOfWeek:daysArray[i].getDay()}
          daysOpenHours[i]=newDay
          let dayOpenningHoursIndex = -1;
          let differentTimesCount = 0;
          for (let j=0 ; j<this.props.openHours.length ; j++){
            if (this.props.openHours[j].selectedDays[daysArray[i].getDay()]===1){
              dayOpenningHoursIndex=j
              differentTimesCount+=1;
            }
          }
          if (differentTimesCount>1){
            for (let j=0 ; j<this.props.openHours.length ; j++){
              if (this.props.openHours[j].selectedDays[daysArray[i].getDay()]===1&&
              this.props.openHours[j].specialDates!==undefined){
                let currentDate = new Date(daysArray[i])
                currentDate.setFullYear(2020);
                currentDate.setMinutes(0);
                currentDate.setHours(0);
                currentDate.setSeconds(0);
                currentDate.setMilliseconds(0);
                let startDate = new Date(this.props.openHours[j].specialDates.startDate);
                startDate.setFullYear(2020);
                startDate.setMinutes(0);
                startDate.setHours(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                let endDate = new Date(this.props.openHours[j].specialDates.endDate);
                endDate.setFullYear(2020);
                endDate.setMinutes(0);
                endDate.setHours(0);
                endDate.setSeconds(0);
                endDate.setMilliseconds(0);
                if (currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime()){
                  dayOpenningHoursIndex=j
                }
              }
            }
          }
          if (dayOpenningHoursIndex===-1){
            daysOpenHours[i].openHours=["Not specified"]
          }
          else{
            if (this.props.openHours[dayOpenningHoursIndex].isClosed){
              daysOpenHours[i].openHours=["Closed"]
            }
            else if (this.props.openHours[dayOpenningHoursIndex].open24){
              daysOpenHours[i].openHours=["Open 24 hours"]
            }
            else{
              daysOpenHours[i].openHours=[]
              for (let j=0 ; j<this.props.openHours[dayOpenningHoursIndex].hoursArray.length ; j++){
                let hours ={};
                let open = new Date(this.props.openHours[dayOpenningHoursIndex].hoursArray[j].open)
                if (open!=="Not specified"){
                  hours.open=open.getHours()+":"+(open.getMinutes()<10?'0':'') + open.getMinutes()
                }
                else{
                  hours.open="Not specified"
                }
                let close = new Date(this.props.openHours[dayOpenningHoursIndex].hoursArray[j].close)
                
                if (close!=="Not specified"){
                  hours.close=close.getHours()+":"+(close.getMinutes()<10?'0':'') + close.getMinutes()
                }
                else{
                  hours.close="Not specified"
                }
                daysOpenHours[i].openHours.push(hours)
              }
            
            }
          }
        }
        this.setState({daysOpenHours:daysOpenHours})
  }

    discard =() =>{
      this.setState({ showHoursList: false ,
        showBackdrop:false  })
    }

    renderHoursDetailIcon = () => {
      let detailIconClass='DetailIcon'
      if (this.props.RTL){
        detailIconClass='DetailIconRTL'
      }
      if (this.props.creationEditMode){
        detailIconClass='DetailIconMoved'
        if (this.props.RTL){
          detailIconClass='DetailIconMovedRTL'
        }
      }
        if (this.state.showHoursList){
          return(
            <img src={closeDetailsIcon} className={classes[detailIconClass]} alt="" onClick={()=>{this.setState({showHoursList: false})}}/>
          )
        }
        return(
          <img src={detailIcon} className={classes[detailIconClass]} alt="" onClick={()=>{this.setState({showHoursList: true})}}/>
        )
      }

      renderHoursRange = (hoursRange) =>{
        if (hoursRange.open!==undefined){
          if (hoursRange.open==="Not specified" && hoursRange.close==="Not specified"){
            return(
              <div className={classes.HoursRange}>
                 <div className={classes.Open}>
                   {this.props.systemText.place.openHours.unspecifiedHours[this.props.userLanguage]}
                </div>
              </div>
            )
          }
          else{
            return(
              <div className={classes.HoursRange}>
                {hoursRange.open==="Not specified"?(
                  <div className={classes.Open}>
                   {this.props.systemText.place.openHours.unspecifiedHours[this.props.userLanguage]}
                </div>
                ):(
                  <div className={classes.Open}>
                  {hoursRange.open}
                </div>
                )}
                {" - "}
                {hoursRange.close==="Not specified"?(
                  <div className={classes.close}>
                   {this.props.systemText.place.openHours.unspecifiedHours[this.props.userLanguage]}
                </div>
                ):(
                  <div className={classes.close}>
                  {hoursRange.close}
                </div>
                )}
                 
              </div>
            )
          }
        }
        else{
          if (hoursRange==="Not specified"){
            return(
              <div className={classes.HoursRange}>
                   {this.props.systemText.place.openHours.unspecifiedHours[this.props.userLanguage]}
              </div>
            )
          }
         if (hoursRange==="Closed"){
            return(
              <div className={classes.HoursRange}>
                   {this.props.systemText.place.openHours.closed[this.props.userLanguage]}
              </div>
            )
          }
          if (hoursRange==="Open 24 hours"){
            return(
              <div className={classes.HoursRange}>
                    {this.props.systemText.place.openHours.open24Hours[this.props.userLanguage]}
              </div>
            )
          } 
        }
        
      }
      
      renderDayOfWeek = (day,index) =>{
        let dayOfWeekClass='DayOfWeek'
        if (index===0){
          dayOfWeekClass='DayOfWeekToday'
        }
        return(
          <div className={classes[dayOfWeekClass]}>
              <div className={classes.DayName}>
                {this.props.systemText.place.openHours.weekDaysLong[moment().day(day.dayOfWeek).format('dddd').toLowerCase()][this.props.userLanguage]}
              </div>
              <div className={classes.OpenHours}>
                  {day.openHours.map((hoursRange,index)=>(
                    <div className={classes.HoursRow} key={index}>
                      {this.renderHoursRange(hoursRange)}
                      {(index)<(this.state.daysOpenHours[0].openHours.length-1)?(
                          <div className={classes.RangeSeperator}>{",  "}</div>
                        ):null}
                    </div>
                  ))}
            </div>
          </div>
        )
      }

    

      renderBody=()=>{
        if (this.props.placeTemporaryClosed){
          return(<div className={classes.Closed}>
            {"Place is temporary closed: "+this.props.temporaryClosedNote}
          </div>)
        }
        else if (this.props.placePermanentlyClosed){
          let closeDateText =""
          if (this.props.permanentlyClosedDate!==undefined){
            closeDateText = this.props.permanentlyClosedDate.getDate()+"/"+(this.props.permanentlyClosedDate.getMonth() + 1)+"/"+(this.props.permanentlyClosedDate.getFullYear());
          }
          return(<div className={classes.Closed}>
            {"Place is closed since "+closeDateText}
          </div>)
        }
        else{
          if (this.state.showHoursList){
            return(<div className={classes.DaysHoursList}>
              {this.state.daysOpenHours.map((day,index)=>(
                <div className={classes.WeekDay} key={index}>
                   {this.renderDayOfWeek(day,index)}
                </div>
              ))}
             </div>)
          }
          else{
            let hoursIconClass = "HoursIcon"
            if (this.props.RTL){
              hoursIconClass = "HoursIconRTL"
            }
            return(<div className={classes.TodayHours}>
              <div className={classes.TodayName}>
                <img src = {hoursIcon} alt="" className={[classes[hoursIconClass],classes.Clickable].join(" ")}
               onClick={()=>{this.setState({showHoursList: true, showBackdrop:true})}}
                />
                {this.props.systemText.place.openHours.today[this.props.userLanguage]}
                <div className={classes.OpenHoursToday}>
                    {this.state.daysOpenHours[0].openHours.map((hoursRange,index)=>(
                      <div className={classes.HoursRow} key={index}>
                        {this.renderHoursRange(hoursRange)}
                        {(index)<(this.state.daysOpenHours[0].openHours.length-1)?(
                          <div className={classes.RangeSeperator}>{",  "}</div>
                        ):null}
                      </div>
                    ))}
              </div>
              </div>
              
             </div>)
          }
        }
        

      }


    render(){
      let RTL = ""
      let hoursIconClass = "HoursIcon"
      if (this.props.RTL){
        RTL = "RTL"
        hoursIconClass = "HoursIconRTL"
      }
      if (this.props.creationEditMode){
        
        return(<div className={[classes.OpenHoursPreviewEditMode,classes[RTL]].join(" ")}>
          
          <div className={classes.TodayHoursEditMode}
          onClick={()=>{this.setState({showHoursList: !this.state.showHoursList})}}>
            <div className={classes.TodayName}>
              <img src = {hoursIcon} alt="" className={classes[hoursIconClass]} onClick={this.props.hoursHandler}/>
              {this.props.systemText.place.openHours.today[this.props.userLanguage]}
              <div className={classes.OpenHoursToday}>
                  {this.state.daysOpenHours[0].openHours.map((hoursRange,index)=>(
                    <div className={classes.HoursRow} key={index}>
                      {this.renderHoursRange(hoursRange)}
                      {(index)<(this.state.daysOpenHours[0].openHours.length-1)?(
                          <div className={classes.RangeSeperator}>{",  "}</div>
                        ):null}
                    </div>
                  ))}
            </div>
            </div>
            
            {this.renderHoursDetailIcon()}
           </div>
           {this.state.showHoursList?(
            <div className={classes.DaysHoursListEditMode}>
            {this.state.daysOpenHours.map((day,index)=>(
              <div className={classes.WeekDay} key={index}>
                 {this.renderDayOfWeek(day,index)}
              </div>
            ))}
         
           </div>
           ):null}
          </div>)
      }
      else{
        return(<div className={[classes.OpenHoursPreview, classes[RTL]].join(" ")}>
          <TransparentBackdrop show={this.state.showBackdrop} clicked={this.discard} />
         {/*  {this.renderHoursDetailIcon()} */}
          {this.renderBody()}
          </div>)
      }
    }
}
export default OpenHoursPreview;