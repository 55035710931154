import React, { Component } from "react";
import classes from "./TripJourney.module.css";

import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {isMobile, isMobileOnly} from 'react-device-detect';
import { Editor, EditorState , convertFromRaw, convertToRaw} from 'draft-js';
import PlanJourneyDay from "../../../Components/Complex/Plan/PlanJourneyDay";

let placeIcon ="https://firebasestorage.googleapis.com/v0/b/mindu-alpha.appspot.com/o/prod%2FsystemData%2FstaticImages%2FWall%2FMarkerIcon.png?alt=media&token=4108217d-c432-4c27-bbac-5667e7e7f237"

const SortableItem = sortableElement(({index, value}) =>
{ 
  return(<img className={classes.MovingImage} alt="" src={value}/>)});

const SortableContainer = sortableContainer(({children}) => {
  return <div  className={classes.ImagesSection}>{children}</div>;
});


class TripJourney extends Component {
    state={
        daysNames: [],
        selectedDayIndex: 1,
    }

    componentDidMount = () =>{
        let daysNames = [];
        for (let i=1 ; i<this.props.planDays.length ; i++){
            if (i===1){
                daysNames.push({name:this.props.planDays[i].name,selected:true})
            }
            else{
                daysNames.push({name:this.props.planDays[i].name,selected:false})
            }
        }
        this.setState({daysNames:daysNames})
    }

    selectDayHandler = index =>{
        let daysNames = this.state.daysNames;
        for (let i=0 ; i<daysNames.length; i++){
            daysNames[i].selected=false;
        }
        daysNames[index].selected=true;
        this.setState({daysNames:daysNames,selectedIndex:index+1,selectedDayIndex:index+1})
    }

   
    renderWall = () =>{
        if (isMobile){
            let index = this.state.selectedDayIndex
            if (this.state.daysNames[index-1]!==undefined){
                let dayName = this.state.daysNames[index-1].name;
                let name= this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage] +" "+ dayName.substr(dayName.indexOf(' ')+1);
                return(
                <div className={classes.Wall}>
                    <PlanJourneyDay
                            key = {index}
                            dayName = {name}
                            dayIndex = {index}
                            selectedDayIndex = {this.state.selectedDayIndex}
                            isEditor = {this.props.isEditor}
                            planDays={this.props.planDays}
                            planExperiences = {this.props.planExperiences}
                            viewPlaceHandler={this.props.viewPlaceHandler}
                            shareExperience={this.props.shareExperience}
                            viewUserJourneyID = {this.props.viewUserJourneyID}
                            removeJourneyImage={this.props.removeJourneyImage}
                            addJourneyImage={this.props.addJourneyImage}
                            updateJourneyImagesOrder = {this.props.updateJourneyImagesOrder}
                            updateExperienceText = {this.props.updateExperienceText}
                  
                            writeAcitivityLog = {this.props.writeAcitivityLog}
                            
                            user={this.props.user}
                            RTL = {this.props.RTL}
                            userLanguage={this.props.userLanguage}
                            systemText={this.props.systemText}
                            />
                </div>
                )
            }
        }
        else{
            return(
                <div className={classes.Wall}>
                    {this.props.planDays.map((day,index)=>{
                        if (index!==0&&this.state.daysNames[index-1]!==undefined){
                            let dayName = this.state.daysNames[index-1].name;
                            let name= this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage] +" "+ dayName.substr(dayName.indexOf(' ')+1);
                            return(
                                <PlanJourneyDay
                                key = {index}
                                dayName = {name}
                                dayIndex = {index}
                                selectedDayIndex = {this.state.selectedDayIndex}
                                isEditor = {this.props.isEditor}
                                planDays={this.props.planDays}
                                planExperiences = {this.props.planExperiences}
                                viewPlaceHandler={this.props.viewPlaceHandler}
                                shareExperience={this.props.shareExperience}
                                viewUserJourneyID = {this.props.viewUserJourneyID}
                                removeJourneyImage={this.props.removeJourneyImage}
                                addJourneyImage={this.props.addJourneyImage}
                                updateJourneyImagesOrder = {this.props.updateJourneyImagesOrder}
                                updateExperienceText = {this.props.updateExperienceText}
                      
                                writeAcitivityLog = {this.props.writeAcitivityLog}
                                
                                user={this.props.user}
                                RTL = {this.props.RTL}
                                userLanguage={this.props.userLanguage}
                                systemText={this.props.systemText}
                                />
                            )
                        }     
                    })}
                </div>
            )
        }
       
    }


    renderDays = () =>{
        return(
            <div className={classes.DaysSection}>
                {this.state.daysNames.map((day,index)=>{
                    if (day.selected){
                        let name = day.name;
                        name= this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage] +" "+ day.name.substr(day.name.indexOf(' ')+1);
                        return(
                            <div className={classes.SelectedDay}>
                                {name}
                            </div>
                        )
                    }
                    else{
                        let name = day.name;
                        name= this.props.systemText.plan.viewPlan.planning.day[this.props.userLanguage] +" "+ day.name.substr(day.name.indexOf(' ')+1);
                        return(
                            <div className={classes.Day} 
                                onClick={()=>{this.selectDayHandler(index)
                                    this.props.writeAcitivityLog({
                                        activityKey:"TRIP_ALBUM_DAY_CLICKED",
                                        userScreen:"VIEW_TRIP"
                                      })
                                
                                }}>
                                {name}
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    render(){
        return(
            <div className={classes.Body}>
                {this.renderDays()}
                {this.renderWall()}
            </div>
        )
    }
}

export default TripJourney;