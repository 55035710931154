
import React, { Component } from "react";

import DetectInputLanguage from "./DetectInputLanguage"

import ShouldPlaceRender from "./ShouldPlaceRender"

import { isMobile } from "react-device-detect";

class SearchFunctions extends Component {

  state={
    shouldPlaceRenderRef:React.createRef()
    
  }

    discoverPlacesLocationSearchFunction = async args =>{
        return new Promise(async (resolve, reject) => {
            let searchTag = args.searchTag;
            let discoverPlacesLocationFilterTags = args.discoverPlacesLocationFilterTags;
            let discoverCountryPlaces = args.discoverCountryPlaces;
            let locationsTree = args.locationsTree
            let countryName = searchTag.treeID.split('_')[0]

            let discoverLocationFilteredPlaces = [];
            let suggestedLocationsFilters = [];
            let discoverPlacesAvailableCategories = [];
            let discoverPlacesAvailableMainCategories = [0,0,0,0,0,0];

            let discoverPlacesNameSearchTags = [];
            let discoverPlacesTagsSearchTags = [];
            let discoverPlacesTagsSearchTagsIDs = [];

            //search tag is a country
            if (searchTag.type==="country"){
                discoverLocationFilteredPlaces = discoverCountryPlaces

                //get locations suggestions tags
                let regions = []
                if (locationsTree[countryName].regions!==undefined){
                    regions=JSON.parse(JSON.stringify(Object.values(locationsTree[countryName].regions)))
                }
                regions.forEach(function(obj){ if (obj.cities!==undefined) {delete obj.cities} });
                let cities = []
                if (locationsTree[countryName].cities!==undefined){
                    cities=Object.values(locationsTree[countryName].cities)
                }
                suggestedLocationsFilters = suggestedLocationsFilters.concat(regions)
                suggestedLocationsFilters = suggestedLocationsFilters.concat(cities)

                //update filter tags
                discoverPlacesLocationFilterTags = [];
                let countryLanguage="";
               
                if (searchTag[this.props.inputLanguage]!==undefined){
                    countryLanguage=this.props.inputLanguage;
                }
                else if (searchTag[this.props.userLanguage]!==undefined){
                    countryLanguage=this.props.userLanguage;
                }
                else {
                    countryLanguage="English";
                }
                discoverPlacesLocationFilterTags.push({tag:searchTag,type:"LocationFilter",language:countryLanguage})
            }
            //search tag is region or city
            else{
                //filtered places
                discoverLocationFilteredPlaces = discoverCountryPlaces.filter(
                    place=>place.placeLocation.locationTree.locationTreeIDs.includes(searchTag.treeID))

                //update filter tags
                if (args.search){
                    let countryTag = JSON.parse(JSON.stringify(locationsTree[countryName]))
                    if (countryTag.regions!==undefined){
                        delete countryTag.regions
                    }
                    if (countryTag.cities!==undefined){
                        delete countryTag.cities
                    }
                    discoverPlacesLocationFilterTags = [];
                    let countryLanguage="";
                
                    if (countryTag[this.props.inputLanguage]!==undefined){
                        countryLanguage=this.props.inputLanguage;
                    }
                    else if (countryTag[this.props.userLanguage]!==undefined){
                        countryLanguage=this.props.userLanguage;
                    }
                    else {
                        countryLanguage="English";
                    }
                    discoverPlacesLocationFilterTags.push({tag:countryTag,type:"LocationFilter",language:countryLanguage})
                }
                    let tagLanguage="";
               
                    if (searchTag[this.props.inputLanguage]!==undefined){
                        tagLanguage=this.props.inputLanguage;
                    }
                    else if (searchTag[this.props.userLanguage]!==undefined){
                        tagLanguage=this.props.userLanguage;
                    }
                    else {
                        tagLanguage="English";
                    }
                    discoverPlacesLocationFilterTags.push({tag:searchTag,type:"LocationFilter",language:tagLanguage})
                
                if (searchTag.type==="region"){
                    let locationRegion=searchTag.treeID.split('_')[1];
                    let cities = []
                    if (locationsTree[countryName].regions[locationRegion].cities!==undefined){
                        cities=Object.values(locationsTree[countryName].regions[locationRegion].cities)
                    }
                    suggestedLocationsFilters = suggestedLocationsFilters.concat(cities)
                }
            }

            for (let i=0 ; i<discoverLocationFilteredPlaces.length ; i++){
              if (discoverLocationFilteredPlaces[i].placeCategory!==undefined){
                for (let j=0 ; j<discoverLocationFilteredPlaces[i].placeCategory.length ; j++){
                  const index = discoverPlacesAvailableCategories.findIndex(cat => {
                      return cat.category === discoverLocationFilteredPlaces[i].placeCategory[j];
                    });
                  if (index!==-1){
                    discoverPlacesAvailableCategories[index].count = discoverPlacesAvailableCategories[index].count+1
                  }
                  else{
                    discoverPlacesAvailableCategories.push({category:discoverLocationFilteredPlaces[i].placeCategory[j],count:1})
                  }
               }
              }
                
              for (let j=0 ; j<discoverLocationFilteredPlaces[i].placeMainCategory.length ; j++){
                  if (discoverLocationFilteredPlaces[i].placeMainCategory[j]===1){
                      discoverPlacesAvailableMainCategories[j]=1;
                  }
              }
                
            }
            for (let i =0 ; i<discoverLocationFilteredPlaces.length ; i++){
              let place = discoverLocationFilteredPlaces[i]
              discoverPlacesNameSearchTags.push(place.placeName)
              if (place.placeTags!==undefined){
                for (let j=0 ; j<place.placeTags.length ; j++){
                  
                  let tag = {...place.placeTags[j]}
                  let tagLanguages = Object.keys(tag);
                  let tagID = tag[tagLanguages[0]];
                  if (!discoverPlacesTagsSearchTagsIDs.includes(tagID)){
                    tag.placeTag = true
                    discoverPlacesTagsSearchTags.push(tag)
                    discoverPlacesTagsSearchTagsIDs.push(tagID)
                  }

                }
              }
              
            }

            let sortedDiscoverLocationFilteredPlaces = [];
            if (Object.keys(this.props.userCategoriesScore).length >0){
              await this.sortPlacesByUserCategory(discoverLocationFilteredPlaces).then(result => {
                sortedDiscoverLocationFilteredPlaces = result;
              }); 
              for (let i=0 ; i<discoverPlacesAvailableCategories.length ; i++){
                if (this.props.userCategoriesScore[discoverPlacesAvailableCategories[i].category]!==undefined){
               
                  discoverPlacesAvailableCategories[i].count+=this.props.userCategoriesScore[discoverPlacesAvailableCategories[i].category].score
                }
              }
            }
            else{
                sortedDiscoverLocationFilteredPlaces = await discoverLocationFilteredPlaces.sort((a, b) => {
                    var keyA = a.placeScore;
                    var keyB = b.placeScore;
                    // Compare score
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                    });
    
            }

            let sortedDiscoverPlacesAvailableCategories = await discoverPlacesAvailableCategories.sort((a, b) => {
              var keyA = a.count;
              var keyB = b.count;
              // Compare score
              if (keyA < keyB) return 1;
              if (keyA > keyB) return -1;
              return 0;
              }).map(cat=>{return cat.category});
              
            let result ={
                discoverLocationFilteredPlaces: sortedDiscoverLocationFilteredPlaces,
                discoverPlacesLocationFilterTags: discoverPlacesLocationFilterTags,
                suggestedLocationsFilters: suggestedLocationsFilters,
                discoverPlacesAvailableCategories: sortedDiscoverPlacesAvailableCategories,
                discoverPlacesAvailableMainCategories: discoverPlacesAvailableMainCategories,
                discoverPlacesNameSearchTags: discoverPlacesNameSearchTags,
                discoverPlacesTagsSearchTags: discoverPlacesTagsSearchTags
            }
            resolve(result);
        });
    }

    discoverPlacesMainCategoryFunction= async args =>{
        return new Promise(async (resolve, reject) => {
            let selectedMainCategoryIndex = args.selectedMainCategoryIndex;
            let discoverLocationFilteredPlaces = args.discoverLocationFilteredPlaces;

            let filteredPlaces =[];

            let regionsTagsTreeIDs = [];
            let citiesTagsTreeIDs = [];
            let citiesRegionLevelTagsTreeIDs = [];
            let suggestedLocationsFilters = [];

            let discoverPlacesNameSearchTags =[];
            for (let i=0 ;i<discoverLocationFilteredPlaces.length ; i++){
                let place = discoverLocationFilteredPlaces[i];
                if (place.placeMainCategory[selectedMainCategoryIndex]===1){
                    filteredPlaces.push(place)
                    discoverPlacesNameSearchTags.push(place.placeName)
                    if (place.placeLocation.locationTree.placeRegion!==undefined){
                        if (!regionsTagsTreeIDs.includes(place.placeLocation.locationTree.placeRegion)){
                          regionsTagsTreeIDs.push(place.placeLocation.locationTree.placeRegion);
                        }
                        if (place.placeLocation.locationTree.placeCity!==undefined){
                          if (!citiesTagsTreeIDs.includes(place.placeLocation.locationTree.placeCity)){
                            citiesTagsTreeIDs.push(place.placeLocation.locationTree.placeCity);
                          }
                        }
                      }
                      else if(place.placeLocation.locationTree.placeCity!==undefined){
                        if (!citiesRegionLevelTagsTreeIDs.includes(place.placeLocation.locationTree.placeCity)){
                          citiesRegionLevelTagsTreeIDs.push(place.placeLocation.locationTree.placeCity);
                        }
                      }
                }
            }
            if (regionsTagsTreeIDs.length===1){
                suggestedLocationsFilters = suggestedLocationsFilters.concat(citiesTagsTreeIDs)
            }
            else{
                suggestedLocationsFilters = suggestedLocationsFilters.concat(regionsTagsTreeIDs)
                suggestedLocationsFilters = suggestedLocationsFilters.concat(citiesRegionLevelTagsTreeIDs)
            }

            let sortedFilteredPlaces = [];
            if (Object.keys(this.props.userCategoriesScore).length >0){
                await this.sortPlacesByUserCategory(filteredPlaces).then(result => {
                 sortedFilteredPlaces = result;
                  }); 
            }
            else{
                sortedFilteredPlaces = await filteredPlaces.sort((a, b) => {
                    var keyA = a.placeScore;
                    var keyB = b.placeScore;
                    // Compare score
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                    });
    
            }
            let suggestedLocationsFiltersTags = [];
            for (let i =0 ; i<suggestedLocationsFilters.length ; i++){
              suggestedLocationsFiltersTags.push(this.props.locationsTranslator[suggestedLocationsFilters[i]])
            }
            let result={
                filteredPlaces:sortedFilteredPlaces,
                suggestedLocationsFilters:suggestedLocationsFiltersTags,
                discoverPlacesNameSearchTags:discoverPlacesNameSearchTags
            }
        resolve(result);
        });
    }

    sortPlacesByUserCategory = placesList =>{
        return new Promise(async (resolve, reject) => {
        for (let i=0;i<placesList.length ; i++){
            let userPlaceScore = placesList[i].placeScore
            let placeCategoriesScore=0
            if (placesList[i].placeCategory!==undefined){
              for (let j=0 ; j<placesList[i].placeCategory.length ; j++){
                let catID = placesList[i].placeCategory[j]
                if (this.props.userCategoriesScore[catID]!==undefined){
                  placeCategoriesScore=placeCategoriesScore+ this.props.userCategoriesScore[catID].score
                }
              }
            }
            
            if (placeCategoriesScore!==0){
              userPlaceScore = userPlaceScore*placeCategoriesScore
            }
            placesList[i].userPlaceScore=userPlaceScore
          }
          let filteredDiscoverPlaces=await placesList.sort((a, b) => {
          var keyA = a.userPlaceScore;
          var keyB = b.userPlaceScore;
          // Compare score
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
          });
          resolve (filteredDiscoverPlaces)
        });
    }

    discoverPlacesCategorySearchTagFunction = async args =>{
        return new Promise(async (resolve, reject) => {
            let discoverPlacesCategoriesFilterTags = args.discoverPlacesCategoriesFilterTags;
            let discoverLocationFilteredPlaces = args.discoverLocationFilteredPlaces;

            let filteredPlaces =[];

            let regionsTagsTreeIDs = [];
            let citiesTagsTreeIDs = [];
            let citiesRegionLevelTagsTreeIDs = [];
            let suggestedLocationsFilters = [];

            let discoverPlacesNameSearchTags =[];
            for (let i=0 ;i<discoverLocationFilteredPlaces.length ; i++){
                let place = discoverLocationFilteredPlaces[i];
                let include = false;
                for (let j=0 ; j<discoverPlacesCategoriesFilterTags.length ; j++){
                  if(place.placeCategory!==undefined){
                    if (place.placeCategory.includes(discoverPlacesCategoriesFilterTags[j].tag.id)){
                      include = true;
                    }
                  }
                    
                }
                if (include){
                    filteredPlaces.push(place)
                    discoverPlacesNameSearchTags.push(place.placeName)
                    if (place.placeLocation.locationTree.placeRegion!==undefined){
                        if (!regionsTagsTreeIDs.includes(place.placeLocation.locationTree.placeRegion)){
                          regionsTagsTreeIDs.push(place.placeLocation.locationTree.placeRegion);
                        }
                        if (place.placeLocation.locationTree.placeCity!==undefined){
                          if (!citiesTagsTreeIDs.includes(place.placeLocation.locationTree.placeCity)){
                            citiesTagsTreeIDs.push(place.placeLocation.locationTree.placeCity);
                          }
                        }
                      }
                      else if(place.placeLocation.locationTree.placeCity!==undefined){
                        if (!citiesRegionLevelTagsTreeIDs.includes(place.placeLocation.locationTree.placeCity)){
                          citiesRegionLevelTagsTreeIDs.push(place.placeLocation.locationTree.placeCity);
                        }
                      }
                }
            }
            if (regionsTagsTreeIDs.length===1){
                suggestedLocationsFilters = suggestedLocationsFilters.concat(citiesTagsTreeIDs)
            }
            else{
                suggestedLocationsFilters = suggestedLocationsFilters.concat(regionsTagsTreeIDs)
                suggestedLocationsFilters = suggestedLocationsFilters.concat(citiesRegionLevelTagsTreeIDs)
            }

            let sortedFilteredPlaces = await filteredPlaces.sort((a, b) => {
                var keyA = a.placeScore;
                var keyB = b.placeScore;
                // Compare score
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
                });

            let suggestedLocationsFiltersTags = [];
            for (let i =0 ; i<suggestedLocationsFilters.length ; i++){
             
              suggestedLocationsFiltersTags.push(this.props.locationsTranslator[suggestedLocationsFilters[i]])
            }
            let result={
                filteredPlaces:sortedFilteredPlaces,
                suggestedLocationsFilters:suggestedLocationsFiltersTags,
                discoverPlacesNameSearchTags:discoverPlacesNameSearchTags
            }
        resolve(result);
        });
    }

    discoverPlacesMapBoundsFunction = async args =>{
        return new Promise(async (resolve, reject) => {
            let mapBounds = args.mapBounds;
            //adjust mobile bounds to show only places that are not covered with toolbar and places list
            if (isMobile){
              let height = Math.abs(mapBounds.north-mapBounds.south)
              let reduceNorth = height*0.2
              let addSouth = height*0.25
              mapBounds.north = mapBounds.north-reduceNorth
              mapBounds.south = mapBounds.south+addSouth
            }
            let discoverCountryPlaces = args.discoverCountryPlaces;
            let discoverPlacesSelectedMainCategory = args.discoverPlacesSelectedMainCategory;
            let discoverPlacesLocationFilterTags = args.discoverPlacesLocationFilterTags

            let mainCategoryFilterEnabled = false;
            const mainCategoryIndex = discoverPlacesSelectedMainCategory.findIndex(cat => {
                return cat === 1;
              }); 
            if (mainCategoryIndex!==0){
                mainCategoryFilterEnabled = true
            }

            let filteredPlaces = [];

            let regionsTagsTreeIDs = [];
            let citiesTagsTreeIDs = [];
            let citiesRegionLevelTagsTreeIDs = [];
            let suggestedLocationsFilters = [];

            let discoverPlacesNameSearchTags =[];

            let discoverPlacesAvailableCategories = [];
            let discoverPlacesAvailableMainCategories = [0,0,0,0,0,0];

            let newDiscoverPlacesLocationFilterTags = [];
            let oldLocationFilterTagsToInclude = [];
            for (let i = 0 ; i<discoverPlacesLocationFilterTags.length ;i++){
                oldLocationFilterTagsToInclude.push({treeID:discoverPlacesLocationFilterTags[i].tag.treeID,include:true})
            }
            for (let i=0; i<discoverCountryPlaces.length ; i++){
                let place = discoverCountryPlaces[i];
                if (place.placeLocation.coordinates.lat>mapBounds.south&&
                place.placeLocation.coordinates.lat<mapBounds.north&&
                place.placeLocation.coordinates.lng>mapBounds.west&&
                place.placeLocation.coordinates.lng<mapBounds.east
                ){
                    let include = false;
                    if (mainCategoryFilterEnabled){
                        if (place.placeMainCategory[mainCategoryIndex-1]===1){
                            include = true
                        }
                    }
                    else{
                        include = true
                    }
                    if (include){
                        filteredPlaces.push(place)
                        discoverPlacesNameSearchTags.push(place.placeName)
                        //validate old location filter tags
                        for (let j = 0 ;j<oldLocationFilterTagsToInclude.length ; j++){
                            if (!place.placeLocation.locationTree.locationTreeIDs.includes(oldLocationFilterTagsToInclude[j].treeID)){
                                oldLocationFilterTagsToInclude[j].include=false;

                            }
                        }

                        //get location filters suggestions 
                        if (place.placeLocation.locationTree.placeRegion!==undefined){
                            if (!regionsTagsTreeIDs.includes(place.placeLocation.locationTree.placeRegion)){
                              regionsTagsTreeIDs.push(place.placeLocation.locationTree.placeRegion);
                            }
                            if (place.placeLocation.locationTree.placeCity!==undefined){
                              if (!citiesTagsTreeIDs.includes(place.placeLocation.locationTree.placeCity)){
                                citiesTagsTreeIDs.push(place.placeLocation.locationTree.placeCity);
                              }
                            }
                          }
                          else if(place.placeLocation.locationTree.placeCity!==undefined){
                            if (!citiesRegionLevelTagsTreeIDs.includes(place.placeLocation.locationTree.placeCity)){
                              citiesRegionLevelTagsTreeIDs.push(place.placeLocation.locationTree.placeCity);
                            }
                          }

                          //get categories suggestions
                            if (place.placeCategory!==undefined){
                              for (let j=0 ; j<place.placeCategory.length ; j++){
                                const index = discoverPlacesAvailableCategories.findIndex(cat => {
                                    return cat.category === place.placeCategory[j];
                                  });
                                  if (index!==-1){
                                    discoverPlacesAvailableCategories[index].count = discoverPlacesAvailableCategories[index].count+1
                                  }
                                  else{
                                    discoverPlacesAvailableCategories.push({category:place.placeCategory[j],count:1})
                                  }
                                }
                            }
                           
                            for (let j=0 ; j<place.placeMainCategory.length ; j++){
                                if (place.placeMainCategory[j]===1){
                                    discoverPlacesAvailableMainCategories[j]=1;
                                }
                            }
                    }
                }
            }
            if (regionsTagsTreeIDs.length===1){
                suggestedLocationsFilters = suggestedLocationsFilters.concat(citiesTagsTreeIDs)
            }
            else{
                suggestedLocationsFilters = suggestedLocationsFilters.concat(regionsTagsTreeIDs)
                suggestedLocationsFilters = suggestedLocationsFilters.concat(citiesRegionLevelTagsTreeIDs)
            }
            let suggestedLocationsFiltersTags = []
            for (let i=0 ; i<suggestedLocationsFilters.length ; i++){
              suggestedLocationsFiltersTags.push(this.props.locationsTranslator[suggestedLocationsFilters[i]])
            }


            let sortedFilteredPlaces = [];
            if (Object.keys(this.props.userCategoriesScore).length >0){
              await this.sortPlacesByUserCategory(filteredPlaces).then(result => {
                sortedFilteredPlaces = result;
              }); 
              for (let i=0 ; i<discoverPlacesAvailableCategories.length ; i++){
                if (this.props.userCategoriesScore[discoverPlacesAvailableCategories[i].category]!==undefined){
                  discoverPlacesAvailableCategories[i].count+=this.props.userCategoriesScore[discoverPlacesAvailableCategories[i].category].score
                }
              }
            }
            else{
                sortedFilteredPlaces = await filteredPlaces.sort((a, b) => {
                    var keyA = a.placeScore;
                    var keyB = b.placeScore;
                    // Compare score
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                    });
    
            }
    
            let sortedDiscoverPlacesAvailableCategories = await discoverPlacesAvailableCategories.sort((a, b) => {
              var keyA = a.count;
              var keyB = b.count;
              // Compare score
              if (keyA < keyB) return 1;
              if (keyA > keyB) return -1;
              return 0;
              }).map(cat=>{return cat.category});

            for (let i=0 ; i< oldLocationFilterTagsToInclude.length ; i++){
                if (oldLocationFilterTagsToInclude[i].include){
                    newDiscoverPlacesLocationFilterTags.push(discoverPlacesLocationFilterTags[i])
                }
            }
            if (regionsTagsTreeIDs.length===1){
              let regionTag = this.props.locationsTranslator[regionsTagsTreeIDs[0]]
              let language = ""
              if (regionTag[this.props.userLanguage]!==undefined){
                language = this.props.userLanguage
              }
              else{
                language = "English"
              }
              if (!newDiscoverPlacesLocationFilterTags.map(tag=>{return tag.tag.treeID}).includes(regionTag.treeID)){
                newDiscoverPlacesLocationFilterTags.push({tag:regionTag,type:"LocationFilter",language:language})
              }
             

              //check if also only one city
              if (citiesTagsTreeIDs.length ===1){
                let cityTag = this.props.locationsTranslator[citiesTagsTreeIDs[0]]
                let language = ""
                if (cityTag[this.props.userLanguage]!==undefined){
                  language = this.props.userLanguage
                }
                else{
                  language = "English"
                }
                
                if (!newDiscoverPlacesLocationFilterTags.map(tag=>{return tag.tag.treeID}).includes(cityTag.treeID)){
                  newDiscoverPlacesLocationFilterTags.push({tag:cityTag,type:"LocationFilter",language:language})
                }
               
              }
          }


            
            let result={
                filteredPlaces:sortedFilteredPlaces,
                suggestedLocationsFilters:suggestedLocationsFiltersTags,
                discoverPlacesAvailableCategories: sortedDiscoverPlacesAvailableCategories,
                discoverPlacesAvailableMainCategories: discoverPlacesAvailableMainCategories,
                discoverPlacesNameSearchTags: discoverPlacesNameSearchTags,
                discoverPlacesLocationFilterTags: newDiscoverPlacesLocationFilterTags
            }
        resolve(result);
    });
    }

    placeCharacteristicsHandler = args =>{
      return new Promise(async (resolve, reject) => {
        let placesList = args.placesList
        let count = 0;
        let addedObject = 0;
        let currentList = []
        while (count<placesList.length){
            let placeArgs = {
            place:placesList[count],
            discoverPlacesNameFilterTag:args.discoverPlacesNameFilterTag,
            discoverPlacesAdditionalTagFilterTag: args.discoverPlacesAdditionalTagFilterTag,
            placeCharacteristicsFilters:args.discoverPlacesCharacteristicsFilter,
            discoverPlacesDaysFilter:args.discoverPlacesDaysFilter
          } 
          if (this.state.shouldPlaceRenderRef){
            await this.state.shouldPlaceRenderRef.current.shouldRender(placeArgs).then(result => {
              if (result){ 
                currentList.push(placesList[count])
                addedObject=addedObject+1;
              } 
              count=count+1;
            }); 
          }
        }
        let result={
          filteredPlaces:currentList
        }
        resolve(result);
      });
    }

    //==========Favorite places===========

    favoritePlacesSearchFunction = args =>{
        return new Promise(async (resolve, reject) => {
            let favoritePlacesFilterTags = args.favoritePlacesFilterTags
            let tag = args.searchTag
            let favoritePlaces = args.favoritePlaces
            let filteredFavoritePlaces = args.filteredFavoritePlaces

            let potentialPlaces = favoritePlaces;
            if (favoritePlacesFilterTags.length>1){
              potentialPlaces=filteredFavoritePlaces
            }

           
            let filteredPlaces=[];
            let favoritePlacesSearchTags = [];
            for (let i=0 ; i<potentialPlaces.length ; i++){
              let include = false;
              let place = potentialPlaces[i];
              if (place.placeName[this.props.inputLanguage]!==undefined){
                if (place.placeName[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                  include = true;
                }
              }
              if (tag.id!==undefined){
                if (place.placeCategory!==undefined){
                  for (let j = 0 ; j<place.placeCategory.length ; j++){
                    if (place.placeCategory[j]===tag.id){
                      include = true;
                    }
                  }
                }
                
              }
              
              if (tag.treeID!==undefined){
                //this is a location tag
                let placeLocationTreeIDs = place.placeLocation.locationTree.locationTreeIDs;
                if (placeLocationTreeIDs.includes(tag.treeID)){
                  include = true;
                }
              }
              if (!include){
                if (place.favoriteSearchTags!==undefined){
                  for (let j =0 ; j<place.favoriteSearchTags.length ; j++){
                    if (place.favoriteSearchTags[j][this.props.inputLanguage]!==undefined){
                      if (place.favoriteSearchTags[j][this.props.inputLanguage]===tag[this.props.inputLanguage]){
                        include = true;
                      }
                    }
                  }
                }
              }
              if (include){
                  filteredPlaces.push(place);
              }      
            }
            await this.updateFavoritePlacesSearchTags(filteredPlaces,args.onlyMapAreaResults).then(result => {
                favoritePlacesSearchTags = result;
            }); 

            let sortedFilteredPlaces = await filteredPlaces.sort((a, b) => {
                var keyA = a.placeLastModified;
                var keyB = b.placeLastModified;
                // Compare score
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
                })

            let result = {
                 favoritePlacesSearchTags:favoritePlacesSearchTags,
                 filteredPlaces:sortedFilteredPlaces,
                 favoritePlacesFilterTags:favoritePlacesFilterTags
            }
            resolve(result);
        });
    }

    updateFavoritePlacesSearchTags = (placesList,usingMapArea) =>{
        return new Promise(async (resolve, reject) => {
            let favoritePlacesSearchTags = [];
            let favoritePlacesSearchTagsIDs = [];
            for (let i=0; i<placesList.length ; i++){
              if (!favoritePlacesSearchTagsIDs.includes(placesList[i].placeName[Object.keys(placesList[i].placeName)[0]])){
                favoritePlacesSearchTags.push(placesList[i].placeName);
                favoritePlacesSearchTagsIDs.push(placesList[i].placeName[Object.keys(placesList[i].placeName)[0]])
              }
             
              if (!usingMapArea){
                let placeCountryTag = {}
                let countryTreeID
                if (placesList[i].placeLocation.locationTree.placeCountry.treeID!==undefined){
                  placeCountryTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeCountry.treeID]
                  countryTreeID = placesList[i].placeLocation.locationTree.placeCountry.treeID
                }
                else{
                  placeCountryTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeCountry]
                  countryTreeID = placesList[i].placeLocation.locationTree.placeCountry
                }
  
                if (!favoritePlacesSearchTagsIDs.includes(countryTreeID)){
                  favoritePlacesSearchTags.push(placeCountryTag);
                  favoritePlacesSearchTagsIDs.push(countryTreeID)
                }
                if (placesList[i].placeLocation.locationTree.placeRegion!==undefined){
                  let placeRegionTag = {}
                  let regionTreeID
                  if (placesList[i].placeLocation.locationTree.placeRegion.treeID!==undefined){
                    placeRegionTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeRegion.treeID]
                    regionTreeID = placesList[i].placeLocation.locationTree.placeRegion.treeID
                  }
                  else{
                    placeRegionTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeRegion]
                    regionTreeID = placesList[i].placeLocation.locationTree.placeRegion
                  }
                  if (!favoritePlacesSearchTagsIDs.includes(regionTreeID)){
                    favoritePlacesSearchTags.push(placeRegionTag);
                    favoritePlacesSearchTagsIDs.push(regionTreeID)
                  }
                }
                if (placesList[i].placeLocation.locationTree.placeCity!==undefined){
                  let placeCityTag = {}
                  let cityTreeID
                  if (placesList[i].placeLocation.locationTree.placeCity.treeID!==undefined){
                    placeCityTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeCity.treeID]
                    cityTreeID = placesList[i].placeLocation.locationTree.placeCity.treeID
                  }
                  else{
                    placeCityTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeCity]
                    cityTreeID = placesList[i].placeLocation.locationTree.placeCity
                  }
                  if (!favoritePlacesSearchTagsIDs.includes(cityTreeID)){
                    favoritePlacesSearchTags.push(placeCityTag);
                    favoritePlacesSearchTagsIDs.push(cityTreeID)
                  }
                }
              }
              if (placesList[i].placeCategory!==undefined){
                for (let j=0 ; j<placesList[i].placeCategory.length ; j++ ){
                  let category = this.props.categoriesTranslator[placesList[i].placeCategory[j]]
                  if (category!==undefined){
                    if (!favoritePlacesSearchTagsIDs.includes(placesList[i].placeCategory[j])){
                      favoritePlacesSearchTags.push(category);
                      favoritePlacesSearchTagsIDs.push(placesList[i].placeCategory[j])
                    }
                  }
                  
                }
              }
              
              if (placesList[i].favoriteSearchTags!==undefined){
                for (let j=0 ; j<placesList[i].favoriteSearchTags.length ; j++){
                  if (!favoritePlacesSearchTagsIDs.includes(placesList[i].favoriteSearchTags[j][Object.keys(placesList[i].favoriteSearchTags[j])[0]])){
                    favoritePlacesSearchTags.push(placesList[i].favoriteSearchTags[j]);
                    favoritePlacesSearchTagsIDs.push(placesList[i].favoriteSearchTags[j][Object.keys(placesList[i].favoriteSearchTags[j])[0]])
                  }
                }
              }
              
            } 
            favoritePlacesSearchTags = favoritePlacesSearchTags.filter(tag=>tag!==undefined)
          resolve (favoritePlacesSearchTags)
        });
    }

    //------USER CREATED CONTENT---------

    userCreatedPlacesSearchFunction = args =>{
      return new Promise(async (resolve, reject) => {
          let userCreatedPlacesFilterTags = args.userCreatedPlacesFilterTags
          let tag = args.searchTag
          let userCreatedPlaces = args.userCreatedPlaces
          let filteredUserCreatedPlaces = args.filteredUserCreatedPlaces

           
            let potentialPlaces = userCreatedPlaces;
            if (userCreatedPlacesFilterTags.length>1){
              potentialPlaces=filteredUserCreatedPlaces
            }
            let filteredPlaces=[];
            let userCreatedPlacesSearchTags = [];
            for (let i=0 ; i<potentialPlaces.length ; i++){
              let include = false;
              let place = potentialPlaces[i];
              if (place.placeName[this.props.inputLanguage]!==undefined){
                if (place.placeName[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                  include = true;
                }
              }
              if (tag.id!==undefined&&place.placeCategory!==undefined){
                for (let j = 0 ; j<place.placeCategory.length ; j++){
                  if (place.placeCategory[j]===tag.id){
                    include = true;
                  }
                }
              }
              
              if (tag.treeID!==undefined){
                //this is a location tag
                let placeLocationTreeIDs = place.placeLocation.locationTree.locationTreeIDs;
                if (placeLocationTreeIDs.includes(tag.treeID)){
                  include = true;
                }
              }
              if (include){
                  filteredPlaces.push(place);
              }      
            }
            await this.updateUserCreatedPlacesSearchTags(filteredPlaces).then(result => {
              userCreatedPlacesSearchTags = result;
          }); 

          let sortedFilteredPlaces = await filteredPlaces.sort((a, b) => {
              var keyA = a.placeLastModified;
              var keyB = b.placeLastModified;
              // Compare score
              if (keyA < keyB) return 1;
              if (keyA > keyB) return -1;
              return 0;
              })

          let result = {
              userCreatedPlacesSearchTags:userCreatedPlacesSearchTags,
               filteredPlaces:sortedFilteredPlaces,
               userCreatedPlacesFilterTags:userCreatedPlacesFilterTags
          }
          resolve(result);
      });
  }

    updateUserCreatedPlacesSearchTags = placesList =>{
      return new Promise(async (resolve, reject) => {
          let userCreatedPlacesSearchTags = [];
          let userCreatedPlacesSearchTagsIDs = [];
          for (let i=0; i<placesList.length ; i++){
            if (!userCreatedPlacesSearchTagsIDs.includes(placesList[i].placeName[Object.keys(placesList[i].placeName)[0]])){
              userCreatedPlacesSearchTags.push(placesList[i].placeName);
              userCreatedPlacesSearchTagsIDs.push(placesList[i].placeName[Object.keys(placesList[i].placeName)[0]])
            }

            let placeCountryTag = {}
            let countryTreeID
            if (placesList[i].placeLocation.locationTree.placeCountry.treeID!==undefined){
              placeCountryTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeCountry.treeID]
              countryTreeID = placesList[i].placeLocation.locationTree.placeCountry.treeID
            }
            else{
              placeCountryTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeCountry]
              countryTreeID = placesList[i].placeLocation.locationTree.placeCountry
            }

            if (!userCreatedPlacesSearchTagsIDs.includes(countryTreeID)){
              userCreatedPlacesSearchTags.push(placeCountryTag);
              userCreatedPlacesSearchTagsIDs.push(countryTreeID)
            }

            if (placesList[i].placeLocation.locationTree.placeRegion!==undefined){
              let placeRegionTag = {}
              let regionTreeID
              if (placesList[i].placeLocation.locationTree.placeRegion.treeID!==undefined){
                placeRegionTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeRegion.treeID]
                regionTreeID = placesList[i].placeLocation.locationTree.placeRegion.treeID
              }
              else{
                placeRegionTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeRegion]
                regionTreeID = placesList[i].placeLocation.locationTree.placeRegion
              }
              if (!userCreatedPlacesSearchTagsIDs.includes(regionTreeID)){
                userCreatedPlacesSearchTags.push(placeRegionTag);
                userCreatedPlacesSearchTagsIDs.push(regionTreeID)
              }
            }
            if (placesList[i].placeLocation.locationTree.placeCity!==undefined){
              let placeCityTag = {}
              let cityTreeID
              if (placesList[i].placeLocation.locationTree.placeCity.treeID!==undefined){
                placeCityTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeCity.treeID]
                cityTreeID = placesList[i].placeLocation.locationTree.placeCity.treeID
              }
              else{
                placeCityTag = this.props.locationsTranslator[placesList[i].placeLocation.locationTree.placeCity]
                cityTreeID = placesList[i].placeLocation.locationTree.placeCity
              }
              if (!userCreatedPlacesSearchTagsIDs.includes(cityTreeID)){
                userCreatedPlacesSearchTags.push(placeCityTag);
                userCreatedPlacesSearchTagsIDs.push(cityTreeID)
              }
            }
            if (placesList[i].placeCategory!==undefined){
              for (let j=0 ; j<placesList[i].placeCategory.length ; j++ ){
                let category = this.props.categoriesTranslator[placesList[i].placeCategory[j]]
                if (category!==undefined){
                  if (!userCreatedPlacesSearchTagsIDs.includes(placesList[i].placeCategory[j])){
                    userCreatedPlacesSearchTags.push(category);
                    userCreatedPlacesSearchTagsIDs.push(placesList[i].placeCategory[j])
                  }
                }
                
              }
            }
            
           
                   
          } 
        resolve (userCreatedPlacesSearchTags.filter(tag=>tag!==undefined))
      });
  }

  experiencesSearchFunction = args =>{
    return new Promise(async (resolve, reject) => {
        let filterTags = args.filterTags
        let tag = args.searchTag
        let experiencesList = args.experiencesList
        let filteredExperiencesList = args.filteredExperiencesList

         
          let potentialExperiences = experiencesList;
          if (filterTags.length>1){
            potentialExperiences=filteredExperiencesList
          }
          let filteredExperiences=[];
          let searchTags = [];
          for (let i=0 ; i<potentialExperiences.length ; i++){
            let include = false;
            let experience = potentialExperiences[i];
            if (experience.experiencePlaceName[this.props.inputLanguage]!==undefined){
              if (experience.experiencePlaceName[this.props.inputLanguage]===tag[this.props.inputLanguage]){
                include = true;
              }
            }
            if (tag.id!==undefined){
              for (let j = 0 ; j<experience.experienceCategory.length ; j++){
                if (experience.experienceCategory[j]===tag.id){
                  include = true;
                }
              }
            }
            
            if (tag.treeID!==undefined){
              //this is a location tag
              let locationTreeIDs = experience.experienceLocation;
              if (locationTreeIDs.includes(tag.treeID)){
                include = true;
              }
            }
            if (include){
              filteredExperiences.push(experience);
            }      
          }
          await this.updateExperienceSearchTags(filteredExperiences).then(result => {
            searchTags = result;
        }); 

        let sortedFilteredExperiences = await filteredExperiences.sort((a, b) => {
            var keyA = a.experienceLastModified;
            var keyB = b.experienceLastModified;
            // Compare score
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
            })

        let result = {
          searchTags:searchTags,
          filteredExperiences:sortedFilteredExperiences,
          filterTags:filterTags
        }
        resolve(result);
    });
}

  updateExperienceSearchTags= experiencesList =>{
    return new Promise(async (resolve, reject) => {
        let searchTags = [];
        let includedTags = [];
        for (let i=0; i<experiencesList.length ; i++){
         
          let experience=experiencesList[i]
         
          for (let j=0 ; j<experience.experienceLocation.length ; j++){
            if (this.props.locationsTranslator[experience.experienceLocation[j]]!==undefined){
              if (!includedTags.includes(experience.experienceLocation[j])){
                includedTags.push(experience.experienceLocation[j])
                searchTags.push(this.props.locationsTranslator[experience.experienceLocation[j]])
              }
            }
           
            
          }
          for (let j=0 ; j<experience.experienceCategory.length ; j++){
            if (this.props.categoriesTranslator[experience.experienceCategory[j]]!==undefined){
              if (!includedTags.includes(experience.experienceCategory[j])){
                includedTags.push(experience.experienceCategory[j])
                searchTags.push(this.props.categoriesTranslator[experience.experienceCategory[j]])
              }
            }
          }

          let placeNameKeys = Object.keys(experience.experiencePlaceName)
          if (!includedTags.includes(experience.experiencePlaceName[placeNameKeys[0]])){
            includedTags.push(experience.experiencePlaceName[placeNameKeys[0]])
            searchTags.push(experience.experiencePlaceName)
          }
         
        } 
      resolve (searchTags)
    });
}

tripsSearchFunction = args =>{
  return new Promise(async (resolve, reject) => {
      let filterTags = args.filterTags
      let tag = args.searchTag
      let tripsList = args.tripsList
      let filteredTripsList = args.filteredTripsList
       
      let potentialTrips = tripsList;
      if (filterTags.length>1){
        potentialTrips=filteredTripsList
      }
      let filteredTrips=[];
      let searchTags = [];
      for (let i=0 ; i<potentialTrips.length ; i++){
        let include = false;
        let trip = potentialTrips[i];

        if (tag.treeID!==undefined){
          //this is a location tag
          let locationTreeIDs = []
          if (trip.tripCountries!==undefined){
            locationTreeIDs = trip.tripCountries
          }
          if (trip.tripLocationTags!==undefined){
            locationTreeIDs = locationTreeIDs.concat(trip.tripLocationTags)
          }
         
          if (locationTreeIDs.includes(tag.treeID)){
            include = true;
          }
        }
        
        if (trip.planName===tag[this.props.inputLanguage]){
          include = true;
        }

        if (include){
          filteredTrips.push(trip);
        }    

      }
      await this.updateTripsSearchTags(filteredTrips).then(result => {
        searchTags = result;
    }); 

    let sortedFilteredTrips = await filteredTrips.sort((a, b) => {
        var keyA = a.planLastModified;
        var keyB = b.planLastModified;
        // Compare score
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
        })

    let result = {
      searchTags:searchTags,
      filteredTrips:sortedFilteredTrips,
      filterTags:filterTags
    }
    resolve(result);
  });
}

updateTripsSearchTags= tripsList =>{
  return new Promise(async (resolve, reject) => {
      let searchTags = [];
      let includedTags = [];
      for (let i=0; i<tripsList.length ; i++){
       
        let trip=tripsList[i]
       
        let tripCountries = []
        if (trip.tripCountries!==undefined){
          tripCountries = trip.tripCountries
        }
        let tripLocationTags = []
        if (trip.tripLocationTags!==undefined){
          tripLocationTags = trip.tripLocationTags
        }
        for (let j=0 ; j<tripCountries.length ; j++){
          if (this.props.locationsTranslator[tripCountries[j]]!==undefined){
            if (!includedTags.includes(tripCountries[j])){
              includedTags.push(tripCountries[j])
              searchTags.push(this.props.locationsTranslator[tripCountries[j]])
            }
          }
        }
        for (let j=0 ; j<tripLocationTags.length ; j++){
          if (this.props.locationsTranslator[tripLocationTags[j]]!==undefined){
            if (!includedTags.includes(tripLocationTags[j])){
              includedTags.push(tripLocationTags[j])
              searchTags.push(this.props.locationsTranslator[tripLocationTags[j]])
            }
          }
        }

        let planName = trip.planName
        
        if (!includedTags.includes(planName)){
          includedTags.push(planName)
          let nameLanguage=""
          await this.refs.detectInputLanguage.detectLanguage(planName).then(result => {
            nameLanguage = result
          }); 
          let nameTag = {}
          nameTag[nameLanguage]=planName
          searchTags.push(nameTag)
        }
       
       
      } 
    resolve (searchTags)
  });
}

favoriteTripPlansSearchFunction = args =>{
  return new Promise(async (resolve, reject) => {
      let favoriteTripPlansFilterTags = args.favoriteTripPlansFilterTags
      let tag = args.searchTag
      let favoriteTrips = args.favoriteTripPlans
      let filteredFavoriteTripPlans = args.filteredFavoriteTripPlans

      let potentialTrips = favoriteTrips;
      if (favoriteTripPlansFilterTags.length>1){
        potentialTrips=filteredFavoriteTripPlans
      }
      let filteredTrips=[];
      let favoriteTripPlansSearchTags = [];
      for (let i=0 ; i<potentialTrips.length ; i++){
        let include = false;
        let trip = potentialTrips[i];

        if (tag.treeID!==undefined){
          //this is a location tag
          let locationTreeIDs = []
          if (trip.tripCountries!==undefined){
            locationTreeIDs = trip.tripCountries
          }
          if (trip.tripLocationTags!==undefined){
            locationTreeIDs = locationTreeIDs.concat(trip.tripLocationTags)
          }
         
          if (locationTreeIDs.includes(tag.treeID)){
            include = true;
          }
        }
        
        if (trip.planName===tag[this.props.inputLanguage]){
          include = true;
        }

        if (!include){
          if (trip.favoriteSearchTags!==undefined){
            for (let j =0 ; j<trip.favoriteSearchTags.length ; j++){
              if (trip.favoriteSearchTags[j][this.props.inputLanguage]!==undefined){
                if (trip.favoriteSearchTags[j][this.props.inputLanguage]===tag[this.props.inputLanguage]){
                  include = true;
                }
              }
            }
          }
        }
        if (include){
          filteredTrips.push(trip);
        }      
      }
      await this.updateFavoriteTripsSearchTags(filteredTrips).then(result => {
        favoriteTripPlansSearchTags = result;
      }); 

      let sortedFilteredTrips = await filteredTrips.sort((a, b) => {
          var keyA = a.planLastModified;
          var keyB = b.planLastModified;
          // Compare score
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
          })

      let result = {
        favoriteTripPlansSearchTags:favoriteTripPlansSearchTags,
        filteredTrips:sortedFilteredTrips,
        favoriteTripPlansFilterTags:favoriteTripPlansFilterTags
      }
      resolve(result);
  });
}

updateFavoriteTripsSearchTags= tripsList =>{
  return new Promise(async (resolve, reject) => {
      let searchTags = [];
      let includedTags = [];
      for (let i=0; i<tripsList.length ; i++){
       
        let trip=tripsList[i]
       
        let tripCountries = []
        if (trip.tripCountries!==undefined){
          tripCountries = trip.tripCountries
        }
        let tripLocationTags = []
        if (trip.tripLocationTags!==undefined){
          tripLocationTags = trip.tripLocationTags
        }
        for (let j=0 ; j<tripCountries.length ; j++){
          if (this.props.locationsTranslator[tripCountries[j]]!==undefined){
            if (!includedTags.includes(tripCountries[j])){
              includedTags.push(tripCountries[j])
              searchTags.push(this.props.locationsTranslator[tripCountries[j]])
            }
          }
        }
        for (let j=0 ; j<tripLocationTags.length ; j++){
          if (this.props.locationsTranslator[tripLocationTags[j]]!==undefined){
            if (!includedTags.includes(tripLocationTags[j])){
              includedTags.push(tripLocationTags[j])
              searchTags.push(this.props.locationsTranslator[tripLocationTags[j]])
            }
          }
        }

        let planName = trip.planName
        
        if (!includedTags.includes(planName)){
          includedTags.push(planName)
          let nameLanguage=""
          await this.refs.detectInputLanguage.detectLanguage(planName).then(result => {
            nameLanguage = result
          }); 
          let nameTag = {}
          nameTag[nameLanguage]=planName
          searchTags.push(nameTag)
        }

        if (trip.favoriteSearchTags!==undefined){
          for (let j=0 ; j<trip.favoriteSearchTags.length ; j++){
            if (!includedTags.includes(trip.favoriteSearchTags[j][Object.keys(trip.favoriteSearchTags[j])[0]])){
              searchTags.push(trip.favoriteSearchTags[j]);
              includedTags.push(trip.favoriteSearchTags[j][Object.keys(trip.favoriteSearchTags[j])[0]])
            }
          }
        }
       
      } 
      searchTags = searchTags.filter(tag=>tag!==undefined)
    resolve (searchTags)
  });
}

detectInputLanguage = () =>{
  return (<DetectInputLanguage
      ref="detectInputLanguage"
    />)
}

render(){
    return (
        <div>
          <ShouldPlaceRender
            ref={this.state.shouldPlaceRenderRef}
          />
            {this.detectInputLanguage()}
        </div>
      );
  }
    
  }
  export default SearchFunctions;